import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import Moment from 'moment'
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import * as CONFIG from "../../../../../helpers/config"
import AddQualityParameterModal from './modals/AddQualityParameterModal';
import AddTraceElemetsModel from './modals/AddTraceElemetsModel';

export default function ListData(props) {
    console.log(props)
    const { instance } = useMsal();
    const [qualityParameterModel, setQualityParameterModel] = useState(false)
    const [traceElementsModel, setTraceElementsModel] = useState(false)
    const [quality, setquality] = useState([])
    const [isQuality, setIsQuality] = useState(false)
    const [trace, setTrace] = useState([]);
    const [isTrace, setIsTrace ] = useState(false)
    const [final_list, setFinal] = useState([])
    const [isQualityOrTraceSet, setIsQualityOrTraceSet] = useState(false)
    const [data, setData] = useState()

    useEffect(()=>{
        let qualityparameter = props.values.tradeEnquiryProductQualityParameters
        let traceelements = props.values.tradeEnquiryProductTraceElement
        setquality(qualityparameter)
        setTrace(traceelements)
        setIsTrace(true)
        setIsQuality(true)
        if(props.isEdit == true){
            console.log(props)
            console.log(props.values.productGroupID)
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
               
            }
            let productGroup = ''
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    let cookie_data =  res.data.result.filter(x=> x.productgroup.productGroupID == props.values.productGroupID)
                    console.log(cookie_data)
                    setData(cookie_data[0])
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
    },[])

    let final_json = props.isEdit?props.values   
    :
    {
        "key" : parseInt(props.values.key),
        "productID": parseInt(props.values.productID),
        "productGradeID": 1,
        "productGroupID" : parseInt(props.values.productGroupID),
        "productQuantity": parseInt(props.values.productQuantity),
        "productUOMID": parseInt(props.values.productUOMID),
        "productUOMValue": props.values.productUOMValue,
        "productSellingPrice": parseFloat(props.values.productSellingPrice),
        "productGroupValue": props.values.productGroupValue
    }
    const handleQualityParameterModel = () => {
        setQualityParameterModel(!qualityParameterModel)
    }

    const handleTraceElementsModel = () => {
        setTraceElementsModel(!traceElementsModel)
    }
    const saveQuality = (data)=>{
        console.log(data)
        console.log(data.tradeEnquiryProductQualityParameters)
        if(data.tradeEnquiryProductQualityParameters.length>0){
            console.log('inside')
            setIsQuality(true)
            if(isQualityOrTraceSet){
                console.log(final_json)
                final_json = {...final_list,...data}
            }
            else{
                console.log(final_json)
                final_json = {...final_json,...data}
            }
            console.log(final_json)
            setFinal(final_json)
            setquality(data.tradeEnquiryProductQualityParameters);
            setIsQualityOrTraceSet(true)
            console.log(quality)
            // if(isQualityOrTraceSet){
            //     if(final_list.tradeEnquiryProductQualityParameters.length > 0 && final_list.tradeEnquiryProductTraceElement.length > 0){
            //         props.addTraceAndQuality(final_list)
            //     }
            // }
            console.log(final_json, 'test')
            props.addTraceAndQuality(final_json)
        }
        handleQualityParameterModel()
    }

    const saveTrace = (data)=>{
        console.log(data.tradeEnquiryProductTraceElement)
        if(data.tradeEnquiryProductTraceElement.length>0){
            console.log('inside')
            setIsTrace(true)
            if(isQualityOrTraceSet){
                final_json = {...final_list,...data}
            }
            else{
                final_json = {...final_json,...data}
            }
            console.log(final_json)
            setFinal(final_json)
            setTrace(data.tradeEnquiryProductTraceElement);
            setIsQualityOrTraceSet(true)
            console.log(trace)
            // if(isQualityOrTraceSet){
            //     if(final_list.tradeEnquiryProductQualityParameters.length > 0 && final_list.tradeEnquiryProductTraceElement.length > 0){
            //         props.addTraceAndQuality(final_list)
            //     }
            // }
            props.addTraceAndQuality(final_json)
        }
        handleTraceElementsModel()
    }

    const handleEdit = () => {
        console.log(props)
        props.showModalForEdit()
        props.handleDataForModal(props)
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' onClick={handleEdit}>Edit</span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' onClick={handleQualityParameterModel}>Add Quality Parameter</span>
                    ),
                },
                {
                     key: '3',
                     label: (
                         <span className='font-weight-400 font-14 pl-10 mb-1-rem' onClick={handleTraceElementsModel}>Add Trace Elements</span>
                    ),
                },
            ]}
        />
    );
    return (
        <>
         <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
            {props.tradeInitiateEdit == true && props.values.product != null ? 
               <>
               <td className='textHead font-14 font-weight-500'>{props.values.product.productCode}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.product.hsCode}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.product.productName}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.quantity}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.productBuyingPrice == null ? 0 : props.values.productBuyingPrice}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.productSellingPrice}</td>
               <td className='textHead font-14 font-weight-500'>{props.values.supplyNo}</td>
               <td className='textHead font-14 font-weight-500'>{Moment().month(props.values.tradeEnquiryProductSupplyplan.month).format('MMMM')} {props.values.tradeEnquiryProductSupplyplan.year}</td>
               <td>
               <Dropdown overlay={menu}>
                   <a onClick={(e) => e.preventDefault()}>
                   <Space>
                       <img src = '/assets/images/Dot_icon.svg' />
                   </Space>
                   </a>
               </Dropdown>
               </td>
               </> 
            :
            <>
            <td className='textHead font-14 font-weight-500'>{props.values.productCode}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.hsCode}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.productName}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.quantity}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.productBuyingPrice == null ? 0 : props.values.productBuyingPrice}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.productSellingPrice}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.supplyNo}</td>
            <td className='textHead font-14 font-weight-500'>{props.values.supplyPeriod}</td>
            <td>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <img src = '/assets/images/Dot_icon.svg' />
                </Space>
                </a>
            </Dropdown>
            </td>
            </> 
            }
          {qualityParameterModel == true 
        ?
        <AddQualityParameterModal 
            value={props.values} 
            close={handleQualityParameterModel} 
            saveQuality={saveQuality}
            isQuality ={isQuality}
            qualityData = {quality}
            isEdit={props.isEdit}
            tradeInitiateEdit = {props.tradeInitiateEdit}
        />
        
        :
        
        ""
        }
         {traceElementsModel == true 
        ?
        <AddTraceElemetsModel 
            value={props.values} 
            close={handleTraceElementsModel} 
            saveTrace={saveTrace}
            isTrace={isTrace}
            traceData ={trace}
            isEdit={props.isEdit}
            tradeInitiateEdit = {props.tradeInitiateEdit}
        />
        :
        ""
        }
        </tr>
        </>
    )
}
