import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { AddNewButton } from '../../../components/buttoncomponents'
import Listing from '../common/listing'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config"
import { BlueButton, CancelButton } from '../../../components/buttoncomponents'
import {Row, Col, ModalFooter} from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../components/formcomponents"
import { useFormik } from 'formik'
import { useMsal } from "@azure/msal-react";


export const GetDivision = (props) =>{
    const { instance } = useMsal();
    const [divisionlist, setdivisionlist] = useState([])
    useEffect(()=>{
        const json_list= {
            "instanceID": CONFIG.PMSInstanceID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(CONFIG.CompanyID)
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_DIVISION, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
           setdivisionlist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    
    },[])
    
    return(
        <>
            {divisionlist.map((re,k)=>{
                return(
                    <>
                        <option value={re.divisionID} selected={re.divisionID == props.val}>{re.divisionName}</option>
                    </>
                )
            })}
        </>
    )
}

export default function Index() {
    const { instance } = useMsal();
   
    const [isFirst, setIsFirst] = useState(true);
    const [isAddGroup, setIsAddGroup] = useState(false);
    const [produtGroup, setProductGroup] = useState([]);
    const [length, setLength] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState([]);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState('');
    const [err, seterr] = useState(false)
    const addGroup = ()=>{
        setIsAddGroup(!isAddGroup);
    }
    const formik = useFormik(
        {
            initialValues:
            {
                productGroupID: 0,
                productGroupName: '',
                DivisionID: '',
            },
            validate :values=>{
                let errors ={};
                seterr(false)
                if(!values.productGroupName){
                    errors.productGroupName = "Please enter a value!"
                }
                if(!values.DivisionID && values.DivisionID !=0){
                    errors.DivisionID = "Please select a division"
                }
                return errors;
            }
        }
    )
    useEffect(()=>{
       if(isFirst){
            getlist()
       }
       setIsFirst(false)
    },[])

    const getlist = ()=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setProductGroup(res.data.result);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

   

    const saveCategory = (data)=>{
        console.log(data)
        if(formik.values.DivisionID == 0 || formik.values.productGroupName == ""){
            seterr(true)
        }
        else{
            const json_list = isEdit?{
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "Modified_User" : CONFIG.LoginID,
                "productGroupID": formik.values.productGroupID,
                "productGroupName": formik.values.productGroupName,
                "divisionID": parseInt(formik.values.DivisionID),
            }
            :
            {
                "productGroupName": formik.values.productGroupName,
                "divisionID": parseInt(formik.values.DivisionID),
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
           if(!isEdit){
                axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_PRODUCT_GROUP, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if(res.data.responseCode == '303002'){
                        setError(true)
                        setErrorData('Product group already exists');
                    }
                    else if(res.data.responseCode == '303001'){
                        setError(true)
                        setErrorData('Product group invalid. Please try again!');
                    }
                    else{
                        setError(false)
                    }
                    setIsEdit(false)
                    toggle()
                    getlist()
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
           }
           else{
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.EDIT_PRODUCT_GROUP, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode == '303002'){
                    setError(true)
                    setErrorData('Product group already exists');
                }
                else if(res.data.responseCode == '303001'){
                    setError(true)
                    setErrorData('Product group invalid. Please try again!');
                }
                else{
                    setError(false)
                }
                setIsEdit(false)
                toggle()
                getlist()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
           }
        }
    }

    const edit = (e)=>{
        console.log('ss')
        const id = e.target.id;
        let data = produtGroup.filter(x=> x.productgroup.productGroupID == id);
        console.log(data)
        formik.values.DivisionID = data[0].productgroup.divisionID
        formik.values.productGroupName = data[0].productgroup.productGroupName
        formik.values.productGroupID = data[0].productgroup.productGroupID
        setSelectedData(data);
        setIsEdit(true)
        addGroup();
    }

    const deletedata = (e)=>{
        const id = parseInt(e.target.id);
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.DELETE_PRODUCT_GROUP, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getlist()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const toggle = ()=>{
        formik.values.DivisionID = 0
        formik.values.productGroupName = ''
        formik.values.productGroupID = 0
        setIsAddGroup(!addGroup)
        setIsEdit(false)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'> 
                <Sidebar id='4.3' isOpenid={4}/>
                <div className='width-100'>
                    <Navbar/>
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/pageicons/productsicon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>PRODUCT GROUP</span>
                        </div>
                    </div>
                    <div className='inner-page-content'>
                        <div className=' br-8 py-3'>
                                <Card className='p-md-4 p-2 col-md-7'>
                                    <div className='d-flex justify-content-end'>
                                        <AddNewButton value='Add New Group' class='bg-white' clickEvent={addGroup}/>
                                    </div>
                                    <Modal isOpen={isAddGroup} toggle={toggle} className='mt-10-rem property_info_modal modal_35 mt-5'>
                                            <ModalHeader toggle={toggle}>
                                                <div className='font-16 font-weight-600 color-707895'>Product Group</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <Row className='d-flex plr-2-rem'>
                                                    <FormFields.InputSelect
                                                        md={6}
                                                        classname='mb-3'
                                                        name='DivisionID'
                                                        label='Product Division'
                                                        type='text'
                                                        optionField={<GetDivision val={formik.values.DivisionID}/>}
                                                        onChange={formik.handleChange}
                                                        onBlur ={formik.handleBlur}
                                                        errors= {formik.touched.DivisionID?formik.errors.DivisionID:''}
                                                    />
                                                    <FormFields.InputField
                                                        md={6}
                                                        name='productGroupName'
                                                        label='Product Group*'
                                                        val ={formik.values.productGroupName}
                                                        onChange = {formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                        errors={formik.touched.productGroupName?formik.errors.productGroupName:''}
                                                    />
                                                </Row>
                                                {err &&
                                                    <div className='input_error'>Required fields cannot be empty!</div>
                                                }
                                            </ModalBody>
                                            <ModalFooter className='d-flex justify-content-start' >
                                                <CancelButton value='Cancel' clickEvent={toggle}/>
                                                <BlueButton value='Save' clickEvent={saveCategory}/>
                                            </ModalFooter>
                                        </Modal>
                                    {error &&
                                        <div className='input_error'>{errorData}</div>
                                    }
                                    <table className="table pcustom table-text mt-1-rem">
                                        <thead>
                                            <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                <th>Product Division</th>
                                                <th>Product Group</th>
                                                <th>Company</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {produtGroup.map((cat,k)=>(
                                                <Listing key={k} companyname = {cat.companyname} id={cat.productgroup.productGroupID} name = {cat.productgroup.productGroupName} divisionName={cat.divisionname} length={length} edit={edit} delete={deletedata} isGroup={true}/>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card>
                        </div>
                    </div>
                </div>
                

        </div>
    )
}
