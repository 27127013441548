import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import * as CONFIG from '../../../helpers/config'
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function UpdateInsuranceDetails(props) {
    const { instance } = useMsal();
    const [insuranceDocument, setInsuranceCompany] = useState('')
    const [issuedDate, setIssueDate] = useState(false);
    const [isdisabled, setisdisabled] = useState(false)
    const [customerData, setCustomerData] = useState([])
    const location = useLocation()
    const MySwal = withReactContent(Swal);
    let navigate = useNavigate();
    const tradeData = props.tradeData
    const tab = props.tab
    const updateinsurancedetails = props.updateInsuranceDetails
    console.log(tradeData)
    useEffect(() => {
        if (updateinsurancedetails != null) {
            setInsuranceCompany(updateinsurancedetails.insuranceDocumentName)
        }
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerData(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData.tradeID,
            issuedDate: updateinsurancedetails != null ? Moment(updateinsurancedetails.issuedDate) : "",
            policyNo: updateinsurancedetails != null ? updateinsurancedetails.policyNo : '',
            insuranceCompany: updateinsurancedetails != null ? updateinsurancedetails.insuranceCompany : "",
            insuranceDocument: updateinsurancedetails != null ? updateinsurancedetails.insuranceDocument : "",
            insuranceDocumentName: updateinsurancedetails != null ? updateinsurancedetails.insuranceDocumentName : "",
            insuredValue: updateinsurancedetails != null ? updateinsurancedetails.insuredValue : 0,
            premium: updateinsurancedetails != null ? updateinsurancedetails.premium : 0
        },
    })

    const handleCompanyLogo = (file) => {
        formik.values.insuranceDocument = file.base64;
        formik.values.insuranceDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setInsuranceCompany(file.name)
        console.log(file.name)
    }

    const handleBankSelect = () => {

    }

    const handleIssueDate = (date) => {
        console.log('click')


        if (date == null) {
            formik.values.issuedDate = false
        }
        else {
            formik.values.issuedDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')

        }
        setIssueDate(date)
    }


    const handleSaveEvent = () => {
        setisdisabled(true)
        if (updateinsurancedetails != null) {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "issuedDate": formik.values.issuedDate,
                "InsuranceType": CONFIG.RoleID == 3021 ||
                    CONFIG.RoleID == 3022 ||
                    CONFIG.RoleID == 3031 ?
                    parseInt(1) :
                    CONFIG.RoleID == 3005 ?
                        parseInt(2) :
                        1,
                "policyNo": formik.values.policyNo,
                "insuranceCompany": formik.values.insuranceCompany,
                "insuranceDocument": formik.values.insuranceDocument,
                "insuranceDocumentName": formik.values.insuranceDocumentName,
                "insuredValue": parseInt(formik.values.insuredValue),
                "premium": parseInt(formik.values.premium),
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "tradeInsuranceDetailsID": updateinsurancedetails.tradeInsuranceDetailsID,
                "modified_User": CONFIG.LoginID

            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_INSURANCE_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Insurace Details have been updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    setisdisabled(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "issuedDate": formik.values.issuedDate,
                "InsuranceType": CONFIG.RoleID == 3021 ||
                    CONFIG.RoleID == 3022 ||
                    CONFIG.RoleID == 3031 ?
                    parseInt(1) :
                    CONFIG.RoleID == 3005 ?
                        parseInt(2) :
                        1,
                "policyNo": formik.values.policyNo,
                "insuranceCompany": formik.values.insuranceCompany,
                "insuranceDocument": formik.values.insuranceDocument,
                "insuranceDocumentName": formik.values.insuranceDocumentName,
                "insuredValue": parseInt(formik.values.insuredValue),
                "premium": parseInt(formik.values.premium),
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,

            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_INSURANCE_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Insurace Details have been updated!',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Insurace Details have been updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='width-100'>
                <div className='d-flex mt-4' style={{ paddingLeft: '32px', }}>
                    {/* <div className = 'd-flex justify-content-center align-items-center '>
                        <ButtonComponent.BackButton clickEvent={back} />
                    </div> */}
                </div>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Update Insurance Details</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        <Card className='bg-white'>
                            <Card.Body>
                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                    Shipment Details
                                </div>
                                <div className="m-3 p-4 grey-color">
                                    {tradeData != null ?
                                        <>
                                            <div className='d-flex align-items-center justify-content-between col-md-10'>
                                                {customerData.map((data, k) => {
                                                    return (
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                            <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Date</div>
                                                    <div className='font-weight-600 color-243448'>{Moment().format('DD/MM/YYYY')}</div>
                                                </div>

                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Incoterms</div>
                                                    <div className='font-weight-600 color-243448'>{tradeData.tradedetails.incoterms.incotermsName}</div>
                                                </div>

                                                <div>

                                                </div>
                                            </div>
                                            <hr />
                                            <div className='d-flex align-items-center justify-content-between mt-3 col-md-10'>
                                                <>
                                                    <div className='d-flex flex-column'>
                                                        <div className='font-weight-400 color-697382'>Mode of Transport</div>
                                                        <div className='font-weight-600 color-243448'>{tradeData.tradedetails.modeOfTransport.modeOfTransportName}</div>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <div className='font-weight-400 color-697382'>Load Port</div>
                                                        <div className='font-weight-600 color-243448'>{tradeData.tradedetails.loadPort}</div>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <div className='font-weight-400 color-697382'>Discharge Port</div>
                                                        <div className='font-weight-600 color-243448'>{tradeData.tradedetails.destinationPort}</div>
                                                    </div>
                                                    <div>

                                                    </div>
                                                </>
                                            </div>
                                        </>
                                        :
                                        ""
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='bg-white my-3'>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            {CONFIG.RoleID == 3021 ||
                                                CONFIG.RoleID == 3022 ||
                                                CONFIG.RoleID == 3031 ?
                                                'Cargo' :
                                                CONFIG.RoleID == 3005 ?
                                                    'C/L' :
                                                    'Cargo'} Insurance Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='policyNo'
                                                label='Policy No.'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.policyNo}

                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='insuranceCompany'
                                                label='Insurance Company'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.insuranceCompany}

                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <FormFields.DatePick
                                                md={3}
                                                label='Issued Date'
                                                name='issuedDate'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleIssueDate}
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.issuedDate}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='insuredValue'
                                                label='Insured Value'
                                                classname='pt-1-rem'
                                                type='number'
                                                val={formik.values.insuredValue}

                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <Row>
                                                <FormFields.InputField
                                                    md={3}
                                                    name='premium'
                                                    label='Premium'
                                                    classname='pt-1-rem'
                                                    type='number'
                                                    val={formik.values.premium}

                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                                <Col md={3} className='pt-1-rem align-items-center'>
                                                    <ButtonComponent.UploadButton value='Insurance Doc' onUploadHandle={handleCompanyLogo} filename={insuranceDocument} />
                                                </Col>
                                            </Row>
                                        </>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>

                                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={handleSaveEvent} disabled={isdisabled} /></span>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateInsuranceDetails