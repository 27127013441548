import React, { useEffect, useState } from 'react'
import {Row} from "react-bootstrap";
import AddBankAccount from "./addBankAccount"
import { AddNewButton } from '../../../../components/buttoncomponents'
import List_contract_row from './list_contract_row';

export default function ListBankAccount(props) {
  console.log(props)
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)



    useEffect(()=>{
      if(isFirstLoad){
        if(props.bankList.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.bankList.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    })
    useEffect(()=>{
      console.log()
      if(props.isEdit == true && isFirstEdit == true){
        console.log(props.bankList, 'bank lisr')
        setIsAdd(false)
        setIsFirstEdit(false)
      }
    })
    useEffect(()=> {
      if(props.isFinanceSet == true){
        setIsAdd(false)
      }
    },[])
    const openAddCustomer =()=>{
      setIsAdd(true);
    }

    const addBanktoList= (data)=>{
      setIsAdd(false);
      props.addBanktoList(data)
    }

    const editBank =(e)=>{
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.bankList.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToCustomer= (data)=>{
      console.log(data)
      let selected = props.bankList.filter(x=> x.key == data.key)
      let remaining = props.bankList.filter(x=> x.key != data.key)
      remaining.push(selected)
      setIsAdd(false);
      setIsEdit(false);
      props.editBankToList(data)
    }
    const handleCancel = () => {
      setIsAdd(false)
    }
    return (
      <Row className='d-flex' style={{background: '#fff'}}>
          <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
              Bank Account Information
              <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Account' clickEvent={openAddCustomer}/></span>
          </div>
          {!isAdd?
              <div className='mt-2-rem'>
                <div className='d-flex justify-content-end color-7350EA font-weight-500'> 
                      <img src='assets/images/information.svg'/>&nbsp;Choose a primary bank account
                </div>
                  <table className="table table-text mt-1-rem">
                    <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                            <th scope='col' className=' font-weight-500'>Bank Name</th>
                            <th scope='col' className=' font-weight-500'>Account No</th>
                            <th scope='col' className=' font-weight-500'>IBAN</th>
                            <th scope='col' className=' font-weight-500'>Currency No</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.bankList.map((values)=>(
                          <List_contract_row  is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected} is_DefaultAvailable={values.is_Default?true:false} values={values} bankList ={props.bankList} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                        ))}
                    </tbody>
                </table>
              </div>
          :
            <AddBankAccount setErrors = {props.setErrors} handleError = {props.handleError} addBanktoList ={addBanktoList} isEdit={isEdit} data={selectedBank} editBank={editBankToCustomer} handleCancel = {handleCancel} />
          }
      </Row>
    )
}
