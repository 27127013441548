import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from "react-bootstrap";
import App from "./menu_topbar_dropdown"
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import * as CONFIG from "../helpers/config"
import axios from "axios"
import Moment from "moment"
import { Dropdown, Menu, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import MobileSidebar from "./sidebar/mobile_sidebar"
import Alert from 'react-bootstrap/Alert';

export default function Navbar() {
    const { instance } = useMsal();
    const [modal, setModal] = useState(false);
    const [connection, setConnection] = useState(null);
    const [inputText, setInputText] = useState("");
    const [count, setCount] = useState(0)
    const [notification, setNotification] = useState([])
    const [item, setItem] = useState([])
    const [alerts, setalerts] = useState([])
    const [show, setShow] = useState(true);
    useEffect(() => {
        awaitmessage("Sample")
        getAllNotifications()
        GetBroadcastMessageForUsers()
    }, [])
    useEffect(() => {
        // console.log(item)
    }, [item])
    const getAllNotifications = () => {
        const json_list = {
            "ID": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let length = res.data.result.length
                setCount(length)
                setNotification(res.data.result)
                console.log(res.data.result, 'notifications list')
                let item = []
                item = [{
                    key: 1,
                    label: (
                        <div className='font-16 color-243448 font-weight-500 px-4'>Notifications <span className='notify_count'>{length}</span></div>
                    )
                }]
                let final = 0
                res.data.result.map((notify, k) => {
                    if (k < 4) {
                        let it = {
                            key: k + 2,
                            label: (
                                notify.description.toLowerCase().includes("send for approval") ?

                                    <a target="_blank" rel="noopener noreferrer" href="/approvals">
                                        <Row className='bottom_border'>
                                            <Col md={1}>
                                                <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                    <p className='font-14 font-weight-500 mb-0'>{notify.createdUserImage}</p>
                                                </div>
                                            </Col>
                                            <Col md={11} className='font-13'>
                                                <div className='font-weight-600'>{notify.description}</div>
                                                <div>{notify.message}</div>
                                                <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                            </Col>
                                        </Row>
                                    </a>
                                    :
                                    <a target="_blank" rel="noopener noreferrer" href="/trade-transactions">
                                        <Row className='bottom_border'>
                                            <Col md={1}>
                                                <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                    <p className='font-14 font-weight-500 mb-0'>{notify.createdUserImage}</p>
                                                </div>
                                            </Col>
                                            <Col md={11} className='font-13'>
                                                <div className='font-weight-600'>{notify.description}</div>
                                                <div>{notify.message}</div>
                                                <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                            </Col>
                                        </Row>
                                    </a>
                            )
                        }

                        item.push(it)
                        final = k + 3
                    }
                })
                let it1 = {
                    label: (
                        <center>
                            <span className='outline-black' onClick={toggle}>View All</span>
                        </center>
                    )
                }
                item.push(it1)
                setItem(item)
                console.log(item, 'menu itemss')
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const awaitmessage = async (user) => {
        try {
            const newConnection = new HubConnectionBuilder()
                .withUrl('http://20.195.98.183/signalhub')
                .configureLogging(LogLevel.Information)
                .build();
            newConnection.on("Que_InAppNotification", (message) => {
                let mes = message.rolewiseInAppNotification
                if (mes.length > 0) {
                    mes.map((me, k) => {
                        if (me.userID == CONFIG.LoginID) {
                            getAllNotifications()
                        }
                    })
                }
            });
            await newConnection.start()
            await newConnection.invoke("SignalREvent", 'sample')


            const newConnection1 = new HubConnectionBuilder()
                .withUrl('http://20.195.98.183/signalhub')
                .configureLogging(LogLevel.Information)
                .build();
            newConnection1.on("Que_BroadcastMsgNotification", (message) => {
                let mes = message.rolewiseInAppNotification
                GetBroadcastMessageForUsers()
            });
            await newConnection1.start()
            await newConnection1.invoke("SignalREvent", 'sample')
        }
        catch (E) {
            console.log(E)
        }
    }

    const GetBroadcastMessageForUsers = () => {
        if (!localStorage.getItem('closeAlert')) {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_BROADCAST_MESSAGE_USERS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setalerts(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const toggle = () => {
        setModal(!modal)
    }
    const getNotifications = () => {

    }
    const sendMessage = async () => {
        if (connection) await connection.send("SendMessage");
        setInputText("");
    };
    const clearNotifications = () => {
        const json_list = {
            "ID": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CLEAR_ALL_NOTIFICATIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                getAllNotifications()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const menu = (
        <Menu
            items={item}
        />
    );

    const closeAlert = () => {
        setShow(false)
        localStorage.setItem('closeAlert', true)
    }

    return (
        <>

            {/* mobile nav */}
            <div className='mobHeader py-4 w-100 px-4 bg-white d-block d-md-none d-flex fixed-top'>

                <div className='col-8'>
                    <Link to='/dashboard'>
                        <img src="/assets/images/tmslanding/tmslogo.svg" className='img-fluid' />
                    </Link>
                </div>

                <div className='col-4 text-right'>
                    <MobileSidebar />
                </div>

            </div>
            <div id="content" className='mobHeader mt-4-rem' >
                <nav className='bg-white navbar navbar-expand-md  mobile-fixed-top' style={{ top: '13%' }}>

                    <div className='container-fluid '>

                        <Col md={6} className='d-flex col-12 justify-content-between align-items-center'>
                            <div className='flex-fill'>
                                <Link to='/meetings' className='d-flex pe-md-5 pe-4 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/meeting.svg' />&nbsp;
                                    <span className='font-14 color-23 d-flex align-items-end d-none d-sm-block d-md-block'>Meetings</span>
                                </Link>
                            </div>
                            <div className='flex-fill'>
                                <Link to='/tasks' className='d-flex pe-md-5 pe-4 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/tasks.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Tasks</span>
                                </Link>
                            </div>
                            <div className='flex-fill'>
                                <Link to='/tourreports' className='d-flex pe-md-5 pe-4 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/tourreports.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Tour Reports</span>
                                </Link>
                            </div>
                            {(localStorage.getItem('Approvals-Menu') == 3 || localStorage.getItem('Approvals-Menu') == 1) &&

                                <div className='d-flex pe-md-5 pe-5 align-self-center'>
                                    <Link to='/approvals' className='d-flex'>
                                        <img className='iconTopbar' src='../assets/images/pageicons/approval.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Approvals</span>
                                    </Link>
                                </div>
                            }
                            {/* 
                        <div className='d-flex  ms-2 align-self-center'>
                        <img className='iconTopbar' src='../assets/images/fullscreenicon.svg'/> 
                        </div>
                        */}
                            {/* <div className='d-flex justify-content-start'>
                            <img src='../assets/images/mail_navbar.svg'/>
                        </> */}
                            <div className='fd-flex pe-md-5 pe-3 ps-3 ps-md-0'>
                                {count > 0 ?
                                    <>
                                        <Dropdown overlay={menu}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <img src='../assets/images/notifications_av.svg' className='pointer' />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <img src='../assets/images/notification_navbar.svg' className='pointer' />
                                    </>
                                }

                            </div>

                            <div md={2} className=' userMobile flex-fill'>
                                <App />
                            </div>
                        </Col>

                        {/* <div md={2} className='col-6 userMobile'>
                    <App/> 
                </div> */}

                    </div>

                    <Modal isOpen={modal} toggle={toggle} className='payment-method-modal'>
                        <ModalHeader toggle={toggle} className='p-40'>
                            <div className='d-flex justify-content-between '>
                                <div className='font-16 color-243448 font-weight-500'>
                                    Notifications <span className='notify_count'>{count}</span>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody style={{ overflowY: 'scroll' }}>
                            {/*{notification.reverse().map((notify,k)=>{*/}
                            {notification.reverse().map((notify, k) => {
                                return (
                                    <>
                                        {notify.description.toLowerCase().includes("send for approval") ?
                                            <a href="/approvals" target='_blank' style={{ color: '#000' }}>
                                                <div className='bottom_border'>
                                                    <div>{notify.description}</div>
                                                    <div>{notify.message}</div>
                                                    <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                                </div>
                                            </a>
                                            :
                                            <a href="trade-transactions" target='_blank' style={{ color: '#000' }}>
                                                <div className='bottom_border'>
                                                    <div>{notify.description}</div>
                                                    <div>{notify.message}</div>
                                                    <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                                </div>
                                            </a>
                                        }
                                    </>
                                )
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <span className='pointer color-2C67FF' onClick={clearNotifications}>Clear All</span>
                        </ModalFooter>
                    </Modal>
                </nav>
            </div>
            {/* mobile nav */}

            <div id="content" className='desktop_view '>
                <nav className='bg-white navbar navbar-expand-md  fixed-top' style={{ zIndex: '999' }}>

                    <div className='container-fluid mx-3'>
                        <Col md={4} className='ps-3 '>
                        </Col>

                        <Col md={8} className='d-flex col-6 justify-content-end'>
                            <div className='align-self-center'>
                                <Link to='/meetings' className='d-flex pe-md-5 pe-5 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/meeting.svg' />&nbsp;
                                    <span className='font-14 color-23 d-flex align-items-end d-none d-sm-block d-md-block'>Meetings</span>
                                </Link>
                            </div>
                            <div className='d-flex align-self-center'>
                                <Link to='/tasks' className='d-flex pe-md-5 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/tasks.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Tasks</span>
                                </Link>
                            </div>
                            <div className='d-flex align-self-center'>
                                <Link to='/tourreports' className='d-flex pe-md-5 ps-3 ps-md-0'>
                                    <img className='iconTopbar' src='../assets/images/tourreports.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Tour Reports</span>
                                </Link>
                            </div>
                            {(localStorage.getItem('Approvals-Menu') == 3 || localStorage.getItem('Approvals-Menu') == 1) &&
                                <div className='d-flex pe-md-5 pe-5 align-self-center'>
                                    <Link to='/approvals' className='d-flex'>
                                        <img className='iconTopbar' src='../assets/images/pageicons/approval.svg' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Approvals</span>
                                    </Link>
                                </div>
                            }
                            {/* {localStorage.getItem('LogInUserRoleID') == 3001 &&
                                <div className='d-flex pe-md-5 pe-5 align-self-center'>
                                    <Link to='/broadcast' className='d-flex'>
                                        <img className='iconTopbar' src='../assets/images/broadcast.png' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Broadcast</span>
                                    </Link>
                                </div>
                            } */}
                            {/* 
                        <div className='d-flex  ms-2 align-self-center'>
                        <img className='iconTopbar' src='../assets/images/fullscreenicon.svg'/> 
                        </div>
                        */}
                            {/* <div className='d-flex justify-content-start'>
                            <img src='../assets/images/mail_navbar.svg'/>
                        </> */}
                            <div className='d-flex align-self-center'>
                                {count > 0 ?
                                    <>
                                        <Dropdown overlay={menu}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <img src='../assets/images/notifications_av.svg' className='pointer' />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <img src='../assets/images/notification_navbar.svg' className='pointer' />
                                    </>
                                }

                            </div>

                            <div md={2} className=' userMobile ms-md-5'>
                                <App />
                            </div>
                        </Col>

                        {/* <div md={2} className='col-6 userMobile'>
                    <App/> 
                </div> */}

                    </div>

                    <Modal isOpen={modal} toggle={toggle} className='payment-method-modal'>
                        <ModalHeader toggle={toggle} className='p-40'>
                            <div className='d-flex justify-content-between '>
                                <div className='font-16 color-243448 font-weight-500'>
                                    Notifications <span className='notify_count'>{count}</span>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody style={{ overflowY: 'scroll' }}>
                            {/*{notification.reverse().map((notify,k)=>{*/}
                            {notification.reverse().map((notify, k) => {
                                return (
                                    <>
                                        {notify.description.toLowerCase().includes("send for approval") ?

                                            <a target="_blank" rel="noopener noreferrer" href="/approvals" style={{ color: '#000' }} className='mt-10-rem'>
                                                <Row className='bottom_border mt-10-rem'>
                                                    <Col md={1}>
                                                        <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                            <p className='font-14 font-weight-500 mb-0'>{notify.createdUserImage}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={11} className='font-13'>
                                                        <div className='font-weight-600'>{notify.description}</div>
                                                        <div>{notify.message}</div>
                                                        <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                                    </Col>
                                                </Row>
                                            </a>
                                            :
                                            <a target="_blank" rel="noopener noreferrer" href="/trade-transactions" style={{ color: '#000' }} className='mt-10-rem'>
                                                <Row className='bottom_border mt-10-rem'>
                                                    <Col md={1}>
                                                        <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                            <p className='font-14 font-weight-500 mb-0'>{notify.createdUserImage}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={11} className='font-13'>
                                                        <div className='font-weight-600'>{notify.description}</div>
                                                        <div>{notify.message}</div>
                                                        <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                                    </Col>
                                                </Row>
                                            </a>

                                        }
                                    </>
                                )
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <span className='pointer color-2C67FF' onClick={clearNotifications}>Clear All</span>
                        </ModalFooter>
                    </Modal>
                </nav>
            </div>

            <div>
                {alerts != null &&
                    alerts.map((alert, k) => {
                        return (
                            <>
                                {show &&
                                    <Alert variant="warning" onClose={closeAlert} dismissible className='d-flex justify-content-center'>
                                        <b>{alert.broadcastMessageText}</b>
                                    </Alert>
                                }
                            </>
                        )
                    })
                }
            </div>
        </>

    )
}
