import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { Row, Col, Form, Card, Button } from "react-bootstrap"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";
import ViewInvoice from '../../Invoices/CustomerPerformaInvoice/ViewInvoice';
import ViewSupplierPurchaseOrder from '../InvoiceLists/Components/ViewSupplierPurchaseOrder'

export default function PreShipment(props) {
    const { instance } = useMsal();
    const location = useLocation()
    let navigate = useNavigate();
    const tradeData = props.tradeData
    const tab = props.tab
    const [doc1, setdoc1] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedDetails, setSelectedDetail] = useState([])
    const MySwal = withReactContent(Swal)
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [isPOOpen, setIsPOOpen] = useState(false)

    useEffect(() => {
        console.log(location.state)
    }, [])
    const formik = useFormik({
        initialValues: {
            tradePreShipmentCheckListID: 0,
            is_IssueProformaInvoice: false,
            issueProformaInvoiceNo: '',
            issueProformaInvoiceDocument: '',
            issueProformaInvoiceDocumentName: '',
            is_IssueSupplierPurchaseOrder: false,
            issueSupplierPurchaseOrderNo: '',
            issueSupplierPurchaseOrderDocument: '',
            issueSupplierPurchaseOrderDocumentName: '',
            is_SupplierProformaInvoice: false,
            supplierProformaNo: "",
            supplierProformaInvoiceDocument: "",
            supplierProformaInvoiceDocumentName: "",
            is_FixVessel: false,
            fixVesselDocument: '',
            fixVesselDocumentName: '',
            is_LcCustomer: false,
            lcCustomerDocument: '',
            lcCustomerDocumentName: '',
            is_LcSupplier: false,
            lcSupplierDocument: '',
            lcSupplierDocumentName: '',
            Remarks: '',
        }
    })
    useEffect(() => {
        console.log(formik.values)
    }, [formik.values])
    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID // TradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PRE_SHIPMENT_BY_TRADEID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.length > 0) {
                    let result = res.data.result[0]
                    formik.values.tradePreShipmentCheckListID = result.tradePreShipmentCheckListID
                    formik.values.is_IssueProformaInvoice = result.is_IssueProformaInvoice
                    formik.values.issueProformaInvoiceNo = result.issueProformaInvoiceNo
                    formik.values.issueProformaInvoiceDocument = result.issueProformaInvoiceDocument
                    formik.values.issueProformaInvoiceDocumentName = result.issueProformaInvoiceDocumentName
                    formik.values.is_IssueSupplierPurchaseOrder = result.is_IssueSupplierPurchaseOrder
                    formik.values.issueSupplierPurchaseOrderNo = result.issueSupplierPurchaseOrderNo
                    formik.values.issueSupplierPurchaseOrderDocument = result.issueSupplierPurchaseOrderDocument
                    formik.values.issueSupplierPurchaseOrderDocumentName = result.issueSupplierPurchaseOrderDocumentName
                    formik.values.is_SupplierProformaInvoice = result.is_SupplierProformaInvoice
                    formik.values.supplierProformaNo = result.supplierProformaNo
                    formik.values.supplierProformaInvoiceDocument = result.supplierProformaInvoiceDocument
                    formik.values.supplierProformaInvoiceDocumentName = result.supplierProformaInvoiceDocumentName
                    formik.values.is_FixVessel = result.is_FixVessel
                    formik.values.fixVesselDocument = result.fixVesselDocument
                    formik.values.fixVesselDocumentName = result.fixVesselDocumentName
                    formik.values.is_LcCustomer = result.is_LcCustomer
                    formik.values.lcCustomerDocument = result.lcCustomerDocument
                    formik.values.lcCustomerDocumentName = result.lcCustomerDocumentName
                    formik.values.is_LcSupplier = result.is_LcSupplier
                    formik.values.lcSupplierDocument = result.lcSupplierDocument
                    formik.values.lcSupplierDocumentName = result.lcSupplierDocumentName
                    formik.values.Remarks = result.remarks
                    setdoc1(!doc1)
                    setIsEdit(true)
                    props.refreshList()
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const savePreShipment = () => {
        const json_list = {
            "tradeID": tradeData.tradeID,
            "is_IssueProformaInvoice": formik.values.is_IssueProformaInvoice,
            "issueProformaInvoiceNo": formik.values.issueProformaInvoiceNo,
            "issueProformaInvoiceDocument": formik.values.issueProformaInvoiceDocument,
            "issueProformaInvoiceDocumentName": formik.values.issueProformaInvoiceDocumentName,
            "is_IssueSupplierPurchaseOrder": formik.values.is_IssueSupplierPurchaseOrder,
            "issueSupplierPurchaseOrderNo": formik.values.issueSupplierPurchaseOrderNo,
            "issueSupplierPurchaseOrderDocument": formik.values.issueSupplierPurchaseOrderDocument,
            "issueSupplierPurchaseOrderDocumentName": formik.values.issueSupplierPurchaseOrderDocumentName,
            "is_SupplierProformaInvoice": formik.values.is_SupplierProformaInvoice,
            "supplierProformaNo": formik.values.supplierProformaNo,
            "supplierProformaInvoiceDocument": formik.values.supplierProformaInvoiceDocument,
            "supplierProformaInvoiceDocumentName": formik.values.supplierProformaInvoiceDocumentName,
            "is_FixVessel": formik.values.is_FixVessel,
            "fixVesselDocument": formik.values.fixVesselDocument,
            "fixVesselDocumentName": formik.values.fixVesselDocumentName,
            "is_LcCustomer": formik.values.is_LcCustomer,
            "lcCustomerDocument": formik.values.lcCustomerDocument,
            "lcCustomerDocumentName": formik.values.lcCustomerDocumentName,
            "is_LcSupplier": formik.values.is_LcSupplier,
            "lcSupplierDocument": formik.values.lcSupplierDocument,
            "lcSupplierDocumentName": formik.values.lcSupplierDocumentName,
            "Remarks": formik.values.Remarks,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        if (isEdit) {
            json_list.tradePreShipmentCheckListID = parseInt(formik.values.tradePreShipmentCheckListID)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_PRE_SHIPMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Pre-shipment details updated successfully!',
                            type: 'success',
                            confirmButtonText: 'Okay',
                        }
                    ).then(() => {
                        navigate("/trade-transactions", { state: { tab: tab } });
                    });
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_PRE_SHIPMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Pre-shipment details added successfully!',
                            type: 'success',
                            confirmButtonText: 'Okay',
                            cancelButtonText: 'Cancel'
                        }
                    ).then(() => {
                        navigate("/trade-transactions", { state: { tab: tab } });
                    });
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    const saveProformaDoc = (file) => {
        formik.values.issueProformaInvoiceDocument = file.base64
        formik.values.issueProformaInvoiceDocumentName = file.name
        setdoc1(!doc1)
    }
    const saveSupplierDoc = (file) => {
        formik.values.issueSupplierPurchaseOrderDocument = file.base64
        formik.values.issueSupplierPurchaseOrderDocumentName = file.name
        setdoc1(!doc1)
    }
    const saveSupplierInvoice = (file) => {
        formik.values.supplierProformaInvoiceDocument = file.base64
        formik.values.supplierProformaInvoiceDocumentName = file.name
        setdoc1(!doc1)
    }
    const saveFixVessel = (file) => {
        formik.values.fixVesselDocument = file.base64
        formik.values.fixVesselDocumentName = file.name
        setdoc1(!doc1)
    }

    const saveLcCustomer = (file) => {
        formik.values.lcCustomerDocument = file.base64
        formik.values.lcCustomerDocumentName = file.name
        setdoc1(!doc1)
    }
    const saveLcSupplier = (file) => {
        formik.values.lcSupplierDocument = file.base64
        formik.values.lcSupplierDocumentName = file.name
        setdoc1(!doc1)
    }
    const handleProformaInvoice = () => {
        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID) //tradeid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT_DETAILS, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedDetail(res.data.result[0])
                openInvoice()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const openInvoice = () => {
        console.log('inside')
        setIsInvoiceOpen(!isInvoiceOpen)
    }

    const openSupplierPO = ()=>{
        console.log('inside')
        setIsPOOpen(!isPOOpen)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>

            {isInvoiceOpen &&
                <ViewInvoice isOpen={isInvoiceOpen} toggle={openInvoice} data={selectedDetails != null ? selectedDetails : ''} />
            }
            <div className='w-100'>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>PRE-SHIPMENT CHECKLIST</span>
                    </div>
                </div>
                <div className=' mx-4'>
                    <div className='add-edit-wrapper'>
                        <div className='d-flex justify-content-between'>
                            <Row className=' w-100'>
                                <Card style={{ background: '#fff' }}>
                                    <Card.Body className='form-card-body mb-10-rem' style={{ overflowY: 'scroll' }}>
                                        <Col md={12}>
                                            <Row className='border-bottom-1 pt-1 pb-4'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_IssueProformaInvoice'
                                                        label='Issue Proforma Invoice.'
                                                        checked={formik.values.is_IssueProformaInvoice}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                    md={3}
                                                    name='issueProformaInvoiceNo'
                                                    label='Invoice No.'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.issueProformaInvoiceNo}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <Col md={3} className='pt-1-rem align-items-center'>
                                                    <Button lg="md" variant='primary' onClick={handleProformaInvoice}>View</Button>
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 pb-4 pt-2'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_IssueSupplierPurchaseOrder'
                                                        label='Issue Supplier Purchase Order.'
                                                        checked={formik.values.is_IssueSupplierPurchaseOrder}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                    md={3}
                                                    name='issueSupplierPurchaseOrderNo'
                                                    label='Supplier Purchase Order No.'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    val={formik.values.issueSupplierPurchaseOrderNo}
                                                />
                                                <Col md={3} className='pt-1-rem align-items-center'>
                                                    <Button lg="md" variant='primary' onClick={openSupplierPO}>View</Button>
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 pb-4 pt-2'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_SupplierProformaInvoice'
                                                        label='Supplier Proforma No.'
                                                        checked={formik.values.is_SupplierProformaInvoice}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                    md={3}
                                                    name='supplierProformaNo'
                                                    label='Supplier Proforma No'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.supplierProformaNo}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton
                                                        value='Supplier Invoice'    
                                                        document={formik.values.supplierProformaInvoiceDocument}
                                                        filename={formik.values.supplierProformaInvoiceDocumentName != "" && formik.values.supplierProformaInvoiceDocumentName}
                                                        onUploadHandle={saveSupplierInvoice}
                                                        multiple={false}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 pb-4 pt-2'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_FixVessel'
                                                        label='Fix Vessel As Per Laycan Agreed With Shippers.'
                                                        checked={formik.values.is_FixVessel}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <Col md={3}></Col>
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton value='Document'
                                                        onUploadHandle={saveFixVessel}
                                                        multiple={false}
                                                        filename={formik.values.fixVesselDocumentName}
                                                        document={formik.values.fixVesselDocument}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 pb-4 pt-2'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_LcCustomer'
                                                        label='L/C from Customer.'
                                                        checked={formik.values.is_LcCustomer}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <Col md={3}></Col>
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton value='L/C Customer'
                                                        onUploadHandle={saveLcCustomer}
                                                        multiple={false}
                                                        filename={formik.values.lcCustomerDocumentName}
                                                        document={formik.values.lcCustomerDocument}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 pb-4 pt-2'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_LcSupplier'
                                                        label='L/C from Supplier.'
                                                        checked={formik.values.is_LcSupplier}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                                <Col md={3}></Col>
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton value='L/C Supplier'
                                                        onUploadHandle={saveLcSupplier}
                                                        multiple={false}
                                                        filename={formik.values.lcSupplierDocumentName}
                                                        document={formik.values.lcSupplierDocument}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className='d-flex align-items-center'>
                                                    <FormFields.inputFieldArea
                                                        md={12}
                                                        row={6}
                                                        name='Remarks'
                                                        label='Remarks'
                                                        classname='pt-1-rem'
                                                        val={formik.values.Remarks}
                                                        type='text'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {isPOOpen &&
                                            <ViewSupplierPurchaseOrder toggle={openSupplierPO} invoiceList = {true} handleClose = {openSupplierPO} data = {tradeData} tradeID = {tradeData.tradeID}/>
                                        }
                                    </Card.Body>
                                    <Card.Footer className='form-card-footer p-2-rem d-flex'>

                                        <span className='pl-10'><BlueButton value='Save' clickEvent={savePreShipment} /></span>
                                    </Card.Footer>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    )
}
