import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { useMemo } from 'react'
import * as CONFIG from  "../../helpers/config"
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../components/formcomponents"
import { BlueButton, BlueBorderButton, CancelButton } from '../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    const { Step } = Steps;
    const [workflow, setWorkFlows] = useState([])
    const [isModalOpen, setIsModalOpen] = useState()
    const [selectedworkflowid, setselectedwf] = useState(0)
    const [selecteddef, setselectedef] = useState([])
    useEffect(()=>{
        getList()
    },[])

    const formik = useFormik(
        {
            initialValues:
            {
                value : ''
            },
            validate : values =>{
                let errors ={};
                if(!formik.values.value){
                    errors.value = "Please enter a step name!"
                }
                return errors;
            }
        }
    )
    const getList = () =>{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_WORKFLOW_DEFINITIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            let result = res.data.result.filter(x => x.messageCategory == 'Trade')
            setWorkFlows(result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const toggle =() =>{
        setIsModalOpen(!isModalOpen)
    }
    const removeWorkFlow = (defstepsid, defid)=>{
        let workfl = workflow.filter(x=> x.workflowDefinitionID == defid)[0]
        let allworkflow = workfl.workflowDefinitionSteps
        let selectedwf = allworkflow.filter(x=> x.workflowDefinitionStepsID == defstepsid)[0]
        let remainingwf = allworkflow.filter(x=> x.workflowDefinitionStepsID != defstepsid)
        selectedwf.is_Active = false
        selectedwf.is_Deleted = true
        console.log(selectedwf)
        remainingwf.push(selectedwf)
        workfl.workflowDefinitionSteps = remainingwf
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_WORKFLOW_DEFENITIONS, workfl, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            getList()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const addworkflowstep = (defstepsid, defid) =>{
        let workfl = workflow.filter(x=> x.workflowDefinitionID == defid)[0]
        let allworkflow = workfl.workflowDefinitionSteps
        let selectedwf = allworkflow.filter(x=> x.workflowDefinitionStepsID == defstepsid)[0]
        setselectedef(selectedwf)
        setselectedwf(defid)
        toggle()
    }

    const saveStep = () =>{

    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
          <Sidebar/>
          <Modal isOpen={isModalOpen} toggle={toggle} className='mt-10-rem property_info_modal modal_35 mt-5 '>
            <ModalHeader toggle={toggle}>
                <div className='font-16 font-weight-600 color-707895'>Step Name</div>
            </ModalHeader>
            <ModalBody>
                <Row className='d-flex plr-2-rem'>
                    <FormFields.InputField
                        md={6}
                        name ='value'
                        label= 'Step Name'
                        val ={formik.values.value}
                        onChange = {formik.handleChange}
                        onBlur = {formik.handleBlur}
                        errors={formik.touched.value?formik.errors.value:''}
                    />
                </Row>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-start'>
                <CancelButton value='Cancel' clickEvent={toggle}/>
                <BlueButton value='Save' clickEvent={saveStep}/>
            </ModalFooter>
        </Modal>
          <div className='width-100'>
                <Navbar/>
                <div className='d-flex inner-page-header justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/Settings.svg' className='img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>SETTINGS</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper'>
                      
                            <Tabs className='d-flex justify-content-between width-100l'>
                                <Col md={3} className='card tabs-scroll br-6' style={{ width: '16%' }}>
                                    <TabList >
                                        {workflow.map((flow,k)=>{
                                            return(
                                                <Tab className="tab-width" style={{ padding: '15px 0 0 0' }} key={k}>
                                                    <span className='font-weight-600 font-16'>{flow.division.divisionName}</span>
                                                </Tab>
                                            )
                                        })}
                                    </TabList>
                                </Col>
                                <Col md={10}>
                                    <Card>
                                        {workflow.map((flow,k)=>{
                                            return(
                                                <TabPanel className='d-flex justify-content-center'> 
                                                    <center>
                                                        <Steps direction="vertical" current={0}>
                                                                    {flow.workflowDefinitionSteps.map((flowdef,k)=>{
                                                                        return(
                                                                            <Step title={
                                                                                flowdef.is_Active ?
                                                                                <span>
                                                                                    {flowdef.description}&nbsp;<img src='assets/images/close.svg' className='pointer' onClick={()=>{removeWorkFlow(flowdef.workflowDefinitionStepsID, flowdef.workflowDefinitionID)}}/>
                                                                                </span>
                                                                                :
                                                                                <span className='d-flex justify-content-center'>
                                                                                    <img src='assets/images/AddButtonIcon.svg' className='pointer' onClick={()=>{addworkflowstep(flowdef.workflowDefinitionStepsID, flowdef.workflowDefinitionID)}}/>
                                                                                </span>
                                                                                }
                                                                            />
                                                                        )
                                                                    })}
                                                        </Steps>
                                                    </center>
                                                </TabPanel>
                                            )
                                       })}
                                </Card>
                                </Col>
                            </Tabs>
                        
                    </div>
                </div>
          </div>
      </div>
    )
}
