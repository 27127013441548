import React from 'react'
import CustomerList from './CustomerList'

export default function List(props) {
    console.log(props)
    return (
      <table className="tCustom table-text mt-1-rem">
              <thead>
                  <tr className=' p-3'>
                      <th className='p-3'>Customer Code</th>
                      <th className='p-3'>Customer Name</th>
                      <th className='p-3'>Address</th>
                      <th className='p-3'>Category</th>
                      <th className='p-3'>Incoterms</th>
                      <th></th>
                    </tr>
              </thead>
              <tbody>
                  {props.data.map((list,k)=>(
                      <CustomerList data={list} sendBack={props.back} selectedCustomer = {props.selectedCustomer}/>
                  ))}
              </tbody>
      </table>
    )
}
