import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik'
import * as FormFields from "../../../../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../../../../components/buttoncomponents';
import * as CONFIG from  "../../../../../helpers/config"
import axios from "axios"

function EditModal(props) {
    console.log(props,'s')
    const [modal, setModal] = useState(true);
    const [tradeEnquiry, setTradeEnquiry] = useState(true)
    const [error, setError] = useState(true)
    const toggle = () => {
        setModal(!modal)
        props.close();
      }

      const formik = useFormik({
        initialValues:
        {
            productSellingPrice: props.data.productSellingPrice,
            productBuyingPrice: props.data.productBuyingPrice,
            quantity: props.data.quantity,
            productUOMName: props.data.productUOMName,
        },
        validate: values => {
            let errors = {};
            if (!values.productSellingPrice) {
                errors.productSellingPrice = "Please enter Product Selling price"
                //
            }
            if (!values.productBuyingPrice) {
                errors.productBuyingPrice = "Please enter Product Buying price"
                //
            }
            if (!values.quantity) {
                errors.quantity = "Please enter quantity"
                //setError(true)
            }
            setError(true)
            sendBack();
            return errors;
          
        }
    })
    const sendBack = () => {
        console.log('asas')
        if ((Object.keys(formik.errors).length == 0) ) {
            
            if((props.isBackClicked == true) 
            && (formik.initialValues == formik.values) 
            && (error == false)){
                console.log('condition one')
                setError(false)
                //props.handleValidationDirector()
            // console.log('error checking')
            // if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (error == false)){
            //     console.log(formik.errors)
            //     console.log('no error first condition')
            //     setError(false)
            //     props.handleError()    
            // }
            // else if(formik.initialValues == formik.values){
            //     console.log(formik.errors)
            //     console.log('error')
            //     props.setErrors()
            // }
            // else{
            //     setError(false)
            //     console.log(formik.errors)
            //     console.log('no error first condition')
            //     props.handleError()    
            // }
            }
            else if(formik.initialValues == formik.values){
                    setError(true)
                    console.log(formik.errors)
                    console.log('error')
                    props.setErrors()
            }//
            else{
                console.log('no log')
                setError(false)
                //props.handleValidationDirector()
            }
            
              
        }
        else{
            setError(true)
            console.log(formik.errors)
            console.log('error')
            //props.setErrors()
        }
    }
    const handleSaveEvent = () => {
        console.log(props)
        console.log(formik.errors)
        if(formik.errors !=null ){
            props.data.quantity = parseInt(formik.values.quantity)
            props.data.productSellingPrice = parseInt(formik.values.productSellingPrice)
            props.data.productBuyingPrice = parseInt(formik.values.productBuyingPrice)
            console.log(props.data)
            props.handleEditSupplyData(props.data)
            toggle()
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className = 'editProduct mt-5' >
        <ModalHeader  toggle={toggle}>
            <div className = 'd-flex justify-content-between'>
                <div className='font-16 font-weight-600 color-707895'>
                    EDIT SUPPLY PLAN
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Row>
                    <FormFields.InputField
                        md={6}
                        name='productBuyingPrice'
                        label='Buying Price*'
                        classname='pr-0 mb-3'
                        type='number'
                        val={formik.values.productBuyingPrice}
                        errors={formik.touched.productBuyingPrice ? formik.errors.productBuyingPrice : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputField
                        md={6}
                        name='productSellingPrice'
                        label='Selling Price*'
                        classname='pr-0 mb-3'
                        type='number'
                        val={formik.values.productSellingPrice}
                        errors={formik.touched.productSellingPrice ? formik.errors.productSellingPrice : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                     <FormFields.InputField
                        md={6}
                        name='quantity'
                        label='Quanitity*'
                        classname='pr-0 mb-3'
                        type='text'
                        val={formik.values.quantity}
                        errors={formik.touched.quantity ? formik.errors.quantity : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                      <FormFields.InputField
                        md={6}
                        name='productUOMName'
                        label='UOM'
                        classname='pr-0'
                        type='text'
                        val={formik.values.productUOMName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled = {true}
                    />
            </Row>
        </ModalBody>
            <ModalFooter className='justify-content-start'>
                <BlueBorderButton value='Cancel'  className='py-2 px-3' clickEvent={toggle} />
                <BlueButton value='Save' className='py-2 px-3' clickEvent={handleSaveEvent} disabled = {error}/>
            </ModalFooter>
        </Modal>
    )
}

export default EditModal