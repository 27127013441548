import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import Navbar from "../../../components/navbar"
import {Row, Col, Form, Card} from "react-bootstrap"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Moment from "moment"
import { useMsal } from "@azure/msal-react";

export default function CompleteLoading(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(false);
    const location = useLocation()
    let navigate = useNavigate();
    const tradeData = props.tradeData
    const tab = props.tab
    const [doc1, setdoc1] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const MySwal = withReactContent(Swal)

    const formik = useFormik({
        initialValues:{
            tradeShipmentLoadingCheckListID : 0,
            is_CoverCargoInsurance: false,
            is_SailingCustomer: false,
            is_ObtainFinalBL: false,
            is_FaxBLCustomer: false,
            is_ClearancePurposes: false,
            is_LayTimeShipper: false,
            layTimeShipperDocument: '',
            layTimeShipperDocumentName: '',
            is_CustomerWithInvoice: false,
            customerWithInvoiceDocument: '',
            customerWithInvoiceDocumentName: '',
            is_ArrangePaymentToSupplier: false,
            is_ObtainOriginalClearancePurposes: false,
            obtainOriginalClearancePurposesDocument: '',
            obtainOriginalClearancePurposesDocumentName: '',
            is_ETAofVesselOfCustomer: false,
            is_AttendedInvoice: false,
            attendedInvoiceDocument: '',
            attendedInvoiceDocumentName: '',
            is_ForwordDocumentRequired: false,
            forwordDocumentRequiredDocument: '',
            forwordDocumentRequiredDocumentName: '',
            is_IssueForReleaseOfCargo: false,
            issueForReleaseOfCargoDocument: '',
            issueForReleaseOfCargoDocumentName: '',
            is_ArrangmentsForReleaseOfDelivery: false,
            arrangmentsForReleaseOfDeliveryDocument: '',
            arrangmentsForReleaseOfDeliveryDocumentName: '',
            is_MonitorDischargeOfVessel: false,
            monitorDischargeOfVesselDocument: '',
            monitorDischargeOfVesselDocumentName: '',
            is_FollowUpOfPayment: false,
            is_PrepareCustomerLaytime: false,
            prepareCustomerLaytimeDocument: '',
            prepareCustomerLaytimeDocumentName: '',
            is_BillOfLading: false,
            billOfLadingNo: '',
            billOfLadingDate: null,
            billOfLadingDocument: '',
            billOfLadingDocumentName: '',
        }
    })
    useEffect(()=>{
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_SHIPMENT_LOADING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            if(res.data.result.length>0){
                Object.assign(formik.values, res.data.result[0])
                setdoc1(!doc1)
                setIsEdit(true)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })
    },[])
    const handleBLDate = (date)=>{
        formik.values.billOfLadingDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        setdoc1(!doc1)
    }
    const handleBLDateFinal = (date)=>{
        formik.values.obtainFinalBLDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        setdoc1(!doc1)
    }

    const laytimeshipperdoc = (file)=>{
        formik.values.layTimeShipperDocument = file.base64
        formik.values.layTimeShipperDocumentName = file.name
        setdoc1(!doc1)
    }
    const customerinvoicedoc = (file)=>{
        formik.values.customerWithInvoiceDocument = file.base64
        formik.values.customerWithInvoiceDocumentName = file.name
        setdoc1(!doc1)
    }
    const clearanceDoc = (file)=>{
        formik.values.obtainOriginalClearancePurposesDocument = file.base64
        formik.values.obtainOriginalClearancePurposesDocumentName = file.name
        setdoc1(!doc1)
    }

    const attendinvoicedoc = (file)=>{
        formik.values.attendedInvoiceDocument = file.base64
        formik.values.attendedInvoiceDocumentName = file.name
        setdoc1(!doc1)
    }
    const forwarddoc = (file)=>{
        formik.values.forwordDocumentRequiredDocument = file.base64
        formik.values.forwordDocumentRequiredDocumentName = file.name
        setdoc1(!doc1)
    }
    const releasecargodoc = (file)=>{
        formik.values.issueForReleaseOfCargoDocument = file.base64
        formik.values.issueForReleaseOfCargoDocumentName = file.name
        setdoc1(!doc1)
    }
    const releasedeliverydoc = (file)=>{
        formik.values.arrangmentsForReleaseOfDeliveryDocument = file.base64
        formik.values.arrangmentsForReleaseOfDeliveryDocumentName = file.name
        setdoc1(!doc1)
    }
    const monitordoc = (file)=>{
        formik.values.monitorDischargeOfVesselDocument = file.base64
        formik.values.monitorDischargeOfVesselDocumentName = file.name
        setdoc1(!doc1)
    }
    const customerlaydoc = (file) =>{
        formik.values.prepareCustomerLaytimeDocument = file.base64
        formik.values.prepareCustomerLaytimeDocumentName = file.name
        setdoc1(!doc1)
    }
    const billladingdoc = (file)=>{
        formik.values.billOfLadingDocument = file.base64
        formik.values.billOfLadingDocumentName = file.name
        setdoc1(!doc1)
    }

    const saveBillofLading = () =>{
        let json_list={
            "tradeID": tradeData.tradeID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        json_list = {...json_list,...formik.values}
        if(isEdit){
            json_list.tradeDischargePortCheckListID = formik.values.tradeDischargePortCheckListID
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_SHIPMENT_LOADING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Completion of lading information updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        cancelButtonText: 'Cancel'
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
        }else{
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_SHIPMENT_LOADING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Completion of lading information added successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        cancelButtonText: 'Cancel'
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
        }
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='w-100'>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>COMPLETION OF LOADING CHECKLIST</span>
                    </div>
                </div>
                <div className=' mx-4'>
                    <div className='add-edit-wrapper'>
                        <div className='d-flex justify-content-between'>
                            <Row className=' w-100'>
                                <Card style={{background:'#fff'}}>
                                    <Card.Body className='form-card-body mb-10-rem' style={{overflowY: 'scroll'}}>
                                        <Col md={12}>
                                            <Row className='border-bottom-1 pb-4 pt-1'>
                                                <Col md={7} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_CoverCargoInsurance'
                                                        label='FOB/CFR Sale - Cover Cargo Insurance.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_CoverCargoInsurance}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4'>
                                                <Col md={7} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_SailingCustomer'
                                                        label='Sailing Notification To Customer.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_SailingCustomer}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ObtainFinalBL'
                                                        label='Obtain Final B/L.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ObtainFinalBL}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_FaxBLCustomer'
                                                        label='FAX B/L To Customers & Request Them To Cover Insurance If Sale is C&F. If L/C Received From Customer, Advice To Be As Per L/C. '
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_FaxBLCustomer}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ClearancePurposes'
                                                        label='Documents required for clearance purposes.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ClearancePurposes}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_LayTimeShipper'
                                                        label='Prepare Laytime A/C Shippers If Required'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_LayTimeShipper}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.layTimeShipperDocumentName}
                                                        document={formik.values.layTimeShipperDocument}
                                                        onUploadHandle={laytimeshipperdoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center '>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_CustomerWithInvoice'
                                                        label='Obtain Certs of QLTY, QTY, ORIGIN & Other required documents by FAX & SEND to Customers with invoice.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_CustomerWithInvoice}
                                                    />
                                                </Col>
                                                <Col md={3} >
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.customerWithInvoiceDocumentName}
                                                        document={formik.values.customerWithInvoiceDocument}
                                                        onUploadHandle={customerinvoicedoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center '>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ArrangePaymentToSupplier'
                                                        label='Arrange payment to Suppliers'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ArrangePaymentToSupplier}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ObtainOriginalClearancePurposes'
                                                        label='Obtain original QUALITY, QTY, ORIGIN, ETC & Courier to customers with invoice if required for clearnce purposes.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ObtainOriginalClearancePurposes}
                                                    />
                                                </Col>
                                                <Col md={3} className=''>
                                                    <ButtonComponent.UploadButton  
                                                        value='Document'
                                                        filename={formik.values.obtainOriginalClearancePurposesDocumentName}
                                                        document={formik.values.obtainOriginalClearancePurposesDocument}
                                                        onUploadHandle={clearanceDoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 '>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ETAofVesselOfCustomer'
                                                        label='Inform customer of ETA of vessel'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ETAofVesselOfCustomer}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_AttendedInvoice'
                                                        label='Attested Invoice & Original documentation for clearance purposes well before arrival of vessal at discharge port. '
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_AttendedInvoice}
                                                    />
                                                </Col>
                                                <Col md={3} >
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.attendedInvoiceDocumentName}
                                                        document={formik.values.attendedInvoiceDocument}
                                                        onUploadHandle={attendinvoicedoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ForwordDocumentRequired'
                                                        label='Forward documents required for clearance.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ForwordDocumentRequired}
                                                    />
                                                </Col>
                                                <Col md={3} className=''>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.forwordDocumentRequiredDocumentName}
                                                        document={formik.values.forwordDocumentRequiredDocument}
                                                        onUploadHandle={forwarddoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_IssueForReleaseOfCargo'
                                                        label='Issue LOI for release of cargo'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_IssueForReleaseOfCargo}
                                                    />
                                                </Col>
                                                <Col md={3} >
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.issueForReleaseOfCargoDocumentName}
                                                        document={formik.values.issueForReleaseOfCargoDocument}
                                                        onUploadHandle={releasecargodoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_ArrangmentsForReleaseOfDelivery'
                                                        label='In case cargo will be discharged against LOI, ensure that shipping DIV makes necessary arrangements for release of delivery order by the discharge port agent and keep customer informed.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_ArrangmentsForReleaseOfDelivery}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.arrangmentsForReleaseOfDeliveryDocumentName}
                                                        document={formik.values.arrangmentsForReleaseOfDeliveryDocument}
                                                        onUploadHandle={releasedeliverydoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            {/* <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7} >
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_MonitorDischargeOfVessel'
                                                        label='Monitor discharge of Vessel.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_MonitorDischargeOfVessel}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ButtonComponent.UploadButton 
                                                        value={formik.values.monitorDischargeOfVesselDocumentName!=""?formik.values.monitorDischargeOfVesselDocumentName:'Document'}
                                                        onUploadHandle={monitordoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_FollowUpOfPayment'
                                                        label='Follow up on payment from Customer.'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_FollowUpOfPayment}
                                                    />
                                                </Col>
                                            </Row> */}
                                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_PrepareCustomerLaytime'
                                                        label="Prepare Customer's laytime statements if applicable."
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_PrepareCustomerLaytime}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.prepareCustomerLaytimeDocumentName}
                                                        document={formik.values.prepareCustomerLaytimeDocument}
                                                        onUploadHandle={customerlaydoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            {/* <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                                <Col md={4}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_BillOfLading'
                                                        label="Bill of Lading."
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.is_BillOfLading}
                                                    />
                                                </Col>
                                                <Col md={5}>
                                                    <Row>
                                                        <FormFields.DatePick
                                                            md={6}
                                                            label='B/L Date'
                                                            name='billOfLadingDate'
                                                            classname=' pt-1-rem iconDatePicker'
                                                            type='date'
                                                            handleCompletionDate={handleBLDate}
                                                            isRequired={false}
                                                            val={formik.values.billOfLadingDate}
                                                        />
                                                        <FormFields.InputField
                                                            md={6}
                                                            name='billOfLadingNo'
                                                            label='Bill of Lading No.'
                                                            type='text'
                                                            classname=' pt-1-rem'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            val={formik.values.billOfLadingNo}
                                                        />
                                                    </Row>
                                                </Col>
                                                <Col md={3} classname='pt-1-rem' >
                                                    <ButtonComponent.UploadButton 
                                                        value={formik.values.billOfLadingDocumentName!=""?formik.values.billOfLadingDocumentName:'Document'}
                                                        onUploadHandle={billladingdoc} 
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row> */}
                                        </Col>
                                    </Card.Body>
                                    <Card.Footer className='form-card-footer p-2-rem d-flex'>
                                        
                                        <span className='pl-10'><BlueButton value='Save' clickEvent={saveBillofLading}/></span>
                                    </Card.Footer>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
