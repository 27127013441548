import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Greeting from "./Greeting/index"
import Notifications from "../Common Components/Notifications"
import TotalCounter from "../Common Components/Total Counters/index"
import * as CONFIG from "../../../helpers/config"
function Index(props) {
    const { instance } = useMsal();
    const [customerCount, setCustomerCount] = useState()
    const [supplierCount, setSupplierCount] = useState()
    const [meetingCount, setMeetingCount] = useState()
    const [notification, setNotifications] = useState([])
    const [shipmentDetails, setShipmentDetails] = useState()

    useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CUSTOMER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setCustomerCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])
  
      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_SUPPLIER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setSupplierCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])
  
      useEffect(()=>{
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "userID" : CONFIG.LoginID
            
        }
      axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DB_MEETING_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setMeetingCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])

      useEffect(()=>{
        let final_json = {
          "ID": CONFIG.LoginID
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setNotifications(res.data.result)
      })
      },[])

      
      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
          "fromDate": props.fromDate,
          "toDate": props.toDate,
          "userID": CONFIG.LoginID,
          "dashboardTypeID": parseInt(3),
          "companyID" : parseInt(props.companyID)
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_SHIPMENT_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setShipmentDetails(res.data.result)
          //setNotifications(res.data.result)
      })
      },[props])
  
      useEffect(()=>{
        console.log(notification)
      },[notification, meetingCount, supplierCount, customerCount, shipmentDetails])
  return (
    <>
        <div className='inner-page-content'>
            <div className = 'row'>
                <div className = 'col-md-7 col-sm-12'>
                    <div className = 'row'>
                        <Greeting shipmentDetails = {shipmentDetails != null ? shipmentDetails : 0}/>
                    </div>
                </div>
                <div className = 'col-md-5 sm-12'>
                    <div className = 'row mt-md-0 mt-4'>
                        <div className = 'col-4'>
                            <TotalCounter title = {customerCount} text = {'CUSTOMERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                        </div>
                        <div className = 'col-4'>
                            <TotalCounter title = {supplierCount} text = {'SUPPLIERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                        </div>
                        <div className = 'col-4'>
                            <TotalCounter title = {meetingCount} text = {'MEETINGS'} img = '/assets/images/supports/dashboard-meeting-counter-icon.svg'/>
                        </div>
                    </div>
                    <div className = 'row mt-5'>
                        <div className = 'col-12'>
                            <Notifications  notification = {notification != null && notification.length > 0 ? notification : '' }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Index