import React from 'react'
import { Dropdown, Menu, Space } from 'antd';
import axios from 'axios';
import * as CONFIG from "../../../../helpers/config"
import { useMsal } from "@azure/msal-react";

function ListDepartments(props) {
    const { instance } = useMsal();
    console.log(props)
    const handleActive = (e) => {
        let data = props.values
        data.bankStatus = true
        console.log(data)
        props.handleActive(data)
    }

    const handleEdit = () => {
        props.handleEditDepartments(props.values)
    }

    const handleRemove = () => {
        let final_json = {
            "departmentID": props.values.departmentID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.REMOVE_DEPARTMENTS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result, 'roles')
            // props.handleDepartmentList(formik.values)
            //setWorkFlowList(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.deparmentsID} onClick={handleEdit}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.deparmentsID} onClick={handleRemove}>Remove</span>
                ),
            },
          ]}
        />
      );
  return (
    <tr className = ''>
        <td>
            {props.values.departmentName}
        </td>
        {props.fromView != null && props.fromView == true ?
        <td className = 'text-end'>
        </td>
        :
        <td className = 'text-end'>
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
            <Space>
                <img src = '/assets/images/Dot_icon.svg' />
            </Space>
            </a>
        </Dropdown>
        </td>
        }                                            
    </tr>
  )
}

export default ListDepartments