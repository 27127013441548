import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import * as FormComponent from "../../../components/formcomponents";
import * as ButtonComponent from "../../../components/buttoncomponents";
import ViewInvoice from './ViewInvoice';
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import GenerateInvoice from './GenerateInvoice';
import GenerateCustomerInvoice from "../../Trading/ContractFinalized/GenerateCustomerInvoice"


export function GetCustomer() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getCustomer()
    }, [])

    const getCustomer = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setCustomerList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.custid}>{list.customername}</option>
                    </>
                )
            })}
        </>
    )
}



export function GetProduct(props) {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getProduct()
    }, [props])

    const getProduct = () => {
        if (parseInt(props.customerid) != 0) {
            let json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(props.customerid)
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_BY_CUSTOMERID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setCustomerList(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.productID}>{list.productName}</option>
                    </>
                )
            })}
        </>
    )
}




export function GetTradeNo(props) {
    console.log(props)
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [tradeNoList, setTradeNoList] = useState([])
    useEffect(() => {
        getTradeNo()
    }, [props])

    const getTradeNo = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        let id = parseInt(props.customerid)
        console.log(id)
        if (id != 0) {
            console.log(props.customerid, 'customerid')
            json_list.customerID = parseInt(props.customerid)
        }
        if (parseInt(props.productid) != 0) {
            json_list.customerProductID = parseInt(props.productid)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let finallist = res.data.result.filter(x => x.is_InvGenerated != true)

                finallist = finallist.filter((ele, ind) => ind === finallist.findIndex(elem => elem.tradeID === ele.tradeID))
                setTradeNoList(finallist)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    return (
        <>
            {tradeNoList.map((list, k) => {
                return (
                    <>
                        <option value={list.tradeID}>{list.tradeNo}</option>
                    </>
                )
            })}
        </>
    )
}

export function GetCompany(props) {
    console.log(props)
    const { instance } = useMsal();
    const [companylist, setcompanylist] = useState([])

    useEffect(() => {
        const json_list = {
            "instanceID": 56522122,
            "accountSubscriptionID": 565221240,
            "created_User": 402,
            "id": props.id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_COMPANY_BY_TRADEID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setcompanylist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    return (
        <>
            {companylist.map((list, k) => {
                return (
                    <>
                        <option value={list.companyID}>{list.companyName}</option>
                    </>
                )
            })}
        </>
    )
}

export default function Index() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [paymentTermList, setPaymentTermList] = useState([])
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false)
    const [selectedDetails, setSelectedDetail] = useState([])
    const [paymentlist, setPaymentlist] = useState([])
    const [isFinal, setIsFinal] = useState(false)
    const [selectedfromlist, setselectedfromlist] = useState([])
    const [isgeneratedisabled, setisgeneraldisabled] = useState(true)
    const [customerid, setcustomerid] = useState(0)
    const [productid, setproductid] = useState(0)
    const [selectedtradeID, setselectedtradeID] = useState(0)
    const [doc, setdoc] = useState(false)
    const [custcomobject, setcustcomobject] = useState([])
    const [tradescenarioID, settradescenarioID] = useState(0)
    const [selectedcustomerid, setselectedcustomerid] = useState(0)
    const [selectedcompanyid, setselectedcompanyid] = useState(0)
    const [tradeData, setTradeData] = useState()
    const [tradeID, setTradeID] = useState()
    const [showView, setShowView] = useState(false)

    useEffect(() => {

    })

    const openInvoice = (e) => {
        console.log('inside')
        let id = parseInt(e.target.id)
        let data = paymentlist.filter(x => x.tradeCustomerPaymentDetailID == id)[0]
        setIsInvoiceOpen(!isInvoiceOpen)
        setselectedfromlist(data)
    }
    const toggleInvoice = () => {
        setIsInvoiceOpen(!isInvoiceOpen)
    }

    const paymentTermById = (e) => {
        let val = parseInt(e.currentTarget.value)
        console.log(e.currentTarget.value)
        setselectedtradeID(parseInt(val))

        let json_lists = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(val) // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_lists, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setcustcomobject(res.data.result)
                settradescenarioID(res.data.result[0].tradeScenarioID)
                if (res.data.result[0].tradeScenarioID == 1) {
                    setselectedcompanyid(res.data.result[0].companyID)
                    setPaymentTermList(res.data.result[0].tradeCustomerPaymentDetail)
                    setselectedcustomerid(res.data.result[0].customerID)
                    let count = 0
                    res.data.result[0].tradeCustomerPaymentDetail.map((pay,k)=>{
                        if(pay.is_InvGenerated == null){
                            count++
                        }
                    })
                    if(count == 1){
                        setIsFinal(true)
                    }
                    else{
                        setIsFinal(false)
                    }
                }
                viewProformaInvoices(parseInt(val))
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })


    }

    const getproductdetails = (e) => {
        let val = e.currentTarget.value
        console.log(e.currentTarget.value)
        setcustomerid(val)
        setdoc(!doc)
    }

    useEffect(() => {
        console.log(customerid)
    }, [customerid])
    const handlePaymentTerm = (e) => {
        let val = parseInt(e.target.value)
        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(selectedtradeID) //tradeid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT_DETAILS, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                let payment = res.data.result.filter(x => x.tradeCustomerPaymentDetailID == val)[0]
                setSelectedDetail(payment)
                setisgeneraldisabled(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const generateInvoice = () => {
        setIsGenerateInvoice(!isGenerateInvoice)
    }

    const getProductID = (e) => {
        let id = e.currentTarget.value
        setproductid(id)
    }

    const handleCompanySelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.companyID == id)[0]
        let payment = filter.tradeCustomerPaymentDetail
        let count = 0
        payment.map((pay, k) => {
            if (pay.is_InvGenerated == null) {
                count++;
            }
        })
        if (count == 1) {
            setIsFinal(true)
        }
        else {
            setIsFinal(false)
        }
        setPaymentTermList(payment)
        setselectedcustomerid(filter.customer.customerID)
        setselectedcompanyid(id)
    }

    const handleCustomerSelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.customerID == id)[0]
        console.log(filter)
        let payment = filter.tradeCustomerPaymentDetail
        let count = 0
        console.log(payment)
        setPaymentTermList(payment)
        payment.map((pay, k) => {
            if (pay.is_InvGenerated == null) {
                console.log('inside customer select')
                count++;
            }
        })
        if (count == 1) {
            setIsFinal(true)
        }
        else {
            setIsFinal(false)
        }
        setselectedcompanyid(filter.company.companyID)
        setselectedcustomerid(id)
    }

    const viewProformaInvoices = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let result = res.data.result
                console.log(result)
                console.log(id)
                let filter = result.filter(x => x.tradeId == id)
                console.log(filter)
                setPaymentlist(filter)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handlePurchaseOrder = (e) => {
        //console.log(e.target.id)
        //console.log(customerInvoiceData)
        //const json_list = {
        //    "instanceID": CONFIG.PMSInstanceID,
        //    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //    "created_User": CONFIG.LoginID,
        //    "id":parseInt(e.target.id)
        //}
        //axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAIL_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //.then((res) => {
        //   console.log(res.data.result)
        //   setTradeData(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
        //   console.log(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
        //   //console.log(res.data.result.filter(x=> x.tradeID == customerInvoiceData.tradeId)[0])
        //   console.log(customerInvoiceData)
        //   //navigate('/customer-invoice', { state:{tradeData: res.data.result.filter(x=> x.tradeID == e.target.id)[0], tab: 1, 
        //   setTradeID(customerInvoiceData.tradeId)
        //   setShowView(true)
        //}).catch((error)=>{
        //    if(error.response.status == 401){
        //        localStorage.clear();
        //        instance.logoutRedirect({
        //          postLogoutRedirectUri: "/",
        //        });
        //    }
        //})  
        //navigate('/customer-proforma-invoice', { state:{tradeData: props.data, tab: 1, tradeID: customerInvoiceData.tradeID}});;

        setShowView(true)
        setTradeID(e.target.id)
    }

    const showviewToggle = () => {
        setShowView(!showView)
    }

    
    const handleClose = () => {
        setShowView(false)
    }
    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='6.1' isOpenid={6} />

                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 text-uppercase d-flex align-items-end pl-10'>Generate Customer Invoice</span>
                        </div>
                    </div>
                    <div className='add-edit-wrapper faq'>
                        {!isGenerateInvoice ?
                            <>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4}>
                                                <FormComponent.InputSelect
                                                    label='Select Customer'
                                                    optionField={<GetCustomer />}
                                                    onChange={getproductdetails}
                                                    onBlur={getproductdetails}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <FormComponent.InputSelect
                                                    label='Select Product'
                                                    optionField={<GetProduct customerid={customerid} />}
                                                    onChange={getProductID}
                                                    onBlur={getProductID}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <FormComponent.InputSelect
                                                    label='Select Trade'
                                                    optionField={<GetTradeNo customerid={customerid} productid={productid} />}
                                                    onChange={paymentTermById}
                                                    onBlur={paymentTermById}
                                                />
                                            </Col>
                                            {tradescenarioID == 2 &&
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label='Select Company'>
                                                        <Form.Select
                                                            className="form_input form_input_color_change mt-1-rem"
                                                            placeholder='Select Company'
                                                            onChange={handleCompanySelect}
                                                            onBlur={handleCompanySelect}
                                                            name='companyselect'>
                                                            <option value={0}>Select</option>
                                                            {custcomobject.map((list, k) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.companyID}>{list.company.companyName}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            {tradescenarioID == 3 &&
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label='Select Customer'>
                                                        <Form.Select
                                                            className="form_input form_input_color_change mt-1-rem"
                                                            placeholder='Select Customer'
                                                            onChange={handleCustomerSelect}
                                                            onBlur={handleCustomerSelect}
                                                            name='companyselect'>
                                                            <option value={0}>Select</option>
                                                            {custcomobject.map((list, k) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.customerID}>{list.customer.customerName}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            <Col md={4}>
                                                <FloatingLabel controlId="floatingSelect" label='Select Payment Term'>
                                                    <Form.Select
                                                        className="form_input form_input_color_change mt-1-rem"
                                                        placeholder='Select Payment Term'
                                                        onChange={handlePaymentTerm}
                                                        onBlur={handlePaymentTerm}
                                                        name='paymentTerm'>
                                                        <option value={0}>Select</option>
                                                        {paymentTermList.map((list, k) => {
                                                            if (!list.is_InvGenerated) {
                                                                return (
                                                                    <>
                                                                        <option value={list.tradeCustomerPaymentDetailID}>{list.paymentType.typeName}</option>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className='mt-1-rem'>
                                                <ButtonComponent.BlueButton value='Generate Invoice' clickEvent={generateInvoice} disabled={isgeneratedisabled} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className='mt-1-rem'>
                                    <table className="table tCustom table-text">
                                        <thead >
                                            <tr className='list-top'>
                                                <th className=''>S.NO</th>
                                                <th className=''>TRADE NO</th>
                                                <th className=''>INVOICE NO</th>
                                                <th className=''>INVOICE DATE</th>
                                                <th></th>
                                            </tr>
                                            {showView == true &&
                                                <GenerateCustomerInvoice
                                                    data={tradeData}
                                                    tradeID={tradeID}
                                                    tab={1}
                                                    invoiceList={true}
                                                    handleClose={handleClose}
                                                    isOpen={showView}
                                                    toggle={showviewToggle}
                                                />
                                            }
                                        </thead>
                                        <tbody>

                                            {paymentlist.map((list, k) => {
                                                return (
                                                    <tr>
                                                        <td>{k + 1}</td>
                                                        <td>{list.tradeNo}</td>
                                                        <td>{list.customerInvoiceNo}</td>
                                                        <td>{list.customerInvoiceDate}</td>
                                                        <td>
                                                            <Button size="md" id={list.customerPaymentDetailID} onClick={(e) => { handlePurchaseOrder(e) }} >
                                                                View
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Card>
                            </>
                            :
                            <>
                                <Col md={9}>
                                    <GenerateInvoice data={selectedDetails} isFinal={isFinal} toggle={generateInvoice} customerID={selectedcustomerid} companyID={selectedcompanyid} />
                                </Col>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
