import React from 'react'
import { Dropdown, Menu, Space } from 'antd';

export default function Listing_two(props) {
  const menu = (
    <Menu
      items={[
        {
            key: '1',
            label: (
                <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.id} onClick={props.edit}>Edit</span>
            ),
        },
        {
          key: '2',
          label: (
              <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.id} onClick={props.delete}>Remove</span>
          ),
      }
      ]}
    />
  );
  return (
    <>
      <tr className='product_list_row  align-items-center border-bottom-1 align-middle' id={props.id}>
          <td><div className = 'align-items-center d-flex cusname py-4'>{props.name}</div></td>
          <td><div className = 'align-items-center d-flex py-4'>{props.unit}</div></td>
          <td>
              <Dropdown overlay={menu}>
                  <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <img src = '/assets/images/Dot_icon.svg' />
                  </Space>
                  </a>
              </Dropdown>
          </td>
      </tr>
    </>
  )
}
