import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton, BlueButton } from '../../components/buttoncomponents'
import axios from 'axios'
import * as CONFIG from ".././../helpers/config"
import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import * as FormFields from "../../components/formcomponents"
import { useMsal } from "@azure/msal-react";
import Add_ins from './add_ins'
import InstitutionList from './institutionList'

export default function Index() {
    const { instance } = useMsal();
    const [isAdd, setIsAdd] = useState(false)
    const [financialList, setFinancialList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [instituteData, setInstituteData] = useState([])
    const [filterlist, setfilterlist] = useState([])

    const openAdd = () => {
        setIsAdd(!isAdd)
    }

    useEffect(()=>{
        getList()
    },[])
    
    const getList = ()=>{
        const json_list ={
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_FINANCIAL_INSTITUTE_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setFinancialList(res.data.result)
            setfilterlist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    
    const openInstitution = (data)=>{
        console.log(data)
        setInstituteData(data)
        setIsEdit(true)
        openAdd()
    }

    const handleSearch = (e)=>{
        let val = e.target.value
        let filter = financialList.filter(x=>  x.institute.bank.bankName.toLowerCase().includes(val.toLowerCase()) ||
            x.institute.branchName.toLowerCase().includes(val.toLowerCase()) ||
            x.institute.country.countryName.toLowerCase().includes(val.toLowerCase()) 
        )
        setfilterlist(filter)
    }

    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='9.1' isOpenid={9} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between mx-2'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/financial.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>FINANCIAL INSTITUTES</span>
                    </div>
                    <div className=''>
                        <div className='me-3'>
                            <Col md={12}>
                                {isAdd?
                                    <BlueButton value='Back' clickEvent={()=>{
                                        getList()
                                        openAdd()
                                    }}/>
                                :
                                    <AddNewButton value='Add New Institute' class='bg-white' clickEvent={()=>{
                                        setInstituteData(null)
                                        setIsEdit(false)
                                        openAdd()
                                    }} />
                                }
                            </Col>
                        </div>
                    </div>
                </div>
                {!isAdd ?
                    <div>
                        <Row className='mx-2'>
                            <Col md={10}>
                            </Col>
                            <Col md={2}>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='Search'
                                            className="form_input form_input_color_change"
                                            name='search'
                                            onChange={handleSearch}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Col>
                        </Row>
                        <div className=''>
                            <div className='inner-page-content  br-8 py-3'>
                                <div className='card card-body table-responsive-sm '>
                                    <Card className='col-lg-12  list-scroll'>
                                        <table className="table tCustom table-text">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895 list-top'>
                                                    <th>Bank Name</th>
                                                    <th>Institution Name</th>
                                                    <th>Branch</th>
                                                    <th>Country</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterlist.map((list,k)=>{
                                                    return(
                                                        <InstitutionList data={list} key={k} openInstitution={openInstitution}/>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className='inner-page-content  br-8 py-3'>
                            <Add_ins back={()=>{
                                openAdd()
                                getList()
                            }} isEdit={isEdit} instituteData={instituteData} isAdd={isAdd}/>
                        </div>
                    </>
                }
            </div>


        </div>
    )
}
