import React from 'react'
import { Dropdown, Menu, Space } from 'antd';

export default function Trace_list(props) {
    console.log(props,'bug check')
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.editList}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.remove}>Remove</span>
                ),
            }
          ]}
        />
      );
    return (
        <>
            <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
              
                <td className='color-585966 px-4'>
                {props.values.productTraceElementName != null ? 
                props.values.productTraceElementName : 
                props.values.productTraceElement != null ?
                props.values.productTraceElement.productTraceElementName :
                ''}
            </td>
                <td>{props.values.typicalValueRange}</td>
                <td>{props.values.rejectionValueRange}</td> 
                <td>
                    <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                        <img src = '/assets/images/Dot_icon.svg' />
                        </Space>
                        </a>
                    </Dropdown>
                </td>
            </tr>
        </>
    )
}
