import React from 'react'
import TradeListData from './TradeListData'
import FinalizedList from './finalizedList'
import ShipmentList from "./shipmentList"
import Closedtrade from './closedtrade'
function List(props) {
    console.log(props)
    return (
        <div class="col-lg-12 list-scroll ">
            <div class="table-responsive-sm ">
                <table className="table table-striped tCustom table-text">
                    <thead className='p-1-rem'>
                        {/* sticky-top */}
                        <tr className='text-uppercase list-top'>
                            <th><p className='w-200 mb-0'>TRADE#</p></th>
                            <th><p className='w-200 mb-0'>COMPANY & DIVISION</p> </th>
                            <th><p className='w-200 mb-0'>CUSTOMER & SUPPLIER</p></th>
                            <th className='px-3'><p className='w-200 mb-0'>PRODUCT & CONTRACT DETIALS</p></th>
                            <th>STATUS</th>
                            <th></th>
                            <th><p className='w-200 mb-0'></p></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.status == 1 &&
                            props.tradeData.map((list, k) => (
                                <TradeListData key={k} data={list} handleEdit={props.handleEdit} disable={props.disable} addcostsheet={props.addcostsheet} viewcostsheet={props.viewcostsheet} refreshList={props.refreshList} status={props.status} />
                            ))
                        }
                        {props.status == 2 &&
                            props.tradeData.map((list, k) => (
                                <FinalizedList key={k} data={list} handleEdit={props.handleEdit} disable={props.disable} addcostsheet={props.addcostsheet} viewcostsheet={props.viewcostsheet} refreshList={props.refreshList} status={4} />
                            ))
                        }

                        {props.status == 4 &&
                            props.tradeData.map((list, k) => (
                                <ShipmentList key={k} data={list} handleEdit={props.handleEdit} disable={props.disable} addcostsheet={props.addcostsheet} viewcostsheet={props.viewcostsheet} refreshList={props.refreshList} status={5} />
                            ))
                        }

                        {props.status == 5 &&
                            props.tradeData.map((list, k) => (
                                <Closedtrade key={k} data={list} handleEdit={props.handleEdit} disable={props.disable} addcostsheet={props.addcostsheet} viewcostsheet={props.viewcostsheet} refreshList={props.refreshList} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default List