import React, { useEffect, useState } from 'react'
import {Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import Generalform from './generalinformation/generalform';

export default function Generalinformation(props) {
    const [isNextClicked, setIsNextClicked] = useState(false)
    const [isdisabled, setIsDisabled] = useState(true)
    const [handleError, setHandleError] = useState(true)
    useEffect(()=>{
        console.log(isNextClicked)
        console.log(props, 'inside general info')
    },[isNextClicked])
    useEffect(()=> {
        if((props.isBackClicked == true) || (props.isEdit == true)){
            setHandleError(false)
        }
    },[])
    const moveToNext =()=>{
        setIsNextClicked(true)
        console.log('next button')
    }

    const saveGeneral = (data)=>{
        setIsNextClicked(false)
        console.log(data)
        props.onNext(data,1)
    }

    const requiredFilled = ()=>{
        setIsDisabled(false)
    }

    const requiredFailed = ()=>{
        setIsDisabled(true)
    }

    const handleErrorValidation = () => {
        console.log('no error main component')
        setHandleError(false)
    }
    const handleSettingError = () => {
        setHandleError(true)
    }

    const handleChange = () => {

    }
    return (
        <div>
            <Card style={{background:'#fff'}}>
                <Card.Body className='form-card-body mb-10-rem' style={{overflowY: 'scroll'}}>
                    <Generalform general = {props.general} isBackClicked={props.isBackClicked} setErrors = {handleSettingError} handleError = {handleErrorValidation} saveGeneral ={saveGeneral} isNextClicked={isNextClicked} requiredFilled={requiredFilled} requiredFailed={requiredFailed} isEdit={props.isEdit} dataForEdit = {props} />
                </Card.Body>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span><BlueBorderButton value='Cancel' clickEvent={props.handleCancel}/></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext} class='sample'  disabled = {handleError == true ? true : false} /></span>
                </Card.Footer>
                
            </Card>
        </div>
    )
}
