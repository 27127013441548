import React,{useState, useEffect} from 'react'
import axios from 'axios';
//import configfile from "../../../../config.json";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useFormik } from 'formik'
import { Row, Col, Button, Dropdown, Card, Form, FloatingLabel } from "react-bootstrap"
import List from './List';
import * as CONFIG from "../../../../helpers/config"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function MultiCompanyModal(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [companyList, setCompanyList] = useState([])
    const [company, setCompany] = useState()
    const [selectedCompany, setSelectedCompany] = useState()
    const [isFirst, setIsFirst] = useState(true);
    const [checkerror, setcheckerror] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    
    const MySwal = withReactContent(Swal)

    useEffect(()=>{
        if(props.updatedCompany != null && props.updatedCompany.length > 0){
            let list = []
            for(let i = 0; i < props.updatedCompany.length; i++ ){
                list.push(props.updatedCompany[i].company.companyID)
            }
            console.log(list, 'list')
            setcheckedList(list)
        }
    },[])

    const toggle = () => {
        setModal(!modal)
        props.close();
    }
   
    const moveToNext = ()=>{
        if(props.split == true){
            let list = []
            if(checkedList.length && props.data.length > 0){
                for (let i = 0; i < props.data.length; i++){
                    for(let x = 0; x < checkedList.length; x++){
                        if(parseInt(checkedList[x]) == props.data[i].company.companyID){
                            list.push(props.data[i])
                        }
                    }
                props.handleSelectedCompany(list)
                props.close() 
                }
            }
        }
       
        else{
            let final_json = {
                "PMSInstanceID": CONFIG.PMSInstanceID,
                "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "companyID": parseInt(company),
            }
            axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0], 'Company')
                props.handleSelectedCompany(res.data.result[0])
                props.close();
                // setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
        }

        // if (selectedCompany != null){
        //     console.log(selectedCompany)
        //     //console.log(selectedCompany)
        //     const json_list = {
        //         "instanceID" : CONFIG.PMSInstanceID,
        //         "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
        //         "created_User" : CONFIG.LoginID,
        //         "id": selectedCompany,
        //     }
        //     axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //     .then((res) => {
        //         console.log(res.data.result)
        //         props.handleError()
        //         props.handleCompanyData(res.data.result)
        //         props.cancel()
        //     }).catch((error)=>{
        //         if(error.response.status == 401){
        //             localStorage.clear();
        //             instance.logoutRedirect({
        //               postLogoutRedirectUri: "/",
        //             });
        //         }
        //     }) 
           
        // }
        // else{
        //     MySwal.fire(
        //         {
        //         icon: 'error',
        //         title: 'Please select a Company',
        //         //type: 'error',
        //         confirmButtonText: 'Back',
        //         backdrop: `
        //         rgba(60, 60, 60, 0.5)
        //         no-repeat
        //       `
        //       })
        // }
        
    }
    const back = (data)=>{
        setSelectedCompany(data)
    }

    const trackCheck = (e) => {
        console.log(props)
        let id = parseInt(e.target.id);
        console.log(id)
        if(props.split == true){
            console.log('check condition')
            if(e.target.checked){
                console.log('if')
                addtolist(id)
            }  
            else{
                console.log('else')
                removefromlist(id)
            }
        }
        else{
            let id = parseInt(e.target.id);
            console.log(id)
            setCompany(id)
        }
        
  
        //props.selectedSupplier(props.data)

    }

    useEffect(()=>{

    },[company])


    const errorPresent = (data) => {
        setcheckerror(data)
    }

    const addtolist = (id)=>{

        console.log(checkedList)
        let list = [...checkedList]
        list.push(id)
        console.log(id, 'id')
        if(list.length < 3){
           
            console.log('error set')
            console.log(list)
            console.log(props.data)
            setcheckedList(list)
            errorPresent(false)
            addtoSelection(list)
        }
        else{
            errorPresent(true)
        }
        if(list.length >= 2){
            setCompany('')
        }
       
        console.log(list)
    }

    const removefromlist = (id)=>{
        console.log('remove list')
        let list = [...checkedList]
        let newlist = list.filter(x=> x != id)
        setcheckedList(newlist)
        if(newlist.length <= 1){
            setCompany(null)
        }
        errorPresent(false)
        addtoSelection(newlist)
    }

    const addtoSelection = (list) => {
        console.log('addToSelection list', list)
        //setselectedCustomer(list)
    }
    return (
      <div className='trade_existing_customer"'>
          <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
                      <ModalHeader className='px-5 py-4' toggle={toggle}>
                        <div className = 'font-16 text-uppercase font-weight-600 color-707895'>
                            Select Company
                        </div>
                      </ModalHeader>
                      <ModalBody  className = 'px-5 mb-10-rem' style={{overflowY:'scroll'}}>
                           
                            <>
                            <div className=''>
                                <div className='mx-4 br-8'>
                                    {checkerror &&
                                        <span className='input_error'>Error: More than two company's cannot be selected</span>
                                    }
                                </div>
                            </div>
                            <div className=' customerList'>
                                <table className="table table-text mt-1-rem">
                                    <thead>
                                        <tr className='bg-light font-weight-500 p-3'>
                                            <th className='p-3'>Company Name</th>
                                            <th className='p-3'>Address</th>
                                            <th className='p-3'>Telephone</th>
                                            <th className='p-3'>Email</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data != null && props.data.length > 0 ? 
                                            props.data.map((list,k)=>(
                                                <tr className='py-3 br-bottom-1 '>
                                                    <td className=''>{list.company.companyName}</td>
                                                    <td className=''>
                                                        {list.company.companyBuilding}, &nbsp;
                                                        {list.company.companyUnit}, &nbsp;
                                                        {list.company.companyStreet}, &nbsp;
                                                        {list.company.companyPoBox}, &nbsp;
                                                        {list.countryname}, &nbsp;
                                                        {list.statename}
                                                    </td>
                                                    <td>{list.company.companyTelephone}</td>
                                                    <td>{list.company.contactEmail}</td>
                                                    <td>
                                                        {props.split != true ?
                                                        <Form.Check 
                                                            type='radio'
                                                            name='supplierData'
                                                            id={list.company.companyID}
                                                            defaultChecked = {props.updatedCompany != null  && props.updatedCompany.length > 0 ?
                                                                props.updatedCompany[0].company.companyID == list.company.companyID  ?
                                                                true :
                                                                
                                                                false :
                                                                false
                                                            }
                                                            onChange = {trackCheck}
                                                            className='mx-3 slectSupplier'
                                                        />
                                                        :
                                                        <Form.Check 
                                                            type='checkbox'
                                                            name='customerData'
                                                            id={list.company.companyID}
                                                            checked={checkedList.filter(x => x == list.company.companyID).length>0}
                                                            onChange = {trackCheck}
                                                            className='mx-3 slectCustomer'
                                                        />
                                                    }
                                                    </td>
                                                </tr>
                                                
                                            ))
                                            :
                                            <h4 className = 'font-weight-500 font-14 mx-3 mt-3'>No company created</h4>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            </>
                      </ModalBody>
                    <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                       <BlueBorderButton value='Cancel' clickEvent={toggle} />
                        <span className='pl-10'><BlueButton value='Select' clickEvent={moveToNext} disabled = {company != null ? false : true} /></span>
                    </ModalFooter>
          </Modal>
      </div>
    )
}

export default MultiCompanyModal