import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import FormComponent from './components/FormComponent';
import List from './components/List';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function AddProductDetails(props) {
    console.log(props, 'addproduct details')
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirst, setIsFirst] = useState(true)
    const [isProduct, setIsProduct] = useState(false)
    const [productList, setProductList] = useState([]);
    const [final_product_list, setfinal] = useState([])
    const [key, setkey ] = useState(1)
    const [selectedProduct, setSelected] = useState([])
    
    const openAddForm=()=>{
        setIsAdd(!isAdd)
    }
    
    useEffect(()=>{
        if(isFirst){
            if(props.isproductavailable){
                setProductList(props.productData)
                setIsProduct(props.isproductavailable)
                let legth = props.productData.length
                setkey(legth+1)
                openAddForm()
            }
        }
        setIsFirst(false)
    },[isFirst])

    const saveProduct = (data)=>{
        console.log(data)
        let list = [...productList]
        data.key = key
        list.push(data)
        setProductList(list)
        console.log(productList)
        setIsProduct(true)
        openAddForm()
        let k = key
        k++
        setkey(k)
        props.addTraceAndQuality(list)
    }
    const remove = (e) =>{
        let id = parseInt(e.target.id)
        console.log(id)
        let list = [...productList]
        productList.map((li,k)=>{
            if(li.key == id){
                list[k].key = false
                list[k].Is_Active = false
                list[k].Is_Deleted =  true
            }
        })
        setProductList(list)
        props.addTraceAndQuality(list)
    }

    const edit = (e)=>{
        let id = parseInt(e.target.id)
        let edit_data = productList.filter(x=> x.key == id)
        setSelected(edit_data);
        setIsEdit(true)
    }

    const addTraceAndQuality = (data)=>{
        console.log(data, 'addProduct details')
        let pr_list = [...productList]

        pr_list.map((plist,k)=>{
            data.map((dt,l)=>{
                if(plist.productID == dt.productID){
                   pr_list[k]= dt
                   pr_list[k].productName = productList[k].productName
                   pr_list[k].productCode = productList[k].productCode
                }
            })
        })
        console.log(pr_list)
        setProductList(pr_list)
        props.addTraceAndQuality(pr_list)
    }
    return (
        <Row className='d-flex bg-white p-4'>
            {props.productData != null && props.productData.length > 0 ? 
            ''
            :
            productList != null && productList.length > 0 
            ? 
            ''
            :
            <>
             <div className = 'd-flex justify-content-between align-items-center'>
                <h4 className='font-16 font-weight-600 color-707895 pb-3'>
                    Add Product Details
                </h4>
            </div>
            <FormComponent selectedDivision = {props.selectedDivision}  saveProduct={saveProduct} isEdit={isEdit} selectedProduct={selectedProduct}/>
            </>
            }
            
           
            {isProduct == true ?
            <>
             <div className = 'd-flex justify-content-between align-items-center mt-2-rem'>
                <h4 className='font-16 font-weight-600 color-707895 pb-3'>Product Details</h4>
             </div>
            <List 
                data= {productList} 
                isProduct= {isProduct} 
                openAddForm= {openAddForm} 
                remove= {remove} 
                addTraceAndQuality={addTraceAndQuality} 
                saveList = {props.saveList}
                isEdit={props.isEdit}
                edit = {edit}
            /> 
            </>
            :
            ''
            }
           
        </Row>
    )
}

export default AddProductDetails