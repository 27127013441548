import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { Col, Row, Accordion, Form, Button } from 'react-bootstrap';

export default function Index() {

    return (
        <div className='main-wrapper inner-wrapper d-flex'>

            <Sidebar id={9} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/pageicons/faq.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 text-uppercase d-flex align-items-end pl-10'>frequently asked questions</span>
                    </div>
                </div>


                <div className='add-edit-wrapper faq'>
                    <div className='row'>
                        {/* <div className='col-3  bg-white  p-40'>
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a class="nav-link active" href="#">Active</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled" href="#">Disabled</a>
                        </li>
                    </ul>
                    </div> */}
                        <div className='col-12 bg-white p-md-4 p-0'>
                            <Accordion className='meeting_accordin mt-2-rem'>
                                <Accordion.Item eventKey={1}>
                                    <Accordion.Header className='faQ'>
                                        How to access the TMS Portal?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <strong>To access the TMS portal, please follow the below steps:</strong><br /><br />
                                        1. Open an internet browser<br /><br />
                                        2. Open an internet browser <strong><a href='https://tmssaas.cloud/' target='_blank'>tmssaas.cloud</a></strong><br /><br />
                                        3. Click Enter.<br /><br />
                                        4. The TMS portal will be shown with the login page as below.

                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-01.png' className='img-fluid' />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={2}>
                                    <Accordion.Header>
                                        How to login to the TMS Portal?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            Your Office 365 credentials (username and password) are used to
                                            access the TMS portal. Click login and use your Office 365 credentials
                                            to login to the portal.<br />
                                        </p>

                                        <strong>Pre-requisites:</strong><br />
                                        1. You are on the login page of the TMS Portal.<br /><br />

                                        <strong>Please follow the below steps to log in to the portal.</strong><br />
                                        1. Click on Login<br />
                                        2. Login using your Rescom Office 365 credentials<br />
                                        3. After successfully login, the portal will take you to the TMS home
                                        page as shown below.<br />

                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-02.png' className='img-fluid' />
                                        </div>
                                        4. You can use the top menu bar or TMS menu bar placed on the left
                                        to access various features. <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={3}>
                                    <Accordion.Header>
                                        What is Organization Settings?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            Organization settings is where the details about the company, who
                                            uses the TMS platform, are provided. <br />
                                            Please follow the below steps to access the organization settings.
                                        </p>

                                        <strong>Pre-requisites:</strong><br />
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege to access Organization Settings.<br /><br />


                                        <strong>Please follow the below steps:</strong><br />
                                        1. Click on the User to select the user menu as shown below:<br />
                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-03.png' className='img-fluid' />
                                        </div><br />
                                        2. Click Organization Setup<br />
                                        3. Organization Setup page is loaded as below.<br />
                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-04.png' className='img-fluid' />
                                        </div><br />
                                        4. The organization setup page shows the below details:<br />

                                        <div className='px-4'>
                                            a. Company name, address, telephone, and email.<br />
                                            b. Trade name, trade license#, trade license issued date, trade
                                            license expiry date, trade license image<br />
                                            c. Tax reference# and tax document image. <br />
                                            d. Company bank details<br />
                                            e. User roles<br />
                                            f. Departments<br />

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={4}>
                                    <Accordion.Header>
                                        How can I view organization bank account?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            You can access organization bank accounts from the organization
                                            settings page. To view bank accounts, follow the below steps:
                                        </p>
                                        1. Go to Organization Settings.<br />
                                        2. Click on the banks tab.<br />
                                        3. The bank accounts will be listed in the banks tab.

                                        <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={5}>
                                    <Accordion.Header>
                                        How can I add a new bank account?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            You can add a new organization bank account from the organization
                                            settings page. To add a new bank account, follow the below steps:<br />
                                        </p>


                                        1. Go to Organization Settings.<br />
                                        2. Click on the “Edit Organization Details” button.
                                        3. To add more bank accounts, click on the “Add New Bank
                                        Account(s)” button. This button is available when the
                                        Organization Settings page is in Edit mode.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={6}>
                                    <Accordion.Header>
                                        What are user roles?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            A user role simplifies the administration and management of privileges
                                            by allowing the administrator to group privileges and permissions into a
                                            single role and then grant this role to the users who need those
                                            privileges and permissions to perform their tasks.
                                            <br /><br />
                                            TMS comes with default user roles and permissions. The client can add
                                            their own custom roles and can configure privileges and permissions to
                                            those roles.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={7}>
                                    <Accordion.Header>
                                        How can I view user roles and permissions?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            You need to access organization settings to view roles and permissions.
                                            Only users with administrator privilege can access organization
                                            settings.
                                        </p>

                                        <strong>Please follow the below steps to view roles and permissions.</strong><br /><br />

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege to access Organization Settings.<br /><br />


                                        <p className='font-weight-600'>Please follow the below steps:</p>

                                        5. Go to Organization Settings page<br />
                                        6. Click on Roles tab<br />
                                        7. Roles tab lists the available user roles.<br />
                                        8. Below screen shows how the roles are listed.<br /><br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-05.png' className='img-fluid' /></div><br /><br />

                                        9. To access the permission settings, click on permissions button. <br />
                                        10. Below screen shows the role permissions associated with a role.

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-06.png' className='img-fluid' /></div>

                                        11. Click save button to save the changes made to permissions.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={8}>
                                    <Accordion.Header>
                                        How can I add a new user role?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            You need to access organization settings to add a new role. Only users
                                            with administrator privilege can access organization settings.

                                        </p>

                                        <strong>Please follow the below steps to add a new user.</strong><br /><br />

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege to access Organization Settings.<br /><br />


                                        <p className='font-weight-600'>Please follow the below steps to add a new role:</p>

                                        1. Go to Organization Settings page<br />
                                        2. Click on Roles tab<br />
                                        3. Roles tab lists the available user roles.<br />
                                        4. Below screen shows how the roles are listed.<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-07.png' className='img-fluid' /></div><br /><br />
                                        5. Click on “Add New Role(s)” button. <br />
                                        6. Provide the role name, description, and set the permissions as
                                        shown below:<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-06.png' className='img-fluid' /></div><br />

                                        7. By default, all permissions are set as “No Rights”<br />
                                        8. Change permission levels for the features and click Save to
                                        create the role.<br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={9}>
                                    <Accordion.Header>
                                        What are departments?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            Departments are the functional areas of the organization such as
                                            Finance, HR, Sales, Procurement, etc. When the new users are
                                            created, they are created under a department.

                                        </p>
                                        <h4 className='font-wight-600 py-3'>How can I view departments?</h4>
                                        You need to access organization settings to view departments of the
                                        organization. Only users with administrator privilege can access
                                        organization settings.

                                        <p className='font-weight-600'>Please follow the below steps to view departments.</p><br /><br />
                                        <p className='font-weight-600'>Pre-requisites:</p><br />
                                        5. You have logged into the portal<br />
                                        6. You have Administrator privilege to access Organization Settings.<br /><br />

                                        <p className='font-weight-600'>Please follow the below steps to add a new role:</p><br />
                                        9. Go to Organization Settings page<br />
                                        10. Click on Departments tab<br />
                                        11.Departments tab lists the departments as shown below:

                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-08.png' className='img-fluid' />
                                        </div><br /><br />

                                        <h4 className='font-wight-600'>How can I add a department?</h4><br />
                                        <p>
                                            You need to access organization settings to add a department. Only
                                            users with administrator privilege can access organization settings.
                                        </p>
                                        <p className='font-weight-600'>Please follow the below steps to add a department.</p>


                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege to access Organization Settings.<br />
                                        3. Click Edit Organization Settings.<br />
                                        4. Click on Departments tab.<br />
                                        5. Click on “Add new Department(s)” button.<br />
                                        6. Provide department name and click save button as shown below:

                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-09.png' className='img-fluid' />
                                        </div><br />
                                        7. Newly added department is listed in departments tab
                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-09.png' className='img-fluid' />
                                        </div><br />


                                        <h4 className='font-wight-600'>How can I edit a department?</h4>
                                        You need to access organization settings to edit a department. Only
                                        users with administrator privilege can access organization settings.<br /><br />

                                        <p className='font-weight-600'>Please follow the below steps to add a department</p><br /><br />


                                        <p className='font-weight-600'> Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege to access Organization Settings. <br />
                                        3. Click Edit Organization Settings<br />
                                        4. Click on Departments tab<br />
                                        5. Select the department that you want to edit. <br />
                                        6. Click on the Action button and select Edit<br />
                                        7. Edit the department details.<br />

                                        <div className='col-8 py-4'>
                                            <img src='assets/images/faq/img-10.png' className='img-fluid' />
                                        </div><br />
                                        8. Click save button after making necessary changes.<br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={10}>
                                    <Accordion.Header>
                                        How can I view the users accessing the platform?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75 '>
                                            Only users with administrator privilege can access platform users.
                                        </p>
                                        The platform users are mapped from Azure AD and their TMS roles are
                                        assigned along with the division mapping. Only users who are mapped
                                        in TMS are shown in the users list.

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege.<br /><br />


                                        <p className='font-weight-600'>Please follow the below steps to view platform users.</p>

                                        1. Click on the Profile to access the settings menu<br />
                                        2. Click on users<br />
                                        3. Shows the list of users who can access the platform.<br /><br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-11.png' className='img-fluid' /></div><br />

                                        <h4>How can I view the users accessing the platform?</h4>
                                        <p>Only users with administrator privilege can add new platform users. </p>
                                        <p>The platform users are mapped from Azure AD and their TMS roles are assigned along with the division mapping.
                                            Only users who are available in Azure AD can be mapped in TMS platform. </p>
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        3. You have logged into the portal<br />
                                        4. You have Administrator privilege. <br />

                                        <p className='font-weight-600'>Please follow the below steps to add a new platform user</p>
                                        4. Click on the Profile to access the settings menu<br />
                                        5. Click on users<br />
                                        6. Shows the list of users who can access the platform.<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-11.png' className='img-fluid' /></div>
                                        7. Click on Add New User<br />
                                        8. The users from Azure AD who are not mapped in TMS are shown.<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-12.png' className='img-fluid' /></div>
                                        9. Select the user to be added by clicking Add user button.<br />
                                        10. Select roles and divisions to be mapped to the user as
                                        shown below.<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-13.png' className='img-fluid' /></div>
                                        11. Click Save button to add the new user. <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={11}>
                                    <Accordion.Header>
                                        How can I change the roles and divisions mapping of a platform user?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75 '>
                                            Only users with administrator privilege can edit the roles and divisions mapping of a platform users.
                                        </p>
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have Administrator privilege.<br /><br />



                                        <p className='font-weight-600'>Please follow the below steps to view platform users.</p>

                                        1. Click on the Profile to access the settings menu<br />
                                        2. Click on users<br />
                                        3. Shows the list of users who can access the platform. <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-11.png' className='img-fluid' /></div>

                                        4. Select the user who you want to change<br />
                                        5. Click on the Action button<br />
                                        6. Select Edit<br />
                                        7. Change roles and divisions mapping<br />
                                        8. Click Save button to save the changes.<br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={12}>
                                    <Accordion.Header>
                                        How can I access customer profiles?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75 '>
                                            TMS allows creation of customer profiles and assigns a unique customer code automatically for each customer. Only authorized users can
                                            access the customers section from where you manage your customers.
                                        </p>
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Customers.


                                        <p className='font-weight-600'>Please follow the below steps to view customers.</p>
                                        1. Click on the Customer from left-side menu<br />
                                        2. Click on All Customers<br />
                                        3. All customers are listed<br /><br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-14.png' className='img-fluid' /></div>
                                        4. To view the details of a customer, select the customer and click on the action button<br />
                                        5. Click Edit<br />
                                        6. The customer details are shown as below:<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-15.png' className='img-fluid' /></div>



                                        <h4>How can I add a new customer? </h4>
                                        <p className='font-weight-600'>Please follow the below steps to view platform users.</p>

                                        Only authorized users can access customers section and add a new customer.<br />
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Customers.<br /><br />

                                        <p className='font-weight-600'>Please follow the below steps to view customers.</p>
                                        1. Click on the Customer from left-side menu<br />
                                        2. Click on All Customers<br />
                                        3. All customers are listed <br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-14.png' className='img-fluid' /></div>
                                        4. To add a new customer, click on Add New Customer button<br />
                                        5. The new customer screen is shown as below

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-15.png' className='img-fluid' /></div>
                                        6. Fill required customer details in different sections – going through the wizard<br />
                                        7. Complete and save to create a new customer.<br />
                                        8. A unique customer code will be generated automatically for the new customer created.<br />

                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey={13}>
                                    <Accordion.Header>
                                        How can I access supplier profiles?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75'>
                                            TMS allows creation of supplier profiles and assigns a unique supplier
                                            code automatically for each customer. Only authorized users can
                                            access the suppliers section from where you manage your suppliers.
                                        </p>
                                        <br />
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Suppliers.<br />

                                        <p className='font-weight-600'>Please follow the below steps to view suppliers.</p>

                                        7. Click on the Supplier from left-side menu<br />
                                        8. Click on All Suppliers<br />
                                        9. All suppliers are listed<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-16.png' className='img-fluid' /></div><br /><br />

                                        10. To view the details of a supplier, select the supplier and click on the action button<br />
                                        11. Click Edit<br />
                                        12. The supplier details are shown as below:<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-17.png' className='img-fluid' /></div>


                                        <h4 className='mb-4'>How can I add a new supplier?</h4>

                                        Only authorized users can access suppliers section and add a new
                                        supplier. <br /><br />
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        3. You have logged into the portal<br />
                                        4. You have sufficient privilege to access Suppliers.<br />

                                        <p className='font-weight-600'> Please follow the below steps to add a new supplier.</p>
                                        9. Click on the Supplier from left-side menu<br />
                                        10. Click on All Suppliers<br />
                                        11. All suppliers are listed<br />
                                        12. To add a new supplier, click on Add New Supplier button<br />
                                        13. The new supplier screen is shown as below:<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-17.png' className='img-fluid' /></div><br />
                                        14. Fill required supplier details in different sections – going through the wizard<br />
                                        15. Complete and save to create a new supplier. <br />
                                        16. A unique supplier code will be generated automatically for
                                        the new supplier created. <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={14}>
                                    <Accordion.Header>
                                        How can I access products?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className='w-75 '>
                                            TMS allows creation of products and assigns a unique product code
                                            automatically for each product created. Only authorized users can
                                            access the products section from where you manage all your product
                                            profiles.
                                        </p>
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Products.<br />

                                        <p className='font-weight-600'>Please follow the below steps to view products.</p>

                                        1. Click on the Products from left-side menu<br />
                                        2. Click on All Products<br />
                                        3. All products are listed<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-18.png' className='img-fluid' /></div>

                                        4. To view the details of a product, select the product and click on the action button<br />
                                        5. Click View<br />
                                        6. The product details of the selected product are shown as below:<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-19.png' className='img-fluid' /></div>


                                        <h4 className='py-4'>How can I add a new product?</h4>
                                        Only authorized users can access products section and add a new product.

                                        <br /><br />
                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Products.<br /><br />

                                        <p className='font-weight-600'>Please follow the below steps to add a new product.</p>
                                        1. Click on the Products from left-side menu<br />
                                        2. Click on All Products<br />
                                        3. All products are listed<br />
                                        4. To add a new product, click on Add New Product button<br />
                                        5. The new product screen is shown as below:<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-20.png' className='img-fluid' /></div>

                                        6. Fill required product details <br />
                                        7. Click save to create a new product. <br />
                                        8. A unique product code will be generated automatically for the new product created.<br /><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={15}>
                                    <Accordion.Header>
                                        What are notifications?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        TMS sends notifications on task updates or on reminders.<br />
                                        The bell icon marked with orange circle indicates that there are new
                                        notifications. <br />To view the notifications, click on the bell icon.
                                        Below screen shows how the notifications are shown:

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-21.png' className='img-fluid' /></div>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={16}>
                                    <Accordion.Header>
                                        What are workflow and approvals?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        TMS has a workflow and approval engine that routes documents
                                        through various departments/user levels (user roles such as entry user
                                        and manager) for approvals. <br />
                                        The workflow can be configured based on the process and number of
                                        approval levels required. For example, a separate workflow can be
                                        created for approving estimated costing sheet for Coal division and
                                        estimated costing sheet for Metal division if both have different
                                        approval steps or different user roles involved. <br />
                                        If the document or task requires approval, the status of the document
                                        or task is shown as “Approval in Progress”. The authorized users can
                                        access these documents from the approval section to review and
                                        approve.<br /><br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-22.png' className='img-fluid' /></div>

                                        The user can view and approve these requests/documents.<br /><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={17}>
                                    <Accordion.Header>
                                        What is a customer requirement?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        A customer requirement is a requirement or enquiry from a customer
                                        for a product to be supplied to them on a shot or long term. When the
                                        trading team receives an enquiry or requirement, they can capture in
                                        the customer requirements section. <br /><br />
                                        Each requirement will have a unique number and a supply plan to help
                                        trading team trace supplies against the requirement.

                                        <h4 className=''>How can I view all customer requirements?</h4>

                                        Authorized users can access customer requirements from the left-side menu.
                                        <br />
                                        <p className='font-weight-600'>Pre-requisites:</p><br />
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to access Customer Requirements. <br />
                                        <p className='font-weight-600'>Please follow the below steps to view customer requirements.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Customer Requirements<br />
                                        3. All customer requirements will be listed as shown below:<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-23.png' className='img-fluid' /></div><br /><br />
                                        <h4>How can I create a new customer requirement</h4>
                                        Authorized users can access customer requirements and create a new customer requirement.
                                        <p className='font-weight-600'> Pre-requisites:</p>
                                        1. You have logged into the portal
                                        2. You have sufficient privilege to create a new Customer
                                        Requirements.
                                        <p className='font-weight-600'>Please follow the below steps to create a new customer requirement.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Customer Requirements<br />
                                        3. All customer requirements will be listed as shown below<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-23.png' className='img-fluid' /></div>

                                        4. Click on New Customer Requirement<br />
                                        5. Select customer<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-24.png' className='img-fluid' /></div><br /><br />
                                        6. Select contract terms
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-25.png' className='img-fluid' /></div>
                                        7. Select product
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-26.png' className='img-fluid' /></div>
                                        8. Select supply plan
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-27.png' className='img-fluid' /></div>

                                        9. Click submit to create a new customer requirement.
                                        10. A new unique customer requirement number will be created.


                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey={18}>
                                    <Accordion.Header>
                                        What is a trade process?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The trade process in TMS refers to the activities performed during the selling of goods by various team members such as the
                                        trade team, shipping team, and finance team. <br /><br />

                                        The trade starts with an enquiry for trade and the trade user initiates a trade. Below are the activities involved in the trade process. <br /><br />



                                        <img src='assets/images/faq/Trade_Process.png' className='img-fluid' />
                                        <h4>The steps involved in the trade process:</h4>

                                        <h6>1) Enquiry</h6>
                                        <p> Record customer enquiries and supply plans.  </p>

                                        <h6>2) Trade Initiation</h6>
                                        <p> The trade team initiates the trade.<br />

                                            There are two types of trade: normal trade and split trade.
                                            Normal trade involves one company and one customer.
                                            Split trade involves splitting the trade between companies or between customers.  </p>


                                        <h6>3) Cost Estimation</h6>
                                        <p> Finance team estimates the total cost and prepares the pre-shipment cost sheet.</p>


                                        <h6>4) Contracts preparation</h6>
                                        <p>Contracts are prepared by the trading team. Depending on the type of trade (normal or split), the contract formats will be changed.</p>



                                        <h6>5) Invoices and Purchase Orders</h6>
                                        <p>The finance team prepares or captures invoices and purchase orders. In the case of split trade, separate invoices will be shared with customers depending on the split percentage. </p>


                                        <h6>6) Vessel Fixture</h6>
                                        <p> The shipment team fixes/nominates the vessel and updates the trade record with the vessel details and ETA. </p>

                                        <h6>7) Pre-Shipment Activities</h6>
                                        <p>The trade team completes the pre-shipment checklist to confirm that the vessel was accepted by both supplier and customer, and draft documents are shared and verified between parties. </p>

                                        <h6>8) Loading</h6>
                                        <p> The trade team completes a checklist to confirm that all loading activities are completed and documents are uploaded.</p>

                                        <h6>9) Discharging</h6>
                                        <p>The discharge port checklist confirms that the details related to the discharge of the shipment are updated and related documents are uploaded. </p>


                                        <h6>10) Payments</h6>
                                        <p>Customer and supplier payments are captured. Trade team updates payment details. In the case of split trade, invoice-wise payment details are updated. </p>

                                        <h6>11) Trade Closure</h6>
                                        <p>The trade team completes a checklist to confirm that all trade closure activities are completed and closes the trade.</p>

                                        <h6>12) Actual Cost Report</h6>
                                        <p>The actual cost incurred for the execution of the trade is captured in the trade. This actual cost is compared with the initial estimated cost.</p>

                                        <h6>13) Reports</h6>
                                        <p>The report provides insights into the trades executed with specific information on revenues, cash flow, shipments, and performance.
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={105}>
                                    <Accordion.Header>
                                        What is split trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Split trade is a trade in which a trade is divided between multiple companies or multiple customers. </p>
                                        <h4>Below are the two options: </h4>
                                        <h6>Multiple companies </h6>
                                        <p>Co-selling - to a customer: In a split trade involving multiple companies selling to a customer the trade is split between the companies. </p>
                                        <h6>Multiple Customers </h6>
                                        <p>co-buyers - buying from a company: In a split trade involving multiple customers buying from a company, the trade is split between the customers. </p>
                                        <br />
                                        <p>The contract document is made with a separate section to show co-sellers or co-owners, depending on the split type. Separate proforma invoices and invoices are generated for customers based on the split.<br />

                                            In both splits, one party will be the main party and the other party will be a co-seller or a co-buyer, depending on the split type. <br /><br />
                                        </p>

                                        <h4>The below diagram shows the flow associated with split trade. </h4>
                                        <img src='assets/images/faq/Split_Trade.png' className='img-fluid' />




                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey={102}>
                                    <Accordion.Header>
                                        Trade Workflow                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <img src='assets/images/faq/trade_Workflow.png' className='img-fluid' />
                                    </Accordion.Body>
                                </Accordion.Item>




                                <Accordion.Item eventKey={101}>
                                    <Accordion.Header>
                                        How can I initiate a normal trade process?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can initiate the trade from a customer requirement. You must
                                        have permission to view customer requirements and initiate trade.
                                        <br /><br />

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to view customer requirements and
                                        initiate trade.<br />

                                        <br /><br /><p className='font-weight-600'>   Please follow the below steps to initiate a new trade</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Customer Requirements<br />
                                        3. All customer requirements will be listed as shown below:<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-36.png' className='img-fluid' /></div><br />
                                        4. Click on Initiate Trade<br />
                                        5. The trade initiation wizard is loaded and shows the general information which you had captured on customer requirement<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-37.png' className='img-fluid' /></div><br />
                                        6. Click on Next<br />
                                        7. Shows the product details. The edit option allows to change Buying Price, Selling Price, and Quantity.<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-38.png' className='img-fluid' /></div><br />
                                        8. Click Next<br />
                                        9. Shows customer details.<br />

                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-40.png' className='img-fluid' /></div><br />
                                        10. Click Next<br />
                                        11. Select supplier<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-41.png' className='img-fluid' /></div><br />
                                        12. Click Next<br />
                                        13. Provide shipping details<br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={103}>
                                    <Accordion.Header>
                                        How can I initiate a split trade process – multiple companies?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can initiate the trade from a customer requirement. You must
                                        have permission to view customer requirements and initiate trade.
                                        <br /><br />

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to view customer requirements and
                                        initiate trade.<br />

                                        <br /><br /><p className='font-weight-600'>   Please follow the below steps to initiate a new trade</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Customer Requirements<br />
                                        3. All customer requirements will be listed as shown below:<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-36.png' className='img-fluid' /></div><br />
                                        4. Click on Initiate Trade<br />
                                        5. Select Split in Trade Type<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/split1.png' className='img-fluid' /></div><br />
                                        6. Select multiple companies and click Select button<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/split6.png' className='img-fluid' /></div><br />
                                        7. Click on Default company and then provide split ratio. After providing split ration, click Next Button.<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/split7.png' className='img-fluid' /></div><br />

                                        8. Continue with Trade and give product details-Buying Price, Selling Price, Quantity and click Next button.<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-38.png' className='img-fluid' /></div><br />

                                        9. Enter customer details and click Next button.<br />

                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-40.png' className='img-fluid' /></div><br />
                                        10. Enter supplier details and click Next button.<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-41.png' className='img-fluid' /></div><br />
                                        11. Provide shipping details and click Submit<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/split5.png' className='img-fluid' /></div><br />
                                        12. A Split trade with a trade# is created. <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={104}>
                                    <Accordion.Header>
                                        How can I initiate a split trade process – multiple customers?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can initiate the trade from a customer requirement. You must
                                        have permission to view customer requirements and initiate trade.
                                        <br /><br />

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to view customer requirements and
                                        initiate trade.<br />

                                        <br /><br /><p className='font-weight-600'>   Please follow the below steps to initiate a new trade</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Customer Requirements<br />
                                        3. All customer requirements will be listed as shown below:<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-36.png' className='img-fluid' /></div><br />
                                        4. Click on Initiate Trade<br />
                                        5. Review General Information and click Next Button<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/split-cus1.png' className='img-fluid' /></div><br />


                                        6. Continue with Trade and give product details-Buying Price, Selling Price, Quantity and click Next button.<br />
                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-38.png' className='img-fluid' /></div><br />

                                        7. Choose Trade Type as Split Trade from customer page and select two customers.<br />

                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/split-cus2.png' className='img-fluid' /></div><br />

                                        8. Choose split ratio and select the default customer<br />

                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/split-cus3.png' className='img-fluid' /></div><br />

                                        9. Provide payment method for both customers and click Next button<br />

                                        <br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/split-cus4.png' className='img-fluid' /></div><br />

                                        10. Enter supplier details and click Next button.<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-41.png' className='img-fluid' /></div><br />
                                        11. Provide shipping details and click Submit<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/split5.png' className='img-fluid' /></div><br />
                                        12. A Split trade with a trade# is created. <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey={20}>
                                    <Accordion.Header>
                                        How can I view all trade transactions?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        How can I view all trade transactions?

                                        <p className='font-weight-600'>Pre-requisites:</p><br />
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to view trade transactions.<br />

                                        <p className='font-weight-600'> Please follow the below steps to view customer requirements.</p><br />
                                        4. Click on the Trading from left-side menu<br />
                                        5. Click on Trade transactions<br />
                                        6. All trade transactions will be listed.<br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={21}>
                                    <Accordion.Header>
                                        How are trades listed in TMS? What are the sections?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The trades are classified into four sections:<br /><br />

                                        <p><span className='font-weight-600 mr-3'> Section 1</span>   Groups trades that are under contract finalization</p>
                                        <p><span className='font-weight-600 mr-3'> Section 1</span>   Groups trades that are in execution</p>
                                        <p><span className='font-weight-600 mr-3'> Section 1</span>   Groups trades that have completed shipment but waiting for pre-closure updates</p>
                                        <p><span className='font-weight-600 mr-3'> Section 1</span>   Groups trades that are closed.</p>


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={22}>
                                    <Accordion.Header>
                                        What are the steps involved in trade process?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The trade process in TMS refers to the activities performed during the
                                        selling of goods by various team members such as trade team,
                                        shipping, and finance team. <br /><br />
                                        The trade starts with the initiation and continues through various
                                        stages such as contract preparation, pre-shipping activities, shipping,
                                        and closing.
                                        <br /><br />

                                        <p><span className='font-weight-600 mr-3'> Below are the steps involved in the trade process:</span></p>
                                        1. Initiate the trade<br />
                                        2. Update estimated freight charges <br />
                                        3. Prepare estimated cost sheet and approve estimated cost sheet<br />
                                        4. Prepare contracts and approve contracts<br />
                                        5. Prepare customer proforma invoice<br />
                                        6. Prepare supplier PO<br />
                                        7. Update supplier proforma invoice<br />
                                        8. Update L/C documents<br />
                                        9. Update insurance details<br />
                                        10. Pre-shipment checklist<br />
                                        11. Fix/nominate vessel<br />
                                        12. Post vessel fixture checklist<br />
                                        13. Loading update<br />
                                        14. Sailing notification<br />
                                        15. Loading checklist<br />
                                        16. Discharge port updates<br />
                                        17. Customer/Supplier Payment updates<br />
                                        18. Actual cost sheet and approvals<br />
                                        19. Close trade<br />


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={23}>
                                    <Accordion.Header>
                                        How can I add estimated freight charges to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Estimated freight charge entry is a mandatory step in the trade
                                        process. Only authorized users can add estimated freight charges to a
                                        trade.
                                        <br /><br />

                                        <p className='bg-dark text-white p-2 mb-3 w-75'>By Default: The permission to add estimated freight charge is only available to Shipment Team.</p> <br />
                                        <p><span className='font-weight-600 mr-3'>Pre-requisites:</span></p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add estimated freight charges. <br /><br />
                                        <p className='font-weight-600'>Please follow the below steps to add estimated freight charges to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Select the trade for which you want to add estimated freight charges<br />
                                        5. Click on More Actions button<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-28.png' className='img-fluid' /></div><br />
                                        6. You will be directed to the trade process screen<br />
                                        7. Enter freight charges in the freight charges updates section<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-42.png' className='img-fluid' /></div><br />
                                        8. Click Submit<br />
                                        9. The trade status will be updated as freight charges added.<br />


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-28.png' className='img-fluid' /></div><br />


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={24}>
                                    <Accordion.Header>
                                        How can I add estimated cost sheet to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Preparation of estimated cost sheet is a mandatory step in the trade
                                        process. Only authorized users can add estimated cost sheet to a
                                        trade.
                                        <br /><br />

                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to add estimated freight charge is only available to Shipment Team.</p>


                                        <p><span className='font-weight-600 mr-3'>The estimated cost sheet goes for an approval:</span></p>


                                        <div class=""><img src='assets/images/faq/Cost_Sheet.png' className='img-fluid' /></div><br />


                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add estimated cost sheet. <br />
                                        3. Freight charge updates have been completed.<br /><br />

                                        <p className='font-weight-600'> Please follow the below steps to add estimated freight charges to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Select the trade for which you want to add estimated cost sheet<br />
                                        5. Click on More Actions button<br /><br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-28.png' className='img-fluid' /></div><br />
                                        6. You will be directed to the trade process screen<br />
                                        7. Click on Estimated Cost Sheet Tab<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-42.png' className='img-fluid' /></div><br />
                                        8. Enter the costing parameters and click Save.<br />
                                        9. The costing sheet must be reviewed and approved by the
                                        manager to proceed with the next steps of trade.<br /><br />



                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={25}>
                                    <Accordion.Header>
                                        How can I prepare contracts?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Contract preparation is a mandatory step in the trade process and
                                        estimated freight charges and estimated cost sheets must be
                                        completed to prepare contracts.
                                        <br /><br />

                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to prepare contracts is only available to Trade Team.</p>


                                        <p><span className='font-weight-600 mr-3'>The contract goes for an approval:</span></p>


                                        <div class=""><img src='assets/images/faq/Contract_Process.png' className='img-fluid' /></div><br />


                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to prepare contract.<br />
                                        3. Estimated freight charges and estimated cost sheets must be
                                        added to the trade.<br /><br />

                                        <p className='font-weight-600'> Please follow the below steps to add estimated freight charges to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />


                                        4. Select the trade for which you want to prepare contracts<br />
                                        5. Click on More Actions button<br />
                                        6. You will be directed to the trade process screen<br />
                                        7. Click on Customer or Supplier Contract Tab<br />
                                        8. The contract sections will be loaded on the screen as shown
                                        below:<br />

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-44.png' className='img-fluid' /></div>

                                        9. Click on the contract section and review the contract content<br />
                                        10. Locked sections cannot be edited<br />

                                        11. After reviewing and making necessary changes (only for
                                        open sections) to the contract, click for Send for Approval.<br />
                                        12. The contract will be sent for approval from the trade
                                        manager. On approval of the contract, the user can continue
                                        with next trade steps. <br /> <br />




                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={26}>
                                    <Accordion.Header>
                                        How can I move a trade to execution stage?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You must mark the trade as contracts finalized to move trade to execution stage.

                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to finalize trade is only available to Trade Team.</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to mark trade contracts finalized.<br />
                                        Customer and supplier contracts must be approved by the trade manager.
                                        <br />
                                        <p className='font-weight-600'>Please follow the below steps to add estimated freight charges to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. The trades for which the contracts are approved will show “Mark Finalized” button.<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-32.png' className='img-fluid' /></div><br />
                                        5. Click on “Mark Finalized” button<br />
                                        6. Upload customer and supplier signed contracts<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-33.png' className='img-fluid' /></div><br />
                                        7. Click on Save<br />
                                        8. The trade will be moved to execution stage.<br />




                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={27}>
                                    <Accordion.Header>
                                        How can I add shipment updates to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        How can I add shipment updates to a trade? <br />

                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to add shipment updates is only available to Shipping Team.</p>
                                        <br />
                                        <p className='font-weight-600'>Pre-requisites:</p><br />
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add shipment updates. <br />
                                        3. The trade is in execution stage.<br /><br />
                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p><br />
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on Shipment Updates<br /><br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-34.png' className='img-fluid' /></div><br />
                                        6. Click on Update Vessel Information<br />
                                        7. Provide vessel details and ETA<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/img-35.png' className='img-fluid' /></div><br />
                                        8. Click Save<br /><br />




                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={28}>
                                    <Accordion.Header>
                                        How can I update invoices and PO to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can add invoices and PO to trade during trade execution.

                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to add invoices and PO is only available to Finance Team.</p>

                                        <p className='font-weight-600'> Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add invoices and PO to the trade. <br />
                                        3. The trade is in execution stage.<br /><br />


                                        <p className='font-weight-600'> Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on Invoices and PO<br />
                                        6. Click on Generate Customer Proforma Invoice tab to create
                                        customer proforma invoice<br />
                                        7. Click on Generate Customer Invoice tab to create customer
                                        invoice<br />
                                        8. Click on Generate Supplier PO tab to create supplier PO<br />
                                        9. Click on Update Supplier Proforma invoice tab to update supplier
                                        proforma invoice<br />

                                        10. Click on Update Supplier Invoice tab to update supplier invoice details.<br /><br />




                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={29}>
                                    <Accordion.Header>
                                        How can I update L/C details to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can add L/C details to trade during trade execution.
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to update L/C details is only available to Finance Team.</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to update L/C details. <br />
                                        3. The trade is in execution stage.<br />

                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on L/C updates<br />
                                        6. Provide L/C details<br />
                                        7. Click on Save<br /><br />




                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={30}>
                                    <Accordion.Header>
                                        How can I update insurance details to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can add insurance details to the trade during trade execution.
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to update insurance details is only available to Finance Team.</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to update insurance details. <br />
                                        3. The trade is in execution stage.<br />

                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on Insurance details<br />
                                        6. Provide insurance details<br />
                                        7. Click on Save<br /><br />



                                    </Accordion.Body>
                                </Accordion.Item>


                                {/*Krupali FAQ*/}


                                <Accordion.Item eventKey={32}>
                                    <Accordion.Header>
                                        How can I add loading, ETA, and discharge port updates to a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can add loading, ETA, and discharge port updates to the trade during trade execution.
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to update loading, ETA, and discharge port updates is only
                                            available to Trade Team.The shipment ETA is available for the shipping user. </p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add loading, ETA, and discharge
                                        port details. <br />
                                        3. The trade is in execution stage.<br />


                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on the appropriate update page<br />
                                        6. Provide required information<br />
                                        7. Click on Save<br /><br />


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={33}>
                                    <Accordion.Header>
                                        How can I mark a trade as shipment completed or move a trade to pre-closure stage?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can move the trade to pre-closure trade after completing all execution tasks.
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to move a trade from execution to pre-closure is only available to the Trade Team.</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to move trade to pre-closure. <br />
                                        3. The trade is in execution stage and all execution steps are
                                        completed.<br /><br />


                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Execution tab (Section 2)<br />
                                        5. Click on the mark shipment complete button<br />
                                        The mark shipment complete button appears only after
                                        completing all trade execution tasks. <br />
                                        6. The trade will be marked as shipment completed and will be
                                        moved to pre-closure update.<br /><br />


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={34}>
                                    <Accordion.Header>
                                        What are pre-closure activities to close the trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can close a trade only after completing pre-closure activities. The
                                        pre-closure activities involve updating supplier payment and customer
                                        payments and adding actual cost sheet.

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={35}>
                                    <Accordion.Header>
                                        How can I add actual cost sheet?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can add actual cost sheet once the trade is moved to the preclosure stage
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to add actual cost sheet is only available to the Finance Team.</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to add actual cost sheet.<br />
                                        3. The trade is in pre-closure stage.<br />
                                        <br /><br />


                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        7. Click on the Trading from left-side menu<br />
                                        8. Click on Trade transactions<br />
                                        9. All trade transactions will be listed<br />
                                        10. Go to Trades in Pre-closure tab (Section 2)<br />
                                        11. Click on the actual cost sheet<br />
                                        12. Provide actual cost details.<br />
                                        13. Click on Save<br /><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={36}>
                                    <Accordion.Header>
                                        How can I close a trade?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can close a trade after completing all pre-closure activities.
                                        <p class="bg-dark text-white p-2 mb-3 w-75">By Default: The permission to close a trade is only available to the Trade Team</p>

                                        <p className='font-weight-600'>Pre-requisites:</p>
                                        1. You have logged into the portal<br />
                                        2. You have sufficient privilege to close the trade. <br />
                                        3. The trade is in pre-closure stage.<br />
                                        <br /><br />


                                        <p className='font-weight-600'>Please follow the below steps to add shipment updates to a trade.</p>
                                        1. Click on the Trading from left-side menu<br />
                                        2. Click on Trade transactions<br />
                                        3. All trade transactions will be listed<br />
                                        4. Go to Trades in Pre-closure tab (Section 2)<br />
                                        5. Click on the close trade button<br />
                                        6. Confirm closing of trade<br />
                                        7. The trade is moved to the closed trade tab<br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={37}>
                                    <Accordion.Header>
                                        How can I access meetings from TMS portal
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        To access meetings features from TMS portal, please click on Meetings icon on the top menu bar in TMS portal.
                                        <p class="mb-3 w-75">On clicking the meeting icon, the meeting page is loaded. The meetings for the current day are listed. A sample meeting page is shown below:</p>

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-45.png' className='img-fluid' /></div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={38}>
                                    <Accordion.Header>
                                        How can I view the meetings for the week?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        To view the meetings of a week, select “week” from the meetings page. The weekly meetings’ view is shown below:

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-46.png' className='img-fluid' /></div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={39}>
                                    <Accordion.Header>
                                        How can I quick view the meeting details from the meeting listing page?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        To view the meetings details as a quick view, single click on the meeting. On clicking the details of the meeting will be populated as shown below:

                                        <div class="col-8 py-4"><img src='assets/images/faq/img-46.png' className='img-fluid' /></div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={40}>
                                    <Accordion.Header>
                                        How can I create a new meeting?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        To create a new meeting, open the meetings page and follow the <span className='font-weight-600'>below steps. </span>
                                        <br /><br />
                                        1)	Click on the “Create Meeting” button<br />
                                        2)	Select Meeting<br />
                                        3)	Enter Meeting Title, Meeting Category, and Meeting Date<br />
                                        4)	Select Meeting Type<br />
                                        5)	Select Attendees<br />
                                        6)	Select Notification Members<br />
                                        7)	Create Agenda Items<br />
                                        8)	Click on “Create”<br /><br />

                                        On clicking “Create”, the meeting will be created.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={41}>
                                    <Accordion.Header>
                                    How can I add meeting minutes, agenda items, and action items/tasks to a meeting?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    You need to open the meeting to add agenda items, meeting minutes, and tasks to the meeting.
                                        <br /><br />

                                        <h4 className='font-16'>Follow the below steps to add agenda items and tasks:</h4>
                                        1)	Click on the meeting<br />
                                        2)	Click on Agenda<br />
                                        3)	Click on + button<br />
                                        4)	Type Agenda <br />
                                        5)	Click Save<br />
                                        6)	After saving expand the Agenda item to add discussion points and Tasks<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/meet-1.png' className='img-fluid' /></div>
                                        
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={42}>
                                    <Accordion.Header>
                                    How can I link meetings to a trade, a supplier, or a customer?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    You can link meetings to a trade, a customer, or a supplier. To link it with the related entity, go to the entity listing page and then click the link button.
                                    <h4 className='font-16 mt-4'>The below steps show how a meeting can be linked to a particular trade.</h4>
                                        <br /><br />

                                        1)	Go to the trade listing<br />
                                        2)	Select the trade to which the meeting to be linked to<br />
                                        3)	Click on the link button<br />
                                        
                                        <div class="col-8 py-4"><img src='assets/images/faq/meet-2.png' className='img-fluid' /></div><br />
                                        4)	Select the meeting to be linked and click the Submit button<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/meet-3.png' className='img-fluid' /></div>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={106}>
                                    <Accordion.Header>
                                    How can I download the meeting minutes?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    The meeting minutes can be downloaded as a PDF. To download the minutes of the meeting, follow the below steps:
                                    <br /><br />

                                        1)	Open the meeting in view mode. <br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/meet-4.png' className='img-fluid' /></div> <br />
                                        2)	Click on the Export button to generate meeting minutes <br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/meet-5.png' className='img-fluid' /></div><br />
                                        3) Click on the Print button to print or save the report to a PDF file. <br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={43}>
                                    <Accordion.Header>
                                        How can I create tour records?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Tour records allow you to record your tours and details of the tour. The key activities during the tour and follow up tasks
                                        associated with the tour can be recorded. Please follow the below steps to create tour record:
                                        <br /><br />

                                        1)	Open meetings page<br />
                                        2)	Click on Create<br />
                                        3)	Select Tour<br />
                                        4)	Provide tour details<br />
                                        5)	Click Save<br />

                                        <p className='font-weight-600'>A sample tour creation screen is shown below:</p>


                                        <div class="col-8 py-4"><img src='assets/images/faq/img-48.png' className='img-fluid' /></div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={44}>
                                    <Accordion.Header>
                                        Can I assign tasks, and will the assigned user get notification on the tasks?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        The tasks can be assigned to users. The users will get notification on tasks assigned. The users will also get reminder emails
                                        if the tasks are not closed on the due date.
                                        <br /><br />

                                        1)	Open meetings page<br />
                                        2)	Click on Create<br />
                                        3)	Select Tour<br />
                                        4)	Provide tour details<br />
                                        5)	Click Save<br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={45}>
                                    <Accordion.Header>
                                        Who can access the meeting and tour records?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Only assigned users to the meeting or tour can access meeting and tour records. The tasks can be accessed by the assigned task users in addition to the meeting assigned users.


                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={107}>
                                    <Accordion.Header>
                                    How can I access financial institute details?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Users with permission to view financial institutes can access financial institute details. <br /> 
                                    <br /><br />
                                    <p className='font-weight-600'>The below steps show how to access financial institute details and view details.</p> <br />
                                        1)	Go to the Financial Institutes module<br />
                                        2)	Click on the Institutes menu<br />
                                        3)  All financial institutes are listed. Below is the sample screen showing list of financial institutions.<br /> 
                                        <div class="col-8 py-4"><img src='assets/images/faq/fimg-1.png' className='img-fluid' /></div>
                                         <br />
                                         4)	Click on the View Details button to access contact details and other information about the financial institute. Below is the sample screen showing details of a financial institute.
                                         <div class="col-8 py-4"><img src='assets/images/faq/fimg-2.png' className='img-fluid' /></div><br /> 

                                         5)	Click the Next button to view contact details. Below is the sample screen with contacts. 
                                         <div class="col-8 py-4"><img src='assets/images/faq/fimg-3.png' className='img-fluid' /></div><br /> 

                                         6)	To open details of a particular contact, click on the View details for that contact. Below is the sample screen associated with a contact’s information. 
                                         <div class="col-8 py-4"><img src='assets/images/faq/fimg-4.png' className='img-fluid' /></div><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey={108}>
                                    <Accordion.Header>
                                    How can I update the details of a person who moved from one institute to another? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    If one person moved from one institution to another institution, this can be updated from the Contacts section. The below steps show how to change a person’s institution details. 
                                    <br /><br />
                                    
                                        1)	Go to contacts<br />
                                        2)	Find the contact that you want to change<br />
                                        3)	Click on View contact<br /> 
                                        4)	Contact page shows the current profile and previous employment history. <br />
                                        5)	Click on Edit<br />
                                        6)	Click on the Add New Employment option<br />
                                        7)	Provide new profile details<br />
                                        8)	Click the Save Contact button<br />
                                        9)	Once done click Submit button at the end to save it.<br />
                                        10)	Below is the sample screen showing how to add new profile details<br />
                                        <div class="col-8 py-4"><img src='assets/images/faq/fimg-6.png' className='img-fluid' /></div>
                                         <br />
                                         <br />
                                        
                                         <div class="col-8 py-4"><img src='assets/images/faq/fimg-9.png' className='img-fluid' /></div>
                                         <br />

                                    </Accordion.Body>
                                </Accordion.Item>



                            </Accordion>
                            <div class="accordion" id="accordionExample">

































































                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}