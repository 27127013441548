import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space, Collapse, Steps, version } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import * as CONFIG from '../../../../helpers/config'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BlueButton, BlueBorderButton } from '../../../../components/buttoncomponents'
import * as ButtonComponent from "../../../../components/buttoncomponents"
import { Row, Col, TabPane } from "react-bootstrap"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Button } from "react-bootstrap"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as FormFields from "../../../../components/formcomponents"
import Moment from "moment";
import { useFormik } from 'formik';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';


//imports for views
import Costsheet from '../../../CostSheet/costsheet';
import GenerateCustomerProformaInvoice from '../../ContractFinalized/GenerateCustomerProformaInvoice';
import GenerateCustomerInvoice from '../../ContractFinalized/GenerateCustomerInvoice';
import GenerateSupplierProformaInvoice from '../../ContractFinalized/GenerateSupplierPurchaseOrder';
import UpdateSupplierProformaInvoice from '../../ContractFinalized/UpdateSupplierProformaInvoice';
import SupplierInvoice from '../../ContractFinalized/SupplierInvoice';
import VesselInformation from '../../ContractFinalized/VesselInformation';
import UpdateInsuranceDetails from '../../ContractFinalized/UpdateInsuranceDetails';
import LoadingUpdates from '../../ContractFinalized/LoadingUpdates';
import ShipmentETA from '../../ContractFinalized/ShipmentETA';
import PreShipment from '../../ContractFinalized/PreShipment';
import Postvesselfixture from '../../ContractFinalized/PostFixtures';
import DischargePortUpdates from '../../ContractFinalized/DischargePortUpdates';
import CompleteLoading from '../../ContractFinalized/CompleteLoading';
import LCDocuments from '../../ContractFinalized/LCDocuments';
import { useMsal } from "@azure/msal-react";
import LinkMeetings from '../../../Meetings/components/LinkMeetings';
import { nb } from 'date-fns/locale';


export const VersionList = (props) => {
    const { instance } = useMsal();
    const [list, setList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [versionlist, setversionlist] = useState()
    useEffect(() => {
        if (isFirst) {
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.VERSION_BY_CONTRACT, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.id
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setversionlist(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {versionlist != null &&
                versionlist.map((list, k) => (
                    <option key={k} value={list}>{list}</option>
                ))
            }
        </>
    )
}

export default function FinalizedList(props) {
    console.log(props)
    const { instance } = useMsal();
    const [enableMark, setEnable] = useState(false)
    const MySwal = withReactContent(Swal)
    const [data, setData] = useState([])
    const [template_list, settemplatelist] = useState([])
    const [modal, setModal] = useState(false)
    const [isContract, setIsContract] = useState(false)
    const [processmodal, setprocessmodal] = useState(false)
    const { Panel } = Collapse;
    const navigate = useNavigate()
    const [tradeprocess, settradeprocesses] = useState([])
    const [error, setError] = useState(false)
    const { Step } = Steps;
    const [ismeeting, setismeeting] = useState(false)

    //View states
    const [customerPIView, setCPIV] = useState(false)
    const [customerInvoice, setCI] = useState(false)
    const [supplierPO, setSPO] = useState(false)
    const [supplierproinv, setSPIV] = useState(false)
    const [supplierinv, setSI] = useState(false)
    const [insurance, setinsurance] = useState([])
    const [suppIns, setSupIns] = useState(0)
    const [tradeData, setTradeData] = useState([])
    const [loading, setloading] = useState(0)
    const [loadingdata, setloadingdata] = useState([])
    const [shipmentETA, setShipment] = useState(false)
    const [lcDoc, setLcDoc] = useState(0)
    const [lcDocumentDataSupplier, setLcDocumentDataSupplier] = useState([])
    const [lcDocumentData, setLcDocumentData] = useState([])
    const [tradeDataLcDoc, setTradeDataLcDoc] = useState()
    const [doc1, setdoc1] = useState(false)
    const [defid, setdefid] = useState(0)
    const [contractmodal, setcontractmodal] = useState(false)
    const [finalDraftVersion, setFinalDraftVersion] = useState(0)
    const [isversioncontract, setisversioncontract] = useState(false)
    const [defaultCustomer, setDefaultCustomer] = useState(0)
    const [defaultCompany, setDefaultCompany] = useState(0)
    //useEffect(() => {
    //    let laycanStart = props.data.laycanPeriod.indexOf('-')
    //    console.log(laycanStart)
    //}, [])
    useEffect(() => {
        if (props.data.is_PreShipmentCompleted == 1 && props.data.is_PostVesselFixtureCompleted == 1 &&
            props.data.is_CompletionOfLoadingCompleted == 1 && props.data.is_DischargePortCompleted == 1) {
            setEnable(true)
        }
    })
    useEffect(()=>{
        if(props.compcustdetails != null){
            props.data.compcustdetails.map((cucm,k)=>{
                if(cucm.is_DefaultCustomer == true){
                    setDefaultCustomer(cucm.customer.customerID)
                }
                if(cucm.is_DefaultCompany == true){
                    setDefaultCompany(cucm.company.companyID)
                }
            })
        }
    },[props])
    const formik = useFormik({
        initialValues: {
            version: '',
            cust_perrforma_invoice_doc: '',
            cust_performa_invoice_doc_name: '',
            cust_invoice_doc: '',
            cust_invoice_doc_name: '',
            supplier_po_doc: '',
            supplier_po_doc_name: ''
        }
    })
    const toggle = () => {
        setModal(!modal)
        setError(false)
    }
    const saveCustDocument = (file) => {
        formik.values.r = file.base64
        formik.values.cust_performa_invoice_doc_name = file.name
        setdoc1(!doc1)
    }

    const saveSuppDocument = (file) => {
        formik.values.cust_invoice_doc = file.base64
        formik.values.cust_invoice_doc_name = file.name
        setdoc1(!doc1)
    }

    const saveShipDocument = (file) => {
        formik.values.supplier_po_doc = file.base64
        formik.values.supplier_po_doc_name = file.name
        setdoc1(!doc1)
    }
    const getTradeInfo = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.data.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PROCESS_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settradeprocesses(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        if (props.data.is_PreShipmentCompleted == 2 && props.data.is_PostVesselFixtureCompleted == 2 &&
            props.data.is_CompletionOfLoadingCompleted == 2 && props.data.is_DischargePortCompleted == 2) {
            setEnable(true)
        }
    })
    const markshipmentcompleted = () => {
        console.log('CLICKED')
        toggle()
    }

    const viewCustomerContract = () => {
        const contractid = props.data.customerContractID
        setdefid(contractid)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                setIsContract(true)
                setFinalDraftVersion(res.data.result[0].draftVersionNo)
                setisversioncontract(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        const list_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.VERSION_BY_CONTRACT, list_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const viewSupplierContract = () => {
        const contractid = props.data.supplierContractID
        setdefid(contractid)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                setIsContract(true)
                setFinalDraftVersion(res.data.result[0].draftVersionNo)
                setisversioncontract(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const printDocument = (e) => {
        let versionNo = parseInt(e.target.id)
        const domElement = document.getElementById("download_section");
        if (versionNo == finalDraftVersion) {
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm');
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    if (data.contractTemplateID == 1) {
                        doc.save('Customer_Contract' + '-' + props.data.tradeNo + '.pdf');
                    }
                    else {
                        doc.save('Supplier_Contract' + '-' + props.data.tradeNo + '.pdf');
                    }
                })
        }
        else {
            var charSet = ' '
            var content = charSet + domElement.innerHTML;
            var converted = htmlDocx.asBlob(content);
            if (data.contractTemplateID == 1) {
                saveAs(converted, 'Customer_Contract' + '-' + props.data.tradeNo + '.docx');
            }
            else {
                saveAs(converted, 'Supplier_Contract' + '-' + props.data.tradeNo + '.docx');
            }
        }
    }


    const handleCustomerProformaInvoice = () => {
        console.log('inside customer proforom')
        const json_list_other = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list_other, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if (res.data.result[0].tradedetails.customerProformaInvoiceNo == null || res.data.result[0].tradedetails.customerProformaInvoiceNo == "") {
                    {
                        localStorage.getItem('Generate_Customer_Proforma_Invoice') == 3 &&
                            MySwal.fire(
                                {
                                    icon: 'warning',
                                    html: 'Are you sure you wish to generate ' + '<b>Customer Proforma Invoice?</b> ',
                                    type: 'success',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                    confirmButtonText: 'Confirm',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        const json_list = {
                                            "instanceID": CONFIG.PMSInstanceID,
                                            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                                            "created_User": CONFIG.LoginID,
                                            "id": parseInt(props.data.tradeID),
                                        }
                                        console.log(props.data)
                                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_CUSTOMER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                            .then((res) => {
                                                getTradeInfo()
                                                props.refreshList()
                                                setCPIV(true)
                                            })
                                    }
                                })
                    }
                }
                else {

                    setCPIV(true)
                }
                props.refreshList()

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleCustomerInvoice = () => {

        console.log('clicked', props.data.tradeID)
        const json_list_other = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list_other, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0].tradedetails.customerInvoiceNo)
                if (res.data.result[0].tradedetails.customerInvoiceNo == null || res.data.result[0].tradedetails.customerInvoiceNo == "") {
                    localStorage.getItem('Generate_Customer_Invoice') == 3 &&
                        MySwal.fire(
                            {
                                icon: 'warning',
                                html: 'Are you sure you wish to generate ' + '<b>Customer Invoice?</b> ',
                                type: 'success',
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Confirm',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    const json_list = {
                                        "instanceID": CONFIG.PMSInstanceID,
                                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                                        "created_User": CONFIG.LoginID,
                                        "id": parseInt(props.data.tradeID),
                                    }
                                    console.log(props.data)
                                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_CUSTOMER_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                        .then((res) => {
                                            setCI(true)
                                            getTradeInfo()
                                            props.refreshList()
                                        })

                                }
                            });
                }
                else {
                    setCI(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        props.refreshList()
        getTradeInfo()
    }

    const handleSupplierPurchaseOrder = (e) => {

        console.log('clicked', props.data.tradeID)
        const json_list_other = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list_other, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log('test')
                if (res.data.result[0].tradedetails.supplierPurchaseOrderNo == null || res.data.result[0].tradedetails.supplierPurchaseOrderNo == "") {
                    console.log('testing')
                    if (localStorage.getItem('Generate_Supplier_PO') == 3) {
                        console.log('testing end')
                        MySwal.fire(
                            {
                                icon: 'warning',
                                html: 'Are you sure you wish to generate ' + '<b>Supplier Purchase Order?</b> ',
                                type: 'success',
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Confirm',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    const json_list = {
                                        "instanceID": CONFIG.PMSInstanceID,
                                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                                        "created_User": CONFIG.LoginID,
                                        "id": parseInt(props.data.tradeID),
                                    }
                                    console.log(props.data)
                                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_SUPPLIER_PO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                        .then((res) => {
                                            setSPO(true)
                                            getTradeInfo()
                                            props.refreshList()
                                        })
                                }
                            })
                    }
                }
                else {
                    console.log('testing else')
                    setSPO(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        props.refreshList()
        getTradeInfo()
    }

    const handleSupplierProformaInvoice = (e) => {
        console.log(props)
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0])
                setTradeData(res.data.result[0])
                setSPIV(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        props.refreshList()
        getTradeInfo()
    }

    const handleSupplierInvoice = (e) => {
        console.log(props)
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0])
                setTradeData(res.data.result[0])
                setSI(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        props.refreshList()
        getTradeInfo()
    }

    const handleLCDocuments = () => {

        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        console.log(props.data)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setTradeDataLcDoc(res.data.result[0])
                console.log(res.data.result, 'trade data')
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_LC_CUSTOMER_DOCUMENT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((response) => {
                        if (response.data.result.length > 0) {
                            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_LC_SUPPLIER_DOCUMENT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                .then((responseSupplier) => {
                                    if (responseSupplier.data.result.length > 0) {
                                        console.log('if Supplier')
                                        console.log(responseSupplier.data.result[0])

                                        setLcDocumentDataSupplier(responseSupplier.data.result[0])
                                        setLcDocumentData(response.data.result[0])
                                        setLcDoc(1)
                                    }
                                    else {
                                        console.log('Else Supplier')

                                        setLcDocumentData(response.data.result[0])
                                        setLcDoc(2)
                                    }
                                }).catch((error) => {
                                    if (error.response.status == 401) {
                                        localStorage.clear();
                                        instance.logoutRedirect({
                                            postLogoutRedirectUri: "/",
                                        });
                                    }
                                })
                        }
                        else {
                            console.log('Else')
                            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_LC_SUPPLIER_DOCUMENT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                .then((responseSupplier) => {
                                    if (responseSupplier.data.result.length > 0) {
                                        console.log('if phase 2')
                                        console.log(responseSupplier.data.result[0])

                                        setLcDocumentDataSupplier(responseSupplier.data.result[0])
                                        setLcDoc(3)
                                    }
                                    else {
                                        console.log('Else Supplier phase 2')
                                        setLcDoc(4)
                                    }
                                }).catch((error) => {
                                    if (error.response.status == 401) {
                                        localStorage.clear();
                                        instance.logoutRedirect({
                                            postLogoutRedirectUri: "/",
                                        });
                                    }
                                })
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        props.refreshList()
        getTradeInfo()
    }

    const handleUpdateInsurance = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
            "insuranceType": CONFIG.RoleID == 3021 ||
                CONFIG.RoleID == 3022 ||
                CONFIG.RoleID == 3031 ?
                parseInt(1) :
                CONFIG.RoleID == 3005 ?
                    parseInt(2) :
                    1
        }
        console.log(props.data)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'trade data')
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INSURANCE_DETAILS_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((response) => {
                        if (response.data.result.length > 0) {
                            setTradeData(res.data.result[0])
                            setinsurance(response.data.result[0])
                            setSupIns(1)
                        }
                        else {
                            setTradeData(res.data.result[0])
                            setSupIns(2)
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        props.refreshList()
        getTradeInfo()
    }

    const handleLoadingUpdates = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        console.log(props.data)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'trade data')
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_LOADING_UPDATES_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((response) => {
                        if (response.data.result.length > 0) {
                            setloadingdata(response.data.result[0])
                            setTradeData(res.data.result[0])
                            setloading(1)
                        }
                        else {
                            setTradeData(res.data.result[0])
                            setloading(2)
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        props.refreshList()
        getTradeInfo()
    }

    const handleShipmentETA = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.data.tradeID),
        }
        console.log(props.data)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setTradeData(res.data.result[0])
                setShipment(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        props.refreshList()
        getTradeInfo()
    }

    useEffect(() => {

    }, [customerPIView, supplierPO])

    const cancelTrade = (e) => {
        const id = parseInt(e.target.id)
        MySwal.fire(
            {
                icon: 'warning',
                title: 'Trade cancellation',
                html: 'Are you sure you want to cancel this trade?',
                type: 'success',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                showCancelButton: true,
            }).then((result) => {
                
                if (result.isConfirmed) {

                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": id
                    }
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.CANCEL_TRADE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            console.log(res.data.result)
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                }
            });

    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={cancelTrade}>Cancel Trade</span>
                        </>
                    ),
                }
            ]}
        />
    );

    const getSteps = () => {

    }
    const processtoggle = () => {
        setprocessmodal(!processmodal)
        getTradeInfo()
        if (processmodal == true) {
            props.refreshList()
        }
    }

    const refreshList = () => {
        props.refreshList()
        getTradeInfo()
    }
    useEffect(() => {
        console.log(template_list)
    }, [template_list])

    const handleVersion = (e) => {
        let id = e.currentTarget.value
        if (id != 0) {
            console.log('inside')
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "versionNo": parseInt(id),
                "id": defid
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CONTRACT_BY_VERSION, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    settemplatelist(res.data.result)
                    setisversioncontract(true)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    const contracttoggle = () => {
        setcontractmodal(!contractmodal)
    }
    const getContract = () => {
        return (
            <>

                <Modal isOpen={contractmodal} toggle={contracttoggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                    <ModalBody toggle={toggle}>

                        <div>
                            <BlueBorderButton value='DOWNLOAD DOCUMENT' id={isContract && isversioncontract ? template_list[0].draftVersionNo : data.draftVersionNo} clickEvent={printDocument} className='ml-1-rem' />
                        </div>
                        <div id='download_section' className='mx-4'>
                            {isContract &&
                                template_list.map((list) => {
                                    var contractData = list.contractContent;
                                    return (
                                        <Row>
                                            <Col md={4} className="my-3">
                                                {list.srNo}.&nbsp;{list.heading} :
                                            </Col>
                                            <Col md={8} className="my-3" >
                                                <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    </ModalBody>
                </Modal>
                <div className='service-request-detail-title mx-4 mt-4'>
                    <div className='d-flex justify-content-between'>
                        <div className='col-md-3'>
                            <FormFields.InputSelect
                                md={12}
                                name='versionno'
                                label='Version No'
                                type='text'
                                optionField={<VersionList id={defid} />}
                                onChange={handleVersion}
                                onBlur={handleVersion}
                            />
                        </div>
                        <div>
                            <BlueBorderButton value='EXPORT DOCUMENT' clickEvent={contracttoggle} className='ml-1-rem' />
                        </div>
                    </div>
                </div>
                <div id='download_sections' className='my-3  mx-4'>
                    <div className='add-edit-wrapper bg-white br-8 w-100 scrollContract'>

                        {isContract &&
                            template_list.map((list) => {
                                var contractData = list.contractContent;
                                return (
                                    <Row>
                                        <Col md={4} className="my-3">
                                            {list.srNo}.&nbsp;{list.heading} :
                                        </Col>
                                        <Col md={8} className="my-3" >
                                            <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
    const viewCustomerProformaInvoice = () => {
        handleCustomerProformaInvoice()
    }
    const saveContract = () => {
        if (enableMark) {
            MySwal.fire(
                {
                    icon: 'success',
                    title: 'Mark shipment completed!',
                    html: 'Are you sure you want to complete the shipment?',
                    type: 'success',
                    confirmButtonText: 'Okay',
                    cancelButtonText: 'Cancel'
                }).then(() => {
                    const cust_proforma = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": props.data.tradeID,
                        "customerID" : defaultCustomer,
                        "comnpanyID" : defaultCompany,
                        "invoiceDocument": formik.values.cust_performa_invoice_doc,
                        "invoiceDocumentName": formik.values.cust_performa_invoice_doc_name
                    }
                    const cust_invoice = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": props.data.tradeID,
                        "customerID" : defaultCustomer,
                        "comnpanyID" : defaultCompany,
                        "invoiceDocument": formik.values.cust_invoice_doc,
                        "invoiceDocumentName": formik.values.cust_invoice_doc_name
                    }
                    const supplier_po = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": props.data.tradeID,
                        "invoiceDocument": formik.values.supplier_po_doc,
                        "invoiceDocumentName": formik.values.supplier_po_doc_name
                    }
                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": parseInt(props.data.tradeID),
                        "statusID": props.status
                    }
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.MARKCONTRACT_TRADE_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            props.refreshList()
                            toggle()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_CUSTOMER_PROFORMA, cust_proforma, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {

                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_CUSTOMER_INVOICE, cust_invoice, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {

                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_SUPPLIER_PO, supplier_po, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {

                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                    
                });
        }
        else {
            MySwal.fire(
                {
                    icon: 'warning',
                    title: 'Mark shipment cannot be finalized!',
                    html: 'Please complete the checklist before finalizing the shipment.',
                    type: 'success',
                    confirmButtonText: 'Cancel',
                }).then(() => {

                });
        }

    }
    const openMeeting = () => {
        setismeeting(!ismeeting)
    }
    return (

        <>
            <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
                <ModalHeader toggle={toggle} className='p-40'>
                    <div className='d-flex justify-content-between '>
                        <div className='font-16 font-weight-600 color-707895'>
                            UPLOAD INVOICE AND PO
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className='service-request-modal-wrapper p-40'>
                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Customer Proforma Invoice
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value='Upload Customer Proforma Invoice'
                                filename={formik.values.cust_performa_invoice_doc_name}
                                document={formik.values.cust_performa_invoice_doc}
                                onUploadHandle={saveCustDocument}
                                multiple={false}
                            />
                        </div>
                    </div>
                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Customer Invoice
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value='Upload Customer Invoice'
                                filename={formik.values.cust_invoice_doc_name}
                                document={formik.values.cust_invoice_doc}
                                onUploadHandle={saveSuppDocument}
                                multiple={false}
                            />
                        </div>
                    </div>

                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Supplier Purchase Order
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value='Upload Supplier Purchase Order'
                                filename={formik.values.supplier_po_doc_name}
                                document={formik.values.supplier_po_doc}
                                onUploadHandle={saveShipDocument}
                                multiple={false}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                    <span><ButtonComponent.BlueBorderButton value='Close' clickEvent={toggle} /></span>
                    <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
                </ModalFooter>
            </Modal>
            <Modal isOpen={processmodal} toggle={processtoggle} className='trade_list_modal'>
                <ModalHeader toggle={processtoggle}>

                </ModalHeader>
                <ModalBody className='service-request-modal-wrapper' style={{ padding: '0' }}>
                    <div className='d-flex justify-content-between '>
                        <Tabs className='d-flex  width-100l '>
                            <Col md={2} className='col-12 scroll-over scrollTradeTrasnaction width-sm trade_process_tabs' style={{ width: '20%' }}>
                                <TabList style={{ background: '#FDFAFE', paddingTop: '2rem', overflowY: 'scroll' }}>
                                    <div className='group_heading'>VIEWS</div>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={viewCustomerContract}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Customer Contract</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.custcontractapprovaldate != null ?
                                            Moment(tradeprocess.custcontractapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'><p className=''>{tradeprocess.custcontractapprovedbyImg}</p></div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    <p className='font-13 mb-0 font-weight-500 colorUserName'>{tradeprocess.custcontractapprovedby}</p>
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.custcontractapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={viewSupplierContract}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Supplier Contract</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.suppcontractapprovaldate != null ?
                                            Moment(tradeprocess.suppcontractapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'><p>{tradeprocess.suppcontractapprovedbyImg}</p></div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName'>
                                                    {tradeprocess.suppcontractapprovedby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.suppcontractapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Estimated Cost Sheet</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.estimatescostapprovaldate != null ?
                                            Moment(tradeprocess.estimatescostapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.estimatescostapprovedbyImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.estimatescostapprovedby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.estimatescostapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <div className='group_heading'>INVOICE & PO</div>
                                    {/* <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleCustomerProformaInvoice}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Generate Customer Proforma Invoice</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.customerproinvdate != null ?
                                            Moment(tradeprocess.customerproinvdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.customerProfomaInvoiceStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.customerproinvbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.customerproinvby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.customerproinvbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </Tab> */}
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleSupplierPurchaseOrder}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Generate Supplier PO</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.supplierpodate != null ?
                                            Moment(tradeprocess.supplierpodate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.supplierPOStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.supplierpobyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.supplierpoby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.supplierpobydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleSupplierProformaInvoice}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Upload Supplier Proforma Invoice</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.supplierproinvdate != null ?
                                            Moment(tradeprocess.supplierproinvdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.supplierProfomaInvoiceStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.supplierproinvbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.supplierproinvby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.supplierproinvbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleSupplierInvoice}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Supplier Invoice</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.supplierinvdate != null ?
                                            Moment(tradeprocess.supplierinvdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.supplierInvoiceStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.supplierinvbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.supplierinvby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.supplierinvbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </Tab>
                                    <div className='group_heading'>SHIPMENT UPDATES</div>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleSupplierInvoice}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Update Vessel Information</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.updatevesseldate != null ?
                                            Moment(tradeprocess.updatevesseldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.updateVesselInformationStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.updatevesselbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.updatevesselby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.updatevesselbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLCDocuments}
                                    >
                                        <h4 className='textHeaderTab mb-0'>L/C Documents</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.lccustomerdate != null ?
                                            Moment(tradeprocess.lccustomerdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.lcDocumentStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.lccustomerbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.lccustomerby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.lccustomerbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleUpdateInsurance}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Update Insurance Details</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.insuarcedate != null ?
                                            Moment(tradeprocess.insuarcedate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.insuranceDocumentStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.insuarcebyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.insuarceby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.insuarcebydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLoadingUpdates}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Pre-Shipment Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.preshipmentdate != null ?
                                            Moment(tradeprocess.preshipmentdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.preshipmentChecklistStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.preshipmentbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.preshipmentby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.preshipmentbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLoadingUpdates}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Post-Vessel Fixture - Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.postvesseldate != null ?
                                            Moment(tradeprocess.postvesseldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.postVesselFixtureStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.postvesselbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.postvesselby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.postvesselbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab><Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleShipmentETA}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Shipment ETA</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.shipmentetadate != null ?
                                            Moment(tradeprocess.shipmentetadate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.shipmentETAStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.shipmentetabyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.shipmentetaby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.shipmentetabydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLoadingUpdates}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Completion Of Loading - Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.shipmentloadingdate != null ?
                                            Moment(tradeprocess.shipmentloadingdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.completionOfLoadingStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.shipmentloadingImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.shipmentloadingby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.shipmentloadingbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLoadingUpdates}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Loading Updates</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.loadingupdatedate != null ?
                                            Moment(tradeprocess.loadingupdatedate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.loadingUpdateStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.loadingupdatebyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.loadingupdateby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.loadingupdatebydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={handleLoadingUpdates}
                                    >
                                        <h4 className='textHeaderTab mb-0'>Discharge Port - Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.dischargedate != null ?
                                            Moment(tradeprocess.dischargedate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.dischargePortStatus == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.dischargebyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.dischargeeby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.dischargebydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                </TabList>
                            </Col>
                            <Col md={8}>
                                <TabPanel>
                                    {(localStorage.getItem('Customer_Contract') == 3 || localStorage.getItem('Customer_Contract') == 1) ?
                                        getContract()
                                        :

                                        <>
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        </>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {

                                        (localStorage.getItem('Supplier_Contract') == 3 || localStorage.getItem('Supplier_Contract') == 1) ?
                                            getContract()
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Estimated_Cost_Sheet') == 3 || localStorage.getItem('Estimated_Cost_Sheet') == 1) ?
                                            <Costsheet trade={props.data} tab={2} isview={true} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                {/* <TabPanel>
                                    {(localStorage.getItem('Generate_Customer_Proforma_Invoice') == 3 || localStorage.getItem('Generate_Customer_Proforma_Invoice') == 1) ?
                                        customerPIView &&
                                            props.data.customerProfomaInvoiceStatus == 2 ?
                                            <GenerateCustomerProformaInvoice data={props.data} tab={1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Customer Proforma invoice is yet to be generated. Please contact the finance team.</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel> */}
                                {/* <TabPanel>
                                    
                                    {(localStorage.getItem('Generate_Customer_Invoice') == 3 || localStorage.getItem('Generate_Customer_Invoice') == 1) ?
                                        customerInvoice &&
                                            props.data.customerInvoiceStatus == 2 ?
                                                <GenerateCustomerInvoice data={props.data} tab={1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Customer invoice is yet to be generated. Please contact the finance team.</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>  
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel> */}
                                <TabPanel>
                                    {(localStorage.getItem('Generate_Supplier_PO') == 3 || localStorage.getItem('Generate_Supplier_PO') == 1) ?
                                        supplierPO &&
                                            props.data.supplierPOStatus == 2 ?
                                            <GenerateSupplierProformaInvoice data={props.data} tab={1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Supplier Purchase Order is yet to be generated. Please contact the finance team.</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    {(localStorage.getItem('Update_Supplier_Proforma_Invoice') == 3 || localStorage.getItem('Update_Supplier_Proforma_Invoice') == 1) ?
                                        supplierproinv &&
                                        <UpdateSupplierProformaInvoice tradeData={tradeData} data={props.data} tab={1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    {(localStorage.getItem('Update_Supplier_Invoice') == 3 || localStorage.getItem('Update_Supplier_Invoice') == 1) ?
                                        supplierinv &&
                                        <SupplierInvoice data={props.data} tradeData={tradeData} tab={1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Update_Vessel_Information') == 3 || localStorage.getItem('Update_Vessel_Information') == 1) ?
                                        <VesselInformation tradeDataForFreight={props.data} tab={localStorage.getItem('Update_Vessel_Information') == 1 ? 2 : 1} tradeID={props.data.tradeID} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    {(localStorage.getItem('L/C_Documents') == 3 || localStorage.getItem('L/C_Documents') == 1) ?
                                        <>
                                            {lcDoc == 1 &&
                                                <LCDocuments tradeData={tradeDataLcDoc}
                                                    tab={1} tradeID={props.data}
                                                    lcDocumentDataSupplier={lcDocumentDataSupplier}
                                                    lcDocumentData={lcDocumentData} refreshList={refreshList}
                                                />
                                            }
                                            {lcDoc == 2 &&
                                                <LCDocuments tradeData={tradeDataLcDoc}
                                                    tab={1} tradeID={props.data}
                                                    lcDocumentData={lcDocumentData} refreshList={refreshList}
                                                />
                                            }
                                            {lcDoc == 3 &&
                                                <LCDocuments tradeData={tradeDataLcDoc}
                                                    tab={1}
                                                    tradeID={props.data}
                                                    lcDocumentDataSupplier={lcDocumentDataSupplier} refreshList={refreshList}
                                                />
                                            }
                                            {lcDoc == 4 &&
                                                <LCDocuments tradeData={tradeDataLcDoc}
                                                    tab={1} tradeID={props.data} refreshList={refreshList}

                                                />
                                            }
                                        </>
                                        :

                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>

                                    }
                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Update_Insurance_Documents') == 3 || localStorage.getItem('Update_Insurance_Documents') == 1) ?
                                        <>
                                            {suppIns == 1 &&
                                                <UpdateInsuranceDetails tradeData={tradeData} tab={1} tradeID={props.data} updateInsuranceDetails={insurance} refreshList={refreshList} />
                                            }
                                            {suppIns == 2 &&
                                                <UpdateInsuranceDetails tradeData={tradeData} tab={1} tradeID={props.data} refreshList={refreshList} />
                                            }
                                        </>
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    {(localStorage.getItem('Pre_shipment_Updates') == 3 || localStorage.getItem('Pre_shipment_Updates') == 1) ?
                                        <PreShipment tradeData={props.data} tab={1} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Post_vessel_fixture_Updates') == 3 || localStorage.getItem('Post_vessel_fixture_Updates') == 1) ?
                                        <Postvesselfixture data={props.data} tab={1} view={false} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Shipment_ETA') == 3 || localStorage.getItem('Shipment_ETA') == 1) ?
                                        shipmentETA &&
                                        <ShipmentETA tradeData={tradeData} tab={1} tradeID={props.data} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) ?
                                        <CompleteLoading tradeData={props.data} tab={1} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>

                                    {(localStorage.getItem('Loading_Port_Updates') == 3 || localStorage.getItem('Loading_Port_Updates') == 1) ?
                                        <>
                                            {loading == 1 &&
                                                <LoadingUpdates tradeData={tradeData} tab={1} tradeID={props.data} updateLoadingUpdatesData={loadingdata} refreshList={refreshList} />
                                            }
                                            {loading == 2 &&
                                                <LoadingUpdates tradeData={tradeData} tab={1} tradeID={props.data} refreshList={refreshList} />
                                            }
                                        </>
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>

                                    }

                                </TabPanel>
                                <TabPanel>
                                    {(localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) ?
                                        <DischargePortUpdates tradeData={props.data} tab={1} refreshList={refreshList} />
                                        :
                                        <div className='text-center pt-100'>
                                            <div>
                                                <img src='assets/images/locked_screen.svg' />
                                            </div>
                                            <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                        </div>
                                    }
                                </TabPanel>
                            </Col>
                            {/* <Col md={2}></Col> */}
                        </Tabs>
                    </div>
                </ModalBody>
            </Modal>
            <tr className='border-bottom-1 '>
                <td>
                    <div className='pt-2'>
                        <h4 className='tradeTrsactionsHeading'>{props.data.tradeNo}</h4>
                        <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'> {props.data.vesselName} <br />  {Moment(props.data.tradeinitiatdate).format("DD/MM/yyyy")} </h4>

                    </div>
                </td>
                <td>
                    {props.data.tradescenarioID == 2 ?
                        <>
                            {props.data.compcustdetails.map((pt, k) => {
                                return (
                                    <>
                                        <div className='pt-2'>
                                            <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{pt.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                        :
                        <>
                            <div className='pt-2'>
                                <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{props.data.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                            </div>
                        </>
                    }
                </td>

                <td className='w-16'>
                    <div className='d-flex flex-column pt-2'>
                    {(props.data.tradescenarioID != null && props.data.tradescenarioID == 3) ?
                        <>
                            {props.data.compcustdetails != null &&
                                props.data.compcustdetails.map((pt, k) => {
                                    return (
                                        <>
                                            <div className='pb-2'>
                                                <div className='align-items-center '>
                                                    <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                    <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {(props.data.compcustdetails != null && props.data.compcustdetails.length>0) &&
                                <div className='pb-2'>
                                    <div className='align-items-center '>
                                        <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.compcustdetails[0].customername}</h5>
                                        <span className='tradeTrsactionsid statBg'>{props.data.compcustdetails[0].customercode}</span>
                                    </div>
                                </div>
                            }

                        </>
                    }

                        <div className='py-2'>
                            <div className='align-items-center '>
                                <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.supplierName}</h5>
                                <span className='tradeTrsactionsid statBg' >{props.data.supplierCode}</span>
                            </div>
                            <div>
                                <h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                    {props.data.supplierpaymentmethods.map((pt, k) => {
                                        return (
                                            <>{pt}<span className='mx-1 dotSep'>&#8226;</span></>
                                        )
                                    })}
                                </h4>
                            </div>
                        </div>


                    </div>
                </td>
                <td>
                    <div className=' pt-2'>
                        {props.data.tradeProductDetails.map((list, k) => {
                            return (
                                <>
                                    <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                        {" "}
                                        {list.productName}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                            {list.quantity}&nbsp;{" "}
                                            {list.productUOMName} <span className='mx-1 dotSep'>&#8226;</span>
                                            {props.data.contracttype} <span className='mx-1 dotSep'>&#8226;</span>
                                            {props.data.incotermsName} <span className='mx-1 dotSep'>&#8226;</span>
                                            {props.data.modeOfTransportName}
                                        </div>
                                        {/*<div className='colorTerms font-12 font-weight-500 ms-3 d-flex align-items-center'> */}
                                        {/*    {props.data.contypewithincoterms} <span className='ms-2 dotSep'>&#8226;</span>*/}
                                        {/*</div>*/}
                                    </div>


                                </>
                            );
                        })}
                    </div>
                    <div className='d-flex py-2'>
                        <div>
                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                Load Port
                            </div>
                            <div className='tradeTrsactionsHeading '>
                                {props.data.loadPort}, {props.data.lordPortCountry}
                            </div>
                        </div>
                        <div className='ml-20'>
                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                Destination Port
                            </div>
                            <div className='tradeTrsactionsHeading'>
                                {props.data.destinationPort}, {props.data.destinationPortcountry}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    {props.data.is_active != null && props.data.is_active ?
                        <Steps direction="vertical" initial={1} current={1}>
                            {(props.data.supplierProfomaInvoiceStatus == 2 /*&& props.data.customerProfomaInvoiceStatus == 2
                            && props.data.customerInvoiceStatus == 2*/ && props.data.supplierPOStatus == 2 && props.data.supplierInvoiceStatus == 2 &&
                                props.data.updateVesselInformationStatus == 2 && props.data.lcDocumentStatus == 2 && props.data.insuranceDocumentStatus == 2 &&
                                props.data.loadingUpdateStatus == 2 && props.data.shipmentETAStatus == 2 && props.data.preshipmentChecklistStatus == 2 &&
                                props.data.postVesselFixtureStatus == 2 && props.data.completionOfLoadingStatus == 2 && props.data.dischargePortStatus == 2) ?
                                <></>
                                :
                                <Step title={localStorage.getItem('Pre_Closure_Updates') == 3 ?
                                    <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Invoices and PO</div>
                                    :
                                    <div className='d-flex justify-content-center my-2 pointer'>Invoices and PO</div>}
                                    // subTitle='Cost Sheet Completed Date'
                                    description=""
                                    icon={props.data.supplierProfomaInvoiceStatus == 2 && /*props.data.customerProfomaInvoiceStatus == 2
                                    && props.data.customerInvoiceStatus == 2 &&*/ props.data.supplierPOStatus == 2 && props.data.supplierInvoiceStatus == 2
                                        ?
                                        <img src='assets/images/ellipse_check.svg' />
                                        :
                                        <img src='assets/images/ellipse_pending.svg' />
                                    } />
                            }

                            <Step title={localStorage.getItem('Actual_Cost_Sheet') == 3 ?
                                <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Shipment Updates</div>
                                :
                                <div className='d-flex justify-content-center my-2 pointer'>Shipment Updates</div>}
                                description=""
                                icon={props.data.updateVesselInformationStatus == 2 && props.data.lcDocumentStatus == 2 && props.data.insuranceDocumentStatus == 2 &&
                                    props.data.loadingUpdateStatus == 2 && props.data.shipmentETAStatus == 2 && props.data.preshipmentChecklistStatus == 2 &&
                                    props.data.postVesselFixtureStatus == 2 && props.data.completionOfLoadingStatus == 2 && props.data.dischargePortStatus == 2
                                    ?
                                    <img src='assets/images/ellipse_check.svg' />
                                    :
                                    <img src='assets/images/ellipse_pending.svg' />
                                } />
                            {props.data.supplierProfomaInvoiceStatus == 2 && props.data.supplierPOStatus == 2 && props.data.supplierInvoiceStatus == 2 &&
                                props.data.updateVesselInformationStatus == 2 && props.data.lcDocumentStatus == 2 && props.data.insuranceDocumentStatus == 2 &&
                                props.data.loadingUpdateStatus == 2 && props.data.shipmentETAStatus == 2 && props.data.preshipmentChecklistStatus == 2 &&
                                props.data.postVesselFixtureStatus == 2 && props.data.completionOfLoadingStatus == 2 && props.data.dischargePortStatus == 2 &&

                                <Step title={
                                    <Button variant="primary" style={{ width: props.width }} id={props.data.TradeID} onClick={markshipmentcompleted} className={'font-weight-500 font-14 rounded-13 '}>
                                        Mark Finalized
                                    </Button>
                                }
                                    description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                            }
                        </Steps>
                        :
                        <Steps direction="vertical" initial={-1}  >
                            <Step title={
                                <div className='d-flex justify-content-center my-2'>Cancelled Trade</div>
                            } description="" icon={<img src='assets/images/rejected-icon.svg' />} />
                        </Steps>
                    }
                </td>

                <td>

                    {ismeeting &&
                        <LinkMeetings data={props.data} tag='Trade' toggle={openMeeting} />
                    }
                    {props.data.is_active &&
                        <Button variant='outline-secondary' onClick={openMeeting}>
                            <img src='assets/images/linkicon.svg' />
                        </Button>
                    }
                </td>
                <td>
                    {props.data.is_active != null && props.data.is_active &&
                        <>
                            <Dropdown className='br-8 py-2' overlay={menu}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <img src='/assets/images/Dot_icon.svg' />
                                    </Space>
                                </a>
                            </Dropdown>
                            <div className='pointer btn-link text-decoration-none pt-2-rem' onClick={processtoggle}><a className='border-bottom-primary d-flex justify-content-center'>More Actions</a></div>
                        </>
                    }
                </td>
            </tr>
        </>
    )
}