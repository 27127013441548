import React from 'react'
import { Dropdown, Menu, Space } from 'antd';
import Moment from "moment"
import { Button } from "react-bootstrap"
import * as CONFIG from "../../helpers/config";
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import { getListItemAvatarUtilityClass } from '@mui/material';

export default function List(props) {
    const editBroadCast = () => {
        props.editBroadcast(props.data.broadcastMessageID)
    }

    const deleteBroadCast = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "modified_User": CONFIG.LoginID,
            "ID": props.data.broadcastMessageID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.DELETE_BROADCAST_MESSAGE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.getlist()
            }).catch((error) => {
                
            })
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={editBroadCast}>Edit</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={deleteBroadCast}>Delete</span></>
                    ),
                }
            ]}
        />
    );
    return (
        <tr>
            <td>{parseInt(props.k) + 1}</td>
            <td>{props.data.broadcastMessageText}</td>
            <td>{Moment.utc(props.data.startDate).local().format("DD/MM/yyyy hh:mm")}</td>
            <td>{Moment.utc(props.data.endDate).local().format("DD/MM/yyyy hh:mm")}</td>
            <td>
                <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <img src='/assets/images/Dot_icon.svg' />
                        </Space>
                    </a>
                </Dropdown>
            </td>
        </tr>
    )
}
