import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import Moment from "moment"
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../formcomponents"
import * as ButtonComponent from "../../../buttoncomponents"
import * as CONFIG from "../../../../helpers/config"
import { CountryList, StateList, AccountType} from "../../../../helpers/API/Api"
import { useMsal } from "@azure/msal-react";
export const Currency = (props) => {
    return(
        <>
            <option value={2} selected={props.CurrencyID == 1}>USD</option>
        </>
    )
}


function DepartmentModal(props) {
    const { instance } = useMsal();
    console.log(props)
    const [error, setError] = useState(true)
    const [modal, setModal] = useState(true);
    const [cntID, setCntID] = useState(0);
    const [countryerror, setCountryError] = useState(false)
    const [stateID, setStateID] = useState(0);
    const [stateerror, setStateError] = useState(false)
    const [isCountryChanged, setIsCountryChanged] = useState(false);

    useEffect(()=>{
        if(props.edit == true){
            setError(false)
        }
    },[])
    
    const formik = useFormik({
        initialValues:
        {
           departmentName: props.edit == true ? props.selectedDepartment.departmentName : '',
           departmentID: props.edit == true ? props.selectedDepartment.departmentID : ''
        },
        validate: values => {
            if (!values.departmentName) {
                setError(true)
            }
            else{
                setError(false)
            }
        }
    })

    const toggle = () => {
        setModal(!modal)
        props.closeDepartment();
    }

    const handleCurrency = (e)=>{
        formik.values.CurrencyID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.currencyValue = e.nativeEvent.target[index].text;
    }

    const onCountrySelect = (e) => {
        setIsCountryChanged(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntID(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryError(false)
            setTimeout(() => {
                setIsCountryChanged(true)
            }, 800);
        }
        else {
            setCountryError(true)
        }
        generalcountrySelected(value)
    }

    const onAccountTypeSelect = (e) => {
        formik.values.AccountTypeID = parseInt(e.currentTarget.value)
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.AccountTypeName = e.nativeEvent.target[index].text;
    }

    const onStateChange = (e) => {
        const value = e.currentTarget.value;
        if (value != 0) {
            setStateID(value)
            formik.values.bankStateID = parseInt(value)
            setStateError(false)
        }
        else {
            setStateError(true)
        }
        generalstateSelected(value)
    }

    const generalstateSelected = (value) => {
        if (value != 0) {
            formik.values.bankStateID = parseInt(value);
        }
    }
    const generalcountrySelected = (value) => {
        if (value != 0) {
            formik.values.bankCountryID = parseInt(value)   
        }
    }
    const handleSaveEvent = () => {
        //formik.values.CurrencyID = 1
        console.log(formik.values)
        if(props.edit == true){
            let final_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "departmentID": formik.values.departmentID,
                "departmentName": formik.values.departmentName,
             
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_DEPARTMENT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                props.handleSaveDepartment()
                // props.handleDepartmentList(formik.values)
                toggle()
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })   
        }
        else{
            let final_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "departmentName": formik.values.departmentName,
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_DEPARTMENTS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                props.reloadDepartment()
                // props.handleDepartmentList(formik.values)
                toggle()
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        } 
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className = 'bank-account-modal'>
        <ModalHeader toggle={toggle} className='p-40'>
            <div className = 'd-flex justify-content-between '>
                <div className='font-16 font-weight-600 color-707895'>
                    {props.edit == true ? "Update Department Details" : "Add Department Details"}
                </div>
            </div>
        </ModalHeader>
        <ModalBody  className = '' style={{backgroundColor:'#f9f9ff'}}>
        <div className=''>
              <div className=''>
                    <Card className = 'my-3'>
                    <CardBody>
                            <Form >
                                <Row>
                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                    Department Details
                                </div>
                                    <>
                                    <FormFields.InputField
                                        md={6}
                                        name='departmentName'
                                        label='Department Name'
                                        classname='pt-1-rem'
                                        type='text'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        val={formik.values.departmentName}
                                        errors={formik.touched.departmentName ? formik.errors.departmentName : ''}
                                        //isabled={props.isDisabled}
                                    />
                                   </>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
              </div>
          </div>
        </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>
            </ModalFooter>
        </Modal>
    )
}

export default DepartmentModal