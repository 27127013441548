import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import NumberFormat from 'react-number-format';
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import * as CONFIG from '../../../helpers/config'
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import DownloadSupplierPayment from './Payment Components/DownloadSupplierPayment';
import { useMsal } from "@azure/msal-react";

function SupplierPayment(props) {
    const { instance } = useMsal();
    const [corDocumentName, setCorDocumentName] = useState('')
    const [quantityInspectionAgencyDocumentName, setQuantityInspectionAgencyDocumentName] = useState('')
    const [qualityInspectionAgencyDocumentName, setQualityInspectionAgencyDocumentName] = useState('')
    const [billOfLadingDate, setBillOfLadingDate] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [error, setError] = useState(false)
    const [paymentInvoicesList, setPaymentInvoicesList] = useState([])
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [country, setCountry] = useState()
    const [totalList, setTotalList] = useState()
    const [paymentSupplier, setPaymentSupplier] = useState(false)
    const [particularPayment, setParticularPayment] = useState()
    const [count, setCount] = useState(0)

    //const location = useLocation()
    let navigate = useNavigate();
    const  tradeData  = props.tradeData
    //console.log(location)
    const tab = props.tab
    console.log(props)
    useEffect(()=>{
        let listValues = []
        let listValuesTax = []
        if(tradeData != null && tradeData.tradedetails != null){
            tradeData.tradedetails.tradeProductDetail.map((list, k) => (
                listValues.push(parseInt((list.productBuyingPrice) * (list.quantity)))
                // listValues.push(parseInt((list.productBuyingPrice) * (list.quantity)))
               ))
               console.log(listValues)
               let sum = 0
               let sumTax = parseInt((tradeData.tradedetails.tradeEnquiry.tax1)) + parseInt((tradeData.tradedetails.tradeEnquiry.tax2))
               for (let i = 0; i < listValues.length; i++) {
                sum += listValues[i]
            }

            console.log(sum)
            setSubTotal(sum)
            setTotalTax(sumTax)
            setOverAllTotal(sum + sumTax)
        }
    },[])

    useEffect(()=>{
       
            axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COUNTRY_LIST, {
                "pmsinstanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "id": 0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY }  })
            .then((res) => {
                for(let i = 0; i < res.data.result.length; i++){
                    if(tradeData != null && tradeData.tradedetails != null){
                        if(res.data.result[i].countryId == tradeData.tradedetails.supplier.registeredCountryID){
                            console.log('success')
                            setCountry(res.data.result[i].countryName)
                        }
                    }
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    },[])

    useEffect(()=>{
        getSupplierPaymentUpdate()
    },[])

    const getSupplierPaymentUpdate = () => {
        let totalListSum = []
        let sum = 0
        if(tradeData != null && tradeData.tradeID != null ){
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": tradeData != null && tradeData.tradeID != null ? tradeData.tradeID : '' ,
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_PAYMENT_UPDATE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if(res.data.result.length > 0 && res.data.result.length == 1){
                    setIsAdd(false)
                    for(let i = 0; i < res.data.result.length; i++){
                        totalListSum.push(res.data.result[i].supplierPaymentAmount)
                        res.data.result[i].supplierPaymentAmount = parseInt(parseInt((tradeData.tradedetails.tradeProductDetail[0].productBuyingPrice) * (tradeData.tradedetails.tradeProductDetail[0].quantity)))
                        res.data.result[i].supplierPaymentPer = parseInt(100)
                        
                    }
                    for(let i = 0; i < totalListSum.length; i++){
                        sum += totalListSum[i];
                    }
                    setTotalList(sum)
                    
                    setPaymentInvoicesList(res.data.result)
                }
                else if(res.data.result.length > 0 && res.data.result.length > 1){
                    setIsAdd(false)
                    for(let i = 0; i < res.data.result.length; i++){
                        totalListSum.push(res.data.result[i].supplierPaymentAmount)
                    }
                    for(let i = 0; i < totalListSum.length; i++){
                        sum += totalListSum[i];
                    }
                    setTotalList(sum)
                    
                    setPaymentInvoicesList(res.data.result)
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    
       
    }
    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData != null ? tradeData.tradeID : '',
            supplierPaymentTypeID: isAdd == true ? '' : '' ,
            supplierPaymentAmount: isAdd == true ? '' : '',
            supplierPaymentPer : isAdd == true ? '' : '',
            typeName: isAdd == true ? '' : ''
        },
    })
    // useEffect(()=>{
    //     if(isFirstLoad){
    //       if(props.directorList.length>0){
    //         setIsAdd(false)
    //       }
    //     }
    //     setIsFirstLoad(false)
    //     setIsPrimarySelected(false)
    //       props.directorList.map((d)=>{
    //           if(d.is_Default){
    //               setIsPrimarySelected(true)
    //               console.log(is_DefaultSelected,'inside list contract')
    //           }
    //       })
    //   })
//     const updateLoadingUpdatesData = location.state.updateLoadingUpdatesData != null ? location.state.updateLoadingUpdatesData : ''
//    useEffect(()=>{
//     console.log(updateLoadingUpdatesData)
//     if(updateLoadingUpdatesData != ''){
//         setCorDocumentName(updateLoadingUpdatesData.corDocumentName)
//         setQuantityInspectionAgencyDocumentName(updateLoadingUpdatesData.quantityInspectionAgencyDocumentName)
//         setQualityInspectionAgencyDocumentName(updateLoadingUpdatesData.qualityInspectionAgencyDocumentName)
//     }
//    },[])
//     const formik = useFormik({
//         initialValues:
//         {

//             tradeID: tradeData.tradeID,
//             billOfLadingNo: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.billOfLadingNo : '',
//             billOfLadingDate: updateLoadingUpdatesData != '' ? Moment(updateLoadingUpdatesData.billOfLadingDate) : '',
//             corDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.corDocument : "",
//             corDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.corDocumentName : "",
//             quantityInspectionAgencyDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.quantityInspectionAgencyDocument :  "",
//             quantityInspectionAgencyDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.quantityInspectionAgencyDocumentName :  "",
//             qualityInspectionAgencyDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.qualityInspectionAgencyDocument :  "",
//             qualityInspectionAgencyDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.qualityInspectionAgencyDocumentName :   "",

//         },
//     })
//     const handleCertificateOfOrigin = (file) => {
//         formik.values.corDocument = file.base64;
//         formik.values.corDocumentName = file.name;
//         //formik.values.incDocument = file.base64.name;
//         console.log('file.base64')
//         setCorDocumentName(file.name)
//         console.log(file.name)
//     }

//     const handleQuantityInspectionAgencyDocument = (file) => {
//         formik.values.quantityInspectionAgencyDocument = file.base64;
//         formik.values.quantityInspectionAgencyDocumentName = file.name;
//         //formik.values.incDocument = file.base64.name;
//         console.log('file.base64')
//         setQuantityInspectionAgencyDocumentName(file.name)
//         console.log(file.name)
//     }

//     const handleQualityInspectionAgencyDocument = (file) => {
//         formik.values.qualityInspectionAgencyDocument = file.base64;
//         formik.values.qualityInspectionAgencyDocumentName = file.name;
//         //formik.values.incDocument = file.base64.name;
//         console.log('file.base64')
//         setQualityInspectionAgencyDocumentName(file.name)
//         console.log(file.name)
//     }

//     const handleBankSelect = () => {

//     }

//     const handleIssueDate = (date) => {
//         console.log('click')
//         setBillOfLadingDate(date)
//         formik.values.billOfLadingDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
//     }

    const handleSaveEvent = () => {
        // if(updateLoadingUpdatesData != ''){
        //     const json_list = {
        //         "tradeID": formik.values.tradeID,
        //         "billOfLadingNo": formik.values.billOfLadingNo,
        //         "billOfLadingDate": formik.values.billOfLadingDate ,
        //         "corDocument": formik.values.corDocument,
        //         "corDocumentName": formik.values.corDocumentName,
        //         "quantityInspectionAgencyDocument": formik.values.quantityInspectionAgencyDocument,
        //         "quantityInspectionAgencyDocumentName": formik.values.quantityInspectionAgencyDocumentName,
        //         "qualityInspectionAgencyDocument": formik.values.qualityInspectionAgencyDocument,
        //         "qualityInspectionAgencyDocumentName": formik.values.qualityInspectionAgencyDocumentName,
        //         "instanceID": CONFIG.PMSInstanceID,
        //         "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //         "created_User": CONFIG.LoginID,
        //         "tradeLoadingUpdateID" : updateLoadingUpdatesData.tradeLoadingUpdateID
        //     }
        //     console.log(json_list)
        //     axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_LOADING_UPDATES, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //         .then((res) => {
        //             back()
        //         })
        // }
        // else{
        // const json_list = {
        //     "tradeID": formik.values.tradeID,
        //     "billOfLadingNo": formik.values.billOfLadingNo,
        //     "billOfLadingDate": formik.values.billOfLadingDate ,
        //     "corDocument": formik.values.corDocument,
        //     "corDocumentName": formik.values.corDocumentName,
        //     "quantityInspectionAgencyDocument": formik.values.quantityInspectionAgencyDocument,
        //     "quantityInspectionAgencyDocumentName": formik.values.quantityInspectionAgencyDocumentName,
        //     "qualityInspectionAgencyDocument": formik.values.qualityInspectionAgencyDocument,
        //     "qualityInspectionAgencyDocumentName": formik.values.qualityInspectionAgencyDocumentName,
        //     "instanceID": CONFIG.PMSInstanceID,
        //     "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //     "created_User": CONFIG.LoginID,
        // }
        // console.log(json_list)
        // axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_LOADING_UPDATES, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //     .then((res) => {
        //         back()
        //     })
        // }
    }

    const handleAddPayment = () => {
        formik.values.supplierPaymentAmount = ''
        formik.values.supplierPaymentPer = ''
        setIsAdd(true)
        // axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COUNTRY_LIST, {
        //     "instanceID": CONFIG.PMSInstanceID,
        //     "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //     "created_User": CONFIG.LoginID,
        //     "id": tradeData.tradeID,
        //     "paymentTypeID" : formik.values.supplierPaymentTypeID
        
        // }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY }  })
        // .then((res) => {
        //     console.log(res.data.result)
        // })
    }


    const handleSupplierPaymentSave = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID,
            "paymentTypeID" : parseInt(formik.values.supplierPaymentTypeID)
        }
        console.log(json_list)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_SUPPLIER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            getSupplierPaymentUpdate()
            setIsAdd(!isAdd)
            props.refreshList()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  

    }
    const back = () => {
        setPaymentSupplier(false)
    }

    const handlePrinSupplierPayment = (e, data) => {
        setParticularPayment(data)
        setPaymentSupplier(true)
    }

    const handlePaymentMethod = (e) => {
        console.log(e.target.value)
        console.log(props.tradeData.tradedetails.tradeSupplierPaymentDetail.length)
        if(props.tradeData.tradedetails.tradeSupplierPaymentDetail.length > 1 ){
            for(let i = 0; i < props.tradeData.tradedetails.tradeSupplierPaymentDetail.length; i++){
                console.log('inside for')
                if(parseInt(props.tradeData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentTypeID) == parseInt(e.target.value)){
                    console.log('inside if')
                    formik.values.supplierPaymentTypeID = parseInt(e.target.value)
                    formik.values.supplierPaymentAmount = props.tradeData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentAmount
                    formik.values.supplierPaymentPer = props.tradeData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentPer
                    console.log(formik.values)
                    //Notupdating check
                    var counter = parseInt(count)
                    counter = counter + 1
                    setCount(counter)
                }
            }
        }
        else if(props.tradeData.tradedetails.tradeSupplierPaymentDetail.length == 1){
            formik.values.supplierPaymentTypeID = parseInt(e.target.value)
            formik.values.supplierPaymentAmount = parseInt(parseInt((tradeData.tradedetails.tradeProductDetail[0].productBuyingPrice) * (tradeData.tradedetails.tradeProductDetail[0].quantity)))
            formik.values.supplierPaymentPer = parseInt(100)
            props.tradeData.tradedetails.tradeSupplierPaymentDetail[0].supplierPaymentAmount = formik.values.supplierPaymentAmount
            props.tradeData.tradedetails.tradeSupplierPaymentDetail[0].supplierPaymentPer = formik.values.supplierPaymentPer
            setPaymentInvoicesList(props.tradeData.tradedetails.tradeSupplierPaymentDetail)
            var counter = parseInt(count)
            counter = counter + 1
            setCount(counter)
        }
    }

    useEffect(()=>{

    },[formik.values, count])
    return (
        <div className='main-wrapper inner-wrapper d-flex mt-5'>
       
      
        <div className='width-100'>

  
              <div className='d-flex inner-page-content justify-content-between'>
                  <div className='d-flex'>
                      <img src='assets/images/sidebaricons/Trading-Sales-Purchase.svg' className='img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Supplier Payments</span>
                  </div>
              </div>
              <div className='d-flex'>
                  <div className='add-edit-wrapper mt-4'>
                    {paymentSupplier == true ? 
                    <DownloadSupplierPayment  tradeData = {props.tradeData} paymentData={particularPayment} back = {back} country = {country} /> 
                    :     
                    <>
                        <Card className='bg-white'>
                                <Card.Body>
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Invoice Details
                                    </div>
                                    <div className = "m-3 p-4 grey-color">
                                        {tradeData != null ? 
                                        <>
                                                <div className = 'd-flex align-items-center justify-content-between'>
                                                    <div className = 'd-flex flex-column'>
                                                        <div className = 'font-weight-600 color-243448'>{tradeData.tradedetails.supplier.supplierCode}</div>
                                                        <div className = 'font-weight-400 color-697382'>{tradeData.tradedetails.supplier.supplierName}</div>
                                                    </div>
                                                    <div className = 'd-flex flex-column'>
                                                        <div className = 'font-weight-400 color-697382'>Address</div>
                                                        <div className = 'font-weight-600 color-243448'>
                                                            {tradeData.tradedetails.supplier.registeredUnitNo}, &nbsp;
                                                            {tradeData.tradedetails.supplier.registeredBuildingName}, &nbsp;
                                                            {tradeData.tradedetails.supplier.registeredStreetName}, &nbsp;
                                                            {tradeData.tradedetails.supplier.registeredPostBox}, &nbsp;
                                                            {tradeData.tradedetails.supplier.registeredCityName}, &nbsp;
                                                            {country}
                                                        </div>
                                                    </div>
                                                    <div className = 'd-flex flex-column'>
                                                        <div className = 'font-weight-400 color-697382'>Invoice Date</div>
                                                        <div className = 'font-weight-600 color-243448'>{Moment().format('DD/MM/YYYY')}</div>
                                                    </div>
                                                    <div className = 'd-flex flex-column'>
                                                        <div className = 'font-weight-400 color-697382'>Invoice Amount</div>
                                                        <div className = 'font-weight-600 color-243448'>$<NumberFormat value = {overAllTotal} displayType={'text'} thousandSeparator={true} /></div>
                                                    </div>
                                                </div>
                                                <hr />
                                                </>
                                        : 
                                        ""
                                        }
                                    </div>
                                </Card.Body>
                        </Card>
                        <Card className = 'my-3'>
                            <Card.Body>
                                <Row className='d-flex' style={{background: '#fff'}}>
                                {!isAdd?
                                <>
                                <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between align-items-center'>
                                        <div>
                                            Supplier Payment
                                        </div>
                                        <div>
                                            <ButtonComponent.AddNewButton value='Add New Payment' clickEvent={handleAddPayment}/>
                                        </div>
                                  
                                </div>
                                    
                                    <div className=''>
                                            <table className="table table-text mt-1-rem">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                    <th scope='col' className=' font-weight-500'>Reciept No.</th>
                                                    <th scope='col' className=' font-weight-500'>Mode Of Payment</th>
                                                    <th scope='col' className=' font-weight-500'>Percentage %</th>
                                                    <th scope='col' className=' font-weight-500'></th>
                                                    <th scope='col' className=' font-weight-500 align-right'>Amount</th>
                                                    <th></th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentInvoicesList != null && paymentInvoicesList.length > 0 ?
                                                 paymentInvoicesList.map((values)=>(
                                                    <tr className='align-middle'>
                                                       <td>{values.recieptNo}</td>
                                                       <td>{values.paymentType.typeName}</td>
                                                       <td>{values.supplierPaymentPer}</td>
                                                       <td></td>
                                                       <td className = 'align-right'>{values.supplierPaymentAmount != null ? values.supplierPaymentAmount : 0}</td>
                                                       <td>
                                                            <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600" id={values.tradeId}  onClick={(e)=>{handlePrinSupplierPayment(e, values)}}>Print</button>
                                                        </td>
                                                    </tr>
                                                   ))
                                                   :
                                                   ""
                                                }
                                                <tr className = 'border-0'>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="table-secondary border border-secondary">TOTAL: </td>
                                                    <td className="table-secondary border border-secondary">$<NumberFormat value = {overAllTotal} displayType={'text'} thousandSeparator={true}/></td>
                                                    <td className="table-secondary border border-secondary"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Supplier Payment
                                    </div>
                                    <Row>
                                       
                                        <FormFields.InputSelect
                                        md={3}
                                        name='supplierPaymentTypeID'
                                        label='Mode of Payment*'
                                        classname='pt-1-rem'
                                        type='text'
                                        optionField={tradeData != null && tradeData.tradedetails != null ? tradeData.tradedetails.tradeSupplierPaymentDetail.map((values, k)=>(
                                            <option key={k} 
                                                value={values.supplierPaymentTypeID} 
                                            >
                                                {values.paymentType.typeName}
                                            </option>
                                        )):''}
                                        val={formik.values.supplierPaymentTypeID}
                                        errors={formik.touched.supplierPaymentTypeID ? formik.errors.supplierPaymentTypeID : ''}
                                        onChange={handlePaymentMethod}
                                        onBlur={handlePaymentMethod}

                                        />
                                        
                                         <FormFields.InputField
                                            md={3}
                                            name='supplierPaymentPer'
                                            label='Percentage %*'
                                            classname='pt-1-rem'
                                            type='text'
                                            val={formik.values.supplierPaymentPer}
                                            errors={formik.touched.supplierPaymentPer ? formik.errors.supplierPaymentPer : ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={true}
                                        />
                                         <FormFields.InputField
                                            md={3}
                                            name='supplierPaymentAmount'
                                            label='Amount*'
                                            classname='pt-1-rem'
                                            type='text'
                                            val={formik.values.supplierPaymentAmount}
                                            errors={formik.touched.supplierPaymentAmount ? formik.errors.supplierPaymentAmount : ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={true}
                                        />
                                         <Col className='d-flex align-items-center pt-1'>
                                            <ButtonComponent.BlueBorderButton value={'Save'} clickEvent={handleSupplierPaymentSave}/>
                                        </Col>
                                    </Row>
                                    </>
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                         </>
                        }
                        {/* <Card className='bg-white my-3'>
                                <Card.Body>
                                    <div className = "d-flex justify-content-between align-items-center">
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Final B/L
                                        </div>
                                        <div className = 'd-flex align-items-center'>
                                                <FormFields.InputField
                                                    name='billOfLadingNo'
                                                    label='Bill of Lading No.'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.billOfLadingNo}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    
                                                />
                                                <FormFields.DatePick
                                                    label='B/L Date'
                                                    name='B/L Date'
                                                    classname='pt-1-rem ms-2 iconDatePicker'
                                                    type='date'
                                                    handleCompletionDate={handleIssueDate}
                                                    isRequired={true}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    val={formik.values.billOfLadingDate}
                                                />
                                        </div>  
                                    </div>
                                    <hr />
                                    <div className = "d-flex justify-content-between align-items-center">
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Document Of Certificate Of Origin
                                        </div>
                                        <div>
                                            <ButtonComponent.UploadButton value='Document of COR' onUploadHandle={handleCertificateOfOrigin} filename={corDocumentName}/>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className = "d-flex justify-content-between align-items-center">
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Document Of Quality Inspection Agency
                                        </div>
                                        <div>
                                            <ButtonComponent.UploadButton value='Quality Agency' onUploadHandle={handleQualityInspectionAgencyDocument} filename={qualityInspectionAgencyDocumentName}/>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className = "d-flex justify-content-between align-items-center">
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Document Of Quantity Inspection Agency
                                        </div>
                                        <div>
                                            <ButtonComponent.UploadButton  value='Quantity Agency' onUploadHandle={handleQuantityInspectionAgencyDocument} filename={quantityInspectionAgencyDocumentName}/>
                                        </div>
                                    </div>
                                </Card.Body>
                        </Card> */}
                        {/* <Card>
                            <CardBody>
                                <span><ButtonComponent.BlueBorderButton value='Cancel' clickEvent = {back}/></span>
                                <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>  
                            </CardBody>
                        </Card> */}
                        
                  </div>
              </div>
        </div>
        </div>
    )
}

export default SupplierPayment