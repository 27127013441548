import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar';
import * as CONFIG from "../../helpers/config";
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import Moment from "moment"
import List from "./List";
import { BlueBorderButton, BlueButton, CancelButton } from '../../components/buttoncomponents';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useFormik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import * as FormFields from "../../components/formcomponents"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Swal from 'sweetalert2'
import { Link, NavLink } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content'
import Menus from "../SuperAdmin/menu";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import * as SideBarIcon from "../../components/sidebar/sidebardIcons";

export default function Index() {
    const { instance } = useMsal();
    const [broadcastlist, setbroadcastlist] = useState([])
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [endDate, setEndDate] = useState(new Date())
    const MySwal = withReactContent(Swal)

    const [startDate, setstartDate] = useState(new Date())
    const [doc, setdoc] = useState(false)
    const [edit, setEdit] = useState(false)
    const [selected, setselected] = useState([])


    useEffect(() => {
        if (localStorage.getItem("SuperAdminToken") != null) {
            getlist()
        } else {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/superadminlogin",
          });
        }
    }, []);
    

    const formik = useFormik({
        initialValues: {
            Message: '',
            startDate: false,
            endDate: false,

        }
    })

    const getlist = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_BROADCAST_MESSAGES, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setbroadcastlist(res.data.result)
            })
    }

    const saveData = () => {
        console.log(Moment.utc(startDate).local().format())
        console.log(Moment.utc(endDate).local().format())
        const json_list = {
            "BroadcastMessageText": formik.values.Message,
            "StartDate": new Date(startDate),
            "EndDate": new Date(endDate),
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_BROADCAST_MESSAGE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                openAdd()
                MySwal.fire(
                    {
                        icon: 'success',
                        html: 'Broadcast message added successfully',
                        type: 'success',
                        confirmButtonText: 'Close',
                        confirmButtonClass: "btn btn-primary",

                    }).then(() => {
                        getlist()
                    });
            })
    }
    
    const openAdd = () => {
        setIsOpenAdd(!isOpenAdd)
        formik.values.Message = ''
        setstartDate(new Date())
        setEndDate(new Date())
        setdoc(!doc)
    }

    const editBroadcast = (id)=>{
        let filter = broadcastlist.filter(x=> x.broadcastMessageID == parseInt(id))[0]
        setselected(filter)
        formik.values.Message = filter.broadcastMessageText
        setstartDate(Moment.utc(filter.startDate).local().format())
        setEndDate(Moment.utc(filter.endDate).local().format())
        setdoc(!doc)
        setIsOpenAdd(true)
        setEdit(true)
    }

    const updateBroadCast = ()=>{
        const json_list = {
            "BroadcastMessageText": formik.values.Message,
            "StartDate": new Date(startDate),
            "EndDate": new Date(endDate),
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "broadcastMessageID" : selected.broadcastMessageID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_BROADCAST_MESSAGE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                openAdd()
                setEdit(false)
                MySwal.fire(
                    {
                        icon: 'success',
                        html: 'Broadcast message upated successfully',
                        type: 'success',
                        confirmButtonText: 'Close',
                        confirmButtonClass: "btn btn-primary",

                    }).then(() => {
                        getlist()
                    });
            })
    }

    return (
        <div className="main-wrapper inner-wrapper d-flex">
                    
            <ProSidebar className="sidebar-component desktop_view">
                <SidebarHeader className="mb-2-rem">
                <Link to="/superadmin">
                    <img
                    src="/assets/images/tmslanding/tmslogo.svg"
                    className="img-fluid"
                    />
                </Link>
                </SidebarHeader>
                <SidebarContent>
                <Menu iconShape="square">
                    <MenuItem
                    icon={<SideBarIcon.DashboardIcon />}
                    className="menu-item-active"
                    >
                    <NavLink to="/superadmin">Dashboard</NavLink>
                    </MenuItem>
                </Menu>
                </SidebarContent>
            </ProSidebar>
            <div id="content" className="desktop_view ">
                <nav
                    className="bg-white navbar navbar-expand-md  fixed-top"
                    style={{ zIndex: "999" }}
                >
                    <div className="container-fluid mx-3">
                    <Col md={4} className="ps-3 "></Col>

                    <Col md={8} className="d-flex col-6 justify-content-end">
                        <Col md={1}>
                    
                        <div className='d-flex pe-md-5 pe-5 align-self-center'>
                            <Link to='/broadcast' className='d-flex'>
                                <img className='iconTopbar' src='../assets/images/broadcast.png' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Broadcast</span>
                            </Link>
                        </div>
                        </Col>
                        <Col md={2} className=" userMobile ms-md-5 d-flex">
                        <Menus />
                        </Col>
                    </Col>
                    </div>
                </nav>
                <div>
                </div>
            </div>
            
            <div className='main-wrapper inner-wrapper d-flex'>
                        <div className='width-100'>
                            <div className='d-flex inner-page-header justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <img src='assets/images/broadcast.png' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>BROADCAST</span>
                                </div>
                                <div>
                                    <BlueBorderButton value="Add Broadcast" clickEvent={openAdd} />
                                </div>
                            </div>
                            <Modal isOpen={isOpenAdd} toggle={openAdd} className='mt-10-rem property_info_modal modal_35 mt-5 '>

                                <ModalHeader toggle={openAdd}>
                                    <div className='font-16 font-weight-600 color-707895'>BroadCast Message</div>
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <FormFields.InputField
                                            md={6}
                                            className='pt-1-rem mb-3'
                                            name='Message'
                                            label='Broadcast Message'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            val={formik.values.Message}
                                            errors={formik.touched.Message ? formik.errors.Message : ''}
                                        />
                                    </Row>
                                    <Row className='mt-1-rem'>
                                        <Col md={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    label="DateTimePicker"
                                                    className="form_input"
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setstartDate(newValue);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Col>
                                        <Col md={6}>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    label="DateTimePicker"
                                                    className="form_input"
                                                    value={endDate}
                                                    onChange={(newValue) => {
                                                        setEndDate(newValue);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter className='d-flex justify-content-start'>
                                    <CancelButton value='Cancel' clickEvent={openAdd} />
                                    <BlueButton value="Save" clickEvent={edit?updateBroadCast: saveData} />
                                </ModalFooter>
                            </Modal>
                            <div className="inner-page-content">
                                <div className='add-edit-wrapper'>

                                    <div class="col-lg-12 p-4 card">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>SI.NO</th>
                                                        <th>MESSAGE</th>
                                                        <th>STAR DATE</th>
                                                        <th>END DATE</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {broadcastlist.map((list, k) => {
                                                        return (
                                                            <List data={list} k={k} getlist={getlist} editBroadcast={editBroadcast}/>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
        </div>
    )
}
