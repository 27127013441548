import React, { useEffect,  useState } from 'react'
import Moment from 'moment'
import axios from 'axios'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { useMsal } from "@azure/msal-react";
import styles from './Notifications.module.css'
import * as CONFIG from "../../../../helpers/config"
function Index(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(false);
    const [count, setCount] = useState(0) 
    useEffect(() => {
        console.log(props)
        if( props.notification != null && props.notification.length > 0 ){
            setCount(props.notification.length) 
            console.log(props.notification[0])
            console.log(Moment(props.notification[0].tradeDate).startOf('day').fromNow())
        }
    },[props])
    const clearNotifications = () =>{
        const json_list = {
            "ID": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CLEAR_ALL_NOTIFICATIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            //getAllNotifications()
        }).catch((error)=>{
            // if(error.response.status == 401){
            //     localStorage.clear();
            //     instance.logoutRedirect({
            //       postLogoutRedirectUri: "/",
            //     });
            // }
        })  
    }
    const toggle = () => {
        setModal(!modal)
    }
    return (
    <>
      <Modal isOpen={modal} toggle={toggle} className = 'payment-method-modal'>
                    <ModalHeader toggle={toggle} className='p-40'>
                        <div className = 'd-flex justify-content-between '>
                            <div className='font-16 color-243448 font-weight-500 '>
                                Notifications <span className='notify_count'>{count}</span>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody style={{overflowY: 'scroll'}}>
                        {/*{notification.reverse().map((notify,k)=>{*/}
                        {props.notification != null && props.notification.length > 0 ? 
                            props.notification.reverse().map((notify,k)=>{
                            return(
                                <>
                                    <div className='bottom_border'>
                                        <div>{notify.description}</div>
                                        <div>{notify.message}</div>
                                        <div>{Moment(notify.created_Datetime).format("DD/MM/yyyy")}</div>
                                    </div>
                                </>
                            )
                        }):
                        ''}
                    </ModalBody>
                    <ModalFooter>
                        <span className='pointer color-2C67FF' onClick={clearNotifications}>Clear All</span>
                    </ModalFooter>
                </Modal>
    <div className = {`${styles.notificationsContainer}`}>
        <div className = {`d-flex justify-content-between ${styles.notificationsHeader}`}>
            <div className = {`gil-medium ${styles.notificationsHeading}`}>
                Notifications
            </div>
            <div className = {`${styles.notificationsHeadingLink}`} style={{cursor:'pointer'}} onClick={toggle}>
                View All
            </div>
        </div>
        <div style = {{padding:'22px 28px'}}>
           {props.notification != null && props.notification.length > 0 ? 
            props.notification.map((values, k)=>(
                props.notificationNo != null ?
                k != null && k < props.notificationNo ?
                <>
                <div className = 'd-flex align-items-center' >
                <div className ='me-3'>
                    {values.createdUserImage}
                </div>
                <div classNmae = ''>
                    <div>
                        {values.message}
                    </div>
                    <div className = {`my-1 ${styles.notificationsTradeNumberPill}`}>
                        {values.tradeNo}
                    </div>
                    <div  className = {`${styles.notificationsTime}`}>
                        {Moment(values.created_Datetime).format("DD/MM/yyyy")}
                    </div>
                </div>
            </div>
            <hr className = {`${styles.notificationsHr}`}/>
            </>
            :
           ''
            :
            k != null && k < 2 ?
            <>
            <div className = 'd-flex align-items-center' >
            <div className ='me-3'>
                {values.createdUserImage}
            </div>
            <div classNmae = ''>
                <div>
                    {values.message}
                </div>
                <div className = {`my-1 ${styles.notificationsTradeNumberPill}`}>
                    {values.tradeNo}
                </div>
                <div  className = {`${styles.notificationsTime}`}>
                    {Moment(values.created_Datetime).format("DD/MM/yyyy")}
                </div>
            </div>
        </div>
        <hr className = {`${styles.notificationsHr}`}/>
        </>
            :
            ''
            ))
            :
            ''
            }
        </div>
    </div>
    </>
  )
}

export default Index