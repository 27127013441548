import { Col, Form, FloatingLabel } from "react-bootstrap"
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import Moment from "moment";


export const InputField = (props)=>{
    return(
        <Col md={props.md} lg={props.lg} className={props.classname}>
            <FloatingLabel controlId="floatingInput" label={props.label}>
                <Form.Control type={props.type} placeholder={props.label} 
                className={'form_input form_input_color_change mb-3' + props.class} 
                defaultValue={props.val} 
                name={props.name} 
                onChange={props.onChange} 
                id={props.id}
                onBlur={props.onBlur} 
                disabled={props.disabled}
                style={{backgroundColor : props.disabled ? 'E7E8EB': 'FCFCFC', border: props.errors && '1px solid red'}} 
                />
            </FloatingLabel>
            {props.errors &&
                <span className='input_error'>{props.errors}</span>
            }
        </Col>
    )
}

export const BottomBorderInputField = (props)=>{
    return(
        <Col md={props.md} lg={props.lg} className={props.classname}>
            <FloatingLabel controlId="floatingInput" label={props.label}>
                <Form.Control type={props.type} placeholder={props.label} 
                className="form_input form_input_color_change br-0" 
                defaultValue={props.val} 
                name={props.name} 
                onChange={props.onChange} 
                id={props.id}
                onBlur={props.onBlur} 
                disabled={props.disabled}
                style={{backgroundColor : props.disabled ? 'E7E8EB': 'FCFCFC', border: props.errors && '1px solid red'}} 
                />
            </FloatingLabel>
            {props.errors &&
                <span className='input_error'>{props.errors}</span>
            }
        </Col>
    )
}

export const CustomInputField = (props) => {
    return(
           
                <Form.Control type={props.type} placeholder={props.label} 
                    className="form_input_custom form_input_color_change" 
                    defaultValue={props.val} 
                    name={props.name} 
                    onChange={props.onChange} 
                    onBlur={props.onBlur} 
                    disabled={props.disabled}
                />
    )
}
export const InputSelect = (props)=>{
    return(
        <Col md={props.md} lg={props.lg} className={props.classname}>
            <FloatingLabel controlId="floatingSelect" label={props.label}>
                <Form.Select 
                className="form_input form_input_color_change" 
                placeholder={props.label} 
                name={props.name} 
                onChange={props.onChange} 
                onBlur={props.onBlur}  
                style={{backgroundColor : props.disabled ? 'E7E8EB': 'FCFCFC', border: props.errors && '1px solid red'}}  
                disabled={props.disabled}>
                    <option value={0}>Select</option>
                    {props.optionField}
                </Form.Select>
            </FloatingLabel>
            {props.errors &&
                <span className='input_error'>{props.errors}</span>
            }
        </Col>
    )
}

export const InputSelectBottomBorderOnly
 = (props)=>{
    return(
        <Col md={props.md} lg={props.lg} className={props.classname}>
            <FloatingLabel controlId="floatingSelect" label={props.label}>
                <Form.Select 
                className="form_input form_input_color_change br-0 border-left-0  border-right-0  border-top-0" 
                placeholder={props.label} 
                name={props.name} 
                onChange={props.onChange} 
                onBlur={props.onBlur}  
                style={{backgroundColor : props.disabled ? 'E7E8EB': 'FCFCFC', border: props.errors && '1px solid red'}}  
                disabled={props.disabled}>
                    <option value={0}></option>
                    {props.optionField}
                </Form.Select>
            </FloatingLabel>
            {props.errors &&
                <span className='input_error'>{props.errors}</span>
            }
        </Col>
    )
}

export const DatePick = (props) =>{
    return(
        <Col md={props.md} className={props.classname}>
            <label className="w-100">
                <img src = 'assets/images/supports/datePickerCalendar.svg' />
                <DatePicker name="DocExpiryDate" placeholderText={props.label} className="form_input" selected={props.val?Moment(props.val).toDate():false} 
                onChange={props.handleCompletionDate} disabled={props.disabled} 
                showYearDropdown/>
            </label>
        </Col>
    )
}
export const DatePickBottomBorder = (props) =>{
    return(
        <Col md={props.md} className='d-flex align-self-end mt-md-0 mt-4'>
            <DatePicker name="DocExpiryDate" placeholderText={props.label} className="form_input br-0 border-top-0 border-left-0 border-right-0 meeting_date font-weight-400" selected={props.val?Moment(props.val).toDate():false} 
            onChange={props.handleCompletionDate} disabled={props.disabled} showYearDropdown/>
             <div className=" "><img className="calenderIcon" src = 'assets/images/supports/datePickerCalendar.svg' /></div>
        </Col>
    )
}
export const inputFieldArea = (props) => {
    return (
        <Col md={props.md} className={props.classname}>
            <FloatingLabel controlId="floatingInput" label={props.label}>
                <Form.Control as="textarea" rows={5} className='input_textarea' name={props.name} onChange={props.onChange} onBlur={props.onBlur} value={props.val} disabled={props.disabled}/>
            </FloatingLabel>
            <span className='input_error'>{props.errorData}</span>
        </Col>
    )
}
