import React, { useEffect } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import * as CONFIG from  "../../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import { useState } from 'react';

function ListBankAccount(props) {
    const { instance } = useMsal();
    const [accounttype, setaccounttype] = useState('')
    useEffect(()=>{
        if(props.values.key == null){
            props.values.key = props.values.bankDetailID
        }
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ACCOUNT_TYPE, { 
            "instanceID": CONFIG.PMSInstanceID, 
            "accountSubscriptionID": CONFIG.AccountSubscriptionID, 
            "created_User": CONFIG.LoginID, 
        }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } }) 
        .then((res) => { 
            let result = res.data.result.filter(x=> x.accountTypeID == props.values.accountTypeID)[0]
            console.log(result)
            setaccounttype(result.accountTypeName)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        }) 
    },[accounttype])
    
    const handleActive = (e) => {
        let data = props.values
        data.bankStatus = true
        console.log(data)
        props.handleActive(data)
    }
    const handleInActive = (e) => {
        let data = props.values
        data.bankStatus = false
        console.log(data)
        props.handleActive(data)
    }

    const handleRemove = (e, data) => {
        props.handleRemove(e, data)
    }
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.key} onClick={props.handleEdit}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.key} onClick={handleActive}>Set Active</span>
                ),
            },
            {
                key: '3',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.key} onClick={handleInActive}>Set Inactive</span>
                ),
            },
            {
                key: '4',
                label: (
                    <span className='font-weight-400 font-14 ' id={props.values.key} onClick={(e) =>{handleRemove(e, props.values)}}>Remove</span>
                ),
            },
          ]}
        />
      );
  return (
    <tr className = ''>
        <td>
            {props.values.accountName}
        </td>
        <td>
             {props.values.accountNo}
        </td>
        <td>
            {props.values.bankName}
        </td>
        <td>
            {props.values.ibanNo}
        </td>
        <td>
            {props.values.AccountTypeName != null ? props.values.AccountTypeName : accounttype}
        </td>
        <td>
            <div className = {props.values.bankStatus == true ? 'bankstatus-pill-active' : 'bankstatus-pill-inactive'}>
                {props.values.bankStatus == true ? 'Active' : 'Inactive'} 
            </div>                                          
        </td>
        {props.fromView != null && props.fromView == true 
        
        ? 
        <td></td>
        : 
        <td>
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
            <Space>
                <img src = '/assets/images/Dot_icon.svg' />
            </Space>
            </a>
        </Dropdown>
        </td>
        }

                                                        
    </tr>
  )
}

export default ListBankAccount