import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Col, Row, Card } from 'react-bootstrap'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import NumberFormat from 'react-number-format';
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { BackButton, BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import * as CONFIG from "../.././../helpers/config"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useMsal } from "@azure/msal-react";
import { useReactToPrint } from 'react-to-print';

export default function GenerateCustomerInvoice(props) {
    const componentRef = useRef();

    const { instance } = useMsal();
    const [customerInvoiceData, setCustomerInvoiceData] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [modal, setmodal] = useState(props.isOpen)

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }
    console.log(props)
    const location = useLocation()
    let navigate = useNavigate();
    const tradeData = customerInvoiceData
    const tradeID = props.tradeID
    const tab = props.tab
    useEffect(() => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADECUSTOMERPAYMENTDETAILS_BY_PAYMENTID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                //console.log((parseInt((res.data.result[0].tradedetails.tradeProductDetail[0].productSellingPrice) * (res.data.result[0].tradedetails.tradeProductDetail[0].quantity))))
                setCustomerInvoiceData(res.data.result[0])
                console.log(customerInvoiceData, 'inside api call')
                res.data.result.tradedetails.tradeProductDetail.map((list, k) => (
                    listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                    // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                ))

                let sum = 0
                let sumTax = parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax1)) + parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax2))
                for (let i = 0; i < listValues.length; i++) {
                    sum += listValues[i]
                }
                console.log(sum)
                setSubTotal(sum)
                setTotalTax(sumTax)
                setOverAllTotal(sum + sumTax)
                //props.refreshList()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })


    }, [])
    const back = () => {
        if (props.invoiceList != null && props.invoiceList == true) {
            props.handleClose()
        }
    }

    //const printDocument = () => {
    //    const domElement = document.getElementById("download_section");
    //    html2canvas(domElement)
    //        .then((canvas) => {
    //            const imgWidth = 300;
    //            const pageHeight = 200;
    //            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //            let heightLeft = imgHeight;
    //            let position = 0;
    //            heightLeft -= pageHeight;
    //            const doc = new jsPDF('l', 'mm', 'A4');
    //            doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
    //            while (heightLeft >= 0) {
    //                position = heightLeft - imgHeight;
    //                doc.addPage();
    //                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
    //                heightLeft -= pageHeight;
    //            }
    //            doc.save('Customer-' + customerInvoiceData.tradedetails.customerInvoiceNo);

    //        })
    //}
    const printDocument = () => {

        //var printContents = document.getElementById('downloadinvoice').innerHTML;
        //const w = window.open();

        //w.document.write(printContents);
        //w.document.write('<scr' + 'ipt type="text/javascript">' + 'window.onload = function() { window.print(); window.close(); };' + '</sc' + 'ript>');

        //w.document.close(); // necessary for IE >= 10
        //w.focus(); // necessary for IE >= 10


        var divElementContents = document.getElementById("downloadinvoice").innerHTML;
        var windows = window.open('', '', 'height=400, width=400');
        windows.document.write('<html>');
        windows.document.write('<body > <h1>Div\'s Content Are Printed Below<br>');
        windows.document.write(divElementContents);
        windows.document.write('</body></html>');
        windows.document.close();
        windows.print();
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (


        //<></>
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: '100vh' }}>
            <ModalHeader toggle={toggle}>

                <div className='mx-2'>
                    <BlueBorderButton value="PRINT" clickEvent={handlePrint} />
                    {/*<ReactToPrint*/}
                    {/*    trigger={() => <BlueBorderButton value="EXPORT PDF" />}*/}
                    {/*    content={() => componentRef.current}*/}
                    {/*/>*/}
                </div>

            </ModalHeader>
            {customerInvoiceData != null ?
                <ModalBody className=' scrollContract' id='downloadinvoice' >
                    <div id='download_section' ref={componentRef} >
                        <Row className='p-1-rem'>
                            <div className='d-flex justify-content-end'>
                                <img src='assets/images/logo.svg' />
                            </div>
                            <div className='font-weight-600 d-flex justify-content-center uline'>
                                COMMERCIAL INVOICE
                            </div>
                            <div className='d-flex justify-content-between mt-1-rem'>
                                <div>
                                    <div className='font-weight-500'>
                                        INVOICE NUMBER: {customerInvoiceData.invoiceNo}
                                    </div>
                                    <div className='font-weight-500'>
                                        VAT TRN NO: {customerInvoiceData.companyTRNNo}
                                    </div>
                                </div>
                                <div>
                                    <div className='font-weight-500'>
                                        INVOICE DATE: {Moment(customerInvoiceData.invoiceDate).format('DD/MM/YYYY')}
                                    </div>
                                    <div className='font-weight-500 d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>DUE DATE:{Moment(customerInvoiceData.invoiceDueDate).format('DD/MM/YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='invoice_border  p-1-rem mt-1-rem'>
                                <div className='font-weight-500'>
                                    NAME AND ADDRESS OF CUSTOMER:
                                </div>
                                <div>
                                    {customerInvoiceData.customerName}<br />
                                    {customerInvoiceData.customerAddress}<br />
                                    {customerInvoiceData.companyTelephone}<br />
                                    {customerInvoiceData.companyWebsite}<br />
                                </div>
                                <div className='font-weight-600 d-flex justify-content-center'>
                                    CONTRACT NUMBER : {customerInvoiceData.tradeNo}
                                </div>
                            </div>
                            <table className='invoice_border p-3-rem width-100l' style={{ border: '1px solid black' }}>
                                <thead className='invoice_border'>
                                    <tr className='width-100l' style={{ border: '1px solid black' }}>
                                        <th clssName='br-right' style={{ border: '1px solid black' }}>S.NO</th>
                                        <th clssName='br-right' style={{ border: '1px solid black' }}>DESCRIPTION OF GOODS</th>
                                        <th clssName='br-right' style={{ border: '1px solid black' }}>QUANTITY</th>
                                        <th clssName='br-right' style={{ border: '1px solid black' }}>UNIT PRICE</th>
                                        <th clssName='br-right' style={{ border: '1px solid black' }}>AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: '1px solid black' }}>
                                            1
                                        </td>
                                        <td style={{ border: '1px solid black' }}>
                                            <div className='font-weight-600 uline'>{customerInvoiceData.productName}</div>
                                            <div className='mt-1-rem font-weight-500'>COUNTRY OF ORIGIN : {customerInvoiceData.loadPortCountry}</div>
                                            <div className='mt-1-rem'><span className='uline'>BILL OF LADING, DATE, QUANTITY :</span> <br />
                                                {customerInvoiceData.billOfLadingNo}<br />
                                                {customerInvoiceData.billOfLadingDate}<br />
                                                {customerInvoiceData.quantity}<br />
                                            </div>
                                            <div className='mt-1-rem font-weight-500'>PORT OF LOADING : {customerInvoiceData.loadPort}, {customerInvoiceData.loadPortCountry}</div>
                                            <div className='font-weight-500'>PORT OF DISCHARGE : {customerInvoiceData.loadPort}, {customerInvoiceData.destinationPort}</div>
                                            <div className='font-weight-500'>TRADE TERM : {customerInvoiceData.loadPort}</div>
                                            <div className='font-weight-500'>VESSEL NAME : {customerInvoiceData.vesselName}</div>
                                            <div className='font-weight-500'>UNIT PRICE : {customerInvoiceData.sallingPrice} USD</div>
                                            <div className='font-weight-500'>PAYMENT TERMS : {customerInvoiceData.paymentType} USD</div>
                                            <div className='font-weight-500'>
                                                {customerInvoiceData.invoiceRemarks}
                                            </div>

                                            {customerInvoiceData.is_FinalInvoice == true &&
                                                <>
                                                    <div className='parameters_div'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>ACTUAL RESULTS</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {customerInvoiceData.actualFinalParameters.map((qt, k) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{qt.parameterName}</td>
                                                                            <td>
                                                                                {qt.parameterPer} %
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className='mt-1-rem d-flex justify-content-between'>
                                                        <div className='uline'> DETAILS OF CALCULATION OF UNIT PRICE</div> <br />
                                                    </div>

                                                    <div className='parameters_div'>
                                                        {customerInvoiceData.actualFinalParameters.map((qt, k) => {
                                                            if (qt.parameterAdjStatus) {
                                                                return (
                                                                    <>
                                                                        <div>{qt.parameterName} :</div>
                                                                        <div>
                                                                            {qt.parameterFormula} = {qt.parameterQty != 0 ? qt.parameterQty + ' MT' : qt.parameterAmt + ' USD'}
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        })}
                                                    </div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>TOTAL QUANTITY AFTER MOISTURE ADJUSTMENT :</td>
                                                                <td>{customerInvoiceData.quantity}</td>
                                                            </tr>
                                                            {customerInvoiceData.actualFinalParameters.map((qt, k) => {
                                                                if (qt.parameterAdjStatus) {
                                                                    if (qt.parameterAmt != "") {
                                                                        return (
                                                                            <tr>
                                                                                <td>LESS: TOTAL {qt.parameterName} ADJUSTMENT</td>
                                                                                <td>USD {qt.parameterAmt}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                }
                                                            })}
                                                            <tr>
                                                                <td>UNIT PRICE AFTER ADJUSTMENT</td>
                                                            <td>{customerInvoiceData.sellingUnitAmount}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            }

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>BENIFICIARY</td>
                                                        <td>:</td>
                                                        <td>{customerInvoiceData.companyName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BENIFICIARY ADDRESS</td>
                                                        <td>:</td>
                                                        <td>{customerInvoiceData.customerAddress}</td>
                                                    </tr>
                                                    {customerInvoiceData.companybankdetails != null ?
                                                        <>
                                                            <tr>
                                                                <td>IBAN A/C NO</td>
                                                                <td>:</td>
                                                                <td>{customerInvoiceData.companybankdetails.ibanNo} / {customerInvoiceData.companybankdetails.accountNo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>BANK NAME AND ADDRESS</td>
                                                                <td>:</td>
                                                                <td>{customerInvoiceData.companybankdetails.bankName}<br />{customerInvoiceData.companybankdetails.bankAddress}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SWIFT</td>
                                                                <td>:</td>
                                                                <td>{customerInvoiceData.companybankdetails.swiftCode}</td>
                                                            </tr>
                                                        </>

                                                        : ''
                                                    }

                                                    <tr>
                                                        <td>CORRESP BANK</td>
                                                        <td>:</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ border: '1px solid black' }}>
                                            <span className='font-weight-500'>{customerInvoiceData.quantity} MT</span>
                                        </td>
                                        <td style={{ border: '1px solid black' }}>
                                            <div className='font-weight-500'>{customerInvoiceData.sellingUnitAmount} USD</div>
                                            <div className='mt-1-rem font-weight-600'>{customerInvoiceData.loadPort}, {customerInvoiceData.loadPortCountry}</div>
                                        </td>
                                        <td style={{ border: '1px solid black' }}>
                                            <span className='font-weight-500'>{customerInvoiceData.amount} USD</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                        <Row className='p-2-rem'>
                            <Col md={6}>
                                <div className='font-weight-600 font-15'>
                                    FOR {customerInvoiceData.companyName}
                                </div>
                                <div className='font-weight-600 font-15'>
                                    AUTHORIZED SIGNATORY
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                            </Col>
                            <Col md={6}>
                                <div className='font-weight-400 font-13 font-weight-600' style={{ textAlign: 'right' }}>{customerInvoiceData.companyName}</div>
                                <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>{customerInvoiceData.customerAddress}</div>
                                <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>Tel : {customerInvoiceData.companyTelephone}</div>
                                <div className='font-weight-400 font-13' style={{ textAlign: 'right' }}>{customerInvoiceData.companyWebsite}</div>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>

                :
                ''
            }

        </Modal>
    )
}
