import React from 'react'
import FormComponent from './components/FormComponent'
import {Row, Col, Form } from "react-bootstrap";


function ContactDetail(props) {
  return (
    <Row>
       <div className = 'd-flex justify-content-between align-items-center'>
            <div className='font-16 font-weight-600 color-707895'>
               Contact Details
            </div>
        </div>
        <FormComponent customerContact={props.customerContact} isdataavailable={props.isdataavailable}/>
    </Row>
  )
}

export default ContactDetail