import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space, Collapse } from 'antd';
import * as CONFIG from '../../../../helpers/config'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BlueButton, BlueBorderButton } from '../../../../components/buttoncomponents'
import { Row, Col } from "react-bootstrap"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Preshipmentview from '../../ContractFinalized/views/preshipmentview';
import Postvesselfixture from '../../ContractFinalized/views/postvesselfixture';
import Completionloading from '../../ContractFinalized/views/completionloading';
import Dishcargeport from '../../ContractFinalized/views/dishcargeport';
import Preclosure from '../../ContractFinalized/views/preclosure';
import VesselInformation from '../../ContractFinalized/VesselInformation';
import Actualcostsheet from '../../../CostSheet/actualcostsheet';
import Costsheet from '../../../CostSheet/costsheet';
import Moment from "moment"
import { useMsal } from "@azure/msal-react";
import * as FormFields from "../../../../components/formcomponents"
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';

export const VersionList = (props) => {
    console.log(props)
    const { instance } = useMsal();
    const [list, setList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [versionlist, setversionlist] = useState()
    useEffect(() => {
        if (isFirst) {
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.VERSION_BY_CONTRACT, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.id
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setversionlist(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {versionlist != null &&
                versionlist.map((list, k) => (
                    <option key={k} value={list}>{list}</option>
                ))
            }
        </>
    )
}
export default function Closedtrade(props) {
    console.log(props)
    const { instance } = useMsal();
    const [enableMark, setEnable] = useState(false)
    const MySwal = withReactContent(Swal)
    const [data, setData] = useState([])
    const [template_list, settemplatelist] = useState([])
    const [modal, setModal] = useState(false)
    const [isContract, setIsContract] = useState(false)
    const [viewshipment, setviewshipment] = useState(false)
    const [viewpostvessel, setviewpostvessel] = useState(false)
    const [completionloading, setcompletionloading] = useState(false)
    const [dischargeport, setdischargeport] = useState(false)
    const [viewpreclosure, setviewpreclosure] = useState(false)
    const [viewactualcostsheet, setviewactualcostsheet] = useState(false)
    const [processmodal, setprocessmodal] = useState(false)
    const [tradeprocess, settradeprocesses] = useState([])
    const { Panel } = Collapse;
    const [defid, setdefid] = useState(0)
    const [contractmodal, setcontractmodal] = useState(false)
    const [finalDraftVersion, setFinalDraftVersion] = useState(0)
    const [isversioncontract, setisversioncontract] = useState(false)

    //useEffect(() => {
    //    let laycanStart = props.data.laycanPeriod.indexOf('-')
    //    console.log(laycanStart)
    //}, [])

    useEffect(() => {
        if (props.data.is_PreShipmentCompleted == 1 && props.data.is_PostVesselFixtureCompleted == 1 &&
            props.data.is_CompletionOfLoadingCompleted == 1 && props.data.is_DischargePortCompleted == 1) {
            setEnable(true)
        }
    })

    const getTradeInfo = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.data.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PROCESS_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settradeprocesses(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const toggle = () => {
        setModal(!modal)
    }
    const processtoggle = () => {
        setprocessmodal(!processmodal)
        getTradeInfo()
        if (processmodal == true) {
            props.refreshList()
        }
    }

    const viewCustomerContract = () => {
        const contractid = props.data.customerContractID
        setdefid(contractid)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                setIsContract(true)
                setFinalDraftVersion(res.data.result[0].draftVersionNo)
                setisversioncontract(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        const list_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.VERSION_BY_CONTRACT, list_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const viewSupplierContract = () => {
        const contractid = props.data.supplierContractID
        setdefid(contractid)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                setIsContract(true)
                setFinalDraftVersion(res.data.result[0].draftVersionNo)
                setisversioncontract(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        const list_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.VERSION_BY_CONTRACT, list_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }


    useEffect(() => {
        console.log(template_list)
    }, [template_list])


    const handleVersion = (e) => {
        let id = e.currentTarget.value
        if (id != 0) {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "versionNo": parseInt(id),
                "id": defid
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CONTRACT_BY_VERSION, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    settemplatelist(res.data.result)
                    setisversioncontract(true)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    const contracttoggle = () => {
        setcontractmodal(!contractmodal)
    }
    const getContract = () => {
        return (
            <>

                <Modal isOpen={contractmodal} toggle={contracttoggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                    <ModalBody toggle={toggle}>

                        <div>
                            <BlueBorderButton value='DOWNLOAD DOCUMENT' id={isContract && isversioncontract ? template_list[0].draftVersionNo : data.draftVersionNo} clickEvent={printDocument} className='ml-1-rem' />
                        </div>
                        <div id='download_section' className='mx-4'>

                            {isContract &&
                                template_list.map((list) => {
                                    var contractData = list.contractContent;
                                    return (
                                        <Row>
                                            <Col md={4} className="my-3">
                                                {list.srNo}.&nbsp;{list.heading} :
                                            </Col>
                                            <Col md={8} className="my-3" >
                                                <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    </ModalBody>
                </Modal>
                <div className='service-request-detail-title mx-4 mt-4'>
                    <div className='d-flex justify-content-between'>
                        <div className='col-md-3'>
                            <FormFields.InputSelect
                                md={12}
                                name='versionno'
                                label='Version No'
                                type='text'
                                optionField={<VersionList id={defid} />}
                                onChange={handleVersion}
                                onBlur={handleVersion}
                            />
                        </div>
                        <div>
                            <BlueBorderButton value='EXPORT DOCUMENT' clickEvent={contracttoggle} className='ml-1-rem' />
                        </div>
                    </div>
                </div>
                <div id='download_sections' className='my-3  mx-4'>
                    <div className='add-edit-wrapper bg-white br-8 w-100 scrollContract'>

                        {isContract &&
                            template_list.map((list) => {
                                var contractData = list.contractContent;
                                return (
                                    <Row>
                                        <Col md={4} className="my-3">
                                            {list.srNo}.&nbsp;{list.heading} :
                                        </Col>
                                        <Col md={8} className="my-3" >
                                            <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
    const printDocument = (e) => {
        let versionNo = parseInt(e.target.id)
        const domElement = document.getElementById("download_section");
        if (versionNo == finalDraftVersion) {
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm');
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    if (data.contractTemplateID == 1) {
                        doc.save('Customer_Contract' + '-' + props.data.tradeNo + '.pdf');
                    }
                    else {
                        doc.save('Supplier_Contract' + '-' + props.data.tradeNo + '.pdf');
                    }
                })
        }
        else {
            var charSet = ' '
            var content = charSet + domElement.innerHTML;
            var converted = htmlDocx.asBlob(content);
            if (data.contractTemplateID == 1) {
                saveAs(converted, 'Customer_Contract' + '-' + props.data.tradeNo + '.docx');
            }
            else {
                saveAs(converted, 'Supplier_Contract' + '-' + props.data.tradeNo + '.docx');
            }
        }
    }

    const toggleViewShipment = () => {
        setviewshipment(!viewshipment)
    }
    const togglePostFixture = () => {
        setviewpostvessel(!viewpostvessel)
    }
    const toggleCompletion = () => {
        setcompletionloading(!completionloading)
    }
    const toggleDischargePort = () => {
        setdischargeport(!dischargeport)
    }
    const toggleviewpreclosure = () => {
        setviewpreclosure(!viewpreclosure)
    }

    const menu = (
        <Menu
            items={[
                (localStorage.getItem('Customer_Contract') == 3 || localStorage.getItem('Customer_Contract') == 1) &&
                {
                    key: '1',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={viewCustomerContract}>View Customer Contract</span>
                    ),
                },
                (localStorage.getItem('Supplier_Contract') == 3 || localStorage.getItem('Supplier_Contract') == 1) &&
                {
                    key: '2',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={viewSupplierContract}>View Supplier Contract</span>
                    ),
                },
                (localStorage.getItem('Estimated_Cost_Sheet') == 3 || localStorage.getItem('Estimated_Cost_Sheet') == 1) &&
                {
                    key: '3',
                    label: (
                        <Link to='/costsheet' state={{ tradeDataForCostSheet: props.data, tab: 3, isview: true }}>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID}>View Estimated Cost Sheet</span>
                        </Link>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <Link to='/vesselinformation' state={{ tradeDataForFreight: props.data, tab: 3 }}>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID}>View Shipment Details</span>
                        </Link>
                    ),
                },
                (localStorage.getItem('Pre_shipment_Updates') == 3 || localStorage.getItem('Pre_shipment_Updates') == 1) &&
                {
                    key: '5',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={toggleViewShipment}>View Pre-Shipment Checklist</span>
                    ),
                },
                (localStorage.getItem('Post_vessel_fixture_Updates') == 3 || localStorage.getItem('Post_vessel_fixture_Updates') == 1) &&
                {
                    key: '6',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={togglePostFixture}>View Post-Vessel Fixture Checklist</span>
                    ),
                },
                (localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) &&
                {
                    key: '7',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={toggleCompletion}>View Completion Of Loading Checklist</span>
                    ),
                },
                (localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) &&
                {
                    key: '8',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={toggleDischargePort}>View Discharge Port Checklist</span>
                    ),
                },
                (localStorage.getItem('Pre_Closure_Updates') == 3 || localStorage.getItem('Pre_Closure_Updates') == 1) &&
                {
                    key: '9',
                    label: (
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={toggleviewpreclosure}> View Pre-Closure Updates</span>
                    ),
                },
                (localStorage.getItem('Actual_Cost_Sheet') == 3 || localStorage.getItem('Actual_Cost_Sheet') == 1) &&
                {
                    key: '10',
                    label: (
                        <Link to='/actualcostsheet' state={{ tradeDataForCostSheet: props.data, tab: 3, isview: true }}>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID}>View Actual Cost Sheet</span>
                        </Link>
                    ),
                }
            ]}
        />
    );



    return (

        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                <ModalBody toggle={toggle}>
                    <div className='service-request-detail-title mx-4 mt-4'>
                        <div className='d-flex justify-content-between'>
                            <div style={{ fontSize: '18px', fontWeight: '600' }}>
                                <>Contract</>
                            </div>
                            <div>
                                <BlueBorderButton value='DOWNLOAD DOCUMENT' clickEvent={printDocument} className='ml-1-rem' />
                            </div>
                        </div>
                    </div>
                    <div id='download_section' className='mx-4'>
                        <center>
                            <div className='justify-content-end d-flex mt-3' style={{ fontSize: '14px', fontWeight: '400' }}>
                                Contract
                            </div>
                        </center>
                        {isContract &&
                            template_list.map((list) => {
                                var contractData = list.contractContent;
                                return (
                                    <Row>
                                        <Col md={4} className="my-3">
                                            {list.srNo}.&nbsp;{list.heading} :
                                        </Col>
                                        <Col md={8} className="my-3" >
                                            <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={processmodal} toggle={processtoggle} className='trade_list_modal'>
                <ModalHeader toggle={processtoggle}>

                </ModalHeader>
                <ModalBody className='service-request-modal-wrapper' style={{ padding: '0' }}>
                    <div className='d-flex justify-content-between '>
                        <Tabs className='d-flex  width-100 trade_process_tabs'>
                            <Col md={2} className='scroll-over' style={{ width: '20%' }}>
                                <TabList style={{ height: '100vh', background: '#FDFAFE', paddingTop: '2rem', overflowY: 'scroll' }}>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={viewCustomerContract}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Customer Contract</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.custcontractapprovaldate != null ?
                                            Moment(tradeprocess.custcontractapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'><p className=''>{tradeprocess.custcontractapprovedbyImg}</p></div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    <p className='font-13 mb-0 font-weight-500 colorUserName'>{tradeprocess.custcontractapprovedby}</p>
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.custcontractapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                        onClick={viewSupplierContract}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Supplier Contract</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.suppcontractapprovaldate != null ?
                                            Moment(tradeprocess.suppcontractapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'><p>{tradeprocess.suppcontractapprovedbyImg}</p></div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName'>
                                                    {tradeprocess.suppcontractapprovedby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.suppcontractapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                        id={props.data.tradeID}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Estimated Cost Sheet</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.estimatescostapprovaldate != null ?
                                            Moment(tradeprocess.estimatescostapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.estimatescostapprovedbyImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.estimatescostapprovedby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.estimatescostapprovedbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Pre-Shipment Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.preshipmentdate != null ?
                                            Moment(tradeprocess.preshipmentdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.preshipmentbyImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.preshipmentby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.preshipmentbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Post-Vessel Fixture Updates</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.postvesseldate != null ?
                                            Moment(tradeprocess.postvesseldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.postvesselbyImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.postvesselby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.postvesselbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Completion of Loading Updates</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.shipmentloadingdate != null ?
                                            Moment(tradeprocess.shipmentloadingdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.shipmentloadingImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.shipmentloadingby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.shipmentloadingbydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Discharge-Port Checklist</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.dischargedate != null ?
                                            Moment(tradeprocess.dischargedate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex pt-1-rem'>
                                                <div className='avatar_trade'>{tradeprocess.dischargebyImg}</div>
                                                <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                    {tradeprocess.dischargeby}
                                                    <div className='tab_sub_heading'>
                                                        {tradeprocess.dischargebydes}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='approved_badge'>
                                                    <img src='assets/images/check.svg' /> Approved
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Pre-Closure Updates</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.preshipmentdate != null ?
                                            Moment(tradeprocess.preshipmentdate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.is_PreClosureCompleted == 2 &&
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.preclosurebyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.preclosureby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.preclosuredes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </Tab>
                                    <Tab
                                        className='tab_completed tab-width'
                                        style={{ padding: '15px 0 0 0' }}
                                    >
                                        <h4 className='textHeaderTab mb-0'>View Actual Costsheet</h4>
                                        <div className='tab_sub_heading'>{tradeprocess.actualcostapprovaldate != null ?
                                            Moment(tradeprocess.actualcostapprovaldate).format("DD/MM/yyyy")
                                            :
                                            ''
                                        }</div>
                                        {props.data.actualCostsheetStatus == 2 &&
                                            props.data.actualCostSheetApprovalStatus == 2 ?

                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.actualcostapprovedbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.actualcostapprovedby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.actualcostapprovedbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Approved
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pt-1-rem'>
                                                    <div className='avatar_trade'>{tradeprocess.actualcostbyImg}</div>
                                                    <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                        {tradeprocess.actualcostby}
                                                        <div className='tab_sub_heading'>
                                                            {tradeprocess.actualcostbydes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='approved_badge'>
                                                        <img src='assets/images/check.svg' /> Updated
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </Tab>

                                </TabList>
                            </Col>
                            <Col md={8}>
                                <TabPanel>
                                    {(localStorage.getItem('Customer_Contract') == 3 || localStorage.getItem('Customer_Contract') == 1) ?
                                        getContract()
                                        :

                                        <>
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        </>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {

                                        (localStorage.getItem('Supplier_Contract') == 3 || localStorage.getItem('Supplier_Contract') == 1) ?
                                            getContract()
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }

                                </TabPanel>
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Estimated_Cost_Sheet') == 3 || localStorage.getItem('Estimated_Cost_Sheet') == 1) ?
                                            <Costsheet trade={props.data} tab={2} isview={true} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                {/* <TabPanel>
                                        <VesselInformation tradeDataForFreight={props.data} tab={2}/>
                                    </TabPanel> */}
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Pre_shipment_Updates') == 3 || localStorage.getItem('Pre_shipment_Updates') == 1) ?
                                            <Preshipmentview tradeID={props.data.tradeID} view={viewshipment} tradeData={props.data} />
                                            :

                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Post_vessel_fixture_Updates') == 3 || localStorage.getItem('Post_vessel_fixture_Updates') == 1) ?
                                            <Postvesselfixture tradeID={props.data.tradeID} view={viewpostvessel} />
                                            :

                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) ?
                                            <Completionloading tradeID={props.data.tradeID} view={completionloading} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        (localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 3 || localStorage.getItem('Discharge_Port_and_Shipment_Completion_Updates') == 1) ?
                                            <Dishcargeport tradeID={props.data.tradeID} view={dischargeport} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        localStorage.getItem('Pre_Closure_Updates') == 3 ?
                                            <Preclosure tradeData={props.data} tradeID={props.data.tradeID} tab={1} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        localStorage.getItem('Actual_Cost_Sheet') == 3 ?
                                            <Actualcostsheet tradeDataForCostSheet={props.data} tab={2} isview={true} />
                                            :
                                            <>
                                                <div className='text-center pt-100'>
                                                    <div>
                                                        <img src='assets/images/locked_screen.svg' />
                                                    </div>
                                                    <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                        <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                                </div>
                                            </>
                                    }
                                </TabPanel>
                            </Col>
                            {/* <Col md={2}></Col> */}
                        </Tabs>
                    </div>
                </ModalBody>
            </Modal>
            <tr className='border-bottom-1 '>
                <td>
                    <div className='pt-2'>
                        <h4 className='tradeTrsactionsHeading'>{props.data.tradeNo}</h4>
                        <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'> {props.data.vesselName} <br />  {Moment(props.data.tradeinitiatdate).format("DD/MM/yyyy")} </h4>

                    </div>
                </td>
                <td>
                    {props.data.tradescenarioID == 2 ?
                        <>
                            {props.data.compcustdetails.map((pt, k) => {
                                return (
                                    <>
                                        <div className='pt-2'>
                                            <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{pt.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                        :
                        <>
                            <div className='pt-2'>
                                <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{props.data.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                            </div>
                        </>
                    }
                </td>

                <td>
                    <div className='d-flex flex-column pt-2'>
                        {(props.data.tradescenarioID != null && props.data.tradescenarioID == 3) ?
                            <>
                                {props.data.compcustdetails != null &&
                                    props.data.compcustdetails.map((pt, k) => {
                                        return (
                                            <>
                                                <div className='pb-2'>
                                                    <div className='align-items-center '>
                                                        <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                        <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                {(props.data.compcustdetails != null && props.data.compcustdetails.length > 0) &&
                                    <div className='pb-2'>
                                        <div className='align-items-center '>
                                            <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.compcustdetails[0].customername}</h5>
                                            <span className='tradeTrsactionsid statBg'>{props.data.compcustdetails[0].customercode}</span>
                                        </div>
                                    </div>
                                }

                            </>
                        }
                        <div className='py-2'>
                            <div className=' align-items-center '>
                                <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.supplierName}</h5>
                                <span className='tradeTrsactionsid statBg'>{props.data.supplierCode}</span>
                            </div>
                            <div>
                                <h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                    {props.data.supplierpaymentmethods.map((pt, k) => {
                                        return (
                                            <>{pt}<span className='mx-1 dotSep'>&#8226;</span></>
                                        )
                                    })}
                                </h4>
                            </div>
                        </div>


                    </div>
                </td>

                <td>
                    <div className=' pt-2'>
                        {props.data.tradeProductDetails.map((list, k) => {
                            return (
                                <>
                                    <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                        {" "}
                                        {list.productName}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        < div className="table-ttile-color-sub font-12 font-weight-500 align-items-center">
                                            {list.quantity}&nbsp;{" "}
                                            {list.productUOMName} <span className='mse-3 dotSep'>&#8226;</span>
                                            {props.data.contracttype} <span className='mse-3 dotSep'>&#8226;</span>
                                            {props.data.incotermsName} <span className='mse-3 dotSep'>&#8226;</span>
                                            {props.data.modeOfTransportName}
                                        </div>
                                        <div className='colorTerms font-12 font-weight-500 ms-3 d-flex align-items-center'>
                                            {props.data.contypewithincoterms}
                                        </div>
                                    </div>


                                </>
                            );
                        })}
                    </div>
                    <div className='d-flex py-2'>
                        <div>
                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                Load Port
                            </div>
                            <div className='tradeTrsactionsHeading '>
                                {props.data.loadPort}, {props.data.lordPortCountry}
                            </div>
                        </div>
                        <div className='ml-20'>
                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                Destination Port
                            </div>
                            <div className='tradeTrsactionsHeading'>
                                {props.data.destinationPort}, {props.data.destinationPortcountry}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                </td>
                <td>
                    {/* <Dropdown className='br-8 py-2' overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <img src='/assets/images/Dot_icon.svg' />
                            </Space>
                        </a>
                    </Dropdown> */}
                    <div className='pointer btn-link text-decoration-none pt-1-5-rem' onClick={processtoggle}><a className='border-bottom-primary d-flex justify-content-center'>More Actions</a></div>

                </td>
            </tr>
        </>
    )
}
