import React, { useState, useEffect } from 'react'
import * as FormFields from "../../../../../../../components/formcomponents";
import { Row  } from "react-bootstrap"
import { useFormik } from 'formik';
import { BlueBorderButton } from '../../../../../../../components/buttoncomponents';
import { AddTrace } from '../../../../../../../helpers/API/ProductApi';

export default function Trace_form(props) {
    console.log(props)
    const [error, setError] = useState(true)
    const [isError, setIsError] = useState(true);
    const [n,setn] = useState(false)

    useEffect(()=>{
        if(props.isEdit == true){
            setIsError(false)
        }
    },[])


    const formik = useFormik({
        initialValues:{
            key : props.isEdit?props.data.key:0,
            tradeEnquiryProductTraceElementID: props.isEdit && props.data.tradeEnquiryProductTraceElementID,
            tradeEnquiryProductDetailID: props.isEdit && props.data.tradeEnquiryProductDetailID,
            productTraceElementID: props.isEdit?props.data.productTraceElementID:0,
            productTraceElementName: props.isEdit?props.data.productTraceElementName:'',
            typicalValueRange: props.isEdit?props.data.typicalValueRange:'',
            rejectionValueRange: props.isEdit?props.data.rejectionValueRange:''
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;

          
            if (!values.typicalValueRange) {
                errors.typicalValueRange = "Please enter typical range"
                
            }
           

            if (!values.rejectionValueRange) {
                errors.rejectionValueRange = "Please enter rejection range"
                
            }
           
           
            setIsError(true)
            sendBack();
            return errors;
        }
    });

    const sendBack = () => {
        console.log('asa')
        // if ((Object.keys(formik.errors).length == 0) ) {
        //     console.log(isError)
        //     if(isError == true){
        //         console.log(formik.errors)
        //         console.log('error')
        //         setIsError(true)
                
        //     }
        //     console.log('error checking')
           
        //     if((formik.initialValues == formik.values) && (isError == false)){
        //         console.log(formik.errors)
        //         console.log('no error first condition')
        //         setIsError(false)
        //         //props.handleError()    
        //     }
        //     else if(props.isEdit == true){
        //         console.log('asassss')
        //         setIsError(false)
        //     }
        //     else if(formik.initialValues == formik.values){
        //         console.log(formik.errors)
        //         console.log('error')
        //         setIsError(true)
                
        //     }
        //     else{
        //         console.log(formik.errors)
        //         console.log('no error first condition')
        //         setIsError(false)
        //         //props.handleError()    
        //     }
              
        // }
        // else{
            
        //     console.log(formik.errors)
        //     console.log('error')
        //      setIsError(true)
        //     //props.setErrors()
        // }
        if(formik.values.productTraceElementID == 0 || formik.values.typicalValueRange == "" || formik.values.rejectionValueRange == ""){
            setIsError(true)
        }
        else{
            setIsError(false)
        }
    }

    const handleModalDataSubmission = ()=>{
        props.handleModalDataSubmission(formik.values)
    }

    const handleTrace = (e)=>{
        formik.values.productTraceElementID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.productTraceElementName = e.nativeEvent.target[index].text;
        setn(!n)
        sendBack()
    }
    const handleModalDataUpdation = ()=>{
        props.handleModalDataUpdation(formik.values)
    }
    return (
        <div className='d-flex mx-4'>
                                <FormFields.InputSelect
                                    
                                    name='productTraceElementID'
                                    label='Trace Parameter'
                                    classname='mr-20'
                                    type='text'
                                    optionField={<AddTrace id={parseInt(formik.values.productTraceElementID)}/>}
                                    val={formik.values.productTraceElementID}
                                    onChange={handleTrace}
                                    onBlur ={handleTrace}
                                    errors= {formik.touched.productTraceElementID?formik.errors.productTraceElementID:''}
                                />
                                <FormFields.InputField
                                    
                                    name='unit'
                                    label='Unit*'
                                    classname='mr-20'
                                    type='text'
                                    val='%'
                                    disabled={true}
                                />
                                <FormFields.InputField
                                    
                                    name='typicalValueRange'
                                    label='Typical*'
                                    classname='mr-20'
                                    type='text'
                                    val={formik.values.typicalValueRange}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errors= {formik.touched.typicalValueRange?formik.errors.typicalValueRange:''}
                                />
                                <FormFields.InputField
                                    
                                    name='rejectionValueRange'
                                    label='Rejection*'
                                    classname='mr-20'
                                    type='text'
                                    val={formik.values.rejectionValueRange}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errors= {formik.touched.rejectionValueRange?formik.errors.rejectionValueRange:''}
                                />
                                 <div>
                                    <BlueBorderButton value = 'Save' clickEvent = {props.isEdit?handleModalDataUpdation:handleModalDataSubmission}  disabled = {isError}/>
                                </div>
        </div>
    )
}
