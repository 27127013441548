import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card } from "react-bootstrap";
import { BlueButton } from '../../components/buttoncomponents';
import * as CONFIG from "../../helpers/config"
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar';
import { Link, useLocation } from 'react-router-dom'
import List from './List';
import { useMsal } from "@azure/msal-react";
import ViewCustomerRequirement from './viewCustomerRequirement';

export default function Index() {
    const { instance } = useMsal();
    const [approvallist, setapprovallist] = useState([])
    useEffect(() => {
        getList()
    }, [])
    const getList = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_APPROVAL_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setapprovallist(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    return (
        <div>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/pageicons/approval.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>APPROVALS</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='add-edit-wrapper'>
                            <Tabs>
                                <Card className='tabs-wrapper'>
                                    <TabList>
                                        <Tab>Pending Requests</Tab>
                                        <Tab>Approved Requests</Tab>
                                        <Tab>Rejected Requests</Tab>
                                    </TabList>
                                </Card>
                                <TabPanel>
                                    <div class="col-lg-12 p-4 card mt-4">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>PROCESS</th>
                                                        <th>REF DOC#</th>
                                                        <th>STEP#</th>
                                                        <th>REQUESTED USER</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {approvallist.filter(x => x.status == 1).map((app, k) => (
                                                        <List data={app} getList={getList} status={1}/>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div class="col-lg-12 p-4 card mt-4">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>PROCESS</th>
                                                        <th>REF DOC#</th>
                                                        <th>STEP#</th>
                                                        <th>REQUESTED USER</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {approvallist.filter(x => x.status == 2).map((app, k) => (
                                                        <List data={app} getList={getList}  status={2}/>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div class="col-lg-12 p-4 card mt-4">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>PROCESS</th>
                                                        <th>REF DOC#</th>
                                                        <th>STEP#</th>
                                                        <th>REQUESTED USER</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {approvallist.filter(x => x.status == 9).map((app, k) => (
                                                        <List data={app} getList={getList} status={9}/>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
