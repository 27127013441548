import React, { useState, useEffect } from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import AddProductDetails from './Add Product Details/AddProductDetails';


function ProductDetailsTab(props) {
    const [productdata, setproductdata] = useState([])
    const [isFirst, setIsFirst] = useState(true)
    useEffect(()=> {
        console.log(props.selectedDivision)
    },[])
    useEffect(()=>{
       if(isFirst){
            if(props.isproductavailable){
                setproductdata(props.productData)
            }
       }
       setIsFirst(false)
    })
    const addTraceAndQuality = (data)=>{
        setproductdata(data)
        console.log(data, 'data in product details tab')
    }
    
    const movetoNext = ()=>{
        console.log('inside')
        console.log(productdata, 'move to next')
        if(productdata.length>0){
            props.addTraceAndQuality(productdata)
        }
    }
    return (
        <>
            <Card className='bg-transparent'>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body mb-2-rem bg-white'>
                        <AddProductDetails 
                            selectedDivision = {props.selectedDivision}
                            saveQuality={props.saveQuality} 
                            addTraceAndQuality={addTraceAndQuality}
                            isproductavailable={props.isproductavailable} 
                            productData ={props.productData}
                            saveList={props.saveList}
                            isEdit={props.isEdit}
                        />
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer py-2-rem pt-4 d-flex'>
                    <span><BlueBorderButton value='Prev' clickEvent={props.back}/></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent = {movetoNext}/></span>
                </Card.Footer>
            </Card>
        </>
    )
}

export default ProductDetailsTab