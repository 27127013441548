import axios from "axios";
import { useEffect, useState } from "react";
import * as CONFIG from "../config"
import { useMsal } from "@azure/msal-react";


export const AddCategory = (props) =>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [produtCat, setProdcutCat] = useState([]);
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_CATEGORY, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setProdcutCat(res.data.result);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {produtCat.map((cat,k)=>(
                <option value={cat.productCategoryID} selected={cat.productCategoryID == props.val}>{cat.productCategoryName}</option>
            ))}
        </>
    )
}
export const AddGroup = (props) =>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [produtGroup, setProdcutGroup] = useState([]);
    console.log(props)
    useEffect(()=>{
        console.log(produtGroup)
    },[produtGroup])
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                let list = []
                if( Array.isArray(props.selectedDivision) == true){
                    console.log('array')
                    // setProdcutGroup(res.data.result.filter((x) => x.productgroup.divisionID == props.selectedDivision[0].divisionID));
                    setProdcutGroup(res.data.result);
                }
                else{
                    setProdcutGroup(res.data.result);
                }
                console.log(res.data.result.filter((x) => x.productgroup.divisionID == props.selectedDivision.divisionID))
                //setProdcutGroup(res.data.result.filter((x) => x.productgroup.divisionID == props.selectedDivision.divisionID));
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {produtGroup.map((cat,k)=>(
                <option value={cat.productgroup.productGroupID} selected={cat.productgroup.productGroupID == props.val}>{cat.productgroup.productGroupName}</option>
            ))}
        </>
    )
}



export const AddUom = (props) =>{
    const [isFirst, setIsFirst] = useState(true);
    const [produtUom, setProductUom] = useState([]);
    const { instance } = useMsal();
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_UOM, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setProductUom(res.data.result);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {produtUom.map((cat,k)=>(
                <option value={cat.productUOMID}>{cat.productUOMName}</option>
            ))}
        </>
    )
}

export const AddGrade = (props) =>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [produtGrade, setProductGrade] = useState([]);
    const [isGradeSet, setIsGradeSet] = useState(false)
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                let selectedList = props.selectedList
                console.log(selectedList, 'selected lsit')
                let result = res.data.result;
                let final = []
                if(selectedList.length > 0){
                    console.log('inside')
                    result.map((re,k)=>{
                        let status = false;
                        selectedList.map((list,l)=>{
                            if(re.productGradeID == parseInt(list.productGradeID)){
                                status = true
                            }
                        })
                        if(status == false){
                            final[k] = re;
                        }
                    })
                    console.log(final,'final')
                    setProductGrade(final)
                }
                else{
                    setProductGrade(res.data.result)
                }   
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {produtGrade.map((cat,k)=>(
                <option value={cat.productGradeID} selected={cat.productGradeID == parseInt(props.id)}>{cat.productGradeName}</option>
            ))}
        </>
    )
}


export const AddQuality = (props) =>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [produtQuality, setProductQuality] = useState([]);
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_QUALITY, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setProductQuality(res.data.result);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
        {
            produtQuality.map((quality,k)=>(
                <option value={quality.productQualityParameterID} selected={quality.productQualityParameterID == parseInt(props.id)}>{quality.productQualityParameterName}</option>
            ))
        }
        </>
    )
}

export const AddTrace = (props) =>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [produtTrace, setProducTrace] = useState([]);
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_TRACE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setProducTrace(res.data.result);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
        {
            produtTrace.map((trace,k)=>(
                <option value={trace.productTraceElementID} selected={trace.productTraceElementID == parseInt(props.id)}>{trace.productTraceElementName}</option>
            ))
        }
        </>
    )
}


export const ProductByGroup = (props) =>{
    const { instance } = useMsal();
    const [productList, setProductList] = useState([])
    const [isProductSet, setIsProduct] = useState(false)
    useState(()=>{
        console.log(isProductSet)
    },[isProductSet])
    useEffect(()=>{
        setIsProduct(false)
        console.log(props.groupId)
        let final_json =  {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.groupId //groupId
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_BY_GROUPID, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setProductList(res.data.result)
            setIsProduct(true)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[props])

    return(
        <>
            {productList.map((list,k)=>{
                    return(
                        <option value={list.productID} selected={list.productID == props.val}>{list.productName}</option>
                    )
                }) 
            }
        </>
    )
}