import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Moment from 'moment'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom';
import * as CONFIG from '../../../../helpers/config'
import * as FormFields from "../../../formcomponents"
import * as ButtonComponent from "../../../buttoncomponents"
import Navbar from '../../../navbar'
import Sidebar from '../../../sidebar/sidebar'
import BankAccountModal from './BankAccountModal';
import RoleCreationModal from './RoleCreationModal';
import ListBankAccount from './ListBankAccount'
import ListDepartments from './ListDepartments'
import { useMsal } from "@azure/msal-react";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

function ViewOrganisationSettings() {
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    const [issueDate, setIssueDate] = useState(false);
    const [expiryDate, setExpiryDate] = useState(false);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [cntID, setCntID] = useState(0);
    const [countryerror, setCountryError] = useState(false)
    const [stateID, setStateID] = useState(0);
    const [stateerror, setStateError] = useState(false)
    const [companyLogoName, setCompanyLogoName] = useState('')
    const [licencedoc, setLicenceDoc] = useState('')
    const [trndocument, setTrndocument] = useState('')
    const [key, setkey] = useState([])
    const [doc1, setdoc1] = useState(false)
    const [companyBankDetails, setCompanyBankDetails] = useState([])
    const [isBankMet, setIsBankMet] = useState(false);
    const [bankAccountModal, setBankAccountModal] = useState(false)
    const [bankAccountList, setBankAccountList] = useState([])
    const [rolesList, setRolesList] = useState([])
    const [bankForEdit, setBankForEdit] = useState([])
    const [edit, setEdit] = useState(false)
    const [company, setCompany] = useState()
    const [companyList, setCompanyList] = useState()
    const [view, setView] = useState(true)
    const [roleCreationModal, setRoleCreationModal] = useState(false)
    const [selectedRole, setSelectedRole] = useState()
    const [isExpand, setIsExpand] = useState(false);
    const [count, setCount] = useState()
    const [departmentList, setDepartmentList] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "InternalFlag": 1
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'Company')
                setCompanyList(res.data.result)
                //setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    useEffect(() => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "companyID": parseInt(CONFIG.CompanyID),
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0], 'Company')
                setCompany(res.data.result[0])
                setBankAccountList(res.data.result[0].company.companyBankDetails)
                // setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    useEffect(() => {
        let final_json = {
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_USER_ROLE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    useEffect(() => {
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID

        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DEPARTMENTS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setDepartmentList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    const formik = useFormik({
        initialValues:
        {
            companyID: parseInt(CONFIG.CompanyID),
            companyName: "",
            companyTelephone: "",
            contactEmail: "",
            cityName: '',
            companyStreet: '',
            companyBuilding: '',
            companyPoBox: '',
            companyLogo: '',
            companyLogoName: '',
            companyUnit: '',
            tradeLicenseNo: '',
            tradeName: '',
            issueDate: '',
            expiryDate: '',
            trnNo: '',
            stateID: '',
            countryID: '',
            is_Parent: true,
            companyTradedocumentDetail: [],
            companyTaxdocumentDetail: [],


        },
    })
    const handleBankAccountModal = () => {
        setBankAccountModal(true)
    }

    const handleRoleCreationModal = () => {
        setRoleCreationModal(true)
    }

    const handleBankAccountList = (data) => {
        if (edit == true) {
            console.log('as')
            console.log(key)
            let list = [...bankAccountList]
            for (let i = 0; i < list.length; i++) {
                if (data.key == list[i].key) {
                    console.log('if')
                    list[i] = data
                }

            }
            setBankAccountList(list)
            setEdit(false)
        }
        else {
            let list = [...bankAccountList]
            let key = 1
            list.push(data)
            list.map((doc, k) => {
                list[k].key = key
                key++

            })
            setkey(key)

            console.log(list)
            setBankAccountList(list)
        }
    }
    const close = () => {
        setBankAccountModal(false)
    }

    const closeRoleModal = () => {
        setRoleCreationModal(false)
    }

    const handleEdit = (e) => {
        console.log(e.target.id)
        for (let i = 0; i < bankAccountList.length; i++) {
            if (bankAccountList[i].key == parseInt(e.target.id)) {
                setBankForEdit(bankAccountList[i])
            }
        }
        setEdit(true)
        setBankAccountModal(true)
    }

    const handleActive = (data) => {
        console.log(data)
        console.log(bankAccountList)
        let remainingaccounts = bankAccountList.filter(x => x.key != data.key)
        remainingaccounts.push(data)
        setBankAccountList(remainingaccounts)
    }

    const handleInActive = (e) => {
        for (let i = 0; i < bankAccountList.length; i++) {
            if (bankAccountList[i].key == parseInt(e.target.id)) {
                bankAccountList[i].bankStatus = 'false'
            }
        }
    }

    const handleSaveOrganisationDetails = () => {
        console.log(formik.values)
        formik.values.countryID = parseInt(formik.values.countryID)
        formik.values.stateID = parseInt(formik.values.stateID)
        let final_json = {
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
            "pmsUserID": CONFIG.LoginID,
            ...formik.values,
            "companyBankDetails": bankAccountList,
        }
        console.log(final_json)
    }

    const handleEditOrganisation = (e, values) => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "companyID": values.company.companyID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0], 'Company')
                navigate('/organisation-setup', { state: { data: res.data.result[0], edit: true } });
                // setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }

    const handleAddOrganisation = () => {
        navigate('/organisation-setup');
    }

    const handlePermissionsClick = (e, data) => {
        setSelectedRole(data)
        setRoleCreationModal(true)
    }

    const toggleExpand = (e, values, k) => {
        console.log(values)
        setCount(k)
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "companyID": values.company.companyID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0], 'Company')
                setCompany(res.data.result[0])
                setBankAccountList(res.data.result[0].company.companyBankDetails)
                // setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
            })
        if (count == null) {
            setIsExpand(!isExpand)
        }
        else if (count != k) {

        }
        else {
            setIsExpand(!isExpand)
        }

    }

    useEffect(() => {

    }, [company, bankAccountList, count])


    const checkForKey = () => {
        console.log('inside check for key')
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "created_User": CONFIG.LoginID,
            "id": CONFIG.AccountSubscriptionID // SubscriptionID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.CHECK_KEY_IN_PROCESS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.is_InProcess == true) {
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'We have received your request. Our representative will contact you soon.',
                            html: '',
                            type: 'success',
                            confirmButtonText: 'Close',
                            confirmButtonClass: "btn btn-primary",

                        }).then(() => {

                        });
                }
                else {
                    if(res.data.result.key == null){
                        const json_new = {
                            "instanceID": CONFIG.PMSInstanceID,
                            "created_User": CONFIG.LoginID,
                            "SubscriptionId": CONFIG.AccountSubscriptionID
                          }
                        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_REQUESTED_COMPANY, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                MySwal.fire(
                                    {
                                        icon: 'success',
                                        title: 'We have received your request. Our representative will contact you soon.',
                                        html: '',
                                        type: 'success',
                                        confirmButtonText: 'Close',
                                        confirmButtonClass: "btn btn-primary",
                                    }).then(() => {
    
                                    });
    
                            }).catch((error) => {
                            })
                    }
                    else{
                        const { value: key } = MySwal.fire(
                            {
                                icon: '',
                                title: 'Please enter the key!',
                                input: 'text',
                                inputPlaceholder: 'Enter the Key',
                                confirmButtonText: 'Confirm',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then((result) => {
                                console.log(result)
                                console.log(key)
                                if (result.isConfirmed) {
                                    if(result.value == res.data.result.key){
                                        navigate('/organisation-setup');
                                    }
                                    else{
                                        MySwal.fire(
                                            {
                                                icon: 'danger',
                                                title: 'The key is not valid!',
                                                html: '',
                                                type: 'danger',
                                                confirmButtonText: 'Close',
                                                confirmButtonClass: "btn btn-primary",
                                            })
                                    }
                                }
                
                            })
                    }
                }
            }).catch((error) => {
            })

    }
    return (
        <div>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar />

                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between align-items-center'>
                        <div className='d-flex'>
                            <img src='assets/images/sidebaricons/Settings.svg' className='img-fluid' />
                            <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Organisation Settings</span>
                        </div>
                        <div>
                            <ButtonComponent.BlueBorderButton value='Add More Organizations' clickEvent={checkForKey} />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='add-edit-wrapper mt-4'>

                            {companyList != null ?
                                companyList.map((values, k) => (
                                    <>
                                        <Card className='mt-3'>
                                            <CardBody>
                                                <div className="m-md-3 p-md-4">
                                                    <div className='row'>
                                                        <>
                                                            <div className='row align-items-center'>
                                                                <div className='col-md-2 col-12  mb-3 mb-md-0'>
                                                                    <img src={values.company.companyLogo} className='company-logo-organisation-settings me-4' />
                                                                </div>
                                                                <div className='col-md-2  col-12  mb-3 mb-md-0'>
                                                                    <div className='font-weight-600 color-243448'>{values.company.companyName}</div>
                                                                    <div className='font-weight-400 color-697382'>
                                                                        {values.company.companyUnit}, &nbsp;
                                                                        {values.company.companyBuilding}, &nbsp;
                                                                        {values.company.companyStreet},&nbsp;
                                                                        <br />
                                                                        {values.company.companyPoBox},&nbsp;
                                                                        {values.company.cityName},&nbsp;

                                                                    </div>
                                                                </div>

                                                                <div className='col-md-2 col-12  mb-3 mb-md-0'>
                                                                    <div className='font-weight-400 color-697382'>Telephone</div>
                                                                    <div className='font-weight-600 color-243448'> {values.company.companyTelephone}</div>
                                                                    <div className='font-weight-400 color-697382 mt-2'>Email</div>
                                                                    <div className='font-weight-600 color-243448'> {values.company.contactEmail}</div>
                                                                </div>
                                                                <div className='col-md-2 '>
                                                                </div>
                                                                <div className='col-md-2 col-6  mb-3 mb-md-0'>
                                                                    <ButtonComponent.EditButtonAccount clickEvent={(e) => { handleEditOrganisation(e, values) }} />
                                                                </div>
                                                                <div className='col-md-2 col-6 mb-3 mb-md-0'>
                                                                    <div className='d-flex justify-content-around align-items-center'>
                                                                        <button className='no-border br-10' onClick={(e) => { toggleExpand(e, values, k) }}>
                                                                            {!isExpand && count == k ?
                                                                                <img src='assets/images/supports/supplyplan_button_down.svg' />
                                                                                :
                                                                                count == k ?
                                                                                    <img src='assets/images/supports/supplyplan_button_up.svg' />
                                                                                    :
                                                                                    <img src='assets/images/supports/supplyplan_button_down.svg' />
                                                                            }

                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        {isExpand &&

                                            company != null && count == k ?
                                            <div>
                                                <>
                                                    <div className='form-title font-16 font-weight-500 my-4 color-4F5466'>
                                                        Corporate Details
                                                    </div>
                                                    <Card className='bg-white my-3'>
                                                        <Card.Body>

                                                            <div className="m-md-3 p-md-4">
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>Trade Name</div>
                                                                            <div className='font-weight-600 color-243448'>{company.company.tradeName}</div>
                                                                        </div>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>License No.</div>
                                                                            <div className='font-weight-600 color-243448'>{company.company.tradeLicenseNo}</div>
                                                                        </div>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>Issued Date</div>
                                                                            <div className='font-weight-600 color-243448'>{Moment(company.company.issueDate).format('DD/MM/YYYY')}</div>
                                                                        </div>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>Expiry Date</div>
                                                                            <div className='font-weight-600 color-243448'>{Moment(company.company.expiryDate).format('DD/MM/YYYY')}</div>
                                                                        </div>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>License Document</div>
                                                                            {company.company.companyTradedocumentDetail.map((values) => (
                                                                                <div className='font-weight-600 color-243448'> <a download={values.name} href={values.base64}>{values.name}</a></div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-4'>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>TRN No.</div>
                                                                            <div className='font-weight-600 color-243448'>{company.company.trnNo}</div>
                                                                        </div>
                                                                        <div className='col-6 col-md-2 mb-3 mb-md-0'>
                                                                            <div className='font-weight-400 color-697382'>TRN Document</div>
                                                                            {company.company.companyTaxdocumentDetail.map((values) => (
                                                                                <div className='font-weight-600 color-243448'> <a download={values.name} href={values.base64}>{values.name}</a></div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <div className='form-title font-16 font-weight-500 my-4 color-4F5466'>
                                                        Bank Details & User Roles
                                                    </div>
                                                    <Tabs >
                                                        <Card className='tabs-wrapper'>
                                                            <TabList>
                                                                <Tab>Bank Accounts</Tab>
                                                                <Tab>Roles</Tab>
                                                                <Tab>Departments</Tab>
                                                            </TabList>
                                                        </Card>
                                                        <TabPanel className='mt-1-rem'>
                                                            <Card className='bg-white'>
                                                                <Card.Body>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div>
                                                                                <img src='assets/images/bankIcon.svg' alt='bank icon' />
                                                                            </div>
                                                                            <div className='font-weight-600 font-16 ms-3 pt-1 px-2'>
                                                                                Bank Accounts
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    {bankAccountList != null && bankAccountList.length > 0 ?

                                                                        <div class="col-lg-12 p-md-4 p-2">
                                                                            <div class="table-responsive-sm ">
                                                                                <table className="table tCustom table-text">
                                                                                    <thead className='p-1-rem'>
                                                                                        <tr className='text-uppercase'>
                                                                                            <th>Account Name</th>
                                                                                            <th>Account No.</th>
                                                                                            <th>Bank Name</th>
                                                                                            <th>IBAN No.</th>
                                                                                            <th>Account Type</th>
                                                                                            <th>Status</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {bankAccountList.map((values, k) => (
                                                                                            <ListBankAccount values={values} handleEdit={handleEdit} handleActive={handleActive} handleInActive={handleInActive} view={view} fromView={true} />
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>



                                                                        :
                                                                        <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                                            <div>
                                                                                <div className='font-weight-600 color-243448'>No Bank Accounts Created</div>
                                                                            </div>
                                                                        </div>}
                                                                </Card.Body>
                                                            </Card>

                                                        </TabPanel>
                                                        <TabPanel className='mt-1-rem'>
                                                            <Card className='bg-white'>
                                                                <Card.Body>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div>
                                                                                <img src='assets/images/userRoles.svg' alt='bank icon' />
                                                                            </div>
                                                                            <div className='font-weight-600 font-16 ms-3 pt-1'>
                                                                                User Roles & Permissions
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    {rolesList != null && rolesList.length > 0 ?


                                                                        <div class="col-lg-12 p-md-4 p-2">
                                                                            <div class="table-responsive-sm ">
                                                                                <table className="table tCustom table-text">
                                                                                    <thead className='p-1-rem'>
                                                                                        <tr className='text-uppercase'>
                                                                                            <th>Role Name</th>
                                                                                            <th>Description</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {rolesList.map((values, k) => (
                                                                                            <tr className='align-middle'>
                                                                                                <td >
                                                                                                    {values.userRoleType}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {values.description}
                                                                                                </td>
                                                                                                <td style={{ float: 'right' }}>
                                                                                                    <ButtonComponent.PermissionsButton value='Permissions' clickEvent={(e) => { handlePermissionsClick(e, values) }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>


                                                                        :
                                                                        <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                                            <div>
                                                                                <div className='font-weight-600 color-243448'>No Roles Created</div>
                                                                            </div>
                                                                        </div>}
                                                                </Card.Body>
                                                            </Card>

                                                        </TabPanel>
                                                        <TabPanel className='mt-1-rem'>
                                                            <Card className='bg-white'>
                                                                <Card.Body>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex align-items-center px-md-2 px-2'>
                                                                            <div>
                                                                                <img src='assets/images/departmentIcon.svg' alt='Department Icon' />
                                                                            </div>
                                                                            <div className='font-weight-600 font-16 ms-3 pt-1'>
                                                                                Departments List
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {departmentList != null && departmentList.length > 0 ?
                                                                        <div class="col-lg-12 p-md-4 p-2">
                                                                            <div class="table-responsive-sm ">
                                                                                <table className="table tCustom table-text">
                                                                                    <thead className='p-1-rem'>
                                                                                        <tr className='text-uppercase'>
                                                                                            <th>Department Name</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {departmentList.map((values, k) => (
                                                                                            <ListDepartments values={values} fromView={true} />
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>



                                                                        :
                                                                        <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                                            <div>
                                                                                <div className='font-weight-600 color-243448'>No Departments Created</div>
                                                                            </div>
                                                                        </div>}
                                                                </Card.Body>
                                                            </Card>
                                                        </TabPanel  >

                                                    </Tabs>
                                                </>
                                            </div>
                                            :
                                            ''
                                        }
                                    </>
                                ))

                                :
                                ''
                            }

                            {roleCreationModal == true ?
                                <RoleCreationModal closeRoleModal={closeRoleModal} handleBankAccountList={handleBankAccountList} companyID={formik.values.companyID} selectedRole={selectedRole} fromView={true} />
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOrganisationSettings