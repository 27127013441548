import React from 'react'
import { BlueButton } from '../../components/buttoncomponents'

export default function ContactSubList(props) {
    return (
        <tr>
            <td>
            <div className = 'align-items-center py-2 cusname'><span className='font-weight-500 font-16'>{props.data.contactname}</span>
                <br/>
                <span className='font-weight-500 font-14 cusweight'>{props.data.designation}</span></div>
            </td>
            <td><div className='align-items-center py-4 font-14 cusname'>{props.data.institute}</div></td>
            <td><div className='align-items-center py-4 font-weight-500 font-14'>{props.data.contactemail} <span className='mx-1'>|</span> {props.data.contactno}</div></td>
            <td><div className='align-items-center py-4 font-weight-500 font-14'>{props.data.contacttype}</div></td>
            <td>
            <div className = 'align-items-center d-flex py-2 '><BlueButton value='View Details' clickEvent={()=>{props.openContactInstitution(props.data)}}/></div>
            </td>
        </tr>
    )
}
