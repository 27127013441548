import React, { useEffect,useState  } from 'react'
import axios from 'axios'
import { Row,  Col, Form, FloatingLabel } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents';
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton, BackButton } from '../../components/buttoncomponents'
import List from './List'
import * as CONFIG from "../../helpers/config"
import { graphConfig, graphRequest, loginRequest } from "../../authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import * as FormFields from "../../components/formcomponents"
import { Divisions, Roles } from '../../helpers/API/Api';
import EditModal from './EditModal';

export default function Index() {
    const [userslist, setuserslist] = useState([])
    const [userfilterlist, setuserfilterlist] = useState([])
    const [azureusers, setAzureUsers] = useState([])
    const [azurefilter, setazurefilter] = useState([])
    const [modal, setModal] = useState(false)
    const [userlistmodal, setuserlistmodal] = useState(false)
    const [graphData,setGraphData] = useState([])
    const [count, setcount] = useState(0)
    const [addUserModal, setAddUserModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState()
    const [selectedUserEdit, setSelectedUserEdit] = useState()
    const [edit, setEdit] = useState(false)
    const { instance, accounts } = useMsal();
    const [roles, setRolesList] = useState([]);
    const [division, setDivisionList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);

    const [checkedDivision, setCheckedDivision] = useState([])
    const [checkedNameDivision, setCheckedNameDivision] = useState([])
    const [checkedRole, setCheckedRole] = useState([])
    const [checkedNameRole, setCheckedNameRole] = useState([])

    const [deleteItem, setDeleteItem] = useState([])
    const [validation, setValidation] = useState([])
    

    useEffect(()=>{
        console.log(userlistmodal)
    },[userlistmodal])

    const toggle = ()=>{
        let emptyList = []
        setCheckedDivision(emptyList)
        setCheckedNameDivision(emptyList)
        setCheckedRole(emptyList)
        setCheckedNameRole(emptyList)
        setModal(false)
        setAddUserModal(false)
    }
    useEffect(()=>{
      getUsersList()
      getAzureUsersList()
    },[])
    const formik = useFormik({
      initialValues:
      {
          fullName: '',
          userEmail:  '',
          designation:  '',
          userAvatar: '',
          divisionID : [],
          Roles: []
      }
  }) 
    useEffect(()=>{
    },[userslist])

    useEffect(()=>{
      console.log(graphData)
    },[graphData])

    useEffect(()=>{
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result, 'Company')
            localStorage.setItem('CompanyID', parseInt(res.data.result[0].company.companyID));
            //setRolesList(res.data.result)
            //setWorkFlowList(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        }) 
      },[])

    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_USER_ROLE, {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "SolutionID": CONFIG.SolutionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setRolesList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_DIVISION_, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setDivisionList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    const getAzureUsersList = ()=>{
    //   callMsGraph(accessToken).then(response => setGraphData(response));
        const json_list ={
            "InstanceID" : CONFIG.PMSInstanceID,
            "AccountSubscriptionID": CONFIG.AccountSubscriptionID
        }
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ALL_AZURE_USERS, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setAzureUsers(res.data.result)
            setazurefilter(res.data.result)
            setcount(res.data.result.length)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const getUsersList = ()=>{
        const json_list ={
          "PmsInstanceID" : CONFIG.PMSInstanceID,
          "PmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
          "SolutionID" : CONFIG.SolutionID
        }
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ALL_USERS, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setuserslist(res.data.result)
            setuserfilterlist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const handleSaveEvent = () => {
        console.log(checkedRole)
        console.log(checkedNameRole)
        console.log(checkedDivision)
        console.log(checkedNameDivision)
        let nameArray = []
        let objectConvertRoles = []
        let objectConvertDivision = []
        //let final = {'customerPaymentTypeID':''}
        checkedRole.map((list, k)=>{
            objectConvertRoles[k] = {'roleID':list}

        })
        checkedNameRole.map((list, k)=>{
            nameArray[k] = {'userRoleType':list}

        })
        checkedDivision.map((list, k)=>{
            objectConvertDivision[k] = {'divisionID':list,
                                     'companyID':parseInt(CONFIG.CompanyID) }

        })
        checkedNameDivision.map((list, k)=>{
            nameArray[k] = {'divisionID':list}

        })
        //formik.values.companyID = companyList[0].company.companyID
        console.log(objectConvertRoles)
        console.log(objectConvertDivision)
        console.log(nameArray)
        console.log(formik.values)
        
        let indexFirst = formik.values.fullName.lastIndexOf(' ')
        let firstNameInitial = formik.values.fullName.charAt(0)
        let LastNameInitial = formik.values.fullName.charAt(indexFirst + 1,)
        formik.values.userAvatar = firstNameInitial + LastNameInitial
        let final_json = {
            "userFullName": formik.values.fullName,
            "userDesignation": formik.values.designation,
            "userAvatar":  formik.values.userAvatar,
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
            "roleIDs":objectConvertRoles,
            "userCompanyDivisionMapping":objectConvertDivision,
            "is_FirstLogin": true,
            "userEmail": formik.values.userEmail,

        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_USER, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result, 'workflows')
            getUsersList()
            toggle()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
        console.log(final_json)
    }

    const userlisttoggle = () =>{
        // setuserlistmodal(!userlistmodal)
        toggle()
        setAddUserModal(true)
        setModal(true)
    }

    const handleAddUser = (e, data) => {
        console.log('clicked')
        console.log(data)
        userlisttoggle()
        toggle()
        setSelectedUser(data)
        setAddUserModal(true)
        setModal(true)
        formik.values.fullName = data.displayName
        formik.values.userEmail = data.mail
    }
    const handleUserName = () => {
        if(edit == true && selectedUserEdit != null){
            return(
                <div>
                   <b>{selectedUserEdit.user.userAvatar}</b>
                </div>
            )
        }
        else{
            let indexFirst = selectedUser.displayName.lastIndexOf(' ')
            let firstNameInitial = selectedUser.displayName.charAt(0)
            let LastNameInitial = selectedUser.displayName.charAt(indexFirst + 1,)
            formik.values.userAvatar = firstNameInitial + LastNameInitial
            return(
                <div>
                   <b>{firstNameInitial}{LastNameInitial}</b>
                </div>
            )
        }
    }

    const handleCheck = (roles,) => {
        
    }

    const getCheckedDivision = (data) => {
        setCheckedDivision(data)
    }

    const getCheckedNameDivsion = (data) => {
        setCheckedNameDivision(data)
    }

    const getCheckedRole = (data) => {
        setCheckedRole(data)
    }

    const getCheckedNameRole = (data) => {
        setCheckedNameRole(data)
    }
    const toggleEdit=()=>{
        setEdit(false)
    }
    const handleEdit = (data) => {
        console.log(data, 'edit index')
        setSelectedUserEdit(data)
        setEdit(true)
        formik.values.fullName =  data.user.userFullName
        formik.values.userEmail =  data.user.userEmail 
        formik.values.designation =  data.user.userDesignation 
        formik.values.userAvatar = data.user.userAvatar 
        console.log(formik.values)
    }

    const close = () => {

    }

    const refreshList = () => {
        getUsersList()
    }
    
    const handleSearchAzure = (e)=>{
        let val = e.target.value
        let filter = azureusers.filter(x=> x.displayName.toLowerCase().includes(val.toLowerCase()) ||
            x.mail.toLowerCase().includes(val.toLowerCase())
        )
        setazurefilter(filter)
    }
    const handleSearch = (e)=>{
        let val = e.target.value
        let filter = userslist.filter(x=> x.user.userFullName.toLowerCase().includes(val.toLowerCase()) ||
            x.user.userEmail.toLowerCase().includes(val.toLowerCase())
        )
        setuserfilterlist(filter)
    }
    return (
      <div className='main-wrapper inner-wrapper d-flex'>
            
          <Sidebar id={1}/>
          <div className='width-100'>
              <Navbar/>
              <div className='d-flex inner-page-header justify-content-between'>
                  <div className='d-flex align-self-center'>
                      <img src='assets/images/pageicons/User.svg' className='imguserIcon'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>USERS</span>
                  </div>
                  <div className='d-flex'>
                  <div className='me-3'>
                                <FormFields.InputField
                                    label='Search'
                                    onChange={handleSearch}
                                />
                    </div>
                    <div>
                    <AddNewButton  value='Add New User' class="bg-white " clickEvent={userlisttoggle}/>
                  </div>
                  </div>
              </div>
              
              <Modal isOpen={userlistmodal} toggle={userlisttoggle} className='add-user-modal' >
                    <ModalHeader toggle={userlisttoggle} className='p-40'>
                        <div className = 'd-flex justify-content-between '>
                            <div className='font-16 font-weight-600 color-707895'>
                                ADD NEW USER<span className='count_badge'>{count}</span>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody className=''style={{ overflowY: 'scroll' }}>
                            <div class="col-lg-12 p-2">
                                <div class="table-responsive-sm ">
                                    <div>
                                        <FormFields.InputField
                                            md={4}
                                            label='Search'
                                            onChange={handleSearchAzure}
                                        />
                                    </div>
                                    <table className="table tCustom userList table-text">
                                        <thead>
                                            <tr className='text-uppercase d-flex'>
                                                <th className='flex-fill col-2'>Name</th>
                                                <th className='flex-fill  col-2'>Email</th>
                                                <th className='flex-fill'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {azurefilter.map((list,k)=>{
                                                    return(
                                                        <tr className='d-flex'>
                                                            <td className='flex-fill col-2'>{list.displayName}</td>
                                                            <td className='flex-fill  col-2'>{list.mail}</td>
                                                            <td className='flex-fill text-right'>
                                                                <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600" onClick={(e)=>{handleAddUser(e, list)}}>Add User</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    </ModalBody>
              </Modal>
              {addUserModal == true ? 
                          <Modal isOpen={modal} toggle={toggle} className='add-user-modal'>
                          <ModalHeader toggle={toggle} className='p-40'>
                              <div className = 'd-flex justify-content-between '>
                                  <div className='font-16 font-weight-600 color-707895'>
                                      ADD NEW USER
                                  </div>
                              </div>
                          </ModalHeader>
                          <ModalBody className=''>
                            {/* <div class="col-lg-12 p-2">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>name</th>
                                                        <th>email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                               
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                            {/* {selectedUser != null ? 
                            
                                :
                                
                            ''
                            } */}

<>
                                    <div className = 'd-flex justify-content-between m-2'>
                                    <div className = 'd-flex align-items-center'>
                                    {/* <div  className='d-flex align-items-center justify-content-center avatar-add-user'>
                                        {handleUserName()}
                                    </div>
                                    <div className = 'ms-2 font-weight-600 font-16'>
                                        {selectedUser.displayName}
                                    </div> */}
                                    </div>
                                    <div>

                                    </div>
                                    </div>
                                    <Row className='pt-2-rem'>
                                        <FormFields.InputField
                                            name='fullName'
                                            label='Full Name'
                                            type='text'
                                            val={formik.values.fullName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                            <FormFields.InputField
                                            name='userEmail'
                                            label='Email'
                                            type='text'
                                            val={formik.values.userEmail}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    
                                    </Row>
                                    <Row className='pt-1-rem'>
                                    <Col md={6} lg={6} >
                                        <FormFields.InputField
                                            name='designation'
                                            label='Designation'
                                            type='text'
                                            val={formik.values.designation}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </Col>
                                    <Col md={6} lg={6} className='usersForm' >
                                        <Multiselect
                                            className='form-control'
                                            displayValue="key"
                                            onKeyPressFn={function noRefCheck(){}}
                                            onRemove={function noRefCheck(roles,data){
                                                console.log(checkedDivision, checkedNameDivision)
                                                var updatedList = [...checkedDivision];
                                                var updatedNameList = [...checkedNameDivision]
                                                var deleteItemList = [...deleteItem]
                                                var validatedList = [...validation]
                                                updatedList.splice(checkedDivision.indexOf(data.cat), 1);
                                                updatedNameList.splice(checkedNameDivision.indexOf(data.key), 1);
                                                getCheckedDivision(updatedList);
                                                getCheckedNameDivsion(updatedNameList)
                                            }}
                                            onSearch={function noRefCheck(){}}
                                            onSelect={function noRefCheck(roles,data){
                                                console.log(checkedDivision, checkedNameDivision)
                                                var updatedList = [...checkedDivision];
                                                var updatedNameList = [...checkedNameDivision]
                                                var deleteItemList = [...deleteItem]
                                                var validatedList = [...validation]
                                                updatedList = [...checkedDivision, parseInt(data.cat)];
                                                updatedNameList = [...checkedNameDivision, data.key]
                                                getCheckedDivision(updatedList);
                                                getCheckedNameDivsion(updatedNameList)
                                            }}
                                            options={division.map((list,k)=>(
                                                {
                                                    cat: list.divisionID,
                                                    key: list.divisionName
                                                }
                                            ))}
                                            showCheckbox
                                            placeholder = 'Divisions'
                                            
                                        />
                                        </Col>
                                    </Row>
                                    <Row className='pt-1-rem'>
                                    <Col md={6} lg={6} >
                                    <Multiselect className='form-control'
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck(){}}
                                        onRemove={function noRefCheck(roles,data){
                                            console.log(checkedRole, checkedNameRole)
                                            var updatedList = [...checkedRole];
                                            var updatedNameList = [...checkedNameRole]
                                            var deleteItemList = [...deleteItem]
                                            var validatedList = [...validation]
                                            updatedList.splice(checkedRole.indexOf(data.cat), 1);
                                            updatedNameList.splice(checkedNameRole.indexOf(data.key), 1);
                                            getCheckedRole(updatedList);
                                            getCheckedNameRole(updatedNameList)
                                        }}
                                        onSearch={function noRefCheck(){}}
                                        onSelect={function noRefCheck(roles,data){
                                            console.log(checkedRole, checkedNameRole)
                                            var updatedList = [...checkedRole];
                                            var updatedNameList = [...checkedNameRole]
                                            var deleteItemList = [...deleteItem]
                                            var validatedList = [...validation]
                                            updatedList = [...checkedRole, parseInt(data.cat)];
                                            updatedNameList = [...checkedNameRole, data.key]
                                            getCheckedRole(updatedList);
                                            getCheckedNameRole(updatedNameList)
                                        }}
                                        options={roles.map((list,k)=>(
                                            {
                                                cat: list.userRoleID,
                                                key: list.userRoleType
                                            }
                                        ))}
                                        showCheckbox
                                        placeholder = 'Roles'
                                        
                                    />
                                    </Col>
                                    </Row>
                                    </>
                           
                          </ModalBody>
                          <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                              <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                              <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} /></span>
                          </ModalFooter>
                      </Modal>
              :
              ''
              }
              {edit == true && selectedUserEdit != null ? 
              <EditModal edit={edit} selectedUserEdit = {selectedUserEdit} close = {close} division={division} roles = {roles} toggleEdit={toggleEdit} refreshList = {refreshList}/>
              :
              ''
              }
              <div className='inner-page-content'>
                
                  <div className='bg-white p-md-4 br-8 scrollContract'>
                      <List data={userfilterlist} handleEdit={handleEdit} refreshList = {refreshList}/>
                  </div>
              </div>
          </div>
          
      </div>
    )
}
