import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import {Row, Col, Form, Card} from "react-bootstrap"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

export default function PostFixtures(props) {
    const { instance } = useMsal();
    const location = useLocation()
    let navigate = useNavigate();
    const  tradeData  = props.data
    const tab = props.tab
    const [doc1, setdoc1] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const MySwal = withReactContent(Swal)
    const [tradedata, settradedata] = useState([])
    const [isDataAvailable, setIsDataAvailable] = useState(false);

    useEffect(()=>{
        getTradeData()
    },[])

    const formik = useFormik({
        initialValues:{
            tradePostVasselFixtureCheckListID: 0,
            vesselName: '',
            eTALoadPort: '',
            ETADischargePort: '',
            VesselDespatch : '',
            VesselDemurrage : '',
            is_NominateVesselSupplier: false,
            is_NominateVesselCustomer: false,
            is_CFSupplier: false,
            is_DraftDocumentChecked: false,
        }
    })
    useEffect(()=>{
        const json_list ={
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID // trade id
          }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_POST_VESSEL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            if(res.data.result.length>0){
                formik.values.tradePostVesselFixtureCheckListID = res.data.result[0].tradePostVesselFixtureCheckListID
                formik.values.is_NominateVesselSupplier= res.data.result[0].is_NominateVesselSupplier
                formik.values.is_NominateVesselCustomer= res.data.result[0].is_NominateVesselCustomer
                formik.values.is_CFSupplier= res.data.result[0].is_CFSupplier
                formik.values.is_DraftDocumentChecked= res.data.result[0].is_DraftDocumentChecked
                console.log(formik.values.is_DraftDocumentChecked)
                setIsEdit(true)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[])
    const getTradeData = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                settradedata(res.data.result[0]);
                formik.values.vesselName= res.data.result[0].tradedetails.vesselName
                formik.values.eTALoadPort= res.data.result[0].tradedetails.etaLoadPort
                formik.values.ETADischargePort= res.data.result[0].tradedetails.etaDischargePort
                formik.values.VesselDespatch= res.data.result[0].tradedetails.vesselDespatch
                formik.values.VesselDemurrage= res.data.result[0].tradedetails.vesselDemurrage
               
                setIsDataAvailable(true)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }
    const savePost = () =>{
        const json_list = {
            "tradeID": tradeData.tradeID,
            "is_NominateVesselSupplier": formik.values.is_NominateVesselSupplier,
            "is_NominateVesselCustomer": formik.values.is_NominateVesselCustomer,
            "is_CFSupplier": formik.values.is_CFSupplier,
            "is_DraftDocumentChecked": formik.values.is_DraftDocumentChecked,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        if(isEdit){
            json_list.tradePostVesselFixtureCheckListID = formik.values.tradePostVesselFixtureCheckListID
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_POST_VESSEL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Post-vessel fixture details updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_POST_VESSEL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Post-vessel fixture details added successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='w-100'>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>POST-VESSEL FIXTURE CHECKLIST</span>
                    </div>
                </div>
                <div className=' mx-4'>
                    <div className='add-edit-wrapper'>
                        <div className='d-flex justify-content-between'>
                            <Row className=' w-100'>
                            <div className='card card-body p-4 br-8 mb-4'>
                                    <Form>
                                        <Row>
                                            <FormFields.InputField
                                                md={3}
                                                name='vesselName'
                                                label='Vessel Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.vesselName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={true}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='eTALoadPort'
                                                label='ETA - Load Port'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.eTALoadPort}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={true}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='ETADischargePort'
                                                label='ETA - Destination Port'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.ETADischargePort}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={true}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='VesselDespatch'
                                                label='Despatch'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.VesselDespatch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={true}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='VesselDemurrage'
                                                label='Demmurage'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.VesselDemurrage}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={true}
                                            />
                                        </Row>
                                    </Form>
                                </div>
                                <Card style={{background:'#fff'}}>
                                    <Card.Body className=' mb-3-rem' style={{overflowY: 'scroll'}}>
                                        <Col md={12}>
                                            <Row className='border-bottom-1 py-4'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_NominateVesselSupplier'
                                                        label='Vessel - Supplier acceptance?'
                                                        checked={formik.values.is_NominateVesselSupplier}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_NominateVesselCustomer'
                                                        label='Vessel - Customer acceptance?'
                                                        checked={formik.values.is_NominateVesselCustomer}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3  font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_CFSupplier'
                                                        label='C&F - Documents Sent to Customers for Obtaining cargo Over?'
                                                        checked={formik.values.is_CFSupplier}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 py-4'>
                                                <Col md={7}>
                                                    <Form.Check
                                                        className='mr-20 ms-3 font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_DraftDocumentChecked'
                                                        label='DRAFT Documents checked prior Vessel loading?'
                                                        checked={formik.values.is_DraftDocumentChecked}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}  
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card.Body>
                                    <Card.Footer className='p-2-rem d-flex'>
                                        
                                        <span className='pl-10'><BlueButton value='Save' clickEvent={savePost} /></span>
                                    </Card.Footer>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
