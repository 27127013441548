import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { AddNewButton } from '../../../components/buttoncomponents'
import Informations_form from '../common/informations_form'
import Listing from '../common/listing'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config"
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [isAddCategory, setIsAddCategory] = useState(false);
    const [custCat, setCustCat] = useState([]);
    const [length, setLength] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState([]);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState('');
    const [bugFix, setBugFix] = useState(false);
    const addCategory = ()=>{
        setIsAddCategory(!isAddCategory);
    }
    
    useEffect(()=>{
       if(isFirst){
            getlist()
       }
       
       setIsFirst(false)
    })

    const getlist = ()=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id":0
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_INDUSTRY_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setCustCat(res.data.result);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const saveCategory = (data)=>{
        console.log(data)
        const json_list = isEdit && bugFix == true ?{
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "Modified_User" : CONFIG.LoginID,
            "customerIndustryTypeID": data.id,
            "industryTypeName": data.data
        }
        :
        {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "industryTypeName" : data.data,
            "created_User" : CONFIG.LoginID
        }
       if(!isEdit){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_CUSTOMER_INDUSTRY_TYPE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode == '303002'){
                    setError(true)
                    setErrorData('Customer Industry already exists');
                }
                else if(res.data.responseCode == '303001'){
                    setError(true)
                    setErrorData('Customer Industry invalid. Please try again!');
                }
                else{
                    setError(false)
                }
                setIsEdit(false)
                getlist()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
       }
       else{
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.EDIT_CUSTOMER_INDUSTRY_TYPE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode == '303002'){
                    setError(true)
                    setErrorData('Customer Industry already exists');
                }
                else if(res.data.responseCode == '303001'){
                    setError(true)
                    setErrorData('Customer Industry invalid. Please try again!');
                }
                else{
                    setError(false)
                }
                console.log(res)
                setIsEdit(false)
                getlist()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
       }
    }

    const edit = (e)=>{
        const id = e.target.id;
        let data = custCat.filter(x=> x.customerIndustryTypeID == id);
        setSelectedData(data);
        setBugFix(true)
        setIsEdit(true)
        addCategory();
    }
    const deletedata = (e)=>{
        const id = parseInt(e.target.id);
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.DELETE_CUSTOMER_INDUSTRY_TYPE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getlist()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const handleBugFix = () => {
        console.log('clicked bug fix')
        setBugFix(false)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
                
                <Sidebar id='2.3' isOpenid={2}/>
                <div className='width-100'>
                    <Navbar/>
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/pageicons/customericon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>CUSTOMER INDUSTRY</span>
                        </div>
                    </div>
                    <div className='br-8 mx-4'>
                        <div className=''>
                                <div className='p-2-rem col-md-7 card card-body'>
                                    <div className='d-flex justify-content-end'>
                                        <AddNewButton value='Add New Industry' class='bg-white ' clickEvent={addCategory}/>
                                    </div>
                                    {isAddCategory && 
                                        <Informations_form 
                                            isOpen={isAddCategory} 
                                            title={'ADD NEW CUSTOMER INDUSTRY'} 
                                            buttontext ={isEdit && bugFix == true ?'Edit Industry':'Add Industry'}
                                            inputLabel = 'Customer Industry'
                                            onSave={saveCategory}
                                            toggle={addCategory}
                                            id={isEdit && bugFix == true ? selectedData[0].customerIndustryTypeID:''}
                                            name={isEdit && bugFix == true ? selectedData[0].industryTypeName:''}
                                            isEdit={isEdit}
                                            isBugFix={bugFix}
                                            handleBugFix = {handleBugFix}
                                        />
                                    }
                                    {error &&
                                        <div className='input_error'>{errorData}</div>
                                    }
                                    <table className="table table-text mt-1-rem">
                                        <thead>
                                            <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                <th>Customer Industry</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {custCat.map((cat,k)=>(
                                                <Listing key={k} id={cat.customerIndustryTypeID} name={cat.industryTypeName} length={length} edit={edit} delete={deletedata}/>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                </div>
                

        </div>
    )
}
