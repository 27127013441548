import React, { useEffect, useState, useRef} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import {Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import NumberFormat from 'react-number-format';
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { BackButton, BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import * as CONFIG from "../.././../helpers/config"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useMsal } from "@azure/msal-react";
import { useReactToPrint } from 'react-to-print';

export default function GenerateSupplierProformaInvoice(props) {
    const { instance } = useMsal();
    const [supplierProformaInvoiceData, setSupplierProformaInvoiceData] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const componentRef = useRef();
    console.log(props)
    const location = useLocation()
    let navigate = useNavigate();
    const tradeData = props.data
    console.log(tradeData)
    const tab = props.tab
    useEffect(()=> {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
               console.log(res.data.result[0])
               console.log((parseInt((res.data.result[0].tradedetails.tradeProductDetail[0].productSellingPrice) * (res.data.result[0].tradedetails.tradeProductDetail[0].quantity))))
               setSupplierProformaInvoiceData(res.data.result[0])
               res.data.result[0].tradedetails.tradeProductDetail.map((list, k) => (
                listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
               ))
               console.log(listValues)
               let sum = 0
               let sumTax = parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax1)) + parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax2))
               for (let i = 0; i < listValues.length; i++) {
                sum += listValues[i]
            }
            console.log(sum)
            setSubTotal(sum)
            setTotalTax(sumTax)
            setOverAllTotal(sum + sumTax)
            props.refreshList()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        
        
    },[])
    const back = () => {
        if(props.invoiceList != null && props.invoiceList == true){
            props.handleClose()
        }  
    }


        const pageStyle = `
        @page {
        size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
        @media print {
        div.page-footer {
        position: fixed;
        bottom:0mm;
        width: 100%;
        height: 50px;
        font-size: 15px;
        color: #fff;
        /* For testing */
        background: red;
        opacity: 0.5;

        page-break-after: always;
        }
        .page-number:before {
            /* counter-increment: page; */
            content: "Pagina "counter(page);
        }


        }
        body {
        marginBottom:50px
        }
    `;
    const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle
    });
    const printDocument = () => {

    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className=''>
                <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-3'>
                    <div className = 'd-flex'>
                        <div className='mx-2'>
                            <BlueBorderButton value="PRINT" clickEvent={handlePrint} />
                        </div>
                    </div>
                </div>
                <div id='download_section' ref={componentRef} >
                {supplierProformaInvoiceData != null ?
                    <>
                    <Row className='mx-4'>
                        <div className='d-flex justify-content-end'>
                            <img src='assets/images/logo.svg' />
                        </div>
                        <div className='font-weight-600 d-flex justify-content-center uline'>
                            SUPPLIER PURCHASE ORDER
                        </div>
                        <div className='d-flex justify-content-between mt-1-rem'>
                            <div>
                                <div className='font-weight-500'>
                                    SUPPLIER PURCHASE ORDER NUMBER: {supplierProformaInvoiceData.tradedetails.supplierPurchaseOrderNo}
                                </div>
                            </div>
                            <div>
                                <div className='font-weight-500'>
                                    PURCHASE ORDER DATE: {Moment(supplierProformaInvoiceData.tradedetails.supplierPurchaseOrderDate).format("DD/MM/YYYY")}
                                </div>
                                <div className='font-weight-500 d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>DUE DATE:{Moment(props.data.invoiceDueDate).format('DD/MM/YYYY')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='invoice_border  p-1-rem mt-1-rem'>
                            <div className='font-weight-500'>
                                NAME AND ADDRESS OF SUPPLIER:
                            </div>
                            <div>
                                {supplierProformaInvoiceData.tradedetails.supplier.supplierName}<br />
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredUnitNo}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredBuildingName}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredStreetName}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredPostBox}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredCityName} &nbsp;<br />
                                {supplierProformaInvoiceData.tradedetails.supplier.telephoneNo}<br />
                                {supplierProformaInvoiceData.tradedetails.supplier.website}<br />
                            </div>
                            <div className='font-weight-600 d-flex justify-content-center'>
                                CONTRACT NUMBER : {supplierProformaInvoiceData.tradeNo}
                            </div>
                        </div>
                        <table className='invoice_border p-3-rem width-100l' style={{ border: '1px solid black' }}>
                            <thead className='invoice_border'>
                                <tr className='width-100l' style={{ border: '1px solid black' }}>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>S.NO</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>DESCRIPTION OF GOODS</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>QUANTITY</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>UNIT PRICE</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                            {supplierProformaInvoiceData != null &&
                                                supplierProformaInvoiceData.tradedetails.tradeProductDetail.map((list, k) => (
                                <tr>
                                    <td style={{ border: '1px solid black' }}>
                                        1
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <div className='font-weight-600 uline'>{list.productName}</div>
                                        <div className='mt-1-rem font-weight-500'>COUNTRY OF ORIGIN : {supplierProformaInvoiceData.tradedetails.loadPortCountry} </div>
                                       
                                        <div className='mt-1-rem font-weight-500'>PORT OF LOADING : {supplierProformaInvoiceData.tradedetails.tradeEnquiry.loadPort}, {supplierProformaInvoiceData.tradedetails.loadPortCountry}</div>
                                        <div className='font-weight-500'>PORT OF DISCHARGE : {supplierProformaInvoiceData.tradedetails.tradeEnquiry.loadPort}, {supplierProformaInvoiceData.tradedetails.tradeEnquiry.destinationPort}</div>
                                        <div className='font-weight-500'>TRADE TERM : {supplierProformaInvoiceData.tradedetails.tradeEnquiry.loadPort}</div>
                                        <div className='font-weight-500'>VESSEL NAME : {supplierProformaInvoiceData.tradedetails.vesselName}</div>
                                        <div className='font-weight-500'>UNIT PRICE : {list.productBuyingPrice} USD</div>
                          
                                        <div className='font-weight-500'>
                                            {props.data.invoiceRemarks}
                                        </div>
                                        {/* {props.data.is_FinalInvoice == true &&
                                            <>
                                                <div className='parameters_div'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>ITEM</th>
                                                                <th>ACTUAL RESULTS</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {props.data.actualFinalParameters.map((qt, k) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{qt.parameterName}</td>
                                                                        <td>
                                                                            {qt.parameterPer} %
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='mt-1-rem d-flex justify-content-between'>
                                                    <div className='uline'> DETAILS OF CALCULATION OF UNIT PRICE</div> <br />
                                                </div>

                                                <div className='parameters_div'>
                                                    {props.data.actualFinalParameters.map((qt, k) => {
                                                        if (qt.parameterAdjStatus) {
                                                            return (
                                                                <>
                                                                    <div>{qt.parameterName} :</div>
                                                                    <div>
                                                                        {qt.parameterFormula} = {qt.parameterQty!=0 ? qt.parameterQty + ' MT' : qt.parameterAmt + ' USD'}
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </div>
                                                
                                            </>
                                        } */}


                                  
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <span className='font-weight-500'>{supplierProformaInvoiceData.tradedetails.tradeProductDetail[0].quantity} MT</span>
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <div className='font-weight-500'>{supplierProformaInvoiceData.tradedetails.tradeProductDetail[0].productBuyingPrice} USD</div>
                                        <div className='mt-1-rem font-weight-600'>{supplierProformaInvoiceData.tradedetails.tradeEnquiry.loadPort}, {supplierProformaInvoiceData.tradedetails.loadPortCountry}</div>
                                    </td>
                                    <td style={{ border: '1px solid black' }}>

                                        <span className='font-weight-500'>{(supplierProformaInvoiceData.tradedetails.tradeProductDetail[0].productBuyingPrice) *(supplierProformaInvoiceData.tradedetails.tradeProductDetail[0].quantity)} USD</span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Row>
                    <Row className='invoice_border mx-4'>
                    </Row>
                    <Row className='m-4'>
                        <Col md={6}>
                            <div className='font-weight-600 font-15'>
                                FOR {supplierProformaInvoiceData.companydetails.companyName}
                            </div>
                            <div className='font-weight-600 font-15'>
                                AUTHORIZED SIGNATORY
                            </div>
                        </Col>
                    </Row>
                    <Row className = 'mx-4'>
                        <Col md={6}>

                        </Col>
                        <Col md={6}>
                            <div className='font-weight-400 font-13 font-weight-600' style={{ textAlign: 'right' }}>
                                {supplierProformaInvoiceData.companydetails.companyName}
                            </div>
                            <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredUnitNo}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredBuildingName}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredStreetName}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredPostBox}, &nbsp;
                                {supplierProformaInvoiceData.tradedetails.supplier.registeredCityName} &nbsp;
                            </div>
                            <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>Tel : {supplierProformaInvoiceData.companydetails.companyTelephone}</div>
                            <div className='font-weight-400 font-13' style={{ textAlign: 'right' }}>{supplierProformaInvoiceData.companydetails.companyWebsite}</div>
                        </Col>
                    </Row>
                    </>
                    :
                    ''
                    }
                </div>
            </div>


        </div>
    )
}
