import React, { useState } from 'react'
import CustomerList from './CustomerList'

export default function List(props) {
    const [checkedList, setcheckedList] = useState([])
    const addtolist = (id)=>{
        let list = [...checkedList]
        list.push(id)
        if(list.length <= 2){
            setcheckedList(list)
            props.errorPresent(false)
            props.addtoSelection(list)
        }
        else{
            props.errorPresent(true)
        }
        console.log(list)
    }
    const removefromlist = (id)=>{
        let list = [...checkedList]
        let newlist = list.filter(x=> x != id)
        setcheckedList(newlist)
        props.errorPresent(false)
        props.addtoSelection(newlist)
    }
    return (
      <table className="tCustom table-text mt-1-rem">
              <thead>
                  <tr className=' p-3'>
                      <th className='p-3'>Customer Code</th>
                      <th className='p-3'>Customer Name</th>
                      <th className='p-3'>Address</th>
                      <th className='p-3'>Category</th>
                      <th className='p-3'>Incoterms</th>
                      <th></th>
                    </tr>
              </thead>
              <tbody>
                  {props.data.map((list,k)=>(
                      <CustomerList data={list} sendBack={props.back} selectedCustomer = {props.selectedCustomer} addtolist={addtolist} selectedlist={checkedList} removefromlist={removefromlist}/>
                  ))}
              </tbody>
      </table>
    )
}
