import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import {Form, Card } from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
//import FormComponent from './components/FormComponent';
import axios from 'axios';
import * as CONFIG from '../../../../helpers/config'
import List from './components/List';
import EditModal from './components/EditModal';
import { useMsal } from "@azure/msal-react";

function Index(props) {
    const { instance } = useMsal();
    const [productList, setProductList] = useState([]);
    const [editModal, setEditModal] = useState(false)
    const [dataForModal, setDataForModal] = useState()
    const [updatedList, setUpdatedList] = useState([])
    console.log(props)
    const moveToNext =() =>{
        props.addProductDetailsToApi(productList)
        props.onNext(props,2)
    }
    const moveToBack = () => {
        props.onBack(props,0)
    }
    const close = () => {
        setEditModal(false)
    }

    
    useEffect(() =>{

        if(props.TradeSupplyPlanDetail.length != 0){
            setProductList(props.TradeSupplyPlanDetail)
        } 
    },[props])

    useEffect(()=> {
        if(props.tradeInitiateEdit == true){
            setProductList(props.TradeInitiateData.tradedetails.tradeProductDetail)
        }
        else if(props.TradeSupplyPlanDetail.length != 0){
            setProductList(props.TradeSupplyPlanDetail)
        }
        else{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.TradeEnquiryByIDData.enqiry.tradeEnquiryID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_SUPPLY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let data = res.data.result.filter(x => x.supplyNo == props.currentSupplyPlan.supplyNo)
                setProductList(data)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    },[])

    const showModalForEdit = () => {
        setEditModal(true)
    }

    const handleDataForModal = (data) => {
        console.log(data.values, 'for modal')
        setDataForModal(data.values)
    }

    const handleEditSupplyData = (data) => {
        console.log(data)
        console.log(productList)
        let id = data.tradeEnquiryProductSupplyplanID
        productList.map((sup,l)=>{
            if(sup.tradeEnquiryProductSupplyplanID == id){
                productList[l] = data
                console.log(productList[l])
            }
        })
        setProductList(productList)
        console.log(productList)

    }

    useEffect(()=>{
        console.log(productList)
    },[productList])
    const addTraceAndQuality = (data)=>{
        console.log(data, 'addProduct details')
        let pr_list = [...productList]

        pr_list.map((plist,k)=>{
            data.map((dt,l)=>{
                if(plist.productID == dt.productID){
                   pr_list[k]= dt
                   pr_list[k].productName = productList[k].productName
                   pr_list[k].productCode = productList[k].productCode
                }
            })
        })
        console.log(pr_list)
        console.log(data)
        console.log(props.TradeSupplyPlanDetail)
        let list = [...productList]
        if(data[0].tradeEnquiryProductQualityParameters != null){
                list.map((values, k)=>(
                    values.tradeEnquiryProductQualityParameters = data[0].tradeEnquiryProductQualityParameters
                ))
                console.log(productList, 'Product')
        }
        if(data[0].tradeEnquiryProductTraceElement != null){
            list.map((values, k)=>(
                values.tradeEnquiryProductTraceElement = data[0].tradeEnquiryProductTraceElement
            ))
            console.log(productList, 'Product')
        }

        console.log(list)
        setProductList(list)
  
    }

    useEffect(()=>{
        console.log('check')
        console.log(props.currentSupplyPlan.productBuyingPrice)
    },[props])
    return (
        <>
        <Row className='p-4 bg-white mx-1'>
            <List 
                data = {productList}
                tradeInitiateEdit = {props.tradeInitiateEdit}
                showModalForEdit = {showModalForEdit}
                addTraceAndQuality={addTraceAndQuality} 
                handleDataForModal = {handleDataForModal}
            />
        </Row>
        <Card.Footer className='form-card-footer p-2-rem d-flex position-fixed'>
            <span><BlueBorderButton value='Prev' clickEvent = {moveToBack}/></span>
        
             <span className='pl-10'>
                <BlueButton 
                    value='Next' 
                    clickEvent={moveToNext} 
                    disabled = {productList != null && productList.length > 0 ? productList[0].productBuyingPrice != null && productList[0].productBuyingPrice != 0 ? false : true : true} 
                    /* disabled={isdisabled} */ 
                />
            </span> 
             

        </Card.Footer>
        {editModal == true ? 
            <EditModal data={dataForModal} handleEditSupplyData = {handleEditSupplyData} enqiry={props.TradeEnquiryByIDData.enqiry} close = {close}/>
        :
        ''
        }
        </>

    )
}

export default Index