import React from 'react'
import List from './List'

export default function ListMeetings(props) {
    return (
        <div>
            <div className='inner-page-content'>
                    <div className='bg md-p-4 br-8 p-0 shadowMeetings'>
                        <div class="col-lg-12 list-scroll">
                            <div class="table-responsive-sm  card card-body ">
                                <table className="table tableMeeting table-text mt-1-rem">
                                    <thead >
                                        <tr className='list-top'>
                                            <th>MEETING ID</th>
                                            <th>TITLE OF MEETING</th>
                                            <th>MEETING CATEGORY</th>
                                            <th>TIME</th>
                                            <th>ATTENDEES</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.meetinglist != null &&
                                            props.meetinglist.map((list, k) => {
                                                return (
                                                    <List data={list}  getlist={props.getlist}/>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}
