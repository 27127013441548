import React, { useEffect, useMemo, useState } from 'react'
import {Card} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from "moment"
import axios from "axios"
import CustomerDetailTab from '../InitiateCustomerSplit/Customer Details/index'
import GeneralInformationTab from '../InitiateCompanySplit/General Information/index'
import ProductDetailsTab from './Products'
import SupplierDetailsTab from './Supplier Details'
import ShipmentDetailsTab from './Shipping Details'
import * as CONFIG from '../../../helpers/config'
import { useMsal } from "@azure/msal-react";


export default function TradeEnquiryTabs(props) {
    console.log(props)
    const { instance } = useMsal();
    const [selectedTab, setSelectedTab]= useState(0)
    const [customerDetailsData, setCustomerDetailsData] = useState([])
    const [supplier, setSupplier] = useState()
    const [TradeSupplyPlanDetail, setTradeSupplyPlanDetail] = useState([])
    const [tradeSupplierPaymentDetails, setTradeSupplierPaymentDetails] = useState()
    const [tradeCustomerPaymentDetails, setTradeCustomerPaymentDetails] = useState()
    const [customer, setCustomer] = useState()
    const [labelsCustomer, setLabelsCustomer] = useState([])
    const [labelsSupplier, setLabelsSupplier] = useState([])
    const [paymentMethodsCustomer, setPaymentMethodsCustomer] = useState()
    const [paymentMethodsSupplier, setPaymentMethodsSupplier] = useState()
    const [backClicked, setBackClicked] = useState(false) 
    const [nextClicked, setNextClicked] = useState(false) 
    const [currentSupplyPlan, setCurrentSupplyPlan] = useState({})
    const [productBuyingPrice, setProductBuyingPrice] = useState(0)
    const [updateCustomerPaymentDetails, setUpdateCustomerPaymentDetails] = useState(false)
    const [updateSupplierPaymentDetails, setUpdateSupplierPaymentDetails] = useState(false)
    const [supplierIncoterms, setSupplierIncoterms] = useState()
    const [companyData, setCompanyData] = useState()
    const [splitData, setCompanySplitData] = useState()
    const [tradeCompanyCustomerDetailsID, setTradeCompanyCustomerDetailsID] = useState()



    useEffect(()=>{
        console.log(currentSupplyPlan)
    },[currentSupplyPlan, productBuyingPrice, TradeSupplyPlanDetail])

    // useEffect(()=>{
    //     let editArray = []
    //     if(props.tradeInitiateEdit == true){
    //         if(props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail != null){
    //             for(let i = 0; i < props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail.length; i++){
    //                 editArray.push({
    //                     "customerPaymentTypeID": props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail[i].customerPaymentTypeID,
    //                     "customerPaymentAmount": props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail[i].customerPaymentAmount,
    //                     "customerPaymentPer": props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail[i].customerPaymentPer,
    //                     "customerPaymentTypeName": props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail[i].paymentType.typeName,
    //                     "isCheck": true,
    //                     "tradeCustomerPaymentDetailID": props.TradeInitiateData.tradedetails.tradeCustomerPaymentDetail[i].tradeCustomerPaymentDetailID,
    //                 })
    //             }
    //             console.log(editArray, "array for edit")
    //             setTradeCustomerPaymentDetails(editArray)
    //         }

    //     }
    // },[])
    
    useEffect(()=>{
        if(props.tradeInitiateEdit == true){
            if(props.TradeInitiateData.tradedetails.supplierIncotermID != null){
                axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_INCOTERMS, {
                    "instanceID": CONFIG.PMSInstanceID,
                    "created_user":CONFIG.LoginID,
                }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    let object = {}
                    for(let i = 0; i < res.data.result.length; i++){
                        if(res.data.result[i].incotermsID == props.TradeInitiateData.tradedetails.supplierIncotermID){
                            object = {
                                "supplierIncotermID" : props.TradeInitiateData.tradedetails.supplierIncotermID,
                                "tradeIncoTermsValue": res.data.result[i].incotermsName
                            }
                        }
                    }
                    console.log(object)
                    setSupplierIncoterms(object)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            }
        }
    },[])
    // useEffect(()=>{
    //     let editArray = []
    //     if(props.tradeInitiateEdit == true){
    //         if(props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail != null){
    //             for(let i = 0; i < props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail.length; i++){
    //                 editArray.push({
    //                     "supplierPaymentTypeID": props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentTypeID,
    //                     "supplierPaymentAmount": props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentAmount,
    //                     "supplierPaymentPer": props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail[i].supplierPaymentPer,
    //                     "supplierPaymentTypeName": props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail[i].paymentType.typeName,
    //                     "isCheck": true,
    //                     "tradeSupplierPaymentDetailID": props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail[i].tradeSupplierPaymentDetailID,
    //                 })
    //             }
    //             console.log(editArray, "array for edit")
    //             setTradeSupplierPaymentDetails(editArray)
    //         }
    //     }
    // },[])

    useEffect(()=>{
        console.log(props)
        if(Object.keys(currentSupplyPlan).length == 0){
            if(props.tradeInitiateEdit == true){
                let emptySpaceInitiation = props.TradeInitiateData.tradeStatus.indexOf(' ')
                let tradeSupply = parseInt(props.TradeInitiateData.tradeStatus.slice(0, emptySpaceInitiation))
                //tradeSupply++
                console.log(tradeSupply)
                //console.log(props.TradeInitiateData.tradedetails.tradeProductDetail[0].tradeEnquiryProductSupplyplan)
                //console.log(props.TradeInitiateData.data.productdetails[0].tradeEnquiryProductSupplyplan)
                if(props.TradeInitiateData.tradedetails.tradeProductDetail[0].tradeEnquiryProductSupplyplan.supplyNo == tradeSupply){
                    console.log('condition')
                    setCurrentSupplyPlan(props.TradeInitiateData.tradedetails.tradeProductDetail[0].tradeEnquiryProductSupplyplan)
                    console.log(currentSupplyPlan)
                }
            }
            else{
                let emptySpaceInitiation = props.TradeInitiateData.tradeSupply.indexOf(' ')
                let emptySpaceScheduled = props.TradeInitiateData.scheduledSupply.indexOf(' ')
                let tradeSupply = parseInt(props.TradeInitiateData.tradeSupply.slice(0, emptySpaceInitiation))
                let scheduledSupply = parseInt(props.TradeInitiateData.scheduledSupply.slice(0, emptySpaceScheduled))
                tradeSupply++
                console.log("Testing")
                console.log(props.TradeInitiateData.productdetails[0].tradeEnquiryProductSupplyplan)
                for(let i = 0; i < props.TradeInitiateData.productdetails[0].tradeEnquiryProductSupplyplan.length; i++){
                    if(props.TradeInitiateData.productdetails[0].tradeEnquiryProductSupplyplan[i].supplyNo == tradeSupply){
                        setCurrentSupplyPlan(props.TradeInitiateData.productdetails[0].tradeEnquiryProductSupplyplan[i])
                    }
                }
            }
        }
    },[props])

    useEffect(()=>{
        if(props.tradeInitiateEdit == true){
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.TradeInitiateData.tradeID
                
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    let id = res.data.result
                    let list = []
                    for(let i = 0; i < res.data.result.length; i++){
                        for(let x = 0; x < id[i].tradeCustomerPaymentDetail.length; x++){
                            id[i].tradeCompanyCustomerDetailsID = id[i].tradeCustomerPaymentDetail[x].tradeCompanyCustomerDetailsID
                            list.push(id[i].tradeCompanyCustomerDetailsID)
                        }
                    }
                    setTradeCompanyCustomerDetailsID(list)
                    setCompanySplitData(res.data.result)
                    let dataForCustomerPayment = res.data.result
                    for(let i = 0; i < res.data.result.length; i++){
                        let editArray = []
                        for(let x = 0; x < dataForCustomerPayment[i].tradeCustomerPaymentDetail.length; x++){
                            editArray.push({
                                "customerPaymentTypeID": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].customerPaymentTypeID,
                                "customerPaymentAmount": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].customerPaymentAmount,
                                "customerPaymentTypeName": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].paymentType.typeName,
                                "customerPaymentPer": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].customerPaymentPer,
                                "isCheck": true,
                                "tradeCustomerPaymentDetailID": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].tradeCustomerPaymentDetailID,
                                "tradeCompanyCustomerDetailsID": dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].tradeCompanyCustomerDetailsID
                            })
                            dataForCustomerPayment[i].tradeCompanyCustomerDetailsID =  dataForCustomerPayment[i].tradeCustomerPaymentDetail[x].tradeCompanyCustomerDetailsID
                        }
                        
                        dataForCustomerPayment[i].tradeCustomerPaymentDetail = editArray   
                    }
                    // console.log(editArray, "array for edit")
                    console.log(res.data.result, "update")
                    setTradeCustomerPaymentDetails(dataForCustomerPayment)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
    },[props])

    useEffect(()=>{

    },[tradeCompanyCustomerDetailsID])

    const moveToNext = (data, index)=>{
        setBackClicked(false)
        //setNextClicked(true)
        console.log(data);
        //setCurrentSupplyPlan
        if(index == 1){
            console.log('1')
            setSelectedTab(index)   
        }
        else if(index == 2){ 
            console.log('2')
            // if(data != null && data.currentSupplyPlan != null){
            //     console.log(currentSupplyPlan)
            //     console.log(data.currentSupplyPlan)
            //     setCurrentSupplyPlan(data.currentSupplyPlan)
            // }
            setSelectedTab(index)   
        }
        else if(index == 3){ 
            console.log('3')  
            setSelectedTab(index)   
        }
        else if(index == 4){
            console.log('4')   
            setSelectedTab(index)   
        }
        else if(index == 5){  
            setSelectedTab(index)   
        }
    }
    
    const handleBackClick = (data, index) => {
        console.log(index);
        setBackClicked(true)
        if(index == 0){
            setSelectedTab(index)   
        }
        else if(index == 1){  
            setSelectedTab(index)   
        }
        else if(index == 2){  
            setSelectedTab(index)   
        }
        else if(index == 3){  
            setSelectedTab(index)   
        }
    }

    const addCustomerDetailToApi = (data) => {
        setTradeCustomerPaymentDetails(data)
    }
    const addSupplierDetailsToApi = (data, supplierData) => {
        console.log(data)
        setSupplier(supplierData)
        setTradeSupplierPaymentDetails(data)

    }

    const handleSetLabelsCustomer = (data) => {
        console.log(data, 'bug')
        setLabelsCustomer(data)
    }

    const handleSetLabelsSupplier = (data) => {
        console.log(data, 'bug')
        setLabelsSupplier(data)
    }



    const addProductDetailsToApi = (data) => {
        console.log(data, 'data')
        setTradeSupplyPlanDetail(data)
        console.log(currentSupplyPlan)
        let currentsupplypl = {...currentSupplyPlan}
        currentsupplypl.productSellingPrice = data[0].productSellingPrice
        currentsupplypl.quantity = data[0].quantity
        currentsupplypl.productBuyingPrice = data[0].productBuyingPrice
        setProductBuyingPrice(data[0].productBuyingPrice)
        setCurrentSupplyPlan(currentsupplypl)
        console.log(currentsupplypl)
    }

    const addPaymentMethodCustomer = (data) => {
        setPaymentMethodsCustomer(data)
    }
    const addPaymentMethodSupplier = (data) => {
        setPaymentMethodsSupplier(data)
    }

    const handleUpdatePaymentDetailsCustomer = () => {
        setUpdateCustomerPaymentDetails(false)
    }
    const handleUpdatePaymentDetailsSupplier = () => {
        setUpdateSupplierPaymentDetails(false)
    }

    const handleSupplierIncoterms = (data) => {
        console.log(data)
        setSupplierIncoterms(data)
    }
    const handleSplitCompanyData = (data, company) => {
        setCompanyData(company)
        setCompanySplitData(data)
        console.log(data, 'from general infromation split')
    }

    const handleSplitCustomerData = (data) => {
        console.log(data, 'split Data')
        setCompanySplitData(data)
    }
    useEffect(()=>{

    },[supplierIncoterms])

    useEffect(()=>{

    },[splitData])
    return (
        <div className='inner-page-contents w-100'>
            {props.TradeInitiateData != null ? 
              <Tabs selectedIndex= {selectedTab}>
              <Card className='tabs-wrapper'>
                  <TabList>
                      <Tab>General Information</Tab>
                      <Tab>Product Details</Tab>
                      <Tab>Customer Details</Tab>
                      <Tab>Supplier Details</Tab>
                      <Tab>Shipping Details</Tab>
                  </TabList>               
              </Card>
              <TabPanel className='mt-1-rem'>
                  <GeneralInformationTab 
                    companyData = {companyData}
                    currentSupplyPlan = {currentSupplyPlan} 
                    TradeInitiateData = {props.tradeInitiateEdit == true ? props.TradeInitiateData : props.TradeEnquiryByIDData}  
                    onNext={moveToNext}  
                    tradeInitiateEdit = {props.tradeInitiateEdit}
                    splitData = {splitData}
                    handleSplitCompanyData={handleSplitCompanyData}
                  />
              </TabPanel>
              <TabPanel className='mt-1-rem'>
                  <ProductDetailsTab 
                      TradeInitiateData = {props.tradeInitiateEdit == true ? props.TradeInitiateData : props.TradeInitiateData} 
                      TradeEnquiryByIDData = {props.TradeEnquiryByIDData} 
                      onNext={moveToNext} 
                      onBack={handleBackClick} 
                      addProductDetailsToApi = {addProductDetailsToApi} 
                      TradeSupplyPlanDetail = {TradeSupplyPlanDetail}
                      backClicked = {backClicked}
                      tradeInitiateEdit = {props.tradeInitiateEdit}
                      currentSupplyPlan = {currentSupplyPlan}
                      companyData = {companyData}
                      splitData = {splitData}
                  />
              </TabPanel>
              <TabPanel className='mt-1-rem'>
                  <CustomerDetailTab 
                      TradeInitiateData = {props.tradeInitiateEdit == true ? props.TradeInitiateData : props.TradeInitiateData} 
                      TradeEnquiryByIDData = {props.TradeEnquiryByIDData} 
                      onNext={moveToNext} 
                      onBack={handleBackClick} 
                      addCustomerDetailToApi={addCustomerDetailToApi}
                      tradeCustomerPaymentDetails = {tradeCustomerPaymentDetails}
                      backClicked = {backClicked}
                      labelsCustomer = {labelsCustomer}
                      tradeInitiateEdit = {props.tradeInitiateEdit}
                      currentSupplyPlan = {currentSupplyPlan}
                      paymentMethodsCustomer = {paymentMethodsCustomer}
                      addPaymentMethodCustomer = {addPaymentMethodCustomer}
                      handleSetLabelsCustomer = {handleSetLabelsCustomer}
                      handleUpdatePaymentDetailsCustomer = {handleUpdatePaymentDetailsCustomer}
                      companyData = {companyData}
                      splitData = {splitData}
                      handleSplitCustomerData = {handleSplitCustomerData}
                      
                  />
              </TabPanel>
            
              <TabPanel className='mt-1-rem'>
                  <SupplierDetailsTab 
                      TradeInitiateData = {props.tradeInitiateEdit == true ? props.TradeInitiateData : props.TradeInitiateData} 
                      TradeEnquiryByIDData = {props.TradeEnquiryByIDData} 
                      onNext={moveToNext} 
                      onBack={handleBackClick} 
                      addSupplierDetailsToApi={addSupplierDetailsToApi}
                      supplier = {supplier}
                      backClicked = {backClicked}
                      tradeSupplierPaymentDetails = {tradeSupplierPaymentDetails}
                      labelsSupplier = {labelsSupplier}
                      nextClicked = {nextClicked}
                      tradeInitiateEdit = {props.tradeInitiateEdit}
                      currentSupplyPlan = {currentSupplyPlan}
                      paymentMethodsSupplier = {paymentMethodsSupplier}
                      addPaymentMethodSupplier = {addPaymentMethodSupplier}
                      productBuyingPrice = {productBuyingPrice != null ? productBuyingPrice : ''}
                      handleSetLabelsSupplier = {handleSetLabelsSupplier}
                      handleUpdatePaymentDetailsSupplier = {handleUpdatePaymentDetailsSupplier}
                      handleSupplierIncoterms = {handleSupplierIncoterms}
                      supplierIncoterms = {supplierIncoterms}
                      companyData = {companyData}
                      splitData = {splitData}
                  />
              </TabPanel>
              <TabPanel className='mt-1-rem'>
                  <ShipmentDetailsTab 
                      TradeInitiateData = {props.tradeInitiateEdit == true ? props.TradeInitiateData : props.TradeInitiateData} 
                      TradeEnquiryByIDData = {props.TradeEnquiryByIDData} 
                      onNext={moveToNext} 
                      onBack={handleBackClick} 
                      supplier={supplier} 
                      tradeCustomerPaymentDetails={tradeCustomerPaymentDetails}
                      tradeSupplierPaymentDetails={tradeSupplierPaymentDetails}
                      TradeSupplyPlanDetail = {TradeSupplyPlanDetail}
                      tradeInitiateEdit = {props.tradeInitiateEdit}
                      backToMainPage = {props.backToMainPage}
                      onSave={props.handleSave}
                      back={props.back}
                      currentSupplyPlan = {currentSupplyPlan}
                      supplierIncoterms = {supplierIncoterms}
                      companyData = {companyData}
                      splitData = {splitData}
                      tradeCompanyCustomerDetailsID = {tradeCompanyCustomerDetailsID}
                  />
              </TabPanel>
              </Tabs>
            :
            ''
            }
          
        </div>
    )
}
