import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import axios from 'axios'
import { AddNewButton, BlueButton } from '../../components/buttoncomponents'
import AddEdit from './addedit/AddEdit'
import List from './List'
import * as CONFIG from "../../helpers/config"
import { useMsal } from "@azure/msal-react";
import * as FormFields from "../../components/formcomponents"
import { Col, FloatingLabel, Form } from 'react-bootstrap'



export default function Index() {
    const { instance } = useMsal();
    const [supplierList, setList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [isEdit, setIsEdit] = useState(false)
    const [removeAddButton, setRemoveAddButton] = useState(true)
    const [specificCustomList, setSpecificCustomList] = useState([])
    const [supplierfilterlist, setsupplierfilterlist] = useState([])
    useEffect(()=>{
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
        if(isFirst){
            getList()
        }
        setIsFirst(false)
    })

    const getList = () =>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setList(res.data.result);
            setsupplierfilterlist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const [isSupplierAdd, setIsSupplierAdd] = useState(false);

    const addSupplier = ()=>{
        setIsSupplierAdd(!isSupplierAdd)
        setRemoveAddButton(false)
        setIsEdit(false)
        setSpecificCustomList([])
    }

    const handleSave = () => {
        setIsSupplierAdd(!isSupplierAdd)
        setRemoveAddButton(true)
    }

    const handleCancel = ()  => {
        setIsEdit(false)
        setIsSupplierAdd(!isSupplierAdd)
        setRemoveAddButton(true)
    }

    const getListForEdit = (id)=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setSpecificCustomList(res.data.result)
            setIsEdit(true)
            setIsSupplierAdd(!isSupplierAdd);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    
    const disable = (e) =>{
        console.log('clicked')
        const id = parseInt(e.target.id)
        console.log(supplierList)
        let data =  supplierList.filter(x=> x.supplierID == id)
        console.log(data)
        let status = data[0].supplierstatus;
        console.log(data[0], status, id)
        const json = {
            "id": id,
            "status": status==1?0:1,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.CHANGE_SUPPLIER_STATUS, json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getList();
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const edit = (e) => {
        console.log(e.target.id)
        let id = parseInt(e.target.id)
        getListForEdit(id)
        console.log(specificCustomList)
        setRemoveAddButton(false)
        
    }
    const handleSearch =(e)=>{
        let val = e.target.value
        let filter = supplierList.filter(x=> x.suppliercode.toLowerCase().includes(val.toLowerCase()) ||
            x.suppliername.toLowerCase().includes(val.toLowerCase()) ||
            x.category.toLowerCase().includes(val.toLowerCase())||
            x.incoterms.toLowerCase().includes(val.toLowerCase()) ||
            x.address.toLowerCase().includes(val.toLowerCase())
        )
        setsupplierfilterlist(filter) 
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
              
            <Sidebar id='3.1' isOpenid={3}/>
            <div className='w-100 h-100 '>
                <Navbar/>
                <div className='inner-page-header'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/pageicons/suppliersicon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10 pt-1'> {isSupplierAdd == true ? 'NEW SUPPLIER' : 'SUPPLIERS'} </span>
                        </div>
                        <div className='d-flex'>
                            {removeAddButton &&
                                <div className='me-3'>
                                        <Col md={12}>
                                            <FloatingLabel controlId="floatingInput" label='Search'>
                                                <Form.Control type='text' placeholder='Search' 
                                                className="form_input form_input_color_change" 
                                                name='search'
                                                onChange={handleSearch} 
                                                onBlur={handleSearch} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                </div>
                            }
                        
                            <div>

                                {removeAddButton == true ? 
                                <>{localStorage.getItem('Add_Supplier') == 3 && <AddNewButton  value='Add New Supplier' class="bg-white " clickEvent={addSupplier}/> }</>
                                :
                                ''
                                }
                            </div>
                            {isSupplierAdd == true  ?
                                <div className='d-flex mt-10-rem'>
                                    <div className = 'd-flex justify-content-center align-items-center '>
                                        <BlueButton value='Back' clickEvent={handleSave}/>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
                <div className='inner-page-content'>
                    {isSupplierAdd?
                        <>
                            <AddEdit 
                                handleCancel = {handleCancel}  
                                data={isEdit == true ? specificCustomList : ''} 
                                isEdit = {isEdit} 
                                onSave={handleSave} 
                                getList = {getList}
                            />
                        </>
                    :
                        <>
                            
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <List 
                                    data={supplierfilterlist} 
                                    edit={edit} 
                                    disable={disable}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
            

        </div>
    )
}
