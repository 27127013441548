import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form } from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { useFormik } from 'formik'
import { TradeIncotermsList } from '../../../../helpers/API/Api';
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
function IncoTermsModal(props) {
    const [modal, setModal] = useState(true);
    const [tradeEnquiry, setTradeEnquiry] = useState(true)

    const toggle = () => {
        setModal(!modal)
        props.close();
      }

      const formik = useFormik({
        initialValues:
        {
           tradeIncoTermsValue : 'FOB',
           supplierIncotermID: props.supplierIncotermID != null ? props.supplierIncotermID : 2
        },
    })

    const handleName = (data) => {
        formik.values.tradeIncoTermsValue = data
    }
    const handleSaveEvent = () => {
        props.handleIncoTermsValue(formik.values)
        toggle(0)
    }
    const handleChange = (e) => {
        formik.values.supplierIncotermID = e.target.value
        formik.values.tradeIncoTermsValue = e.target.id
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className = 'modal-dialog-custom-width-payments payment-method-modal'>
        <ModalHeader className='px-5 py-4' toggle={toggle}>
            <div className = 'd-flex justify-content-between'>
                <h4 className='font-16 font-weight-600 color-707895 mb-0'>
                    INCOTERMS
                </h4>
            </div>
        </ModalHeader>
        <ModalBody  className = 'service-request-modal-wrapper'>
            <>
            <div className ='d-flex'>
                {<TradeIncotermsList onChange={handleChange} name='supplierIncotermID' value={formik.values.supplierIncotermID} tradeEnquiry={tradeEnquiry} handleName={handleName}/>}
            </div>
            </>
        </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} width="90px"/></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} width="90px"/></span>
            </ModalFooter>
        </Modal>
    )
}

export default IncoTermsModal