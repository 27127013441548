import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useFormik } from 'formik'
import Moment from "moment"
import { Row, Col, Form } from "react-bootstrap";
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import NumberFormat from 'react-number-format';
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as CONFIG from "../../../helpers/config"
import { BlueBorderButton, BlueButton, BackButton } from '../../../components/buttoncomponents';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function UpdateSupplierProformaInvoice(props) {
    const { instance } = useMsal();
    const [supplierInvoiceData, setSupplierInvoiceData] = useState()

    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [invoiceDate, setInvoiceDate] = useState(false);
    const [invoiceDocumentName, settInvoiceDocumentName] = useState('');
    const MySwal = withReactContent(Swal);

    let navigate = useNavigate();
    const location = useLocation()
    const tradeData  = props.tradeData
    const [selectedData, setSelectedData] = useState()
  
    
    useEffect(()=>{
        if(tradeData.tradedetails.supplierProformaInvoiceDocumentName != '' && tradeData.tradedetails.supplierProformaInvoiceDocumentName != null){
            settInvoiceDocumentName(tradeData.tradedetails.supplierProformaInvoiceDocumentName)
        }
    },[props])
    const invoiceDocumentHandler = (file) => {
        formik.values.invoiceDocument = file.base64;
        formik.values.invoiceDocumentName = file.name
        settInvoiceDocumentName(file.name)
    }
    const formik = useFormik({
        initialValues:
        {
      
             invoiceNo: tradeData.tradedetails.supplierProformaInvoiceNo != null ? tradeData.tradedetails.supplierProformaInvoiceNo : '',
             invoiceDate: tradeData.tradedetails.supplierProformaInvoiceDate != null ? Moment(tradeData.tradedetails.supplierProformaInvoiceDate) : false,
             invoiceDocument: tradeData.tradedetails.supplierProformaInvoiceDocument != null ? tradeData.tradedetails.supplierProformaInvoiceDocument : '',
        },
    })
    const back = () => {
        navigate('/trade-transactions', { state:{ tab: 1,}});
    }

    const handleInvoiceDate = (date) => {
        console.log('click')
        if (date == null) {
            formik.values.invoiceDate = false
        }
        else {
            formik.values.invoiceDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')

        }
        
        setInvoiceDate(date)
    }

    const handleSubmit = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID),
            "invoiceNo": formik.values.invoiceNo,
            "invoiceDate" : formik.values.invoiceDate,
            "invoiceDocument" : formik.values.invoiceDocument,
            "invoiceDocumentName" : formik.values.invoiceDocumentName
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_SUPPLIER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode != '304000'){
                    MySwal.fire(
                        {
                        icon: 'error',
                        title: 'Something went wrong',
                        //type: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        back()
                       //redirecting 
                       //history.push("/login")
                    }); 
                }
                else if(res.data.responseCode == '304000'){
                    props.refreshList()
                    MySwal.fire(
                        {
                        icon: 'success',
                        title: 'Supplier Proforma Invoice Uploaded!',
                        html: '<p>Supplier Proforma Invoice No: ' + formik.values.invoiceNo + '</p>' + '<br />',
                        type: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        back()
                       //redirecting 
                       //history.push("/login")
                    });
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }
    return(
    <div className='main-wrapper inner-wrapper d-flex'>
    <div className='width-100'>
            <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                <div className = 'd-flex justify-content-center align-items-center '>
                    <BackButton clickEvent={back} />
                </div>
            </div>
     
        <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-3'>
            <div className='d-flex align-items-center'>
                <span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10 text-uppercase'>Supplier Proforma Invoice</span>
            </div>
            
        </div>
        <div className='inner-page-content mx-4 px-3 mt-4 '  id='download_section'>
            <Card className='h-100vh'>
                <CardBody className='p-40'>
                    <Form >
                        <Row>
                        
                            <>
                            <FormFields.InputField
                                md={3}
                                name='invoiceNo'
                                label='Proforma Invoice Number'
                                classname='pt-1-rem'
                                type='text'
                                val={formik.values.invoiceNo}
                                disabled={localStorage.getItem('Update_Supplier_Invoice') == 3 ? false: true}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            />
                            <FormFields.DatePick
                                md={3}
                                label='Proforma Invoice Date'
                                name='invoiceDate'
                                classname='pt-1-rem iconDatePicker'
                                type='date'
                                handleCompletionDate={handleInvoiceDate}
                                isRequired={false}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                val={formik.values.invoiceDate}
                                disabled={localStorage.getItem('Update_Supplier_Invoice') == 3 ? false: true}
                            />
                            <Col md={3} className='pt-1-rem align-items-center'>
                                <ButtonComponent.UploadButton value='Proforma Invoice' onUploadHandle={invoiceDocumentHandler} filename={invoiceDocumentName} document={formik.values.invoiceDocument}/>
                            </Col>
                            <Col md={3} className='pt-1-rem  align-items-center'>
                                <BlueButton value = "Submit" clickEvent = {handleSubmit} />
                            </Col>
                            </>
                           
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    </div>


</div>
)
}

export default UpdateSupplierProformaInvoice