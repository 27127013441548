import React, { useState, useEffect } from 'react'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import NumberFormat from 'react-number-format';
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import * as ButtonComponent from "../../../../components/buttoncomponents"
import * as CONFIG from "../../../../helpers/config"
import styles from './styles.module.css'

function SalesModal(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [error, setError] = useState(true)
    const [customerInvoiceData, setCustomerInvoiceData] = useState()
    const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const toggle = () => {
        setModal(!modal)
        props.close();
    }
    useEffect(() => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.modalData.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0])
                console.log((parseInt((res.data.result[0].tradedetails.tradeProductDetail[0].productSellingPrice) * (res.data.result[0].tradedetails.tradeProductDetail[0].quantity))))
                setCustomerInvoiceData(res.data.result[0])
                res.data.result[0].tradedetails.tradeProductDetail.map((list, k) => (
                    listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                    // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                ))
                console.log(listValues)
                let sum = 0
                let sumTax = parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax1)) + parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax2))
                for (let i = 0; i < listValues.length; i++) {
                    sum += listValues[i]
                }
                console.log(sum)
                setSubTotal(sum)
                setTotalTax(sumTax)
                setOverAllTotal(sum + sumTax)
                props.refreshList()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })


    }, [])

    useEffect(()=>{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.modalData.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result[0])
                setCustomerInvoiceDetails(res.data.result[0])
                console.log(res.data.result[0].customer)
               
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    },[])

    const printDocumentDetails = () => {
        const domElement = document.getElementById("download_section_details");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('SalesDetailsReports-' + customerInvoiceDetails.customerInvoiceNo);
                // var doc = new jsPDF("p", "pt", "letter");
                // doc.html(document.getElementById("download_section"), {
                //     callback: function (doc) {
                //       doc.save();
                //     },
                //     margin: 10
                // });


            })
    }
    console.log()
    return (
        <Modal isOpen={modal} toggle={toggle} className='bank-account-modal' id=''>
            <ModalHeader toggle={toggle} className='reports_details'>
                <div className='width-100l d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <p className='font-weight-400 color-697382 mb-1'>SALES DETAILS</p>
                    </div>
                    <div>
                        <img src='assets/images/icon_pdf.svg' className='img-fluid' onClick={printDocumentDetails} />
                    </div>
                </div>
               
            </ModalHeader>
            <ModalBody className='' style={{ backgroundColor: '#fff' }}>
                <>
                    <div className='container'>
                        <div className='row p-4 mt-4' style={{ background: '#FAFAFC' }}>
                            <div className='col-md-6 col-sm-12'>
                                <p className={` ${styles.upperHeadingTitle}`}>
                                    CUSTOMER
                                </p>
                                <div className='align-items-center'>
                                    <p className={`me-2 ${styles.upperHeadingText}`}>
                                        {props.modalData.customername}
                                    </p>
                                    <div className={`me-2 ${styles.CodePill} w-50`  }>
                                        <p>
                                            {props.modalData.customercode}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <p className={` ${styles.upperHeadingTitle}`}>
                                    product details
                                </p>
                                <div className={`me-2 d-flex align-items-center ${styles.upperHeadingText}`}>
                                    <div>
                                        {props.modalData.divisionName}
                                    </div>
                                    <div className={` mx-3 ${styles.seperator}`} />
                                    <div>
                                        {props.modalData.productName} {props.modalData.qty}
                                        {props.modalData.prodUOM}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='inner-page-content px-3 mt-4 ' id='download_section_details' style={{ background: '#FAFAFC' }}>
                        <Card className=''>
                            <CardBody className=''>
                                {customerInvoiceData != null && customerInvoiceDetails != null ?
                                    <>
                                        <div className='mx-2 br-8 py-3 d-flex justify-content-between align-items-center'>
                                            <div>
                                                <img src="/assets/images/logo.svg" className='img-fluid' />
                                            </div>

                                        </div>
                                        <div className='mx-2 br-8 py-3 d-flex justify-content-between '>
                                            <div>
                                                <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}><b>CUSTOMER</b></p>
                                                <p className='mt-2 p-0 mb-0'><b>{customerInvoiceDetails.customer.customerName}</b></p>
                                                <p className='customer-invoice-text'>
                                                    {customerInvoiceDetails.customer.registeredUnitNo}, &nbsp;
                                                    {customerInvoiceDetails.customer.registeredBuildingName}, &nbsp;
                                                    {customerInvoiceDetails.customer.registeredStreetName}, &nbsp;
                                                    {customerInvoiceDetails.customer.registeredPostBox}, &nbsp;
                                                    {customerInvoiceDetails.customer.registeredCityName} &nbsp;
                                                </p>
                                            </div>
                                            <div>
                                                <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}>SHIP TO</p>
                                                {customerInvoiceDetails.customer.separateShipmentAddress === false ?
                                                    <>
                                                        <p className='mt-2 p-0 mb-0'><b>{customerInvoiceDetails.customer.customerName}</b></p>
                                                        <p className='customer-invoice-text'>
                                                            {customerInvoiceDetails.customer.registeredUnitNo}, &nbsp;
                                                            {customerInvoiceDetails.customer.registeredBuildingName}, &nbsp;
                                                            {customerInvoiceDetails.customer.registeredStreetName}, &nbsp;
                                                            {customerInvoiceDetails.customer.registeredPostBox}, &nbsp;
                                                            {customerInvoiceDetails.customer.registeredCityName} &nbsp;
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <p className='mt-2 p-0 mb-0'><b>{customerInvoiceDetails.customer.customerName}</b></p>
                                                        <p className='customer-invoice-text'>
                                                            {customerInvoiceDetails.customer.shipmentUnitNo}, &nbsp;
                                                            {customerInvoiceDetails.customer.shipmentBuildingName}, &nbsp;
                                                            {customerInvoiceDetails.customer.shipmentStreetName}, &nbsp;
                                                            {customerInvoiceDetails.customer.shipmentPostBox}, &nbsp;
                                                            {customerInvoiceDetails.customer.shipmentCityName} &nbsp;
                                                        </p>
                                                    </>
                                                }
                                            </div>
                                            <div>
                                                <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}>SHIPMENT DETAILS</p>
                                                <p className='mt-2 p-0 mb-0'><b>{customerInvoiceDetails.customer.customerName}</b></p>
                                                <p className='customer-invoice-text'>
                                                    Mode Of Transport: <b>{customerInvoiceData.tradedetails.modeOfTransport.modeOfTransportName}</b><br />
                                                    Est Shipment Date: <br />
                                                    Country Of Origin: <br />
                                                    Load Port: <b>{customerInvoiceData.tradedetails.tradeEnquiry.loadPort}</b><br />
                                                    Destination Port: <b>{customerInvoiceData.tradedetails.tradeEnquiry.destinationPort}</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text border-bottom-0">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>PRODUCT CODE</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>QTY</th>
                                                        <th>UNIT PRICE</th>
                                                        <th>AMOUNT</th>
                                                    </tr>
                                                </thead>
                                                <tbody className=''>
                                                    {customerInvoiceData != null &&
                                                        customerInvoiceData.tradedetails.tradeProductDetail.map((list, k) => (
                                                            <tr>
                                                                <td>{list.product.productCode}</td>
                                                                <td>{list.product.description}</td>
                                                                <td><NumberFormat value={list.quantity} displayType={'text'} thousandSeparator={true} /> {list.productUOM.productUOMName}</td>

                                                                <td>${list.productSellingPrice}</td>

                                                                <td>$<NumberFormat value={parseInt((list.productSellingPrice) * (list.quantity))} displayType={'text'} thousandSeparator={true} /></td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr className='border-0'>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td className="table-secondary border border-secondary">SUB TOTAL:</td>
                                                        <td className="table-secondary border border-secondary">$<NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} /></td>
                                                    </tr>

                                                    <tr className=''>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td className="border border-secondary">GRAND TOTAL:</td>
                                                        <td className="border border-secondary">$<NumberFormat value={overAllTotal} displayType={'text'} thousandSeparator={true} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                    :
                                    ''
                                }


                            </CardBody>
                        </Card>

                    </div>

                </>
            </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueBorderButton value="EXPORT PDF" clickEvent={printDocumentDetails} /></span>

            </ModalFooter>
        </Modal>
    )
}

export default SalesModal