import React, { useState, useEffect } from 'react'
import axios from 'axios';
//import configfile from "../../../../config.json";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useFormik } from 'formik'
import { Row, Col, Button, Dropdown, Card, Form, FloatingLabel } from "react-bootstrap"
import List from './List';
import * as CONFIG from "../../.././../../helpers/config"
import { BlueBorderButton } from '../../../../../components/buttoncomponents';
import { BlueButton } from '../../../../../components/buttoncomponents';
import { useMsal } from "@azure/msal-react";


function ModalForExistingCustomer(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [customerList, setList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [selectedCustomer, setselectedCustomer] = useState([]);
    const [customerFilterList, setCustomerFilterList] = useState([])
    const [checkerror, setcheckerror] = useState(false)
    useEffect(() => {
        console.log('inside modal')

        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href = "/"
        }
        else {
            console.log('inside')
        }
        if (isFirst) {
            getList()
        }
        setIsFirst(false)
    })
    useEffect(() => {
        console.log(selectedCustomer)
    }, [selectedCustomer])
    const toggle = () => {
        setModal(!modal)
        props.cancel();
    }
    const getList = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setList(res.data.result);
                setCustomerFilterList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const moveToNext = () => {
        console.log(selectedCustomer)
        let object = {}
        let list = []
        for(let i = 0; i < selectedCustomer.length; i++){
            if(i == 0){
                object = {
                    "customerID" : parseInt(selectedCustomer[i]),
                    "incotermsID" : parseInt(props.splitData.incotermsID),
                    "companyID" : props.splitData.companyID,
                    "tradeScenarioID" : 3,
                    "ratio" : props.splitData.ratio,
                    "ratioAmount" : props.splitData.ratioAmount,
                    "Is_DefaultCustomer" : true,
                    "Is_DefaultCompany" : true,
                }
            }
            else{
                object = {
                    "customerID" : parseInt(selectedCustomer[i]),
                    "incotermsID" : parseInt(props.splitData.incotermsID),
                    "companyID" : props.splitData.companyID,
                    "tradeScenarioID" : 3,
                    "ratio" : props.splitData.ratio,
                    "ratioAmount" : props.splitData.ratioAmount,
                    "Is_DefaultCustomer" : false,
                    "Is_DefaultCompany" : false,
                }
            }
            list.push(object)
        }
        console.log(list)
        props.customerData(selectedCustomer)
        props.clearData()
        props.handleSplitData(list)
        setModal(!modal)
    }

    const back = (data) => {
        setselectedCustomer(data)
    }

    const handleSearch = (e) => {
        let val = e.target.value
        let filter = customerList.filter(x => x.customercode.toLowerCase().includes(val.toLowerCase()) ||
            x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.category.toLowerCase().includes(val.toLowerCase()) ||
            x.incoterms.toLowerCase().includes(val.toLowerCase()) ||
            x.address.toLowerCase().includes(val.toLowerCase())
        )
        setCustomerFilterList(filter)
    }

    const errorPresent = (data) => {
        setcheckerror(data)
    }

    const addtoSelection = (list) => {
        setselectedCustomer(list)
    }

    return (
        <div className='trade_existing_customer'>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
                <ModalHeader className='mx-5' toggle={toggle}>
                    <div className='service-request-detail-title'>
                        <div><h4 class="font-16 text-uppercase font-weight-600 color-707895">SELECT CUSTOMER</h4></div>
                        <div className='me-3'>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingInput" label='Search'>
                                    <Form.Control type='text' placeholder='Search'
                                        className="form_input form_input_color_change"
                                        name='search'
                                        onChange={handleSearch}
                                        onBlur={handleSearch}
                                    />
                                </FloatingLabel>
                            </Col>
                        </div>
                    </div>
                </ModalHeader>
                
                <ModalBody className='service-request-modal-wrapper mx-3' style={{ overflowY: 'scroll' }}>
                    <div className=''>
                        <div className='mx-4 br-8'>
                            {checkerror &&
                                <span className='input_error'>Error: More than two customer's cannot be selected</span>
                            }
                            <List data={customerFilterList} back={back} selectedCustomer={props.selectedCustomer} errorPresent={errorPresent} addtoSelection={addtoSelection} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                    <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext} disabled={selectedCustomer != null && selectedCustomer.length == 2 ? false : true} /></span>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalForExistingCustomer