import React,{useEffect, useState} from 'react'
import {Row, Form, Col,} from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { BlueButton, BlueBorderButton } from '../../../../components/buttoncomponents'
import { useFormik } from 'formik';


export const Currency = (props)=> {
  return(
      <>
          <option value={1} selected={props.currencyID == 1}>USD</option>
      </>
  )
}
export default function AddBankAccount(props) {
    console.log(props)
    const [currencyTypeError, setCurrencyTypeError] = useState(false);
    const [isError, setIsError] = useState(true);
    useEffect(()=>{

    },[props])
    const formik = useFormik({
        initialValues:
        !props.isEdit?
        {
            bankName: '',
            accountNo: '',
            iban: '',
            currencyID: 0,
            currencyValue: '',
        }
        :
        {
            bankName: props.data.bankName,
            accountNo: props.data.accountNo,
            iban: props.data.iban,
            currencyID: props.data.currencyID,
            currencyValue: props.data.currencyValue,
            customerBankAccountInformationID : props.data.customerBankAccountInformationID
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;

        
            if (!values.bankName) {
                errors.bankName = "Please enter Bank Name"
               
            }
            if (!values.accountNo) {
                errors.accountNo = "Please enter Account Number"
      
            }
            if (!values.iban) {
                errors.iban = "Please enter IBAN Number"
               
            }
            if (!values.bankName) {
                errors.bankName = "Please enter Bank Name"
           
            }


            else if (!letters.test(values.name)) {
                errors.name = "Please enter a valid Contact Name"
            }
            if (!phone.test(values.telephoneNo) && values.telephoneNo){
                errors.telephoneNo = 'Please enter a valid Telephone Number'
            }
            if (!phone.test(values.mobileNo) && values.mobileNo){
                errors.mobileNo = 'Please enter a valid Mobile Number'
            }
          
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email) {
                errors.email = 'Please enter a valid Email Address';
            }
            setIsError(true)
            sendBack();
            return errors;
        }
    })

    const handleCurrency = (e)=>{
      formik.values.currencyID = e.target.value;
      var index = e.nativeEvent.target.selectedIndex;
      formik.values.currencyValue = e.nativeEvent.target[index].text;
      if(formik.values.currencyTypeError == 0){
        setCurrencyTypeError(true)
        setIsError(true)
        props.setErrors()
    }
    else if(Object.keys(formik.errors).length != 0){
        // setCurrencyTypeError(true)
        setIsError(true)
        props.setErrors()
    }
    else{
        setCurrencyTypeError(false)
        setIsError(false)
       
    }
  }
    
    const addBankInfo = ()=>{
        const bank_data={
          "bankName": formik.values.bankName,
          "accountNo": formik.values.accountNo,
          "iban" : formik.values.iban,
          "currencyID": parseInt(formik.values.currencyID),
          "currencyValue": formik.values.currencyValue,
          "is_Default" : false,
        }
        props.handleError() 
        props.addBanktoList(bank_data)
    }

    const editBank = ()=>{
        const bank_data={
          "bankName": formik.values.bankName,
          "accountNo": formik.values.accountNo,
          "iban" : formik.values.iban,
          "currencyID": parseInt(formik.values.currencyID),
          "currencyValue": formik.values.currencyValue,
          "is_Default" : props.data.is_Default,
          "key" :props.data.key,
          "customerBankAccountInformationID" : props.data.customerBankAccountInformationID
        }
        console.log(bank_data);
        props.handleError() 
        props.editBank(bank_data)
    }

    const sendBack = () => {

        if ((Object.keys(formik.errors).length == 0) ) {
            if(isError == true){
                console.log(formik.errors)
                console.log('error')
                //setIsError(true)
                props.setErrors()
            }
            console.log('error checking')
            if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                props.handleError()    
            }
            else if(formik.initialValues == formik.values){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                props.setErrors()
            }
            else if(formik.values.currencyTypeError == 0){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                props.setErrors()
            }
            else{
                setIsError(false)
                console.log(formik.errors)
                console.log('no error first condition')
                //props.handleError()    
            }
              
        }
        else{
            
            console.log(formik.errors)
            console.log('error')
            setIsError(true)
            props.setErrors()
        }
    }
    return (
        <Row className='d-flex ' style={{background: '#fff'}}>
            <Form>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='bankName'
                        label='Bank Name*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.bankName}
                        errors= {formik.touched.bankName?formik.errors.bankName:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='accountNo'
                        label='Account Number*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.accountNo}
                        errors= {formik.touched.accountNo?formik.errors.accountNo:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='iban'
                        label='IBAN*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.iban}
                        errors= {formik.touched.iban?formik.errors.iban:''}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='currencyID'
                        label='Currency*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={handleCurrency}
                        val={formik.values.currencyID}
                        optionField ={<Currency currencyID={props.isEdit?props.data.currencyID:''}/>}
                        errors= {currencyTypeError?'Please select a value':''}
                    />
                    <Col className='d-flex align-items-end pt-1-rem'>
                        <BlueButton value={props.isEdit?'Edit Contact':'Add New Account'} clickEvent={props.isEdit?editBank:addBankInfo} disabled = {isError}/>
                        <div className = 'ms-4'>
                            <BlueBorderButton value={'Cancel'} clickEvent={props.handleCancel}/>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Row>
    )
}
