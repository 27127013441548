import React, { useEffect } from 'react'
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import Moment from "moment";
import { useState } from 'react';
import * as FormComponent from "../../../components/formcomponents"
import { useFormik } from 'formik';
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function GenerateInvoice(props) {
    console.log(props)
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    const [dueDate, setDueDate] = useState(false)
    const [bankList, setBankList] = useState([])
    const [isBankOpen, setIsBankOpen] = useState(false)
    const [modal, setmodal] = useState(false)
    const [bankAccountList, setBankAccountList] = useState([])
    const [selectedBank, setSelectedBank] = useState([])
    const [formulamodal, setformulamodal] = useState(false)
    const [acutalqualitylist, setactualqualitylist] = useState([])
    const [formulanext, setformulanext] = useState(false)
    const [finalquantity, setfinalquantity] = useState(0)
    const [finalunitprice, setfinalunitprice] = useState(0)
    const [temfinalquantity, settemfinalquantity] = useState(0)
    const [temfinalunitprice, settempfinalunitprice] = useState(0)
    const [isformuladded, setisformuladded] = useState(false)

    const formik = useFormik({
        initialValues: {
            invoiceRemarks: '',
            bankAccountId: 0
        }
    })

    useEffect(() => {
        let finalList = []
        let index = 1
        setfinalquantity(props.data.quantity)
        setfinalunitprice(props.data.sallingPrice)
        settemfinalquantity(props.data.quantity)
        settempfinalunitprice(props.data.sallingPrice)
        props.data.qualityParameter.map((qt, k) => {
            let list = {
                "index": index,
                "parameterID": qt.qualityParameterID,
                "parameterType": 1,
                "parameterPer": 0,
                "parameterQty": 0,
                "parameterAmt": 0,
                "parameterAdjPer": 0,
                "parameterName": qt.qualityParametername
            }
            finalList.push(list)
            index++
        })
        props.data.traceElement.map((qt, k) => {
            let list = {
                "index": index,
                "parameterID": qt.traceElementID,
                "parameterType": 2,
                "parameterPer": 0,
                "parameterQty": 0,
                "parameterAmt": 0,
                "parameterAdjPer": 0,
                "parameterName": qt.traceElementName
            }
            finalList.push(list)
            index++
        })
        console.log(finalList)
        setactualqualitylist(finalList)
    }, [])

    const toggle = () => {
        setIsBankOpen(!isBankOpen)
    }


    const openBankList = () => {
        const json_list = {
            PMSAccountSubscriptionID: CONFIG.AccountSubscriptionID,
            PMSInstanceID: CONFIG.PMSInstanceID,
            companyID: props.companyID
        }

        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setBankAccountList(res.data.result[0].company.companyBankDetails)

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        setIsBankOpen(!isBankOpen)
    }

    const handleBankSelect = (e) => {
        console.log(e)
        if (e.target.checked) {
            let id = e.target.id
            let filter = bankAccountList.filter(x => x.bankDetailID == parseInt(id))
            console.log(filter)
            setSelectedBank(filter)
        }
    }
    const selectBank = () => {
        if (selectedBank.length > 0) {
            toggle()
        }
    }
    const saveInvoice = () => {
        console.log(selectedBank)
        console.log(dueDate)
        if (selectedBank.length > 0 && dueDate != false) {
            console.log('inside')
            const { value: text } = MySwal.fire(
                {
                    icon: 'warning',
                    title: 'Are you sure you wish to generate the invoice?',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Confirm',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-primary",
                    backdrop: `
                rgba(60, 60, 60, 0.5)
                no-repeat
              `
                }).then((result) => {
                    if (result.isConfirmed) {
                        const json_list = {
                            "instanceID": CONFIG.PMSInstanceID,
                            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                            "created_User": CONFIG.LoginID,
                            "tradeID": props.data.tradeID,
                            "customerID" : props.customerID,
                            "companyID" : props.companyID,
                            "customerBankDetailsID": selectedBank[0].bankDetailID,
                            "dueDate": dueDate,
                            "paymentTermID": props.data.customerPaymentTypeID,
                            "tradeCustInvActualResult": props.isFinal ? acutalqualitylist : null,
                            "customerInvAmount": parseFloat((finalquantity * finalunitprice * props.data.paymentPer) / 100),
                            "customerInvQty": finalquantity,
                            "invoiceRemarks": formik.values.invoiceRemarks,
                            "SellingUnitAmount": finalunitprice
                        }
                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_CUSTOMER_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                props.toggle()
                            }).catch((error) => {
                                if (error.response.status == 401) {
                                    localStorage.clear();
                                    instance.logoutRedirect({
                                        postLogoutRedirectUri: "/",
                                    });
                                }
                            })
                    }
    
                })
        }
        else{
            MySwal.fire(
                {
                    icon: 'error',
                    title: 'Error',
                    html: 'Due date and bank information should be selected',
                    type: 'success',
                    confirmButtonText: 'Cancel',
                    confirmButtonClass: "btn btn-primary",

                }).then(() => {
                    
                });
        }
        

    }

    const handlemeetingdate = (date) => {
        console.log(date)
        if (date == null) {
            setDueDate(false)
        }
        else {
            setDueDate(date)
        }
    }

    const handleQualityPercentage = (e) => {
        let id = parseInt(e.target.id)
        let value = e.currentTarget.value
        console.log(id)
        console.log(value)
        let actuallist = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.parameterID == id) {
                console.log(actuallist[k])
                actuallist[k].parameterPer = parseFloat(value)
            }
        })
        console.log(actuallist)
        setactualqualitylist(actuallist)
    }

    const handleTracePercentage = (e) => {
        let id = parseInt(e.target.id)
        let value = e.currentTarget.value
        console.log(id)
        console.log(value)
        let actuallist = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.parameterID == id) {
                console.log(actuallist[k])
                actuallist[k].parameterPer = parseFloat(value)
            }
        })
        console.log(actuallist)
        setactualqualitylist(actuallist)
    }

    const handleFormula = () => {
        setformulamodal(!formulamodal)
        setformulanext(false)
    }

    const handleCheck = (e) => {
        let val = [...acutalqualitylist]
        if (e.target.checked) {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].ischeck = true
                    val[k].parameterAdjStatus = true
                }
            })
        }
        else {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].ischeck = false
                    val[k].parameterAdjStatus = false
                }
            })
        }
        console.log(val)
        setactualqualitylist(val)
    }

    const handleQuantityAdjustment = (e) => {
        let val = [...acutalqualitylist]
        if (e.target.checked) {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].isquantity = true
                }
            })
        }
        else {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].isquantity = false
                }
            })
        }
        console.log(val)
        setactualqualitylist(val)
    }

    const handleAdjustment = (e) => {
        let index = parseInt(e.target.id)
        let val = e.currentTarget.value
        let li = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.index == index) {
                li[k].ParameterFormula = val
            }
        })
        console.log(li)
        setactualqualitylist(li)
    }



    const handleValue = (e) => {
        let index = parseInt(e.target.id)
        let val = e.target.value
        let li = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.index == index) {
                if (list.isquantity) {
                    li[k].parameterQty = parseFloat(val)
                }
                else {
                    li[k].parameterAmt = parseFloat(val)
                }
            }
        })
        console.log(finalquantity)
        console.log(li)
        setactualqualitylist(li)
    }

    const saveFormula = () => {
        let li = [...acutalqualitylist]
        console.log(temfinalquantity)
        console.log(temfinalunitprice)
        let finalqn = temfinalquantity
        let finalupr = temfinalunitprice
        acutalqualitylist.map((list, k) => {
            if (list.isquantity) {
                finalqn = finalqn - li[k].parameterQty
            }
            else {
                finalupr = finalupr - li[k].parameterAmt
            }
        })
        console.log(finalqn)
        console.log(finalupr)
        setfinalquantity(finalqn)
        setfinalunitprice(finalupr)
        setisformuladded(true)
        handleFormula()
    }

    return (
        <Card>
            <Card.Body className=' p-3-rem list-scroll'>
                <div className='d-flex justify-content-end'>
                    <img src='assets/images/logo.svg' />
                </div>
                <div className='font-weight-600 d-flex justify-content-center uline'>
                    COMMERCIAL INVOICE
                </div>
                <div className='d-flex justify-content-between mt-1-rem'>
                    <div>
                        <div className='font-weight-500'>
                            INVOICE NUMBER:
                        </div>
                        <div className='font-weight-500'>
                            VAT TRN NO:
                        </div>
                    </div>
                    <div>
                        <div className='font-weight-500'>
                            INVOICE DATE: {Moment(new Date()).format('DD/MM/YYYY')}
                        </div>
                        <div className='font-weight-500 d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>DUE DATE:</div>
                            <div>
                                <FormComponent.DatePickBottomBorder
                                    md={12}
                                    handleCompletionDate={handlemeetingdate}
                                    label='Due Date'
                                    val={dueDate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='invoice_border  p-1-rem mt-1-rem'>
                    <div className='font-weight-500'>
                        NAME AND ADDRESS OF CUSTOMER:
                    </div>
                    <div>
                        {props.data.customerName}<br />
                        {props.data.customerAddress}<br />
                        {props.data.companyTelephone}<br />
                        {props.data.companyWebsite}<br />
                    </div>
                    <div className='font-weight-600 d-flex justify-content-center'>
                        CONTRACT NUMBER : {props.data.tradeNo}
                    </div>
                </div>
                <table className='invoice_border p-3-rem width-100l'>
                    <thead className='invoice_border'>
                        <tr className='width-100l'>
                            <th clssName='br-right'>S.NO</th>
                            <th clssName='br-right'>DESCRIPTION OF GOODS</th>
                            <th clssName='br-right'>QUANTITY</th>
                            <th clssName='br-right'>UNIT PRICE</th>
                            <th clssName='br-right'>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                <div className='font-weight-600 uline'>{props.data.productName}</div>
                                <div className='mt-1-rem font-weight-500'>COUNTRY OF ORIGIN : {props.data.loadPortCountry}</div>
                                <div className='mt-1-rem'><span className='uline'>BILL OF LADING, DATE, QUANTITY :</span> <br />
                                    {props.data.billOfLadingNo}<br />
                                    {props.data.billOfLadingDate}<br />
                                    {props.data.quantity}<br />
                                </div>
                                <div className='mt-1-rem font-weight-500'>PORT OF LOADING : {props.data.loadPort}, {props.data.loadPortCountry}</div>
                                <div className='font-weight-500'>PORT OF DISCHARGE : {props.data.loadPort}, {props.data.destinationPort}</div>
                                <div className='font-weight-500'>TRADE TERM : {props.data.loadPort}</div>
                                <div className='font-weight-500'>VESSEL NAME : {props.data.vesselName}</div>
                                <div className='font-weight-500'>UNIT PRICE : {props.data.sallingPrice} USD</div>
                                <div className='font-weight-500'>PAYMENT TERMS : {props.data.paymentType} USD</div>
                                <div className='font-weight-500'>
                                    <FormComponent.InputField
                                        name='invoiceRemarks'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Remarks"
                                    />
                                </div>
                                {props.isFinal &&
                                    <div>
                                        <div className='parameters_div'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>ITEM</th>
                                                        <th>ACTUAL RESULTS</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.data.qualityParameter.map((qt, k) => {
                                                        return (
                                                            <tr>
                                                                <td>{qt.qualityParametername}</td>
                                                                <td>
                                                                    <FormComponent.InputField
                                                                        label='%'
                                                                        id={qt.qualityParameterID}
                                                                        onChange={handleQualityPercentage}
                                                                        onBlur={handleQualityPercentage}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    {props.data.traceElement.map((qt, k) => {
                                                        return (
                                                            <tr>
                                                                <td>{qt.traceElementName}</td>
                                                                <td>
                                                                    <FormComponent.InputField
                                                                        label='%'
                                                                        id={qt.traceElementID}
                                                                        onChange={handleTracePercentage}
                                                                        onBlur={handleTracePercentage}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <Modal isOpen={formulamodal} toggle={handleFormula} className='mt-10-rem property_info_modal modal_65 mt-5 '>
                                            <ModalHeader toggle={handleFormula}>
                                                <div className='font-16 font-weight-600 color-707895'>UNIT PRICE AND QUALITY ADJUSTMENT</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className='mt-1-rem font-weight-500'>SELECT PARAMETERS TO BE ADJUSTED</div>
                                                <div className='parameters_div'>
                                                    <table>
                                                        <thead>
                                                            {formulanext ?
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>ACTUAL RESULTS</th>
                                                                    <th>FORMULA TEXT</th>
                                                                    <th></th>
                                                                    <th>ADJUSTMENT</th>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>ACTUAL RESULTS</th>
                                                                    <th>

                                                                    </th>
                                                                </tr>

                                                            }
                                                        </thead>
                                                        <tbody>
                                                            {formulanext ?
                                                                acutalqualitylist.map((list, k) => {
                                                                    return (
                                                                        <>
                                                                            {list.ischeck &&
                                                                                <tr>
                                                                                    <td>{list.parameterName}</td>
                                                                                    <td>{list.parameterPer} %</td>
                                                                                    <td>
                                                                                        <FormComponent.InputField
                                                                                            id={list.index}
                                                                                            onChange={handleAdjustment}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Check
                                                                                            label='Quantity Adjustment'
                                                                                            id={list.index}
                                                                                            onChange={handleQuantityAdjustment}
                                                                                        />

                                                                                    </td>
                                                                                    <td>
                                                                                        <FormComponent.InputField
                                                                                            id={list.index}
                                                                                            onChange={handleValue}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                acutalqualitylist.map((list, k) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{list.parameterName}</td>
                                                                            <td>{list.parameterPer} %</td>
                                                                            <td>
                                                                                <Form.Check
                                                                                    id={list.index}
                                                                                    onChange={handleCheck}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <span><BlueBorderButton value='Cancel' clickEvent={handleFormula} /></span>
                                                <span className='pl-10'><BlueButton value={formulanext ? 'Save' : 'Next'} clickEvent={() => {
                                                    formulanext ?
                                                        handleFormula()
                                                        :
                                                        setformulanext(true)
                                                }} /></span>
                                            </ModalFooter>
                                        </Modal>
                                        <div className='mt-1-rem d-flex justify-content-between'>
                                            <div className='uline'> DETAILS OF CALCULATION OF UNIT PRICE</div> <br />
                                            <div>
                                                <Button variant="outline-secondary" size="md" className={'font-weight-500 font-14 rounded-13'} type="button" onClick={handleFormula} >
                                                    <img src='assets/images/blue_add.svg' />
                                                </Button>
                                            </div>
                                        </div>
                                        {isformuladded &&
                                            <>

                                                <div className='parameters_div'>
                                                    {acutalqualitylist.map((list, k) => {
                                                        if (list.ischeck) {
                                                            return (
                                                                <>
                                                                    <div>{list.parameterName} :</div>
                                                                    <div>
                                                                        {list.ParameterFormula} = {list.isquantity ? list.parameterQty + ' MT' : list.parameterAmt + ' USD'}
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    })}
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>TOTAL QUANTITY AFTER MOISTURE ADJUSTMENT :</td>
                                                                <td>{finalquantity}</td>
                                                            </tr>
                                                            {acutalqualitylist.map((list, k) => {
                                                                if (list.ischeck) {
                                                                    if (!list.isquantity) {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>LESS: TOTAL {list.parameterName} ADJUSTMENT</td>
                                                                                    <td>USD {list.parameterAmt}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                }

                                                            })}
                                                            <tr>
                                                                <td>UNIT PRICE AFTER ADJUSTMENT</td>
                                                                <td>{finalunitprice}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        }
                                        <Modal isOpen={formulamodal} toggle={handleFormula} className='mt-10-rem property_info_modal modal_65 mt-5 '>
                                            <ModalHeader toggle={handleFormula}>
                                                <div className='font-16 font-weight-600 color-707895'>UNIT PRICE AND QUALITY ADJUSTMENT</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className='mt-1-rem font-weight-500'>SELECT PARAMETERS TO BE ADJUSTED</div>
                                                <div className='parameter_div'>
                                                    <table>
                                                        <thead>
                                                            {formulanext ?
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>ACTUAL RESULTS</th>
                                                                    <th>FORMULA TEXT</th>
                                                                    <th></th>
                                                                    <th>ADJUSTMENT</th>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th>ITEM</th>
                                                                    <th>ACTUAL RESULTS</th>
                                                                    <th>

                                                                    </th>
                                                                </tr>

                                                            }
                                                        </thead>
                                                        <tbody>
                                                            {formulanext ?
                                                                acutalqualitylist.map((list, k) => {
                                                                    return (
                                                                        <>
                                                                            {list.ischeck &&
                                                                                <tr>
                                                                                    <td>{list.parameterName}</td>
                                                                                    <td>{list.parameterPer} %</td>
                                                                                    <td>
                                                                                        <FormComponent.InputField
                                                                                            id={list.index}
                                                                                            onChange={handleAdjustment}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Check
                                                                                            label='Quantity Adjustment'
                                                                                            id={list.index}
                                                                                            onChange={handleQuantityAdjustment}
                                                                                        />

                                                                                    </td>
                                                                                    <td>
                                                                                        <FormComponent.InputField
                                                                                            id={list.index}
                                                                                            onChange={handleValue}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                acutalqualitylist.map((list, k) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{list.parameterName}</td>
                                                                            <td>{list.parameterPer} %</td>
                                                                            <td>
                                                                                <Form.Check
                                                                                    id={list.index}
                                                                                    onChange={handleCheck}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <span><BlueBorderButton value='Cancel' clickEvent={handleFormula} /></span>
                                                <span className='pl-10'><BlueButton value={formulanext ? 'Save' : 'Next'} clickEvent={() => {
                                                    formulanext ?
                                                        saveFormula()
                                                        :
                                                        setformulanext(true)
                                                }} /></span>
                                            </ModalFooter>
                                        </Modal>
                                    </div>
                                }
                                <div className='d-flex justify-content-end mt-1-rem'>
                                    <Button onClick={openBankList}>Choose Bank</Button>
                                </div>
                                {selectedBank.length > 0 &&
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>BENIFICIARY</td>
                                                <td>:</td>
                                                <td>{props.data.companyName}</td>
                                            </tr>
                                            <tr>
                                                <td>BENIFICIARY ADDRESS</td>
                                                <td>:</td>
                                                <td>{props.data.customerAddress}</td>
                                            </tr>
                                            <tr>
                                                <td>IBAN A/C NO</td>
                                                <td>:</td>
                                                <td>{selectedBank[0].ibanNo} / {selectedBank[0].accountNo}</td>
                                            </tr>
                                            <tr>
                                                <td>BANK NAME AND ADDRESS</td>
                                                <td>:</td>
                                                <td>{selectedBank[0].bankAddress}</td>
                                            </tr>
                                            <tr>
                                                <td>SWIFT</td>
                                                <td>:</td>
                                                <td>{selectedBank[0].swiftCode}</td>
                                            </tr>
                                            <tr>
                                                <td>CORRESP BANK</td>
                                                <td>:</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                }
                                {isBankOpen &&
                                    <Modal isOpen={isBankOpen} toggle={toggle} className='modal-dialog-custom-width trade_existing_customer'>
                                        <ModalHeader toggle={toggle}>

                                        </ModalHeader>
                                        <ModalBody className=''>
                                            {bankAccountList != null && bankAccountList.length > 0 ?

                                                <div class="col-lg-12 p-md-4 p-2 ">
                                                    <div class="table-responsive-sm list-scroll">
                                                        <table className="table tCustom table-text">
                                                            <thead className='p-1-rem'>
                                                                <tr className='text-uppercase list-top'>
                                                                    <th>Account Name</th>
                                                                    <th>Account No.</th>
                                                                    <th>Bank Name</th>
                                                                    <th>IBAN No.</th>
                                                                    <th>Status</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {bankAccountList.map((values, k) => (
                                                                    <tr className=''>
                                                                        <td>
                                                                            {values.accountName}
                                                                        </td>
                                                                        <td>
                                                                            {values.accountNo}
                                                                        </td>
                                                                        <td>
                                                                            {values.bankName}
                                                                        </td>
                                                                        <td>
                                                                            {values.ibanNo}
                                                                        </td>
                                                                        <td>
                                                                            <div className={values.bankStatus == true ? 'bankstatus-pill-active' : 'bankstatus-pill-inactive'}>
                                                                                {values.bankStatus == true ? 'Active' : 'Inactive'}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Check
                                                                                className=''
                                                                                type='radio'
                                                                                name='bankselect'
                                                                                id={values.bankDetailID}
                                                                                onChange={handleBankSelect}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>



                                                :
                                                <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                    <div>
                                                        <div className='font-weight-600 color-243448'>No Bank Accounts Created</div>
                                                    </div>
                                                </div>}
                                        </ModalBody>
                                        <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                                            <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                                            <span className='pl-10'><BlueButton value='Save' clickEvent={selectBank} disabled={selectedBank != null && selectedBank.length > 0 ? false : true} /></span>
                                        </ModalFooter>
                                    </Modal>
                                }
                            </td>
                            <td >
                                <span className='font-weight-500'>{finalquantity} MT</span>
                            </td>
                            <td>
                                <div className='font-weight-500'>{finalunitprice} USD</div>
                                <div className='mt-1-rem font-weight-600'>{props.data.loadPort}, {props.data.loadPortCountry}</div>
                            </td>
                            <td>
                                {isformuladded ?

                                    <span className='font-weight-500'>{(finalquantity * finalunitprice * props.data.paymentPer) / 100} USD</span>
                                    :
                                    <span className='font-weight-500'>{props.data.amount} USD</span>
                                }

                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card.Body>
            <Card.Footer>
                <span><BlueBorderButton value='Cancel' clickEvent={props.toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={saveInvoice} /></span>
            </Card.Footer>
        </Card >
    )
}
