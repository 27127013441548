import React, {useState, useEffect} from 'react'
import {Row, Col} from "react-bootstrap";
import { BlueButton } from '../../../../../../components/buttoncomponents'
import Slist from './slist';

export default function Supply_plan_list(props) {
    console.log(props, 'data') 
    const [total, settotal] = useState(0)
    useEffect(()=>{
        let total = 0
        if(props.supplylist != null){
            props.supplylist.map((val)=>{
                total += parseFloat(val.quantity)
            })
            settotal(total)
            console.log(total)
        }
        else{
            props.values.map((val)=>{
                total += parseFloat(val.quantity)
            })
            settotal(total)
        }
    },[props])
    return (
        <div>
            <div className='d-flex justify-content-between mb-4 align-self-center pt-4'>
                <h4 className='font-16 font-weight-600 mb-0 color-707895 align-self-center'>Supply Plan Details</h4>
               
            </div>
            <div className='bg-F8F9FA font-weight-500 color-707895 d-flex justify-content-between table_heading align-items-center px-4'>
                <Col md={2}>Supply#</Col>
                <Col md={2}>Year</Col>
                <Col md={2}>Month</Col>
                <Col md={2}>Quantity</Col>
                <Col md={2}>Status</Col>
                <Col md={1}></Col>
            </div>
            {props.values.length > 0 ? 
            <>   
                {props.values.map((list,k)=>(
                    <Slist supplylist={props.supplylist} yearList={props.edit == true ? props.values[k].year : props.yearList[k]} monthList={props.monthList[k]} list={list} editList={props.editList} serialNumber = {k+1} handleSerialNumber = {props.handleSerialNumber} testing = {'123'}/>
                ))}
                <div className='d-flex justify-content-center pl-80 mt-1-rem'>
                    Total Quantity: <span className='font-weight-500 font-14'>&nbsp;{props.totalQuantity} MT</span>
                </div>
            </>
            :
            <>   
                {props.supplylist.map((list,k)=>(
                    <Slist supplylist={props.supplylist} yearList={props.edit == true ? props.values[k].year : props.yearList[k]} monthList={props.monthList[k]} list={list} editList={props.editList} serialNumber = {k+1} handleSerialNumber = {props.handleSerialNumber} values = {list} />
                ))}
                <div className='d-flex justify-content-center pl-80 mt-1-rem'>
                    Total Quantity: <span className='font-weight-500 font-14'>&nbsp;{total} MT</span>
                </div>
            </>
            }
            </div>
    )
}
