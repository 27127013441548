import React, { useEffect, useState } from 'react'
import {Card} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ContractTermsTab from './Contract Terms Tab/ContractTermsTab';
import CustomerDetailsTab from './Customer Details Tab/CustomerDetailsTab';
import ProductDetailsTab from './Product Details Tab/ProductDetailsTab';
import SupplyPlanTab from './Supply Plan Tab/SupplyPlanTab';
import moment from "moment"
import * as CONFIG from  "../../../helpers/config"
import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BackButton } from '../../../components/buttoncomponents';
import { useMsal } from "@azure/msal-react";

export default function TradeEnquiryTabs(props) {
    console.log(props)
    const { instance } = useMsal();
    console.log(props, 'TradeEnquiry')
    const [selectedTab, settab] = useState(0)
    const [customerdat, setCustomerData] = useState([]);
    const [isdataavailable, setisdataavailable] = useState(false)
    const [destinationport, setdestinationport]= useState('');
    const [contractterms, setterms] = useState([]);
    const [iscontractavailable, setcontractAvailable] = useState(false)
    const [isproductavailable, setisproductavailable] = useState(false)
    const [productData, setProductData] = useState([])
    const [isFirst, setIsFirst] = useState(false)
    const [prevClicked, setPrevClicked] = useState(false)
    const [getData, setGetData] = useState()
    const [documentDetails, setDocumentDetails] = useState()
    const MySwal = withReactContent(Swal)
    useEffect(()=>{
        if(props.isEdit){
            let contractList ={
                "contractTypeID": props.selectedEnquiry.enqiry.contractTypeID,
                "modeOfTransportID": props.selectedEnquiry.enqiry.modeOfTransportID,
                "incotermsID": props.selectedEnquiry.enqiry.incotermsID,
                "loadPort": props.selectedEnquiry.enqiry.loadPort,
                "destinationPort": props.selectedEnquiry.enqiry.destinationPort
            }
            setterms(contractList);
            setcontractAvailable(true)
            let product_list_from_api = props.selectedEnquiry.enqiry.tradeEnquiryProductDetail
            let product_list = []
            product_list_from_api.map((list,k)=>{
                let pr_list = {
                    "key": k+1,
                    "productID": list.productID,
                    "tradeEnquiryProductDetailID": list.tradeEnquiryProductDetailID,
                    "tradeEnquiryID": list.tradeEnquiryID,
                    "productGradeID": 1,
                    "productQuantity": list.productQuantity,
                    "productUOMID": list.productUOMID,
                    "productSellingPrice": list.productSellingPrice,
                    "productName": list.product.productName,
                    "productCode": list.product.productCode,
                    "productGroupID": list.product.productGroupID
                }
                let quality_parameter = list.tradeEnquiryProductQualityParameters
                let qr_list = []
                quality_parameter.map((par,k)=>{
                    let qr = {
                        "key": k+1,
                        "tradeEnquiryProductQualityParametersID": par.tradeEnquiryProductQualityParametersID,
                        "tradeEnquiryProductDetailID": par.tradeEnquiryProductDetailID,
                        "productQualityParameterID": par.productQualityParameterID,
                        "typicalValueRange": par.typicalValueRange,
                        "rejectionValueRange": par.rejectionValueRange
                    }
                    qr_list.push(qr)
                })
                pr_list.tradeEnquiryProductQualityParameters = qr_list
                
                let trace_elements = list.tradeEnquiryProductTraceElement
                let tr_list = []
                trace_elements.map((tar,k)=>{
                    let tr ={
                        "key": k+1,
                        "tradeEnquiryProductTraceElementID": tar.tradeEnquiryProductTraceElementID,
                        "tradeEnquiryProductDetailID": tar.tradeEnquiryProductDetailID,
                        "productTraceElementID": tar.productTraceElementID,
                        "typicalValueRange": tar.typicalValueRange,
                        "rejectionValueRange": tar.rejectionValueRange
                    }
                    tr_list.push(tr)
                })
                pr_list.tradeEnquiryProductTraceElement = tr_list

                let supply_plan = list.tradeEnquiryProductSupplyplan
                let s_list = []
                console.log(supply_plan, 'testing supply plan interval')
                supply_plan.map((sp,k)=>{
                    let sl ={
                        "key": k+1,
                        "tradeEnquiryProductSupplyplanID": sp.tradeEnquiryProductSupplyplanID,
                        "tradeEnquiryProductDetailID": sp.tradeEnquiryProductDetailID,
                        "year": sp.year,
                        "month": sp.month,
                        "quantity": sp.quantity,
                        "supplyNo": sp.supplyNo,
                        "supplyStatus": sp.supplyStatus,
                        "supplyTermsID": sp.supplyTermsID
                    }
                    s_list.push(sl)
                })
                pr_list.tradeEnquiryProductSupplyplan = s_list
                product_list.push(pr_list)
            })
            console.log(product_list, 'daata from api')
            setProductData(product_list)
            setisproductavailable(true)
        }
    },[])
    const addToTab = ()=>{
        settab(selectedTab+1)
    }

    const back= () =>{
        
        console.log('checked ')
        setPrevClicked(true)
        settab(selectedTab-1)
    }


    const customerData = (data)=>{
        setCustomerData(data)
        setisdataavailable(true)
        addToTab()
        setdestinationport(data[0].customerdata.finalDestination);
        console.log(data[0].customerdata.finalDestination)
    }

    const saveContract = (data)=>{
        setterms(data);
        console.log(data, 'contract page data')
        setcontractAvailable(true)
        addToTab();
    }

    const addTraceAndQuality = (data) =>{
        console.log('inside')
        console.log(data)
        let totaldata = productData
        console.log(productData)
        if(Array.isArray(data)){
            if(totaldata.length>0){
                if(totaldata.some(trade => trade.tradeEnquiryProductSupplyplan)){
                    if(data.some(d => d.tradeEnquiryProductQualityParameters)){
                        totaldata[0].tradeEnquiryProductQualityParameters = data[0].tradeEnquiryProductQualityParameters
                    }
                    if(data.some(d => d.tradeEnquiryProductTraceElement)){
                        totaldata[0].tradeEnquiryProductTraceElement = data[0].tradeEnquiryProductTraceElement
                    }
                }
                else{
                    if(data.some(d => d.tradeEnquiryProductQualityParameters)){
                        totaldata[0].tradeEnquiryProductQualityParameters = data[0].tradeEnquiryProductQualityParameters
                    }
                    if(data.some(d => d.tradeEnquiryProductTraceElement)){
                        totaldata[0].tradeEnquiryProductTraceElement = data[0].tradeEnquiryProductTraceElement
                    }
                }
                setProductData(totaldata, 'product page data')
                setisproductavailable(true)
            }else{
                setProductData(data, 'product page data')
                setisproductavailable(true)
            }
        }
        addToTab();
    }

    const saveList = (data)=>{
        console.log('inside', data)
        setProductData(data)
    }
    const addTradeEnquiry = ()=>{
        console.log(props)
        const final_json = { ...contractterms}
        final_json["customerID"] = customerdat[0].customerdata.customerID
        final_json.contractTypeID = parseInt(final_json.contractTypeID)
        final_json.incotermsID = parseInt(final_json.incotermsID)
        final_json.modeOfTransportID = parseInt(final_json.modeOfTransportID)
        // // final_json.customerRequirementWorkFlowID = props.selectedEnquiry.enqiry.customerRequirementWorkFlowID != null ? props.selectedEnquiry.enqiry.customerRequirementWorkFlowID : null
        // final_json.customerRequirementWorkFlowStatus = props.selectedEnquiry.enqiry.customerRequirementWorkFlowStatus != null ? props.selectedEnquiry.enqiry.customerRequirementWorkFlowStatus : null
        // final_json.customerRequirementApprovalComment = props.selectedEnquiry.enqiry.customerRequirementApprovalComment != null ? props.selectedEnquiry.enqiry.customerRequirementApprovalComment : null
        // final_json.custRequirementApprovalModified_Datetime = props.selectedEnquiry.enqiry.custRequirementApprovalModified_Datetime ? props.selectedEnquiry.enqiry.custRequirementApprovalModified_Datetime : null
        // final_json.custRequirementApprovedModified_By = props.selectedEnquiry.enqiry.custRequirementApprovedModified_By != null ? props.selectedEnquiry.enqiry.custRequirementApprovedModified_By : null
        final_json["tradeEnquiryProductDetail"] =  productData
        final_json["enquiryDate"] = moment(new Date).format("YYYY-MM-DDTHH:mm:ss")
        final_json["instanceID"] = CONFIG.PMSInstanceID
        final_json["accountSubscriptionID"] = CONFIG.AccountSubscriptionID
        final_json["created_User"] = CONFIG.LoginID
        final_json["tax1"] = "0"
        final_json["tax2"] = "0"
        final_json["QuoteDocument"] = documentDetails != null ? documentDetails.QuoteDocument : ""
        final_json["QuoteDocumentName"] = documentDetails != null ? documentDetails.QuoteDocumentName : ""
        if(props.isEdit){
            final_json["divisionID"] = props.selectedEnquiry.enqiry.divisionID
            final_json["companyid"] = props.selectedEnquiry.enqiry.companyID
        }
        else{
            final_json["divisionID"] = Array.isArray(props.selectedDivision) == true ? props.selectedDivision[0].divisionID : props.selectedDivision.divisionID
            final_json["companyid"] =   Array.isArray(props.selectedDivision) == true ? props.selectedDivision[0].companyID : props.selectedDivision.companyID 
        }
        console.log(final_json)
        if(props.isEdit){
            final_json["tradeEnquiryID"] = props.selectedEnquiry.enqiry.tradeEnquiryID
            final_json["enquiryNo"] = props.selectedEnquiry.enqiry.enquiryNo
            final_json.tradeEnquiryProductDetail[0].noOfSupply = props.selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].noOfSupply
            final_json["CustomerRequirementWorkFlowStatus"] = props.selectedEnquiry.enqiry.customerRequirementWorkFlowStatus
            // console.log(final_json)
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.UPDATE_TRADEENQUIRY, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode == '304001'){
                    MySwal.fire(
                        {
                        icon: 'error',
                        title: 'Something went wrong',
                        //type: 'error',
                        confirmButtonText: 'Back to Customer Requirement List',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        props.back()
                       //redirecting 
                       //history.push("/login")
                    }); 
                }
                else if(res.data.responseCode == '304000'){
                    MySwal.fire(
                        {
                        icon: 'success',
                        title: 'Customer Requirement updated Successfully!',
                        html: '<p>Requirement No: ' + '<b>' + res.data.result + '</b>' +  '</p>',
                        type: 'success',
                        confirmButtonText: 'Back to Customer Requirement List',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        props.back()
                       //redirecting 
                       //history.push("/login")
                    });
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            console.log(final_json)
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_TRADEENQUIRY, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then(res => {
                if(res.data.responseCode == '304001' || res.data.responseCode != '304000'){
                    MySwal.fire(
                        {
                        icon: 'error',
                        title: 'Something went wrong. Please try again',
                        //type: 'error',
                        showCancelButton: true,
                        confirmButtonText: 'Back to Customer Requirement List',
                        confirmButtonClass: "btn btn-primary",
                        cancelButtonText:"Cancel",
                        reverseButtons: true,
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                        }).then((result) => {
                            if(result.isConfirmed){
                                props.back()
                            }
                        });
                }
                else if(res.data.responseCode == '304000'){
                    MySwal.fire(
                        {
                        icon: 'success',
                        title: 'Customer Requirement added Successfully!',
                        html: '<p>Requirement No: ' + '<b>' + res.data.result + '</b>' +  '</p>',
                        type: 'success',
                        confirmButtonText: 'Back to Customer Requirement List',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        props.back()
                       //redirecting 
                       //history.push("/login")
                    });
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        console.log(productData)
    }

    const handleIsFirst = () => {
        setIsFirst(true)
    }

    useEffect(()=>{

    },[isFirst])

    useEffect(()=>{

    },[contractterms])

    const handleDocumentAttached = (data) => {
        console.log(data)
        setDocumentDetails(data)
    }

    useEffect(() => {

    },[documentDetails])
    return (
        <div className=' bg-transparent br-8 py-3'>
            
            <Tabs selectedIndex= {selectedTab}>
                <div className='tabs-wrapper pl-0 bg-white px-3'>
                    <TabList>
                        <Tab>Customer Detail</Tab>
                        <Tab>Contract Terms</Tab>
                        <Tab>Product Details</Tab>
                        <Tab>Supply Plan</Tab>
                    </TabList>               
                </div>
                <TabPanel className='mt-1-rem'>
                    <CustomerDetailsTab 
                        customerData={customerData} 
                        isdataavailable = {isdataavailable}
                        custData={customerdat}
                        isEdit={props.isEdit}
                        selectedEnquiry={props.selectedEnquiry}
                        back={props.back}
                        handleDocumentAttached = {handleDocumentAttached}
                        documentDetails = {documentDetails}

                    />
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <ContractTermsTab  back={back} destinationport={destinationport} saveContract = {saveContract} iscontractavailable={iscontractavailable} contractterms={contractterms}/>
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <ProductDetailsTab 
                        selectedDivision = {props.selectedDivision}
                        back={back} 
                        addTraceAndQuality={addTraceAndQuality} 
                        isproductavailable={isproductavailable} 
                        productData ={productData}
                        isEdit={props.isEdit}
                    />
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <SupplyPlanTab 
                        contractterms = {contractterms}
                        handleIsFirst = {handleIsFirst}
                        back={back} 
                        isproductavailable={isproductavailable} 
                        productData ={productData}
                        saveList ={saveList}
                        addTradeEnquiry={addTradeEnquiry}
                        isEdit={props.isEdit}
                        selectedDivision = {props.selectedDivision}
                        prevClicked = {prevClicked}
                    />
                </TabPanel>
            </Tabs>
        </div>
    )
}
