import React, { useEffect } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, NavLink } from 'react-router-dom';
import * as SideBarIcon from "./sidebardIcons";
import 'react-pro-sidebar/dist/css/styles.css';
import { slide as BurgerMenu } from 'react-burger-menu'

export default function Sidebar(props) {
    useEffect(() => {
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href = "/"
        }
        else {

        }
    })
    return (
        <ProSidebar className='sidebar-component desktop_view'>
            <SidebarHeader className='mb-2-rem'>
                <Link to='/dashboard'>
                    <img src="/assets/images/tmslanding/tmslogo.svg" className='img-fluid' />
                </Link>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">
                    {localStorage.getItem('Dashboards_Menu') == 1 || localStorage.getItem('Dashboards_Menu') == 3 ?
                        <MenuItem icon={<SideBarIcon.DashboardIcon />} className={props.id == 1 ? 'menu-item-active' : ''}>
                            <NavLink to="/dashboard">
                                Dashboard
                            </NavLink>
                        </MenuItem>
                        :
                        <></>
                    }
                    {localStorage.getItem('Customers_Menu') == 1 || localStorage.getItem('Customers_Menu') == 3 ?
                        <SubMenu title="Customer" icon={<SideBarIcon.CustomerIcon />} defaultOpen={props.isOpenid == 2 ? true : false}>
                            <MenuItem className={props.id == '2.1' ? 'menu-item-active' : ''}>
                                <NavLink to="/customers">
                                    All Customers
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '2.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/customers-category">
                                    Customer Category
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '2.3' ? 'menu-item-active' : ''}>
                                <NavLink to="/customers-industry">
                                    Customer Industry
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        : <></>
                        }
                        {localStorage.getItem('Suppliers_Menu') == 1 || localStorage.getItem('Suppliers_Menu') == 3 ?
                        <SubMenu title="Suppliers" icon={<SideBarIcon.SupplierIcon />} defaultOpen={props.isOpenid == 3 ? true : false}>
                            <MenuItem className={props.id == '3.1' ? 'menu-item-active' : ''}>
                                <NavLink to="/suppliers">
                                    All Suppliers
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '3.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/suppliers-category">
                                    Suppliers Category
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '3.3' ? 'menu-item-active' : ''}>
                                <NavLink to="/suppliers-industry">
                                    Suppliers Industry
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        : <></>
                    }
                    {localStorage.getItem('Financial-Menu') == 1 || localStorage.getItem('Financial-Menu') == 3 ?
                        <SubMenu title="Financial Institutions" icon={<SideBarIcon.Financial />} defaultOpen={props.isOpenid == 9 ? true : false}>
                            <MenuItem className={props.id == '9.1' ? 'menu-item-active' : ''}>
                                <NavLink to="/financialinstitutions">
                                    Institutions
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '9.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/contactlist">
                                    Contacts
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        :
                        <></>
                    }
                    {localStorage.getItem('Products_Menu') == 1 || localStorage.getItem('Products_Menu') == 3 ?
                        <SubMenu title="Products" icon={<SideBarIcon.ProductIcon />} defaultOpen={props.isOpenid == 4 ? true : false}>
                            {(localStorage.getItem('Products_Menu') == 1 || localStorage.getItem('Products_Menu') == 3) &&
                                <>
                                    <MenuItem className={props.id == '4.1' ? 'menu-item-active' : ''}>
                                        <NavLink to="/products" >
                                            All Products
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink to="/productcategories" className={props.id == '4.2' ? 'menu-item-active' : ''}>
                                            Product Categories
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink to="/productgroups" className={props.id == '4.3' ? 'menu-item-active' : ''}>
                                            Product Groups
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink to="/productuoms" className={props.id == '4.4' ? 'menu-item-active' : ''}>
                                            Product UOM's
                                        </NavLink>
                                    </MenuItem>
                                </>
                            }
                            {/* <MenuItem>
                            <NavLink to="/productgrades" className={props.id=='4.5'?'menu-item-active':''}>
                                Product Grades
                            </NavLink>
                        </MenuItem> */}
                            <MenuItem>
                                <NavLink to="/qualityparameters" className={props.id == '4.6' ? 'menu-item-active' : ''}>
                                    Quality Parameters
                                </NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to="/traceelements" className={props.id == '4.7' ? 'menu-item-active' : ''}>
                                    Trace Elements
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        : <></>
                    }
                    {localStorage.getItem('Trading_Menu') == 1 || localStorage.getItem('Trading_Menu') == 3 ?

                        <SubMenu title="Trading" icon={<SideBarIcon.TradeSalesPurchase />} defaultOpen={props.isOpenid == 5 ? true : false}>
                            {(localStorage.getItem('List_Trade_Enquiries') == 1 || localStorage.getItem('List_Trade_Enquiries') == 3) &&

                                <MenuItem className={props.id == '5.1' ? 'menu-item-active' : ''}>

                                    <NavLink to="/trading">
                                        Customer Requirement
                                    </NavLink>

                                    {/*<NavLink to="/products" >*/}
                                    {/*    All Products*/}
                                    {/*</NavLink>*/}
                                </MenuItem>
                            }
                            <MenuItem className={props.id == '5.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/trade-transactions">
                                    Trade Transactions
                                </NavLink>
                            </MenuItem>

                        </SubMenu>
                        : <></>
                    }
                    {(localStorage.getItem('Invoices_Menu') == 3 || localStorage.getItem('Invoices_Menu') == 1) &&
                        <SubMenu title="Invoices" icon={<SideBarIcon.Invoices />} defaultOpen={props.isOpenid == 6 ? true : false}>
                            <MenuItem className={props.id == '6.3' ? 'menu-item-active' : ''}>
                                <NavLink to="/generate-customer-po">
                                    Customer PO
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '6.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/generate-customer-proforma-invoice">
                                    Customer Proforma Invoice
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '6.1' ? 'menu-item-active' : ''}>
                                <NavLink to="/generate-customer-invoice">
                                    Customer Invoice
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '6.4' ? 'menu-item-active' : ''}>
                                <NavLink to="/debitorcredit">
                                    Debit/Credit
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                    }
                    {(localStorage.getItem('Downloads_Menu') == 3 || localStorage.getItem('Downloads_Menu') == 1) &&
                        <SubMenu title="Downloads" icon={<SideBarIcon.Downloads />} defaultOpen={props.isOpenid == 7 ? true : false}>

                            <MenuItem className={props.id == '7.1' ? 'menu-item-active' : ''}>
                                <NavLink to="/customer-proforma-invoice-list">
                                    Customer Proforma Invoices
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.2' ? 'menu-item-active' : ''}>
                                <NavLink to="/customer-invoice-list">
                                    Customer Invoices
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.3' ? 'menu-item-active' : ''}>
                                <NavLink to="/customer-po-list">
                                    Customer Purchase Orders
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.4' ? 'menu-item-active' : ''}>
                                <NavLink to="/supplier-purchase-order-list">
                                    Supplier Purchase Orders
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.5' ? 'menu-item-active' : ''}>
                                <NavLink to="/supplier-invoice-list">
                                    Supplier Invoices
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.6' ? 'menu-item-active' : ''}>
                                <NavLink to="/customer-contract-list">
                                    Customer Signed Contracts
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.7' ? 'menu-item-active' : ''}>
                                <NavLink to="/supplier-contract-list">
                                    Supplier Signed Contracts
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.8' ? 'menu-item-active' : ''}>
                                <NavLink to="/shipment-contract-list">
                                    Shipment Signed Contracts
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.9' ? 'menu-item-active' : ''}>
                                <NavLink to="/customercreditdebit">
                                    Customer Debit/Credit
                                </NavLink>
                            </MenuItem>
                            <MenuItem className={props.id == '7.10' ? 'menu-item-active' : ''}>
                                <NavLink to="/suppliercreditdebit">
                                    Supplier Debit/Credit
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                    }

                    {(localStorage.getItem('Reports_Menu') == 3 || localStorage.getItem('Reports_Menu') == 1) &&
                        <SubMenu title="Reports" icon={<SideBarIcon.Payment />} defaultOpen={props.isOpenid == 8 ? true : false}>

                            {localStorage.getItem('Customer_Reports') == 1 || localStorage.getItem('Customer_Reports') == 3 ?
                                <MenuItem className={props.id == '8.1' ? 'menu-item-active' : ''}>
                                    <NavLink to="/customer-report">
                                        Customer Report
                                    </NavLink>
                                </MenuItem>
                                : ''}

                            {localStorage.getItem('Trade_Reports') == 1 || localStorage.getItem('Trade_Reports') == 3 ?
                                <MenuItem className={props.id == '8.2' ? 'menu-item-active' : ''}>
                                    <NavLink to="/trade-report">
                                        Trade Report
                                    </NavLink>
                                </MenuItem>
                                : ''}


                            {localStorage.getItem('Shipment_Reports') == 1 || localStorage.getItem('Shipment_Reports') == 3 ?
                                <MenuItem className={props.id == '8.3' ? 'menu-item-active' : ''}>
                                    <NavLink to="/shipment-report">
                                        Shipment Report
                                    </NavLink>
                                </MenuItem>
                                : ''
                            }
                            {localStorage.getItem('PurchaseSales_Reports') == 1 || localStorage.getItem('PurchaseSales_Reports') == 3 ?
                                <MenuItem className={props.id == '8.4' ? 'menu-item-active' : ''}>
                                    <NavLink to="/purchase-sales-report">
                                        Purchase & Sales Report
                                    </NavLink>
                                </MenuItem>
                                : ''
                            }
                            {localStorage.getItem('Performance_Reports') == 1 || localStorage.getItem('Performance_Reports') == 3 ?
                                <MenuItem className={props.id == '8.5' ? 'menu-item-active' : ''}>
                                    <NavLink to="/performance-report">
                                        Performance Report
                                    </NavLink>
                                </MenuItem>
                                : ''
                            }
                        </SubMenu>
                    }

                    <MenuItem icon={<SideBarIcon.DashboardIcon />} className={props.id == 9 ? 'menu-item-active' : ''}>
                        <NavLink to="/faq">
                            FAQ
                        </NavLink>
                    </MenuItem>
                </Menu>
            </SidebarContent>
        </ProSidebar>
    )
}
