import React, {useState, useEffect} from 'react'
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { useFormik } from 'formik'
import * as CONFIG from "../../helpers/config"
import { BlueBorderButton, BlueButton } from '../buttoncomponents';
import { useMsal } from "@azure/msal-react";


function AddDivisionsModal(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [tradeEnquiry, setTradeEnquiry] = useState(true)
    const [checked, setChecked] = useState([])
    const [checkedName, setCheckedName] = useState([])
    const [deleteItem, setDeleteItem] = useState([])
    const [validation, setValidation] = useState([])
    const [count, setCount] = useState(0)
    const [paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [workFlowList, setWorkFlowList] = useState()
    const [error, setError] = useState(false)

    useEffect(()=>{
        if(props.edit == true){
            
            let checkedList = []
            let checkedNameList = []
            let validationList = []
            for(let i = 0; i < props.editData.divWorkflowDefinition.length; i++){
                checkedList.push(props.editData.divWorkflowDefinition[i].workflowDefinitionID)
                checkedNameList.push(props.editData.divWorkflowDefinition[i].workflowDefinition.description)
                validationList.push(props.editData.divWorkflowDefinition[i].workflowDefinition.messageCategory)
                
               
            }
            setValidation(validationList)
            setChecked(checkedList)
            setCheckedName(checkedNameList)
        }
    },[])
    useEffect(()=>{
        // console.log(props.editData.divWorkflowDefinition[3].workflowDefinitionID)
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_COMPANY_LIST, {
                    "PMSInstanceID": CONFIG.PMSInstanceID,
                    "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
                }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result[0], 'company')
                    setCompanyList(res.data.result)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
                axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_WORKFLOW_DEFINITIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result, 'workflows')
                    setWorkFlowList(res.data.result)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        
        // let final =[]
        // if(isFirst){
        //     axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_TMS_PAYMENT_TERMS, {
        //         "instanceID": CONFIG.PMSInstanceID,
        //         "created_user":CONFIG.LoginID,
        //     }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //     .then((res) => {
        //         setPaymentType(res.data.result)
        //         res.data.result.map((re,k)=>{
        //             let fin =[]
                    
        //             let fin1 = {
        //                 "customerPaymentTypeID": re.paymentTypeID,
        //                 "customerPaymentAmount" : 0,
        //                 "customerPaymentPer": 0
        //             }
        //             final.push(fin1)
        //         })
        //     })
        //     setFinal(final)
        // }else{
        //     setIsFirst(false)
        // }
    },[])

    const toggle = () => {
        props.getList()
        props.closeEdit()
        setModal(!modal)
        props.close();
      }

      const formik = useFormik({
        initialValues:
        {
            divisionName: props.edit == true && props.editData !=null ? props.editData.divisionName : '',
            companyID:'',
        }
    })
    const handleSaveEvent = () => {
        if(props.edit == true ){
            
            console.log(checked)
            console.log(checkedName)
            console.log(deleteItem)
            let nameArray = []
            let objectConvert = []
            //let final = {'customerPaymentTypeID':''}

            checked.map((list, k)=>{
                if(deleteItem != null && deleteItem.length > 0){
                    for(let i = 0; i < deleteItem.length; i++){
                        if(deleteItem[i] == list){
                            console.log(deleteItem[i], 'delete index')
                            objectConvert[k] = {'workflowDefinitionID':list,
                            "Is_Deleted":true,
                            "Is_Active":false,
                            'DivisionID': props.editData.divisionID,
                            'DivWorkflowDefinitionID':props.editData.divWorkflowDefinition[k].divWorkflowDefinitionID             
                            }   
                            break;   
                        }
                        else{
                            console.log(i)
                            if(k < props.editData.divWorkflowDefinition.length){
                                objectConvert[k] = {'workflowDefinitionID':list,
                                'DivWorkflowDefinitionID':props.editData.divWorkflowDefinition[k].divWorkflowDefinitionID 
                                } 
                            }
                            else{
                                objectConvert[k] = {'workflowDefinitionID':list}
                            }
                        }
                    }
                }
                else{
                    if(k < props.editData.divWorkflowDefinition.length){
                        objectConvert[k] = {'workflowDefinitionID':list,
                        'DivWorkflowDefinitionID':props.editData.divWorkflowDefinition[k].divWorkflowDefinitionID 
                        } 
                    }
                    else{
                        objectConvert[k] = {'workflowDefinitionID':list}
                    }
                }
            })
            checkedName.map((list, k)=>{
                nameArray[k] = {'divisionName':list}
    
            })
            formik.values.companyID = companyList[0].company.companyID
            console.log(objectConvert)
            console.log(nameArray)
            console.log(formik.values)
            let final_json = {
                "companyID": formik.values.companyID,
                "divisionName": formik.values.divisionName,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "divWorkflowDefinition":objectConvert,
                "divisionID":props.editData.divisionID
    
            }
            console.log(final_json)
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_DIVISION, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'workflows')
                setWorkFlowList(res.data.result)
                props.getList()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  

        }
        else{
            console.log(checked)
            console.log(checkedName)
            let nameArray = []
            let objectConvert = []
            //let final = {'customerPaymentTypeID':''}
            checked.map((list, k)=>{
                objectConvert[k] = {'workflowDefinitionID':list}
    
            })
            checkedName.map((list, k)=>{
                nameArray[k] = {'divisionName':list}
    
            })
            formik.values.companyID = companyList[0].company.companyID
            console.log(objectConvert)
            console.log(nameArray)
            console.log(formik.values)
            let final_json = {
                "companyID": formik.values.companyID,
                "divisionName": formik.values.divisionName,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "divWorkflowDefinition":objectConvert
    
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_DIVISION, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'workflows')
                setWorkFlowList(res.data.result)
                props.getList()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    
        }
        //props.handlePaymentMethodList(objectConvert, nameArray)
        props.getList()
        toggle()

    }
  const handleCheck = (event, data) => {
        console.log(event.target.value, event.target.name)
        console.log(checked, checkedName)
        var updatedList = [...checked];
        var updatedNameList = [...checkedName]
        var deleteItemList = [...deleteItem]
        var validatedList = [...validation]

        if(props.edit == true){
            if (event.target.checked) {
                updatedList = [...checked, parseInt(event.target.value)];
                updatedNameList = [...checkedName, event.target.name]
              } 
            else {
                deleteItemList = [...deleteItem, parseInt(event.target.value)]
              }
        }
        else{
            if (event.target.checked) {
                updatedList = [...checked, parseInt(event.target.value)];
                updatedNameList = [...checkedName, event.target.name]
                validatedList = [...validation,data.messageCategory]
              } else {
                updatedList.splice(checked.indexOf(event.target.value), 1);
                updatedNameList.splice(checkedName.indexOf(event.target.id), 1);
                validatedList.splice(validation.indexOf(data.messageCategory), 1);
              }
        }
        console.log(updatedList.length)
        console.log(updatedNameList.length)
        console.log(validatedList)
        // if(updatedList.length > 1){
        //     setShowInputBox(true)
        // }
        // else{
        //     setShowInputBox(false)
        // }
        // //setCount(updatedList.length)
        getDeleteItemsList(deleteItemList)
        getChecked(updatedList);
        getCheckedName(updatedNameList)
        getValidationList(validatedList)
    };

    const getChecked = (data) => {
        setChecked(data)
    }

    const getCheckedName = (data) => {
        setCheckedName(data)
    }

    const getDeleteItemsList = (data) => {
        setDeleteItem(data)
    }
    const getValidationList = (data) => {
        setValidation(data)
    }
    const test = (values, index) => {
        console.log(values, index)
    }
    const handleDefaultChecker = (data) => {
        console.log(data,'running')
        if(props.edit == true){
            for(let i = 0; i < props.editData.divWorkflowDefinition.length; i++){
                if(props.editData.divWorkflowDefinition[i].workflowDefinitionID == data.workflowDefinitionID){
                    return true
                }
            }
        }
       
    }
    const handleLabel = (data) => {
        return (
            <div className = ''>
                <p>{data.description}</p>
                <p className ='font-weight-400 font-12'>Message Category: {data.messageCategory}</p>
            </div>
        )
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className = 'payment-method-modal'>
        <ModalHeader toggle={toggle} className='p-40'>
            <div className = 'd-flex justify-content-between '>
                <div className='font-16 font-weight-600 color-707895'>
                    CREATE DIVISION
                </div>
            </div>
        </ModalHeader>
        <ModalBody  className = 'service-request-modal-wrapper p-40'>
            <>
            <div className =''>
            <>
            <div className ='bg-light my-2 p-4 align-items-center br-8'>
                {companyList != null && companyList.length > 0 ? 
                    <>
                    <div className = 'd-flex align-items-center justify-content-between mt-2'>
                        <div className = 'd-flex flex-column'>
                            <div className = 'font-weight-400 color-697382'>Company Name</div>
                            <div className = 'font-weight-600 color-243448'>{companyList[0].company.companyName}</div>
                        </div>
                    </div>
                    <div className = 'd-flex flex-column mt-2'>
                            <div className = 'font-weight-400 color-697382'>Address</div>
                            <div className = 'font-weight-600 color-243448'>{companyList[0].company.companyUnit}, &nbsp; {companyList[0].company.companyBuilding}, &nbsp; 
                            {companyList[0].company.companyStreet}, &nbsp; {companyList[0].company.companyPoBox}, &nbsp; {companyList[0].statename}, &nbsp; 
                            {companyList[0].countryname} &nbsp;
                            </div>
                    </div>
                    </>
                    : 
                    ''
                    }
            </div>
            <div className =' my-2 p-4 align-items-center br-8'>
                <div>
                <FloatingLabel controlId="floatingInput" label='Division Name'>
                    <Form.Control
                        className="form_input form_input_color_change" 
                        type="text"
                        name='divisionName'
                        placeholder="Division Name"
                        onChange = {formik.handleChange}
                        value = {formik.values.divisionName}
                    />
                </FloatingLabel>
                </div>                
            </div>
        
            {workFlowList != null && workFlowList.length > 0 ? 
            <div style={{overflowY:'scroll', maxHeight: '400px'}}>
                    {workFlowList.map((list,k) => (
                        <div className ='bg-light my-2 p-4 align-items-center br-8'>
                        <div>
                            <Form.Check
                                className = ''
                                type='checkbox'
                                defaultChecked={handleDefaultChecker(list)}
                                name={list.description}
                                label={handleLabel(list)}
                                id = {list.description}
                                data = {list.description}
                                onChange={e => handleCheck(e, list)}
                                value={list.workflowDefinitionID}
                            />
                        </div> 
                </div>
                    ))}
                </div>
                :
                ''
                }
            </>
            </div>
            </>
        </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
            {error == true ? 
            <div className = '' style={{color:'red'}}>
                <p className = 'mb-0'>* Two same Message Categories cannot be selected for Work Flow Definations</p>
                <p className ='m-0'>Please select another Work Flow Defination</p>
            </div>
            :
            ''
            }
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>
            </ModalFooter>
        </Modal>
    )
}

export default AddDivisionsModal