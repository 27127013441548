import React from 'react'
import { useEffect } from 'react'
import countrylist from  "./countrylist.txt"
import axios from "axios"
import * as CONFIG from "../helpers/config"

export default function Testcountry() {

    useEffect(()=>{
        fetch(countrylist)
        .then(r => r.text())
        .then(text => {
            let txt_arr = text.split('\n')
            txt_arr.map((ar,k)=>{
                let json_list ={
                    "pmsInstanceId": 56522122,
                    "countryName": ar,
                    "created_User": 1
                  }
                if(k == 0){
                    axios.post('http://52.139.243.133/api/Instance/AddCountry', json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                    
                    })
                }

            })
        });
    },[])

  return (
    <div>
        
    </div>
  )
}
