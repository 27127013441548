
import React, { useEffect, useState } from 'react'
import {Row, Col} from "react-bootstrap";
import ListData from './ListData';
import { AddNewButton } from '../../../../../../components/buttoncomponents';
import { min } from 'date-fns';

function List(props) {
    console.log(props.data, 'list')
  const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
  const [productList, setProductList] = useState([])
  const [diff, setdfiff] = useState(0)
  useEffect(()=>{
      console.log(props, 'inside list contract')
      setIsPrimarySelected(false)
      props.data.map((d)=>{
          if(d.is_Default){
              setIsPrimarySelected(true)
              console.log(is_DefaultSelected,'inside list contract')
          }
      })
      if(props.data[0].tradeEnquiryProductSupplyplan != null){
        console.log('inside')
            if(props.data[0].tradeEnquiryProductSupplyplan.length > 1){
                let val1 = props.data[0].tradeEnquiryProductSupplyplan[0].month
                let val2 = props.data[0].tradeEnquiryProductSupplyplan[1].month
                let diff = Math.max(val1,val2) - Math.min(val1,val2)
                console.log(diff, 'difference')
                setdfiff(diff)
            }
      }
      setProductList(props.data)
  },[])
  const saveToList = (list)=>{
    let pr_list =[...productList]
    productList.map((li,k)=>{
        if(li.productID == list.productID){
            pr_list[k] = list
        }
    })
    setProductList(pr_list)
    props.saveList(pr_list)
  }
  return (
      <div>
        
        <div className='bg-F8F9FA  d-flex justify-content-between table_heading align-items-center px-4'>
            <Col md={2}>Product Group</Col>
            <Col md={2}>Product Code</Col>
            <Col md={2}>Product Name</Col>
            <Col md={1}>Product UOM</Col>
            <Col className='text-center' md={2}>No. of Supply Plan</Col>
            <Col md={1}>Supply Intervals</Col>
            <Col md={2}></Col>
        </div>
          {props.data.map((values)=>{
                if(values.key != false){
                    return(
                        <ListData contractterms = {props.contractterms} handleDisableEnd = {props.handleDisableEnd} handleIsFirst = {props.handleIsFirst} handleDisable = {props.handleDisable} updateProductDetails={props.updateProductDetails} handleUpdateProductDetails={props.handleUpdateProductDetails} values={values} saveToList={saveToList} isEdit={props.isEdit} diff={diff}/>
                    )
                }
          })}
      </div>
  )
}

export default List