import React,{useEffect, useState} from 'react'
import { Dropdown, Menu, Space } from 'antd';
import {useFormik } from 'formik'
import {  Col, Card, Form } from "react-bootstrap"
import * as CONFIG from "../../../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";

export default function CustomerList(props) {
    const { instance } = useMsal();
    console.log(props, props.selectedlist, 'props on check')
    const [modal, setModal] = useState(true);
    const [checked, setChecked] = useState(false);
    const [checkedID, setCheckedID] = useState()
    const [data, setData] = useState([])
    const [list, setList] = useState([])
    
    const formik = useFormik({
        initialValues: {
            customerData : []
        },
      })
    
    const trackCheck = (e) => {
        console.log(props)
        let id = parseInt(e.target.id);
        //props.selectedCustomer(props.data)
        // const json_list = {
        //         "instanceID" : CONFIG.PMSInstanceID,
        //         "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
        //         "created_User" : CONFIG.LoginID,
        //         "id": props.data.custid,
        //     }
        //     axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //     .then((res) => {
        //         console.log(res.data.result)
        //         setList(res.data.result);
        //     }).catch((error)=>{
        //         if(error.response.status == 401){
        //             localStorage.clear();
        //             instance.logoutRedirect({
        //               postLogoutRedirectUri: "/",
        //             });
        //         }
        //     })
        if(e.target.checked){
            props.addtolist(id)
        }  
        else{
            props.removefromlist(id)
        }
    }
    useEffect(()=>{
        props.sendBack(list)
    },[list])
    return (
        <tr className='border-bottom-1 '>
            <td className='py-4 d-flex px-0 '><div className = 'idStatus'> {props.data.customercode}</div></td>
            <td className='px-3 font-weight-500'>{props.data.customername}</td>
            <td className='px-3 w-25 '>{props.data.address}</td>
            <td className='px-3 font-weight-500'>{props.data.category}</td>
            <td className='px-3 font-weight-500'>{props.data.incoterms}</td>
            <td className=''>
            <Form.Check 
                    type='checkbox'
                    name='customerData'
                    id={props.data.custid}
                    checked={props.selectedlist.filter(x=> x == props.data.custid).length>0}
                    onChange = {trackCheck}
                    className='mx-3 slectCustomer'
                />
            </td>
        </tr>
    )
}
