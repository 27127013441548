import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { FormikProvider, useFormik } from 'formik'
//import * as CONFIG from "../../helpers/config"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents';
import * as CONFIG from "../../helpers/config";
import { useMsal } from "@azure/msal-react";

export default function CompanyModal(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(true)
    const [companyList, setCompanyList] = useState([])
    const [doc, setDoc] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'Company')
                setCompanyList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const toggle = () => {
        setModal(!modal)
        props.toggle()
    }

    const formik = useFormik({
        initialValues: {
            company: []
        }
    })

    const handleSaveEvent = ()=>{
        if(Object.keys(formik.values.company).length>0){
            console.log('oinside ')
            props.saveCompany(formik.values.company)
            setError(false)
            props.toggle()
        }
        else{
            setError(true)
        }
    }
    const handleSelect = (e)=>{
        if(e.target.checked){
            let filter = companyList.filter(x=> x.company.companyID == parseInt(e.target.id))[0]
            formik.values.company = filter
            setDoc(!doc)
            setError(false)
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modal'>
            <ModalHeader toggle={toggle} className='p-40'>
                <div className='d-flex justify-content-between '>
                    <div className='font-16 font-weight-600 color-707895'>
                        SELECT A COMPANY
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-40'>
                {companyList.map((clist, k) => {
                    return (
                        <div className='bg-light my-2 p-4 align-items-center br-8'>
                            <Form.Check
                                name='companySelect'
                                type='radio'
                                id={clist.company.companyID}
                                label={clist.company.companyName}
                                onChange={handleSelect}
                            />
                        </div>
                    )
                })}
                {error &&
                    <span className='input_error'>Please select a company!</span>
                }
            </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>

                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} /></span>
            </ModalFooter>
        </Modal>
    )
}
