import React, {useState, useEffect} from 'react'
import { Dropdown, Menu, Space } from 'antd';
import axios from 'axios';
import AddQualityParameterModal from './modals/AddQualityParameterModal';
import AddTraceElemetsModel from './modals/AddTraceElemetsModel';
import * as CONFIG from '../../../../../../helpers/config'
import { useMsal } from "@azure/msal-react";

export default function ListData(props) {
    console.log(props, 'values')
    const { instance } = useMsal();
    const [qualityParameterModel, setQualityParameterModel] = useState(false)
    const [traceElementsModel, setTraceElementsModel] = useState(false)
    const [quality, setquality] = useState([])
    const [isQuality, setIsQuality] = useState(false)
    const [trace, setTrace] = useState([]);
    const [isTrace, setIsTrace ] = useState(false)
    const [final_list, setFinal] = useState([])
    const [isQualityOrTraceSet, setIsQualityOrTraceSet] = useState(false)
    const [data, setData] = useState()

    useEffect(()=>{
        let qualityparameter = props.values.tradeEnquiryProductQualityParameters
        let traceelements = props.values.tradeEnquiryProductTraceElement
        setquality(qualityparameter)
        setTrace(traceelements)
        setIsTrace(true)
        setIsQuality(true)
        if(props.isEdit == true){
            console.log(props)
            console.log(props.values.productGroupID)
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
               
            }
            let productGroup = ''
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    let cookie_data =  res.data.result.filter(x=> x.productgroup.productGroupID == props.values.productGroupID)
                    console.log(cookie_data)
                    setData(cookie_data[0])
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
    },[])

    let final_json =props.isEdit?props.values   
    :
    {
        "key" : parseInt(props.values.key),
        "productID": parseInt(props.values.productID),
        "productGradeID": 1,
        "productGroupID" : parseInt(props.values.productGroupID),
        "productQuantity": parseInt(props.values.productQuantity),
        "productUOMID": parseInt(props.values.productUOMID),
        "productUOMValue": props.values.productUOMValue,
        "productSellingPrice": parseFloat(props.values.productSellingPrice),
        "productGroupValue": props.values.productGroupValue
    }
    const handleQualityParameterModel = () => {
        setQualityParameterModel(!qualityParameterModel)
    }

    const handleTraceElementsModel = () => {
        setTraceElementsModel(!traceElementsModel)
    }
    const saveQuality = (data)=>{
        console.log(data)
        console.log(data.tradeEnquiryProductQualityParameters)
        if(data.tradeEnquiryProductQualityParameters.length>0){
            console.log('inside')
            setIsQuality(true)
            if(isQualityOrTraceSet){
                console.log(final_json)
                final_json = {...final_list,...data}
            }
            else{
                console.log(final_json)
                final_json = {...final_json,...data}
            }
            console.log(final_json)
            setFinal(final_json)
            setquality(data.tradeEnquiryProductQualityParameters);
            setIsQualityOrTraceSet(true)
            console.log(quality)
            // if(isQualityOrTraceSet){
            //     if(final_list.tradeEnquiryProductQualityParameters.length > 0 && final_list.tradeEnquiryProductTraceElement.length > 0){
            //         props.addTraceAndQuality(final_list)
            //     }
            // }
            console.log(final_json)
            props.addTraceAndQuality(final_json)
        }
        handleQualityParameterModel()
    }

    const saveTrace = (data)=>{
        console.log(data.tradeEnquiryProductTraceElement)
        if(data.tradeEnquiryProductTraceElement.length>0){
            console.log('inside')
            setIsTrace(true)
            if(isQualityOrTraceSet){
                final_json = {...final_list,...data}
            }
            else{
                final_json = {...final_json,...data}
            }
            console.log(final_json)
            setFinal(final_json)
            setTrace(data.tradeEnquiryProductTraceElement);
            setIsQualityOrTraceSet(true)
            console.log(trace)
            // if(isQualityOrTraceSet){
            //     if(final_list.tradeEnquiryProductQualityParameters.length > 0 && final_list.tradeEnquiryProductTraceElement.length > 0){
            //         props.addTraceAndQuality(final_list)
            //     }
            // }
            props.addTraceAndQuality(final_json)
        }
        handleTraceElementsModel()
    }
    const menu = (
        <Menu
          items={[
            // {
            //     key: '1',
            //     label: (
            //         <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.edit}>Edit</span>
            //     ),
            // },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' onClick={handleQualityParameterModel}>Add Quality Parameter</span>
                ),
            },
            {
                key: '3',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' onClick={handleTraceElementsModel}>Add Trace Elements</span>
                ),
            },
            // {
            //     key: '4',
            //     label: (
            //         <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.remove}>Remove</span>
            //     ),
            // }
          ]}
        />
      );

      const handleProductGroup = () => {
        if(props.isEdit == true){
            console.log(props)
            console.log(props.values.productGroupID)
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
               
            }
            let productGroup = ''
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    let cookie_data =  res.data.result.filter(x=> x.productGroupID == props.values.productGroupID)
                    console.log(cookie_data)
                    setData(cookie_data[0])
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
        else{
            return(
                props.values.productGroupValue
            )
        }
      }
    return (
        <>
         <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
            <td className='titilePrimary'>{props.isEdit == true && data != null ? data.productgroup.productGroupName : props.values.productGroupValue}</td>
            <td className='titilePrimary'>{props.values.productCode}</td>
            <td className='titilePrimary'>{props.values.productName}</td>
            <td className='titilePrimary'>{props.values.productUOMName != null ? props.values.productUOMName  : props.values.productUOMValue }</td>
            <td className='text-right'>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>

                <img src = '/assets/images/Dot_icon.svg' />

                </Space>
                </a>
            </Dropdown>
            </td>
        </tr>
        {qualityParameterModel == true 
        ?
        <AddQualityParameterModal 
            value={props.values} 
            close={handleQualityParameterModel} 
            saveQuality={saveQuality}
            isQuality ={isQuality}
            qualityData = {quality}
            isEdit={props.isEdit}
        />
        
        :
        
        ""
        }
         {traceElementsModel == true 
        ?
        <AddTraceElemetsModel 
            value={props.values} 
            close={handleTraceElementsModel} 
            saveTrace={saveTrace}
            isTrace={isTrace}
            traceData ={trace}
            isEdit={props.isEdit}
        />
        :
        ""
        }
        </>
    )
}
