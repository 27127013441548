import React, { useEffect } from 'react'
import { useState } from 'react'
import { AddNewButton, BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import Add_contact from './add_contact';
import { Card, Row, Col } from "react-bootstrap"
import C_list from './c_list';
import * as CONFIG from "../../helpers/config";
import { useMsal } from "@azure/msal-react";
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function ContactList(props) {
    console.log(props)
    const { instance } = useMsal();
    const [isAdd, setIsAdd] = useState(false)
    const [data, setData] = useState([])
    const [datatoedit, setDatatoedit] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [key, setKey] = useState(props.isEdit == true ? props.keydata : 0)
    const [isHistoryPresent, setIsHistoryPresent] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        setData(props.cData)
    }, [props])
    const openAddContact = () => {
        setIsAdd(!isAdd)
    }

    const saveContact = (d) => {
        console.log(key)
        let k = key
        if (data.length == 0) {
            let da = []
            d.key = k
            da.push(d)
            setData(da)
        }
        else {
            d.key = k
            let datas = [...data]
            datas.push(d)
            setData(datas)
        }
        k++
        setKey(k)
        openAddContact()
        console.log(data)
    }
    useEffect(() => {
        console.log(data)
    }, [data])

    const editContact = (da) => {
        if (da.is_NewEmployment == true) {
            let filter = data.filter(x => x.financialInstituteContactID == da.financialInstituteContactID)
            setDatatoedit(filter)
            setIsHistoryPresent(true)
            setIsEdit(true)
            openAddContact()
        } else {
            setDatatoedit(da)
            openAddContact()
            setIsEdit(true)
            setIsHistoryPresent(false)
        }
    }
    const updateContact = (da) => {
        console.log(da)
        let remaining = []
        if (data.length == 0) {
            remaining = [da]
        }
        else {
            console.log(data)
            remaining = [...data]
            if (da.length > 1) {
                console.log('inside update for history')
                let index = remaining.findIndex(x => x.key == da[0].key)
                console.log(index)
                remaining[index] = da[1]
                let filter = remaining.filter(x => x.financialInstituteContactID == da[1].financialInstituteContactID)
                if (filter.length > 1) {
                    let ind_new = remaining.findIndex(x => x.financialInstituteContactID == da[1].financialInstituteContactID && x.is_NewEmployment == true)
                    remaining[ind_new] = da[0]
                }
                else {
                    remaining.push(da[0])
                }
                setIsNew(true)
            }
            else {
                let index = remaining.findIndex(x => x.key == da.key)
                remaining[index] = da
                setIsNew(false)
            }
            setData(remaining)
        }
        props.contactData(remaining)
        openAddContact()
        console.log(remaining)
    }
    const back = () => {
        props.contactData(data)
        props.changeindex(0)
    }
    const addContact = () => {
        console.log('inside')
        props.insituteData.bankCountryID = parseInt(props.insituteData.bankCountryID)
        props.insituteData.countryID = parseInt(props.insituteData.countryID)
        props.insituteData.bank = null
        if (props.isEdit == true) {
            console.log('inside edit foer add acontact  ')
            if (isNew) {
                data.map((da, k) => {
                    da.contactType = null
                })
            }
            else {
                data.map((da, k) => {
                    da.contactType = null
                    da.financialInstituteID = parseInt(props.insituteData.financialInstituteID)
                    props.insituteData.bank = null
                })
            }
        }
        else {
            data.map((da, k) => {
                da.contactType = null
            })
        }
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            ...props.insituteData,
            "financialInstituteContact": data
        }
        if (props.isEdit == true) {
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_FINANCIAL_INSTITUTE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Financial Institution Updated Successfully!',
                            html: '',
                            type: 'success',
                            confirmButtonText: 'Back',
                            confirmButtonClass: "btn btn-primary",

                        }).then(() => {
                            props.back()

                        });
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {

            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_FINANCIAL_INSTITUTE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Financial Institution Added Successfully!',
                            html: '',
                            type: 'success',
                            confirmButtonText: 'Back',
                            confirmButtonClass: "btn btn-primary",

                        }).then(() => {
                            props.back()

                        });
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    const saveAchievement = (da) => {
        console.log(da)
        let datas = [...data]
        let index = datas.filter(x => x.key != da.key).findIndex()
        datas[index] = da
        setData(datas)
    }
    return (
        <div>
            <div className='d-flex justify-content-between px-4'>
                <div className='form-title font-16 font-weight-500 mt-1-rem '>Add Contact Information</div>
                {isAdd ?
                    <></>
                    :

                    <AddNewButton value='Add New Contact' class='bg-white' clickEvent={() => {
                        setDatatoedit([])
                        openAddContact()
                    }} />
                }
            </div>
            <div>
                {isAdd ?
                    <Add_contact isHistoryPresent={isHistoryPresent} keydata={key} back={() => {
                        setDatatoedit([])
                        openAddContact()
                    }} saveContact={saveContact} datatoedit={datatoedit} isEdit={isEdit} updateContact={updateContact} />
                    :
                    <Card className='mt-2-rem' style={{ height: '65vh', background: 'transparent' }} >
                        <Card.Body className="scroll-cont" style={{ background: '#fff' }}>

                            <table className="table tCustom table-text">
                                <thead>
                                    <tr className='bg-F8F9FA font-weight-500 color-707895 cont-top'>
                                        <th>Contact Name</th>
                                        <th>Designation</th>
                                        <th>Contact</th>
                                        <th>Contact Type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data != null && data.length > 0) &&
                                        data.map((da, k) => {
                                            if (da.is_History != true) {
                                                if(props.isContact == true){
                                                    if (da.financialInstituteContactID == props.financecontactID) {
                                                        return (
                                                            <C_list data={da} isNew={isNew} key={k} insituteData={props.insituteData} editContact={editContact} isEdit={props.isEdit} saveAchievement={saveAchievement} isContact={props.isContact} financecontactID={props.financecontactID} />
                                                        )
                                                    }
                                                }
                                                else{
                                                    return (
                                                        <C_list data={da} isNew={isNew} key={k} insituteData={props.insituteData} editContact={editContact} isEdit={props.isEdit} saveAchievement={saveAchievement} isContact={props.isContact} financecontactID={props.financecontactID} />
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </Card.Body>
                        <Card.Footer className='form-card-footer pt-4 d-flex'>
                            {props.isContact != true &&
                                <span><BlueBorderButton value='Back' clickEvent={back} /></span>
                            }
                            <span className='pl-10 '><BlueButton className='font-weight-500' value='Submit' clickEvent={addContact} /></span>
                        </Card.Footer>
                    </Card>
                }
            </div>
        </div>
    )
}
