import React, { useEffect, useRef, useState } from 'react';
 import { Editor } from '@tinymce/tinymce-react';

export default function Tiny_editor(props) {
    console.log(props)
    const [isFirst, setIsFirst] = useState(true)
    
    const [readStatus, setReadStatus] = useState(props.isDraft?0:1)
    const editorRef = useRef(null);
    
    const [editorContent, setEditorContent] = useState(props.data.contractContent)
    useEffect(()=>{
        if(isFirst){
            props.saveSections(props.data.contractContent, props)
        }
        setIsFirst(false)
    },[])
    const setcontent = (data)=>{
        setEditorContent(data)
    }

    const sendBack = ()=>{
        if(props.isDraft){
            props.saveSections(editorContent, props)
        }
    }

    return (
        <>
             <Editor
                apiKey='r6voj3mdtzcx7tt0k59ilpsw5vx41vknh8vm68wpknstru3w'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={props.data.contractContent}
                disabled={!props.isDraft || props.data.is_Lock}
                onEditorChange={(newText) => setcontent(newText)}
                onBlur = {sendBack}
                init={{
                height: 650,
                readonly: 1,
                menubar: false,
                elementpath: false,
                branding: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            
        </>
    )
}
