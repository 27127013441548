import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import * as CONFIG from '../../helpers/config'
import axios from 'axios';
import { Row, Col, Button, Form } from "react-bootstrap"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import Moment from "moment"
import { useFormik } from 'formik'
import NumberFormat from 'react-number-format';
import { useMsal } from "@azure/msal-react";

export default function CostSheetModal(props) {
    const { instance } = useMsal();
    const tradedata = props.tradedata
    console.log(tradedata)
    const [modal, setModal] = useState(props.modal)
    const [costsheetdata, setcostsheetdata] = useState([])
    const [iscostsheetavailable, setisavailable] = useState(false)
    const [buyingprice, setbuyingprice] = useState(0); // replace 1 by props.id
    const [sellingPrice, setSellingPrice] = useState(0); // replace 1 by props.id
    const [margin, setmargin] = useState(0)
    const [marginper, setmarginper] = useState(0)
    const [total, setTotal] = useState(0)
    const [isChecked, setIsChecked] = useState(false)
    const [customerData, setCustomerData] = useState([])

    const formik = useFormik({
        initialValues:
        {
            BuyingPrice: "",
            OceanFreight: "",
            FinancePrice: "",
            InsuranceCostCargo: "",
            InsuranceCostCL: "",
            InsuranceCostOthers: "",
            total: "",
            margin: "",
            marginper: ""

        },
        validate: values => {
            let errors = {};

            const val = /^[0-9]+$/;

            //sendBack();
            return errors;
        }
    })
    useEffect(() => {
        let json_lists = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.tradeID) // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_lists, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerData(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        let total1 = 0, total2 = 0
        tradedata.map((list, k) => {
            list.tradedetails.tradeProductDetail.map((pro, p) => {
                total1 = total1 + (pro.productBuyingPrice * pro.quantity)
                total2 = total2 + (pro.productSellingPrice * pro.quantity)
            })
        })
        formik.values.BuyingPrice = total1;
        const sellingPrice = total2
        setSellingPrice(total2)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.tradeID), // tradeid,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setcostsheetdata(res.data.result[0])
                if (Object.keys(res.data.result[0]).length > 0) {
                    formik.values.FinancePrice = res.data.result[0].financeCost
                    formik.values.InsuranceCostCargo = res.data.result[0].insuranceCargoCost
                    formik.values.InsuranceCostCL = res.data.result[0].insuranceCLCost
                    formik.values.InsuranceCostOthers = res.data.result[0].insuranceOthersCost
                    let total = 0
                    if (tradedata.length > 0) {
                        let freight = tradedata[0].tradedetails.oceanFreight != null ? tradedata[0].tradedetails.oceanFreight : 0
                        total = parseFloat(formik.values.BuyingPrice) + parseFloat(freight) + parseFloat(formik.values.FinancePrice) + parseFloat(formik.values.InsuranceCostCargo) + parseFloat(formik.values.InsuranceCostCL) + parseFloat(formik.values.InsuranceCostOthers) + parseFloat(tradedata[0].tradedetails.dispatch) + parseFloat(tradedata[0].tradedetails.demurrage);
                    }
                    console.log(total)
                    if (sellingPrice > 0) {
                        var margin = sellingPrice - total
                        var marginper = (margin / sellingPrice) * 100;
                    }
                    else {
                        margin = total
                        marginper = 100
                    }
                    formik.values.total = total
                    formik.values.margin = margin
                    formik.values.marginper = marginper
                    console.log(formik.values.total)
                    setTotal(total)
                    setmargin(margin);
                    setmarginper(marginper);
                    setisavailable(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    const toggle = () => {
        setModal(!modal)
        props.toggle()
    }
    const togglechecked = () => {
        setIsChecked(!isChecked)
    }
    const approve = () => {
        toggle()
        props.approve()
    }
    const reject = () => {
        toggle()
        props.reject()
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract h-100vh" style={{ background: '#fff' }}>
            <ModalBody toggle={toggle}>
                <div className='service-request-detail-title mx-4 mt-4'>
                    <div className='d-flex justify-content-between'>
                        <div style={{ fontSize: '18px', fontWeight: '600' }}>
                            <>CostSheet</>
                        </div>
                    </div>
                </div>
                <div id='download_section' className='mx-4'>
                    <div className='d-flex justify-content-between mt-4 mx-3 br-8 p-3-rem product_data_quality'>
                        {tradedata.map((list, k) => (
                            <>
                                <div className='d-flex flex-column'>
                                    <div className='font-weight-600 '>{list.enquiryNo}</div>
                                    <div className='font-weight-400 LabelText'>{Moment(list.tradeDate).format("DD/MM/yyyy")} </div>
                                </div>
                                {customerData.map((data, k) => {
                                    return (
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                            <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                        </div>
                                    )
                                })}
                                <div className='d-flex flex-column'>
                                    <div className='font-weight-600 '>{list.tradedetails.supplier.supplierCode}</div>
                                    <div className='font-weight-400 LabelText'>{list.tradedetails.supplier.supplierName}</div>
                                    {list.tradedetails.tradeSupplierPaymentDetail.map((pay, j) => (
                                        <div className='font-weight-500 table-ttile-color-sub font-12'>
                                            {pay.paymentType.typeName}
                                        </div>
                                    ))}
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='font-weight-400 LabelText'>{list.tradedetails.tradeEnquiry.contractType.contractTypeName} <span className='font-weight-600'>{list.tradedetails.incoterms.incotermsName} {list.tradedetails.modeOfTransport.modeOfTransportName} </span> </div>
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='font-weight-400 LabelText '>Laycan</div>
                                    <div className='font-weight-600 '>{Moment(list.tradedetails.fromLaycanPeriod).format("DD/MM/yyyy")} - {Moment(list.tradedetails.toLaycanPeriod).format("DD/MM/yyyy")} </div>
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='font-weight-400 LabelText '>Lord Port</div>
                                    <div className='font-weight-600 '>{list.tradedetails.loadPort} {list.tradedetails.loadPortCountry}</div>
                                </div>
                            </>

                        ))
                        }
                    </div>

                    <div className='table-responsive-sm card-body'>
                        <table className='table tCustom table-text mt-1-rem  mt-4'>
                            <thead>
                                <tr>
                                    <th> <div className='font-weight-600 ps-5'>SL No </div></th>
                                    <th><div className='font-weight-600'>Description</div></th>
                                    <th><div className='font-weight-600'>Estimated Amount</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>1</div></td>
                                    <td>Buying Price (FOB)</td>
                                    <td>
                                        <NumberFormat value={formik.values.BuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>2</div></td>
                                    <td>Ocean Freight</td>
                                    <td>
                                        <NumberFormat value={tradedata[0].tradedetails.oceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>3</div></td>
                                    <td>Finance Cost</td>
                                    <td>
                                        <NumberFormat value={formik.values.FinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>4</div></td>
                                    <td>Insurance Cost(Cargo)</td>
                                    <td>
                                        <NumberFormat value={formik.values.InsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>5</div></td>
                                    <td>Insurance Cost(CL)</td>
                                    <td>
                                        <NumberFormat value={formik.values.InsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>6</div></td>
                                    <td>Insurance Cost(Others)</td>
                                    <td>
                                        <NumberFormat value={formik.values.InsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>7</div></td>
                                    <td>Despatch</td>
                                    <td>
                                        <NumberFormat value={tradedata[0].tradedetails.dispatch} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>8</div></td>
                                    <td>Demmurage</td>
                                    <td>
                                        <NumberFormat value={tradedata[0].tradedetails.demurrage} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div className='d-flex'>Estimated Total Amount :
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={formik.values.total} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>Selling Price :
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            Margin : &nbsp;
                                            <NumberFormat value={formik.values.margin} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        </div>
                                        <div className='font-weight-600 color-243448'>Margin % (Margin/Selling Price*100) : {parseFloat(formik.values.marginper).toFixed(2)}%</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                {localStorage.getItem('LogInUserRoleID') != 3001 &&
                    <>
                        <>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_DischargePortETA'
                                label='I have reviewed this document'
                                onChange={togglechecked}
                            />
                        </>
                        <>
                            <Button variant="primary" size="sm" onClick={approve} className={'font-weight-500 font-12 rounded-13 '} disabled={!isChecked}>
                                Approve
                            </Button>
                        </>
                        <>
                            <Button variant="danger" size="sm" onClick={reject} className={'font-weight-500 font-12 rounded-13 ms-2'} disabled={!isChecked}>
                                Reject
                            </Button>
                        </>
                    </>

                }

            </ModalFooter>
        </Modal>
    )
}
