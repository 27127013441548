import React, { useEffect,useState  } from 'react'
import axios from 'axios'
import { Row,  Col, Form, FloatingLabel } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents';
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton, BackButton } from '../../components/buttoncomponents'
import List from './List'
import * as CONFIG from "../../helpers/config"
import { graphConfig, graphRequest, loginRequest } from "../../authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import * as FormFields from "../../components/formcomponents"
import { Divisions, Roles } from '../../helpers/API/Api';

export default function EditModal(props) {
    console.log(props)
    const [userslist, setuserslist] = useState([])
    const [azureusers, setAzureUsers] = useState([])
    const [modal, setModal] = useState(true)
    const [userlistmodal, setuserlistmodal] = useState(false)
    const [graphData,setGraphData] = useState([])
    const [count, setcount] = useState(0)
    const [addUserModal, setAddUserModal] = useState(false)
    const [selectedUserEdit, setSelectedUserEdit] = useState()

    const [edit, setEdit] = useState(false)
    const { instance, accounts } = useMsal();
    const [roles, setRolesList] = useState([]);
    const [division, setDivisionList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);

    const [selectedRoles, setSelectedRoles] = useState()
    const [selectedDivisions, setSelectedDivisions] = useState()

    const [checkedDivision, setCheckedDivision] = useState([])
    const [checkedNameDivision, setCheckedNameDivision] = useState([])
    const [checkedRole, setCheckedRole] = useState([])
    const [checkedNameRole, setCheckedNameRole] = useState([])

    const [deleteItemDivision, setDeleteItemDivision] = useState([])
    const [deleteItemRole, setDeleteItemRole] = useState([])

    const [deleteItemNameRole, setDeleteItemNameRole] = useState([])
    const [deleteItemNameDivision, setDeleteItemNameDivision] = useState([])

    const [password, setPassword] = useState('')

    const [validation, setValidation] = useState([])
    
    useEffect(() => {
        console.log(props)
        setSelectedUserEdit(props.selectedUserEdit)
        setEdit(props.edit)
    }, [props])

    const addUser = () =>{
      
    }
    const toggle = ()=>{
        let emptyList = []
        setCheckedDivision(emptyList)
        setCheckedNameDivision(emptyList)
        setCheckedRole(emptyList)
        setCheckedNameRole(emptyList)
        setModal(!modal)
        setAddUserModal(!addUserModal)
        props.toggleEdit()
    }
    useEffect(()=>{
      getUsersList()
      getAzureUsersList()
    },[])
    const formik = useFormik({
      initialValues:
      {
          userFullName: props.selectedUserEdit != null ? props.selectedUserEdit.user.userFullName : '',
          userEmail:  props.selectedUserEdit != null ? props.selectedUserEdit.user.userEmail : '',
          userDesignation:  props.selectedUserEdit != null ? props.selectedUserEdit.user.userDesignation : '',
          userAvatar: props.selectedUserEdit != null ? props.selectedUserEdit.user.userAvatar : '',
          divisionID : [],
          Roles: []
      }
  }) 
    useEffect(()=>{
    },[userslist])

    useEffect(()=>{
      console.log(graphData)
    },[graphData])

    useEffect(()=>{
        let listRole = []
        let listDiv = []
        for(let i = 0; i < props.selectedUserEdit.roleid.length; i++){
            for(let x = 0; x < props.roles.length; x++){
                if(props.selectedUserEdit.roleid[i].roleID == props.roles[x].userRoleID){
                    let object = {'userRoleID':props.roles[x].userRoleID,
                    'userRoleType': props.roles[x].userRoleType}
                    listRole.push(object) 
                }
            }
           
        }
        for(let i = 0; i < props.selectedUserEdit.divmapp.length; i++){
            for(let x = 0; x < props.division.length; x++){
                if(props.selectedUserEdit.divmapp[i].divisionID == props.division[x].divisionID){
                    console.log(props.division[x])
                    let object = {
                                    'divisionID':props.division[x].divisionID,
                                    'divisionName': props.division[x].divisionName
                                }
                    listDiv.push(object)
                }
            }
           
        }
        setSelectedRoles(listRole)
        setSelectedDivisions(listDiv)
        console.log(listRole)
        console.log(listDiv)
    },[])
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_USER_ROLE, {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "SolutionID": CONFIG.SolutionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setRolesList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    useEffect(()=>{

    })

    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_DIVISION_, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setDivisionList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    const getAzureUsersList = ()=>{
    //   callMsGraph(accessToken).then(response => setGraphData(response));
        const json_list ={
            "InstanceID" : CONFIG.PMSInstanceID,
            "AccountSubscriptionID": CONFIG.AccountSubscriptionID
        }
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ALL_AZURE_USERS, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setAzureUsers(res.data.result)
            setcount(res.data.result.length)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const getUsersList = ()=>{
        const json_list ={
          "PmsInstanceID" : CONFIG.PMSInstanceID,
          "PmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
          "SolutionID" : CONFIG.SolutionID
        }
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ALL_USERS, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
          setuserslist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const handleSaveEvent = () => {
        console.log(checkedRole)
        console.log(checkedNameRole)
        console.log(checkedDivision)
        console.log(checkedNameDivision)
        let nameArray = []
        let objectConvertRoles = []
        let objectConvertDivision = []

        let existingRolesList = []
        let existingDivisionList = []

        let deleteExistingRolesList = []
        let deleteExistingDivisionList = []
        
        let finalroleIDs = []
        let finaluserCompanyDivisionMapping = []
        let roleCheck = false
        let divCheck = false
        //let final = {'customerPaymentTypeID':''}
        checkedRole.map((list, k)=>{
            objectConvertRoles[k] = {'roleID':list}

        })
        checkedNameRole.map((list, k)=>{
            nameArray[k] = {'userRoleType':list}

        })
        checkedDivision.map((list, k)=>{
            objectConvertDivision[k] = {'divisionID':list,
                                     'companyID':parseInt(localStorage.getItem('CompanyID')) }

        })
        checkedNameDivision.map((list, k)=>{
            nameArray[k] = {'divisionID':list}

        })
        
   
        if(selectedRoles != null){
            console.log(selectedRoles, 'selectedRoles')
            console.log(deleteItemRole, 'deletedRoles')
            if(deleteItemRole != null && deleteItemRole.length > 0 ){
                
                    for(let k = 0; k < selectedRoles.length; k++ ){
                        for(let x = 0; x < deleteItemRole.length; x++){
                           if(selectedRoles[k].userRoleID == deleteItemRole[x]){
                            deleteExistingRolesList[k] = {"userRolesMappingID": props.selectedUserEdit.roleid[k].userRolesMappingID,
                            "userID": props.selectedUserEdit.roleid[k].userID,
                            "roleID": props.selectedUserEdit.roleid[k].roleID,
                            "is_Active": false,
                            "is_Deleted": true
                    }
                            break;
                           }
                           else{
                            deleteExistingRolesList[k] = {"userRolesMappingID": props.selectedUserEdit.roleid[k].userRolesMappingID,
                                    "userID": props.selectedUserEdit.roleid[k].userID,
                                    "roleID": props.selectedUserEdit.roleid[k].roleID,
                                    "is_Active": true,
                                    "is_Deleted": false
                            }
                           }
                        }
                    }
               
                    finalroleIDs = [...deleteExistingRolesList, ...objectConvertRoles,]
            }
            else{
                selectedRoles.map((list, k)=>{
                    existingRolesList[k] = {"userRolesMappingID": props.selectedUserEdit.roleid[k].userRolesMappingID,
                    "userID": props.selectedUserEdit.roleid[k].userID,
                    "roleID": props.selectedUserEdit.roleid[k].roleID,
                    "is_Active": true,
                    "is_Deleted": false
                }
                finalroleIDs = [...existingRolesList, ...objectConvertRoles]
                })
            }
            roleCheck = true
         }
        if(selectedDivisions != null){
            console.log(selectedDivisions, 'selectedDivisions')
            console.log(deleteItemDivision, 'selectedDivisions')
            if(deleteItemDivision != null && deleteItemDivision.length > 0 ){
                
                    for(let k = 0; k < selectedDivisions.length; k++ ){
                        for(let x = 0; x < deleteItemDivision.length; x++){
                           if(selectedDivisions[k].divisionID == deleteItemDivision[x]){
                            deleteExistingDivisionList[k] = {"userCompanyDivisionMappingID": props.selectedUserEdit.divmapp[k].userCompanyDivisionMappingID,
                            "userID":  props.selectedUserEdit.divmapp[k].userID,
                            "companyID": parseInt(props.selectedUserEdit.divmapp[k].companyID),
                            "divisionID": props.selectedUserEdit.divmapp[k].divisionID,
                            "is_Active": false,
                            "is_Deleted": true
                            }
                            break;
                           }
                           else{
                            deleteExistingDivisionList[k] = {"userCompanyDivisionMappingID": props.selectedUserEdit.divmapp[k].userCompanyDivisionMappingID,
                            "userID":  props.selectedUserEdit.divmapp[k].userID,
                            "companyID": parseInt(props.selectedUserEdit.divmapp[k].companyID),
                            "divisionID": props.selectedUserEdit.divmapp[k].divisionID,
                            "is_Active": true,
                            "is_Deleted": false
                            }
                           }
                        }
                    }
               
                finaluserCompanyDivisionMapping = [...deleteExistingDivisionList, ...objectConvertDivision,]
            }
            else{
                selectedDivisions.map((list, k)=>{
                    existingDivisionList[k] = { "userCompanyDivisionMappingID":  props.selectedUserEdit.divmapp[k].userCompanyDivisionMappingID,
                                                "userID":  props.selectedUserEdit.divmapp[k].userID,
                                                "companyID": parseInt(props.selectedUserEdit.divmapp[k].companyID),
                                                "divisionID": props.selectedUserEdit.divmapp[k].divisionID,
                                                "is_Active": true,
                                                "is_Deleted": false
                }
                finaluserCompanyDivisionMapping = [...existingDivisionList, ...objectConvertDivision,]
                })
            }
       
            
            
            divCheck = true
         }
        //formik.values.companyID = companyList[0].company.companyID
        console.log(deleteItemDivision)
        console.log(objectConvertRoles)
        console.log(objectConvertDivision)
        console.log(existingRolesList)
        console.log(finalroleIDs)
        console.log(deleteExistingRolesList)
        console.log(nameArray)
        console.log(formik.values)
        console.log(roleCheck)
        console.log(finaluserCompanyDivisionMapping)
        console.log(existingDivisionList)
        if(props.selectedUserEdit != null ){
            let final_json = {
          
                "userID" : props.selectedUserEdit.user.userID,
                "userFullName": formik.values.userFullName,
                "userDesignation": formik.values.userDesignation,
                "userAvatar":  formik.values.userAvatar,
                "password" : props.selectedUserEdit.user.password,
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "roleIDs": roleCheck = true ? finalroleIDs.length > 0 ? finalroleIDs : objectConvertRoles : objectConvertRoles,
                "userCompanyDivisionMapping": divCheck = true ? finaluserCompanyDivisionMapping.length > 0 ? finaluserCompanyDivisionMapping : objectConvertDivision : objectConvertDivision,
                "is_FirstLogin": true,
                "userEmail": formik.values.userEmail,
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_USERS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'workflows')
                props.refreshList()
                toggle()
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
            console.log(final_json)
        }
        else{
            let final_json = {
                "userFullName": formik.values.fullName,
                "userDesignation": formik.values.userDesignation,
                "userAvatar":  formik.values.userAvatar,
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "roleIDs": roleCheck = true ? finalroleIDs.length > 0 ? finalroleIDs : objectConvertRoles : objectConvertRoles,
                "userCompanyDivisionMapping": divCheck = true ? finaluserCompanyDivisionMapping.length > 0 ? finaluserCompanyDivisionMapping : objectConvertDivision : objectConvertDivision,
                "is_FirstLogin": true,
                "userEmail": formik.values.userEmail,
    
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_USER, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'workflows')
                props.refreshList()
                toggle()
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
            console.log(final_json)
        }
       
        // axios.post(CONFIG.PMS_SERVER_URL + CONFIG.ADD_USER, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        // .then((res) => {
        //     console.log(res.data.result, 'workflows')
        //     //setWorkFlowList(res.data.result)
        // })
    //console.log(final_json)
    //console.log(finalUserCompanyDivisionMapping)
    //props.handlePaymentMethodList(objectConvert, nameArray)
    //toggle()
    }

    const userlisttoggle = () =>{
        setuserlistmodal(!userlistmodal)
    }

    const handleAddUser = (e, data) => {
        console.log('clicked')
        console.log(data)
        toggle(userlisttoggle())
        setSelectedUserEdit(data)
        setAddUserModal(true)
        setModal(true)
        formik.values.userFullName = data.displayName
        formik.values.userEmail = data.mail
    }
    const handleUserName = () => {
       
            return(
                <div>
                   <b>{props.selectedUserEdit.user.userAvatar}</b>
                </div>
            )
        
       
       
    }

    const handleCheck = (roles,) => {
        
    }

    const getCheckedDivision = (data) => {
        setCheckedDivision(data)
    }

    const getCheckedNameDivsion = (data) => {
        setCheckedNameDivision(data)
    }

    const getCheckedRole = (data) => {
        setCheckedRole(data)
    }

    const getCheckedNameRole = (data) => {
        setCheckedNameRole(data)
    }

    const getUncheckedRole = (data) => {
        setDeleteItemRole(data)
    }

    const getUncheckedRoleName = (data) => {
        setDeleteItemNameRole(data)
    }

    const getUncheckedDivision = (data) => {
        console.log(data)
        setDeleteItemDivision(data)
    }

    const getUncheckedDivisionName = (data) => {
        setDeleteItemNameDivision(data)
    }

    const handleEdit = (data) => {
        console.log(data, 'edit index')
        setAddUserModal(true)

        console.log(addUserModal)
        setModal(true)
        setEdit(true)
        setSelectedUserEdit(data)
        formik.values.userFullName =  data.user.userFullName
        formik.values.userEmail =  data.user.userEmail 
        formik.values.userDesignation =  data.user.userDesignation 
        formik.values.userAvatar = data.user.userAvatar 
        console.log(formik.values)
        //toggle()

    }
    
    return (
        <>
                    <Modal isOpen={modal} toggle={toggle} className='add-user-modal'>
                          <ModalHeader toggle={toggle} className='p-40'>
                              <div className = 'd-flex justify-content-between '>
                                  <div className='font-16 font-weight-600 color-707895'>
                                      UPDATE USER
                                  </div>
                              </div>
                          </ModalHeader>
                          <ModalBody className=''>
                            {/* <div class="col-lg-12 p-2">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th>name</th>
                                                        <th>email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                               
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                          
                            <>
                                    <div className = 'd-flex justify-content-between m-2'>
                                    <div className = 'd-flex align-items-center'>
                                    <div  className='d-flex align-items-center justify-content-center avatar-add-user'>
                                        {handleUserName()}
                                    </div>
                                    <div className = 'ms-2 font-weight-600 font-16'>
                                        {props.selectedUserEdit.user.userFullName}
                                    </div>
                                    </div>
                                    <div>

                                    </div>
                                    </div>
                                    <Row className='pt-2-rem'>
                                        <FormFields.InputField
                                            name='userFullName'
                                            label='Full Name'
                                            type='text'
                                            disabled={true}
                                            val={formik.values.userFullName}
                                        />
                                            <FormFields.InputField
                                            name='userEmail'
                                            label='Email'
                                            type='text'
                                            disabled={true}
                                            val={formik.values.userEmail}
                                        />
                                    
                                    </Row>
                                    <Row className='pt-1-rem'>
                                    <Col md={6} lg={6} >
                                        <FormFields.InputField
                                            name='userDesignation'
                                            label='Designation'
                                            type='text'
                                            val={formik.values.userDesignation}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </Col>
                                    {selectedDivisions != null ? 
                                      <Col md={6} lg={6} >
                                      <Multiselect
                                          displayValue="key"
                                          onKeyPressFn={function noRefCheck(){}}
                                          onRemove={function noRefCheck(roles,data){
                                              console.log(checkedDivision, checkedNameDivision)

                                              var updatedList = [...checkedDivision];
                                              var updatedNameList = [...checkedNameDivision]

                                              var deleteItemList = [...deleteItemDivision]
                                              var deleteItemListName = [...deleteItemNameDivision]

                                              var validatedList = [...validation]

                                              deleteItemList = [...deleteItemDivision, parseInt(data.cat)];
                                              deleteItemListName = [...deleteItemNameDivision, data.key]

                                              updatedList.splice(checkedDivision.indexOf(data.cat), 1);
                                              updatedNameList.splice(checkedNameDivision.indexOf(data.key), 1);

                                              getCheckedDivision(updatedList);
                                              getCheckedNameDivsion(updatedNameList)

                                              getUncheckedDivision(deleteItemList)
                                              getUncheckedDivisionName(deleteItemListName)
                                          }}
                                          onSearch={function noRefCheck(){}}
                                          onSelect={function noRefCheck(roles,data){
                                              console.log(checkedDivision, checkedNameDivision)
                                              var updatedList = [...checkedDivision];
                                              var updatedNameList = [...checkedNameDivision]
                                              var deleteItemList = [...deleteItemDivision]
                                              var deleteItemListName = [...deleteItemNameDivision]
                                              var validatedList = [...validation]

                                              updatedList = [...checkedDivision, parseInt(data.cat)];
                                              updatedNameList = [...checkedNameDivision, data.key]
                                              var condition = deleteItemList.indexOf(parseInt(data.cat))
                                              if (Math.sign(condition) === -1) {
                                                
                                              }
                                              else{
                                                deleteItemList.splice(deleteItemDivision.indexOf(data.cat), 1);
                                                deleteItemListName.splice(deleteItemNameDivision.indexOf(data.key), 1);
                                              }
                                             
                                              getCheckedDivision(updatedList);
                                              getCheckedNameDivsion(updatedNameList)
                                              
                                              getUncheckedDivision(deleteItemList)
                                              getUncheckedDivisionName(deleteItemListName)
                                          }}
                                          options={division.map((list,k)=>(
                                              {
                                                  cat: list.divisionID,
                                                  key: list.divisionName
                                              }
                                              
                                          ))}
                                          selectedValues={selectedDivisions.map((list,k)=>(
                                              {
                                                  cat: list.divisionID,
                                                  key: list.divisionName
                                              }
                                              
                                          ))}
                                          showCheckbox
                                          placeholder = 'Divisions'
                                          
                                      />
                                      </Col>
                                    :
                                    ''
                                    }
                                  
                                    </Row>
                                    <Row className='pt-1-rem'>
                                    {selectedRoles != null ? 
                                    <Col md={6} lg={6} >
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck(){}}
                                        onRemove={function noRefCheck(roles,data){
                                            console.log(checkedRole, checkedNameRole)
                                            var updatedList = [...checkedRole];
                                            var updatedNameList = [...checkedNameRole]
                                            
                                            var deleteItemList = [...deleteItemRole]
                                            var deleteItemListName = [...deleteItemNameRole]
                                            
                                            deleteItemList = [...deleteItemRole, parseInt(data.cat)];
                                            deleteItemListName = [...deleteItemNameRole, data.key]
                                          
                                            updatedList.splice(checkedRole.indexOf(data.cat), 1);
                                            updatedNameList.splice(checkedNameRole.indexOf(data.key), 1);

                                            getCheckedRole(updatedList);
                                            getCheckedNameRole(updatedNameList)

                                            getUncheckedRole(deleteItemList)
                                            getUncheckedRoleName(deleteItemListName)
                                        }}
                                        onSearch={function noRefCheck(){}}
                                        onSelect={function noRefCheck(roles,data){
                                            console.log(checkedRole, checkedNameRole)

                                            var updatedList = [...checkedRole];
                                            var updatedNameList = [...checkedNameRole]

                                            var deleteItemList = [...deleteItemRole]
                                            var deleteItemListName = [...deleteItemNameRole]

                                            updatedList = [...checkedDivision, parseInt(data.cat)]
                                            updatedNameList = [...checkedNameDivision, data.key]

                                            var condition = deleteItemList.indexOf(parseInt(data.cat))
                                            if (Math.sign(condition) === -1) {
                                              
                                            }
                                            else{
                                            deleteItemList.splice(deleteItemRole.indexOf(data.cat), 1);
                                            deleteItemListName.splice(deleteItemNameRole.indexOf(data.key), 1);
                                            }

                                            updatedList = [...checkedRole, parseInt(data.cat)];
                                            updatedNameList = [...checkedNameRole, data.key]
                                            getCheckedRole(updatedList);
                                            getCheckedNameRole(updatedNameList)

                                            getUncheckedRole(deleteItemList)
                                            getUncheckedRoleName(deleteItemListName)
                                        }}
                                        options={roles.map((list,k)=>(
                                            {
                                                cat: list.userRoleID,
                                                key: list.userRoleType
                                            }
                                        ))}
                                        selectedValues={selectedRoles.map((list,k)=>(
                                            {
                                                cat: list.userRoleID,
                                                key: list.userRoleType
                                            }
                                            
                                        ))}
                                        showCheckbox
                                        placeholder = 'Roles'
                                        
                                    />
                                    </Col>
                                    :
                                    ''
                                        }
                                    </Row>
                                    </>
                          
                           
                          </ModalBody>
                          <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                              <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                              <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} /></span>
                          </ModalFooter>
                    </Modal>
            </>
         
    )
}

