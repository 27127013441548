import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import * as CONFIG from "../../../../helpers/config"
import { useFormik } from 'formik'
import axios from 'axios'
import {Row, Col, Form, Card} from "react-bootstrap"
import * as FormFields from "../../../../components/formcomponents"
import Moment from "moment"
import { useMsal } from "@azure/msal-react";

export default function Dishcargeport(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(props.view)
    const [doc1, setdoc1] = useState(false)
    const [key, setkey] = useState([])
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }
    
    const formik = useFormik({
        initialValues:{     
                tradeDischargePortCheckListID : 0,
                is_DischargePortETA: false,
                dischargePortETA: '',
                dischargePortETADate: '',
                is_DischargePortETACommunicated: false,
                is_DischargePortNOR: false,
                dischargePortNOR: '',
                dischargePortNORDocument: '',
                dischargePortNORDocumentName: '',
                is_DischargeCompleted: false,
                is_DispatchValue: false,
                dispatchValue: '',
                dispatchValueDocument: '',
                dispatchValueDocumentName: '',
                is_DummurageValue: false,
                dummurageValue: '',
                dummurageValueDocument: '',
                dummurageValueDocumentName: '',
                is_Discharge : false,
                dischargeDocumentDetail: []
        }
    })
    useEffect(()=>{
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DISCHARGE_PORT_BY_TRADE_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            if(res.data.result.length>0){
                Object.assign(formik.values, res.data.result[0])
                setdoc1(!doc1)
                let key = 1
                let doc_list = []
                formik.values.dischargeDocumentDetail.map((doc,k)=>{
                    doc_list[k] = doc
                    doc_list[k].key = key
                    key++
                })
                formik.values.dischargeDocumentDetail = doc_list
                console.log(formik.values)
                setkey(key)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[])
    return (
        <>
                    <div className='d-flex justify-content-between'>
                            <div className='p-4 mx-3 mt-4'>
                                <h4 className='font-16 font-weight-600 color-707895' >VIEW DISCHARGE PORT CHECKLIST</h4>  
                            </div>
                        </div>
                        <div className='add-edit-wrapper'>
                            <div className='card card-body p-4 br-8 mb-4'>
                            <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DischargePortETA'
                                    label='Discharge Port - ETA'
                                    checked={formik.values.is_DischargePortETA}
                                />
                            </Col>
                            <Col md={6}>
                                <span className='font-weight-600'>
                                    {formik.values.dischargePortETA != null &&
                                        Moment(formik.values.dischargePortETA).format("DD/MM/yyyy")
                                    }
                                </span>, &nbsp;&nbsp;&nbsp;
                                <span className='font-weight-600'>{Moment(formik.values.dischargePortETADate).format("DD/MM/yyyy")}</span>
                            </Col>
                            
                        </Row>
                        
                        <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DischargePortETACommunicated'
                                    label='Discharge Port ETA Communicated?'
                                    checked={formik.values.is_DischargePortETACommunicated}
                                />
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                            </Col>
                        </Row>
                        <Row className='border-bottom-1 py-4  d-flex align-items-center '>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DischargePortNOR'
                                    label='Discharge Port - NOR'
                                    checked={formik.values.is_DischargePortNOR}
                                />
                            </Col>
                            <Col md={3}>
                                <span className='font-weight-600'>{formik.values.dischargePortNOR}</span>
                            </Col>
                            <Col md={3}>
                                <a download={formik.values.dischargePortNORDocumentName} href={formik.values.dischargePortNORDocument}>View Attachment</a>
                            </Col>
                        </Row>
                        
                        <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DischargeCompleted'
                                    label='Discharge Completed?'
                                    checked={formik.values.is_DischargeCompleted}
                                />
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                            </Col>
                        </Row>
                        
                        
                        <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DispatchValue'
                                    label='Despatch Value'
                                    checked={formik.values.is_DispatchValue}
                                />
                            </Col>
                            <Col md={3}>
                                <span className='font-weight-600'>{formik.values.dispatchValue}</span>
                            </Col>
                            <Col md={3}>
                                <a download={formik.values.dispatchValueDocumentName} href={formik.values.dispatchValueDocument}>View Attachment</a>
                            </Col>
                        </Row>
                        <Row className='border-bottom-1 py-4  d-flex align-items-center '>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='is_DummurageValue'
                                    label='Demmurage Value'
                                    checked={formik.values.is_DummurageValue}
                                />
                            </Col>
                            <Col md={3}>
                                <span className='font-weight-600'>{formik.values.dummurageValue}</span>
                            </Col>
                            <Col md={3}>
                                <a download={formik.values.dummurageValueDocumentName} href={formik.values.dummurageValueDocument}>View Attachment</a>
                            </Col>
                        </Row>
                        <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                            <Col md={6} >
                                <Form.Check
                                    className='  font-15 font-weight-400 color-2C2C2E'
                                    type='checkbox'
                                    name='Is_Discharge'
                                    label='Discharge Documents'
                                    checked={formik.values.is_Discharge}
                                />
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                                {formik.values.dischargeDocumentDetail.map((doc,k)=>{
                                    return(
                                        <>
                                            {doc.is_Active == true &&
                                                <><a download={doc.dischargeDocumentName} href={doc.dischargeDocument}>View Attachment</a><br/></>
                                            
                                            }    
                                        </>
                                    )
                                })}
                            </Col>
                        </Row>
                            </div>
                        </div>
                        
        </>
    )
}
