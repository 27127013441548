import React, { useState, useEffect } from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import AddressDetail from './Address Details/AddressDetail';
import ContactDetail from './Contact Details/ContactDetail';
import CustomerDetails from './Customer Details/CustomerDetails';
import ModalForExistingCustomer from './components/ModalForExistingCustomer';
import * as CONFIG from "../../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";

function CustomerDetailsTab(props) {
    const { instance } = useMsal();
    const [customerList, setList] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [isFirst, setIsFirst] = useState(true);
    const [customerdetails_json, setcustomerdetails] = useState([]);
    const [customeraddress_json, setcustomeraddress]= useState([]);
    const [customercontact, setcontactdetails] = useState([]);
    const [isdataavailable, setisdataavailable] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [isCustomer, setIsCustomer] = useState(true)
    const [buttonStatus, setButtonStatus] = useState(true)
    const [documentDetails, setDocumentDetails] = useState()
    
    useEffect(()=>{
        if(props.isdataavailable){
            customerData(props.custData)
        }
        if(props.isEdit){
            getList(props.selectedEnquiry.enqiry.customerID)
        }
    },[props])
    const getList = (id) =>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id": id,
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            customerData(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const setShowModalForModal = () => {
        setShowModal(!showModal)
    }
    const customerData = (data)=>{
        console.log(data)
        let customerData = data[0].customerdata;
        let customerdetails_json = {
            "customerID": customerData.customerID,
            "customerName": customerData.customerName,
            "customerCode": customerData.customerCode,
            "website" : customerData.website,
            "telephoneNo" : customerData.telephoneNo,
            "fax" : customerData.fax
        }
        setcustomerdetails(customerdetails_json)
        let customeraddress_json  = {
            "registeredUnitNo": customerData.registeredUnitNo,
            "registeredBuildingName" : customerData.registeredBuildingName,
            "registeredStreetName" : customerData.registeredStreetName,
            "registeredCityName" : customerData.registeredCityName,
            "registeredCountry" : customerData.registeredCountry,
            "countryName" : customerData.country.countryName
        }
        setcustomeraddress(customeraddress_json)
        console.log(customerData, 'customer')
        let contact = customerData.customerContactInformation;
        let contact_details = contact.filter(x=>x.is_Default == true)
        console.log('contact details', contact_details)
        setcontactdetails(contact_details)
        setisdataavailable(true)
        setSelectedCustomer(data)
        setIsCustomer(false)
        
    }

    const moveToNext = ()=>{
        let length = selectedCustomer.length;
        console.log(selectedCustomer)
        console.log(documentDetails)
        if(selectedCustomer){
            console.log(selectedCustomer)
            props.customerData(selectedCustomer)
        }
    }



    useEffect(()=>{

    },[documentDetails])
    return (
        <>
            <Card className='bg-white'>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body p-4 br-10 bg-white'>
                        <CustomerDetails 
                            selectedEnquiry = {props.selectedEnquiry} 
                            handleDocumentAttached = {props.handleDocumentAttached} 
                            customerDetails={customerdetails_json} 
                            setShowModalForModal = {setShowModalForModal} 
                            isdataavailable={isdataavailable} 
                            isCustomer = {isCustomer}
                            isEdit = {props.isEdit}
                            documentDetails = {props.documentDetails}
                        />
                    </Card.Body>
                    <Card.Body className='form-card-body  p-4 br-10 bg-white'>
                        <AddressDetail customerAddress={customeraddress_json} isdataavailable={isdataavailable}/>
                    </Card.Body>
                    <Card.Body className='form-card-body bg-white'>
                        <ContactDetail customerContact = {customercontact} isdataavailable={isdataavailable}/>
                    </Card.Body>
                </Card>
                {showModal == true ? 

                <ModalForExistingCustomer cancel={setShowModalForModal} customerData={customerData} selectedCustomer={selectedCustomer}/>
                :
                ''
                }
                <Card.Footer className='form-card-footer py-2-rem pt-4 d-flex'>
                    <span><BlueBorderButton value='Cancel' clickEvent={props.back}/></span>
                    <span className='pl-10'><BlueButton value='Next' disabled = {isCustomer} clickEvent={moveToNext}/></span>
                </Card.Footer>
            </Card>
        </>
    )
}

export default CustomerDetailsTab