import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../../../components/sidebar/sidebar";
import Navbar from "../../../../components/navbar";
import { useMsal } from "@azure/msal-react";
import * as CONFIG from "../../../../helpers/config";
import axios from "axios";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  Col,
  Row,
  Accordion,
  Form,
  Button,
  Card,
  FloatingLabel,
} from "react-bootstrap";
import {
  BlueBorderButton,
  BlueButton,
} from "../../../../components/buttoncomponents";
import { useReactToPrint } from 'react-to-print';

export default function List() {
  const { instance } = useMsal();
  const componentRef = useRef();
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isView, setIsView] = useState(false);
  const [dbdata, setDbdata] = useState([]);
  const [payment, setPayment] = useState([]);

  useEffect(() => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
    };
    

    axios
      .post(
        CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_DEBIT_CREDIT,
        json_list,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setList(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  }, []);

  const toggle = () => {
    setIsView(!isView);
  };

  const selectDebitCredit = (id) => {
    let selected = list.filter((x) => x.debitcreditdata.debitCreditID == id);

    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
      id: id,
    };

    axios
      .post(CONFIG.TMS_SERVER_URL + CONFIG.GET_DEBIT_CREDIT_BY_ID, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setDbdata(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
    const json_list1 = {
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
      id: selected[0].paymentid,
    };

    axios
      .post(
        CONFIG.TMS_SERVER_URL +
          CONFIG.GET_TRADECUSTOMERPAYMENTDETAILS_BY_PAYMENTID,
        json_list1,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setPayment(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
    setSelected(selected);
    toggle();
  };
  const pageStyle = `
  @page {
    size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
  @media print {
    div.page-footer {
    position: fixed;
    bottom:0mm;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: #fff;
    /* For testing */
    background: red;
    opacity: 0.5;

    page-break-after: always;
    }
    .page-number:before {
      /* counter-increment: page; */
      content: "Pagina "counter(page);
    }


  }
  body {
    marginBottom:50px
  }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle
  });
  return (
    <div className="main-wrapper inner-wrapper d-flex">
      <Modal
        isOpen={isView}
        toggle={toggle}
        className="modal-dialog-custom-width trade_contract"
        style={{ height: "100vh" }}
      >
        <ModalHeader toggle={toggle}>
          <div className="mx-2">
            <BlueBorderButton value="PRINT" clickEvent={handlePrint} />
          </div>
        </ModalHeader>
        <ModalBody className=" scrollContract">
          <div id="download_section" ref={componentRef}>
            <Row className="p-1-rem">
              <div className="d-flex justify-content-end">
                <img src="assets/images/logo.svg" />
              </div>
              <div className="font-weight-600 d-flex justify-content-center uline">
                {selected != null && selected.length > 0 && (
                  <>
                    {selected[0].debitcreditdata.type == 1 ? (
                      <>CUSTOMER DEBIT NOTE</>
                    ) : (
                      <>CUSTOMER CREDIT NOTE</>
                    )}
                  </>
                )}
              </div>
              <div className="d-flex justify-content-between mt-1-rem">
                <div>
                  <div className="font-weight-500">
                    {selected != null && selected.length > 0 && (
                      <>
                        {selected[0].debitcreditdata.type == 1 ? (
                          <>
                            CUSTOMER DEBIT NOTE :{" "}
                            {selected[0].debitcreditdata.drCrNo}
                          </>
                        ) : (
                          <>
                            CUSTOMER CREDIT NOTE :{" "}
                            {selected[0].debitcreditdata.drCrNo}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="font-weight-500">
                    REFERENCE NO :{" "}
                    {selected != null && selected.length > 0 && (
                      <>
                        {selected[0].debitcreditdata.type == 1 ? (
                          <>
                            CUSTOMER DEBIT NOTE :{" "}
                            {selected[0].debitcreditdata.referenceNo}
                          </>
                        ) : (
                          <>
                            CUSTOMER CREDIT NOTE :{" "}
                            {selected[0].debitcreditdata.referenceNo}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="invoice_border  p-1-rem mt-1-rem">
                <div className="font-weight-500">
                  NAME AND ADDRESS OF CUSTOMER:
                  <div>
                    {dbdata != null && dbdata.length > 0 && (
                      <>
                        {dbdata[0].customername}
                        <br />
                        {dbdata[0].customerRegisteredUnitNo},
                        {dbdata[0].custRegisteredBuildingName},
                        {dbdata[0].customerRegisteredCityName},
                        {dbdata[0].custRegisteredPostBox}
                        <br />
                        {dbdata[0].companyTelephone}
                        <br />
                        {dbdata[0].companyWebsite}
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>

              {(payment != null && payment.length> 0) &&
                <table
                className="invoice_border p-3-rem width-100l"
                style={{ border: "1px solid black" }}
              >
                <thead className="invoice_border">
                  <tr
                    className="width-100l"
                    style={{ border: "1px solid black" }}
                  >
                    <th
                      clssName="br-right"
                      style={{ border: "1px solid black" }}
                    >
                      S.NO
                    </th>
                    <th
                      clssName="br-right"
                      style={{ border: "1px solid black" }}
                    >
                      DESCRIPTION OF GOODS
                    </th>
                    <th
                      clssName="br-right"
                      style={{ border: "1px solid black" }}
                    >
                      QUANTITY
                    </th>
                    <th
                      clssName="br-right"
                      style={{ border: "1px solid black" }}
                    >
                      UNIT PRICE
                    </th>
                    <th
                      clssName="br-right"
                      style={{ border: "1px solid black" }}
                    >
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid black" }}>1</td>
                    <td style={{ border: "1px solid black" }}>
                      <div className="font-weight-600 uline">
                        {payment[0].productName}
                      </div>
                    

                      <div className="font-weight-500">
                        {payment[0].invoiceRemarks}
                      </div>
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      <span className="font-weight-500">
                        {payment[0].quantity} MT
                      </span>
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      <div className="font-weight-500">
                        {payment[0].sellingUnitAmount} USD
                      </div>
                      <div className="mt-1-rem font-weight-600">
                        {payment[0].loadPort}, {payment[0].loadPortCountry}
                      </div>
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      <span className="font-weight-500">
                        {selected[0].debitcreditdata.amount} USD
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              }
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <Sidebar id="7.9" isOpenid={7} />
      <div className="width-100">
        <Navbar />
        <div className="d-flex inner-page-header justify-content-between mx-2">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/sidebaricons/financial.svg"
              className="inner-page-titleicon img-fluid"
            />
            <span className="font-16 font-weight-600 color-707895 d-flex align-items-center pl-10">
              CUSTOMER DEBIT OR CREDIT
            </span>
          </div>
        </div>

        <div className="">
          <div className="inner-page-content  br-8 py-3">
            <Card>
              <Card.Body>
                <div class="col-lg-12 list-scroll">
                  <div class="table-responsive-sm ">
                    <table className="table tCustom table-text">
                      <thead className="p-1-rem">
                        <tr className="text-uppercase list-top">
                          <th>No#</th>
                          <th>Type</th>
                          <th>Customer#</th>
                          <th>Reference No#</th>
                          <th>Amount</th>
                          <th>Document</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((li, k) => {
                          return (
                            <tr>
                              <td>{li.debitcreditdata.drCrNo}</td>
                              <td>
                                {li.debitcreditdata.type == 1
                                  ? "Debit"
                                  : "Credit"}
                              </td>
                              <td>{li.customername}</td>
                              <td>{li.debitcreditdata.referenceNo}</td>
                              <td>USD {li.debitcreditdata.amount}</td>
                              <td>
                                <a
                                  download={
                                    li.debitcreditdata.type == 1
                                      ? "Debit Document"
                                      : "Credit Document"
                                  }
                                  href={li.debitcreditdata.documentUpload}
                                >
                                  {li.debitcreditdata.type == 1
                                    ? "Debit Document"
                                    : "Credit Document"}
                                </a>
                              </td>
                              <td
                                onClick={() => {
                                  selectDebitCredit(
                                    li.debitcreditdata.debitCreditID
                                  );
                                }}
                              >
                                <Button>View</Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
