import React, { useEffect, useState, useRef } from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../components/buttoncomponents'
import * as FormFields from "../../../../components/formcomponents"
import { CountryList } from '../../../../helpers/API/Api';

function ParentCompanyAddress(props) {

   console.log(props.registeredCompanyData)

    const ref = useRef(null);
    const [checked, setChecked] = useState(false)

  
    const trackCheck = (e) => {
        setChecked(ref.current.checked)
    }

    const formik = useFormik({
        initialValues:
        {
            parentCompanyUnitNo: props.registeredCompanyData.registeredUnitNo,
            parentCompanyBuildingName: props.registeredCompanyData.registeredBuildingName,
            parentCompanyStreetName: props.registeredCompanyData.registeredStreetName,
            parentCompanyCityName: props.registeredCompanyData.registeredCityName,
            parentCompanyCountryID: props.registeredCompanyData.registeredCountryID,
            ParentCompanyStateName: props.registeredCompanyData.RegisteredStateName,
            parentCompanyPostBox: props.registeredCompanyData.registeredPostBox,
        },
      
        validate: values => {
            
            let errors = {}

            const letters = /^[A-Za-z ]+$/;
            
            if(!values.parentCompanyUnitNo){
                errors.parentCompanyUnitNo = "Please fill in Name"
            }
            return errors
        }

        
    })

    useEffect(()=>{
        props.saveParentCompanyAddress(formik.values)
    }, [formik])
  return (
    <>
    <div className = 'd-flex align-items-center'>
        <div className='font-16 font-weight-600 color-707895'>
            Parent Company Address      
        </div>
        <div className = 'd-flex ms-3'>
            <Form.Check type="checkbox"  ref={ref} defaultChecked={false} onChange = {trackCheck}/>
            <span className = 'ms-2'>Parent company address if different other than the registered address of the customer</span>
        </div>
    </div>
    <div className = 'd-flex'>
        {checked == true ?      
        <Row>
        <FormFields.InputField
            md={3}
            name='parentCompanyUnitNo'
            label='Unit No'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyUnitNo}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyBuildingName'
            label='Building Name'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyBuildingName}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyStreetName'
            label='Street Name'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyStreetName}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyCityName'
            label='City'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyCityName}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
        />
        <FormFields.InputSelect
            md={3}
            name='parentCompanyCountryID'
            label='Country'
            classname='pt-1-rem'
            type='text'
            optionField = {<CountryList cntid = {props.registeredCompanyData.registeredCountryID}/> }
            val={formik.values.parentCompanyCountryID}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyPostBox'
            label='Post Box'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyPostBox}
            onChange={formik.handleChange}
            onBlur = {formik.handleBlur}
            
        />
        <div className = 'col-md-3 d-flex align-items-center'>
            <BlueButton value='Save'/>
        </div>
        </Row>
        :
        <Row>
        <FormFields.InputField
            md={3}
            name='parentCompanyUnitNo'
            label='Unit No'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyUnitNo}
            disabled
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyBuildingName'
            label='Building Name'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyBuildingName}
            disabled
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyStreetName'
            label='Street Name'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyStreetName}
            disabled
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyCityName'
            label='City'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyCityName}
            disabled
        />
        <FormFields.InputSelect
            md={3}
            name='parentCompanyCountryID'
            label='Country'
            classname='pt-1-rem'
            type='text'
            optionField = {<CountryList cntid = {props.registeredCompanyData.registeredCountryID}/> }
            val={formik.values.parentCompanyCountryID}
            disabled
        />
        <FormFields.InputField
            md={3}
            name='parentCompanyPostBox'
            label='Post Box'
            classname='pt-1-rem'
            type='text'
            val={formik.values.parentCompanyPostBox}
            disabled
        />
        <div className = 'd-flex col-md-3 align-items-center'>
            <BlueButton value='Save' disabled = {checked == false ? true : false}/>
        </div>
        </Row>
        }
    
    </div>
    </>
   
  )
}

export default ParentCompanyAddress