import React, { useEffect, useState } from 'react'
import { Card } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Add_ins_form from './add_ins_form';
import ContactList from './contactList';
import * as CONFIG from "../../helpers/config";
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

export default function Add_ins(props) {
    console.log(props)
    const { instance } = useMsal();
    const [index, setIndex] = useState(0)
    const [data, setData] = useState([])
    const [cData, setCData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [key, setKey] = useState(0)

    useEffect(() => {
        if (props.isEdit == true) {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "ID": props.instituteData.institute.financialInstituteID // Country ID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_FINANCIAL_INSTITUTE_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setData(res.data.result[0].institute)
                    let key = 0
                    res.data.result[0].institute.financialInstituteContact.map((fin,k)=>{
                        fin.key = key
                        key++
                    })
                    setCData(res.data.result[0].institute.financialInstituteContact)
                    setIsEdit(true)
                    setKey(key)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    },[])

    const changeindex = (index) => {
        setIndex(index)
    }

    const saveData = (data) => {
        setData(data)
    }
    const contactData = (dat) => {
        console.log(dat)
        setCData(dat)
    }
    return (
        <div className='br-8 py-3'>
            <Tabs selectedIndex={index}>
                <Card className='tabs-wrapper'>
                    <TabList>
                        <Tab>General Information</Tab>
                        <Tab>Contact Information</Tab>
                    </TabList>
                </Card>
                <TabPanel className='mt-1-rem'>
                    <Add_ins_form changeindex={changeindex} saveData={saveData} data={data} isEdit={props.isEdit} isAdd={props.isAdd} back={props.back}/>
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <ContactList changeindex={changeindex} insituteData={data} contactData={contactData} cData={cData} back={props.back}  isEdit={isEdit} keydata={key}/>
                </TabPanel>
            </Tabs>
        </div>
    )
}
