import React, { useEffect, useState } from "react";
import * as SideBarIcon from "../../components/sidebar/sidebardIcons";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import { Link, NavLink } from "react-router-dom";
import { Button, Card, Col, Row, FloatingLabel, Form } from "react-bootstrap";
import {
  AddNewButton,
  BackButton,
  BlueButton,
} from "../../components/buttoncomponents";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as FormFields from "../../components/formcomponents";
import { useFormik } from "formik";
import * as CONFIG from "../../helpers/config";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import NumberFormat from "react-number-format";
import * as ButtonComponent from "../../components/buttoncomponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Menus from "./menu";

export default function Index() {
  const { instance } = useMsal();
  const MySwal = withReactContent(Swal);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState();
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [history, setHistory] = useState([]);
  const [request, setRequest] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(0);
  const [doc, setDoc] = useState(false);
  const [isKeyModal, setIsKeyModal] = useState(false);
  const [generatedKey, setGeneratedKey] = useState(0);
  const [keyModal, setKeyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [renewModal, setRenewModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("SuperAdminToken") != null) {
      getList();
    } else {
      localStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: "/superadminlogin",
      });
    }
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleView = () => {
    setViewModal(!viewModal);
  };
  const toggleIsModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleKeyModal = () => {
    setIsKeyModal(!isKeyModal);
  };

  const keyModalToggle = () => {
    setKeyModal(!keyModal);
  };

  const renewModalToggle = () => {
    setRenewModal(!renewModal);
  };

  const formik = useFormik({
    initialValues: {},
  });

  const getList = () => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      created_User: 0,
      id: CONFIG.SolutionID, // SolutionID
    };

    axios
      .post(
        CONFIG.PRO_SERVER_URL + CONFIG.GET_ACCOUNT_SUBSCRIPTION,
        json_list,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setSubscriptionList(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          // localStorage.clear();
          // instance.logoutRedirect({
          //     postLogoutRedirectUri: "/",
          // });
        }
      });
  };

  const saveSubscription = () => {
    const json_foremail = {
      UserEmail: formik.values.subscriptionAccountEmail,
      PMSInstanceID: CONFIG.PMSInstanceID,
    };
    axios
      .post(
        CONFIG.PRO_SERVER_URL + CONFIG.CHECK_USER_AVAILABLE,
        json_foremail,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.responseCode != "02002") {
          formik.values.paymentTermID = parseInt(formik.values.paymentTermID);
          formik.values.subscriptionUserEmail =
            formik.values.subscriptionAccountEmail;
          formik.values.totalAmount = parseInt(formik.values.totalAmount);
          let indexFirst =
            formik.values.subscriptionUserFullName.lastIndexOf(" ");
          let firstNameInitial =
            formik.values.subscriptionUserFullName.charAt(0);
          let LastNameInitial = formik.values.subscriptionUserFullName.charAt(
            indexFirst + 1
          );
          formik.values.userAvatar = firstNameInitial + LastNameInitial;
          const json_list = {
            pmsInstanceId: 56522122,
            solutionId: 3,
            subscriptionUserDesignation: "Admin",
            ...formik.values,
          };
          console.log(formik.values);
          axios
            .post(
              CONFIG.PRO_SERVER_URL + CONFIG.ADD_ACCOUNT_SUBSCRIPTION,
              json_list,
              {
                headers: {
                  Authorization: `Bearer ${CONFIG.AuthToken}`,
                  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                },
              }
            )
            .then((res) => {
              console.log(res.data.result);
              let accountid = res.data.result;
              const json1 = {
                messageCategory: "Trade",
                description: "Trade-Process-For-Rescom",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "Contracts Stage",
                    level: 1,
                    stepNo: 1,
                    description: "Initiate Trade",
                    type: "Task",
                    daysToComplete: null,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                  },
                  {
                    stage: "Contracts Stage",
                    level: 2,
                    stepNo: 2,
                    description: "Freight Charges Update",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Contracts Stage",
                    level: 3,
                    stepNo: 3,
                    description: "Prepare Pre-shipment Costsheet and Approval",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Contracts Stage",
                    level: 4,
                    stepNo: 4,
                    description: "Customer Contract",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Contracts Stage",
                    level: 4,
                    stepNo: 5,
                    description: "Supplier Contract",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 6,
                    description: "Update Vessel Information",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 7,
                    description: "Post Vessel Fixture Checklist",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 8,
                    description: "Customer Proforma Invoice",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 9,
                    description: "Customer Invoice",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 10,
                    description: "Supplier PO",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 11,
                    description: "Supplier Proforma Invoice",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 12,
                    description: "Supplier Invoice",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 13,
                    description: "L/C Documents",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 14,
                    description: "Insurance Documentations(CIF,FOB/CFR)",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 15,
                    description: "Shipment ETA",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 16,
                    description: "Pre-Shipment Checklist",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 17,
                    description: "Loading Update",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 5,
                    stepNo: 18,
                    description: "Loading Checklist",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Execution Stage",
                    level: 6,
                    stepNo: 19,
                    description: "Discharge Port Updates",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Trade Closure",
                    level: 5,
                    stepNo: 20,
                    description: "Customer Payment Updates",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Trade Closure",
                    level: 5,
                    stepNo: 21,
                    description: "Supplier Payment Updates",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Trade Closure",
                    level: 7,
                    stepNo: 22,
                    description: "Actual Costsheet and Approvals",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Trade Closure",
                    level: 7,
                    stepNo: 23,
                    description: "Pre-Closure Updates",
                    type: "Task",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3005,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                  {
                    stage: "Trade Closure",
                    level: 7,
                    stepNo: 24,
                    description: "Close Trade",
                    type: "Task",
                    daysToComplete: null,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-09-24T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };
              const json2 = {
                messageCategory: "CostsheetApproval",
                description: "Estimated-Costsheet-Approval",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "Estimated Costsheet Approval",
                    level: 1,
                    stepNo: 1,
                    description: "Estimated Costsheet Approval",
                    type: "Approval",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3022,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3022,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3001,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3001,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-09-24T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };
              const json3 = {
                messageCategory: "CustomerContractApproval",
                description: "Customer-Contract-Approval",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "Customer Contract Approval",
                    level: 1,
                    stepNo: 1,
                    description: "Customer Contract Approval",
                    type: "Approval",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-09-24T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };
              const json4 = {
                messageCategory: "SupplierContractApproval",
                description: "Supplier-Contract-Approval",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "Supplier Contract Approval",
                    level: 1,
                    stepNo: 1,
                    description: "Supplier Contract Approval",
                    type: "Approval",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-09-24T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };
              const json5 = {
                messageCategory: "ActualCostSheetApproval",
                description: "Actual-Costsheet-Approval",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "Actual Costsheet Approval",
                    level: 1,
                    stepNo: 1,
                    description: "Actual Costsheet Approval",
                    type: "Approval",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3022,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-completion",
                        roleID: 3022,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-rejection",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3021,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-09-24T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };

              const json6 = {
                messageCategory: "CustomerRequirementApproval",
                description: "CustomerRequirement-Approval",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
                workflowDefinitionSteps: [
                  {
                    stage: "CustomerRequirement Approval",
                    level: 1,
                    stepNo: 1,
                    description: "CustomerRequirement Approval",
                    type: "Approval",
                    daysToComplete: 2,
                    is_Milestone: null,
                    is_SendNotification: true,
                    is_CommonEmail: null,
                    commonEmail: null,
                    order: null,
                    taskOwner: [
                      {
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    taskNotification: [
                      {
                        taskAction: "on-rejection",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-approved",
                        roleID: 3003,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                      {
                        taskAction: "on-completion",
                        roleID: 3004,
                        created_User: 1,
                        created_Datetime: "0001-01-01T00:00:00",
                        modified_User: null,
                        modified_Datetime: null,
                        is_Active: true,
                        is_Deleted: false,
                        instanceID: 56522122,
                        accountSubscriptionID: 565221240,
                      },
                    ],
                    created_User: 1,
                    created_Datetime: "2022-09-24T11:59:37.908489",
                    modified_User: null,
                    modified_Datetime: null,
                    is_Active: true,
                    is_Deleted: false,
                    instanceID: 56522122,
                    accountSubscriptionID: 565221240,
                  },
                ],
                created_User: 1,
                created_Datetime: "2022-11-07T11:37:10.349747",
                modified_User: null,
                modified_Datetime: null,
                is_Active: true,
                is_Deleted: false,
                referenceID: 0,
                referenceNo: null,
              };

              //ADD JSON 1
              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json1,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              // ADD JSON 2

              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json2,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              // ADD JSON 3

              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json3,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              // ADD JSON 4

              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json4,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              // ADD JSON 5

              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json5,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              // ADD JSON 6
              axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_WORKFLOW_DEFENITION,
                  json6,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

              const company_list = CONFIG.COMPANY_TYPE;
              const financial_list = CONFIG.FINANCIAL_TYPE_LIST;
              console.log(company_list);
              console.log(financial_list);
              company_list.map((list, k) => {
                let json_company1 = {
                  instanceID: CONFIG.PMSInstanceID,
                  accountSubscriptionID: accountid,
                  companyTypeName: list,
                };
                console.log(json_company1);
                axios
                  .post(
                    CONFIG.TMS_SERVER_URL + CONFIG.CUSTOMER_ADD_COMPANY_TYPE,
                    json_company1,
                    {
                      headers: {
                        Authorization: `Bearer ${CONFIG.AuthToken}`,
                        "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data.result);
                  })
                  .catch((error) => {});
                axios
                  .post(
                    CONFIG.TMS_SERVER_URL + CONFIG.SUPPLIER_ADD_COMPANY_TYPE,
                    json_company1,
                    {
                      headers: {
                        Authorization: `Bearer ${CONFIG.AuthToken}`,
                        "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data.result);
                  })
                  .catch((error) => {});
              });

              financial_list.map((list, k) => {
                let json_company = {
                  instanceID: CONFIG.PMSInstanceID,
                  accountSubscriptionID: accountid,
                  customerFinancialInfoTypeName: list,
                };
                let json_company_sup = {
                  instanceID: CONFIG.PMSInstanceID,
                  accountSubscriptionID: accountid,
                  supplierFinancialInfoTypeName: list,
                };
                console.log(json_company);
                axios
                  .post(
                    CONFIG.TMS_SERVER_URL +
                      CONFIG.CUSTOMER_ADD_FINANCIAL_INFO_TYPE,
                    json_company,
                    {
                      headers: {
                        Authorization: `Bearer ${CONFIG.AuthToken}`,
                        "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data.result);
                  })
                  .catch((error) => {});

                axios
                  .post(
                    CONFIG.TMS_SERVER_URL +
                      CONFIG.SUPPLIER_ADD_FINANCIAL_INFO_TYPE,
                    json_company_sup,
                    {
                      headers: {
                        Authorization: `Bearer ${CONFIG.AuthToken}`,
                        "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data.result);
                  })
                  .catch((error) => {});
              });

              const account_type = {
                accountTypeName: "Current Account",
                instanceID: CONFIG.PMSInstanceID,
                accountSubscriptionID: accountid,
              };
              axios
                .post(
                  CONFIG.PRO_SERVER_URL + CONFIG.ADD_ACCOUNT_TYPE,
                  account_type,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
                .catch((error) => {});

                const meeting_json ={
                   "meetingCategoryName": "Financial Institution",
                   "instanceID": CONFIG.PMSInstanceID,
                   "accountSubscriptionID": accountid
                }

                axios
                .post(
                  CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_CATEGORY,
                  account_type,
                  {
                    headers: {
                      Authorization: `Bearer ${CONFIG.AuthToken}`,
                      "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data.result);
                })
              getList();
              toggle();
            })
            .catch((error) => {});
        } else {
          MySwal.fire(
            {
                icon: 'danger',
                title: 'Subscription email already registered. Please try again with a different email!',
                type: 'danger',
                confirmButtonText: 'Close',
                confirmButtonClass: "btn btn-primary",

            }).then(() => {

            })
        }
      });
  };

  const selectSubscription = (id) => {
    const filter = subscriptionList.filter((x) => x.subscriptionId == id)[0];
    setSubscription(filter);
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      created_User: 0,
      id: id, // SubscriptionID
    };
    axios
      .post(
        CONFIG.PRO_SERVER_URL + CONFIG.GET_SUBSCIRPITON_HISTORY,
        json_list,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setHistory(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
    axios
      .post(
        CONFIG.PRO_SERVER_URL + CONFIG.GET_REQUESTED_COMPANY_BY_SUBSCRIPTION,
        json_list,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setRequest(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
    toggleView();
  };

  const selectPayment = (id) => {
    setSelectedSubscription(id);
    toggleIsModal();
  };

  const receiptDocHandler = (file) => {
    formik.values.receiptDocName = file.name;
    formik.values.receiptDoc = file.base64;
    setDoc(!doc);
  };
  const savePayment = (id) => {
    const json_list = {
      accountSubscriptionHistoryID: selectedSubscription,
      modified_User: 0,
      receiptUpload: formik.values.receiptDoc,
      amount: parseInt(formik.values.amount),
    };

    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_PAYMENT_STATUS, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        const json_list = {
          instanceID: CONFIG.PMSInstanceID,
          created_User: 0,
          id: subscription.subscriptionId, // SubscriptionID
        };
        axios
          .post(
            CONFIG.PRO_SERVER_URL + CONFIG.GET_SUBSCIRPITON_HISTORY,
            json_list,
            {
              headers: {
                Authorization: `Bearer ${CONFIG.AuthToken}`,
                "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
              },
            }
          )
          .then((res) => {
            console.log(res.data.result);
            setHistory(res.data.result);
            toggleIsModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              localStorage.clear();
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            }
          });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  };

  const selectRequest = (id) => {
    console.log(id);
    setSelectedCompany(id);
    keyModalToggle();
    // const json_list = {
    //     "instanceID": CONFIG.PMSInstanceID,
    //     "created_User": 0,
    //     "id": id // RequestCompanyID
    // }
  };

  const generateKey = () => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      created_User: 0,
      id: selectedCompany,
      Amount: parseFloat(formik.values.amount),
      CompanyReceiptUpload: formik.values.receiptDoc,
    };
    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.GENERATE_KEY, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        keyModalToggle();
        formik.values.amount = "";
        formik.values.receiptDoc = "";
        MySwal.fire({
          icon: "success",
          title:
            "The key has been generated successfully and shared with the customer!",
          html: "<p>Key: " + res.data.result.activationKey + "</p>" + "<br />",
          type: "success",
          confirmButtonText: "Close",
          confirmButtonClass: "btn btn-primary",
        }).then(() => {
          const json_list = {
            instanceID: CONFIG.PMSInstanceID,
            created_User: 0,
            id: subscription.subscriptionId, // SubscriptionID
          };
          axios
            .post(
              CONFIG.PRO_SERVER_URL +
                CONFIG.GET_REQUESTED_COMPANY_BY_SUBSCRIPTION,
              json_list,
              {
                headers: {
                  Authorization: `Bearer ${CONFIG.AuthToken}`,
                  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                },
              }
            )
            .then((res) => {
              console.log(res.data.result);
              setRequest(res.data.result);
            })
            .catch((error) => {
              if (error.response.status == 401) {
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
              }
            });
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  };

  const cancelCompany = (id) => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      id: id, // SubscriptionID
    };
    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.CANCEL_COMPANY, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        const json_list = {
          instanceID: CONFIG.PMSInstanceID,
          created_User: 0,
          id: subscription.subscriptionId, // SubscriptionID
        };
        axios
          .post(
            CONFIG.PRO_SERVER_URL +
              CONFIG.GET_REQUESTED_COMPANY_BY_SUBSCRIPTION,
            json_list,
            {
              headers: {
                Authorization: `Bearer ${CONFIG.AuthToken}`,
                "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
              },
            }
          )
          .then((res) => {
            console.log(res.data.result);
            setRequest(res.data.result);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              localStorage.clear();
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            }
          });
      });
  };

  const renewSubscription = () => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      paymentTermID: parseInt(formik.values.paymentTermID),
      totalAmount: parseFloat(formik.values.amount),
      created_User: 0,
      id: selectedSubscriptionId,
    };

    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.RENEW_ACCOUNT, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        renewModalToggle();
        getList();
      });
  };

  const chooseSubscription = (id) => {
    formik.values.amount = "";
    formik.values.paymentTermID = 0;
    setSelectedSubscriptionId(id);
    renewModalToggle();
  };

  return (
    <div className="main-wrapper inner-wrapper d-flex">
      <Modal
        isOpen={renewModal}
        toggle={renewModalToggle}
        className="mt-10-rem property_info_modal mt-5"
      >
        <ModalHeader>Renew Subscription</ModalHeader>
        <ModalBody>
          <Row>
            <center>
              <FormFields.InputField
                label="Amount"
                name="amount"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={10}
              />
              <Col md={10} lg={10} className="mt-1-rem">
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Payment Method"
                >
                  <Form.Select
                    className="form_input form_input_color_change"
                    placeholder="Payment Method"
                    name="paymentTermID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={0}>Select</option>
                    <option value={1}>Monthly</option>
                    <option value={2}>Quarterly</option>
                    <option value={3}>Half Yearly</option>
                    <option value={4}>Yearly</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>

              <Col md={10} className="mt-4-rem">
                <Button
                  style={{ width: "100%", height: "50px" }}
                  onClick={renewSubscription}
                >
                  Submit
                </Button>
              </Col>
            </center>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={keyModal}
        toggle={keyModalToggle}
        className="mt-10-rem property_info_modal mt-5"
      >
        <ModalHeader>Generate Key</ModalHeader>
        <ModalBody>
          <Row>
            <center>
              <FormFields.InputField
                label="Amount"
                name="amount"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={10}
              />
              <Col md={10} className="mt-1-rem">
                <ButtonComponent.UploadButton
                  value="Payment Receipt"
                  onUploadHandle={receiptDocHandler}
                  filename={formik.values.receiptDocName}
                  document={formik.values.receiptDoc}
                />
              </Col>
              <Col md={10} className="mt-4-rem">
                <Button
                  style={{ width: "100%", height: "50px" }}
                  onClick={generateKey}
                >
                  Submit
                </Button>
              </Col>
            </center>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleIsModal}
        className="mt-10-rem property_info_modal mt-5"
      >
        <ModalBody>
          <Row>
            <center>
              <div className="landing_modal_contact mt-1-rem">
                Update Payment
              </div>
              <FormFields.InputField
                label="Amount"
                name="amount"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={10}
              />
              <Col md={10} className="mt-1-rem">
                <ButtonComponent.UploadButton
                  value="Payment Receipt"
                  onUploadHandle={receiptDocHandler}
                  filename={formik.values.receiptDocName}
                  document={formik.values.receiptDoc}
                />
              </Col>
              <Col md={10} className="mt-4-rem">
                <Button
                  style={{ width: "100%", height: "50px" }}
                  onClick={savePayment}
                >
                  Submit
                </Button>
              </Col>
            </center>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={viewModal}
        toggle={toggleView}
        className="modal-dialog-custom-width trade_existing_customer"
      >
        <ModalBody style={{ background: "#F9F9FF" }}>
          <Row className="p-2-rem subsScroll">
            <div className="mt-2-rem subs_over">SUBSCRIPTION DETAILS</div>
            <Card className="mt-1-rem br-10">
              <Card.Body>
                <Row className="p-1-rem">
                  <Col md={3}>
                    <div className="d-flex">
                      <div>{subscription.subscriptionAccountName}</div>
                      <div className="tradeTrsactionsid statBg ml-1-rem">
                        {subscription.subscriptionId}
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="font-weight-400 font-12">
                      SUBSCRIPTION DETAILS
                    </div>
                    <div className="d-flex font-weight-500 font-14">
                      <div className="">
                        {subscription.paymentTermID == 1 && "Monthly"}
                        {subscription.paymentTermID == 2 && "Quarterly"}
                        {subscription.paymentTermID == 3 && "Half Yearly"}
                        {subscription.paymentTermID == 4 && "Yearly"}
                      </div>
                      <div className="pl-10">{subscription.totalAmount}</div>
                    </div>
                  </Col>
                  <Col md={2} className="br-right">
                    <div className="font-weight-400 font-12">VALID TILL</div>
                    {Moment(subscription.endDate).format("DD/MM/YYYY")}
                  </Col>
                  <Col md={4}>
                    <div class="font-14 font-weight-600 d-flex">
                      <div>
                        {subscription.subscriptionAccountName}
                        <br />
                        {subscription.subscriptionAccountEmail}
                      </div>
                      <div className="d-flex align-items-center">
                        <span class="mx-3 font-weight-400 brd-color">|</span>
                        {subscription.mobileNo}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row className="p-1-rem mt-2-rem">
              <Tabs>
                <Card className="tabs-wrapper">
                  <TabList>
                    <Tab>Payments</Tab>
                    <Tab>Company Requests</Tab>
                  </TabList>
                </Card>
                <TabPanel>
                  <table className="table tCustom table-text mt-1-rem">
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>AMOUNT</th>
                        <th>RECEIPT</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {history != null &&
                        history.length > 0 &&
                        history.map((hist, k) => {
                          return (
                            <tr>
                              <td>{k + 1}</td>
                              <td>
                                {Moment(
                                  hist.subscriptionValidityStartDate
                                ).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {Moment(
                                  hist.subscriptionValidityEndDate
                                ).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                <NumberFormat
                                  value={hist.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix="USD "
                                />
                              </td>
                              <td>
                                {hist.receiptUpload != null && (
                                  <a
                                    download="Payment_Recipt"
                                    href={hist.receiptUpload}
                                  >
                                    Payment Receipt
                                  </a>
                                )}
                              </td>
                              <td>
                                {hist.is_PaymentDone == true ? (
                                  <>
                                    <div className="approved_badge">
                                      <img src="assets/images/check.svg" /> Paid
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="primary"
                                      className={
                                        "font-weight-500 font-14 rounded-13 "
                                      }
                                      onClick={() => {
                                        selectPayment(
                                          hist.accountSubscriptionHistoryID
                                        );
                                      }}
                                    >
                                      Make Payment
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel>
                  <table className="table tCustom table-text mt-1-rem">
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>COMPANY CODE</th>
                        <th>REQUESTED DATE</th>
                        <th>KEY</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {request != null &&
                        request.length > 0 &&
                        request.map((re, k) => {
                          return (
                            <tr>
                              <td>{k + 1}</td>
                              <td>{re.companyCode}</td>
                              <td>
                                {Moment(re.requestedDateTime).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )}
                              </td>
                              <td>
                                {re.activationKey != null ? (
                                  <>{re.activationKey}</>
                                ) : (
                                  <>
                                    <Button
                                      variant="primary"
                                      className={
                                        "font-weight-500 font-14 rounded-13 "
                                      }
                                      onClick={() => {
                                        selectRequest(re.requestedCompanyID);
                                      }}
                                    >
                                      Generate Key
                                    </Button>
                                  </>
                                )}
                              </td>
                              <td>
                                {re.amount} USD
                              </td>
                              <td>
                                {re.companyStatus == 1 && <span>Active</span>}
                                {re.companyStatus == 2 && <span>Deleted</span>}
                              </td>
                              <td>
                                {re.companyStatus != 2 && (
                                  <span>
                                    <ButtonComponent.DeleteButton
                                      clickEvent={() => {
                                        cancelCompany(re.requestedCompanyID);
                                      }}
                                      value="Delete"
                                    />
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </TabPanel>
              </Tabs>
            </Row>
          </Row>
        </ModalBody>
      </Modal>
      <ProSidebar className="sidebar-component desktop_view">
        <SidebarHeader className="mb-2-rem">
          <Link to="/superadmin">
            <img
              src="/assets/images/tmslanding/tmslogo.svg"
              className="img-fluid"
            />
          </Link>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem
              icon={<SideBarIcon.DashboardIcon />}
              className="menu-item-active"
            >
              <NavLink to="/superadmin">Dashboard</NavLink>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
      <div className="width-100l">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="mt-10-rem property_info_modal modal_35 mt-5 "
        >
          <ModalBody>
            <center>
              <div className="landing_modal_contact mt-1-rem">
                Add a subscription
              </div>
            </center>
            <Row className="d-flex justify-content-center mt-2-rem mb-5-rem">
              <FormFields.InputField
                label="Account Name"
                name="companyName"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="Account Email"
                name="subscriptionAccountEmail"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="Account Mobile No"
                name="companyMobile"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="User Full Name"
                name="subscriptionUserFullName"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="User Login Name"
                name="subscriptionUserName"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="User Mobile No"
                name="mobileNo"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <FormFields.InputField
                label="User Email"
                name="contactEmail"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <Col md={6} lg={6} className="mt-1-rem">
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Payment Method"
                >
                  <Form.Select
                    className="form_input form_input_color_change"
                    placeholder="Payment Method"
                    name="paymentTermID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={0}>Select</option>
                    <option value={1}>Monthly</option>
                    <option value={2}>Quarterly</option>
                    <option value={3}>Half Yearly</option>
                    <option value={4}>Yearly</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <FormFields.InputField
                label="Subscription Amount"
                name="totalAmount"
                classname="mt-1-rem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                md={6}
              />
              <Col md={10} className="mt-4-rem">
                <Button
                  style={{ width: "100%", height: "50px" }}
                  onClick={saveSubscription}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <div id="content" className="desktop_view ">
          <nav
            className="bg-white navbar navbar-expand-md  fixed-top"
            style={{ zIndex: "999" }}
          >
            <div className="container-fluid mx-3">
              <Col md={4} className="ps-3 "></Col>

              <Col md={8} className="d-flex col-6 justify-content-end">
                <Col md={1}>
               
                <div className='d-flex pe-md-5 pe-5 align-self-center'>
                    <Link to='/broadcast' className='d-flex'>
                        <img className='iconTopbar' src='../assets/images/broadcast.png' />&nbsp;<span className='font-14 color-23 d-flex align-items-end  d-none d-sm-block d-md-block'>Broadcast</span>
                    </Link>
                </div>
                </Col>
                <Col md={2} className=" userMobile ms-md-5 d-flex">
                  <Menus />
                </Col>
              </Col>
            </div>
          </nav>
        </div>
        <div className="d-flex inner-page-header justify-content-between">
          <div className="d-flex align-self-center">
            <img
              src="assets/images/pageicons/Dashboard.svg"
              className="imguserIcon"
            />
            <span className="font-16 font-weight-600 color-707895 d-flex align-items-end pl-10">
              DASHBOARD
            </span>
          </div>
          <div className="d-flex"></div>
        </div>
        <div className="inner-page-content">
          <div className=" p-md-4 br-8 scrollContracts">
            <Card className="col-md-3 br-10">
              <Card.Body>
                <div className="d-flex align-items-center">
                  <div
                    className="p-0_5-rem avatar d-flex align-items-center justify-content-center"
                    style={{ width: "40px", height: "40px", fontSize: "12px" }}
                  >
                    SA
                  </div>
                  <div className="pl-10">
                    <div className="good_mng">Good Morning,</div>
                    <div className="font-weight-600 font-18">Super Admin</div>
                  </div>
                </div>
                <div>
                  <div className="total_sub">Total Subscribers</div>
                  <div className="total_count">125</div>
                </div>
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-between col-md-10">
              <div className="mt-4-rem subs_over">SUBSCRIPTION OVERVIEW</div>
              <div>
                <AddNewButton
                  value="Add New Subscription"
                  class="bg-white "
                  clickEvent={toggle}
                />
              </div>
            </div>
            <Card className="col-md-10 br-10 mt-1-rem">
              <Card.Body>
                <table className="table tCustom table-text mt-1-rem">
                  <thead>
                    <tr>
                      <th>ORGANIZATION & SUBSCRIPTION ID</th>
                      <th>SUBSCRIPTION DETAILS</th>
                      <th>VALID TIL</th>
                      <th>CONTACT DETAILS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionList != null &&
                      subscriptionList.length > 0 &&
                      subscriptionList.map((list, k) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex">
                                <div>{list.subscriptionAccountName}</div>
                                <div className="tradeTrsactionsid statBg ml-1-rem">
                                  {list.subscriptionId}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {list.paymentTermID == 1 && "Monthly"}
                                {list.paymentTermID == 2 && "Quarterly"}
                                {list.paymentTermID == 3 && "Half Yearly"}
                                {list.paymentTermID == 4 && "Yearly"}
                              </div>
                              <div>{list.totalAmount}</div>
                            </td>
                            <td>{Moment(list.endDate).format("DD/MM/YYYY")}</td>
                            <td>
                              <div class="font-14 font-weight-600 d-flex">
                                <div>
                                  {list.subscriptionUserFullName}
                                  <br />
                                  {list.subscriptionAccountEmail}
                                </div>
                                <div className="d-flex align-items-center">
                                  <span class="mx-3 font-weight-400 brd-color">
                                    |
                                  </span>
                                  {list.mobileNo}
                                </div>
                              </div>
                            </td>
                            <td>
                              {list.renewalStatus == 1 ? (
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    chooseSubscription(list.subscriptionId);
                                  }}
                                >
                                  Renew
                                </Button>
                              ) : (
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => {
                                    selectSubscription(list.subscriptionId);
                                  }}
                                >
                                  <img src="assets/images/rightarrow.svg" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
