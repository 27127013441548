import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { useLocation } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import * as FormFields from "../../../components/formcomponents"
import { useMsal } from "@azure/msal-react";

export default function List(props) {
    const { instance } = useMsal();
    const location = useLocation()
    const [isFirst, setIsFirst] = useState(true);
    const [product, setProduct] = useState([]);
    const [productGrade, setProductGrade] = useState([]);
    const {type} = location.state
    const {id} = location.state
    useEffect(()=>{
        console.log(product)
    },[product])
    useEffect(()=>{
        if(isFirst){
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "id" : id
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setProduct(res.data.result[0]);
                getGradeList()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    },[isFirst])

    const getGradeList = ()=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    return (
        <div className='main-wrapper inner-wrapper d-flex'>
          
        <Sidebar id={1}/>
        <div className='width-100'>
            <Navbar/>
            <div className='d-flex inner-page-header justify-content-between'>
                <div className='d-flex'>
                    {type == 'trace'?
                        <>
                            <img src='assets/images/sidebaricons/Dashboard.svg' className='img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>TRACE ELEMENTS</span>
                        
                        </>
                        :
                        <>
                            <img src='assets/images/sidebaricons/Dashboard.svg' className='img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>QUALITY PARAMETERS</span>
                        </>

                    }
                </div>
            </div>
            <div className='inner-page-content'>
                
                <div className='add-edit-wrapper'>
                    <Card className='p-2-rem col-md-11'>
                        <Card.Header style={{background: '#fff'}}>
                            <FormFields.InputField
                                md={4}
                                val={product.productName}
                                disabled={true}
                            />
                        </Card.Header>
                    </Card>
                </div>
            </div>
        </div>
        
    </div>
    )
}
