import React from 'react'
import styles from './Trade.module.css'
function index() {
  return (
    <>
        <div className = {`${styles.trendContainer}`}>
            <div className = {`${styles.trendHeader}`}>
              <div className = 'd-flex align-items-center justify-content-between '>
                  <div className = 'd-flex flex-column'>
                    <div className = {` ${styles.trendsHeading}`}>
                      3,945
                    </div>
                    <div className = {`mt-2 ${styles.subtext}`}>
                      closed trades
                    </div>
                  </div>
                  <div className = 'd-flex align-items-center'>
                    <div>
                      10.3%
                    </div>
                    <div className = {` ms-2 ${styles.lossIcon}`}>
                      <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' />
                    </div>
                  </div>
              </div>
              <div className = 'd-flex justify-content-between align-items-center mt-3'>
           
                  <div className = 'd-flex align-items-center'>
                      <div className = 'd-flex flex-column'>
                        <div className = {`mt-2 ${styles.subtext}`}>
                          total materials
                        </div>
                        <div className = {`mt-2 ${styles.trendsNewTradeAmount}`}>
                          69,7369 MT
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className = 'd-flex align-items-center'>
                <div>
                    <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' />
                </div>
                <div className = 'd-flex flex-column ms-3'>
                    <div className = {`${styles.title}`}>
                        new trades
                    </div>
                    <div className = {`${styles.text}`}>
                        256
                    </div>
                </div>
            </div>
            <div className = 'd-flex justify-content-around  mt-4'>
                <div className = 'd-flex flex-column '>
                    <div className = {`${styles.title}`}>
                        Trades in progress
                    </div>
                    <div className = {`${styles.otherText}`}>
                        35
                    </div>
                </div>
                <div className = 'd-flex flex-column a' >
                    <div className = {`${styles.title}`}>
                        Total materials
                    </div>
                    <div className = {`${styles.otherText}`}>
                        69,7369 MT
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default index