import React, { useEffect, useState } from 'react'
import { Button } from "react-bootstrap"
import FileBase64 from 'react-file-base64';


export const LoginButton = (props) => {
    return (
        <Button className='login-button' type="button" onClick={props.clickEvent}>{props.value}</Button>
    )
}

export const AddNewButton = (props) => {
    return (
        <Button variant="outline-primary" size="lg" className={'d-flex align-items-center font-weight-500 font-14 rounded-13 ' + props.class} type="button" onClick={props.clickEvent} style={{ width: props.width }}>
            <div className="d-flex"> <img className="img-icn" src='assets/images/AddButtonIcon.svg' /> <span className="ps-1 d-none d-md-block d-lg-block">{props.value}</span></div>
        </Button>
    )
}

export const AddNewButtonBlue = (props) => {
    return (
        <Button variant="primary" size="lg" style={{ width: props.width }} onClick={props.clickEvent} disabled={props.disabled} className={'font-weight-500 font-14 rounded-13 ' + props.class}>
            <div> <img className="img-icn" src='assets/images/AddButtonIconWhite.svg' /> <span className="ps-1">{props.value}</span></div>
        </Button>
    )
}


export const UploadButton = (props) => {
    const [filename, setFileName] = useState()
    useEffect(() => {
        if (props.filename != null) {
            if (props.filename.length > 5) {
                let fileName = props.filename.slice(0, 5) + '...' + props.filename.slice(props.filename.length, -1)
                setFileName(fileName)
            }
        }

    }, [props])
    useEffect(() => {

    }, [filename])
    return (
        <>
            <div>
                <label className={'upload-document-button font-12 font-weight-600 d-flex justify-content-center align-items-center color-707895 cursor-pointer '}>
                    <div className=" ">
                        <div className='d-flex align-items-end '>
                            {props.value}
                            <div className='mx-2 d-flex align-items-end uploadText '>
                                <img src='assets/images/attachfileicon.svg' />
                                {props.isEdit ? 'Update' : props.filename == '' ? 'Attach' : 'Attached'}
                            </div>
                            <FileBase64 className="form_input" onDone={props.onUploadHandle} multiple={props.multiple} disabled={props.disabled} />
                        </div>
                    </div>

                    <FileBase64 className="form_input" onDone={props.onUploadHandle} multiple={props.multiple} disabled={props.disabled} />
                </label>
            </div>
            <div>
                
                {(props.filename != "" && props.filename != null) == true ?
                props.multiple == true && props.list.length > 0 ?
                props.list.map((values,k)=>(
                    <a href={values.base64} download={values.name} className='mt-1-rem py-3 font-weight-600 font-13'><img src='assets/images/sidebaricons/downloads.svg' className='mr-3' style={{ width: '16px' }} />&nbsp;{values.name}, </a>
                ))
                :
                    <a href={props.document} download={props.filename} className='mt-1-rem py-3 font-weight-600 font-13'><img src='assets/images/sidebaricons/downloads.svg' className='mr-3' style={{ width: '16px' }} />&nbsp;{props.filename}</a>
                    :
                    ''
                }
                
            </div>
        </>
    )
}


export const BlueBorderButton = (props) => {
    return (
        <Button variant="outline-secondary" size="lg" id={props.id} className={'font-weight-500 font-14 rounded-13'} type="button" onClick={props.clickEvent} disabled={props.disabled}>
            {props.value}
        </Button>
    )
}

export const BlueButton = (props) => {
    return (
        <Button variant="primary" size="lg" style={{ width: props.width }} onClick={props.clickEvent} disabled={props.disabled} className={'font-weight-500 font-14 rounded-13 ' + props.class}>
            {props.value}
        </Button>
    )
}

export const RedButton = (props) => {
    return (
        <Button variant="danger" size="lg" style={{ width: props.width }} onClick={props.clickEvent} disabled={props.disabled} className={'font-weight-500 font-14 rounded-13 ' + props.class}>
            {props.value}
        </Button>
    )
}

export const CancelButton = (props) => {
    return (
        <Button variant="secondary" size="lg" className='font-weight-500 font-14' onClick={props.clickEvent}>
            {props.value}
        </Button>
    )
}

export const SelectExistingButton = (props) => {
    return (
        <Button variant="secondary" className='' onClick={props.clickEvent}>
            {props.value}
        </Button>
    )
}

export const BackButton = (props) => {
    return (
        <buttom className='back-button' onClick={props.clickEvent}>
            <img src='/assets/images/supports/backButton.svg' />
        </buttom>
    )
}

export const PermissionsButton = (props) => {
    return (
        <Button variant="outline-secondary" size="lg" className={'d-flex align-items-center font-weight-500 font-14 rounded-13 ' + props.class} type="button" onClick={props.clickEvent} style={{ width: props.width }}>
            <div><span>{props.value}</span> <img className="img-icn ms-2" src='assets/images/rolePermissionsArroweIcon.svg' /></div>
        </Button>
    )
}

export const EditButton = (props) => {
    return (
        <>
            <div>
                <button className='edit-button' type="button" id={props.id} onClick={props.clickEvent} style={{ width: props.width }}>
                    <div>
                        <img src='/assets/images/supports/edit-button-icon.svg' alt='edit-icon' />
                    </div>
                </button>
            </div>
        </>
    )
}

export const EditButtonAccount = (props) => {
    return (
        <>
            <div>
                <button className='edit-button-account d-flex' type="button" id={props.id} onClick={props.clickEvent} style={{ width: props.width }}>
                    <div>
                        <img src='/assets/images/supports/edit-button-icon.svg' alt='edit-icon' />
                    </div>
                    <div className='mx-2'>
                        Edit
                    </div>
                </button>
            </div>
        </>
    )
}

export const DeleteButton = (props) => {
    return (
        <>
            <div>
                <button className='delete-button' type="button" id={props.id} onClick={props.clickEvent} style={{ width: props.width }}>
                    <div className='d-flex'>
                        <img src='/assets/images/supports/delete-button-icon.svg' className='delete-button-img' alt='delete-icon' />
                    </div>
                </button>
            </div>
        </>

    )
}