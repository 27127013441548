import React, { useEffect, useState } from "react";
import * as CONFIG from "../../../../helpers/config";
import axios from "axios";
import Moment from "moment";
import { useFormik } from "formik";
import { useMsal } from "@azure/msal-react";
import {
  Col,
  Row,
  Accordion,
  Form,
  Button,
  Card,
  FloatingLabel,
} from "react-bootstrap";
import * as FormComponent from "../../../../components/formcomponents";
import * as ButtonComponent from "../../../../components/buttoncomponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function GetSupplier() {
  const { instance } = useMsal();
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [CustomerList, setCustomerList] = useState([]);
  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = () => {
    let json_list = {
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
    };
    axios
      .post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_LIST, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setCustomerList(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  };
  return (
    <>
      {CustomerList.map((list, k) => {
        return (
          <>
            <option value={list.supplierID}>{list.suppliername}</option>
          </>
        );
      })}
    </>
  );
}

export function GetTradeListById(props) {
  const { instance } = useMsal();
  const MySwal = withReactContent(Swal);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);

  useEffect(() => {
    getList();
  }, [props]);

  const getList = () => {
    const json_list = {
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
      id: parseInt(props.id), // CustomerID
    };
    axios
      .post(
        CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADELIST_BY_SUPPLIER,
        json_list,
        {
          headers: {
            Authorization: `Bearer ${CONFIG.AuthToken}`,
            "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(res.data.result);
        setInvoiceList(res.data.result);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  };
  return (
    <>
      {invoiceList.map((list, k) => {
        return (
          <>
            <option value={list.tradeNo}>{list.tradeNo}</option>
          </>
        );
      })}
    </>
  );
}

export default function Index() {
  const { instance } = useMsal();
  const MySwal = withReactContent(Swal)
  const [customerId, setCustomerId] = useState(0);
  const [doc, setDoc] = useState(false);

  const formik = useFormik({
    initialValues: {
      customerID: 0,
      type: 0,
      referenceNo: "",
      amount: 0,
      docName: "",
      documentUpload: "",
    },
  });

  const getInvoiceList = (e) => {
    const id = e.target.value;
    setCustomerId(id);
  };
  useEffect(() => {
    console.log(customerId);
  }, [customerId]);

  const selectInvoiceNo = (e) => {
    formik.values.referenceNo = e.target.value;
    setDoc(!doc);
  };

  const handleDoc = (file) => {
    formik.values.docName = file.name;
    formik.values.documentUpload = file.base64;
    setDoc(!doc);
  };

  const handleSubmit = () => {
    const json_list = {
      type: parseInt(formik.values.type),
      supplierID: parseInt(customerId),
      referenceNo: formik.values.referenceNo,
      amount: parseFloat(formik.values.amount),
      documentUpload: formik.values.documentUpload,
      is_ForCustomer: false,
      instanceID: CONFIG.PMSInstanceID,
      accountSubscriptionID: CONFIG.AccountSubscriptionID,
      created_User: CONFIG.LoginID,
    };
    console.log(json_list);
    axios
      .post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_DEBIT_CREDIT, json_list, {
        headers: {
          Authorization: `Bearer ${CONFIG.AuthToken}`,
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        MySwal.fire({
          icon: "success",
          title: parseInt(formik.values.type) == 1 ? 'Debit Note added sucessfully':'Credit Note added sucessfully',
          confirmButtonText: "Okay",

          confirmButtonClass: "btn btn-primary",
          backdrop: `
        rgba(60, 60, 60, 0.5)
        no-repeat
  `,
        }).then(() => {
          window.location.href = "/suppliercreditdebit";

        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <FormComponent.InputSelect
                label="Select Type"
                classname="mt-1-rem"
                name="type"
                optionField={
                  <>
                    <option value={1}>Debit</option>
                    <option value={2}>Credit</option>
                  </>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col md={4}>
              <FormComponent.InputSelect
                label="Select Supplier"
                classname="mt-1-rem"
                optionField={<GetSupplier />}
                onChange={getInvoiceList}
                onBlur={getInvoiceList}
              />
            </Col>
            <Col md={4}>
              <FormComponent.InputSelect
                label="Select Trade"
                classname="mt-1-rem"
                optionField={<GetTradeListById id={customerId} />}
                onChange={selectInvoiceNo}
                onBlur={selectInvoiceNo}
              />
            </Col>
            <Col md={4}>
              <FormComponent.InputField
                label="Amount"
                classname="mt-1-rem"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col md={3} className="pt-1-rem align-items-center">
              <ButtonComponent.UploadButton
                value="Debit/Credit Note"
                onUploadHandle={handleDoc}
                filename={formik.values.docName}
                document={formik.values.documentUpload}
              />
            </Col>
            <Col md={3} className="pt-1-rem align-items-center">
              <ButtonComponent.BlueButton
                value="Submit"
                clickEvent={handleSubmit}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
