import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import {Row, Col} from "react-bootstrap";

export default function Slist(props) {

    console.log(props, 'slist')
    // useEffect(()=>{
    //     let test = props.serialNumber
    //     test++
    //     props.handleSerialNumber(test)
    // },[])
    const [month, setmonth] = useState('')
    const [isMonth, setIsMonth] = useState(false)

    useEffect(()=>{
        console.log('asas')
        if(props.list.tradeEnquiryProductDetailID != null){
            let month = key_pair.filter(x=> x.month == props.list.month)
            console.log(month)
            setmonth(month[0].monthName)
            setIsMonth(true)
        }
    },[props])
    const key_pair  = [
        {
            month : 1,
            monthName : "Janurary"
        },
        {
            month : 2,
            monthName : "February"
        },
        {
            month : 3,
            monthName : "March"
        },
        {
            month : 4,
            monthName : "April"
        },
        {
            month : 5,
            monthName : "May"
        },
        {
            month : 6,
            monthName : "Jun"
        },
        {
            month : 7,
            monthName : "July"
        },
        {
            month : 8,
            monthName : "August"
        },
        {
            month : 9,
            monthName : "September"
        },
        {
            month : 10,
            monthName : "October"
        },
        {
            month : 11,
            monthName : "November"
        },
        {
            month : 12,
            monthName : "December"
        }
        
    ]

    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.list.key} onClick={props.editList}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.list.key} onClick={props.remove}>Remove</span>
                ),
            }
          ]}
        />
    );
    return (
            <div className='customer_supplier_row font-weight-600 font-13 color-707895 d-flex justify-content-between align-items-center border_table px-4'>
  
                                    <>
                                    <Col md={2}>{props.serialNumber}</Col>
                                    <Col md={2}>{props.yearList != null ? props.yearList : props.list.year}</Col>
                                    <Col md={2}>{month != null && month != '' ? month : props.monthList != null ? props.monthList : key_pair[props.list.month-1].monthName }</Col>
                                    <Col md={2}>{props.list.quantity}</Col>
                                    <Col md={2}>{props.list.supplyStatus == 1 ? 'Initiated':'Scheduled'}</Col>
                                    <Col md={1}>
                           
                                    </Col>
                                    </>
                                    
                                  
        </div>
    )
}
