import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import Moment from "moment"
import { Row, Col, Form } from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import * as ButtonComponent from "../../../../components/buttoncomponents"
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Addresscomponent from '../../../../components/addresscomponent'
import { CompanyType, CountryList, PaymentTypeList, CustomerCategoryList, IncotermsList, SofList, StateList } from "../../../../helpers/API/Api"
import * as Yup from 'yup';


const CustomerSchema = Yup.object().shape({
    customerShortCode: Yup.string()
      .min(4, 'Customer Short Code cannot be lower than 4 characters')
      .max(11, 'Customer Short Code cannot be larger than 10 characters')
      .required('Please Enter Customer Short Code'),
      customerName: Yup.string()
      .min(3, 'Customer Short Code cannot be lower than 3 characters')
      .required('Please Enter Customer Short Code'),
});



export default function Generalform(props) {
    console.log(props, 'general form')
    const [isError, setIsError] = useState(true);
    const [isFirst, setIsFirst] = useState(true)
    const [activeStatus, setActiveStatus] = useState(true);
    const [isBusinessAddress, setIsBusinessAddress] = useState(false);
    const [isShipmentAddress, setIsShipmentAddress] = useState(false);
    const [DateOfIncorporation, SetDateOfIncorporation] = useState(false);
    const [registeredAddress, setRegisteredAddress] = useState([])

    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isCountryChangedBusiness, setIsCountryChangedBusiness] = useState(false);
    const [isCountryChangedShipment, setIsCountryChangedShipment] = useState(false);


    const [cntID, setCntID] = useState(0);
    const [cntIDBusiness, setCntIDBusiness] = useState(0);
    const [cntIDShipment, setCntIDShipment] = useState(0);

    const [countryerror, setCountryError] = useState(false)
    const [countryerrorBusiness, setCountryErrorBusiness] = useState(false)
    const [countryerrorShipment, setCountryErrorShipment] = useState(false)

    const [incorporationDocumentName, setincorporationDocumentName] = useState('');
    const [customerVATDocumentName, setcustomerVATDocumentName] = useState('');
    


    useEffect(() => {
        console.log('testing update', formik.values)
        if (props.isNextClicked) {
            console.log('next clicked')
            formik.values.registeredCountryID = formik.values.registeredCountryID == null ? formik.values.registeredCountryID : parseInt(formik.values.registeredCountryID)
            formik.values.RegisteredStateName = formik.values.RegisteredStateName
            formik.values.businessCountryID = formik.values.businessCountryID == null ? formik.values.businessCountryID : parseInt(formik.values.businessCountryID)
            formik.values.BusinessStateName = formik.values.BusinessStateName
            formik.values.shipmentCountryID = formik.values.shipmentCountryID == null ? formik.values.shipmentCountryID : parseInt(formik.values.shipmentCountryID)
            formik.values.ShipmentStateName = formik.values.ShipmentStateName

            props.saveGeneral(formik.values)
        }
        console.log(props, ' inside general form components', props.isEdit,)
    })
    useEffect(() => {
        console.log(isFirst)
    }, [isFirst])
    const formik = useFormik({
        initialValues:
        {
            customerCode: '',
            customerShortCode: '',
            customerName: '',
            companyTypeID: 0,
            registrationNo: '',
            incorporationDate: 0,
            hqCountryID: 0,
            paymentTypeID: 0,
            paymentTerms: '',
            customerCategoryID: 0,
            incotermsID: 0,
            customerVATDocumentNumber: '',
            customerVATDocument: '',
            incorporationDocument: '',

            registeredUnitNo: '',
            registeredBuildingName: '',
            registeredStreetName: '',
            registeredCityName: '',
            registeredCountryID: null,
            RegisteredStateName: null,
            registeredPostBox: '',

            telephoneNo: '',
            fax: '',
            website: '',
            email: '',

            businessUnitNo: '',
            businessCityName: '',
            businessBuildingName: '',
            businessStreetName: '',
            businessCityName: '',
            businessCountryID: null,
            BusinessStateName: null,
            businessPostBox: '',

            lmoOffice: '',
            finalDestination: '',
            shipTo: '',

            shipmentUnitNo: '',
            shipmentBuildingName: '',
            shipmentStreetName: '',
            shipmentCityName: '',
            shipmentCountryID: null,
            ShipmentStateName: null,
            shipmentPostBox: '',

        },
        validationSchema:CustomerSchema,
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;

          
            if (!values.customerName) {
                errors.customerName = "Please enter customer name"
                
            }
           
            if (!values.registrationNo) {
                errors.registrationNo = "Please Enter Registration No"
                
            }
            if (!values.paymentTerms) {
                errors.paymentTerms = "Please Enter Payment Terms"
                
            }
            if (!phone.test(values.telephoneNo) && values.telephoneNo){
                errors.telephoneNo = 'Please enter a valid Telephone Number'
            }
            if (!values.customerVATDocumentNumber){
                errors.customerVATDocumentNumber = 'Please enter a TIN/VAT/IEC number'
            }
            if (!phone.test(values.registeredPostBox) && values.registeredPostBox){
                errors.registeredPostBox = 'Please enter a valid post box number'
            }
           
            if (values.companyTypeID == 0) {
                errors.companyTypeID = "Please select a value"
        
            }

            if (values.customerCategoryID == 0) {
                errors.customerCategoryID = "Please select a value"
           
            }

            if (values.incotermsID == 0) {
                errors.incotermsID = "Please select a value"
            }

            if(values.incorporationDate == null || values.incorporationDate == 0){
                errors.incorporationDate = "Please select a date"
            }


            
            if(!values.registeredBuildingName){
                errors.registeredBuildingName = "Please enter building name"
            }

            if(!values.registeredUnitNo){
                errors.registeredUnitNo = "Please enter unit number"
            }

            if(!values.registeredStreetName){
                errors.registeredStreetName = "Please enter street name"
            }
            
            if(!values.registeredCityName){
                errors.registeredCityName = "Please enter city name"
            }

            if(!values.registeredStateName){
                errors.registeredStateName = "Please enter state name"
            }


            if(values.registeredCountryID == 0){
                errors.registeredCountryID = "Please select the country"
            }
       
            if (!values.email) {
                errors.email = 'Invalid email address';
            }
            setIsError(true)
            sendBack();
            return errors;
        }
    })

    useEffect(() => {
        if (props.isEdit && isFirst ) {
            Object.assign(formik.values, props.dataForEdit.dataForEdit.customerdata)
        }
        else if(props.isBackClicked == true){
            console.log('check back button in general form', props.general)
             Object.assign(formik.values, props.general)
        }
        setIsFirst(false);

    },[])

    const sendBack = () => {

        if ((Object.keys(formik.errors).length == 0) ) {
            if(isError == true){
                console.log(formik.errors)
                console.log('error')
                props.setErrors()
            }
            console.log('error checking')
            if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                props.handleError()    
            }
            else if(formik.initialValues == formik.values){
                console.log(formik.errors)
                console.log('error')
                props.setErrors()
            }
            else{
                setIsError(false)
                console.log(formik.errors)
                console.log('no error first condition')
                props.handleError()    
            }
              
        }
        else if(formik.values.incorporationDate == 0){
            console.log('date error')
            props.setErrors()
        }
        else{
            console.log(formik.errors)
            console.log('error')
            props.setErrors()
        }
    }

    const setData = (data, prefix) => {
        console.log(data, 'daata')
        console.log(prefix)
        if (prefix == 'registered') {
            formik.values.registeredUnitNo = data.registeredUnitNo
            formik.values.registeredBuildingName = data.registeredBuildingName
            formik.values.registeredStreetName = data.registeredStreet
            formik.values.registeredCityName = data.registeredCity
            formik.values.registeredCountryID = parseInt(data.country_val)
            formik.values.RegisteredStateName = data.state_val
            formik.values.registeredPostBox = data.registeredPoBox
            console.log(formik.values)
            setRegisteredAddress(data)
        }
        else if (prefix == 'business') {
            formik.values.businessUnitNo = data.businessUnitNo
            formik.values.businessBuildingName = data.businessBuildingName
            formik.values.businessStreetName = data.businessStreet
            formik.values.businessCityName = data.businessCity
            formik.values.businessCountryID = parseInt(data.country_val)
            formik.values.BusinessStateName = parseInt(data.state_val)
            formik.values.businessPostBox = data.businessPoBox
        }
        else if (prefix == 'shipment') {
            formik.values.shipmentUnitNo = data.shipmentUnitNo
            formik.values.shipmentBuildingName = data.shipmentBuildingName
            formik.values.shipmentStreetName = data.shipmentStreet
            formik.values.shipmentCityName = data.shipmentCity
            formik.values.shipmentCountryID = parseInt(data.country_val)
            formik.values.ShipmentStateName = parseInt(data.state_val)
            formik.values.shipmentPostBox = data.shipmentPoBox
        }
    }
    const incorporationDocumentHandler = (file) => {
        formik.values.incorporationDocument = file.base64;
        formik.values.incorporationDocumentName = file.name;
        console.log('file.base64')
        var fileName = ''
        console.log(file)
        console.log(file.name.length)
        console.log(file.type)
        if(file.name.length > 5){
            console.log(file.name.slice(0, (file.name.length - 1)))
            fileName = file.name.slice(0, 5) + '...' + file.name.slice(file.name.length, -1)
            console.log(fileName)
        }
        setincorporationDocumentName(fileName)
        console.log(file.name)

    }

    const customerVATDocumentHandler = (file) => {
        formik.values.customerVATDocument = file.base64;
        formik.values.customerVATDocumentName = file.name
        var fileName = ''
        if(file.name.length > 5){
            fileName = file.name.slice(0, 5) + '...' + file.name.slice(file.name.length, -1)
            console.log(fileName)
        }
        setcustomerVATDocumentName(fileName)
    }
    const setSwitchStatus = () => {
        setActiveStatus(!activeStatus)
    }

    const setBusinessAddressStatus = () => {
        setIsBusinessAddress(!isBusinessAddress)
    }

    const setShipmentAddressStatus = () => {
        setIsShipmentAddress(!isShipmentAddress)
    }

    const handleDOC = (date) => {
        if(date == null){
            formik.values.incorporationDate = false
        }
        else{
            formik.values.incorporationDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
            props.handleError()
            sendBack()    
        }
        SetDateOfIncorporation(date)
    }



    const onCountrySelect = (e) => {
        setIsCountryChanged(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntID(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryError(false)
            setTimeout(() => {
                setIsCountryChanged(true)
            }, 800);
        }
        else {
            setCountryError(true)
        }
        generalcountrySelected(value)
    }



    const onCountrySelectBusiness = (e) => {
        setIsCountryChangedBusiness(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntIDBusiness(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryErrorBusiness(false)
            setTimeout(() => {
                setIsCountryChangedBusiness(true)
            }, 800);
        }
        else {
            setCountryErrorBusiness(true)
        }
        
    }



    const onCountrySelectShipment = (e) => {
        setIsCountryChangedShipment(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntIDShipment(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryErrorShipment(false)
            setTimeout(() => {
                setIsCountryChangedShipment(true)
            }, 800);
        }
        else {
            setCountryErrorShipment(true)
        }
    }



   


    const generalcountrySelected = (value) => {
        if (value != 0) {
            formik.values.registeredCountryID = value
            setTimeout(() => {
                sendBack()
            }, 800);
        }
    }

 
    const businesscountrySelected = (value) => {
        formik.values.businessCountryID = value
    }

    const businessstateSelected = (value) => {
        formik.values.BusinessStateName = value;
    }

    const shippingcountrySelected = (value) => {
        formik.values.General_Shipping_CountryID = value
    }

    const shippingstateSelected = (value) => {
        formik.values.General_Shipping_StateID = value;
    }

    return (

        <Row className='d-flex'>
            <Form >
                <Row>

                    <FormFields.InputField
                        md={3}
                        name='customerName'
                        label='Customer Name*'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.customerName}
                        errors={formik.touched.customerName ? formik.errors.customerName : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputField
                        md={3}
                        name='customerShortCode'
                        label='Customer Short Code*'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.customerShortCode}
                        errors={formik.touched.customerShortCode ? formik.errors.customerShortCode : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='companyTypeID'
                        label='Company Type*'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <CompanyType 
                                companyTypeID={props.dataForEdit.dataForEdit.customerdata.companyTypeID} 
                            /> 
                            : 
                            props.isBackClicked ? 
                            <CompanyType 
                                companyTypeID={formik.values.companyTypeID} 
                            /> 
                            :
                            <CompanyType 
                            />}
                        val={formik.values.companyTypeID}
                        errors={formik.touched.companyTypeID ? formik.errors.companyTypeID : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                    />
                    <FormFields.InputField
                        md={3}
                        name='registrationNo'
                        label='Registration No*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.touched.registrationNo ? formik.errors.registrationNo : ''}
                        val={formik.values.registrationNo}
                    />
                    <FormFields.DatePick
                        md={3}
                        label='Date of incorporation*'
                        name='incorporationDate'
                        classname='pt-1-rem iconDatePicker'
                        type='date'
                        handleCompletionDate={handleDOC}
                        isRequired={false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.incorporationDate}
                        errors={formik.touched.incorporationDate ? formik.errors.incorporationDate : ''}/>
                    <FormFields.InputSelect
                        md={3}
                        name='hqCountryID'
                        label='HQ Country*'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <CountryList 
                                cntid={props.dataForEdit.dataForEdit.customerdata.hqCountryID} 
                            /> 
                            : 
                            props.isBackClicked ? 
                            <CountryList 
                                cntid={formik.values.hqCountryID} 
                            /> 
                            :
                            <CountryList />}
                        val={formik.values.hqCountryID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='paymentTypeID'
                        label='Payment Type*'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <PaymentTypeList 
                                paymentTypeID={props.dataForEdit.dataForEdit.customerdata.paymentTypeID} 
                            />
                            : 
                            props.isBackClicked ? 
                            <PaymentTypeList 
                                paymentTypeID={formik.values.paymentTypeID} 
                            /> 
                            :
                            <PaymentTypeList />}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val = {formik.values.paymentTypeID}
                    />
                    <FormFields.InputField
                        md={3}
                        name='paymentTerms'
                        label='Payment Terms*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.touched.paymentTerms ? formik.errors.paymentTerms : ''}
                        val={formik.values.paymentTerms}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='customerCategoryID'
                        label='Customer Category*'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <CustomerCategoryList 
                                customerCategoryID={props.dataForEdit.dataForEdit.customerdata.customerCategoryID} 
                            />
                            : 
                            props.isBackClicked ? 
                            <CustomerCategoryList 
                                customerCategoryID={formik.values.customerCategoryID} 
                            /> 
                            :
                            <CustomerCategoryList />}
                        val={formik.values.customerCategoryID}
                        errors={formik.touched.customerCategoryID ? formik.errors.customerCategoryID : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='incotermsID'
                        label='Incoterms*'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <IncotermsList 
                                incotermsID={props.dataForEdit.dataForEdit.customerdata.incotermsID} 
                            />
                            : 
                            props.isBackClicked ? 
                            <IncotermsList 
                                incotermsID={formik.values.incotermsID} 
                            /> 
                            :
                            <IncotermsList />}
                        val={formik.values.incotermsID}
                        errors={formik.touched.incotermsID ? formik.errors.incotermsID : ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
              
                    <FormFields.InputField
                        md={3}
                        name='customerVATDocumentNumber'
                        label='TIN/VAT/IEC No*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.touched.customerVATDocumentNumber ? formik.errors.customerVATDocumentNumber : ''}
                        val={formik.values.customerVATDocumentNumber}
                    />
                    <Col md={3} className=' d-flex align-items-center pt-1-rem'>
                        <ButtonComponent.UploadButton value={formik.values.customerVATDocumentName!=null ? formik.values.customerVATDocumentName:'TIN/VAT/IEC'} onUploadHandle={customerVATDocumentHandler} filename={customerVATDocumentName}/>
                    </Col>
                    <Col md={3} className='d-flex align-items-center pt-1-rem'>
                        <ButtonComponent.UploadButton value={formik.values.incorporationDocumentName != null? formik.values.incorporationDocumentName :'Document of Incorporation'} onUploadHandle={incorporationDocumentHandler} filename={incorporationDocumentName} />
                    </Col>
                    <FormFields.InputField
                        md={3}
                        name='telephoneNo'
                        label='Telephone No'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.touched.telephoneNo ? formik.errors.telephoneNo : ''}
                        val={formik.values.telephoneNo}
                    />
                    <FormFields.InputField
                        md={3}
                        name='fax'
                        label='Fax'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.fax}
                    />
                    <FormFields.InputField
                        md={3}
                        name='website'
                        label='Website'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.website}
                    />
                    <FormFields.InputField
                        md={3}
                        name='email'
                        label='Email ID*'
                        classname='pt-1-rem'
                        type='email'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.email}
                        errors={formik.touched.email ? formik.errors.email : ''}
                    />
                </Row>

                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                    Registered Address
                </div>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='registeredUnitNo'
                        label='Unit No*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredUnitNo}
                        errors={formik.touched.registeredUnitNo ? formik.errors.registeredUnitNo : ''}
                        //isabled={props.isDisabled}
                    />
                    <FormFields.InputField
                        md={3}
                        name='registeredBuildingName'
                        label='Building Name*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredBuildingName}
                        errors={formik.touched.registeredBuildingName ? formik.errors.registeredBuildingName : ''}
                        //disabled={props.isDisabled}
                    />
                    <FormFields.InputField
                        md={3}
                        name='registeredStreetName'
                        label='Street Name*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredStreetName}
                        errors={formik.touched.registeredStreetName ? formik.errors.registeredStreetName : ''}
                        //disabled={props.isDisabled}
                    />
                    <FormFields.InputField
                        md={3}
                        name='registeredCityName'
                        label='City*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredCityName}
                        errors={formik.touched.registeredCityName ? formik.errors.registeredCityName : ''}
                        //disabled={props.isDisabled}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='registeredCountryID'
                        label='Country*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={onCountrySelect}
                        onBlur={onCountrySelect}
                        optionField={props.isEdit == true ?
                            <CountryList 
                                cntid={props.dataForEdit.dataForEdit.customerdata.registeredCountryID} 
                            /> 
                            : 
                            props.isBackClicked == true ? 
                            <CountryList 
                                cntid={formik.values.registeredCountryID} 
                            /> 
                            :
                            <CountryList />}
                        val={formik.values.registeredCountryID}
                        errors={formik.touched.registeredCountryID ? formik.errors.registeredCountryID : ''}
                        //disabled={props.isDisabled}
                    />
                    <FormFields.InputField
                        md={3}
                        name='registeredStateName'
                        label='State*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredStateName}
                        errors={formik.touched.registeredStateName ? formik.errors.registeredStateName : ''}
                    />
                   
                    <FormFields.InputField
                        md={3}
                        name='registeredPostBox'
                        label='Post Box*'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.registeredPostBox}
                        errors={formik.touched.registeredPostBox ? formik.errors.registeredPostBox : ''}
                        disabled={props.isDisabled}
                    />
                    
                </Row>
                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466 d-flex'>
                    <span>Business Address</span>

                    <span className=' d-flex align-items-center ms-3'>
                        <Form.Check type="checkbox" onChange={setBusinessAddressStatus}/>
                        <span className='pl-8 font-12 font-weight-400'>Business address if different other than registered address</span>
                    </span>
                </div>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='businessUnitNo'
                        label='Unit No'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.businessUnitNo}
                        disabled={!isBusinessAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='businessBuildingName'
                        label='Building Name'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.businessBuildingName}
                        disabled={!isBusinessAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='businessStreetName'
                        label='Street Name'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.businessStreetName}
                        disabled={!isBusinessAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='businessCityName'
                        label='City'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.businessCityName}
                        disabled={!isBusinessAddress}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='businessCountryID'
                        label='Country'
                        classname='pt-1-rem'
                        type='text'
                        onChange={onCountrySelectBusiness}
                        onBlur={onCountrySelectBusiness}
                        val={formik.values.businessCountryID}
                        optionField={props.isEdit == true ?
                            <CountryList 
                                cntid={props.dataForEdit.dataForEdit.customerdata.businessCountryID} 
                            />
                            : 
                            props.isBackClicked ? 
                            <CountryList 
                                cntid={formik.values.businessCountryID} 
                            /> 
                            :
                            <CountryList />}
                        disabled={!isBusinessAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='BusinessStateName'
                        label='State'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.BusinessStateName}
                        disabled={!isBusinessAddress}
                    />
                </Row>
                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                    Shipment Details
                </div>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='lmoOffice'
                        label='LMO Office'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.lmoOffice}

                    />
                    <FormFields.InputField
                        md={3}
                        name='finalDestination'
                        label='Final Destination'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.finalDestination}
                    />
                    <FormFields.InputField
                        md={3}
                        name='shipTo'
                        label='Ship To'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.shipTo}
                    />
                </Row>
                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466 d-flex'>
                    <span>Shipment Address</span>
                    <span className='pl-10 d-flex align-items-center'>
                        <Form.Check type="checkbox" onChange={setShipmentAddressStatus} />
                        <span className='pl-10 font-12 font-weight-400'>Shipment address if different other than registered address</span>
                    </span>
                </div>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='shipmentUnitNo'
                        label='Unit No'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.shipmentUnitNo}
                        disabled={!isShipmentAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='shipmentBuildingName'
                        label='Building Name'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.shipmentBuildingName}
                        disabled={!isShipmentAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='shipmentStreetName'
                        label='Street Name'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.shipmentStreetName}
                        disabled={!isShipmentAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='shipmentCityName'
                        label='City'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.shipmentCityName}
                        disabled={!isShipmentAddress}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='shipmentCountryID'
                        label='Country'
                        classname='pt-1-rem'
                        type='text'
                        onChange={onCountrySelectShipment}
                        onBlur={onCountrySelectShipment}
                        val={formik.values.shipmentCountryID}
                        optionField={props.isEdit == true ?
                            <CountryList 
                                cntid={props.dataForEdit.dataForEdit.customerdata.shipmentCountryID} 
                            />
                            : 
                            props.isBackClicked ? 
                            <CountryList 
                                cntid={formik.values.shipmentCountryID} 
                            /> 
                            :
                            <CountryList 
                            />}
                        disabled={!isShipmentAddress}
                    />
                    <FormFields.InputField
                        md={3}
                        name='ShipmentStateName'
                        label='State'
                        classname='pt-1-rem'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.ShipmentStateName}
                        disabled={!isShipmentAddress}
                    />
                      
                </Row>
            </Form>
        </Row>
    )
}
