import React from 'react'
import Moment from 'moment'
import styles from "./greetings.module.css"
import NumberFormat from 'react-number-format';

function Index(props) {
    
    const handleTimeGretting = () => {
        var currentHour = Moment().format("HH");
        const hour = Moment().hour();
        console.log(hour)
        if (hour > 17){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Evening
                </div>
            );
        }

         if (hour > 12){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Afternoon
                </div>
            );
         }

         return (
            <div className = {` ${styles.greetingHourText}`}>
                Good Morning
            </div>
        );
    }
  return (
    <>
                <div  className = {`${styles.greetingContainer}`}>
                    <div className = 'd-flex align-items-center'>
                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{background:'#FFCE22', backgroundColor:"#FFCE22", width: '50px', height: '50px', fontSize: '12px' }}>
                            <p className='font-16 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                        </div>
                        <div className = 'd-flex flex-column ms-2'>
                            {handleTimeGretting()}
                            <div className = {`${styles.greetingNameText}`}>
                                {localStorage.getItem('UserFullName')}
                            </div>
                        </div>
                    </div>
                    <div className = 'mt-4'>
                        <p className = {`mt-2 mb-0 ${styles.greetingEarnedProfitTitle}`}>
                            total shipments
                        </p>
                        <p className = {`mt-2 mb-0 ${styles.greetingEarnedProfitAmount}`}>
                            <NumberFormat value={props.shipmentDetails.shipmentCompletedCount != null ? props.shipmentDetails.shipmentCompletedCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                    </div>
                    <div className = 'row'>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-shipment-newshipment-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.shipmentDetails.ongoingShipmentCount != null ? props.shipmentDetails.ongoingShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        new shipments
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-shipment-preshipment-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.shipmentDetails.totalPreShipmentCount != null ? props.shipmentDetails.totalPreShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        pre-shipments
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.shipmentDetails.totalPostVesselFixtureCount != null ? props.shipmentDetails.totalPreShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        post fixtures vessel
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.shipmentDetails.totalInTrasitCount != null ? props.shipmentDetails.totalInTrasitCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        in transit
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.shipmentDetails.totalPreClosureCount != null ? props.shipmentDetails.totalPreClosureCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        pre-closure
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default Index