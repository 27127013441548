import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import axios from 'axios';
import * as CONFIG from "../../../../../../helpers/config";
import { GetShareHolderType } from '../../../../../../helpers/API/Api';
import { useMsal } from "@azure/msal-react";
export default function ListData(props) {
    const [shareholderType, setShareHolderType] = useState()
    const { instance } = useMsal();
    useEffect(()=>{
        axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_SHAREGOLDER_TYPE, {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            res.data.result.filter(x => x.shareholderTypeID == props.values.shareholderTypeID).map((values)=>(
                setShareHolderType(values.shareholderTypeName)   
            ))
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
                
            }
        })  
    },[shareholderType])
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key}  onClick={props.editBank}>Edit</span>
                ),
            },
            
          ]}
        />
      );
    return (
        <tr className='supplier_supplier_row font-weight-600 font-13 color-707895'>
            <td>{props.values.shareholderName}</td>
            <td>{props.values.shareholdingValue == null ? shareholderType : props.values.shareholdingValue }</td>
            <td>{props.values.shareholderNationality}</td>
            <td>{props.values.shareholdingPercentage} %</td>
            <td style={{top:'5px', position:'relative'}}>{props.is_Default &&
                    <span className='contact-form-primary-badge'>Primary</span>
                }
            </td>
            <td>
           
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>

                      <img src = '/assets/images/Dot_icon.svg' />

                  </Space>
                </a>
            </Dropdown>
            </td>
        </tr>
    )
}
