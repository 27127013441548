import React,{useEffect, useState} from 'react'
import {Row, Col, Form, Card } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, BlueBorderButton } from '../../../../components/buttoncomponents';
import * as FormFields from "../../../../components/formcomponents";
import { ContractTypeList, ModeOfTransportList, TradeIncotermsList} from "../../../../helpers/API/Api"

function ContractTermsTab(props) {
    const [error, setError] = useState([]);
    const [errorData, setErrorData] = useState([]);
    const [isdataset, setisdataset] = useState(false)
    console.log(props, 'inside contract terms page')
    const formik = useFormik({
        initialValues:
        {
            contractTypeID: props.iscontractavailable?props.contractterms.contractTypeID :0,
            modeOfTransportID: props.iscontractavailable?props.contractterms.modeOfTransportID :2,
            incotermsID: props.iscontractavailable?props.contractterms.incotermsID :0,
            loadPort: props.iscontractavailable? props.contractterms.loadPort :'',
            destinationPort: props.iscontractavailable?props.contractterms.destinationPort :props.destinationport,
          
        },
        validate:values=>{
            let errors={}
            if(!values.loadPort){
                errors.loadPort = "Please enter a load port"
            }
   
            return errors;
        }
    })
    
    const moveToNext = () =>{
        let err = validate()
        console.log(err)
        if(formik.values.loadPort == ''){

        }
        else if(!err){

            props.saveContract(formik.values)

        }
    }

    const validate = ()=>{
        let error = [],errorData = [];
        let if_error = false;
        console.log(formik.values.loadPort)
        if(formik.values.contractTypeID == 0){
            error["contractTypeID"] = true;
            errorData["contractTypeID"] = "Please select a contact type"
            if_error = true;
        }   
        else if(formik.values.contractTypeID != 0){
            error["contractTypeID"] = false;
            errorData["contractTypeID"] = ""
            if_error = false;
        }
        if(formik.values.loadPort == ''){
            error["loadPort"] = true;
            errorData["loadPort"] = "Please enter a load port"
            if_error = true;
        }
        else if(formik.values.loadPort != ''){
            error["loadPort"] = false;
            errorData["loadPort"] = ""
            if_error = false; 
        }
         if(formik.values.incotermsID == 0){
            error["incotermsID"] = true;
            errorData["incotermsID"] = "Please select a incoterms method"
            if_error = true;
        }   
        else if(formik.values.incotermsID != 0){
            error["incotermsID"] = false;
            errorData["incotermsID"] = ""
            if_error = false;
        }  
        else if(formik.values.modeOfTransportID != 0){
            if(formik.values.incotermsID == 0 || formik.values.contractTypeID == 0 || formik.values.loadPort == '' ){
                console.log('check')
                error["modeOfTransportID"] = true;
                //errorData["modeOfTransportID"] = "Please select a trasnportation mode"
                if_error = true;
            }
            else{
                error["modeOfTransportID"] = false;
                // errorData["modeOfTransportID"] = ""
                if_error = false;
            }
            console.log(if_error)
        }

        setError(error);
        setErrorData(errorData)
        return if_error
    }

    const changemode = (val)=>{
        formik.values.modeOfTransportID = parseInt(val)
        console.log(val)
        setisdataset(!isdataset)
    }
    return (
        <Row>
            <Card style={{background: 'transparent'}}>
                    <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                        <Card.Body className='form-card-body p-4 mb-2-rem' style={{ background: '#fff'}}>
                            <div className = 'd-flex justify-content-between align-items-center'>
                                <div className='font-16 font-weight-600 color-707895'>
                                    Contract Type
                                </div>
                            </div>
                            <div className = 'my-3 d-flex align-items-center'>
                            {<ContractTypeList onChange={formik.handleChange} name='contractTypeID' value={formik.values.contractTypeID}/>}
                            {error["contractTypeID"] &&
                                <span className='ms-2 input_error'>{errorData["contractTypeID"]}</span>
                            }
                            </div>
                            <div className = 'd-flex justify-content-between align-items-center'>
                                <div className='font-16 font-weight-600 color-707895'>
                                    Select Mode of Transport
                                </div>
                            </div>
                            <div className = 'my-3 d-flex align-items-center'>
                            {<ModeOfTransportList onChange={changemode} name='modeOfTransportID' value={formik.values.modeOfTransportID}/>}
                            </div>


                            <div className = 'd-flex justify-content-between align-items-center'>
                                <div className='font-16 font-weight-600 color-707895'>
                                    Select Incoterms
                                </div>
                            </div>
                            <div className = 'my-3 d-flex align-items-center'>
                            {<TradeIncotermsList onChange={formik.handleChange} name='incotermsID' value={formik.values.incotermsID}/>}
                            {error["incotermsID"] &&
                                <span className='ms-2 input_error'>{errorData["incotermsID"]}</span>
                            }
                            </div>

                            <div className = 'my-3'>
                                <div className = 'd-flex justify-content-between align-items-center'>
                                    <div className='font-16 font-weight-600 color-707895'>
                                        Port Details
                                    </div>
                                </div>
                                <Form>
                                        <Row>
                                            <FormFields.InputField
                                                md={3}
                                                name='loadPort'
                                                label='Loading Port*'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.loadPort}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                errors=   {error["loadPort"] &&
                                                <span className='ms-2 input_error'>{errorData["loadPort"]}</span>
                                            }
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='destinationPort'
                                                label='Destination Port'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.destinationPort}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card.Footer className='form-card-footer py-2-rem pt-4 d-flex'>
                        <span><BlueBorderButton value='Prev' clickEvent= {props.back}/></span>
                        <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext}/></span>
                    </Card.Footer>
            </Card>
        </Row>
    )
}

export default ContractTermsTab