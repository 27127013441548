import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import {Row, Col, Form, Card} from "react-bootstrap"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import { useFormik } from 'formik'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as CONFIG from "../../../helpers/config"
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";


export default function PreClosure(props) {
    const location = useLocation()
    const { instance } = useMsal();
    let navigate = useNavigate();
    const tradeData = props.tradeData
    const tab = props.tab
    const [doc1, setdoc1] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const MySwal = withReactContent(Swal)


    const formik = useFormik({
        initialValues:{ 
            "tradePreClosureCheckListID": 0,
            "is_CustomerInvoice": false,
            "customerInvoiceNo": "",
            "customerInvoiceNoDocument": "",
            "customerInvoiceNoDocumentName": "",
            "is_CustomerPaymentCompletionStatus": false,
            "customerPaymentRecieptNo": "",
            "customerPaymentDocument": "",
            "customerPaymentDocumentName": "",
            "is_SupplierInvoice": false,
            "supplierInvoiceNo": "",
            "supplierInvoiceDocument": "",
            "supplierInvoiceDocumentName": "",
            "is_SupplierPaymentCompletionStatus": false,
            "supplierPaymentRecieptNo": "",
            "supplierPaymentDocument": "",
            "supplierPaymentDocumentName": "",
        }
    })
    useEffect(()=>{
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PRE_CLOSURE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            if(res.data.result.length>0){
                Object.assign(formik.values, res.data.result[0])
                setdoc1(!doc1)
                setIsEdit(true)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[])
    const savecustomerinvoicedoc = (file)=>{
        formik.values.customerInvoiceNoDocument = file.base64
        formik.values.customerInvoiceNoDocumentName = file.name
        setdoc1(!doc1)
    }
    const customerpaymentdoc = (file)=>{
        formik.values.customerPaymentDocument = file.base64
        formik.values.customerPaymentDocumentName = file.name
        setdoc1(!doc1)
    }
    const supplierdoc = (file)=>{
        formik.values.supplierInvoiceDocument = file.base64
        formik.values.supplierInvoiceDocumentName = file.name
        setdoc1(!doc1)
    }
    const supplierypaymentdoc = (file) =>{
        formik.values.supplierPaymentDocument = file.base64
        formik.values.supplierPaymentDocumentName = file.name
        setdoc1(!doc1)
    }
    const savePreClosure = ()=>{
        const json_list ={
            "tradeID": tradeData.tradeID,
            "is_CustomerInvoice": formik.values.is_CustomerInvoice,
            "customerInvoiceNo": formik.values.customerInvoiceNo,
            "customerInvoiceNoDocument": formik.values.customerInvoiceNoDocument,
            "customerInvoiceNoDocumentName": formik.values.customerInvoiceNoDocumentName,
            "is_CustomerPaymentCompletionStatus": formik.values.is_CustomerPaymentCompletionStatus,
            "customerPaymentRecieptNo": formik.values.customerPaymentRecieptNo,
            "customerPaymentDocument": formik.values.customerPaymentDocument,
            "customerPaymentDocumentName": formik.values.customerPaymentDocumentName,
            "is_SupplierInvoice": formik.values.is_SupplierInvoice,
            "supplierInvoiceNo": formik.values.supplierInvoiceNo,
            "supplierInvoiceDocument": formik.values.supplierInvoiceDocument,
            "supplierInvoiceDocumentName": formik.values.supplierInvoiceDocumentName,
            "is_SupplierPaymentCompletionStatus": formik.values.is_SupplierPaymentCompletionStatus,
            "supplierPaymentRecieptNo":formik.values.supplierPaymentRecieptNo,
            "supplierPaymentDocument": formik.values.supplierPaymentDocument,
            "supplierPaymentDocumentName": formik.values.supplierPaymentDocumentName,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
          }
          if(isEdit){
            json_list.tradePreClosureCheckListID = formik.values.tradePreClosureCheckListID
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_PRE_CLOSURE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Pre-Closure information updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        cancelButtonText: 'Cancel'
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_PRE_CLOSURE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                props.refreshList()
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Pre-Closure information added successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        cancelButtonText: 'Cancel'
                    }
                ).then(() => {
                    navigate("/trade-transactions", {state:{ tab: tab }});
                });
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='w-100'>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>PRE-CLOSURE UPDATE</span>
                    </div>
                </div>
                <div className=' mx-4'>
                    <div className='add-edit-wrapper'>
                        <div className='d-flex justify-content-between'>
                            <Row className=' w-100'>
                                <Card style={{background:'#fff'}}>
                                    <Card.Body className='form-card-body mb-10-rem' style={{overflowY: 'scroll'}}>
                                        <Col md={12}>
                                            <Row className='border-bottom-1 p-2-rem'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_CustomerInvoice'
                                                        label='Customer Invoice.'
                                                        checked={formik.values.is_CustomerInvoice}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                        md={3}
                                                        name='customerInvoiceNo'
                                                        label='Invoice No'
                                                        classname='pt-1-rem'
                                                        type='text'
                                                        val={formik.values.customerInvoiceNo}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton
                                                        value='Document'
                                                        filename={formik.values.customerInvoiceNoDocumentName}
                                                        document={formik.values.customerInvoiceNoDocument}
                                                        onUploadHandle={savecustomerinvoicedoc} 
                                                        multiple={false}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 p-2-rem'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_CustomerPaymentCompletionStatus'
                                                        label='Customer Payment Completion Status.'
                                                        checked={formik.values.is_CustomerPaymentCompletionStatus}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}

                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                        md={3}
                                                        name='customerPaymentRecieptNo'
                                                        label='Receipt No'
                                                        classname='pt-1-rem'
                                                        type='text'
                                                        val={formik.values.customerPaymentRecieptNo}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}

                                                    />
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.customerPaymentDocumentName}
                                                        document={formik.values.customerPaymentDocument}
                                                        onUploadHandle={customerpaymentdoc} 
                                                        multiple={false}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 p-2-rem'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_SupplierInvoice'
                                                        label='Supplier Invoice.'
                                                        checked = {formik.values.is_SupplierInvoice}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                </Col>
                                                <FormFields.InputField
                                                        md={3}
                                                        name='supplierInvoiceNo'
                                                        label='Invoice No'
                                                        classname='pt-1-rem'
                                                        type='text'
                                                        val={formik.values.supplierInvoiceNo}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.supplierInvoiceDocumentName}
                                                        document={formik.values.supplierInvoiceDocument}
                                                        onUploadHandle={supplierdoc} 
                                                        multiple={false}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='border-bottom-1 p-2-rem'>
                                                <Col md={5} className='d-flex align-items-center'>
                                                    <Form.Check
                                                        className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                        type='checkbox'
                                                        name='is_SupplierPaymentCompletionStatus'
                                                        label='Supplier Payment Completion Status.'
                                                        checked={formik.values.is_SupplierPaymentCompletionStatus}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                </Col>  
                                                <FormFields.InputField
                                                        md={3}
                                                        name='supplierPaymentRecieptNo'
                                                        label='Receipt No'
                                                        classname='pt-1-rem'
                                                        type='text'
                                                        val = {formik.values.supplierPaymentRecieptNo}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    />
                                                <Col md={3} className='pt-1-rem'>
                                                    <ButtonComponent.UploadButton 
                                                        value='Document'
                                                        filename={formik.values.supplierPaymentDocumentName}
                                                        document={formik.values.supplierPaymentDocument}
                                                        onUploadHandle={supplierypaymentdoc} 
                                                        multiple={false}
                                                        isEdit={isEdit}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card.Body>
                                    <Card.Footer className='form-card-footer p-2-rem d-flex'>
                                        {/* <span><Link to='/trade-transactions' state={{tab:tab}}><BlueBorderButton value='Back'/></Link></span> */}
                                        <span className='pl-10'><BlueButton value='Save' clickEvent={savePreClosure}/></span>
                                    </Card.Footer>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
