import React from 'react'
import styles from './Counter.module.css'
function index(props) {
  return (
    <>
        <div className = {` ${styles.counterContainer}`}>
            <div className = ''>
                <div>
                    <img src = {props.img} alt = 'icon' />
                </div>
            </div>
            <div className = 'd-flex flex-column align-items-center mt-md-5 '>
                <div className = {` gil-regular ${styles.title}`}>
                    {props.title != null ? props.title : ''}
                </div>
                <div className = {` gil-semibold my-2 ${styles.text}`}>
                    {props.text}
                </div>
            </div>
        </div>
    </>
  )
}

export default index