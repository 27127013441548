import React from 'react'
import styles from './Trade.module.css'
import NumberFormat from 'react-number-format';

function Index(props) {
  const handlePercentageClosedTrades = () => {
    let difference = 0
    let product = 0
    
    if(parseInt(props.closedTradeDetails.closedTradeCountPrevious) > (parseInt(props.closedTradeDetails.closedTradeCount))){
      difference = parseInt(props.closedTradeDetails.closedTradeCountPrevious)- (((parseInt(props.closedTradeDetails.closedTradeCount)))) 
    }
    else{
      difference = (((parseInt(props.closedTradeDetails.closedTradeCount) - parseInt(props.closedTradeDetails.closedTradeCountPrevious))))
    }
   

    if(props.closedTradeDetails.closedTradeCountPrevious == 0 && difference != 0){
        product = parseInt(props.closedTradeDetails.closedTradeCountPrevious) / parseInt(difference)
        console.log(product)
    }
    else if(difference == 0 && props.closedTradeDetails.closedTradeCountPrevious == 0){
        product = 0
    }

    product = product * 100
    console.log(product)
    return(
      <>
        <div>
          {product}%
        </div>
        {(parseInt(props.closedTradeDetails.closedTradeCountPrevious) < (parseInt(props.closedTradeDetails.closedTradeCount))) == true ? 
         <div className = {` ms-2 ${styles.profitIcon}`}>
          <img src = '/assets/images/supports/dashboard-trends-profit-icon-1.svg' alt = 'icon' />
         </div>
         :
         <div className = {` ms-2 ${styles.lossIcon}`}>
          <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' />
         </div>
      }
        
      </>
    )
    //console.log(division)
  }
  return (
    <>
        <div className = {`pb-5 ${styles.trendContainer}`}>
            <div className = {`${styles.trendHeader}`}>
              <div className = 'd-flex align-items-center justify-content-between '>
                  <div className = 'd-flex flex-column'>
                    <div className='gil-semibold'>
                     Trades
                    </div>
                    <div className = {`mt-2 ${styles.trendsHeading}`}>
                      <NumberFormat className='gil-regular' value={props.closedTradeDetails.closedTradeCount} displayType={'text'} thousandSeparator={true}  />
                    </div>
                    <div className = {`gil-semibold mt-2 ${styles.subtext}`}>
                      closed trades
                    </div>
                  </div>
                  <div className = 'd-flex align-items-center gil-regular'>
                      {handlePercentageClosedTrades()}
                  </div>
              </div>
              <div className = 'd-flex justify-content-between align-items-center mt-3'>
           
                  <div className = 'd-flex align-items-center'>
                      <div className = 'd-flex flex-column'>
                        <div className = {`mt-2 gil-semibold ${styles.subtext}`}>
                          total materials
                        </div>
                        <div className = {`mt-2  ${styles.trendsNewTradeAmount}`}>
                          <NumberFormat className='gil-semibold' value={props.closedTradeDetails.totalQuantityPurchaseMaterial != null ? props.closedTradeDetails.totalQuantityPurchaseMaterial : 0} displayType={'text'} thousandSeparator={true}  /> MT
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className = 'd-flex align-items-center'>
                <div>
                    <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' />
                </div>
                <div className = 'd-flex flex-column ms-3'>
                    <div className = {`gil-semibold ${styles.title}`}>
                        new trades
                    </div>
                    <div className = {`${styles.text}`}>
                    <NumberFormat className='gil-regular' value={props.newTrades != null ? props.newTrades : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
            </div>
            <div className = 'd-flex justify-content-around  mt-4'>
                <div className = 'd-flex flex-column '>
                    <div className = {`gil-semibold ${styles.title}`}>
                        Trades in progress
                    </div>
                    <div className = {`${styles.otherText}`}>
                      <NumberFormat className='gil-regular' value={props.closedTradeDetails.tradeInProgressCount != null ? props.closedTradeDetails.tradeInProgressCount : 0 } displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
                <div className = 'd-flex flex-column ' >
                    <div className = {`gil-semibold ${styles.title}`}>
                        Total materials
                    </div>
                    <div className = {`${styles.otherText}`}>
                      <NumberFormat className='gil-regular' value={props.closedTradeDetails.tradeInProgressMaterial != null ? props.closedTradeDetails.tradeInProgressMaterial : 0} displayType={'text'} thousandSeparator={true}  /> MT
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Index