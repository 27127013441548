import React, { useEffect, useState } from 'react'
import {Form, Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import * as CONFIG from '../../../../helpers/config'
import Moment from 'moment'
import axios from 'axios'
import {Col} from "react-bootstrap"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Tooltip } from 'antd';
import { useMsal } from "@azure/msal-react";
import MultiCompanyModal from './MultiCompanyModal';
import NumberFormat from 'react-number-format';
import RatioModal from './RatioModal';

function Index(props) {
    console.log(props)
    const { instance } = useMsal();
    const [isNextClicked, setIsNextClicked] = useState(false)
    const [issingle, setissingle] = useState(true)
    const [companyList, setCompanyList] = useState()
    const [showCompanyListModal, setShowCompanyListModal] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState([])
    const [updatedCompany, setUpdatedCompany] = useState([])
    const [split, setSplit] = useState(false)
    const [showRatioModal, setShowRatioModal] = useState(false)
    const [splitData, setSplitData] = useState()
    const [error, setError] = useState(false)
    const [defaultCompany, setDetaultCompany] = useState()

    useEffect(()=>{
        console.log(props)
        if(props.companyData != null){
            if(props.companyData.length > 1){
                console.log('if')
                setUpdatedCompany(props.companyData)
                setissingle(false)
                setSplit(true)
            }
            else{
                console.log('else')
                setUpdatedCompany(props.companyData)
            }
            
        }
    },[])

    useEffect(()=>{
        if(props.tradeInitiateEdit == true && props.splitData != null){
            console.log('edit true')
            setSplitData(props.splitData)
        }
    },[props])
    
    const moveToNext = () => {
    
        if(splitData != null){
            console.log(splitData)
            if(defaultCompany != null){
                for(let i = 0; i < splitData.length; i++){
                    if(splitData[i].companyID == defaultCompany){
                        splitData[i].Is_DefaultCompany = true
                        splitData[i].Is_DefaultCustomer = true
                    }
                    else{
                        splitData[i].Is_DefaultCompany = false
                        splitData[i].Is_DefaultCustomer = true
                    }
                }
            }
          
            console.log(splitData)
            props.handleSplitCompanyData(splitData, updatedCompany)
        }
        else if(props.splitData != null){
            if(defaultCompany != null){
                for(let i = 0; i < props.splitData.length; i++){
                    if(props.splitData[i].companyID == defaultCompany){
                        props.splitData[i].Is_DefaultCompany = true
                        props.splitData[i].Is_DefaultCustomer = true
                    }
                    else{
                        props.splitData[i].Is_DefaultCompany = false
                        props.splitData[i].Is_DefaultCustomer = true
                    }
                }
            }
            props.handleSplitCompanyData(props.splitData, updatedCompany)
        }
        else{
            console.log(updatedCompany)
            console.log(companyList)
            let object = {}
            object = {
                "customerID" : parseInt(props.tradeInitiateEdit != false ? props.TradeInitiateData.enqiry.customerID : ''),
                "incotermsID" : parseInt(props.tradeInitiateEdit != false ? props.TradeInitiateData.enqiry.incotermsID : ''),
                "companyID" : updatedCompany != null && updatedCompany.length > 0 ? updatedCompany[0].company.companyID : companyList[0].company.companyID,
                "tradeScenarioID" : 1,
                "ratio" : 100,
                "ratioAmount" : 0,
                "Is_DefaultCustomer" : true,
                "Is_DefaultCompany" : true,
            }
            props.handleSplitCompanyData(object, updatedCompany)
        }
        props.onNext(props,1)
    }
    
    useEffect(()=>{
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result, 'Company')
            setCompanyList(res.data.result)
            
            //setRolesList(res.data.result)
            //setWorkFlowList(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        }) 
    },[])

    
    const handleEditTrade = () => {
        if(props.tradeInitiateEdit == true && props.splitData != null){
            if(props.TradeInitiateData.tradedetails.tradeScenarioID == 1 || props.TradeInitiateData.tradedetails.tradeScenarioID == 3){
                for(let i = 0; i < companyList.length; i++){
                    for(let x = 0; x < props.splitData.length; x++){
                        if(props.splitData[x].companyID == companyList[i].company.companyID){
                            console.log('scenario 1 or 3')
                            setUpdatedCompany(companyList[i])
                        }
                    }
                }
            }
            else if(props.TradeInitiateData.tradedetails.tradeScenarioID == 2){
                let listSelected = []
                for(let i = 0; i < companyList.length; i++){
                    for(let x = 0; x < props.splitData.length; x++){
                        console.log('scenario 2')
                        if(companyList[i].company.companyID == props.splitData[x].companyID){
                            listSelected.push(companyList[i])
                        }
                    }
                    
                }
                setSplit(true)
                setUpdatedCompany(listSelected)
            }
            
        }
    }
    
    const handleSupply = () => {
    
        if(props.tradeInitiateEdit == null){
            console.log('clicked')
            let emptySpaceInitiation = props.TradeInitiateData.tradeSupply.indexOf(' ')
            let emptySpaceScheduled = props.TradeInitiateData.scheduledSupply.indexOf(' ')
            let tradeSupply = props.TradeInitiateData.tradeSupply.slice(0, emptySpaceInitiation)
            let scheduledSupply = props.TradeInitiateData.scheduledSupply.slice(0, emptySpaceScheduled)
            console.log(tradeSupply)
            console.log(scheduledSupply)
            return(
                <>
                <span><b>{tradeSupply}</b></span>
                <span> / </span>
                <span>{scheduledSupply} supplies</span>
                </>
            )
        }
        else{
            
            return(
                <>
                {props.TradeInitiateData.tradeStatus}
                </>
            )
        }
        console.log(props)
     
    }

    const handleGeneralDetails = () => {
       
        if(props.tradeInitiateEdit != true ){
            
            return (
                <>
                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                            <p className = 'font-weight-400 color-697382 mb-1'>Customer Requirement#</p>
                            <h6 className = 'font-weight-600 font-14 mb-0 textHead'>{props.TradeInitiateData.enqiry.enquiryNo}</h6>
                        </div>
                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                            <p className = 'font-weight-400 color-697382 mb-1'>Customer Requirement Date</p>
                            <p className = 'font-weight-600 font-14 mb-0 textHead'>{Moment(props.TradeInitiateData.enqiry.enquiryDate).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                            <p className = 'font-weight-400 color-697382 mb-1'>Supply#</p>
                            <p className = 'font-weight-600 font-14 mb-0 textHead'>{props.currentSupplyPlan != null ? props.currentSupplyPlan.supplyNo : ''}</p>
                        </div>
                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                            <p className = 'font-weight-400 color-697382 mb-1'>Supply Period</p>
                            <p className = 'font-weight-600 font-14 mb-0 textHead'>{props.currentSupplyPlan != null ? Moment().month(props.currentSupplyPlan.month - 1).format('MMMM') : ''} {props.currentSupplyPlan != null ? props.currentSupplyPlan.year : ''}</p>
                        </div>
                </>
            )
        }
       else{
        let emptySpace = props.TradeInitiateData.tradeStatus.indexOf(' ')
        let tradeSupply = props.TradeInitiateData.tradeStatus.slice(0, emptySpace)
        let supplyPeriodIndex = parseInt(tradeSupply) - 1
        return(
            <>
            <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                    <p className = 'font-weight-400 color-697382 mb-1'>Customer Requirement#</p>
                    <h6 className = 'font-weight-600 font-14 mb-0 textHead'>{props.TradeInitiateData.enquiryNo}</h6>
                </div>
                <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                    <p className = 'font-weight-400 color-697382 mb-1'>Customer Requirement Date</p>
                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{Moment(props.TradeInitiateData.enquiryDate).format('DD/MM/YYYY')}</p>
                </div>
                <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                    <p className = 'font-weight-400 color-697382 mb-1'>Supply#</p>
                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{tradeSupply}</p>
                </div>
                <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                    <p className = 'font-weight-400 color-697382 mb-1'>Supply Period</p>
                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{props.TradeInitiateData.supplyPeriod[supplyPeriodIndex].supplyPeriod}</p>
                </div>
            </>
        )
       }
    }

    const handleChangeCompany = () => {
        console.log('track')
        setShowCompanyListModal(true)
    }

    const handleSelectedCompany = (data) => {
        let list = [...selectedCompany]
        let listSelected = []
        console.log(companyList, 'company')
        console.log(data, 'data')
        if(data != null && Array.isArray(data) != true){
            for(let i = 0; i < companyList.length; i++){
                if(companyList[i].company.companyID == data.company.companyID){
                    listSelected.push(companyList[i])
                }
            }
            setUpdatedCompany(listSelected)
        }
        else if(Array.isArray(data)){
            setUpdatedCompany(data)
        }
        console.log(listSelected)
        //setSelectedCompany(data)
    }
    
    const cancel = () => {
        setShowCompanyListModal(false)
    }

    const closeRatioModal = () => {
        setShowRatioModal(false)
    }

    useEffect(()=>{

    },[selectedCompany])

    useEffect(()=>{
        if(companyList != null && companyList.length > 0){
            handleEditTrade()
        }
    },[companyList])

//     useEffect(()=>{
//         if(issingle == false){
//             setSplit(true)
//             setShowCompanyListModal(true)
//         }
//         else{
//             if(props.companyData != null){
// setSplit(false)
//             }
//             else{
                
//                 setUpdatedCompany(null)
//             }
      
//         }
//     },[issingle])

    const handleCompany = (data) => {
        if(data == false){
            setError(true)
            setSplit(true)
            setShowCompanyListModal(true)
        }
        else{
            setError(false)
            setSplit(false)
            setUpdatedCompany(null)
        }
    }

    useEffect(()=>{
   
    },[split])

    const handleSplitRatio = () => {
        setShowRatioModal(true)
    }

    const handleRatioData = (data) => {
        console.log(data)
        console.log(updatedCompany)
        for(let i = 0; i < updatedCompany.length; i++){
            for(let x = 0; x < data.length; x++){
                if(data[x].companyID == updatedCompany[i].company.companyID){
                    updatedCompany[i].company.ratio = data[x].ratio
                }
            }
        }
        console.log(updatedCompany)
        setSplitData(data)
    }

    useEffect(()=>{
        console.log('set split')
        console.log(splitData)
        let totalPer = 0
        if(splitData != null){
            if(Array.isArray(splitData) == true){
                splitData.map((amt,k)=>{
                    totalPer += amt.ratio
                })
                if(totalPer != 100){
                    setError(true)
                }
                else{
                    setError(false)
                }
            }
        }
    },[splitData])

    const trackCheck = (e) => {
        console.log(e.target.id)
        setDetaultCompany(parseInt(e.target.id))
    }

    
    useEffect(()=>{
        console.log('toggled', props.companyData, updatedCompany)
        if(props.tradeInitiateEdit == true){
            handleRatioData(props.splitData)
        }
    },[updatedCompany])

    useEffect(()=>{

    },[defaultCompany])

  return (
    <>

       <Card className='bg-white'>
                <Card.Body className='p-0  '  style={{overflowY: 'scroll', height:'75vh'}}>
                <div className = " p-4 grey-color m-4 bg-light row">
                    {handleGeneralDetails()}
                </div>
                {props.tradeInitiateEdit == true ? 
                <div className = "p-4 grey-color m-4 bg-light">
                    <Col className="d-flex">
                    <div className = 'col-md-3'>
                        <p className = 'font-weight-400 color-697382 mb-1'>Trade #</p>
                        <p className = 'font-weight-600 mb-0 font-14 textHead'>{props.TradeInitiateData.tradeNo}</p>
                    </div>
                    <div className = 'col-md-3'>
                        <p className = 'font-weight-400 color-697382 mb-1'>Trade Date</p>
                        <p className = 'font-weight-600 mb-0 font-14 textHead'>{Moment(props.TradeInitiateData.enquiryDate).format('DD/MM/YYYY')}</p>
                    </div>
                    </Col>
                    <Col md={6}></Col>
                </div>
                :
                ''
                }
                 <div className='table-ttile-color font-14 m-4'>
                        Trade Type
                        <div className='mt-1-rem'>
                            <ButtonGroup>
                                <ToggleButton
                                    key={3}
                                    id='radio-3'
                                    type="radio"
                                    variant={'outline-primary'}
                                    name="Internal2"
                                    checked={issingle}
                                    value={3}
                                    onChange={(e) => {
                                        setissingle(true)
                                        handleCompany(true)
                                    }}
                                >
                                    Single
                                </ToggleButton>
                                <ToggleButton
                                    key={4}
                                    id='radio-4'
                                    type="radio"
                                    variant={'outline-primary'}
                                    name="External2"
                                    checked={!issingle}
                                    value={4}
                                    onChange={(e) => {
                                        setissingle(false)
                                        handleCompany(false)
                                    }}
                                >
                                    Split
                                </ToggleButton>
                            </ButtonGroup>
                        </div>
                    </div>
           
                    <div className='mb-10-rem'>
                        
                                    <Card className = 'mb-5'>
                                    <Card.Body className='mx-4 p-0 border p-4'>
                                   
                                    <div className = 'row d-flex align-items-center mt-3'>
                                        <div className = 'col-12 col-md-6 text-md-start multiple-trades-header' >
                                            Company Details
                                        </div>
                                        <div className = "col-12 col-md-6 text-md-end">
                                            <BlueBorderButton value = 'Change Company' clickEvent = {handleChangeCompany}/>
                                        </div>
                                    </div>
                                    {updatedCompany != null && updatedCompany.length > 0 ? 
                                    <>
                                    {updatedCompany.map((values, k)=>(
                                    <>
                                    <div className = "p-4 mt-4 grey-color bg-light row">
                                  <>
                                  <div className = 'col-md-3'>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{values.company.companyName}</p>
                                    <p className = 'm-0 mt-2 font-weight-400 color-697382 mb-1'>
                                        {values.company.companyBuilding}, &nbsp;
                                        {values.company.companyUnit}, &nbsp;
                                        {values.company.companyStreet}, &nbsp;
                                        {values.company.companyPoBox}, &nbsp;
                                        {values.countryname}, &nbsp;
                                        {values.statename}
                                    </p>
                                 </div>
                                 <div className = 'col-md-3'>
                                    <p className = 'font-weight-400 color-697382 mb-1'>
                                       Telephone
                                    </p>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{values.company.companyTelephone}</p>
                                 </div>
                                 <div className = 'col-md-3'>
                                    <p className = 'font-weight-400 color-697382 mb-1'>
                                        Email ID
                                    </p>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{values.company.contactEmail}</p>
                                 </div>
                                 <div className = 'col-md-3 d-flex align-items-center'>
                                    <div>
                                    <p className = 'font-weight-400 color-697382 mb-0'>Set Company as default</p>
                                    </div>
                                    <Form.Check 
                                        type='radio'
                                        name='values.company.companyID'
                                        id={values.company.companyID}
                                        onChange = {trackCheck}
                                        defaultChecked = {props.splitData != null && props.splitData[k] != null && props.splitData[k].Is_DefaultCompany == true  ? true : false}
                                        className='mx-3 slectCustomer'
                                    />
                                 </div>
                                  </> 
                                  </div>
                                  <hr className = 'my-4' />
                                  </> 
                                 
                                ))}
            
                                 </>
                                 :
                                 companyList != null ? 
                                 <>
                                   
                                  <>
                          
                                    <div className = "p-4 mt-4 grey-color bg-light row">
                                 <div className = 'col-md-4'>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{companyList[0].company.companyName}</p>
                                    <p className = 'm-0 mt-2 font-weight-400 color-697382 mb-1'>
                                        {companyList[0].company.companyBuilding}, &nbsp;
                                        {companyList[0].company.companyUnit}, &nbsp;
                                        {companyList[0].company.companyStreet}, &nbsp;
                                        {companyList[0].company.companyPoBox}, &nbsp;
                                        {companyList[0].countryname}, &nbsp;
                                        {companyList[0].statename}
                                    </p>
                                 </div>
                                 <div className = 'col-md-4'>
                                    <p className = 'font-weight-400 color-697382 mb-1'>
                                       Telephone
                                    </p>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{companyList[0].company.companyTelephone}</p>
                                 </div>
                                 <div className = 'col-md-4'>
                                    <p className = 'font-weight-400 color-697382 mb-1'>
                                        Email ID
                                    </p>
                                    <p className = 'font-weight-600 font-14 mb-0 textHead'>{companyList[0].company.contactEmail}</p>
                                 </div>
                                
                                 </div>
                                 </>
                                
                                 </>
                                 :
                                 ''
                                 
                                }
                      
                                </Card.Body>
                                {updatedCompany != null && updatedCompany.length > 1 ? 
                                <>
                              
                                <div className = 'pt-4 p-2'>
                                    <div className='d-flex align-items-center'>
                                        <div className='table-ttile-color font-14 m-4'>
                                            Split Ratio
                                        </div>
                                        <div className = 'ms-4'>
                                            <Tooltip placement="top" title={'Edit Ratio'}>
                                                <button className = 'border-0 bg-transparent' onClick={handleSplitRatio}>
                                                    <img src ='/assets/images/supports/penIcon.svg' />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {error == true ?
                                  <span className='input_error px-4'>Please select a correct ratio percentage. Ratio cannot be 0 or below 100%!</span>
                                :
                                ''
                                }
                              
                                <div className = "py-3 px-5 grey-color mx-4" style = {{width:'fit-content'}}>
                                    <div className = ''>
                                        <div className = 'd-flex align-items-center'>
                                            {updatedCompany.map((values, k)=>(
                                            <>
                                            <div className = 'd-flex align-items-center justify-content-center'>  
                                            <div className = ''>
                                                {values.company.companyName}
                                            </div>
                                            <div className = 'ms-2'>
                                                 <div className = 'payment-method-pill d-flex align-items-center justify-content-center'>
                                                     {values.company.ratio != null ? values.company.ratio + '%' : '0%'}      
                                                </div>
                                            </div>
                                            </div>  
                                            {k < 1 ?
                                            <div className = 'mx-5 seperator' />
                                            :
                                            ''
                                            }
                                            </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                </>
                                :
                                ''
                                }
                                </Card>
                            </div>            
                </Card.Body>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext}  disabled={error} /></span>
                </Card.Footer>
            </Card>

            {showCompanyListModal == true ? 
                <MultiCompanyModal 
                    data = {companyList != null ? companyList : null}
                    updatedCompany={updatedCompany}
                    handleSelectedCompany = {handleSelectedCompany}
                    split={split != null ? split : null}
                    close={cancel} 
                />
                :
                ''
            }
            {showRatioModal == true ? 
            <RatioModal 
                updatedCompany={updatedCompany}
                currentSupplyPlan={props.currentSupplyPlan}
                currentTrade = {props.TradeInitiateData.enqiry != null ? props.TradeInitiateData.enqiry : props.TradeInitiateData.tradedetails.tradeEnquiry}
                close = {closeRatioModal}
                handleRatioData = {handleRatioData}
                splitData = {splitData}
            />  
            :
            ''  
            }
    </>
  )
}

export default Index