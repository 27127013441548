import React, {useState, useEffect} from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Index from './Marketing Information';
import ManufacturingInformation from './Manufacturing Information/ManufacturingInformation';
import StorageInformation from './Storage Information/StorageInformation';
import { AddSupplier } from '../../../../helpers/API/Api';
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import * as CONFIG from "../../../../helpers/config"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";


function MarketingInformation(props) {
    const { instance } = useMsal();
    const [key, setKey] = useState(1);
    const [isDisabled, setIsDisabled] = useState(true)
    const [isReveneMet, setIsRevenueMet] = useState(false);
    const [isBankMet, setIsBankMet] = useState(false);
    const [revenueData, setRevenueData] = useState([]);
    const [marketingInformation, setMarketingInformation] = useState();
    const [supplierManufacturingInformation, setsupplierManufacturingInformation] = useState([]);
    const [marketingStorageInformation, setmarketingStorageInformation] = useState([]);
    const [subsidiaryCompanyList, setsubsidiaryCompanyList] = useState([]);
    const [disabled, setDisabled] = useState(false)
    const [docs, setDocs] = useState([]);
    const MySwal = withReactContent(Swal)

    const navigate = useNavigate()
    console.log(props.dataForEdit, 'data or edit')
    useEffect(()=>{
        console.log(supplierManufacturingInformation, marketingStorageInformation)
    },[supplierManufacturingInformation, marketingStorageInformation])

    useEffect(()=>{
        if(isReveneMet && isBankMet){
            console.log('inside use effet')
            setIsDisabled(false)
        }
    },[isReveneMet, isBankMet])
    useEffect(() => {
        if(props.isEdit == true ){
            let manufList = []
            let strgList = []
            manufList = [...props.dataForEdit.supplierdata.supplierManufacturingInformation]
            manufList.map((list,k)=>{
                manufList[k].key = list.supplierManufacturingInformationID
            })
            strgList = [...props.dataForEdit.supplierdata.marketingStorageInformation]
            strgList.map((list,k)=>{
                strgList[k].key = list.marketingStorageInformationID
            })
          
            setsupplierManufacturingInformation(manufList)
            setmarketingStorageInformation(strgList)

        }
    }, [])
    const addManufacturingToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...supplierManufacturingInformation]
        list.push(data);
        k++
        setKey(k)
        setsupplierManufacturingInformation(list)
        setIsBankMet(true)
    }
    const addStorageToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...marketingStorageInformation]
        list.push(data);
        k++
        setKey(k)
        setmarketingStorageInformation(list)
        setIsBankMet(true)
    }
   

    const setPrimaryManufacturing=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        supplierManufacturingInformation.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setsupplierManufacturingInformation(cusList)
    }
    const setPrimaryStorage=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        marketingStorageInformation.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setmarketingStorageInformation(cusList)
    }
    
    const removePrimaryManufacturing=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        supplierManufacturingInformation.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setsupplierManufacturingInformation(cusList)
    }
    const removePrimaryStorage=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        marketingStorageInformation.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setmarketingStorageInformation(cusList)
    }
  


    const editManufacturingToList =(data)=>{
        const list = data;
        const supplierList = [...supplierManufacturingInformation]
        supplierManufacturingInformation.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setsupplierManufacturingInformation(supplierList)
    }
    const editStorageToList =(data)=>{
        const list = data;
        const supplierList = [...marketingStorageInformation]
        marketingStorageInformation.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setmarketingStorageInformation(supplierList)
    }
    
    const saveMarketingInformation = (data) => {
        console.log(data)
        setMarketingInformation(data)
    }
    
    const saveDocuments = (doc1, doc2, doc3)=>{
        let docs = [];
        docs['audit'] = doc1;
        docs['refer'] = doc2;
        docs['memo'] = doc3;
        setDocs(docs);
    }

    const moveToNext = ()=>{
        setDisabled(true)
        let list = [];
        let manufacturingFinalArray = []
        let shareholderFinalArray = []
        let supplierBankAccountInformation = props.supplierBankAccountInformation
        let supplierCompanyDirector = props.supplierCompanyDirector
        let supplierShareholder = props.supplierShareholder
        let supplierSubsidiaries = props.supplierSubsidiaries
        let supplierContactInformation = props.supplierContactInformation
        let supplierFinancialInformation = props.supplierFinancialInformation
        manufacturingFinalArray = [...supplierManufacturingInformation]
        shareholderFinalArray = [...marketingStorageInformation]
        list = [manufacturingFinalArray, shareholderFinalArray]
        console.log(list, 'market data')
        console.log(props, ' all the data')
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            ...props.generalTabInfo,
            ...marketingInformation,
            supplierBankAccountInformation,
            ...props.financialTabInfo.docs,
            supplierCompanyDirector,
            supplierContactInformation,
            supplierShareholder,
            supplierSubsidiaries,
            supplierManufacturingInformation,
            marketingStorageInformation,
            supplierFinancialInformation,
            ...props.parentTabInfo

        }
      
        final_json.companyTypeID = parseInt(final_json.companyTypeID)
        final_json.supplierCategoryID = parseInt(final_json.supplierCategoryID)
        final_json.parentCompanyCountryID =  final_json.parentCompanyCountryID == null ? final_json.parentCompanyCountryID : parseInt(final_json.parentCompanyCountryID)
        final_json.hqCountryID = parseInt(final_json.hqCountryID)
        final_json.incotermsID = parseInt(final_json.incotermsID)
        final_json.paymentTypeID = parseInt(final_json.paymentTypeID)
        //final_json.SupplierVATDocumentNumber = "12141"
        //final_json.supplierVATDocument = ""
        //final_json.incorporationDocument = ""
        final_json.supplierVATDocumentNumber = final_json.supplierVATDocumentNumber.toString()
        
        console.log(final_json.parentCompanyCountryID, 'checkin')
        //supplierSourceFundsID
        console.log(final_json, 'data for api')
       
        if (props.isEdit == true){
            final_json.supplierID = props.dataForEdit.supplierdata.supplierID
            console.log(final_json)
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.UPDATE_SUPPLIER, 
                final_json, 
                {headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then(res => {
                    if(res.data.responseCode == '302002'){
                        MySwal.fire(
                            {
                            icon: 'error',
                            title: 'Register Number ' + final_json.registrationNo + ' Already Exists!',
                            html: '<p>Please use a different registration number</p>',
                            //type: 'error',
                            confirmButtonText: 'Back to Supplier List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                          }).then(() => {
                            //props.onSave()
                           //redirecting 
                           //history.push("/login")
                        }); 
                    }
                    else if(res.data.responseCode == '302000'){
                        MySwal.fire(
                            {
                            icon: 'success',
                            title: 'Supplier Updated Successfully!',
                            html: '<p>Name: ' + final_json.supplierName + '</p>' + '<br />' + '<p>Registration Number: ' + final_json.registrationNo + '</p>',
                            type: 'success',
                            confirmButtonText: 'Back to Supplier List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                          }).then(() => {
                            setDisabled(false)
                            props.getList()
                            props.onSave()
                           //redirecting 
                           //history.push("/login")
                        });
                    }
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })    
            //window.location.reload()
              
        }
        else{
            console.log('add api reached', final_json)
            
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_SUPPLIER, 
                final_json, 
                {headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    if(res.data.responseCode == '302002'){
                        MySwal.fire(
                            {
                            icon: 'error',
                            title: 'Register Number' + final_json.registrationNo + 'Already Exists!',
                            html: '<p>Please use a different registration number</p>',
                            //type: 'error',
                            confirmButtonText: 'Back to Supplier List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                        }).then(() => {
                            //props.onSave()
                           //redirecting 
                           //history.push("/login")
                        });
                    }
                    else if(res.data.responseCode == '302000'){
                        MySwal.fire(
                            {
                            icon: 'success',
                            title: 'Supplier Added Successfully!',
                            html: '<p>Name: ' + final_json.supplierName + '</p>' + '<br />' + '<p>Registration Number: ' + final_json.registrationNo + '</p>',
                            type: 'success',
                            confirmButtonText: 'Back to Supplier List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                          }).then(() => {
                            setDisabled(false)
                            props.getList()
                            props.onSave()
                           //redirecting 
                           //history.push("/login")
                        });
                    }
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
  
                
                //props.onSave()
                
            //window.location.reload()
        }
        
    }

    const backClickEvent = () => {
        props.backClickEvent(4)
    }
    useEffect(()=>{

    },[disabled])
  return (
    <div>
            <Card style={{background: 'transparent'}}>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body mb-2-rem' style={{background: '#fff'}}>
                        <Index saveMarketingInformation = {saveMarketingInformation} isEdit = {props.isEdit} dataForEdit = {props.dataForEdit}/>
                    </Card.Body>
                    <Card.Body className='form-card-body mb-2-rem' style={{background: '#fff'}}>
                        <ManufacturingInformation addManufacturingToList={addManufacturingToList} editManufacturingToList={editManufacturingToList} supplierManufacturingInformation = {supplierManufacturingInformation} setPrimaryManufacturing={setPrimaryManufacturing} removePrimaryManufacturing={removePrimaryManufacturing} isEdit = {props.isEdit} dataForEdit = {props.dataForEdit}/>
                    </Card.Body>
                    <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                        <StorageInformation addStorageToList={addStorageToList} editStorageToList={editStorageToList} marketingStorageInformation = {marketingStorageInformation} setPrimaryStorage= {setPrimaryStorage} removePrimaryStorage={removePrimaryStorage} isEdit = {props.isEdit} dataForEdit = {props.dataForEdit}/>
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span><BlueBorderButton value='Back' clickEvent = {backClickEvent}/></span>
                    <span className='pl-10'><BlueButton value='Submit' disabled = {disabled} clickEvent={moveToNext}/></span>
                </Card.Footer>
            </Card>
    </div>
  )
}

export default MarketingInformation  