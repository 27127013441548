import React from 'react'
import FormComponent from './components/FormComponent'
import {Row, Col, Form } from "react-bootstrap";
import { SelectExistingButton } from '../../../../../components/buttoncomponents'

function AddressDetail(props) {
  return (
    <Row>
       <div className = 'd-flex justify-content-between align-items-center'>
            <div className='font-16 font-weight-600 color-707895'>
               Address Details
            </div>
        </div>
        <FormComponent customerAddress={props.customerAddress} isdataavailable={props.isdataavailable}/>
    </Row>
  )
}

export default AddressDetail