import React from 'react'
import SupplierList from './SupplierList'

export default function List(props) {
    console.log(props)
    return (
        <div class="col-lg-12 list-scroll">
        <div class="table-responsive-sm  card card-body">
        <table className="table tCustom table-text">
              <thead >
                  <tr className='list-top'>
                      <th>Supplier Code</th>
                      <th>Supplier Name</th>
                      <th>Address</th>
                      <th>Category</th>
                      <th>Incoterms</th>
                      <th></th>
                      <th></th>
                    </tr>
              </thead>
              <tbody>
                  {(localStorage.getItem('List_Suppliers') == 3 || localStorage.getItem('List_Suppliers') == 1) &&
                    <>
                        {props.data.map((list,k)=>(
                            <SupplierList data={list}  edit={props.edit} disable={props.disable}/>
                        ))}
                    </>
                  }
              </tbody>
      </table>
      </div>
      </div>
    )
}
