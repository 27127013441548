import { Footer } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Row, Card, Col, Button, FloatingLabel } from "react-bootstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BlueButton } from '../../components/buttoncomponents';
import * as FormFields from "../../components/formcomponents"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useFormik } from 'formik';
import * as CONFIG from "../../helpers/config"
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState()

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    
    useEffect(()=>{
        console.log(value)
    },[value])

    const formik = useFormik({
        initialValues:{
            FullName: '',
            Email: '',
            Designation: ''
        }
    })

    const sendContact =  () =>{
        const json_list ={
            ...formik.values,
            'PhoneNo': value,
            'Product' : "TMS"
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.CONTATC_US_EMAIL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            toggle()
        }).catch((error) => {
            if (error.response.status == 401) {
                localStorage.clear();
                instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                });
            }
        })
    }
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} className="mt-10-rem property_info_modal modal_35 mt-5 ">
                <ModalBody>
                    <center>
                        <div className='landing_modal_contact mt-1-rem'>Contact Us</div>
                    </center>
                    <Row className='d-flex justify-content-center mt-2-rem mb-5-rem'>
                        <FormFields.InputField
                            label='Full Name'
                            name= 'FullName'
                            classname='mt-1-rem'
                            md={10}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormFields.InputField
                            label='Email'
                            name='Email'
                            classname='mt-1-rem'
                            md={10}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <Col md={10} className='mt-1-rem'>
                            <FloatingLabel controlId="floatingInput" label={'Phone Number'}>
                                <PhoneInput
                                    className='form-control'
                                    placeholder=""
                                    onChange={setValue}
                                />
                            </FloatingLabel>
                        </Col>
                        <FormFields.InputField
                            label='Designation'
                            name= 'Designation'
                            classname='mt-1-rem'
                            md={10}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormFields.InputField
                            label='Organization Name'
                            classname='mt-1-rem'
                            name='OrganisationName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            md={10}
                        />
                        <Col md={10} className='mt-4-rem'>
                            <Button style={{ width: '100%', height: '50px' }} onClick={sendContact}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <div className='bg_landing'>
                <div className='landing_top bg_landing_right'>
                    <div className='container'>
                        <Row className='pt-1-rem'>
                            <Col md={2}>
                                <img src='assets/images/tmslanding/tmslogo.svg' />
                            </Col>
                            <Col md={6} className='d-flex justify-content-end align-items-center'>
                                <span className='landing_menu'>Features</span>
                                <span className='landing_menu'>Contact</span>
                            </Col>
                            <Col md={4} className='d-flex align-items-center'>
                                <Col md={6}>
                                    <Button className='landing_demo_button'>
                                        <a href="/login">Login</a>
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    <Button className='landing_demo_button' onClick={toggle}>
                                        Request for a Demo <img src='assets/images/tmslanding/rightarrowwhite.svg' />
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                        <Row className='mt-4-rem'>
                            <Col md={5} className='mt-4-rem'>
                                <div>
                                    <div className='landing_choose_text'>Choose the Best <img src='assets/images/tmslanding/scribble.png'/></div>
                                    <div className='landing_trade_text'>Trading Platform</div>
                                    <div className='landing_small_text'>
                                        With the TMS platform, digitalize and advance your trading processes. We are the best at helping you track your shipments.
                                    </div>
                                    <Button className='landing_demo_button mt-2-rem' onClick={toggle}>
                                        Contact Us <img src='assets/images/tmslanding/rightarrowwhite.svg' />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} className='landing_top_image_right'>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className='container'>
                <Row>
                    <Col md={4} className='resp_text'>
                        <Row className='mt-2-rem'>
                            <Col md={4}>
                                <img src='assets/images/tmslanding/icon3.png' />
                            </Col>
                            <Col md={8}>
                                <div className='landing_100'>100+</div>
                                <div className='landing_small_texts'>Active happy trading customers</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <section className='landing_second_section'>
                <div className='container'>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <div className='landing_second_section_left_heading'>Why Choose TMS Platform?</div>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <div>
                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Multiple Companies</div>
                                                        <div className='landing_left_regular mt-1-rem'>The Platform is capable of adding multiple companies and managing trades under single or multiple company.</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Invoicing</div>
                                                        <div className='landing_left_regular mt-1-rem'>Single Company or Split Company Invoicing</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Workflow & Approvals</div>
                                                        <div className='landing_left_regular mt-1-rem'>Workflows and approval management including roles and permissions.</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <div>
                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Multiple Users</div>
                                                        <div className='landing_left_regular mt-1-rem'>Create and manage unlimited Users.</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Notifications & Reminders</div>
                                                        <div className='landing_left_regular mt-1-rem'>Notifications and reminders on pending tasks on each roles / permission on business workflow</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='mt-2-rem'>
                                                <Col md={1} className='landing_left_hr'>
                                                    <hr />
                                                </Col>
                                                <Col md={10} className='d-flex align-items-center '>
                                                    <div>
                                                        <div className='landing_left_inner_heading'>Meeting & Tours</div>
                                                        <div className='landing_left_regular mt-1-rem'>Manage meeting reports and tour reports including inviting and tracking tours and reports.</div>
                                                    </div>
                                                </Col>
                                                <Row className='d-flex justify-content-end landing_explore_more'>
                                                    <Col md={7}>
                                                        Explore More <img src='assets/images/tmslanding/bluearrow.svg' />
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Row>
                                    <Col md={4}>
                                        <Button variant="outline-secondary" size="lg" className='landing_demo_button_white' onClick={toggle}>
                                            Contact Us <img src='assets/images/tmslanding/bluearrow.svg' />
                                        </Button>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <img src='assets/images/tmslanding/img2.png' />
                        </Col>
                    </Row>
                </div>
            </section>

            <section>
                <Footer className="container-fluid mt-5 bg-footer py-5">
                    <div className="container-fluid">
                        <div className="row mx-5">

                            <div className="col-md-4 col-12 mt-m-40">
                                <p className=" txt-ftr font-13">Powered By:</p>
                                <div className="pt-80"> <img className="logo" src="assets/images/tmslanding/systecx.svg" /></div>
                                {/* <p className="footerCompanyAddress txt-ftr font-12">Unit-212, P Level,<br />
                                    Business Bay, PO Box 124721, Dubai,<br />
                                    United Arab Emirates</p> */}
                            </div>
                            <div className="col-md-4 col-12 mt-m-40 d-flex align-items-center">
                                <div className="d-block ">
                                    <h5 className="txt-ftr pb-4">Let's Start a Conversation</h5>
                                    <div className="conInfo txt-ftr">
                                        <a className=" txt-ftr ms-md-3 ms-0" href="mailto:">support@tmssaas.cloud</a>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4 mt-m-40 d-flex align-items-end justify-content-end msb-3">
                                <div className="d-flex ">
                                    <img src="assets/images/tmslanding/Up.svg" />
                                </div>
                            </div> */}

                        </div>
                    </div>
                </Footer>
                <div className="container-fluid bg-last ">
                    <div className="d-flex align-items-center justify-content-between mx-md-5 mx-2 py-3">
                        <div className="copy txt-ftr font-12">COPYRIGHT 2023</div>
                        <div className="last-items d-flex align-items-center">
                            <div className="policy txt-ftr font-12">Privacy Policy<span className="mx-3">|</span></div>
                            <div className="terms txt-ftr font-12">Terms of Use</div>
                        </div>
                    </div>

                </div>
                
            </section>
        </div>
    )
}
