import React, {useState, useEffect} from 'react'
import { useFormik } from 'formik'
import {Row, Col} from "react-bootstrap";
import Moment from "moment"
import { SupplyTerms } from '../../../../../../helpers/API/Api';
import * as FormFields from "../../../../../../components/formcomponents";
import { BlueButton } from '../../../../../../components/buttoncomponents'




const YearList = (props)=>{
    console.log(props, 'Test')
    const yearList = [];
    const [yearlist, setyearlist] = useState([])
    const [isFirst, setIsFirst] = useState(true)
    
    useEffect(()=>{
        if(isFirst){
            const index = 10
            let currentYear = parseInt(Moment(new Date()).format("YYYY"))
            yearList[0] = currentYear
            for(var i =1; i<index;i++){
                yearList[i] = currentYear+1
                currentYear++
            }
            setyearlist(yearList)
            console.log(yearList);
        }
        setIsFirst(false)
    },[isFirst])
    return(
        <>
            {yearlist.map((list,k)=>(
                <option key={k} value={list} selected={list == props.val}>{list}</option>
            ))}
        </>
    )
}


const MonthList = (props)=>{
    
    return(
        <>
            <option value={1} selected={props.val == 1}>Janurary</option>
            <option value={2} selected={props.val == 2}>Feburary</option>
            <option value={3} selected={props.val == 3}>March</option>
            <option value={4} selected={props.val == 4}>April</option>
            <option value={5} selected={props.val == 5}>May</option>
            <option value={6} selected={props.val == 6}>June</option>
            <option value={7} selected={props.val == 7}>July</option>
            <option value={8} selected={props.val == 8}>August</option>
            <option value={9} selected={props.val == 9}>September</option>
            <option value={10} selected={props.val == 10}>October</option>
            <option value={11} selected={props.val == 11}>November</option>
            <option value={12} selected={props.val == 12}>December</option>
        </>
    )
}
export default function Supply_plan_form(props) {
    const [disable, setDisable] = useState(true)
    console.log(props, 'test form')
    const formik = useFormik({
        initialValues:{
            key: props.isEdit?props.selected.key:0,
            years: props.isEdit?props.selected.years:'',
            months : props.isEdit?props.selected.months:0,
            monthNames: props.isEdit?props.selected.monthNames:'',
            quantity: props.isEdit?props.selected.quantity:'',
            noOfSupply: props.isEdit ? props.selected.noOfSupply : props.contractterms != null ? 
                props.contractterms.contractTypeID == "2"
                ? "1" 
                : '' 
                : '',
            productSellingPrice: props.isEdit?props.selected.productSellingPrice:'',
            supplyTermsID: props.isEdit?props.selected.supplyTermsID:props.contractterms != null ? 
            props.contractterms.contractTypeID == "2"
                ? 5 
                : 0 
                : 0,
            supplyTermsName: props.isEdit?props.selected.supplyTermsID:props.contractterms != null ? 
            props.contractterms.contractTypeID == "2"
                ? 'Single Trade' 
                : ''
                : '',

        },
        validate: values=>{
            let errors ={}
            if(!values.years){
                errors.years = "Please select a years"
            }
            if(!values.months){
                errors.months = "Please select a years"
            }
            if(!values.quantity){
                errors.months = "Please select a years"
            }
        }
    })

    useEffect(()=>{
        if(formik.values.years != ''){
            if(Object.keys(formik.errors).length == 0){
                if(formik.values == formik.initialValues){
                    setDisable(true)
                }
                else{
                    setDisable(false)
                }
            }
          
        }
    })
    const saveSupply = ()=>{
        if(formik.values.years != ''){
            if(Object.keys(formik.errors).length == 0){
                console.log(props)
                props.handleDisable()
 
                formik.values.key = parseInt(props.keyForData)
                formik.values.years = parseInt(formik.values.years)
                formik.values.quantity = parseInt(formik.values.quantity)
                formik.values.months = parseInt(formik.values.months)
                formik.values.noOfSupply = parseInt(formik.values.noOfSupply)
                formik.values.productSellingPrice = parseInt(formik.values.productSellingPrice)
                formik.values.supplyTermsID = parseInt(formik.values.supplyTermsID)
                if(props.selectedtradeEnquiryProductDetailID){
                    formik.values.tradeEnquiryProductDetailID = props.selectedtradeEnquiryProductDetailID
                }
                if(props.selected.tradeEnquiryProductSupplyplanID){
                    formik.values.tradeEnquiryProductSupplyplanID = props.selected.tradeEnquiryProductSupplyplanID
                }
                console.log(formik.values, props)
                
                props.handleUpdateProductDetails(formik.values)
                props.saveSupply(formik.values)
                props.handleDisableEnd()
                
            }
        }
    }

    const updateSupply = ()=>{
        if(formik.values.years != ''){
            if(Object.keys(formik.errors).length == 0){
                formik.values.years = parseInt(formik.values.years)
                formik.values.quantity = parseInt(formik.values.quantity)
                formik.values.months = parseInt(formik.values.months)
                formik.values.noOfSupply = parseInt(formik.values.noOfSupply)
                formik.values.productSellingPrice = parseInt(formik.values.productSellingPrice)
                formik.values.supplyTermsID = parseInt(formik.values.supplyTermsID)
                if(props.selectedtradeEnquiryProductDetailID){
                    formik.values.tradeEnquiryProductDetailID = props.selectedtradeEnquiryProductDetailID
                }
                if(props.selected.tradeEnquiryProductSupplyplanID){
                    formik.values.tradeEnquiryProductSupplyplanID = props.selected.tradeEnquiryProductSupplyplanID
                }
                props.updateSupply(formik.values)
            }
        }
    }
    
    const handleMonth = (e)=>{
        formik.values.months = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.monthNames = e.nativeEvent.target[index].text;
    }

    const handleSupplyIntervals = (e) => {
        formik.values.supplyTermsID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.supplyTermsName = e.nativeEvent.target[index].text;
    }

    return (
        <Row className=' width-100l d-flex'>
            <div className='font-16 font-weight-500 color-707895'>
                Add Supply Plan
                </div>
                        <FormFields.InputField
                            md={3}
                            name='noOfSupply'
                            label='No. of Supplies'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.noOfSupply}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.noOfSupply ? formik.errors.noOfSupply : ''}
                            disabled = {props.contractterms.contractTypeID == "2" ? true : false}
                        />
                        <FormFields.InputField
                            md={3}
                            name='quantity'
                            label='Quanitity*'
                            classname='pt-1-rem'
                            type='number'
                            val={formik.values.quantity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.quantity ? formik.errors.quantity : ''}
                        />
                        <FormFields.InputField
                            md={3}
                            name='productSellingPrice'
                            label='Price'
                            classname='pt-1-rem'
                            type='number'
                            val={formik.values.productSellingPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.productSellingPrice ? formik.errors.productSellingPrice : ''}
                        />
                        <FormFields.InputSelect
                            md={3}
                            name='supplyTermsID'
                            label='Supply Terms*'
                            classname='pt-1-rem'
                            type='text'
                            optionField={props.isEdit == true ?
                                <SupplyTerms 
                                 supplyTermsID={props.selected.supplyTermsID} 
                                /> 
                                : 
                                props.isBackClicked ? 
                                <SupplyTerms 
                                    supplyTermsID={formik.values.supplyTermsID} 
                                /> 
                                :
                                <SupplyTerms 
                                    supplyTermsID={formik.values.supplyTermsID} 
                                />}
                            val={formik.values.supplyTermsID}
                            onChange={handleSupplyIntervals}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.supplyTermsID ? formik.errors.supplyTermsID : ''}
                            disabled = {props.contractterms.contractTypeID == "2" ? true : false}
                        />
                        <FormFields.InputSelect
                            md={3}
                            name='months'
                            label='Start Month'
                            classname='pt-1-rem'
                            type='text'
                            optionField={<MonthList val={parseInt(formik.values.months)}/>}
                            onChange={handleMonth}
                            onBlur={handleMonth}
                            errors={formik.touched.months ? formik.errors.months : ''}
                        />
                        <FormFields.InputSelect
                            md={3}
                            name='years'
                            label='Year'
                            classname='pt-1-rem'
                            type='text'
                            optionField={<YearList val={formik.values.years}/>}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.years ? formik.errors.years : ''}
                        />
                        <Col md={3} className='p-1-rem'>
                            <BlueButton value={props.isEdit?'Update':'Save'} clickEvent={props.isEdit?updateSupply:saveSupply} disabled = {disable}/>
                        </Col>
                    </Row>
    )
}
