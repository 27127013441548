import React, { useEffect, useState } from 'react'
import { Form, Card } from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import Moment from 'moment'
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import * as CONFIG from '../../../../helpers/config'
import PaymentMethodModal from './PaymentMethodModal';
import { useMsal } from "@azure/msal-react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ModalForExistingCustomer from './components/ModalForExistingCustomer';
import RatioModal from "./RatioModal"

function Index(props) {
    console.log(props, 'vishnu')
    const { instance } = useMsal();
    const [customerDetailsData, setCustomerDetailsData] = useState([])
    const [defaultCustomer, setDefaultCustomer] = useState()
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [paymentMethodSelection, setPaymentMethodSelection] = useState(false)
    const [dataFromModal, setDataFromModal] = useState()
    const [error, setError] = useState(true)
    const [issingle, setissingle] = useState(true)
    const [iscustomerModal, setIsCustomerModal] = useState(false)
    const [splitCustomerData, setsplitCustomerData] = useState([])
    const [showRatioModal, setShowRatioModal] = useState(false)
    const [selectecustomerid, setselectedcustomerid] = useState(0)
    const [iscustomersplit, setiscustomersplit] = useState(false)
    const [issplit, setissplit] = useState(false)
    const [paymentmethodforsplit, setpaymentmethodforsplit] = useState(false)
    const [paymentamount, setpaymentamount] = useState(0)
    const [errors, setErrors] = useState(false)
    const [paymentMethodSplitData, setPaymentMethodSplitData] = useState()
    const [customerID, setCustomerID] = useState()
    const [defaultCompanyCustomer, setDetaultCompanyCustomer] = useState()

    const [splitData, setSplitData] = useState()
    useEffect(() => {
        console.log('test console')
        if (props.tradeInitiateEdit == true) {
            if(props.TradeInitiateData.tradedetails.tradeScenarioID == 3){
                let list = []
                for(let i = 0; i < props.splitData.length; i++){
                    list.push(props.splitData[i].customerID)
                }
                customerData(list)
                setShow(true)
                setissingle(false)
            }
            else if(props.TradeInitiateData.tradedetails.tradeScenarioID == 1 || props.TradeInitiateData.tradedetails.tradeScenarioID == 2){
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "id": props.splitData[0].customerID
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        console.log(res.data.result[0]);
                        res.data.result[0].customerdata.customerContactInformation.filter(x => x.is_Default == true).map((list) => (
                            setDefaultCustomer(list)
                        ))
                        setCustomerDetailsData(res.data.result);
                        setShow(true)
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                })
            }
        }
        else {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.TradeEnquiryByIDData.enqiry.customerID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result[0]);
                    res.data.result[0].customerdata.customerContactInformation.filter(x => x.is_Default == true).map((list) => (
                        setDefaultCustomer(list)
                    ))
                    setCustomerDetailsData(res.data.result);
                    setShow(true)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }, [])

    useEffect(() => {
        console.log('test console 2')
        if (props.tradeCustomerPaymentDetails != null) {
            console.log(props)
            console.log('prev / next')
            setPaymentMethodSelection(true)
            let list = []
            if(Array.isArray(props.splitData) == true && props.splitData[0].tradeScenarioID != 2){
                for(let i = 0; i < props.splitData.length; i++){
                    list.push(props.splitData[i].customerID)
                }
                console.log(list)
                setSplitData(props.splitData)
                setPaymentMethodSplitData(props.splitData)
                customerData(list)
                if(props.tradeInitiateEdit == true ){
                    if(props.TradeInitiateData.tradedetails.tradeScenarioID == 2){
                        if(props.tradeCustomerPaymentDetails[0].tradeCustomerPaymentDetail != null){
                            setDataFromModal(props.tradeCustomerPaymentDetails[0].tradeCustomerPaymentDetail)
                        }
                        else{
                            console.log('else condition 1')
                            setDataFromModal(props.tradeCustomerPaymentDetails)
                        }
                        
                    }
                    if(props.tradeCustomerPaymentDetails[0].tradeCustomerPaymentDetail != null){
                        console.log('else condition 2')
                        setDataFromModal(props.tradeCustomerPaymentDetails[0].tradeCustomerPaymentDetail)
                    }
                    else{
                        console.log('else condition 3')
                        setDataFromModal(props.tradeCustomerPaymentDetails)
                    }
                }
                else{
                    console.log('else 4')
                    setDataFromModal(props.tradeCustomerPaymentDetails)
                }
                
            }
            else{
                console.log('else 5')
                setDataFromModal(props.tradeCustomerPaymentDetails)
            } 
        }
    }, [props])

    const clearData = () => {
        setSplitData(null)
        setPaymentMethodSelection(false)
        setError(true)
    }

    useEffect(()=>{
        console.log(splitData)
    },[splitData])

    const handleSplitRatio = () => {
        setShowRatioModal(true)
    }

    const handleRatioData = (data) => {
        for (let i = 0; i < splitCustomerData.length; i++) {
            for (let x = 0; x < data.length; x++) {
                if (data[x].customerID == splitCustomerData[i].customerdata.customerID) {
                    splitCustomerData[i].customerdata.ratio = data[x].ratio
                }
            }
        }
        console.log(splitCustomerData)
        console.log(data)
        setSplitData(data)
        setErrors(false)
    }

    const moveToNext = () => {
        if(issingle == true){
            props.addCustomerDetailToApi(dataFromModal)
            props.onNext(props, 3)
        }
        else{
            console.log(splitData)
            if(splitData != null){
                console.log(splitData)
                if(defaultCompanyCustomer != null){
                    for(let i = 0; i < splitData.length; i++){
                        if(splitData[i].customerID == defaultCompanyCustomer){
                            splitData[i].Is_DefaultCompany = true
                            splitData[i].Is_DefaultCustomer = true
                        }
                        else{
                            splitData[i].Is_DefaultCompany = false
                            splitData[i].Is_DefaultCustomer = false
                        }
                    }
                }
              
                console.log(splitData)
                //props.handleSplitCompanyData(splitData, updatedCompany)
            }
            props.handleSplitCustomerData(splitData)
            props.addCustomerDetailToApi(splitData)
            props.onNext(props, 3)
        }
        //
    }

    const moveToBack = () => {
        props.onBack(props, 1)
    }


    const handlePaymentMethod = () => {
        setShowModal(true)
        //setIsFirst(true)
    }

    const handlePaymentMethodforSplit = (e) => {
        if (splitData != null && splitData.length > 0) {
            let customerid = parseInt(e.currentTarget.id)
            let filter = splitData.filter(x => x.customerID == customerid)[0]
            let paymentamount = filter.ratioAmount
            setpaymentamount(paymentamount)
            setselectedcustomerid(customerid)
            console.log(paymentamount)
            console.log(customerid)
            setiscustomersplit(true)
            setissplit(true)
            console.log(paymentMethodSplitData)
            for(let i = 0; i < paymentMethodSplitData.length; i++){
                if(paymentMethodSplitData[i].customerID == parseInt(e.currentTarget.id)){
                    console.log('matched')
                    if(paymentMethodSplitData[i].tradeCustomerPaymentDetail != null){
                        console.log(paymentMethodSplitData[i])
                        setDataFromModal(paymentMethodSplitData[i].tradeCustomerPaymentDetail)
                    }
                    else{
                        console.log('else')
                        setDataFromModal(null)
                    }
                }
            }
            setpaymentmethodforsplit(true)
            setErrors(false)
        }
        else {
            setErrors(true)
        }
    }

    const close = () => {
        setShowModal(false)
        setiscustomersplit(false)
        setissplit(false)
        setpaymentmethodforsplit(false)
    }

    const handleDataFromModal = (data) => {
        console.log(data, 'after save')
        if (issingle) {
            setDataFromModal(data)
            setPaymentMethodSelection(true)
        }
        else {
            console.log('logged')
            let filter = splitData.filter(x => x.customerID == selectecustomerid)[0]
            filter.tradeCustomerPaymentDetail = data
            let remaining = splitData.filter(x => x.customerID != selectecustomerid)
            remaining.push(filter)
            console.log(remaining)
            setSplitData(remaining)
            setDataFromModal(data)
            setPaymentMethodSelection(true)
        }
    }



    useEffect(() => {
        console.log('test console 3')
        console.log(dataFromModal)
        if (dataFromModal != null) {
            setError(false)
        }
    }, [dataFromModal])

    useEffect(() => {
        console.log(error)
    }, [error])

    const handleCustomer = () => {
        console.log('inside handle customer')
        setIsCustomerModal(!iscustomerModal)
    }

    const handleCustomerCancel = () => {
        setIsCustomerModal(!iscustomerModal)
        if (splitCustomerData.length == 0) {
            setissingle(true)
        }
    }

    const closeRatioModal = () => {
        setShowRatioModal(false)
    }

    const handleSingle = () => {
        setissingle(false)
    }
    const customerData = (data) => {
        console.log(data)
        //handleCustomer()
        // setCustomerDetailsData(data)
        let customerdata = []
        let len = 1
        if(data.length > 1){
            setissingle(false)
        }
        let splitDataList = []
        data.map((d, k) => {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": d
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result);
                    customerdata.push(res.data.result[0])
                    if (len == data.length) {
                        console.log('hoover')
                        setissingle(false)
                        setsplitCustomerData(customerdata)
                    }
                    len++
                   
                    //handleCustomer()
                    
                    // if(customerdata.length > 1){
                    //     console.log(customerdata)
                    //     customerdata.map((values, k)=>{
                    //         let object = props.splitData
                    //         if(!Array.isArray(props.splitData) == true){
                    //             object.customerID = d
                    //             console.log( object.customerID )
                    //             splitDataList.push(object)
                    //         }
                    //     })
                    //     console.log(splitDataList, 'split data list')
                    // }

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        })
        console.log(splitDataList, 'Split data')
        console.log(customerdata, 'customer data')
    }

    const handleSplitData = (data) => {
        console.log(props.splitData)
        console.log(data)
        setissingle(false)
        handleCustomer()
        setPaymentMethodSplitData(data)
       
    }

    useEffect(()=>{

    },[paymentMethodSplitData])

    useEffect(() => {
        console.log(customerDetailsData)
        if(customerDetailsData.length > 0){
            setShow(true)
        }
    }, [customerDetailsData])


    const trackCheck = (e) => {
        console.log(e.target.id)
        setDetaultCompanyCustomer(parseInt(e.target.id))
    }


    return (
        <>
            <Card>
                <Card.Body className='form-card-body mb-10-rem p-md-4 p-2 scrollContract'>
                    <div className='table-ttile-color font-14'>
                        Customer Trade Type
                        <div className='mt-1-rem d-flex justify-content-between'>
                            {(props.tradeInitiateEdit == true && props.TradeInitiateData.tradedetails.tradeScenarioID == 3 ) 
                            || 
                            !Array.isArray(props.splitData) == true  ?
                                <ButtonGroup className='d-flex align-items-start'>
                                    <ToggleButton
                                        key={3}
                                        id='radio-3'
                                        type="radio"
                                        variant={'outline-primary'}
                                        name="Internal2"
                                        checked={issingle}
                                        value={3}
                                        onChange={(e) => {
                                            setissingle(true)
                                        }}
                                    >
                                        Single
                                    </ToggleButton>
                                    <ToggleButton
                                        key={4}
                                        id='radio-4'
                                        type="radio"
                                        variant={'outline-primary'}
                                        name="External2"
                                        checked={!issingle}
                                        value={4}
                                        onChange={(e) => {
                                            setissingle(false)
                                            setPaymentMethodSelection(false)
                                            handleCustomer()
                                        }}
                                    >
                                        Split
                                    </ToggleButton>
                                </ButtonGroup>
                            :
                            props.splitData[0].tradeCustomerPaymentDetail != null && (props.tradeInitiateEdit == true && props.TradeInitiateData.tradedetails.tradeScenarioID == 3 ) ?
                            <ButtonGroup className='d-flex align-items-start'>
                            <ToggleButton
                                key={3}
                                id='radio-3'
                                type="radio"
                                variant={'outline-primary'}
                                name="Internal2"
                                checked={issingle}
                                value={3}
                                onChange={(e) => {
                                    setissingle(true)
                                }}
                            >
                                Single
                            </ToggleButton>
                            <ToggleButton
                                key={4}
                                id='radio-4'
                                type="radio"
                                variant={'outline-primary'}
                                name="External2"
                                checked={!issingle}
                                value={4}
                                onChange={(e) => {
                                    setissingle(false)
                             
                                    handleCustomer()
                                }}
                            >
                                Split
                            </ToggleButton>
                        </ButtonGroup>
                        :
                        ''
                            
                        }
                        <div className='table-ttile-color font-14 mb-3 font-weight-500'>
                                {!issingle &&
                                    <BlueBorderButton value='Select Customer' clickEvent={handleCustomer} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='mb-2-rem'>
                        {issingle == false && splitCustomerData.length > 0 &&
                            <>
                                <div className=''>
                                    <div className='d-flex align-items-center'>
                                        <div className='table-ttile-color font-14 m-4'>
                                            Split Ratio
                                        </div>
                                        <div className='ms-4'>
                                            <Tooltip placement="top" title={'Edit Ratio'}>
                                                <button className='border-0 bg-transparent' onClick={handleSplitRatio}>
                                                    <img src='/assets/images/supports/penIcon.svg' />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 px-4 grey-color col-6">
                                    <div className=''>
                                        <div className='d-flex align-items-center'>
                                            {splitCustomerData.map((values, k) => (
                                                <>
                                                    <div className=''>
                                                        {values.customerdata.customerName}
                                                    </div>
                                                    <div className='ms-2'>
                                                        <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                            {values.customerdata.ratio != null ? values.customerdata.ratio + '%' : props.splitData[k] != null ? props.splitData[k].ratio  + '%' : '0%'}
                                                        </div>
                                                    </div>
                                                    {k < 1 ?
                                                        <div className='mx-5 seperator' />
                                                        :
                                                        ''
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {show == true ?
                        <>
                            {iscustomerModal &&
                                <ModalForExistingCustomer clearData = {clearData} handleSingle = {handleSingle} cancel={handleCustomerCancel} customerData={customerData} splitData = {props.splitData} handleSplitData = {handleSplitData} />
                            }
                            {issingle == true ?
                                <>
                                    {customerDetailsData.map((cdata, k) => {
                                        return (
                                            <div className='customer_selection_block mb-1-rem' >
                                                <div className='d-flex justify-content-between'>
                                                    <div className='table-ttile-color font-14 mb-3 font-weight-500'>
                                                        Customer Details
                                                    </div>
                                                </div>
                                                
                                                <div className="p-md-4 p-2 grey-color col-12">
                                                    <div className='d-flex align-items-center border_table pb-4   '>
                                                        <div className='col-4'>
                                                            <div className='font-weight-600 textHead'>{cdata.customerdata.customerCode}</div>
                                                            <div className='font-weight-400 color-697382'>{cdata.customerdata.customerName}</div>
                                                        </div>
                                                        <div className='mx-5 seperator' />
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-400 color-697382'>Address</div>
                                                            <div className='font-weight-400 textHead'>{cdata.customerdata.registeredBuildingName}, {cdata.customerdata.registeredStreetName}, {cdata.customerdata.registeredCityName}</div>
                                                        </div>
                                                    </div>
                                                    <h4 className='table-ttile-color font-14 mb-1 pt-3'>Contact Person Details</h4>
                                                    {cdata.customerdata.customerContactInformation.length > 0 &&
                                                        <>
                                                            {cdata.customerdata.customerContactInformation.map((contact, k) => {
                                                                return (
                                                                    <>
                                                                        {contact.is_Default == true &&
                                                                            <>
                                                                                <div className='row align-items-center justify-content-between mt-3'>
                                                                                    <div className='col-12 col-md-3 mb-md-0 mb-3'>
                                                                                        <div className='font-weight-600 color-243448'>{contact.fullName}</div>
                                                                                        <div className='font-weight-400 color-697382'>{contact.designation}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Email ID</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.email}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Mobile No</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.mobileNo}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Telephone No</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.telephoneNo}</div>
                                                                                    </div>
                                                                                    <div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                                <div className='pt-4 p-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div>
                                                            <h4 className='table-ttile-color font-14 m-0'>Payment Method</h4>
                                                        </div>
                                                        <div className='ms-4'>
                                                            <Tooltip placement="top" title={paymentMethodSelection == false ? 'Add payment method(s)' : 'Change payment method(s)'}>
                                                                <button className='border-0 bg-transparent' onClick={handlePaymentMethod}>
                                                                    <img src='/assets/images/supports/penIcon.svg' />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                {paymentMethodSelection == false  ?
                                                    <div className="p-md-4 p-2 grey-color col-3 mt-3">
                                                        Please select payment method(s)
                                                    </div>
                                                    :
                                                    
                                                    dataFromModal != null ?
                                                        <>
                                                            <div className='row ps-2'>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='row'>
                                                                        {dataFromModal.map((value, k) => (
                                                                            value.is_Deleted == null ?
                                                                                <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                        <div className='d-flex'>
                                                                                            <Form.Check
                                                                                                className=''
                                                                                                type='checkbox'
                                                                                                name='paymentMethod'
                                                                                                id={value.customerPaymentTypeID}
                                                                                                value='paymentMethod'
                                                                                                defaultChecked={true}
                                                                                                disabled={true}
                                                                                            />
                                                                                            <div className='ms-2'>
                                                                                                {value.customerPaymentTypeName}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ms-2'>
                                                                                            <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                                $<NumberFormat
                                                                                                    value={value.customerPaymentAmount}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                value.is_Deleted == false ?
                                                                                    <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                                            <div className='d-flex'>
                                                                                                <Form.Check
                                                                                                    className=''
                                                                                                    type='checkbox'
                                                                                                    name='paymentMethod'
                                                                                                    id={value.customerPaymentTypeID}
                                                                                                    value='paymentMethod'
                                                                                                    defaultChecked={true}
                                                                                                    disabled={true}
                                                                                                />
                                                                                                <div className='ms-2'>
                                                                                                    {value.customerPaymentTypeName}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='ms-2'>
                                                                                                <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                                    $<NumberFormat
                                                                                                        value={value.customerPaymentAmount}
                                                                                                        displayType={'text'}
                                                                                                        thousandSeparator={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        ''

                                                }
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <>
                                    {splitCustomerData.map((cdata, k) => {
                                        return (
                                            <div className='customer_selection_block mb-1-rem' >
                                                <div className='d-flex justify-content-between mb-3 align-items-center'>
                                                    <div className='table-ttile-color font-14  font-weight-500'>
                                                        Customer Details
                                                    </div>
                                                    <div className = 'col-md-3 d-flex align-items-center'>
                                                    <div>
                                                    <p className = 'font-weight-400 color-697382 mb-0'>Set Customer as default</p>
                                                    </div>
                                                    <Form.Check 
                                                        type='radio'
                                                        name='values.company.companyID'
                                                        id={cdata.customerdata.customerID}
                                                        defaultChecked = {props.splitData != null && props.splitData[k] != null && props.splitData[k].Is_DefaultCompany == true  ? true : false}
                                                        onChange = {trackCheck}
                                                        className='mx-3 slectCustomer'
                                                    />
                                                </div>
                                                </div>
                                       
                                                <div className="p-md-4 p-2 grey-color col-12">
                                                    <div className='d-flex align-items-center border_table pb-4   '>
                                                        <div className='col-4'>
                                                            <div className='font-weight-600 textHead'>{cdata.customerdata.customerCode}</div>
                                                            <div className='font-weight-400 color-697382'>{cdata.customerdata.customerName}</div>
                                                        </div>
                                                        <div className='mx-5 seperator' />
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-400 color-697382'>Address</div>
                                                            <div className='font-weight-400 textHead'>{cdata.customerdata.registeredBuildingName}, {cdata.customerdata.registeredStreetName}, {cdata.customerdata.registeredCityName}</div>
                                                        </div>
                                                    </div>

                                                    <h4 className='table-ttile-color font-14 mb-1 pt-3'>Contact Person Details</h4>
                                                    {cdata.customerdata.customerContactInformation.length > 0 &&
                                                        <>
                                                            {cdata.customerdata.customerContactInformation.map((contact, k) => {
                                                                return (
                                                                    <>
                                                                        {contact.is_Default == true &&
                                                                            <>
                                                                                <div className='row align-items-center justify-content-between mt-3'>
                                                                                    <div className='col-12 col-md-3 mb-md-0 mb-3'>
                                                                                        <div className='font-weight-600 color-243448'>{contact.fullName}</div>
                                                                                        <div className='font-weight-400 color-697382'>{contact.designation}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Email ID</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.email}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Mobile No</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.mobileNo}</div>
                                                                                    </div>
                                                                                    <div className='col-12 col-md-3  mb-md-0 mb-3'>
                                                                                        <div className='font-weight-400 color-697382'>Telephone No</div>
                                                                                        <div className='font-weight-600 color-243448'>{contact.telephoneNo}</div>
                                                                                    </div>
                                                                                    <div>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                                <div className='pt-4 p-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div>
                                                            <h4 className='table-ttile-color font-14 m-0'>Payment Method</h4>
                                                        </div>
                                                        <div className='ms-4'>
                                                            <Tooltip placement="top" title={paymentMethodSelection == false ? 'Add payment method(s)' : 'Change payment method(s)'}>
                                                                <button className='border-0 bg-transparent' id={cdata.customerdata.customerID} onClick={handlePaymentMethodforSplit}>
                                                                    <img src='/assets/images/supports/penIcon.svg' />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {errors &&
                                                            <div className='input_error'>Error: Please choose ratio values first.</div>
                                                        }
                                                    </div>
                                                </div>
                                                {paymentMethodSelection == false ?
                                                    <div className="p-md-4 p-2 grey-color col-3 mt-3">
                                                        Please select payment method(s)
                                                    </div>
                                                    :
                                                    dataFromModal != null ?
                                                        <>
                                                            <div className='row ps-2'>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='row'>
                                                                        {splitData.filter(x => x.customerID == cdata.customerdata.customerID)[0].tradeCustomerPaymentDetail != null && splitData.filter(x => x.customerID == cdata.customerdata.customerID)[0].tradeCustomerPaymentDetail.map((value, k) => (
                                                                            value.is_Deleted == null ?
                                                                                <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                        <div className='d-flex'>
                                                                                            <Form.Check
                                                                                                className=''
                                                                                                type='checkbox'
                                                                                                name='paymentMethod'
                                                                                                id ={value.customerPaymentTypeID}
                                                                                                value ='paymentMethod'
                                                                                                defaultChecked={true}
                                                                                                disabled={true}
                                                                                            />
                                                                                            <div className='ms-2'>
                                                                                                {value.customerPaymentTypeName}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ms-2'>
                                                                                            <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                                $<NumberFormat
                                                                                                    value={value.customerPaymentAmount}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                value.is_Deleted == false ?
                                                                                    <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                                            <div className='d-flex'>
                                                                                                <Form.Check
                                                                                                    className=''
                                                                                                    type='checkbox'
                                                                                                    name='paymentMethod'
                                                                                                    id={value.customerPaymentTypeID}
                                                                                                    value='paymentMethod'
                                                                                                    defaultChecked={true}
                                                                                                    disabled={true}
                                                                                                />
                                                                                                <div className='ms-2'>
                                                                                                    {value.customerPaymentTypeName}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='ms-2'>
                                                                                                <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                                    $<NumberFormat
                                                                                                        value={value.customerPaymentAmount}
                                                                                                        displayType={'text'}
                                                                                                        thousandSeparator={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :

                                                        splitData.filter(x => x.customerID == cdata.customerdata.customerID)[0].tradeCustomerPaymentDetail != null ? 
                                                        <>
                                                        
                                                        <div className='row ps-2'>
                                                            <div className='col-md-12 col-sm-12'>
                                                                <div className='row'>
                                                                    {splitData.filter(x => x.customerID == cdata.customerdata.customerID)[0].tradeCustomerPaymentDetail != null && splitData.filter(x => x.customerID == cdata.customerdata.customerID)[0].tradeCustomerPaymentDetail.map((value, k) => (
                                                                        value.is_Deleted == null ?
                                                                            <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='d-flex'>
                                                                                        <Form.Check
                                                                                            className=''
                                                                                            type='checkbox'
                                                                                            name='paymentMethod'
                                                                                            id ={value.customerPaymentTypeID}
                                                                                            value ='paymentMethod'
                                                                                            defaultChecked={true}
                                                                                            disabled={true}
                                                                                        />
                                                                                        <div className='ms-2'>
                                                                                            {value.customerPaymentTypeName}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='ms-2'>
                                                                                        <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                            $<NumberFormat
                                                                                                value={value.customerPaymentAmount}
                                                                                                displayType={'text'}
                                                                                                thousandSeparator={true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            value.is_Deleted == false ?
                                                                                <div className="py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                        <div className='d-flex'>
                                                                                            <Form.Check
                                                                                                className=''
                                                                                                type='checkbox'
                                                                                                name='paymentMethod'
                                                                                                id={value.customerPaymentTypeID}
                                                                                                value='paymentMethod'
                                                                                                defaultChecked={true}
                                                                                                disabled={true}
                                                                                            />
                                                                                            <div className='ms-2'>
                                                                                                {value.customerPaymentTypeName}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ms-2'>
                                                                                            <div className='payment-method-pill d-flex align-items-center justify-content-center'>
                                                                                                $<NumberFormat
                                                                                                    value={value.customerPaymentAmount}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                ''
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                        :
                                                        <div className="p-md-4 p-2 grey-color col-3 mt-3">
                                                            Please select payment method(s)
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </>
                        :
                        ''
                    }
                </Card.Body>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span><BlueBorderButton value='Prev' clickEvent={moveToBack} /></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext} disabled={error} /></span>
                </Card.Footer>
            </Card>
            {showModal == true ?
                <PaymentMethodModal
                    customerID = {selectecustomerid}
                    splitData = {props.splitData}
                    close={close}
                    selectedCustomer = {splitCustomerData}
                    currentSupplyPlan={props.currentSupplyPlan}
                    handleDataFromModal={handleDataFromModal}
                    dataFromModal={dataFromModal}
                    tradeInitiateEdit={props.tradeInitiateEdit}
                />
                :
                ''
            }
            {paymentmethodforsplit == true ?
                <PaymentMethodModal
                    paymentMethodSplitData = {paymentMethodSplitData}
                    customerID = {selectecustomerid}
                    splitData = {props.splitData}
                    selectedCustomer = {splitCustomerData}
                    close={close}
                    currentSupplyPlan={props.currentSupplyPlan}
                    isPaymentsplit={true}
                    paymentamount={paymentamount}
                    handleDataFromModal={handleDataFromModal}
                    dataFromModal={dataFromModal}
                    tradeInitiateEdit={props.tradeInitiateEdit}
                />
                :
                ''
            }
            {showRatioModal == true ?
                <RatioModal
                    updatedCompany={splitCustomerData}
                    currentSupplyPlan={props.currentSupplyPlan}
           
                    currentTrade = {props.TradeInitiateData.enqiry == null ?
                            props.TradeInitiateData.tradedetails == null ? 
                            props.TradeEnquiryByIDData.enqiry
                            : 
                            props.TradeInitiateData.tradedetails.tradeEnquiry
                        :
                        props.TradeInitiateData.enqiry
                    }
                    data={splitData}
                    splitData = {props.splitData}
                    close={closeRatioModal}
                    handleRatioData={handleRatioData}
                />
                :
                ''
            }
        </>
    )
}

export default Index