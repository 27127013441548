import React, {useEffect, useState} from 'react'
import {Row,  Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as FormFields from "../../../../../components/formcomponents"
import { IndustryListSupplier } from '../../../../../helpers/API/Api';
function Index(props) {
    const formik = useFormik({
        initialValues:
        props.isEdit == true ? 
        {
            supplierIndustryTypeID: props.dataForEdit.supplierdata.supplierIndustryTypeID,
            numberOfManufacturingSite: props.dataForEdit.supplierdata.numberOfManufacturingSite,
            numberOfStorageFacilities: props.dataForEdit.supplierdata.numberOfStorageFacilities,
        }
        
        :
        
        {
            supplierIndustryTypeID: null,
            numberOfManufacturingSite: 0,
            numberOfStorageFacilities: 0,
        },
      
        validate: values => {
            
            let errors = {}

            const letters = /^[A-Za-z ]+$/;
            
            if(!values.parentCompanyUnitNo){
                errors.parentCompanyUnitNo = "Please fill in Name"
            }
            return errors
        }

        
    })
    useEffect(()=>{

        formik.values.supplierIndustryTypeID =  formik.values.supplierIndustryTypeID != null ? parseInt(formik.values.supplierIndustryTypeID) : formik.values.supplierIndustryTypeID
        formik.values.numberOfManufacturingSite = parseInt(formik.values.numberOfManufacturingSite)
        formik.values.numberOfStorageFacilities = parseInt(formik.values.numberOfStorageFacilities)
        props.saveMarketingInformation(formik.values)
    }, [formik])

  return (
    <Row className='d-flex p-2-rem' style={{background: '#fff'}}>
    <div className = 'd-flex justify-content-between'>
        <div className='font-16 font-weight-600 color-707895'>
        Marketing Information
        </div>
    </div>
    <Row className='d-flex'>
                <Row>
                    <FormFields.InputSelect
                        md={3}
                        name='supplierIndustryTypeID'
                        label='Industry'
                        classname='pt-1-rem'
                        type='text'
                        optionField = {props.isEdit == true ? <IndustryListSupplier supplierIndustryTypeID = {props.dataForEdit.supplierdata.supplierIndustryTypeID}/> : <IndustryListSupplier/>}
                        onChange={formik.handleChange}
                        onBlur = {formik.handleBlur}
                    />
                    <FormFields.InputField
                        md={3}
                        name='numberOfManufacturingSite'
                        label='No. of Manufacturing Sites'
                        classname='pt-1-rem'
                        type='text'
                        val={parseInt(formik.values.numberOfManufacturingSite)}
                        onChange={formik.handleChange}
                        onBlur = {formik.handleBlur}
                    />
                    <FormFields.InputField
                        md={3}
                        name='numberOfStorageFacilities'
                        label='No. of Storage Facilities'
                        classname='pt-1-rem'
                        type='text'
                        val={parseInt(formik.values.numberOfStorageFacilities)}
                        onChange={formik.handleChange}
                        onBlur = {formik.handleBlur}
                    />
                 
                </Row>
    </Row>
    </Row>
  )
}

export default Index