import React from 'react'
import { useState } from 'react'
import { Row } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import * as FormFields from "../../../components/formcomponents"
import * as ButtonComponent from "../../../components/buttoncomponents"
import { useFormik } from 'formik'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";

export default function AddExternalUser(props) {
    const { instance } = useMsal();
    const [modal, setmodal] = useState(props.isOpen)
    const [errors, seterror] = useState(false)
    const toggle = () =>{
        setmodal(!modal)
        props.toggle()
    }

    const formik = useFormik({
        initialValues:{
            companyName: '',
            userName: '',
            userEmail: '',
            userMobileNo: '',
            department: '',
            designation: '',
            userAvatar: '',
        },
        validate : values =>{
            let errors ={};
            seterror(false)
            if(!formik.values.userName){
                errors.userName = "Please enter user name!"
            }
            if(!formik.values.userEmail){
                errors.userEmail = "Please enter user email!"
            }
            return errors;
        }
    })

    const saveUser = ()=>{
        if(formik.values.userName == '' || formik.values.userEmail == ''){
            seterror(true)
        }
        else{
            seterror(false)
            const json_list =  {
                "companyName": formik.values.companyName,
                "userName": formik.values.userName,
                "userEmail": formik.values.userEmail,
                "userMobileNo": formik.values.userMobileNo,
                "department": formik.values.department,
                "designation": formik.values.designation,
                "userAvatar": "AA",
                "is_Internal": false,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            axios.post(CONFIG.PCS_SERVER_URL+CONFIG.ADD_MEETING_USER, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.getUsers()
                props.toggle()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='mt-10-rem property_info_modal mt-5'>
            <ModalHeader toggle={toggle}>
                <h5>EXTERNAL HOST INFO</h5>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <span className='border_bottom_input'>
                        <FormFields.BottomBorderInputField
                            md={12}
                            label='Company Name'
                            name='companyName'
                            val={formik.values.companyName}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                        />
                    </span>
                    <span className='border_bottom_input'>
                        <FormFields.BottomBorderInputField
                            md={12}
                            label='Name'
                            name='userName'
                            val={formik.values.userName}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                            errors={formik.touched.userName?formik.errors.userName:''}
                        />
                    </span>
                    <span className='border_bottom_input d-flex justify-content-between'>
                        <FormFields.BottomBorderInputField
                            md={5}
                            label='Email'
                            name='userEmail'
                            val={formik.values.userEmail}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                            errors={formik.touched.userEmail?formik.errors.userEmail:''}
                        />
                        <FormFields.BottomBorderInputField
                            md={5}
                            label='Mobile No'
                            name='userMobileNo'
                            val={formik.values.userMobileNo}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                        />
                    </span>
                    <span className='border_bottom_input d-flex justify-content-between'>
                        <FormFields.BottomBorderInputField
                            md={5}
                            label='Department'
                            name='department'
                            val={formik.values.department}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                        />
                        <FormFields.BottomBorderInputField
                            md={5}
                            label='Designation'
                            name='designation'
                            val={formik.values.designation}
                            onChange = {formik.handleChange}
                            onBlur ={formik.handleBlur}
                        />
                    </span>
                </Row>
            </ModalBody>
            <ModalFooter className='p-2-rem d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Back' clickEvent={toggle}/></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveUser} /></span>
            </ModalFooter>
        </Modal>
    )
}
