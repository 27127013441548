import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import NumberFormat from 'react-number-format';
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import * as CONFIG from '../../../helpers/config'
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import CustomerBankSelectionModal from './Modals/CustomerBankSelectionModal';
import SupplierBankSelectionModal from './Modals/SupplierBankSelectionModal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";
import { BackButton, BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'

function LCDocuments(props) {
    console.log(props)
    const { instance } = useMsal();
    const [lcCustomerDocument, setLcCustomerDocument] = useState('')
    const [lcCustomerDocumentFile, setLcCustomerDocumentFile] = useState('')
    const [lcSupplierDocument, setLcSupplierDocument] = useState('')
    const [lcSupplierDocumentFile, setLcSupplierDocumentFile] = useState('')
    const [error, setError] = useState(false)
    const [errorSupplier, setErrorSupplier] = useState(false)
    const [lcCustomerDate, setLcCustomerDate] = useState(false);
    const [defaultBankCustomer, setDefaultBankCustomer] = useState()
    const [defaultBankSupplier, setDefaultBankSupplier] = useState()
    const [selectedCustomerBank, setSelectedCustomerBank] = useState()
    const [selectedSupplierBank, setSelectedSupplierBank] = useState()
    const [customerBankAccountModal, setCustomerBankAccountModal] = useState(false)
    const [supplierBankAccountModal, setSupplierBankAccountModal] = useState(false)
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotalSupplier, setOverAllTotalSupplier] = useState()
    const [overAllTotalCustomer, setOverAllTotalCustomer] = useState()
    const [customerData, setCustomerData] = useState([])
    const [defaultSelected, setDefaultSelected] = useState(0)
    const [defaultCustomer, setDefaultCustomer] = useState([])

    const MySwal = withReactContent(Swal);
    //const location = useLocation()
    let navigate = useNavigate();
    console.log(props)
    const tradeData = props.tradeData
    //console.log(location)
    const { tab } = props.tab
    const updateCustomer = props.lcDocumentData != null ? props.lcDocumentData : ''
    const updateSupplier = props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier : ''

    useEffect(() => {
        let json_list1 = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID) // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list1, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if (res.data.result != null) {
                    console.log(res.data.result)
                    setCustomerData(res.data.result)
                    res.data.result.map((re, k) => {
                        if (re.is_DefaultCustomer == true) {
                            setDefaultSelected(re.customer.customerID)
                            console.log(re)
                            setDefaultCustomer(re)
                        }
                    })
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    useEffect(() => {
        if (props.lcDocumentData != null) {
            console.log('aasas')
            console.log(props.lcDocumentData)
            setLcCustomerDocument(props.lcDocumentData.lcCustomerDocumentName)
            setLcCustomerDocumentFile(props.lcDocumentData.lcCustomerDocument)
            console.log(props.lcDocumentData.lcCustomerDocumentName)
            for (let i = 0; i < props.lcDocumentData.customer.customerBankAccountInformation.length; i++) {
                if (props.lcDocumentData.customer.customerBankAccountInformation[i].customerBankAccountInformationID == props.lcDocumentData.customerBankAccountInformationID) {
                    console.log('if phase 2')
                    setSelectedCustomerBank(props.lcDocumentData.customer.customerBankAccountInformation[i])
                }
            }
        }
    }, [props])

    useEffect(() => {
        if (props.lcDocumentDataSupplier != null) {
            console.log('aasas')
            console.log(props.lcDocumentDataSupplier)
            setLcSupplierDocument(props.lcDocumentDataSupplier.lcSupplierDocumentName)
            setLcSupplierDocumentFile(props.lcDocumentDataSupplier.lcSupplierDocument)

            console.log(props.lcDocumentDataSupplier.lcSupplierDocumentName)
            for (let i = 0; i < props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation.length; i++) {
                if (props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation[i].supplierBankAccountInformationID == props.lcDocumentDataSupplier.supplierBankAccountInformationID) {
                    console.log('if phase 2')
                    setSelectedCustomerBank(props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation[i])
                }
            }
        }
    }, [props])

    useEffect(() => {
        console.log(customerData)
        if (props.lcDocumentData != null) {
            for (let i = 0; i < props.lcDocumentData.customer.customerBankAccountInformation.length; i++) {
                if (props.lcDocumentData.customer.customerBankAccountInformation[i].customerBankAccountInformationID == props.lcDocumentData.customerBankAccountInformationID) {
                    console.log('if phase 2')
                    setSelectedCustomerBank(props.lcDocumentData.customer.customerBankAccountInformation[i])
                }
            }
        }
    }, [customerData])

    useEffect(() => {
        if (tradeData.tradedetails.supplier.supplierBankAccountInformation != null) {
            console.log('arrray test')
            if (props.lcDocumentDataSupplier != null) {
                for (let i = 0; i < props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation.length; i++) {
                    if (props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation[i].supplierBankAccountInformationID == props.lcDocumentDataSupplier.supplierBankAccountInformationID) {
                        console.log('if phase 2')
                        setSelectedSupplierBank(props.lcDocumentDataSupplier.supplier.supplierBankAccountInformation[i])
                    }
                }
            }
            else if (tradeData.tradedetails.supplier.supplierBankAccountInformation != null) {
                console.log('test')
                for (let i = 0; i < tradeData.tradedetails.supplier.supplierBankAccountInformation.length; i++) {
                    console.log(tradeData.tradedetails.supplier.supplierBankAccountInformation[i])
                    if (tradeData.tradedetails.supplier.supplierBankAccountInformation[i].is_Default == true) {
                        console.log(tradeData.tradedetails.supplier.supplierBankAccountInformation[i])
                        setSelectedSupplierBank(tradeData.tradedetails.supplier.supplierBankAccountInformation[i])
                    }
                }
            }
        }
    }, [])
    useEffect(() => {
        let listValues = []
        let listValuesSupply = []
        let listValuesTax = []
        if (tradeData != null) {
            tradeData.tradedetails.tradeProductDetail.map((list, k) => (
                listValues.push(parseInt((list.productSellingPrice) * (list.quantity))),
                listValuesSupply.push(parseInt((list.productBuyingPrice) * (list.quantity)))
                // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
            ))
            console.log(listValues)
            let sum = 0
            let sumSupplier = 0
            let sumTax = parseInt((tradeData.tradedetails.tradeEnquiry.tax1)) + parseInt((tradeData.tradedetails.tradeEnquiry.tax2))
            for (let i = 0; i < listValues.length; i++) {
                sum += listValues[i]
            }
            for (let i = 0; i < listValuesSupply.length; i++) {
                sumSupplier += listValuesSupply[i]
            }

            console.log(sum)
            setSubTotal(sum)
            setTotalTax(sumTax)
            setOverAllTotalCustomer(sum + sumTax)
            setOverAllTotalSupplier(sumSupplier + sumTax)
        }
    }, [])

    useEffect(() => {

    }, [])
    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData.tradeID,
            customerID: tradeData.tradedetails.customerID,
            supplierID: tradeData.tradedetails.supplierID,
            customerBankAccountInformationID: props.lcDocumentData != null ? props.lcDocumentData.customerBankAccountInformationID : null,
            supplierBankAccountInformationID: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.supplierBankAccountInformationID : null,
            lcCustomerDate: props.lcDocumentData != null ? Moment(props.lcDocumentData.lcCustomerDate) : null,
            lcSupplierDate: props.lcDocumentDataSupplier != null ? Moment(props.lcDocumentDataSupplier.lcSupplierDate) : null,
            lcCustomerDocument: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerDocument : "",
            lcSupplierDocument: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierDocument : "",
            lcCustomerDocumentName: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerDocumentName : "",
            lcSupplierDocumentName: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierDocumentName : "",
            lcIssuingCustomerBank: props.lcDocumentData != null ? props.lcDocumentData.lcIssuingCustomerBank : "",
            lcIssuingSupplierBank: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcIssuingSupplierBank : "",
            lcCustomerCharges: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerCharges : null,
            lcSupplierCharges: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierCharges : null,
            lcCustomerAssociatedCharges: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerAssociatedCharges : null,
            lcSupplierAssociatedCharges: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierAssociatedCharges : null,
            lcCustomerTerms: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerTerms : "",
            lcSupplierTerms: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierTerms : "",
            lcCustomerDocumentNo: props.lcDocumentData != null ? props.lcDocumentData.lcCustomerDocumentNo : "",
            lcSupplierDocumentNo: props.lcDocumentDataSupplier != null ? props.lcDocumentDataSupplier.lcSupplierDocumentNo : "",

        },
    })

    const handleLCDateCustomer = (date) => {
        console.log('click')
        setLcCustomerDate(date)
        formik.values.lcCustomerDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }

    const handleLCDateSupplier = (date) => {
        console.log('click')
        setLcCustomerDate(date)
        formik.values.lcSupplierDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }
    const handleCompanyLogo = (file) => {
        formik.values.lcCustomerDocument = file.base64;
        formik.values.lcCustomerDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setLcCustomerDocument(file.name)
        setLcCustomerDocumentFile(file.base64)
        console.log(file.name)
    }

    const handleSupplierDocument = (file) => {
        formik.values.lcSupplierDocument = file.base64;
        formik.values.lcSupplierDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setLcSupplierDocument(file.name)
        setLcSupplierDocumentFile(file.base64)
        console.log(file.name)
    }

    const handleBankSelectSupplier = () => {
        setSupplierBankAccountModal(true)

    }
    const handleBankSelectCustomer = () => {
        setCustomerBankAccountModal(true)
    }

    const handleSaveEventCustomer = () => {
        setError(true)
        console.log(props.lcDocumentData)
        if (props.lcDocumentData != null) {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "customerID": defaultSelected,
                "lcCustomerDocumentNo": formik.values.lcCustomerDocumentNo,
                "customerBankAccountInformationID": selectedCustomerBank != null ? selectedCustomerBank.customerBankAccountInformationID : formik.values.customerBankAccountInformationID,
                "lcCustomerDate": formik.values.lcCustomerDate,
                "lcCustomerDocument": formik.values.lcCustomerDocument,
                "lcCustomerDocumentName": formik.values.lcCustomerDocumentName,
                "lcIssuingCustomerBank": formik.values.lcIssuingCustomerBank,
                "lcCustomerCharges": parseInt(formik.values.lcCustomerCharges),
                "lcCustomerAssociatedCharges": parseInt(formik.values.lcCustomerAssociatedCharges),
                "lcCustomerTerms": formik.values.lcCustomerTerms,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "tradeLcCustomerUpdateID": props.lcDocumentData.tradeLcCustomerUpdateID
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_LC_CUSTOMER_DOCUMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log('checked')
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Customer LC Document updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    setError(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "customerID": defaultSelected,
                "lcCustomerDocumentNo": formik.values.lcCustomerDocumentNo,
                "customerBankAccountInformationID": selectedCustomerBank != null ? selectedCustomerBank.customerBankAccountInformationID : formik.values.customerBankAccountInformationID,
                "lcCustomerDate": formik.values.lcCustomerDate,
                "lcCustomerDocument": formik.values.lcCustomerDocument,
                "lcCustomerDocumentName": formik.values.lcCustomerDocumentName,
                "lcIssuingCustomerBank": formik.values.lcIssuingCustomerBank,
                "lcCustomerCharges": parseInt(formik.values.lcCustomerCharges),
                "lcCustomerAssociatedCharges": parseInt(formik.values.lcCustomerAssociatedCharges),
                "lcCustomerTerms": formik.values.lcCustomerTerms,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,

            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_LC_CUSTOMER_DOCUMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Customer LC Document updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    setError(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const handleSaveEventSupplier = () => {
        setErrorSupplier(true)
        if (props.lcDocumentDataSupplier != null) {
            const json_list = {
                "tradeID": parseInt(formik.values.tradeID),
                "supplierID": formik.values.supplierID,
                "lcSupplierDocumentNo": formik.values.lcSupplierDocumentNo,
                "supplierBankAccountInformationID": selectedSupplierBank != null ? selectedSupplierBank.supplierBankAccountInformationID : formik.values.supplierBankAccountInformationID,
                "lcSupplierDate": formik.values.lcSupplierDate,
                "lcSupplierDocument": formik.values.lcSupplierDocument,
                "lcSupplierDocumentName": formik.values.lcSupplierDocumentName,
                "lcIssuingSupplierBank": formik.values.lcIssuingSupplierBank,
                "lcSupplierCharges": parseInt(formik.values.lcSupplierCharges),
                "lcSupplierAssociatedCharges": parseInt(formik.values.lcSupplierAssociatedCharges),
                "lcSupplierTerms": formik.values.lcSupplierTerms,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "tradeLcSupplierUpdateID": props.lcDocumentDataSupplier.tradeLcSupplierUpdateID
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_LC_SUPPLIER_DOCUMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Supplier LC Document updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    setErrorSupplier(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_list = {
                "tradeID": parseInt(formik.values.tradeID),
                "supplierID": formik.values.supplierID,
                "lcSupplierDocumentNo": formik.values.lcSupplierDocumentNo,
                "supplierBankAccountInformationID": selectedSupplierBank != null ? selectedSupplierBank.supplierBankAccountInformationID : formik.values.supplierBankAccountInformationID,
                "lcSupplierDate": formik.values.lcSupplierDate,
                "lcSupplierDocument": formik.values.lcSupplierDocument,
                "lcSupplierDocumentName": formik.values.lcSupplierDocumentName,
                "lcIssuingSupplierBank": formik.values.lcIssuingSupplierBank,
                "lcSupplierCharges": parseInt(formik.values.lcSupplierCharges),
                "lcSupplierAssociatedCharges": parseInt(formik.values.lcSupplierAssociatedCharges),
                "lcSupplierTerms": formik.values.lcSupplierTerms,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_LC_SUPPLIER_DOCUMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Supplier LC Document updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    setErrorSupplier(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const handleSaveBankSelectionSupplier = (data) => {
        console.log(data)
        formik.values.supplierBankAccountInformationID = data.supplierBankAccountInformationID
        setSelectedSupplierBank(data)
    }

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }

    const handleSaveBankSelectionCustomer = (data) => {
        console.log(data)
        formik.values.customerBankAccountInformationID = data.customerBankAccountInformationID
        setSelectedCustomerBank(data)
    }

    const close = () => {
        setCustomerBankAccountModal(false)
        setSupplierBankAccountModal(false)
    }

    const handleError = () => {
        setError(false)
    }

    const handleCustomer = (e) => {
        let val = parseInt(e.currentTarget.value)
        let filter = customerData.filter(x => x.customer.customerID == val)[0]
        setDefaultCustomer(filter)
    }

    return (
        <div className='main-wrapper inner-wrapper d-flex'>

            <div className='width-100'>
                <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                    {/* <div className = 'd-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div> */}
                </div>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>LC Documents</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        <Tabs >
                            <Card className='tabs-wrapper'>
                                <TabList>
                                    <Tab>L/C Supplier</Tab>
                                    <Tab>L/C Customer</Tab>
                                </TabList>
                            </Card>
                            <TabPanel className='mt-1-rem '>

                                <div className='scrollLcustom'>

                                    <Card className='bg-white'>
                                        <Card.Body>
                                            <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                Supplier Details
                                            </div>
                                            <div className="m-3 p-4 grey-color">
                                                {tradeData != null ?
                                                    <>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='d-flex flex-column me-5'>
                                                                <div className='font-weight-600 color-243448'>{tradeData.tradedetails.supplier.supplierCode}</div>
                                                                <div className='font-weight-400 color-697382'>{tradeData.tradedetails.supplier.supplierName}</div>
                                                            </div>
                                                            <div className='mx-5 seperator' />
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Address</div>
                                                                <div className='font-weight-600 color-243448'>{tradeData.tradedetails.supplier.registeredBuildingName}, {tradeData.tradedetails.supplier.registeredStreetName}, {tradeData.tradedetails.supplier.registeredCityName}</div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='d-flex align-items-center justify-content-between mt-3'>
                                                            <>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Date</div>
                                                                    <div className='font-weight-600 color-243448'>{Moment().format('DD/MM/YYYY')}</div>
                                                                </div>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Mode of Payment</div>
                                                                    <div className='font-weight-600 color-243448'></div>
                                                                </div>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Amount</div>
                                                                    <div className='font-weight-600 color-243448'> <div className='font-weight-600 color-243448'>$<NumberFormat value={overAllTotalSupplier} displayType={'text'} thousandSeparator={true} /></div></div>
                                                                </div>
                                                                <div>

                                                                </div>
                                                            </>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <CardBody>
                                            <Form >
                                                <Row>
                                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                        L/C Details
                                                    </div>
                                                    <>
                                                        <FormFields.DatePick
                                                            label='L/C Date'
                                                            name='lcSupplierDate'
                                                            classname='pt-1-rem iconDatePicker'
                                                            type='date'
                                                            handleCompletionDate={handleLCDateSupplier}
                                                            isRequired={true}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            val={formik.values.lcSupplierDate}
                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcSupplierDocumentNo'
                                                            label='L/C Document No.'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.lcSupplierDocumentNo}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcIssuingSupplierBank'
                                                            label='Issuing Bank'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.lcIssuingSupplierBank}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcSupplierCharges'
                                                            label='L/C Charges'
                                                            classname='pt-1-rem'
                                                            type='number'
                                                            val={formik.values.lcSupplierCharges}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <Row>
                                                            <FormFields.InputField
                                                                md={3}
                                                                name='lcSupplierAssociatedCharges'
                                                                label='Associated Charges'
                                                                classname='pt-1-rem'
                                                                type='number'
                                                                val={formik.values.lcSupplierAssociatedCharges}

                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}

                                                            />
                                                            <Col md={3} className='pt-1-rem align-items-center'>
                                                                <ButtonComponent.UploadButton value='L/C Document' onUploadHandle={handleSupplierDocument} filename={lcSupplierDocument} document = {lcSupplierDocumentFile}/>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <CardBody>
                                            <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                L/C Terms:
                                            </div>

                                            <Col md={12} className=''>
                                                <FormFields.inputFieldArea
                                                    md={12}
                                                    row={6}
                                                    name='lcSupplierTerms'
                                                    label='Remarks'
                                                    classname='pt-1-rem'
                                                    val={formik.values.lcSupplierTerms}
                                                    type='text'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </Col>


                                        </CardBody>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <Card.Body>
                                            <div className='d-flex justify-content-between'>
                                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                    Supplier Bank Details
                                                </div>
                                                <div className="d-flex" style={{ marginLeft: 'auto' }}>
                                                    <ButtonComponent.BlueBorderButton value='Change Supplier Bank' clickEvent={handleBankSelectSupplier} />
                                                </div>
                                            </div>
                                            {selectedSupplierBank != null ?
                                                <div className="m-3 p-4 grey-color">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex flex-column me-5'>
                                                            <div className='font-weight-600 color-243448'>Supplier Name</div>
                                                            <div className='font-weight-400 color-697382'>{tradeData.tradedetails.supplier.supplierName}</div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex align-items-center justify-content-between mt-3'>
                                                        <>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Bank Name</div>
                                                                <div className='font-weight-600 color-243448'>{selectedSupplierBank.bankName}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Account No</div>
                                                                <div className='font-weight-600 color-243448'>{selectedSupplierBank.accountNo}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>IBAN</div>
                                                                <div className='font-weight-600 color-243448'>{selectedSupplierBank.iban}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Currency</div>
                                                                <div className='font-weight-600 color-243448'>USD</div>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                                :
                                                <div className="m-3 p-4 grey-color">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex flex-column me-5'>
                                                            <div className='font-weight-600 color-243448'>Supplier Name</div>
                                                            <div className='font-weight-400 color-697382'></div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex align-items-center justify-content-between mt-3'>
                                                        <>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Bank Name</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Account No</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>IBAN</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Currency</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </>
                                                    </div>
                                                </div>

                                            }

                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={handleSaveEventSupplier} disabled={errorSupplier} /></span>
                                        </CardBody>
                                    </Card>
                                    {supplierBankAccountModal == true ?
                                        <SupplierBankSelectionModal selectedSupplierBank={selectedSupplierBank} data={tradeData.tradedetails.supplier.supplierBankAccountInformation} handleSaveBankSelectionSupplier={handleSaveBankSelectionSupplier} close={close} />
                                        :
                                        ''
                                    }

                                </div>


                            </TabPanel>
                            <TabPanel className='mt-1-rem'>
                                <div className='scrollLcustom'>

                                    <Card className='bg-white '>
                                        <Card.Body>
                                            <div className='d-flex justify-content-between'>
                                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                    Customer Details
                                                </div>
                                            </div>
                                            <div className="m-3 p-4 grey-color">
                                                {tradeData != null ?
                                                    <>
                                                        {(defaultCustomer != null && Object.keys(defaultCustomer).length) &&
                                                            <div className='d-flex align-items-center'>
                                                                <div className='d-flex flex-column mr-20'>
                                                                    <div className='font-weight-600 color-243448'>{defaultCustomer.customer.customerCode}</div>
                                                                    <div className='font-weight-400 color-697382'>{defaultCustomer.customer.customerName}</div>
                                                                </div>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Address</div>
                                                                    <div className='font-weight-600 color-243448'>
                                                                        {defaultCustomer.customer.registeredUnitNo}, &nbsp;
                                                                        {defaultCustomer.customer.registeredBuildingName}, &nbsp;
                                                                        {defaultCustomer.customer.registeredStreetName}, &nbsp;
                                                                        {defaultCustomer.customer.registeredPostBox}, &nbsp;
                                                                        {defaultCustomer.customer.registeredCityName}, &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <hr />
                                                        <div className='d-flex align-items-center justify-content-between mt-3'>
                                                            <>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Date</div>
                                                                    <div className='font-weight-600 color-243448'>{Moment().format('DD/MM/YYYY')}</div>
                                                                </div>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Mode of Payment</div>
                                                                    <div className='font-weight-600 color-243448'></div>
                                                                </div>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='font-weight-400 color-697382'>Amount</div>
                                                                    <div className='font-weight-600 color-243448'>$<NumberFormat value={overAllTotalCustomer} displayType={'text'} thousandSeparator={true} /></div>
                                                                </div>
                                                                <div>

                                                                </div>
                                                            </>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <CardBody>
                                            <Form >
                                                <Row>
                                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                        L/C Details
                                                    </div>
                                                    <>
                                                        <FormFields.DatePick
                                                            label='L/C Date'
                                                            name='lcCustomerDate'
                                                            classname='pt-1-rem iconDatePicker'
                                                            type='date'
                                                            handleCompletionDate={handleLCDateCustomer}
                                                            isRequired={true}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            val={formik.values.lcCustomerDate}
                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcCustomerDocumentNo'
                                                            label='L/C Document No.'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.lcCustomerDocumentNo}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcIssuingCustomerBank'
                                                            label='Issuing Bank'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.lcIssuingCustomerBank}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <FormFields.InputField
                                                            md={3}
                                                            name='lcCustomerCharges'
                                                            label='L/C Charges'
                                                            classname='pt-1-rem'
                                                            type='number'
                                                            val={formik.values.lcCustomerCharges}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        <Row>
                                                            <FormFields.InputField
                                                                md={3}
                                                                name='lcCustomerAssociatedCharges'
                                                                label='Associated Charges'
                                                                classname='pt-1-rem'
                                                                type='number'
                                                                val={formik.values.lcCustomerAssociatedCharges}

                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}

                                                            />
                                                            <Col md={3} className='pt-1-rem align-items-center'>
                                                                <ButtonComponent.UploadButton value='L/C Document' onUploadHandle={handleCompanyLogo} filename={lcCustomerDocument} document = {lcCustomerDocumentFile}/>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <CardBody>
                                            <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                L/C Terms:
                                            </div>

                                            <Col md={12} className=''>
                                                <FormFields.inputFieldArea
                                                    md={12}
                                                    row={6}
                                                    name='lcCustomerTerms'
                                                    label='Remarks'
                                                    classname='pt-1-rem'
                                                    val={formik.values.lcCustomerTerms}
                                                    type='text'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </Col>


                                        </CardBody>
                                    </Card>
                                    <Card className='bg-white my-3'>
                                        <Card.Body>
                                            <div className='d-flex justify-content-between'>
                                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                    Customer Bank Details
                                                </div>
                                                <div className="d-flex" style={{ marginLeft: 'auto' }}>
                                                    <ButtonComponent.BlueBorderButton value='Change Customer Bank' clickEvent={handleBankSelectCustomer} />
                                                </div>
                                            </div>
                                            {selectedCustomerBank != null ?
                                                <div className="m-3 p-4 grey-color">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex flex-column me-5'>
                                                            <div className='font-weight-600 color-243448'>Customer Name</div>
                                                            <div className='font-weight-400 color-697382'>
                                                                {(defaultCustomer != null && Object.keys(defaultCustomer).length) &&
                                                                    defaultCustomer.customer.customerName
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex align-items-center justify-content-between mt-3'>
                                                        <>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Bank Name</div>
                                                                <div className='font-weight-600 color-243448'>{selectedCustomerBank.bankName}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Account No</div>
                                                                <div className='font-weight-600 color-243448'>{selectedCustomerBank.accountNo}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>IBAN</div>
                                                                <div className='font-weight-600 color-243448'>{selectedCustomerBank.iban}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Currency</div>
                                                                <div className='font-weight-600 color-243448'>USD</div>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                                :
                                                <div className="m-3 p-4 grey-color">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex flex-column me-5'>
                                                            <div className='font-weight-600 color-243448'>Customer Name</div>
                                                            <div className='font-weight-400 color-697382'></div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex align-items-center justify-content-between mt-3'>
                                                        <>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Bank Name</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Account No</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>IBAN</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <div className='font-weight-400 color-697382'>Currency</div>
                                                                <div className='font-weight-600 color-243448'></div>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </>
                                                    </div>
                                                </div>

                                            }

                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <CardBody>

                                            <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={handleSaveEventCustomer} disabled={error} /></span>
                                        </CardBody>
                                    </Card>
                                    {customerBankAccountModal == true ?
                                        <CustomerBankSelectionModal selectedCustomerBank={selectedCustomerBank} data={defaultCustomer.customer.customerBankAccountInformation} handleSaveBankSelectionCustomer={handleSaveBankSelectionCustomer} close={close} />
                                        :
                                        ''
                                    }
                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LCDocuments