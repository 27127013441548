import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card, Button } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
//import CustomerDetails from './customerdetails';
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import ShipmentByIdDetails from './shipmentbyiddetails';

export default function ShipmentInTransitList(props) {
    const [rptshipmentcompleteddata, setrptshipmentcompleteddata] = useState()
    const { instance } = useMsal();
    const [tradeId, settradeId] = useState(0)
    const [isDetails, setisDetails] = useState(false)

    //useEffect(() => {
    //    let listValues = []
    //    let listValuesTax = []
    //    const json_list = {
    //        "instanceID": CONFIG.PMSInstanceID,
    //        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    //        "created_User": CONFIG.LoginID,
    //    }
    //    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_SHIPMENTINTRANSIT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    //        .then((res) => {
    //            console.log(res.data.result)
    //            setrptshipmentcompleteddata(res.data.result)
    //            props.settransitval(res.data.result.length)
    //        }).catch((error) => {
    //            if (error.response.status == 401) {
    //                localStorage.clear();
    //                instance.logoutRedirect({
    //                    postLogoutRedirectUri: "/",
    //                });
    //            }
    //        })
    //}, [])


    useEffect(() => {
        console.log('props.rptshipmentcompleteddata')
        console.log(props.rptshipmentransitddata)
        setrptshipmentcompleteddata(props.rptshipmentransitddata);

    }, [props])

    const openDetails = (e) => {
        console.log(e.currentTarget.id)
        let id = parseInt(e.currentTarget.id)
        settradeId(id);
        setisDetails(!isDetails)
        console.log(isDetails)
    }
    const toggle = () => {
        setisDetails(!isDetails)
    }
    return (
        <div class="col-lg-12 ">
            {isDetails &&
                <ShipmentByIdDetails data={rptshipmentcompleteddata} tag='Shipment' id={tradeId} toggle={toggle} value={props.value}  companyID={props.companyID}/>
            }
            <div class="table-responsive-sm ">
                <table className="table table-striped tCustom table-text">
                    <thead className='p-1-rem'>
                        <tr className='text-uppercase'>
                            <th><p className='w-200 mb-0'>TRADE#</p></th>
                            <th><p className='w-200 mb-0'>CUSTOMER</p> </th>
                            <th><p className='w-200 mb-0'>PRODUCT DETAILS</p></th>
                            <th><p className='w-200 mb-0'>VESSEL NAME</p></th>
                            <th><p className='w-200 mb-0'>LOAD PORT</p></th>
                            <th className='px-3'><p className='w-200 mb-0'>DESTINATION PORT & ETA</p></th>
                            <th>STATUS</th>
                            <th></th>
                            <th><p className='w-200 mb-0'></p></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rptshipmentcompleteddata != null && rptshipmentcompleteddata.length > 0 ?
                            rptshipmentcompleteddata.map((values, k) => (
                                <tr>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.tradeNo} </p></td>

                                    <td className='font-weight-500 font-14 d-flex'>
                                        <p className='w-150 mb-0'>{values.customername}</p>
                                        <span className='tradeTrsactionsid statBg ml-1-rem'>{values.customercode}</span>
                                    </td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.divisionName} <span className='mx-1 dotSep'>&#8226;</span> {values.productName}</p></td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.vesselname}</p></td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.loadport}
                                        {values.etaLoadPort != null ? <div>{Moment(values.etaLoadPort).format('DD/MM/YYYY')}</div> : ""
                                        }
                                    </p>
                                    </td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.destinationport}
                                        {values.etaLoadPort != null ? <div>{Moment(values.shipmentCompletedDate).format('DD/MM/YYYY')}</div> : ""
                                        }
                                    </p></td>
                                    <td>
                                        <Button variant='outline-secondary' id={values.tradeID} onClick={openDetails} >
                                            <img src='assets/images/rightarrow.svg' />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            :
                            ''
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

