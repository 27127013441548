import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as FormFields from "../../../components/formcomponents"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import DatePicker from "react-datepicker";
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as CONFIG from "../../../helpers/config";
import { useMsal } from "@azure/msal-react";
import AutoSuggest from "react-autosuggest";
import axios from 'axios'
import { Col, Form, FloatingLabel, Button, Row, ButtonToolbar, Accordion } from "react-bootstrap"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import AddExternalUser from './addExternalUser';
import Chip from '@mui/material/Chip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Moment from "moment"
import dayjs from 'dayjs';

export default function EditMeeting(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [agendaList, setAgendaList] = useState(props.meeting.meeting.meetingAgenda)
    const [agendaStatus, setAgendStatus] = useState(false)
    const [meetingtypelist, setmeetingtypelist] = useState([])
    const [meetingtypid, setmeetingid] = useState('')
    const [categoryavail, setcategoryavail] = useState(false)
    const [meetingdate, setmeetingdate] = useState(props.meeting.meeting.meetingDate)
    const [meetingtype, setmeetingtype] = useState(props.meeting.meeting.meetingType)
    const [hosttype, sethosttype] = useState(props.meeting.meeting.meetingHost)
    const [userlist, setuserlist] = useState([])
    const [hostmodal, sethostmodal] = useState(false)
    const [taskduedate, settaskduedate] = useState(false)
    const [taskstatus, settaskstatus] = useState([])
    const [taskassignedtouser, settaskassignedto] = useState(0)
    const [attendeeslist, setattendeeslist] = useState([])
    const [notifyuserlist, setnotifyuserlist] = useState([])
    const [isTaskEdit, setistaskedit] = useState(false)
    const [preemeetingstatus, setpreemeetingstatus] = useState(false)
    const [index, setindex] = useState(0)
    const [premeetinglist, setpreemeetinglist] = useState(props.meeting.meeting.meetingAgendaTask)
    const [hostid, sethostid] = useState(props.meeting.meeting.meetingHostUserID)
    const [meetingstarttime, setmeetingstarttime] = useState(props.meeting.meetingstarttime)
    const [meetingendtime, setmeetingendtime] = useState(props.meeting.meetingendtime)
    const [hostlist, sethostlist] = useState([])
    const [meetingcategory, setmeetingcategory] = useState([])
    const [defaultatendee, setdefaultattendee] = useState([])
    const [defaultnotifylist, setdefaultnotify] = useState([])
    const [defaulthost, setdefaulthost] = useState([])
    const [isdataset, setisdataset] = useState(false)
    const [iscategoryset, setiscategoryset] = useState(false)
    const [taskpresentindex, settaskpresentindex] = useState(0)
    useEffect(() => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_CATEGORY, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setmeetingtypelist(res.data.result)
                console.log(props.meeting.meeting.meetingCategoryID)
                console.log(res.data.result)
                let cat = res.data.result.filter(x=> x.meetingCategoryID === props.meeting.meeting.meetingCategoryID)
                console.log(cat)
                setmeetingcategory(cat)
                setiscategoryset(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        getUsers()
    }, [])

    useEffect(()=>{
        console.log(attendeeslist)
    },[attendeeslist])

    const formik = useFormik({
        initialValues:
        {
            meetingTitle :  props.meeting.meeting.meetingTitle,
            agendaList: '',
            taskname : '',
            taskDescription: '',
            meetingUserID: 0,
            is_Completed: false
        }
    })
    
    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserlist(res.data.result)
                let users = []
                props.meeting.meeting.meetingRequiredAttendance.map((attendee,k)=>{
                    let data = res.data.result.filter(x=> x.meetingUserID == attendee.meetingUserID)[0]
                    users.push(data)
                })
                setdefaultattendee(users)
                let usr = []
                props.meeting.meeting.meetingNotifyUser.map((notify,k)=>{
                    let data = res.data.result.filter(x=> x.meetingUserID == notify.meetingUserID)[0]
                    usr.push(data)
                })
                setdefaultnotify(usr)
                console.log(usr)
                let host = []
                setdefaulthost(props.meeting.meeting.meetingUser)
                setisdataset(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        console.log(meetingtypid)
        console.log(hosttype)
        console.log(agendaList)
        console.log(meetingstarttime)
    }, [meetingtypid, hosttype, agendaList, meetingstarttime])

    const listbox = {
        options: meetingtypelist,
        getOptionLabel: (option) => option.meetingCategoryName
    }
    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }

    const saveContract = () => {
        console.log(meetingstarttime)
        console.log(meetingendtime)
        const json_list ={
            "is_Meeting": 1,
            "meetingID" : props.meeting.meeting.meetingID,
            "meetingCode": props.meeting.meeting.meetingCode,
            "meetingTitle": formik.values.meetingTitle,
            "meetingCategoryID" : props.meeting.meeting.meetingCategoryID,
            "meetingDate": meetingdate,
            "strMeetingStartTime": Moment(new Date(meetingstarttime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meetingendtime)).format('HH:mm:ss').toString(),
            "meetingType": meetingtype,
            "meetingHost": hosttype,
            "meetingHostUserID": hostid,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": props.meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime" : props.meeting.meeting.created_Datetime,
            "meetingAgenda": agendaList,
            "meetingAgendaTask":premeetinglist,
            "meetingRequiredAttendance": attendeeslist,
            "meetingNotifyUser": notifyuserlist
        }
        if(categoryavail){
            json_list.meetingCategoryID =meetingtypid
        }
        else{
            json_list.meetingCategoryName =meetingtypid
        }
        
        if(!attendeeslist.length>0){
            console.log(defaultatendee)
            json_list.meetingRequiredAttendance = props.meeting.meeting.meetingRequiredAttendance
        }
        if(!notifyuserlist.length>0){
            json_list.meetingNotifyUser = props.meeting.meeting.meetingNotifyUser
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            props.toggle()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                });
            }
        })
    }


    const handleTask = (e) => {
        let ind = parseInt(e.target.id)
        let taskstatus = []
        taskstatus[ind] = true
        settaskstatus(taskstatus)
    }

    const agendaHtml = () => {
        setAgendStatus(!agendaStatus)
    }

    const removeTask = (e) => {
        let ind = parseInt(e.target.id)
        let taskstatus = []
        taskstatus[ind] = false
        settaskstatus(taskstatus)
    }

    const saveAgenda = () => {
        console.log(preemeetingstatus)
        if(preemeetingstatus == true){
            let tasklist ={
                "task": formik.values.taskname,
                "is_PreMeetingPoints": preemeetingstatus,
                "is_Completed": false
            }   
            let preemeeting = [...premeetinglist]
            preemeeting.push(tasklist)
            formik.values.taskname = ''
            setpreemeetinglist(preemeeting)
            setAgendStatus(false)
        }
        else{
            let agendList = [...agendaList]
            let agenda = {
                agendaname: '',
                meetingAgendaTask: []
            }
            agenda.agendaname = formik.values.agendaList
            console.log(agenda)
            agendList.push(agenda)
            formik.values.agendaList = ''
            setAgendaList(agendList)
            console.log(agendaList)
            setAgendStatus(false)
        }
    }
    useEffect(()=>{
        console.log(taskduedate)
    },[taskduedate])
    const handlemeetingdate = (date) => {
        console.log(date)
        if(date == null){
            setmeetingdate(false)
        }
        else{
            setmeetingdate(date)
        }
    }

    const handleTaskDueDate = (date)=>{
        console.log(date)
        if(date == null){
            settaskduedate(false)
        }
        else{
            settaskduedate(date)
            console.log(taskduedate)
        }
    }

    const handleMeeetingType = (e) => {
        let addedValue = e.target.value
        let selectedMeeting = meetingtypelist.filter(x => x.meetingCategoryName == addedValue)
        if (selectedMeeting.length > 0) {
            setcategoryavail(true)
            setmeetingid(selectedMeeting[0].meetingCategoryID)
        }
        else {
            setcategoryavail(false)
            setmeetingid(addedValue)
        }
    }

    const handleSelectMeeting = (event, values) => {
        console.log(values)
        setcategoryavail(true)
        setmeetingid(values.meetingCategoryID)
    }

    const handleSelectUser = (event, values) => {   
        console.log(values.meetingUserID)
        sethostid(values.meetingUserID)
    }
    const handleHost = (e) => {
        let host = e.target.value
        console.log(host)
    }
    const addHost = () => {
        sethostmodal(!hostmodal)
    }
    const savetask = (e) =>{
        let ind = e
        let agendalis = [...agendaList]
        let tasklist ={
            "task": formik.values.taskname,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": preemeetingstatus,
            "is_Completed": false
        }
        formik.values.taskDescription = ''
        formik.values.taskname = ''
        settaskduedate(false)
        settaskassignedto(0)
        agendalis[ind].meetingAgendaTask.push(tasklist)
        setAgendaList(agendalis)
        let tasksta = [...taskstatus]
        tasksta[ind] = false
        settaskstatus(tasksta)
    }

    const handleAssignedTo = (event, values)=>{
        settaskassignedto(values.meetingUserID)
    }

    const handlerequiredattendees = (event, values)=>{
        console.log(values)
        let atlist = []
        if(Array.isArray(values)){
            values.map((val,k)=>{
                let ispresent = props.meeting.meeting.meetingRequiredAttendance.filter(x=> x.meetingUserID == val.meetingUserID)
                if(ispresent.length>0){
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    atlist[k] = ispresent[0]
                }
                else{
                    atlist[k] ={
                        "meetingUserID" : val.meetingUserID
                    }
                }
            })
            let preselected = atlist.filter(x => x.meetingRequiredAttendanceID != null)
            props.meeting.meeting.meetingRequiredAttendance.map((at,l)=>{
                let ispresentinnew = preselected.filter(x=> x.meetingUserID == at.meetingUserID)
                if(ispresentinnew.length > 0){
                    //console.log(at)
                }
                else{
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    atlist.push(newlist)
                }
            })
            console.log(atlist)
            setattendeeslist(atlist)
        }else{
            setattendeeslist(values.meetingUserID)
        }
    }

    const handlenotifyusers = (event, values)=>{
        let notify = []
        if(Array.isArray(values)){
            values.map((val,k)=>{
                let ispresent = props.meeting.meeting.meetingNotifyUser.filter(x=> x.meetingUserID == val.meetingUserID)
                if(ispresent.length>0){
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    notify[k] = ispresent[0]
                }
                else{
                    notify[k] ={
                        "meetingUserID" : val.meetingUserID
                    }
                }
            })
            let preselected = notify.filter(x => x.meetingAgendaTaskNotifyUserID != null)
            props.meeting.meeting.meetingNotifyUser.map((at,l)=>{
                let ispresentinnew = preselected.filter(x=> x.meetingUserID == at.meetingUserID)
                if(ispresentinnew.length > 0){
                    //console.log(at)
                }
                else{
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    notify.push(newlist)
                }
            })
            console.log(notify)
            setnotifyuserlist(notify)
        }else{
            setnotifyuserlist(values.meetingUserID)
        }
    }

    const editTask = (k, l)=>{
        let ind = k
        setindex(l)
        console.log(agendaList[k].meetingAgendaTask)
        formik.values.taskname = agendaList[k].meetingAgendaTask[l].task
        formik.values.taskDescription = agendaList[k].meetingAgendaTask[l].taskDescription
        formik.values.is_Completed = agendaList[k].meetingAgendaTask[l].is_Completed
        console.log(agendaList[k].meetingAgendaTask[l].meetingAgendaTaskID)
        if(agendaList[k].meetingAgendaTask[l].meetingAgendaTaskID != null){
            settaskpresentindex(agendaList[k].meetingAgendaTask[l].meetingAgendaTaskID)
        }
        else{
            settaskpresentindex(0)
        }
        settaskduedate(agendaList[k].meetingAgendaTask[l].taskDueDate)
        settaskassignedto(agendaList[k].meetingAgendaTask[l].meetingUserID)
        let tasksta = [...taskstatus]
        tasksta[ind] = true
        settaskstatus(tasksta)
        setistaskedit(true)
    }
    const updatetask = (k, index)=>{
        let agendalis = [...agendaList]
        let tasklist ={
            "task": formik.values.taskname,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": formik.values.is_Completed
        }
        if(taskpresentindex!= 0){
            tasklist.meetingAgendaTaskID = taskpresentindex
        }
        console.log(tasklist)
        formik.values.taskDescription = ''
        formik.values.taskname = ''
        settaskduedate(false)
        settaskassignedto(0)
        agendalis[k].meetingAgendaTask[index] = tasklist
        setAgendaList(agendalis)
        let tasksta = [...taskstatus]
        tasksta[k] = false
        settaskstatus(tasksta)
        setistaskedit(false)
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
            <ModalHeader toggle={toggle} className='p-40'>
                <h2 className='meetingHeading'>Meetings</h2>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-40 scrollContract mb-10-rem'>
                {hostmodal &&
                    <AddExternalUser isOpen={hostmodal} getUsers={getUsers} toggle={addHost}/>
                }
                <div className=''>
                    <div className='font-16 font-weight-600 color-707895 '>
                        <>
                            <Row className='border_bottom_input'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Meeting Title' className=''
                                    name='meetingTitle'
                                    val={formik.values.meetingTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem px-3'>
                                <Col md={6} className='border_bottom_input'>
                                    {iscategoryset &&
                                        <Autocomplete
                                            id="free-solo-demo"
                                            options= {meetingtypelist}
                                            getOptionLabel= {(option) => option.meetingCategoryName}
                                            freeSolo
                                            onChange={handleSelectMeeting}
                                            defaultValue={meetingcategory[0]}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Type of Meeting" variant="standard"
                                                    onChange={handleMeeetingType} />
                                            )}
                                        />
                                    }
                                </Col>
                                <FormFields.DatePickBottomBorder
                                    md={6}
                                    handleCompletionDate={handlemeetingdate}
                                    label='Date of Meeting'
                                    val={meetingdate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                />
                            </Row>
                            <Row className='mt-2-rem'>
                                <Col md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Meeting Start Time"
                                            value={meetingstarttime}
                                            onChange={(newValue) => {
                                                setmeetingstarttime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                                <Col md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Meeting End Time"
                                            value={meetingendtime}
                                            onChange={(newValue) => {
                                                setmeetingendtime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                            </Row>
                            <Row className='mt-2-rem'>
                                <Col md={4}>
                                    <span className='meeting_type'>Type of Meeting</span>
                                    <ButtonGroup>
                                        <ToggleButton
                                            key={1}
                                            id='radio-1'
                                            type="radio"
                                            variant={'outline-primary'}
                                            name="Internal1"
                                            value={1}
                                            checked={meetingtype == 1 ? true : false}
                                            onChange={(e) => setmeetingtype(1)}
                                        >
                                            Internal
                                        </ToggleButton>
                                        <ToggleButton
                                            key={2}
                                            id='radio-2'
                                            type="radio"
                                            variant={'outline-primary'}
                                            name="External1"
                                            value={2}
                                            checked={meetingtype == 2 ? true : false}
                                            onChange={(e) => setmeetingtype(2)}
                                        >
                                            External
                                        </ToggleButton>
                                    </ButtonGroup>

                                </Col>
                                <Col md={3}>
                                    <span className='meeting_type'>Host of the Meeting</span>

                                    <ButtonGroup>
                                        <ToggleButton
                                            key={3}
                                            id='radio-3'
                                            type="radio"
                                            variant={'outline-primary'}
                                            name="Internal2"
                                            value={3}
                                            checked={hosttype == 1 ? true : false}
                                            onChange={(e) => sethosttype(1)}
                                        >
                                            Internal
                                        </ToggleButton>
                                        <ToggleButton
                                            key={4}
                                            id='radio-4'
                                            type="radio"
                                            variant={'outline-primary'}
                                            name="External2"
                                            value={4}
                                            checked={hosttype == 2 ? true : false}
                                            onChange={(e) => sethosttype(2)}
                                        >
                                            External
                                        </ToggleButton>
                                    </ButtonGroup>
                                </Col>
                                <Col md={5}>
                                    {/* <span className='meeting_type'>Host of Meeting</span> */}
                                    {hosttype == 1 ?
                                        <Col md={12} className='mt-1-rem'>
                                            {/* <Turnstone id="autocomplete" listbox={userlistbox} styles={styles} typeahead={false} onChange={selectmeetingtypelist}/> */}
                                            {isdataset &&
                                                <Autocomplete
                                                    multiple={false}
                                                    id="tags-standard"
                                                    options={hosttype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                    getOptionLabel={(option) => option.userName}
                                                    onChange={handleSelectUser}
                                                    defaultValue={defaulthost}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                            </div>
                                                            <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Host of Meeting"
                                                            placeholder="Favorites"
                                                        />
                                                    )}
                                                />
                                            }

                                            {props.errors &&
                                                <span className='input_error'>{props.errors}</span>
                                            }
                                        </Col>
                                        :
                                        <Row className='border_bottom_input task_description'>
                                            <div className='task_div col-md-10 d-flex' style={{ padding: '14px 5px' }}>
                                                <Col md={12}>
                                                    {isdataset &&
                                                        <Autocomplete
                                                            multiple={false}
                                                            id="tags-standard"
                                                            options={hosttype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                            getOptionLabel={(option) => option.userName}
                                                            onChange={handleSelectUser}
                                                            defaultValue={defaulthost}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                    </div>
                                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Host of Meeting"
                                                                    placeholder="Favorites"
                                                                />
                                                            )}
                                                        />
                                                    }
                                                </Col>
                                                <img src='assets/images/blue_add.svg' className='pointer w-20px' onClick={addHost} />

                                            </div>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col md={6}>
                                    {isdataset &&
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={meetingtype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                            getOptionLabel={(option) => option.userName}
                                            onChange={handlerequiredattendees}
                                            limitTags={2}
                                            defaultValue={defaultatendee}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Add Required Attendees"
                                                    placeholder="Add Required Attendees"
                                                />
                                            )}
                                        />
                                    }
                                    {meetingtype == 2 &&
                                        <img src='assets/images/blue_add.svg' className='pointer' onClick={addHost} />
                                    }
                                </Col>
                                <Col md={6}>
                                    {isdataset &&
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            onChange={handlenotifyusers}
                                            options={meetingtype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                            getOptionLabel={(option) => option.userName}
                                            defaultValue={defaultnotifylist}
                                            limitTags={2}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Notify Members"
                                                    placeholder="Notify Members"
                                                />
                                            )}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-2-rem'>

                                <Tabs>
                                    <div className='d-flex'>
                                        <Col md={6}>
                                            <TabList>
                                                <Tab onClick={()=>{
                                                    setpreemeetingstatus(false)
                                                }}>
                                                    Agenda
                                                </Tab>
                                                <Tab onClick={()=>{
                                                    setpreemeetingstatus(true)
                                                }}>
                                                    Pre Meeting Points
                                                </Tab>
                                            </TabList>
                                        </Col>
                                        <Col md={6} className='d-flex  justify-content-end'>
                                            <div className='no_border'>
                                                <img src='assets/images/blue_add.svg' className='pointer w-20px' onClick={agendaHtml} />
                                            </div>
                                        </Col>
                                    </div>
                                    <TabPanel>
                                        {agendaStatus &&
                                            <Row className='border_bottom_input task_description'>
                                                <div className='task_div col-md-10'>
                                                    <FormFields.BottomBorderInputField
                                                        md={12}
                                                        name='agendaList'
                                                        val={formik.values.agendaList}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        label='Type Agenda Title here...' className=''
                                                    />
                                                </div>
                                                <div className='task_div col-md-2'>
                                                    <span className='pl-10'>
                                                        <Button variant="primary" size="md" onClick={saveAgenda}>
                                                            Save
                                                        </Button>
                                                    </span>
                                                </div>
                                            </Row>
                                        }
                                        <Accordion className='mt-1-rem'>
                                            {agendaList.map((agenda, k) => {
                                                console.log(agenda)
                                                return (
                                                    <Accordion.Item eventKey={k}><Accordion.Header><span className='bgMeeting'><img src='assets/images/drag.svg' /></span> {agenda.agendaName}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {taskstatus[k] &&
                                                                <>
                                                                <div className='d-flex task1'>
                                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                                        <div className='task_div'>
                                                                            <img src='assets/images/meeting_task.svg' />
                                                                        </div>
                                                                        <div className='task_div '>
                                                                            <Form.Check
                                                                                name='is_Completed' 
                                                                                checked={formik.values.is_Completed}
                                                                                onChange={formik.handleChange}
                                                                                onBlur = {formik.handleBlur}
                                                                            />
                                                                        </div>
                                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                            <FormFields.BottomBorderInputField
                                                                                md={12}
                                                                                label='Add Task' 
                                                                                name='task'
                                                                                val={formik.values.task}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                                        <div className='task_div d-flex meeting_task_due_date'>
                                                                            <FormFields.DatePickBottomBorder
                                                                                md={12}
                                                                                handleCompletionDate={handleTaskDueDate}
                                                                                label='Task Due Date'
                                                                                val={taskduedate}
                                                                                className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                            />
                                                                        </div>
                                                                        <div className='task_div col-md-8'>
                                                                            <Autocomplete
                                                                                id="tags-standard"
                                                                                defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID ==taskassignedtouser)[0]:null}
                                                                                options={meetingtype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                                                getOptionLabel={(option) => option.userName}
                                                                                onChange={handleAssignedTo}
                                                                                renderOption={(props, option) => (
                                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                            <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                        </div>
                                                                                        <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                                    </Box>
                                                                                )}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        variant="standard"
                                                                                        label="Assigned To"
                                                                                        placeholder="Assigned To"
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                                <Row className='border_bottom_input task_description'>
                                                                    <div className='task_div'>
                                                                        <FormFields.BottomBorderInputField
                                                                            md={12}
                                                                            label='Task Description' 
                                                                            name='taskDescription'
                                                                            val={formik.values.taskDescription}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <div className='d-flex justify-content-end'>
                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k} onClick={removeTask}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k} 
                                                                        onClick={()=>{
                                                                            isTaskEdit?
                                                                                updatetask(k, index)
                                                                            :
                                                                                savetask(k)
                                                                        }}>
                                                                        Save Task
                                                                    </Button>
                                                                </div>
                                                            </>
                                                            }
                                                            {agenda.meetingAgendaTask != null &&
                                                                agenda.meetingAgendaTask.map((task,l)=>{
                                                                    let users = userlist.filter(x=> x.meetingUserID == task.meetingUserID)
                                                                    console.log(users)
                                                                    return(
                                                                        <div className='d-flex task1'>
                                                                            <div className='task_div'>
                                                                                <img src='assets/images/meeting_task.svg' />
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <Form.Check
                                                                                
                                                                                    checked={task.is_Completed}
                                                                                    name='taskCompletedStatus' disabled={true} />
                                                                            </div>
                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                {task.task}
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                {Moment(task.taskDueDate).format('DD/MM/YYYY')}
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                    <p className='font-12 font-weight-500 mb-0'>
                                                                                        {users[0] != null &&
                                                                                            users[0].userAvatar
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <ButtonComponent.EditButton clickEvent={()=>{editTask(k,l)}}/>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <Row>
                                                                <Col md={12} className='d-flex task1 align-items-center'>
                                                                    <div className='task_div'>
                                                                        <img src='assets/images/meeting_task.svg' />
                                                                    </div>
                                                                    <div className='task_div '>
                                                                        <img src='assets/images/blue_add.svg' className='pointer' id={k} onClick={handleTask} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })}
                                        </Accordion>
                                    </TabPanel>
                                    <TabPanel>
                                        {agendaStatus &&
                                            <>
                                                <div className='d-flex task1'>
                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='taskCompletedStatus' disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            <FormFields.BottomBorderInputField
                                                                md={12}
                                                                label='Add Task' 
                                                                name='taskname'
                                                                val={formik.values.taskname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" >
                                                        Cancel
                                                    </Button>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={saveAgenda}>
                                                        Save Task
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {premeetinglist.map((meeting)=>{
                                            return(
                                                
                                                <div className='d-flex task1'>
                                                    
                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='taskCompletedStatus' disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            {meeting.task}
                                                        </div>
                                                    </Col>
                                                </div>
                                            )
                                        })}
                                    </TabPanel>
                                </Tabs>
                            </Row>
                        </>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Back' clickEvent={toggle}/></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
            </ModalFooter>
        </Modal>
    )
}
