import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {Form, Card, Row, Col} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "moment"
import axios from 'axios';
import * as CONFIG from "../../../../helpers/config"
import * as FormFields from "../../../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import * as ButtonComponent from "../../../../components/buttoncomponents"
import IncoTermsModal from './IncoTermsModal';
import ModeOfTransportModal from './ModeOfTransportModal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import parseISO from 'date-fns/parseISO';
import { reach } from 'yup';
import { useMsal } from "@azure/msal-react";

function Index(props) {
    const { instance } = useMsal();
    console.log(props, props.tradeCustomerPaymentDetails)
    const [DateOfIncorporation, SetDateOfIncorporation] = useState(false);
    const [updateFormik, setUpdateFormik] = useState(false)
    const [fob, setFob] = useState(true)
    const [taxDocumentName, settaxDocumentName] = useState('');
    const [dateRange, setDateRange] = useState(props.tradeInitiateEdit == true?[Moment(props.TradeInitiateData.tradedetails.fromLaycanPeriod).toDate(),Moment(props.TradeInitiateData.tradedetails.toLaycanPeriod).toDate()] : [null, null]);
    const [startDate, endDate] = dateRange;
    const [modeOfTransportModal, setModeOfTransportModal] = useState(false)
    const [incotermsModal, setIncoTermsModal] = useState(false)
    const [incoTermsLabel, setIncotermsLabel] = useState('FOB')
    const [modeOfTransportLabel, setModeOfTransportLabel] = useState('By Sea')
    const [transitionArray, setTransitionArray] = useState([])
    const MySwal = withReactContent(Swal)
    const [disableButton, setdisable] = useState(false)
    const [dc, setdc] = useState(false)
    const [isdone, setIsdone] = useState(false)

    useEffect(()=>{
        console.log(disableButton)
    },[disableButton])
   
    const formik = useFormik({

        initialValues:
        props.tradeInitiateEdit == true ?
        {
            tradeEnquiryID: props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.tradeEnquiryID : props.TradeEnquiryByIDData.enqiry.tradeEnquiryID,
            customerID:props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.customerID : props.TradeEnquiryByIDData.enqiry.customerID,
            supplierID: props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.supplierID : props.supplier.supplierdata.supplierID,
            TradeDate: "",
            tradeID: props.TradeInitiateData.tradeID,
            modeOfTransportID: props.TradeInitiateData.tradedetails.modeOfTransportID,
            incotermsID: props.TradeInitiateData.tradedetails.incotermsID,
            shipmentSize: props.TradeInitiateData.tradedetails.shipmentSize,
            loadPort:  props.TradeInitiateData.tradedetails.loadPort,
            loadPortCountry: props.TradeInitiateData.tradedetails.loadPortCountry,
            loadQuantityInspectionAgency:props.TradeInitiateData.tradedetails.loadQuantityInspectionAgency,
            loadQualityInspectionAgency: props.TradeInitiateData.tradedetails.loadQualityInspectionAgency,
            loadPortAgency:props.TradeInitiateData.tradedetails.loadPortAgency,
            loadPortRate: props.TradeInitiateData.tradedetails.loadPortRate,
            fromLaycanPeriod: props.TradeInitiateData.tradedetails.fromLaycanPeriod,
            toLaycanPeriod: props.TradeInitiateData.tradedetails.toLaycanPeriod,
            destinationPort: props.TradeInitiateData.tradedetails.destinationPort,
            destinationPortCountry: props.TradeInitiateData.tradedetails.destinationPortCountry,
            destinationQuantityInspectionAgency: props.TradeInitiateData.tradedetails.destinationQuantityInspectionAgency,
            destinationQualityInspectionAgency: props.TradeInitiateData.tradedetails.destinationQualityInspectionAgency,
            destinationPortAgency: props.TradeInitiateData.tradedetails.destinationPortAgency,
            destinationPortRate: props.TradeInitiateData.tradedetails.destinationPortRate,

            tradeNo: props.TradeInitiateData.tradeNo
        }
        :
        {
            tradeEnquiryID: props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.tradeEnquiryID : props.TradeEnquiryByIDData.enqiry.tradeEnquiryID,
            customerID:props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.customerID : props.TradeEnquiryByIDData.enqiry.customerID,
            supplierID: props.tradeInitiateEdit == true ?  props.TradeInitiateData.tradedetails.supplierID : props.supplier.supplierdata.supplierID,
            TradeDate: "", 
            modeOfTransportID: props.TradeEnquiryByIDData.enqiry.modeOfTransportID,
            incotermsID: props.TradeEnquiryByIDData.enqiry.incotermsID,
            shipmentSize: props.currentSupplyPlan.quantity,
            loadPort:props.TradeEnquiryByIDData.enqiry.loadPort,
            loadPortCountry: "",
            loadQuantityInspectionAgency: "",
            loadQualityInspectionAgency: "",
            loadPortAgency: "",
            loadPortRate: null,
            fromLaycanPeriod: "",
            toLaycanPeriod: "",
            destinationPort: props.TradeEnquiryByIDData.enqiry.destinationPort,
            destinationPortCountry: "",
            destinationQuantityInspectionAgency: "",
            destinationQualityInspectionAgency: "",
            destinationPortAgency: "",
            destinationPortRate: null,
 
           
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;

            if (!values.customerCode) {
                errors.customerCode = "Please enter customer code"
                //setIsError(true)
            }
            if (!values.customerName) {
                errors.customerName = "Please enter customer name"
                //setIsError(true)
            }
            else if (!letters.test(values.name)) {
                errors.name = "Please enter a valid Name"
            }
            //sendBack();
            return errors;
        }
    })

    useEffect(()=>{
        if(props.tradeInitiateEdit == true){
            
            //Object.assign(formik.values, props.TradeInitiateData.tradedetails)
            //console.log(formik.values)
            //setUpdateFormik(true)
            // setDateRange(Moment(formik.values.fromLaycanPeriod).format('YYYY-MM-DDTHH:mm:ss'), Moment(formik.values.toLaycanPeriod).format('YYYY-MM-DDTHH:mm:ss'))
        }
    },[formik])

    useEffect(()=>{
        // formik.values.fromLaycanPeriod = new Date(startDate)
        // formik.values.toLaycanPeriod = new Date(endDate)
        // setdc(!dc)
        console.log(dateRange)
    },[dateRange])
    
    const moveToNext = () =>{
        console.log(props)
        setdisable(true)
        formik.values.fromLaycanPeriod = Moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
        formik.values.toLaycanPeriod = Moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
        formik.values.shipmentSize = parseInt(formik.values.shipmentSize)
        formik.values.loadPortRate = parseInt(formik.values.loadPortRate)
        formik.values.destinationPortRate = parseInt(formik.values.destinationPortRate)
        formik.values.etaLoadPort = parseInt(formik.values.etaLoadPort)
        formik.values.ETADischargePort = parseInt(formik.values.ETADischargePort)
        formik.values.DespatchDemurrage = parseInt(formik.values.DespatchDemurrage)
        let TradeDate = Moment().format('YYYY-MM-DDTHH:mm:ss')
        let tradeCustomerPaymentDetail = props.tradeCustomerPaymentDetails
        let tradeSupplierPaymentDetail = props.tradeSupplierPaymentDetails
        let TradeProductDetail = [...props.TradeSupplyPlanDetail]
        let TradeCompanyCustomerDetails = []
        let tradeScenarioID = Array.isArray(props.splitData) == true ? props.splitData[0].tradeScenarioID :  props.splitData.tradeScenarioID
        if(Array.isArray(props.splitData) != true){
            //props.splitData["tradeCustomerPaymentDetail"] = props.tradeCustomerPaymentDetail
            props.splitData.tradeCustomerPaymentDetail = props.tradeCustomerPaymentDetails
            console.log(props.splitData)
            TradeCompanyCustomerDetails.push(props.splitData)
        }
        else if(tradeScenarioID == 2 && props.splitData[0].tradeCustomerPaymentDetail == null){
            let list = []
            props.splitData.map((values,k)=>{
                let product = parseInt(props.currentSupplyPlan.quantity) * parseInt(props.currentSupplyPlan.productSellingPrice)
                console.log(product, 'product')
                let percent = parseInt(values.ratio) / 100
                console.log(percent, 'percent')
                let value = parseInt(product) * parseFloat(percent)
                console.log(value, 'percent calc')
                var ratioAmount = value
                console.log(ratioAmount, 'Ratio Amount')
                console.log(value)
                values.ratioAmount = ratioAmount
                values.tradeCustomerPaymentDetail = props.tradeCustomerPaymentDetails
                // values.tradeCustomerPaymentDetail.map((subValues, k)=>(
                //     subValues.customerPaymentAmount = (subValues.customerPaymentPer / 100) * ratioAmount
                    
                // ))
                console.log(values.tradeCustomerPaymentDetail)
            })
            let ratio1 = props.splitData[0].ratioAmount

            let ratio2 = props.splitData[1].ratioAmount
            console.log(ratio1)
            console.log(ratio2)
            let p1 = props.tradeCustomerPaymentDetails
            console.log(p1)
            let finalPayment1 = []
            p1.map((p,k)=>{
                let calc = (p.customerPaymentPer/100)*ratio1
                let payment1 ={
                    "customerPaymentAmount" : calc,
                    "customerPaymentPer" : p.customerPaymentPer,
                    "customerPaymentTypeID" : p.customerPaymentTypeID,
                    "customerPaymentTypeName" : p.customerPaymentTypeName,
                    "isCheck" : p.isCheck
                }
                console.log(payment1)
                finalPayment1.push(payment1)
            })
            console.log(finalPayment1)

            let p2 = props.tradeCustomerPaymentDetails
            console.log(p2)
            let finalPayment2 = []
            p2.map((pp,l)=>{
                
                let calc = (pp.customerPaymentPer/100)*ratio2
                let payment2 ={
                    "customerPaymentAmount" : calc,
                    "customerPaymentPer" : pp.customerPaymentPer,
                    "customerPaymentTypeID" : pp.customerPaymentTypeID,
                    "customerPaymentTypeName" : pp.customerPaymentTypeName,
                    "isCheck" : pp.isCheck
                }
                finalPayment2.push(payment2)
            })
            console.log(finalPayment2)
            props.splitData[0].tradeCustomerPaymentDetail = finalPayment1
            props.splitData[1].tradeCustomerPaymentDetail = finalPayment2
            TradeCompanyCustomerDetails = props.splitData
            console.log(props.splitData)
        }
        else{
            TradeCompanyCustomerDetails = props.splitData
        }
        if(tradeScenarioID == 1){
            TradeCompanyCustomerDetails[0].ratioAmount = (parseInt((props.currentSupplyPlan.productSellingPrice) * (props.currentSupplyPlan.quantity)))
        }

        if(props.tradeInitiateEdit == true){
            for (let i = 0; i < TradeCompanyCustomerDetails.length; i++){
                if(TradeCompanyCustomerDetails[i].customer != null){
                    TradeCompanyCustomerDetails[i].customer = null
                }
                if(TradeCompanyCustomerDetails[i].company != null){
                    TradeCompanyCustomerDetails[i].company = null 
                }
                TradeCompanyCustomerDetails[i].tradeCompanyCustomerDetailsID = props.tradeCompanyCustomerDetailsID[i]  
            }
        }
        TradeProductDetail.map((detail,k)=>{
            TradeProductDetail[k].tradeProductQualityParameters = detail.tradeEnquiryProductQualityParameters != null ?
                                                                detail.tradeEnquiryProductQualityParameters :
                                                                detail.tradeProductQualityParameters != null ?
                                                                detail.tradeProductQualityParameters :
                                                                []
            TradeProductDetail[k].tradeProductTraceElement = detail.tradeEnquiryProductTraceElement != null ?
                                                            detail.tradeEnquiryProductTraceElement :
                                                            detail.tradeProductTraceElement != null ?
                                                            detail.tradeProductTraceElement :
                                                            []
            TradeProductDetail[k].tradeProductQualityParameters.map((details,i)=>{
                if(TradeProductDetail[k].tradeProductQualityParameters[i].productQualityParameter != null){
                    console.log(TradeProductDetail[k].tradeProductQualityParameters[i].productQualityParameter)
                    delete TradeProductDetail[k].tradeProductQualityParameters[i].productQualityParameter
                }
            })
            TradeProductDetail[k].tradeProductTraceElement.map((details,i)=>{
                if(TradeProductDetail[k].tradeProductTraceElement[i].productTraceElement != null){
                    console.log(TradeProductDetail[k].tradeProductTraceElement[i].productTraceElement)
                    delete TradeProductDetail[k].tradeProductTraceElement[i].productTraceElement
                }
            })
        })
        console.log(TradeProductDetail)
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            ...formik.values,
            tradeCustomerPaymentDetail,
            tradeSupplierPaymentDetail,
            TradeProductDetail,
            "supplierIncotermID": props.supplierIncoterms.supplierIncotermID,
            TradeDate,
            TradeCompanyCustomerDetails,
            tradeScenarioID,
            

        }

        if(props.tradeInitiateEdit == true){

            let history_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": formik.values.tradeID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.TRADE_HISTORY, history_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then(res => {
                console.log(res.data.result)
                final_json={
                    ...final_json,
                    "versionNo": res.data.result.versionNo,
                    "supplierContractWorkFlowID":res.data.result.supplierContractWorkFlowID,
                    "supplierContractApprovalStatus": res.data.result.supplierContractApprovalStatus,
                    "costSheetWorkFlowID": res.data.result.costSheetWorkFlowID,
                    "costSheetApprovalStatus": res.data.result.costSheetApprovalStatus,
                    "actualCostSheetWorkFlowID": res.data.result.actualCostSheetWorkFlowID,
                    "actualCostSheetApprovalStatus" : res.data.result.actualCostSheetApprovalStatus,
                    "workflowID" : res.data.result.workflowID,
                    "companyID" : res.data.result.companyID,
                    "divisionID": res.data.result.divisionID,
                    "oceanFreight": res.data.result.oceanFreight,   
                    "dispatch": res.data.result.dispatch,
                    "demurrage" : res.data.result.demurrage,
                    "remarks": res.data.result.remarks,
                    "modified_User" : CONFIG.LoginID,

                    
                    "custContractStatus" : res.data.result.custContractStatus,
                    "customerContractApprovalModified_Datetime" : res.data.result.customerContractApprovalModified_Datetime,
                    "customerContractApprovalStatus" : res.data.result.customerContractApprovalStatus,
                    "customerContractApprovedModified_By" : res.data.result.customerContractApprovedModified_By,
                    "customerContractID" : res.data.result.customerContractID,
                    "customerContractModified_By" : res.data.result.customerContractModified_By,
                    "customerContractWorkFlowID" : res.data.result.customerContractWorkFlowID,

                    "supplierContractID" : res.data.result.supplierContractID,
                    "supplierContractApprovalModified_Datetime" : res.data.result.supplierContractApprovalModified_Datetime,
                    "supplierContractApprovalStatus" : res.data.result.supplierContractApprovalStatus,
                    "supplierContractApprovedModified_By" : res.data.result.supplierContractApprovedModified_By,
                    "supplierContractModified_By" : res.data.result.supplierContractModified_By,
                    "supplierContractModified_Datetime" : res.data.result.supplierContractModified_Datetime,
                    "supplierContractWorkFlowID" : res.data.result.supplierContractWorkFlowID,

                    "freightChargesModified_By" : res.data.result.freightChargesModified_By,
                    "freightChargesModified_Datetime" : res.data.result.freightChargesModified_Datetime,
                    "freightChargesStatus" : res.data.result.freightChargesStatus,

                    "estimatedCostSheetStatus" : res.data.result.estimatedCostSheetStatus,
                    "estimatedCost`sheetApprovalModified_Datetime" : res.data.result.estimatedCostsheetApprovalModified_Datetime,
                    "estimatedCostsheetApprovedModified_By" : res.data.result.estimatedCostsheetApprovedModified_By,

                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then(res => {
                    if(res.data.responseCode == '304001'){
                        MySwal.fire(
                            {
                            icon: 'error',
                            title: 'Something went wrong',
                            confirmButtonText: 'Okay',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                      `
                        }).then(() => {
                            props.back()
                        }); 
                    }
                    else if(res.data.responseCode == '304000'){
                        MySwal.fire(
                            {
                            icon: 'success',
                            title: 'Trade Updated Successfully!',
                            html: '<p>Trade Number: ' + res.data.result + '</p>',
                            type: 'success',
                            confirmButtonText: 'Okay',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                        `
                        }).then(() => {
                            props.back()
                        });
                    }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
            console.log(final_json) 
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then(res => {
                if(res.data.responseCode == '304001'){
                    MySwal.fire(
                        {
                        icon: 'error',
                        title: 'Something went wrong',
                        //type: 'error',
                        confirmButtonText: 'Okay',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        props.back()
                    }); 
                }
                else if(res.data.responseCode == '304000'){
                    MySwal.fire(
                        {
                        icon: 'success',
                        title: 'Trade Initiated Successfully!',
                        html: '<p>Trade Number: ' + '<b>' + res.data.result + '</b>' +  '</p>',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        confirmButtonClass: "btn btn-primary",
                        backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                      }).then(() => {
                        props.back()
                    });
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })   
            console.log(final_json)
            console.log(props.splitData)
        }
        setdisable(false)
    }
    const moveToBack = () => {
        props.onBack(props,3)
    }
    const handleDOC = (date) => {
        SetDateOfIncorporation(date)
        formik.values.blDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }
    const taxDocumentHandler = (file) => {
        formik.values.blDocument = file.base64;
        settaxDocumentName(file.name)
    }
    const handleModeOfTransport = () => {
        setModeOfTransportModal(true)
    }
    const handleIncoTerms = () => {
        setIncoTermsModal(true)
    }
    const close = () => {
       if(incotermsModal == true){
        setIncoTermsModal(false)
       }
       else if(modeOfTransportModal == true){
        setModeOfTransportModal(false)
       }
    }
    const handleIncoTermsValue = (data) => {
        formik.values.incotermsID = parseInt(data.incotermsID)
        setIncotermsLabel(data.tradeIncoTermsValue)
    }
    const handleModeOfTransortValue = (data) => {
        console.log(data)
        formik.values.modeOfTransportID = parseInt(data.modeOfTransportID)
        setModeOfTransportLabel(data.tradeModeOfTransportValue)
    }
  return (
    <>
        <div className='min-height-1150'>
            <div className='card card-body p-4 br-8 mb-4'>
            <div className = 'form-title font-16 font-weight-500  color-4F5466'>
                <p>Shipment Related Information</p>
            </div>
            <Row>
                    <Col md={3}>
                    <div className = 'd-flex justify-content-between'>
                            Mode Of Transport
                        <button className = 'border-0 bg-transparent' onClick={handleModeOfTransport}>
                            <img src ='/assets/images/supports/penIcon.svg' />
                        </button>
                        </div>
                        <div className ='bg-light p-3 my-2'>
                                <Form.Check 
                                className = ''
                                label= {modeOfTransportLabel}
                                defaultChecked = {true}
                                type='radio'
                                />
                        </div>
                    </Col>

                    <Col md={3}>
                    <div className = 'd-flex justify-content-between'>
                        Incoterms
                    <button className = 'border-0 bg-transparent' onClick={handleIncoTerms}>
                        <img src ='/assets/images/supports/penIcon.svg' />
                    </button>
                    </div>
                    
                    <div className ='bg-light p-3 my-2'>
                    <Form.Check 
                             className = ''
                             label= {incoTermsLabel}
                             defaultChecked = {true}
                             type='radio'
                            />
                    </div>

                    </Col>

                    <Col md={3}>
                    <FormFields.InputField
                                name='shipmentSize'
                                label='Shipment Size'
                                disabled={true}
                                classname='mt-4'
                                type='number'
                                val={formik.values.shipmentSize}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                    </Col>

                    <Col md={3} className='laycan-date'>
                    <img src = 'assets/images/supports/datePickerCalendar.svg' />
                            <DatePicker
                                className="form_input mt-4"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Laycan period"
                                onChange={(update) => {
                                    setDateRange(update);
                              }}
                              dateFormat="dd/MM/yyyy"
                                //placeholderTest = "Laycan Period"
                                />
                    </Col>
                </Row>
                </div>
                <div className='card card-body p-4 br-8 mb-4'>
                    <div className = 'form-title font-16 font-weight-500  color-4F5466'>
                        <p className='mb-0'>Loading Port Related Information</p>
                    </div>
                    <Row>
                    <FormFields.InputField
                                md={3}
                                name='loadPort'
                                label='Load Port'
                                classname='pt-2'
                                type='text'
                                val={formik.values.loadPort}
                                errors={formik.touched.customerName ? formik.errors.customerName : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='loadPortCountry'
                                label='Load Port Country'
                                classname='pt-2'
                                type='text'
                                val={formik.values.loadPortCountry}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                             <FormFields.InputField
                                md={3}
                                name='loadQuantityInspectionAgency'
                                label='Quantity Inspection Agency'
                                classname='pt-2'
                                type='text'
                                val={formik.values.loadQuantityInspectionAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='loadQualityInspectionAgency'
                                label='Quality Inspection Agency'
                                classname='pt-2'
                                type='text'
                                val={formik.values.loadQualityInspectionAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='loadPortAgency'
                                label='Load Port Agent'
                                classname='pt-2'
                                type='text'
                                val={formik.values.loadPortAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='loadPortRate'
                                label='Load Rate'
                                classname='pt-2'
                                type='number'
                                val={formik.values.loadPortRate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                    </Row>
                </div>
                <div className='card card-body p-4 br-8 mb-4'>
                    <div className = "d-flex justify-content-between">
                        <p className='mb-0 form-title font-16 font-weight-500 color-4F5466'>Destination Port Related Information</p>
                       
                    </div>
                    <Form>
                        <Row>
                            <FormFields.InputField
                                md={3}
                                name='destinationPort'
                                label='Destination Port'
                                classname='pt-3'
                                type='text'
                                val={formik.values.destinationPort}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='destinationPortCountry'
                                label='Destination Port Country'
                                classname='pt-3'
                                type='text'
                                val={formik.values.destinationPortCountry}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                             <FormFields.InputField
                                md={3}
                                name='destinationQuantityInspectionAgency'
                                label='Quantity Inspection Agency'
                                classname='pt-3'
                                type='text'
                                val={formik.values.destinationQuantityInspectionAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='destinationQualityInspectionAgency'
                                label='Quality Inspection Agency'
                                classname='pt-3'
                                type='text'
                                val={formik.values.destinationQualityInspectionAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='destinationPortAgency'
                                label='Destination Port Agent'
                                classname='pt-3'
                                type='text'
                                val={formik.values.destinationPortAgency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                            /> 
                             <FormFields.InputField
                                md={3}
                                name='destinationPortRate'
                                label='Destination Rate'
                                classname='pt-3'
                                type='number'
                                val={formik.values.destinationPortRate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Row>
                    </Form>
                </div>
                <Card.Footer className='form-card-footer right-auto left-auto d-flex fixed-bottom'>
                <span><BlueBorderButton value='Prev' clickEvent = {moveToBack}/></span>
                <span className='pl-10'><BlueButton value='Submit' clickEvent={()=>{
                    moveToNext()
                    setdisable(true)
                }} disabled={disableButton} /></span>
            </Card.Footer>
        </div>
        {incotermsModal == true ?
        <IncoTermsModal close={close}  handleIncoTermsValue = {handleIncoTermsValue} incotermsID = {formik.values.incotermsID}/>
        :
        modeOfTransportModal == true ? 
        <ModeOfTransportModal close={close} handleModeOfTransortValue = {handleModeOfTransortValue} modeOfTransportID = {formik.values.modeOfTransportID}/>
        :
        ''
        }
    </>
  )
}

export default Index