
export const DashboardIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/Dashboard.svg'/>
    )
}

export const CustomerIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/Customers.svg'/>
    )
}

export const SupplierIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/Supplier.svg'/>
    )
}
export const ProductIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/Product.svg'/>
    )
}
export const ShipmentIcon = ()=>{
    return(
        <img src='../../assets/images/sidebaricons/Shipments.svg'/>
    )
}

export const TradeSalesPurchase = ()=>{
    return(
        <img src='assets/images/sidebaricons/Trading-Sales-Purchase.svg'/>
    )
}

export const SettingsIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/Settings.svg' className='mr-10'/>
    )
}

export const UserIcon = ()=>{
    return(
        <img src='assets/images/sidebaricons/User.svg' className='mr-10'/>
    )
}

export const Downloads = ()=>{
    return(
        <img src='assets/images/sidebaricons/downloads.svg' className='mr-10'/>
    )
}

export const Invoices = ()=>{
    return(
        <img src='assets/images/sidebaricons/reports.svg' className='mr-10'/>
    )
}

export const Payment = ()=>{
    return(
        <img src='assets/images/sidebaricons/invoices.svg' className='mr-10'/>
    )
}

export const Financial = ()=>{
    return(
        <img src='assets/images/sidebaricons/financial.svg' className='mr-10'/>
    )
}