import React, { useEffect, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Button, Col } from "reactstrap"
import { Row, Form } from "react-bootstrap"
import { useState } from 'react'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as FormFields from "../../../components/formcomponents"
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

export default function ViewMeetingReport(props) {
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [customerdata, setcustomerdata] = useState([])
    const [supplierdata, setsupplierdata] = useState([])
    const componentRef = useRef();

    useEffect(() => {
        getcustsuppdetails()
    }, [])


    console.log(props, 'props')
    const toggle = () => {
        setmodal(!modal)
    }

    useEffect(() => {
        console.log(customerdata)
        console.log(supplierdata)
    }, [customerdata, supplierdata])
    const getcustsuppdetails = () => {
        if (props.meetingdata.meetinglink != null) {
            if (props.meetingdata.meetinglink.tag == 'Customer') {
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "id": parseInt(props.meetingdata.meetinglink.referenceID)
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        console.log(res.data.result)
                        setcustomerdata(res.data.result[0])
                        console.log(customerdata, 'customerdata')
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                        console.log(error)
                    })
            }

            else if (props.meetingdata.meetinglink.tag == 'Supplier') {
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "id": parseInt(props.meetingdata.meetinglink.referenceID)
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        console.log(res.data.result)
                        setsupplierdata(res.data.result[0])
                        console.log(supplierdata, 'supplierdata')
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                        console.log(error)
                    })
            }
        }
    }
    const pageStyle = `
    @page {
    size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
    @media print {
    div.page-footer {
    position: fixed;
    bottom:0mm;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: #fff;
    /* For testing */
    background: red;
    opacity: 0.5;

    page-break-after: always;
    }
    .page-number:before {
        /* counter-increment: page; */
        content: "Pagina "counter(page);
    }


    }
    body {
    marginBottom:50px
    }
`;
    const handlePrint = () => {
        setTimeout(() => {
            const domElement = document.getElementById("download_section");
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm');
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    doc.save(props.meetingdata.meeting.is_Meeting == 1 ? 'Meeting_Reports.pdf' : 'Tour_Reports.pdf');
                })
        }, 300);
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
            <ModalBody className='service-request-modal-wrapper scrollContract' style={{ padding: '0' }}>
                <div className='meeting_innner_info mb-10-rem'>
                    <div className='mx-2 d-flex justify-content-end me-5'>
                        <BlueBorderButton value="PRINT" clickEvent={handlePrint} />
                    </div>
                    <div id="download_section">
                        <Row>
                            <center>
                                <Col>
                                    <h5>{props.meetingdata.meeting.is_Meeting == 1 ? 'Meeting' : 'Tour'} Report</h5>
                                </Col>
                            </center>
                        </Row>
                        <Row className='mx-5'>
                            <Col md={12} className="mt-3 p-0" >
                                <p className='font-weight-500'>Meeting Reference No:<span className='font-16 ms-4 font-weight-600'>{props.meetingdata.meeting.meetingCode}</span></p>
                                {/* <p className='font-12'>(must be obtained from Commercial/Finance for document control under respective trade/business/stackholder)</p> */}
                            </Col>

                        </Row>

                        <br />
                        <Row className='mx-5'>
                            <br />

                            <table className='meetingrpt_table'>
                                <thead className='bg-meetExpo'>
                                    <th>
                                        PART A
                                    </th>
                                    <th>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Date Of Meeting
                                        </td>
                                        <td>
                                            <strong>{Moment(props.meetingdata.meeting.meetingDate).format("DD/MM/yyyy")}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Type Of Meeting
                                        </td>
                                        <td>
                                            <strong>{props.meetingdata.meeting.meetingType == 1 ? 'Internal' : 'External'}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Host Of Meeting
                                        </td>
                                        <td>
                                            <strong> {props.meetingdata.meeting.meetingType == 1 ? 'Internal' : 'External'}</strong>

                                        </td >
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Name of Host
                                        </td>
                                        <td>
                                            <strong> {props.meetingdata.meeting.meetingUser != null && props.meetingdata.meeting.meetingUser.userName}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Name of the company ,personnel,department and designation (in case host is external)
                                        </td>
                                        <td>
                                            <strong>
                                                {(props.meetingdata.meeting.meetingType != null && props.meetingdata.meeting.meetingType == 1) &&
                                                    <>  {props.meetingdata.meeting.meetingUser.userName} <br />
                                                        {props.meetingdata.meeting.meetingUser.designation} <br />
                                                        {props.meetingdata.meeting.meetingUser.department}</>}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Name of the employee ,designation and <br /> department (in case host is internal)
                                        </td>
                                        <td>
                                            <strong>
                                                {(props.meetingdata.meeting.meetingType != null && props.meetingdata.meeting.meetingType == 1) &&
                                                    <>
                                                        {props.meetingdata.meeting.meetingUser.userName} <br />
                                                        {props.meetingdata.meeting.meetingUser.designation} <br />
                                                        {props.meetingdata.meeting.meetingUser.department}</>}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Agenda of Meeting
                                        </td>
                                        <td>

                                            {props.meetingdata.meeting.meetingAgenda.map((values, k) => {
                                                return (
                                                    <>

                                                        <>{k + 1}. <strong> {values.agendaName}</strong><br /></>
                                                    </>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            Name of participants
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-50 br-rightMeet'>
                                            <div className='font-weight-400 '>Internal</div> <br />

                                            {props.meetingdata.meeting.meetingRequiredAttendance.filter(x => x.meetingUser.is_Internal == true).map((values, k) => {
                                                return (
                                                    <>
                                                        <>{k + 1}. <strong>{values.meetingUser.userName}</strong><br /></>
                                                    </>
                                                )
                                            })}

                                        </td>
                                        <td>
                                            <div className='font-weight-400'> External </div>
                                            <br />

                                            {props.meetingdata.meeting.meetingRequiredAttendance.filter(x => x.meetingUser.is_Internal != true).map((values, k) => {
                                                return (
                                                    <>
                                                        <>{k + 1}. <strong> {values.meetingUser.userName} </strong><br /></>
                                                    </>
                                                )
                                            })}

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />
                        </Row>
                        <br />

                        <Row className='mx-5'>

                            <table className='meetingrpt_table'>
                                <thead className='bg-meetExpo'>
                                    <th>
                                        PART B: Discussion points,actions,responsibility (detail based on agenda)
                                    </th>
                                    <th>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            
                                            <div>
                                                <br />
                                                {props.meetingdata.meeting.meetingAgenda.map((values, k) => {
                                                    return (
                                                        <>
                                                            <><div className='font-weight-500'>Agenda: <span className='font-weight-600 font-14 ms-2'>{values.agendaName}</span>  </div> <br />
                                                           
                                                                <div className='font-weight-400'>Discussion Point :  <span className='font-weight-500 font-14 ms-2'> {values.discussionPoint} </span> </div> <br />
                                                                <div className='font-weight-400'>Action List: <br />{values.meetingAgendaTask.map((task, t) => {
                                                                    return (
                                                                        <>
                                                                            {t + 1}. {task.task}<br />
                                                                        </>

                                                                    )
                                                                })}
                                                                </div><br />
                                                            </>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='font-weight-600'>
                                                2.  Specific points of concern,suggestions,call to action (based on agenda or discussed beyond agenda)
                                            </div>
                                            <div>
                                                <br />
                                                {props.meetingdata.meeting.is_Meeting == 1 ?
                                                    <>
                                                        {props.meetingdata.meeting.meetingAgenda.map((values, k) => {
                                                            return (
                                                                <>
                                                                    <><div className='font-weight-400'>Agenda: <span className='font-weight-500 font-14 ms-2'>{values.agendaName}</span> </div><br />
                                                                        <div className='font-weight-400'>Suggestions:</div> 
                                                                            {values.agendaSuggestion.map((sugg, t) => {
                                                                                return (
                                                                                    <>
                                                                                        {t + 1}. {sugg.description}<br />
                                                                                        {sugg.meetingAgendaTask.map((task, m) => {
                                                                                            return (
                                                                                                <>
                                                                                                    Task : {m + 1}. {task.task}<br />
                                                                                                    { }
                                                                                                </>

                                                                                            )
                                                                                        })}
                                                                                    </>

                                                                                )
                                                                            })}<br />
                                                                    </>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                        <div className='font-weight-500'>Suggestions:
                                                        </div>
                                                            {props.meetingdata.meeting.agendaSuggestion.map((sugg, t) => {
                                                                return (
                                                                    <>
                                                                        {t + 1}. {sugg.description}<br />
                                                                        {sugg.meetingAgendaTask.map((task, m) => {
                                                                            return (
                                                                                <>
                                                                                    Task : {m + 1}. {task.task}<br />
                                                                                    { }
                                                                                </>

                                                                            )
                                                                        })}
                                                                    </>

                                                                )
                                                            })}
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <div className='font-weight-600'>
                                                    3.  Any other point of interest which may be useful for any decision making
                                                </div> <br />
                                            </div>
                                            <div>

                                                <div className='font-weight-400'> {props.meetingdata.meeting.meetingOtherIntPoints}</div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </Row>

                        <br />

                        <Row className='mx-5'>
                            <table className='meetingrpt_table'>
                                <thead className='bg-meetExpo'>
                                    <th>
                                        <div>PART C (to be filled for the first meeting with external party or variation from the first meeting in any of the status - market,capacity,financial,products,ownership,expansion etc.)</div>
                                        </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                a. Ownership status/structure
                                            </div>
                                            <div>
                                                <br />
                                                {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Customer' &&

                                                    (customerdata != null && Object.keys(customerdata).length > 0) &&
                                                    customerdata.customerdata.customerCompanyDirector.map((values, k) => {
                                                        return (
                                                            <>
                                                                <><div className='font-weight-400'>Name: <span className='font-weight-500'> {values.directorName}</span></div>
                                                                <div className='font-weight-400 mt-2'>  Designation :<span className='font-weight-500'> {values.directorDesignation}</span> </div> <br />
                                                                </>
                                                            </>
                                                        )
                                                    })}

                                                {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Supplier' &&
                                                    (supplierdata != null && Object.keys(supplierdata).length > 0) &&

                                                    supplierdata.supplierdata.supplierCompanyDirector.map((values, k) => {
                                                        return (
                                                            <>
                                                                <>  <div className='font-weight-400'>Name:<span className='font-weight-500'> {values.directorName}</span></div>
                                                                    <div className='font-weight-400 mt-2'>Designation :<span className='font-weight-500'> {values.directorDesignation}</span> </div> <br />
                                                                </>
                                                            </>
                                                        )
                                                    })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>b. Products</div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                c. Revenue/turnover
                                            </div>
                                            <Row className='mx-2'>
                                                <br />

                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Year
                                                            </th>
                                                            <th>
                                                                Value
                                                            </th>
                                                        </tr>

                                                        <tbody>
                                                            {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Customer' &&
                                                                (customerdata != null && Object.keys(customerdata).length > 0) &&
                                                                customerdata.customerdata.customerFinancialInformation.map((values, k) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td style={{ width: '50%' }}>
                                                                                    {values.year}
                                                                                </td>
                                                                                <td>
                                                                                    {values.value} USD
                                                                                </td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })}

                                                            {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Supplier' &&
                                                                (supplierdata != null && Object.keys(supplierdata).length > 0) &&

                                                                supplierdata.supplierdata.supplierFinancialInformation.map((values, k) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    {values.year}
                                                                                </td>
                                                                                <td>
                                                                                    {values.value} USD
                                                                                </td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </thead>
                                                </table>

                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                d. Market share,<span className='ms-1'>Geography</span>
                                            </div>
                                            <div>
                                                <br />

                                                {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Customer' &&
                                                    (customerdata != null && Object.keys(customerdata).length > 0) &&

                                                    customerdata.customerdata.customerShareholder.map((values, k) => {
                                                        return (
                                                            <>
                                                                <><span className='mx-1 dotSep'></span>Name: {values.shareholderName}<br />
                                                                    Nationality : {values.shareholderNationality} <br />
                                                                </>
                                                            </>
                                                        )
                                                    })}

                                                {props.meetingdata.meetinglink != null && props.meetingdata.meetinglink.tag == 'Supplier' &&
                                                    (supplierdata != null && Object.keys(supplierdata).length > 0) &&

                                                    supplierdata.supplierdata.supplierShareholder.map((values, k) => {
                                                        return (
                                                            <>
                                                                <><span className='mx-1 dotSep'></span>Name: {values.shareholderName}<br />
                                                                    Nationality : {values.shareholderNationality} <br />
                                                                </>
                                                            </>
                                                        )
                                                    })}

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>e. Value and volume of products</div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> f. Various quality of product available </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> g. Business stackholders </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>  h. Short term plan of business </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> i. Long term plan of business </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> j. Business associates</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> k. Production units details, if any</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div> l. Any other information of relavance to decision making and interest. </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>

                        <br />
                        <Row className='mx-5'>
                            <table className='meetingrpt_table'>
                                <thead className='bg-meetExpo'>
                                    <tr >
                                        <td colspan='5' className='w-100'><strong>PART D Profile of Key people of to the external stakeholders in the meeting</strong></td>
                                    </tr>
                                  
                                </thead>

                                {props.meetingdata.meeting.meetingKeyPeople.map((values, k) => {
                                    return (
                                        <tbody>
                                            <tr>
                                                <th  className=' br-rightMeet'>
                                                <div className = 'cusweight font-13'>  Name </div>
                                                </th>
                                                <th className=' br-rightMeet'>
                                                <div className = 'cusweight font-13 '>Qualification</div>
                                                </th>
                                                <th className=' br-rightMeet'>
                                                <div className = 'cusweight font-13 '>Previous Company</div>
                                                </th>
                                                <th className=' br-rightMeet'>
                                                <div className = 'cusweight font-13'> Current Company & Position </div>
                                                </th>
                                                <th>
                                                <div className = 'cusweight font-13 '> Current Hierarchy </div>
                                                </th>
                                            </tr>
                                               
                                            
                                            <tr>
                                            <td className=' br-rightMeet'>
                                            <div className = 'font-weight-500 font-14'> {values.name} </div>
                                                </td>
                                            
                                                <td className=' br-rightMeet'>
                                                <div className = 'font-weight-500 font-14'> {values.qualification}</div>
                                                </td>
                                                <td className=' br-rightMeet'>
                                                <div className = 'font-weight-500 font-14'>  {values.prevCompany}</div>
                                                </td>
                                                <td className=' br-rightMeet'>
                                                <div className = 'font-weight-500 font-14'>  {values.currentCompany} - {values.currentPosition}</div>
                                                </td>
                                                <td>
                                                <div className = 'font-weight-500 font-14'>  {values.currentHierarchy}</div>
                                                </td>
                                                
                                            </tr>
                                        
                                        </tbody>
                                    )
                                })}
                            </table>


                        </Row>
                    </div>

                </div>

            </ModalBody>
        </Modal>
    )
}