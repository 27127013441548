import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Moment from 'moment'
import styles from './styles.module.css'
import GreetingsContainer from "./Components/Greeting Component/index"
import Tradeoverview from "./Components/Trade Overview/index"
import Trends from "./Components/Trends/index"
import ShipmentOverview from "./Components/Shipment Overview/index"
import CashFlows from "./Components/Cash Flows/index"
import * as CONFIG from "../../../helpers/config"
import Division from "../Common Components/Divisions/index"
import TotalCounter from "../Common Components/Total Counters/index"
import Notifications from "../Common Components/Notifications/index"


export default function Index(props) {
    const { instance } = useMsal();
    const [customerCount, setCustomerCount] = useState()
    const [supplierCount, setSupplierCount] = useState()
    const [meetingCount, setMeetingCount] = useState()
    const [newTrades, setNewTrades] = useState()
    const [closedTradeDetails ,setClosedTradeDetails] = useState()
    const [shipmentDetails, setShipmentDetails] = useState()
    const [notification, setNotifications] = useState([])


    useEffect(() => {
      console.log(props.toDate)
      console.log(props.fromDate)
    },[props])
    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CUSTOMER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setCustomerCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_SUPPLIER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setSupplierCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "userID" : CONFIG.LoginID
        
    }
    axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DB_MEETING_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setMeetingCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "ID": CONFIG.LoginID
    }
    axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setNotifications(res.data.result)
    })
    },[])


    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "dashboardTypeID": parseInt(1),
        "companyID":parseInt(props.companyID)

    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_NEW_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setNewTrades(res.data.result)
        //setNotifications(res.data.result)
    })
    },[props])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "termsID":props.terms,
        "dashboardTypeID": parseInt(1),
        "companyID":parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CLOSED_TRADE_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setClosedTradeDetails(res.data.result)
        //setNotifications(res.data.result)
    })
    },[props])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "termsID":props.terms,
        "companyID":parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_SHIPMENT_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setShipmentDetails(res.data.result)
        //setNotifications(res.data.result)
    })
    },[props])



    useEffect(()=>{
      console.log(notification)
    },[notification])
    
    return (
            <>
              <div className='inner-page-content'>
                <div className = 'row'>
                  <div className = 'col-md-5 col-sm-12 mb-3 mb-md-0'>
                      <GreetingsContainer className="mt-2"
                         closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}
                         toDate = {props.toDate} fromDate = {props.fromDate} terms = {props.terms}
                         companyID = {props.companyID}
                      />
                  </div>
                  <div className = 'col-md-3 col-sm-12 mb-3 mb-md-0'>
                    <Division companyID = {props.companyID} terms = {props.terms}  dashboardTypeID = {props.dashboardTypeID} toDate = {props.toDate} fromDate = {props.fromDate}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12 mb-3 mb-md-0'>
                    <div className = 'row mt-md-2 mt-3'>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {customerCount != null ? customerCount : 0} text = {'CUSTOMERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                      </div>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {supplierCount != null ? supplierCount : 0} text = {'SUPPLIERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                      </div>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {meetingCount != null ? meetingCount : 0} text = {'MEETINGS'} img = '/assets/images/supports/dashboard-meeting-counter-icon.svg'/>
                      </div>
                    </div>
                    <div className = 'row mt-4'>
                      <div className = 'col-12'>
                        <Notifications companyID = {props.companyID} notification = {notification != null && notification.length > 0 ? notification : '' }/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className = {`ms-4 mt-5 gil-medium ${styles.titleHeader}`}>
                  Trade Overview
                </div>
                <div div className = 'mt-4 row'>
                  <div className = 'col-md-4 col-sm-12'>
                    <Tradeoverview companyID = {props.companyID}  newTrades = {newTrades != null ? newTrades : 0}   closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12 mt-md-0 mt-4 '>
                    <Trends companyID = {props.companyID}  toDate = {props.toDate} fromDate = {props.fromDate} terms = {props.terms} closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12 mt-md-0 mt-4'>
                    <ShipmentOverview companyID = {props.companyID}  shipmentDetails = {shipmentDetails != null ? shipmentDetails : 0}/>
                  </div>
                </div>
                <div className = {`mt-5 gil-medium ${styles.titleHeader}`}>
                    Cash Flow
                </div>
                <div className = 'mt-4 row mb-4'>
                  <div className = 'col-md-6 col-sm-12'>
                    <CashFlows companyID = {props.companyID} />
                  </div>
                </div>
              </div>
            </>
    )
}
