import React, { useEffect, useState } from 'react'
import {Card} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import Generalinformation from './generalinformation';
import ContactInformation from './Contact Info'
import FinancialInformation from './FinancialInformation';
import ParentCompany from './Parent Company/ParentCompany';
import DirectorsInformation from './Directors Information/DirectorsInformation';
import MarketingInformation from './Marketing Information/MarketingInformation';

export default function AddEdit(props) {

    const [selectedTab, setSelectedTab]= useState(0)
    const [general, setGeneral] = useState([]);
    const [contact, setContact] = useState([]);
    const [finance, setFinance] = useState([]);
    const [isFinanceSet, setIsFinanceSet] = useState(false)
    const [isContactSet, setIsContactSet] = useState(false)
    const [isParentSet, setIsParentSet] = useState(false)
    const [isDirectorSet, setIsDirectorSet] = useState(false)
    const [isMarketingSet, setIsMarketingSet] = useState(false)
    const [parent, setParent] = useState([]);
    const [director, setDirector] = useState([]);
    const [marketing, setMarketing] = useState([]);
    const [isBackClicked, setIsBackClicked] = useState(false)

    console.log(props)
    useEffect(()=>{
        console.log(props, 'data')
        console.log(general)
        console.log(contact)
        console.log(finance)
        console.log(parent)
        console.log(director)
        console.log(marketing)
    },[general, contact, finance, parent, director, marketing])

    const moveToNext = (data, index)=>{
        console.log(data);
        console.log(data, 'test');
        if(index == 1){
            if(props.isEdit == true){
                Object.assign(props.data[0], data)
            }
            //Object.assign(props.data[0], data)
            setSelectedTab(index)
            setIsBackClicked(false)
            setGeneral(data)
        }
        else if(index == 2){
            setIsBackClicked(false)
            setIsContactSet(true)
            setContact(data)
            setSelectedTab(index)
        }
        else if(index == 3){
            setIsFinanceSet(true)
            setFinance(data)
            setSelectedTab(index)
        }
        else if(index == 4){
            console.log('4th index')
            setIsBackClicked(false)
            let generalParentMerge = {}
            setParent(data)
            generalParentMerge = {
                ...general,
                ...parent
            }
            setGeneral(generalParentMerge)
            setSelectedTab(index)
            setIsParentSet(true)
        }
        else if(index == 5){
            setIsBackClicked(false)
            setDirector(data)
            setSelectedTab(index)
            setIsDirectorSet(true)
        }
        else if(index == 6){
            setIsBackClicked(false)
            setMarketing(data)
            
            //setSelectedTab(index)
        }
    }
    const handleBackClick = (index) => {
        console.log('clicked', index)
        
       if(index == 1){
            setSelectedTab(index)
            setIsBackClicked(true)
        }
        else if(index == 0){
            setSelectedTab(index)
            setIsBackClicked(true)
        }
        else if(index == 2){
            setSelectedTab(index)
            setIsBackClicked(true)
        }
        else if(index == 3){
            setSelectedTab(index)
            setIsBackClicked(true)
        }
        else if(index == 4){
            setSelectedTab(index)
            setIsBackClicked(true)
        }
        
    }
    return (
        <div className=' br-8 py-3'>
            <Tabs selectedIndex={selectedTab}>
                <Card className='tabs-wrapper'>
                    <TabList>
                        <Tab>General Information</Tab>
                        <Tab>Contact Information</Tab>
                        <Tab>Financial Information</Tab>
                        <Tab>Parent Company</Tab>
                        <Tab>Directors Information</Tab>
                        <Tab>Marketing Information</Tab>
                    </TabList>               
                </Card>
                <TabPanel className='mt-1-rem'>
                    <Generalinformation 
                        handleCancel = {props.handleCancel} 
                        onSave = {props.onSave} 
                        onNext={moveToNext} 
                        general={general} 
                        dataForEdit = {props.isEdit && props.data[0]} 
                        isEdit = {props.isEdit} 
                        supplierdata = {isBackClicked == true ? general : ''} isBackClicked = {isBackClicked}
                    />
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <ContactInformation onNext={moveToNext} contact = {contact} dataForEdit = {props.isEdit && props.data[0]} isContactSet={isContactSet} isEdit = {props.isEdit} supplierdata = {isBackClicked == true ? contact : isContactSet == true ? contact : ''} isBackClicked = {isBackClicked} backClickEvent = {handleBackClick}/>
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <FinancialInformation onNext={moveToNext} dataForEdit = {props.isEdit && props.data[0]} isEdit = {props.isEdit} isFinanceSet={isFinanceSet} supplierdata = {isFinanceSet == true && finance} isBackClicked = {isBackClicked} backClickEvent = {handleBackClick} />
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <ParentCompany registeredCompanyAddress = {general} onNext={moveToNext} parent={parent} dataForEdit = {props.isEdit&& props.data[0]} isEdit = {props.isEdit} supplierdata = {isBackClicked == true ?  parent : isParentSet == true ? parent : ''} isParentSet = {isParentSet} isBackClicked = {isBackClicked} backClickEvent = {handleBackClick}/>
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <DirectorsInformation onNext={moveToNext} dataForEdit = {props.isEdit&& props.data[0]} isEdit = {props.isEdit}  supplierdata = {isBackClicked == true ?  director : isDirectorSet == true ? director : ''} isDirectorSet = {isDirectorSet} isBackClicked = {isBackClicked} backClickEvent = {handleBackClick}/>
                </TabPanel>
                <TabPanel className='mt-1-rem'>
                    <MarketingInformation onNext={moveToNext} 
                    generalTabInfo={general}
                    supplierContactInformation={contact}
                    financialTabInfo={finance}
                    supplierBankAccountInformation = {finance.bankListFinal}
                    supplierFinancialInformation = {finance.revenueDataList}
                   //supplierBankAccountInformation = {finance.bankListFinal}
                    parentTabInfo={parent}
                    supplierCompanyDirector = {director[0]}
                    supplierShareholder = {director[1]}
                    supplierSubsidiaries = {director[2]}
                    dataForEdit = {props.isEdit && props.data[0]} 
                    isEdit = {props.isEdit}
                    backClickEvent = {handleBackClick}
                    onSave={props.onSave}
                    getList = {props.getList}
                    />
                </TabPanel>
            </Tabs>
        </div>
    )
}
