import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import TradeDetails from './tradedetails';
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import * as FormFields from "../../../components/formcomponents";
import html2canvas from "html2canvas";
import { Dropdown, Menu, Space, DatePicker } from 'antd';
import { Slider, Switch } from 'antd';
import { useFormik } from 'formik'


export const GetDivision = (props) => {
    const { instance } = useMsal();
    const [divisionlist, setdivisionlist] = useState([])
    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(CONFIG.CompanyID)
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setdivisionlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }, [])

    return (
        <>
            {divisionlist.map((re, k) => {
                return (
                    <>
                        <option value={re.divisionID} selected={re.divisionID == props.val}>{re.divisionName}</option>
                    </>
                )
            })}
        </>
    )
}

export default function Index(props) {
    const { instance } = useMsal();
    const [rpttradedata, setrpttradedata,] = useState()
    const [isDetails, setisDetails] = useState(false)
    const [tradeId, settradeId] = useState(0)
    const [count, setcount] = useState(0)
    const [sum, setsum] = useState(0)
    const [qty, setqty] = useState(0)
    const [rpttradedataall, setrpttradedataall] = useState()
    const [showsearch, setshowsearch] = useState(false)
    const [term, setterm] = useState('Interval')
    const [calendarstatus, setcalendarstatus] = useState(false)
    const { RangePicker } = DatePicker;
    const [priceval1, setpriceval1] = useState(0)
    const [priceval2, setpriceval2] = useState(0)
    const [qtyval1, setqtyval1] = useState(0)
    const [qtyval2, setqtyval2] = useState(0)
    const [todate, settodate] = useState(false)
    const [fromdate, setfromdate] = useState(false)
    const [coalval, setcoalval] = useState(0)
    const [metalval, setmetalval] = useState(0)
    const [companyList, setCompanyList] = useState()
    const [companyID, setCompanyID] = useState()
    const [pricemin, setPricemin] = useState(0)
    const [pricemax, setPricemax] = useState(100)
    const [quanmin, setQuanmin] = useState(0)
    const [quanmax, setQuanmax] = useState(100)


    const item = []

    useEffect(() => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'Company')
                setCompanyList(res.data.result)
                setCompanyID(res.data.result[0].company.companyID)
                //setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
                getAll(res.data.result[0].company.companyID)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    const formik = useFormik({
        initialValues: {
            DivisionID: 0
        }
    })
    const getAll = (id) => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "companyID": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_TRADE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setrpttradedata(res.data.result)
                setrpttradedataall(res.data.result)
                setcount(res.data.result.length)
                let sum = 0
                let qty = 0
                let coalSum = 0
                let metalSum = 0
                res.data.result.map((re) => {
                    sum += re.tradeValue
                    qty += re.qty
                    if (re.divisionID == 1) {
                        coalSum += re.tradeValue
                    }
                    else {
                        metalSum += re.tradeValue
                    }
                })
                setsum(sum)
                setqty(qty)
                setpriceval2(sum)
                setqtyval2(qty)
                setcoalval(coalSum)
                setmetalval(metalSum)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const openDetails = (e) => {
        console.log(e.currentTarget.id)
        let id = parseInt(e.currentTarget.id)
        settradeId(id);
        setisDetails(!isDetails)
    }

    const toggle = () => {
        setisDetails(!isDetails)
    }
    useEffect(() => {
        console.log(term)
    }, [term])

    const printDocument = () => {
        const domElement = document.getElementById("download_section");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('TradeReports.pdf');
            })
    }
    const handleSearch = (e) => {
        let val = e.target.value
        let filter = rpttradedataall.filter(x => x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.customercode.toLowerCase().includes(val.toLowerCase()) || x.tradeNo.toLowerCase().includes(val.toLowerCase()))
        setrpttradedata(filter)
    }
    const handleOpenFilter = () => {
        setshowsearch(!showsearch)
    }
    const onPriceChange = (value) => {
        console.log(value)
        setPricemin(value[0])
        setPricemax(value[1])
        let val1 = (sum * value[0]) / 100
        console.log(val1)
        setpriceval1(val1)
        let val2 = (sum * value[1]) / 100
        console.log(val2)
        setpriceval2(val2)
    }
    const onQuantityChange = (value) => {
        setQuanmin(value[0])
        setQuanmax(value[1])
        console.log(value)
        let val1 = (qty * value[0]) / 100
        console.log(val1)
        setqtyval1(val1)
        let val2 = (qty * value[1]) / 100
        console.log(val2)
        setqtyval2(val2)
    }

    const findmonthly = () => {
        setterm('monthly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const findquarterly = () => {
        setterm('quarterly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('quarter').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const findyearly = () => {
        setterm('yearly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findmonthly}>Monthly</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findquarterly}>Quarterly</span></>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findyearly}>Yearly</span></>
                    ),
                }
            ]}
        />
    );


    const setrrange = () => {
        setcalendarstatus(!calendarstatus)
    }
    const searchData = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "companyID": companyID,
            "fromDate": fromdate == false ? null : fromdate,
            "toDate": todate == false ? null : todate,
            "tradeStartValue": priceval1,
            "tradeEndValue": priceval2,
            "quantityStartValue": qtyval1,
            "quantityEndValue": qtyval2,
            "divisionID": parseInt(formik.values.DivisionID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_TRADE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setrpttradedata(res.data.result)

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const setDateRange = (date, dateString) => {
        setfromdate(new Date(dateString[0]))
        settodate(new Date(dateString[1]))
    }
    
    const handleCompanySelect = (e) => {
        console.log(e.target.value)
        setCompanyID(parseInt(e.target.value))
        getAll(parseInt(e.target.value))
    }

    const clearFilters = ()=>{
        setPricemin(0)
        setPricemax(100)
        setQuanmin(0)
        setQuanmax(100)
        setpriceval1(0)
        setpriceval2(sum)
        setqtyval1(0)
        setqtyval2(qty)
        getAll(companyID)
    }

    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='8.2' isOpenid={8} />

            {isDetails &&
                <TradeDetails data={rpttradedata} tag='Trade' id={tradeId} toggle={toggle} companyID={companyID}/>
            }

            <div className='width-100'>
                <Navbar />
                <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                    <div className='d-flex justify-content-center align-items-center '>
                        <BackButton />
                    </div>
                </div>
                <div id='download_section'>
                    <div className='mt-5 mt-md-0 bg-white px-4'>
                        <Row>
                            <Col md={4} sm={12} lg={4} className='d-flex align-items-center justify-content-center rt-b sec5 '>
                                <div>
                                    <span className='reports_count_header'>Total Trades</span>
                                    <div className='reports_count_value'>{count}</div>
                                </div>
                            </Col>
                            <Col md={4} sm={12} lg={4} className='d-flex align-items-center justify-content-center rt-b sec3 '>
                                <div className="ms-5">
                                    <span className='reports_count_header'>Total Quantity</span>
                                    <div className='reports_count_value'><NumberFormat value={qty} displayType={'text'} thousandSeparator={true} />
                                        <span className='reports_count_pre_suf'> MT</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} sm={12} lg={4} className='d-flex justify-content-center mt-5'>
                                <div className='text-center md-text-start'>
                                    <span className='reports_count_header d-flex'>Total Value</span>

                                    <div className='reports_count_value d-flex align-items-center'>
                                        <div className='reports_count_pre_suf'>
                                            $
                                        </div>
                                        <div>
                                            <NumberFormat value={parseFloat(sum).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                        {/*<div className='ReportsprofitIcon'>*/}
                                        {/*    10%*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className='d-flex'>
                                        <div className='rt-sm-b mt-4  d-flex pe-5'>
                                            <div className=''>
                                                <span className='reports_count_header'>Coal Trade</span>
                                                <div className='reports_count_value'><span className='reports_count_pre_suf'></span>
                                                    <NumberFormat value={parseFloat(coalval).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-4  d-flex ps-5 '>
                                            <div className=''>
                                                <span className='reports_count_header'>Metal Trade</span>
                                                <div className='reports_count_value'><span className='reports_count_pre_suf'></span>
                                                    <NumberFormat value={parseFloat(metalval).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            {/*<Col md={4} className='d-flex align-items-center justify-content-center sec2'>*/}

                            {/*</Col>*/}

                        </Row>
                    </div>
                    <div className='mx-4 px-3 mt-5 row'>
                        <div className='col-md-6 align-self-center'>
                            <div className='d-flex align-items-center'>
                                <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' />
                                <span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>ALL TRADE REPORTS</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='d-flex align-items-center justify-content-center'>

                                <div className='titleHeader intervals mx-4'>
                                    <Form.Select
                                        placeholder='Intervals'
                                        name='division'
                                        onChange={handleCompanySelect}
                                        onBlur={handleCompanySelect}
                                        style={{ borderRadius: '8px', border: 'none', padding: '11px 25px' }}
                                    >
                                        {companyList != null ?
                                            companyList.map((values, k) => (
                                                <option value={values.company.companyID}>{values.company.companyName}</option>
                                            ))

                                            :
                                            <option value={1}></option>}

                                    </Form.Select>
                                </div>
                                <div >
                                    <span className='me-5'><BlueButton value="EXPORT PDF" clickEvent={printDocument} /></span>
                                </div>
                                <div>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label='Search'>
                                            <Form.Control type='text' placeholder='Search'
                                                className="form_input form_input_color_change"
                                                name='Search'
                                                onChange={handleSearch}
                                                onBlur={handleSearch}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </div>
                                <div className='pl-10'>
                                    <img src='assets/images/bluefilter.svg' className='pointer' onClick={handleOpenFilter} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                    {showsearch &&
                        <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-4 '>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormFields.InputSelect
                                                md={6}
                                                classname='mb-3'
                                                name='DivisionID'
                                                label='Product Division'
                                                type='text'
                                                optionField={<GetDivision val={formik.values.DivisionID} />}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <span className='report_filter_title'>Date Range</span>
                                            <div className='date_range_filter d-flex justify-content-between'>
                                                <div>
                                                    <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                                                        <a onClick={(e) => e.preventDefault()}>
                                                            <Space className='d-flex justify-content-between align-items-center'>
                                                                {!calendarstatus &&
                                                                    <span className='meeting_term'>{term}</span>
                                                                }
                                                                <span><img src='assets/images/DownwardArrow.svg' /></span>
                                                            </Space>
                                                        </a>
                                                    </Dropdown>
                                                </div>
                                                <div className='pl-10'>
                                                    {calendarstatus ?
                                                        <RangePicker onChange={setDateRange} />
                                                        :
                                                        <img src='assets/images/calendar.svg' onClick={setrrange} />
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <span className='report_filter_title'>Price Range</span>
                                            <Slider
                                                range
                                                value={[pricemin, pricemax]}
                                                onChange={onPriceChange}
                                            />
                                            <div className='d-flex justify-content-between'>
                                                <span>{priceval1}</span>
                                                <span>{priceval2}</span>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <span className='report_filter_title'>Quantity Range</span>
                                            <Slider
                                                range
                                                value={[quanmin, quanmax]}
                                                onChange={onQuantityChange}
                                            />
                                            <div className='d-flex justify-content-between'>
                                                <span>{qtyval1}</span>
                                                <span>{qtyval2}</span>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <Button variant='primary' className='mx-3' size='md' onClick={searchData}>Search</Button>
                                            <Button variant='outline-primary' className='mx-3' size='md' onClick={clearFilters}>
                                                Clear Filters
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    }
                    <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-2 '>
                        <Card className=''>
                            <CardBody>
                                <div class="col-lg-12 p-md-4 p-0">
                                    <div class="table-responsive-sm ">
                                        <table className="table tCustom table-text">
                                            <thead className='p-1-rem'>
                                                <tr className='text-uppercase'>
                                                    <th>trade#</th>
                                                    <th>customer</th>
                                                    <th>product</th>
                                                    <th>quantity</th>
                                                    <th>trade value</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rpttradedata != null && rpttradedata.length > 0 ?
                                                    rpttradedata.map((values, k) => (
                                                        <tr>
                                                            <td className='font-weight-500 font-14'>
                                                                <p className='w-150 mb-0'>{values.tradeNo}</p>
                                                            </td>
                                                            <td className='font-weight-500 font-14 d-flex'>
                                                                <p className='w-150 mb-0'>{values.customername}</p>

                                                                <span className='tradeTrsactionsid statBg ml-1-rem'>{values.customercode}</span>
                                                            </td>
                                                            <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.productName}</p></td>
                                                            <td className='font-weight-500 font-14 '><p className='w-150 mb-0'><NumberFormat value={values.qty} displayType={'text'} thousandSeparator={true} /> {values.prodUOM}</p></td>
                                                            <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>

                                                                <NumberFormat value={parseFloat(values.tradeValue).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix='$ ' />
                                                            </p></td>
                                                            <td>
                                                                <Button variant='outline-secondary' id={values.tradeID} onClick={openDetails} >
                                                                    <img src='assets/images/rightarrow.svg' />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}