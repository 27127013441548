import React, { useState } from 'react'
import {Row, Col, ModalFooter} from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../components/formcomponents"
import { BlueButton, BlueBorderButton, CancelButton } from '../../../components/buttoncomponents'
import { useFormik } from 'formik'

export default function Informations_form(props) {
    const [isModalOpen, setIsModalOpen] = useState(props.isOpen)
    const [isError, setIsError] = useState(true);
    const toggle = ()=>{
        if(props.isEdit == true){
            props.handleBugFix()
        }
        setIsModalOpen(!isModalOpen)
        setTimeout(() => {
            props.toggle();
        }, 300);
    }
    const formik = useFormik(
        {
            initialValues:
            props.isEdit?
            {
                id : props.id,
                data: props.name
            }
            :
            {
                data : ''
            },
            validate: values => {
            
                let errors = {}
                const letters = /^[A-Za-z ]+$/;
                const phone = /^[0-9]+$/;
          
                if(!values.data){
                    errors.data = "Please fill in name of Company"
                }
                setIsError(true)
                sendBack();
                return errors
            }
        }
    )
    
    const sendBack = () => {

        if ((Object.keys(formik.errors).length == 0) ) {
            if(isError == true){
                console.log(formik.errors)
                console.log('error')
                //props.setErrors()
            }
            console.log('error checking')
            if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                //props.handleError()    
            }
            else if(formik.initialValues == formik.values){
                console.log(formik.errors)
                console.log('error')
                //props.setErrors()
            }
            else{
                setIsError(false)
                console.log(formik.errors)
                console.log('no error first condition')
                //props.handleError()    
            }
              
        }
        else{
            
            console.log(formik.errors)
            console.log('error')
            //props.setErrors()
        }
      }
    const saveData = ()=>{
        if(formik.values.data == ''){

        }
        else{
            props.onSave(formik.values)
            toggle()
        }
       
    }
    return (
        <Modal isOpen={isModalOpen} toggle={toggle} className='mt-10-rem property_info_modal mt-5 '>
            <ModalHeader className='p-4' toggle={toggle}>
                <div className='font-16 font-weight-600 color-707895'>{props.title}</div>
            </ModalHeader>
            <ModalBody>
                <Row className='py-3'>
                    <FormFields.InputField
                        md={10}
                        name='data'
                        label={props.inputLabel}
                        val ={formik.values.data}
                        onChange = {formik.handleChange}
                        onBlur = {formik.handleBlur}
                        errors= {formik.touched.data?formik.errors.data:''}
                    />
                </Row>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-start'>
                <CancelButton className='btn btn-sm' value='Back' clickEvent={toggle}/>
                <BlueButton className='btn btn-sm' value={props.buttontext} clickEvent={saveData} disabled = {isError}/>
            </ModalFooter>
        </Modal>
    )
}
