import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form, FloatingLabel } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import NumberFormat from 'react-number-format';
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import * as CONFIG from '../../../helpers/config'
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import DownloadCustomerPayment from './Payment Components/DownloadCustomerPayment';
import { useMsal } from "@azure/msal-react";

function CustomerPayment(props) {
    const { instance } = useMsal();
    const [corDocumentName, setCorDocumentName] = useState('')
    const [quantityInspectionAgencyDocumentName, setQuantityInspectionAgencyDocumentName] = useState('')
    const [qualityInspectionAgencyDocumentName, setQualityInspectionAgencyDocumentName] = useState('')
    const [billOfLadingDate, setBillOfLadingDate] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [error, setError] = useState(false)
    const [paymentInvoicesList, setPaymentInvoicesList] = useState([])
    const [paymentList, setPaymentList] = useState([])
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [country, setCountry] = useState()
    const [totalList, setTotalList] = useState()
    const [paymentCustomer, setPaymentCustomer] = useState(false)
    const [particularPayment, setParticularPayment] = useState()
    const [count, setCount] = useState(0)
    const [customerData, setCustomerData] = useState([])
    const [custcomobject, setcustcomobject] = useState([])
    const [tradescenarioID, settradescenarioID] = useState(0)
    const [selectedcustomerid, setselectedcustomerid] = useState(0)
    const [selectedcompanyid, setselectedcompanyid] = useState(0)
    const [doc, setdoc] = useState(false)
    const [compcusttoview, setcompcusttoview] = useState([])
 

    //const location = useLocation()
    let navigate = useNavigate();
    console.log(props)
    const tradeData = props.tradeData
    //console.log(location)
    const tab = props.tab

    useEffect(() => {

        
        let json_lists = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.tradeID) // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_lists, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setcustcomobject(res.data.result)
                setCustomerData(res.data.result)
                settradescenarioID(res.data.result[0].tradeScenarioID)
                if (res.data.result[0].tradeScenarioID == 1) {
                    setselectedcompanyid(res.data.result[0].companyID)
                    setselectedcustomerid(res.data.result[0].customerID)
                    setPaymentList(res.data.result[0].tradeCustomerPaymentDetail)
                }
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        let listValues = []
        let listValuesTax = []
        if (tradeData != null) {
            tradeData.tradedetails.tradeProductDetail.map((list, k) => (
                listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
            ))
            console.log(listValues, 'List values')
            let sum = 0
            let sumTax = parseInt((tradeData.tradedetails.tradeEnquiry.tax1)) + parseInt((tradeData.tradedetails.tradeEnquiry.tax2))
            for (let i = 0; i < listValues.length; i++) {
                sum += listValues[i]
            }

            console.log(sum, 'Sum')
            setSubTotal(sum)
            setTotalTax(sumTax)
            setOverAllTotal(sum + sumTax)
        }
    }, [])

    useEffect(() => {

        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COUNTRY_LIST, {
            "pmsinstanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": 0
        }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                for (let i = 0; i < res.data.result.length; i++) {

                    if (res.data.result[i].countryId == tradeData.tradedetails.customer.registeredCountryID) {
                        console.log('success')
                        setCountry(res.data.result[i].countryName)
                    }
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    useEffect(() => {
        getCustomerPaymentUpdate()
    }, [])

    const getCustomerPaymentUpdate = () => {
        let totalListSum = []
        let sum = 0
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID,
        }
        console.log(json_list)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PAYMENT_UPDATE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.length > 0 && res.data.result.length == 1) {
                    setIsAdd(false)
                    for (let i = 0; i < res.data.result.length; i++) {
                        totalListSum.push(res.data.result[i].customerPaymentAmount)
                        res.data.result[i].customerPaymentAmount = parseInt(parseInt((tradeData.tradedetails.tradeProductDetail[0].productSellingPrice) * (tradeData.tradedetails.tradeProductDetail[0].quantity)))
                        res.data.result[i].customerPaymentPer = parseInt(100)

                    }
                    for (let i = 0; i < totalListSum.length; i++) {
                        sum += totalListSum[i];
                    }
                    setTotalList(sum)

                    setPaymentInvoicesList(res.data.result)
                }
                else if (res.data.result.length > 0 && res.data.result.length > 1) {
                    setIsAdd(false)
                    for (let i = 0; i < res.data.result.length; i++) {
                        totalListSum.push(res.data.result[i].customerPaymentAmount)
                    }
                    for (let i = 0; i < totalListSum.length; i++) {
                        sum += totalListSum[i];
                    }
                    setTotalList(sum)

                    setPaymentInvoicesList(res.data.result)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData.tradeID,
            customerPaymentTypeID: isAdd == true ? '' : '',
            customerPaymentAmount: isAdd == true ? '' : '',
            customerPaymentPer: isAdd == true ? '' : '',
            typeName: isAdd == true ? '' : ''
        },
    })

    useEffect(() => {
        // console.log(paymentInvoicesList)
        // console.log(props.tradeData.tradedetails.tradeCustomerPaymentDetail)
        // let list = []
        // if (paymentInvoicesList != null && paymentInvoicesList.length > 0) {
        //     for (let i = 0; i < props.tradeData.tradedetails.tradeCustomerPaymentDetail.length; i++) {
        //         for (let x = 0; x < paymentInvoicesList.length; x++) {
        //             if (paymentInvoicesList[x].customerPaymentTypeID != props.tradeData.tradedetails.tradeCustomerPaymentDetail[i].customerPaymentTypeID) {
        //                 console.log(paymentInvoicesList[x], 'x')
        //             }
        //             else {
        //                 list.push(i)
        //             }
        //         }
        //     }
        // }
        // console.log(list)
    }, [isAdd])


    const handleAddPayment = () => {
        formik.values.customerPaymentAmount = ''
        formik.values.customerPaymentPer = ''
        setIsAdd(true)
        // axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COUNTRY_LIST, {
        //     "instanceID": CONFIG.PMSInstanceID,
        //     "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //     "created_User": CONFIG.LoginID,
        //     "id": tradeData.tradeID,
        //     "paymentTypeID" : formik.values.customerPaymentTypeID

        // }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY }  })
        // .then((res) => {
        //     console.log(res.data.result)
        // })
    }

    const handleCustomerPaymentSave = () => {
        console.log(formik.values.paymentTypeID)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "customerID" : selectedcustomerid,
            "companyID" : selectedcompanyid,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID,
            "paymentTypeID": parseInt(formik.values.paymentTypeID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getCustomerPaymentUpdate()
                setIsAdd(!isAdd)
                props.refreshList()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }
    const back = () => {
        setPaymentCustomer(false)
    }

    const handlePrintCustomerPayment = (e, data) => {
        // setParticularPayment(data)
        // setPaymentCustomer(true)
        let custcompid = data.tradeCompanyCustomerDetailsID
        let filter = custcomobject.filter(x=> x.tradeCompanyCustomerDetailsID == custcompid)[0]
        setcompcusttoview(filter)
        setParticularPayment(data)
        setPaymentCustomer(true)
    }

    const handlePaymentMethod = (e) => {
        console.log(e.target.value)
        let id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = paymentList.filter(x=> x.paymentType.paymentTypeID == id)[0]
        console.log(filter)
        formik.values.paymentTypeID = id
        formik.values.customerPaymentAmount = filter.customerPaymentAmount
        formik.values.customerPaymentPer = filter.customerPaymentPer
        setdoc(!doc)
    }

    useEffect(() => {

    }, [formik.values, count])

    const handleCompanySelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.companyID == id)[0]
        let payment = filter.tradeCustomerPaymentDetail
        let count = 0
        console.log(payment)
        setPaymentList(payment)
        setselectedcustomerid(filter.customer.customerID)
        setselectedcompanyid(id)
    }

    const handleCustomerSelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.customerID == id)[0]
        let payment = filter.tradeCustomerPaymentDetail
        console.log(payment)
        let count = 0
        setPaymentList(payment)
        setselectedcompanyid(filter.company.companyID)
        setselectedcustomerid(id)
    }

    useEffect(() => {
        console.log(paymentList)
    }, [paymentList])

    return (
        <div className='main-wrapper inner-wrapper d-flex mt-5'>
            <div className='width-100'>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/Trading-Sales-Purchase.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Customer Payments</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        {paymentCustomer == true ?
                            <DownloadCustomerPayment tradeData={props.tradeData} custcompdata = {compcusttoview} paymentData={particularPayment} back={back} country={country} />
                            :
                            <>
                                <Card className='bg-white'>
                                    <Card.Body>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Invoice Details
                                        </div>
                                        <div className="m-3 p-4 grey-color">
                                            {tradeData != null ?
                                                <>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        {customerData.map((data, k) => {
                                                            return (
                                                                <>
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                                        <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                                    </div>
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='font-weight-400 color-697382'>Address</div>
                                                                        <div className='font-weight-600 color-243448'>
                                                                            {data.customer.registeredUnitNo}, &nbsp;
                                                                            {data.customer.registeredBuildingName}, &nbsp;
                                                                            {data.customer.registeredStreetName}, &nbsp;
                                                                            {data.customer.registeredPostBox}, &nbsp;
                                                                            {data.customer.registeredCityName}, &nbsp;
                                                                            {country}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-400 color-697382'>Invoice Date</div>
                                                            <div className='font-weight-600 color-243448'>{Moment().format('DD/MM/YYYY')}</div>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-400 color-697382'>Invoice Amount</div>
                                                            <div className='font-weight-600 color-243448'>$<NumberFormat value={overAllTotal} displayType={'text'} thousandSeparator={true} /></div>
                                                        </div>
                                                    </div>
                                                    <hr />


                                                </>
                                                :
                                                ""
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='my-3'>
                                    <Card.Body>
                                        <Row className='d-flex' style={{ background: '#fff' }}>
                                            {!isAdd ?
                                                <>
                                                    <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            Customer Payment
                                                        </div>
                                                        <div>
                                                            <ButtonComponent.AddNewButton value='Add New Payment' clickEvent={handleAddPayment} />
                                                        </div>

                                                    </div>

                                                    <div className='list-scroll-user'>
                                                        <table className="table table-text mt-1-rem list-scroll">
                                                            <thead>
                                                                <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                                    <th scope='col' className=' font-weight-500'>Reciept No.</th>
                                                                    <th scope='col' className=' font-weight-500'>Mode Of Payment</th>
                                                                    <th scope='col' className=' font-weight-500'>Percentage %</th>
                                                                    <th scope='col' className=' font-weight-500'></th>
                                                                    <th scope='col' className=' font-weight-500 align-right'>Amount</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentInvoicesList != null && paymentInvoicesList.length > 0 ?
                                                                    paymentInvoicesList.map((values) => (
                                                                        <tr className='align-middle border-bottom-0'>
                                                                            <td>{values.recieptNo}</td>
                                                                            <td>{values.paymentType.typeName}</td>
                                                                            <td>{values.customerPaymentPer}</td>
                                                                            <td></td>
                                                                            <td className='align-right'>$<NumberFormat value={values.customerPaymentAmount != null ? values.customerPaymentAmount : 0} displayType={'text'} thousandSeparator={true} /></td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600" id={values.tradeCompanyCustomerDetailsID} onClick={(e) => { handlePrintCustomerPayment(e, values) }}>Print</button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    :
                                                                    ""
                                                                }
                                                                <tr className='border-0'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="table-secondary border border-secondary">TOTAL: </td>
                                                                    <td className="table-secondary border border-secondary">$<NumberFormat value={overAllTotal} displayType={'text'} thousandSeparator={true} /></td>
                                                                    <td className="table-secondary border border-secondary"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                                        Customer Payment
                                                    </div>
                                                    <Row>
                                                        {tradescenarioID == 2 &&
                                                            <Col md={4}>
                                                                <FloatingLabel controlId="floatingSelect" label='Select Company'>
                                                                    <Form.Select
                                                                        className="form_input form_input_color_change mt-1-rem"
                                                                        placeholder='Select Company'
                                                                        onChange={handleCompanySelect}
                                                                        onBlur={handleCompanySelect}
                                                                        name='companyselect'>
                                                                        <option value={0}>Select</option>
                                                                        {custcomobject.map((list, k) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={list.companyID}>{list.company.companyName}</option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        }
                                                        {tradescenarioID == 3 &&
                                                            <Col md={4}>
                                                                <FloatingLabel controlId="floatingSelect" label='Select Customer'>
                                                                    <Form.Select
                                                                        className="form_input form_input_color_change mt-1-rem"
                                                                        placeholder='Select Customer'
                                                                        onChange={handleCustomerSelect}
                                                                        onBlur={handleCustomerSelect}
                                                                        name='companyselect'>
                                                                        <option value={0}>Select</option>
                                                                        {custcomobject.map((list, k) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={list.customerID}>{list.customer.customerName}</option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        }
                                                        <Col md={4}>
                                                            <FloatingLabel controlId="floatingSelect" label='Mode of Payment*'>
                                                                <Form.Select
                                                                    className="form_input form_input_color_change mt-1-rem"
                                                                    placeholder='Mode of Payment*'
                                                                    onChange={handlePaymentMethod}
                                                                    onBlur={handlePaymentMethod}
                                                                    name='customerPaymentTypeID'>
                                                                    <option value={0}>Select</option>
                                                                    {paymentList.map((invoice, k) => {
                                                                        return (
                                                                            <option value={invoice.paymentType.paymentTypeID}>{invoice.paymentType.typeName}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='customerPaymentPer'
                                                            label='Percentage %*'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.customerPaymentPer}
                                                            errors={formik.touched.customerPaymentPer ? formik.errors.customerPaymentPer : ''}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            disabled={true}
                                                        />
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='customerPaymentAmount'
                                                            label='Amount*'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            val={formik.values.customerPaymentAmount}
                                                            errors={formik.touched.customerPaymentAmount ? formik.errors.customerPaymentAmount : ''}

                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            disabled={true}
                                                        />
                                                        <Col className='d-flex align-items-center pt-1'>
                                                            <ButtonComponent.BlueBorderButton value={'Save'} clickEvent={handleCustomerPaymentSave} />
                                                        </Col>
                                                    </Row>
                                                </>

                                            }
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerPayment