import React, { useState, useEffect } from 'react'
import { Row } from "react-bootstrap"
import { useFormik } from 'formik';
import * as FormFields from "../../../../../../components/formcomponents";
import { BlueBorderButton, BlueButton, AddNewButton } from '../../../../../../components/buttoncomponents';
import { AddQuality } from '../../../../../../helpers/API/ProductApi';
export default function Quality_form(props) {
    
    console.log(props)
    const [error, setError] = useState(true)
    const [isError, setIsError] = useState(true);
    
    useEffect(()=>{
        if(props.isEdit == true){
            setIsError(false)
        }
    },[])
    const formik = useFormik({
        initialValues:{
            key : props.isEdit?props.data.key:0,
            // tradeEnquiryProductTraceElementID: props.isEdit && props.data.tradeProductTraceElementID,
            tradeEnquiryProductDetailID: props.isEdit && props.data.tradeProductDetailID,
            productQualityParameterID: props.isEdit?props.data.productQualityParameterID:0,
            productQualityParameterName: props.isEdit?props.data.productTraceElement != null ? props.data.productTraceElement.productQualityParameterName: props.data.productQualityParameterName: '',
            typicalValueRange: props.isEdit?props.data.typicalValueRange:'',
            rejectionValueRange: props.isEdit?props.data.rejectionValueRange:''
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;

          
            if (!values.typicalValueRange) {
                errors.typicalValueRange = "Please enter typical range"
                
            }
            

            if (!values.rejectionValueRange) {
                errors.rejectionValueRange = "Please enter rejection range"
                
            }
            
           
            setIsError(true)
            sendBack();
            return errors;
        }
        
    });
   
    const sendBack = () => {
        console.log('asa')
        if ((Object.keys(formik.errors).length == 0) ) {
            console.log(isError)
            if(isError == true){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                
            }
            console.log('error checking')
           
            if((formik.initialValues == formik.values) && (isError == false)){
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                //props.handleError()    
            }
            else if(props.isEdit == true){
                console.log('asassss')
                setIsError(false)
            }
            else if(formik.initialValues == formik.values){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                
            }
            else{
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                //props.handleError()    
            }
              
        }
        else{
            
            console.log(formik.errors)
            console.log('error')
             setIsError(true)
            //props.setErrors()
        }
    }
    const handleModalDataSubmission = ()=>{
        setError(false)
        props.handleModalDataSubmission(formik.values)
    }
    const handleModalDataUpdation = ()=>{
        setError(false)
        props.handleModalDataUpdation(formik.values)
    }
    const handleQuality = (e)=>{
        formik.values.productQualityParameterID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.productQualityParameterName = e.nativeEvent.target[index].text;
    }

    return (
        <div className='row mx-md-2 mx-2'>
                                    <FormFields.InputSelect
                                        name='productQualityParameterID'
                                        label='Quality Parameter'
                                        classname='col-md-3 col-12'
                                        type='text'
                                        optionField={<AddQuality id={parseInt(formik.values.productQualityParameterID)}/>}
                                        val={formik.values.productQualityParameterID}
                                        onChange={handleQuality}
                                        onBlur ={handleQuality}
                                        errors= {formik.touched.productQualityParameterID?formik.errors.productQualityParameterID:''}
                                    />
                                    <FormFields.InputField
                                        name='unit'
                                        label='Unit*'
                                        classname='col-md-3 col-12'
                                        type='text'
                                        val='%'
                                        disabled={true}
                                    />
                                    <FormFields.InputField
                                        name='typicalValueRange'
                                        label='Typical*'
                                        classname='col-md-3 col-12'
                                        type='text'
                                        val={formik.values.typicalValueRange}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errors= {formik.touched.typicalValueRange?formik.errors.typicalValueRange:''}
                                    />
                                    <FormFields.InputField
                                        name='rejectionValueRange'
                                        label='Rejection*'
                                        classname='col-md-3 col-12'
                                        type='text'
                                        val={formik.values.rejectionValueRange}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errors= {formik.touched.rejectionValueRange?formik.errors.rejectionValueRange:''}
                                    />
                                    <div className='col-md-3 col-12 mt-3'><BlueBorderButton value = 'Save' className='w-100' clickEvent = {props.isEdit?handleModalDataUpdation:handleModalDataSubmission} disabled = {isError}/></div>
                                </div>
    )
}
