import React from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";

function FormComponent(props) {
    console.log(props.data)
    const addDirector = ()=>{
        const director_list ={
            "make": formik.values.make,
            "noOfMachines": parseInt(formik.values.noOfMachines),
            "capacityPerKGHr": formik.values.capacityPerKGHr,
            "capacityMTPerMonth": formik.values.capacityMTPerMonth,
            "actualProductionMTPerMonth": formik.values.actualProductionMTPerMonth,
            "is_Default" : false,
        }
        console.log(director_list)
        props.addDirectorToList(director_list)
    }

    const formik = useFormik({
        initialValues:
        !props.isEdit?
        {
            make: '',
            noOfMachines: '',
            capacityPerKGHr: '',
            capacityMTPerMonth: '',
            actualProductionMTPerMonth: '',
        }
        :
        {
            make: props.data.make,
            noOfMachines: props.data.noOfMachines,
            capacityPerKGHr: props.data.capacityPerKGHr,
            capacityMTPerMonth: props.data.capacityMTPerMonth,
            actualProductionMTPerMonth: props.data.actualProductionMTPerMonth,
        }
    })

    const editDirector = ()=>{
        const director_list ={
            "make": formik.values.make,
            "noOfMachines": parseInt(formik.values.noOfMachines),
            "capacityPerKGHr": formik.values.capacityPerKGHr,
            "capacityMTPerMonth": formik.values.capacityMTPerMonth,
            "actualProductionMTPerMonth": formik.values.actualProductionMTPerMonth,
            "is_Default" : props.data.is_Default,
            "key" :props.data.key
        }
        console.log(director_list)
        props.editDirectorToList(director_list)
    }
  return (
    <Row>
        <FormFields.InputField
            md={3}
            name='make'
            label='Make'
            classname='pt-1-rem'
            type='text'
            val={formik.values.make}
            onChange={formik.handleChange}
        />
        <FormFields.InputField
            md={3}
            name='noOfMachines'
            label='No. of Machines'
            classname='pt-1-rem'
            type='text'
            val={formik.values.noOfMachines}
            onChange={formik.handleChange}
        />
        <FormFields.InputField
            md={3}
            name='capacityPerKGHr'
            label='Capacity - KG Hour'
            classname='pt-1-rem'
            type='text'
            val={formik.values.capacityPerKGHr}
            onChange={formik.handleChange}
        />
        <FormFields.InputField
            md={3}
            name='capacityMTPerMonth'
            label='Capacity - MT/Month'
            classname='pt-1-rem'
            type='text'
            val={formik.values.capacityMTPerMonth}
            onChange={formik.handleChange}
        />
        <FormFields.InputField
            md={3}
            name='actualProductionMTPerMonth'
            label='Actual Production - MT/Month'
            classname='pt-1-rem'
            type='text'
            val={formik.values.actualProductionMTPerMonth}
            onChange={formik.handleChange}
        />
        <Col className='d-flex align-items-end'>
            <BlueButton value={props.isEdit?'Edit Manufacturing Information':'Add Manufacturing Information'} clickEvent={props.isEdit?editDirector:addDirector}/>
        </Col>
    </Row>
  )
}

export default FormComponent