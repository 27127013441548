import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './Trends.module.css'
import * as CONFIG from "../../../../../helpers/config"
import NumberFormat from 'react-number-format';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function Index(props) {
  const [keyApi, setKeyApi] = useState([])
  const [key, setKey] = useState([])
  const [labelSales, setLabelSales] = useState([])
  const [labelSalesApi, setLabelSalesApi] = useState([])
  const [labelPurchase, setLabelPurchase] = useState([])
  const [divDetails, setDivDetails] = useState() 
  const [productDivDetails, setDivProductDetails] = useState() 

  useEffect(()=>{
    let final_json = {
      "instanceID": CONFIG.PMSInstanceID,
      "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      "fromDate": props.fromDate,
      "toDate": props.toDate,
      "termsID": props.terms,
      "dashboardTypeID": 4,
      "userID": parseInt(CONFIG.LoginID),
      "companyID" : parseInt(props.companyID)
  }
  axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_DIV_TOTAL, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
  .then((res) => {
      console.log(res.data.result)
      setDivDetails(res.data.result)
  })
  },[props])

  
  useEffect(()=>{
    let final_json = {
      "instanceID": CONFIG.PMSInstanceID,
      "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      "fromDate": props.fromDate,
      "toDate": props.toDate,
      "termsID": props.terms,
      "dashboardTypeID": 4,
      "userID": parseInt(CONFIG.LoginID),
      "companyID" : parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_PRODUCT_TREND, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        let keyList = []
        let labelSales = []
        let labelPurchase = []
        res.data.result.map((values, k)=>(
          keyList.push(values),
          labelSales.push(values)
        ))
        setKeyApi(keyList)
        setLabelSalesApi(labelSales)
        //setDivProductDetails(res.data.result)
    })
  },[props])


  const handlePercentageSalesAmount = () => {

    let difference = 0
    let product = 0
    console.log(props)
    if(props.closedTradeDetails != 0 && props.closedTradeDetails != null){
      if(difference == 0 && props.closedTradeDetails.tradeRevenuePrevious == 0){
            console.log('cannot calc')
            product = 0
      }
      else{
        difference =  parseInt(props.closedTradeDetails.totalSalesValue) - parseInt(props.closedTradeDetails.tradeRevenuePrevious) 
        console.log(difference, 'calculated')
        product =  parseInt(difference) / parseInt(props.closedTradeDetails.tradeRevenuePrevious)
      }
    }
    
    else{
      product = 0
    }

    console.log(difference)
    console.log(product, 'product before percentage')
    console.log(parseFloat(product * 100).toFixed(2))
    product = parseFloat(product * 100).toFixed(2)
    console.log(product)
    return(
      <>
        <div>
          {product}%
        </div>
        {(parseInt(props.closedTradeDetails.tradeRevenuePrevious) < (parseInt(props.closedTradeDetails.totalSalesValue))) == true ? 
         <div className = {` ms-2 ${styles.profitIcon}`}>
          <img src = '/assets/images/supports/dashboard-trends-profit-icon-1.svg' alt = 'icon' />
         </div>
         :
         <div className = {` ms-2 ${styles.lossIcon}`}>
          <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' />
         </div>
      }
        
      </>
    )
    //console.log(division)
  }

  const handlePercentageRevenueAmount = (values) => {
    console.log(values)
    console.log(labelSalesApi)
    let labelsList = []
    let key = []

    for(let i = 0; i < labelSalesApi.length; i++){
      if(values.productID == labelSalesApi[i].productID){
        labelsList.push(labelSalesApi[i].purchaseValue)
        key.push(labelSalesApi[i].tradeClosedDate)
      }
    }

    console.log(labelsList)
    console.log(key)
    const labels = key;
  
    const dataLoss  = {
      labels,
      datasets: [
        {
          fill: false,
          label: 'Dataset 2',
          data: labelsList,
          borderColor: 'red',
          lineTension: 0
        },
      ],
    };
  
    const dataProfit = {
      labels,
      datasets: [
        {
          fill: false,
          label: 'Dataset 2',
          data: labelsList,
          borderColor: 'green',
          lineTension: 0
        },
      ],
    };

    let difference = 0
    let product = 0
    console.log(props)
    if(difference == 0 && values.prevSellingAmount == 0){
          console.log('cannot calc')
          product = 0
    }
    else{
      difference =  parseInt(values.sellingAmount) - parseInt(values.prevSellingAmount) 
      console.log(difference, 'calculated')
      product =  parseInt(difference) / parseInt(values.prevSellingAmount)
    }
    console.log(difference)
    console.log(product, 'product before percentage')
    console.log(parseFloat(product * 100).toFixed(2))
    product = parseFloat(product * 100).toFixed(2)
    console.log(product)
    return(
      <>
       
        {(parseInt(values.prevSellingAmount) < (parseInt(values.sellingAmount))) == true ||
        (parseInt(values.prevSellingAmount) == (parseInt(values.sellingAmount))) == true
        ? 
          <>
            <div style={{height:'67px', width: '100px', marginBottom: '20px'}}>
              <Line  id='canvas' options={options} data={dataProfit} />
            </div>
            <div className = {`ms-3 ${styles.trendsChartPercentage}`}>
              {product}%
            </div>
            <div className = 'ms-2 mb-1'>
              <img src = '/assets/images/supports/trends-chart-profit-icon.svg' icon = 'loss-icon' />
            </div>
          </>
         :
         <>
         <div style={{height:'67px', width: '100px', marginBottom: '20px'}}>
           <Line  id='canvas' options={options} data={dataLoss} />
         </div>
         <div className = {`ms-3 ${styles.trendsChartPercentage}`}>
           {product}%
         </div>
         <div className = 'ms-2 mb-1'>
           <img src = '/assets/images/supports/trends-chart-loss-icon.svg' icon = 'loss-icon' />
         </div>
       </>
      }
        
      </>
    )
    //console.log(division)
  }
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        }
      },
      x:{
        grid: {
          display: false
        },
        ticks: {
          display: false
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: true,
      },
    },
  };

  return (
    <>
        <div className = {`${styles.trendContainer}`}>
            <div className = {`${styles.trendHeader}`}>
              <div className = 'd-flex align-items-center justify-content-between '>
                  <div className = 'd-flex flex-column'>
                    <div>
                      Sales
                    </div>
                    <div className = {`mt-2 ${styles.trendsHeading}`}>
                      $<NumberFormat value={props.closedTradeDetails.totalSalesValue != null ? props.closedTradeDetails.totalSalesValue : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                    <div className = {`mt-2 ${styles.subtext}`}>
                      total sales amount
                    </div>
                  </div>
                  <div className = 'd-flex align-items-center'>
                    {handlePercentageSalesAmount()}
                  </div>
              </div>
              <div className = 'd-flex justify-content-between align-items-center mt-2'>
                <div className = 'd-flex flex-column'>
                      <div className = {`mt-2 ${styles.subtext}`}>
                        total no. of sales
                      </div>
                      <div className = {`mt-2 ${styles.trendsNewTradeAmount}`}>
                        <NumberFormat value={props.closedTradeDetails.closedTradeCount != null ? props.closedTradeDetails.closedTradeCount : 0 } displayType={'text'} thousandSeparator={true}  />
                      </div>
                  </div>
                  <div className = 'd-flex align-items-center'>
                      <div className = 'd-flex flex-column'>
                        <div className = {`mt-2 ${styles.subtext}`}>
                          total materials
                        </div>
                        <div className = {`mt-2 ${styles.trendsNewTradeAmount}`}>
                          <NumberFormat value={props.closedTradeDetails.totalQuantitySalesMaterial != null ? props.closedTradeDetails.totalQuantitySalesMaterial : 0} displayType={'text'} thousandSeparator={true}  /> MT
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className={`${styles.trendBody}`} style={{overflowY:'scroll',height:'235px'}}>
              {divDetails != null ? 
                divDetails.map((values, k)=>(
                  values.productName != null ?
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <div>
                      <div className = {`${styles.trendsChartTitle}`}  >
                        {values.productName}
                      </div>
                      <div className = {`${styles.trendsChartAmount}`} >
                        $<NumberFormat value={values.sellingAmount} displayType={'text'} thousandSeparator={true}  />
                      </div>
                    </div>
                    <div className = 'd-flex align-items-center'>
                      {handlePercentageRevenueAmount(values)}
                    </div>
                  </div>   
                  :
                  ''  
                ))
              : 
              ''
              }
             
            </div>
        </div>
    </>
  )
}

export default Index