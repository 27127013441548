import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/sidebar/sidebar";
import Navbar from "../../../../components/navbar";
import { Card } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import * as CONFIG from "../../../../helpers/config";
import axios from "axios";

export default function List() {
    const { instance } = useMsal();
    const [list, setList] = useState([])

    useEffect(()=>{
        const json_list = {
            instanceID: CONFIG.PMSInstanceID,
            accountSubscriptionID: CONFIG.AccountSubscriptionID,
            created_User: CONFIG.LoginID,
          };
      
          axios
            .post(
              CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_DEBIT_CREDIT,
              json_list,
              {
                headers: {
                  Authorization: `Bearer ${CONFIG.AuthToken}`,
                  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
                },
              }
            )
            .then((res) => {
              console.log(res.data.result);
              setList(res.data.result);
            })
            .catch((error) => {
              if (error.response.status == 401) {
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
              }
            });
    })
  return (
    <div className="main-wrapper inner-wrapper d-flex">
      <Sidebar id="7.10" isOpenid={7} />
      <div className="width-100">
        <Navbar />
        <div className="d-flex inner-page-header justify-content-between mx-2">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/sidebaricons/financial.svg"
              className="inner-page-titleicon img-fluid"
            />
            <span className="font-16 font-weight-600 color-707895 d-flex align-items-center pl-10">
              SUPPLIER DEBIT OR CREDIT
            </span>
          </div>
        </div>

        <div className="">
          <div className="inner-page-content  br-8 py-3">
            
          <Card>
              <Card.Body>
                <div class="col-lg-12 list-scroll">
                  <div class="table-responsive-sm ">
                    <table className="table tCustom table-text">
                      <thead className="p-1-rem">
                        <tr className="text-uppercase list-top">
                          <th>No#</th>
                          <th>Type</th>
                          <th>Supplier#</th>
                          <th>Reference No#</th>
                          <th>Amount</th>
                          <th>Document</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((li,k)=>{
                            return(
                                <tr>
                                    <td>{li.debitcreditdata.drCrNo}</td>
                                    <td>{li.debitcreditdata.type == 1? "Debit":'Credit'}</td>
                                    <td>{li.suppliername}</td>
                                    <td>{li.debitcreditdata.referenceNo}</td>
                                    <td>USD {li.debitcreditdata.amount}</td>
                                    <td><a download={li.debitcreditdata.type == 1?'Debit Document':'Credit Document'} href={li.debitcreditdata.documentUpload}>{li.debitcreditdata.type == 1?'Debit Document':'Credit Document'}</a></td>
                                </tr>
                            )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
