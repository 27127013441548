import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form } from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { useFormik } from 'formik'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import * as CONFIG from '../../../../helpers/config'
import { PaymentTypeList } from '../../../../helpers/API/Api';
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';




function RatioModal(props) {
    console.log(props)
    const [modal, setModal] = useState(true);
    const [checkError, setCheckError] = useState(false) 
    const [checkErrorPer, setCheckErrorPer] = useState(false) 
    const [paymentMethod, setPaymentMethod] = useState()
    const { instance } = useMsal();
    const [paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [overAllTotal, setOverAllTotal] = useState()
    const [trackCheck, setTrackCheck] = useState([])
    const [isPaymentset, setispaymentset] = useState(false)
    const [TradeCompanyCustomerDetails, setTradeCompanyCustomerDetails] = useState()


    useState(()=>{
       
    },[])

    const toggle = () => {
        setModal(!modal)
        props.close();
      }

    useEffect(()=>{
        let object = {}
        let list = []
        let data = []
        if(props.updatedCompany != null){
            props.updatedCompany.map((values,k)=>{
                object = {
                    "customerID" : parseInt(props.currentTrade.customerID),
                    "incotermsID" : parseInt(props.currentTrade.incotermsID),
                    "companyID" : parseInt(values.company.companyID),
                    "tradeScenarioID" : 2,
                    "ratio" : values.company.ratio != null ? values.company.ratio : 0,
                    "ratioAmount" : 0,
                    "Is_DefaultCustomer" : k==0?true:false,
                    "Is_DefaultCompany" : k==0?true:false,
                }
                if(values.company.ratio != null){
                    data.push(values.company.ratio)
                }
                list.push(object)
            })
            console.log(data)
            handleErrors(data)
            setTradeCompanyCustomerDetails(list)
        }

    },[])
    const formik = useFormik({
        initialValues:
        {
            supplierPaymentTypeID: 4,
        },
    })


    const handleSaveEvent = () => {
        props.handleRatioData(TradeCompanyCustomerDetails)
        toggle()
    }

    const handleErrors = (data) => {    
        console.log(data)
        let totalPer = 0
        if(data != null && data.length > 0){
            data.map((amt,k)=>{
                console.log(amt,k)
                totalPer += amt
            })
        }

        console.log(totalPer)
     
        if(totalPer != 100){
            console.log('condition 1')
            setCheckError(false)
            setCheckErrorPer(true) 
        }
        else{
            console.log('condition 3')
            setCheckError(false)
            setCheckErrorPer(false) 
        }
    } 

    useEffect(()=>{

    },[paymentMethod])
 
    const handleCheck = (event) => {
        let trackChec = [...final_json]
        if(event.target.checked){
            for(let i = 0; i < trackChec.length; i++){
                if(trackChec[i].supplierPaymentTypeID == event.target.value){
                    trackChec[i].isCheck = true
                    if(props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null){
                        console.log('check condition')
                        trackChec[i].is_Active = true
                        trackChec[i].is_Deleted = false
                    }
                }
            }
        }
        else{
            for(let i = 0; i < trackChec.length; i++){
                if(trackChec[i].supplierPaymentTypeID == event.target.value){
                    trackChec[i].isCheck = false
                    trackChec[i].supplierPaymentAmount = 0
                    trackChec[i].supplierPaymentPer = 0
                    if(props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null){
                        console.log('check condition')
                        trackChec[i].is_Active = false
                        trackChec[i].is_Deleted = true
                    }
                }
                
            }
        }
        setFinal(trackChec)
        console.log(trackCheck)
    };

    const handlePercentageValues = (e) => {
        let list = TradeCompanyCustomerDetails
        let errorList = []
        list.map((values,k)=>{
            if(values.companyID == parseInt(e.target.id)){
                values.ratio = parseInt(e.target.value)
            }
            
        })
        list.map((values, k)=>(
            errorList.push(values.ratio)
        ))
        console.log(errorList)
        handleErrors(errorList)
        console.log(list)
        setTradeCompanyCustomerDetails(list)
 
    }
    useEffect(()=>{
        console.log('check')
        console.log(props)
        if(props.currentSupplyPlan != null && props.currentSupplyPlan.productBuyingPrice){
            console.log('check')
            var sum = props.currentSupplyPlan.productBuyingPrice * props.currentSupplyPlan.quantity
            setOverAllTotal(parseFloat(sum).toFixed(2))
        }
    },[])

    // useEffect(()=>{
    //     let list = []
    //     console.log(TradeCompanyCustomerDetails, 'handleError')
    //     if(TradeCompanyCustomerDetails != null){
    //         TradeCompanyCustomerDetails.map((values, k)=>(
    //             list.push(values.ratio)
    //         ))
    //         handleErrors(list)
    //     }

    // },[TradeCompanyCustomerDetails])


    const handleDefaultChecked = (list) => {
     
    }

    return (
            <Modal isOpen={modal} toggle={toggle} className = 'payment-method-modal'>
                <ModalHeader toggle={toggle} className='p-40'>
                    <div className = 'd-flex justify-content-between '>
                        <div className='font-16 font-weight-600 color-707895'>
                            SPLIT RATIO
                        </div>
                    </div>
                </ModalHeader>
            <ModalBody  className = 'service-request-modal-wrapper p-40'>
            <>
            <div className =''>
                
                                {props.updatedCompany.map((list,k) => (
                                        <>
                                            <div className = {'bg-lighter d-flex justify-content-between  my-2 p-4 align-items-center'}>
                                                    <>
                                                    <div className = 'multicompany-split-ratio-modal-text'>
                                                        {list.company.companyName}
                                                    </div>
                                                    <div className = 'd-flex align-items-center'>
                                                        <Form.Control
                                                            type="text"
                                                            className = 'payment-terms-width'
                                                            name={list.company.companyID}
                                                            id = {list.company.companyID}
                                                            onChange = {handlePercentageValues}
                                                            defaultValue = {list.company.ratio}
                                                        />
                                                        <div className = 'ms-2'>
                                                            (%)
                                                        </div>
                                                    </div>
                                                    {/* <div className = 'font-weight-600'>{final_json[k].supplierPaymentAmount}</div> */}
                                                </>         
                                            </div>
                                            </>
                                    ))}
               
                <div className = 'd-flex justify-content-end me-3'>
                    <p>Total Volume: <b><NumberFormat value={parseInt(props.currentSupplyPlan.quantity)} displayType={'text'} thousandSeparator={true}/></b></p>
                </div>
            </div>
            </>
            
            {checkError && 
                <span className='input_error'>Please select a payment method before proceeding!</span>
            }
            {checkErrorPer && 
                <span className='input_error'>Total percentage should equal 100!</span>
            }
        </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_supplier_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} disabled={checkError == false && checkErrorPer == false ? false : true } /></span>
            </ModalFooter>
        </Modal>
    )
}

export default RatioModal