import React, { useEffect, useState } from 'react'
import * as CONFIG from "../../helpers/config";
import axios from "axios"
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import { Row, Form, Col, Button } from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function ViewCustomerRequirement(props) {
    const MySwal = withReactContent(Swal)
    const [selectedEnquiry, setSelected] = useState([])
    const [customerData, setcustomerData] = useState([])
    const { instance } = useMsal();
    const [modal, setmodal] = useState(props.modal)
    const [contractType, setContractType] = useState('')
    const [transportmode, settransportmode] = useState('')
    const [incoterms, setincoterms] = useState('')
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        getCustomerRequirementById(props.data.tradeID)
    }, [])

    useEffect(() => {
        console.log(customerData)
    }, [customerData])

    const getCustomerRequirementById = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADEENQUIRY_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result);
                setSelected(res.data.result[0]);
                getCustomerById(res.data.result[0].enqiry.customerID)
                getContractType(res.data.result[0].enqiry.contractTypeID)
                getTransportMode(res.data.result[0].enqiry.modeOfTransportID)
                getIncoterms(res.data.result[0].enqiry.incotermsID)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getCustomerById = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setcustomerData(res.data.result[0])
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
                console.log(error)
            })
    }

    const getContractType = (id) => {
        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_TMS_TRADEENQUIRY_CONTRACTTYPE, {
            "instanceID": CONFIG.PMSInstanceID,
            "created_user": CONFIG.LoginID
        }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                res.data.result.map((re, k) => {
                    if (re.contractTypeID == id) {
                        setContractType(re.contractTypeName)
                    }
                })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getTransportMode = (id) => {
        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_TMS_TRADEENQUIRY_MODEOFTRANSPORT, {
            "instanceID": CONFIG.PMSInstanceID,
            "created_user": CONFIG.LoginID
        }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                res.data.result.map((re, k) => {
                    if (re.modeOfTransportID == id) {
                        settransportmode(re.modeOfTransportName)
                    }
                })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getIncoterms = (id) => {
        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_INCOTERMS, {
            "instanceID": CONFIG.PMSInstanceID,
            "created_user": CONFIG.LoginID,
        }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                res.data.result.map((re, k) => {
                    if (re.incotermsID == id) {
                        setincoterms(re.incotermsName)
                    }
                })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }
    const togglechecked = ()=>{
        setIsChecked(!isChecked)
    }
    const approve = ()=>{
        props.approve()
        toggle()
    }
    const reject = ()=>{
        toggle()
        props.reject()
    }
    return (

        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract h-100vh" >
            <ModalHeader toggle={toggle} className='reports_details'>
                <div className='width-100l d-flex justify-content-between'>
                    <div className='d-flex align-items-center ms-4'>
                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER REQUIREMENT DETAILS</p>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper scrollContract' style={{ padding: '0', overflowX: 'hidden' }}>
                {customerData.customerdata != null &&
                    <Row>
                        <div class="table-responsive-sm ">
                            <div className='br-8 p-4 grey-color m-4 bg-light row mb-2-rem'>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>CUSTOMER DETAILS</p>
                                </Row>
                                <Row className='mb-2-rem'>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER NAME</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.customerName}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER CODE</p>
                                        <span className='tradeTrsactionsid statBg '>{customerData.customerdata.customerCode}</span>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER WEBSITE</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.website}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER TEL</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.telephoneNo}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER FAX</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.fax}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER EMAIL</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.email}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>ADDRESS DETAILS</p>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>UNIT NO</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.registeredUnitNo}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>BUILDING NAME</p>
                                        <span className='tradeTrsactionsid statBg '>{customerData.customerdata.registeredBuildingName}</span>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>STREET NAME</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.registeredStreetName}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CITY</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerData.customerdata.registeredCityName}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className='br-8 p-4 grey-color m-4 bg-light row mb-2-rem'>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>CONTRACT TERMS</p>
                                </Row>
                                <Row className='mb-2-rem'>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>CONTRACT TYPE</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{contractType}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>MODE OF TRANSPORT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{transportmode}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>INCOTERMS</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{incoterms}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className='br-8 p-4 grey-color m-4 bg-light row mb-2-rem'>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>PRODUCT DETAILS</p>
                                </Row>
                                <Row className='mb-2-rem'>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>PRODUCT NAME</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].product.productName}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>PRODUCT CODE</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].product.productCode}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>HSCODE</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].product.hsCode}</h6>
                                    </Col>
                                    <Col md={2}>
                                        <p className='font-weight-400 color-697382 mb-1'>PRODUCT UOM</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].product.productUOM!= null ? selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].product.productUOM.productUOMName:''}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>QUALITY PARAMETER AND TRACE ELEMENTS</p>
                                </Row>
                                <Row>
                                    <table className="table tCustom table-text">
                                        <thead>
                                            <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-4'>PARAMETER</th>
                                                <th>UNITS</th>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>TYPICAL</th>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>REJECTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].tradeEnquiryProductQualityParameters.map((quality, k) => {
                                                return (
                                                    <tr>
                                                        <td className='color-585966 px-4'>{quality.productQualityParameter.productQualityParameterName}</td>
                                                        <td className='color-585966'>%</td>
                                                        <td className='color-585966'>{quality.typicalValueRange}</td>
                                                        <td className='color-585966'>{quality.rejectionValueRange}</td>
                                                    </tr>
                                                )
                                            })}
                                            {selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].tradeEnquiryProductTraceElement.map((trace, k) => {
                                                return (
                                                    <tr>
                                                        <td className='color-585966 px-4'>{trace.productTraceElement.productTraceElementName}</td>
                                                        <td className='color-585966'>%</td>
                                                        <td className='color-585966'>{trace.typicalValueRange}</td>
                                                        <td className='color-585966'>{trace.rejectionValueRange}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Row>
                            </div>
                            <div className='br-8 p-4 grey-color m-4 bg-light row mb-2-rem'>
                                <Row>
                                    <p className='font-weight-600 color-697382 mb-4'>SUPPLY PLAN</p>
                                </Row>
                                <Row className='mb-2-rem'>

                                    <table className="table tCustom table-text">
                                        <thead>
                                            <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-4'>Supply#</th>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-4'>Year</th>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>Month</th>
                                                <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedEnquiry.enqiry.tradeEnquiryProductDetail[0].tradeEnquiryProductSupplyplan.map((supply, k) => {
                                                return (
                                                    <tr>
                                                        <td className='color-585966 px-4'>{k+1}</td>
                                                        <td className='color-585966'>{supply.year}</td>
                                                        <td className='color-585966'>{Moment(supply.month, 'M').format('MMMM')}</td>
                                                        <td className='color-585966'>{supply.quantity}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Row>
                            </div>
                        </div>
                    </Row>
                }
            </ModalBody>
            
            <ModalFooter>
                    {localStorage.getItem('LogInUserRoleID') != 3001 &&
                        <>
                        <>
                        <Form.Check
                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                            type='checkbox'
                            name='is_DischargePortETA'
                            label='I have reviewed this document'
                            onChange= {togglechecked}
                        />
                        </>
                        <>
                        <Button variant="primary" size="sm" onClick={approve} className={'font-weight-500 font-12 rounded-13 '} disabled={!isChecked}>
                            Approve
                        </Button>
                        </>
                        <>
                        <Button variant="danger" size="sm" onClick={reject} className={'font-weight-500 font-12 rounded-13 ms-2 btn-danger'} disabled={!isChecked}>
                            Reject
                        </Button>
                        </>
                        </>
                    }
                    
                </ModalFooter>
        </Modal>
    )
}
