import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form } from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { useFormik } from 'formik'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import * as CONFIG from '../../../../helpers/config'
import { PaymentTypeList } from '../../../../helpers/API/Api';
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';




function RatioModal(props) {
    console.log(props, 'test')
    const [modal, setModal] = useState(true);
    const [checkError, setCheckError] = useState(false)
    const [checkErrorPer, setCheckErrorPer] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState()
    const { instance } = useMsal();
    const [paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [overAllTotal, setOverAllTotal] = useState()
    const [trackCheck, setTrackCheck] = useState([])
    const [isPaymentset, setispaymentset] = useState(false)
    const [TradeCompanyCustomerDetails, setTradeCompanyCustomerDetails] = useState()


    useState(() => {

    }, [])

    const toggle = () => {
        setModal(!modal)
        props.close();
    }

    useEffect(() => {
        let object = {}
        let list = []
        let data = []
        if(props.data != null){
            console.log('data not null', props.data)
            props.data.map((values, k) => {
                object = {
                    "customerID": parseInt(values.customerID),
                    "incotermsID": parseInt(props.currentTrade.incotermsID),
                    "companyID": parseInt(values.companyID),
                    "tradeScenarioID": 3,
                    "ratio": values.ratio != null ? values.ratio : 0,
                    "ratioAmount": values.ratioAmount,
                    "Is_DefaultCustomer": true,
                    "Is_DefaultCompany": true,
                    'amount' : parseFloat(props.currentSupplyPlan.productSellingPrice),
                    "quantity" : parseFloat(props.currentSupplyPlan.quantity) 
                }
                if(values.ratio != null){
                    data.push(values.ratio)
                }
                list.push(object)
            })
            handleErrors(data)
            setTradeCompanyCustomerDetails(list)
        }
        else if (props.updatedCompany != null) {
            console.log('data  null')
            props.updatedCompany.map((values, k) => {
                object = {
                    "customerID": parseInt(values.customerdata.customerID),
                    "incotermsID": parseInt(props.currentTrade.incotermsID),
                    "companyID": parseInt(props.splitData.companyID != null ? props.splitData.companyID : props.splitData[0].companyID ),
                    "companyID": parseInt(Array.isArray(props.splitData) == true ? props.splitData[0].companyID : props.splitData.companyID ),
                    "tradeScenarioID": 3,
                    "ratio": values.customerdata.ratio != null ? values.customerdata.ratio : 0,
                    "ratioAmount": 0,
                    "Is_DefaultCustomer": true,
                    "Is_DefaultCompany": true,
                    'amount' : parseFloat(props.currentSupplyPlan.productSellingPrice),
                    "quantity" : parseFloat(props.currentSupplyPlan.quantity) 
                }
                if(values.customerdata.ratio != null){
                    data.push(values.customerdata.ratio)
                }
                list.push(object)
            })
            handleErrors(data)
            setTradeCompanyCustomerDetails(list)
        }

    }, [])
    const formik = useFormik({
        initialValues:
        {
            supplierPaymentTypeID: 4,
        },
    })


    const handleSaveEvent = () => {
        props.handleRatioData(TradeCompanyCustomerDetails)
        toggle()
    }

    const handleErrors = (data) => {
        console.log(data)
        let totalPer = 0
        if (data != null && data.length > 0) {
            data.map((amt, k) => {
                console.log(amt, k)
                totalPer += amt
            })
        }

        console.log(totalPer)

        if (totalPer != 100) {
            console.log('condition 1')
            setCheckError(false)
            setCheckErrorPer(true)
        }
        else {
            console.log('condition 3')
            setCheckError(false)
            setCheckErrorPer(false)
        }
    }

    const handleCheck = (event) => {
        let trackChec = [...final_json]
        if (event.target.checked) {
            for (let i = 0; i < trackChec.length; i++) {
                if (trackChec[i].supplierPaymentTypeID == event.target.value) {
                    trackChec[i].isCheck = true
                    if (props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null) {
                        console.log('check condition')
                        trackChec[i].is_Active = true
                        trackChec[i].is_Deleted = false
                    }
                }
            }
        }
        else {
            for (let i = 0; i < trackChec.length; i++) {
                if (trackChec[i].supplierPaymentTypeID == event.target.value) {
                    trackChec[i].isCheck = false
                    trackChec[i].supplierPaymentAmount = 0
                    trackChec[i].supplierPaymentPer = 0
                    if (props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null) {
                        console.log('check condition')
                        trackChec[i].is_Active = false
                        trackChec[i].is_Deleted = true
                    }
                }

            }
        }
        setFinal(trackChec)
        console.log(trackCheck)
    };

    const handlePercentageValues = (e) => {
        let list = TradeCompanyCustomerDetails
        let errorList = []
        list.map((values, k) => {
            if (values.customerID == parseInt(e.target.id)) {
                values.ratio = parseInt(e.target.value)
                values.ratioAmount = (values.quantity*values.amount)*parseInt(e.target.value)/100
            }

        })
        list.map((values, k) => (
            errorList.push(values.ratio)
        ))
        console.log(errorList)
        handleErrors(errorList)
        console.log(list)
        setTradeCompanyCustomerDetails(list)

    }
    useEffect(() => {
        console.log('check')
        console.log(props)
        if (props.currentSupplyPlan != null && props.currentSupplyPlan.productBuyingPrice) {
            console.log('check')
            var sum = props.currentSupplyPlan.productSellingPrice * props.currentSupplyPlan.quantity
            setOverAllTotal(parseFloat(sum).toFixed(2))
        }
    }, [])
    
    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modal'>
            <ModalHeader toggle={toggle} className='p-40'>
                <div className='d-flex justify-content-between '>
                    <div className='font-16 font-weight-600 color-707895'>
                        SPLIT RATIO
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-40'>
                <>
                    <div className=''>
                        
                        {props.updatedCompany.map((list, k) => (
                            <>
                                <div className={'bg-lighter d-flex justify-content-between  my-2 p-4 align-items-center'}>
                                    <>
                                        <div className='multicompany-split-ratio-modal-text'>
                                            {list.customerdata.customerName}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <Form.Control
                                                type="text"
                                                className='payment-terms-width'
                                                name={list.customerdata.customerID}
                                                id={list.customerdata.customerID}
                                                onChange={handlePercentageValues}
                                                defaultValue = { props.data != null ? props.data[k].ratio : list.customerdata.ratio}
                                            />
                                            <div className='ms-2'>
                                                (%)
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </>
                        ))}

                        <div className='d-flex justify-content-end me-3'>
                            <p>Total Volume: <b><NumberFormat value={overAllTotal} displayType={'text'} thousandSeparator={true}/></b></p>
                        </div>
                    </div>
                </>

                {checkError &&
                    <span className='input_error'>Please select a payment method before proceeding!</span>
                }
                {checkErrorPer &&
                    <span className='input_error'>Total percentage should equal 100!</span>
                }
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_supplier_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} disabled={checkError == false && checkErrorPer == false ? false : true} /></span>
            </ModalFooter>
        </Modal>
    )
}

export default RatioModal