import React, { useEffect } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import Moment from "moment";
import { useState } from 'react';
import DatePicker from "react-datepicker";
import * as FormFields from "../../components/formcomponents"
import { useFormik } from 'formik';
import { Col, Form, FloatingLabel, Button, Row, ButtonToolbar, ToggleButtonGroup, ToggleButton, Accordion } from "react-bootstrap"
import { Dropdown, Menu, Space } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import * as ButtonComponent from "../../components/buttoncomponents"
import * as CONFIG from "../../helpers/config";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import Autosuggest from 'react-autosuggest';
import CalendarView from './components/calendarView';
import ListMeetings from './components/listMeetings';
import AddMeeting from './components/addMeeting';
import AddTour from './components/AddTour';
import ViewMeeting from './components/ViewMeeting';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LinkMeetings from './components/LinkMeetings';
import CompanyModal from './companyModal';

export default function Index() {
    const location = useLocation()
    const { instance } = useMsal();
    const [selectedDate, setselectedDate] = useState(false)
    const [ssdate, setSsDate] = useState(false)
    const [modal, setmodal] = useState(false)
    const [term, setTerm] = useState('All')
    const [meetinglist, setmeetinglist] = useState([])
    const [taskhtml, settaskhtml] = useState('')
    const [agendaList, setAgendaList] = useState([])
    const [agendaStatus, setAgendStatus] = useState(false)
    const [meetingtype, setmeetingtype] = useState([])
    const [viewType, setViewType] = useState(false)
    const [tourmodal, settourmodal] = useState(false)
    const [isLinker, setIsLinker] = useState(false)
    const [filteredlist, setfilteredlist] = useState([])
    const [companyModal, setCompanyModal] = useState(false)
    const [company, setCompany] = useState([])
    const [type, setType] = useState(0)

    useEffect(() => {
        let today = Moment(new Date()).format('MMMM D, YYYY')
        setselectedDate(today)
        getlist()
    }, [])

    useState(() => {
        console.log(isLinker)
    }, [isLinker])

    const getlist = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "roleID": parseInt(localStorage.getItem('LogInUserRoleID'))
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETINGS_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setmeetinglist(res.data.result)
                setfilteredlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
                console.log(error)
            })
    }
    const changeSelectedDate = (date) => {
        let sdate = Moment(date).format('MMMM D, YYYY')
        setselectedDate(sdate)
        setSsDate(date)
        let filters = [...meetinglist]
        let list = filters.filter(x => Moment(x.meetingTime).format("DD/MM/YYY") == Moment(date).format("DD/MM/YYY"))
        setfilteredlist(list)
    }

    const formik = useFormik({
        initialValues:
        {
            searchBox: '',
            agendaList: ''
        }
    })

    useEffect(() => {
        console.log(filteredlist)
    }, [filteredlist])

    const toggle = () => {
        setmodal(!modal)
        settourmodal(false)
        getlist()
    }

    const companyToggle = () => {
        setCompanyModal(!companyModal)
    }

    const meetings = () => {
        let filter = filteredlist
        filter = meetinglist.filter(x => x.is_MeetingVal == 1)
        setfilteredlist(filter)
        setTerm('Meeting')
    }

    const tours = () => {
        let filter = filteredlist
        filter = meetinglist.filter(x => x.is_MeetingVal == 2)
        setfilteredlist(filter)
        setTerm('Tours')
    }

    const allList = () => {
        let filter = filteredlist
        filter = meetinglist
        setfilteredlist(filter)
        setTerm('All')
    }

    const tourtoggle = () => {
        settourmodal(!tourmodal)
        setmodal(false)
        getlist()
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={allList}>All Meetings & Tours</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={meetings}>All Meetings</span></>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={tours}>All Tours</span></>
                    ),
                }
            ]}
        />
    );
    const menu1 = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={() => {
                            setType(0)
                            companyToggle()
                        }}>Meeting</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={() => {
                            setType(1)
                            companyToggle()
                        }}>Tour</span></>
                    ),
                }
            ]}
        />
    );

    const changeView = () => {
        setViewType(!viewType)
    }

    const linktoggle = () => {
        setIsLinker(!isLinker)
    }
    const handleSearch = (e) => {
        let val = e.target.value
        let filter = meetinglist.filter(x =>
            x.meetingTitle != null && x.meetingTitle.toLowerCase().includes(val.toLowerCase()) ||
            x.meetingCode != null && x.meetingCode.toLowerCase().includes(val.toLowerCase()) ||
            x.meetingCategory != null && x.meetingCategory.toLowerCase().includes(val.toLowerCase())
        )
        setfilteredlist(filter)
    }
    const saveCompany = (data) => {
        console.log(data)
        setCompany(data)
        if (type == 0) {
            toggle()
        }
        else {
            tourtoggle()
        }
    }

    const changeCompany = () => {
        toggle()
        companyToggle()
    }

    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            {companyModal &&
                <CompanyModal toggle={companyToggle} saveCompany={saveCompany} />
            }
            {modal &&
                <AddMeeting toggle={toggle} company={company} changeCompany={changeCompany} />
            }
            {tourmodal &&
                <AddTour toggle={tourtoggle} company={company} />
            }
            <Sidebar />
            <div className='width-100'>
                <Navbar />
                <div id="content" className='mobHeader'>
                    <div className=' '>
                        <div className='meetings_header justify-content-between p-2-rem align-items-center col-12 mt-8r'>

                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='col-md-2'>
                                    <div className='meeting_header_date'>
                                        {selectedDate}
                                        <div className='meeting_date_select'>
                                            <label className='d-flex align-items-center'>
                                                <img src='assets/images/DownwardArrow.svg' className='pointer' />
                                                <DatePicker name="DocExpiryDate" className="form_input" onChange={changeSelectedDate} selected={Moment(!ssdate ? new Date() : ssdate).toDate()} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2 '>
                                    <div className='d-flex'>
                                    </div>
                                    <div className='ml-1-rem'>
                                        <Dropdown className='br-8 py-2' overlay={menu1} trigger={['click']}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <Button variant="primary" className='font-weight-500 font-14 rounded-13 create_meeting_button d-flex justify-content-between align-items-center'>
                                                        <img className="img-icn" src='assets/images/AddButtonIconWhite.svg' /> <span className="ps-1" style={{ width: '70px' }}>Create</span><span className='create_meeting_button_end'>
                                                            <img src='assets/images/white_drropdown.svg' className='pointer' /></span>
                                                    </Button>
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 d-flex align-items-center mt-4 justify-content-between'>
                                <div className='meeting_searchbox  col-md-6'>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='search'
                                            className="form_input form_input_color_change"
                                            name='searchBox'
                                            val={formik.values.searchBox}
                                            onChange={handleSearch}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className='choose_type'>
                                    <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space className='d-flex justify-content-between align-items-center'>
                                                <span className='meeting_term'>{term}</span>
                                                <span><img src='assets/images/DownwardArrow.svg' /></span>
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className='col-md-3 mt-4'>
                                <div className='d-flex br-4'>
                                    <span className='view_type d-flex justify-content-center align-items-center pointer' onClick={() => {
                                        setViewType(false)
                                    }}>List View</span>
                                    <span className='view_type d-flex justify-content-center align-items-center pointer' onClick={() => {
                                        setViewType(true)
                                    }}>Calendar View</span>
                                    {/* <span className='filter_meeting pointer'><img src='assets/images/filter_meeting.svg' /></span> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div id="content" className='desktop_view'>
                    <div className=' d-flex justify-content-center' style={{marginTop:  '5rem'}}>
                        <div className='meetings_header row justify-content-between p-2-rem align-items-center col-12 '>
                            <div className='col-lg-2 col-md-12  col-sm-12'>
                                <div className='meeting_header_date'>
                                    {selectedDate}
                                    <div className='meeting_date_select'>
                                        <label className='d-flex align-items-center'>
                                            <img src='assets/images/DownwardArrow.svg' className='pointer' />
                                            <DatePicker name="DocExpiryDate" className="form_input" onChange={changeSelectedDate} selected={Moment(!ssdate ? new Date() : ssdate).toDate()} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12  col-sm-12 d-flex align-items-center'>
                                <div className='meeting_searchbox ml-1-rem col-md-6'>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='search'
                                            className="form_input form_input_color_change"
                                            name='searchBox'
                                            val={formik.values.searchBox}
                                            onChange={handleSearch}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className='choose_type'>
                                    <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space className='d-flex justify-content-between align-items-center'>
                                                <span className='meeting_term'>{term}</span>
                                                <span><img src='assets/images/DownwardArrow.svg' /></span>
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12  col-sm-12'>
                                <div className='d-flex br-4'>
                                    <span className='view_type d-flex justify-content-center align-items-center pointer' onClick={() => {
                                        setViewType(false)
                                    }}>List View</span>
                                    <span className='view_type d-flex justify-content-center align-items-center pointer' onClick={() => {
                                        setViewType(true)
                                    }}>Calendar View</span>
                                    {/* <span className='filter_meeting pointer'><img src='assets/images/filter_meeting.svg' /></span> */}
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-12  col-sm-12'>
                                <div className='d-flex'>
                                </div>
                                <div className='ml-1-rem'>
                                    <Dropdown className='br-8 py-2' overlay={menu1} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                <Button variant="primary" className='font-weight-500 font-14 rounded-13 create_meeting_button d-flex justify-content-between align-items-center'>
                                                    <img className="img-icn" src='assets/images/AddButtonIconWhite.svg' /> <span className="ps-1" style={{ width: '70px' }}>Create</span><span className='create_meeting_button_end'>
                                                        <img src='assets/images/white_drropdown.svg' className='pointer' /></span>
                                                </Button>
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {viewType ?
                    <CalendarView meetinglist={filteredlist} />
                    :
                    <ListMeetings meetinglist={filteredlist} getlist={getlist} />
                }

            </div>

        </div>
    )
}
