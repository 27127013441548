import React, { useEffect, useState } from 'react'
import {Row} from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { useFormik } from 'formik';
import * as CONFIG from "../../../../helpers/config"
import axios from "axios";
import Moment from "moment";
import { useMsal } from "@azure/msal-react";

export default function Revenuescreen(props) {
    const { instance } = useMsal();
    console.log(props, 'revenue screen')
    const [isDataSet, setIsDataSet] = useState(true);
    const [isFirst, setIsFirst] = useState(true);
    const [financelist, setfinancelist] = useState([])
    const [final_json, setFinal] = useState([])
    const currentYear = parseInt(Moment(new Date()).format("YYYY"));
    const previousYear = (currentYear-1);
    console.log(previousYear, 'previous year');
    useEffect(()=>{
        let final =[]
        if(props.isEdit){
            console.log('condition 1')
            final = props.data
            console.log(final)
            let fins = []
            console.log(props)
            final.map((fin,k)=>{
                console.log(fin)
               let fin1 = {
                    "supplierFinancialInformationID": fin.supplierFinancialInformationID,
                    "supplierID": fin.supplierID,
                    "supplierFinancialInfoTypeID": fin.supplierFinancialInfoTypeID,
                    "year": fin.year,
                    "value": fin.value
                }
                fins.push(fin1)
            })
            console.log(fins)
            setFinal(fins)
            getFinancialInfo()
            props.setFinance(fins)
        }
        else if(props.isFinanceSet){
            console.log('condition 2')
            final = props.data
            setFinal(final)
            getFinancialInfo()
        }
        else{
            console.log('condition 3')
            const json_list ={
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_FINANCIAL_INFO, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setfinancelist(res.data.result)
                    res.data.result.map((re,k)=>{
                        console.log(re)
                        let fin =[]
                        
                        let fin1 = {
                            "supplierFinancialInfoTypeID": re.supplierFinancialInfoTypeID,
                            "year": parseInt(currentYear),
                            "value" : ''
                        }
                        let fin2= {
                            "supplierFinancialInfoTypeID": re.supplierFinancialInfoTypeID,
                            "year": previousYear,
                            "value" : ''
                        }
                        final.push(fin1)
                        final.push(fin2)
                        setFinal(final)
                    })
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
            //setFinal(final)
        }

        setIsFirst(false)
    },[isFirst])


    const getFinancialInfo = ()=>{
        const json_list ={
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_FINANCIAL_INFO, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setfinancelist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })
    }

    const formik = useFormik({
        initialValues:{
            
        },
       
    })
    useEffect(()=>{
        props.setFinance(final_json)
    },[isDataSet])
    // const sendBack = ()=>{
    //     console.log(Object.keys(formik.errors).length)
    //     if((Object.keys(formik.errors).length == 0) && !isFirst){
    //         console.log(Object.keys(formik.errors).length,'inside formik error use effect')
    //         props.requiredFilled()
    //         props.setFinance(final_json)
    //     }
    //     else{
    //         props.requiredFailed()
    //         props.setFinance(final_json)
    //     }
    // }

    const handleCurrent = (e) =>{
        const id = parseInt(e.target.id);
        console.log(id)
        const value = parseFloat(e.currentTarget.value)
        let final_data = [...final_json]
        console.log(final_json, 'handleCurrent')
        final_json.map((final,k)=>{
            if(final.supplierFinancialInfoTypeID == id && final.year == currentYear){
                final_data[k].value = value
            }
        })
        setFinal(final_data)
        setIsDataSet(!isDataSet)
        props.setFinance(final_data)
    }

    const handlePrevious = (e) =>{
        console.log(e.currentTarget.value)
        const id = parseInt(e.target.id);
        const value = parseFloat(e.currentTarget.value)
        let final_data = [...final_json]
        console.log(final_data, 'handlePrevious')
        final_json.map((final,k)=>{
            if(final.supplierFinancialInfoTypeID == id && final.year == previousYear){
                final_data[k].value = value
            }
        })
        setFinal(final_data)
        setIsDataSet(!isDataSet)
        props.setFinance(final_data)
    }

    return (    
        <Row className='d-flex' >
            {financelist.map((list,k)=>{
                let rendered_finance = props.data.filter(x=>x.supplierFinancialInfoTypeID == list.supplierFinancialInfoTypeID)
                let currentYear_data = []
                let previous_data = []
                if(Array.isArray(rendered_finance)){
                    currentYear_data = rendered_finance.filter(x=>x.year == currentYear)
                    previous_data = rendered_finance.filter(x=>x.year == previousYear)
                }
                console.log(currentYear_data)
                return(
                    <>
                    <Row>
                        <FormFields.InputField
                            md={3}
                            label={list.supplierFinancialInfoTypeName}
                            classname='pt-1-rem'
                            type='number'
                            disabled={true}
                        />
                        <FormFields.InputField
                            md={3}
                            label='Current Year (in US $)*'
                            classname='pt-1-rem'
                            id={list.supplierFinancialInfoTypeID}
                            type='number'
                            onChange={handleCurrent}
                            val={currentYear_data.length > 0 ? currentYear_data[0].value :0}
                        />
                        <FormFields.InputField
                            md={3}
                            label='Previous Year (in US $)*'
                            classname='pt-1-rem'
                            id={list.supplierFinancialInfoTypeID}
                            type='text'
                            onChange={handlePrevious}
                            val={previous_data.length ? previous_data[0].value : 0}
                        />
                    </Row>
                
                </>
                )
            })}
            
        </Row>
        
    )
}
