import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { BlueButton, BlueBorderButton } from '../../components/buttoncomponents'
import * as CONFIG from "../../helpers/config"
import axios from "axios";
import Tiny_editor from './tiny_editor'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Col, Row } from 'react-bootstrap'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Modal, ModalBody } from "reactstrap"
import Moment from "moment"
import { useLocation } from 'react-router-dom'
import { useMsal } from "@azure/msal-react";
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';

export default function Index(props) {
    console.log(props)
    const { instance } = useMsal();
    const location = useLocation()
    const tradeID = props.tradeID
    const ctId = props.ctId
    const spId = props.spId;
    const type = props.type
    const tradescenarioID = props.tradescenarioID
    const [contractID, setContractID] = useState(type == 1 ? ctId : spId)
    const [template_list, settemplatelist] = useState([])
    const [isFirst, setIsFirst] = useState(true)
    const [modal, setModal] = useState(false)
    const [data, setData] = useState({})
    const [isCustomer, setIsCustomer] = useState(false)
    const [customerData, setCustomerData] = useState([])
    const [coBuyerData, setCoBuyerData] = useState([])
    const [contractNumber, setContractNumber] = useState([])
    const [apiData, setApiData] = useState([])
    const [qualityparamhtml, setqualityparam] = useState('')
    const [loader, setloader] = useState(true)
    const [ishistory, setishistory] = useState(false)
    const [companydata, setcompanydata] = useState([])
    const [coSellerData, setCoSellerData] = useState([])
    const [divisiondata, setdivisiondatadata] = useState([])
    const [customercountry, setcustomercountry] = useState('')
    const [customerstate, setcustomerstate] = useState('')
    const [suppliercountry, setsuppliercountry] = useState('')
    const [supplierstate, setsupplierstate] = useState('')
    const [countrylist, setCountryList] = useState([])
    const [senddisabled, setsenddisabled] = useState(false)
    useEffect(() => {
        console.log(customerData)
        console.log(countrylist)
    }, [customerData, countrylist])

    useEffect(() => {
        if (isFirst) {
            if (contractID == null) {
                getSections()
                axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COUNTRY_LIST, {
                    "PMSInstanceID": CONFIG.PMSInstanceID
                }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        console.log(res.data.result)
                        setCountryList(res.data.result)
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }
            else {
                getContractById(contractID)
            }
            getTradeDetails()
        }
        setIsFirst(false)
    })
    useEffect(() => {
        console.log(data)
    }, [data])
    useEffect(() => {
        console.log(qualityparamhtml)
    }, [qualityparamhtml])
    const getTradeDetails = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeID // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAIL_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                var cs_name = ''
                if (type == 1) {
                    if (tradescenarioID == 1) {
                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                setCustomerData(res.data.result[0].customer)
                                setcompanydata(res.data.result[0].company)
                                cs_name = res.data.result[0].customer.customerName
                                setloader(false)
                            }).catch((error) => {
                                if (error.response.status == 401) {
                                    localStorage.clear();
                                    instance.logoutRedirect({
                                        postLogoutRedirectUri: "/",
                                    });
                                }
                            })
                    }
                    else if (tradescenarioID == 2) {
                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                console.log(res.data.result)
                                setCustomerData(res.data.result[0].customer)
                                cs_name = res.data.result[0].customer.customerName
                                res.data.result.map((re, k) => {
                                    if (re.is_DefaultCompany == true) {
                                        setcompanydata(re.company)
                                    }
                                    else {
                                        console.log(re.company)
                                        setCoSellerData(re.company)
                                        console.log(re.company, 'seller co data')
                                    }
                                    if (k == 1) {
                                        setloader(false)
                                    }
                                })
                            }).catch((error) => {
                                if (error.response.status == 401) {
                                    localStorage.clear();
                                    instance.logoutRedirect({
                                        postLogoutRedirectUri: "/",
                                    });
                                }
                            })
                    }
                    else if (tradescenarioID == 3) {
                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                console.log(res.data.result)
                                setcompanydata(res.data.result[0].company)
                                res.data.result.map((re, k) => {
                                    if (re.is_DefaultCustomer == true) {
                                        setCustomerData(re.customer)
                                    } else {
                                        setCoBuyerData(re.customer)
                                    }
                                })
                                setloader(false)
                            }).catch((error) => {
                                if (error.response.status == 401) {
                                    localStorage.clear();
                                    instance.logoutRedirect({
                                        postLogoutRedirectUri: "/",
                                    });
                                }
                            })
                    }
                }
                else {
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            setCustomerData(res.data.result[0].customer)
                            setcompanydata(res.data.result[0].company)
                            cs_name = res.data.result[0].customer.customerName
                            setloader(false)
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                }
                setApiData(res.data.result[0].tradedetails)
                setcompanydata(res.data.result[0].companydetails)
                setdivisiondatadata(res.data.result[0].divdetails)
                console.log(res.data.result[0])
                setIsCustomer(true)
                console.log(cs_name)
                let cs_code = cs_name.substring(0, 3)
                let d_month = Moment(new Date()).format("mm")
                let d_year = Moment(new Date()).format("YYYY")
                var alpha = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
                var ContractNumber = cs_code.toUpperCase() + "-" + d_month + "-" + d_year + "-" + alpha.toUpperCase()
                setContractNumber(ContractNumber)
                let productdetails = res.data.result[0].tradedetails.tradeProductDetail;
                console.log(productdetails)
                productdetails.map((product, k) => {
                    let quality = product.tradeProductQualityParameters
                    let trace = product.tradeProductTraceElement
                    renderQualityandTrace(quality, trace)
                })
                if (type == 1) {
                    if (res.data.result[0].tradedetails.customerContractID != null) {
                        setContractID(res.data.result[0].tradedetails.customerContractID)
                        getContractById(res.data.result[0].tradedetails.customerContractID)
                    }
                }
                else {
                    if (res.data.result[0].tradedetails.supplierContractID != null) {
                        setContractID(res.data.result[0].tradedetails.supplierContractID)
                        getContractById(res.data.result[0].tradedetails.supplierContractID)
                    }
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const renderQualityandTrace = (quality, trace) => {
        console.log('inside')
        let html = "<table><thead><tr><th>Item</th><th>Typical</th><th>Rejection</th></tr></thead><tbody>";
        quality.map((qul, k) => {
            html += "<tr><td>" + qul.productQualityParameter.productQualityParameterName + "</td><td>" + qul.typicalValueRange + "</td><td>" + qul.rejectionValueRange + "</td>"
        })
        trace.map((trc) => {
            html += "<tr><td>" + trc.productTraceElement.productTraceElementName + "</td><td>" + trc.typicalValueRange + "</td><td>" + trc.rejectionValueRange + "</td>"
        })
        html += "</tbody></table>"
        setqualityparam(html)
    }

    const toggle = () => {
        setModal(!modal)
    }

    const getSections = () => {
        let json_list = {}
        if (type == 1) {
            if (tradescenarioID == 1) {
                json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "created_User": CONFIG.LoginID,
                    "id": type
                }
            }
            else if (tradescenarioID == 2) {
                json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "created_User": CONFIG.LoginID,
                    "id": 3
                }
            }
            else {
                json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "created_User": CONFIG.LoginID,
                    "id": 4
                }
            }
        }
        else {
            json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "created_User": CONFIG.LoginID,
                "id": type
            }
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_SECTIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                settemplatelist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getContractById = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                console.log(res.data.result[0])
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const saveSections = (data, li) => {
        if (contractID == null) {
            console.log('inside if')
            console.log(data)
            let temp_list = [...template_list]
            let final_json = {
                "contractTemplateID": parseInt(type),
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }
            let template_json = []
            template_list.map((list, k) => {
                template_json[k] = {
                    "order": k + 1,
                    "srNo": k + 1,
                    "heading": list.heading,
                    "is_Lock": list.is_Lock
                }
                if (list.contractTemplateSectionsID == li.data.contractTemplateSectionsID) {
                    template_json[k].contractContent = data
                }
                else {
                    template_json[k].contractContent = list.contractContent
                }
            })
            final_json["contractSections"] = template_json
            console.log(final_json)
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_CONTRACT_SECTIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setContractID(res.data.result)
                    getContractById(res.data.result)
                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": tradeID, //tradeid
                        "TemplateTypeID": type, // 1 customer , 2 supplier
                        "contractID": parseInt(res.data.result)
                    }
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_CONTRACT_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            console.log(res.data.result)
                            props.refreshList()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            console.log("inside")
            console.log(data)
            let final_json = {
                "contractID": contractID,
                "contractTemplateID": type,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "modified_User": CONFIG.LoginID,
            }

            let template_json = {
                "contractSectionsID": li.data.contractSectionsID,
                "order": li.data.order,
                "srNo": li.data.srNo,
                "heading": li.data.heading,
                "contractContent": data,
                "is_Lock": li.data.is_Lock,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "modified_User": CONFIG.LoginID
            }
            final_json["contractSections"] = [template_json]
            console.log(final_json)
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_CONTRACT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    getContractById(res.data.result)
                    props.refreshList()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const printDocument = () => {
        const domElement = document.getElementById("download_section");
        if (apiData.customerContractApprovalStatus == 2) {
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm', 'A4');
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    if (data.contractTemplateID == 1) {
                        doc.save('Customer_Contract' + '-' + apiData.tradeNo + '.pdf');
                    } else {
                        doc.save('Supplier_Contract' + '-' + apiData.tradeNo + '.pdf');
                    }
                    // var doc = new jsPDF("p", "pt", "letter");
                    // doc.html(document.getElementById("download_section"), {
                    //     callback: function (doc) {
                    //       doc.save();
                    //     },
                    //     margin: 10
                    // });

                    getContractById(contractID)
                })
        }
        else {

            var charSet = ' '
            var content = charSet + domElement.innerHTML;
            var converted = htmlDocx.asBlob(content);
            console.log(data)
            if (data.contractTemplateID == 1) {
                saveAs(converted, 'Customer_Contract' + '-' + apiData.tradeNo + '.docx');
            } else {
                saveAs(converted, 'Supplier_Contract' + '-' + apiData.tradeNo + '.docx');
            }
        }

        props.refreshList()
    }


    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    const sendForApproval = () => {
        console.log('send for approval')
        setsenddisabled(true)
        finalizeContract()
        if (type == 1) {
            const json_final = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": tradeID,
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_CUSTOMERCONTRACT_APPROVAL_WFID, json_final, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res1) => {
                    //getTradeDetails()
                    props.refreshList()
                    setsenddisabled(false)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_final = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": tradeID,
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_SUPPLIERCONTRACT_APPROVAL_WFID, json_final, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res1) => {
                    //getTradeDetails()
                    props.refreshList()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }

    }

    const finalizeContract = () => {
        template_list.map((list) => {
            var contractData = list.contractContent;
            contractData = contractData.replace("&lt;Customer Name&gt;", customerData.customerName);
            contractData = contractData.replace("&lt;Customer Street&gt;", customerData.registeredStreetName);
            contractData = contractData.replace("&lt;Customer City&gt;", customerData.registeredCityName);
            contractData = contractData.replace("&lt;Customer Post&gt;", customerData.registeredPostBox);
            contractData = contractData.replace("&lt;Customer Country&gt;", customerData.CustomerCountry);
            contractData = contractData.replace("&lt;Customer State&gt;", 'Dubai');
            contractData = contractData.replace("&lt;DestinationPortName&gt;", apiData.destinationPort);
            contractData = contractData.replace("&lt;DestinationPortname&gt;", apiData.destinationPort);
            contractData = contractData.replace("&lt;DestinationPort&gt;", apiData.destinationPort);
            contractData = contractData.replace("&lt;LoadingPortName&gt;", apiData.loadPort);
            contractData = contractData.replace("&lt;Name&gt;", apiData.loadPort);
            contractData = contractData.replace("&lt;DestCountry&gt;", apiData.destinationPortCountry);
            contractData = contractData.replace("&lt;CountryOfOriginAndType&gt;", apiData.destinationPortCountry);
            contractData = contractData.replace("&lt;LoadCountry&gt;", apiData.loadPortCountry);
            contractData = contractData.replace("&lt;ContractNumber&gt;", contractNumber);
            contractData = contractData.replace("&lt;CustomerName&gt;", customerData.customerName);
            contractData = contractData.replace("&lt;Boiler-Plate-TMS-Quality-Components&gt;", qualityparamhtml);
            var totalShipmentSize = 1000
            var month = "January"
            let productDetail = apiData.tradeProductDetail
            var totalQuantity = 0
            var total_amount = 0
            productDetail.map((detail) => {
                totalQuantity += detail.quantity
                total_amount += detail.productSellingPrice
            })
            contractData = contractData.replace("&lt;TotalQuantity&gt;", totalQuantity + " ");
            contractData = contractData.replace("&lt;TotalShipmentSize&gt;", totalShipmentSize);
            contractData = contractData.replace("&lt;Month&gt;", month);
            contractData = contractData.replace("&lt;Amount&gt;", total_amount);
            contractData = contractData.replace("&lt;Amount&gt;", total_amount);
            let final_json = {
                "contractID": contractID,
                "contractTemplateID": parseInt(type),
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "modified_User": CONFIG.LoginID,
            }

            let template_json = {
                "contractSectionsID": list.contractSectionsID,
                "order": list.order,
                "srNo": list.srNo,
                "heading": list.heading,
                "contractContent": contractData
            }
            final_json["contractSections"] = [template_json]
            console.log(final_json)
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_CONTRACT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        })
        const json_final = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": contractID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.FINALIZE_CONTRACT, json_final, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        if (type == 1) {
            const json_finalizecontract = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(tradeID),
                "statusID": 0
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATESTATUS_FINALIZE_CONTRACT, json_finalizecontract, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_finalizecontract = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(tradeID),
                "statusID": 0
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATESTATUS_FINALIZE_CONTRACT_SUPPLIER, json_finalizecontract, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        getContractById(contractID)
        history()
    }

    const history = () => {
        if (!ishistory) {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": contractID
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CONTRACT_HISTORY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    getTradeDetails()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        setishistory(true)
    }

    const getCustomerContractStatus = () => {
        console.log('inside')
        if (apiData.customerContractApprovalStatus == 1) {
            return (
                <div className='approval_in_process d-flex justify-content-center align-items-center my-2 me-2'>Customer Contract - Pending Approval</div>
            )
        }
        else if (apiData.customerContractApprovalStatus == 2) {
            return (
                <div className='approved d-flex justify-content-center align-items-center my-2 me-2'>Customer Contract Approved</div>
            )
        }
        else if (apiData.customerContractApprovalStatus == 9) {
            return (
                <div className='re d-flex justify-content-center align-items-center my-2 me-2'>Customer Contract Rejected</div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    const getSupplierContractStatus = () => {
        console.log('inside')
        if (apiData.supplierContractApprovalStatus == 1) {
            return (
                <div className='approval_in_process d-flex justify-content-center align-items-center my-2 me-2'>Supplier Contract - Pending Approval</div>
            )
        }
        else if (apiData.supplierContractApprovalStatus == 2) {
            return (
                <div className='approved d-flex justify-content-center align-items-center my-2 me-2'>Supplier Contract Approved</div>
            )
        }
        else if (apiData.supplierContractApprovalStatus == 9) {
            return (
                <div className='rejected d-flex justify-content-center align-items-center my-2 me-2'>Supplier Contract Rejected</div>
            )
        }
        else {
            return (
                <></>
            )
        }

    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='w-100'>
                <div className='d-flex inner-page-header justify-content-between align-items-center'>
                    <div className='d-flex justify-content-between width-100l'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <img src='assets/images/pageicons/Dashboard.svg' className='img-fluid' />
                            </div>
                            <div className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>CONTRACT</div>
                        </div>
                        <div className='d-flex'>
                            {
                                type == 1 ?
                                    <>
                                        {Object.keys(data).length > 0 &&
                                            <>
                                                {apiData.customerContractWorkFlowID == null ?
                                                    <BlueButton class='me-2' value='SEND FOR APPROVAL' clickEvent={sendForApproval} disabled={senddisabled} />
                                                    :
                                                    <>
                                                        {getCustomerContractStatus()}
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                    :
                                    <>

                                        {Object.keys(data).length > 0 &&
                                            <>
                                                {apiData.supplierContractWorkFlowID == null ?
                                                    <BlueButton class='me-2' value='SEND FOR APPROVAL' clickEvent={sendForApproval} />
                                                    :
                                                    <>
                                                        {getSupplierContractStatus()}
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                            }
                            <BlueBorderButton value='EXPORT CONTRACT' clickEvent={toggle} />
                        </div>
                    </div>
                </div>
                <div className=''>
                    {loader ?
                        <div className='add-edit-wrapper contract-wrapper d-flex align-items-center justify-content-center' style={{ height: '30vh' }}>
                            <img src='assets/images/loader.gif' className='img-fluid loader_class' />
                        </div>
                        :
                        <div className='inner-page-content'>
                            <div className='d-flex justify-content-between contract_tabs'>
                                <Tabs className='d-flex justify-content-between width-100l'>

                                    <Col md={2} className='card tabs-scroll br-6'>
                                        <TabList>
                                            {template_list.map((list, k) => (
                                                <Tab className="tab-width d-flex justify-content-between" style={{ padding: '15px 0 0 0' }}>
                                                    {k != 0 && k + '.'}&nbsp;{list.heading}
                                                    {list.is_Lock ?
                                                        <img src='assets/images/lock.svg' className='img-fluid lock_unlock' />
                                                        :
                                                        <img src='assets/images/unlock.svg' className='img-fluid lock_unlock' />
                                                    }
                                                </Tab>
                                            ))}
                                        </TabList>
                                    </Col>
                                    <Col md={10} className='ml-1-rem'>
                                        {template_list.map((list, k) => {
                                            var contractData = list.contractContent;
                                            let custcon = ''
                                            let customercountry = ''
                                            let compcountry = ''
                                            let comcon = ''
                                            let suppcon = ''
                                            let suppliercountry = ''
                                            if (contractID == null) {
                                                console.log(customerData.registeredCountryID)
                                                if (customerData.registeredCountryID != 0 && customerData.registeredCountryID != null) {
                                                    custcon = countrylist.filter(x => x.countryId == customerData.registeredCountryID)[0]
                                                    customercountry = custcon.countryName
                                                }
                                                if(companydata.countryID != 0 && companydata.countryID != null){
                                                    comcon = countrylist.filter(x => x.countryId == companydata.countryID)[0]
                                                    compcountry = comcon.countryName
                                                }
                                                if(apiData.supplier.registeredCountryID != 0 && apiData.supplier.registeredCountryID != null){
                                                    suppcon = countrylist.filter(x => x.countryId == apiData.supplier.registeredCountryID)
                                                    suppliercountry = suppcon.countryName
                                                }
                                                if (tradescenarioID == 2) {
                                                    console.log(coSellerData)
                                                    let cocomcon = countrylist.filter(x => x.countryId == coSellerData.countryID)[0]
                                                    let cocompcountry = cocomcon.countryName
                                                    contractData = contractData.replace("&lt;CoSellerCompanyName&gt;", coSellerData.companyName);
                                                    contractData = contractData.replace("&lt;CoSellerCompanyAddress&gt;", '');
                                                    contractData = contractData.replace("&lt;CoSellerCompanyPostBox&gt;", coSellerData.companyPoBox)
                                                    contractData = contractData.replace("&lt;CoSellerCompanyCity&gt;", coSellerData.cityName)
                                                    contractData = contractData.replace("&lt;CoSellerCompanyStreet&gt;", coSellerData.companyStreet)
                                                    contractData = contractData.replace("&lt;CoSellerCompanyState&gt;", '')
                                                    contractData = contractData.replace("&lt;CoSellerCompanyCountry&gt;", cocompcountry)
                                                }
                                                if (tradescenarioID == 3) {
                                                    let cocuscon = countrylist.filter(x => x.countryId == coBuyerData.registeredCountryID)[0]
                                                    let coccuscountry = ''
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerName&gt;", coBuyerData.customerName);
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerStreet&gt;", coBuyerData.registeredStreetName);
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerAddress&gt;", '');
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerCity&gt;", coBuyerData.registeredCityName);
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerPostBox&gt;", coBuyerData.registeredPostBox);
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerCountry&gt;", coccuscountry);
                                                    contractData = contractData.replace("&lt;CoBuyerCustomerState&gt;", '');
                                                }
                                            }
                                            contractData = contractData.replace("&lt;Trade Date&gt;", Moment(apiData.created_Datetime).format('DD/MM/YYYY'))
                                            contractData = contractData.replace("&lt;ContractNumber&gt;", contractNumber)
                                            contractData = contractData.replace("&lt;CustomerName&gt;", customerData.customerName);
                                            contractData = contractData.replace("&lt;CustomerStreet&gt;", customerData.registeredStreetName);
                                            contractData = contractData.replace("&lt;CustomerAddress&gt;", '');
                                            contractData = contractData.replace("&lt;CustomerCity&gt;", customerData.registeredCityName);
                                            contractData = contractData.replace("&lt;CustomerPostBox&gt;", customerData.registeredPostBox);
                                            contractData = contractData.replace("&lt;CustomerCountry&gt;", customercountry);
                                            contractData = contractData.replace("&lt;CustomerState&gt;", 'Dubai');
                                            contractData = contractData.replace("&lt;DestinationPortName&gt;", apiData.destinationPort);
                                            contractData = contractData.replace("&lt;DestinationPort&gt;", apiData.destinationPort);
                                            contractData = contractData.replace("&lt;LoadingPortName&gt;", apiData.loadPort);
                                            contractData = contractData.replace("&lt;Name&gt;", apiData.loadPort);
                                            contractData = contractData.replace("&lt;DestCountry&gt;", apiData.destinationPortCountry);
                                            contractData = contractData.replace("&lt;CountryOfOriginAndType&gt;", apiData.destinationPortCountry);
                                            contractData = contractData.replace("&lt;LoadCountry&gt;", apiData.loadPortCountry);
                                            contractData = contractData.replace("&lt;ContractNumber&gt;", contractNumber);
                                            contractData = contractData.replace("&lt;CustomerName&gt;", customerData.customerName);
                                            contractData = contractData.replace("&lt;Boiler-Plate-TMS-Quality-Components&gt;", qualityparamhtml);
                                            contractData = contractData.replace("&lt;CompanyName&gt;", companydata.companyName);
                                            contractData = contractData.replace("&lt;CompanyAddress&gt;", '');
                                            contractData = contractData.replace("&lt;ShipmentSizeVariation&gt;", 'N/A');
                                            contractData = contractData.replace("&lt;IncotermsPort&gt;", 'N/A');
                                            contractData = contractData.replace("&lt; IncotermsCountry&gt;", 'N/A');
                                            contractData = contractData.replace("&lt;CustomerLCDate&gt;", 'N/A');
                                            contractData = contractData.replace("&lt;TraceElementsList&gt;", qualityparamhtml);
                                            contractData = contractData.replace("&lt;COAParameters&gt;", 'N/A');
                                            contractData = contractData.replace("&lt;AdditionalCOO&gt;", 'N/A');
                                            //contractData = contractData.replace("&lt;CompanyName&gt;", 'Rescom')
                                            //contractData = contractData.replace("&lt;CompanyAddress&gt;", '')
                                            contractData = contractData.replace("&lt;CompanyPostBox&gt;", companydata.companyPoBox)
                                            contractData = contractData.replace("&lt;CompanyCity&gt;", companydata.cityName)
                                            contractData = contractData.replace("&lt;CompanyStreet&gt;", companydata.companyStreet)
                                            contractData = contractData.replace("&lt;CompanyState&gt;", 'Dubai')
                                            contractData = contractData.replace("&lt;CompanyCountry&gt;", compcountry)
                                            contractData = contractData.replace("&lt;ProductName&gt;", apiData.tradeProductDetail[0].product.productName)
                                            contractData = contractData.replace("&lt;Incoterms&gt;", apiData.incoterms.incotermsName)
                                            contractData = contractData.replace("&lt;DestinationPortName&gt;", apiData.destinationPort)
                                            contractData = contractData.replace("&lt;DestinationPortCountry&gt;", apiData.destinationPortCountry)
                                            contractData = contractData.replace("&lt;DischargeRate&gt;", apiData.destinationPortRate)
                                            contractData = contractData.replace("&lt;ProductUOM&gt;", apiData.tradeProductDetail[0].productUOM.productUOMName)
                                            contractData = contractData.replace("&lt;TotalQuantity&gt;", apiData.tradeProductDetail[0].quantity)
                                            contractData = contractData.replace("&lt;CountryOfOrigin&gt;", apiData.loadPortCountry)
                                            contractData = contractData.replace("&lt;Division&gt;", divisiondata.divisionName)
                                            contractData = contractData.replace("&lt;ShipmentSize&gt;", apiData.shipmentSize)
                                            contractData = contractData.replace("&lt;ShipmentPeriodYear&gt;", apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.year)
                                            contractData = contractData.replace("&lt;QualityParameters&gt;", qualityparamhtml);
                                            contractData = contractData.replace("&lt;BuyingPrice&gt;", apiData.tradeProductDetail[0].productBuyingPrice);
                                            contractData = contractData.replace("&lt;TradeCurrency&gt;", 'USD');
                                            contractData = contractData.replace("&lt;TradeCurrencyLong&gt;", 'USD');
                                            contractData = contractData.replace("&lt;BuyingPriceInWords&gt;", apiData.tradeProductDetail[0].productBuyingPrice);
                                            contractData = contractData.replace("&lt;ProductUOMLong&gt;", apiData.tradeProductDetail[0].productUOM.productUOMName);
                                            contractData = contractData.replace("&lt;LoadPortName&gt;", apiData.loadPort);
                                            contractData = contractData.replace("&lt;LoadPortCountry&gt;", apiData.loadPortCountry);
                                            contractData = contractData.replace("&lt;SupplierName&gt;", apiData.supplier.supplierName)
                                            contractData = contractData.replace("&lt;SupplierStreet&gt;", apiData.supplier.registeredStreetName)
                                            contractData = contractData.replace("&lt;SupplierAddress&gt;", '')
                                            contractData = contractData.replace("&lt;SupplierState&gt;", '')
                                            contractData = contractData.replace("&lt;SupplierCountry&gt;", suppliercountry)
                                            contractData = contractData.replace("&lt;SupplierCity&gt;", apiData.supplier.registeredCityName != null ? apiData.supplier.registeredCityName != null : '')
                                            contractData = contractData.replace("&lt;SupplierPostBox&gt;", apiData.supplier.registeredPostBox != null ? apiData.supplier.registeredPostBox : '')

                                            if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 1)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'January')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 2)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'February')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 3)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'March')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 4)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'April')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 5)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'May')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 6)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'June')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 7)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'July')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 8)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'August')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 9)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'September')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 10)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'October')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 11)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'November')
                                            else if (apiData.tradeProductDetail[0].tradeEnquiryProductSupplyplan.month == 12)
                                                contractData = contractData.replace("&lt;ShipmentPeriodMonth&gt;", 'December')

                                            var totalShipmentSize = 1000
                                            var month = "January"
                                            let productDetail = apiData.tradeProductDetail
                                            var totalQuantity = 0
                                            var total_amount = 0
                                            productDetail.map((detail) => {
                                                totalQuantity += detail.quantity
                                                total_amount += detail.productSellingPrice
                                            })
                                            contractData = contractData.replace("&lt;TotalQuantity&gt;", totalQuantity + " ");
                                            contractData = contractData.replace("&lt;TotalShipmentSize&gt;", totalShipmentSize);
                                            contractData = contractData.replace("&lt;Month&gt;", month);
                                            contractData = contractData.replace("&lt;Amount&gt;", total_amount);
                                            contractData = contractData.replace("&lt;Amount&gt;", total_amount);
                                            list.contractContent = contractData
                                            return (
                                                <TabPanel>
                                                    <Tiny_editor data={list} saveSections={saveSections} isDraft={contractID ? data.is_Draft : true} />
                                                </TabPanel>
                                            )
                                        })}
                                    </Col>
                                </Tabs>
                            </div>
                        </div>
                    }
                </div>
            </div>


            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                <ModalBody toggle={toggle}>
                    <div className='service-request-detail-title mx-4 mt-4'>
                        <div className='d-flex justify-content-between'>
                            <div style={{ fontSize: '18px', fontWeight: '600' }}>{data.is_Draft ?
                                <>Draft Contract Version - {data.draftVersionNo != 0 ? data.draftVersionNo : 1}</>
                                :
                                <>Contract</>
                            }
                            </div>
                            <div>
                                <BlueBorderButton value='DOWNLOAD DOCUMENT' clickEvent={printDocument} className='ml-1-rem' />
                            </div>
                        </div>
                    </div>

                    <div id='download_section' className='mx-4'>
                        <center>
                            <div className='justify-content-center d-flex mt-3' style={{ fontSize: '14px', fontWeight: '400' }}>
                                {data.is_Draft ?
                                    <>Draft Contract Version - {data.draftVersionNo != 0 ? data.draftVersionNo : 1}</>
                                    :
                                    <div className='font-weight-500 font-18'>Contract {apiData.customerContractApprovalStatus == 1 && <> - Pending Approval</>}</div>
                                }
                            </div>
                        </center>
                        {isCustomer &&
                            template_list.map((list) => {
                                var contractData = list.contractContent;
                                contractData = contractData.replace("&lt;Customer Name&gt;", customerData.customerName);
                                contractData = contractData.replace("&lt;Customer Street&gt;", customerData.registeredStreetName);
                                contractData = contractData.replace("&lt;Customer City&gt;", customerData.registeredCityName);
                                contractData = contractData.replace("&lt;Customer Post Box&gt;", customerData.registeredPostBox);
                                contractData = contractData.replace("&lt;Customer Country&gt;", customerData.CustomerCountry);
                                contractData = contractData.replace("&lt;Customer State&gt;", 'Dubai');
                                contractData = contractData.replace("&lt;DestinationPortName&gt;", apiData.destinationPort);
                                contractData = contractData.replace("&lt;DestinationPortname&gt;", apiData.destinationPort);
                                contractData = contractData.replace("&lt;DestinationPort&gt;", apiData.destinationPort);
                                contractData = contractData.replace("&lt;LoadingPortName&gt;", apiData.loadPort);
                                contractData = contractData.replace("&lt;Name&gt;", apiData.loadPort);
                                contractData = contractData.replace("&lt;DestCountry&gt;", apiData.destinationPortCountry);
                                contractData = contractData.replace("&lt;CountryOfOriginAndType&gt;", apiData.destinationPortCountry);
                                contractData = contractData.replace("&lt;LoadCountry&gt;", apiData.loadPortCountry);
                                contractData = contractData.replace("&lt;ContractNumber&gt;", contractNumber);
                                contractData = contractData.replace("&lt;CustomerName&gt;", customerData.customerName);
                                contractData = contractData.replace("&lt;QualityParameters&gt;", qualityparamhtml);
                                var totalShipmentSize = 1000
                                var month = "January"
                                let productDetail = apiData.tradeProductDetail
                                var totalQuantity = 0
                                var total_amount = 0
                                productDetail.map((detail) => {
                                    totalQuantity += detail.quantity
                                    total_amount += detail.productSellingPrice
                                })
                                contractData = contractData.replace("&lt;TotalQuantity&gt;", totalQuantity + " ");
                                contractData = contractData.replace("&lt;TotalShipmentSize&gt;", totalShipmentSize);
                                contractData = contractData.replace("&lt;Month&gt;", month);
                                contractData = contractData.replace("&lt;Amount&gt;", total_amount);
                                contractData = contractData.replace("&lt;Amount&gt;", total_amount);
                                return (
                                    <Row>
                                        <Col md={4} className="my-3">
                                            {list.srNo}.&nbsp;{list.heading} :
                                        </Col>
                                        <Col md={8} className="my-3" >
                                            <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>

    )
}
