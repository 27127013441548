import React, { useState, useEffect } from 'react'
import { Row } from "react-bootstrap"
import { useFormik } from 'formik';
import * as FormFields from "../../../../../../../components/formcomponents";
import { BlueBorderButton, BlueButton, AddNewButton } from '../../../../../../../components/buttoncomponents';
import { AddQuality } from '../../../../../../../helpers/API/ProductApi';
export default function Quality_form(props) {
    
    console.log(props)
    const [error, setError] = useState(true)
    const [isError, setIsError] = useState(true);
    const [n, setn] = useState(false)
    useEffect(()=>{
        if(props.isEdit == true){
            setIsError(false)
        }
    },[])

    useEffect(()=>{
        console.log(isError)
    },[isError])

    const formik = useFormik({
        initialValues:{
            key : props.isEdit?props.data.key:0,
            tradeEnquiryProductDetailID : props.isEdit&&props.data.tradeEnquiryProductDetailID,
            tradeEnquiryProductQualityParametersID: props.isEdit&&props.data.tradeEnquiryProductQualityParametersID,
            productQualityParameterID: props.isEdit?props.data.productQualityParameterID:0,
            productQualityParameterName: props.isEdit?props.data.productQualityParameterName:'',
            typicalValueRange: props.isEdit?props.data.typicalValueRange:'',
            rejectionValueRange: props.isEdit?props.data.rejectionValueRange:''
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;
            console.log(values.productQualityParameterID)
            if(values.productQualityParameterID == 0){
                errors.productQualityParameterID = "Please select a quality parameter"
            }

            if (!values.typicalValueRange) {
                errors.typicalValueRange = "Please enter typical range"
                
            }
            

            if (!values.rejectionValueRange) {
                errors.rejectionValueRange = "Please enter rejection range"
                
            }
            
            // if(errors){
            //     setIsError(true)
            // }
            // else{
            //     setIsError(false)
            // }
            sendBack();
            return errors;
            console.log(errors)
        }
        
    });
   
    const sendBack = () => {
        console.log('asa')
        // if ((Object.keys(formik.errors).length == 0) ) {
        //     // console.log(isError)
        //     // if(isError == true){
        //     //     console.log(formik.errors)
        //     //     console.log('error')
        //     //     setIsError(true)
                
        //     // }
        //     // console.log('error checking')
           
        //     // if((formik.initialValues == formik.values) && (isError == false)){
        //     //     console.log(formik.errors)
        //     //     console.log('no error first condition')
        //     //     setIsError(false)
        //     //     //props.handleError()    
        //     // }
        //     // else if(props.isEdit == true){
        //     //     console.log('asassss')
        //     //     setIsError(false)
        //     // }
        //     // else if(formik.initialValues == formik.values){
        //     //     console.log(formik.errors)
        //     //     console.log('error')
        //     //     setIsError(true)
                
        //     // }
        //     // else{
        //     //     console.log(formik.errors)
        //     //     console.log('no error first condition')
        //     //     setIsError(false)
        //     //     //props.handleError()    
        //     // }
              
        //     if(formik.values.productQualityParameterID == 0 || formik.values.typicalValueRange == "" || formik.values.rejectionValueRange == ""){
        //         setIsError(true)
        //     }
        //     else{
        //         setIsError(false)
        //     }
        // }
        // else{
            
        //     console.log(formik.errors)
        //     console.log('error')
        //      setIsError(true)
        //     //props.setErrors()
        // }
        if(formik.values.productQualityParameterID == 0 || formik.values.typicalValueRange == "" || formik.values.rejectionValueRange == ""){
            console.log(formik.values.productQualityParameterID)
            console.log(formik.values.rejectionValueRange)
            console.log(formik.values.typicalValueRange)
            setIsError(true)
        }
        else{
            setIsError(false)
        }
    }
    const handleModalDataSubmission = ()=>{
        setError(false)
        props.handleModalDataSubmission(formik.values)
    }
    const handleModalDataUpdation = ()=>{
        setError(false)
        props.handleModalDataUpdation(formik.values)
    }
    const handleQuality = (e)=>{
        formik.values.productQualityParameterID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.productQualityParameterName = e.nativeEvent.target[index].text;
        setn(!n)
        sendBack()
    }

    return (
        <div className='d-flex mx-4'>
                                    <FormFields.InputSelect
                                        name='productQualityParameterID'
                                        label='Quality Parameter'
                                        classname='mr-20'
                                        type='text'
                                        optionField={<AddQuality id={parseInt(formik.values.productQualityParameterID)}/>}
                                        val={formik.values.productQualityParameterID}
                                        onChange={handleQuality}
                                        onBlur ={handleQuality}
                                        errors= {formik.touched.productQualityParameterID?formik.errors.productQualityParameterID:''}
                                    />
                                    <FormFields.InputField
                                        name='unit'
                                        label='Unit*'
                                        classname='mr-20'
                                        type='text'
                                        val='%'
                                        disabled={true}
                                    />
                                    <FormFields.InputField
                                        name='typicalValueRange'
                                        label='Typical*'
                                        classname='mr-20'
                                        type='text'
                                        val={formik.values.typicalValueRange}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errors= {formik.touched.typicalValueRange?formik.errors.typicalValueRange:''}
                                    />
                                    <FormFields.InputField
                                        name='rejectionValueRange'
                                        label='Rejection*'
                                        classname='mr-20'
                                        type='text'
                                        val={formik.values.rejectionValueRange}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errors= {formik.touched.rejectionValueRange?formik.errors.rejectionValueRange:''}
                                    />
                                    <div>
                                    <BlueBorderButton value = 'Save' clickEvent = {props.isEdit?handleModalDataUpdation:handleModalDataSubmission} disabled = {isError}/>
                                    </div>
                                </div>
    )
}
