import React from 'react'
import { Dropdown, Menu, Space } from 'antd';
import Moment from "moment";
import { BlueBorderButton } from '../../../components/buttoncomponents';
import ViewMeeting from './ViewMeeting';
import { useState } from 'react';
import * as CONFIG from "../../../helpers/config"
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import EditMeeting from './EditMeeting';
import EditTour from "./EditTour";
import { useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function List(props) {
    const MySwal = withReactContent(Swal)
    const { instance } = useMsal();
    const [viewstatuts, setviewstatus] = useState(false)
    const [editmee, seteditmee] = useState(false)
    const [edittou, setedittoui] = useState(false)
    const [selectedmeeting, setselectedmeeting] = useState([])
    useEffect(()=>{
        console.log(editmee)
    },[editmee])
    const edit = (e)=>{
        const id = parseInt(e.target.id)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setselectedmeeting(res.data.result[0])
            toggle()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })
    }

    const cancelMeeting = (e)=>{
        MySwal.fire(
            {
                icon: 'warning',
                html: 'Are you sure you wish to cancel the meeting?',
                type: 'success',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    const id = parseInt(e.target.id)
                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": id
                    }
                    axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CANCEL_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        props.getlist()
                    }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.clear();
                            instance.logoutRedirect({
                              postLogoutRedirectUri: "/",
                            });
                        }
                    })
                }
            })
    }

    const toggle =()=>{
        if(props.data.is_MeetingVal == 1){
            seteditmee(!editmee)
        }
        else{
            setedittoui(!edittou)
        }
        props.getlist()
    }
    const menu = (
        <Menu
          items={[
            // {
            //     key: '1',
            //     label: (
            //         <>{/*localStorage.getItem('Edit_Supplier') == 3 &&*/ <span className=' font-14' id={props.data.meetingID} onClick={edit}>Edit</span>}</>
            //     ),
            // },
            {
                key: '2',
                label: (
                    <>{/*localStorage.getItem('Edit_Supplier') == 3 &&*/ <span className=' font-14'  id={props.data.meetingID} onClick={cancelMeeting}>Cancel Meeting</span>}</>
                ),
            }
          ]}
        />
    );
    const viewMeeting = ()=>{
        const json_list ={
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.data.meetingID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            toggleviewstatus()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })
    }
    const toggleviewstatus = ()=>{
        setviewstatus(!viewstatuts)
    }
    return (
        <tr className='product_list_row align-items-center border-bottom-1 align-middle'>
            {viewstatuts &&
                <ViewMeeting toggle={toggleviewstatus} meetingid={props.data.meetingID}/>
            }
            {editmee &&
                <EditMeeting toggle={toggle} meeting={selectedmeeting}/>
            }
            {edittou &&
                <EditTour toggle={toggle} meeting={selectedmeeting}/>
            }
            <td>
            <div className='meeting_list_bold align-items-center d-flex py-4 '> {props.data.meetingCode}</div>
            </td>
            <td>
            <div className='meeting_list_normal align-items-center d-flex py-4 '> {props.data.meetingTitle}</div>
            </td>
            <td>
            <div className='meeting_list_bold align-items-center d-flex py-4 '> {props.data.is_MeetingVal == 1 ? props.data.meetingCategory: 'Tour'}</div>
            </td>
            <td>
            <div className='align-items-center py-4 '>{Moment(props.data.meetingTime).format('DD/MM/YYYY')}<br/>
                <span className='font-weight-600'>{Moment(props.data.meetingStartTime).format('h:mm')}</span>{Moment(props.data.meetingStartTime).format('a')}</div>   
            </td>
            <td>
            <div className='meeting_list_bold align-items-center d-flex py-4 '>{props.data.meetingAttendees.map((mt,k)=>{
                    return(
                        <div className='avatar d-flex align-items-center justify-content-center me-1' style={{padding: '0px', width: '40px', height: '40px'}}>
                            <p className='font-14 font-weight-500 mb-0'>{mt}</p>
                        </div>
                    )
                })}</div>
                
            </td>
            <td>
                <BlueBorderButton value='View' clickEvent={viewMeeting}/>
            </td>
            <td>
                <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <img src = '/assets/images/Dot_icon.svg' />
                        </Space>
                        </a>
                </Dropdown>
            </td>
        </tr>
    )
}
