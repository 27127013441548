import React,{useEffect, useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";

function FormComponent(props) {
    const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
    const formik = useFormik({
        initialValues:
        {
            fullName: '',
            designation: '',
            email: '',
            telephoneNo: '',
            mobileNo: '',
            contactTypeID: '',
        }

    })

    useEffect(()=>{
        setTimeout(() => {
            setDataSubmissionValidation(!dataSubmissionValidation)
            if(props.isdataavailable){
                if(props.customerContact.length>0){
                    formik.values.fullName= props.customerContact[0].fullName
                    formik.values.designation= props.customerContact[0].designation
                    formik.values.email= props.customerContact[0].email
                    formik.values.telephoneNo= props.customerContact[0].telephoneNo
                    formik.values.mobileNo= props.customerContact[0].mobileNo
                    formik.values.contactTypeID= props.customerContact[0].contactTypeID
                }
                else{
                    formik.values.fullName= ''
                    formik.values.designation= ''
                    formik.values.email= ''
                    formik.values.telephoneNo= ''
                    formik.values.mobileNo= ''
                    formik.values.contactTypeID= ''
                }
            }
        }, 500);
    })
    return (
        <>
                <Form>
                    <Row>
                        <FormFields.InputField
                            md={3}
                            name='fullName'
                            label='Full Name'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors= {formik.touched.fullName?formik.errors.fullName:''}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='designation'
                            label='Designation'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.designation}
                            onChange={formik.handleChange}
                            disabled
                            
                        />
                        <FormFields.InputField
                            md={3}
                            name='email'
                            label='Email'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.email}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='telephoneNo'
                            label='Mobile No'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.telephoneNo}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='mobileNo'
                            label='Telephone No'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.mobileNo}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Row>
                </Form>

        </>

    )
}

export default FormComponent