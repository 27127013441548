import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { Link, useLocation } from 'react-router-dom'
import * as CONFIG from "../../helpers/config"
import axios from 'axios'
import { useFormik } from 'formik'
import Moment from "moment"
import NumberFormat from 'react-number-format';
import * as FormFields from "../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import { Row, Col } from 'react-bootstrap'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useMsal } from "@azure/msal-react";
import { Modal, ModalBody } from "reactstrap"

export default function Actualcostsheet(props) {
    const { instance } = useMsal();
    const location = useLocation()
    const tradeDataForCostSheet = props.tradeDataForCostSheet
    const tab = props.tab
    const isview = props.isview
    const [tradeid, settradeid] = useState(); // replace 1 by props.id
    const [tradedata, settradedata] = useState([]); // replace 1 by props.id
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [costsheetdata, setcostsheetdata] = useState([])
    const [iscostsheetavailable, setisavailable] = useState(false)
    const [total, setTotal] = useState(0)
    const [buyingprice, setbuyingprice] = useState(0); // replace 1 by props.id
    const [sellingPrice, setSellingPrice] = useState(0); // replace 1 by props.id
    const [margin, setmargin] = useState(0)
    const [marginper, setmarginper] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [isprint, setIsprint] = useState(false)
    const [isApprovalSend, setIsApproval] = useState(false)
    const [approvalStatus, setApprovalStatus] = useState('')
    const [isActualCostAvailable, setIsActual] = useState(false)
    const [actualcostsheetdata, setactualcostsheetdata] = useState([])
    const [actualmargin, setactualmargin] = useState(0)
    const [atcualmarginper, setactualmarginper] = useState(0)
    const [actualtotal, setActualTotal] = useState(0)
    const [doc1, setdoc1] = useState(false)
    const [modal, setmodal] = useState(false)
    const [senddisabled, setsenddisabled] = useState(false)
    const [customerData, setCustomerData] = useState([])

    useEffect(() => {
        //props.id = 1; // get id from props (tradeid)
        console.log(tradeDataForCostSheet.tradeID, 'trading id');
        settradeid(tradeDataForCostSheet.tradeID)
        getTradeData(tradeDataForCostSheet.tradeID);
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeDataForCostSheet.tradeID // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerData(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const toggle = () => {
        setmodal(!modal)
    }
    useEffect(() => {
        if (tradedata.length > 0) {
            if (tradedata[0].tradedetails.costSheetApprovalStatus >= 0) {
                getActualCostSheetData(tradeDataForCostSheet.tradeID)
            }
        }
    }, [tradedata])

    useEffect(() => {
        console.log(sellingPrice)
    }, [sellingPrice])

    useEffect(() => {
        console.log(isDataAvailable)
        getcostsheetdata(tradeDataForCostSheet.tradeID)
    }, [isDataAvailable])

    const formik = useFormik({
        initialValues:
        {
            BuyingPrice: "",
            OceanFreight: "",
            FinancePrice: "",
            InsuranceCostCargo: "",
            InsuranceCostCL: "",
            InsuranceCostOthers: "",
            total: "",
            margin: "",
            marginper: "",
            ActualBuyingPrice: "",
            ActualOceanFreight: "",
            ActualFinancePrice: "",
            ActualInsuranceCostCargo: "",
            ActualInsuranceCostCL: "",
            ActualInsuranceCostOthers: "",
            ActualDispatchCost: "",
            ActualDemurrageCost: "",
            Remarks: "",
            actualtotal: "",
            actualmargin: "",
            actualmarginper: "",

        },
        validate: values => {
            let errors = {};

            const val = /^[0-9]+$/;

            //sendBack();
            return errors;
        }
    })

    const getcostsheetdata = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id), // tradeid,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setcostsheetdata(res.data.result[0])
                console.log(res.data.result[0])
                if (Object.keys(res.data.result[0]).length > 0) {
                    formik.values.FinancePrice = res.data.result[0].financeCost
                    formik.values.InsuranceCostCargo = res.data.result[0].insuranceCargoCost
                    formik.values.InsuranceCostCL = res.data.result[0].insuranceCLCost
                    formik.values.InsuranceCostOthers = res.data.result[0].insuranceOthersCost
                    setdoc1(!doc1)
                    let total = 0
                    if (isDataAvailable) {
                        let freight = tradedata[0].tradedetails.oceanFreight != null ? tradedata[0].tradedetails.oceanFreight : 0
                        total = parseFloat(formik.values.BuyingPrice) + parseFloat(freight) + parseFloat(formik.values.FinancePrice) + parseFloat(formik.values.InsuranceCostCargo) + parseFloat(formik.values.InsuranceCostCL) + parseFloat(formik.values.InsuranceCostOthers) + parseFloat(tradedata[0].tradedetails.dispatch) + parseFloat(tradedata[0].tradedetails.demurrage);


                    }

                    if (sellingPrice > 0) {
                        var margin = sellingPrice - total
                        var marginper = (margin / sellingPrice) * 100;
                    }
                    else {
                        margin = total
                        marginper = 100
                    }
                    formik.values.total = total
                    formik.values.margin = margin
                    formik.values.marginper = marginper
                    setdoc1(!doc1)
                    setTotal(total)
                    setmargin(margin);
                    setmarginper(marginper);
                    setisavailable(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getActualCostSheetData = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id), // tradeid,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_ACTUAL_COST_SHEET, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setactualcostsheetdata(res.data.result[0])
                console.log(res.data.result[0])
                if (res.data.result.length > 0) {
                    formik.values.ActualBuyingPrice = res.data.result[0].buyingPrice
                    formik.values.ActualOceanFreight = res.data.result[0].oceanFreight
                    formik.values.ActualFinancePrice = res.data.result[0].financeCost
                    formik.values.ActualInsuranceCostCargo = res.data.result[0].insuranceCargoCost
                    formik.values.ActualInsuranceCostCL = res.data.result[0].insuranceCLCost
                    formik.values.ActualInsuranceCostOthers = res.data.result[0].insuranceOthersCost
                    formik.values.ActualDispatchCost = res.data.result[0].dispatchCost
                    formik.values.ActualDemurrageCost = res.data.result[0].dummurageCost
                    setdoc1(!doc1)
                    let total = 0
                    total = parseFloat(formik.values.ActualBuyingPrice) + parseFloat(formik.values.ActualDispatchCost) + parseFloat(formik.values.ActualDemurrageCost) + parseFloat(formik.values.ActualOceanFreight) + parseFloat(formik.values.ActualFinancePrice) + parseFloat(formik.values.ActualInsuranceCostCargo) + parseFloat(formik.values.ActualInsuranceCostCL) + parseFloat(formik.values.ActualInsuranceCostOthers)
                        ;
                    console.log(sellingPrice)
                    if (sellingPrice > 0) {

                        var margin = sellingPrice - total
                        var marginper = (margin / sellingPrice) * 100;
                    }
                    else {
                        margin = total
                        marginper = 100
                    }
                    formik.values.actualtotal = total
                    formik.values.actualmargin = margin
                    formik.values.actualmarginper = marginper
                    console.log(total)
                    setdoc1(!doc1)
                    setActualTotal(total)
                    setactualmargin(margin);
                    setactualmarginper(marginper);
                    setIsActual(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getTradeData = (id) => {
        console.log('inside', tradeDataForCostSheet.tradeID)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                let total1 = 0, total2 = 0
                res.data.result.map((list, k) => {
                    list.tradedetails.tradeProductDetail.map((pro, p) => {
                        total1 = total1 + (pro.productBuyingPrice * pro.quantity)
                        total2 = total2 + (pro.productSellingPrice * pro.quantity)
                    })
                })
                console.log(total1, 'buying price');
                formik.values.BuyingPrice = total1;
                setbuyingprice(total1);
                setSellingPrice(total2)

                if (res.data.result[0].tradedetails.actualCostSheetWorkFlowID != null) {
                    setApprovalStatus(res.data.result[0].tradedetails.actualCostSheetApprovalStatus)
                    setIsApproval(true)
                }
                settradedata(res.data.result);
                setIsDataAvailable(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        let total = 0
        if (!isActualCostAvailable && isDataAvailable) {
            total = parseFloat(formik.values.ActualBuyingPrice) + parseFloat(formik.values.ActualDispatchCost) + parseFloat(formik.values.ActualDemurrageCost) + parseFloat(formik.values.ActualOceanFreight) + parseFloat(formik.values.ActualFinancePrice) + parseFloat(formik.values.ActualInsuranceCostCargo) + parseFloat(formik.values.ActualInsuranceCostCL) + parseFloat(formik.values.ActualInsuranceCostOthers);

            if (sellingPrice > 0) {

                var margin = sellingPrice - total
                var marginper = (margin / sellingPrice) * 100;
            }
            else {
                margin = total
                marginper = 100
            }
            formik.values.actualtotal = total
            formik.values.actualmargin = margin
            formik.values.actualmarginper = marginper
            setActualTotal(total)
            setactualmargin(margin);
            setactualmarginper(marginper);
        }
    }, [formik.values])

    const savecostsheet = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "tradeid": parseInt(tradeid), // tradeid,
            "addressCommission": 0,
            "buyingPrice": parseFloat(formik.values.ActualBuyingPrice),
            "oceanFreight": parseFloat(formik.values.ActualOceanFreight),
            "financeCost": parseFloat(formik.values.ActualFinancePrice),
            "insuranceCargoCost": parseFloat(formik.values.ActualInsuranceCostCargo),
            "insuranceCLCost": parseFloat(formik.values.ActualInsuranceCostCL),
            "insuranceOthersCost": parseFloat(formik.values.ActualInsuranceCostOthers),
            "dispatchCost": parseFloat(formik.values.ActualDispatchCost),
            "dummurageCost": parseFloat(formik.values.ActualDemurrageCost)
        }
        if (isEdit) {
            json_list.tradeActualCostSheetID = actualcostsheetdata.tradeActualCostSheetID
            json_list.modified_User = CONFIG.LoginID
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_ACTUAL_COST_SHEET, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    console.log(res.data.result, "Trade data")
                    getActualCostSheetData(tradeDataForCostSheet.tradeID)
                    getActualCostSheetData(tradeDataForCostSheet.tradeID)
                    setIsEdit(false)
                })
        }
        else {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_ACTUAL_COST_SHEET, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    getcostsheetdata(tradeDataForCostSheet.tradeID)
                    getActualCostSheetData(tradeDataForCostSheet.tradeID)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const openEdit = () => {
        setIsActual(false)
        setIsEdit(true)
    }

    const printDocument = () => {
        setIsprint(true)
        setTimeout(() => {
            const domElement = document.getElementById("download_section");
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm');
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    doc.save('ActualCostSheet.pdf');
                    setIsprint(false)
                })
        }, 300);

    }

    const sendForApproval = () => {
        setsenddisabled(true)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeDataForCostSheet.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ACTUAL_COSTSHEET_SEND_FOR_APPROVAL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                getTradeData(tradeDataForCostSheet.tradeID)
                props.refreshList()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                <ModalBody toggle={toggle}>
                    <div className='service-request-detail-title mx-4 mt-4'>
                        <div className='d-flex justify-content-end'>
                            <div>
                                <BlueBorderButton value='DOWNLOAD DOCUMENT' clickEvent={printDocument} className='ml-1-rem' />
                            </div>
                        </div>
                    </div>
                    <div className='table-responsive-sm card-body mx-3' id="download_section">

                        <div className=' br-8 py-3 d-flex justify-content-between align-items-center'>

                            <div>
                                <img src="/assets/images/logo.svg" className='img-fluid' />
                            </div>

                            <div className='d-flex flex-column'>

                                <div className='font-16 tradeTrsactionsHeading d-flex  mt-5'><b>ACTUAL COST SHEET</b></div>

                                <div className='mt-1'>
                                    Issue Date: <span className='tradeTrsactionsHeading'> {Moment(new Date()).format("DD/MM/yyyy")}  </span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 border-bottom-1 pb-5'>
                            <Col md={9} className=' d-flex justify-content-between'>
                                {tradedata.map((list, k) => (
                                    <>
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-600 '>{list.enquiryNo}</div>
                                            <div className='font-weight-400 LabelText'>{Moment(list.tradeDate).format("DD/MM/yyyy")} </div>
                                        </div>
                                        {customerData.map((data, k) => {
                                            return (
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                    <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                </div>
                                            )
                                        })}
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-600 '>{list.tradedetails.supplier.supplierCode}</div>
                                            <div className='font-weight-400 LabelText'>{list.tradedetails.supplier.supplierName}</div>
                                            {list.tradedetails.tradeSupplierPaymentDetail.map((pay, j) => (
                                                <div className='font-weight-500 table-ttile-color-sub font-12'>
                                                    {pay.paymentType.typeName}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-400 LabelText'>{list.tradedetails.tradeEnquiry.contractType.contractTypeName} <span className='font-weight-600'>{list.tradedetails.incoterms!=null && list.tradedetails.incoterms.incotermsName} {list.tradedetails.modeOfTranspor!= null && list.tradedetails.modeOfTransport.modeOfTransportName} </span> </div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-400 LabelText '>Laycan</div>
                                            <div className='font-weight-600 '>{Moment(list.tradedetails.fromLaycanPeriod).format("DD/MM/yyyy")} - {Moment(list.tradedetails.toLaycanPeriod).format("DD/MM/yyyy")} </div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div className='font-weight-400 LabelText '>Lord Port</div>
                                            <div className='font-weight-600 '>{list.tradedetails.loadPort} {list.tradedetails.loadPortCountry}</div>
                                        </div>
                                    </>

                                ))}
                            </Col>
                        </div>



                        <table className='table tCustom table-striped table-text mt-1-rem  mt-5
                         ' >
                            <thead>
                                <tr>
                                    <th> <div className='font-weight-600 ps-5'>SL No </div></th>
                                    <th><div className='font-weight-600'>Description</div></th>
                                    <th><div className='font-weight-600'>Estimated Amount</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 col-md-2'>1</div></td>
                                    <td>Buying Price (FOB)</td>
                                    <td>
                                        <NumberFormat value={formik.values.BuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' disabled />
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualBuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualBuyingPrice'
                                                val={formik.values.ActualBuyingPrice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>2</div></td>
                                    <td>Ocean Freight</td>
                                    <td>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.oceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualOceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualOceanFreight'
                                                val={formik.values.ActualOceanFreight}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>3</div></td>
                                    <td>Finance Cost</td>
                                    <td>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.FinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='FinancePrice'
                                                type='number'
                                                val={formik.values.FinancePrice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualFinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualFinancePrice'
                                                val={formik.values.ActualFinancePrice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>4</div></td>
                                    <td>Insurance Cost(Cargo)</td>
                                    <td>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostCargo'
                                                type='number'
                                                val={formik.values.InsuranceCostCargo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualInsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualInsuranceCostCargo'
                                                val={formik.values.ActualInsuranceCostCargo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>5</div></td>
                                    <td>Insurance Cost(CL)</td>
                                    <td>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostCL'
                                                type='number'
                                                val={formik.values.InsuranceCostCL}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualInsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualInsuranceCostCL'
                                                val={formik.values.ActualInsuranceCostCL}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5'>6</div></td>
                                    <td>Insurance Cost(Others)</td>
                                    <td>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostOthers'
                                                type='number'
                                                val={formik.values.InsuranceCostOthers}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualInsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualInsuranceCostOthers'
                                                val={formik.values.ActualInsuranceCostOthers}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>7</div></td>
                                    <td>Despatch</td>
                                    <td>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.dispatch} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualDispatchCost} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualDispatchCost'
                                                val={formik.values.ActualDispatchCost}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 '>8</div></td>
                                    <td>Demmurage</td>
                                    <td>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.demurrage} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                    <td>
                                        {isActualCostAvailable ?
                                            <NumberFormat value={formik.values.ActualDemurrageCost} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                type='number'
                                                name='ActualDemurrageCost'
                                                val={formik.values.ActualDemurrageCost}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div className='d-flex'>Estimated Total Amount : &nbsp;
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={formik.values.total ? formik.values.total : 0} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>Selling Price : &nbsp;
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            Margin : &nbsp;
                                            {formik.values.margin}
                                        </div>
                                        <div className='font-weight-600 color-243448'>Margin % (Margin/Selling Price*100) :  &nbsp;
                                            {parseFloat(formik.values.marginper).toFixed(2)}%
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex'>Actual Total Amount : &nbsp;
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={parseFloat(formik.values.actualtotal)} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>Selling Price : &nbsp;
                                            <div className='font-weight-600 color-243448'>
                                                <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            Margin : &nbsp;<NumberFormat value={parseFloat(formik.values.actualmargin).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        </div>
                                        <div className='font-weight-600 color-243448'>Margin % (Margin/Selling Price*100) :  &nbsp;{parseFloat(formik.values.actualmarginper).toFixed(2)}%</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <div className='main-wrapper inner-wrapper d-flex' style={{ overflowY: 'scroll' }}>
                <div className='w-100' style={{ overflowY: 'scroll' }}>
                    <div className='d-flex inner-page-content justify-content-between' style={{ overflowY: 'scroll' }}>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/pageicons/Dashboard.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>ACTUAL COST SHEET</span>
                        </div>
                        <div className='d-flex '>
                            <BlueBorderButton class='ms-2' value='EXPORT COST SHEET' clickEvent={toggle} />
                            {iscostsheetavailable &&
                                <>
                                    {!isApprovalSend &&
                                        <BlueButton class="ms-2" value="Send For Approval" clickEvent={sendForApproval} disabled={senddisabled} />
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className='inner-page-content' >
                        <div className='add-edit-wrapper card  br-8'>
                            <div id="download_section">
                                <div className='d-flex justify-content-between align-items-center mt-4 mx-3'>
                                    <span className='font-weight-500 font-14 color-707895'>Actual Cost Update</span>
                                    <div>
                                        {
                                            isApprovalSend &&
                                            <>
                                                {
                                                    approvalStatus == 1 ?
                                                        <span className='approval_in_process'>Cost Sheet Approval - in Process</span>

                                                        :
                                                        <span className='approved'>Cost Sheet Approved</span>
                                                }
                                            </>
                                        }
                                        {!isApprovalSend && <span><img src="assets/images/editButton.svg" className='img-fluid pointer' onClick={openEdit} /></span>}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between mt-4 mx-3 br-8 p-3-rem product_data_quality' style={{ overflowY: 'scroll' }}>
                                    {tradedata.map((list, k) => (
                                        <>
                                            <div className='d-flex flex-column'>
                                                <div className='font-weight-600 '>{list.enquiryNo}</div>
                                                <div className='font-weight-400 LabelText'>{Moment(list.tradeDate).format("DD/MM/yyyy")} </div>
                                            </div>
                                            {customerData.map((data, k) => {
                                                return (
                                                    <div className='d-flex flex-column'>
                                                        <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                        <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                    </div>
                                                )
                                            })}
                                            <div className='d-flex flex-column'>
                                                <div className='font-weight-600 '>{list.tradedetails.supplier.supplierCode}</div>
                                                <div className='font-weight-400 LabelText'>{list.tradedetails.supplier.supplierName}</div>
                                                {list.tradedetails.tradeSupplierPaymentDetail.map((pay, j) => (
                                                    <div className='font-weight-500 table-ttile-color-sub font-12'>
                                                        {pay.paymentType.typeName}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <div className='font-weight-400 LabelText'>{list.tradedetails.tradeEnquiry.contractType.contractTypeName} <span className='font-weight-600'>{list.tradedetails.incoterms!= null && list.tradedetails.incoterms.incotermsName} {list.tradedetails.modeOfTransport!= null && list.tradedetails.modeOfTransport.modeOfTransportName} </span> </div>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <div className='font-weight-400 LabelText '>Laycan</div>
                                                <div className='font-weight-600 '>{Moment(list.tradedetails.fromLaycanPeriod).format("DD/MM/yyyy")} - {Moment(list.tradedetails.toLaycanPeriod).format("DD/MM/yyyy")} </div>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <div className='font-weight-400 LabelText '>Lord Port</div>
                                                <div className='font-weight-600 '>{list.tradedetails.loadPort} {list.tradedetails.loadPortCountry}</div>
                                            </div>
                                        </>

                                    ))
                                    }
                                </div>
                                <div className=' card-body scroll-custom'>
                                    <table className='table-responsive-sm table tCustom table-striped table-text mt-1-rem  mt-4' >
                                        <thead>
                                            <tr>
                                                <th> <div className='font-weight-600 ps-5'>SL No </div></th>
                                                <th><div className='font-weight-600'>Description</div></th>
                                                <th><div className='font-weight-600'>Estimated Cost</div></th>
                                                <th><div className='font-weight-600'>Actual Cost</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5 col-md-2'>1</div></td>
                                                <td>Buying Price (FOB)</td>
                                                <td>
                                                    <NumberFormat value={formik.values.BuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' disabled />
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualBuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualBuyingPrice'
                                                            val={formik.values.ActualBuyingPrice}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5 '>2</div></td>
                                                <td>Ocean Freight</td>
                                                <td>
                                                    {isDataAvailable &&
                                                        <NumberFormat value={tradedata[0].tradedetails.oceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualOceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualOceanFreight'
                                                            val={formik.values.ActualOceanFreight}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5'>3</div></td>
                                                <td>Finance Cost</td>
                                                <td>
                                                    {iscostsheetavailable ?
                                                        <NumberFormat value={formik.values.FinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='FinancePrice'
                                                            type='number'
                                                            val={formik.values.FinancePrice}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualFinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualFinancePrice'
                                                            val={formik.values.ActualFinancePrice}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5'>4</div></td>
                                                <td>Insurance Cost(Cargo)</td>
                                                <td>
                                                    {iscostsheetavailable ?
                                                        <NumberFormat value={formik.values.InsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='InsuranceCostCargo'
                                                            type='number'
                                                            val={formik.values.InsuranceCostCargo}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualInsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualInsuranceCostCargo'
                                                            val={formik.values.ActualInsuranceCostCargo}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5'>5</div></td>
                                                <td>Insurance Cost(CL)</td>
                                                <td>
                                                    {iscostsheetavailable ?
                                                        <NumberFormat value={formik.values.InsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='InsuranceCostCL'
                                                            type='number'
                                                            val={formik.values.InsuranceCostCL}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualInsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualInsuranceCostCL'
                                                            val={formik.values.ActualInsuranceCostCL}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5'>6</div></td>
                                                <td>Insurance Cost(Others)</td>
                                                <td>
                                                    {iscostsheetavailable ?
                                                        <NumberFormat value={formik.values.InsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            name='InsuranceCostOthers'
                                                            type='number'
                                                            val={formik.values.InsuranceCostOthers}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualInsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualInsuranceCostOthers'
                                                            val={formik.values.ActualInsuranceCostOthers}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5 '>7</div></td>
                                                <td>Despatch</td>
                                                <td>
                                                    {isDataAvailable &&
                                                        <NumberFormat value={tradedata[0].tradedetails.dispatch} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualDispatchCost} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualDispatchCost'
                                                            val={formik.values.ActualDispatchCost}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr className=' d-felx'>
                                                <td><div className='ps-5 '>8</div></td>
                                                <td>Demmurage</td>
                                                <td>
                                                    {isDataAvailable &&
                                                        <NumberFormat value={tradedata[0].tradedetails.demurrage} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    }
                                                </td>
                                                <td>
                                                    {isActualCostAvailable ?
                                                        <NumberFormat value={formik.values.ActualDemurrageCost} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        :
                                                        <FormFields.InputField
                                                            md={4}
                                                            type='number'
                                                            name='ActualDemurrageCost'
                                                            val={formik.values.ActualDemurrageCost}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <div className='d-flex'>Estimated Total Amount : &nbsp;
                                                        <div className='font-weight-600 color-243448'>
                                                            <NumberFormat value={formik.values.total ? formik.values.total : 0} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>Selling Price : &nbsp;
                                                        <div className='font-weight-600 color-243448'>
                                                            <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        Margin : &nbsp;
                                                        {formik.values.margin}
                                                    </div>
                                                    <div className='font-weight-600 color-243448'>Margin % (Margin/Selling Price*100) :  &nbsp;
                                                        {parseFloat(formik.values.marginper).toFixed(2)}%
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex'>Actual Total Amount : &nbsp;
                                                        <div className='font-weight-600 color-243448'>
                                                            <NumberFormat value={parseFloat(formik.values.actualtotal)} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>Selling Price : &nbsp;
                                                        <div className='font-weight-600 color-243448'>
                                                            <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        Margin : &nbsp;<NumberFormat value={parseFloat(formik.values.actualmargin).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    </div>
                                                    <div className='font-weight-600 color-243448'>Margin % (Margin/Selling Price*100) :  &nbsp;{
                                                        formik.values.actualmarginper != "" ?
                                                            parseFloat(formik.values.actualmarginper).toFixed(2)
                                                            : 0
                                                    }%</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <Row>
                                    <div className='form-card-footers p-2-rem d-flex' style={{ position: 'relative' }}>
                                        <span className='pl-10'>
                                            <BlueButton value='Save' clickEvent={savecostsheet} disabled={isActualCostAvailable ? true : false} />
                                        </span>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
