import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { AddNewButton } from '../../../components/buttoncomponents'
import Informations_form from '../common/informations_form'
import Listing from '../common/listing'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config"
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    useEffect(()=>{
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
    })
    const [isFirst, setIsFirst] = useState(true);
    const [isAddGrade, setIsAddGrade] = useState(false);
    const [productGrade, setProductGrade] = useState([]);
    const [length, setLength] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState([]);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState('');
    const addGrade = ()=>{
        setIsAddGrade(!isAddGrade);
    }
    
    useEffect(()=>{
       if(isFirst){
            getlist()
       }
       
       setIsFirst(false)
    })

    const getlist = ()=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setProductGrade(res.data.result);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const saveCategory = (data)=>{
        console.log(data)
        const json_list = isEdit?{
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "Modified_User" : CONFIG.LoginID,
            "productGradeID": data.id,
            "productGradeName": data.data
        }
        :
        {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "productGradeName" : data.data,
            "created_User" : CONFIG.LoginID
        }
       if(!isEdit){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if(res.data.responseCode == '303002'){
                    setError(true)
                    setErrorData('Product grade already exists');
                }
                else if(res.data.responseCode == '303001'){
                    setError(true)
                    setErrorData('Product grade invalid. Please try again!');
                }
                else{
                    setError(false)
                }
                setIsEdit(false)
                getlist()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
       }
       else{
        console.log('inside');
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.EDIT_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            if(res.data.responseCode == '303002'){
                setError(true)
                setErrorData('Product grade already exists');
            }
            else if(res.data.responseCode == '303001'){
                setError(true)
                setErrorData('Product grade invalid. Please try again!');
            }
            else{
                setError(false)
            }
            setIsEdit(false)
            getlist()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
       }
    }

    const edit = (e)=>{
        const id = e.target.id;
        let data = productGrade.filter(x=> x.productGradeID == id);
        setSelectedData(data);
        setIsEdit(true)
        addGrade();
    }
    const deletedata = (e)=>{
        const id = parseInt(e.target.id);
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.DELETE_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getlist()
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
                
                <Sidebar id='4.5' isOpenid={4}/>
                <div className='width-100'>
                    <Navbar/>
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/pageicons/productsicon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>PRODUCT GRADE</span>
                        </div>
                    </div>
                    <div className='inner-page-content'>
                        <div className='add-edit-wrapper'>
                                <Card className='p-2-rem col-md-7'>
                                    <div className='d-flex justify-content-end'>
                                        <AddNewButton value='Add New Grade' class='bg-white' clickEvent={addGrade}/>
                                    </div>
                                    {isAddGrade && 
                                        <Informations_form 
                                            isOpen={isAddGrade} 
                                            title={isEdit?'EDIT GRADE':'ADD NEW PRODCUT GRADE'} 
                                            buttontext ={isEdit?'Edit Grade':'Add Grade'}
                                            inputLabel = 'Product Grade'
                                            onSave={saveCategory}
                                            toggle={addGrade}
                                            id={isEdit?selectedData[0].productGradeID:''}
                                            name={isEdit?selectedData[0].productGradeName:''}
                                            isEdit={isEdit}
                                        />
                                    }
                                    {error &&
                                        <div className='input_error'>{errorData}</div>
                                    }
                                    <table className="table table-text mt-1-rem">
                                        <thead>
                                            <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                <th>Product Grade</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productGrade.map((cat,k)=>(
                                                <Listing key={k} id={cat.productGradeID} name={cat.productGradeName} length={length} edit={edit} delete={deletedata}/>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card>
                        </div>
                    </div>
                </div>
                

        </div>
    )
}
