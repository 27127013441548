import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import * as ButtonComponent from "../../../components/buttoncomponents";
import { BackButton,} from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import GenerateCustomerProformaInvoice from '../ContractFinalized/GenerateCustomerProformaInvoice'
import ViewInvoice from '../../Invoices/CustomerPerformaInvoice/ViewInvoice';


function CustomerProformaInvoiceList(props) {
    const { instance } = useMsal();
    const [customerProformaInvoiceData, setPurchaseOrder] = useState()
    const [tradeData, setTradeData] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [showView, setShowView] = useState(false)
    const [tradeID, setTradeID] = useState()
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [selectedDetails, setSelectedDetail] = useState([])
    let navigate = useNavigate();

    useEffect(()=> {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
               console.log(res.data.result)
               setPurchaseOrder(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    },[])
    
    const back = () => {
        navigate('/trade-transactions', { state:{ tab: 1,}});
    }
    const handleClose = () => {
        setShowView(false)
    }
    const handleSelectTrade = (e, values) => {
        console.log(values)
        console.log(e.target.value)
        for(let i = 0; i < customerProformaInvoiceData.length; i++){
            if(customerProformaInvoiceData[i].tradeId == values.tradeId){

            }
        } 
        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(values.tradeId), //tradeid,
            "customerID" : parseInt(values.customerID),
            "companyID" : parseInt(values.companyID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PROFORMA_INVOICE_DETAILS, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedDetail(res.data.result[0])
                openInvoice()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        
    }
    const openInvoice = () => {
        console.log('inside')
        setIsInvoiceOpen(!isInvoiceOpen)
    }
    useEffect(()=>{

    },[customerProformaInvoiceData])
  return (
    <>
     <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='7.1' isOpenid={7} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between mx-2'>
                    <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-5'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>CUSTOMER PROFORMA INVOICES</span>
                        </div>
                    </div>
                </div>
                {showView == true ? 
                <GenerateCustomerProformaInvoice handleClose = {handleClose} invoiceList = {true} data = {tradeData} tradeID = {tradeID} tab={1} test={'asasas'}/>
                :
                <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0'>
                    <Card className=''>
                        <CardBody>
                            <div class="col-lg-12 list-scroll">
                                <div class="table-responsive-sm ">
                                    <table className="table tCustom table-text">
                                        <thead className='p-1-rem'>
                                            <tr className='text-uppercase list-top'>
                                                <th>customer proforma invoice#</th>
                                                <th>invoice date</th>
                                                <th>trade#</th>
                                                <th>customer</th>
                                                <th>document</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {isInvoiceOpen &&
                                                <ViewInvoice isOpen={isInvoiceOpen} toggle={openInvoice} data={selectedDetails != null ? selectedDetails : ''}  customerProformaInvoiceData = {customerProformaInvoiceData != null ? customerProformaInvoiceData : ''} />
                                              } 
                                        {customerProformaInvoiceData != null && customerProformaInvoiceData.length > 0?
                                            customerProformaInvoiceData.map((values,k) =>(
                                            <tr>
                                           
                                            <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.customerProformaInvoiceNo}</p></td>
                                            <td className='font-weight-500 font-14'><p className='w-150 mb-0'>{Moment(values.customerProformaInvoiceDate).format("DD/MM/YYYY")}</p></td>
                                            <td className='font-weight-500 font-14'><p className='w-150 mb-0'>{values.tradeNo}</p></td>
                                                <td  className='w-150'>
                                                    <div className = 'd-flex flex-column'>
                                                        <div>
                                                            {values.customerCode}
                                                        </div>
                                                        <div>
                                                            {values.customerName}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a download={values.customerProformaInvoiceDocumentName} href={values.customerProformaInvoiceDocument}>{values.customerProformaInvoiceDocumentName}</a>
                                                </td>
                                                <td>
                                                    <Button size="md" onClick={(e)=>{handleSelectTrade(e, values)}}>
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        )) 
                                        :
                                        ''
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                }
            </div>


    </div>
    </>
   
  )
}

export default CustomerProformaInvoiceList