import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row } from "react-bootstrap"
import { useFormik } from 'formik';
import * as FormFields from "../../../../../../components/formcomponents";
import { BlueBorderButton, BlueButton, AddNewButton } from '../../../../../../components/buttoncomponents';
import Quality_list from './quality_list';
import Quality_form from './Quality_form';
import * as CONFIG from  "../../../../../../helpers/config"

import { useMsal } from "@azure/msal-react";

function AddQualityParameterModal(props) {
    console.log(props.value)
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [showList, setShowList] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [list, setList] = useState([])
    const [key, setkey] = useState(1)
    const [isEdit, setIsEdit] = useState(false)
    const [selected, setselected] = useState([])
    const [tradeEnquiryProductDetail, setTEP] = useState([]);
    const [isFirst, setIsFirst] = useState(true)
    const toggle = () => {
      setModal(!modal)
      props.close();
    }
    useEffect(()=>{
        if(props.tradeInitiateEdit == true ){
            console.log('check')
            props.value.tradeProductQualityParameters.map((values,k)=>(
                values.key = k+1
            ))
            setList(props.value.tradeProductQualityParameters)
            setShowList(true)
        }   
    },[])

    useEffect(()=>{
        if(props.isQuality){
            let data =[]
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_QUALITY, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                data = res.data.result
                let list_ql = []
                console.log(data)
                props.qualityData.map((qul,k)=>{
                    list_ql[k] = data.filter(x=> x.productQualityParameterID == qul.productQualityParameterID)[0]
                })
                console.log(list_ql)
                let ini_list =[]
                let ke = 0
                props.qualityData.map((q,k)=>{
                    if(props.isEdit){
                        ini_list[k] ={
                            key : k,
                            tradeEnquiryProductDetailID: q.tradeEnquiryProductDetailID,
                            tradeEnquiryProductQualityParametersID: q.tradeEnquiryProductQualityParametersID,
                            productQualityParameterID: q.productQualityParameterID,
                            productQualityParameterName: list_ql[k].productQualityParameterName,
                            typicalValueRange: q.typicalValueRange,
                            rejectionValueRange: q.rejectionValueRange
                        }
                    }
                    else{
                        ini_list[k] ={
                            key : k,
                            productQualityParameterID: q.productQualityParameterID,
                            productQualityParameterName: list_ql[k].productQualityParameterName,
                            typicalValueRange: q.typicalValueRange,
                            rejectionValueRange: q.rejectionValueRange
                        }
                    }
                        
                    ke =k
                })
                setkey(ke)
                if(isFirst){
                    setList(ini_list)
                    console.log(ini_list, 'trade enuriy data')
                    final_json["tradeEnquiryProductQualityParameters"] = ini_list
                    setTEP(final_json)
                    addform()
                    setShowSubmit(true)
                }
                
                setIsFirst(false)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    },[isFirst])



    useEffect(()=>{
        console.log(tradeEnquiryProductDetail)
    },[tradeEnquiryProductDetail])

    useEffect(()=>{
        console.log(list)
    },[list])

    const final_json ={
        
    }


    const handleModalDataSubmission = (data) => {
        let lists = [...list]
        let k = key;
        k++
        data.key = k
        lists.push(data)
        setList(lists)
        setkey(k)
        
        let li = []
        lists.map((lis,l)=>{
            li[l]={
                "productQualityParameterID": parseInt(lis.productQualityParameterID),
                "typicalValueRange": lis.typicalValueRange,
                "rejectionValueRange": lis.rejectionValueRange
            }
            if(lis.tradeEnquiryProductDetailID){
                li[l].tradeEnquiryProductDetailID = lis.tradeEnquiryProductDetailID
            }
            if(lis.tradeEnquiryProductQualityParametersID){
                li[l].tradeEnquiryProductQualityParametersID = lis.tradeEnquiryProductQualityParametersID
            }
        })
        console.log(li)
        final_json["tradeEnquiryProductQualityParameters"] = li
        setTEP(final_json)
        console.log(tradeEnquiryProductDetail, 'final data')
        addform()
        setShowSubmit(true)
    }

    const handleModalDataUpdation = (data)=>{
        console.log(data)
        let lis  = [...list]
        list.map((li,k)=>{
            if(li.key == data.key){
                lis[k] = data
            }
        })
        setList(lis)
        let li = []
        lis.map((lis,k)=>{
            li[k]= {
                "productQualityParameterID": parseInt(lis.productQualityParameterID),
                "typicalValueRange": lis.typicalValueRange,
                "rejectionValueRange":lis.rejectionValueRange
            }
            if(lis.tradeEnquiryProductDetailID){
                li[k].tradeEnquiryProductDetailID = lis.tradeEnquiryProductDetailID
            }
            if(lis.tradeEnquiryProductQualityParametersID){
                li[k].tradeEnquiryProductQualityParametersID = lis.tradeEnquiryProductQualityParametersID
            }
        })
        final_json["tradeEnquiryProductQualityParameters"] = li
        setTEP(final_json)
        addform()
        setShowSubmit(true)
        isEditToggle()
    }

    const addform = ()=>{
        console.log('show list')
        setShowList(!showList)
    }
    const editList = (e)=>{
        const id = e.currentTarget.id
        let lis = list.filter(x=> x.key == parseInt(id));
        setselected(lis[0])
        isEditToggle()
        addform()
    }
    const isEditToggle = ()=>{
        setIsEdit(!isEdit)
    }
    const remove = (e)=>{
        const id = e.currentTarget.id
        console.log(id)
        console.log(list)
        let lis = list.filter(x=> x.key != parseInt(id))
        console.log(lis)
        setList(lis)
        let li = []
        lis.map((lis,k)=>{
            li[k]={
                "productQualityParameterID": parseInt(lis.productQualityParameterID),
                "typicalValueRange": lis.typicalValueRange,
                "rejectionValueRange": lis.rejectionValueRange
            }
        })
        final_json["tradeEnquiryProductQualityParameters"] = li
        setTEP(final_json)
        if(list.length == 0){
            addform()
        }
        setShowSubmit(true)
    }
    const saveQuality = ()=>{
     props.saveQuality(tradeEnquiryProductDetail)   
    }
    return (
    <>
        <div className = ''>
        <Modal isOpen={modal} toggle={toggle} className = 'modal-dialog-custom-width trade_existing_customer'>
                        <ModalHeader className='p-md-40 p-3' toggle={toggle}>
                            <div className = 'd-flex justify-content-between'>
                                <h4 className='font-16 font-weight-600 color-707895'>Quality Parameters</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody  className = 'service-request-modal-wrapper'>
                            <>
                                <div className='p-md-4 p-3 bg-light mx-md-4 br-8 mb-3'>
                                    <div className = 'row'>
                                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Code
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                                {props.value.productCode}
                                            </div>
                                        </div>
                                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Name
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productName}
                                            </div>
                                        </div>
                                        <div className = 'col-12 col-md-3 mb-3 mb-md-0'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Group
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productGroupValue}
                                            </div>
                                        </div>
                                        <div className = 'col-12 col-md-3 mb-3 mb-md-0   '>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product UOM
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productUOMValue}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4'>
                                <div className = 'd-flex justify-content-between'>
                                <h4 className='font-16 font-weight-600 color-707895 pt-4 mb-3'>Quality Parameters</h4>
                                </div>
                                
                                </div>
                            {showList ? 
                                <>
                                <Row className='mx-md-4'>
                                        <div className='d-flex justify-content-end'>
                                            <AddNewButton value='Add Quality Parameters' clickEvent={addform} width='auto'/>
                                        </div>
                                      
                                        <div className='table-responsive-sm'>
                                            <table className="table tQaulityCustom table-text mt-2-rem">
                                                <thead>
                                                    <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                        <th scope='col' className='font-12 colorTableHeading font-weight-500 px-4'>QUALITY PARAMETER</th>
                                                        <th>UNITS</th>
                                                        <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>TYPICAL</th>
                                                        <th scope='col' className='font-12 colorTableHeading font-weight-500 px-2'>REJECTION</th>
                                                        <th scope='col'></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody className='pt-3'>
                                                    {list.map((lis)=>(
                                                        <Quality_list value={lis} editList={editList} remove={remove}/>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </Row>
                                </>
                                :
                                <Quality_form handleModalDataSubmission={handleModalDataSubmission} isEdit={isEdit} data={selected} handleModalDataUpdation={handleModalDataUpdation}/>
                            }
                            </>
                        </ModalBody>
                        <ModalFooter className='mx-5 my-5 justify-content-start'>
                            <BlueBorderButton value='Cancel' clickEvent={toggle} />
                            {showSubmit == true ? 
                                <span className=''><BlueButton value='Submit' clickEvent={saveQuality}/></span>
                            : 
                                <span className='pl-3'><BlueButton value='Submit' disabled/></span>
                            }
                        </ModalFooter>
                </Modal>
        </div>
    </>
    )
}

export default AddQualityParameterModal