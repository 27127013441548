import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import PurchaseDetailsList from './purchasedetails';
import SalesDetailsList from './salesdetails';
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as FormFields from "../../../components/formcomponents";
import { Dropdown, Menu, Space, DatePicker } from 'antd';
import { Slider, Switch } from 'antd';

export default function Index(props) {
    const { instance } = useMsal();
    const location = useLocation()
    const { tab } = location.state != null && location.state
    const [isDetails, setisDetails] = useState(false)
    const [tradeId, settradeId] = useState(0)
    const [traderevenue, settraderevenuesum] = useState(0)
    const [tradepurchase, settradepurchasesum] = useState(0)
    const [rptsalesdata, setrptsalesdata] = useState()
    const [rptpurchasedata, setrptpurchasedata] = useState()
    const [rptsalesdataall, setrptsalesdataall] = useState(0)
    const [rptpurchasedataall, setrptpurchasedataall] = useState(0)
    const [tradeprofit, settradeprofitsum] = useState(false)
    const [showsearch, setshowsearch] = useState(false)
    const [term, setterm] = useState('Interval')
    const [calendarstatus, setcalendarstatus] = useState(false)
    const { RangePicker } = DatePicker;
    const [todate, settodate] = useState(false)
    const [fromdate, setfromdate] = useState(false)
    const [page, setpage] = useState(1)
    const [sum, setsum] = useState(0)
    const [qty, setqty] = useState(0)
    const [sum1, setsum1] = useState(0)
    const [qty1, setqty1] = useState(0)
    const [pricevalcommin, setpricevalcommin] = useState(0)
    const [pricevalcommax, setpricevalcommax] = useState(0)
    const [pricevaltramin, setpricevaltramin] = useState(0)
    const [pricevaltramax, setpricevaltramax] = useState(0)
    const [qtyvalcommin, setqtyvalcommin] = useState(0)
    const [qtyvalcommax, setqtyvalcommax] = useState(0)
    const [qtyvaltramin, setqtyvaltramin] = useState(0)
    const [qtyvaltramax, setqtyvaltramax] = useState(0)
    const [companyList, setCompanyList] = useState()
    const [companyID, setCompanyID] = useState()
    const [pricemin, setPricemin] = useState(0)
    const [pricemax, setPricemax] = useState(100)
    const [quanmin, setQuanmin] = useState(0)
    const [quanmax, setQuanmax] = useState(100)

    useEffect(() => {
        let final_json = {
            "PMSInstanceID": CONFIG.PMSInstanceID,
            "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'Company')
                setCompanyList(res.data.result)
                setCompanyID(res.data.result[0].company.companyID)
                //setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
                getAll(res.data.result[0].company.companyID)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])


    const getAll = (id) => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "companyID": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_SALESDETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setrptsalesdata(res.data.result)
                setrptsalesdataall(res.data.result)
                let sum = 0
                let qty = 0
                res.data.result.map((re) => {
                    sum += parseInt(re.tradeValue)
                    qty += parseInt(re.qty)
                })
                setsum(sum)
                setqty(qty)
                setpricevalcommax(sum)
                setqtyvalcommax(qty)
                settraderevenue(sum)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_PURCHASEDETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setrptpurchasedata(res.data.result)
                setrptpurchasedataall(res.data.result)
                let sum = 0
                let qty = 0
                res.data.result.map((re) => {
                    sum += parseInt(re.tradeValue)
                    qty += parseInt(re.qty)
                })
                console.log(sum)
                setsum1(sum)
                setqty1(qty)
                setpricevaltramax(sum)
                setqtyvaltramax(qty)
                //setqty(qty)
                settradepurchase(sum)
                let diff = tradeprofit - tradepurchase
                settradeprofitsum(diff)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }
    const openDetails = (e) => {
        console.log(e.currentTarget.id)
        let id = parseInt(e.currentTarget.id)
        settradeId(id);
        setisDetails(!isDetails)
    }

    const settraderevenue = (val1) => {
        console.log(val1)
        settraderevenuesum(val1)
    }

    const settradepurchase = (val) => {
        settradepurchasesum(val)
    }

    useEffect(() => {
        console.log(term)
    }, [term])

    const printDocument = () => {
        const domElement = document.getElementById("download_section");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('Purchaseandsalesreports.pdf');
            })
    }
    const handleSearch = (e) => {
        let val = e.target.value
        let salefilter = rptsalesdataall.filter(x => x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.customercode.toLowerCase().includes(val.toLowerCase())
        )
        let purchasefilter = rptpurchasedataall.filter(x => x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.customercode.toLowerCase().includes(val.toLowerCase())
        )
        setrptsalesdata(salefilter)
        setrptpurchasedata(purchasefilter)
    }

    const handleOpenFilter = () => {
        setshowsearch(!showsearch)
    }
    const onPriceChange = (value) => {
        setPricemin(value[0])
        setPricemax(value[1])
        let val1 = (sum * value[0]) / 100
        setpricevalcommin(val1)
        let val2 = (sum * value[1]) / 100
        setpricevalcommax(val2)
        let val3 = (sum1 * value[0]) / 100
        setpricevaltramin(val3)
        let val4 = (sum1 * value[1]) / 100
        setpricevaltramax(val4)
    }
    const onQuantityChange = (value) => {
        setQuanmin(value[0])
        setQuanmax(value[1])
        let val1 = (qty * value[0]) / 100
        setqtyvalcommin(val1)
        let val2 = (qty * value[1]) / 100
        setqtyvalcommax(val2)
        let val3 = (qty1 * value[0]) / 100
        setqtyvaltramin(val3)
        let val4 = (qty1 * value[1]) / 100
        setqtyvaltramax(val4)
    }

    const findmonthly = () => {
        setterm('monthly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const findquarterly = () => {
        setterm('quarterly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('quarter').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const findyearly = () => {
        setterm('yearly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findmonthly}>Monthly</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findquarterly}>Quarterly</span></>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findyearly}>Yearly</span></>
                    ),
                }
            ]}
        />
    );
    const setrrange = () => {
        setcalendarstatus(!calendarstatus)
    }
    const searchData = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "companyID": companyID,
            "fromDate": fromdate == false ? null : fromdate,
            "toDate": todate == false ? null : todate,
            "tradeStartValue": pricevalcommin,
            "tradeEndValue": pricevalcommax,
            "quantityStartValue": qtyvalcommin,
            "quantityEndValue": qtyvalcommax
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_SALESDETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setrptsalesdata(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        const json_data = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "fromDate": fromdate == false ? null : fromdate,
            "toDate": todate == false ? null : todate,
            "tradeStartValue": pricevaltramin,
            "tradeEndValue": pricevaltramax,
            "quantityStartValue": qtyvaltramin,
            "quantityEndValue": qtyvaltramax
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_PURCHASEDETAILS, json_data, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setrptpurchasedata(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const setDateRange = (date, dateString) => {
        setfromdate(new Date(dateString[0]))
        settodate(new Date(dateString[1]))
    }
    
    
    const handleCompanySelect = (e) => {
        console.log(e.target.value)
        setCompanyID(parseInt(e.target.value))
        getAll(parseInt(e.target.value))
    }

    const clearFilters = ()=>{
        setPricemin(0)
        setPricemax(100)
        setQuanmin(0)
        setQuanmax(100)

        setpricevalcommin(0)
        setpricevalcommax(sum)
        setqtyvalcommin(0)
        setqtyvalcommax(qty)

        
        setpricevaltramin(0)
        setpricevaltramax(sum1)
        setqtyvaltramin(0)
        setqtyvaltramax(qty1)

        getAll(companyID)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='8.4' isOpenid={8} />

            <div className='width-100'>
                <Navbar />
                <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                    <div className='d-flex justify-content-center align-items-center '>
                        <BackButton />
                    </div>
                </div>
                <div className='mt-5 mt-md-0 bg-white px-4'>
                    <Row>
                        <Col md={4} sm={12} lg={4} className='d-flex align-items-center justify-content-center rt-b sec2'>
                            <div>
                                <span className='reports_count_header'>Total Trade Revenue</span>
                                <div className='reports_count_value d-flex align-items-center'>
                                    <div className='reports_count_pre_suf'>
                                        $
                                    </div>
                                    <div>
                                        <NumberFormat value={parseFloat(sum).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                                {/* <div className='reports_count_value'>{traderevenue}</div>*/}
                            </div>
                        </Col>
                        <Col md={4} sm={12} lg={4} className='d-flex align-items-center justify-content-center rt-b sec2'>
                            <div>
                                <span className='reports_count_header'>Total Purchase Amount</span>
                                <div className='reports_count_value d-flex align-items-center'>
                                    <div className='reports_count_pre_suf'>
                                        $
                                    </div>
                                    <div>
                                        <NumberFormat value={parseFloat(tradepurchase).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                                {/*<div className='reports_count_value'>{tradepurchase}</div>*/}
                            </div>
                        </Col>
                        <Col md={4} sm={12} lg={4} className='d-flex align-items-center justify-content-center rt-b  sec2'>
                            <div>
                                <span className='reports_count_header'>Total Profit</span>
                                <div className='reports_count_value d-flex align-items-center'>
                                    <div className='reports_count_pre_suf'>
                                        $
                                    </div>
                                    <div>
                                        <NumberFormat value={tradepurchase > traderevenue ? parseFloat(tradepurchase - traderevenue).toFixed(2) : parseFloat(traderevenue - tradepurchase).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                </div>
                                {/*<div className='reports_count_value'>{traderevenue - tradepurchase}</div>*/}

                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='row mx-4 px-3 mt-5'>
                    <div className='col-md-6 align-self-center'>
                        <div className='d-flex align-self-center'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' />
                            <h6 className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>SALES & PURCHASE OVERVIEW</h6>
                        </div>

                    </div>
                    <div className='col-md-6 align-self-center'>
                        <div className='d-flex d-flex '>
                            <div className='titleHeader intervals mx-4'>
                                <Form.Select
                                    placeholder='Intervals'
                                    name='division'
                                    onChange={handleCompanySelect}
                                    onBlur={handleCompanySelect}
                                    style={{ borderRadius: '8px', border: 'none', padding: '11px 25px' }}
                                >
                                    {companyList != null ?
                                        companyList.map((values, k) => (
                                            <option value={values.company.companyID}>{values.company.companyName}</option>
                                        ))

                                        :
                                        <option value={1}></option>}

                                </Form.Select>
                            </div>
                            <div className='mx-2 align-self-center'>
                                <span className='me-5'><BlueButton value="EXPORT PDF" clickEvent={printDocument} /></span>
                            </div>
                            <div>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='Search'
                                            className="form_input form_input_color_change"
                                            name='Search'
                                            onChange={handleSearch}
                                            onBlur={handleSearch}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </div>
                            <div className='pl-10'>
                                <img src='assets/images/bluefilter.svg' className='pointer' onClick={handleOpenFilter} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
                {showsearch &&
                    <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-4 '>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <span className='report_filter_title'>Date Range</span>
                                        <div className='date_range_filter d-flex justify-content-between'>
                                            <div>
                                                <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                                                    <a onClick={(e) => e.preventDefault()}>
                                                        <Space className='d-flex justify-content-between align-items-center'>
                                                            {!calendarstatus &&
                                                                <span className='meeting_term'>{term}</span>
                                                            }
                                                            <span><img src='assets/images/DownwardArrow.svg' /></span>
                                                        </Space>
                                                    </a>
                                                </Dropdown>
                                            </div>
                                            <div className='pl-10'>
                                                {calendarstatus ?
                                                    <RangePicker onChange={setDateRange} />
                                                    :
                                                    <img src='assets/images/calendar.svg' onClick={setrrange} />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <span className='report_filter_title'>Price Range</span>
                                        <Slider
                                            range
                                            value={[pricemin, pricemax]}
                                            onChange={onPriceChange}
                                        />
                                        <div className='d-flex justify-content-between'>
                                            <span>{page == 1 ? pricevalcommin : pricevaltramin}</span>
                                            <span>{page == 1 ? pricevalcommax : pricevaltramax}</span>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <span className='report_filter_title'>Quantity Range</span>
                                        <Slider
                                            range
                                            value={[quanmin, quanmax]}
                                            onChange={onQuantityChange}
                                        />
                                        <div className='d-flex justify-content-between'>
                                            <span>{page == 1 ? qtyvalcommin : qtyvaltramin}</span>
                                            <span>{page == 2 ? qtyvalcommax : qtyvaltramax}</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className='d-flex align-items-center'>
                                        <Button variant='primary' className='mx-3' size='md' onClick={searchData}>Search</Button>
                                        <Button variant='outline-primary' className='mx-3' size='md' onClick={clearFilters}>
                                            Clear Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
                <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-4 '>
                    <div className=''>
                        <Tabs defaultIndex={tab}>
                            <Card className='tabs-wrapper'>
                                <TabList>
                                    <Tab onClick={() => {
                                        setpage(1)
                                    }}>Sales</Tab>
                                    <Tab onClick={() => {
                                        setpage(2)
                                    }}>Purchase</Tab>
                                </TabList>
                            </Card>
                            <TabPanel className='mt-1-rem'>
                                <div className='inner-page-content' style={{ background: 'white' }} id='download_section'>
                                    {/*<SalesDetailsList settraderevenue={settraderevenue} settradepurchase={settradepurchase} />*/}
                                    <SalesDetailsList rptsalesdata={rptsalesdata} companyID={companyID}/>
                                    {/*<SalesDetailsList />*/}
                                </div>
                            </TabPanel>
                            <TabPanel className='mt-1-rem'>
                                <div className='inner-page-content' style={{ background: 'white' }} id='download_section'>
                                    {/*<PurchaseDetailsList />*/}
                                    <PurchaseDetailsList rptpurchasedata={rptpurchasedata}  companyID={companyID}/>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div >
    )
}