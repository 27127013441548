import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import FormComponent from './components/FormComponent';
import ListData from './components/ListData';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function StorageInformation(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)

    useEffect(()=>{
      if(isFirstLoad){
        if(props.marketingStorageInformation.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.marketingStorageInformation.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    })

    useEffect(()=>{
          console.log()
          if(props.isEdit == true && isFirstEdit == true){
    
            setIsAdd(false)
            setIsFirstEdit(false)
          }
    })
    
    const openAddSupplier =()=>{
      setIsAdd(true);
    }

    const addDirectorToList= (data)=>{
        console.log('data on addDirectorToList function')
      setIsAdd(false);
      props.addStorageToList(data)
    }

    const editBank =(e)=>{
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.marketingStorageInformation.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToSupplier= (data)=>{
      setIsAdd(false);
      setIsEdit(false);
      props.editStorageToList(data)
    }
  return (
    <>
      <Row className='d-flex' style={{background: '#fff'}}>
            <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
                Storage Information
                <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Storage' clickEvent={openAddSupplier}/></span>
            </div>
                {!isAdd?
                <div className='mt-2-rem'>
                  
                    <table className="table table-text mt-1-rem">
                        <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                            <th scope='col' className=' font-weight-500'>Product Information</th>
                            <th scope='col' className=' font-weight-500'>Sotrage Facility</th>
                            <th scope='col' className=' font-weight-500'>Production / Capactiy</th>
                            <th scope='col' className=' font-weight-500'>Existing Supply Sources</th>
                            <th scope='col' className=' font-weight-500'>Tonnage Sold</th>
                            <th scope='col' className=' font-weight-500'>Markets Served</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                        </thead>
                        <tbody>
                            {props.marketingStorageInformation.map((values)=>(
                            <ListData  is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected} is_DefaultAvailable={values.is_Default?true:false} values={values} marketingStorageInformation ={props.marketingStorageInformation} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                            
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                <FormComponent  addDirectorToList = {addDirectorToList}  isEdit={isEdit} data={selectedBank} editDirectorToList={editBankToSupplier}/>
            }
            </Row>
    </>
  )
}

export default StorageInformation