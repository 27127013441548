import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import Moment from "moment"
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../formcomponents"
import * as ButtonComponent from "../../../buttoncomponents"
import { CountryList, StateList, AccountType } from "../../../../helpers/API/Api"
export const Currency = (props) => {
    return (
        <>
            <option value={2} selected={props.currencyID == 2}>USD</option>
        </>
    )
}


function BankAccountModal(props) {
    console.log(props)
    const [error, setError] = useState(true)
    const [modal, setModal] = useState(true);
    const [cntID, setCntID] = useState(0);
    const [countryerror, setCountryError] = useState(false)
    const [stateID, setStateID] = useState(0);
    const [stateerror, setStateError] = useState(false)
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [bankstatename, setbankstatename] = useState();

    useEffect(() => {
        if (props.edit == true) {
            setError(false)
            if(props.bankdetailsstate != null)
            {
                let bankstatename = props.bankdetailsstate.filter(x => x.bankdetailsid == props.bankForEdit.bankDetailID);
                if (bankstatename != null) {
                    console.log(bankstatename[0].banktatename);
                    setbankstatename(bankstatename[0].banktatename);
                    formik.values.bankstatename = bankstatename[0].banktatename;
                }
                else {
                    formik.values.bankstatename = ""
                }
            }
        }
        console.log('props.bankbankstatename')
        console.log(props.bankbankstatename)

        
    }, [])

    const formik = useFormik({
        initialValues:
        {
            companyID: props.companyID,
            accountName: props.edit == true ? props.bankForEdit.accountName : '',
            bankName: props.edit == true ? props.bankForEdit.bankName : '',
            accountNo: props.edit == true ? props.bankForEdit.accountNo : '',
            bankAddress: props.edit == true ? props.bankForEdit.bankAddress : '',
            swiftCode: props.edit == true ? props.bankForEdit.swiftCode : '',
            ibanNo: props.edit == true ? props.bankForEdit.ibanNo : '',
            bankCountryID: props.edit == true ? props.bankForEdit.bankCountryID : '',
            bankStateID: props.edit == true ? props.bankForEdit.bankStateID : null,
            bankCityName: props.edit == true ? props.bankForEdit.bankCityName : '',
            bankStatus: props.edit == true ? props.bankForEdit.bankStatus : true,
            AccountTypeID: props.edit == true ? props.bankForEdit.AccountTypeID : '',
            currencyID: props.edit == true ? props.bankForEdit.currencyID : '',
            AccountTypeName: props.edit == true ? props.bankForEdit.AccountTypeName : '',
            key: props.edit == true ? props.bankForEdit.key : '',
            bankstatename: props.edit == true ? props.bankForEdit.bankstatename : ''
            //bankbankstatename: props.bankdetailsstate.filter(x => x.bankdetailsid == props.bankForEdit.bankDetailID)
        },
        validate: values => {
            if (!values.swiftCode) {
                setError(true)
            }
            else {
                setError(false)
            }
        }
    })

    const toggle = () => {
        setModal(!modal)
        props.close();
    }

    const handleCurrency = (e) => {
        console.log(e.target.value)
        formik.values.currencyID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.currencyValue = e.nativeEvent.target[index].text;
    }

    const onCountrySelect = (e) => {
        setIsCountryChanged(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntID(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryError(false)
            setTimeout(() => {
                setIsCountryChanged(true)
            }, 800);
        }
        else {
            setCountryError(true)
        }
        generalcountrySelected(value)
    }

    const onAccountTypeSelect = (e) => {
        formik.values.AccountTypeID = parseInt(e.currentTarget.value)
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.AccountTypeName = e.nativeEvent.target[index].text;
    }

    const onStateChange = (e) => {
        const value = e.currentTarget.value;
        if (value != 0) {
            setStateID(value)
            formik.values.bankStateID = parseInt(value)
            setStateError(false)
        }
        else {
            setStateError(true)
        }
        generalstateSelected(value)
    }

    const generalstateSelected = (value) => {
        if (value != 0) {
            formik.values.bankStateID = parseInt(value);
        }
    }
    const generalcountrySelected = (value) => {
        if (value != 0) {
            formik.values.bankCountryID = parseInt(value)
        }
    }
    const handleSaveEvent = () => {
        if(props.edit == true){
            if (props.bankForEdit.bankDetailID != null){
                console.log('check')
                formik.values.bankDetailID = props.bankForEdit.bankDetailID
            }
        }
     
        formik.values.currencyID = 2
        console.log(formik.values)
        formik.values.bankStateID = parseInt(formik.values.bankStateID)
        props.handleBankAccountList(formik.values)
        toggle()
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='bank-account-modal'>
            <ModalHeader toggle={toggle} className='p-40'>
                <div className='d-flex justify-content-between '>
                    <div className='font-16 font-weight-600 color-707895'>
                        {props.edit == true ? "Update Bank Account Details" : "Add Bank Account Details"}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='' style={{ backgroundColor: '#f9f9ff' }}>
                <div className=''>
                    <div className=''>
                        <Card className='my-3'>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Bank Account Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='accountName'
                                                label='Account Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.accountName}
                                                errors={formik.touched.accountName ? formik.errors.accountName : ''}
                                            //isabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='accountNo'
                                                label='Account No'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.accountNo}
                                                errors={formik.touched.accountNo ? formik.errors.accountNo : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='bankName'
                                                label='Bank Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.bankName}
                                                errors={formik.touched.bankName ? formik.errors.bankName : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='swiftCode'
                                                label='Swift Code*'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.swiftCode}
                                                errors={formik.touched.swiftCode ? formik.errors.swiftCode : ''}
                                            //disabled={props.isDisabled}
                                            />

                                        </>
                                        <Row>
                                            <FormFields.InputField
                                                md={3}
                                                name='ibanNo'
                                                label='IBAN No.'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.ibanNo}
                                                errors={formik.touched.ibanNo ? formik.errors.ibanNo : ''}
                                            //isabled={props.isDisabled}
                                            />
                                            <FormFields.InputSelect
                                                md={3}
                                                name='AccountTypeID'
                                                label='Account Type'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={onAccountTypeSelect}
                                                onBlur={onAccountTypeSelect}
                                                optionField={props.edit == true ?
                                                    <AccountType
                                                        AccountTypeID={props.bankForEdit.AccountTypeID}
                                                    />

                                                    :
                                                    <AccountType />}
                                                val={formik.values.AccountTypeID}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputSelect
                                                md={3}
                                                name='currencyID'
                                                label='Currency'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={handleCurrency}
                                                val={formik.values.currencyID}
                                                optionField={<Currency currencyID={props.edit ? props.bankForEdit.currencyID : ''} />}
                                            />
                                        </Row>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className=''>
                    <div className=''>
                        <Card className='my-3'>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Bank Address Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='bankAddress'
                                                label='Bank Address'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.bankAddress}
                                                errors={formik.touched.bankAddress ? formik.errors.accountName : ''}
                                            //isabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='bankCityName'
                                                label='Bank City'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.bankCityName}
                                                errors={formik.touched.bankCityName ? formik.errors.accountNo : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputSelect
                                                md={3}
                                                name='bankCountryID'
                                                label='Bank Country'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={onCountrySelect}
                                                onBlur={onCountrySelect}
                                                optionField={props.edit == true ?
                                                    <CountryList
                                                        cntid={props.bankForEdit.bankCountryID}
                                                    />

                                                    :
                                                    <CountryList />}
                                                val={formik.values.bankCountryID}
                                            //disabled={props.isDisabled}
                                            />
                                            {/*{isCountryChanged ?*/}
                                            {/*    <FormFields.InputSelect*/}
                                            {/*        md={3}*/}
                                            {/*        name='bankStateID'*/}
                                            {/*        label='Bank State'*/}
                                            {/*        classname='pt-1-rem'*/}
                                            {/*        type='text'*/}
                                            {/*        optionField={<StateList countryID={cntID} countryChange={'country change'} />}*/}
                                            {/*        val={formik.values.bankStateID}*/}
                                            {/*        onChange={onStateChange}*/}
                                            {/*        onBlur={onStateChange}*/}
                                            {/*    //disabled={props.isDisabled}*/}
                                            {/*    />*/}
                                            {/*    :*/}
                                            {/*    <FormFields.InputSelect*/}
                                            {/*        md={3}*/}
                                            {/*        name='bankStateID'*/}
                                            {/*        label='State'*/}
                                            {/*        classname='pt-1-rem'*/}
                                            {/*        type='text'*/}
                                            {/*        optionField={props.edit == true ?*/}
                                            {/*            <StateList*/}
                                            {/*                countryID={props.bankForEdit.bankCountryID} countryChange={'no country change'}*/}
                                            {/*            />*/}

                                            {/*            :*/}
                                            {/*            ''}*/}
                                            {/*        //val={formik.values.bankStateID}*/}
                                            {/*        onChange={onStateChange}*/}
                                            {/*        onBlur={onStateChange}*/}
                                            {/*    //disabled={props.isDisabled}*/}
                                            {/*    />*/}
                                            {/*}*/}

                                            <FormFields.InputField
                                                md={3}
                                                name='bankstatename'
                                                label='State'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.bankstatename}
                                            />


                                        </>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>
            </ModalFooter>
        </Modal>
    )
}

export default BankAccountModal