import React, {useEffect, useState} from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../components/buttoncomponents'
import * as FormFields from "../../../../components/formcomponents"
import { CountryList } from '../../../../helpers/API/Api';
import Moment from 'moment';

function ParentCompanyInformation(props) {
    console.log(props, 'data for edit')
   
    const [DateOfIncorporation, SetDateOfIncorporation] = useState();
    const handleDOC = (date)=>{
        SetDateOfIncorporation(date)
        formik.values.parentCompanyIncorporationDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }
   
    const formik = useFormik({
        initialValues:
        {
            parentCompanyName: '',
            parentCompanyRegNo:  '',
            parentCompanyIncorporationDate: null,
            parentCompanyCountryID: '',
            ParentCompanyStateName: null
        },
      
        validate: values => {
            
            let errors = {}

            const letters = /^[A-Za-z ]+$/;
            
            if(!values.fullname){
                errors.fullname = "Please fill in Name"
            }
            return errors
        }   
    })
    useEffect(()=>{
        if(props.isEdit == true){
            formik.values.parentCompanyName = props.dataForEdit.customerdata.parentCompanyName
            formik.values.parentCompanyRegNo =  props.dataForEdit.customerdata.parentCompanyRegNo 
            formik.values.parentCompanyIncorporationDate = props.dataForEdit.customerdata.parentCompanyIncorporationDate 
            formik.values.parentCompanyCountryID = props.dataForEdit.customerdata.parentCompanyCountryID 
        }
    }, [])
    useEffect(()=> {
        if(props.isBackClicked == true){
            formik.values.parentCompanyName = props.customerdata.parentCompanyName
            formik.values.parentCompanyRegNo =  props.customerdata.parentCompanyRegNo 
            formik.values.parentCompanyIncorporationDate = props.customerdata.parentCompanyIncorporationDate 
            formik.values.parentCompanyCountryID = props.customerdata.parentCompanyCountryID 
        }
    }, [])
    useEffect(()=>{
        //formik.values.parentCompanyIncorporationDate = DateOfIncorporation
        props.saveCompanyInformaton(formik.values)
    }, [formik])

  return (
    <div>
                <div className='font-16 font-weight-600 color-707895'>
                    Parent Company Information (in case of a subsidiary)
                </div>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='parentCompanyName'
                        label='Parent Company Name'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.parentCompanyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputField
                        md={3}
                        name='parentCompanyRegNo'
                        label='Registration No.'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.parentCompanyRegNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.DatePick 
                        md={3}
                        label='Date of incorporation' 
                        name='parentCompanyIncorporationDate'
                        classname='pt-1-rem iconDatePicker'
                        handleCompletionDate={handleDOC}
                        isRequired={false}
                        val={formik.values.parentCompanyIncorporationDate}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='parentCompanyCountryID'
                        label='Country'
                        classname='pt-1-rem'
                        type='text'
                        optionField={props.isEdit == true ?
                            <CountryList 
                                cntid={props.dataForEdit.customerdata.parentCompanyCountryID} 
                            /> 
                            : 
                            props.isBackClicked ? 
                            <CountryList 
                                cntid={formik.values.parentCompanyCountryID} 
                            /> 
                            :
                            <CountryList 
                            />}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        val={formik.values.parentCompanyCountryID}
                    />
                </Row>
    </div>
  )
}

export default ParentCompanyInformation