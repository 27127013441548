import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import { Formik, useFormik } from 'formik'
import { Row, Col, Form, FloatingLabel} from "react-bootstrap";
import Moment from 'moment'
import { DatePicker } from 'antd';

import styles from './styles.module.css'
import Management from "./Management/index"
import Finance from "./Finance/index"
import Trading from "./Trade/index"
import Shipment from "./Shipment/index"
import General from "./General/index"
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import * as CONFIG from "../../helpers/config"
import * as FormFields from "../../components/formcomponents"



export default function Index(props) {
  const { RangePicker } = DatePicker;
  const [toDate, setToDate] = useState()
  const [fromDate, setFromDate] = useState()
  const [terms, setTerms] = useState(3)
  const [opencustomrange, setopencustomrange] = useState(false)
  const [companyList, setCompanyList] = useState()
  const [companyID, setCompanyID] = useState()
  const { instance } = useMsal();
  const formik = useFormik({
    initialValues:
    {
      division: 1,
      toDate : Moment().format("YYYY-MM-DDTHH:mm:ss"),
      fromDate : Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss"),
    },
  })

  useEffect(()=>{
    let final_json = {
        "PMSInstanceID": CONFIG.PMSInstanceID,
        "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
        "created_User": CONFIG.LoginID
    }
    axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_LIST, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result, 'Company')
        setCompanyList(res.data.result)
        setCompanyID(res.data.result[0].company.companyID)
        localStorage.setItem('CompanyID', parseInt(res.data.result[0].company.companyID));
        //setRolesList(res.data.result)
        //setWorkFlowList(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    }) 
  },[])

  useState(()=>{
    let toDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
    let fromDate = Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss")
    console.log(toDate)
    console.log(fromDate)
    setToDate(toDate)
    setFromDate(fromDate)
  },[])

  useEffect(()=>{

  },[terms])

  const handleDateTerm = (e) => {
    console.log(e.target.value)
    if(e.target.value == 1){
      setopencustomrange(false)
      formik.values.toDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
      const month = Moment().month()
      formik.values.fromDate = Moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss")
      setToDate(formik.values.toDate)
      setFromDate(formik.values.fromDate)
      console.log(formik.values.toDate)
      console.log(formik.values.fromDate)
      setTerms(1)
      //formik.values.fromDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
    }
    else if(e.target.value == 2){
      setopencustomrange(false)
      formik.values.toDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
      const month = Moment().month()
      formik.values.fromDate = Moment().startOf('quarter').format("YYYY-MM-DDTHH:mm:ss")
      setToDate(formik.values.toDate)
      setFromDate(formik.values.fromDate)
      console.log(formik.values.toDate)
      console.log(formik.values.fromDate)
      setTerms(2)
      // formik.values.fromDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
    }
    else if(e.target.value == 3){
      setopencustomrange(false)
      formik.values.toDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
      formik.values.fromDate = Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss")
      setToDate(formik.values.toDate)
      setFromDate(formik.values.fromDate)
      console.log(formik.values.toDate)
      console.log(formik.values.fromDate)
      setTerms(3)
    }
    else if(e.target.value == 4){
      setopencustomrange(true)
    }
  }
  const customrange = (date, dateString)=>{
    setFromDate(Moment(dateString[0]).format("YYYY-MM-DDTHH:mm:ss"))
    setToDate(Moment(dateString[1]).format("YYYY-MM-DDTHH:mm:ss"))
    setTerms(0)
  }
  useEffect(()=>{

  },[toDate, fromDate])

  const handleCompanySelect = (e) => {
    console.log(e.target.value)
    setCompanyID(e.target.value)
  }

  useEffect(()=>{

  },[companyID])
    return (
      <div className='main-wrapper dashboard-background d-flex font-face-gilroy'>
        <Sidebar id={1}/>
        <div className='width-100'>
            <Navbar/>
            <div className='d-flex justify-content-between inner-page-header align-items-center'>
              <div className='d-flex'>
                <img src='assets/images/pageicons/customericon.svg' className='img-fluid'/>
                <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>
                  DASHBOARD
                </span>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
              <div className = 'd-flex align-items-center mx-3'>
                <p className = 'mb-0 me-4' >Selected Company</p>
                <Form.Select 
                  placeholder='Intervals' 
                  name= 'division'
                  onChange={handleCompanySelect} 
                  onBlur={handleCompanySelect}
                  className = {` ${styles.intervals}`}
                  style={{borderRadius:'8px', border:'none', padding:'11px 25px'}}
                >
                  {companyList != null ?
                  companyList.map((values, k)=>(
                    <option value = {values.company.companyID}>{values.company.companyName}</option>
                  )) 
      
                :
                  <option value = {1}></option>}

                </Form.Select>
              </div>
              <div className='me-3'>
                  {opencustomrange &&
                    <>
                    <RangePicker
                      className="form_input"
                      onChange={customrange}
                      dateFormat="dd/MM/yyyy"
                    />
                    </>
                  }
              </div>
              <div>
                <Form.Select 
                  placeholder='Intervals' 
                  name= 'division'
                  onChange={handleDateTerm} 
                  onBlur={handleDateTerm}
                      
                  className = {` ${styles.intervals}`}
                  style={{borderRadius:'8px', border:'none', padding:'11px 25px'}}
                >
                    <option value={1}>Monthly</option>
                    <option value={2}>Quarterly</option>
                    <option value={3} selected={true}>Yearly</option>
                    <option value={4}>Custom</option>
                  </Form.Select>
                </div>
              </div>
            </div>
            {companyID != null &&
              <>
                  {localStorage.getItem('LogInUserRoleID') == 3001 || localStorage.getItem('LogInUserRoleID') == 3032 ?
                    <Management companyID = {companyID} terms = {terms} dashboardTypeID = {1} toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''}/>
                    :
                    ''
            }
            {localStorage.getItem('LogInUserRoleID') == 3003 || localStorage.getItem('LogInUserRoleID') == 3004 ?
              <Trading companyID = {companyID}  terms = {terms}  dashboardTypeID = {2} toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''} />
              :
              ''
            }
            {localStorage.getItem('LogInUserRoleID') == 3021 || localStorage.getItem('LogInUserRoleID') == 3022 || localStorage.getItem('LogInUserRoleID') == 3031 ?
              <Finance companyID = {companyID}  terms = {terms}  dashboardTypeID = {4} toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''}/>
              :
              ''
            }
            {localStorage.getItem('LogInUserRoleID') == 3005 &&
              <Shipment companyID = {companyID}  dashboardTypeID = {3}  toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''} />
            }
            {(localStorage.getItem('LogInUserRoleID') == 3056 ||  localStorage.getItem('LogInUserRoleID') == 3057 )&&
              <General companyID = {companyID}   toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''} />
            }
              </>
            
            }
            {/* <General  toDate = {toDate != null ? toDate : ''} fromDate = {fromDate != null ? fromDate : ''} /> */}
        </div>
      </div>
    )
}
