import React,{useEffect, useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";

function FormComponent(props) {
    const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
    const formik = useFormik({
        initialValues:
        {
            registeredUnitNo: '',
            registeredBuildingName: '',
            registeredStreetName: '',
            registeredCityName: '',
            registeredCountryID: '',
            country: '',
        }

    })
    useEffect(()=>{
        setTimeout(() => {
            setDataSubmissionValidation(!dataSubmissionValidation)
            if(props.isdataavailable && !dataSubmissionValidation){
                formik.values.registeredUnitNo= props.customerAddress.registeredUnitNo
                formik.values.registeredBuildingName= props.customerAddress.registeredBuildingName
                formik.values.registeredStreetName= props.customerAddress.registeredStreetName
                formik.values.registeredCityName= props.customerAddress.registeredCityName
                formik.values.registeredCountryID= props.customerAddress.registeredCountryID
                formik.values.country= props.customerAddress.countryName
            }
        }, 100);
    })
   
    return (
        <>
                <Form>
                    <Row>
                        <FormFields.InputField
                            md={3}
                            name='registeredUnitNo'
                            label='Unit No'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.registeredUnitNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors= {formik.touched.registeredUnitNo?formik.errors.registeredUnitNo:''}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='registeredBuildingName'
                            label='Building Name'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.registeredBuildingName}
                            onChange={formik.handleChange}
                            disabled
                            
                        />
                        <FormFields.InputField
                            md={3}
                            name='registeredStreetName'
                            label='Street Name'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.registeredStreetName}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='registeredCityName'
                            label='City'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.registeredCityName}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='country'
                            label='Country'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.country}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Row>
                </Form>

        </>

    )
}

export default FormComponent