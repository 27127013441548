import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import FormComponent from './components/FormComponent';
import ListData from './components/ListData';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function Index(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)
    const [bug, setBug] = useState(true)
    console.log(props, 'Contact information')
    useEffect(()=>{
      if(isFirstLoad){
        if(props.directorList.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.directorList.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    })

    useEffect(()=>{
      console.log()
      if(props.isEdit == true && isFirstEdit == true){
 
        setIsAdd(false)
        setIsFirstEdit(false)
      }
    }, [])
    useEffect(()=> {
      if(props.isContactSet == true){
        setIsAdd(false)
      }
    },[])
    useEffect(()=> {
      if(props.isBackClicked == true){
        console.log(props)
        setIsAdd(false)
      }
    },[])
    
    const openAddCustomer =()=>{
      setIsAdd(true);
    }

    const addDirectorToList= (data)=>{
      console.log('data on addDirectorToList function', data)
      setIsAdd(false);
      props.handleError()
      props.addDirectorToList(data)
    }

    const editBank =(e)=>{
      setBug(true)
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.directorList.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToCustomer= (data)=>{
      setIsAdd(false);
      setIsEdit(false);
      props.editDirectorToList(data)
    }

    const handleCancel = () => {
      setIsAdd(false)
    }
    return (
        <>
        <Row className='d-flex' style={{background: '#fff'}}>
          <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
                Customer Contact
              <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Contact' clickEvent={openAddCustomer}/></span>
          </div>
            {!isAdd?
              <div className='mt-2-rem'>
                <div className='d-flex justify-content-end color-7350EA font-weight-500'> 
                      <img src='assets/images/information.svg'/>&nbsp;Choose a primary contact
                </div>
                    <table className="table table-text mt-1-rem">
                    <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                            <th scope='col' className=' font-weight-500'>Contact Name</th>
                            <th scope='col' className=' font-weight-500'>Designation</th>
                            <th scope='col' className=' font-weight-500'>Mobile No</th>
                            <th scope='col' className=' font-weight-500'>Telephone No</th>
                            <th scope='col' className=' font-weight-500'>Email</th>
                            <th scope='col' className=' font-weight-500'>Contact Type</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.directorList.map((values)=>(
                          <ListData bug={bug} isEdit={props.isEdit} is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected?values.is_Default?false:true:false} is_DefaultAvailable={values.is_Default?true:false} values={values} directorList ={props.directorList} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                        ))}
                    </tbody>
                </table>
              </div>
            :
            <FormComponent 
              setErrors = {props.setErrors} 
              handleError = {props.handleError}   
              addDirectorToList = {addDirectorToList}
              handleCancel = {handleCancel} 
              isEdit={isEdit} 
              data={selectedBank} 
              editDirectorToList={editBankToCustomer}/>
          }
        </Row>
        </>
        
    )
}

export default Index