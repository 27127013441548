import React,{useState, useEffect} from 'react'
import axios from 'axios';
//import configfile from "../../../../config.json";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useFormik } from 'formik'
import { Row, Col, Button, Dropdown, Card, Form, FloatingLabel } from "react-bootstrap"
import List from './List';
import * as CONFIG from "../../../../helpers/config"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function SupplierListModal(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [supplierList, setSupplierList] = useState([])
    const [isFirst, setIsFirst] = useState(true);
    const [selectedSupplier, setselectedSupplier] = useState();
    const MySwal = withReactContent(Swal)

     useEffect(()=>{
        console.log('inside modal')

        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
        if(isFirst){
            getList()
        }
        setIsFirst(false)
    })
    useEffect(()=>{
        console.log(selectedSupplier)
    },[selectedSupplier])
    const toggle = () => {
        setModal(!modal)
        props.cancel();
    }
    const getList = () =>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setSupplierList(res.data.result);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        }) 
    }

    const moveToNext = ()=>{
        
        if (selectedSupplier != null){
            console.log(selectedSupplier)
            //console.log(selectedSupplier)
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "id": selectedSupplier,
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.handleError()
                props.handleSupplierData(res.data.result)
                props.cancel()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
           
        }
        else{
            MySwal.fire(
                {
                icon: 'error',
                title: 'Please select a Supplier',
                //type: 'error',
                confirmButtonText: 'Back',
                backdrop: `
                rgba(60, 60, 60, 0.5)
                no-repeat
              `
              })
        }
    }
    const back = (data)=>{
        setselectedSupplier(data)
    }
    return (
      <div className='trade_existing_customer"'>
          <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
                      <ModalHeader className='px-5 py-4' toggle={toggle}>
                        <div className = 'font-16 text-uppercase font-weight-600 color-707895'>
                            Existing Supplier
                        </div>
                      </ModalHeader>
                      <ModalBody  className = 'px-5 mb-10-rem' style={{overflowY:'scroll'}}>
                            <div className=' customerList'>
                                <List selectedSupplier = {props.selectedSupplier} data={supplierList} back={back}/>
                            </div>
                      </ModalBody>
                    <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                       <BlueBorderButton value='Cancel' clickEvent={toggle} />
                        <span className='pl-10'><BlueButton value='Select' clickEvent={moveToNext} /></span>
                    </ModalFooter>
          </Modal>
      </div>
    )
}

export default SupplierListModal