import React from 'react'
import { useState, useRef } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents';
import Moment from "moment";
import { useReactToPrint } from 'react-to-print';

export default function ViewInvoice(props) {
    const componentRef = useRef();

    console.log(props)
    const [modal, setmodal] = useState(props.isOpen)

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }
     // useEffect(()=> {
    //     if(customerProformaInvoiceData != null && customerProformaInvoiceData == true){
    //         let listValues = []
    //         let listValuesTax = []
    //         const json_list = {
    //             "instanceID": CONFIG.PMSInstanceID,
    //             "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    //             "created_User": CONFIG.LoginID,
    //             "id": parseInt(data.tradeID),
    //         }
    //         axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    //             .then((res) => {
    //                console.log(res.data.result[0])
    //                console.log((parseInt((res.data.result[0].tradedetails.tradeProductDetail[0].productSellingPrice) * (res.data.result[0].tradedetails.tradeProductDetail[0].quantity))))
    //                setCustomerProformaInvoiceData(res.data.result[0])
    //                res.data.result[0].tradedetails.tradeProductDetail.map((list, k) => (
    //                 listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
    //                 // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
    //                ))
    //                console.log(listValues)
    //                let sum = 0
    //                let sumTax = parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax1)) + parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax2))
    //                for (let i = 0; i < listValues.length; i++) {
    //                 sum += listValues[i]
    //             }
    //             console.log(sum)
    //             setSubTotal(sum)
    //             setTotalTax(sumTax)
    //             setOverAllTotal(sum + sumTax)
    //             props.refreshList()
    //             }).catch((error)=>{
    //                 if(error.response.status == 401){
    //                     localStorage.clear();
    //                     instance.logoutRedirect({
    //                       postLogoutRedirectUri: "/",
    //                     });
    //                 }
    //             })  
    //     }
    // },[])
    const pageStyle = `
              @page {
                size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
              @media print {
                div.page-footer {
                position: fixed;
                bottom:0mm;
                width: 100%;
                height: 50px;
                font-size: 15px;
                color: #fff;
                /* For testing */
                background: red;
                opacity: 0.5;

                page-break-after: always;
                }
                .page-number:before {
                  /* counter-increment: page; */
                  content: "Pagina "counter(page);
                }


              }
              body {
                marginBottom:50px
              }
  `;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
        copyStyles: true,
        pageStyle: pageStyle
    });
    const printDocument = () => {

    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: '100vh' }}>
            <ModalHeader toggle={toggle}>

                <div className='mx-2'>
                    <BlueBorderButton value="PRINT" clickEvent={handlePrint} />
                </div>

            </ModalHeader>
            <ModalBody className=' scrollContract'>
                <div id='download_section' ref={componentRef} >
                    <Row className='p-1-rem'>
                        <div className='d-flex justify-content-end'>
                            <img src='assets/images/logo.svg' />
                        </div>
                        <div className='font-weight-600 d-flex justify-content-center uline'>
                            CUSTOMER PROFORMA INVOICE
                        </div>
                        <div className='d-flex justify-content-between mt-1-rem'>
                            <div>
                                <div className='font-weight-500'>
                                   PROFORMA INVOICE NUMBER: {props.data.customerProformaInvoiceNo}
                                </div>
                              
                            </div>
                            <div>
                                <div className='font-weight-500'>
                                    INVOICE DATE: {Moment(props.data.customerProformaInvoiceDate[0]).format('DD/MM/YYYY')}
                                </div>
                                
                            </div>
                        </div>
                        <div className='invoice_border  p-1-rem mt-1-rem'>
                            <div className='font-weight-500'>
                                NAME AND ADDRESS OF CUSTOMER:
                            </div>
                            <div>
                                {props.data.customerName}<br />
                                {props.data.customerAddress}<br />
                                {props.data.companyTelephone}<br />
                                {props.data.companyWebsite}<br />
                            </div>
                            <div className='font-weight-600 d-flex justify-content-center'>
                                CONTRACT NUMBER : {props.data.tradeNo}
                            </div>
                        </div>
                        <table className='invoice_border p-3-rem width-100l' style={{ border: '1px solid black' }}>
                            <thead className='invoice_border'>
                                <tr className='width-100l' style={{ border: '1px solid black' }}>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>S.NO</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>DESCRIPTION OF GOODS</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>QUANTITY</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>UNIT PRICE</th>
                                    <th clssName='br-right' style={{ border: '1px solid black' }}>AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid black' }}>
                                        1
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <div className='font-weight-600 uline'>{props.data.productName}</div>
                                        <div className='mt-1-rem font-weight-500'>COUNTRY OF ORIGIN : {props.data.loadPortCountry}</div>
                                        <div className='mt-1-rem'><span className='uline'>BILL OF LADING, DATE, QUANTITY :</span> <br />
                                            {props.data.billOfLadingNo}<br />
                                            {props.data.billOfLadingDate}<br />
                                            {props.data.quantity}<br />
                                        </div>
                                        <div className='mt-1-rem font-weight-500'>PORT OF LOADING : {props.data.loadPort}, {props.data.loadPortCountry}</div>
                                        <div className='font-weight-500'>PORT OF DISCHARGE : {props.data.loadPort}, {props.data.destinationPort}</div>
                                        <div className='font-weight-500'>TRADE TERM : {props.data.loadPort}</div>
                                        <div className='font-weight-500'>VESSEL NAME : {props.data.vesselName}</div>
                                        <div className='font-weight-500'>UNIT PRICE : {props.data.sallingPrice} USD</div>
                          
                                        <div className='font-weight-500'>
                                            {props.data.invoiceRemarks}
                                        </div>
                                        {props.data.is_FinalInvoice == true &&
                                            <>
                                                <div className='parameters_div'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>ITEM</th>
                                                                <th>ACTUAL RESULTS</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {props.data.actualFinalParameters.map((qt, k) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{qt.parameterName}</td>
                                                                        <td>
                                                                            {qt.parameterPer} %
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='mt-1-rem d-flex justify-content-between'>
                                                    <div className='uline'> DETAILS OF CALCULATION OF UNIT PRICE</div> <br />
                                                </div>

                                                <div className='parameters_div'>
                                                    {props.data.actualFinalParameters.map((qt, k) => {
                                                        if (qt.parameterAdjStatus) {
                                                            return (
                                                                <>
                                                                    <div>{qt.parameterName} :</div>
                                                                    <div>
                                                                        {qt.parameterFormula} = {qt.parameterQty!=0 ? qt.parameterQty + ' MT' : qt.parameterAmt + ' USD'}
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    })}
                                                </div>
                                                
                                            </>
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <span className='font-weight-500'>{props.data.quantity} MT</span>
                                    </td>
                                    <td style={{ border: '1px solid black' }}>
                                        <div className='font-weight-500'>{props.data.sellingUnitAmount} USD</div>
                                        <div className='mt-1-rem font-weight-600'>{props.data.loadPort}, {props.data.loadPortCountry}</div>
                                    </td>
                                    <td style={{ border: '1px solid black' }}>

                                        <span className='font-weight-500'>{props.data.amount} USD</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    <Row className='invoice_border'>
                    </Row>
                    <Row className='p-2-rem'>
                        <Col md={6}>
                            <div className='font-weight-600 font-15'>
                                FOR {props.data.companyName}
                            </div>
                            <div className='font-weight-600 font-15'>
                                AUTHORIZED SIGNATORY
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>

                        </Col>
                        <Col md={6}>
                            <div className='font-weight-400 font-13 font-weight-600' style={{ textAlign: 'right' }}>{props.data.companyName}</div>
                            <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>{props.data.customerAddress}</div>
                            <div className='font-weight-400 font-13 font-weight-500' style={{ textAlign: 'right' }}>Tel : {props.data.companyTelephone}</div>
                            <div className='font-weight-400 font-13' style={{ textAlign: 'right' }}>{props.data.companyWebsite}</div>
                        </Col>
                    </Row>

                </div>
            </ModalBody>
        </Modal>
    )
}
