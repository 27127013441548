import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import axios from 'axios';
import * as CONFIG from "../../../../../../helpers/config"
import { useMsal } from "@azure/msal-react";

export default function ListData(props) {
    console.log(props.is_DefaultSelected,'isprimary')
    console.log(props)
    const [shareholderType, setShareHolderType] = useState()
    const { instance } = useMsal();
    useEffect(()=>{
        axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_CONTACT_TYPE_LIST_SUPPLIER, {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            res.data.result.filter(x => x.supplierContactTypeID == props.values.contactTypeID).map((values)=>(
                console.log(values),
                setShareHolderType(values.contactTypeName)   
            ))
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
                
            }
        })  
    },[shareholderType])
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key}  onClick={props.editBank}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    props.is_DefaultAvailable?
                        props.is_Default?
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.removePrimary}>Remove Primary</span>
                        :
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.setPrimary}>Make Primary</span>
                    :
                    <span className={props.is_DefaultSelected?'disabled-contact-form font-weight-400 font-14 pl-10 mb-1-rem':'font-weight-400 font-14 pl-10 mb-1-rem'} id={props.values.key} onClick={props.is_DefaultSelected?'':props.setPrimary}>Make Primary</span>
                ),
            }
          ]}
        />
      );
    return (
        <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
            <td>{props.values.fullName}</td>
            <td>{props.values.designation}</td>
            <td>{props.values.mobileNo}</td>
            <td>{props.values.telephoneNo}</td>
            <td>{props.values.email}</td>
            <td>{props.values.contactValue == null || props.values.contactValue == '' ? shareholderType : props.values.contactValue }</td>
            <td style={{top:'5px', position:'relative'}}>{props.is_Default &&
                    <span className='contact-form-primary-badge'>Primary</span>
                }
            </td>
            <td>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <img src = '/assets/images/Dot_icon.svg' />
                    </Space>
                </a>
            </Dropdown>
            </td>
        </tr>
    )
}
