import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Form, Card, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "moment"
import axios from 'axios';
import * as CONFIG from "../../../helpers/config"
import * as FormFields from "../../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

export default function VesselInformation(props) {
    const { instance } = useMsal();
    const location = useLocation()
    let navigate = useNavigate();
    const tradeDataForFreight = props.tradeDataForFreight
    const tab = props.tab
    const [disabled, setisDisabled] = useState(false)
    console.log(tradeDataForFreight, 'trade data')
    const [tradeid, settradeid] = useState(); // replace 1 by tradedata.tradedetails.id
    const [tradedata, settradedata] = useState([]); // replace 1 by tradedata.tradedetails.id
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [incoTermsLabel, setIncotermsLabel] = useState('FOB')
    const [modeOfTransportLabel, setModeOfTransportLabel] = useState('By Sea')
    const [dateRange, setDateRange] = useState([null, null]);
    const [doc1, setdoc1] = useState(false)
    const [startDate, endDate] = dateRange;
    const MySwal = withReactContent(Swal)
    const [isdisabled, setisdisabled] = useState(false)

    useEffect(() => {
        getTradeData()
        if(tab == 2){
            setisDisabled(true)
            setisdisabled(true)
        }
    }, [])
    const getTradeData = () => {
        console.log('inside ')
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeDataForFreight.tradeID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                settradedata(res.data.result[0]);
                formik.values.tradeEnquiryID = res.data.result[0].tradedetails.tradeEnquiry.tradeEnquiryID
                formik.values.customerID = res.data.result[0].tradedetails.tradeEnquiry.customerID
                formik.values.supplierID = res.data.result[0].tradedetails.supplier.supplierID
                formik.values.TradeDate = ""
                formik.values.tradeID = res.data.result[0].tradedetails.tradeID
                formik.values.modeOfTransportID = res.data.result[0].tradedetails.modeOfTransportID
                formik.values.incotermsID = res.data.result[0].tradedetails.incotermsID
                formik.values.shipmentSize = res.data.result[0].tradedetails.shipmentSize
                formik.values.loadPort = res.data.result[0].tradedetails.loadPort
                formik.values.loadPortCountry = res.data.result[0].tradedetails.loadPortCountry
                formik.values.loadQuantityInspectionAgency = res.data.result[0].tradedetails.loadQuantityInspectionAgency
                formik.values.loadQualityInspectionAgency = res.data.result[0].tradedetails.loadQualityInspectionAgency
                formik.values.loadPortAgency = res.data.result[0].tradedetails.loadPortAgency
                formik.values.loadPortRate = res.data.result[0].tradedetails.loadPortRate
                formik.values.fromLaycanPeriod = res.data.result[0].tradedetails.fromLaycanPeriod
                formik.values.toLaycanPeriod = res.data.result[0].tradedetails.toLaycanPeriod
                formik.values.destinationPort = res.data.result[0].tradedetails.destinationPort
                formik.values.destinationPortCountry = res.data.result[0].tradedetails.destinationPortCountry
                formik.values.destinationQuantityInspectionAgency = res.data.result[0].tradedetails.destinationQuantityInspectionAgency
                formik.values.destinationQualityInspectionAgency = res.data.result[0].tradedetails.destinationQualityInspectionAgency
                formik.values.destinationPortAgency = res.data.result[0].tradedetails.destinationPortAgency
                formik.values.destinationPortRate = res.data.result[0].tradedetails.destinationPortRate
                formik.values.etaLoadPort = res.data.result[0].tradedetails.etaLoadPort
                formik.values.ETADischargePort = res.data.result[0].tradedetails.ETADischargePort
                formik.values.DespatchDemurrage = res.data.result[0].tradedetails.DespatchDemurrage
                formik.values.tradeNo = res.data.result[0].tradedetails.tradeNo
                formik.values.OceanFreight = res.data.result[0].tradedetails.oceanFreight
                formik.values.Remarks = res.data.result[0].tradedetails.remarks
                formik.values.Demurrage = res.data.result[0].tradedetails.demurrage
                formik.values.Dispatch = res.data.result[0].tradedetails.dispatch
                formik.values.vesselName= res.data.result[0].tradedetails.vesselName
                formik.values.eTALoadPort= res.data.result[0].tradedetails.etaLoadPort
                formik.values.ETADischargePort= res.data.result[0].tradedetails.etaDischargePort
                formik.values.VesselDespatch= res.data.result[0].tradedetails.vesselDespatch
                formik.values.VesselDemurrage= res.data.result[0].tradedetails.vesselDemurrage
                setIsDataAvailable(true)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    useEffect(() => {
        console.log(tradedata)
    }, [tradedata])

    const formik = useFormik({
        initialValues:
        {
            tradeEnquiryID: 0,
            customerID: 0,
            supplierID: 0,
            TradeDate: "",
            modeOfTransportID: 1,
            incotermsID: 2,
            shipmentSize: '',
            loadPort: "",
            loadPortCountry: "",
            loadQuantityInspectionAgency: "",
            loadQualityInspectionAgency: "",
            loadPortAgency: "",
            loadPortRate: "",
            fromLaycanPeriod: "",
            toLaycanPeriod: "",
            destinationPort: "",
            destinationPortCountry: "",
            destinationQuantityInspectionAgency: "",
            destinationQualityInspectionAgency: "",
            destinationPortAgency: "",
            destinationPortRate: "",
            etaLoadPort: false,
            ETADischargePort: false,
            DespatchDemurrage: "",
            OceanFreight: "",
            Demurrage: "",
            Dispatch: "",
            Remarks: "",
            vesselName: "",
            eTALoadPort : "",
            ETADischargePort: "",
            VesselDespatch: "",
            VesselDemurrage: ""
        }

    })
    const  updatevesselinfo = ()=>{
        setisdisabled(true)
        const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "tradeid": tradeDataForFreight.tradeID, // tradeid,
                "vesselName": formik.values.vesselName,
                "eTALoadPort": formik.values.eTALoadPort,
                "ETADischargePort": formik.values.ETADischargePort,
                "VesselDespatch": parseFloat(formik.values.VesselDespatch),
                "VesselDemurrage": parseFloat(formik.values.VesselDemurrage)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_VESSEL_INFO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            props.refreshList()
            MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Vessel information updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                        cancelButtonText: 'Cancel'
                    }
                ).then(() => {
                    // navigate("/trade-transactions", {state:{ tab: tab }});
                });
                setisdisabled(false)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const handleLoadETA = (date)=>{
        formik.values.eTALoadPort = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        setdoc1(!doc1)
    }
    const handleUpdateETA = (date)=>{
        formik.values.ETADischargePort = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        setdoc1(!doc1)
    }
    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <div className='w-100'>
                    <div className='p-4 mx-3 mt-4'>
                        
                    <h4 class="font-16 font-weight-600 color-707895">UPDATE VESSEL INFORMATION</h4>
                    </div>

                    <div className='add-edit-wrapper'>
                        <div className='card card-body p-4 br-8 mb-4'>
                        <Form>
                                        <Row>
                                            <FormFields.InputField
                                                md={3}
                                                name='vesselName'
                                                label='Vessel Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.vesselName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={disabled}
                                            />
                                            <FormFields.DatePick
                                                md={3}
                                                name='eTALoadPort'
                                                label='ETA - Load Port'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleLoadETA}
                                                isRequired={false}
                                                val={formik.values.eTALoadPort}
                                                disabled={disabled}
                                            />
                                            <FormFields.DatePick
                                                md={3}
                                                name='ETADischargePort'
                                                label='ETA - Destination Port'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleUpdateETA}
                                                isRequired={false}
                                                val={formik.values.ETADischargePort}
                                                disabled={disabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='VesselDespatch'
                                                label='Despatch'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.VesselDespatch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={disabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='VesselDemurrage'
                                                label='Demmurage'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.VesselDemurrage}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={disabled}
                                            />
                                        </Row>
                                    </Form>
                                    <Card.Footer className='form-card-footer right-auto left-auto d-flex fixed-bottom p-2-rem'>
                                    
                                    <span className='pl-10'><BlueButton value='Submit' clickEvent={updatevesselinfo} disabled={isdisabled} /></span>
                                </Card.Footer>
                        </div>
                    </div>
                    
                </div>

            </div>
        </>
    )
}
