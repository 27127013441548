import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import axios from 'axios'
import * as CONFIG from ".././../helpers/config"
import { Row, Col, Card } from 'react-bootstrap'
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import { useFormik } from 'formik'
import * as FormFields from "../../components/formcomponents"
import Moment from "moment";
import { Link, NavLink } from 'react-router-dom';


export default function FinalCostSubmit(props) {
    console.log('trade data inside seccond time')
    console.log(props.tradingdata)
    console.log(props.sendFormikData)
    const [total, setTotal] = useState(0)
    const [sellingpricetotal, setsellingpricetotal] = useState(0)
    const [margin, setmargin] = useState(0)
    const [marginper, setmarginper] = useState(0)
    const handlefirstsetpedit = () => {
        props.setedit()
        //setShowModal(true)
    }
    useEffect(() => {
        const total = parseInt(props.sendFormikData.BuyingPrice) + parseInt(props.sendFormikData.OceanFreight) + parseInt(props.sendFormikData.AddressCommission) + parseInt(props.sendFormikData.FinancePrice) +
            parseInt(props.sendFormikData.InsuranceCostCargo) + parseInt(props.sendFormikData.InsuranceCostCL) + parseInt(props.sendFormikData.InsuranceCostOthers)
        setTotal(total)

        var total1 = 0;
        props.tradingdata.map((list, k) => {
            list.tradedetails.tradeProductDetail.map((pro, p) => {
                total1 = total1 + (pro.productSellingPrice * pro.quantity)
            })
        })
        setsellingpricetotal(total1);

        var margin = total1 - total
        setmargin(margin);

        var marginper = (margin / total1) * 100;
        setmarginper(marginper);

        console.log(total1, 'selling price total')
    })
    const savecostsheet = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "tradeid": parseInt(props.id), // tradeid,
            "oceanPrice": parseInt(props.sendFormikData.OceanFreight),
            "addressCommission": parseInt(props.sendFormikData.AddressCommission),
            "insuranceCargoCost": parseInt(props.sendFormikData.InsuranceCostCargo),
            "insuranceCLCost": parseInt(props.sendFormikData.InsuranceCostCL),
            "insuranceOthersCost": parseInt(props.sendFormikData.InsuranceCostOthers),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                props.toggleCost()
            })
    }
    return (
        <div>
            <div>
                <div className='d-flex justify-content-between align-items-center mt-4 mx-3'>
                    Pre-Shipment Estimated Cost
                    <button className='border-0 bg-transparent' onClick={handlefirstsetpedit}>
                        <img src='/assets/images/supports/penIcon.svg' />
                    </button>
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    {props.tradingdata.map((list, k) => (

                        <>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-600 color-243448'>{list.enquiryNo}</div>
                                <div className='font-weight-400 color-697382'>{Moment(list.tradeDate).format("DD/MM/yyyy")} </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-600 color-697382'>{list.tradedetails.customer.customerCode}</div>
                                <div className='font-weight-400 color-697382'>{list.tradedetails.customer.customerName}</div>
                                {list.tradedetails.tradeCustomerPaymentDetail.map((pay, j) => (
                                    <div className='font-weight-400 color-697382'>
                                        {pay.paymentType.typeName}
                                    </div>
                                ))}

                            </div>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-600 color-697382'>{list.tradedetails.supplier.supplierCode}</div>
                                <div className='font-weight-400 color-697382'>{list.tradedetails.supplier.supplierName}</div>
                                {list.tradedetails.tradeSupplierPaymentDetail.map((pay, j) => (
                                    <div className='font-weight-400 color-697382'>
                                        {pay.paymentType.typeName}
                                    </div>
                                ))}
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-400 color-243448'>{list.tradedetails.tradeEnquiry.contractType.contractTypeName} {list.tradedetails.incoterms.incotermsName} {list.tradedetails.modeOfTransport.modeOfTransportName} </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-600 color-243448'>Laycan</div>
                                <div className='font-weight-400 color-697382'>{Moment(list.tradedetails.fromLaycanPeriod).format("DD/MM/yyyy")} - {Moment(list.tradedetails.toLaycanPeriod).format("DD/MM/yyyy")} </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='font-weight-600 color-243448'>Lord Port</div>
                                <div className='font-weight-400 color-697382'>{list.tradedetails.loadPort} {list.tradedetails.loadPortCountry}</div>
                            </div>
                        </>
                    ))


                    }
                </div>

                <div>
                    <table className="table table-text mt-1-rem">
                        <thead>
                            <tr className='bg-F8F9FA font-weight-600 color-243448'>
                                <th>SL No</th>
                                <th>Description</th>
                                <th>Estimated Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>1</div></td>
                                <td>Buying Price (FOB)</td>
                                <td>{props.sendFormikData.BuyingPrice}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>2</div></td>
                                <td>Ocean Freight</td>
                                <td>{props.sendFormikData.OceanFreight}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>3</div></td>
                                <td>Address Commission</td>
                                <td>{props.sendFormikData.AddressCommission}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>4</div></td>
                                <td>Finance Cost</td>
                                <td>{props.sendFormikData.FinancePrice}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>5</div></td>
                                <td>Insurance Cost(Cargo)</td>
                                <td>{props.sendFormikData.InsuranceCostCargo}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>6</div></td>
                                <td>Insurance Cost(CL)</td>
                                <td>{props.sendFormikData.InsuranceCostCL}</td>
                            </tr>
                            <tr className='product_list_row d-felx align-middle'>
                                <td><div className='d-flex flex-column align-items-center list-id-pill'>7</div></td>
                                <td>Insurance Cost(Others)</td>
                                <td>{props.sendFormikData.InsuranceCostOthers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div>
                    <div className='d-flex flex-column'>
                        <div className='font-weight-600 color-243448'>Estimated Total Amount : {total}</div>
                        <div className='font-weight-400 color-697382'>Selling Price : {sellingpricetotal}</div>
                        <div className='font-weight-400 color-697382'>Margin :{margin} </div>
                        <div className='font-weight-400 color-697382'>Margin % (Margin/Selling Price*100) : {marginper}%</div>
                    </div>

                </div>
                <div>
                    <Row>
                        <div className='form-card-footer p-2-rem d-flex' style={{ position: 'relative' }}>
                            <span><BlueBorderButton value='Cancel' /></span>
                            <span className='pl-10'>
                                    <BlueButton value='Save' clickEvent={savecostsheet} />
                            </span>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    )

}