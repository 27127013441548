import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form } from "react-bootstrap";
import * as FormFields from "../../../../components/formcomponents"
import { useFormik } from 'formik'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import * as CONFIG from '../../../../helpers/config'
import { PaymentTypeList } from '../../../../helpers/API/Api';
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';




function PaymentMethodModal(props) {
    console.log(props)
    const [modal, setModal] = useState(true);
    const [checkError, setCheckError] = useState(false) 
    const [checkErrorPer, setCheckErrorPer] = useState(false) 
    const [paymentMethod, setPaymentMethod] = useState()
    const { instance } = useMsal();
    const [paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [overAllTotal, setOverAllTotal] = useState()
    const [trackCheck, setTrackCheck] = useState([])
    const [isPaymentset, setispaymentset] = useState(false)


    useState(()=>{
       
    },[])

    const toggle = () => {
        setModal(!modal)
        props.close();
      }

    const formik = useFormik({
        initialValues:
        {
            supplierPaymentTypeID: 4,
        },
    })


    const handleSaveEvent = () => {
        console.log(final_json)
        let dataToSave = []
        if(final_json != null){
            let data = [...final_json]
            let dataToSave = []
            for(let i = 0; i < data.length; i++){
                if (props.tradeInitiateEdit == true){
                    if(data[i].isCheck == true){
                        dataToSave.push(data[i])
                    }
                    else if(data[i].isCheck == false && data[i].tradeSupplierPaymentDetailID != null){
                        dataToSave.push(data[i])
                    }
                }
                else{
                    if(data[i].isCheck == true){
                        dataToSave.push(data[i])
                    }
                }
               
            }
            console.log(dataToSave, 'Handle Save')
            props.handleDataFromModal(dataToSave)
            toggle()
        }
      
        //toggle()
    }

    const handleValues = (data) => {    
        console.log(data)
        let checkValidation = []
        for (let i = 0; i < data.length; i++){
            if(data[i].isCheck == true){
                checkValidation.push(data[i])
            }
        }
        let totalPer = 0
        if(data != null && data.length > 0){
            data.map((amt,k)=>{
                totalPer += amt.supplierPaymentPer
            })
        }

        console.log(totalPer)
     
        if(checkValidation.length > 0 && totalPer != 100){
            console.log('condition 1')
            setCheckError(false)
            setCheckErrorPer(true) 
        }
        else if(checkValidation.length == 0){
            console.log('condition 2')
            setCheckError(true) 
        }

        else{
            console.log('condition 3')
            setCheckError(false)
            setCheckErrorPer(false) 
        }
    } 

    useEffect(()=>{

    },[paymentMethod])
 

    useEffect(()=>{
        console.log('inside')
        let final = []
        let trackCheck = []
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_TMS_PAYMENT_TERMS_SUPPLIER, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user":CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log('Use Effect Fin')
                console.log(res.data.result)
                setPaymentType(res.data.result)
                res.data.result.map((re,k)=>{
                    let fin =[]
                    let fin1 = {
                        "supplierPaymentTypeID": re.paymentTypeID,
                        "supplierPaymentAmount" : 0,
                        "supplierPaymentPer": 0,
                        "supplierPaymentTypeName": re.typeName,
                        "isCheck": false
                    }
                    let track = {
                        "supplierPaymentTypeID": re.paymentTypeID,
                        "isCheck": false
                    }
                    trackCheck.push(track)
                    final.push(fin1)
                }
                )
                if(props.dataFromModal != null){
                    console.log(final, "final list")
                    console.log(props.dataFromModal, "Saved list")
                    for(let i = 0; i < final.length; i++){
                        for(let x = 0; x < props.dataFromModal.length; x++){
                            if(props.dataFromModal[x].supplierPaymentTypeID == final[i].supplierPaymentTypeID){
                                final[i] = props.dataFromModal[x]
                            }
                        }
                    }
                    console.log(final, "final list after")
                    console.log(props.dataFromModal, "Saved list after")
                }
                setTrackCheck(trackCheck)
                setFinal(final)
                setispaymentset(true)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
        }else{
            setIsFirst(false)
        }
    },[])
    
    const handleCheck = (event) => {
        let trackChec = [...final_json]
        if(event.target.checked){
            for(let i = 0; i < trackChec.length; i++){
                if(trackChec[i].supplierPaymentTypeID == event.target.value){
                    trackChec[i].isCheck = true
                    if(props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null){
                        console.log('check condition')
                        trackChec[i].is_Active = true
                        trackChec[i].is_Deleted = false
                    }
                }
            }
        }
        else{
            for(let i = 0; i < trackChec.length; i++){
                if(trackChec[i].supplierPaymentTypeID == event.target.value){
                    trackChec[i].isCheck = false
                    trackChec[i].supplierPaymentAmount = 0
                    trackChec[i].supplierPaymentPer = 0
                    if(props.tradeInitiateEdit == true && trackChec[i].tradeSupplierPaymentDetailID != null){
                        console.log('check condition')
                        trackChec[i].is_Active = false
                        trackChec[i].is_Deleted = true
                    }
                }
                
            }
        }
        setFinal(trackChec)
        console.log(trackCheck)
    };

    const handlePercentageValues = (e) => {
        let final_data = [...final_json]
        const supplierPaymentPer = parseFloat(e.currentTarget.value)
        const id = parseInt(e.target.id);
         final_json.map((final,k)=>{
            if(final.supplierPaymentTypeID == id){
                console.log(final_data[k])
                final_data[k].supplierPaymentPer = parseInt(supplierPaymentPer)
                console.log(final_data[k].supplierPaymentPer)
                var supplierPaymentAmount = ((parseInt(supplierPaymentPer) / 100) * (parseInt((props.currentSupplyPlan.productBuyingPrice) * (props.currentSupplyPlan.quantity))))
                final_data[k].supplierPaymentAmount = parseInt(supplierPaymentAmount)
            }
        })
        console.log(final_data)
        setFinal(final_data)
    }
    useEffect(()=>{
        console.log('check')
        console.log(props)
        if(props.currentSupplyPlan != null && props.currentSupplyPlan.productBuyingPrice){
            console.log('check')
            var sum = props.currentSupplyPlan.productBuyingPrice * props.currentSupplyPlan.quantity
            setOverAllTotal(parseFloat(sum).toFixed(2))
        }
    },[])

    useEffect(()=>{
        handleValues(final_json)
    },[final_json])


    const handleDefaultChecked = (list) => {
     
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className = 'payment-method-modal'>
        <ModalHeader toggle={toggle} className='p-40'>
            <div className = 'd-flex justify-content-between '>
                <div className='font-16 font-weight-600 color-707895'>
                    PAYMENT METHOD
                </div>
            </div>
        </ModalHeader>
        <ModalBody  className = 'service-request-modal-wrapper p-40'>
            <>
            <div className =''>
            
                {isPaymentset ? 
                                final_json.map((list,k) => {
                                    return(
                                        <>
                                            <div className = {'bg-light  d-flex justify-content-between  my-2 p-4 align-items-center'}>
                                                <div>
                                                <Form.Check
                                                    className = ''
                                                    type='checkbox'
                                                    name={list.supplierPaymentTypeID}
                                                    label={list.supplierPaymentTypeName}
                                                    id = {list.supplierPaymentTypeName}
                                                    onChange={handleCheck}
                                                    value={list.supplierPaymentTypeID}
                                                    defaultChecked = {list.isCheck}
                                                
                                                />
                                                </div>
                                                {list.isCheck == true ? 
                                                    <>
                                                    <div className = 'd-flex align-items-center'>
                                                        <Form.Control
                                                            type="text"
                                                            className = 'payment-terms-width'
                                                     
                                                            name={list.supplierPaymentTypeName}
                                                            id = {list.supplierPaymentTypeID}
                                                            onChange = {handlePercentageValues}
                                                            defaultValue = {list.supplierPaymentPer}
                                                        />
                                                        <div className = 'ms-2'>
                                                            (%)
                                                        </div>
                                                    </div>
                                                    
                                                
                                                    {/* <div className = 'font-weight-600'>{final_json[k].supplierPaymentAmount}</div> */}
                                                </>         
                                                :
                                                ''
                                                }
                                    
                                            </div>
                                            </>
                                    )
                                })
                :
                ''
                }
                <div className = 'd-flex justify-content-end'>
                    <p>Total Amount: <b><NumberFormat value={parseInt(overAllTotal)} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'.00'}/></b></p>
                </div>
            </div>
            </>
            
            {checkError && 
                <span className='input_error'>Please select a payment method before proceeding!</span>
            }
            {checkErrorPer && 
                <span className='input_error'>Total percentage should equal 100!</span>
            }
        </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_supplier_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={handleSaveEvent} disabled={checkError == false && checkErrorPer == false ? false : true } /></span>
            </ModalFooter>
        </Modal>
    )
}

export default PaymentMethodModal