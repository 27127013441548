import React from 'react'
import UserList from './UserList'

export default function List(props) {
    return (
      <div class="col-lg-12">
      <div class="table-responsive-sm  card card-body">
      <table className="table tCustom table-text mt-1-rem">
            <thead >
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
            </thead>
            <tbody>
                {props.data.map((li,k)=>{
                    return(
                      <UserList list={li}
                      index={k}
                      handleEdit = {props.handleEdit}
                      refreshList = {props.refreshList}
                      />
                     
                    )
                })}
            </tbody>
    </table>
    </div>
    </div>
    )
}
