import React, {useState, useEffect} from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as FormFields from "../../../../components/formcomponents"
import * as ButtonComponent from "../../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import { AddNewButton } from '../../../../components/buttoncomponents'
import CompanyDirectors from './Company Directors/CompanyDirectors';
import ShareHolder from './Share Holder/ShareHolder';
import SubsidiaryCompanyType from './Subsdiary Company Type/SubsidiaryCompanyType';

function DirectorsInformation(props) {
    console.log(props)
    const [key, setKey] = useState(1);
    const [isDisabled, setIsDisabled] = useState(true)
    const [isReveneMet, setIsRevenueMet] = useState(false);
    const [isBankMet, setIsBankMet] = useState(false);
    const [revenueData, setRevenueData] = useState([]);
    const [handleError, setHandleError] = useState(true)
 
    const [directorList, setDirectortList] = useState([]);
    const [shareholderList, setShareholderList] = useState([]);
    const [subsidiaryCompanyList, setsubsidiaryCompanyList] = useState([]);

    const [handleErrorDirector, setHandleErrorDirector] = useState(true)
    const [handleErrorShareholder, setHandleErrorShareholder] = useState(true)
    const [handleErrorSubsidiary , setHandleErrorSubsidiary ] = useState(true)

    const [docs, setDocs] = useState([]);

    useEffect(()=>{
        console.log(directorList, shareholderList, subsidiaryCompanyList, 'list of data')
    },[directorList, shareholderList, subsidiaryCompanyList])

    useEffect(()=>{
        if(isReveneMet && isBankMet){
            console.log('inside use effet')
            setIsDisabled(false)
        }
    },[isReveneMet, isBankMet])

    useEffect(()=> {
        if(props.isEdit == true){
            setHandleError(false)
            setHandleErrorDirector(false)
            setHandleErrorShareholder(false)
            setHandleErrorSubsidiary(false)
        }
    },[props])

    useEffect(() => {
        console.log(props,'in the props')
        if(props.isEdit == true ){
            let dirctList = []
            let shareholdList = []
            let subsidList = []
            dirctList = [...props.dataForEdit.supplierdata.supplierCompanyDirector]
            dirctList.map((list,k)=>{
                dirctList[k].key = list.supplierCompanyDirectorID
            })
            shareholdList = [...props.dataForEdit.supplierdata.supplierShareholder]
            shareholdList.map((list,k)=>{
                shareholdList[k].key = list.supplierShareholderID
            })
            subsidList = [...props.dataForEdit.supplierdata.supplierSubsidiaries]
            subsidList.map((list,k)=>{
                subsidList[k].key = list.supplierSubsidiaryCompanyID
            })
            setDirectortList(dirctList)
            setShareholderList(shareholdList)
            setsubsidiaryCompanyList(subsidList)
        }
    }, [props])

    useEffect(() => {
        if(props.isBackClicked == true ){
            console.log(props)
            setDirectortList(props.supplierdata[0])
            setShareholderList(props.supplierdata[1])
            setsubsidiaryCompanyList(props.supplierdata[2])
            setHandleError(false)
            setHandleErrorDirector(false)
            setHandleErrorShareholder(false)
            setHandleErrorSubsidiary(false)
        } 
    }, [])
    
    useEffect(()=>{
        if(props.isDirectorSet == true){
            console.log('next button')
            console.log(props)
            setDirectortList(props.supplierdata[0])
            setShareholderList(props.supplierdata[1])
            setsubsidiaryCompanyList(props.supplierdata[2])
            setHandleError(false)
            setHandleErrorDirector(false)
            setHandleErrorShareholder(false)
            setHandleErrorSubsidiary(false)
        }
    },[])
    const addDirectorToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...directorList]
        list.push(data);
        k++
        setKey(k)
        setDirectortList(list)
        setIsBankMet(true)
    }
    const addShareholderToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...shareholderList]
        list.push(data);
        k++
        setKey(k)
        setShareholderList(list)
        setIsBankMet(true)
    }
    const addSubsidiaryCompanyToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...subsidiaryCompanyList]
        list.push(data);
        k++
        setKey(k)
        setsubsidiaryCompanyList(list)
        setIsBankMet(true)
    }
    



    const setPrimaryDirector=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        directorList.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setDirectortList(cusList)
    }
    const setPrimaryShareholder=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        shareholderList.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setShareholderList(cusList)
    }
    const setPrimarySubsidiary=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        subsidiaryCompanyList.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setsubsidiaryCompanyList(cusList)
    }
    
    const removePrimaryDirector=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        directorList.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setDirectortList(cusList)
    }
    const removePrimaryShareholder=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        shareholderList.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setShareholderList(cusList)
    }
    const removePrimarySubsidiary=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        subsidiaryCompanyList.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setsubsidiaryCompanyList(cusList)
    }


    const editDirectorToList =(data)=>{
        const list = data;
        const supplierList = [...directorList]
        directorList.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setDirectortList(supplierList)
    }
    const editShareholderToList =(data)=>{
        const list = data;
        const supplierList = [...shareholderList]
        shareholderList.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setShareholderList(supplierList)
    }
    const editSubsidiaryToList =(data)=>{
        const list = data;
        const supplierList = [...subsidiaryCompanyList]
        subsidiaryCompanyList.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setsubsidiaryCompanyList(supplierList)
    }



    
    const requiredFilled = ()=>{
        setIsRevenueMet(true)
    }

    const requiredFailed = ()=>{
        setIsRevenueMet(false)
    }

    const setFinance = (data)=>{
        setRevenueData(data)
    }

    const saveDocuments = (doc1, doc2, doc3)=>{
        let docs = [];
        docs['audit'] = doc1;
        docs['refer'] = doc2;
        docs['memo'] = doc3;
        setDocs(docs);
    }
    const moveToNext = ()=>{
        console.log(directorList, shareholderList, subsidiaryCompanyList, 'list of data inside mvoeToNext')
        let list = [];
        let directorsFinalArray = []
        let shareholderFinalArray = []
        let subsidiaryFinalArray = []
        directorsFinalArray = [...directorList]
        shareholderFinalArray = [...shareholderList]
        subsidiaryFinalArray = [...subsidiaryCompanyList]
        list = [directorsFinalArray, shareholderFinalArray, subsidiaryFinalArray]
        console.log(list)
        props.onNext(list,5)
    }

    const backClickEvent = () => {
        props.backClickEvent(3)
    }
    const handleErrorValidation = () => {
        setHandleError(false)
    }
    const handleSettingError = () => {
        setHandleError(true)
    }


    const handleValidationDirector = () => {
        console.log('validation Director')
        setHandleErrorDirector(false)
    }

    const handleValidationShareholder = () => {
        console.log('validation Shareholder')
        setHandleErrorShareholder(false)
    }

    const handleValidationSubsidiary = () => {
        console.log('validation Subsidiary')
        setHandleErrorSubsidiary(false)
    }

  return (
    <div>
            <Card style={{background: 'transparent'}}>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body mb-2-rem' style={{background: '#fff'}}>
                        <CompanyDirectors handleValidationDirector = {handleValidationDirector} setErrors = {handleSettingError}  handleError = {handleErrorValidation}  addDirectorToList={addDirectorToList} editDirectorToList={editDirectorToList} directorList = {directorList} isDirectorSet = {props.isDirectorSet} setPrimaryDirector={setPrimaryDirector} removePrimaryDirector={removePrimaryDirector}  isEdit = {props.isEdit} isBackClicked = {props.isBackClicked}/>
                    </Card.Body>
                    <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                        <ShareHolder handleValidationShareholder = {handleValidationShareholder} setErrors = {handleSettingError} handleError = {handleErrorValidation} addShareholderToList={addShareholderToList} editShareholderToList={editShareholderToList} shareholderList = {shareholderList} isDirectorSet = {props.isDirectorSet} setPrimaryShareholder={setPrimaryShareholder} removePrimaryShareholder={removePrimaryShareholder}  isEdit = {props.isEdit} isBackClicked = {props.isBackClicked}/>
                    </Card.Body>
                    <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                        <SubsidiaryCompanyType handleValidationSubsidiary = {handleValidationSubsidiary} setErrors = {handleSettingError} handleError = {handleErrorValidation} addSubsidiaryCompanyToList={addSubsidiaryCompanyToList} editSubsidiaryToList={editSubsidiaryToList} isDirectorSet = {props.isDirectorSet} subsidiaryCompanyList = {subsidiaryCompanyList} setPrimarySubsidiary={setPrimarySubsidiary} removePrimarySubsidiary={removePrimarySubsidiary}  isEdit = {props.isEdit} isBackClicked = {props.isBackClicked}/>
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span><BlueBorderButton value='Back' clickEvent = {backClickEvent}/></span>
                    <span className='pl-10'>
                        
                        <BlueButton value='Next' 
                        clickEvent= {moveToNext} 
                        // disabled = {handleErrorDirector == false  && handleErrorShareholder == false && handleErrorSubsidiary == false ? false : true}
                        />
                    </span>
                </Card.Footer>
            </Card>
    </div>
  )
}

export default DirectorsInformation