import React, {useState, useEffect} from 'react'
import {Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import Contactinformation from './Contact Information';


function Index(props) {
    console.log(props, 'contact page')
    const [key, setKey] = useState(1);
    const [isDisabled, setIsDisabled] = useState(true)
    const [isReveneMet, setIsRevenueMet] = useState(false);
    const [isBankMet, setIsBankMet] = useState(false);
    const [directorList, setDirectortList] = useState([]);
    const [is_DefaultAvailable, setIsPrimaryAvailable] = useState(false)
    const [handleError, setHandleError] = useState(true)


    useEffect(()=>{
        console.log(directorList,'list of data')
    },[directorList])
 
    useEffect(()=>{
            if(props.isContactSet == true){
                console.log('next button')
                console.log(props)
                setHandleError(false)
                setDirectortList(props.supplierdata)
            }
        },[])
    useEffect(()=>{
        if(isReveneMet && isBankMet){
            console.log('inside use effet')
            setIsDisabled(false)
        }
    },[isReveneMet, isBankMet])

    useEffect(()=> {
        if(props.isEdit == true){
            setHandleError(false)
        }
    },[])

    useEffect(() => {
        console.log(props,'in the props')
        if(props.isEdit == true ){
            let dirctList = []
            dirctList = [...props.dataForEdit.supplierdata.supplierContactInformation]
            dirctList.map((list,k)=>{
                dirctList[k].key = list.supplierContactInformationID
            })
            setDirectortList(dirctList)
        }
    }, [])

    useEffect(() => {
        if(props.isBackClicked == true ){
            console.log(props)
            setHandleError(false)
            setDirectortList(props.supplierdata)
        } 
    }, [])

    const addDirectorToList = (data) =>{
        let k = key
        data.key = key;
        let list =[...directorList]
        list.push(data);
        k++
        setKey(k)
        let listPrimary = []
        if(list != null && list.length > 0){
            for(let i = 0; i < list.length; i++){
                if(list[i].is_Default == true){
                    listPrimary.push(list[i])
                }
            }
            if(listPrimary.length == 0){
                list[0].is_Default = true
            }
        }
        setDirectortList(list)
        setIsBankMet(true)
    }

    const setPrimaryDirector=(e)=>{
        const key = parseInt(e.target.id)
        console.log(key)
        let cusList = []
        directorList.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        console.log(cusList)
        setDirectortList(cusList)
        setIsPrimaryAvailable(true)
    }

    const removePrimaryDirector=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        directorList.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        console.log(cusList)
        setDirectortList(cusList)
    }

    const editDirectorToList =(data)=>{
        const list = data;
        const supplierList = [...directorList]
        directorList.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setHandleError(false)
        setDirectortList(supplierList)
    }

    const moveToNext = ()=>{
        console.log(directorList, 'list of data inside mvoeToNext')
        props.onNext(directorList,2)
    }

    const backClickEvent = () => {
        props.backClickEvent(0)
    }
    const handleErrorValidation = () => {
        setHandleError(false)
    }
    const handleSettingError = () => {
        setHandleError(true)
    }
  return (
    <div>
            <Card style={{background: 'transparent'}}>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body mb-2-rem' style={{background: '#fff'}}>
                        <Contactinformation setErrors = {handleSettingError} handleError = {handleErrorValidation}  addDirectorToList={addDirectorToList} editDirectorToList={editDirectorToList} directorList = {directorList} setPrimary={setPrimaryDirector} removePrimary={removePrimaryDirector} isContactSet = {props.isContactSet}  isEdit = {props.isEdit} isBackClicked = {props.isBackClicked}/>
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer p-2-rem d-flex'>
                    <span><BlueBorderButton value='Back' clickEvent = {backClickEvent}/></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext} disabled = {props.isEdit == true ? false : handleError == true ? true : false}/></span>
                </Card.Footer>
            </Card>
    </div>
  )
}

export default Index