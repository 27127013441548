import React, { useState, useEffect } from 'react'
import * as CONFIG from "../../helpers/config"
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import moment from "moment"
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap';
import ViewMeeting from './ViewMeeting';

export default function MyTasks() {
    const MySwal = withReactContent(Swal)
    const { instance } = useMsal();
    const [tasklist, settasklist] = useState([])
    const [isloader, setisLoader] = useState(true)
    const [meetingid, setmeetingid] = useState(0)
    const [viewstatus, setviewstatus] = useState(false)

    useEffect(() => {
       getList()
    }, [])
    const getList=() =>{
        setisLoader(true)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_TEAM_TASK_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settasklist(res.data.result);
                setisLoader(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const handleChange = (e)=>{
        if(e.target.checked){
            MySwal.fire(
                {
                    icon: 'warning',
                    title: 'Tasks',
                    html: 'Are you sure you want to change the task status to completed?',
                    type: 'success',
                    confirmButtonText: 'Yes',
                    confirmButtonClass: "btn btn-primary",
                    cancelButtonText: 'Cancel',
                    showCancelButton: true

                }).then((result) => {
                    if(result.isConfirmed){const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": parseInt(e.target.id),
                        "is_Completed": true
                    }
                    axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CHANGE_TASK_STATUS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            console.log(res.data.result)
                            getList()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                    }else{
                        getList()
                    }
                });
        }
    }
    const handleView = (e)=>{
        setmeetingid(e.target.id)
        toggleview()
    }
    const toggleview = () =>{
        setviewstatus(!viewstatus)
    }
    return (
        <div>
            {viewstatus &&
                <ViewMeeting isOpen={viewstatus} id={meetingid} toggle={toggleview}/>
            }
            {isloader ?
                <center>
                    <img src='assets/images/loadertrad.gif' className='img-fluid' />
                </center>
                :
                <div class="col-lg-12">
                <div class="table-responsive-sm  card card-body">
                <table className="table table-striped tCustom table-text">
                    <thead className='p-1-rem'>
                        {/* sticky-top */}
                        <tr className='text-uppercase list-top'>
                            <th><p className='w-200 mb-0'>TASK#</p></th>
                            <th><p className='w-200 mb-0'>TASK NAME</p> </th>
                            <th><p className='w-200 mb-0'>DESCRIPTION</p></th>
                            <th className='px-3'><p className='w-200 mb-0'>CREATED DATE</p></th>
                            <th>DUE DATE</th>
                            <th>ASSIGNED</th>
                            <th>STATUS</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasklist != null &&
                            tasklist.map((list, k) => {
                                return (
                                    <tr>
                                        <td>
                                            {k + 1}
                                        </td>
                                        <td>
                                            {list.taskName}
                                        </td>
                                        <td>
                                            {list.description}
                                        </td>
                                        <td>
                                            {list.createdDate != null && moment(list.createdDate).format("DD/MM/YYYY")}
                                        </td>
                                        <td>
                                            {list.dueDate != null && moment(list.dueDate).format("DD/MM/YYYY")}
                                        </td>
                                        <td>
                                            {list.assignedTo}
                                        </td>
                                        <td>
                                            {!list.taskStatus ?
                                                <span>Pending</span>
                                                :
                                                <span>Completed</span>
                                            }
                                        </td>
                                        <td>
                                            {CONFIG.LoginID == list.assignedToID &&
                                                <Form.Check
                                                    defaultChecked={list.taskStatus}
                                                    disabled={list.taskStatus ? true : false}
                                                    id={list.taskID}
                                                    onChange={handleChange}
                                                />
                                            }
                                        </td>
                                        <td>
                                            <Button lg="md" variant='primary' onClick={handleView} id={list.meetingID}>View</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </div>
                </div>
            }
        </div>
    )
}
