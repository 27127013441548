import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import * as FormComponent from "../../../components/formcomponents";
import * as ButtonComponent from "../../../components/buttoncomponents";
import * as CONFIG from "../../../helpers/config"
import * as FormFields from "../../../components/formcomponents"
import axios from "axios"
import Moment from "moment"
import { useFormik } from 'formik'
import { useMsal } from "@azure/msal-react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export function GetCustomer() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getCustomer()
    }, [])

    const getCustomer = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setCustomerList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.custid}>{list.customername}</option>
                    </>
                )
            })}
        </>
    )
}

export function GetProduct(props) {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getProduct()
    }, [props])

    const getProduct = () => {
        if (parseInt(props.customerid) != 0) {
            let json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(props.customerid)
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_BY_CUSTOMERID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setCustomerList(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.productID}>{list.productName}</option>
                    </>
                )
            })}
        </>
    )
}

export function GetTradeNo(props) {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [tradeNoList, setTradeNoList] = useState([])

    useEffect(() => {
        getTradeNo()
    }, [props])

    const getTradeNo = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        let id = parseInt(props.customerid)
        console.log(id)
        if (id != 0) {
            console.log(props.customerid, 'customerid')
            json_list.customerID = parseInt(props.customerid)
        }
        if (parseInt(props.productid) != 0) {
            json_list.customerProductID = parseInt(props.productid)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let final = res.data.result.filter( (ele, ind) => ind === res.data.result.findIndex( elem => elem.tradeID === ele.tradeID))
                let finallist = final.filter(x => x.is_CustomerPOUploaded != true)
                console.log(final)
                setTradeNoList(finallist)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            {tradeNoList.map((list, k) => {
                return (
                    <>
                        <option value={list.tradeID}>{list.tradeNo}</option>
                    </>
                )
            })}
        </>
    )
}


export default function Index() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [customerPurchaseOrderDate, setCustomerPurchaseOrderDate] = useState(false);
    const [customerPurchaseOrderDocumentName, setCustomerPurchaseOrderDocumentName] = useState('');
    const [count, setCount] = useState(0)
    const [customerid, setcustomerid] = useState(0)
    const [productid, setproductid] = useState(0)
    const [custcomobject, setcustcomobject] = useState([])
    const [doc, setdoc] = useState(false)
    const MySwal = withReactContent(Swal)
    const [tradescenarioID, settradescenarioID] = useState(0)
    const [selectedcustomerid, setselectedcustomerid] = useState(0)
    const [selectedcompanyid, setselectedcompanyid] = useState(0)

    let navigate = useNavigate();

    const openInvoice = () => {
        console.log('inside')
        setIsInvoiceOpen(!isInvoiceOpen)
    }

    const formik = useFormik({
        initialValues:
        {
            id: '',
            customerPurchaseOrderNo: '',
            customerPurchaseOrderDate: '',
            customerPurchaseOrderDocument: '',
        },
    })

    const handleSubmit = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": formik.values.id,
            "customerPONumber": formik.values.customerPurchaseOrderNo,
            "customerPODocument": formik.values.customerPurchaseOrderDocument,
            "customerPODate": formik.values.customerPurchaseOrderDate,
            "customerPODocumentName": formik.values.customerPurchaseOrderDocumentName,
            "customerID" : selectedcustomerid,
            "companyID" : selectedcompanyid
        }
        console.log(json_list)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_CUSTOMER_PURCHASE_ORDER_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if (res.data.responseCode == '304001') {
                    MySwal.fire(
                        {
                            icon: 'error',
                            title: 'Something went wrong! Please try after some time',
                            confirmButtonText: 'Back',

                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                    rgba(60, 60, 60, 0.5)
                    no-repeat
              `
                        }).then(() => {

                        });
                }
                else if (res.data.responseCode == '304000') {
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Customer Purchase Order Uploaded Successfully!',
                            html: '<p>Customer Purchase Order Number: ' + formik.values.customerPurchaseOrderNo + '</p>',
                            type: 'success',
                            showCancelButton: true,
                            confirmButtonText: 'View Customer Purchase Order List',
                            cancelButtonText: "Back",
                            confirmButtonClass: "btn btn-primary",
                            reverseButtons: true,
                            backdrop: `
                    rgba(60, 60, 60, 0.5)
                    no-repeat
                `
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/customer-po-list')
                            }
                        });
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleSelectTrade = (e) => {
        console.log(e.target.value)
        formik.values.id = parseInt(e.target.value)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(e.target.value),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PURCHASE_ORDER_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result != null) {
                    formik.values.customerPurchaseOrderNo = res.data.result.customerPurchaseOrderNo != null ?
                        res.data.result.customerPurchaseOrderNo : ''
                    formik.values.customerPurchaseOrderDate = res.data.result.customerPurchaseOrderDate != null ?
                        Moment(res.data.result.customerPurchaseOrderDate) : ''
                    formik.values.id = parseInt(e.target.value)

                    if (res.data.result.customerPurchaseOrderDocumentName != null) {
                        console.log('condition')
                        setCustomerPurchaseOrderDocumentName(res.data.result.customerPurchaseOrderDocumentName)
                    }
                    else {
                        console.log('inside')
                        setCustomerPurchaseOrderDocumentName('')
                    }
                }

                let json_lists = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "id": parseInt(e.target.value) // InitiationId
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_lists, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        setcustcomobject(res.data.result)
                        settradescenarioID(res.data.result[0].tradeScenarioID)
                        res.data.result.map((re,k)=>{
                            if(re.is_DefaultCompany == true){
                                setselectedcompanyid(re.companyID)
                            }
                            if(re.is_DefaultCustomer == true){
                                setselectedcustomerid(re.customerID)
                            }
                        })
                        console.log(res.data.result)
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
                var counter = parseInt(count)
                counter = counter + 1
                setCount(counter)
                // setData(formik.values)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const customerPurchaseOrderDocumentHandler = (file) => {
        formik.values.customerPurchaseOrderDocument = file.base64;
        formik.values.customerPurchaseOrderDocumentName = file.name
        console.log('file.base64')
        var fileName = ''
        console.log(file)
        console.log(file.name.length)
        console.log(file.type)
        if (file.name.length > 5) {
            console.log(file.name.slice(0, (file.name.length - 1)))
            fileName = file.name.slice(0, 5) + '...' + file.name.slice(file.name.length, -1)
            console.log(fileName)
        }
        setCustomerPurchaseOrderDocumentName(fileName)
    }

    const handleInvoiceDate = (date) => {
        console.log('click')
        if (date == null) {
            formik.values.customerPurchaseOrderDate = false
        }
        else {
            formik.values.customerPurchaseOrderDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')

        }
        setCustomerPurchaseOrderDate(date)
    }

    // useEffect(()=>{
    //     console.log('')
    // },[formik.values, update])
    const getproductdetails = (e) => {
        let val = e.currentTarget.value
        console.log(e.currentTarget.value)
        setcustomerid(val)
        setdoc(!doc)
    }

    const getProductID = (e) => {
        let id = e.currentTarget.value
        setproductid(id)
    }

    useEffect(() => {
        console.log(count, 'count', formik.values)

    }, [count, formik.values, customerPurchaseOrderDocumentName])

    const handleCompanySelect = (e)=>{
        const id = parseInt(e.currentTarget.value)
        let filter = custcomobject.filter(x=> x.companyID == id)[0]
        setselectedcustomerid(filter.customer.customerID)
        setselectedcompanyid(id)
    }

    const handleCustomerSelect = (e)=>{
        const id = parseInt(e.currentTarget.value)
        let filter = custcomobject.filter(x=> x.customerID == id)[0]
        setselectedcompanyid(filter.company.companyID)
        setselectedcustomerid(id)
    }

    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='6.3' isOpenid={6} />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 text-uppercase d-flex align-items-end pl-10'>Upload Customer PO</span>
                        </div>
                    </div>
                    <div className='add-edit-wrapper faq'>
                        <>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md={4}>
                                            <FormComponent.InputSelect
                                                label='Select Customer'
                                                optionField={<GetCustomer />}
                                                onChange={getproductdetails}
                                                onBlur={getproductdetails}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <FormComponent.InputSelect
                                                label='Select Product'
                                                optionField={<GetProduct customerid={customerid} />}
                                                onChange={getProductID}
                                                onBlur={getProductID}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <FormComponent.InputSelect
                                                label='Select Trade'
                                                classname=''
                                                optionField={<GetTradeNo customerid={customerid} productid={productid} />}
                                                onChange={handleSelectTrade}
                                            />
                                        </Col>
                                        <Col md={3} className='pt-1-rem'>
                                            <FloatingLabel controlId="floatingInput" label={'Purchase Order Number'}>
                                                <Form.Control
                                                    name='customerPurchaseOrderNo'
                                                    type='text'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.customerPurchaseOrderNo}
                                                    className={'form_input form_input_color_change'}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={3}>
                                            <FormComponent.DatePick

                                                label='Purchase Order Date'
                                                name='customerPurchaseOrderDate'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleInvoiceDate}
                                                isRequired={false}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.customerPurchaseOrderDate}

                                            />
                                        </Col>
                                        <Col md={3} className='pt-1-rem align-items-center'>
                                            <ButtonComponent.UploadButton value='Purchase Order' onUploadHandle={customerPurchaseOrderDocumentHandler} filename={customerPurchaseOrderDocumentName} document={formik.values.customerPurchaseOrderDocument}/>
                                        </Col>
                                        <Col md={3} className='pt-1-rem align-items-center'>
                                            <ButtonComponent.BlueButton value="Submit" clickEvent={handleSubmit} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </>
                    </div>
                </div>
            </div>
        </>
    )
}


