import React from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";


function FormComponent(props) {
  console.log(props.data)
  const addDirector = ()=>{
      const director_list ={
          "productInformation": formik.values.productInformation,
          "storageFacility": formik.values.storageFacility,
          "productionCapacity": formik.values.productionCapacity,
          "exisitingSupplySources": formik.values.exisitingSupplySources,
          "tonnageSold": formik.values.tonnageSold,
          "marketServed": formik.values.marketServed,
          "is_Default" : false,
      }
      console.log(director_list)
      props.addDirectorToList(director_list)
  }

  const formik = useFormik({
      initialValues:
      !props.isEdit?
      {
          productInformation: '',
          storageFacility: '',
          productionCapacity: '',
          exisitingSupplySources: '',
          tonnageSold: '',
          marketServed: '',
      }
      :
      {
          productInformation: props.data.productInformation,
          storageFacility: props.data.storageFacility,
          productionCapacity: props.data.productionCapacity,
          exisitingSupplySources: props.data.exisitingSupplySources,
          tonnageSold: props.data.tonnageSold,
          marketServed: props.data.marketServed,
      }
  })

  const editDirector = ()=>{
      const director_list ={
          "productInformation": formik.values.productInformation,
          "storageFacility": formik.values.storageFacility,
          "productionCapacity": formik.values.productionCapacity,
          "exisitingSupplySources": formik.values.exisitingSupplySources,
          "tonnageSold": formik.values.tonnageSold,
          "marketServed": formik.values.marketServed,
          "is_Default" : props.data.is_Default,
          "key" :props.data.key
      }
      console.log(director_list)
      props.editDirectorToList(director_list)
  }
  return (
    <Row className = 'mb-10-rem'>
    <FormFields.InputField
        md={3}
        name='productInformation'
        label='Product Information'
        classname='pt-1-rem'
        type='text'
        val={formik.values.productInformation}
        onChange={formik.handleChange}
    />
    <FormFields.InputField
        md={3}
        name='storageFacility'
        label='Storage Facility'
        classname='pt-1-rem'
        type='text'
        val={formik.values.storageFacility}
        onChange={formik.handleChange}
    />
    <FormFields.InputField
        md={3}
        name='productionCapacity'
        label='Production / Capacity'
        classname='pt-1-rem'
        type='text'
        val={formik.values.productionCapacity}
        onChange={formik.handleChange}
    />
    <FormFields.InputField
        md={3}
        name='exisitingSupplySources'
        label='Existing Supply Sources'
        classname='pt-1-rem'
        type='text'
        val={formik.values.exisitingSupplySources}
        onChange={formik.handleChange}
    />
     <FormFields.InputField
        md={3}
        name='tonnageSold'
        label='Tonnage Sold'
        classname='pt-1-rem'
        type='text'
        val={formik.values.tonnageSold}
        onChange={formik.handleChange}
    />
      <FormFields.InputField
        md={3}
        name='marketServed'
        label='Markets Served'
        classname='pt-1-rem'
        type='text'
        val={formik.values.marketServed}
        onChange={formik.handleChange}
    />
    <Col className='d-flex align-items-end'>
        <BlueButton value={props.isEdit?'Edit Storage Information':'Add Storage Information'} clickEvent={props.isEdit?editDirector:addDirector}/>
    </Col>
    </Row>
  )
}

export default FormComponent