import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {Form, Card } from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import Moment from 'moment'
import axios from 'axios';
import {Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import * as CONFIG from '../../../../helpers/config'
import PaymentMethodModal from './PaymentMethodModal';
import SupplierListModal from './SupplierListModal';
import IncoTermsModal from './IncoTermsModal';
import { useMsal } from "@azure/msal-react";


function Index(props) {
    console.log(props)
    const { instance } = useMsal();
    const [supplierDetailsData, setSupplierDetailsData] = useState(['Letter of Credit'])
    const [defaultSupplier, setDefaultSupplier] = useState()
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalIncoterms, setShowModalIncoterms] = useState(false)
    const [paymentMethodList, setPaymentMethodList] = useState()
    const [dataForApi, setDataForApi] = useState()
    const [isFirst, setIsFirst] = useState(true)
    const [getAmountValues, setAmountValues] = useState()
    const [supplierModal, setSupplierModal] = useState(false)
    const [error, setError] = useState(true)
    const [bugFix, setBugFix] = useState(false)
    const [incoTermsLabel, setIncotermsLabel] = useState('FOB')
    const [paymentMethodSelection, setPaymentMethodSelection] = useState(false)
    const [dataFromModal, setDataFromModal] = useState()
    const [incotermsModal, setIncoTermsModal] = useState(false)
    const [disabled, setDisabled] = useState(true)

    useEffect(()=>{

    },[props])

    useEffect(()=>{
        if(props.supplierIncoterms != null){
            setIncotermsLabel(props.supplierIncoterms.tradeIncoTermsValue)
        }
    },[])

    useEffect(()=>{

    },[incoTermsLabel])
    useEffect(()=>{

        if(props.tradeInitiateEdit == true && props.supplier == null){
            console.log('Edit if')
            console.log(props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail)
            setPaymentMethodSelection(true)
            setPaymentMethodList(props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail)
            setAmountValues(props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail)
            setDataFromModal(props.TradeInitiateData.tradedetails.tradeSupplierPaymentDetail)
            
            console.log('Trade Initiate')
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.TradeInitiateData.tradedetails.supplierID
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_LIST_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result[0]);
                    res.data.result[0].supplierdata.supplierContactInformation.filter(x => x.is_Default == true).map((list) => (
                        setDefaultSupplier(list)
                    ))
                    setSupplierDetailsData(res.data.result[0]);
                    setIsFirst(false)
                    console.log(props.paymentMethodsSupplier)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            
        }


    },[])

    const formik = useFormik({
        initialValues:
        {
            supplierIncotermID: props.supplierIncoterms != null ? props.supplierIncoterms.supplierIncotermID : 2,
            tradeIncoTermsValue: props.supplierIncoterms != null ? props.supplierIncoterms.tradeIncoTermsValue : 'FOB'
        },
    })
    // useEffect(()=>{
    //     if((props.backClicked == true)){
        
    //         if(props.supplier != null){
                

    //             const supplierDetailsDataObject = {
    //                 supplierdata : props.supplier
    //             }
    //             console.log(supplierDetailsDataObject)
    //             Object.assign(supplierDetailsData, props.supplier)
    //             if(props.tradeInitiateEdit == true){
    //                 setSupplierDetailsData(supplierDetailsDataObject)
    //             }   
    //         }
    //         else{
    //         Object.assign(supplierDetailsData, props.supplier)
    //         console.log(supplierDetailsData.supplierdata.supplierCode)
    //         setIsFirst(false)
    //         }
            
    //     }
        
    //    }, [props])

       useEffect(()=>{
        if(props.supplier != null){
            setIsFirst(false)
            const supplierDetailsDataObject = {
                supplierdata : props.supplier
            }
            console.log(supplierDetailsDataObject)
            Object.assign(supplierDetailsData, props.supplier)
            
        }
       },[])

    //    useEffect(()=> {
    //     if(props.backClicked == true){
    
    //     }
    //    }, [])

  



    useEffect(()=>{
        // if(props.currentSupplyPlan != null){
        //     setAmountValues(props.currentSupplyPlan.productBuyingPrice)
        // }
    },[])


    const handleSupplierSelect = () => {
        setSupplierModal(true)
    }
    const setShowModalForModal = () => {
        setSupplierModal(false)
    }
    const handleError = (data) => {
    }
   
    const handleSupplierData = (data) => {
        console.log(data, 'data from modal')
        setSupplierDetailsData(data[0])
        setBugFix(true)
        setIsFirst(false)
    }


    useEffect(()=>{
        console.log('test console 2')
        if(props.tradeSupplierPaymentDetails != null){
            console.log('prev / next')
            setPaymentMethodSelection(true)
            setDataFromModal(props.tradeSupplierPaymentDetails)
        }
    },[])
   
    const moveToNext = () => {
        props.addSupplierDetailsToApi(dataFromModal, supplierDetailsData)
        props.handleSupplierIncoterms(formik.values)
        props.onNext(props,4)
    }
    const moveToBack = () => {
        props.onBack(props,2)
    }


    const handlePaymentMethod = () => {
        setShowModal(true)
        //setIsFirst(true)
    }
    const close = () => {
        setShowModal(false)
    }

    const closeIncoterms = () => {
        setShowModalIncoterms(false)
    }

    const handleDataFromModal = (data) => {
        console.log(data,'after save')
        setDataFromModal(data)
        setPaymentMethodSelection(true)
    }

    useEffect(()=>{
        if(supplierDetailsData.supplierdata  != null){
            setDisabled(false)
        }
    },[supplierDetailsData])

    useEffect(()=>{

    },[disabled])
    useEffect(()=>{
        console.log(dataFromModal)
        console.log('test console 3')
        if(dataFromModal != null){
            setError(false)
        }
    },[dataFromModal])

    useEffect(()=>{
        console.log(error)
    },[error])

    const handleIncoTerms = () => {
        setShowModalIncoterms(true)
    }

    const handleIncoTermsValue = (data) => {
        formik.values.supplierIncotermID = parseInt(data.supplierIncotermID)
        formik.values.tradeIncoTermsValue = data.tradeIncoTermsValue
        setIncotermsLabel(data.tradeIncoTermsValue)
    }
  return (
    <>

       <Card>
                <Card.Body className='form-card-body mb-10-rem p-md-4 p-2' style={{ overflowY: 'scroll'}}>
                    <div className = 'table-ttile-color font-14 mb-3'>
                        Supplier Details
                    </div>
                    {isFirst == true ? 
                    <>
                    <div className = "row p-3 d-flex align-items-center grey-color">
                        <div className='col-md-8 col-12'>
                            <h4 className = 'font-weight-500 font-14 mx-md-3 m-0 mb-md-0 mb-3'>Please select a supplier from the list of added suppliers.</h4>
                        </div>
                        <div className='col-md-4 col-12 d-flex justify-content-center'>
                            <BlueBorderButton value = 'Select Supplier' clickEvent={handleSupplierSelect} />
                        </div>
                     
                    </div>
                    </>
                 
                    :
                    <div className = "grey-color p-4">
                    <div className = 'row'>
                        <div className = 'col-md-2 col-12 mb-md-0 mb-3'>
                            <div className = 'font-weight-600 color-243448'>{supplierDetailsData.supplierdata.supplierCode}</div>
                            <div className = 'font-weight-400 color-697382'>{supplierDetailsData.supplierdata.supplierName}</div>
                        </div>
                       
                        <div className = 'col-12 col-md-4 offset-md-1  mb-md-0 mb-3'>
                            <div className = 'font-weight-400 color-697382'>Address</div>
                            <div className = 'font-weight-600 color-243448'>{supplierDetailsData.supplierdata.registeredBuildingName}, {supplierDetailsData.supplierdata.registeredStreetName}, {supplierDetailsData.supplierdata.registeredCityName}</div>
                        </div>
                        <div className = "col-12 col-md-3  mb-md-0 mb-3 d-flex justify-content-end">
                            <BlueBorderButton value = 'Change Supplier' clickEvent={handleSupplierSelect} />
                        </div>
                    </div>
                    <hr />
                    Contact Person Details
                    <div className = 'row mt-3'>
                        {supplierDetailsData.supplierdata.supplierContactInformation != null && supplierDetailsData.supplierdata.supplierContactInformation.length > 0 ?
                           supplierDetailsData.supplierdata.supplierContactInformation.map((list) => (
                           <>
                            <div className = 'col-md-3 col-12 mb-md-0 mb-3'>
                                <div className = 'font-weight-600 color-243448'>{list.fullName}</div>
                                <div className = 'font-weight-400 color-697382'>{list.designation}</div>
                            </div>
                            <div className = 'col-md-3 col-12 mb-md-0 mb-3'>
                                <div className = 'font-weight-400 color-697382'>Email ID</div>
                                <div className = 'font-weight-600 color-243448'>{list.email}</div>
                            </div>
                            <div className = 'col-md-3 col-12 mb-md-0 mb-3'>
                                <div className = 'font-weight-400 color-697382'>Mobile No</div>
                                <div className = 'font-weight-600 color-243448'>{list.mobileNo}</div>
                            </div>
                            <div className = 'col-md-3 col-12 mb-md-0 mb-3'>
                                <div className = 'font-weight-400 color-697382'>Telephone No</div>
                                <div className = 'font-weight-600 color-243448'>{list.telephoneNo}</div>
                            </div>
                            <div>

                            </div>
                        </>
                        ))  
                        
                        :
                        'No Contact Person Available'
                        }
                        </div>
                    </div>
                    }
                    {supplierDetailsData.supplierdata != null ?
                        <>
                        <div className = 'pt-4 p-2'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <h4 className='table-ttile-color font-14 m-0'>Payment Method</h4>
                                </div>
                                <div className = 'ms-4'>
                                    <Tooltip placement="top" title={paymentMethodSelection == false ? 'Add payment method(s)' : 'Change payment method(s)'}>
                                        <button className = 'border-0 bg-transparent' onClick={handlePaymentMethod}>
                                            <img src ='/assets/images/supports/penIcon.svg' />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    {paymentMethodSelection == false ?
                    <div className = "p-md-4 p-2 grey-color col-3 mt-3">
                        Please select payment method(s)
                    </div>
                    :
                    <>
                    {dataFromModal != null ? 
                    <>  
                        <div className = 'row ps-2'>
                            <div className = 'col-md-12 col-sm-12'>
                                <div className = 'row'>
                                {dataFromModal.map((value, k)=>(
                                    value.is_Deleted == null ?
                                        <div className = "py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                            <div className = 'd-flex align-items-center justify-content-between'>
                                                <div className = 'd-flex'>
                                                    <Form.Check
                                                        className = ''
                                                        type='checkbox'
                                                        name='paymentMethod'
                                                        id = {value.supplierPaymentTypeID}
                                                        value='paymentMethod'
                                                        defaultChecked={true}
                                                        disabled = {true}                    
                                                    />
                                                    <div className = 'ms-2'>
                                                        {value.supplierPaymentTypeName != null ? value.supplierPaymentTypeName : value.paymentType.typeName}
                                                    </div>
                                                </div>
                                                <div className = 'ms-2'>
                                                    <div className = 'payment-method-pill d-flex align-items-center justify-content-center'>
                                                        $<NumberFormat 
                                                            value = {value.supplierPaymentAmount} 
                                                            displayType={'text'} 
                                                            thousandSeparator={true} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                :
                                value.is_Deleted == false ?
                                <div className = "py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                    <div className = 'd-flex align-items-center justify-content-between'>
                                        <div className = 'd-flex'>
                                            <Form.Check
                                                className = ''
                                                type='checkbox'
                                                name='paymentMethod'
                                                id = {value.supplierPaymentTypeID}
                                                value='paymentMethod'
                                                defaultChecked={true}
                                                disabled = {true}                    
                                            />
                                            <div className = 'ms-2'>
                                                {value.supplierPaymentTypeName != null ? value.supplierPaymentTypeName : value.paymentType.typeName}
                                            </div>
                                        </div>
                                        <div className = 'ms-2'>
                                            <div className = 'payment-method-pill d-flex align-items-center justify-content-center'>
                                                $<NumberFormat 
                                                    value = {value.supplierPaymentAmount} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                :
                                ''
                                
                                ))}
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    ''}
                   
                    </>
                    }
                        <div className = 'pt-4 p-2'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <h4 className='table-ttile-color font-14 m-0'>Supplier Incoterms</h4>
                                </div>
                                <div className = 'ms-4'>
                                    <Tooltip placement="top" title={'Change incoterms'}>
                                        <button className = 'border-0 bg-transparent' onClick={handleIncoTerms}>
                                            <img src ='/assets/images/supports/penIcon.svg' />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className = 'row ps-2'>
                            <div className = 'col-md-12 col-sm-12'>
                                <div className = 'row'>
                               
                                        <div className = "py-3 px-4 grey-color col-md-3 col-sm-12 mt-3 me-3">
                                            <div className = 'd-flex align-items-center justify-content-between'>
                                                <div className = 'd-flex'>
                                                <Form.Check 
                                                    className = ''
                                                    label= {incoTermsLabel}
                                                    defaultChecked = {true}
                                                    type='radio'
                                                />
                                                </div>
                                        
                                            </div>
                                        </div> 
                               
                                </div>
                            </div>
                        </div>
                        </>
                        :
                        ''
                    }
                    </Card.Body>
                    <Card.Footer className='form-card-footer p-2-rem d-flex'>
                        <span><BlueBorderButton value='Prev' clickEvent = {moveToBack}/></span>
                        <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext}  disabled={error}  /></span>
                    </Card.Footer>
            </Card>
            {supplierModal == true ? 
              <SupplierListModal selectedSupplier = {supplierDetailsData.supplierdata != null ? supplierDetailsData.supplierdata : null} cancel={setShowModalForModal} handleSupplierData = {handleSupplierData} handleError = {handleError}/>
            :
            ''
            }
            {showModal == true ? 
                <PaymentMethodModal
                 close = {close}
                 currentSupplyPlan = {props.currentSupplyPlan} 
                 handleDataFromModal = {handleDataFromModal}
                 dataFromModal = {dataFromModal}
                 tradeInitiateEdit = {props.tradeInitiateEdit}
                />  
                   
                    
            : 
            ''
            }
             {showModalIncoterms == true ?
             <IncoTermsModal 
             close={closeIncoterms}  
             handleIncoTermsValue = {handleIncoTermsValue} 
             supplierIncotermID = {formik.values.supplierIncotermID}
             />
             :
             ''
             }
 
    </>

  )
}

export default Index