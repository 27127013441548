import React, { useState, useEffect } from 'react'
import * as CONFIG from "../../helpers/config"
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { BlueBorderButton } from '../../components/buttoncomponents';
import ViewMeeting from './ViewMeeting';

export default function OtherTours() {
    const { instance } = useMsal();
    const [tourlist, settourlist] = useState([])
    const [isloader, setisLoader] = useState(true)
    const [viewstatuts, setViewstatus] = useState(false)
    const [meetingid, setMeetingid] = useState(0)

    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_OTHER_TOUR_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settourlist(res.data.result);
                setisLoader(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    
    const viewMeeting = (e)=>{
        let id = parseInt(e.target.id)
        console.log(id)
        setMeetingid(id)
        toggleviewstatus()
    }

    useEffect(()=>{
        console.log(meetingid)
    },[meetingid])
    
    const toggleviewstatus = ()=>{
        setViewstatus(!viewstatuts)
    }
    return (
        <div>
            
            {viewstatuts &&
                <ViewMeeting toggle={toggleviewstatus} isOpen={viewstatuts} id={meetingid}/>
            }
            {isloader ?
                <center>
                    <img src='assets/images/loadertrad.gif' className='img-fluid' />
                </center>
                :
                <div class="col-lg-12">
                <div class="table-responsive-sm  card card-body">
                <table className="table table-striped tCustom table-text">
                    <thead className='p-1-rem'>
                        {/* sticky-top */}
                        <tr className='text-uppercase list-top'>
                            <th><p className='w-200 mb-0'>TOIUR ID</p></th>
                            <th><p className='w-200 mb-0'>TITLE OF TOUR</p> </th>
                            <th><p className='w-200 mb-0'>TOUR CATEGORY</p></th>
                            <th className='px-3'><p className='w-200 mb-0'>TIME</p></th>
                            <th>ATTENDEES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tourlist != null &&
                            tourlist.map((list, k) => {
                                return (
                                    <tr className='product_list_row align-items-center border-bottom-1 align-middle'>
                                        <td>
                                            <div className='meeting_list_bold align-items-center d-flex py-4 '> {list.meetingCode}</div>
                                        </td>
                                        <td>
                                            <div className='meeting_list_normal align-items-center d-flex py-4 '> {list.meetingTitle}</div>
                                        </td>
                                        <td>
                                            <div className='meeting_list_bold align-items-center d-flex py-4 '> {list.is_MeetingVal == 1 ? list.meetingCategory : 'Tour'}</div>
                                        </td>
                                        <td>
                                            <div className='align-items-center py-4 '>{Moment(list.meetingTime).format('DD/MM/YYYY')}<br />
                                                <span className='font-weight-600'>{Moment(list.meetingStartTime).format('h:mm')}</span>{Moment(list.meetingStartTime).format('a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='meeting_list_bold align-items-center d-flex py-4 '>
                                                {list.meetingAttendees.map((mt, k) => {
                                                    return (
                                                        <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                            <p className='font-14 font-weight-500 mb-0'>{mt}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </td>
                                        <td>
                                            <BlueBorderButton value='View' id={list.meetingID} clickEvent={viewMeeting} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </div>
                </div>
            }
        </div>
    )
}
