import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import { BlueButton, RedButton } from '../../components/buttoncomponents'
import { PMSInstanceID } from '../../helpers/config';
import * as CONFIG from "../../helpers/config"
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from "react-bootstrap"
import ContractModal from './ContractModal';
import CostSheetModal from './CostSheetModal';
import ActualCostsheetModal from './ActualCostsheetModal';
import { useMsal } from "@azure/msal-react";
import ViewCustomerRequirement from './viewCustomerRequirement';

export default function List(props) {
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    console.log(props)
    const [isContractModal, setIsContractModal] = useState(false)
    const [isCostSheetModal, setIsCostSheetModal] = useState(false)
    const [isActualCostSheetModal, setIsActualCostSheetModal] = useState(false)
    const [isCustomerRequirement, setIsCustomerRequirement] = useState(false)
    const [contractID, setContractID] = useState(0)
    const [tradeData, setTradeData] = useState([])
    useEffect(()=>{
        
    },[])
    const approve = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "workflowID": props.data.workflowID,
            "workflowStepsID": props.data.workflowstepsID,
            "comments": "approved done",
            "status": CONFIG.STATUS_APPROVED,
            "description": props.data.description,
            "tradeID": props.data.tradeID
        }
        const {value: text } = MySwal.fire(
            {
            icon: 'warning',
            title: 'Are you sure you wish to approve the request?',
            input: 'textarea',
            inputPlaceholder: 'Comments',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, Approve',
            showCancelButton: true,
            confirmButtonClass: "btn btn-primary",
            backdrop: `
            rgba(60, 60, 60, 0.5)
            no-repeat
          `
          }).then((result) => {
            json_list.comments =  result.value
            if (result.isConfirmed) {
                axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_REJECT_APPROVAL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.getList()
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            }
           
        })
    }
    const reject = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "workflowID": props.data.workflowID,
            "workflowStepsID": props.data.workflowstepsID,
            "comments": "approved done",
            "status": CONFIG.STATUS_REJECTED,
            "description": props.data.description,
            "tradeID": props.data.tradeID
        }
        
        MySwal.fire(
            {
            icon: 'warning',
            title: 'Are you sure you wish to reject the request?',
            input: 'textarea',
            inputPlaceholder: 'Comments',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, Reject',
            showCancelButton: true,
            confirmButtonClass: "btn btn-primary",
            backdrop: `
            rgba(60, 60, 60, 0.5)
            no-repeat
          `
          }).then((result) => {
            json_list.comments =  result.value
            
            if (result.isConfirmed) {
                axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_REJECT_APPROVAL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.getList()
                    if(isContractModal){
                        const json_li = {
                            "instanceID": CONFIG.PMSInstanceID,
                            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                            "created_User": CONFIG.LoginID,
                            "id": contractID
                        }
                        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.CONTRACT_IS_LOCK_ON_REJECT, json_li, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            props.getList()
                        }).catch((error)=>{
                            if(error.response.status == 401){
                                localStorage.clear();
                                instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                                });
                            }
                        })  
                    }
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })
                
            }
            
        })
    }
    const getTradeData = ()=>{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.data.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAIL_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setTradeData(res.data.result)
            const type = props.data.description
            if(type == "Customer Contract Approval"){
                if(res.data.result[0].tradedetails!=null){
                    let contractID = res.data.result[0].tradedetails.customerContractID
                    setContractID(contractID)
                    setIsContractModal(true)
                }
            }
            else if(type == "Supplier Contract Approval"){
                console.log('inside')
                if(res.data.result[0].tradedetails!=null){
                    let contractID = res.data.result[0].tradedetails.supplierContractID
                    setContractID(contractID)
                    setIsContractModal(true)
                }
            }
            else if(type == "Estimated Costsheet Approval"){
                setIsCostSheetModal((true))
            }
            else if(type == "Actual Costsheet Approval"){
                setIsActualCostSheetModal((true))
            }
            else if(type == "CustomerRequirement Approval"){
                console.log('inside')
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const openApprovalDoc = ()=>{
        const type = props.data.description
        if(type == "CustomerRequirement Approval"){
            setIsCustomerRequirement(true)
        }
        else{
            getTradeData()
        }
    }

    const toggle=()=>{
        setIsContractModal(false)
        setIsCostSheetModal(false)
        setIsActualCostSheetModal(false)
        setIsCustomerRequirement(false)
    }

    return (
        <tr>
            {isContractModal &&
                <ContractModal modal={isContractModal} contractID={contractID} toggle={toggle} approve={approve} reject={reject} status={props.status}/>
            }
            {isCostSheetModal &&
                <CostSheetModal modal={isCostSheetModal} tradeID={props.data.tradeID} toggle={toggle} tradedata={tradeData} approve={approve} reject={reject}/>
            }
            {isActualCostSheetModal &&
                <ActualCostsheetModal modal={isActualCostSheetModal} tradeID={props.data.tradeID} toggle={toggle} tradedata={tradeData} approve={approve} reject={reject}/>
            }
             {isCustomerRequirement &&
                <ViewCustomerRequirement modal={isCustomerRequirement} id={props.data.tradeID} toggle={toggle} approve={approve} reject={reject} data={props.data} getList={props.getList}/>
            }
            <td>{props.data.description}</td>
            <td>{props.data.tradeNo}</td>
            <td>{props.data.stepNo} / {props.data.totalSteps}</td>
            <td>{props.data.userName}</td>
            <td>
                {props.data.status == 1 &&
                    <>
                        <>
                            <Button variant="primary" size="sm" id={props.data.tradeID} onClick={openApprovalDoc} className={'font-weight-500 font-12 rounded-13 '}>
                                Review and Approve
                            </Button>
                        </>
                    </>
                }
            </td>
        </tr>
    )
}
