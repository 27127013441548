import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton, BackButton, BlueButton } from '../../components/buttoncomponents'
import axios from 'axios'
import * as CONFIG from ".././../helpers/config"
import TradeEnquiryList from './tradeenquirylist'
import { Card, Col, FloatingLabel, Form } from 'react-bootstrap'
//import AddEdit from './addedit/AddEdit'
import AddEdit from './TradeEnquiries';
import EnquiryList from './tradeenquirylist'
import TradeInitiate from '../Trading/Trade Initiation/index'
import UserDivisionSelectionModal from './UserDivisionSelectionModal'
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true)
    const [isView, setIsView] = useState(false);
    const [tradeEnquiryByID, setTradeEnquiryByID] = useState()



    useEffect(() => {
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href = "/"
        }
        else {
            console.log('inside')
        }
    })

    useEffect(() => {
        if (isFirst) {
            getProductsList();
        }
        setIsFirst(false)
    }, [isFirst])

    const [isProductAdd, setIsProductAdd] = useState(false);
    const [pList, setPList] = useState([]);
    const [selectedEnquiry, setSelected] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [isTradeInitiate, setTradeInitiate] = useState(false);
    const [tradeInitiateData, setTradeInitiateData] = useState();
    const [divisionSelectionModal, setDivisionSelectionModal] = useState(false)
    const [userDivision, setUserDivisions] = useState()
    const [customerDetailsData, setCustomerDetailsData] = useState([])
    const [supplyNo, setSupplyNo] = useState()
    const [selectedDivision, setSelectedDivision] = useState()
    const [backDivision, setBackDivision] = useState(false)
    const [tradedetailsdata, settradedetailsdata] = useState([])
    const [all_list, setall_list] = useState([])
    useEffect(() => {
        console.log(isEdit, 'Is edit')
    }, [isEdit])

    const addProduct = (data) => {
        console.log(data)
        let divisionList = []
        let userDivisionList = []
        console.log(CONFIG.Divisions)
        if(CONFIG.Divisions.includes(",")){
            let list = CONFIG.Divisions.split(",")
            list.map((values)=>{
                divisionList.push(parseInt(values))
            })
        }
        else{
            let list = []
            list.push(parseInt(CONFIG.Divisions))
            list.map((values)=>{
                divisionList.push(parseInt(values))
            })
        }
        console.log(divisionList)
        if(divisionList.length > 1){
            let final_json = {

                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
              
    
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION_, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                console.log(divisionList)
                for (let i = 0; i < res.data.result.length; i++){
                    for(let x = 0; x < divisionList.length; x++){
                        if(divisionList[x] == res.data.result[i].divisionID){
                            userDivisionList.push(res.data.result[i]) 
                        }
                    }
                }
                console.log(userDivisionList)
                setUserDivisions(userDivisionList)
                
                if(isProductAdd == false && isTradeInitiate == false && data != 'test'){
                    console.log(isEdit)
                    setDivisionSelectionModal(true)
                }
                else{
                    if (isTradeInitiate == true) {
                        console.log('clicked')
                        setTradeInitiate(false)
                    }
                    else {
                        setIsProductAdd(!isProductAdd)
                    }
                }
                
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else if(divisionList.length == 1){
            let final_json = {

                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
              
    
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION_, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                for (let i = 0; i < res.data.result.length; i++){
                    for(let x = 0; x < divisionList.length; x++){
                        if(divisionList[x] == res.data.result[i].divisionID){
                            userDivisionList.push(res.data.result[i]) 
                        }
                    }
                }
                console.log(userDivisionList)
                setSelectedDivision(userDivisionList)
                if (isTradeInitiate == true) {
                    console.log('clicked')
                    setTradeInitiate(false)
                }
                else {
                    setIsProductAdd(!isProductAdd)
                }
                
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            if (isTradeInitiate == true) {
                console.log('clicked')
                setTradeInitiate(false)
            }
            else {
                setIsProductAdd(!isProductAdd)
            }
        }
        
    }

    const getProductsList = () => {
        console.log('inside')
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADEENQUIRY_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setPList(res.data.result);
                setall_list(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }
    const back = () => {
        console.log('clicked')
        setIsView(false);
        setTradeInitiate(false)
        setIsEdit(false)
        addProduct();
        getProductsList()
        
    }

    const edit = (e) => {
        const id = parseInt(e.target.id)
        let data = 'test'
        console.log(id)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADEENQUIRY_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelected(res.data.result[0]);
                setIsEdit(true)
                addProduct(data)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    const handleTradeInitiate = (data) => {
        console.log(data.data.enqiryid)
        setTradeInitiateData(data)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": data.data.enqiryid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADEENQUIRY_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setTradeEnquiryByID(res.data.result[0]);
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADEENQUIRY_BY_IDINITIATE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    settradedetailsdata(res.data.result[0]);
                    setTradeInitiate(true)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    const handleSave = () => {
        setTradeInitiate(false)
        setIsProductAdd(false)
        //setRemoveAddButton(true)
    }

    const handleSaveDivision = (data) => {
        setSelectedDivision(data)
        if (isTradeInitiate == true) {
            console.log('clicked')
            setTradeInitiate(false)
        }
        else {
            setIsProductAdd(!isProductAdd)
        }
    }
    const close = () => {
        setDivisionSelectionModal(false)
       
    }

    const handleSearch = (event)=>{
        let val = event.currentTarget.value
        // let filter = all_list.filter(x=> x.customername.toLowerCase().includes(val.toLowerCase()))

        let filter = all_list.map(item => ({
          ...item,
          productdetails: item.productdetails
            .filter(child => child.productName.toLowerCase().includes(val.toLowerCase()))
        }))
        .filter(item => item.productdetails.length > 0)
        let f = all_list.filter(x=> x.customername.toLowerCase().includes(val.toLowerCase()))
        filter = [...filter, ...f]
        setPList(filter)
    }

    const handleEventCondition = () => {
        if (isProductAdd == true) {
            return (
                <>
                    {localStorage.getItem('Edit_Trade_Enquiry') == 3 &&
                        <AddEdit selectedDivision = {selectedDivision != null ? selectedDivision : null } back={back} selectedEnquiry={selectedEnquiry} isEdit={isEdit} />
                    }
                </>
            )

        }
        else if (isTradeInitiate == true) {
            return (
                <>
                    {localStorage.getItem('Initiate_Trade') == 3 &&
                        <TradeInitiate back={back} onSave={handleSave} TradeInitiateData={tradedetailsdata} TradeEnquiryByIDData={tradeEnquiryByID} />
                    }
                </>
            )

        }
        else {
            return (
                <>

                    <div className='  br-8 py-3 col-lg-12 card p-2-rem '>
                        <div className='table-responsive-sm list-scroll '>
                            <table className="table tradeCustom table-text">
                                <thead>
                                    <tr className='bg-F8F9FA font-weight-500 color-707895 table_heading_padding list-top  '>
                                        <th>Requirement No</th>
                                        <th>Customer</th>
                                        <th>Products</th>
                                        <th>Type Of Contract</th>
                                        <th>Trade Status</th>
                                        <th>Approval Status</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(localStorage.getItem('List_Trade_Enquiries') == 1 || localStorage.getItem('List_Trade_Enquiries') == 3) &&
                                        <>
                                            {pList != null &&
                                                pList.map((list, k) => (
                                                    <EnquiryList back = {back} data={list} edit={edit} handleTradeInitiate={handleTradeInitiate} />
                                                ))
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </>
            )
        }

    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>

            <Sidebar id='5.1' isOpenid={5} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between mx-2'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>{isProductAdd ? 'NEW REQUIREMENT' : isTradeInitiate ? 'TRADE INITIATION' : 'ALL CUSTOMER REQUIREMENTS'}</span>
                    </div>
                    <div className='d-flex'>
                        {isProductAdd == true || isTradeInitiate == true ?
                            <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                                <div className = 'd-flex justify-content-center align-items-center '>
                                    <BlueButton value='Back' clickEvent={back}/>
                                </div>
                            </div>
                            :
                            <>{localStorage.getItem('Add_New_Trade_Enquiry') == 3 && 
                            <>
                                <div className='me-3'>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label='Search'>
                                            <Form.Control type='text' placeholder='Search' 
                                                className="form_input form_input_color_change" 
                                                name='search'
                                                onChange={handleSearch}
                                                onBlur={handleSearch}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </div>
                                <AddNewButton className='font-weight-500 font-12 textPrimary' class='bg-white' value='New Customer Requirement' clickEvent={addProduct} />
                            </>
                            }</>
                        }
                    </div>
                </div>
                <div className='inner-page-content'>
                    {handleEventCondition()}
                </div>
            </div>
            {divisionSelectionModal == true ? 
            <UserDivisionSelectionModal handleSaveDivision = {handleSaveDivision} close={close} userDivision = {userDivision} />
            :
            ''
            }
        </div>
    )
}
