import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import FormComponent from './components/FormComponent';
import ListData from './components/ListData';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function CompanyDirectors(props) {
    console.log(props)
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)

    useEffect(()=>{
      if(isFirstLoad){
        if(props.directorList.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.directorList.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    },[])

    useEffect(()=>{
      console.log()
      if(props.isEdit == true && isFirstEdit == true){
 
        setIsAdd(false)
        setIsFirstEdit(false)
      }
    }, [])
    useEffect(()=> {
      if(props.isDirectorSet == true){
        setIsAdd(false)
      }
    },[])
    useEffect(()=> {
      if(props.isBackClicked == true){
        setIsAdd(false)
      }
    },[])
    
    const openAddCustomer =()=>{
      setIsAdd(true);
    }

    const addDirectorToList= (data)=>{
      console.log('data on addDirectorToList function')
      setIsAdd(false);
      props.addDirectorToList(data)
    }

    const editBank =(e)=>{
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.directorList.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToCustomer= (data)=>{
      setIsAdd(false);
      setIsEdit(false);
      props.editDirectorToList(data)
    }

   const handleCancel = () => {
      setIsAdd(false)
    }
    return (
        <>
        <Row className='d-flex' style={{background: '#fff'}}>
          <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
                Company Directors
              <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Director' clickEvent={openAddCustomer}/></span>
          </div>
            {!isAdd?
              <div className='mt-2-rem'>
              
                  <table className="table table-text mt-1-rem">
                    <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                        <th scope='col' className=' font-weight-500'>Director Name</th>
                        <th scope='col' className=' font-weight-500'>Designation</th>
                        <th scope='col' className=' font-weight-500'>Nationaltiy</th>
                        <th scope='col' className=' font-weight-500'>National Identity No</th>
                        <th scope='col' className=' font-weight-500'>Appointed Data</th>
                        <th scope='col'></th>
                        <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.directorList.map((values)=>(
                          <ListData  is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected} is_DefaultAvailable={values.is_Default?true:false} values={values} directorList ={props.directorList} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                         
                        ))}
                    </tbody>
                </table>
              </div>
            :
            <FormComponent handleCancel = {handleCancel}  handleValidationDirector = {props.handleValidationDirector} setErrors = {props.setErrors} handleError = {props.handleError} addDirectorToList = {addDirectorToList}  isEdit={isEdit} data={selectedBank} editDirectorToList={editBankToCustomer}/>
          }
        </Row>
        </>
        
    )
}

export default CompanyDirectors