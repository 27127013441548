import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space, Alert, Steps } from 'antd';
import * as CONFIG from '../../../../helpers/config'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import { BlueBorderButton } from '../../../../components/buttoncomponents';
import Tradelismodals from './modals/tradelismodals';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FreightCharges from "../../../FreightCharges"
import CostSheet from "../../../CostSheet/costsheet"
import Contract from "../../../DraftContract";
import * as ButtonComponent from "../../../../components/buttoncomponents"
import { Button } from "react-bootstrap"
import { useFormik } from 'formik';
import Moment from "moment";
import { Col } from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import LinkMeetings from '../../../Meetings/components/LinkMeetings';

export default function TradeListData(props) {
    console.log(props)
    const { instance } = useMsal();
    const [laycanStart, setLayCanStart] = useState()
    const [ismeeting, setismeeting] = useState(false)
    const [laycanEnd, setLayCanEnd] = useState()
    const [tradedata, settradedata] = useState()
    const [enableMark, setEnable] = useState(false)
    const [modal, setModal] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const MySwal = withReactContent(Swal)
    const [doc1, setdoc1] = useState(false)
    const [error, setError] = useState(false)
    const { Step } = Steps;
    const [tradeprocessmodal, settradeprocess] = useState(false)
    const [tradeprocess, settradeprocesses] = useState([])
    //useEffect(() => {
    //    let laycanStart = props.data.laycanPeriod.indexOf('-')
    //},[])
    const handleEdit = () => {
        if (props.data.suppliercontractstatus == 1 || props.data.customercontractstatus == 1 || props.data.costsheetstatus == 1) {

        }
        else {
            props.handleEdit(props)
        }

    }

    const openMeeting = () => {
        setismeeting(!ismeeting)
    }
    const getTradeInfo = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.data.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PROCESS_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                settradeprocesses(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const costsheet = () => {
        console.log('CLICKED')
        props.addcostsheet(props);
    }
    useEffect(() => {
        if (props.data.costsheetstatus == 2 && props.data.customercontractstatus == 2 && props.data.suppliercontractstatus == 2) {
            setEnable(true)
        }
    })
    const handleClose = () => {
        setIsAlert(false)
    };
    const formik = useFormik({
        initialValues:
        {
            cust_signed_document: '',
            cust_signed_document_name: '',
            supp_signed_document: '',
            supp_signed_document_name: '',
            ship_signed_document_name: '',
            ship_signed_document: ''
        }
    })

    const toggle = () => {
        setModal(!modal)
    }

    const processtoggle = () => {
        settradeprocess(!tradeprocessmodal)
        getTradeInfo()
        if (tradeprocessmodal == true) {
            props.refreshList()
        }
    }
    const refreshList = () => {
        getTradeInfo()
        props.refreshList()
    }
    const markcontractfinalize = () => {
        if (!enableMark) {
            MySwal.fire(
                {
                    icon: 'warning',
                    title: 'Contract cannot be finalized!',
                    html: 'Cost sheet, customer contract and supplier contract needs to be approved before a trade can be finalized.',
                    type: 'success',
                    confirmButtonText: 'Cancel',
                }).then(() => {

                });
        }
        else {
            toggle()
        }
    }

    const alertToggle = () => {
        setIsAlert(true)
    }
    const cancelTrade = (e) => {
        const id = parseInt(e.target.id)
        MySwal.fire(
            {
                icon: 'warning',
                title: 'Trade cancellation',
                html: 'Are you sure you want to cancel this trade?',
                type: 'success',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {

                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": id
                    }
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.CANCEL_TRADE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            console.log(res.data.result)
                            props.refreshList()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                }
            });

    }
    const menu = (
        <Menu
            items={[
                localStorage.getItem('Edit_Trade') == 3 &&
                {
                    key: '1',
                    label: (
                        <>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={props.data.suppliercontractstatus == 1 || props.data.customercontractstatus == 1 || props.data.costsheetstatus == 1 ? '' : handleEdit}><img src='assets/images/Edit_Icon.svg' />Edit</span>
                        </>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.data.tradeID} onClick={cancelTrade}>Cancel Trade</span>
                        </>
                    ),
                }
            ]}
        />
    );
    const saveCustDocument = (file) => {
        formik.values.cust_signed_document = file.base64
        formik.values.cust_signed_document_name = file.name
        setdoc1(!doc1)
    }

    const saveSuppDocument = (file) => {
        formik.values.supp_signed_document = file.base64
        formik.values.supp_signed_document_name = file.name
        setdoc1(!doc1)
    }

    const saveShipDocument = (file) => {
        formik.values.ship_signed_document = file.base64
        formik.values.ship_signed_document_name = file.name
        setdoc1(!doc1)
    }

    const saveContract = () => {
        if (formik.values.cust_signed_document != '' && formik.values.supp_signed_document != '' && formik.values.ship_signed_document != '') {
            setError(false)
            const supplier_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.data.tradeID,
                "signedContractDocument": formik.values.supp_signed_document,
                "signedContractDocumentName": formik.values.supp_signed_document_name
            }
            const customer_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.data.tradeID,
                "signedContractDocument": formik.values.cust_signed_document,
                "signedContractDocumentName": formik.values.cust_signed_document_name
            }
            const shipment_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": props.data.tradeID,
                "signedContractDocument": formik.values.ship_signed_document,
                "signedContractDocumentName": formik.values.ship_signed_document_name
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_SUPPLIER_SIGNED_CONTRACT, supplier_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_CUSTOMER_SIGNED_CONTRACT, customer_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPLOAD_SHIPMENT_SIGNED_CONTRACT, customer_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(props.data.tradeID),
                "statusID": props.status
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.MARKCONTRACT_TRADE_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
            toggle()
        }
        else {
            console.log('inside else')
            setError(true)
        }
    }
    const getSteps = () => {
        const steps = props.data
        if (steps.freightchargestatus == 1) {
            return (
                <Steps direction="vertical" initial={-1}  >
                    <Step title="Trade Initiated"
                        icon={<img src='assets/images/ellipse_check.svg' />}
                    />
                    <Step title={localStorage.getItem('Freight_Charges_Update') == 3 ?
                        <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Freight Charges</div>
                        :
                        <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Freight Charges</div>
                    } description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                </Steps>
            )
        }
        else if (steps.freightchargestatus == 2) {
            if (steps.is_EstimatedCostSheetCompleted == 2) {
                if (steps.custcontractstatus == 2) {
                    return (
                        <Steps direction="vertical" initial={2} current={steps.suppliercontractstatus == 2 ? 4 : steps.customercontractstatus == 2 ? 3 : 1} onClick={tradeprocessmodal}>
                            {steps.suppcontractstatus != 2 &&
                                <Step title={localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                    <>
                                        <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Estimated Cost Sheet</div>
                                    </>
                                    :
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>}
                                    // subTitle={steps.costsheetstatus == 9? 'Rejected':'Pending Approval'}
                                    description="" icon={<img src='assets/images/ellipse_check.svg' />} />
                            }
                            {(steps.customercontractstatus == 2) &&
                                <Step title={localStorage.getItem('Customer_Contract') == 3 ?
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Customer Contract</div>
                                    :
                                    <div className='d-flex justify-content-center my-2' onClick={alertToggle}>Customer Contract</div>}
                                    // subTitle={steps.customercontractstatus == 9 ?'Rejected' : steps.customercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                    description="" icon={<img src='assets/images/ellipse_check.svg' />} />
                            }
                            {(steps.customercontractstatus == 1) &&
                                <Step title={localStorage.getItem('Customer_Contract') == 3 ?
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Customer Contract</div>
                                    :
                                    <div className='d-flex justify-content-center my-2' onClick={alertToggle}>Customer Contract</div>}
                                    // subTitle={steps.customercontractstatus == 9 ?'Rejected' : steps.customercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                    description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                            }
                            {(steps.customercontractstatus == null) &&
                                <Step title={localStorage.getItem('Customer_Contract') == 3 ?
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Customer Contract</div>
                                    :
                                    <div className='d-flex justify-content-center my-2' onClick={alertToggle}>Customer Contract</div>}
                                    // subTitle={steps.customercontractstatus == 9 ?'Rejected' : steps.customercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                    description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                            }
                            {(steps.customercontractstatus == 9) &&
                                <Step title={localStorage.getItem('Customer_Contract') == 3 ?
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Customer Contract</div>
                                    :
                                    <div className='d-flex justify-content-center my-2' onClick={alertToggle}>Customer Contract</div>}
                                    // subTitle={steps.customercontractstatus == 9 ?'Rejected' : steps.customercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                    description="" icon={<img src='assets/images/rejected-icon.svg' />} />
                            }

                            {steps.suppcontractstatus == 2 &&
                                <>
                                    {steps.suppliercontractstatus == 2 &&
                                        <Step title={
                                            localStorage.getItem('Supplier_Contract') == 3 ?
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Supplier Contract</div>
                                                :
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Supplier Contract</div>}
                                            // subTitle={steps.suppliercontractstatus == 9 ?'Rejected' : steps.suppliercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                            description="" icon={<img src='assets/images/ellipse_check.svg' />} />}

                                    {steps.suppliercontractstatus == 1 &&
                                        <Step title={
                                            localStorage.getItem('Supplier_Contract') == 3 ?
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Supplier Contract</div>
                                                :
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Supplier Contract</div>}
                                            // subTitle={steps.suppliercontractstatus == 9 ?'Rejected' : steps.suppliercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                            description="" icon={<img src='assets/images/ellipse_pending.svg' />} />}
                                    {steps.suppliercontractstatus == null &&
                                        <Step title={
                                            localStorage.getItem('Supplier_Contract') == 3 ?
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Supplier Contract</div>
                                                :
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Supplier Contract</div>}
                                            // subTitle={steps.suppliercontractstatus == 9 ?'Rejected' : steps.suppliercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                            description="" icon={<img src='assets/images/ellipse_pending.svg' />} />}

                                    {steps.suppliercontractstatus == 9 &&
                                        <Step title={
                                            localStorage.getItem('Supplier_Contract') == 3 ?
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Supplier Contract</div>
                                                :
                                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Supplier Contract</div>}
                                            // subTitle={steps.suppliercontractstatus == 9 ?'Rejected' : steps.suppliercontractstatus == 2 ? 'Approved':'Pending Approval'} 
                                            description="" icon={<img src='assets/images/rejected-icon.svg' />} />}

                                </>
                            }
                            {(steps.customercontractstatus == 2 && steps.suppliercontractstatus == 2) &&
                                <Step title={
                                    <Button
                                        variant="primary"
                                        style={{ width: props.width }}
                                        id={props.data.TradeID}
                                        onClick={markcontractfinalize}
                                        className={'font-weight-500 font-14 rounded-13 '}
                                        disabled={localStorage.getItem('Mark_Contracts_Finalized') == 3 ? false : true}
                                    >
                                        Mark Contract Finalized
                                    </Button>
                                }
                                    description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                            }
                        </Steps>
                    )
                }
                else if (steps.custcontractstatus == 1) {
                    return (
                        <Steps direction="vertical" initial={1} current={1}>
                            <Step title={localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Estimated Cost Sheet</div>
                                :
                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>}
                                // subTitle='Cost Sheet Completed Date'
                                description="" icon={<img src='assets/images/ellipse_check.svg' />} />
                            <Step title={localStorage.getItem('Customer_Contract') == 3 ?
                                <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Customer Contract</div>
                                :
                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Customer Contract</div>}
                                description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                        </Steps>
                    )
                }
                else {
                    return (
                        <Steps direction="vertical" initial={1} current={1}>
                            {steps.costsheetstatus == 0 &&
                                <Step title={localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                    <>
                                        <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Estimated Cost Sheet</div>
                                    </>
                                    :
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>}
                                    description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                            }
                            {steps.costsheetstatus == 2 &&
                                <Step title={localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                    <>
                                        <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Estimated Cost Sheet</div>
                                    </>
                                    :
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>}
                                    description="" icon={<img src='assets/images/ellipse_check.svg' />} />
                            }
                            {steps.costsheetstatus == 9 &&
                                <Step title={localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                    <>
                                        <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Estimated Cost Sheet</div>
                                    </>
                                    :
                                    <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>}
                                    description="" icon={<img src='assets/images/rejected-icon.svg' />} />
                            }
                            {/* // subTitle={steps.costsheetstatus == 9? 'Rejected':'Pending Approval'} */}
                            <Step title={
                                <div className='d-flex justify-content-center my-2'
                                >Customer Contract</div>} description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                        </Steps>
                    )
                }
            }
            else if (steps.is_EstimatedCostSheetCompleted == 1) {
                return (
                    <Steps direction="vertical" current={1}>
                        <Step title={
                            localStorage.getItem('Freight_Charges_Update') == 3 ?
                                <div className='d-flex justify-content-center my-2' onClick={processtoggle}>Freight Charges</div>
                                :
                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Freight Charges</div>}
                            // subTitle={Moment(steps.freightChargesModified_Datetime).format("DD/MM/yyyy")}
                            description="" icon={<img src='assets/images/ellipse_check.svg' />} />
                        <Step title={
                            localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                <div className='d-flex justify-content-center my-2 pointer' onClick={processtoggle}>Estimated Cost Sheet</div>
                                :
                                <div className='d-flex justify-content-center my-2 pointer' onClick={alertToggle}>Estimated Cost Sheet</div>} description="" icon={<img src='assets/images/ellipse_pending.svg' />} />
                    </Steps>
                )
            }
        }
    }

    const showComments = () => {
        MySwal.fire(
            {
                title: 'Cost sheet comments',
                html: <div style={{ textAlign: 'left' }}>{props.data.estCostsheetApprovalComment}</div>,
                type: 'success',
                confirmButtonText: 'Ok',
            })
    }
    const showCustomerContractComment = () => {
        MySwal.fire(
            {
                title: 'Customer Contract Comments',
                html: <div style={{ textAlign: 'left' }}>{props.data.custContractApprovalComment}</div>,
                type: 'success',
                confirmButtonText: 'Ok',
            })
    }
    const showSupplierContractComment = () => {
        MySwal.fire(
            {
                title: 'Supplier Contract Comments',
                html: <div style={{ textAlign: 'left' }}>{props.data.suppContractApprovalComment}</div>,
                type: 'success',
                confirmButtonText: 'Ok',
            })
    }
    const getEstimatedCostSheetModalStatus = () => {
        if (props.data.is_EstimatedCostSheetCompleted == 2) {
            if (props.data.costsheetstatus == 2) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'><p className=''>{tradeprocess.estimatescostapprovedbyImg}</p></div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                <p className='font-13 mb-0 font-weight-500 colorUserName'>{tradeprocess.estimatescostapprovedby}</p>
                                <div className='tab_sub_heading'>
                                    {tradeprocess.estimatescostapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Approved
                            </div>
                        </div>
                    </div>
                )
            }
            else if (props.data.costsheetstatus == 9) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'><p className=''>{tradeprocess.estimatescostapprovedbyImg}</p></div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                <p className='font-13 mb-0 font-weight-500 colorUserName'>{tradeprocess.estimatescostapprovedby}</p>
                                <div className='tab_sub_heading'>
                                    {tradeprocess.estimatescostapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='rejected_badge d-flex justify-content-center'>
                                Rejected
                            </div>
                            <Button variant='outline-secondary' className='task_button' onClick={showComments}>
                                <img src='assets/images/comments_icon.svg' onClick={showComments} />
                            </Button>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'><p>{tradeprocess.estimatescostbyImg}</p></div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName'>
                                {tradeprocess.estimatescostby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.estimatescostbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Updated
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    const getCustomerContractModalStatus = () => {
        if (props.data.custcontractstatus == 2) {
            if (props.data.customercontractstatus == 2) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'><p>{tradeprocess.custcontractapprovedbyImg}</p></div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName'>
                                {tradeprocess.custcontractapprovedby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.custcontractapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Approved
                            </div>
                        </div>
                    </div>
                )
            }
            else if (props.data.customercontractstatus == 9) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'><p>{tradeprocess.custcontractapprovedbyImg}</p></div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName'>
                                {tradeprocess.custcontractapprovedby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.custcontractapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='rejected_badge d-flex justify-content-center'>
                                Rejected
                            </div>
                            <Button variant='outline-secondary' className='task_button' onClick={showCustomerContractComment}>
                                <img src='assets/images/comments_icon.svg' onClick={showCustomerContractComment} />
                            </Button>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'>{tradeprocess.custcontractbyImg}</div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                <p className='mb-0'>{tradeprocess.custcontractby}</p>
                                <div className='tab_sub_heading'>
                                    {tradeprocess.custcontractbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Updated
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
    const getSupplierContractModalStatus = () => {
        if (props.data.suppcontractstatus == 2) {
            if (props.data.suppliercontractstatus == 2) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'>{tradeprocess.suppcontractapprovedbyImg}</div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                {tradeprocess.suppcontractapprovedby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.suppcontractapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Approved
                            </div>
                        </div>
                    </div>
                )
            }
            else if (props.data.suppliercontractstatus == 9) {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'>{tradeprocess.suppcontractapprovedbyImg}</div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                {tradeprocess.suppcontractapprovedby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.suppcontractapprovedbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='rejected_badge d-flex justify-content-center'>
                                Rejected
                            </div>
                            <Button variant='outline-secondary' className='task_button' onClick={showSupplierContractComment}>
                                <img src='assets/images/comments_icon.svg' onClick={showSupplierContractComment} />
                            </Button>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pt-1-rem'>
                            <div className='avatar_trade'>{tradeprocess.suppcontractbyImg}</div>
                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                {tradeprocess.suppcontractby}
                                <div className='tab_sub_heading'>
                                    {tradeprocess.suppcontractbydes}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='approved_badge'>
                                <img src='assets/images/check.svg' /> Updated
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
    return (
        <tr className='product_list_row  border-bottom-1'>
            <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
                <ModalHeader toggle={toggle} className='p-40'>
                    <div className='d-flex justify-content-between '>
                        <div className='font-16 font-weight-600 color-707895'>
                            UPLOAD SIGNED CONTRACTS
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className='service-request-modal-wrapper p-40'>
                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Signed Customer Contract
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value={formik.values.cust_signed_document_name != "" ? formik.values.cust_signed_document_name : 'Upload Customer Document'}
                                onUploadHandle={saveCustDocument}
                                multiple={false}
                            />
                        </div>
                    </div>
                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Signed Supplier Contract
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value={formik.values.supp_signed_document_name != "" ? formik.values.supp_signed_document_name : 'Upload Supplier Document'}
                                onUploadHandle={saveSuppDocument}
                                multiple={false}
                            />
                        </div>
                    </div>

                    <div className='signed_document d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            Signed Shipment Contract
                        </div>
                        <div>
                            <ButtonComponent.UploadButton
                                value={formik.values.ship_signed_document_name != "" ? formik.values.ship_signed_document_name : 'Upload Shipment Document'}
                                onUploadHandle={saveShipDocument}
                                multiple={false}
                            />
                        </div>
                    </div>
                    {error &&
                        <span className='error-text'>Upload all documents to successfully finalize a trade!</span>
                    }
                </ModalBody>
                <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                    <span><ButtonComponent.BlueBorderButton value='Back' /></span>
                    <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
                </ModalFooter>
            </Modal>
            <Modal isOpen={tradeprocessmodal} toggle={processtoggle} className='trade_list_modal'>
                <ModalHeader toggle={processtoggle}>

                </ModalHeader>
                <ModalBody className='service-request-modal-wrapper' style={{ padding: '0' }}>
                    <div className='d-flex justify-content-between '>
                        <Tabs className='trade_process_tabs w-100'>
                            <div className='row'>
                                <Col md={2} className='col-12 scroll-over scrollTradeTrasnaction width-sm' style={{ width: '20%' }}>
                                    <TabList style={{ background: '#FDFAFE' }}>
                                        <div className='tabTransaction'>
                                            <Tab className={props.data.freightchargestatus == 2 ? "tab_completed tab-width" : "tab-width"} >
                                                <h4 className='textHeaderTab mb-0'>Freight Charge Updates</h4>
                                                <div className='tab_sub_heading'>{tradeprocess.freightchargedate != null ?
                                                    Moment(tradeprocess.freightchargedate).format("DD/MM/yyyy")
                                                    :
                                                    ''
                                                }</div>

                                                {props.data.freightchargestatus == 2 &&
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex pt-1-rem'>
                                                            <div className='avatar_trade'><p>{tradeprocess.freightchargebyImg}</p></div>
                                                            <div className='font-13 mb-0 font-weight-500 colorUserName ml-10'>
                                                                {tradeprocess.freightchargeby}
                                                                <div className='tab_sub_heading'>
                                                                    {tradeprocess.freightchargebydes}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='approved_badge'>
                                                                <img src='assets/images/check.svg' /> Updated
                                                            </div>
                                                        </div>
                                                    </div>

                                                }

                                            </Tab>
                                            <Tab
                                                className={props.data.is_EstimatedCostSheetCompleted == 2 ? "tab_completed tab-width" : "tab-width"} style={{ padding: '15px 0 0 0' }}
                                                disabled={props.data.freightchargestatus != 2}
                                            >
                                                <h4 className='textHeaderTab mb-0'>Estimated Cost Sheet</h4>
                                                <div className='tab_sub_heading'>{tradeprocess.estimatescostdate != null ?
                                                    Moment(tradeprocess.estimatescostdate).format("DD/MM/yyyy")
                                                    :
                                                    ''
                                                }</div>
                                                {getEstimatedCostSheetModalStatus()}
                                            </Tab>
                                            <Tab
                                                className={props.data.custcontractstatus == 2 ? "tab_completed tab-width" : "tab-width"}
                                                style={{ padding: '15px 0 0 0' }}
                                                disabled={props.data.costsheetstatus != 2}
                                            >
                                                <h4 className='textHeaderTab mb-0'>Customer Contract</h4>
                                                <div className='tab_sub_heading'>{tradeprocess.custcontractdate != null ?
                                                    Moment(tradeprocess.custcontractdate).format("DD/MM/yyyy")
                                                    :
                                                    ''
                                                }</div>
                                                {getCustomerContractModalStatus()}
                                            </Tab>
                                            <Tab
                                                className={props.data.suppcontractstatus == 2 ? "tab_completed tab-width" : "tab-width"}
                                                style={{ padding: '15px 0 0 0' }}
                                                disabled={props.data.costsheetstatus != 2}
                                            >
                                                <h4 className='textHeaderTab mb-0'>Supplier Contract</h4>
                                                <div className='tab_sub_heading'>{tradeprocess.suppcontractdate != null ?
                                                    Moment(tradeprocess.suppcontractdate).format("DD/MM/yyyy")
                                                    :
                                                    ''
                                                }</div>
                                                {getSupplierContractModalStatus()}
                                            </Tab>
                                        </div>
                                    </TabList>
                                </Col>
                                <Col md={8} className='col-12'>
                                    <TabPanel>
                                        {localStorage.getItem('Freight_Charges_Update') == 3 ?
                                            <FreightCharges trade={props.data} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>

                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        {localStorage.getItem('Estimated_Cost_Sheet') == 3 ?
                                            <CostSheet trade={props.data} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        {localStorage.getItem('Customer_Contract') == 3 ?
                                            <Contract tradeID={props.data.tradeID} ctId={props.data.customerContractID} type={1} tradescenarioID={props.data.tradescenarioID} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        {localStorage.getItem('Supplier_Contract') == 3 ?
                                            <Contract tradeID={props.data.tradeID} spId={props.data.supplierContractID} type={2} refreshList={refreshList} />
                                            :
                                            <div className='text-center pt-100'>
                                                <div>
                                                    <img src='assets/images/locked_screen.svg' />
                                                </div>
                                                <p className='pt-50'><span className='font-16 font-normal'>Permission required to access this page</span><br />
                                                    <span className='font-normal font-24 py-3 text-orange'>Thank You!</span></p>

                                            </div>
                                        }
                                    </TabPanel>
                                </Col>
                            </div>
                        </Tabs>
                    </div>
                </ModalBody>
            </Modal>
            <td>
                <div className='pt-2'>
                    <h4 className='tradeTrsactionsHeading'>{props.data.tradeNo}</h4>
                    <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'> {Moment(props.data.tradeinitiatdate).format("DD/MM/yyyy")} <br /> {props.data.vesselName}</h4>
                </div>
            </td>
            <td>
                {props.data.tradescenarioID == 2 ?
                    <>
                        {props.data.compcustdetails.map((pt, k) => {
                            return (
                                <>
                                    <div className='pt-2'>
                                        <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{pt.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                                    </div>
                                </>
                            )
                        })}
                    </>
                    :
                    <>
                        <div className='pt-2'>
                            <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{props.data.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                        </div>
                    </>
                }
            </td>
            {ismeeting &&
                <LinkMeetings data={props.data} tag='Trade' toggle={openMeeting} />
            }
            <td className='w-16'>
                <div className='d-flex flex-column pt-2'>
                    {(props.data.tradescenarioID != null && props.data.tradescenarioID == 3) ?
                        <>
                            {props.data.compcustdetails != null &&
                                props.data.compcustdetails.map((pt, k) => {
                                    return (
                                        <>
                                            <div className='pb-2'>
                                                <div className='align-items-center '>
                                                    <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                    <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {(props.data.compcustdetails != null && props.data.compcustdetails.length > 0) &&
                                <div className='pb-2'>
                                    <div className='align-items-center '>
                                        <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.compcustdetails[0].customername}</h5>
                                        <span className='tradeTrsactionsid statBg'>{props.data.compcustdetails[0].customercode}</span>
                                    </div>
                                </div>
                            }

                        </>
                    }


                    <div className='py-2'>
                        <div className='align-items-center '>
                            <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.supplierName}</h5>
                            <span className='tradeTrsactionsid statBg'>{props.data.supplierCode}</span>
                        </div>
                        <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                            {props.data.supplierpaymentmethods.map((pt, k) => {
                                return (
                                    <>
                                        {pt} <span className='mx-1 dotSep'>&#8226;</span>
                                    </>
                                )
                            })}
                        </h4>
                        </div>
                    </div>
                </div>
            </td>
            <td className='w-20'>
                <div className=' pt-2'>
                    {props.data.tradeProductDetails.map((list, k) => {
                        return (
                            <>
                                <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                    {" "}
                                    {list.productName}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                        {list.quantity}&nbsp;{" "}
                                        {list.productUOMName} <span className='mse-3 dotSep'>&#8226;</span>
                                        {props.data.contracttype} <span className='mse-3 dotSep'>&#8226;</span>
                                        {props.data.incotermsName} <span className='mse-3 dotSep'>&#8226;</span>
                                        {props.data.modeOfTransportName}
                                    </div>

                                </div>
                            </>
                        );
                    })}
                </div>
                <div className='d-flex py-2'>
                    <div>
                        <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                            Load Port
                        </div>
                        <div className='tradeTrsactionsHeading '>
                            {props.data.loadPort}, {props.data.lordPortCountry}
                        </div>
                    </div>
                    <div className='ml-20'>
                        <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                            Destination Port
                        </div>
                        <div className='tradeTrsactionsHeading'>
                            {props.data.destinationPort}, {props.data.destinationPortcountry}
                        </div>
                    </div>
                </div>
            </td>
            <td>
                {isAlert &&
                    <Alert
                        message="Error"
                        description="No privilleges for this operation!"
                        type="error"
                        closable
                        onClose={handleClose}
                        banner
                    />
                }

                {props.data.is_active ?
                    getSteps()
                    :
                    <Steps direction="vertical" initial={-1}  >
                        <Step title={
                            <div className='d-flex justify-content-center my-2'>Cancelled Trade</div>
                        } description="" icon={<img src='assets/images/rejected-icon.svg' />} />
                    </Steps>
                }
            </td>
            <td>
                {props.data.is_active &&
                    <Button variant='outline-secondary' onClick={openMeeting}>
                        <img src='assets/images/linkicon.svg' />
                    </Button>
                }
            </td>
            <td>

                {props.data.is_active &&
                    <>
                        <div class=''>
                            <Dropdown className='br-8 py-2' overlay={menu}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <img src='/assets/images/Dot_icon.svg' />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>

                        <div className='pointer btn-link text-decoration-none pt-1-5-rem' onClick={processtoggle}><a className='border-bottom-primary d-flex justify-content-center'>More Actions</a></div>
                    </>
                }

            </td>
        </tr>
    )
}
