import React, { useEffect } from 'react'
import { Dropdown, Menu, Space } from 'antd';

export default function List_contract_row(props) {
   
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key}  onClick={props.editBank}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    props.is_DefaultAvailable?
                        props.is_Default?
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.removePrimary}>Remove Primary</span>
                        :
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key} onClick={props.setPrimary}>Make Primary</span>
                    :
                    <span className={props.is_DefaultSelected?'disabled-contact-form font-weight-400 font-14 pl-10 mb-1-rem':'font-weight-400 font-14 pl-10 mb-1-rem'} id={props.values.key} onClick={props.is_DefaultSelected?'':props.setPrimary}>Make Primary</span>
                ),
            }
          ]}
        />
      );
    return (
        <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
            <td>{props.values.bankName}</td>
            <td>{props.values.accountNo}</td>
            <td>{props.values.iban}</td>
            <td>{props.values.currencyID == 1 ? 'USD' : 'USD'}</td>
            <td style={{top:'5px', position:'relative'}}>{props.is_Default &&
                    <span className='contact-form-primary-badge'>Primary</span>
                }
            </td>
            <td>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    ...
                </Space>
                </a>
            </Dropdown>
            </td>
        </tr>
    )
}
