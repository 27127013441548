import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import * as FormFields from "../../../components/formcomponents"
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import { Col, Form, FloatingLabel, Button, Row, ButtonToolbar, Accordion } from "react-bootstrap"
import AddExternalUser from './addExternalUser'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { useMsal } from "@azure/msal-react";
import { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as ButtonComponent from "../../../components/buttoncomponents"
import Moment from "moment";

export default function AddTour(props) {
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [visitdate, setvisitdate] = useState(false)
    const [hostmodal, sethostmodal] = useState(false)
    const [userlist, setuserlist] = useState([])
    const [userid, setuserid] = useState([])
    const [attendeeslist, setattendeeslist] = useState([])
    const [notifyuserlist, setnotifyuserlist] = useState([])
    const [agendaStatus, setAgendStatus] = useState(false)
    const [premeetinglist, setpreemeetinglist] = useState([])
    const [preemeetingstatus, setpreemeetingstatus] = useState(false)
    const [taskduedate, settaskduedate] = useState(false)
    const [isTaskEdit, setistaskedit] = useState(false)
    const [taskassignedtouser, settaskassignedto] = useState(0)
    const [index, setindex] = useState(0)
    const [taskstatus, settaskstatus] = useState(false)

    useEffect(() => {
        getUsers()
    }, [])

    const toggle = () => {
        setmodal(!Modal)
        props.toggle()
    }

    const formik = useFormik({
        initialValues: {
            referenceNo: '',
            companyname: '',
            purposeofVisit: ''
        }
    })

    const handlevisitdate = (date) => {
        if (date == null) {
            setvisitdate(false)
        }
        else {
            setvisitdate(date)
        }
    }
    const addHost = () => {
        sethostmodal(!hostmodal)
    }
    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleSelectUser = (event, values) => {
        let atlist = [...userid]
        if (Array.isArray(values)) {
            console.log(atlist)
            values.map((val, k) => {
                atlist[k] = {
                    "meetingUserID": val.meetingUserID
                }
            })
            setuserid(atlist)
        } else {
            setuserid(values.meetingUserID)
        }
    }

    const handlerequiredattendees = (event, values) => {
        let atlist = [...attendeeslist]
        if (Array.isArray(values)) {
            console.log(atlist)
            values.map((val, k) => {
                atlist[k] = {
                    "meetingUserID": val.meetingUserID
                }
            })
            setattendeeslist(atlist)
        } else {
            setattendeeslist(values.meetingUserID)
        }
    }

    const handlenotifyusers = (event, values) => {
        let notify = [...notifyuserlist]
        if (Array.isArray(values)) {
            values.map((val, k) => {
                notify[k] = {
                    "meetingUserID": val.meetingUserID
                }
            })
            setnotifyuserlist(notify)
        } else {
            setnotifyuserlist(values.meetingUserID)
        }
    }

    const agendaHtml = () => {
        setAgendStatus(!agendaStatus)
        setistaskedit(false)
        formik.values.taskname = ''
        formik.values.taskDescription = ''
        settaskduedate(false)
        settaskassignedto(0)
    }

    const saveAgenda = (e) => {
        setAgendStatus(!agendaStatus)
        let ind = e
        let tasklist = {
            "task": formik.values.taskname,
            "is_PreMeetingPoints": preemeetingstatus,
            "is_Completed": false,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_Completed": false,
            "taskDescription": formik.values.taskDescription
        }
        let preemeeting = [...premeetinglist]
        preemeeting.push(tasklist)
        formik.values.taskname = ''
        setpreemeetinglist(preemeeting)
        let tasksta = [...taskstatus]
        tasksta[ind] = false
        settaskstatus(tasksta)
    }

    const saveContract = () => {
        const json_list = {
            "is_Meeting": 2,
            "companyID": props.company.company.companyID,
            "meetingDate": visitdate,
            "documentNumberForDocControl": formik.values.referenceNo,
            "strMeetingStartTime": "00:00",
            "strMeetingEndTime": "00:00",
            "companyEntityName": formik.values.companyname,
            "purposeofVisit": formik.values.purposeofVisit,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "meetingAgendaTask": premeetinglist,
            "meetingRequiredAttendance": attendeeslist,
            "meetingNotifyUser": notifyuserlist,
            "meetingTourUsers": userid

        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.toggle()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleTaskDueDate = (date) => {
        console.log(date)
        if (date == null) {
            settaskduedate(false)
        }
        else {
            settaskduedate(date)
            console.log(taskduedate)
        }
    }
    const handleAssignedTo = (event, values) => {
        console.log(values)
        settaskassignedto(values.meetingUserID)
    }

    useEffect(() => {
        console.log(taskassignedtouser)
    }, [taskassignedtouser])
    const editTask = (k) => {
        let ind = k
        setindex(ind)
        formik.values.taskname = premeetinglist[k].task
        formik.values.taskDescription = premeetinglist[k].taskDescription
        settaskduedate(premeetinglist[k].taskDueDate)
        settaskassignedto(premeetinglist[k].meetingUserID)
        setistaskedit(true)
        setAgendStatus(!agendaStatus)
    }
    const updateAgenda = (k) => {
        let ind = k
        let tasklist = [...premeetinglist]
        let task_json = {
            "task": formik.values.taskname,
            "is_PreMeetingPoints": preemeetingstatus,
            "is_Completed": false,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_Completed": false,
            "taskDescription": formik.values.taskDescription
        }
        console.log(task_json)
        tasklist[ind] = task_json
        setpreemeetinglist(tasklist)
        setAgendStatus(!agendaStatus)

        formik.values.taskname = ''
        formik.values.taskDescription = ''
        settaskduedate(false)
        settaskassignedto(0)
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
            <ModalHeader toggle={toggle} className='p-40'>
                {hostmodal &&
                    <AddExternalUser isOpen={hostmodal} getUsers={getUsers}  toggle={addHost}/>
                }
                <h2>Tours</h2>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-50 scrollMeet mb-10'>
                <div className='comp-color p-1-rem br-4'>
                    {props.company != null &&
                        <div className='d-flex justify-content-between px-2'>
                            <div className='font-weight-400 font-13'>Company: <span className='font-16 font-weight-500 ps-2'> {props.company.company.companyName}</span></div>
                            <div className='font-13 font-weight-500 pointer' style={{ color: '#0d6efd' }} onClick={props.changeCompany}>Change</div>
                        </div>
                    }
                </div>
                <div className=''>
                    <div className='font-16 font-weight-600 color-707895 '>
                        <>
                            <Row className='border_bottom_input'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Reference No. for document control' className=''
                                    name='referenceNo'
                                    val={formik.values.referenceNo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <FormFields.DatePickBottomBorder
                                    md={6}
                                    handleCompletionDate={handlevisitdate}
                                    label='Date of Visit'
                                    val={visitdate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                />
                                <FormFields.BottomBorderInputField
                                    md={6}
                                    label='Name of the Company / Entity' className=''
                                    name='companyname'
                                    val={formik.values.companyname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <Col md={11}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={userlist.filter(x => x.is_Internal == false)}
                                        getOptionLabel={(option) => option.userName}
                                        onChange={handleSelectUser}
                                        limitTags={2}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                </div>
                                                <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Name of person met"
                                                placeholder="Person Met"
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={1}>
                                    <div className='no_border'>
                                        <img src='assets/images/blue_add.svg' className='pointer' onClick={addHost} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <Col md={6}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={userlist.filter(x => x.is_Internal == true)}
                                        getOptionLabel={(option) => option.userName}
                                        onChange={handlerequiredattendees}
                                        limitTags={2}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                </div>
                                                <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Add Required Attendees"
                                                placeholder="Add Required Attendees"
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        onChange={handlenotifyusers}
                                        limitTags={2}
                                        options={userlist.filter(x => x.is_Internal == true)}
                                        getOptionLabel={(option) => option.userName}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                </div>
                                                <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Notify Members"
                                                placeholder="Notify Members"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Purpose of visit' className=''
                                    name='purposeofVisit'
                                    val={formik.values.purposeofVisit}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem'>
                                <Tabs>
                                    <div className='d-flex'>
                                        <Col md={6}>
                                            <TabList>
                                                <Tab>
                                                    Discussion Points
                                                </Tab>
                                            </TabList>
                                        </Col>
                                        <Col md={6} className='d-flex  justify-content-end'>
                                            <div className='no_border'>
                                                <img src='assets/images/blue_add.svg' className='pointer' onClick={agendaHtml} />
                                            </div>
                                        </Col>
                                    </div>
                                    <TabPanel>
                                        {agendaStatus == true &&
                                            <>

                                                <Row className='border_bottom_input task_description'>
                                                    <div className='task_div'>
                                                        <FormFields.BottomBorderInputField
                                                            md={12}
                                                            label='Discussion Point'
                                                            name='taskDescription'
                                                            val={formik.values.taskDescription}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                </Row>
                                                <div className='d-flex task1'>
                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='taskCompletedStatus' disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            <FormFields.BottomBorderInputField
                                                                md={12}
                                                                label='Add Task'
                                                                name='taskname'
                                                                val={formik.values.taskname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                        <div className='task_div d-flex meeting_task_due_date'>
                                                            <FormFields.DatePickBottomBorder
                                                                md={12}
                                                                handleCompletionDate={handleTaskDueDate}
                                                                label='Task Due Date'
                                                                val={taskduedate}
                                                                className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                            />
                                                        </div>
                                                        <div className='task_div col-md-8'>
                                                            <Autocomplete
                                                                id="tags-standard"
                                                                defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                options={userlist}
                                                                getOptionLabel={(option) => option.userName}
                                                                onChange={handleAssignedTo}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                            <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                        </div>
                                                                        <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Assigned To"
                                                                        placeholder="Assigned To"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={agendaHtml}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={
                                                        () => {
                                                            isTaskEdit ?
                                                                updateAgenda(index)
                                                                :
                                                                saveAgenda()
                                                        }
                                                    }>
                                                        Save Task
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {premeetinglist.map((meeting, k) => {
                                            let users = userlist.filter(x => x.meetingUserID == meeting.meetingUserID)
                                            console.log(users)
                                            return (

                                                <>
                                                    <Col md={12} className='bg-light mt-1-rem' style={{ padding: '10px', borderRadius: '13px' }}>
                                                        {meeting.taskDescription}

                                                        <div className='d-flex task1'>
                                                            <Col md={12} className='d-flex task1 align-items-center'>
                                                                <div className='task_div'>
                                                                    <img src='assets/images/meeting_task.svg' />
                                                                </div>
                                                                <div className='task_div '>
                                                                    <Form.Check
                                                                        name='taskCompletedStatus' disabled={true} />
                                                                </div>
                                                                <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                    {meeting.task}
                                                                </div>

                                                                <div className='d-flex justify-content-end col-md-6'>
                                                                    <div className='task_div '>
                                                                        {Moment(meeting.taskDueDate).format('DD/MM/YYYY')}
                                                                    </div>
                                                                    <div className='task_div '>
                                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                            <p className='font-12 font-weight-500 mb-0'>{users[0]!=null && users[0].userAvatar}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='task_div '>
                                                                        <ButtonComponent.EditButton clickEvent={() => { editTask(k) }} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                </>
                                            )
                                        })}
                                    </TabPanel>
                                </Tabs>
                            </Row>
                        </>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Back' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
            </ModalFooter>
        </Modal>
    )
}
