import React, { useState, useEffect  } from 'react'
import { Row } from 'react-bootstrap';
import ListData from './components/ListData';
import FormComponent from './components/FormComponent';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function ShareHolder(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)
    useEffect(()=>{
      if(isFirstLoad){
        if(props.shareholderList.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.shareholderList.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    }, [])
    useEffect(()=>{
      console.log()
      if(props.isEdit == true && isFirstEdit == true){
        console.log(props.shareholderList, 'bank lisr')
        setIsAdd(false)
        setIsFirstEdit(false)
      }
    }, [])
    useEffect(()=> {
      if(props.isDirectorSet == true){
        setIsAdd(false)
      }
    },[])
    useEffect(()=> {
      if(props.isBackClicked == true){
        setIsAdd(false)
      }
    }, [])
    const openAddSupplier =()=>{
      setIsAdd(true);
    }

    const addShareholderToList= (data)=>{
        console.log('data on addShareholderToList function')
      setIsAdd(false);
      props.addShareholderToList(data)
    }

    const editBank =(e)=>{
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.shareholderList.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToSupplier= (data)=>{
      setIsAdd(false);
      setIsEdit(false);
      props.editShareholderToList(data)
    }

    
    const handleCancel = () => {
      setIsAdd(false)
    }
  return (
    <>
        <Row className='d-flex' style={{background: '#fff'}}>
          <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
            Shareholders
              <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Shareholder' clickEvent={openAddSupplier}/></span>
          </div>
            {!isAdd?
              <div className='mt-2-rem'>
         
                  <table className="table table-text mt-1-rem">
                    <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                            <th scope='col' className=' font-weight-500'>Name of Shareholder</th>
                            <th scope='col' className=' font-weight-500'>Shareholder Type</th>
                            <th scope='col' className=' font-weight-500'>Nationaltiy</th>
                            <th scope='col' className=' font-weight-500'>Shareholding Percentage</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.shareholderList.map((values)=>(
                          <ListData  is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected} is_DefaultAvailable={values.is_Default?true:false} values={values} shareholderList ={props.shareholderList} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                         
                        ))}
                    </tbody>
                </table>
              </div>
            :
            <FormComponent handleCancel= {handleCancel} handleValidationShareholder = {props.handleValidationShareholder} setErrors = {props.setErrors} handleError = {props.handleError} addShareholderToList = {addShareholderToList}  isEdit={isEdit} data={selectedBank} editShareholderToList={editBankToSupplier}/>
          }
        </Row>
        </>
    
  )
}

export default ShareHolder