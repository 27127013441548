import React,{useState, useEffect} from 'react'
import axios from 'axios';
//import configfile from "../../../../config.json";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useFormik } from 'formik'
import { Row, Col, Button, Dropdown, Card, Form, FloatingLabel } from "react-bootstrap"
import * as CONFIG from "../../../../helpers/config"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function CustomerBankSelectionModal(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [supplierList, setSupplierList] = useState([])
    const [isFirst, setIsFirst] = useState(true);
    const [selectedBank, setSelectedBank] = useState();
    const [error, setError] = useState(false)
    const MySwal = withReactContent(Swal)

     useEffect(()=>{
        console.log('inside modal')

        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
        if(isFirst){
            getList()
        }
        setIsFirst(false)
    })
   
    const toggle = () => {
        setModal(!modal)
        props.close();
    }
    const getList = () =>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SUPPLIER_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setSupplierList(res.data.result);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const moveToNext = ()=>{
        if (selectedBank != null){
            console.log('Not worked')
            //console.log(selectedBank)
            props.handleSaveBankSelectionCustomer(selectedBank)
            props.close()
        }
        else{
            MySwal.fire(
                {
                icon: 'error',
                title: 'Please select a Bank Account or choose Cancel to go back to L/C Documents Screen',
                //type: 'error',
                confirmButtonText: 'Back',
                backdrop: `
                rgba(60, 60, 60, 0.5)
                no-repeat
              `
              })
        }
    }
    const back = (data)=>{
        setSelectedBank(data)
    }
    const trackCheck = (e, data) => {
        setError(false)
        setSelectedBank(data)
    }

    return (
      <div className='trade_existing_customer"'>
          <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
                      <ModalHeader className='px-5 py-4' toggle={toggle}>
                        <div className = 'font-16 text-uppercase font-weight-600 color-707895'>
                            Existing Customer Bank Accounts
                        </div>
                      </ModalHeader>
                      <ModalBody  className = 'px-5' style={{overflowY:'scroll'}}>
                      <div className=' customerList'>
                        <table className="table table-text mt-1-rem">
                                <thead>
                                    <tr className='bg-light font-weight-500 p-3'>
                                        <th className='p-3'>Bank Name</th>
                                        <th className='p-3'>Account No</th>
                                        <th className='p-3'>IBAN</th>
                                        <th className='p-3'>Currency</th>
                                        <th></th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {
                                       props.data != null && props.data.length > 0 ?
                                       props.data.map((list,k)=>(
                                            <tr className='py-3 br-bottom-1 '>
                                                <td className=''>{list.bankName}</td>
                                                <td className=''>{list.accountNo}</td>
                                                <td className=''>{list.iban}</td>
                                                <td className=''>USD</td>
                                                <td>
                                                <Form.Check 
                                                        type='radio'
                                                        name='supplierData'
                                                        id={list.customerBankAccountInformationID}
                                                        defaultChecked = {props.selectedCustomerBank != null &&  props.selectedCustomerBank != "" ? props.selectedCustomerBank.customerBankAccountInformationID == list.customerBankAccountInformationID ? true : false : false}
                                                        onChange = {(e) => {trackCheck(e,list)}}
                                                        className='mx-3 slectSupplier'
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                       :
                                       <tr className='py-3 br-bottom-1 '>
                                        <td>
                                            No Bank Accounts Created For Customer
                                        </td>
                                        </tr>
                                    }
                                </tbody>
                        </table>
                      </div>
                      </ModalBody>
                    <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                       <BlueBorderButton value='Cancel' clickEvent={toggle} />
                        <span className='pl-10'><BlueButton value='Select' clickEvent={moveToNext} disabled={error == true ? true : false}  /></span>
                    </ModalFooter>
          </Modal>
      </div>
    )
}

export default CustomerBankSelectionModal