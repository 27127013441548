import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import * as CONFIG from '../../helpers/config'
import axios from 'axios';
import { Row, Col, Button, Form } from "react-bootstrap"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { useMsal } from "@azure/msal-react";
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import { BlueButton, BlueBorderButton } from '../../components/buttoncomponents'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

export default function ContractModal(props) {
    const { instance } = useMsal();
    console.log(props)
    const [modal, setModal] = useState(props.modal)
    const [template_list, settemplatelist] = useState([])
    const [isContract, setIsContract] = useState(false)
    const [data, setData] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const contractid = props.contractID
    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.contractID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_CONTRACT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settemplatelist(res.data.result[0].contractSections)
                setData(res.data.result[0])
                setModal(true)
                setIsContract(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    const toggle = () => {
        setModal(!modal)
        props.toggle()
    }
    const togglechecked = () => {
        setIsChecked(!isChecked)
    }
    const approve = () => {
        toggle()
        props.approve()
    }
    const reject = () => {
        toggle()
        props.reject()
    }
    const printDocument = () => {
        if (props.status == 1) {
            const domElement = document.getElementById("download_section");
            var charSet = ' '
            var content = charSet + domElement.innerHTML;
            var converted = htmlDocx.asBlob(content);
            if(data.contractTemplateID == 1){
                saveAs(converted, 'Customer_Contract.docx');
            }else{
                saveAs(converted, 'Supplier_Contract.docx');
            }
        }
        else {

            const domElement = document.getElementById("download_section");
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm', 'A4');
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    if(data.contractTemplateID == 1){
                        doc.save('Customer_Contract.pdf');
                    }else{
                        doc.save('Supplier_Contract.pdf');
                    }
                })
        }

    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
            <ModalBody toggle={toggle}>
                <div className='service-request-detail-title mx-4 mt-4'>
                    <div className='d-flex justify-content-between'>
                        <div style={{ fontSize: '18px', fontWeight: '600' }}>
                            <>Contract</>
                        </div>
                        <div>
                            <BlueBorderButton value='DOWNLOAD DOCUMENT' clickEvent={printDocument} className='ml-1-rem' />
                        </div>
                    </div>
                </div>
                <div id='download_section' className='mx-4'>
                    <center>
                        <div className='justify-content-end d-flex mt-3' style={{ fontSize: '14px', fontWeight: '400' }}>
                            Contract
                        </div>
                    </center>
                    {isContract &&
                        template_list.map((list) => {
                            var contractData = list.contractContent;
                            return (
                                <Row>
                                    <Col md={4} className="my-3">
                                        {list.srNo}.&nbsp;{list.heading} :
                                    </Col>
                                    <Col md={8} className="my-3" >
                                        <div dangerouslySetInnerHTML={{ __html: contractData }} />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                {localStorage.getItem('LogInUserRoleID') != 3001 &&
                    <>
                        <>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_DischargePortETA'
                                label='I have reviewed this document'
                                onChange={togglechecked}
                            />
                        </>
                        <>
                            <Button variant="primary" size="sm" onClick={approve} className={'font-weight-500 font-12 rounded-13 '} disabled={!isChecked}>
                                Approve
                            </Button>
                        </>
                        <>
                            <Button variant="danger" size="sm" onClick={reject} className={'font-weight-500 font-12 rounded-13 ms-2'} disabled={!isChecked}>
                                Reject
                            </Button>
                        </>
                    </>
                }

            </ModalFooter>
        </Modal>
    )
}
