import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row  } from "react-bootstrap"
import { useFormik } from 'formik';
import { BlueBorderButton, BlueButton,AddNewButton } from '../../../../../../../components/buttoncomponents';
import Trace_form from './Trace_form';
import * as CONFIG from  "../../../../../../../helpers/config"
import Trace_list from './trace_list';
import { useMsal } from "@azure/msal-react";

function AddTraceElemetsModel(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [showList, setShowList] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [list, setList] = useState([])
    const [tradeEnquiryProductTraceElement, setTEP]= useState([])
    const [key, setkey] = useState(1)
    const [isEdit, setIsEdit] = useState(false)
    const [selected, setselected] = useState([])
    const [isFirst, setIsFirst] = useState(true)

    useEffect(()=>{
        if(props.isTrace){
            let data =[]
            const json_list = {
                "instanceID" : CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID
            }
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_TRACE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                data = res.data.result
                let list_ql = []
                console.log(data)
                props.traceData.map((qul,k)=>{
                    list_ql[k] = data.filter(x=> x.productTraceElementID == qul.productTraceElementID)[0]
                })
                console.log(list_ql)
                let ini_list =[]
                let ke = 0
                props.traceData.map((q,k)=>{
                    if(props.isEdit){
                        ini_list[k] ={
                            key : k,
                            tradeEnquiryProductDetailID: q.tradeEnquiryProductDetailID,
                            tradeEnquiryProductTraceElementID: q.tradeEnquiryProductTraceElementID,
                            productTraceElementID: q.productTraceElementID,
                            productTraceElementName: list_ql[k].productTraceElementName,
                            typicalValueRange: q.typicalValueRange,
                            rejectionValueRange: q.rejectionValueRange
                        }
                    }
                    else{

                        ini_list[k] ={
                            key : k,
                            productTraceElementID: q.productTraceElementID,
                            productTraceElementName: list_ql[k].productTraceElementName,
                            typicalValueRange: q.typicalValueRange,
                            rejectionValueRange: q.rejectionValueRange
                        }
                    }
                    ke =k
                })
                setkey(ke)
                if(isFirst){
                    setList(ini_list)
                    console.log(list)
                    final_json["tradeEnquiryProductTraceElement"] = ini_list
                    setTEP(final_json)
                    addform()
                    setShowSubmit(true)
                }
                
                setIsFirst(false)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
    },[isFirst])

    useEffect(()=>{
        console.log(list)
    },[list])
    const toggle = () => {
      setModal(!modal)
      props.close();
    }
    const final_json ={
        
    }
    const formik = useFormik({
        initialValues:{
            qualityParameterName:'',
            unit:'',
            typicalValueRange: '',
            rejectionValueRange: '',
        }
    });

    const addform = ()=>{
        setShowList(!showList)
    }
 
    const editList = (e)=>{
        
        const id = e.currentTarget.id
        console.log(id)
        let lis = list.filter(x=> x.key == parseInt(id));
        setselected(lis[0])
        isEditToggle()
        addform()
    }
    const handleModalDataSubmission = (data) => {
        console.log(data, 'handle sumbitssion')
        let lists = [...list]
        let k = key;
        k++
        data.key = k
        lists.push(data)
        setList(lists)
        setkey(k)
        
        let li = []
        lists.map((lis,l)=>{
            li[l]={
                "productTraceElementID": parseInt(lis.productTraceElementID),
                "typicalValueRange": lis.typicalValueRange,
                "rejectionValueRange": lis.rejectionValueRange
            }
            if(lis.tradeEnquiryProductDetailID){
                li[l].tradeEnquiryProductDetailID = lis.tradeEnquiryProductDetailID
            }
            if(lis.tradeEnquiryProductTraceElementID){
                li[l].tradeEnquiryProductTraceElementID = lis.tradeEnquiryProductTraceElementID
            }
        })
        console.log(li)
        final_json["tradeEnquiryProductTraceElement"] = li
        setTEP(final_json)
        console.log(tradeEnquiryProductTraceElement, 'final data')
        addform()
        setShowSubmit(true)
    }
    const isEditToggle = ()=>{
        setIsEdit(!isEdit)
    }
    const remove = (e)=>{
        const id = e.currentTarget.id
        console.log(id)
        console.log(list)
        let lis = list.filter(x=> x.key != parseInt(id))
        let re = list.filter(x=> x.key == parseInt(id))[0]
        re.is_Active = false
        re.is_Deleted = true
        console.log(lis)
        setList(lis)
        let li = []
        lis.map((liss,k)=>{
            li[k]=liss
        })
        li.push(re)
        final_json["tradeEnquiryProductTraceElement"] = li
        setTEP(final_json)
        console.log(lis.length)
        if(lis.length == 0){
            addform()
        }
    }

    const handleModalDataUpdation = (data)=>{
        console.log(data)
        let lis  = [...list]
        list.map((li,k)=>{
            if(li.key == data.key){
                lis[k] = data
            }
        })
        setList(lis)
        let li = []
        lis.map((lis,k)=>{
            li[k]={
                "productTraceElementID": parseInt(lis.productTraceElementID),
                "typicalValueRange": lis.typicalValueRange,
                "rejectionValueRange": lis.rejectionValueRange
            }
            if(lis.tradeEnquiryProductDetailID){
                li[k].tradeEnquiryProductDetailID = lis.tradeEnquiryProductDetailID
            }
            if(lis.tradeEnquiryProductTraceElementID){
                li[k].tradeEnquiryProductTraceElementID = lis.tradeEnquiryProductTraceElementID
            }
        })
        final_json["tradeEnquiryProductTraceElement"] = li
        setTEP(final_json)
        addform()
        isEditToggle()
    }
    const saveTrace = ()=>{
        props.saveTrace(tradeEnquiryProductTraceElement)   
    }
    return (
    <>
        <div>
            <div>
            <Modal isOpen={modal} toggle={toggle} className = 'modal-dialog-custom-width trade_existing_customer'>
                        <ModalHeader className='p-5' toggle={toggle}>
                            <div className = 'd-flex justify-content-between'>
                                <div className='font-16 font-weight-600 color-707895'>
                                    Trace Elements
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody  className = 'service-request-modal-wrapper'>
                            <>
                            <div className='p-4 bg-light mx-4 br-8 mb-3'>
                                    <div className = 'd-flex justify-content-between'>
                                        <div className = 'd-flex flex-column'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Code
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                                {props.value.productCode}
                                            </div>
                                        </div>
                                        <div className = 'd-flex flex-column'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Name
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productName}
                                            </div>
                                        </div>
                                        <div className = 'd-flex flex-column'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product Group
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productGroupValue}
                                            </div>
                                        </div>
                                        <div className = 'd-flex flex-column'>
                                            <div className = 'font-weight-400 color-69738'>
                                                  Product UOM
                                            </div>
                                            <div className = 'font-weight-600 textHead'>
                                            {props.value.productUOMValue}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4'>
                                    <div className = 'd-flex justify-content-between'>
                                        <div className='font-16 font-weight-600 color-707895 pt-4 mb-3'>
                                        Add Trace Elements
                                        </div>
                                    </div>
                                </div>
                            {showList ?
                                <>
                                <Row className='mx-4 br-8 py-3'> 
                                <div className='d-flex justify-content-end'>
                                            <AddNewButton value='Add Trace Parameters' clickEvent={addform} width='auto'/>
                                </div>
                                        <table className="table tCustom table-text mt-2-rem">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                    <th scope='col' className='font-weight-500'>TRACE ELEMENTS</th>
                                                    <th>UNITS</th>
                                                    <th scope='col' className='font-weight-500'>TYPICAL</th>
                                                    <th scope='col' className='font-weight-500'>REJECTION</th>
                                                    <th scope='col'></th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((lis)=>(
                                                    <Trace_list values={lis} editList={editList} remove={remove}/>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Row>
                                </>
                                :
                                <Trace_form handleModalDataSubmission={handleModalDataSubmission} isEdit={isEdit} data={selected} handleModalDataUpdation={handleModalDataUpdation}/>
                            }
                            
                            </>
                        </ModalBody>
                        <ModalFooter className='mx-5 my-5 justify-content-start modal-footer'>
                            <BlueBorderButton value='Cancel' clickEvent={toggle} />
                            {showSubmit == true ? 
                                <span className='pl-10'><BlueButton value='Submit' clickEvent={saveTrace}/></span>
                            : 
                                <span className='pl-10'><BlueButton value='Submit' disabled/></span>
                            }
                        
                        </ModalFooter>
                </Modal>
            </div>
        </div>
    </>
    )
}

export default AddTraceElemetsModel