import React from 'react'
import Moment from 'moment'
import styles from "./greetings.module.css"
import NumberFormat from 'react-number-format';
function Index(props) {
    
    const handleTimeGretting = () => {
        var currentHour = Moment().format("HH");
        const hour = Moment().hour();
        console.log(hour)
        if (hour > 17){
            return (
                <div className = {` gil-semibold ${styles.greetingHourText}`}>
                    Good Evening
                </div>
            );
        }

         if (hour > 12){
            return (
                <div className = {` gil-semibold ${styles.greetingHourText}`}>
                    Good Afternoon
                </div>
            );
         }

         return (
            <div className = {` gil-semibold ${styles.greetingHourText}`}>
                Good Morning
            </div>
        );
    }
  return (
    <>
                <div  className = {`${styles.greetingContainer}`}>
                    <div className = 'd-flex align-items-center'>
                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{background:'#FFCE22', backgroundColor:"#FFCE22", width: '50px', height: '50px', fontSize: '12px' }}>
                            <p className='font-16 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                        </div>
                        <div className = 'd-flex flex-column ms-2'>
                            {handleTimeGretting()}
                            <div className = {` gil-semibold ${styles.greetingNameText}`}>
                                {localStorage.getItem('UserFullName')}
                            </div>
                        </div>
                    </div>
                    <div className = 'row'>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat className='gil-regular' value={props.shipmentDetails.newRequirementCount != null ? props.shipmentDetails.newRequirementCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`gil-semibold my-2 ${styles.text}`}>
                                        new requirements
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0  ${styles.title}`}>
                                        <NumberFormat className='gil-regular' value={props.shipmentDetails.totalIntiatedCount != null ? props.shipmentDetails.totalIntiatedCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`gil-semibold my-2 ${styles.text}`}>
                                        initiated
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-4 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat className='gil-regular' value={props.shipmentDetails.totalUpcomingTradeCount != null ? props.shipmentDetails.totalUpcomingTradeCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 gil-semibold ${styles.text}`}>
                                        upcoming
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default Index