import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFormik } from 'formik'
import * as CONFIG from "../config"
import Multiselect from 'multiselect-react-dropdown';
import { Row, Col, Form, Card, FloatingLabel  } from "react-bootstrap";
import * as FormFields from "../../components/formcomponents"
import NumberFormat from 'react-number-format';
import { useMsal } from "@azure/msal-react";
//import

export const CompanyType = (props)=>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [companyType, setCompanyType] = useState([]);
    console.log('country list', props)
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_COMPANY_TYPE, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "id": 0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCompanyType(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])
    
    return (
        <>
            {companyType.map((type,k)=>(
                <option  key={k} value={type.companyTypeID} defaultValue={props.companyTypeID == type.companyTypeID} selected={props.companyTypeID == type.companyTypeID}>{type.companyTypeName}</option>
            ))}
        </>
    )
}

export const CompanyTypeSupplier = (props)=>{
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true);
    const [companyType, setCompanyType] = useState([]);
    console.log('country list')
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_COMPANY_TYPE_SUPPLIER, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User" : CONFIG.LoginID,
                "id": 0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCompanyType(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])
    
    return (
        <>
            {companyType.map((type,k)=>(
                <option key={k} value={type.companyTypeID} defaultValue={type.companyTypeID == props.id} selected={props.companyTypeID == type.companyTypeID}>{type.companyTypeName}</option>
            ))}
        </>
    )
}


export const CountryList = (props)=>{
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_COUNTRY_LIST, {
                "PMSInstanceID": CONFIG.PMSInstanceID
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY }  })
            .then((res) => {
                console.log(res.data.result)
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    console.log(countryList)
    return(
        <>
        {countryList.map((type,k)=>(
            <option key={k} value={type.countryId} selected={type.countryId == props.cntid}>{type.countryName}</option>
        ))}
    </>
    )
}

export const IndustryList = (props)=>{
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_INDUSTRY_LIST, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id" :0,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    console.log(countryList)
    return(
        <>
            {countryList.map((list,k)=>(
                <option key={k} value={list.customerIndustryTypeID} defaultValue={list.customerIndustryTypeID == props.id} selected={props.customerIndustryTypeID == list.customerIndustryTypeID}>{list.industryTypeName}</option>
            ))}
        </>
    )
}

export const IndustryListSupplier = (props)=>{
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    console.log('give')
    console.log(props)
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_INDUSTRY_LIST_SUPPLIER, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id" :0,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {countryList.map((list,k)=>(
                <option key={k} value={list.supplierIndustryTypeID} defaultValue={list.supplierIndustryTypeID == props.id} selected={props.supplierIndustryTypeID == list.supplierIndustryTypeID}>{list.industryTypeName}</option>
            ))}
        </>
    )
}

export const ContactTypeList = (props)=>{
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    console.log('give', props.contactTypeID)
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_CONTACT_TYPE_LIST, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_User" : CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {countryList.map((list,k)=>(
                <option 
                    key={k} 
                    value={list.customerContactTypeID} 
                    defaultValue={list.customerContactTypeID == props.contactTypeID} 
                    selected={props.contactTypeID == list.customerContactTypeID}>
                        {list.contactTypeName}
                </option>
            ))}
        </>
    )
}




export const ContactTypeListSupplier = (props)=>{
    console.log(props)
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    console.log('give')
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_CONTACT_TYPE_LIST_SUPPLIER, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_User" : CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {countryList.map((list,k)=>(
                <option 
                key={k} 
                value={list.supplierContactTypeID} 
                defaultValue={list.supplierContactTypeID == props.contactTypeID} 
                selected={props.contactTypeID == list.supplierContactTypeID}>
                    {list.contactTypeName}
                </option>
            ))}
        </>
    )
}

export const AddCustomer = (data)=>{
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_CUSTOMER, 
            data, 
            {headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then(res => {
                
            })   
}



export const StateList = (props)=>{
    const { instance } = useMsal();
    const[stateList, setStateList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    let countryId = props.countryID
    console.log(props.stateId)
    console.log(props.checking, props.countryID)
    console.log(props.countryChange)
    useEffect(()=>{
        if(props.countryID!=null){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_STATE_LIST, {
                "PMSInstanceID": CONFIG.PMSInstanceID,
                "countryId" : countryId
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setStateList(res.data.result)
                
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    console.log(stateList)
    return(
        <>
            {stateList.map((list,k)=>(
                <option key={k} value={list.stateId}   defaultValue={list.stateId == props.id} selected={props.countryID == list.stateId}>{list.stateName}</option>
            ))}
        </>
    )
}

export const GetShareHolderType = (props)=>{
    const { instance } = useMsal();
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_SHAREGOLDER_TYPE, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCountryList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {countryList.map((list,k)=>(
                <option key={k} value={list.shareholderTypeID} defaultValue={list.shareholderTypeID == props.id} selected={props.shareholderTypeID == list.shareholderTypeID}>{list.shareholderTypeName}</option>
            ))}
        </>
    )
}


export const PaymentTypeList = (props)=>{
    console.log(props)
    const { instance } = useMsal();
    const [paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [overAllTotal, setOverAllTotal] = useState()
    const [trackCheck, setTrackCheck] = useState([])
    const [isPaymentset, setispaymentset] = useState(false)
    const formik = useFormik({
        initialValues:
        {
            //percentageValue: ''
        }
    })

    useEffect(()=>{
        console.log(props)
        if(props.tradeCustomerPaymentDetails != null && props.tradeCustomerPaymentDetails.length > 1){
            console.log('assa')
            setShowInputBox(true)
        }
        else if(props.paymentMethodsCustomer != null && props.paymentMethodsCustomer.length > 1){
            console.log('CHeck ')
              setShowInputBox(true)
        }
    },[])
    useEffect(()=>{
        console.log('inside')

        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_TMS_PAYMENT_TERMS, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user":CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setPaymentType(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
        }else{
            setIsFirst(false)
        }
    },[])

    useEffect(()=>{
        
    },[paymentType])
    const handleDefaultChecked = (list) => {
     
    }
        return(
            <>
           
            {paymentType.map((type,k)=>(
                <option 
                key={k} 
                value={type.paymentTypeID} 
                defaultValue={type.paymentTypeID == props.id} 
                selected={props.paymentTypeID == type.paymentTypeID}>
                    {type.typeName}
                </option>
            ))
            }
            </>
        )
}

export const AddDivisions = (props)=>{
    const { instance } = useMsal();
    const[paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [checked, setChecked] = useState()
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [workFlowList, setWorkFlowList] = useState()
    const max = 100;
    const min = 0;
    const formik = useFormik({
        initialValues:
        {
            divisionName: '',
            companyID:'',
        }
    })

    useEffect(()=>{
        console.log(props)
        axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_COMPANY_LIST, {
                    "PMSInstanceID": CONFIG.PMSInstanceID,
                    "PMSAccountSubscriptionID":CONFIG.AccountSubscriptionID,
                }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result[0], 'company')
                    setCompanyList(res.data.result)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
                axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_WORKFLOW_DEFINITIONS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result, 'workflows')
                    setWorkFlowList(res.data.result)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        
    },[])

    const handleCheck = (event) => {
        console.log(event.target.value, event.target.name)
        console.log(props.checked, props.checkedName)
        var updatedList = [...props.checked];
        var updatedNameList = [...props.checkedName]
        if (event.target.checked) {
          updatedList = [...props.checked, parseInt(event.target.value)];
          updatedNameList = [...props.checkedName, event.target.name]
        } else {
          updatedList.splice(props.checked.indexOf(event.target.value), 1);
          updatedNameList.splice(props.checkedName.indexOf(event.target.id), 1);
          
        }
        console.log(updatedList.length)
        console.log(updatedNameList.length)
        // if(updatedList.length > 1){
        //     setShowInputBox(true)
        // }
        // else{
        //     setShowInputBox(false)
        // }
        // //setCount(updatedList.length)
        props.getChecked(updatedList);
        props.getCheckedName(updatedNameList)


    };

        return(
            <>
            <div className ='bg-light my-2 p-4 align-items-center br-8'>
                {companyList != null && companyList.length > 0 ? 
                    <>
                    <div className = 'd-flex align-items-center justify-content-between mt-2'>
                        <div className = 'd-flex flex-column'>
                            <div className = 'font-weight-400 color-697382'>Company Name</div>
                            <div className = 'font-weight-600 color-243448'>{companyList[0].company.companyName}</div>
                        </div>
                    </div>
                    <div className = 'd-flex flex-column mt-2'>
                            <div className = 'font-weight-400 color-697382'>Address</div>
                            <div className = 'font-weight-600 color-243448'>{companyList[0].company.companyUnit}, &nbsp; {companyList[0].company.companyBuilding}, &nbsp; 
                            {companyList[0].company.companyStreet}, &nbsp; {companyList[0].company.companyPoBox}, &nbsp; {companyList[0].statename}, &nbsp; 
                            {companyList[0].countryname} &nbsp;
                            </div>
                    </div>
                    </>
                    : 
                    ''
                    }
            </div>
            <div className =' my-2 p-4 align-items-center br-8'>
                <div>
                <FloatingLabel controlId="floatingInput" label='Division Name'>
                    <Form.Control
                        className="form_input form_input_color_change" 
                        type="text"
                        name='divisionName'
                        placeholder="Division Name"
                        onChange = {formik.handleChange}
                        value = {formik.values.divisionName}
                    />
                </FloatingLabel>
                </div>                
            </div>
            {workFlowList != null && workFlowList.length > 0 ? 
           <div style={{overflowY:'scroll', maxHeight: '400px'}}>
                {workFlowList.map((list,k) => (
                    <div className ='bg-light my-2 p-4 align-items-center br-8'>
                            <div>
                                <Form.Check
                                    className = ''
                                    type='checkbox'
                                    // defaultChecked={list.paymentTypeID == 4 ? true:false}
                                    name={list.description}
                                    label={list.description}
                                    id = {list.description}
                                    onChange={handleCheck}
                                    value={list.workflowDefinitionID}
                                />
                            </div> 
                    </div>
                ))}
           </div>
            :
            ''
            }
            </>
        )
}

export const SupplierPaymentTypeList = (props)=>{
    console.log(props)
    const { instance } = useMsal();
    const[paymentType, setPaymentType] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [checked, setChecked] = useState(4)
    const [showInputBox, setShowInputBox] = useState(false)
    const [final_json, setFinal] = useState([])
    const [overAllTotal, setOverAllTotal] = useState()
    
    const [updatedList, setUpdatedList] = useState()
    const [updatedNameList, setUpdatedNameList] = useState()
    const max = 100;
    const min = 0;
    const formik = useFormik({
        initialValues:
        {
            //percentageValue: ''
        }
    })

    useEffect(()=>{
        console.log(props)
        if(props.tradeSupplierPaymentDetails != null && props.tradeSupplierPaymentDetails.length > 1){
            console.log('assa')
            setShowInputBox(true)
        }
        else if(props.paymentMethodsSupplier != null && props.paymentMethodsSupplier.length > 1){
            console.log('CHeck ')
              setShowInputBox(true)
        }
    },[])
    useEffect(()=>{
        let final =[]
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_TMS_PAYMENT_TERMS, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user":CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log('Use Effect Fin')
                console.log(res.data.result)
                setPaymentType(res.data.result)
                res.data.result.map((re,k)=>{
                    let fin =[]
                    
                    let fin1 = {
                        "supplierPaymentTypeID": re.paymentTypeID,
                        "supplierPaymentAmount" : 
                        props.tradeSupplierPaymentDetails != null && props.tradeSupplierPaymentDetails.length > 0 ? 
                        parseInt(props.tradeSupplierPaymentDetails.supplierPaymentAmount) : 
                        props.paymentMethodsSupplier != null && props.paymentMethodsSupplier.length > 0 ?  parseInt(props.paymentMethodsSupplier.supplierPaymentAmount)
                        :
                        parseInt(((props.currentSupplyPlan.productBuyingPrice) * (props.currentSupplyPlan.quantity)))
                        ,
                        "supplierPaymentPer": 
                        props.tradeSupplierPaymentDetails != null && props.tradeSupplierPaymentDetails.length > 0 ? 
                        parseInt(props.tradeSupplierPaymentDetails.supplierPaymentPer) : 
                        props.paymentMethodsSupplier != null && props.paymentMethodsSupplier.length > 0 ? parseInt( props.paymentMethodsSupplier.supplierPaymentPer)
                        :
                        0
                    }
                    final.push(fin1)
                })
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
            setFinal(final)
        }else{
            setIsFirst(false)
        }
    },[])

    const handleCheck = (event) => {
        console.log(props)
        var updatedList = [...props.checked];
        var updatedNameList = [...props.checkedName]
        var getAmountValuesList = props.getAmountValues
        let updateGetAmount = []
        console.log(getAmountValuesList)
        if (event.target.checked) {
          updatedList = [...props.checked, parseInt(event.target.value)];
          updatedNameList = [...props.checkedName, event.target.id]
        } else {
          updatedList.splice(props.checked.indexOf(event.target.value), 1)
          updatedNameList.splice(props.checkedName.indexOf(event.target.id), 1)
          console.log(props.getAmountValues)
          if(getAmountValuesList != null && getAmountValuesList.length > 0){
            for(let i = 0; i < getAmountValuesList.length; i++){
                console.log(event.target.value)
                console.log(getAmountValuesList)
                if(getAmountValuesList[i].supplierPaymentTypeID == parseInt(event.target.value)){
                    console.log(getAmountValuesList[i])
                    getAmountValuesList.splice(i, 1)
                }
              }
          }
        

        }
        console.log(getAmountValuesList)
        console.log(updatedList.length)
        console.log(updatedNameList.length)
        console.log(updatedList)
        console.log(updatedNameList)
        if(updatedNameList.length > 1){
            setShowInputBox(true)
        }
        else{
            setShowInputBox(false)
        }
        //setCount(updatedList.length)
        props.getChecked(updatedList);
        props.getCheckedName(updatedNameList)


    };

    const handlePercentageValues = (e) => {
        console.log(final_json)
        console.log(e.target.value)
        
        const value = Math.max(min, Math.min(max, Number(e.target.value)));
        console.log(value)
        const id = parseInt(e.target.id);
        console.log(id)
        const supplierPaymentPer = parseFloat(e.currentTarget.value)
        let final_data = [...final_json]
        console.log(final_json)
        console.log(final_json)
        console.log(supplierPaymentPer)
        final_json.map((final,k)=>{
            if(final.supplierPaymentTypeID == id){
                console.log(final_data[k])
                final_data[k].supplierPaymentPer = parseInt(supplierPaymentPer)
                console.log(final_data[k].supplierPaymentPer)
                var supplierPaymentAmount = ((parseInt(supplierPaymentPer) / 100) * (parseInt((props.currentSupplyPlan.productBuyingPrice) * (props.currentSupplyPlan.quantity))))
                final_data[k].supplierPaymentAmount = parseInt(supplierPaymentAmount)
            }
        })
        if(props.paymentMethodsSupplier != null && props.paymentMethodsSupplier.length > 0){
            for(let i = 0; i < final_data.length; i++){
                console.log(final_data[i].supplierPaymentTypeID)
                console.log(props.paymentMethodsSupplier[i])
                if(final_data[i].supplierPaymentTypeID != null && props.paymentMethodsSupplier[i] != null){
                    if(final_data[i].supplierPaymentTypeID == props.paymentMethodsSupplier[i].supplierPaymentTypeID){
                        if(final_data[i].supplierPaymentAmount == null){
                            final_data[i].supplierPaymentAmount = parseInt(props.paymentMethodsSupplier[i].supplierPaymentAmount)
                            final_data[i].supplierPaymentPer = parseInt(props.paymentMethodsSupplier[i].supplierPaymentPer)
                        }
                    }
                } 
            }
        }

        console.log(final_data)
        if(final_data != null && final_data.length > 0){
            for(let i = 0; i < final_data.length; i++){
                console.log(final_data[i])
                if(final_data[i].supplierPaymentPer != null && final_data[i].supplierPaymentPer == 0){
                    final_data[i].supplierPaymentAmount = 0
                }
            }
        }
        
        console.log(final_data)
        //setFinal(final_data)
        props.handleAmountValues(final_data)
        
        //setIsDataSet(!isDataSet)
        //props.setFinance(final_data)
        
    }
    useEffect(()=>{
        let listValues = []
        let listValuesTax = []
        if(props.currentSupplyPlan != null && props.currentSupplyPlan.productBuyingPrice){

            // console.log(sum)
            // setSubTotal(sum)
            // setTotalTax(sumTax)
            setOverAllTotal(parseInt(props.currentSupplyPlan.productBuyingPrice * props.currentSupplyPlan.quantity))
        }
    },[])

    const handleDefaultChecked = (list) => {
        console.log(list.paymentTypeID)
        console.log(props.paymentMethodsSupplier)
        for (let i = 0; i < props.paymentMethodsSupplier.length; i++){
            if(props.paymentMethodsSupplier[i].supplierPaymentTypeID == list.paymentTypeID){
                console.log(i)
                console.log(props.paymentMethodsSupplier[i].supplierPaymentTypeID )
                return true
            }
          
        }
    }
        return(
            <>
            {props.tradeEnquiry == true ? 
                            paymentType.map((list,k) => ( 
                                <>
                                <div className = {showInputBox == true ? 'bg-light  d-flex justify-content-between  my-2 p-4 align-items-center' : 'bg-light my-2 p-4 align-items-center br-8'}>
                                    <div>
                                       {props.paymentMethodsSupplier != null && props.paymentMethodsSupplier.length > 0 ?
                                       <Form.Check
                                        className = ''
                                        type='checkbox'
                                        defaultChecked={handleDefaultChecked(list)}
                                        name={list.paymentTypeID}
                                        label={list.typeName + (showInputBox == true ? ' (%)' : '')}
                                        id = {list.typeName}
                                        onChange={handleCheck}
                                        value={list.paymentTypeID}
                                       
                                      />
                                       :
                                       <Form.Check
                                       className = ''
                                       type='checkbox'
                                       defaultChecked={list.paymentTypeID == 4 ? true:false}
                                       name={list.paymentTypeID}
                                       label={list.typeName + (showInputBox == true ? ' (%)' : '')}
                                       id = {list.typeName}
                                       onChange={handleCheck}
                                       value={list.paymentTypeID}
                                   
                                      />
                                        }

                                       
                                    </div>
                                    {showInputBox == true ?
                                        <>
                                            <Form.Control
                                                type="text"
                                                className = 'payment-terms-width'
                                                maxlength = {3}
                                                name={list.typeName}
                                                id = {list.paymentTypeID}
                                                onChange = {handlePercentageValues}
                                                defaultValue = {
                                                    props.paymentMethodsSupplier != null 
                                                    && 
                                                    props.paymentMethodsSupplier.length > 0 
                                                    ?
                                                    props.paymentMethodsSupplier[k] != null ? props.paymentMethodsSupplier[k].supplierPaymentPer 
                                                    :
                                                    0
                                                    :
                                                    0
                                                }
                                            />
                                            {/* <div className = 'font-weight-600'>{final_json[k].supplierPaymentAmount}</div> */}
                                        </>
                            
                                        :
                                    ''
                                    }
                                </div>
                                </>
            ))
            : 
            paymentType.map((type,k)=>(
                <option key={k} value={type.paymentTypeID} defaultValue={type.paymentTypeID == props.id} selected={props.paymentTypeID == type.paymentTypeID}>{type.typeName}</option>
            ))
            }
               {showInputBox == true ?
               <div className = 'd-flex justify-content-end'>
                <p>Total Amount: <b><NumberFormat value={overAllTotal} displayType={'text'} thousandSeparator={true}  />.00</b></p>
               </div>
               :
               ''
                }
            </>
        )
}

export const CustomerCategoryList = (props) =>{
    const [customerCategory, setCustomerCategory] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_TMS_CUSTOMER_CATEGORY, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_user":CONFIG.LoginID,
                "id":0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerCategory(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    return(
        <>
            {customerCategory.map((category,k)=>(
                <option key={k} value={category.customerCategoryID} selected={category.customerCategoryID == props.customerCategoryID}>{category.categoryName}</option>
            ))}
        </>
    )
}


export const SupplierCategoryList = (props) =>{
    const { instance } = useMsal();
    const [supplierCategory, setSupplierCategory] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_TMS_SUPPLIER_CATEGORY, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                "created_user":CONFIG.LoginID,
                "id":0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setSupplierCategory(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    return(
        <>
            {supplierCategory.map((category,k)=>(
                <option key={k} value={category.supplierCategoryID} selected={category.supplierCategoryID == props.supplierCategoryID}>{category.categoryName}</option>
            ))}
        </>
    )
}
export const IncotermsList = (props)=>{
    const [incoterms, setIncoterms] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_INCOTERMS, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user":CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setIncoterms(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    return(
        <>
            {incoterms.map((inc,k)=>(
                <option key={k} value={inc.incotermsID} defaultValue={inc.incotermsID == props.id} selected={inc.incotermsID == props.incotermsID}>{inc.incotermsName} </option>
            ))}
        </>
    )
}

export const SofList = (props)=>{
    const { instance } = useMsal();
    const [socList, setSocList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        console.log('inside sof', props)
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SOURCE_OF_FUNDS, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_user":CONFIG.LoginID,
                "id":0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setSocList(res.data.result)
                console.log(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {socList.map((list,k)=>(
                <option key={k} value={list.customerSourceFundsID} defaultValue={list.customerSourceFundsID == props.id} selected={list.incotermsID == props.incotermsID}>{list.customerSourceFundsName}</option>
            ))}
        </>
    )
}

export const SofListSupplier = (props)=>{
    const { instance } = useMsal();
    const [socList, setSocList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        console.log('inside sof')
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_SOURCE_OF_FUNDS_SUPPLIER, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_user":CONFIG.LoginID,
                "id":0
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setSocList(res.data.result)
                console.log(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {socList.map((list,k)=>(
                <option key={k} value={list.supplierSourceFundsID} defaultValue={list.supplierSourceFundsID == props.id}>{list.supplierSourceFundsName}</option>
            ))}
        </>
    )
}

/*
export const AddCustomer = (props) =>{
    const [customerCategory, setCustomerCategory] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(()=>{
        let json_array = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_user":CONFIG.LoginID,
            "id":0
        }
        json_array = [...json_array,...arrayr]
        //let array3 =["customerManufacturingInformation"] = {}
        let bankLIst 
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.POST_CUSTOMER_DATA, {
               
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerCategory(res.data.result)
            })
        }else{
            setIsFirst(false)
        }
    },[isFirst])
    return(
        <>
            {customerCategory.map((category,k)=>(
                <option key={k} value={category.customerCategoryID} seletced={category.customerCategoryID == props.id}>{category.categoryName}</option>
            ))}
        </>
    )
}
*/


//Trade Enq API Starts
export const ContractTypeList = (props) => {
    const { instance } = useMsal();
    const [ConTypeList, setConTypeList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        console.log('inside sof')
        if (isFirst) {
            axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_TMS_TRADEENQUIRY_CONTRACTTYPE, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user": CONFIG.LoginID
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setConTypeList(res.data.result)
                    console.log(res.data.result, 'contract type')
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {ConTypeList.map((list, k) => (
                <Form.Check
                    className='mr-20 ms-3'
                    type='radio'
                    defaultChecked={list.contractTypeID == props.value? true:false}
                    name={props.name}
                    label={list.contractTypeName}
                    onChange={props.onChange}
                    value={list.contractTypeID}
                />
            ))}
        </>
    )
}

export const ModeOfTransportList = (props) => {
    const { instance } = useMsal();
    const [ModeOfTransportList, setModeOfTransportList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        console.log('inside sof')
        if (isFirst) {
            axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_TMS_TRADEENQUIRY_MODEOFTRANSPORT, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user": CONFIG.LoginID
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setModeOfTransportList(res.data.result)
                    let bysea = res.data.result.filter(x=> x.modeOfTransportName == 'By Sea')
                    console.log(bysea)
                    props.onChange(bysea[0].modeOfTransportID)
                    console.log(res.data.result, 'contract type')
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
        <div className = 'd-flex mx-3'>
            {ModeOfTransportList.map((list, k) => (
                props.tradeEnquiry == true ? 
                    <div>
                    <Form.Check
                        className='mr-20 ms-3'
                        type='radio'
                        defaultChecked = {list.modeOfTransportName == 'By Sea' ? true : false}
                        name={props.name}
                        id = {list.modeOfTransportName}
                        onChange={props.onChange}
                        label={list.modeOfTransportName}
                        value={list.modeOfTransportID}
                        disabled = {list.modeOfTransportName == 'By Air' ? true : false}
                       />
                    </div>
                   :
                    <Form.Check
                        className='mr-20 ms-3 '
                        type='radio'
                        defaultChecked = {list.modeOfTransportName == 'By Sea' ? true : false}
                        name={props.name}
                        label={list.modeOfTransportName}
                        onChange={props.onChange}
                        value={list.modeOfTransportID}
                        disabled = {list.modeOfTransportName == 'By Air' ? true : false}
                    />
            ))}
            </div>
        </>
    )
}
export const TradeIncotermsList = (props) => {
    const [incotermsTrade, setIncotermsTrade] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(() => {
        if (isFirst) {
            axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_INCOTERMS, {
                "instanceID": CONFIG.PMSInstanceID,
                "created_user": CONFIG.LoginID,
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setIncotermsTrade(res.data.result)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

   
    return (
        <>
            {incotermsTrade.map((list, k) => (
                props.tradeEnquiry == true ? 
                <div className = 'trade-enquiry-payment-method-inner-wrapper-modal d-flex justify-content-between my-2 p-3 align-items-center'>
                    <div>
                    <Form.Check
                        className='mr-20'
                        type='radio'
                        defaultChecked = {list.incotermsID == props.value? true:false}
                        name={props.name}
                        id = {list.incotermsName}
                        onChange={props.onChange}
                        label={list.incotermsName}
                        value={list.incotermsID}
                        disabled = {list.incotermsName == 'FAS' ? true : false}
                    />
                    </div>
                </div>
                :
                <Form.Check
                    className='mr-20 ms-3'
                    type='radio'
                    checked={list.incotermsID == props.value? true:false}
                    name={props.name}
                    onChange={props.onChange}
                    label={list.incotermsName}
                    value={list.incotermsID}
                    disabled = {list.incotermsName == 'FAS' ? true : false}
                />))}
        </>
    )
}
export const ProductList = (props) => {
    const { instance } = useMsal();
    const [socProduct, setProductist] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        console.log('inside sof')
        if (isFirst) {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TMS_PRODUCTLIST, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_user": CONFIG.LoginID,
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setProductist(res.data.result)
                    console.log(res.data.result, 'get product')
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {socProduct.map((list, k) => (
                <option key={k} value={list.productID} defaultValue={list.productID == props.id}>{list.productName}</option>
            ))}
        </>
    )
}

export const ProductGroup = (props) => {
    const { instance } = useMsal();
    const [socProduct, setProductist] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        console.log('inside sof')
        if (isFirst) {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TMS_PRODUCTGROUP, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_user": CONFIG.LoginID,
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setProductist(res.data.result)
                    console.log(res.data.result, 'get product')
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {socProduct.map((list, k) => (
                <option key={k} value={list.productID} defaultValue={list.productID == props.id}>{list.productName}</option>
            ))}
        </>
    )
}
export const ProductByIDList = (props) => {
    const [socProduct, setProductist] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(() => {
        console.log('inside sof')
        if (isFirst) {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TMS_PRODUCTBYIDLIST, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_user": CONFIG.LoginID,
                "productID": parseInt(props.id)
            }, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    setProductist(res.data.result)
                    console.log(res.data.result, 'get product by id')
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        } else {
            setIsFirst(false)
        }
    }, [isFirst])

    return (
        <>
            {socProduct.map((list, k) => (
                <option key={k} value={list.productID} defaultValue={list.productID == props.id}>{list.productName}</option>
            ))}
        </>
    )
}

export const ProductByGroup = (props) =>{
    const [productList, setProductList] = useState([])
    const [isProductSet, setIsProduct] = useState(false)
    const { instance } = useMsal();
    useState(()=>{
        console.log(isProductSet)
    },[isProductSet])
    useEffect(()=>{
        setIsProduct(false)
        console.log(props.groupId)
        let final_json =  {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.groupId //groupId
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_BY_GROUPID, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setProductList(res.data.result)
            setIsProduct(true)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[props])

    return(
        <>
            {productList.map((list,k)=>{
                    return(
                        <option value={list.productID} selected={list.productID == props.val}>{list.productName}</option>
                    )
                }) 
            }
        </>
    )
}

export const SupplyTerms = (props)=>{
    const [countryList, setCountryList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    console.log(props)
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_TMS_SUPPLY_TERMS, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(props.supplyTermsID)
                if(props.supplyTermsID != 5){
                    var filter = res.data.result.filter(x => x.supplytermsID != 5)
                    console.log(filter, ' test aspi')
                    setCountryList(filter)
                }
                else{
                    setCountryList(res.data.result)
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
        }
        else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {countryList.map((list,k)=>(
                <option key={k} 
                value={list.supplytermsID} 
                defaultValue={list.supplytermsID == props.supplyTermsID} 
                selected={props.supplyTermsID == list.supplytermsID}>{list.supplytermsName}</option>
            ))}
        </>
    )
}

export const Divisions = (props) => {
    const [division, setDivisionList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_DIVISION_, {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setDivisionList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            {division.map((list,k)=>(
                <option key={k} 
                    value={list.divisionID} 
                    defaultValue={list.divisionID == props.id} 
                    selected={props.divisionID == list.divisionID}
                >
                        {list.divisionName}
                </option>
            ))}
        </>
    )
} 

export const Roles = (props) => {
    const [roles, setRolesList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const { instance } = useMsal();
    useEffect(()=>{
        if(isFirst){
            axios.post(CONFIG.PMS_SERVER_URL+CONFIG.GET_USER_ROLE, {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "SolutionID": CONFIG.SolutionID,
                "created_User": CONFIG.LoginID,
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setRolesList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })
        }else{
            setIsFirst(false)
        }
    },[isFirst])

    return(
        <>
            <Multiselect
                displayValue="key"
                onKeyPressFn={function noRefCheck(){}}
                onRemove={function noRefCheck(){}}
                onSearch={function noRefCheck(){}}
                onSelect={function noRefCheck(){}}
                options={roles.map((list,k)=>(
                    {
                        cat: list.userRoleID,
                        key: list.userRoleType
                    }
                ))}
                showCheckbox
            />
            {/* {roles.map((list,k)=>(
                <option key={k} 
                    value={list.userRoleID} 
                    defaultValue={list.userRoleID == props.id} 
                    selected={props.userRoleID == list.userRoleID}
                >
                        {list.userRoleType} 
                </option>
            ))} */}
        </>
    )
} 

export const AccountType = (props)=>{ 
    const [accountTypeList, setAccountTypeList] = useState([]); 
    const [isFirst, setIsFirst] = useState(true); 
    const { instance } = useMsal();
    useEffect(()=>{ 
        if(isFirst){ 
            axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_ACCOUNT_TYPE, { 
                "instanceID": CONFIG.PMSInstanceID, 
                "accountSubscriptionID": CONFIG.AccountSubscriptionID, 
                "created_User": CONFIG.LoginID, 
            }, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,"Ocp-Apim-Subscription-Key": CONFIG.API_KEY } }) 
            .then((res) => { 
                setAccountTypeList(res.data.result) 
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            }) 
        }else{ 
            setIsFirst(false) 
        } 
    },[isFirst]) 
    console.log(accountTypeList) 
    return( 
        <> 
            {accountTypeList.map((list,k)=>( 
                <option key={k} value={list.accountTypeID} defaultValue={list.accountTypeID == props.AccountTypeID} selected={props.AccountTypeID == list.accountTypeID}>{list.accountTypeName}</option> 
            ))} 
        </> 
    ) 
}
