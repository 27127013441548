import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, Col, FloatingLabel, Form } from "react-bootstrap";
import MyTasks from './MyTasks';
import MyTeamTasks from './MyTeamTasks';
import OtherTasks from './OtherTasks';
import * as CONFIG from "../../helpers/config";

export default function Index() {
    return (
        <div>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/tasks.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>TASKS</span>
                        </div>
                    </div>

                    <div className='inner-page-content'>
                        <Tabs>
                            <Card className='tabs-wrapper'>
                                <TabList>
                                    <Tab>My Tasks</Tab>
                                    {(localStorage.getItem('LogInUserRoleID') == 3004 || localStorage.getItem('LogInUserRoleID') == 3022) &&
                                        <Tab>My Team Tasks</Tab>
                                    }
                                    {(localStorage.getItem('LogInUserRoleID') == 3001 || localStorage.getItem('LogInUserRoleID') == 3054 || localStorage.getItem('LogInUserRoleID') == 3055) &&
                                        <Tab>Other Tasks</Tab>
                                    }
                                </TabList>
                            </Card>
                            <Card className='mt-1-rem p-1-rem'>
                                <TabPanel>
                                    <MyTasks />
                                </TabPanel>
                                    {(localStorage.getItem('LogInUserRoleID') == 3004 || localStorage.getItem('LogInUserRoleID') == 3022) &&
                                    <TabPanel>
                                        <MyTeamTasks />
                                    </TabPanel>
                                }
                                {(localStorage.getItem('LogInUserRoleID') == 3001 || localStorage.getItem('LogInUserRoleID') == 3054 || localStorage.getItem('LogInUserRoleID') == 3055) &&
                                    <TabPanel>
                                        <OtherTasks />
                                    </TabPanel>
                                }
                            </Card>
                        </Tabs>

                    </div>

                </div>


            </div>
        </div>
    )
}
