import React from 'react'
import styles from "./styles.module.css"
import NumberFormat from 'react-number-format';
function Index(props) {
    const handlePercentageRevenueAmount = () => {
        let difference = 0
        let product = 0
        console.log(props)
        if(props.closedTradeDetails != 0 && props.closedTradeDetails != null){

            if(difference == 0 && props.closedTradeDetails.tradeRevenuePrevious == 0){
                console.log('cannot calc')
                product = 0
            }
            else{
            difference =  parseInt(props.closedTradeDetails.totalTradeRevenue) - parseInt(props.closedTradeDetails.tradeRevenuePrevious) 
            console.log(difference, 'calculated')
            product =  parseInt(difference) / parseInt(props.closedTradeDetails.tradeRevenuePrevious)
            }
        }
        else{
            product = 0
        }
        console.log(difference)
        console.log(product, 'product before percentage')
        console.log(parseFloat(product * 100).toFixed(2))
        product = parseFloat(product * 100).toFixed(2)
        console.log(product)
        return(
          <>
           
            {(parseInt(props.closedTradeDetails.tradeRevenuePrevious) < (parseInt(props.closedTradeDetails.totalTradeRevenue))) == true ||
            (parseInt(props.closedTradeDetails.tradeRevenuePrevious) == (parseInt(props.closedTradeDetails.totalTradeRevenue))) == true ?
             <div className = {` ms-2 d-flex align-items-center ${styles.profitIcon}`}>
              {product}%
              <img src = '/assets/images/supports/dashboard-trends-profit-icon-1.svg' alt = 'icon' className = 'ms-2'/>
             </div>
             :
             <div className = {`  ms-2 d-flex align-items-center ${styles.lossIcon}`}>
                 {product}%
              <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' />
             </div>
          }
            
          </>
        )
        //console.log(division)
      }
  return (
    <>
      <div  className = {` ${styles.container}`}>
        <div className = 'd-flex justify-content-between'>
            <div>
                <p className = {` gil-semibold ${styles.title}`}>
                    total trade
                </p>
                <p className = {` gil-regular ${styles.text}`}>
                    <NumberFormat className='gil-regular' value={props.newTrades != null ? props.newTrades : 0} displayType={'text'} thousandSeparator={true}  />
                </p>
            </div>
            <div>
                <p className = {` gil-semibold ${styles.title}`}>
                    total revenue of trades
                </p>
                <p className = {` gil-regular ${styles.text}`}>
                    <div className = 'd-flex align-items-center'>
                        $<NumberFormat className='gil-regular' value={props.closedTradeDetails.totalTradeRevenue != null ? props.closedTradeDetails.totalTradeRevenue : 0} displayType={'text'} thousandSeparator={true}  />            
                        {handlePercentageRevenueAmount()}
                    </div>
                </p>
            </div>
        </div>
        <div className = 'row'>
            <div className = 'col-md-2 col-sm-12 pt-4'>
                <div className = {` ${styles.greetingCounter}`}>
                    <div className = ''>
                        <div>
                            <img src = '/assets/images/supports/dashboard-trade-overview-icon.svg' alt = 'icon' />
                        </div>
                    </div>
                    <div className = 'ps-3 pe-3 pb-3'>
                        <div className = 'd-flex justify-content-between'>
                        <p className = {`m-0 me-2 ${styles.titleCounter}`}>
                            <NumberFormat className='gil-regular' value={props.shipmentDetails.totalContractStageCount != null ? props.shipmentDetails.totalContractStageCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        <p className = {`mt-3 ${styles.textCounterValue}`}>
                            $<NumberFormat className='gil-semibold' value={props.shipmentDetails.totalContractStageQty != null ? props.shipmentDetails.totalContractStageQty : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        </div>
                        <p className = {` gil-semibold ${styles.textCounter}`}>
                            contract stage
                        </p>
                    </div>
                </div>
            </div>
            <div className = 'col-md-2 col-sm-12 pt-4'>
                <div className = {` ${styles.greetingCounter}`}>
                    <div className = ''>
                        <div>
                            <img src = '/assets/images/supports/dashboard-shipment-preshipment-icon.svg' alt = 'icon' />
                        </div>
                    </div>
                    <div className = 'ps-3 pe-3 pb-3'>
                        <div className = 'd-flex justify-content-between'>
                        <p className = {`m-0 me-2 ${styles.titleCounter}`}>
                            <NumberFormat className='gil-regular' value={props.shipmentDetails.totalPreShipmentCount != null ? props.shipmentDetails.totalPreShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        <p className = {`mt-3 ${styles.textCounterValue}`}>
                            $<NumberFormat className='gil-semibold' value={props.shipmentDetails.totalPreShipmentQty != null ? props.shipmentDetails.totalPreShipmentQty : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        </div>
                        <p className = {` gil-semibold ${styles.textCounter}`}>
                            pre shipment stage
                        </p>
                    </div>
                </div>
            </div> <div className = 'col-md-2 col-sm-12 pt-4'>
                <div className = {` ${styles.greetingCounter}`}>
                    <div className = ''>
                        <div>
                            <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                        </div>
                    </div>
                    <div className = 'ps-3 pe-3 pb-3'>
                        <div className = 'd-flex justify-content-between'>
                        <p className = {`m-0 me-2 ${styles.titleCounter}`}>
                            <NumberFormat className='gil-regular' value={props.shipmentDetails.totalPostVesselFixtureCount != null ? props.shipmentDetails.totalPreShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        <p className = {`mt-3 ${styles.textCounterValue}`}>
                            $<NumberFormat className='gil-semibold' value={props.shipmentDetails.totalPostVesselFixtureQty != null ? props.shipmentDetails.totalPostVesselFixtureQty : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        </div>
                        <p className = {`gil-semibold ${styles.textCounter}`}>
                            post vessel fixture stage
                        </p>
                    </div>
                </div>
            </div> <div className = 'col-md-2 col-sm-12 pt-4'>
                <div className = {` ${styles.greetingCounter}`}>
                    <div className = ''>
                        <div>
                            <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                        </div>
                    </div>
                    <div className = 'ps-3 pe-3 pb-3'>
                        <div className = 'd-flex justify-content-between'>
                        <p className = {`m-0 me-2 ${styles.titleCounter}`}>
                            <NumberFormat className='gil-regular' value={props.shipmentDetails.totalInTrasitCount != null ? props.shipmentDetails.totalInTrasitCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        <p className = {`mt-3 ${styles.textCounterValue}`}>
                            $<NumberFormat className='gil-semibold' value={props.shipmentDetails.totalInTrasitQty != null ? props.shipmentDetails.totalInTrasitQty : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        </div>
                        <p className = {`gil-semibold ${styles.textCounter}`}>
                            in transit stage
                        </p>
                    </div>
                </div>
            </div> <div className = 'col-md-2 col-sm-12 pt-4'>
                <div className = {` ${styles.greetingCounter}`}>
                    <div className = ''>
                        <div>
                            <img src = '/assets/images/supports/dashboard-shipment-postfixture-icon.svg' alt = 'icon' />
                        </div>
                    </div>
                    <div className = 'ps-3 pe-3 pb-3'>
                        <div className = 'd-flex justify-content-between'>
                        <p className = {`m-0 me-2 ${styles.titleCounter}`}>
                            <NumberFormat className='gil-regular' value={props.shipmentDetails.totalPreClosureCount != null ? props.shipmentDetails.totalPreClosureCount : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        <p className = {`mt-3 ${styles.textCounterValue}`}>
                            $<NumberFormat className='gil-semibold' value={props.shipmentDetails.totalPreClosureQty != null ? props.shipmentDetails.totalPreClosureQty : 0} displayType={'text'} thousandSeparator={true}  />
                        </p>
                        </div>
                        <p className = {` gil-semibold  ${styles.textCounter}`}>
                            pre-closure
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Index