import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { Link, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { Form, Card, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "moment"
import axios from 'axios';
import * as CONFIG from "../../helpers/config"
import * as FormFields from "../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

export default function Index(props) {
    console.log(props)
    const { instance } = useMsal();
    const location = useLocation()
    const tradeDataForFreight = props.trade
    const [tradeid, settradeid] = useState(); // replace 1 by tradedata.tradedetails.id
    const [tradedata, settradedata] = useState([]); // replace 1 by tradedata.tradedetails.id
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [incoTermsLabel, setIncotermsLabel] = useState('FOB')
    const [modeOfTransportLabel, setModeOfTransportLabel] = useState('By Sea')
    const [dateRange, setDateRange] = useState([null, null]);
    const [error, seterror] = useState(false)
    const [disable, setDisable] = useState(true)
    const [startDate, endDate] = dateRange;
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        getTradeData()
    }, [])
    const formik = useFormik({
        initialValues:
        {
            tradeEnquiryID: 0,
            customerID: 0,
            supplierID: 0,
            TradeDate: "",
            modeOfTransportID: 1,
            incotermsID: 2,
            shipmentSize: '',
            loadPort: "",
            loadPortCountry: "",
            loadQuantityInspectionAgency: "",
            loadQualityInspectionAgency: "",
            loadPortAgency: "",
            loadPortRate: "",
            fromLaycanPeriod: "",
            toLaycanPeriod: "",
            destinationPort: "",
            destinationPortCountry: "",
            destinationQuantityInspectionAgency: "",
            destinationQualityInspectionAgency: "",
            destinationPortAgency: "",
            destinationPortRate: "",
            etaLoadPort: "",
            ETADischargePort: "",
            DespatchDemurrage: "",
            OceanFreight: "",
            Demurrage: "",
            Dispatch: "",
            Remarks: "",
        },
        validate: values => {
            let errors = {};
            setDisable(false)
            if (!values.OceanFreight) {
                errors.data = "Please enter a value!"
            }
            if (!values.Dispatch) {
                errors.data = "Please enter a value!"
            }
            if (!values.Demurrage) {
                errors.data = "Please enter a value!"
            }
            if (!errors) {
                seterror(false)
            }
            return errors;
        }
    })
    const getTradeData = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeDataForFreight.tradeID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                settradedata(res.data.result[0]);
                formik.values.tradeEnquiryID = res.data.result[0].tradedetails.tradeEnquiry.tradeEnquiryID
                formik.values.customerID = res.data.result[0].tradedetails.tradeEnquiry.customerID
                formik.values.supplierID = res.data.result[0].tradedetails.supplier.supplierID
                formik.values.TradeDate = ""
                formik.values.tradeID = res.data.result[0].tradedetails.tradeID
                formik.values.modeOfTransportID = res.data.result[0].tradedetails.modeOfTransportID
                formik.values.incotermsID = res.data.result[0].tradedetails.incotermsID
                formik.values.shipmentSize = res.data.result[0].tradedetails.shipmentSize
                formik.values.loadPort = res.data.result[0].tradedetails.loadPort
                formik.values.loadPortCountry = res.data.result[0].tradedetails.loadPortCountry
                formik.values.loadQuantityInspectionAgency = res.data.result[0].tradedetails.loadQuantityInspectionAgency
                formik.values.loadQualityInspectionAgency = res.data.result[0].tradedetails.loadQualityInspectionAgency
                formik.values.loadPortAgency = res.data.result[0].tradedetails.loadPortAgency
                formik.values.loadPortRate = res.data.result[0].tradedetails.loadPortRate
                formik.values.fromLaycanPeriod = Moment(res.data.result[0].tradedetails.fromLaycanPeriod).format('DD/MM/YYYY')
                formik.values.toLaycanPeriod = Moment(res.data.result[0].tradedetails.toLaycanPeriod).format('DD/MM/YYYY')
                formik.values.destinationPort = res.data.result[0].tradedetails.destinationPort
                formik.values.destinationPortCountry = res.data.result[0].tradedetails.destinationPortCountry
                formik.values.destinationQuantityInspectionAgency = res.data.result[0].tradedetails.destinationQuantityInspectionAgency
                formik.values.destinationQualityInspectionAgency = res.data.result[0].tradedetails.destinationQualityInspectionAgency
                formik.values.destinationPortAgency = res.data.result[0].tradedetails.destinationPortAgency
                formik.values.destinationPortRate = res.data.result[0].tradedetails.destinationPortRate
                formik.values.etaLoadPort = res.data.result[0].tradedetails.etaLoadPort
                formik.values.ETADischargePort = res.data.result[0].tradedetails.ETADischargePort
                formik.values.DespatchDemurrage = res.data.result[0].tradedetails.DespatchDemurrage
                formik.values.tradeNo = res.data.result[0].tradedetails.tradeNo
                formik.values.OceanFreight = res.data.result[0].tradedetails.oceanFreight
                formik.values.Remarks = res.data.result[0].tradedetails.remarks
                formik.values.Demurrage = res.data.result[0].tradedetails.demurrage
                formik.values.Dispatch = res.data.result[0].tradedetails.dispatch
                formik.values.laycanDate = formik.values.fromLaycanPeriod + '-' + formik.values.toLaycanPeriod
                setIsDataAvailable(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    useEffect(() => {
        console.log(tradedata)
    }, [tradedata])


    const updatefreightcharge = () => {
        if (Object.keys(formik.errors).length == 0) {
            console.log(formik.values)
            if (tradedata.costsheetstatus != 0) {
                let history_json = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "id": formik.values.tradeID
                }
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.TRADE_HISTORY, history_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then(res => {
                        const json_list = {
                            "instanceID": CONFIG.PMSInstanceID,
                            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                            "created_User": CONFIG.LoginID,
                            "tradeid": parseInt(tradeDataForFreight.tradeID), // tradeid,
                            "oceanFreight": parseFloat(formik.values.OceanFreight),
                            "dispatch": parseFloat(formik.values.Dispatch),
                            "demurrage": parseFloat(formik.values.Demurrage),
                            "remarks": formik.values.Remarks,
                        }
                        console.log(json_list)
                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_FREIGHTCHARGE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                            .then((res) => {
                                console.log(res.data.result, "Trade data")
                                if (res.data.responseCode == '304000') {
                                    MySwal.fire(
                                        {
                                            icon: 'success',
                                            title: 'Freight Charges Updated Successfully!',
                                            html: '<p>Trade Number: ' + formik.values.tradeNo + '</p>' + '<br />',
                                            type: 'success',
                                            confirmButtonText: 'Back to Trade Transactions',
                                            confirmButtonClass: "btn btn-primary",

                                        }).then(() => {
                                            window.location.href = "/trade-transactions"

                                        });
                                    props.refreshList()
                                }
                            }).catch((error) => {
                                if (error.response.status == 401) {
                                    localStorage.clear();
                                    instance.logoutRedirect({
                                        postLogoutRedirectUri: "/",
                                    });
                                }
                            })
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }
            else {
                const json_list = {
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID,
                    "tradeid": parseInt(formik.values.tradeID), // tradeid,
                    "oceanFreight": parseFloat(formik.values.OceanFreight),
                    "dispatch": parseFloat(formik.values.Dispatch),
                    "demurrage": parseFloat(formik.values.Demurrage),
                    "remarks": formik.values.Remarks,
                }
                console.log(json_list)
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_FREIGHTCHARGE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                    .then((res) => {
                        console.log(res.data.result, "Trade data")
                        props.refreshList()
                        if (res.data.responseCode == '304000') {
                            MySwal.fire(
                                {
                                    icon: 'success',
                                    title: 'Freight Charges Updated Successfully!',
                                    html: '<p>Trade Number: ' + formik.values.tradeNo + '</p>' + '<br />',
                                    type: 'success',
                                    confirmButtonText: 'Back to Trade Transactions',
                                    confirmButtonClass: "btn btn-primary",

                                }).then(() => {
                                    window.location.href = "/trade-transactions"
                                });
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            localStorage.clear();
                            instance.logoutRedirect({
                                postLogoutRedirectUri: "/",
                            });
                        }
                    })
            }
        }
        else {
            console.log('inside error')
            seterror(!error)
        }
        // props.refreshList()
    }

    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex width-100'>
                <div className='width-100'>
                    <div className='inner-page-content' style={{ opacity: props.locked ? '0.1' : '' }}>
                        <div className='br-8 py-3'>
                            <div className='card card-body p-4 br-8 mb-4 d-none d-md-block d-lg-block'>
                                <table className='w-100'>
                                    <tr style={{ borderBottom: 'none' }}>
                                        <td >
                                            <div className='pt-2'>
                                                <h4 className='tradeTrsactionsHeading'>{props.trade.tradeNo}</h4>
                                                <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'>{Moment(props.trade.created_date).format('DD/MM/yyyy')}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='pt-2'>
                                                <h4 className='tradeTrsactionsHeading'>{props.trade.companyname}<span className='mx-1 dotSep'>&#8226;</span>{props.trade.divisionname}</h4>
                                            </div>
                                        </td>

                                        <td>
                                            <div className='d-flex flex-column pt-2'>
                                                {props.trade.compcustdetails.map((pt, k) => {
                                                    return (
                                                        <>
                                                            <div className='pb-2'>
                                                                <div className='align-items-center '>
                                                                    <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                                    <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                                </div>
                                                                <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                                </h4></div>
                                                            </div>
                                                        </>
                                                    )
                                                })}

                                                <div className='py-2'>
                                                    <div className='d-flex align-items-center '>
                                                        <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.trade.supplierName}</h5>
                                                        <h4 className='tradeTrsactionsid statBg'>{props.trade.supplierCode}</h4>
                                                    </div>
                                                    <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                        {props.trade.supplierpaymentmethods.map((pt, k) => {
                                                            return (
                                                                <>
                                                                    {pt} <span className='mx-1 dotSep'>&#8226;</span>
                                                                </>
                                                            )
                                                        })}
                                                    </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className=' pt-2'>
                                                {props.trade.tradeProductDetails.map((list, k) => {
                                                    return (
                                                        <>
                                                            <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                                                {" "}
                                                                {list.productName}
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                                                    {list.quantity}&nbsp;{" "}
                                                                    {list.productUOMName} <span className='mse-3 dotSep'>&#8226;</span>
                                                                    {props.trade.contracttype} <span className='mse-3 dotSep'>&#8226;</span>
                                                                    {props.trade.incotermsName} <span className='mse-3 dotSep'>&#8226;</span>
                                                                    {props.trade.modeOfTransportName}
                                                                </div>

                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            <div className='d-flex py-2'>
                                                <div>
                                                    <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                        Load Port
                                                    </div>
                                                    <div className='tradeTrsactionsHeading '>
                                                        {props.trade.loadPort}, {props.trade.lordPortCountry}
                                                    </div>
                                                </div>
                                                <div className='ml-20'>
                                                    <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                        Destination Port
                                                    </div>
                                                    <div className='tradeTrsactionsHeading'>
                                                        {props.trade.loadPort}, {props.trade.lordPortCountry}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className='card' style={{ height: '100%' }}>
                                <div className='card-body p-4 br-8 mb-4' >

                                    <div className="d-flex">
                                        <p className='form-title font-16 font-weight-500 color-4F5466'>Freight and Other Charges</p>
                                    </div>

                                    <Form>
                                        <Row>
                                            <FormFields.InputField
                                                md={3}
                                                name='OceanFreight'
                                                label='Ocean Freight*'
                                                classname='pt-1-rem'
                                                type='number'
                                                val={formik.values.OceanFreight}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='Dispatch'
                                                label='Despatch'
                                                classname='pt-1-rem'
                                                type='number'
                                                val={formik.values.Dispatch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='Demurrage'
                                                label='Demurrage'
                                                classname='pt-1-rem'
                                                type='number'
                                                val={formik.values.Demurrage}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </Row>

                                        <Row>
                                            <FormFields.InputField
                                                md={12}
                                                name='Remarks'
                                                label='Remarks'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.Remarks}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </Row>
                                        <Row>
                                            {error &&
                                                <span className='input_error'>Required fields cannot be empty!</span>
                                            }
                                        </Row>

                                    </Form>

                                </div>
                                <div className='trade_process_card_footer'>
                                    <Card.Footer className='form-card-footer right-auto left-auto d-flex fixed-bottom'>
                                        <span className='pl-10'><BlueButton value='Submit' clickEvent={updatefreightcharge} disabled={disable} /></span>
                                    </Card.Footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
