import React,{useState} from 'react'
import FormComponent from './components/FormComponent'
import {Row, Col, Form } from "react-bootstrap";
import { SelectExistingButton,BlueBorderButton } from '../../../../../components/buttoncomponents'
import ModalForExistingCustomer from '../components/ModalForExistingCustomer';

function CustomerDetails(props) {
  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    console.log('clicked')
    setShowModal(true)
    props.setShowModalForModal(showModal)
  }
  return (
    <div>
       <Row>
       <div className = 'd-flex justify-content-between align-items-center'>
            <div className='font-16 font-weight-600 color-707895'>
                Customer Details
            </div>
            <div className = ''>
                <BlueBorderButton value = {props.isCustomer == false ? "CHANGE CUSTOMER" : "SELECT CUSTOMER"} class='font-12 textPrimary font-weight-500' clickEvent={openModal}/>
            </div>
        </div>
        <FormComponent 
          selectedEnquiry = {props.selectedEnquiry} 
          handleDocumentAttached = {props.handleDocumentAttached} 
          customerDetails={props.customerDetails} 
          isdataavailable={props.isdataavailable}
          isEdit={props.isEdit}
          documentDetails = {props.documentDetails}
        />
        </Row>
    </div>
  )
}

export default CustomerDetails