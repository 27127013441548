import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import * as CONFIG from '../../../helpers/config'
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import { useMsal } from "@azure/msal-react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ShipmentETA(props) {
    const MySwal = withReactContent(Swal)
    const { instance } = useMsal();
    const [corDocumentName, setCorDocumentName] = useState('')
    const [quantityInspectionAgencyDocumentName, setQuantityInspectionAgencyDocumentName] = useState('')
    const [qualityInspectionAgencyDocumentName, setQualityInspectionAgencyDocumentName] = useState('')
    const [customerData, setCustomerData] = useState([])
    const [sailDate, setSailDate] = useState(false)
    const [customerETA, setCustomerETA] = useState(false)
    const [error, setError] = useState(false)
    const location = useLocation()

    let navigate = useNavigate();
    const tradeData = props.tradeData
    const tab = props.tab
    console.log(tradeData)

    useEffect(() => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerData(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData.tradeID,
            sailDate: tradeData.tradedetails.sailDate != null ? Moment(tradeData.tradedetails.sailDate) : "",
            customerETA: tradeData.tradedetails.customerETA != null ? Moment(tradeData.tradedetails.customerETA) : "",
        },
    })


    const handleSailDate = (date) => {
        console.log('click')
        setSailDate(date)
        formik.values.sailDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }

    const handleCustomerETA = (date) => {
        console.log('click')
        setCustomerETA(date)
        formik.values.customerETA = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }

    const handleSaveEvent = () => {
        const json_list = {
            "tradeID": formik.values.tradeID,
            "sailDate": formik.values.sailDate,
            "customerETA": formik.values.customerETA,
            "modified_User": CONFIG.LoginID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        console.log(json_list)
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_SHIPMENT_ETA, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                MySwal.fire(
                    {
                        icon: 'success',
                        title: 'Shipment ETA updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Okay',
                    }
                ).then(() => {

                });
                props.refreshList()
                back()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='width-100'>
                <div className='d-flex mt-4' style={{ paddingLeft: '32px', }}>
                    {/* <div className = 'd-flex justify-content-center align-items-center '>
                            <ButtonComponent.BackButton clickEvent={back} />
                        </div> */}
                </div>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Shipment ETA</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        <Card className='bg-white'>
                            <Card.Body>
                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                    Shipment Details
                                </div>
                                <div className="m-3 p-4 grey-color">
                                    {tradeData != null ?
                                        <>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                {customerData.map((data, k) => {
                                                    return (
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                            <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Load Port</div>
                                                    <div className='font-weight-600 color-243448'>{tradeData.tradedetails.loadPort}</div>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Destination Port</div>
                                                    <div className='font-weight-600 color-243448'>{tradeData.tradedetails.destinationPort}</div>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Mode of Transport</div>
                                                    <div className='font-weight-600 color-243448'>By Sea</div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                        :
                                        ""
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='bg-white my-3'>

                            <CardBody>
                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                    Shipment Dates
                                </div>
                                <Form>
                                    <Row>
                                        <>
                                            <FormFields.DatePick
                                                md={3}
                                                label='Sail Date'
                                                name='sailDate'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                placeHolderText='asas'

                                                handleCompletionDate={handleSailDate}
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.sailDate}
                                            />
                                            <FormFields.DatePick
                                                md={3}
                                                label='Customer ETA'
                                                name='customerETA'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleCustomerETA}
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.customerETA}
                                            />
                                        </>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>

                                <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShipmentETA