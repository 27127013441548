import { Formik, useFormik } from 'formik';
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import * as CONFIG from '../../../../../../helpers/config'
import * as FormFields from "../../../../../../components/formcomponents";
import {Row, Col} from "react-bootstrap";
import Moment from "moment"
import Supply_plan_list from './supply_plan_list';
import Supply_plan_form from './supply_plan_form';
import { useMsal } from "@azure/msal-react";

export default function Lisdivata(props) {
    console.log(props)
    const { instance } = useMsal();
    const [isExpand, setIsExpand] = useState(false);
    const [isSupplyAvailable, setIsSupply] = useState(false);
    const [supplylist, setsupply] = useState([]) 
    const [key, setKey] = useState(1)
    const [isEdit, setIsEdit] = useState(false)
    const [selected, setselected] = useState([])
    const [isFirst, setIsFirst] = useState(true)
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [serialNumber, setSerialNumber] = useState(1)
    const [monthList, setMonthList] = useState([])
    const [yearList, setYearList] = useState([])
    const [supply_nno, setsupplyno] = useState(0)
    const [data, setData] = useState()

    useEffect(()=>{

        let total = 0
        let supply_no = ''
        supplylist.map((list)=>{
            total = total+list.quantity
        })
        setTotalQuantity(total)
        if(supplylist.length == supply_nno && supply_nno!=0){
            saveToList()
        }
        if(props.isEdit == true){
            console.log(props)
            console.log(props.values.productGroupID)
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
               
            }
            let productGroup = ''
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GROUP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    console.log( props.values.productGroupID)
                    let cookie_data =  res.data.result.filter(x=> x.productgroup.productGroupID == props.values.productGroupID)
                    console.log(cookie_data)
                    setData(cookie_data[0])
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
    },[supplylist])

    useEffect(()=>{
        if(props.values.noOfSupply != null){
            setIsSupply(true) 
        }
    },[])

    useEffect(()=>{
        if(props.isEdit == true){
            setsupply(props.values.tradeEnquiryProductSupplyplan)
           
            setIsSupply(true)
        }
    },[])
    const toggleExpand = () =>{
        setIsExpand(!isExpand)
    }
    const handleSerialNumber = (data) => {
        console.log('called', data)
        setSerialNumber(data)
    }
    const updateSupply = (data)=>{
        console.log(data)
        for (let i = 0; i <= data.noOfSupply; i++) {
            console.log(i)
        }
        let list = [...supplylist]

        supplylist.map((li,k)=>{
            if(li.key == data.key){
                list[k] = data
            }
        })
        setsupply(list)
       
        setIsEdit(false)
        saveToList(list)
        toggleSupplyList()
    }
    
    const saveSupply = (data)=>{
        console.log(data)
        setsupplyno(data.noOfSupply)
        let list = [...supplylist]
        let k = key
        data.key = k
        console.log(data)
        var currentDate = Moment('2015-10-30').format("YYYY-MM-DD");
        var futureMonth = Moment(currentDate).add(1, 'M').format('MM');
        var getFutureMonth = Moment().months(futureMonth)
        var futureMonthEnd = Moment(futureMonth).endOf('months').format();
        console.log(currentDate, futureMonth, futureMonthEnd, getFutureMonth)
        console.log(list)
        for (let i = 1; i <= data.noOfSupply; i++) {
            console.log(i)
            //list.push(data);
            // console.log(i)
            // //list[i] = data
            // // data[i].supplySerialNumber = i
            // console.log(list[i])
            // console.log(data);
            // // list = [...list, data]
            // console.log(list);
            // setsupply((supplylist) => [...supplylist, data])

        };
        let x = 1
        let moList = [...monthList]
        let yeList = [...yearList]
        for (let i = 1;i <= data.noOfSupply; i++) {
      
            if (data.supplyTermsID == 1 || data.supplyTermsID == 5){
                if(i == 1){
                    var nList = {
                        month : parseInt(data.months),
                        year : parseInt(data.years),
                        supplyNo : i
                    }
                    nList = {...nList,...data}
                    moList.push(data.monthNames)
                    yeList.push(data.years)
                    list.push(nList)
                }
                else{
                    var date = data.years + '-' + data.months + '-' +'20' 
                    var months = Moment(date).month(data.monthNames).format("YYYY-MM-DD");
                    console.log(months)
                    var futureMonth = Moment(months).add(x, 'M').format('MMMM');
                    var futureYear = Moment(date).add(x, 'M').format('YYYY');
                    //var futureMonthEnd = Moment(futureMonth).endOf('months').format();
                    // console.log(futureMonth, futureYear)
                    // data.monthNames = futureMonth
                    // data.years = futureYear
                    // console.log(data)
                    // console.log(list[i])
                    console.log(Moment().month(futureMonth).format("M"))
                    var nList = {
                        month : parseInt(Moment().month(futureMonth).format("M")),
                        year : parseInt(Moment(futureYear).format("YYYY")),
                        supplyNo: i,
                    }
                    nList = {...nList,...data}
                    moList.push(futureMonth)
                    yeList.push(futureYear)
                    list.push(nList)
                    x++
                    console.log(list)
                }
            }
           else if (data.supplyTermsID == 2){
                if(i == 1){
                    var nList = {
                        month : parseInt(data.months),
                        year : parseInt(data.years),
                        supplyNo : i
                    }
                    nList = {...nList,...data}
                    moList.push(data.monthNames)
                    yeList.push(data.years)
                    list.push(nList)
                }
                else{
                    var date = data.years + '-' + data.months + '-' +'20' 
                    var months = Moment(date).month(data.monthNames).format("YYYY-MM-DD");
                    console.log(months)
                    var futureMonth = Moment(months).add(x*3, 'M').format('MMMM');
                    var futureYear = Moment(date).add(x*3, 'M').format('YYYY');
                    //var futureMonthEnd = Moment(futureMonth).endOf('months').format();
                    // console.log(futureMonth, futureYear)
                    // data.monthNames = futureMonth
                    // data.years = futureYear
                    // console.log(data)
                    // console.log(list[i])
                    console.log(Moment().month(futureMonth).format("M"))
                    var nList = {
                        month : parseInt(Moment().month(futureMonth).format("M")),
                        year : parseInt(Moment(futureYear).format("YYYY")),
                        supplyNo: i,
                    }
                    nList = {...nList,...data}
                    moList.push(futureMonth)
                    yeList.push(futureYear)
                    list.push(nList)
                    x++
                    console.log(list)
                }
            }
            if (data.supplyTermsID == 3){
                if(i == 1){
                    var nList = {
                        month : parseInt(data.months),
                        year : parseInt(data.years),
                        supplyNo : i
                    }
                    nList = {...nList,...data}
                    moList.push(data.monthNames)
                    yeList.push(data.years)
                    list.push(nList)
                }
                else{
                    var date = data.years + '-' + data.months + '-' +'20' 
                    var months = Moment(date).month(data.monthNames).format("YYYY-MM-DD");
                    console.log(months)
                    var futureMonth = Moment(months).add(x*6, 'M').format('MMMM');
                    var futureYear = Moment(date).add(x*6, 'M').format('YYYY');
                    //var futureMonthEnd = Moment(futureMonth).endOf('months').format();
                    // console.log(futureMonth, futureYear)
                    // data.monthNames = futureMonth
                    // data.years = futureYear
                    // console.log(data)
                    // console.log(list[i])
                    console.log(Moment().month(futureMonth).format("M"))
                    var nList = {
                        month : parseInt(Moment().month(futureMonth).format("M")),
                        year : parseInt(Moment(futureYear).format("YYYY")),
                        supplyNo: i,
                    }
                    nList = {...nList,...data}
                    moList.push(futureMonth)
                    yeList.push(futureYear)
                    list.push(nList)
                    x++
                    console.log(list)
                }
            }
            if (data.supplyTermsID == 4){
                if(i == 1){
                    var nList = {
                        month : parseInt(data.months),
                        year : parseInt(data.years),
                        supplyNo : i
                    }
                    nList = {...nList,...data}
                    moList.push(data.monthNames)
                    yeList.push(data.years)
                    list.push(nList)
                }
                else{
                    var date = data.years + '-' + data.months + '-' +'20' 
                    var months = Moment(date).month(data.monthNames).format("YYYY-MM-DD");
                    console.log(months)
                    var futureMonth = Moment(months).add(x*12, 'M').format('MMMM');
                    var futureYear = Moment(date).add(x*12, 'M').format('YYYY');
                    //var futureMonthEnd = Moment(futureMonth).endOf('months').format();
                    // console.log(futureMonth, futureYear)
                    // data.monthNames = futureMonth
                    // data.years = futureYear
                    // console.log(data)
                    // console.log(list[i])
                    console.log(Moment().month(futureMonth).format("M"))
                    var nList = {
                        month : parseInt(Moment().month(futureMonth).format("M")),
                        year : parseInt(Moment(futureYear).format("YYYY")),
                        supplyNo: i,
                    }
                    nList = {...nList,...data}
                    moList.push(futureMonth)
                    yeList.push(futureYear)
                    list.push(nList)
                    x++
                    console.log(list)
                }
            }
        };
        setYearList(yeList)
        setMonthList(moList)
        console.log(list)
        console.log(supplylist)
        setsupply(list)
        k++
        setKey(k)
        toggleSupplyList()
        props.handleIsFirst()
    }
    const toggleSupplyList =()=>{
        setIsSupply(!isSupplyAvailable)
    }

    const editList = (e)=>{
        const key = parseInt(e.target.id)
        let list = supplylist.filter(x=> x.key == key)
        setselected(list[0])
        setIsEdit(true)
        toggleSupplyList()
    }


    const saveToList = (data)=>{
        let list = props.values
        console.log(supplylist)
        list["tradeEnquiryProductSupplyplan"] = supplylist
        props.saveToList(list)
    }

    useEffect(()=>{
        if(isSupplyAvailable == true){
            props.handleDisable()
        }
    },[isSupplyAvailable])
    return (
        <>
         <div className='customer_supplier_row font-weight-600 font-13 color-707895 d-flex justify-content-between align-items-center border-bottom-1 px-4'>
            <Col md={2}>{props.isEdit == true && data != null ? data.productgroup.productGroupName : props.values.productGroupValue}</Col>
            <Col md={2}>{props.values.productCode}</Col>
            <Col md={2}>{props.values.productName}</Col>
            <Col md={1}>{props.values.productUOMValue == null ? 'Mt' : props.values.productUOMValue }</Col>
            <Col md={2}>{props.isEdit == true ? props.values.tradeEnquiryProductSupplyplan.length : props.values.noOfSupply == null ? 0 : props.values.noOfSupply}</Col>
            <Col md={1}>{props.isEdit == true ? props.contractterms.contractTypeID == 2 ? 'Single Trade' : props.diff : props.values.supplyTermsName == null ? 0 : props.values.supplyTermsName}</Col>
            <Col md={2}>
                <div className = 'd-flex justify-content-around align-items-center'>
                    <p className = 'font-weight-400 m-0'>Click & add supply plan(s)</p>
                    <button className='no-border br-10' onClick={toggleExpand}>
                        {!isExpand?
                        <img src='assets/images/supports/supplyplan_button_down.svg'/>
                        :
                        <img src='assets/images/supports/supplyplan_button_up.svg'/>
                        }
                    </button>
                </div>
            </Col>
        </div>
        {
            isExpand &&

            <div className=' p-0_5-rem width-100l'>
                

                {isSupplyAvailable ?
                    <Supply_plan_list supplylist={props.values.tradeEnquiryProductSupplyplan} edit={props.isEdit} yearList={yearList} monthList={monthList} handleSerialNumber = {handleSerialNumber} serialNumber = {serialNumber} keyForData = {props.values.key} values={supplylist} opensupply={toggleSupplyList} editList={editList} totalQuantity={totalQuantity}/>
                    :
                    
                    <Supply_plan_form contractterms = {props.contractterms} handleDisableEnd = {props.handleDisableEnd} handleDisable = {props.handleDisable} keyForData = {props.values.key} data = {props.values.productID} handleUpdateProductDetails={props.handleUpdateProductDetails} saveSupply={saveSupply} updateSupply={updateSupply} isEdit={isEdit} selected={selected}/>
                }
            </div>
        }
        </>
    )
}
