import React, {useState, useEffect} from 'react'
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { useFormik } from 'formik'
//import * as CONFIG from "../../helpers/config"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents';
function UserDivisionSelectionModal(props) {
    const [disabled, setDisabled] = useState(true)
    console.log(props)

    const [modal, setModal] = useState(true);
    const toggle = () => {
        // props.closeEdit()
        setModal(!modal)
        props.close();
    }
    const formik = useFormik({
        initialValues:
        {
           divisionID: '',
           companyID: '',
           divisionName: '',
        },
        validate:values=>{
            let errors={}
            if(!values.loadPort){
                errors.loadPort = "Please enter a load port"
            }
   
            return errors;
        }
    })
    const handleCheck = (e, data) => {
        console.log(data)
        formik.values.divisionID = data.divisionID
        formik.values.companyID = data.companyID
        formik.values.divisionName = data.divisionName
        setDisabled(false)
    }
    const handleSaveEvent = () => {
        props.handleSaveDivision(formik.values)
        console.log(formik.values)
        toggle()
    }
    const handleDefaultChecker = (e, data) => {
       
    }
  return (
    <Modal isOpen={modal} toggle={toggle} className = 'payment-method-modal'>
    <ModalHeader toggle={toggle} className='p-40'>
        <div className = 'd-flex justify-content-between '>
            <div className='font-16 font-weight-600 color-707895'>
                SELECT A DIVISION
            </div>
        </div>
    </ModalHeader>
    <ModalBody  className = 'service-request-modal-wrapper p-40'>
        <>
        <div className =''>
        <>
      
        {props.userDivision != null && props.userDivision.length > 0 ? 
        <div style={{overflowY:'scroll', maxHeight: '400px'}}>
                {props.userDivision.map((list,k) => (
                    <div className ='bg-light my-2 p-4 align-items-center br-8'>
                    <div>
                        <Form.Check
                            className = ''
                            type='radio'
                            defaultChecked={handleDefaultChecker(list)}
                            name={list.companyID}
                            label={list.divisionName}
                            id = {list.divisionID}
                            onChange={e => handleCheck(e, list)}
                            value={list.divisionID}
                        />
                    </div> 
            </div>
                ))}
            </div>
            :
            ''
            }
        </>
        </div>
        </>
    </ModalBody>
        <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
  
            <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
            <span className='pl-10'><BlueButton value='Save' disabled = {disabled}  clickEvent={handleSaveEvent} /></span>
        </ModalFooter>
    </Modal>
  )
}

export default UserDivisionSelectionModal