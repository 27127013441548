import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './CashFlows.module.css'
import { Row, Col, Form, FloatingLabel} from "react-bootstrap";
import { AgChartsReact } from "ag-charts-react";
import * as agCharts from 'ag-charts-community';
import * as CONFIG from "../../../../../helpers/config"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function Index(props) {
  const [terms, setTerms] = useState(3)
  const [key, setKey] = useState([])
  const [labelSales, setLabelSales] = useState([])
  const [labelPurchase, setLabelPurchase] = useState([])
  const options = {
    responsive: true,
    plugins: {

    },
  };
  const handleDateTerm = (e) => {
    console.log(e.target.value)
    if(e.target.value == 1){
      setTerms(1)
      // formik.values.fromDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
    }
    else if(e.target.value == 2){
      setTerms(2)
      // formik.values.fromDate = Moment().format("YYYY-MM-DDTHH:mm:ss")
    }
    else if(e.target.value == 3){
      setTerms(3)
    }
  }

  useEffect(()=>{
    let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "termsID": terms,
        "dashboardTypeID": 4,
        "userID" :parseInt(CONFIG.LoginID),
        "companyID" : parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_CASH_FLOW, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        let keyList = []
        let labelSales = []
        let labelPurchase = []
        res.data.result.map((values, k)=>(
          keyList.push(values.key),
          labelSales.push(values.salesValue),
          labelPurchase.push(values.purchaseValue)
        ))
        setKey(keyList)
        setLabelSales(labelSales)
        setLabelPurchase(labelPurchase)
        //setShipmentDetails(res.data.result)
        //setNotifications(res.data.result)
    })
  },[terms])

  const gradientOne = () => {
    var ctx = document.getElementById('canvas').getContext("2d")
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, 'rgba(176, 159, 255, 0.4)')
    gradient.addColorStop(1, 'rgba(141, 121, 246, 0.4)')
    return(gradient)
  }

  const gradientTwo = () => {
    var ctx = document.getElementById('canvas').getContext("2d")
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, 'rgba(176, 159, 255, 1)')
    gradient.addColorStop(1, 'rgba(141, 121, 246, 1)')
    return (gradient)
  }
  const labels = terms == 2 ? ['Q1', 'Q2', 'Q3', 'Q4'] : key
  const data  = {
    labels,
     datasets: [
    {
      borderRadius: 4,
      label: 'Sales',
      data: labelSales,
      backgroundColor: gradientOne,
    },
    {
      borderRadius: 4,
      fillOpacity: 3.4,
      label: 'Purchases',
      data: labelPurchase,
      backgroundColor: gradientTwo,
    },
  ],
  };
   

    return (
        <>
        <div className = {` ${styles.cashFlowContainer}`}>
                <div className = 'container p-0 m-0'>
                  <div className = 'd-flex justify-content-end'>
                    <div className = 'col-md-2 col-sm-2 custom-form-select' >
                    <Form.Select 
                      placeholder='Intervals' 
                      name= 'division'
                      onChange={handleDateTerm} 
                      onBlur={handleDateTerm}
                      className = {` ${styles.intervals}`}
                      style={{borderRadius:'8px', border:'none', }}
                    >
                      <option value={1}>Monthly</option>
                      <option value={2}>Quarterly</option>
                      <option value={3} selected={true}>Yearly</option>
                    </Form.Select>
                    </div>
                  </div>
                </div>
            <div className = 'mt-2'>
              <Bar options={options} data={data} />
            </div>
        </div>
        </>
    )
}

export default Index