import React from 'react'
import { Dropdown, Menu, Space } from 'antd';
import { Link    } from "react-router-dom";

export default function Productlist(props) {
    console.log(props)
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <>
                    {props.data.status!=0 &&
                        <span className='font-weight-400 font-14 pl-10 mb-1-rem width-100l' id={props.data.productID} onClick={props.view}>View</span>
                    }
                    </>
                ),
            },
            {
              key: '2',
              label: (
                    <>
                    {props.data.status != 0 && 
                        <>{localStorage.getItem('Edit_Product') == 3 && <span className='font-weight-400 font-14 pl-10 mb-1-rem width-100l' id={props.data.productID} onClick={props.edit}>Edit</span>}</>
                    }
                    </>
              ),
            },
            {
              key: '3',
              label: (
                  <>{localStorage.getItem('Enable_Disable_Product') == 3 && <span className='font-weight-400 font-14 pl-10 mb-1-rem width-100l' id={props.data.productID} onClick={props.disable}>{props.data.status == 0 ?'Enable Product':'Disable Product'}</span>}</>
              ),
            }
          ]}
        />
      );
    return (
        <tr id={props.data.productID} className='product_list_row  align-items-center border-bottom-1' style={{color: props.data.status == 0? '#A7B3C3':'#000'}}>
            <td className = 'align-items-center d-flex py-4 px-2'>
                <center className={props.data.status == 0 ? 'disabled_badge' : 'status_badge'}>{props.data.productCode}</center>
                {props.data.status == 0 ? <img className='ms-2 badgesize' src = 'assets/images/supports/disablePill.svg' /> : ''}
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusname '> {props.data.productName}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusweight '> {props.data.categoryName}</div> 
            </td>
            <td>
                <div className = 'align-items-center d-flex py-4 w-50 cusaddress '>{props.data.description}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusname '> {props.data.divisionName}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusname '> {props.data.companyName}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusname'> {props.data.uomName}</div> 
                
            </td>
            <td>
                <Dropdown overlay={menu}>
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                            <img src = '/assets/images/Dot_icon.svg' />
                    </Space>
                    </a>
                </Dropdown>
            </td>
        </tr>
    )
}
