import React, {useState, useEffect} from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import * as FormFields from "../../../../components/formcomponents"
import * as ButtonComponent from "../../../../components/buttoncomponents"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import ParentCompanyInformation from './ParentCompanyInformation';
import ParentCompanyAddress from './ParentCompanyAddress';



function ParentCompany(props) {
    useEffect(()=>{
        if(props.isBackClicked == true && props.dataForEdit != false){
            props.dataForEdit.supplierdata.parentCompanyName = props.supplierdata.parentCompanyName
            props.dataForEdit.supplierdata.parentCompanyRegNo = props.supplierdata.parentCompanyRegNo
            props.dataForEdit.supplierdata.parentCompanyIncorporationDate = props.supplierdata.parentCompanyIncorporationDate
            props.dataForEdit.supplierdata.parentCompanyCountryID = props.supplierdata.parentCompanyCountryID
        }
    }, [])
    console.log(props)
    const [parentCompanyData, setParentCompanyData] = useState([])
    const [parentCompanyAddress, setParentCompanyAddress] = useState([])
    useEffect(()=>{
        if(props.isContactSet){
            console.log('next button')
            console.log(props)
            setParentCompanyData(props.supplierdata)
        }
    },[])
    const moveToNext = () => {
        props.onNext(parentCompanyData, 4)
        console.log(parentCompanyData)
    }
    const saveCompanyInformaton = (data)=>{
        setParentCompanyData(data)
    }
    const saveParentCompanyAddress = (data)=>{
        console.log(data)
    }
    
    const backClickEvent = () => {
        props.backClickEvent(2)
    }
  return (
    <div>
            <Card className='bg-white br-8'>
                <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='p-4'>
                        <ParentCompanyInformation saveCompanyInformaton = {saveCompanyInformaton} dataForEdit = {props.dataForEdit} isEdit = {props.isEdit} registeredCompanyAddress = {props.registeredCompanyAddress} isBackClicked = {props.isBackClicked} supplierdata = {props.supplierdata}/>
                    </Card.Body>
                    <Card.Body className='form-card-body p-4'>
                        <ParentCompanyAddress  saveParentCompanyAddress = {saveParentCompanyAddress} registeredCompanyData = {props.registeredCompanyAddress} />
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer p-4 d-flex'>
                    <span><BlueBorderButton value='Back' clickEvent = {backClickEvent}/></span>
                    <span className='pl-10'><BlueButton value='Next' clickEvent={moveToNext}/></span>
                </Card.Footer>
            </Card>
    </div>
  )
}

export default ParentCompany