import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/sidebar";
import Navbar from "../../../components/navbar";
import { Card } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Customers from "./Customer"
import Supplier from "./Supplier"

export default function Index() {
  return (
    <div className="main-wrapper inner-wrapper d-flex">
      <Sidebar id="6.4" isOpenid={6} />
      <div className="width-100">
        <Navbar />
        <div className="d-flex inner-page-header justify-content-between mx-2">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/sidebaricons/financial.svg"
              className="inner-page-titleicon img-fluid"
            />
            <span className="font-16 font-weight-600 color-707895 d-flex align-items-center pl-10">
              DEBIT OR CREDIT
            </span>
          </div>
        </div>

        <div className="">
          <div className="inner-page-content  br-8 py-3">
            <Tabs>
              <Card className="tabs-wrapper">
                <TabList>
                  <Tab>Customer</Tab>
                  <Tab>Supplier</Tab>
                </TabList>
              </Card>
              <TabPanel className="mt-1-rem">
                <Customers/>
              </TabPanel>
              <TabPanel className="mt-1-rem">
                <Supplier/>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
