import React from 'react'
import styles from './Overview.module.css'
import NumberFormat from 'react-number-format';
function Index(props) {
  return (
    <>
        <div className = {`pb-5 ${styles.overviewContainer}`}>
            <div className = {`gil-semibold d-flex align-items-center ${styles.overviewHeader}`}>
                shipment overview
            </div>
            <div className = 'd-flex align-items-center'>
                <div>
                    <img src = '/assets/images/supports/shipmentoverview-shipment-icon.svg' />
                </div>
                <div className = 'd-flex flex-column ms-3'>
                    <div className = {`gil-semibold ${styles.title}`}>
                        Shipments completed 
                    </div>
                    <div className = {`gil-regular ${styles.text}`}>
                     <NumberFormat className='gil-regular' value={props.shipmentDetails.shipmentCompletedCount != null ? props.shipmentDetails.shipmentCompletedCount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
            </div>
            <div className = 'd-flex justify-content-around mt-4 '>
                <div className = 'd-flex flex-column'>
                    <div className = {`gil-semibold ${styles.title}`}>
                        Ongoing shipments 
                    </div>
                    <div className = {`${styles.otherText}`}>
                        <NumberFormat className='gil-regular' value={props.shipmentDetails.ongoingShipmentCount != null ? props.shipmentDetails.ongoingShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
                <div className = 'd-flex flex-column'>
                    <div className = {`gil-semibold ${styles.title}`}>
                        Upcoming
                    </div>
                    <div className = {`${styles.otherText}`}>
                        <NumberFormat className='gil-regular' value={props.shipmentDetails.upcomingShipmentCount != null ? props.shipmentDetails.upcomingShipmentCount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Index