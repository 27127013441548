import React, { useEffect, useState } from 'react'
import {Row, Col, Card} from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Productsform from './productsform'
import Product_grade from './product_grade'
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import Listing from '../common/listing'
import { AddNewButton } from '../../../components/buttoncomponents'
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import FileBase64 from 'react-file-base64'; 
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

export default function AddEdit(props) {
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    const [isGradeAdded, setIsGradeAdded] = useState(false);
    const [gradeList, setGradeList] = useState([]);
    const [general, setGeneral] = useState([])
    const [isFirst, setIsFirst] = useState(true)
    const [proval, setproval] = useState([])
    const [proimg, setproimg] = useState('');
    const [isproval, setisproval] = useState(false)
    const [isdisabled, setisdisabled] = useState(true)
    useEffect(()=>{
        console.log(proval, 'proval')
    },[proval])
    useEffect(()=>{
        console.log(isproval, 'is proval')
    },[isproval])
    useEffect(()=>{
        if(props.isEdit){
            if(isFirst){
                // const json_list = {
                //     "instanceID" : CONFIG.PMSInstanceID,
                //     "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
                //     "created_User" : CONFIG.LoginID
                // }
                // axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_GRADE, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                // .then((res) => {
                //     let result = res.data.result
                //     let gradel = [];
                //     let grList = [];
                //     let selectedgrade = [props.selectedProduct[0].productGradeMapping];
                //     result.map((re,l)=>{
                //         let status = false
                //         selectedgrade[0].map((gr,k)=>{
                //             if(re.productGradeID == gr.productGradeID){
                //                 status = true
                //             }
                //         })
                //         if(status == true){
                //             gradel[l] ={
                //                 "productGradeID": re.productGradeID,
                //                 "productGradeName" : re.productGradeName
                //             }
                //         }
                //     })
                //     setGradeList(gradel)
                //     setIsGradeAdded(true)
                // })
                let product_val = {
                    "productCode" : props.selectedProduct[0].productCode,
                    "hscode" : props.selectedProduct[0].hsCode,
                    "productName" : props.selectedProduct[0].productName,
                    "productCategoryID": parseInt(props.selectedProduct[0].productCategoryID),
                    "productGroupID" : parseInt(props.selectedProduct[0].productGroupID),
                    "productUOMID" : parseInt(props.selectedProduct[0].productUOMID),
                    "description" : props.selectedProduct[0].description,
                }
                setproimg(props.selectedProduct[0].productImagebase64);
                setGeneral(product_val);
                console.log(product_val,'pro val')
                setproval(product_val) 
                setisproval(true)
            }
            setIsFirst(false)
        }
    },[isFirst])


    const addGrade = ()=>{
        setIsGradeAdded(!isGradeAdded)
    }

    const saveGrade = (datas)=>{
        let list = [...gradeList];
        let data = gradeList.filter(x=> x.productGradeID == parseInt(datas.productGradeID));
        let length = data.length
        if(length == 0){
            list.push(datas)
            setGradeList(list)
        }
        else{

        }
        setIsGradeAdded(true)
    }

    const deletedata = (e)=>{
        const id = e.target.id;
        console.log(id)
        let data = [];
        data = gradeList.filter(x=> x.productGradeID != id);
        setGradeList(data);
    }

    const sendBack = (data)=>{
        setGeneral(data);
        console.log(general, 'general')
        setisdisabled(false)
    }

    const saveProduct = ()=>{
        
        let grlist = []
        // gradeList.map((list,k)=>{
        //     grlist[k] = {productGradeID : parseInt(list.productGradeID)}
        // })
        grlist[0] = {productGradeID : 1}
        let final_json = {
            
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "productName" : general.productName,
            "productCategoryID": parseInt(general.productCategoryID),
            "hscode" : general.hscode,
            "productGroupID" : parseInt(general.productGroupID),
            "productUOMID" : parseInt(general.productUOMID),
            "description" : general.description,
            "productImagebase64" : proimg,
            "productGradeMapping" : grlist
        }
        if(props.isEdit == true){
            final_json["productID"] = props.selectedProduct[0].productID;
            final_json["productCode"] = props.selectedProduct[0].productCode;
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.UPDATE_PRODUCT, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data)
                if(res.data.responseCode == "303002"){
                    MySwal.fire(
                        {
                            icon: 'warning',
                            html: res.data.responseMessage,
                            //type: 'error',
                            confirmButtonText: 'Back',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                            `
                      }).then(() => {
                        
                    }); 
                }
                else if(res.data.responseCode == "303000"){
                    MySwal.fire(
                        {
                            icon: 'success',
                            html: "Product updated successfully",
                            //type: 'error',
                            confirmButtonText: 'Back to Product List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                            `
                      }).then(() => {
                        props.back();
                    }); 
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            axios.post(CONFIG.TMS_SERVER_URL+CONFIG.ADD_PRODUCT, final_json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data)
                if(res.data.responseCode == "303002"){
                    MySwal.fire(
                        {
                            icon: 'warning',
                            html: res.data.responseMessage,
                            //type: 'error',
                            confirmButtonText: 'Back',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                            `
                      }).then(() => {
                        
                    }); 
                }
                else if(res.data.responseCode == "303000"){
                    MySwal.fire(
                        {
                            icon: 'success',
                            html: "Product added successfully!",
                            //type: 'error',
                            confirmButtonText: 'Back to Product List',
                            confirmButtonClass: "btn btn-primary",
                            backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                            `
                      }).then(() => {
                        props.back();
                    }); 
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
       
    }

    const addImg = (file) =>{
        setproimg(file.base64);
    }
    return (
      <div>
          <Row>
              <Col md={7} className='ms-4 me-3 py-3'>
                    <Card style={{  height: '70vh', background:'transparent'}} >
                        <Card style={{background: 'transparent',overflowY: 'auto' }}>
                            <Card.Body className='px-4 pb-4 br-8' style={{background: '#fff'}}>
                                <div className='d-flex justify-content-between width-100l'>
                                    <div className='font-16 font-weight-400 '>General Information</div>
                                </div>
                                {isproval ?
                                    <Productsform sendBack={sendBack} isEdit={props.isEdit} proval={proval}/>
                                    :
                                    <Productsform sendBack={sendBack} sample={true}/>
                                }
                            </Card.Body>
                            {/* <Card.Body className='mt-2-rem mb-10-rem' style={{background: '#fff'}}>
                                {isGradeAdded?
                                    <Row>
                                        {!props.isView &&
                                            <div className='d-flex justify-content-end'>
                                                <AddNewButton value='Add New Grade' clickEvent={addGrade}/>
                                            </div>
                                        }
                                        <table className="table table-text mt-1-rem">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                    <th>
                                                        Product Grade
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gradeList.map((grade,k)=>(
                                                    <Listing key={k} 
                                                        id={grade.productGradeID} 
                                                        name={grade.productGradeName} 
                                                        delete={deletedata} 
                                                        onlyRemove={true}
                                                        isMenuDisabled={props.isView}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>

                                    </Row>
                                :
                                    <Product_grade 
                                        saveGrade= {saveGrade}
                                        selectedList = {gradeList}
                                    />
                                }
                            </Card.Body> */}
                        </Card>
                        <Card.Footer className='form-card-footer pt-4 d-flex'>
                            <span><BlueBorderButton value='Cancel' clickEvent={props.cancel}/></span>
                            <span className='pl-10 '><BlueButton className='font-weight-500' value='Save' clickEvent={saveProduct} disabled={isdisabled}/></span>
                        </Card.Footer>
                    </Card>
              </Col>
              <Col md={3} className='py-3'>
                  <Card className='br-8 p-2-rem'>
                        <div className='width-100l d-flex justify-content-center'>
                            {proimg!='' ?
                                <img className='img-fluid' src={proimg}/>

                                :
                                <div className='product_img_wrapper img-fluid'>
                                
                                </div>
                            }
                        </div>
                        
                        <div className='mt-1-rem '>
                                <label className=' d-flex justify-content-center btn btn-outline-primary' >
                                    <div className='d-flex '> 
                                        <span><img className='img-fluid' src='assets/images/AddButtonIcon.svg'/> </span>
                                        <span className='ps-2'>Upload Product Image Here</span>
                                    </div>
                                    <FileBase64 className="form_input" onDone={addImg} />
                                </label>
                        </div>
                  </Card>
              </Col>
          </Row>
      </div>
    )
}
