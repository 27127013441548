import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form } from "react-bootstrap";
import { Button } from "react-bootstrap"
import * as FormFields from "../../components/formcomponents"
import * as ButtonsComponents from "../../components/buttoncomponents"
import { useFormik } from "formik";
import { checkEmailAvailableTrue } from "../../helpers/API/Api";
import axios from 'axios';
import * as CONFIG from "../../helpers/config"
import { PRIVILEGE_JSON, PRIVILEGE_ARRAY } from '../../helpers/privilegeList';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useIsAuthenticated } from "@azure/msal-react";


export default function Index() {
    const isAuthenticated = useIsAuthenticated();
    const [isloggedin_status, setIsloggedin_status] = useState(false);
    const [loginError, setLoginError] = useState(false)
    const [isLogginIn, setIsLogginIn] = useState(false)
    const { instance, accounts } = useMsal();
    // useEffect(()=>{
    //     if(isloggedin_status){
    //         console.log('inside')
    //         window.location.href= "/superadmin"
    //     }
    // },[isloggedin_status])



    useEffect(() => {

        if(isAuthenticated){
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                console.log(response)
                if(response.account.username == CONFIG.SUPER_ADMIN_USER_EMAIL){
                    localStorage.setItem('SuperAdminToken', response.accessToken)
                    window.location.href= "/superadmin"
                    setLoginError(false)
                }
                else{
                    setLoginError(true)
                }
            })
        }
    }, [isAuthenticated])
    const LoginIn = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isForgot, setIsForgot] = useState(false);
    const [isError, setIsError] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        }
    }
    )
    const validate = () => {
        if (!formik.values.email) {
            formik.errors.email = 'Required';
            setIsError(true);
            return true
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formik.values.email)) {
            formik.errors.email = 'Invalid email address';
            setIsError(true);
            return true;
        }
        else {
            formik.errors.email = '';
            setIsError(false);
            return false;
        }
    }

    const signIn = () => {
        //let result = handleLogin();
        setIsLogginIn(true)
        let json_array = {
            instanceID: CONFIG.PMSInstanceID,
            userEmail: formik.values.email,
            password: formik.values.password
        }

        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.SUPER_ADMIN_LOGIN, json_array, { headers: { "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data)
                localStorage.setItem('LoginUserID', parseInt(res.data.result.superAdminID));
                setIsloggedin_status(true)
            })
    }

    const checkEmailAvailable = (e) => {
        let u_email = e.target.value;
        formik.values.email = u_email;
        let res = validate();
        if (!res) {
            setUserName(userName)
        }
        // if(!res){
        //     let result = checkEmailAvailableTrue(formik.values.email )
        //     console.log(result,'here');
        // }

    }

    const setPwd = (e) => {
        formik.values.password = e.target.value;
    }


    const resetPassword = (e) => {

    }

    const setForgot = () => {
        setIsForgot(!isForgot);
    }

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                event.preventDefault();

                // 👇️ your logic here
                signIn();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <div className='main-wrapper login-wrapper login-image-background'>
            <Row>
                <Col md={12} className='pt-3-rem pl-10-rem'>
                    <a href='/'>
                        <img src="assets/images/tmslanding/tmslogo.svg" className='img-fluid' alt='logo' />
                    </a>
                </Col>
                <Row className='plr-10-rem'>
                    <Col md={7} className=''>
                        <img src='assets/images/login-left-image.svg' className='img-fluid login-left-image mt-1-rem' alt='left' />
                    </Col>
                    <Col md={5} className='d-flex align-items-center' style={{ width: '30%' }}>
                        <div className='align-items-center width-100l'>
                            <Card className='br-10 p-2-rem'>
                                <Card.Body>
                                    <div className='font-normal font-weight-400 font-16 login-welcome-title'>Welcome to TMS</div>
                                    <div className='font-weight-500 font-22 login-welcome-sub-title'>Trading & Analytics Platform</div>

                                    <Col className='pt-1-rem'>
                                        <Button className='login-button' type="button" onClick={LoginIn}>
                                            Login
                                        </Button>
                                    </Col>
                                    <Col>
                                        {loginError &&
                                            <span className='input_error'>Invalid user email. Please try again!</span>
                                        }
                                    </Col>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row>
                <Col md={12} className='pt-1-rem pl-10-rem'>
                    <span className='font-13'>Powered by <b>STX Platform</b></span>
                </Col>
            </Row>
        </div>
    )
}
