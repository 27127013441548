import React, { useState } from 'react'
import {Row, Col} from "react-bootstrap";
import { useFormik } from 'formik'
import { UploadButton } from '../../../../components/buttoncomponents'

export default function UploadDocument(props) {
    console.log(props, 'upload Document')
    const [annualAudit, setAnnualAudit] = useState([]);
    const [bankReference, setBankReference] = useState([]);
    const [Memo, setMemo] = useState([]);
    const [annualAuditDocumentName, setauditDocumentName] = useState(props.data != '' ? props.data.annualAuditDocumentName : '');
    const [bankReferenceLetterDocumentName, setreferDocumentName] = useState(props.data != '' ? props.data.bankReferenceLetterDocumentName : '');
    const [memorandumArticleDocumentName, setmemoDocumentName] = useState(props.data != '' ? props.data.memorandumArticleDocumentName : '');


     const formik = useFormik({
        initialValues:
        {
            bankReferenceLetterDocument: '',
            annualAuditDocument: '',
            memorandumArticleDocument: ''

        },
    })


    const auditHandler = (file)=>{
        formik.values.annualAuditDocument = file.base64
        formik.values.annualAuditDocumentName = file.name
        setAnnualAudit(file);
        setauditDocumentName(file.name)
        props.saveDocuments(formik.values)
    }
    const referHandler = (file)=>{
        formik.values.bankReferenceLetterDocument = file.base64
        formik.values.bankReferenceLetterDocumentName = file.name
        setBankReference(file)
        setreferDocumentName(file.name)
        props.saveDocuments(formik.values)
    }
    const memoHandler = (file)=>{
        formik.values.memorandumArticleDocument = file.base64
        formik.values.memorandumArticleDocumentName = file.name
        setMemo(file)
        setmemoDocumentName(file.name)
        props.saveDocuments(formik.values)
    }


    return (
        <Row>
            <div className='font-16 font-weight-600 color-707895'>
                Upload Document
            </div>
            <Row className='d-flex '>
                <Col md={3} className='pt-1-rem align-items-center'>
                    <UploadButton value='Annual Audit' onUploadHandle={auditHandler} filename={annualAuditDocumentName}/>
                </Col>
                <Col md={3} className='pt-1-rem align-items-center'>
                    <UploadButton value='Bank Reference Letter' onUploadHandle={referHandler} filename={bankReferenceLetterDocumentName}/>
                </Col>
                <Col md={3} className='pt-1-rem align-items-center'>
                    <UploadButton value='Memorandum & Article' onUploadHandle={memoHandler} filename={memorandumArticleDocumentName}/>
                </Col>
            </Row>
        </Row>
    )
}
