export const PRIVILEGE_ARRAY = [
    {
        id : 103001,
        name: 'PLATFORM_SETUP'
    },
    {
        id : 103002,
        name : 'List_Customers'
    },
    {
        id : 103003,
        name : 'Add_Customer'
    },
    {
        id : 103004,
        name : 'Edit_Customer'
    },
    {
        id : 103005,
        name : 'Enable_Disable_Customer'
    },
    {
        id : 103006,
        name : 'List_Suppliers'
    },
    {
        id : 103007,
        name : 'Add_Supplier'
    },
    {
        id : 103008,
        name : 'Edit_Supplier'
    },
    {
        id : 103009,
        name : 'Enable_Disable_Supplier'
    },
    {
        id : 103010,
        name : 'List_Products'
    },
    {
        id : 103011,
        name : 'Add_Product'
    },
    {
        id : 103012,
        name : 'Edit_Product'
    },
    {
        id : 103013,
        name : 'Enable_Disable_Product'
    },
    {
        id : 103014,
        name : 'List_Trade_Enquiries'
    },
    {
        id : 103015,
        name : 'View_Trade_Enquiry_Details'
    },
    {
        id : 103016,
        name : 'Add_New_Trade_Enquiry'
    },
    {
        id : 103017,
        name : 'Edit_Trade_Enquiry'
    },
    {
        id : 103018,
        name : 'Initiate_Trade'
    },
    {
        id : 103019,
        name : 'List_Trade'
    },
    {
        id : 103020,
        name : 'View_Trade'
    },,
    {
        id : 103021,
        name : 'Edit_Trade'
    },
    {
        id : 103022,
        name : 'Customer_Contract'
    },
    {
        id : 103023,
        name : 'Supplier_Contract'
    },
    {
        id : 103024,
        name : 'Freight_Charges_Update'
    },
    {
        id : 103025,
        name : 'Estimated_Cost_Sheet'
    },
    {
        id : 103026,
        name : 'Mark_Contracts_Finalized'
    },
    {
        id : 103027,
        name : 'Pre_shipment_Updates'
    },
    {
        id : 103028,
        name : 'Post_vessel_fixture_Updates'
    },
    {
        id : 103029,
        name : 'Loading_Port_Updates'
    },
    {
        id : 103030,
        name : 'Discharge_Port_and_Shipment_Completion_Updates'
    },
    {
        id : 103031,
        name : 'Pre_Closure_Updates'
    },
    {
        id : 103032,
        name : 'Close_Trade'
    },
    {
        id : 103033,
        name : 'Actual_Cost_Sheet'
    },
    {
        id : 103034,
        name : 'Update_Vessel_Information'
    },
    {
        id : 103035,
        name : 'Generate_Customer_Proforma_Invoice'
    },
    {
        id : 103036,
        name : 'Generate_Customer_Invoice'
    },
    {
        id : 103037,
        name : 'Generate_Supplier_PO'
    },
    {
        id : 103038,
        name : 'Update_Supplier_Proforma_Invoice'
    },
    {
        id : 103039,
        name : 'Update_Supplier_Invoice'
    },
    {
        id : 103040,
        name : 'L/C_Documents'
    },
    {
        id : 103041,
        name : 'Update_Insurance_Documents'
    },
    {
        id : 103042,
        name : 'Customer_Payment_Update'
    },
    {
        id : 103043,
        name : 'Supplier_Payment_Update'
    },
    {
        id : 103044,
        name : 'Shipment_ETA'
    },
    {
        id : 103045,
        name : 'Trade_Contracts_Supplier_Listing'
    },
    {
        id : 103046,
        name : 'Trade_Contracts_Supplier_Listing'
    },
    {
        id : 103047,
        name : 'Customer_Documents_Listing'
    },
    {
        id : 103048,
        name : 'Supplier_Documents_Listing'
    },
    {
        id : 103049,
        name : 'Customer_Payments_Listing'
    },
    {
        id : 103050,
        name : 'Supplier_Payments_Listing'
    },
    {
        id : 103051,
        name : 'Customer_Reports'
    },
    {
        id : 103052,
        name : 'Trade_Reports'
    },
    {
        id : 103053,
        name : 'Shipment_Reports'
    },
    {
        id : 103054,
        name : 'PurchaseSales_Reports'
    },
    {
        id : 103055,
        name : 'Performance_Reports'
    },
    {
        id : 903002,
        name : 'Dashboards_Menu'
    },
    {
        id : 903003,
        name : 'Customers_Menu'
    },
    {
        id : 903004,
        name : 'Suppliers_Menu'
    },
    {
        id : 903005,
        name : 'Products_Menu'
    },
    {
        id : 903006,
        name : 'Trading_Menu'
    },
    {
        id : 903007,
        name : 'Sales_Menu'
    },
    {
        id : 903008,
        name : 'Purchase_Menu'
    },
    {
        id : 903009,
        name : 'Shipment_Menu'
    },
    {
        id : 903010,
        name : 'Trading_Reports_Menu'
    },
    {
        id : 903011,
        name : 'Shipment_Reports_Menu'
    },
    {
        id : 903015,
        name : 'Reports_Menu'
    },
    {
        id : 903012,
        name: 'User-Menu'
    },
    {
        id: 903013,
        name: 'Division-Menu'
    },
    {
        id: 903016,
        name: 'Downloads_Menu'
    },
    {
        id: 903017,
        name: 'Invoices_Menu'
    },
    {
        id: 903018,
        name: 'Approvals-Menu'
    },
    {
        id: 903019,
        name: 'Financial-Menu'
    }
]