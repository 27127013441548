import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton,} from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";

function CustomerPOList(props) {
    const { instance } = useMsal();
    const [customerProformaInvoiceData, setPurchaseOrder] = useState()
    const [tradeID, setTradeID] = useState()
    const [tradeData, setTradeData] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [showView, setShowView] = useState(false)

    let navigate = useNavigate();

    useEffect(()=> {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PURCHASE_ORDER_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
               console.log(res.data.result)
               setPurchaseOrder(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    },[])
    
    const back = () => {
        navigate('/trade-transactions', { state:{ tab: 1,}});
    }

    const handleBase64Validation = (values) => {
        let searchData = values.supplierDocument.includes("data:")
        let searchBase64 = values.supplierDocument.includes("base64")
    }
  return (
    <>
     <div className='main-wrapper inner-wrapper d-flex'>
              <Sidebar id='7.3' isOpenid={7} />
            <div className='width-100'>
                <Navbar />
                    <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                        <div className = 'd-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div>
                    </div>
                <div className='d-flex justify-content-between align-items-center mx-4 px-5 mt-5'>
                    <div className='d-flex align-items-center'>
                          <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>CUSTOMER PURCHASE ORDERS</span>
                    </div>
                </div>
                <div className='inner-page-content mx-md-4 mx-2'>
                    <Card>
                        <CardBody>
                            <div class="col-lg-12 list-scroll">
                                <div class="table-responsive-sm ">
                                    <table className="table tCustom table-text">
                                        <thead className='p-1-rem'>
                                            <tr className='text-uppercase list-top'>
                                                <th>customer purchase order#</th>
                                                  <th>purchase order date</th>
                                                <th>trade#</th>
                                                <th>customer</th>
                                                <th>Document</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {customerProformaInvoiceData != null && customerProformaInvoiceData.length > 0 ?
                                            customerProformaInvoiceData.map((values,k) =>(
                                            <tr>
                                                    <td className='font-14 font-weight-500'><p className='w-150 mb-0'>{values.customerPurchaseOrderNo}</p></td>
                                                    <td className='font-14 font-weight-500'><p className='w-150  mb-0'>{Moment(values.customerPurchaseOrderDate).format("DD/MM/YYYY")}</p></td>
                                            <td className='font-14 font-weight-500'><p className='w-150  mb-0'>{values.tradeNo}</p></td>
                                                <td>
                                                    <div className = 'd-flex flex-column w-200'>

                                                        <div>
                                                                {values.customerName}
                                                        </div>
                                                        <div>
                                                                {values.customerNo}
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                                <td>
                                                        <div className='font-weight-600 color-243448'> <a download={values.customerPurchaseOrderDocumentName} href={values.customerPurchaseOrderDocument}>{values.customerPurchaseOrderDocumentName}</a></div>
                                                </td>
                                            </tr>
                                        )) 
                                        :
                                        ''
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>


    </div>
    </>
   
  )
}

export default CustomerPOList