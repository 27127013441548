import React from 'react'
import TradeEnquiryTabs from './Trade Enquiries/TradeEnquiryTabs'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from '../../components/navbar'
function TradeEnquiries(props) {
  return (
   
        <>
          <TradeEnquiryTabs selectedDivision = {props.selectedDivision} back={props.back} selectedEnquiry={props.selectedEnquiry} isEdit={props.isEdit} />
        </>

  )
}

export default TradeEnquiries