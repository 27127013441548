import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton, BlueButton } from '../../components/buttoncomponents'
import axios from 'axios'
import * as CONFIG from ".././../helpers/config"
import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import * as FormFields from "../../components/formcomponents"
import { useMsal } from "@azure/msal-react";
import Add_ins from './add_ins'
import ContactSubList from './ContactSubList'
import Add_ins_contact from './add_ins_contact'

export default function Contactmainlist() {
    const { instance } = useMsal();
    const [filterlist, setFilterlist] = useState([])
    const [contactList, setContactList] = useState([])
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        getList()
    }, [])

    const getList =()=>{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_FINANCIAL_CONTATC_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setContactList(res.data.result)
                setFilterlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleSearch = (e) => {
        const val = e.target.value

        let filter = contactList.filter(x => x.contactname.toLowerCase().includes(val.toLowerCase()) ||
            x.contactemail.toLowerCase().includes(val.toLowerCase()) ||
            x.contacttype.toLowerCase().includes(val.toLowerCase()) ||
            x.designation.toLowerCase().includes(val.toLowerCase())||
            x.institute.toLowerCase().includes(val.toLowerCase())
        )
        setFilterlist(filter)
    }

    const openContactInstitution = (da) => {
        console.log(data)
        setData(da)
        setIsOpen(true)
    }
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='9.2' isOpenid={9} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between mx-2'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/financial.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>CONTACTS</span>
                    </div>
                    <div className=''>
                        <div className='me-3'>
                            <Col md={12}>
                                {isOpen &&
                                    <BlueButton value='Back' clickEvent={() => {
                                        setIsOpen(false)
                                        getList()
                                    }} />
                                }
                            </Col>
                        </div>
                    </div>
                </div>
                {!isOpen ?
                    <div>
                        <Row className='mx-2'>
                            <Col md={10}>
                            </Col>
                            <Col md={2}>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='Search'
                                            className="form_input form_input_color_change"
                                            name='search'
                                            onChange={handleSearch}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Col>
                        </Row>
                        <div className=''>
                            <div className='inner-page-content  br-8 py-3'>
                                <div className='card card-body table-responsive-sm '>
                                    <Card className='col-lg-12  list-scroll'>
                                        <table className="table tCustom table-text">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895 list-top'>
                                                    <th>Contact Name</th>
                                                    <th>Institute</th>
                                                    <th>Contact</th>
                                                    <th>Contact Type</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterlist.map((list, k) => {
                                                    return (
                                                        <ContactSubList data={list} key={k} openContactInstitution={openContactInstitution} />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='inner-page-content  br-8 py-3'>
                        <Add_ins_contact data={data} back={()=>{
                            setIsOpen(false)
                            getList()
                        }}/>
                    </div>

                }
            </div>


        </div>
    )
}
