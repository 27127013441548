import React from 'react'
import SupplierList from './SupplierList'

export default function List(props) {
    console.log(props)
    return (
      <table className="table table-text mt-1-rem">
              <thead>
                  <tr className='bg-light font-weight-500 p-3'>
                      <th className='p-3'>Supplier Code</th>
                      <th className='p-3'>Supplier Name</th>
                      <th className='p-3'>Address</th>
                      <th className='p-3'>Category</th>
                      <th className='p-3'>Incoterms</th>
                      <th></th>
                    </tr>
              </thead>
              <tbody>
                {props.data != null && props.data.length > 0 ? 
                 props.data.map((list,k)=>(
                    <SupplierList selectedSupplier = {props.selectedSupplier} data={list} sendBack={props.back}/>
                ))
                :
                <h4 className = 'font-weight-500 font-14 mx-3 mt-3'>No supplier created</h4>
                }
                 
              </tbody>
      </table>
    )
}
