import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import axios from 'axios'
import { AgChartsReact } from "ag-charts-react";
import * as agCharts from 'ag-charts-community';
import NumberFormat from 'react-number-format';
import styles from './Greeting.module.css'
import * as CONFIG from "../../../../../helpers/config"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function Index(props) {
  const [key, setKey] = useState([])
  const [labelSales, setLabelSales] = useState([])
  const [labelPurchase, setLabelPurchase] = useState([])
  
  useEffect(()=>{
    let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "termsID": props.termsID,
        "dashboardTypeID": 4,
        "userID": parseInt(CONFIG.LoginID),
        "companyID" : parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_DETAILS_TREND, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        let keyList = []
        let labelSales = []
        // let labelPurchase = []
        res.data.result.map((values, k)=>(
          keyList.push(Moment(values.tradeClosedDate).format('MMMM Do YYYY')),
          labelSales.push(values.salesValue)
        ))
        console.log(key)
        console.log(key)
        setKey(keyList)
        setLabelSales(labelSales)
        // setLabelPurchase(labelPurchase)
        //setShipmentDetails(res.data.result)
        //setNotifications(res.data.result)
    })
  },[props])

  // useEffect(()=>{
  //   console.log(key)
  //   console.log(labelSales)
  // },[key, labelSales])
  const handlePercentageRevenueAmount = () => {
    let difference = 0
    let product = 0
    console.log(props)
    if(props.closedTradeDetails != 0 && props.closedTradeDetails != null){
      if(difference == 0 && props.closedTradeDetails.tradeRevenuePrevious == 0){
            console.log('cannot calc')
            product = 0
      }
      else{
        difference =  parseInt(props.closedTradeDetails.totalTradeRevenue) - parseInt(props.closedTradeDetails.tradeRevenuePrevious) 
        console.log(difference, 'calculated')
        product =  parseInt(difference) / parseInt(props.closedTradeDetails.tradeRevenuePrevious)
      }
    }
    else{
      product = 0
    }
    console.log(difference)
    console.log(product, 'product before percentage')
    console.log(parseFloat(product * 100).toFixed(2))
    product = parseFloat(product * 100).toFixed(2)
    console.log(product)
    return(
      <>
       
        {(parseInt(props.closedTradeDetails.tradeRevenuePrevious) < (parseInt(props.closedTradeDetails.totalTradeRevenue))) == true ? 
         <div className = {` ms-2 d-flex align-items-center ${styles.profitIcon}`}>
          {product}%
          <img src = '/assets/images/supports/dashboard-trends-profit-icon-1.svg' alt = 'icon' className = 'ms-2'/>
          
         </div>
         :
         <div className = {`  ms-2 d-flex align-items-center ${styles.lossIcon}`}>
             {product}%
          <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' className = 'ms-2' />
         </div>
      }
        
      </>
    )
    //console.log(division)
  }
  const gradient = () => {
    var ctx = document.getElementById('canvas').getContext("2d")
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, 'rgba(1, 247, 202, 0.44)')
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')
    return(gradient)
  }

  const gradientLine = () => {
    var ctx = document.getElementById('canvas').getContext("2d")
    var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, 'rgba(18, 84, 255, 1)')
    gradient.addColorStop(1, 'rgba(20, 216, 133, 1)')
    return (gradient)
  }

  const options = {
    //   layout: {
    //     // padding:-30
    // },
      responsive: true,
      scales: {
        y: {
          ticks: {
            display: false,
            padding: 0,
          },
          grid: {
            display: false,
            drawBorder: false
          },
        },
        x:{
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
  
        title: {
          display: true,
        
        },
      },
    };
  const labels = key.length > 0 ? key : []
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Trade Revenue',
        data: labelSales.length > 0 ? labelSales : [],
        borderColor: gradientLine,
        backgroundColor: gradient,
        lineTension: 0.5,
        pointRadius: [6],
        pointHoverRadius: 8,
        pointHitRadius: 8,
        pointStyle:['circle'],
        pointBackgroundColor: ['#2C67FF'],
        pointBorderColor:['#fff'],
        pointBorderWidth: 4
      },
    ],
  };


    const handleTimeGretting = () => {
        var currentHour = Moment().format("HH");
        const hour = Moment().hour();

        if (hour > 16){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Evening
                </div>
            );
        }

         if (hour > 11){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Afternoon
                </div>
            );
         }

         return (
            <div className = {` ${styles.greetingHourText}`}>
                Good Morning
            </div>
        );
    }

   
    return (
        <>
            <div className = {` ${styles.greetingContainer}`}>
              <div className = {` ${styles.greetingsSubcontainer}`}>
                <div className = 'd-flex align-items-center'>
                    <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{background:'#FFCE22', backgroundColor:"#FFCE22", width: '50px', height: '50px', fontSize: '12px' }}>
                        <p className='font-16 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                    </div>
                    <div className = 'd-flex flex-column ms-2'>
                        {handleTimeGretting()}
                        <div className = {`${styles.greetingNameText}`}>
                          {localStorage.getItem('UserFullName')}
                        </div>
                    </div>
                </div>
                <div className = 'd-flex flex-column my-3 pt-5'>
                    <div className = {` ${styles.greetingEarnedProfitTitle}`}>
                        total earned profit
                    </div>
                    <h4 className = {` ${styles.greetingEarnedProfitAmount}`}>
                     <span className = {`mt-3 ${styles.dollarTextColor}`}>$</span><NumberFormat value={props.closedTradeDetails.totalEarnedProfit} displayType={'text'} thousandSeparator={true}  />
                    </h4>
                </div>
                <div className = 'd-flex flex-column'>
                    <div className = {` ${styles.greetingEarnedProfitTitle}`}>
                        total trading revenue
                    </div>
                    <div className = {` ${styles.greetingEarnedProfitAmount}`}>
                      <div className = 'd-flex align-items-center'>
                        <div className = {` ${styles.totalTradeRevenueAmount}`}>
                          $<NumberFormat value={props.closedTradeDetails.totalTradeRevenue} displayType={'text'} thousandSeparator={true}  />
                        </div>
                          {handlePercentageRevenueAmount()}
                      </div>
                    </div>
                </div>
              </div>
              <div className = 'mb-4'>
                <Line  id='canvas' options={options} data={data} />
              </div>
            </div>
        </>
    )
}

export default Index