import React, {useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, BlueBorderButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";
import { CountryList, CompanyType } from '../../../../../../helpers/API/Api';

function FormComponent(props) {
  const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
  const [contactTypeError, setContactTypeError] = useState(false);
  const [isError, setIsError] = useState(true);
  console.log(props.data)
  const addDirector = ()=>{

      const director_list ={
        "subsidiaryCompanyName": formik.values.subsidiaryCompanyName,
        "subsidiaryCompanyValue": formik.values.subsidiaryCompanyValue,
        "subsidiaryCountryValue": formik.values.subsidiaryCountryValue,
        "subsidiaryCompanyTypeID": formik.values.subsidiaryCompanyTypeID == null ? formik.values.subsidiaryCompanyTypeID : parseInt(formik.values.subsidiaryCompanyTypeID),
        "subisidiaryCompanyCountryID": formik.values.subisidiaryCompanyCountryID == null ? formik.values.subisidiaryCompanyCountryID : parseInt(formik.values.subisidiaryCompanyCountryID),
        "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
        "is_Default" : false,
    }

    console.log(director_list)
    props.addDirectorToList(director_list)
    
     
  }

  const formik = useFormik({
    initialValues:
    !props.isEdit?
    {
        subsidiaryCompanyName: '',
        subsidiaryCompanyValue: '',
        subsidiaryCountryValue: '',
        subsidiaryCompanyTypeID: null,
        subisidiaryCompanyCountryID: null,
        shareholdingPercentage: 0,
    }
    :
    {
      subsidiaryCompanyName: props.data.subsidiaryCompanyName,
      subsidiaryCompanyValue: props.data.subsidiaryCompanyValue,
      subsidiaryCountryValue: props.data.subsidiaryCountryValue,
      subsidiaryCompanyTypeID: props.data.subsidiaryCompanyTypeID,
      subisidiaryCompanyCountryID: props.data.subisidiaryCompanyCountryID,
      shareholdingPercentage: props.data.shareholdingPercentage,
    },

    validate: values => {
            
      let errors = {}
      const letters = /^[A-Za-z ]+$/;
      const phone = /^[0-9]+$/;

      if(!values.subsidiaryCompanyName){
          errors.subsidiaryCompanyName = "Please fill in name of Subsidiary Company"
      }else if (!letters.test(values.subsidiaryCompanyName)) {
          errors.subsidiaryCompanyName = "Please enter a valid Subsidiary Company Name"
      }

      if (!letters.test(values.directorDesignation) && values.directorDesignation) {
          errors.directorDesignation = "Please enter a valid Designation"
      }
      if (!letters.test(values.shareholderNationality) && values.shareholderNationality) {
          errors.shareholderNationality = 'Please enter a valid Nationality'
      }
      if (!phone.test(values.shareholdingPercentage) && values.shareholdingPercentage) {
          errors.shareholdingPercentage = 'Please enter a valid Percentage'
      }
      setIsError(true)
      sendBack();
      return errors
  }
})


  const editDirector = () => {
    if(props.isEdit == true){
      const director_list = {
          "subsidiaryCompanyName": formik.values.subsidiaryCompanyName,
          "subsidiaryCompanyValue": formik.values.subsidiaryCompanyValue,
          "subsidiaryCountryValue": formik.values.subsidiaryCountryValue,
          "subsidiaryCompanyTypeID": formik.values.subsidiaryCompanyTypeID == null ? formik.values.subsidiaryCompanyTypeID : parseInt(formik.values.subsidiaryCompanyTypeID),
          "subisidiaryCompanyCountryID": formik.values.subisidiaryCompanyCountryID == null ? formik.values.subisidiaryCompanyCountryID : parseInt(formik.values.subisidiaryCompanyCountryID),
          "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
          "is_Default" : props.data.is_Default,
          "key" :props.data.key,
          "supplierSubsidiaryCompanyID": props.data.supplierSubsidiaryCompanyID,
          "supplierID" : props.data.supplierID
      }
        console.log(director_list)
        props.editDirectorToList(director_list)
    }
    else{
      const director_list = {
        "subsidiaryCompanyName": formik.values.subsidiaryCompanyName,
        "subsidiaryCompanyValue": formik.values.subsidiaryCompanyValue,
        "subsidiaryCountryValue": formik.values.subsidiaryCountryValue,
        "subsidiaryCompanyTypeID": formik.values.subsidiaryCompanyTypeID == null ? formik.values.subsidiaryCompanyTypeID : parseInt(formik.values.subsidiaryCompanyTypeID),
        "subisidiaryCompanyCountryID": formik.values.subisidiaryCompanyCountryID == null ? formik.values.subisidiaryCompanyCountryID : parseInt(formik.values.subisidiaryCompanyCountryID),
        "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
        "is_Default" : props.data.is_Default,
        "key" :props.data.key
    }
      console.log(director_list)
      props.editDirectorToList(director_list)
    }
  }
  const handleContactType = (e)=>{
    formik.values.subsidiaryCompanyTypeID = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    formik.values.subsidiaryCompanyValue = e.nativeEvent.target[index].text;
    console.log(e.nativeEvent.target[index].text)
    if(formik.values.subsidiaryCompanyTypeID == 0){
        setContactTypeError(true)
    }
    else{
        setContactTypeError(false)
    }
  }
  const handleCountry = (e)=>{
    formik.values.subisidiaryCompanyCountryID = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    formik.values.subsidiaryCountryValue = e.nativeEvent.target[index].text;
    console.log(e.nativeEvent.target[index].text)
    if(formik.values.subisidiaryCompanyCountryID == 0){
        setContactTypeError(true)
    }
    else{
        setContactTypeError(false)
    }
  }
  const sendBack = () => {

    if ((Object.keys(formik.errors).length == 0) ) {
        
        if((props.isBackClicked == true) 
        && (formik.initialValues == formik.values) 
        && (isError == false)){
            console.log('condition one')
            setIsError(false)
            props.handleValidationDirector()
        // console.log('error checking')
        // if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
        //     console.log(formik.errors)
        //     console.log('no error first condition')
        //     setIsError(false)
        //     props.handleError()    
        // }
        // else if(formik.initialValues == formik.values){
        //     console.log(formik.errors)
        //     console.log('error')
        //     props.setErrors()
        // }
        // else{
        //     setIsError(false)
        //     console.log(formik.errors)
        //     console.log('no error first condition')
        //     props.handleError()    
        // }
        }
        else if(formik.initialValues == formik.values){
                setIsError(true)
                console.log(formik.errors)
                console.log('error')
                props.setErrors()
        }
        else{
            console.log('no log')
            setIsError(false)
            props.handleValidationSubsidiary()
        }
        
          
    }
    else{
        setIsError(true)
        console.log(formik.errors)
        console.log('error')
        props.setErrors()
    }
}
  return (
    <Row>
          <FormFields.InputField
            md={3}
            name='subsidiaryCompanyName'
            label='Subsidiary Company'
            classname='pt-1-rem'
            type='text'
            val={formik.values.subsidiaryCompanyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors= {formik.touched.subsidiaryCompanyName?formik.errors.subsidiaryCompanyName:''}
          />
          <FormFields.InputSelect
              md={3}
              name='subsidiaryCompanyTypeID'
              label='Subsidiary Type'
              classname='pt-1-rem'
              type='text'
              optionField={props.subsidiaryCompanyTypeID != '' ? 
              <CompanyType 
                companyTypeID={formik.values.subsidiaryCompanyTypeID} 
              /> 
              :
              <CompanyType 
              />}
              val={formik.values.subsidiaryCompanyTypeID}
              onChange={handleContactType}
              onBlur = {formik.handleBlur}
          />
          <FormFields.InputSelect
               md={3}
              name='subisidiaryCompanyCountryID'
              label='Country'
              classname='pt-1-rem'
              type='text'
              optionField={props.subisidiaryCompanyCountryID != '' ? 
              <CountryList 
                cntid={formik.values.subisidiaryCompanyCountryID} 
              /> 
              :
              <CountryList 
              />}
              onChange={handleCountry}
              onBlur = {formik.handleBlur}
          />
          <FormFields.InputField
              md={3}
              name='shareholdingPercentage'
              label='Share Percentage'
              classname='pt-1-rem'
              type='text'
              val={formik.values.shareholdingPercentage}
              onChange={formik.handleChange}
              onBlur = {formik.handleBlur}
              errors= {formik.touched.shareholdingPercentage?formik.errors.shareholdingPercentage:''}
          />
         <Col className='d-flex align-items-end mt-4'>
              <BlueButton value={props.isEdit?'Edit Subsidiary Company Type':' Add Subsidiary Company Type'} clickEvent={props.isEdit?editDirector:addDirector} disabled = {props.isEdit == true ? false : isError}/>
              <div className = 'ms-4'>
                  <BlueBorderButton value={'Cancel'} clickEvent={props.handleCancel}/>
              </div>
         </Col>
      </Row>
  )
}

export default FormComponent