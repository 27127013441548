import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import Moment from "moment"
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../formcomponents"
import * as ButtonComponent from "../../../buttoncomponents"
import * as CONFIG from "../../../../helpers/config"
import { CountryList, StateList, AccountType} from "../../../../helpers/API/Api"
import { useMsal } from "@azure/msal-react";


function RoleCreationModal(props) {
    const { instance } = useMsal();
    console.log(props)
    const [error, setError] = useState(false)
    const [modal, setModal] = useState(true);
    const [privilegesList, setPrivilegesList] = useState()
    const [isFirst, setIsFirst] = useState(true);
    const [selectedPrivileges, setSelectedPrivileges] = useState([])
    const [preDefinedPermissions, setPredefinedPermissions] = useState([])
    const [loader, setloader] = useState(false)

    useEffect(() => {
        let final_json = {
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_PRIVILEGE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result, 'roles')
            setPrivilegesList(res.data.result)
            //setWorkFlowList(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[])

    useEffect(()=>{
        console.log(selectedPrivileges)
    },[props])

    useEffect(() => {
        console.log(props.selectedRole.userRoleID)
        if(props.selectedRole != null && props.selectedRole != ""){
            let list = [...selectedPrivileges]
            let final_json = {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "solutionID": CONFIG.SolutionID,
                "roleID": props.selectedRole.userRoleID,
                
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_PRIVILLEGES_BY_ID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setPredefinedPermissions(res.data.result)
                for(let i = 0; i < res.data.result.length; i++ ){
                    list.push({'privilegeID':parseInt(res.data.result[i].privilegeID),
                    'permission':parseInt(res.data.result[i].permission)})
                    
                }
                setSelectedPrivileges(list)
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
            
        }
       
    },[props])

    const formik = useFormik({
        initialValues:
        {
           companyID: props.companyID,
           userRoleType: props.selectedRole != null && props.selectedRole != "" ? props.selectedRole.userRoleType : '',
           description:  props.selectedRole != null && props.selectedRole != "" ? props.selectedRole.description : '',

        },
    })

    const toggle = () => {
        setModal(!modal)
        props.closeRoleModal();
    }
  
    
    const handleChange = (e) => {
        console.log('text')
        let list = [...selectedPrivileges]
        console.log(list)
        if(list.length == 0){
            list.push({'privilegeID':parseInt(e.target.id),
            'permission':parseInt(e.target.value)})
        }
        else{
            let selected = list.filter(x=> x.privilegeID == parseInt(e.target.id))
            if(selected.length > 0){
                list  = list.filter(x=> x.privilegeID != parseInt(e.target.id))
                selected[0].permission = parseInt(e.target.value)
                list.push(selected[0])
            }
            else{
            list.push({'privilegeID':parseInt(e.target.id),
            'permission':parseInt(e.target.value)})
            }
        }
        console.log(list)
       
        // for(let i = 0; i < selectedPrivileges.length; i++){
        //     if(list[i].privilegeID == parseInt(e.target.id)){
        //         list[i] = {
        //             'privilegeID':parseInt(e.target.id),
        //             'permission':parseInt(e.target.value)
        //         }
        //         break;
        //     }
        //     else{
        //         list.push({'privilegeID':parseInt(e.target.id),
        //         'permission':parseInt(e.target.value)})
        //     }
        // }
        setSelectedPrivileges(list)
    } 

    const handleSave = () => {
        setloader(!loader)
        if(props.selectedRole != null && props.selectedRole != ""){
            let final_json = {
                "userRoleType": formik.values.userRoleType,
                "description": formik.values.description,
                "solutionID": CONFIG.SolutionID,
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "created_User": CONFIG.LoginID,
                "userRoleID":props.selectedRole.userRoleID,
                "modified_User": CONFIG.LoginID,
                "is_Admin" : props.selectedRole.is_Admin
            }
            console.log(final_json)
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_USER_ROLE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res)
                let privList = []
                selectedPrivileges.map((list, k)=>{
                    privList[k] = { 
                    "privilegeID": list.privilegeID,
                    "roleID": res.data.result.userRoleID,
                    "permission": list.permission,
                    "solutionID": CONFIG.SolutionID,
                    "pmsInstanceID": CONFIG.PMSInstanceID,
                    "created_User": CONFIG.LoginID,
                    "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID
                    
                }
                console.log(privList)
    
            })
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_PRIVILEGE_MAPPING, privList, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log('phase 1')
                props.reloadList()
                setloader(!loader)
                toggle()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })      
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
        else{
            let final_json = {
                "userRoleType": formik.values.userRoleType,
                "description": formik.values.description,
                "solutionID": CONFIG.SolutionID,
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "created_User": CONFIG.LoginID,
                
            }
            console.log(final_json)
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_USER_ROLE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res)
                let privList = []
                selectedPrivileges.map((list, k)=>{
                    privList[k] = { 
                    "privilegeID": list.privilegeID,
                    "roleID": res.data.result.userRoleID,
                    "permission": list.permission,
                    "solutionID": CONFIG.SolutionID,
                    "pmsInstanceID": CONFIG.PMSInstanceID,
                    "created_User": CONFIG.LoginID,
                    "PMSAccountSubscriptionID": CONFIG.AccountSubscriptionID
                }
                console.log(privList)
    
            })
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_PRIVILEGE_MAPPING, privList, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log('phase 2')
                props.reloadList()
                setloader(!loader)
                toggle()        
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })      
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        }
       
    }
    const handleNoRights = (e) => {
       return(true)
       
    }

    const handleReadOnly = (e) => {
        for(let x = 0; x < preDefinedPermissions.length; x++){
            if(e.privilegeID == preDefinedPermissions[x].privilegeID){
                if(preDefinedPermissions[x].permission == 1){
                    return(true)
                }
            }
        }
    }

    const handleFullRights = (e) => {
        for(let x = 0; x < preDefinedPermissions.length; x++){
            if(e.privilegeID == preDefinedPermissions[x].privilegeID){
                if(preDefinedPermissions[x].permission == 3){
                    return(true)
                }
            }
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className = 'bank-account-modal'>
        <ModalHeader toggle={toggle} className='p-40'>
            <div className = 'd-flex justify-content-between '>
                <div className='font-16 font-weight-600 color-707895'>
                    {props.selectedRole != null && props.selectedRole != "" ? "Update Role & Permissions" : "Create Role & Permissions"}
                </div>
            </div>
        </ModalHeader>
        <ModalBody  className = '' style={{backgroundColor:'#f9f9ff', overflowY: 'scroll'}} >
        {loader ?
            <div className='d-flex justify-content-center`'><img src='assets/images/loader.gif'/></div>
        :
            <>
                <div className=''>
                    <div className=''>
                            <Card className = 'my-3'>
                            <CardBody>
                                    <Form >
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Name & Description
                                        </div>
                                        <Row>
                                            <>
                                            <FormFields.InputField
                                                md={6}
                                                name='userRoleType'
                                                label='Role Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.userRoleType}
                                                errors={formik.touched.userRoleType ? formik.errors.userRoleType : ''}
                                                disabled = {props.fromView != null && props.fromView == true ? true : false}
                                                //isabled={props.isDisabled}
                                            />
                                            </>
                                        </Row>
                                        <Row>
                                            <>
                                            <Col md={6} className=''>
                                                <FormFields.inputFieldArea
                                                    md={12}
                                                    row={6}
                                                    name='description'
                                                    label='Description'
                                                    classname='pt-1-rem'
                                                    val={formik.values.description}
                                                    type='text'
                                                    onChange={formik.handleChange}
                                                    onBlur = {formik.handleBlur}
                                                    disabled = {props.fromView != null && props.fromView == true ? true : false}
                                                />
                                            </Col>
                                            </>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                    </div>
                </div>
                <div className=''>
                    <div className=''>
                            <Card className = 'my-3'>
                            <CardBody>
                                    <Form >
                                        <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Privileges & Permissions
                                        </div>
                                        <div className = 'px-2 mb-10-rem'>
                                        <>
                                        {privilegesList != null && privilegesList.length > 0 ?
                                        privilegesList.map((list, k) => (
                                            <>
                                            <div className = 'row privileges-permission-box mt-2 px-2 '>
                                                <div className='col-12 col-md-7'>
                                                    {list.privilegeName}
                                                </div>
                                                <div className = 'col-12 col-md-5 d-flex' >
                                                <Form.Check
                                                    className=''
                                                    type='radio'
                                                    defaultChecked={handleNoRights(list)}
                                                    disabled = {props.selectedRole.is_Admin? false: props.fromView != null && props.fromView == true ? true : false}
                                                    name= {'rights' + [k]}
                                                    label= 'No Rights'
                                                    onChange={(e)=>{handleChange(e)}}
                                                    value = {0}
                                                    id = {list.privilegeID}
                                                />
                                                <Form.Check
                                                    className='mx-3'
                                                    type='radio'
                                                    defaultChecked={handleReadOnly(list)}
                                                    disabled = {props.selectedRole.is_Admin? false: props.fromView != null && props.fromView == true ? true : false}
                                                    name= {'rights' + [k]}
                                                    label= 'Read Only'
                                                    onChange={(e)=>{handleChange(e)}}
                                                    value={1}
                                                    id = {list.privilegeID}
                                                />
                                                <Form.Check
                                                    className='me-2'
                                                    type='radio'
                                                    defaultChecked={handleFullRights(list)}
                                                    disabled = {props.selectedRole.is_Admin? false: props.fromView != null && props.fromView == true ? true : false}
                                                    name= {'rights' + [k]}
                                                    label= 'Full Rights'
                                                    onChange={(e)=>{handleChange(e)}}
                                                    value={3}
                                                    id = {list.privilegeID}
                                                />
                                                </div>

                                            </div>
                                        
                                            </>
                                        ))
                                        :
                                        <div className = 'privileges-permission-box d-flex justify-content-between mt-2'>
                                            <div>
                                                No Privileges Available
                                            </div>
                                        </div>
                                        }
                                        </>
                                        </div>
                                    
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                    </div>
                </div>
            </>
        }
        </ModalBody>
            <ModalFooter className='ps-2 pe-2 trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                {props.fromView != null && props.fromView == true ? '' : <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSave} /></span>}
            </ModalFooter>
        </Modal>
    )
}

export default RoleCreationModal