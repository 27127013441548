
import React, { useEffect, useState } from 'react'
import {Row} from "react-bootstrap";
import ListData from './ListData';
import { AddNewButton } from '../../../../../../components/buttoncomponents';

function List(props) {
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [productList, setProductList] = useState([])
    useEffect(()=>{
            console.log(props, 'inside list contract')
            setIsPrimarySelected(false)
            props.data.map((d)=>{
                if(d.is_Default){
                    setIsPrimarySelected(true)
                    console.log(is_DefaultSelected,'inside list contract')
                }
            })
    },[])
    
    const addTraceAndQuality = (data) =>{
        console.log(data,'list')
        let prlist = [...productList];
        console.log(prlist)
        let pr_contains = prlist.filter(x => x.productID == data.productID)
        if(Object.keys(pr_contains).length == 0){
            prlist.push(data)
        }
        else{
            prlist.map((list,k)=>{
                if(list.productID == data.productID){
                    prlist[k] = data
                }
            })
        }
        setProductList(prlist)
        console.log(prlist)
        props.addTraceAndQuality(prlist)
    }

    return (
        <Row>
           
            <table className="table table-text customerList">
                <thead>
                    <tr className='bg-F8F9FA font-weight-500 color-707895'>
                        <th scope='col' className='font-weight-500 py-3 px-3'>Product Group</th>
                        <th scope='col' className='font-weight-500 py-3 px-3'>Product Code</th>
                        <th scope='col' className='font-weight-500 py-3'>Product Name</th>
                        <th scope='col' className='font-weight-500 py-3'>Product UOM</th>
                        <th scope='col'></th>
                    </tr>
                </thead>
                <tbody>
                        {props.isProduct &&
                            props.data.map((values)=>{
                               if(values.key !=false){
                                return(
                                    <ListData 
                                        values={values} 
                                        remove={props.remove} 
                                        addTraceAndQuality={addTraceAndQuality}
                                        isEdit={props.isEdit}
                                        edit={props.edit}
                                    />
                                )
                               }
                            })
                        }
                </tbody>
            </table>
        </Row>
    )
}

export default List