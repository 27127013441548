import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment"
import axios from 'axios'
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import * as CONFIG from '../../../helpers/config'
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMsal } from "@azure/msal-react";

function LoadingUpdates(props) {
    const { instance } = useMsal();
    const [corDocumentName, setCorDocumentName] = useState('')
    const [quantityInspectionAgencyDocumentName, setQuantityInspectionAgencyDocumentName] = useState('')
    const [qualityInspectionAgencyDocumentName, setQualityInspectionAgencyDocumentName] = useState('')
    const [customerData, setCustomerData] = useState([])
    const [billOfLadingDate, setBillOfLadingDate] = useState(false);
    const [error, setError] = useState(false)
    const MySwal = withReactContent(Swal);
    const location = useLocation()
    let navigate = useNavigate();
    const tradeData = props.tradeData != null ? props.tradeData : ''
    const tab = props.tab
    const updateLoadingUpdatesData = props.updateLoadingUpdatesData != null ? props.updateLoadingUpdatesData : ''
    useEffect(() => {
        console.log(updateLoadingUpdatesData)
        if (updateLoadingUpdatesData != '') {
            setCorDocumentName(updateLoadingUpdatesData.corDocumentName)
            setQuantityInspectionAgencyDocumentName(updateLoadingUpdatesData.quantityInspectionAgencyDocumentName)
            setQualityInspectionAgencyDocumentName(updateLoadingUpdatesData.qualityInspectionAgencyDocumentName)
        }
    }, [])

    useEffect(() => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeData.tradeID // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCustomerData(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const formik = useFormik({
        initialValues:
        {
            tradeID: tradeData.tradeID,
            billOfLadingNo: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.billOfLadingNo : '',
            billOfLadingDate: updateLoadingUpdatesData != '' ? Moment(updateLoadingUpdatesData.billOfLadingDate) : '',
            corDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.corDocument : "",
            corDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.corDocumentName : "",
            quantityInspectionAgencyDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.quantityInspectionAgencyDocument : "",
            quantityInspectionAgencyDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.quantityInspectionAgencyDocumentName : "",
            qualityInspectionAgencyDocument: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.qualityInspectionAgencyDocument : "",
            qualityInspectionAgencyDocumentName: updateLoadingUpdatesData != '' ? updateLoadingUpdatesData.qualityInspectionAgencyDocumentName : "",

        },
    })
    const handleCertificateOfOrigin = (file) => {
        formik.values.corDocument = file.base64;
        formik.values.corDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setCorDocumentName(file.name)
        console.log(file.name)
    }

    const handleQuantityInspectionAgencyDocument = (file) => {
        formik.values.quantityInspectionAgencyDocument = file.base64;
        formik.values.quantityInspectionAgencyDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setQuantityInspectionAgencyDocumentName(file.name)
        console.log(file.name)
    }

    const handleQualityInspectionAgencyDocument = (file) => {
        formik.values.qualityInspectionAgencyDocument = file.base64;
        formik.values.qualityInspectionAgencyDocumentName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setQualityInspectionAgencyDocumentName(file.name)
        console.log(file.name)
    }

    const handleBankSelect = () => {

    }

    const handleIssueDate = (date) => {
        console.log('click')
        setBillOfLadingDate(date)
        formik.values.billOfLadingDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
    }

    const handleSaveEvent = () => {
        if (updateLoadingUpdatesData != '') {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "billOfLadingNo": formik.values.billOfLadingNo,
                "billOfLadingDate": formik.values.billOfLadingDate,
                "corDocument": formik.values.corDocument,
                "corDocumentName": formik.values.corDocumentName,
                "quantityInspectionAgencyDocument": formik.values.quantityInspectionAgencyDocument,
                "quantityInspectionAgencyDocumentName": formik.values.quantityInspectionAgencyDocumentName,
                "qualityInspectionAgencyDocument": formik.values.qualityInspectionAgencyDocument,
                "qualityInspectionAgencyDocumentName": formik.values.qualityInspectionAgencyDocumentName,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "tradeLoadingUpdateID": updateLoadingUpdatesData.tradeLoadingUpdateID
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_LOADING_UPDATES, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Loading Details Updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                            rgba(60, 60, 60, 0.5)
                            no-repeat
                          `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            const json_list = {
                "tradeID": formik.values.tradeID,
                "billOfLadingNo": formik.values.billOfLadingNo,
                "billOfLadingDate": formik.values.billOfLadingDate,
                "corDocument": formik.values.corDocument,
                "corDocumentName": formik.values.corDocumentName,
                "quantityInspectionAgencyDocument": formik.values.quantityInspectionAgencyDocument,
                "quantityInspectionAgencyDocumentName": formik.values.quantityInspectionAgencyDocumentName,
                "qualityInspectionAgencyDocument": formik.values.qualityInspectionAgencyDocument,
                "qualityInspectionAgencyDocumentName": formik.values.qualityInspectionAgencyDocumentName,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
            }
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_LOADING_UPDATES, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode != '304000') {
                        MySwal.fire(
                            {
                                icon: 'error',
                                title: 'Something went wrong',
                                //type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                    else if (res.data.responseCode == '304000') {
                        props.refreshList()
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Loading Details Updated!',
                                type: 'success',
                                confirmButtonText: 'Ok',
                                confirmButtonClass: "btn btn-primary",
                                backdrop: `
                        rgba(60, 60, 60, 0.5)
                        no-repeat
                      `
                            }).then(() => {
                                back()
                                //redirecting 
                                //history.push("/login")
                            });
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='width-100'>
                <div className='d-flex mt-4' style={{ paddingLeft: '32px', }}>
                    {/* <div className = 'd-flex justify-content-center align-items-center '>
                            <ButtonComponent.BackButton clickEvent={back} />
                        </div> */}
                </div>
                <div className='d-flex inner-page-content justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Loading Updates</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        <Card className='bg-white'>
                            <Card.Body>
                                <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                    Shipment Details
                                </div>
                                <div className="m-3 p-4 grey-color">
                                    {tradeData != null && tradeData != '' ?
                                        <>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                {customerData.map((data, k) => {
                                                    return (
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-weight-600 color-243448'>{data.customer.customerCode}</div>
                                                            <div className='font-weight-400 color-697382'>{data.customer.customerName}</div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Load Port</div>
                                                    <div className='font-weight-600 color-243448'>{tradeData.tradedetails.loadPort}</div>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Destination Port</div>
                                                    <div className='font-weight-600 color-243448'>{tradeData.tradedetails.destinationPort}</div>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div className='font-weight-400 color-697382'>Mode of Transport</div>
                                                    <div className='font-weight-600 color-243448'>By Sea</div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                        :
                                        ""
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='bg-white my-3'>
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Final B/L
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <FormFields.InputField
                                            name='billOfLadingNo'
                                            label='Bill of Lading No.'
                                            classname='pt-1-rem'
                                            type='text'
                                            val={formik.values.billOfLadingNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}

                                        />
                                        <FormFields.DatePick
                                            label='B/L Date'
                                            name='B/L Date'
                                            classname='pt-1-rem ms-2 iconDatePicker'
                                            type='date'
                                            handleCompletionDate={handleIssueDate}
                                            isRequired={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            val={formik.values.billOfLadingDate}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Document Of Certificate Of Origin
                                    </div>
                                    <div>
                                        <ButtonComponent.UploadButton value='Document of COR' onUploadHandle={handleCertificateOfOrigin}
                                            filename={corDocumentName}
                                            document={formik.values.corDocument}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Document Of Quality Inspection Agency
                                    </div>
                                    <div>
                                        <ButtonComponent.UploadButton value='Quality Agency' onUploadHandle={handleQualityInspectionAgencyDocument}
                                            filename={qualityInspectionAgencyDocumentName}
                                            document={formik.values.quantityInspectionAgencyDocument}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                        Document Of Quantity Inspection Agency
                                    </div>
                                    <div>
                                        <ButtonComponent.UploadButton value='Quantity Agency' onUploadHandle={handleQuantityInspectionAgencyDocument}
                                            filename={quantityInspectionAgencyDocumentName}
                                            document={formik.values.qualityInspectionAgencyDocument}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card>
                            <CardBody>

                                <span className='pl-10'><ButtonComponent.BlueButton value='Save' disabled={error == true ? true : false} clickEvent={handleSaveEvent} /></span>
                            </CardBody>
                        </Card>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingUpdates