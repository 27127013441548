import React, { useState } from 'react'
import { BlueButton } from '../../components/buttoncomponents'
import { Button } from 'react-bootstrap';
import LinkMeetings from '../Meetings/components/LinkMeetings';

export default function InstitutionList(props) {
    const [ismeeting, setismeeting] = useState(false)

    const openMeeting = () => {
        setismeeting(!ismeeting)
    }
    return (
        <tr>
            {ismeeting &&
                <LinkMeetings data={props.data} tag='FinanceInstitute' toggle={openMeeting} />
            }
            <td>
            <div className = 'align-items-center d-flex py-4 cusname '> {props.data.institute.bankCode} </div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 cusname '> {props.data.institute.bank.bankName}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 font-14 '>  {props.data.institute.branchName}</div>
            </td>
            <td>
            <div className = 'align-items-center d-flex py-4 font-14 '>  {props.data.institute.country.countryName}</div>
            </td>
            <td>
                <Button variant='outline-secondary' onClick={openMeeting}>
                    <img src='assets/images/linkicon.svg' />
                </Button>
            </td>
            <td className='d-flex justify-content-center'>
            <div className = 'align-items-center d-flex py-2'> <BlueButton value='View Details' clickEvent={()=>{props.openInstitution(props.data)}}/></div>
            </td>
        </tr>
    )
}
