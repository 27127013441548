import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Button, Col } from "reactstrap"
import { Row, Form } from "react-bootstrap"
import { useState } from 'react'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as FormFields from "../../../components/formcomponents"

export default function LinkMeetings(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [issupplier, setissupplier] = useState(false)
    const [iscustomer, setiscustomer] = useState(false)
    const [iscustomerrequirement, setiscustomerrequirement] = useState(false)
    const [isfinanceinstitute, setisfinanceinstitute] = useState(false)
    const [istrade, setistrade] = useState(false)
    const [meetinglist, setmeetinglist] = useState([])
    const [linkmeetinglist, setlinkmeetinglist] = useState([])
    const MySwal = withReactContent(Swal)
    const [filterlist, setfilterlist] = useState([])


    useEffect(() => {
        if (props.tag == 'Supplier') {
            setissupplier(true)
        }
        else if (props.tag == 'Customer') {
            setiscustomer(true)
        }
        else if (props.tag == 'CustomerRequirement') {
            setiscustomerrequirement(true)
        }
        else if (props.tag == 'FinanceInstitute') {
            setisfinanceinstitute(true)
        }
        else {
            setistrade(true)
        }
        console.log(isfinanceinstitute, 'isfinanceinstitute')
        if (props.tag == 'FinanceInstitute') {
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "roleID": parseInt(localStorage.getItem('LogInUserRoleID'))
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_FOR_FINANCE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setmeetinglist(res.data.result.filter(x => x.meetingLink == null))
                    setfilterlist(res.data.result.filter(x => x.meetingLink == null))

                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                    console.log(error)
                })
        }
        else {
            console.log(isfinanceinstitute, 'isfinanceinstitute')
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "roleID": parseInt(localStorage.getItem('LogInUserRoleID'))
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETINGS_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setmeetinglist(res.data.result.filter(x => x.meetingLink == null && x.meetingCategory != "Financial Institution"))
                    setfilterlist(res.data.result.filter(x => x.meetingLink == null && x.meetingCategory != "Financial Institution"))


                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                    console.log(error)
                })
        }


    }, [])

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }

    const handleClick = (e) => {
        let status = e.target.checked
        let id = parseInt(e.target.id)
        let links = [...linkmeetinglist]
        if (status) {
            links.push(id)
        }
        else {
            links = links.filter(x => x != id)
        }
        console.log(links)
        setlinkmeetinglist(links)
    }

    const saveLinks = () => {
        linkmeetinglist.map((link, k) => {
            let json_list = {}
            if (props.tag == 'Supplier') {
                json_list = {
                    "meetingID": link,
                    "tag": props.tag,
                    "referenceID": props.data.supplierID,
                    "description": props.data.suppliercode,
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
            }
            else if (props.tag == 'Customer') {
                json_list = {
                    "meetingID": link,
                    "tag": props.tag,
                    "referenceID": props.data.custid,
                    "description": props.data.customercode,
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
            }
            else if (props.tag == 'CustomerRequirement') {
                json_list = {
                    "meetingID": link,
                    "tag": props.tag,
                    "referenceID": props.data.enqiryid,
                    "description": props.data.enquiryno,
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
            }
            else if (props.tag == 'FinanceInstitute') {
                console.log(props.data, 'FinanceInstitute data')
                console.log(props.data.institute.financialInstituteID, 'financialInstituteID')
                console.log(props.data.institute.bankCode, 'bankCode')
                json_list = {
                    "meetingID": link,
                    "tag": props.tag,
                    "referenceID": props.data.institute.financialInstituteID,
                    "description": props.data.institute.bankCode,
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
            }
            else {
                json_list = {
                    "meetingID": link,
                    "tag": props.tag,
                    "referenceID": props.data.tradeID,
                    "description": props.data.tradeNo,
                    "instanceID": CONFIG.PMSInstanceID,
                    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                    "created_User": CONFIG.LoginID
                }
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_LINK, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Meeting linked successfully!',
                            html: '',
                            type: 'success',
                            confirmButtonText: 'Close',
                            confirmButtonClass: "btn btn-primary",

                        }).then(() => {
                            toggle()
                        });
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        })
    }

    const handleSearch = (e) => {
        console.log(e.currentTarget.value, 'e.currentTarget.value')
        let val = e.currentTarget.value
        //let filter = [...meetinglist]
        console.log(meetinglist, 'meetinglist')
        let filter = meetinglist.filter(x => x.meetingCode.toLowerCase().includes(val.toLowerCase()) ||
            x.meetingTitle.toLowerCase().includes(val.toLowerCase()) ||
            x.meetingCategory.toLowerCase().includes(val.toLowerCase())
        )
        setfilterlist(filter)

    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
            <ModalBody className='service-request-modal-wrapper scrollContract' style={{ padding: '0' }}>
                {issupplier &&
                    <Row style={{ margin: '2rem' }}>
                        <div class="table-responsive-sm  card card-body">
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <table className="table tCustom table-text mt-1-rem">
                                    <thead >
                                        <tr>
                                            <th>Supplier Code</th>
                                            <th>Supplier Name</th>
                                            <th>Address</th>
                                            <th>Category</th>
                                            <th>Incoterms</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.supplierstatus == 0 ? { color: '#A7B3C3' } : { color: '#4F5466' }}>
                                            <td className='align-items-center d-flex py-4 px-2'>
                                                <center className={props.data.supplierstatus == 0 ? 'disabled_badge' : 'status_badge'}>{props.data.suppliercode} </center>
                                                {props.data.supplierstatus == 0 ? <img className='ms-2 badgesize' src='assets/images/supports/disablePill.svg' /> : ''}
                                            </td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusname '>{props.data.suppliername}</div>
                                            </td>

                                            <td>
                                                <div className='align-items-center d-flex py-4 w-50 cusaddress '>{props.data.address}</div></td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusweight '>{props.data.category}</div></td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusweight '>{props.data.incoterms}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                }
                {iscustomer &&
                    <Row style={{ margin: '2rem' }}>
                        <div class="table-responsive-sm  card card-body">
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <table className="table tCustom table-text mt-1-rem">
                                    <thead >
                                        <tr>
                                            <th className=''>Customer Code</th>
                                            <th className=''>Customer Name</th>
                                            <th className=''>Address</th>
                                            <th className=''>Category</th>
                                            <th className=''>Incoterms</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.supplierstatus == 0 ? { color: '#A7B3C3' } : { color: '#4F5466' }}>
                                            <td className='align-items-center d-flex py-4 px-2'>
                                                <center className={props.data.custstatus == 0 ? 'disabled_badge' : 'status_badge'}>{props.data.customercode} </center>
                                                {props.data.custstatus == 0 ? <img className='ms-2 badgesize' src='assets/images/supports/disablePill.svg' /> : ''}
                                            </td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusname '>{props.data.customername}</div>
                                            </td>

                                            <td>
                                                <div className='align-items-center d-flex py-4 w-50 cusaddress '>{props.data.address}</div></td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusweight '>{props.data.category}</div></td>
                                            <td>
                                                <div className='align-items-center d-flex py-4 cusweight '>{props.data.incoterms}</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                }
                {iscustomerrequirement &&
                    <Row style={{ margin: '2rem' }}>
                        <div class="table-responsive-sm  card card-body">
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <table className="table tCustom table-text mt-1-rem">
                                    <thead >
                                        <tr>
                                            <th>Requirement No</th>
                                            <th>Date</th>
                                            <th>Customer</th>
                                            <th>Products</th>
                                            <th>Type Of Contract</th>
                                            <th>Trade Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.supplierstatus == 0 ? { color: '#A7B3C3' } : { color: '#4F5466' }}>
                                            <td className="align-items-center">
                                                <div className="align-items-center d-flex table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.enquiryno}{" "}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-500">
                                                    {" "}
                                                    {Moment(props.data.enquirydate).format("DD/MM/YYYY")}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.customercode}
                                                    <br />
                                                    <span className="table-ttile-color-sub font-12 font-weight-400">{props.data.customername}</span>
                                                </div>
                                            </td>
                                            <td>
                                                {props.data.productdetails.map((list) => {
                                                    return (
                                                        <>
                                                            <div className="table-ttile-color font-14 font-weight-600">
                                                                {" "}
                                                                {list.productCode}&nbsp;{list.productName}
                                                                <span className="table-ttile-color-sub font-12 font-weight-400">
                                                                    &nbsp;&nbsp;{list.productQuantity}&nbsp;{" "}
                                                                    {list.productUOMName}
                                                                </span>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.contypewithincoterms}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    <div className="table-ttile-color font-14 font-weight-600">
                                                        {props.data.tradeSupply}
                                                    </div>
                                                    <div className="table-ttile-color font-13 font-weight-500">
                                                        {" "}
                                                        {props.data.scheduledSupply}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                }

                {isfinanceinstitute &&
                    <Row style={{ margin: '2rem' }}>
                        <div class="table-responsive-sm  card card-body">
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <table className="table tCustom table-text mt-1-rem">
                                    <thead >
                                        <tr>
                                            <th>Bank Code</th>
                                            <th>Institution Name</th>
                                            <th>Branch</th>
                                            <th>Country</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.supplierstatus == 0 ? { color: '#A7B3C3' } : { color: '#4F5466' }}>
                                            <td className="align-items-center">
                                                <div className="align-items-center d-flex table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.institute.bankCode}{" "}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-500">
                                                    {" "}
                                                    {props.data.institute.bank.bankName}{" "}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.institute.branchName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-ttile-color font-14 font-weight-600">
                                                    {" "}
                                                    {props.data.institute.country.countryName}
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                }

                {istrade &&
                    <Row style={{ margin: '0rem' }}>
                        <div class="table-responsive-sm  card card-body">
                            <div className='bg-white p-0 p-md-4  br-8'>
                                <table className="table tCustom table-text mt-1-rem">
                                    <thead >
                                        <tr>
                                            <th><p className='w-200 mb-0'>TRADE#</p></th>
                                            <th><p className='w-200 mb-0'>COMPANY & DIVISION</p> </th>
                                            <th><p className='w-200 mb-0'>CUSTOMER & SUPPLIER</p></th>
                                            <th className='px-3'><p className='w-200 mb-0'>PRODUCT & CONTRACT DETIALS</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.supplierstatus == 0 ? { color: '#A7B3C3' } : { color: '#4F5466' }}>
                                            <td>
                                                <div className='pt-2'>
                                                    <h4 className='tradeTrsactionsHeading'>{props.data.tradeNo}</h4>
                                                    <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'> {Moment(props.data.tradeinitiatdate).format("DD/MM/yyyy")} <br /> {props.data.vesselName}</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='pt-2'>
                                                    <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{props.data.companyname} <span className='mx-1 dotSep'>&#8226;</span> {props.data.divisionname} </h4>
                                                </div>
                                            </td>

                                            <td className='w-16'>
                                                <div className='d-flex flex-column pt-2'>
                                                    <div className='pb-2'>
                                                        <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                            {props.data.compcustdetails.map((pt, k) => {
                                                                return (
                                                                    <>
                                                                        <div className='pb-2'>
                                                                            <div className='align-items-center '>
                                                                                <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                                                <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                                            </div>
                                                                            <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                                            </h4></div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </h4></div>
                                                    </div>

                                                    <div className='py-2'>
                                                        <div className='align-items-center '>
                                                            <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.data.supplierName}</h5>
                                                            <span className='tradeTrsactionsid statBg'>{props.data.supplierCode}</span>
                                                        </div>
                                                        <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                            {props.data.supplierpaymentmethods.map((pt, k) => {
                                                                return (
                                                                    <>
                                                                        {pt} <span className='mx-1 dotSep'>&#8226;</span>
                                                                    </>
                                                                )
                                                            })}
                                                        </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='w-28'>
                                                <div className=' pt-2'>
                                                    {props.data.tradeProductDetails.map((list, k) => {
                                                        return (
                                                            <>
                                                                <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                                                    {" "}
                                                                    {list.productName}
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                                                        {list.quantity}&nbsp;{" "}
                                                                        {list.productUOMName} <span className='mx-1 dotSep'>&#8226;</span>
                                                                        {props.data.contracttype} <span className='mx-1 dotSep'>&#8226;</span>
                                                                        {props.data.incotermsName} <span className='mx-1 dotSep'>&#8226;</span>
                                                                        {props.data.modeOfTransportName}
                                                                    </div>

                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                                <div className='d-flex py-2'>
                                                    <div>
                                                        <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                            Load Port
                                                        </div>
                                                        <div className='tradeTrsactionsHeading '>
                                                            {props.data.loadPort}, {props.data.lordPortCountry}
                                                        </div>
                                                    </div>
                                                    <div className='ml-20'>
                                                        <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                            Destination Port
                                                        </div>
                                                        <div className='tradeTrsactionsHeading'>
                                                            {props.data.destinationPort}, {props.data.destinationPortcountry}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                }
                <div>
                    <div className='inner-page-content'>
                        <div className='bg br-8 shadowMeetings'>
                            <Col>
                                <FormFields.InputField
                                    md={4}
                                    label='Search'
                                    onChange={handleSearch}
                                />
                            </Col>
                            <div class="col-lg-12">
                                <div class="table-responsive-sm  card card-body ">
                                    <table className="table tableMeeting table-text mt-1-rem">
                                        <thead >
                                            <tr>
                                                <th>MEETING ID</th>
                                                <th>TITLE OF MEETING</th>
                                                <th>MEETING CATEGORY</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterlist.map((list, k) => {
                                                return (
                                                    <tr>
                                                        <td className='meeting_list_bold'>{list.meetingCode}</td>
                                                        <td className='meeting_list_normal'>{list.meetingTitle}</td>
                                                        <td className='meeting_list_bold'>{list.meetingCategory}</td>
                                                        <td>
                                                            <Form.Check
                                                                name='check'
                                                                id={list.meetingID}
                                                                onChange={handleClick}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueButton value='Submit' clickEvent={saveLinks} /></span>
            </ModalFooter>
        </Modal>
    )
}
