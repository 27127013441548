import React,{useEffect, useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, BlueBorderButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";
import { ContactTypeList, ContactTypeListSupplier } from '../../../../../../helpers/API/Api';
import Moment from 'moment';

function FormComponent(props) {
    const [contactTypeError, setContactTypeError] = useState(false);
    const [isError, setIsError] = useState(true);
    useEffect(()=>{

    },[props])
    console.log(props.data)
    const addDirector = ()=>{
 
            const director_list ={
                "fullName": formik.values.fullName,
                "designation": formik.values.designation,
                "email": formik.values.email,
                "mobileNo": formik.values.mobileNo,
                "telephoneNo": formik.values.telephoneNo,
                "contactTypeID": parseInt(formik.values.contactTypeID),
                "contactValue" : formik.values.contactValue,
                "is_Default" : false,
            }
            console.log(director_list, 'sds')
            console.log(props)
            props.addDirectorToList(director_list)
        
       
    }

    const formik = useFormik({
        initialValues:
        !props.isEdit?
        {
            fullName: '',
            designation: '',
            email: '',
            mobileNo: '',
            telephoneNo: '',
            contactTypeID: 0,
            contactValue: ''
        }
        :
        {
            fullName: props.data.fullName,
            designation: props.data.designation,
            email: props.data.email,
            mobileNo: props.data.mobileNo,
            telephoneNo: props.data.telephoneNo,
            contactTypeID: props.data.contactTypeID,
            contactValue: props.data.contactValue
        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;
  
            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;

        
            if (!values.fullName) {
                errors.fullName = "Please enter Contact Name"
                
            }else if (!letters.test(values.fullName)) {
                errors.fullName = "Please enter a valid  Name"
            }
            if(!letters.test(values.designation) && values.designation){
                errors.designation = "Please enter valid Designation"
            }
            if (!phone.test(values.telephoneNo) && values.telephoneNo){
                errors.telephoneNo = 'Please enter a valid Telephone Number'
            }
            if (!phone.test(values.mobileNo) && values.mobileNo){
                errors.mobileNo = 'Please enter a valid Mobile Number'
            }
          
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email) {
                errors.email = 'Please enter a valid Email Address';
            }
            setIsError(true)
            sendBack();
            return errors;
        }
    })

    const handleContactType = (e)=>{
        formik.values.contactTypeID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.contactValue = e.nativeEvent.target[index].text;
        if(formik.values.contactTypeID == 0){
            setContactTypeError(true)
            setIsError(true)
            props.setErrors()
        }
        else if(Object.keys(formik.errors).length != 0){
            setContactTypeError(true)
            setIsError(true)
            props.setErrors()
        }
        else{
            setContactTypeError(false)
            setIsError(false)
           
        }
    }


    const editBank = ()=>{
        const director_list ={
            "fullName": formik.values.fullName,
            "designation": formik.values.designation,
            "email": formik.values.email,
            "mobileNo": formik.values.mobileNo,
            "telephoneNo": formik.values.telephoneNo,
            "contactTypeID": parseInt(formik.values.contactTypeID),
            "contactValue" : formik.values.contactValue,
            "is_Default" : props.data.is_Default,
            "key" :props.data.key
        }

        console.log(director_list)
        props.editDirectorToList(director_list)
    }
    const sendBack = () => {

        if ((Object.keys(formik.errors).length == 0) ) {
            if(isError == true){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                props.setErrors()
            }
            console.log('error checking')
            if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
                console.log(formik.errors)
                console.log('no error first condition')
                setIsError(false)
                props.handleError()    
            }
            else if(formik.initialValues == formik.values){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                props.setErrors()
            }
            else if(formik.values.contactTypeID == 0){
                console.log(formik.errors)
                console.log('error')
                setIsError(true)
                props.setErrors()
            }
            else{
                setIsError(false)
                console.log(formik.errors)
                console.log('no error first condition')
                //props.handleError()    
            }
              
        }
        else{
            
            console.log(formik.errors)
            console.log('error')
            setIsError(true)
            props.setErrors()
        }
    }
    console.log(formik.errors)
  return (
    <>
            <Form>
                <Row>
                <FormFields.InputField
                        md={3}
                        name='fullName'
                        label='Full Name*'
                        classname='mt-1-rem'
                        type='text'
                        val={formik.values.fullName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.fullName?formik.errors.fullName:''}
                    />
                    <FormFields.InputField
                         md={3}
                         name='designation'
                         label='Designation'
                         classname='mt-1-rem'
                         type='text'
                         val={formik.values.designation}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         errors= {formik.touched.designation?formik.errors.designation:''}
                         
                    />
                    <FormFields.InputField
                        md={3}
                        name='email'
                        label='Email'
                        classname='mt-1-rem'
                        type='text'
                        val={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.email?formik.errors.email:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='mobileNo'
                        label='Mobile No'
                        classname='mt-1-rem'
                        type='text'
                        val={formik.values.mobileNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.mobileNo?formik.errors.mobileNo:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='telephoneNo'
                        label='Telephone No'
                        classname='mt-1-rem'
                        type='text'
                        val={formik.values.telephoneNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.telephoneNo?formik.errors.telephoneNo:''}
                    />
                    <FormFields.InputSelect
                        md={3}
                        name='contactTypeID'
                        label='Contact Type*'
                        classname='mt-1-rem'
                        type='text'
                        val={formik.values.contactTypeID}
                        optionField={props.contactTypeID != '' ? 
                            <ContactTypeList 
                                contactTypeID={formik.values.contactTypeID} 
                            /> 
                            :
                            <ContactTypeList 
                            />}
                        onChange={handleContactType}
                        onBlur={handleContactType}
                        errors= {contactTypeError?'Please select a value':''}
                        
                    />

                    <Col md={3} className='d-flex align-items-center Bt-disable mt-1-rem'>
                        <BlueButton value={props.isEdit?'Edit Contact':'Add Contact'} clickEvent={props.isEdit?editBank:addDirector} disabled = {isError}/>
                        <div className = 'ms-4'>
                            <BlueBorderButton value={'Cancel'} clickEvent={props.handleCancel}/>
                        </div>
                    </Col>
                   
                </Row>
            </Form>

    </>

  )
}

export default FormComponent