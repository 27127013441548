import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import * as CONFIG from "../../../../helpers/config"
import { useFormik } from 'formik'
import axios from 'axios'
import {Row, Col, Form, Card} from "react-bootstrap"
import * as FormFields from "../../../../components/formcomponents"
import Moment from "moment";
import { useMsal } from "@azure/msal-react";

export default function Completionloading(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(props.view)
    const [doc1, setdoc1] = useState(false)
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }
    const formik = useFormik({
        initialValues:{
            tradeShipmentLoadingCheckListID : 0,
            is_CoverCargoInsurance: false,
            is_SailingCustomer: false,
            is_ObtainFinalBL: false,
            obtainFinalBLDate: '',
            obtainFinalBLNo: '',
            is_FaxBLCustomer: false,
            is_ClearancePurposes: false,
            is_LayTimeShipper: false,
            layTimeShipperDocument: '',
            layTimeShipperDocumentName: '',
            is_CustomerWithInvoice: false,
            customerWithInvoiceDocument: '',
            customerWithInvoiceDocumentName: '',
            is_ArrangePaymentToSupplier: false,
            is_ObtainOriginalClearancePurposes: false,
            obtainOriginalClearancePurposesDocument: '',
            obtainOriginalClearancePurposesDocumentName: '',
            is_ETAofVesselOfCustomer: false,
            is_AttendedInvoice: false,
            attendedInvoiceDocument: '',
            attendedInvoiceDocumentName: '',
            is_ForwordDocumentRequired: false,
            forwordDocumentRequiredDocument: '',
            forwordDocumentRequiredDocumentName: '',
            is_IssueForReleaseOfCargo: false,
            issueForReleaseOfCargoDocument: '',
            issueForReleaseOfCargoDocumentName: '',
            is_ArrangmentsForReleaseOfDelivery: false,
            arrangmentsForReleaseOfDeliveryDocument: '',
            arrangmentsForReleaseOfDeliveryDocumentName: '',
            is_MonitorDischargeOfVessel: false,
            monitorDischargeOfVesselDocument: '',
            monitorDischargeOfVesselDocumentName: '',
            is_FollowUpOfPayment: false,
            is_PrepareCustomerLaytime: false,
            prepareCustomerLaytimeDocument: '',
            prepareCustomerLaytimeDocumentName: '',
            is_BillOfLading: false,
            billOfLadingNo: '',
            billOfLadingDate: '',
            billOfLadingDocument: '',
            billOfLadingDocumentName: '',
        }
    })
    useEffect(()=>{
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.tradeID // TradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_SHIPMENT_LOADING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            if(res.data.result.length>0){
                Object.assign(formik.values, res.data.result[0])
                setdoc1(!doc1)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    },[])
    return (
        <>
            <div className='d-flex justify-content-between'>
                            <div className='p-4 mx-3 mt-4'>
                               <h4 className='font-16 font-weight-600 color-707895'>COMPLETION OF LOADING CHECKLIST</h4> 
                            </div>
                        </div>

                        <div className='add-edit-wrapper'>
                            <div className='card card-body p-4 br-8 mb-4 scroll-check'>
                                
                            <Row className='border-bottom-1  py-4  d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_CoverCargoInsurance'
                                        label='FOB/CFR Sale - Cover Cargo Insurance.'
                                        checked={formik.values.is_CoverCargoInsurance}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_SailingCustomer'
                                        label='Sailing Notification To Customer.'
                                        checked={formik.values.is_SailingCustomer}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className=' font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ObtainFinalBL'
                                        label='Obtain Final B/L.'
                                        checked={formik.values.is_ObtainFinalBL}
                                    />
                                </Col>
                                <Col md={3}>
                                    {/* <span className='font-weight-600'>{Moment(formik.values.obtainFinalBLDate).format("DD-MM-YYYY")}</span> */}
                                </Col>
                                <Col md={3}>
                                    <span className='font-weight-600'>{formik.values.obtainFinalBLNo}</span>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_FaxBLCustomer'
                                        label='FAX B/L To Customers & Request Them To Cover Insurance If Sale is C&F. If L/C Received From Customer, Advice To Be As Per L/C. '
                                        checked={formik.values.is_FaxBLCustomer}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ClearancePurposes'
                                        label='Documents required for clearance purposes.'
                                        checked={formik.values.is_ClearancePurposes}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_LayTimeShipper'
                                        label='Prepare Laytime A/C Shippers If Required'
                                        checked={formik.values.is_LayTimeShipper}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.layTimeShipperDocumentName} href={formik.values.layTimeShipperDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_CustomerWithInvoice'
                                        label='Obtain Certs of QLTY, QTY, ORIGIN & Other required documents by FAX & SEND to Customers with invoice.'
                                        checked={formik.values.is_CustomerWithInvoice}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.customerWithInvoiceDocumentName} href={formik.values.customerWithInvoiceDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ArrangePaymentToSupplier'
                                        label='Arrange payment to Suppliers'
                                        checked={formik.values.is_ArrangePaymentToSupplier}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            {/* <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_CustomerWithInvoice'
                                        label='Obtain Certs of QLTY, QTY, ORIGIN & Other required documents by FAX & SEND to Customers with invoice.'
                                        checked={formik.values.is_CustomerWithInvoice}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.customerWithInvoiceDocumentName} href={formik.values.customerWithInvoiceDocument}>View Attachment</a>
                                </Col>
                            </Row> */}
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ObtainOriginalClearancePurposes'
                                        label='Obtain original QUALITY, QTY, ORIGIN, ETC & Courier to customers with invoice if required for clearnce purposes.'
                                        checked={formik.values.is_ObtainOriginalClearancePurposes}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.obtainOriginalClearancePurposesDocumentName} href={formik.values.obtainOriginalClearancePurposesDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ETAofVesselOfCustomer'
                                        label='Inform customer of ETA of vessel'
                                        checked={formik.values.is_ETAofVesselOfCustomer}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_AttendedInvoice'
                                        label='Attested Invoice & Original documentation for clearance purposes well before arrival of vessal at discharge port. '
                                        checked={formik.values.is_AttendedInvoice}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.attendedInvoiceDocumentName} href={formik.values.attendedInvoiceDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ForwordDocumentRequired'
                                        label='Forward documents required for clearance.'
                                        checked={formik.values.is_ForwordDocumentRequired}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.forwordDocumentRequiredDocumentName} href={formik.values.forwordDocumentRequiredDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_IssueForReleaseOfCargo'
                                        label='Issue LOI for release of cargo'
                                        checked={formik.values.is_IssueForReleaseOfCargo}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.issueForReleaseOfCargoDocumentName} href={formik.values.issueForReleaseOfCargoDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_ArrangmentsForReleaseOfDelivery'
                                        label='In case cargo will be discharged against LOI, ensure that shipping DIV makes necessary arrangements for release of delivery order by the discharge port agent and keep customer informed.'
                                        checked={formik.values.is_ArrangmentsForReleaseOfDelivery}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.arrangmentsForReleaseOfDeliveryDocumentName} href={formik.values.arrangmentsForReleaseOfDeliveryDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            {/* <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_MonitorDischargeOfVessel'
                                        label='Monitor discharge of Vessel.'
                                        checked={formik.values.is_MonitorDischargeOfVessel}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.monitorDischargeOfVesselDocumentName} href={formik.values.monitorDischargeOfVesselDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_FollowUpOfPayment'
                                        label='Follow up on payment from Customer.'
                                        checked={formik.values.is_FollowUpOfPayment}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                            </Row> */}
                            <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6}>
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_PrepareCustomerLaytime'
                                        label="Prepare Customer's laytime statements if applicable."
                                        checked={formik.values.is_PrepareCustomerLaytime}
                                    />
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.prepareCustomerLaytimeDocumentName} href={formik.values.prepareCustomerLaytimeDocument}>View Attachment</a>
                                </Col>
                            </Row>
                            {/* <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                                <Col md={6} >
                                    <Form.Check
                                        className='font-15 font-weight-400 color-2C2C2E'
                                        type='checkbox'
                                        name='is_BillOfLading'
                                        label="Bill of Lading."
                                        checked={formik.values.is_BillOfLading}
                                    />
                                </Col>
                                <Col md={3}>
                                <span className='font-weight-600'>{Moment(formik.values.billOfLadingDate).format("DD-MM-YYYY")}</span>,&nbsp;&nbsp;&nbsp;
                                <span className='font-weight-600'>{formik.values.billOfLadingNo}</span>
                                </Col>
                                <Col md={3}>
                                    <a download={formik.values.billOfLadingDocumentName} href={formik.values.billOfLadingDocument}>View Attachment</a>
                                </Col>
                            </Row> */}

                            </div>
                        </div>

                            
        </>
    )
}
