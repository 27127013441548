import React, { useEffect, useState } from 'react'
//import Sidebar from '../../components/sidebar/sidebar'
//import Navbar from "../../components/navbar"
import axios from 'axios'
import * as CONFIG from "../../helpers/config"
import { Row, Col, Card } from 'react-bootstrap'
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import { useFormik } from 'formik'
import * as FormFields from "../../components/formcomponents"
import FinalCostSubmit from './finalcostsubmit'

export default function Index(props) {
    const [isFirst, setIsFirst] = useState(true)
    const [isView, setIsView] = useState(false);
    const [isSecondStep, setisSecondStep] = useState(false);
    const [tradeid, settradeid] = useState(); // replace 1 by props.id
    const [tradedata, settradedata] = useState([]); // replace 1 by props.id
    const [currentcostdata, setcurrentcostdata] = useState(); // replace 1 by props.id
    const [buyingprice, setbuyingprice] = useState(0); // replace 1 by props.id
    
    useEffect(() => {
        //props.id = 1; // get id from props (tradeid)
        console.log(props.tradeDataForCostSheet.data.tradeID, 'trading id');
        settradeid(props.tradeDataForCostSheet.data.tradeID)
        console.log(props)
        getTradeData();
    },[])
    console.log('')
    const formik = useFormik({
        initialValues:
        {
            BuyingPrice: '',
            OceanFreight: '',
            AddressCommission: '',
            FinancePrice: '',
            InsuranceCostCargo: '',
            InsuranceCostCL: '',
            InsuranceCostOthers: '',

        },
        validate: values => {
            let errors = {};

            //sendBack();
            return errors;
        }
    })

    const setedit = () => {
        setisSecondStep(false)
    }

    const setSecondStep = () => {
        setisSecondStep(true)
        console.log(tradeid);
    //    getTradeData();
    }

    const getTradeData = () => {
        console.log('inside', props.tradeDataForCostSheet.data.tradeID)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.tradeDataForCostSheet.data.tradeID)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                settradedata(res.data.result);

                let total1 = 0
                res.data.result.map((list, k) => {
                    list.tradedetails.tradeProductDetail.map((pro, p) => {
                        total1 = total1 + (pro.productBuyingPrice * pro.quantity)
                    })
                })
                console.log(total1, 'buying price');
                formik.values.BuyingPrice = total1;
                console.log(formik.values.BuyingPrice, 'formik.values.BuyingPrice')
                setbuyingprice(total1);
            })
    }
    return (
        <div className='main-wrapper inner-wrapper'>

<div className='inner-page-header justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/pageicons/productsicon.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>ESTIMATED COST SHEET</span>
                    </div>
                </div>
                <Row>
                    <Col md={12} className='mx-4'>
                       
                    <Card>
                                <Card.Body>
                                    {isSecondStep ?
                                        <FinalCostSubmit tradingdata={tradedata} sendFormikData={formik.values} setedit={setedit} id={tradeid} toggleCost={props.toggleCost}/>
                                        :
                                        <div>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='font-16 font-weight-400 color-343A40'>Pre-Shipment Estimated Cost</div>
                                            </div>
                                            <Row>
                                                <FormFields.InputField
                                                    md={3}
                                                    name='BuyingPrice'
                                                    label='Buying Price(FOB)*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.BuyingPrice}
                                                    errors={formik.touched.BuyingPrice ? formik.errors.BuyingPrice : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='OceanFreight'
                                                    label='Ocean Freight*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.OceanFreight}
                                                    errors={formik.touched.OceanFreight ? formik.errors.OceanFreight : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='AddressCommission'
                                                    label='Address Commission*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.AddressCommission}
                                                    errors={formik.touched.AddressCommission ? formik.errors.AddressCommission : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='FinancePrice'
                                                    label='Finance Price*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.FinancePrice}
                                                    errors={formik.touched.FinancePrice ? formik.errors.FinancePrice : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='InsuranceCostCargo'
                                                    label='Insurance Cost(Cargo)*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.InsuranceCostCargo}
                                                    errors={formik.touched.InsuranceCostCargo ? formik.errors.InsuranceCostCargo : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='InsuranceCostCL'
                                                    label='Insurance Cost(CL)*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.InsuranceCostCL}
                                                    errors={formik.touched.InsuranceCostCL ? formik.errors.InsuranceCostCL : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <FormFields.InputField
                                                    md={3}
                                                    name='InsuranceCostOthers'
                                                    label='Insurance Cost(Others)*'
                                                    classname='pt-1-rem'
                                                    type='text'
                                                    val={formik.values.InsuranceCostOthers}
                                                    errors={formik.touched.InsuranceCostOthers ? formik.errors.InsuranceCostOthers : ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </Row>
                                            <Row>
                                                <div className='form-card-footer p-2-rem d-flex' style={{ position: 'relative' }}>
                                                    <span><BlueBorderButton value='Cancel' /></span>
                                                    <span className='pl-10'><BlueButton value='Save' clickEvent={setSecondStep} /></span>
                                                </div>
                                            </Row>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                            {/*<Card.Footer className='form-card-footer p-2-rem d-flex' style={{position : 'relative'}}>*/}
                            {/*    <span><BlueBorderButton value='Cancel' /></span>*/}
                            {/*    <span className='pl-10'><BlueButton value='Save' clickEvent={setSecondStep} /></span>*/}
                            {/*</Card.Footer>*/}
                       
                    </Col>
                </Row>

        </div>
    )
}
