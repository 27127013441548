import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Button } from "reactstrap"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as CONFIG from "../../../helpers/config"
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Moment from "moment"
import ViewMeetingInfo from './ViewMeetingInfo';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function ViewMeeting(props) {
    const MySwal = withReactContent(Swal)
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [linkedmeetings, setlinked] = useState([])
    const [meeting, setmeeting] = useState([])
    const [meetingavail, setmeetingavail] = useState(false)
    const [index, setindex] = useState(0)
    useEffect(() => {
        getAssociateMeetings(props.meetingid)
    }, [])
    useEffect(() => {
        console.log(meeting)
        console.log(meetingavail)
    }, [meeting, meetingavail])
    const getAssociateMeetings = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_LINKS_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setlinked(res.data.result)
                if (res.data.result == null) {
                    console.log('inside')
                    getMeetingById()
                }
                else {
                    let links = [...res.data.result]
                    let selected = res.data.result.filter(x => x.meetingID == props.meetingid)[0]
                    let linked_z = links[0]
                    res.data.result.map((re, k) => {
                        if (re.meetingID == props.meetingid) {
                            links[0] = selected
                            links[k] = linked_z
                        }
                    })
                    setlinked(links)
                }
            }).catch((error) => {
                if (error) {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                }
            })
    }

    useEffect(() => {
        console.log(index)
    }, [index])

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }

    const getMeetingById = () => {
        console.log('inside meeting by id')
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.meetingid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setmeeting(res.data.result[0])
                console.log(res.data.result[0])
                setmeetingavail(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const unlinkMeeting = (e) => {

        MySwal.fire(
            {
                icon: 'warning',
                html: 'Are you sure you wish to unlink the meeting?',
                type: 'success',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    const id = parseInt(e.target.id)
                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "id": id
                    }
                    axios.post(CONFIG.PCS_SERVER_URL + CONFIG.REMOVE_MEETING_LINK, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            console.log(res.data.result)
                            toggle()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                }
            })
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='trade_list_modal'>
            <ModalHeader toggle={toggle}>

            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper' style={{ padding: '0' }}>
                <div className='d-flex justify-content-between '>
                    <Tabs className='trade_process_tabs w-100' defaultIndex={1}>
                        <Row>
                            <Col md={2} className='col-12 scroll-over scrollTradeTrasnaction width-sm' style={{ width: '20%', borderRight: '1px solid #EFEEF1' }}>

                                <TabList style={{ background: '#FDFAFE' }}>
                                    <div className='tabTransaction'>
                                        {linkedmeetings != null ?
                                            linkedmeetings.map((link, k) => {
                                                return (
                                                    <Tab className='tab-width'>
                                                        <h6 >{link.meetingTitle}</h6>

                                                        <div className='d-flex justify-content-between'>
                                                            <span className='meetingdate_view'>{Moment(link.meetingTime).format('DD/MM/YYYY')}</span>
                                                            {link.is_meeting == "Tour" ?
                                                                <span className='meetingtype_view'> Tour</span>
                                                                :
                                                                <span className='meetingtype_view'>{link.meetingType} Meeting</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-1-rem'>
                                                            <div>
                                                                <div>Ref: {link.meetingDescription}</div>
                                                                <div>Tag: {link.tag}</div>
                                                            </div>
                                                            <Button variant="outline-secondary" size="sm" className={'font-weight-500 font-14 rounded-13'} type="button" id={link.meetingLinkId} onClick={unlinkMeeting}>
                                                                Unlink
                                                            </Button>
                                                        </div>
                                                    </Tab>
                                                )
                                            })
                                            :
                                            <>
                                                {meetingavail &&
                                                    <Tab className='tab-width'>
                                                        <div className=''>
                                                            <div>

                                                                {meeting.meeting.is_Meeting == 1 ?
                                                                    <h6 >{meeting.meeting.meetingTitle}</h6>
                                                                    :
                                                                    <h6>Ref : {meeting.meeting.documentNumberForDocControl}</h6>
                                                                }
                                                            </div>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='meetingdate_view'>{Moment(meeting.meeting.meetingTime).format('DD/MM/YYYY')}</div>
                                                                {meeting.meeting.is_Meeting == 1 ?
                                                                    <div className='meetingtype_view'>{meeting.meeting.meetingType == 1 ? 'Internal' : 'External'} Meeting</div>
                                                                    :

                                                                    <div className='meetingtype_view'> Tour</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                }
                                            </>
                                        }
                                    </div>
                                </TabList>
                            </Col>
                            <Col md={9} className='col-12'>
                                {linkedmeetings != null ?
                                    linkedmeetings.map((link, k) => {
                                        return (
                                            <TabPanel className='meeting_info_scroll'>
                                                <ViewMeetingInfo id={link.meetingID} />
                                            </TabPanel>
                                        )
                                    })
                                    :
                                    <>
                                        {meetingavail &&
                                            <TabPanel>
                                                <ViewMeetingInfo id={meeting.meeting.meetingID} />
                                            </TabPanel>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </Tabs>
                </div>
            </ModalBody>
        </Modal>
    )
}
