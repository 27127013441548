import React, {useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, BlueBorderButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";
import { GetShareHolderType } from '../../../../../../helpers/API/Api';

function FormComponent(props) {
  const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
  const [contactTypeError, setContactTypeError] = useState(false);
  const [isError, setIsError] = useState(true);
  console.log(props.data)
  
  const addDirector = ()=>{

        const director_list ={
          "shareholderName": formik.values.shareholderName,
          "shareholderTypeID": formik.values.shareholderTypeID == null ? formik.values.shareholderTypeID : parseInt(formik.values.shareholderTypeID),
          "shareholderNationality": formik.values.shareholderNationality,
          "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
          "shareholdingValue": formik.values.shareholdingValue,
          "is_Default" : false,
      }
      console.log(director_list)
      props.addShareholderToList(director_list)
      
      
  }

  const formik = useFormik({
      initialValues:
      !props.isEdit?
      {
          shareholderName: '',
          shareholderTypeID: null,
          shareholderNationality: '',
          shareholdingPercentage: 0,
          shareholdingValue : ''
      }
      :
      {
        shareholderName: props.data.shareholderName,
        shareholderTypeID: props.data.shareholderTypeID,
        shareholderNationality: props.data.shareholderNationality,
        shareholdingPercentage: props.data.shareholdingPercentage,
        shareholdingValue :  props.data.shareholdingValue
      },
      validate: values => {
            
        let errors = {}
        const letters = /^[A-Za-z ]+$/;
        const phone = /^[0-9]+$/;

        if(!values.shareholderName){
            errors.shareholderName = "Please fill in name of Share Holder"
        }else if (!letters.test(values.shareholderName)) {
            errors.shareholderName = "Please enter a valid Name"
        }
        if (!letters.test(values.shareholderNationality) && values.shareholderNationality) {
            errors.shareholderNationality = 'Please enter a valid Nationality'
        }
        if (!phone.test(values.shareholdingPercentage) && values.shareholdingPercentage) {
            errors.shareholdingPercentage = 'Please enter a valid Percentage'
        }
        setIsError(true)
        sendBack();
        return errors
    }
  })

  const editDirector = ()=>{
    if(props.isEdit == true){
        const director_list = { 
          "shareholderName": formik.values.shareholderName,
          "shareholderTypeID":  formik.values.shareholderTypeID == null ? formik.values.shareholderTypeID : parseInt(formik.values.shareholderTypeID),
          "shareholderNationality": formik.values.shareholderNationality,
          "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
          "shareholdingValue": formik.values.shareholdingValue,
          "is_Default" : props.data.is_Default,
          "key" :props.data.key,
          "supplierShareholderID": props.data.supplierShareholderID,
          "supplierID": props.data.supplierID
      }
      console.log(director_list)
      props.editShareholderToList(director_list)
    }
    else{
        const director_list = { 
            "shareholderName": formik.values.shareholderName,
            "shareholderTypeID":  formik.values.shareholderTypeID == null ? formik.values.shareholderTypeID : parseInt(formik.values.shareholderTypeID),
            "shareholderNationality": formik.values.shareholderNationality,
            "shareholdingPercentage": parseInt(formik.values.shareholdingPercentage),
            "shareholdingValue": formik.values.shareholdingValue,
            "is_Default" : props.data.is_Default,
            "key" :props.data.key, 
        }
        console.log(director_list)
        props.editShareholderToList(director_list)
    }
  }
  const handleContactType = (e)=>{
    formik.values.shareholderTypeID = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    formik.values.shareholdingValue = e.nativeEvent.target[index].text;
    console.log(e.nativeEvent.target[index].text)
    if(formik.values.shareholderTypeID == 0){
        setContactTypeError(true)
    }
    else{
        setContactTypeError(false)
    }
 }   
 const sendBack = () => {

    if ((Object.keys(formik.errors).length == 0) ) {
        
        if((props.isBackClicked == true) 
        && (formik.initialValues == formik.values) 
        && (isError == false)){
            console.log('condition one')
            setIsError(false)
            props.handleValidationShareholder()
        // console.log('error checking')
        // if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
        //     console.log(formik.errors)
        //     console.log('no error first condition')
        //     setIsError(false)
        //     props.handleError()    
        // }
        // else if(formik.initialValues == formik.values){
        //     console.log(formik.errors)
        //     console.log('error')
        //     props.setErrors()
        // }
        // else{
        //     setIsError(false)
        //     console.log(formik.errors)
        //     console.log('no error first condition')
        //     props.handleError()    
        // }
        }
        else if(formik.initialValues == formik.values){
                setIsError(true)
                console.log(formik.errors)
                console.log('error')
                props.setErrors()
        }
        else{
            console.log('no log')
            setIsError(false)
            props.handleValidationShareholder()
        }
        
          
    }
    else{
        setIsError(true)
        console.log(formik.errors)
        console.log('error')
        props.setErrors()
    }
}
  return (
    <div>  
      <Row>
        <FormFields.InputField
            md={3}
            name='shareholderName'
            label='Name of Shareholder'
            classname='pt-1-rem'
            type='text'
            val={formik.values.shareholderName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors= {formik.touched.shareholderName?formik.errors.shareholderName:''}
        />
        <FormFields.InputSelect
            md={3}
            name='shareholderTypeID'
            label='Shareholder Type'
            classname='pt-1-rem'
            type='text'
            optionField={props.shareholderTypeID != '' ? 
            <GetShareHolderType 
                shareholderTypeID={formik.values.shareholderTypeID} 
            /> 
            :
            <GetShareHolderType 
            />}
            val={formik.values.shareholderTypeID}
            onChange={handleContactType}
        />
        <FormFields.InputField
            md={3}
            name='shareholderNationality'
            label='Nationality'
            classname='pt-1-rem'
            type='text'
            val={formik.values.shareholderNationality}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors= {formik.touched.shareholderNationality?formik.errors.shareholderNationality:''}
        />
        <FormFields.InputField
            md={3}
            name='shareholdingPercentage'
            label='Shareholding Percentage'
            classname='pt-1-rem'
            type='text'
            val={formik.values.shareholdingPercentage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors= {formik.touched.shareholdingPercentage?formik.errors.shareholdingPercentage:''}
        />
         <Col className='d-flex align-items-end mt-4'>
            <BlueButton value={props.isEdit?'Edit Shareholder':'Add Shareholder'} clickEvent={props.isEdit?editDirector:addDirector}  disabled = {props.isEdit == true ? false : isError}/>
            <div className = 'ms-4'>
                <BlueBorderButton value={'Cancel'} clickEvent={props.handleCancel}/>
            </div>
          </Col>
      </Row>
    </div>
  )
}

export default FormComponent