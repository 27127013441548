import React,{useEffect, useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, UploadButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";

function FormComponent(props) {
    const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
    const [QuoteDocumentName, setQuoteDocumentName] = useState('')

    useEffect(()=>{
        if(props.isEdit == true){
            if(props.selectedEnquiry.enqiry.quoteDocumentName != null){
                var fileName = ''
                if(props.selectedEnquiry.enqiry.quoteDocumentName.length > 5){
                    fileName = props.selectedEnquiry.enqiry.quoteDocumentName.slice(0, 5) + '...' + props.selectedEnquiry.enqiry.quoteDocumentName.slice(props.selectedEnquiry.enqiry.quoteDocumentName.length, -1)
                    console.log(fileName)
                }
             
                setQuoteDocumentName(fileName)
            }
        }
        else if(props.documentDetails != null){
            setQuoteDocumentName(props.documentDetails.QuoteDocumentName)
        }
    },[])
    const formik = useFormik({
        initialValues:
        {
            customerCode: '',
            customerName: '',
            website: '',
            telephoneNo: '',
            fax: '',
            QuoteDocument: ''
            
        }
    })
    useEffect(()=>{
        setTimeout(() => {
            setDataSubmissionValidation(!dataSubmissionValidation)
            if(props.isdataavailable){
                formik.values.customerCode= props.customerDetails.customerCode
                formik.values.customerName= props.customerDetails.customerName
                formik.values.website= props.customerDetails.website
                formik.values.telephoneNo= props.customerDetails.telephoneNo
                formik.values.fax = props.customerDetails.fax
              
            }
        }, 500);
    })

    const quoteDocumentHandler = (file) => {
        console.log(file.base64)
        formik.values.QuoteDocument = file.base64;
        formik.values.QuoteDocumentName = file.name
        var fileName = ''
        if(file.name.length > 5){
            fileName = file.name.slice(0, 5) + '...' + file.name.slice(file.name.length, -1)
            console.log(fileName)
        }
        setQuoteDocumentName(fileName)
        console.log(formik.values)
        props.handleDocumentAttached(formik.values)
    }
    return (
        <>
                <Form>
                    <Row>
                        <FormFields.InputField
                            md={3}
                            name='customerCode'
                            label='Customer Code*'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.customerCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors= {formik.touched.customerCode?formik.errors.customerCode:''}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='customerName'
                            label='Customer Name'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.customerName}
                            onChange={formik.handleChange}
                            disabled
                            
                        />
                        <FormFields.InputField
                            md={3}
                            name='website'
                            label='Website'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.website}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='telephoneNo'
                            label='Telephone'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.telephoneNo}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <FormFields.InputField
                            md={3}
                            name='fax'
                            label='Fax'
                            classname='pt-1-rem'
                            type='text'
                            val={formik.values.fax}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <Col md={3} className='d-flex align-items-center pt-1-rem'>
                            <UploadButton value='Quotation' onUploadHandle={quoteDocumentHandler} filename={QuoteDocumentName} disabled={true}/>
                        </Col>
                    </Row>
                </Form>

        </>

    )
}

export default FormComponent