
import React, { useEffect, useState } from 'react'
import {Row} from "react-bootstrap";
import ListData from './ListData';
import { AddNewButton } from '../../../../../components/buttoncomponents';

function List(props) {
    console.log(props)
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [productList, setProductList] = useState([])
    useEffect(()=>{
            console.log(props, 'inside list contract')
            setIsPrimarySelected(false)
            props.data.map((d)=>{
                if(d.is_Default){
                    setIsPrimarySelected(true)
                    console.log(is_DefaultSelected,'inside list contract')
                }
            })
    },[])
    
    const addTraceAndQuality = (data) =>{
        console.log(data,'list')
        let prlist = [...productList];
        console.log(prlist)
        let pr_contains = prlist.filter(x => x.productID == data.productID)
        if(Object.keys(pr_contains).length == 0){
            prlist.push(data)
        }
        else{
            prlist.map((list,k)=>{
                if(list.productID == data.productID){
                    prlist[k] = data
                }
            })
        }
        setProductList(prlist)
        console.log(prlist)
        props.addTraceAndQuality(prlist)
    }
    return (
        <div className='table-responsive-sm'>
            <table className="table table-text tradeInitiation ">
                <thead>
                    <tr className='bg-light font-weight-500 color-707895'>
                    <th scope='col' className='font-13 tableHead'>Product Code</th>
                    <th scope='col' className='font-13 tableHead'>HS Code</th>
                    <th scope='col' className='font-13 tableHead'>Product Name</th>
                    <th scope='col' className='font-13 tableHead'>Product Quantity</th>
                    <th scope='col' className='font-13 tableHead'>Buying Price</th>
                    <th scope='col' className='font-13 tableHead'>Selling Price / Unit Price</th>
                    <th scope='col' className='font-13 tableHead'>Supply#</th>
                    <th scope='col' className='font-13 tableHead'>Supply Period</th>
                    <th scope='col'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                            props.data.map((values)=>(
                                <ListData 
                                values={values} 
                                showModalForEdit = {props.showModalForEdit}
                                addTraceAndQuality={addTraceAndQuality}
                                handleDataForModal = {props.handleDataForModal} 
                                tradeInitiateEdit = {props.tradeInitiateEdit}                               
                                />
                            ))
                    }   
                </tbody>
            </table>
        </div>
    )
}

export default List