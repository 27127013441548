import React, { useEffect, useState } from 'react'
import * as FormFields from "../../../components/formcomponents"
import { Row } from 'react-bootstrap'
import {AddCategory, AddGroup, AddUom} from "../../../helpers/API/ProductApi"
import { useFormik } from 'formik'

export default function Productsform(props) {
    
    const formik = useFormik({
        initialValues:
            {
                productCode :  props.proval.productCode,
                productName : props.proval.productName,
                productCategoryID: props.proval.productCategoryID,
                productGroupID: props.proval.productGroupID,
                productUOMID: props.proval.productUOMID,
                description: props.proval.description
            }
    })


    return (
        <Row>
            <FormFields.InputField
                md={6}
                name='productCode'
                label='Product Code*'
                classname='pt-1-rem'
                type='text'
                val={formik.values.productCode}
                disabled={props.isView?true:false}
            />
            <FormFields.InputField
                md={6}
                name='productName'
                label='Product Name*'
                classname='pt-1-rem'
                type='text'
                val={formik.values.productName}
                disabled={props.isView?true:false}
            />
            <FormFields.InputSelect
                md={6}
                name='productCategoryID'
                label='Product Category'
                classname='pt-1-rem'
                type='text'
                optionField={<AddCategory val={formik.values.productCategoryID}/>}
                disabled={props.isView?true:false}
            />
            <FormFields.InputSelect
                md={6}
                name='productGroupID'
                label='Product Group'
                classname='pt-1-rem'
                type='text'
                optionField={<AddGroup val={formik.values.productGroupID}/>}
                disabled={props.isView?true:false}
            />
            <FormFields.InputSelect
                md={6}
                name='productUOMID'
                label='Product UOM'
                classname='pt-1-rem'
                type='text'
                optionField={<AddUom val={formik.values.productUOMID}/>}
                disabled={props.isView?true:false}
            />
            <FormFields.inputFieldArea
                md={12}
                name='description'
                label='Remarks'
                classname='pt-1-rem'
                type='text'
                val={formik.values.description}
                disabled={props.isView?true:false}
            />
        </Row>
    )
}
