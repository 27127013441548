import React, { useEffect } from 'react'
import { Calendar, momentLocalizer, dateFnsLocalizer } from 'react-big-calendar'
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import { useState } from 'react';
import ViewMeeting from './ViewMeeting';

export default function CalendarView(props) {
    const [viewstatuts, setviewstatus] = useState(false)
    const localizer = momentLocalizer(moment)
    const [meetingid, setmeetingid] = useState(0)
    const [myEventsList, setmyEventsList] = useState([])
    const locales = {
        "en-US": enUS
    };

    useEffect(() => {
        getEventList()
    }, [])

    const getEventList = ()=>{
        let myEventsList = []
        if(props.meetinglist != null){
            props.meetinglist.map((list, k) => {
                let data = {
                    start: new Date(list.meetingStartTime),
                    end: new Date(list.meetingEndTime),
                    eventClasses: "optionalEvent",
                    title: list.meetingTitle,
                    description: list.meetingCategory,
                    id: list.meetingID
                }
                myEventsList.push(data)
            })
            setmyEventsList(myEventsList)
            console.log(myEventsList)
        }
        
    }

    const handleEvent = (e) => {
        console.log(e)
        setmeetingid(e.id)
        toggleviewstatus()
    }
    const toggleviewstatus = () => {
        setviewstatus(!viewstatuts)
    }
    return (
        <div className='calender'>
            <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={handleEvent}
                style={{ height: '80vh' }}
            />
            {viewstatuts &&
                <ViewMeeting toggle={toggleviewstatus} meetingid={meetingid} />
            }
        </div>
    )
}
