import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Dropdown, Menu, Space, DatePicker } from 'antd';
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import * as FormFields from "../../../components/formcomponents";
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Slider, Switch } from 'antd';
import { max, min, toDate } from 'date-fns'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ListPage from './listPage'


export default function Index(props) {
    const { instance } = useMsal();
    const [rptcustomerdata, setrptcustomerdata] = useState()
    const [isDetails, setisDetails] = useState(false)
    const [customerId, setcustomerId] = useState(0)
    const [count, setcount] = useState(0)
    const [sum, setsum] = useState(0)
    const [qty, setqty] = useState(0)
    const [rptcustomerdataall, setrptcustomerdataall] = useState()
    const [showsearch, setshowsearch] = useState(false)
    const [term, setterm] = useState('')
    const [calendarstatus, setcalendarstatus] = useState(false)
    const { RangePicker } = DatePicker;
    const [priceval1, setpriceval1] = useState(0)
    const [priceval2, setpriceval2] = useState(0)
    const [qtyval1, setqtyval1] = useState(0)
    const [qtyval2, setqtyval2] = useState(0)
    const [todate, settodate] = useState(false)
    const [fromdate, setfromdate] = useState(false)
    const [divisionlist, setdivisionlist] = useState([])
    const [userlist, setuserslist] = useState([])
    const [trade_details, settradedetails] = useState([])
    const [isset, setisset] = useState(false)
    const [dataForReport, setDataForReport] = useState()
    const [currentid, setcurrentid] = useState([])
    const [companyID, setCompanyID] = useState()
    useEffect(() => {

    }, [])

    useEffect(() => {
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION_, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setdivisionlist(res.data.result)
                let length = res.data.result.length
                res.data.result.map((div, l) => {
                    handlePerformance(div.divisionID)
                    if(l==0){
                        setcurrentid(div.divisionID)
                    }
                })
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    useEffect(() => {

    }, [dataForReport])
    useEffect(() => {
        getAll()
    }, [])

    const getAll = () => {
        const json_list = {
            "PmsInstanceID": CONFIG.PMSInstanceID,
            "PmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "SolutionID": CONFIG.SolutionID
        }
        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_ALL_USERS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserslist(res.data.result)
                setcount(res.data.result.length)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const openDetails = (e) => {
        console.log(e.currentTarget.id)
        let id = parseInt(e.currentTarget.id)
        setcustomerId(id);
        setisDetails(!isDetails)
    }
    const handleSearch = (e) => {
        let val = e.target.value
        let filter = rptcustomerdataall.filter(x => x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.customercode.toLowerCase().includes(val.toLowerCase())
        )
        setrptcustomerdata(filter)
    }

    const handleOpenFilter = () => {
        setshowsearch(!showsearch)
    }

    const printDocument = () => {
        const domElement = document.getElementById("download_section");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('PerformanceReports.pdf');
            })
    }
    
    useEffect(()=>{
        console.log(trade_details)
    },[trade_details])

    const searchData = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "fromDate": fromdate,
            "toDate": todate,
            "divisionID": currentid,
            "tradeStartValue": 0,
            "tradeEndValue": 0,
            "quantityStartValue": 0,
            "quantityEndValue": 0
        }
        axios.post(CONFIG.TMS_SERVER_URL + 'Sales/RPTPerformanceData', json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let trade_detail =trade_details
                trade_detail['"'+currentid+'"'] = res.data.result
                settradedetails(trade_detail)
                console.log(trade_details)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const findmonthly = () => {
        setterm('monthly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }

    const findquarterly = () => {
        setterm('quarterly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('quarter').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }

    const findyearly = () => {
        setterm('yearly')
        let todate = Moment().format("YYYY-MM-DDTHH:mm:ss")
        let fromdate = Moment().startOf('year').format("YYYY-MM-DDTHH:mm:ss")
        settodate(todate)
        setfromdate(fromdate)
        setcalendarstatus(false)
    }

    const setDateRange = (date, dateString) => {
        setfromdate(new Date(dateString[0]))
        settodate(new Date(dateString[1]))
    }


    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findmonthly}>Monthly</span></>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findquarterly}>Quarterly</span></>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <><span className=' font-14 pl-10 mb-1-rem' onClick={findyearly}>Yearly</span></>
                    ),
                }
            ]}
        />
    );
    const setrrange = () => {
        setcalendarstatus(!calendarstatus)
    }

    const handlePerformance = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "fromDate": null,
            "toDate": null,
            "divisionID": id,
            "tradeStartValue": 0,
            "tradeEndValue": 0,
            "quantityStartValue": 0,
            "quantityEndValue": 0
        }
        axios.post(CONFIG.TMS_SERVER_URL + 'Sales/RPTPerformanceData', json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setDataForReport(res.data.result)
                let trade_detail = trade_details
                trade_detail['"' + id + '"'] = res.data.result
                settradedetails(trade_detail)
                console.log(trade_details)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='8.5' isOpenid={8} />

            {/*{isDetails &&*/}
            {/*    <CustomerDetails data={rptcustomerdata} tag='Customer' id={customerId}/>*/}
            {/*}*/}

            <div className='width-100'>
                <Navbar />
                <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                    <div className='d-flex justify-content-center align-items-center '>
                        <BackButton />
                    </div>
                </div>
                <div className='mt-5 mt-md-0 bg-white px-4'>
                    <Row className=''>
                        <Col md={4} className='d-flex align-items-center justify-content-center rt-b sec1'>
                            <div>
                                <span className='reports_count_header'>Total Employees</span>
                                <div className='reports_count_value'>{count}</div>
                            </div>
                        </Col>

                    </Row>
                </div>
                <div className='mx-4 px-3 mt-5'>
                    <div className='row'>
                        <div className='col-md-8 align-self-center'>
                            <div className='d-flex'>
                                <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' />
                                <h6 className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>PERFOMANCE REPORTS</h6>
                            </div>
                        </div>
                        <div className='col-md-4 text-right'>

                            <div className='d-flex justify-content-end'>
                                <div className='align-self-center'>
                                    <span className='me-5'><BlueButton value="EXPORT PDF" clickEvent={printDocument} /></span>
                                </div>

                                {/* <div>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label='Search'>
                                            <Form.Control type='text' placeholder='Search'
                                                className="form_input form_input_color_change"
                                                name='Search'
                                                onChange={handleSearch}
                                                onBlur={handleSearch}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </div> */}

                                <div className='pl-10'>
                                    <img src='assets/images/bluefilter.svg' className='pointer' onClick={handleOpenFilter}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {showsearch &&
                    <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-4 '>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <span className='report_filter_title'>Date Range</span>
                                        <div className='date_range_filter d-flex justify-content-between'>
                                            <div>
                                                <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                                                    <a onClick={(e) => e.preventDefault()}>
                                                        <Space className='d-flex justify-content-between align-items-center'>
                                                            {!calendarstatus &&
                                                                <span className='meeting_term'>{term}</span>
                                                            }
                                                            <span><img src='assets/images/DownwardArrow.svg' /></span>
                                                        </Space>
                                                    </a>
                                                </Dropdown>
                                            </div>
                                            <div className='pl-10'>
                                                {calendarstatus ?
                                                    <RangePicker onChange={setDateRange} />
                                                    :
                                                    <img src='assets/images/calendar.svg' onClick={setrrange} />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3} className='d-flex align-items-center'>
                                        <Button variant='primary' className='mx-3' size='md' onClick={searchData}>Search</Button>
                                        <Button variant='outline-primary' className='mx-3' size='md' onClick={()=>{
                                            setfromdate(null)
                                            settodate(null)
                                            searchData()
                                        }}>
                                            Clear Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
                <div className='inner-page-content mx-md-4 mx-2 px-md-3 px-0 mt-4 '>
                    <div className=''>
                        <Tabs>
                            <Card className='tabs-wrapper'>
                                <TabList>
                                    {divisionlist.map((div, k) => {
                                        return (
                                            <Tab onClick={()=>{
                                                setcurrentid(div.divisionID)
                                            }}>{div.divisionName}</Tab>
                                        )
                                    })}
                                </TabList>
                            </Card>
                            {divisionlist.map((div, k) => {
                                return (
                                    <TabPanel className={'card'}>
                                        <Row className='m-1-rem p-4 text-center text-md-start'>
                                            <Col md={3} className='d-flex align-items-center justify-content-center'>
                                                <div>
                                                    <div className='performance_overview_title'>
                                                        {(div.divisionName).toUpperCase()} TEAM
                                                    </div>
                                                    <div className='d-flex mt-2'>
                                                        {userlist != null && userlist.length > 0 ?
                                                            userlist.map((user, k) => {
                                                                if (k < 9) {
                                                                    return (
                                                                        <>
                                                                            {user.divmapp.map((us, l) => {
                                                                                if (us.divisionID == div.divisionID) {
                                                                                    return (
                                                                                        <div className='avatar d-flex align-items-center justify-content-center'>
                                                                                            <p className='font-14 font-weight-500 mb-0'>{user.user.userAvatar != '' &&
                                                                                                user.user.userAvatar
                                                                                            }</p>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </>
                                                                    )
                                                                }
                                                            })

                                                            :
                                                            ''
                                                        }

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3} className='mt-3 md-mt-0 d-flex align-items-center justify-content-center'>
                                                <div className='performance_overview_title'>
                                                    TRADE COMPLETED<div className='mt-3'>
                                                        {trade_details['"'+div.divisionID+'"'] != null &&
                                                           trade_details['"'+div.divisionID+'"'].tradeCompletedCount
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3} className='my-3 md-my-0 d-flex align-items-center justify-content-center'>
                                                <div className='performance_overview_title'>
                                                    TOTAL PURCHASES<div className='mt-3'>
                                                        {trade_details['"'+div.divisionID+'"'] != null ?
                                                           <>$ {parseFloat(trade_details['"'+div.divisionID+'"'].buyingValue).toFixed(2)}</>
                                                           :
                                                           <>$ 0</>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={3} className='d-flex align-items-center justify-content-center'>
                                                <div className='performance_overview_title'>
                                                    TOTAL PROFIT EARNED <div className='mt-3'>
                                                        {trade_details['"'+div.divisionID+'"'] != null ?
                                                           <>$ {
                                                                (parseFloat(trade_details['"'+div.divisionID+'"'].tradeValue) - parseFloat(trade_details['"'+div.divisionID+'"'].buyingValue)).toFixed(2)
                                                            }</>
                                                           :
                                                           <>$ 0</>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div class="table-responsive-sm card card-body ">
                                            <table className="table table-striped tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase'>
                                                        <th><p className='w-200 mb-0'>NAME</p></th>
                                                        <th><p className='w-200 mb-0'>TRADE COMPLETED</p> </th>
                                                        <th><p className='w-200 mb-0'>TRADE IN PROCESS</p></th>
                                                        <th><p className='w-200 mb-0'>TOTAL QTY</p></th>
                                                        <th><p className='w-200 mb-0'>TOTAL VALUE</p></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userlist.map((user, k) => {
                                                        return (
                                                            <>
                                                                {user.divmapp.map((us, l) => {
                                                                    if (us.divisionID == div.divisionID) {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <div className='avatar d-flex align-items-center justify-content-center'>
                                                                                        <p className='font-14 font-weight-500 mb-0'>
                                                                                            {user.user.userAvatar != '' &&
                                                                                                user.user.userAvatar
                                                                                            }</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                {trade_details['"'+div.divisionID+'"'] != null &&
                                                                                    trade_details['"'+div.divisionID+'"'].tradeCompletedCount
                                                                                }
                                                                                </td>
                                                                                <td>
                                                                                {trade_details['"'+div.divisionID+'"'] != null &&
                                                                                    trade_details['"'+div.divisionID+'"'].tradeInProcessCount
                                                                                }
                                                                                </td>
                                                                                <td>
                                                                                {trade_details['"'+div.divisionID+'"'] != null &&
                                                                                    trade_details['"'+div.divisionID+'"'].tradeTotalQty
                                                                                }
                                                                                </td>
                                                                                <td>
                                                                                {trade_details['"'+div.divisionID+'"'] != null &&
                                                                                    parseFloat(trade_details['"'+div.divisionID+'"'].tradeValue).toFixed(2)
                                                                                }
                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </>
                                                        )
                                                    })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>


        </div>
    )
}