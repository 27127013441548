import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Button } from "reactstrap"
import { Row, Form } from "react-bootstrap"
import { useState } from 'react'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import NumberFormat from 'react-number-format';

export default function CustomerDetails(props) {
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [customerdetail, setcustomerdetail] = useState([])

    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.id),
            "companyID" : props.companyID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_CUSTOMER_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setcustomerdetail(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
                console.log(error)
            })
    }, [])

    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }

    const printDocument = () => {
        const domElement = document.getElementById("download_section_detail");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('CustomerDetailsReports.pdf');
            })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
            <ModalHeader toggle={toggle} className='reports_details'>
                <div className='width-100l d-flex justify-content-between'>
                    <div className='d-flex align-items-center ms-4'>
                        <p className='font-weight-400 color-697382 mb-1'>REPORT DETAILS</p>
                    </div>
                    <div>
                        <img src='assets/images/icon_pdf.svg' className='img-fluid' onClick={printDocument} />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper scrollContract' style={{ padding: '0' }} id='download_section_detail'>

                <Row className='card-body'>
                    <div class="table-responsive-sm ">
                        <div className='bg-white br-8' >
                            <table className="table tCustom table-text mt-1-rem">
                                <thead >
                                    <tr>
                                        {customerdetail.length > 0 &&
                                            <div className=" p-4 grey-color m-4 bg-light row">
                                                <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                                    <p className='font-weight-400 color-697382 mb-1'>CUSTOMER</p>
                                                    <div>
                                                    <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{customerdetail[0].customername}</h6>
                                                        <span className='tradeTrsactionsid statBg '>{customerdetail[0].customercode}</span>
                                                    
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                                    <p className='font-weight-400 color-697382 mb-1'>PRODUCT DETAILS</p>
                                                    <p className='font-weight-600 font-14 mb-0 textHead'>{customerdetail[0].divisionName} <span className='mx-1 dotSep'>&#8226;</span> {customerdetail[0].productName}</p>
                                                </div>
                                            </div>
                                        }
                                    </tr>
                                </thead>

                            </table>
                        </div>
                    </div>
                </Row>

                <div>
                    <div className='inner-page-content'>
                        <div className='bg  br-8 shadowMeetings'>
                            <div class="col-lg-12">
                                <div class="table-responsive-sm  card card-body ">
                                    <table className="table tableMeeting table-text mt-1-rem">
                                        <thead >
                                            <tr>
                                                <th>TRADE#</th>
                                                <th>PRODUCT</th>
                                                <th>QY</th>
                                                <th>AMOUNT</th>
                                                <th>CLOSD DATE</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerdetail.length > 0 &&
                                                customerdetail.map((list, k) => {
                                                    return (
                                                        <tr>
                                                            <td className='meeting_list_bold'>{list.tradeNo}</td>
                                                            <td className='meeting_list_normal'>{list.productName}</td>
                                                            <td className='meeting_list_bold'><NumberFormat value={list.qty} displayType={'text'} thousandSeparator={true} /></td>
                                                            <td className='meeting_list_bold'>{parseFloat(list.tradeValue).toFixed(2)}</td>
                                                            <td className='meeting_list_bold'>{Moment(list.tradeClosedDate).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle}/></span>
                <span className='pl-10'><BlueBorderButton value="EXPORT PDF" clickEvent={printDocument} /></span>
                

            </ModalFooter>
        </Modal>
    )

}