import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card, Button } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import NumberFormat from 'react-number-format';
//import CustomerDetails from './customerdetails';
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import PurchaseModal from './Modals/PurchaseModal'

export default function PurchaseDetailsList(props) {
    const [rptpurchasedata, setrptpurchasedata] = useState([])
    const { instance } = useMsal();
    const [modalData, setModalData] = useState()
    const [modal, setModal] = useState(false)

    //useEffect(() => {
    //    let listValues = []
    //    let listValuesTax = []
    //    const json_list = {
    //        "instanceID": CONFIG.PMSInstanceID,
    //        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    //        "created_User": CONFIG.LoginID,
    //    }
    //    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_PURCHASEDETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    //        .then((res) => {
    //            console.log(res.data.result)
    //            setrptpurchasedata(res.data.result)
    //        }).catch((error) => {
    //            if (error.response.status == 401) {
    //                localStorage.clear();
    //                instance.logoutRedirect({
    //                    postLogoutRedirectUri: "/",
    //                });
    //            }
    //        })
       
    //}, [])
    useEffect(() => {
        console.log('props.rptpurchasedata')
        console.log(props.rptpurchasedata)
        setrptpurchasedata(props.rptpurchasedata);

    }, [props])
    const handleClick = (e, data) => {
        console.log(data)
        setModalData(data)
        setModal(true)
    }
    const close = () => {
        setModal(false)
    }
    useEffect(()=>{

    },[modalData])
    return (
        <>
        <div class="col-lg-12 ">
            <div class="table-responsive-sm ">
                <table className="table table-striped tCustom table-text">
                    <thead className='p-1-rem'>
                        <tr className='text-uppercase'>
                            <th><p className='w-200 mb-0'>TRADE# & DATE</p></th>
                            <th><p className='w-200 mb-0'>SUPPLIER</p> </th>
                            <th><p className='w-200 mb-0'>DIVISION & PRODUCT</p></th>
                            <th><p className='w-200 mb-0'>QTY</p></th>
                            <th><p className='w-200 mb-0'>AMOUNT</p></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rptpurchasedata != null && rptpurchasedata.length > 0 ?
                            rptpurchasedata.map((values, k) => (
                                <tr>
                                    <td className='font-weight-500 font-14 '>
                                        <p className='w-150 mb-0'>{values.tradeNo} </p>
                                        <p className='w-150 mb-0'>
                                            {values.tradeClosedDate != null ? <div>{Moment(values.tradeClosedDate).format('DD/MM/YYYY')}</div> : ""
                                            }
                                        </p>
                                    </td>
                                    <td className='font-weight-500 font-14 d-flex'>
                                        <p className='w-150 mb-0'>{values.suppliername}</p>
                                        <span className='tradeTrsactionsid statBg ml-1-rem'>{values.suppliercode}</span>
                                    </td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'>{values.divisionName}
                                        <span className='mx-1 dotSep'>&#8226;</span> {values.productName}</p></td>
                                    <td className='font-weight-500 font-14 '><p className='w-150 mb-0'><NumberFormat value={values.qty}  displayType={'text'} thousandSeparator={true} /> {values.prodUOM}</p></td>
                                    <td className='font-weight-500 font-14 '>
                                        <p className='w-150 mb-0'>
                                            <NumberFormat value={parseFloat(values.tradeValue).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix='$ ' />
                                        </p></td>
                                    <td>
                                        {values.supplierInvoiceBy &&
                                            <div className='avatar-secondary d-flex align-items-center justify-content-center'>
                                                <p className='font-14 font-weight-500 mb-0'>{values.supplierInvoiceBy}</p>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                            <Button variant='outline-secondary' id={values.customerID} onClick={(e)=>{handleClick(e, values)}}  >
                                                <img src='assets/images/rightarrow.svg' />
                                            </Button>
                                        </td>
                                </tr>
                            ))
                            :
                            ''
                        }
                    </tbody>
                </table>
            </div>
        </div>
        {modal &&
            <PurchaseModal 
                modalData = {modalData != null ? modalData : ''} 
                close = {close}
            />
        }
        </>
    )
}

