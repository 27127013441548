import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import List from './components/List';

function ProductDetails(props) {
    console.log(props)
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [key, setKey] = useState(1);

    return (
        <Row className='d-flex bg-white p-4'>
            <div className = 'd-flex justify-content-between'>
                <h4 className='font-16 font-weight-600 color-707895 mb-3'>
                   Product Details
                </h4>
            </div>
            <List contractterms = {props.contractterms} handleDisableEnd = {props.handleDisableEnd} handleIsFirst = {props.handleIsFirst} handleDisable = {props.handleDisable} updateProductDetails = {props.updateProductList} handleUpdateProductDetails = {props.handleUpdateProductDetails} data={props.productData} saveList={props.saveList} isEdit={props.isEdit}/>
        </Row>
    )
}

export default ProductDetails