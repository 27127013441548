import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import axios from 'axios'
import { AddNewButton, BlueButton } from '../../components/buttoncomponents'
import AddEdit from './addedit/AddEdit'
import List from './List'
import * as CONFIG from "../../helpers/config"
import { useMsal } from "@azure/msal-react";
import * as FormFields from "../../components/formcomponents"
import { Col, FloatingLabel, Form } from 'react-bootstrap'


export default function Index() {
    const { instance } = useMsal();
    const [customerList, setList] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [isEdit, setIsEdit] = useState(false)
    const [removeAddButton, setRemoveAddButton] = useState(true)
    const [specificCustomList, setSpecificCustomList] = useState([])
    const [customerFilterList, setCustomerFilterList] = useState([])
    useEffect(()=>{
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
        if(isFirst){
            getList()
        }
        setIsFirst(false)
    })

    const getList = () =>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_CUSTOMER_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setList(res.data.result);
            setCustomerFilterList(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const [isCustomerAdd, setIsCustomerAdd] = useState(false);

    const addCustomer = ()=>{
        setIsCustomerAdd(!isCustomerAdd)
        setSpecificCustomList([])
        setRemoveAddButton(false)
        setIsEdit(false)

    }

    const handleSave = () => {
        setIsCustomerAdd(!isCustomerAdd)
        setRemoveAddButton(true)
    }

    const handleCancel = ()  => {
        setIsEdit(false)
        setIsCustomerAdd(!isCustomerAdd)
        setRemoveAddButton(true)
    }

    const getListForEdit = (id)=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_CUSTOMER_LIST_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setSpecificCustomList(res.data.result)
            setIsEdit(true)
            setIsCustomerAdd(!isCustomerAdd);
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    
    const disable = (e) =>{
        console.log('clicked')
        const id = parseInt(e.target.id)
        console.log(customerList)
        let data =  customerList.filter(x=> x.custid == id)
        console.log(data)
        let status = data[0].custstatus;
        console.log(data[0], status, id)
        const json = {
            "id": id,
            "status": status==1?0:1,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.CHANGE_CUSTOMER_STATUS, json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getList();
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const edit = (e) => {
        console.log(e.target.id)
        let id = parseInt(e.target.id)
        getListForEdit(id)
        console.log(specificCustomList)
        setRemoveAddButton(false)
        
    }

    const handleSearch = (e)=>{
        let val = e.target.value
        let filter = customerList.filter(x=> x.customercode.toLowerCase().includes(val.toLowerCase()) ||
            x.customername.toLowerCase().includes(val.toLowerCase()) ||
            x.category.toLowerCase().includes(val.toLowerCase())||
            x.incoterms.toLowerCase().includes(val.toLowerCase()) ||
            x.address.toLowerCase().includes(val.toLowerCase())
        )
        setCustomerFilterList(filter)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>_
              
            <Sidebar id='2.1' isOpenid={2}/>
            <div className='w-100 h-100'>
                <Navbar/>
                <div className='inner-page-header'>
                    <div className='d-flex justify-content-between'>
                        {isEdit == true ? 
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/pageicons/customericon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10 pt-1'> {isCustomerAdd == true ? 'EDIT CUSTOMER' : 'CUSTOMERS'} </span>
                        </div>
                        : 
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/pageicons/customericon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10 pt-1'> {isCustomerAdd == true ? 'NEW CUSTOMER' : 'CUSTOMERS'} </span>
                        </div>    
                        }
                    
                        <div className='d-flex'>

                            {removeAddButton &&
                                <div className='me-3'>
                                        <Col md={12}>
                                            <FloatingLabel controlId="floatingInput" label='Search'>
                                                <Form.Control type='text' placeholder='Search' 
                                                    className="form_input form_input_color_change" 
                                                    name='search'
                                                    onChange={handleSearch} 
                                                    onBlur={handleSearch} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                </div>
                            }   

                            {removeAddButton == true ? 
                                <>{localStorage.getItem('Add_Customer') == 3 && <AddNewButton  value='Add New Customer' class="bg-white " clickEvent={addCustomer}/> }</>
                                :
                                ''
                            }
                        
                            {isCustomerAdd == true  ?
                                <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                                    <div className = 'd-flex justify-content-center align-items-center '>
                                        <BlueButton value='Back' clickEvent={handleSave}/>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
                <div className='inner-page-content'>
                    {isCustomerAdd?
                        <>
                            <AddEdit handleCancel = {handleCancel} data={isEdit == true ? specificCustomList : ''} isEdit = {isEdit} onSave={handleSave} getList = {getList}/>
                        </>
                    :
                        <>
                            
                            <div className='bg-white p-md-4 p-0 br-8'>
                                <List data={customerFilterList} edit={edit} disable={disable}/>
                            </div>
                        </>
                    }
                </div>
            </div>
            

        </div>
    )
}
