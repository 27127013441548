import React, { useEffect, useState } from 'react'
import {Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import Revenuescreen from "./Revenue/revenuescreen"
import ListBankAccount from './Revenue/listBankAccount';
import UploadDocument from './Revenue/uploadDocument';

export default function FinancialInformation(props) {
    console.log(props, 'finance props')
    const [key, setKey] = useState(1);
    const [isDisabled, setIsDisabled] = useState(true)
    const [isReveneMet, setIsRevenueMet] = useState(false);
    const [isBankMet, setIsBankMet] = useState(false);
    const [revenueData, setRevenueData] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [docs, setDocs] = useState([]);
    const [isFirst, setIsFirst] = useState(true)
    const [handleError, setHandleError] = useState(true)
    const [auditdoc, setauditdoc] = useState('');
    const [bankref, setbankref] = useState('');
    const [memodoc, setmemodoc] = useState('');

    useEffect(()=>{
        if(props.isFinanceSet){
            setBankList(props.supplierdata.bankListFinal)
        }
    },[])
    useEffect(()=>{
        if(props.isFinanceSet){
            setHandleError(false)
            setRevenueData(props.supplierdata.revenueDataList)
        }
    },[revenueData])

    useEffect(()=> {
        if(props.isEdit == true){
            setHandleError(false)
        }
    },[])
    useEffect(()=>{
        if(isReveneMet && isBankMet){
            console.log('inside use effet')
            setIsDisabled(false)
        }
    },[isReveneMet, isBankMet])

    useEffect(()=> {
        if(props.isBackClicked == true){
            setHandleError(false)
        }
    },[])
    useEffect(() => {
        if(isFirst){
            if(props.isEdit == true ){
                let cusList = []
                cusList = [...props.dataForEdit.supplierdata.supplierBankAccountInformation]
                cusList.map((list,k)=>{
                    cusList[k].key = list.supplierBankAccountInformationID
                })
                setBankList(cusList)
                setRevenueData(props.dataForEdit.supplierdata.supplierFinancialInformation)
                setauditdoc(props.dataForEdit.annualAuditDocumentName)
                setbankref(props.dataForEdit.bankReferenceLetterDocumentName)
                setmemodoc(props.dataForEdit.memorandumArticleDocumentName)
                
            }
        }
        setIsFirst(false)
    })
    // useEffect(() => {
    //     if(props.isBackClicked){
    //         console.log('Back inside')
    //         console.log(props)
    //         setBankList(props.supplierdata.bankListFinal)
    //         setRevenueData(props.supplierdata.revenueDataList)
    //     } 
    // }, [])
    const addBanktoList = (data) =>{
        let k = key
        data.key = key;
        let list =[...bankList]
        list.push(data);
        k++
        setKey(k)
        let listPrimary = []
        if(list != null && list.length > 0){
            for(let i = 0; i < list.length; i++){
                if(list[i].is_Default == true){
                    listPrimary.push(list[i])
                }
            }
            if(listPrimary.length == 0){
                list[0].is_Default = true
            }
        }
        setBankList(list)
        setIsBankMet(true)
    }
    
    const setPrimary=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        bankList.map((list,k)=>{
            if(list.key == key){
                list.is_Default = true
            }
            cusList.push(list)
        })
        setBankList(cusList)
    }
    
    const removePrimary=(e)=>{
        const key = parseInt(e.target.id)
        let cusList = []
        bankList.map((list,k)=>{
            list.is_Default = false
            cusList.push(list)
        })
        setBankList(cusList)
    }

    const editBankToList =(data)=>{
        const list = data;
        const supplierList = [...bankList]
        bankList.map((list,k)=>{
            if(list.key == data.key){
                supplierList[k] = data
            }
        })
        setBankList(supplierList)
    }
    
    const requiredFilled = ()=>{
        setIsRevenueMet(true)
    }

    const requiredFailed = ()=>{
        setIsRevenueMet(false)
    }

    const setFinance = (data)=>{
        console.log(data, 'finance data')
        for (let i = 0; i < data.length; i++){
            if(data[i].value != '' && isNaN(data[i].value) != true){
                console.log(data, 'set finance')
                setRevenueData(data)
                setHandleError(false)
            }
            else{
                setHandleError(true)
            }
        }
       
    }

    const saveDocuments = (data)=>{
        console.log(data)
        setDocs(data)
        // let docs = [];
        // docs['audit'] = doc1;
        // docs['refer'] = doc2;
        // docs['memo'] = doc3;
        // console.log(docs)
        // setDocs(docs);
    }
    const moveToNext = ()=>{
        console.log('insdie')
        console.log(revenueData)
        let list = [];
        let bankListFinal = [];
        let revenueDataList = [];
        let docsList =[];
        bankListFinal = [...bankList]
        revenueDataList = [...revenueData]
        list = {revenueDataList, bankListFinal, docs}
        console.log(docs)
        console.log(revenueData)
        props.onNext(list, 3)

    }
    const backClickEvent = () => {
        props.backClickEvent(1)
    }

    const handleErrorValidation = () => {
        setHandleError(false)
    }
    const handleSettingError = () => {
        setHandleError(true)
    }

    useEffect(()=>{

    },[docs])
    return (
    <div>
        <Card style={{background: 'transparent'}}>
            <Card className='mb-10-rem' style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                <Card.Body className='form-card-body mb-2-rem' style={{background: '#fff'}}>
                    <Revenuescreen handleError = {handleErrorValidation} setErrors = {handleSettingError} requiredFilled={requiredFilled} requiredFailed={requiredFailed} isEdit={props.isEdit} setFinance= {setFinance} isFinanceSet = {props.isFinanceSet} data={revenueData}/>
                </Card.Body>
                <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                    <ListBankAccount 
                        setErrors = {handleSettingError} 
                        handleError = {handleErrorValidation} 
                        addBanktoList={addBanktoList} 
                        editBankToList={editBankToList} 
                        bankList ={bankList} 
                        setPrimary={setPrimary} 
                        removePrimary={removePrimary} 
                        isEdit = {props.isEdit} 
                        isFinanceSet = {props.isFinanceSet} 
                        isBackClicked = {props.isBackClicked}
                    />
                </Card.Body>
                <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                <UploadDocument 
                        isEdit={props.isEdit} 
                        saveDocuments = {saveDocuments} 
                        memodoc={props.isEdit? memodoc:''} 
                        auditdoc={props.isEdit? auditdoc:''} 
                        bankref={props.isEdit? bankref: ''}
                        data = {props.dataForEdit != null ? props.dataForEdit : ''}
                    />
                </Card.Body>
            </Card>
            <Card.Footer className='form-card-footer p-2-rem d-flex'>
                <span><BlueBorderButton value='Back' clickEvent = {backClickEvent}/></span>
                <span className='pl-10'><BlueButton value='Next'disabled = {props.isEdit == true ? false : handleError == true ? true : false} clickEvent={moveToNext}/></span>
            </Card.Footer>
        </Card>
    </div>
    )
}
