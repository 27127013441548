    import React, { useEffect, useState } from 'react'
    import Sidebar from '../../../components/sidebar/sidebar'
    import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
    import Navbar from "../../../components/navbar"
    import {Row, Col, Form, Card} from "react-bootstrap"
    import * as FormFields from "../../../components/formcomponents"
    import * as ButtonComponent from "../../../components/buttoncomponents"
    import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
    import { Link, useLocation, useNavigate } from 'react-router-dom'
    import { useFormik } from 'formik'
    import * as CONFIG from "../../../helpers/config"
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import withReactContent from 'sweetalert2-react-content'
    import Moment from "moment"
    import { useMsal } from "@azure/msal-react";
    
    export default function DischargePortUpdates(props) {
        const { instance } = useMsal();
        const [modal, setModal] = useState(false);
        const location = useLocation()
        let navigate = useNavigate();
        const  tradeData = props.tradeData
        console.log(tradeData)
        const tab = props.tab
        const [doc1, setdoc1] = useState(false)
        const [isEdit, setIsEdit] = useState(false)
        const MySwal = withReactContent(Swal)
        const [key, setkey] = useState([])
        const toggle = () => {
            setModal(!modal)
        }
        const formik = useFormik({
            initialValues:{     
                    tradeDischargePortCheckListID : 0,
                    is_DischargePortETA: false,
                    dischargePortETA: '',
                    dischargePortETADate: null,
                    is_DischargePortETACommunicated: false,
                    is_DischargePortNOR: false,
                    dischargePortNOR: '',
                    dischargePortNORDocument: '',
                    dischargePortNORDocumentName: '',
                    is_DischargeCompleted: false,
                    is_DispatchValue: false,
                    dispatchValue: '',
                    dispatchValueDocument: '',
                    dispatchValueDocumentName: '',
                    is_DummurageValue: false,
                    dummurageValue: '',
                    dummurageValueDocument: '',
                    dummurageValueDocumentName: '',
                    is_Discharge : false,
                    dischargeDocumentDetail: []
            }
        })
        useEffect(()=>{
            let json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": tradeData.tradeID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DISCHARGE_PORT_BY_TRADE_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if(res.data.result.length>0){
                    Object.assign(formik.values, res.data.result[0])
                    setdoc1(!doc1)
                    let key = 1
                    let doc_list = []
                    formik.values.dischargeDocumentDetail.map((doc,k)=>{
                        doc_list[k] = doc
                        doc_list[k].key = key
                        key++
                    })
                    formik.values.dischargeDocumentDetail = doc_list
                    console.log(formik.values)
                    setkey(key)
                    setIsEdit(true)
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        },[])
        const savedischargenor = (file) =>{ 
            formik.values.dischargePortNORDocument = file.base64
            formik.values.dischargePortNORDocumentName = file.name
            setdoc1(!doc1)
        }
        const savedispatch = (file)=>{
            formik.values.dispatchValueDocument = file.base64
            formik.values.dispatchValueDocumentName = file.name
            setdoc1(!doc1)
        }
        const savedemmurage = (file)=>{
            formik.values.dummurageValueDocument = file.base64
            formik.values.dummurageValueDocumentName = file.name
            setdoc1(!doc1)
        }
        const saveDocument = (files)=>{
            let file_ll = []
            console.log(files)
            let ke =key
            files.map((file,k)=>{
                let list ={
                    "is_Discharge" :true,
                    "dischargeDocument" : file.base64,
                    "dischargeDocumentName": file.name,
                    "is_Active" : true,
                    "key" : ke
                }
                file_ll.push(list)
                ke++
            })
            setkey(ke)
            formik.values.dischargeDocumentDetail = file_ll
            console.log(formik.values.dischargeDocumentDetail)
            setModal(!modal)
            setdoc1(!doc1)
        }
        const handleDischargeDate = (date)=>{
            formik.values.dischargePortETADate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
            setdoc1(!doc1)
        }
        const saveDischargeInfo = ()=>{
            const json_list ={
                "tradeID": tradeData.tradeID,
                "is_DischargePortETA": formik.values.is_DischargePortETA,
                "dischargePortETA": formik.values.dischargePortETA,
                "dischargePortETADate": formik.values.dischargePortETADate,
                "is_DischargePortETACommunicated": formik.values.is_DischargePortETACommunicated,
                "is_DischargePortNOR": formik.values.is_DischargePortNOR,
                "dischargePortNOR": formik.values.dischargePortNOR,
                "dischargePortNORDocument": formik.values.dischargePortNORDocument,
                "dischargePortNORDocumentName": formik.values.dischargePortNORDocumentName,
                "is_DischargeCompleted": formik.values.is_DischargeCompleted,
                "is_DispatchValue": formik.values.is_DispatchValue,
                "dispatchValue": parseFloat(formik.values.dispatchValue),
                "dispatchValueDocument": formik.values.dispatchValueDocument,
                "dispatchValueDocumentName": formik.values.dispatchValueDocumentName,
                "is_DummurageValue": formik.values.is_DummurageValue,
                "dummurageValue": parseFloat(formik.values.dummurageValue),
                "dummurageValueDocument": formik.values.dummurageValueDocument,
                "dummurageValueDocumentName": formik.values.dummurageValueDocumentName,
                "dischargeDocumentDetail": formik.values.dischargeDocumentDetail,
                "is_Discharge": formik.values.is_Discharge,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }

            if(isEdit){
                json_list.tradeDischargePortCheckListID = formik.values.tradeDischargePortCheckListID
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_DISCHARGE_PORT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Discharge port information updated successfully!',
                            type: 'success',
                            confirmButtonText: 'Okay',
                            cancelButtonText: 'Cancel'
                        }
                    ).then(() => {
                        navigate("/trade-transactions", {state:{ tab: tab }});
                    });
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            }else{
                axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_DISCHARGE_PORT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    props.refreshList()
                    MySwal.fire(
                        {
                            icon: 'success',
                            title: 'Discharge port information added successfully!',
                            type: 'success',
                            confirmButtonText: 'Okay',
                            cancelButtonText: 'Cancel'
                        }
                    ).then(() => {
                        navigate("/trade-transactions", {state:{ tab: tab }});
                    });
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
            }
        }
        const addmoredocs  = (files)=>{
            let file_ll = [...formik.values.dischargeDocumentDetail]
            let ke =key
            files.map((file,k)=>{
                let list ={
                    "is_Discharge" :true,
                    "dischargeDocument" : file.base64,
                    "dischargeDocumentName": file.name,
                    "is_Active" : true,
                    "key" : ke
                }
                file_ll.push(list)
                ke++
            })
            
            setkey(ke)
            formik.values.dischargeDocumentDetail = file_ll
            console.log(formik.values.dischargeDocumentDetail)
            setdoc1(!doc1)
        }
        const removeDocument = (e)=>{
            const id = e.currentTarget.id
            console.log(id)
            let final_list = formik.values.dischargeDocumentDetail.filter(x=> x.key != id)
            let removed_list = formik.values.dischargeDocumentDetail.filter(x=> x.key == id)[0]
            console.log(removed_list)
            if(isEdit){
                removed_list.is_Active = false
                removed_list.is_Deleted = true
                final_list.push(removed_list)
            }
            formik.values.dischargeDocumentDetail = final_list
            console.log(formik.values.dischargeDocumentDetail)
            setdoc1(!doc1)
        }

        return (
            <div className='main-wrapper inner-wrapper d-flex'>
                <Modal isOpen={modal} toggle={toggle} className = 'modal-dialog-custom-width-payments payment-method-modal'>
                    <ModalHeader toggle={toggle}>

                    </ModalHeader>
                    <ModalBody  className = 'service-request-modal-wrapper mt-2-rem'>
                        <div className = 'd-flex justify-content-between align-items-center mx-4'>
                            <div className='font-16 font-weight-600 color-707895'>
                                DISCHRAGE Document
                            </div>
                            <div>
                                <ButtonComponent.UploadButton 
                                    onUploadHandle={addmoredocs} 
                                    multiple={true}
                                    class='px-4 py-2 rounded-3'
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-wrap mt-4 mx-4'>
                            {formik.values.dischargeDocumentDetail.map((doc)=>{
                                    return(
                                        <>
                                            {doc.is_Active ==  true &&
                                                <div className='document_badge px-4 py-2 badge-size color-document mb-2 align-self-center mr-10'>{doc.dischargeDocumentName} <span><img src='assets/images/close.svg' className='pointer' id={doc.key} onClick={removeDocument}/></span></div>
                                            }
                                        </>
                                    ) 
                                })}
                        </div>
                    </ModalBody>
                        <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                            <span><BlueBorderButton value='Cancel' clickEvent={toggle} width="90px"/></span>
                        </ModalFooter>
                </Modal>
                <div className='w-100'>
                    <div className='d-flex inner-page-content justify-content-between'>
                        <div className='d-flex'>
                            <span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>DISCHARGE PORT CHECKLIST</span>
                        </div>
                    </div>
                    <div className='mx-4'>
                        <div className='add-edit-wrapper'>
                            <div className='d-flex justify-content-between'>
                                <Row className='w-100'>
                                    <Card style={{background:'#fff'}}>
                                        <Card.Body className='form-card-body mb-10-rem' style={{overflowY: 'scroll'}}>
                                            <Col md={12}>
                                                <Row className='border-bottom-1 pt-1 pb-4'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DischargePortETA'
                                                            label='Discharge Port - ETA'
                                                            checked={formik.values.is_DischargePortETA}
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                        />
                                                    </Col>
                                                    {/* <FormFields.InputField
                                                        md={3}
                                                        name='dischargePortETA'
                                                        label='Discharge Port - ETA'
                                                        classname='pt-1-rem'
                                                        type='text'
                                                        val={formik.values.dischargePortETA}
                                                        onChange={formik.handleChange}
                                                        onBlur = {formik.handleBlur}
                                                    /> */}
                                                    <FormFields.DatePick
                                                        md={3}
                                                        label='ETA - Last Update On'
                                                        name='dischargePortETADate'
                                                        classname='pt-1-rem iconDatePicker'
                                                        type='date'
                                                        handleCompletionDate={handleDischargeDate}
                                                        isRequired={false}
                                                        val={formik.values.dischargePortETADate}
                                                    />
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DischargePortETACommunicated'
                                                            label='Discharge Port ETA Communicated?'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_DischargePortETACommunicated} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DischargePortNOR'
                                                            label='Discharge Port - NOR'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_DischargePortNOR}
                                                        />
                                                    </Col>
                                                    <FormFields.InputField
                                                            md={3}
                                                            name='dischargePortNOR'
                                                            label='Discharge Port - NOR'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            val={formik.values.dischargePortNOR}
                                                        />
                                                    <Col md={3} className='pt-1-rem'>
                                                        <ButtonComponent.UploadButton 
                                                            value='Document'
                                                            filename={formik.values.dischargePortNORDocumentName}
                                                            document={formik.values.dischargePortNORDocument}
                                                            onUploadHandle={savedischargenor} 
                                                            multiple={false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DischargeCompleted'
                                                            label='Discharge Completed?'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_DischargeCompleted}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DispatchValue'
                                                            label='Despatch Value'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_DispatchValue}
                                                        />
                                                    </Col>
                                                    <FormFields.InputField
                                                            md={3}
                                                            name='DespatchValue'
                                                            label='Discharge Value'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            val={formik.values.dispatchValue}
                                                        />
                                                    <Col md={3} className='pt-1-rem'>
                                                        <ButtonComponent.UploadButton 
                                                            value='Document'
                                                            filename={formik.values.dispatchValueDocumentName}
                                                            document={formik.values.dispatchValueDocument}
                                                            onUploadHandle={savedispatch} 
                                                            multiple={false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_DummurageValue'
                                                            label='Demmurage Value'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_DummurageValue}
                                                        />
                                                    </Col>
                                                    <FormFields.InputField
                                                            md={3}
                                                            name='dummurageValue'
                                                            label='Demmurage Value'
                                                            classname='pt-1-rem'
                                                            type='text'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            val={formik.values.dummurageValue}
                                                        />
                                                    <Col md={3} className='pt-1-rem'>
                                                        <ButtonComponent.UploadButton
                                                            value='Document'
                                                            filename={formik.values.dummurageValueDocumentName}
                                                            document={formik.values.dummurageValueDocumentName}
                                                            onUploadHandle={savedemmurage} 
                                                            multiple={false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='border-bottom-1 pb-4 pt-2'>
                                                    <Col md={4} className='d-flex align-items-center'>
                                                        <Form.Check
                                                            className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                                            type='checkbox'
                                                            name='is_Discharge'
                                                            label='Discharge Document'
                                                            onChange={formik.handleChange}
                                                            onBlur = {formik.handleBlur}
                                                            checked={formik.values.is_Discharge}
                                                        />
                                                    </Col>
                                                    <Col md={3}></Col>
                                                    <Col md={3} className='pt-1-rem'>
                                                        {console.log(formik.values.dischargeDocumentDetail)}
                                                        {formik.values.dischargeDocumentDetail.length>0 ?
                                                        <BlueBorderButton
                                                            value='View Documents'
                                                            clickEvent={toggle}
                                                        />
                                                        :
                                                        <ButtonComponent.UploadButton 
                                                            value='Documents'
                                                            onUploadHandle={saveDocument} 
                                                            multiple={true}
                                                        />
                                                        }
                                                        
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Card.Body>
                                        <Card.Footer className='form-card-footer p-1-rem d-flex'>
                                        
                                            <span className='pl-10'><BlueButton value='Save' clickEvent={saveDischargeInfo}/></span>
                                        </Card.Footer>
                                    </Card>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        )
    }
    