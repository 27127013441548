import React from 'react'
import Moment from 'moment'
import NumberFormat from 'react-number-format';
import styles from "./greetings.module.css"
function Index(props) {
    
    const handleTimeGretting = () => {
        var currentHour = Moment().format("HH");
        const hour = Moment().hour();
        console.log(hour)
        if (hour > 17){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Evening
                </div>
            );
        }

         if (hour > 12){
            return (
                <div className = {` ${styles.greetingHourText}`}>
                    Good Afternoon
                </div>
            );
         }

         return (
            <div className = {` ${styles.greetingHourText}`}>
                Good Morning
            </div>
        );
    }
  return (
    <>
                <div  className = {`${styles.greetingContainer}`}>
                    <div className = 'd-flex align-items-center'>
                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{background:'#FFCE22', backgroundColor:"#FFCE22", width: '50px', height: '50px', fontSize: '12px' }}>
                            <p className='font-16 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                        </div>
                        <div className = 'd-flex flex-column ms-2'>
                            {handleTimeGretting()}
                            <div className = {`${styles.greetingNameText}`}>
                                {localStorage.getItem('UserFullName')}
                            </div>
                        </div>
                    </div>
                    <div className = 'row'>
                        <div className = 'col-md-6 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-general-taskcompleted-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.meetingDetails.taskCompletedCount != null ? props.meetingDetails.taskCompletedCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        tasks completed
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-6 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-general-meeting-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.meetingDetails.meetingCompletedCount != null ? props.meetingDetails.meetingCompletedCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        meetings completed
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className = 'row'>
                        <div className = 'col-md-6 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-general-taskpending-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.meetingDetails.pendingTaskCount != null ? props.meetingDetails.pendingTaskCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        pending tasks
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-6 col-sm-12 pt-4'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-general-meeting-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 ${styles.title}`}>
                                        <NumberFormat value={props.meetingDetails.upComingMeetingCount != null ? props.meetingDetails.upComingMeetingCount : 0} displayType={'text'} thousandSeparator={true}  />
                                    </p>
                                    <p className = {`my-2 ${styles.text}`}>
                                        upcoming meetings
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default Index