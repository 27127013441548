import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import FormComponent from './components/FormComponent';
import ListData from './components/ListData'
import { AddNewButton } from '../../../../../components/buttoncomponents';


function SubsidiaryCompanyType(props) {
    console.log(props)
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [is_DefaultSelected, setIsPrimarySelected] = useState(false)
    const [selectedBank, setSelectedBank] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirstEdit, setIsFirstEdit] = useState(true)
    useEffect(()=>{
      if(isFirstLoad){
        if(props.subsidiaryCompanyList.length>0){
          setIsAdd(false)
        }
      }
      setIsFirstLoad(false)
      setIsPrimarySelected(false)
        props.subsidiaryCompanyList.map((d)=>{
            if(d.is_Default){
                setIsPrimarySelected(true)
                console.log(is_DefaultSelected,'inside list contract')
            }
        })
    })

    useEffect(()=>{
      console.log()
      if(props.isEdit == true && isFirstEdit == true){
        console.log(props.subsidiaryCompanyList, 'bank lisr')
        setIsAdd(false)
        setIsFirstEdit(false)
      }
    }, [])
    useEffect(()=> {
      if(props.isDirectorSet == true){
        setIsAdd(false)
      }
    },[])
    useEffect(()=> {
      if(props.isBackClicked == true){
        setIsAdd(false)
      }
    }, [])
    const openAddCustomer =()=>{
      setIsAdd(true);
    }

    const addDirectorToList= (data)=>{
        console.log('data on addDirectorToList function')
      setIsAdd(false);
      props.addSubsidiaryCompanyToList(data)
    }

    const editBank =(e)=>{
      console.log('uibsde')
      const key = parseInt(e.target.id)
      const bank = props.subsidiaryCompanyList.filter(x=> x.key == key)
      setSelectedBank(bank[0])
      setIsAdd(true);
      setIsEdit(true);
    }

    const editBankToCustomer= (data)=>{
      setIsAdd(false);
      setIsEdit(false);
      props.editSubsidiaryToList(data)
    }
    
    const handleCancel = () => {
      setIsAdd(false)
    }
    return (
        <>
        <>
            <Row className='d-flex' style={{background: '#fff'}}>
            <div className='font-16 font-weight-600 color-707895 d-flex justify-content-between'>
                    Subsidiary Company Type
                <span style={{display: isAdd?'none':'block'}}><AddNewButton value='Add New Subsidiary' clickEvent={openAddCustomer}/></span>
            </div>
                {!isAdd?
                <div className='mt-2-rem'>
                 
                    <table className="table table-text mt-1-rem">
                        <thead>
                        <tr className='bg-F8F9FA font-weight-500 color-707895'>
                        <th scope='col' className=' font-weight-500'>Subsidiary Company Name</th>
                        <th scope='col' className=' font-weight-500'>Share Type</th>
                        <th scope='col' className=' font-weight-500'>Country</th>
                        <th scope='col' className=' font-weight-500'>Share Percentage</th>
                        <th scope='col'></th>
                        <th scope='col'></th>
                        </tr>
                        </thead>
                        <tbody>
                            {props.subsidiaryCompanyList.map((values)=>(
                            <ListData  is_Default={values.is_Default} is_DefaultSelected={is_DefaultSelected} is_DefaultAvailable={values.is_Default?true:false} values={values} subsidiaryCompanyList ={props.subsidiaryCompanyList} setPrimary={props.setPrimary} removePrimary={props.removePrimary}  editBank={editBank}/>
                            
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                <FormComponent handleCancel= {handleCancel} handleValidationSubsidiary = {props.handleValidationSubsidiary} setErrors = {props.setErrors} addDirectorToList = {addDirectorToList}  isEdit={isEdit} data={selectedBank} editDirectorToList={editBankToCustomer}/>
            }
            </Row>
            </>
        </>
        
    )
}

export default SubsidiaryCompanyType