import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import * as CONFIG from "../../../../helpers/config"
import { useFormik } from 'formik'
import axios from 'axios'
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import * as FormFields from "../../../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import { useMsal } from "@azure/msal-react";
import ViewInvoice from '../../../Invoices/CustomerPerformaInvoice/ViewInvoice';
import ViewSupplierPurchaseOrder from '../../InvoiceLists/Components/ViewSupplierPurchaseOrder'

export default function Preshipmentview(props) {
    const tradeData = props.tradeData
    const { instance } = useMsal();
    const [modal, setModal] = useState(props.view)
    const [doc1, setdoc1] = useState(false)
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [selectedDetails, setSelectedDetail] = useState([])
    const [customerProformaInvoiceData, setPurchaseOrder] = useState()
    const [isPOOpen, setIsPOOpen] = useState(false)
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }
    const formik = useFormik({
        initialValues: {
            tradePreShipmentCheckListID: 0,
            is_IssueProformaInvoice: false,
            issueProformaInvoiceNo: '',
            issueProformaInvoiceDocument: '',
            issueProformaInvoiceDocumentName: '',
            is_IssueSupplierPurchaseOrder: false,
            issueSupplierPurchaseOrderNo: '',
            issueSupplierPurchaseOrderDocument: '',
            issueSupplierPurchaseOrderDocumentName: '',
            is_SupplierProformaInvoice: false,
            supplierProformaNo: "",
            supplierProformaInvoiceDocument: "",
            supplierProformaInvoiceDocumentName: "",
            is_FixVessel: false,
            fixVesselDocument: '',
            fixVesselDocumentName: '',
            is_LcCustomer: false,
            lcCustomerDocument: '',
            lcCustomerDocumentName: '',
            is_LcSupplier: false,
            lcSupplierDocument: '',
            lcSupplierDocumentName: '',
            Remarks: '',
        }
    })
    useEffect(() => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setPurchaseOrder(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.tradeID // TradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PRE_SHIPMENT_BY_TRADEID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.length > 0) {
                    let result = res.data.result[0]
                    formik.values.tradePreShipmentCheckListID = result.tradePreShipmentCheckListID
                    formik.values.is_IssueProformaInvoice = result.is_IssueProformaInvoice
                    formik.values.issueProformaInvoiceNo = result.issueProformaInvoiceNo
                    formik.values.issueProformaInvoiceDocument = result.issueProformaInvoiceDocument
                    formik.values.issueProformaInvoiceDocumentName = result.issueProformaInvoiceDocumentName
                    formik.values.is_IssueSupplierPurchaseOrder = result.is_IssueSupplierPurchaseOrder
                    formik.values.issueSupplierPurchaseOrderNo = result.issueSupplierPurchaseOrderNo
                    formik.values.issueSupplierPurchaseOrderDocument = result.issueSupplierPurchaseOrderDocument
                    formik.values.issueSupplierPurchaseOrderDocumentName = result.issueSupplierPurchaseOrderDocumentName
                    formik.values.is_SupplierProformaInvoice = result.is_SupplierProformaInvoice
                    formik.values.supplierProformaNo = result.supplierProformaNo
                    formik.values.supplierProformaInvoiceDocument = result.supplierProformaInvoiceDocument
                    formik.values.supplierProformaInvoiceDocumentName = result.supplierProformaInvoiceDocumentName
                    formik.values.is_FixVessel = result.is_FixVessel
                    formik.values.fixVesselDocument = result.fixVesselDocument
                    formik.values.fixVesselDocumentName = result.fixVesselDocumentName
                    formik.values.is_LcCustomer = result.is_LcCustomer
                    formik.values.lcCustomerDocument = result.lcCustomerDocument
                    formik.values.lcCustomerDocumentName = result.lcCustomerDocumentName
                    formik.values.is_LcSupplier = result.is_LcSupplier
                    formik.values.lcSupplierDocument = result.lcSupplierDocument
                    formik.values.lcSupplierDocumentName = result.lcSupplierDocumentName
                    formik.values.Remarks = result.remarks
                    setdoc1(!doc1)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const handleSelectTrade = () => {

        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.tradeID) //tradeid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT_DETAILS, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedDetail(res.data.result[0])
                openInvoice()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }
    const openInvoice = () => {
        console.log('inside')
        setIsInvoiceOpen(!isInvoiceOpen)
    }
    useEffect(() => {

    }, [customerProformaInvoiceData])

    const openSupplierPO = () => {
        console.log('inside')
        setIsPOOpen(!isPOOpen)
    }
    return (
        <>
            <div className='p-4 mx-3 mt-4'>
                <h4 className='font-16 font-weight-600 color-707895'>PRE-SHIPMENT UPDATES</h4>
            </div>

            <div className='add-edit-wrapper'>
                <div className='card card-body p-4 br-8 mb-4'>
                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                        <Col md={6} >
                            <Form.Check
                                className=' font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_IssueProformaInvoice'
                                label='Issue Porforma Invoice.'
                                checked={formik.values.is_IssueProformaInvoice}
                            />
                        </Col>

                        <Col md={3}>
                            <span>#{formik.values.issueProformaInvoiceNo}</span>
                        </Col>

                        <Col md={3}>
                            <Button size="md" onClick={handleSelectTrade}>
                                View
                            </Button>
                        </Col>

                    </Row>

                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>

                        <Col md={6} >
                            <Form.Check
                                className='font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_IssueSupplierPurchaseOrder'
                                label='Issue Supplier Purchase Order.'
                                checked={formik.values.is_IssueSupplierPurchaseOrder}
                            />
                        </Col>
                        <Col md={3}>
                            <span>#{formik.values.issueSupplierPurchaseOrderNo}</span>
                        </Col>
                        <Col md={3} className='pt-1-rem align-items-center'>
                            <Button lg="md" variant='primary' onClick={openSupplierPO}>View</Button>
                        </Col>


                    </Row>
                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>

                        <Col md={6} >
                            <Form.Check
                                className=' font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_SupplierProformaInvoice'
                                label='Supplier Proforma No.'
                                checked={formik.values.is_SupplierProformaInvoice}
                            />
                        </Col>
                        <Col md={3}>
                            <span>#{formik.values.supplierProformaNo}</span>
                        </Col>
                        <Col md={3}>
                            <span><a download={formik.values.supplierProformaInvoiceDocumentName} href={formik.values.supplierProformaInvoiceDocument}>View Attachment</a></span>
                        </Col>

                    </Row>


                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>

                        <Col md={6}>
                            <Form.Check
                                className='font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_FixVessel'
                                label='Fix Vessel As Per Laycan Agreed With Shippers.'
                                checked={formik.values.is_FixVessel}
                            />
                        </Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                            <span><a download={formik.values.fixVesselDocumentName} href={formik.values.fixVesselDocument}>View Attachment</a></span>
                        </Col>

                    </Row>
                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                        <Col md={6}>
                            <Form.Check
                                className=' font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_LcCustomer'
                                label='L/C from Customer.'
                                checked={formik.values.is_LcCustomer}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                            <span><a download={formik.values.lcCustomerDocumentName} href={formik.values.lcCustomerDocument}>View Attachment</a></span>
                        </Col>
                    </Row>

                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                        <Col md={6} >
                            <Form.Check
                                className='font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_LcSupplier'
                                label='L/C from Supplier.'
                                checked={formik.values.is_LcSupplier}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                            <span><a download={formik.values.lcSupplierDocumentName} href={formik.values.lcSupplierDocument}>View Attachment</a></span>
                        </Col>
                    </Row>


                    <Row className='py-4  d-flex align-items-center'>

                        <Col md={11} className='d-flex align-items-center'>
                            <FormFields.inputFieldArea
                                md={12}
                                row={6}
                                name='Remarks'
                                label='Remarks'
                                classname='pt-1-rem'
                                val={formik.values.Remarks}
                                type='text'
                                disabled={true}
                            />
                        </Col>

                    </Row>

                </div>
            </div>
            {isInvoiceOpen &&
                <ViewInvoice isOpen={isInvoiceOpen} toggle={openInvoice} data={selectedDetails != null ? selectedDetails : ''} customerProformaInvoiceData={customerProformaInvoiceData != null ? customerProformaInvoiceData : ''} />
            }



            {isPOOpen &&
                <ViewSupplierPurchaseOrder toggle={openSupplierPO} invoiceList={true} handleClose={openSupplierPO} data={tradeData} tradeID={tradeData.tradeID} />
            }

        </>
    )
}
