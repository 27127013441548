import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Greeting from "./Greeting/index"
import TradeOverview from "./Trade Overview/index"
import styles from "./styles.module.css"
import Notifications from "../Common Components/Notifications"
import Divisions from "../Common Components/Divisions/index"
import * as CONFIG from "../../../helpers/config"

function Index(props) {
    const { instance } = useMsal();
    const [customerCount, setCustomerCount] = useState()
    const [supplierCount, setSupplierCount] = useState()
    const [meetingCount, setMeetingCount] = useState()
    const [notification, setNotifications] = useState([])
    const [closedTradeDetails ,setClosedTradeDetails] = useState()
    const [shipmentDetails, setShipmentDetails] = useState()
    const [newTrades, setNewTrades] = useState()

    useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CUSTOMER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          console.log(CONFIG.AuthToken, ' CONFIG AUTHO TOKEN')
          setCustomerCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])
  
      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_SUPPLIER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setSupplierCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])
  
      useEffect(()=>{
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "userID" : CONFIG.LoginID
            
        }
      axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DB_MEETING_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setMeetingCount(res.data.result)
      }).catch((error)=>{
        //   if(error.response.status == 401){
        //       localStorage.clear();
        //       instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //       });
        //   }
      })  
      },[])


    useEffect(()=>{
        let final_json = {
          "ID": CONFIG.LoginID
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setNotifications(res.data.result)
      })
      },[])



      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
          "fromDate": props.fromDate,
          "toDate": props.toDate,
          "userID": CONFIG.LoginID,
          "dashboardTypeID": parseInt(2),
          "companyID" : parseInt(props.companyID)
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_SHIPMENT_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setShipmentDetails(res.data.result)
          //setNotifications(res.data.result)
      })
      },[props])
  
      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
          "fromDate": props.fromDate,
          "toDate": props.toDate,
          "dashboardTypeID": parseInt(2),
          "userID":CONFIG.LoginID,
          "companyID" : parseInt(props.companyID)
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CLOSED_TRADE_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setClosedTradeDetails(res.data.result)
          //setNotifications(res.data.result)
      })
      },[props])
  
      useEffect(()=>{
        let final_json = {
          "instanceID": CONFIG.PMSInstanceID,
          "accountSubscriptionID": CONFIG.AccountSubscriptionID,
          "fromDate": props.fromDate,
          "toDate": props.toDate,
          "dashboardTypeID": parseInt(2),
          "userID": CONFIG.LoginID,
          "companyID" : parseInt(props.companyID)
  
      }
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_NEW_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setNewTrades(res.data.result)
          //setNotifications(res.data.result)
      })
      },[props])


   
      useEffect(()=>{
        console.log(notification)
      },[notification])
  return (
    <>
        <div className='inner-page-content'>
            <div className = 'row'>
                <div className = 'col-md-7 col-sm-12'>
                    <div className = 'row'>
                        <Greeting companyID = {props.companyID} shipmentDetails = {shipmentDetails != null ? shipmentDetails : 0} />
                    </div>
                    <div className = 'row' style={{marginTop:'35px'}}>
                        <Notifications companyID = {props.companyID} notification = {notification != null && notification.length > 0 ? notification : '' }/>
                    </div>
                </div>
                <div className = 'col-md-3 col-sm-12'>
                    <Divisions companyID = {props.companyID} terms = {props.terms} dashboardTypeID={2} toDate = {props.toDate} fromDate = {props.fromDate}/>
                </div>
                <div className = 'col-md-2 col-sm-12 p-0'>
                        <div className = 'col-md-12 col-sm-4 '>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-customer-counter-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 gil-regular ${styles.title}`}>
                                        {customerCount}
                                    </p>
                                    <p className = {`my-2 gil-semibold ${styles.text}`}>
                                        customers
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-12 col-sm-4 pt-3'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-customer-counter-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 gil-regular ${styles.title}`}>
                                        {supplierCount}
                                    </p>
                                    <p className = {`my-2 gil-semibold ${styles.text}`}>
                                        suppliers
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className = 'col-md-12 col-sm-4 pt-3'>
                            <div className = {` ${styles.greetingCounter}`}>
                                <div className = ''>
                                    <div>
                                        <img src = '/assets/images/supports/dashboard-meeting-counter-icon.svg' alt = 'icon' />
                                    </div>
                                </div>
                                <div className = 'ps-3 pb-3'>
                                    <p className = {`m-0 gil-regular ${styles.title}`}>
                                        {meetingCount}
                                    </p>
                                    <p className = {`my-2 gil-semibold ${styles.text}`}>
                                        meetings
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className = {`mt-4 gil-semibold ${styles.titleHeader}`}>
               Trades Overview
            </div>
            <div className = 'row mt-4'>
                <div className = 'col-12 p-0'>
                    <TradeOverview 
                        closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0} 
                        shipmentDetails = {shipmentDetails != null ? shipmentDetails : 0}
                        newTrades = {newTrades != null ? newTrades : 0}
                        companyID = {props.companyID}
                    />
                </div>
            </div>
        </div>
    </>
  )
}

export default Index