import React, { useState } from 'react'
import { Dropdown, Menu, Space } from 'antd';
import LinkMeetings from '../Meetings/components/LinkMeetings';
import {Button} from 'react-bootstrap';

export default function CustomerList(props) {
    console.log(props)
    const [ismeeting, setismeeting] = useState(false)
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <>{localStorage.getItem('Edit_Customer') == 3 && <span className=' font-14 pl-10 mb-1-rem' id={props.data.custid} onClick={props.edit}>Edit</span>}</>
                ),
            },
            {
              key: '2',
              label: (
                <>{localStorage.getItem('Enable_Disable_Customer') == 3 && <span className=' font-14 pl-10 mb-1-rem width-100l' id={props.data.custid} onClick={props.disable}>{props.data.custstatus == 0 ?'Enable Customer':'Disable Customer'}</span>}</>
                ),
            },
            {
                key: '3',
                label: (
                    <span className=' font-14 pl-10 mb-1-rem width-100l' id={props.data.custid} onClick={props.terminate}>Terminate</span>
                ),
            }
          ]}
        />
      );
      const openMeeting = ()=>{
          setismeeting(!ismeeting)
      }
    return (
        <tr className='product_list_row align-items-center border-bottom-1 align-middle' style={props.data.custstatus == 0 ? {color:'#A7B3C3'} : {color:'#4F5466'}}>
            {ismeeting &&
                <LinkMeetings data={props.data} tag='Customer' toggle={openMeeting}/>
            }
            <td className = 'align-items-center d-flex py-4 px-2'>
                <center className={props.data.custstatus == 0 ? 'disabled_badge' : 'status_badge'}>{props.data.customercode} </center>
                {props.data.custstatus == 0 ? <img className='ms-2 badgesize' src = 'assets/images/supports/disablePill.svg' /> : ''}
            </td>
            <td> 
                <div className = 'align-items-center d-flex py-4 cusname '>{props.data.customername}</div>
            </td>

            <td>
                <div className = 'align-items-center d-flex py-4 w-50 cusaddress '>{props.data.address}</div></td>
            <td>
                <div className = 'align-items-center d-flex py-4 cusweight '>{props.data.category}</div></td>
            <td>
                <div className = 'align-items-center d-flex py-4 cusweight '>{props.data.incoterms}</div></td>
            <td>
                <Button variant='outline-secondary' onClick={openMeeting}>
                    <img src='assets/images/linkicon.svg'/>
                </Button>
            </td>
            <td>
                <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <img src = '/assets/images/Dot_icon.svg' />
                        </Space>
                        </a>
                    </Dropdown>
            </td>
        </tr>
    )
}
