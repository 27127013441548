import { Dropdown, Menu, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as CONFIG from "../helpers/config"
import {Row, Col, ModalFooter} from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "./formcomponents"
import { BlueButton, BlueBorderButton, CancelButton } from './buttoncomponents'
import { useFormik } from 'formik'

import { SettingsIcon, UserIcon } from './sidebar/sidebardIcons';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'


function handleLogout(instance) {
  instance.logoutRedirect().catch(e => {
      console.error(e);
      window.location.href = "/login"
  });
}
export function App(){
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    const [modal, setModal] = useState(false)
    const [isError, setIsError] = useState(false)
    const [success, setSuccess] = useState(false)
    const logout = ()=>{
        localStorage.clear();
        window.location.href = "/login"
    }

    const formik = useFormik({
      initialValues:{
        'otp': '',
        'password': ''
      }
    })
    
    const menu = (
      <Menu
        items={[
          localStorage.getItem('PLATFORM_SETUP') == 3 &&
          {
            key: '1',
            label: (
              <Link to='/organisation-settings'>
                  <SettingsIcon/>
                  <span className='font-weight-400 font-14 pl-10 mb-1-rem'>Organization Setup</span>
              </Link>
            ),
          },
          localStorage.getItem('Division-Menu') == 3 &&
          {
            key: '2',
            label: (
              <Link  to="/divisions-list">
                <SettingsIcon/>
                <span className='font-weight-400 font-14 pl-10 mb-1-rem'>Divisions</span>
              </Link>
            ),
          },
          // {
          //   key: '3',
          //   label: (
          //     <a target="_blank" rel="noopener noreferrer" href="#">
          //     <SettingsIcon/>
          //     <span className='font-weight-400 font-14 pl-10 mb-1-rem'>Departments</span>
          //     </a>
          //   ),
          // },
          localStorage.getItem('User-Menu') == 3 &&
          {
            key: '4',
            label: (
              <Link to='/users'>
                <UserIcon/>
                <span className='font-weight-400 font-14 pl-10 mb-1-rem'>Users</span>
              </Link>
              ),
          },
          {
            key: '5',
            label: (
                <a onClick={() => resetPassword()}>
                  <UserIcon/>
                  <span className='font-weight-400 font-14 pl-10 mb-1-rem' >Reset Password</span>
                </a>
              ),
          },
          {
            key: '6',
            label: (
                <a onClick={() => logout()}>
                  <UserIcon/>
                  <span className='font-weight-400 font-14 pl-10 mb-1-rem' >Logout</span>
                </a>
              ),
          },
        ]}
      />
    );
        
    const resetPassword = () =>{
      const json_list = {
        "userEmail": CONFIG.UserEmail,
        "pmsInstanceID": CONFIG.PMSInstanceID
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GENERATE_OTP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
        MySwal.fire(
          {
              icon: 'success',
              title: 'An OTP has been send to your email',
              type: 'success',
              confirmButtonText: 'Close',
              confirmButtonClass: "btn btn-primary",

          }).then(() => {
            toggle()
          });
          console.log(res.data.result)
      }).catch((error) => {
        if (error.response.status == 401) {
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
      })
    }
    const toggle = () =>{
      formik.values.password = ''
      formik.values.otp = ""
      setIsError(false)
      setSuccess(false)
      setModal(!modal)
    }

    const validateOtp = ()=>{
      toggle()
      const json_list = {
        "userEmail": CONFIG.UserEmail,
        "pmsInstanceID": CONFIG.PMSInstanceID,
        "OTP" : formik.values.otp
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.VALIDATE_OTP, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
        if(res.data.result == true){
          console.log('inside ttrue')
          formik.values.otp = ''
          setIsError(false)
          setSuccess(true)
        }
        else{
          setSuccess(false)
          setIsError(true)
        }
        toggle()
      }).catch((error) => {
        if (error.response.status == 401) {
            localStorage.clear();
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
      })
    }

    const savePassword =  ()=>{
      const json_list = {
        "userEmail": CONFIG.UserEmail,
        "pmsInstanceID": CONFIG.PMSInstanceID,
        "password" : formik.values.password
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.RESET_PASSWORD, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
        MySwal.fire(
          {
              icon: 'success',
              title: 'Password has been reset!',
              type: 'success',
              confirmButtonText: 'Close',
              confirmButtonClass: "btn btn-primary",

          }).then(() => {
            toggle()
          });
      }).catch((error) => {
        if (error.response.status == 401) {
            localStorage.clear();
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
      })
    }

    return(
      <>
        <Modal isOpen={modal} toggle={toggle} className='mt-10-rem property_info_modal mt-5'>
            <ModalHeader toggle={toggle}>
                <div className='font-16 font-weight-600 color-707895'>Reset Password</div>
            </ModalHeader>
            <ModalBody>
                <Row className='d-flex plr-2-rem'>
                  {success == false ?
                      <>
                        <Col md={10}>Please enter the OTP!</Col>
                        <FormFields.InputField
                        md={10}
                        className='mb-3'
                        name='otp'
                        label='OTP'
                        val={formik.values.otp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                      </>
                    :
                    <>
                      <Col md={10}>Please enter the new Password!</Col>
                    <FormFields.InputField
                        md={10}
                        className='mb-3'
                        name='password'
                        label='Password'
                        val={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    </>
                  }
                  {isError &&
                    <span className='input_error'>OTP validation failed. Please try again!</span>
                  }
                  </Row>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-start' >
                <CancelButton  value='Cancel' clickEvent={toggle}/>
                <BlueButton value='Submit' clickEvent ={success == true? savePassword :validateOtp}/>
            </ModalFooter>
        </Modal>
        <Dropdown overlay={menu}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                    <p className='font-12 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                </div>
                <div><span className='font-weight-500 color-23 d-none d-sm-block d-md-block'>{CONFIG.UserFullName}</span><span className='font-weight-400 color-23 d-none d-sm-block d-md-block'>{localStorage.getItem('UserDesignation')}</span></div>
                <img src="../assets/images/DownwardArrow.svg"/>
          </Space>
        </a>
      </Dropdown>
      </>
    )
}

export default App;