import React, { useEffect } from "react";
import { Dropdown, Menu, Space } from "antd";
import Moment from "moment";
import { Button } from "react-bootstrap";
import { BlueButton } from "../../components/buttoncomponents";
import { useState } from "react";
import LinkMeetings from '../Meetings/components/LinkMeetings';
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export default function TradeEnquirylist(props) {
  console.log(props)
  const [ismeeting, setismeeting] = useState(false)
  const [hideinitiate, sethideinitiate] = useState(false)
  const [hideedit, sethideedit] = useState(false)
  const handleInitiateEvent = () => {
    props.handleTradeInitiate(props);
  };
  useEffect(() => {
    let totalsupply = props.data.scheduledSupply
    let initiated_supply = props.data.tradeSupply
    let arr1 = totalsupply.split(' ');
    let arr2 = initiated_supply.split(' ')
    let count1 = parseInt(arr1[0])
    let count2 = parseInt(arr2[0])
    if (count2 > 0) {
      sethideedit(true)
    }
    if (count1 == count2) {
      sethideinitiate(true)
    }
    else {
      sethideinitiate(false)
    }
  }, [props])

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <>
              {localStorage.getItem('Edit_Trade_Enquiry') == 3 &&
                <span
                  className="table-ttile-color font-14 font-weight-400"
                  id={props.data.enqiryid}
                  onClick={props.edit}
                >
                  <img src='assets/images/Edit_Icon.svg' />Edit
                </span>
              }
            </>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //     <span
        //       className="table-ttile-color font-14 font-weight-400"
        //       id={props.data.enqiryid}
        //       onClick={props.terminate}
        //     >
        //       View
        //     </span>
        //   ),
        // },
      ]}
    />
  );
  const openMeeting = () => {
    setismeeting(!ismeeting)
  }
  return (
    <tr
      id={props.data.enqiryid}
      className="product_list_row  border-bottom-1 align-middle">
      {ismeeting &&
        <LinkMeetings data={props.data} tag='CustomerRequirement' toggle={openMeeting} />
      }
      <td className="align-items-center">
        <div className="align-items-center d-flex table-ttile-color font-14 font-weight-600">
          <div>
            <div>{props.data.enquiryno}{" "}</div>
            <div className="table-ttile-color-sub font-12 font-weight-400">{Moment(props.data.enquirydate).format("DD/MM/YYYY")}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="table-ttile-color font-14 font-weight-600">
          {" "}
          {props.data.customercode}
          <br />
          <span className="table-ttile-color-sub font-12 font-weight-400">{props.data.customername}</span>
        </div>
      </td>
      <td>
        {props.data.productdetails.map((list) => {
          return (
            <>
              <div className="table-ttile-color font-14 font-weight-600">
                {" "}
                {list.productCode}<br />{list.productName}
                <span className="table-ttile-color-sub font-12 font-weight-400">
                  &nbsp;&nbsp;{list.productQuantity}&nbsp;{" "}
                  {list.productUOMName}
                </span>
              </div>
            </>
          );
        })}
      </td>
      <td>
        <div className="table-ttile-color font-14 font-weight-600">
          {" "}
          {props.data.contypewithincoterms}
        </div>
      </td>
      <td>
        <div className="">
          <div className="table-ttile-color font-14 font-weight-600">
            {props.data.tradeSupply}
          </div>
          <div className="table-ttile-color font-13 font-weight-500">
            {" "}
            {props.data.scheduledSupply}
          </div>
        </div>
      </td>
      <td>
        {props.data.enqapprovalstatus == 1 &&
          <div className=""><img src='assets/images/ellipse_pending.svg' />&nbsp;&nbsp;Approval Pending</div>
        }
        {props.data.enqapprovalstatus == 2 &&
          <div><img src='assets/images/ellipse_check.svg' />&nbsp;&nbsp;Approved</div>
        }
        {props.data.enqapprovalstatus == 9 &&
          <div><img src='assets/images/rejected-icon.svg' />&nbsp;&nbsp;Rejected</div>
        }
      </td>
      <td>
        <Button variant='outline-secondary' onClick={openMeeting}>
          <img src='assets/images/linkicon.svg' />
        </Button>
      </td>
      <td className=''>
        {localStorage.getItem('Initiate_Trade') == 3 &&
          <>
            {props.data.enqapprovalstatus == 2 &&
              <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600" onClick={handleInitiateEvent} disabled={hideinitiate}>Initiate Trade</button>
            }
          </>
        }

        {/* <Button className='btn btn-info text-white bg-primary font-12' onClick={handleInitiateEvent}>Initiate Trade</Button>    */}
      </td>

      <td className="">
        {!hideedit &&
          < Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <img src="/assets/images/Dot_icon.svg" />
              </Space>
            </a>
          </Dropdown>
        }
      </td>
    </tr >
  );
}
