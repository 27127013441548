import React, { useState, useEffect } from 'react'
import {Row, Col, Form, Card} from "react-bootstrap";
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import ProductDetails from './Product Details/ProductDetails';
import SupplyPlanDetails from './Supply Plan Details/SupplyPlanDetails';


function SupplyPlanTab(props) {
    console.log(props, 'supply plan')
    const [updateProductDetails, setUpdateProductDetails] = useState()
    const [disable, setDisable] = useState(true)
    const [prevClicked, setPrevClicked] = useState(false)
    const [disabled, setDisabled] = useState (true)
   
    useState(()=>{
        console.log(props)
        if(props.prevClicked == true){
            
            setDisabled(false)
        }
    },[props, disabled])
    const handleUpdateProductDetails = (data) => {
        console.log(data,props, 'product for update')
        props.productData.map((values,k)=>{
            if(values.key == data.key){
                Object.assign(values, data)
            }
        })
        console.log(props,data)
        setUpdateProductDetails(data)
    }
    const handleBack = () => {

        props.back()
    }
    useEffect(()=>{
        console.log(props, props)
        if(props.prevClicked == true && props.isFirst == true){
            setDisable(false)
        }
    },[])

    const handleDisable = () =>{
        setDisable(false)
    }

    const handleTriggerDisable = () => {
        setDisable(false)
    }

    const handleDisableEnd = () => {
        console.log('Disabled')
        setDisabled(false)
    }

    useEffect(()=>{
        if(props.isEdit == true){
            setDisabled(false)
        }
    },[])
    console.log(props, 'product for update')
    return (
        <>
            <Card style={{background: 'transparent'}}>
                <Card className='mb-10-rem'  style={{ overflowY:'scroll',  height: '50vh', background:'transparent'}}>
                    <Card.Body className='form-card-body mb-2-rem' style={{ background: '#fff'}}>
                        <ProductDetails contractterms = {props.contractterms} handleDisableEnd = {handleDisableEnd}  handleIsFirst = {props.handleIsFirst} handleDisable = {handleDisable} updateProductList = {updateProductDetails} handleUpdateProductDetails={handleUpdateProductDetails} productData={props.productData} saveList={props.saveList} isEdit={props.isEdit}/>
                    </Card.Body>
                </Card>
                <Card.Footer className='form-card-footer py-2-rem pt-4 d-flex'>
                    <span><BlueBorderButton value='Prev' clickEvent={handleBack}/></span>
                    <span className='pl-10'><BlueButton value='Submit' clickEvent={()=>{
                    props.addTradeEnquiry()
                    setDisabled(true)
                }} disabled={disabled}/></span>
                </Card.Footer>
            </Card>
        </>
    )
}

export default SupplyPlanTab