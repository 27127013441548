import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Button } from "reactstrap"
import { Row, Form } from "react-bootstrap"
import { useState } from 'react'
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Moment from "moment";
import { BlueBorderButton, BlueButton } from "../../../components/buttoncomponents";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import NumberFormat from 'react-number-format';

export default function TradeDetails(props) {
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [tradedetail, settradedetail] = useState([])

    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.id),
            "companyID" : props.companyID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.RPT_TRADE_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                settradedetail(res.data.result)
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
                console.log(error)
            })
    }, [])

    const toggle = () => {
        setmodal(!modal)
        props.close()
    }

    //const toggle = () => {
    //    setModal(!modal)
    //    props.close();
    //}

    const printDocument = () => {
        const domElement = document.getElementById("download_section_detail");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('TradeDetailsReports.pdf');
                // var doc = new jsPDF("p", "pt", "letter");
                // doc.html(document.getElementById("download_section"), {
                //     callback: function (doc) {
                //       doc.save();
                //     },
                //     margin: 10
                // });


            })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer">
            <ModalHeader toggle={toggle} className='reports_details'>
                <div className='width-100l d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <p className='font-weight-400 color-697382 mb-1 ms-5'>TRADE DETAILS</p>
                    </div>
                    <div className='me-5'>
                        <img src='assets/images/icon_pdf.svg' className='img-fluid' onClick={printDocument} />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper scrollContract' style={{ padding: '0' }} id='download_section_detail'>

                <Row style={{ margin: '2rem' }}>
                    <div class="table-responsive-sm  card card-body">
                        <div className='bg-white p-0 p-md-4  br-8' >
                            {tradedetail.length > 0 &&

                                <div className="row">
                                    <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                        <p className='font-weight-400 color-697382 mb-1'>TRADE#</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].tradeNo}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                        <p className='font-weight-400 color-697382 mb-1'>CUSTOMER</p>
                                        <div>
                                            <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{tradedetail[0].customername}</h6>
                                            <span className='tradeTrsactionsid statBg '>{tradedetail[0].customercode}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                        <p className='font-weight-400 color-697382 mb-1'>SUPPLIER</p>
                                        <div>
                                            <h6 className='font-weight-600 font-14 mb-0 textHead pb-2'>{tradedetail[0].suppliername}
                                            </h6>

                                            <span className='tradeTrsactionsid statBg '>{tradedetail[0].suppliercode}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0'>
                                        <p className='font-weight-400 color-697382 mb-1'>DATE</p>
                                    <h6 className='font-weight-600 font-14 mb-0 textHead'>{Moment(tradedetail[0].tradeClosedDate).format("DD/MM/yyyy")}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>PRODUCT DETAILS</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].productName}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>QUANTITY</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>
                                            <NumberFormat value={tradedetail[0].qty} displayType={'text'} thousandSeparator={true} />
                                            <span></span> {tradedetail[0].prodUOM}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>MODE OF TRANSPORT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].modeofTransport}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>INCOTERMS</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].incoterms}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>MODE OF PAYMENT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].customerPayment}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>PURCHASE AMOUNT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{parseFloat(tradedetail[0].tradepurchaseValue).toFixed(2)}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>SALE AMOUNT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{parseFloat(tradedetail[0].tradeValue).toFixed(2)}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>PORT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].loadport}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>DESTINATION PORT</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].destinationport}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>LOAD PORT COUNTRY</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].loadportcountry}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>VESSEL NAME</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].vesselname}
                                        </h6>
                                    </div>
                                    <div className='col-12 col-md-3 mb-3 mb-md-0 mt-5'>
                                        <p className='font-weight-400 color-697382 mb-1'>DESTINATION PORT COUNTRY</p>
                                        <h6 className='font-weight-600 font-14 mb-0 textHead'>{tradedetail[0].destinationcountry}
                                        </h6>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><BlueBorderButton value='Cancel' clickEvent={toggle} /></span>
                <span className='pl-10'><BlueBorderButton value="EXPORT PDF" clickEvent={printDocument} /></span>
            </ModalFooter>
        </Modal>
    )

}