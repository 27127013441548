import React from 'react'
import { useEffect, useState } from 'react'
import { useMsal } from "@azure/msal-react";
import * as CONFIG from "../../../helpers/config"
import axios from 'axios'
import Moment from 'moment'
import { Col, Row, Accordion, Form, Button, Card } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as FormFields from "../../../components/formcomponents"
import FileBase64 from 'react-file-base64';
import { useFormik } from 'formik';
import ViewMeetingReport from './ViewMeetingReport';
import { Tooltip } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { set } from 'date-fns';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BlueButton, BlueBorderButton, CancelButton } from '../../../components/buttoncomponents'

export const HandleTaskAttachments = (props) => {
    const { instance } = useMsal();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [jsonList, setJsonList] = useState([])

    const formik = useFormik({
        initialValues: {
            description: ''
        }
    })
    useEffect(()=>{
        setJsonList([])
    },[props])
    const toggle = () => {
        setIsModalOpen(!isModalOpen)
    }
    const saveAttachments = () => {
        let count = jsonList.length
        let current = 0
        jsonList.map((list, k) => {
            list.attachmentDescription = formik.values.description
            current++
        })
        console.log(jsonList)

        if (current == count) {
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_ATTACHMENTS, jsonList, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    toggle()
                    props.getmeetingbyid()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }
    const listAttachment = (file) => {
        console.log(file)
        let json_list = []
        let json_in = {
            "meetingAgendaTaskID": props.id,
            "taskAttahchment": file.base64,
            "attachmentName": file.name,
            "attachmentDescription": formik.values.description,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        json_list.push(json_in)
        setJsonList(json_list)
    }
    useEffect(()=>{
        console.log(jsonList)
    },[jsonList])
    return (
        <>
            <Modal isOpen={isModalOpen} toggle={toggle} className='mt-10-rem property_info_modal mt-5'>
                <ModalHeader toggle={toggle}>
                    <div className='font-16 font-weight-600 color-707895'>ATTACHMENTS</div>
                </ModalHeader>
                <ModalBody>
                    <Row className='d-flex plr-2-rem justify-content-center'>
                        <Col md={10} className='mb-1-rem'>
                            <div>
                                <label className={'upload-document-button font-12 font-weight-600 d-flex justify-content-center align-items-center color-707895 cursor-pointer '}>
                                    <div className=" ">
                                        <div className='d-flex align-items-end '>

                                            <div className='mx-2 d-flex align-items-end uploadText '>
                                                <img src='assets/images/attachfileicon.svg' />
                                                Upload
                                            </div>
                                            <FileBase64 className="form_input" onDone={listAttachment} />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            {jsonList.map((list,k)=>{
                                return(
                                    <div>
                                        <a download={list.taskAttahchment} href={list.attachmentName}>{list.attachmentName}</a>
                                    </div>

                                )
                            })}
                        </Col>
                        <FormFields.inputFieldArea
                            name='description'
                            label='Description'
                            md={10}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                    </Row>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-start' >
                    <CancelButton value='Cancel' clickEvent={toggle} />
                    <BlueButton value='Save' clickEvent={saveAttachments} />
                </ModalFooter>
            </Modal>
            <div className='p-0_5-rem'>
                <label className={'task_button_attachment font-12 font-weight-600 d-flex justify-content-center align-items-center color-707895 cursor-pointer '}>
                    <div className=" ">
                        <div className='d-flex align-items-end '>
                            <div className='mx-2 d-flex align-items-end uploadText '>
                                <img src='assets/images/paperclip.svg' onClick={toggle} />
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        </>
    )
}

export default function ViewMeetingInfo(props) {
    const { instance } = useMsal();
    const [meeting, setMeeting] = useState([])
    const [userlist, setuserlist] = useState([])
    const [commentstatus, setcommentstatus] = useState([])
    const [commentlist, setcommentlist] = useState([])
    const [commenterror, setcommenterror] = useState(false)
    const [viewstatuts, setviewstatus] = useState(false);
    const [premetingStatus, setPremeetingStatus] = useState(false)
    const [isPremeetingEdit, setIsPremeetingEdit] = useState(false)
    const [premeetingId, setPremeetingId] = useState(0)
    const [isEditNotifyUser, setIsEditNotifyUser] = useState(false)
    const [isEditRequiredUser, setIsEditRequiredUser] = useState(false)
    const [attendeeslist, setAttendeeslist] = useState([])
    const [notifylist, setNotifylist] = useState([])
    const [selectednotifylist, setSelectednotifylist] = useState([])
    const [doc, setDoc] = useState(false)
    const [agendaindex, setAgendaindex] = useState(0)
    const [isAgendaEdit, setIsAgendaEdit] = useState(false)
    const [isAgendaAdd, setIsAgendaAdd] = useState(false)
    const [selectedTaskAgendaId, setSelectedTaskAgendaId] = useState(0)
    const [selectedAgendaTaskId, setSelectedAgendaTaskId] = useState(0)
    const [isTaskEdit, setIsTaskEdit] = useState(false)
    const [taskduedate, setTaskduedate] = useState(false)
    const [taskassignedtouser, setTaskassignedtouser] = useState(0)
    const [isTaskChange, setIsTaskChange] = useState(false)
    const [isSuggestion, setIsSuggestion] = useState(false)
    const [isAddSuggestion, setIsAddSuggestion] = useState(false)
    const [isSuggestionTask, setIsSuggestionTask] = useState(false)
    const [isSuggestionTaskAdd, setIsSuggestionTaskAdd] = useState(false)
    const [agendaSuggestionId, setAgendaSuggestionId] = useState(0)
    const [agendaSuggestionTaskId, setAgendaSuggestionTaskId] = useState(0)
    const [isAddKey, setIsAddKey] = useState(false)
    const [discussionAgendaId, setDiscussionAgendaId] = useState(0)
    const [attendeescount, setAttendeescount] = useState(0)

    useEffect(() => {
        getmeetingbyid()
    }, [])


    const getmeetingbyid = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.id
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setMeeting(res.data.result[0])
                setAttendeescount(res.data.result[0].meeting.meetingRequiredAttendance.length)
                getUsers()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setuserlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }


    const formik = useFormik({
        initialValues: {
            comment: ''
        },
        validate: values => {
            setcommenterror(false)
        }
    })

    useEffect(() => {
        console.log(commentstatus)
        console.log(commentlist)
    }, [commentstatus, commentlist])

    const showAddComments = (e) => {
        let index = e.target.id
        handleTaskComment(index)
        let commentst = [...commentstatus]
        commentst['"' + index + '"'] = true
        setcommentstatus(commentst)
    }
    const handleTaskComment = (id) => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "id": parseInt(id),
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_TASK_COMMENTS, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                let cmtlist = [...commentlist]
                cmtlist['"' + id + '"'] = res.data.result
                setcommentlist(cmtlist)
                console.log(commentlist)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }


    const removeComment = () => {
        let commentst = [...commentstatus]
        commentst = []
        setcommentstatus(commentst)
    }
    const saveComment = (e) => {
        const id = e.target.id
        if (formik.values.comment != '') {
            setcommenterror(false)
            const json_list = {
                "meetingAgendaTaskID": parseInt(id),
                "comment": formik.values.comment,
                "commentedBy": CONFIG.LoginID,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_TASK_COMMENTS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    handleTaskComment(parseInt(id))
                    let commentst = [...commentstatus]
                    commentst = []
                    setcommentstatus(commentst)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            setcommenterror(true)
        }
    }

    const removeDocument = (e) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(e.currentTarget.id)
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DELETE_MEETING_ATTACHMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const viewMeetingReport = () => {
        setviewstatus(!viewstatuts)
    }

    const saveOtherPoints = () => {
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": formik.values.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": CONFIG.LoginID
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editOtherPoint = () => {
        console.log('inside')
        let meet = { ...meeting }
        let meetingInPoint = meeting.meeting.meetingOtherIntPoints
        formik.values.meetingOtherIntPoints = meetingInPoint
        formik.values.tempmeetingOtherIntPoints = meetingInPoint
        meet.meeting.meetingOtherIntPoints = null
        console.log(meet)
        setMeeting(meet)
    }

    useEffect(() => {
        if (Object.keys(meeting).length > 0) {
            if (Object.keys(userlist).length > 0) {
                let usrlist = []
                let selected = []
                console.log(meeting)
                meeting.meeting.meetingRequiredAttendance.map((list, k) => {
                    let filter = userlist.filter(x => x.meetingUserID == list.meetingUserID)[0]
                    usrlist.push(filter)
                })
                meeting.meeting.meetingNotifyUser.map((list1, k) => {
                    let filter = userlist.filter(x => x.meetingUserID == list1.meetingUserID)[0]
                    selected.push(filter)
                })
                console.log(usrlist, 'notify user list')
                setNotifylist(usrlist)
                setSelectednotifylist(selected)
            }
        }
    }, [meeting, userlist])

    const addPremeeting = () => {
        let tasklist = {
            "task": formik.values.premeetingTask,
            "is_PreMeetingPoints": 1,
            "is_Completed": false
        }
        meeting.meeting.meetingAgendaTask.push(tasklist)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.premeetingTask = ''
                setPremeetingStatus(!premetingStatus)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const deletePremeeting = (id) => {
        let filter = meeting.meeting.meetingAgendaTask.filter(x => x.meetingAgendaTaskID == id)
        filter[0].is_Active = false
        filter[0].is_Deleted = true
        let remaining = meeting.meeting.meetingAgendaTask.filter(x => x.meetingAgendaTaskID != id)
        remaining.push(filter[0])
        meeting.meeting.meetingAgendaTask = remaining
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editPremeetingTask = (id) => {
        let premeeting = meeting.meeting.meetingAgendaTask.filter(x => x.meetingAgendaTaskID == id)[0]
        formik.values.premeetingTask = premeeting.task
        setPremeetingId(id)
        setIsPremeetingEdit(!isPremeetingEdit)
        setPremeetingStatus(!premetingStatus)
    }

    const updatePremeeting = () => {
        let premeeting = meeting.meeting.meetingAgendaTask.filter(x => x.meetingAgendaTaskID == premeetingId)[0]
        let remaining = meeting.meeting.meetingAgendaTask.filter(x => x.meetingAgendaTaskID != premeetingId)
        premeeting.task = formik.values.premeetingTask
        remaining.push(premeeting)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": remaining,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.premeetingTask = ''
                setPremeetingStatus(!premetingStatus)
                setIsPremeetingEdit(!isPremeetingEdit)
                setPremeetingId(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handlenotifyusers = (event, values) => {
        let notify = []
        if (Array.isArray(values)) {
            values.map((val, k) => {
                let ispresent = meeting.meeting.meetingNotifyUser.filter(x => x.meetingUserID == val.meetingUserID)
                if (ispresent.length > 0) {
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    notify[k] = ispresent[0]
                }
                else {
                    notify[k] = {
                        "meetingUserID": val.meetingUserID
                    }
                }
            })

            let preselected = notify.filter(x => x.meetingAgendaTaskNotifyUserID != null)
            meeting.meeting.meetingNotifyUser.map((at, l) => {
                let ispresentinnew = preselected.filter(x => x.meetingUserID == at.meetingUserID)
                if (ispresentinnew.length > 0) {
                    //console.log(at)
                }
                else {
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    notify.push(newlist)
                }
            })
            formik.values.notifyuserlist = notify
            setDoc(!doc)

        }
        else {
            formik.values.notifyuserlist = values.meetingUserID
            setDoc(!doc)
        }
        console.log(formik.values.notifyuserlist)
    }

    const updateNotifyList = () => {
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": formik.values.notifyuserlist,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.notifyuserlist = []
                setIsEditNotifyUser(!isEditNotifyUser)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handlerequiredattendees = (event, values) => {
        console.log(values)
        let atlist = []
        if (Array.isArray(values)) {
            values.map((val, k) => {
                let ispresent = meeting.meeting.meetingRequiredAttendance.filter(x => x.meetingUserID == val.meetingUserID)
                if (ispresent.length > 0) {
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    atlist[k] = ispresent[0]
                }
                else {
                    atlist[k] = {
                        "meetingUserID": val.meetingUserID
                    }
                }
            })
            let preselected = atlist.filter(x => x.meetingRequiredAttendanceID != null)
            meeting.meeting.meetingRequiredAttendance.map((at, l) => {
                let ispresentinnew = preselected.filter(x => x.meetingUserID == at.meetingUserID)
                if (ispresentinnew.length > 0) {
                    //console.log(at)
                }
                else {
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    atlist.push(newlist)
                }
            })
            console.log(atlist)
            formik.values.attendesslist = atlist
            setDoc(!doc)
        } else {
            formik.values.attendesslist = values.meetingUserID
            setDoc(!doc)
        }
    }

    const updateRequiredAttendees = () => {
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.attendesslist = []
                setIsEditRequiredUser(!isEditRequiredUser)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editAgenda = (id) => {
        let filter = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == id)[0]
        formik.values.agendaName = filter.agendaName
        setAgendaindex(id)
        setIsAgendaEdit(!isAgendaEdit)
    }

    const updateAgenda = () => {
        let filter = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == agendaindex)[0]
        let remaining = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID != agendaindex)
        filter.agendaName = formik.values.agendaName
        remaining.push(filter)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": remaining,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.agendaName = ''
                setIsAgendaEdit(!isAgendaEdit)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const removeAgenda = (id) => {
        let filter = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == id)[0]
        let remaining = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID != id)
        filter.is_Active = false
        filter.is_Deleted = true
        remaining.push(filter)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": remaining,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const AddAgenda = () => {
        const agendalis = meeting.meeting.meetingAgenda
        let agenda = {
            agendaname: formik.values.agendaName,
            meetingAgendaTask: []
        }
        agendalis.push(agenda)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": agendalis,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.agendaName = ''
                setIsAgendaAdd(false)
                setIsAgendaEdit(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const saveDiscussionPoint = (id) => {
        let filter = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == id)[0]
        let remaining = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID != id)
        filter.discussionPoint = formik.values.discussionPoint
        remaining.push(filter)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": remaining,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                formik.values.discussionPoint = ''
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editTask = (agendaid, taskid) => {
        setSelectedTaskAgendaId(agendaid)
        setSelectedAgendaTaskId(taskid)
        setIsTaskEdit(true)
        let agenda = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == agendaid)[0]
        console.log(agenda)
        let task = agenda.meetingAgendaTask.filter(x => x.meetingAgendaTaskID == taskid)[0]
        console.log(task)
        formik.values.taskDescription = task.taskDescription
        formik.values.task = task.task
        formik.values.is_Completed = task.is_Completed
        setTaskduedate(task.taskDueDate)
        setTaskassignedtouser(task.meetingUserID)
    }
    const handleAssignedTo = (event, values) => {
        setTaskassignedtouser(values.meetingUserID)
    }

    const handleTaskDueDate = (date) => {
        if (date == null) {
            setTaskduedate(false)
        }
        else {
            setTaskduedate(date)
            console.log(taskduedate)
        }
    }

    const updateTask = () => {
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == selectedTaskAgendaId)
        let agenda = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == selectedTaskAgendaId)[0]
        let taskindex = agenda.meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == selectedAgendaTaskId)
        agenda.meetingAgendaTask[taskindex].task = formik.values.task
        agenda.meetingAgendaTask[taskindex].taskDescription = formik.values.taskDescription
        agenda.meetingAgendaTask[taskindex].is_Completed = formik.values.is_Completed
        agenda.meetingAgendaTask[taskindex].taskDueDate = taskduedate
        agenda.meetingAgendaTask[taskindex].meetingUserID = taskassignedtouser
        agenda.meetingAgendaTask[taskindex].meetingUser = null
        let meet = { ...meeting }
        meet.meeting.meetingAgenda[agendaindex] = agenda
        meet.meeting.meetingAgenda.map((agenda, k) => {
            agenda.meetingAgendaTask.map((ag, l) => {
                ag.meetingUser = null
            })
        })

        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedTaskAgendaId(0)
                setSelectedAgendaTaskId(0)
                setIsTaskEdit(false)
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const addTask = (id) => {
        setSelectedTaskAgendaId(id)
        setIsTaskEdit(false)
    }

    const saveTask = () => {
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == selectedTaskAgendaId)
        let meet = { ...meeting }
        let list = {
            "task": formik.values.task,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": formik.values.is_Completed
        }
        meet.meeting.meetingAgenda[agendaindex].meetingAgendaTask.push(list)


        meet.meeting.meetingAgenda.map((agenda, k) => {
            agenda.meetingAgendaTask.map((ag, l) => {
                ag.meetingUser = null
            })
        })

        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        console.log(json_list)

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedTaskAgendaId(0)
                setSelectedAgendaTaskId(0)
                setIsTaskEdit(false)
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const removeTask = (agendaid, taskid) => {
        let meet = { ...meeting }
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == agendaid)
        let agenda = meeting.meeting.meetingAgenda.filter(x => x.meetingAgendaID == agendaid)
        let taskindex = meeting.meeting.meetingAgenda[agendaindex].meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == taskid)
        meet.meeting.meetingAgenda[agendaindex].meetingAgendaTask[taskindex].is_Active = false
        meet.meeting.meetingAgenda[agendaindex].meetingAgendaTask[taskindex].is_Deleted = true
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const addSuggestion = (id) => {
        let meet = { ...meeting }
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == id)
        let list = {
            "description": formik.values.description,
            "suggestedBy": CONFIG.LoginID,
            "meetingAgendaTask": []
        }
        meet.meeting.meetingAgenda[agendaindex].agendaSuggestion.push(list)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setIsAddSuggestion(false)
                setIsSuggestion(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const updateSuggestion = (id) => {

    }

    const saveSuggestionTask = (agendaid, suggestionid) => {
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == agendaid)
        let suggestionindex = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion.findIndex(x => x.agendaSuggestionID == suggestionid)
        let list = {
            "task": formik.values.task,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": formik.values.is_Completed
        }
        let meet = { ...meeting }
        meet.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask.push(list)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedTaskAgendaId(0)
                setIsSuggestionTaskAdd(false)
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const updateSuggestionTask = (agendaid, suggestionid) => {
        console.log('onsde')

    }

    const editSuggestionTask = (agendaid, suggestionid, suggestiontaskid) => {
        setSelectedTaskAgendaId(agendaid)
        setAgendaSuggestionId(suggestionid)
        setAgendaSuggestionTaskId(suggestiontaskid)
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == agendaid)
        let suggestionindex = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion.findIndex(x => x.agendaSuggestionID == suggestionid)
        let taskindex = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == suggestiontaskid)
        formik.values.task = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].task
        formik.values.taskDescription = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].taskDescription
        formik.values.is_Completed = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].is_Completed
        setTaskduedate(meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].taskDueDate)
        setTaskassignedtouser(meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].meetingUserID)
    }
    const removeSuggestionTask = (agendaid, suggestionid, suggestiontaskid) => {
        let agendaindex = meeting.meeting.meetingAgenda.findIndex(x => x.meetingAgendaID == agendaid)
        let suggestionindex = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion.findIndex(x => x.agendaSuggestionID == suggestionid)
        let taskindex = meeting.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == suggestiontaskid)
        let meet = { ...meeting }
        meet.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].is_Active = false
        meet.meeting.meetingAgenda[agendaindex].agendaSuggestion[suggestionindex].meetingAgendaTask[taskindex].is_Deleted = true
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meet.meeting.meetingAgenda,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": formik.values.attendesslist,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedTaskAgendaId(0)
                setIsSuggestionTaskAdd(false)
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const removeTourTask = (id) => {
        let taskindex = meeting.meeting.meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == id)
        let meet = { ...meeting }
        meet.meeting.meetingAgendaTask[taskindex].is_Active = false
        meet.meeting.meetingAgendaTask[taskindex].is_Deleted = true
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meet.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedTaskAgendaId(0)
                setIsSuggestionTaskAdd(false)
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editTourTask = (id) => {
        let taskindex = meeting.meeting.meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == id)
        formik.values.taskDescription = meeting.meeting.meetingAgendaTask[taskindex].taskDescription
        formik.values.task = meeting.meeting.meetingAgendaTask[taskindex].task
        formik.values.is_Completed = meeting.meeting.meetingAgendaTask[taskindex].is_Completed
        formik.values.tourtaskid = id
        setTaskduedate(meeting.meeting.meetingAgendaTask[taskindex].taskDueDate)
        setTaskassignedtouser(meeting.meeting.meetingAgendaTask[taskindex].meetingUserID)
        setIsTaskChange(true)
        setIsTaskEdit(true)
    }
    const updateTourTask = () => {
        let taskindex = meeting.meeting.meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == formik.values.tourtaskid)
        let meet = { ...meeting }
        meet.meeting.meetingAgendaTask[taskindex].task = formik.values.task
        meet.meeting.meetingAgendaTask[taskindex].taskDescription = formik.values.taskDescription
        meet.meeting.meetingAgendaTask[taskindex].taskDueDate = taskduedate
        meet.meeting.meetingAgendaTask[taskindex].meetingUserID = taskassignedtouser
        meet.meeting.meetingAgendaTask[taskindex].is_Completed = formik.values.is_Completed

        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meet.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                setIsTaskChange(false)
                setIsTaskEdit(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const saveTourTask = () => {
        let list = {
            "task": formik.values.task,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": formik.values.is_Completed
        }
        let meet = { ...meeting }
        meet.meeting.meetingAgendaTask.push(list)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meet.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                formik.values.taskDescription = ''
                formik.values.task = ''
                formik.values.is_Completed = false
                setTaskduedate(false)
                setTaskassignedtouser(0)
                setIsTaskChange(false)
                setIsTaskEdit(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const addTourSuggestion = () => {
        let list = {
            "description": formik.values.description,
            "suggestedBy": CONFIG.LoginID,
            "meetingAgendaTask": []
        }
        meeting.meeting.agendaSuggestion.push(list)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                formik.values.taskDescription = ''
                setIsAddSuggestion(false)
                setIsSuggestion(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const updateTourSuggestion = () => {

    }

    const saveTourSuggestionTask = () => {
        let list = {
            "task": formik.values.task,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": formik.values.is_Completed
        }
        let meet = { ...meeting }
        let index = meeting.meeting.agendaSuggestion.findIndex(x => x.agendaSuggestionID == agendaSuggestionId)
        meeting.meeting.agendaSuggestion[index].meetingAgendaTask.push(list)
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                formik.values.taskDescription = ''
                formik.values.task = ''
                setTaskduedate(false)
                setTaskassignedtouser(0)
                setAgendaSuggestionId(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

    }

    const updateTourSuggestionTask = () => {

    }
    const removeTourSuggestionTask = (sugid, taskid) => {
        let sugind = meeting.meeting.agendaSuggestion.findIndex(x => x.agendaSuggestionID == sugid)
        let taskind = meeting.meeting.agendaSuggestion[sugind].meetingAgendaTask.findIndex(x => x.meetingAgendaTaskID == taskid)
        meeting.meeting.agendaSuggestion[sugind].meetingAgendaTask[taskind].is_Active = false
        meeting.meeting.agendaSuggestion[sugind].meetingAgendaTask[taskind].isDeleted = true
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const saveKey = () => {
        let list = {
            "name": formik.values.name,
            "designation": formik.values.designation,
            "qualification": formik.values.qualification,
            "currentPosition": formik.values.currentPosition,
            "currentHierarchy": formik.values.currentHierarchy,
            "currentCompany": formik.values.currentCompany,
            "prevCompany": formik.values.prevCompany,
            "emailID": formik.values.emailID,
            "phoneNo": formik.values.phoneNo,
            "interests": formik.values.interests
        }
        meeting.meeting.meetingKeyPeople.push(list)
        meeting.meeting.meetingAgenda.map((agenda, k) => {
            agenda.meetingAgendaTask.map((ag, l) => {
                ag.meetingUser = null
            })
        })
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setIsAddKey(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const deleteKey = (id) => {
        const index = meeting.meeting.meetingKeyPeople.findIndex(x => x.meetingKeyPeopleID == id)
        meeting.meeting.meetingKeyPeople[index].is_Active = false
        meeting.meeting.meetingKeyPeople[index].is_Deleted = true
        meeting.meeting.meetingAgenda.map((agenda, k) => {
            agenda.meetingAgendaTask.map((ag, l) => {
                ag.meetingUser = null
            })
        })
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setIsAddKey(false)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const updateDicussionPoint = (agendaid) => {
        const agendaindex = meeting.meeting.meetingAgenda.findIndex(x=> x.meetingAgendaID == agendaid)
        meeting.meeting.meetingAgenda[agendaindex].discussionPoint = formik.values.discussionPoint
        
        meeting.meeting.meetingAgenda.map((agenda, k) => {
            agenda.meetingAgendaTask.map((ag, l) => {
                ag.meetingUser = null
            })
            agenda.agendaSuggestion.map((sug,k)=>{
                sug.meetingAgendaTask.map((ag, l) => {
                    ag.meetingUser = null
                })
            })
        })
        const json_list = {
            "is_Meeting": meeting.meeting.is_Meeting,
            "meetingID": meeting.meeting.meetingID,
            "companyID": meeting.meeting.companyID,
            "agendaSuggestion": meeting.meeting.agendaSuggestion,
            "meetingCode": meeting.meeting.meetingCode,
            "meetingTitle": meeting.meeting.meetingTitle,
            "meetingCategoryID": meeting.meeting.meetingCategoryID,
            "meetingDate": meeting.meeting.meetingDate,
            "strMeetingStartTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meeting.meeting.strMeetingStartTime)).format('HH:mm:ss').toString(),
            "meetingType": meeting.meeting.meetingType,
            "meetingHost": meeting.meeting.meetingHost,
            "meetingHostUserID": meeting.meeting.meetingHostUserID,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": meeting.meeting.created_User,
            "modified_User": CONFIG.LoginID,
            "created_Datetime": meeting.meeting.created_Datetime,
            "meetingAgenda": meeting.meeting.meetingAgenda,
            "meetingAgendaTask": meeting.meeting.meetingAgendaTask,
            "meetingRequiredAttendance": meeting.meeting.meetingRequiredAttendance,
            "meetingNotifyUser": meeting.meeting.meetingNotifyUser,
            "meetingOtherIntPoints": meeting.meeting.meetingOtherIntPoints,
            "meetingOtherIntPointsBy": meeting.meeting.meetingOtherIntPointsBy,
            "meetingKeyPeople": meeting.meeting.meetingKeyPeople
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                formik.values.discussionPoint = ''
                setDiscussionAgendaId(0)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            {viewstatuts &&
                <ViewMeetingReport toggle={viewMeetingReport} meetingdata={meeting} />
            }
            <div className='meeting_vew_top_header'>
                {meeting.meeting != null &&
                    <>
                        <span> Created on {Moment(meeting.meeting.meetingDate).format('DD/MM/YYYY')}</span>
                        {meeting.meeting.is_Meeting == 1 ?
                            <>
                                <div className='d-flex mt-3'>
                                    <h5>{meeting.meeting.meetingTitle}</h5>  <span className='pl-20 font-15 font-weight-600'>{meeting.meeting.meetingCode}</span>
                                    <span className='pl-10'>|</span>
                                    <span className='pl-10'>{meeting.companyname}</span>
                                </div>
                            </>
                            :
                            <h4>Ref : {meeting.meeting.documentNumberForDocControl}</h4>
                        }
                    </>
                }
                <div>
                    <BlueBorderButton value='Export' clickEvent={viewMeetingReport} />
                </div>
            </div>

            <div className='meeting_innner_info mb-10-rem'>
                <Row>
                    {meeting.meeting != null &&
                        <>
                            <Col md={1} className='meeting_inner_left_info  d-flex justify-content-center' style={{ width: '10%', height: '100px' }}>
                                <div>
                                    {(Moment(meeting.meeting.meetingDate).format('DD  MMM').toString().toUpperCase())}
                                    <span className='meeting_time d-flex justify-content-between'>
                                        {Moment(meeting.meetingstarttime).format('h:mm a')} - {Moment(meeting.meetingendtime).format('h:mm a')}
                                    </span>
                                </div>
                            </Col>
                            <Col md={9} className='meeting_inner_right_info'>
                                <Row className=' d-flex justify-content-between'>
                                    <Col md={6}>
                                        {meeting.meeting.meetingCategory != null &&
                                            <span className='meeting_category_title'>{meeting.meeting.meetingCategory.meetingCategoryName}</span>
                                        }
                                    </Col>
                                    <Col md={6}>
                                        <span className='meeting_hosted_by mb-2'>Hosted By : </span>
                                        {meeting.meeting.meetingUser != null &&
                                            <div className='d-flex bg-meetCard host-con justify-content-center align-items-center p-3 br-10 mt-2'>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                    <p className='font-14 font-weight-500 mb-0'>{meeting.meeting.meetingUser.userAvatar}</p>
                                                </div>
                                                <div className='pl-10'>
                                                    <div className='font-15 font-weight-500'>{meeting.meeting.meetingUser.userName}</div>
                                                    <span className='font-13 font-weight-400'>{meeting.meeting.meetingUser.designation}</span>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-2-rem d-flex justify-content-between'>
                                    <Col md={6}><div className='d-flex'>
                                        <span className='meeting_hosted_by'>Attendees : </span>
                                        <span className='pl-10'>
                                            <ButtonComponent.EditButton clickEvent={() => {
                                                setIsEditRequiredUser(!isEditRequiredUser)
                                            }} />
                                        </span>
                                    </div>
                                        {isEditRequiredUser == true ?
                                            <>
                                                <div>
                                                    <Col md={12}>
                                                        <Autocomplete
                                                            multiple
                                                            id="tags-standard"
                                                            options={userlist}
                                                            getOptionLabel={(option) => option.userName}
                                                            defaultValue={notifylist}
                                                            onChange={handlerequiredattendees}
                                                            limitTags={2}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                    </div>
                                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Add Required Attendees"
                                                                    placeholder="Add Required Attendees"
                                                                />
                                                            )}
                                                        />
                                                    </Col>

                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {
                                                            setIsEditRequiredUser(!isEditRequiredUser)
                                                        }}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                            updateRequiredAttendees()
                                                        }}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className='d-flex mt-2'>
                                                <div className='bg-meetCard col-md-4 p-2 br-10'>
                                                    <span className='font-13'>Internal</span>
                                                    <div className='d-flex align-items-center mt-2'>
                                                        
                                                        {meeting.meeting.meetingRequiredAttendance.map((attendees, k) => {
                                                            if (k < 3) {
                                                                if (attendees.meetingUser.is_Internal == true) {
                                                                    return (
                                                                        <>
                                                                            <Tooltip placement="top" title={attendees.meetingUser.userName}>
                                                                                <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                                    <p className='font-14 font-weight-500 mb-0'>{attendees.meetingUser.userAvatar}</p>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        })}
                                                        <span className=''>{attendeescount}</span>
                                                    </div>
                                                </div>
                                                <div className='bg-meetCard col-md-4 p-2 br-10 ml-1-rem'>
                                                    <span className='font-13'>External</span>
                                                    <div className='d-flex align-items-center mt-2'>
                                                        {meeting.meeting.meetingRequiredAttendance.map((attendees, k) => {
                                                            if (k < 3) {
                                                                if (attendees.meetingUser.is_Internal == false) {
                                                                    return (
                                                                        <>
                                                                            <Tooltip placement="top" title={attendees.meetingUser.userName}>
                                                                                <div className='avatar d-flex align-items-center justify-content-center me-1 ' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                                    <p className='font-14 font-weight-500 mb-0'>{attendees.meetingUser.userAvatar}</p>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={6}>
                                        <div className='d-flex'>
                                            <span className='meeting_hosted_by'>Notify Members : </span>
                                            <span className='pl-10'>
                                                <ButtonComponent.EditButton clickEvent={() => {
                                                    setIsEditNotifyUser(!isEditNotifyUser)
                                                }} />
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center mt-2'>
                                            {isEditNotifyUser == true ?
                                                <>
                                                    <div>
                                                        <Col md={12}>
                                                            <Autocomplete
                                                                multiple
                                                                id="tags-standard"
                                                                className=''
                                                                onChange={handlenotifyusers}
                                                                limitTags={2}
                                                                options={notifylist}
                                                                getOptionLabel={(option) => option.userName}
                                                                defaultValue={selectednotifylist}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                            <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                        </div>
                                                                        <div className='font-weight-500 ps-2' style={{ fontSize: '12px' }}>{option.userName}
                                                                            {option.companyName != null &&
                                                                                <>
                                                                                    <span className='mx-2'>|</span><span className='font-weight-400'>{option.companyName}</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Notify Members"
                                                                        placeholder="+ Add More"
                                                                    />
                                                                )}
                                                            />
                                                        </Col>

                                                        <div className='d-flex justify-content-end'>
                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {
                                                                setIsEditNotifyUser(!isEditNotifyUser)
                                                            }}>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                                updateNotifyList()
                                                            }}>
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                meeting.meeting.meetingNotifyUser.map((attendees, k) => {
                                                    return (
                                                        <Tooltip placement="top" title={attendees.meetingUser.userName}>
                                                            <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                <p className='font-14 font-weight-500 mb-0'>{attendees.meetingUser.userAvatar}</p>
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Accordion className=' mt-1-rem bg-meetCard'>
                                                <Accordion.Header className='keyHeader' style={{ padding: '20px' }} >
                                                    Key People <hr width="82%" className='mx-3' color="green" size="10px" />
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='d-flex justify-content-end'>
                                                        <Col md={2}>
                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 keyPeople_save bg-meetCard'} type="button" onClick={() => {
                                                                setIsAddKey(true)
                                                            }}>
                                                                Add Key People
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    {isAddKey ?
                                                        <>
                                                            <Row>
                                                                <Row className='mt-1-rem'>
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Name'
                                                                        name='name'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Designation'
                                                                        name='designation'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Qualification'
                                                                        name='qualification'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </Row>
                                                                <Row className='mt-1-rem'>
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Current Hierarchy'
                                                                        name='currentHierarchy'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Current Company'
                                                                        name='currentCompany'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Previous Company'
                                                                        name='prevCompany'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </Row>
                                                                <Row className='mt-1-rem'>
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Email ID'
                                                                        name='emailID'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Phone No'
                                                                        name='phoneNo'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <FormFields.InputField
                                                                        md={4}
                                                                        label='Interests'
                                                                        name='interests'
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </Row>
                                                                <Col md={2} className='mt-1-rem'>
                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                                        saveKey()
                                                                    }}>
                                                                        Save
                                                                    </Button>

                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            {meeting.meeting.meetingKeyPeople.map((pep, k) => {
                                                                return (
                                                                    <div className='bg-white mt-1-rem p-1-rem br-10'>
                                                                        <Row>
                                                                            <Col md={3}>
                                                                                <div className='font-weight-600 font-15'>{pep.name}</div>
                                                                                <div className='font-weight-500 font-13'>{pep.designation}</div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className='font-weight-500 font-13'>Qualification</div>
                                                                                <div className='font-weight-500 font-15'>{pep.qualification}</div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className='font-weight-500 font-13'>Heirarchy</div>
                                                                                <div className='font-weight-500 font-15'>{pep.currentHierarchy}</div>
                                                                            </Col>
                                                                            <Col md={3}>
                                                                                <div className='font-weight-500 font-15'>{pep.emailID}</div>
                                                                                <div className='font-weight-500 font-13'>{pep.phoneNo}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={3} className='mt-2'>
                                                                                <div className='font-weight-500 font-13'>Current Company</div>
                                                                                <div className='font-weight-500 font-15'>{pep.currentCompany}</div>
                                                                            </Col>
                                                                            <Col md={3} className='mt-2'>
                                                                                <div className='font-weight-500 font-13'>Previous Company</div>
                                                                                <div className='font-weight-500 font-15'>{pep.prevCompany}</div>
                                                                            </Col>
                                                                            <Col md={3} className='mt-2'>
                                                                                <div className='font-weight-500 font-13'>Interests</div>
                                                                                <div className='font-weight-500 font-15'>{pep.interests}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <div className='d-flex width justify-content-end pe-4'>

                                                                                <ButtonComponent.DeleteButton
                                                                                    clickEvent={() => {
                                                                                        deleteKey(pep.meetingKeyPeopleID)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </Accordion.Body>
                                            </Accordion>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt-2-rem meeting_content_view'>
                                    <Tabs>
                                        <TabList>
                                            {meeting.meeting.is_Meeting == 1 ?
                                                <>
                                                    <Tab>Agenda</Tab>
                                                    <Tab>Pre-Meeting Tasks</Tab>
                                                </>
                                                :
                                                <>
                                                    <Tab>Discussion Points</Tab>
                                                    <Tab>Suggestions</Tab>
                                                </>
                                            }
                                            <Tab>Other Interesting Points</Tab>
                                        </TabList>
                                        {meeting.meeting.is_Meeting == 1 ?
                                            <>
                                                <TabPanel className='mb-2-rem'>
                                                    {!isAgendaAdd &&
                                                        <Row className='d-flex justify-content-end'>
                                                            <Col md={2}>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                                    setIsAgendaEdit(!isAgendaEdit)
                                                                    setIsAgendaAdd(true)
                                                                }}>
                                                                    Add Agenda
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {isAgendaEdit &&
                                                        <div>
                                                            <Row className='border_bottom_input task_description py-3'>
                                                                <div className='task_div col-md-10'>
                                                                    <FormFields.BottomBorderInputField
                                                                        md={12}
                                                                        name='agendaName'
                                                                        val={formik.values.agendaName}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        label='Type Agenda Title here...' className=''
                                                                    />
                                                                </div>
                                                                <div className='task_div col-md-2'>
                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {

                                                                        formik.values.agendaName = ''
                                                                        setIsAgendaEdit(!isAgendaEdit)
                                                                        setIsAgendaAdd(false)
                                                                    }}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button variant="primary" size="md" onClick={() => {
                                                                        if (isAgendaAdd == true) {
                                                                            AddAgenda()
                                                                        } else {
                                                                            updateAgenda()
                                                                        }
                                                                    }}>
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    }
                                                    <Accordion className='meeting_accordin mt-2-rem'>
                                                        {meeting.meeting.meetingAgenda.map((agenda, k) => {
                                                            return (
                                                                <Accordion.Item className='mb-2' eventKey={k}>
                                                                    <Accordion.Header>

                                                                        <span className='bgMeeting'> <img src='assets/images/meeting_task.svg' /> </span>
                                                                        <div className='d-flex justify-content-between width-100l'>
                                                                            <span className='meeting_agendaname'>
                                                                                {agenda.agendaName}
                                                                            </span>
                                                                            <div className='d-flex me-5'>
                                                                                <span className='me-2'>
                                                                                    <ButtonComponent.EditButton
                                                                                        clickEvent={() => {
                                                                                            editAgenda(agenda.meetingAgendaID)
                                                                                            setIsAgendaAdd(false)
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                                <span>
                                                                                    <ButtonComponent.DeleteButton clickEvent={() => {
                                                                                        removeAgenda(agenda.meetingAgendaID)
                                                                                    }} />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {discussionAgendaId == agenda.meetingAgendaID &&
                                                                            <div>
                                                                                <Row>
                                                                                    <Col md={1}>
                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                    </Col>
                                                                                    <Col md={11}>
                                                                                        <FormFields.InputField
                                                                                            name='discussionPoint'
                                                                                            label='Discussion Points'
                                                                                            md={12}
                                                                                            val={formik.values.discussionPoint}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <div className='d-flex justify-content-end mt-1-rem'>
                                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k}
                                                                                        onClick={() => {
                                                                                            formik.values.discussionPoint = ''
                                                                                            setDiscussionAgendaId(0)
                                                                                        }}>
                                                                                        Cancel
                                                                                    </Button>
                                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                        onClick={() => {
                                                                                            updateDicussionPoint(agenda.meetingAgendaID)
                                                                                        }}>
                                                                                        Save
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {agenda.discussionPoint != null ?
                                                                            <div className='bg-light p-1-rem font-13'>
                                                                                <Row>
                                                                                    <Col md={11}>
                                                                                        {agenda.discussionPoint}
                                                                                    </Col>
                                                                                    <Col md={1}>
                                                                                        <img src='assets/images/Edit_icon.svg' className='pointer' onClick={() => {
                                                                                            formik.values.discussionPoint = agenda.discussionPoint
                                                                                            setDiscussionAgendaId(agenda.meetingAgendaID)
                                                                                        }} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <Row>
                                                                                    <Col md={1}>
                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                    </Col>
                                                                                    <Col md={11}>
                                                                                        <FormFields.InputField
                                                                                            name='discussionPoint'
                                                                                            label='Discussion Points'
                                                                                            md={12}
                                                                                            val={formik.values.discussionPoint}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <div className='d-flex justify-content-end mt-1-rem'>
                                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                        onClick={() => {
                                                                                            saveDiscussionPoint(agenda.meetingAgendaID)
                                                                                        }}>
                                                                                        Save
                                                                                    </Button>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <Tabs>
                                                                            <TabList>
                                                                                <Tab>Tasks</Tab>
                                                                                <Tab>Suggesstions</Tab>
                                                                            </TabList>
                                                                            <TabPanel>
                                                                                {selectedTaskAgendaId == agenda.meetingAgendaID ?
                                                                                    <>
                                                                                        <div className='d-flex task1'>
                                                                                            <Col md={4} className='d-flex task1 align-items-center'>
                                                                                                <div className='task_div'>
                                                                                                    <img src='assets/images/meeting_task.svg' />
                                                                                                </div>
                                                                                                <div className='task_div '>
                                                                                                    <Form.Check
                                                                                                        name='is_Completed'
                                                                                                        checked={formik.values.is_Completed}
                                                                                                        onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                    <FormFields.BottomBorderInputField
                                                                                                        md={12}
                                                                                                        label='Add Task'
                                                                                                        name='task'
                                                                                                        val={formik.values.task}
                                                                                                        onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur}
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                                                                <div className='task_div d-flex meeting_task_due_date'>
                                                                                                    <FormFields.DatePickBottomBorder
                                                                                                        md={12}
                                                                                                        handleCompletionDate={handleTaskDueDate}
                                                                                                        label='Task Due Date'
                                                                                                        val={taskduedate}
                                                                                                        className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='task_div col-md-8'>
                                                                                                    <Autocomplete
                                                                                                        id="tags-standard"
                                                                                                        defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                                                        options={userlist}
                                                                                                        getOptionLabel={(option) => option.userName}
                                                                                                        onChange={handleAssignedTo}
                                                                                                        renderOption={(props, option) => (
                                                                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                                                </div>
                                                                                                                <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField
                                                                                                                {...params}
                                                                                                                variant="standard"
                                                                                                                label="Assigned To"
                                                                                                                placeholder="Assigned To"
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </div>
                                                                                        <Row className='border_bottom_input task_description'>
                                                                                            <div className='task_div'>
                                                                                                <FormFields.BottomBorderInputField
                                                                                                    md={12}
                                                                                                    label='Task Description'
                                                                                                    name='taskDescription'
                                                                                                    val={formik.values.taskDescription}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                        </Row>
                                                                                        <div className='d-flex justify-content-end'>
                                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k} onClick={() => {
                                                                                                setSelectedTaskAgendaId(0)
                                                                                                setSelectedAgendaTaskId(0)
                                                                                                setIsTaskEdit(false)
                                                                                                formik.values.taskDescription = ''
                                                                                                formik.values.task = ''
                                                                                                formik.values.is_Completed = false
                                                                                                setTaskduedate(false)
                                                                                                setTaskassignedtouser(0)
                                                                                            }}>
                                                                                                Cancel
                                                                                            </Button>
                                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                                onClick={() => {
                                                                                                    isTaskEdit ?
                                                                                                        updateTask()
                                                                                                        :
                                                                                                        saveTask()
                                                                                                }}>
                                                                                                Save Task
                                                                                            </Button>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <Row className='d-flex justify-content-end'>
                                                                                        <Col md={2}>
                                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                                onClick={() => {
                                                                                                    addTask(agenda.meetingAgendaID)
                                                                                                }}>
                                                                                                Add Task
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {agenda.meetingAgendaTask.length > 0 &&
                                                                                    <>
                                                                                        {agenda.meetingAgendaTask.map((task, l) => {
                                                                                            let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                                                            return (
                                                                                                <>
                                                                                                    <div className='d-flex task1 mt-3 justify-content-between'>
                                                                                                        <div className='d-flex'>
                                                                                                            <div className='task_div'>
                                                                                                                <img src='assets/images/meeting_task.svg' />
                                                                                                            </div>
                                                                                                            <div className='task_div '>
                                                                                                                <Form.Check
                                                                                                                    name='taskCompletedStatus' disabled={true}
                                                                                                                    checked={task.is_Completed}
                                                                                                                />
                                                                                                            </div>

                                                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                                <div>
                                                                                                                    <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                                                    <div className='meeting_inner_task_date'>{Moment(task.created_Date).format('DD/MM/YYYY')} - {Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                                                    <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <div className='task_div '>
                                                                                                        </div> */}
                                                                                                        <div className='d-flex'>
                                                                                                            <div className='task_div me-5'>
                                                                                                                <div className='d-flex '>
                                                                                                                    <p>Assigned to:</p>

                                                                                                                </div>

                                                                                                                {users.length > 0 &&
                                                                                                                    <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                                                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                            <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                                                        </div>
                                                                                                                        <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>

                                                                                                            <div className='task_div '>
                                                                                                                <ButtonComponent.EditButton clickEvent={() => { editTask(agenda.meetingAgendaID, task.meetingAgendaTaskID) }} />
                                                                                                            </div>
                                                                                                            <div className='task_div '>
                                                                                                                <ButtonComponent.DeleteButton clickEvent={() => { removeTask(agenda.meetingAgendaID, task.meetingAgendaTaskID) }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className='d-flex task1 mt-3'>
                                                                                                        <div className='task_div'>
                                                                                                            <img src='assets/images/meeting_task.svg' />
                                                                                                        </div>
                                                                                                        <div className='task_div '>
                                                                                                            <Form.Check
                                                                                                                name='taskCompletedStatus' disabled={true}
                                                                                                                checked={task.is_Completed}
                                                                                                            />
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                            <div>
                                                                                                                <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                                                <div className='meeting_inner_task_date'>{Moment(task.created_Date).format('DD/MM/YYYY')} - {Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                                                <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        {/* <div className='task_div '>
                                                                                                        </div> 
                                                                                                    <div className='d-flex'>
                                                                                                        <div className='task_div me-5'>
                                                                                                            <div className='d-flex '>
                                                                                                                <p>Assigned to:</p>

                                                                                                            </div>

                                                                                                            {users.length > 0 &&
                                                                                                                <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                                                    <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                        <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                                                    </div>
                                                                                                                    <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className='task_div '>
                                                                                                            <ButtonComponent.EditButton clickEvent={() => { editTask(agenda.meetingAgendaID, task.meetingAgendaTaskID) }} />
                                                                                                        </div>
                                                                                                    </div>*/}
                                                                                                    <div className='d-flex'>
                                                                                                        {task.taskAttahcments != null &&
                                                                                                            task.taskAttahcments.map((att, k) => {
                                                                                                                console.log(att)
                                                                                                                return (
                                                                                                                    <div className='document_badge px-4 py-2 badge-size color-document mb-2 align-self-center m-1-rem'>
                                                                                                                        <a download={att.attachmentName} href={att.taskAttahchment}>
                                                                                                                            {att.attachmentName}
                                                                                                                        </a>
                                                                                                                        <span className='pl-8'>
                                                                                                                            <img src='assets/images/close.svg' className='pointer' id={att.taskAttahcmentsID} onClick={removeDocument} />
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {commentlist['"' + task.meetingAgendaTaskID + '"'] != null &&
                                                                                                            <>
                                                                                                                {console.log(commentlist['"' + task.meetingAgendaTaskID + '"'])}
                                                                                                                {commentlist['"' + task.meetingAgendaTaskID + '"'].map((comment, k) => {
                                                                                                                    return (
                                                                                                                        <div className='comment_box'>
                                                                                                                            <div className={comment.userID == CONFIG.LoginID ? 'comment_user_avatar d-flex justify-content-end mt-1-rem' : 'comment_user_avatar d-flex mt-1-rem'}>
                                                                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                                                    <p className='font-12 font-weight-500 mb-0'>{comment.commentedByNameAvatar}</p>
                                                                                                                                </div>
                                                                                                                                <div className='pl-10'>
                                                                                                                                    <div className='user_info_meeting_comments'>{comment.commentedByName}</div>
                                                                                                                                    <div className='user_designation_meeting'>{comment.commentedByDesignation}</div>
                                                                                                                                    <div className='meeting_comments_data mt-1-rem'>{comment.meetingComments}</div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {commentstatus['"' + task.meetingAgendaTaskID + '"'] &&
                                                                                                        <Row className='meeting_task_comment_section'>
                                                                                                            <Col md={1} className='d-flex align-items-center'>
                                                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                                    <p className='font-12 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col md={7} className='border_bottom_input'>
                                                                                                                <FormFields.BottomBorderInputField
                                                                                                                    md={12}
                                                                                                                    label='Add your comment here...'
                                                                                                                    name='comment'
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    onBlur={formik.handleBlur}
                                                                                                                />
                                                                                                                {commenterror &&
                                                                                                                    <span className='input_error'>Please enter a comment</span>
                                                                                                                }
                                                                                                            </Col>
                                                                                                            <Col md={4} className='d-flex align-items-center'>
                                                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={task.meetingAgendaTaskID} onClick={removeComment}>
                                                                                                                    Cancel
                                                                                                                </Button>
                                                                                                                <Button
                                                                                                                    variant="outline-primary"
                                                                                                                    size="lg"
                                                                                                                    className={'font-weight-500 font-14 rounded-13 task_description_save'}
                                                                                                                    type="button"
                                                                                                                    id={task.meetingAgendaTaskID}
                                                                                                                    onClick={saveComment}
                                                                                                                >
                                                                                                                    Add
                                                                                                                </Button>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    }
                                                                                                    <div className='task_div d-flex justify-content-end br-meetBorder'>
                                                                                                        <div className='p-0_5-rem'>
                                                                                                            <Button variant='outline-secondary' className='task_button' id={task.meetingAgendaTaskID} onClick={showAddComments}>
                                                                                                                <img src='assets/images/comments_icon.svg' id={task.meetingAgendaTaskID} onClick={showAddComments} />
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                        <HandleTaskAttachments id={task.meetingAgendaTaskID} getmeetingbyid={getmeetingbyid} />
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                }
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <Row className='d-flex justify-content-end'>
                                                                                    <Col md={2}>
                                                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                            onClick={() => {
                                                                                                setIsSuggestion(true)
                                                                                                setIsAddSuggestion(true)
                                                                                            }}>
                                                                                            Add Suggestion
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                                {isSuggestion &&
                                                                                    <div>
                                                                                        <Row className='border_bottom_input task_description py-3'>
                                                                                            <div className='task_div col-md-8'>
                                                                                                <FormFields.BottomBorderInputField
                                                                                                    md={12}
                                                                                                    name='description'
                                                                                                    val={formik.values.description}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                    onChange={formik.handleChange}
                                                                                                    label='Type new suggestion...' className=''
                                                                                                />
                                                                                            </div>
                                                                                            <div className='task_div col-md-2 d-flex'>
                                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {

                                                                                                    formik.values.taskDescription = ''
                                                                                                    formik.values.task = ''
                                                                                                    formik.values.is_Completed = false
                                                                                                    setTaskduedate(false)
                                                                                                    setTaskassignedtouser(0)
                                                                                                    setIsSuggestion(false)
                                                                                                    setIsAddSuggestion(false)
                                                                                                }}>
                                                                                                    Cancel
                                                                                                </Button>
                                                                                                <Button variant="primary" size="md" onClick={() => {
                                                                                                    if (isAddSuggestion == true) {
                                                                                                        addSuggestion(agenda.meetingAgendaID)
                                                                                                    } else {
                                                                                                        updateSuggestion(agenda.meetingAgendaID)
                                                                                                    }
                                                                                                }}>
                                                                                                    Save
                                                                                                </Button>
                                                                                            </div>
                                                                                        </Row>
                                                                                    </div>
                                                                                }
                                                                                {agenda.agendaSuggestion.length > 0 &&
                                                                                    <>
                                                                                        {agenda.agendaSuggestion.map((sug, l) => {
                                                                                            return (
                                                                                                <div className='p-1-rem mt-1-rem' style={{ backgroundColor: '#F5F4F4', borderRadius: '13px' }}>
                                                                                                    <Row>
                                                                                                        <Col md={11} className='d-flex'>
                                                                                                            {/* <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                                                                {/* <p className='font-14 font-weight-500 mb-0'>{suguser != null && suguser.userAvatar}</p> *
                                                                                                            </div> */}
                                                                                                            <div className=' pl-10'>
                                                                                                                <div>
                                                                                                                    {/* <div className='font-13 font-weight-600'>{suguser != null && suguser.userName}</div>
                                                                                                                    <div className='font-12 font-weight-400'>{suguser != null && suguser.designation}</div> */}
                                                                                                                </div>
                                                                                                                <div className='font-weight-500'>
                                                                                                                    {sug.description}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col md={1}>
                                                                                                            <ButtonComponent.EditButton clickEvent={() => {
                                                                                                                editOtherPoint()
                                                                                                            }} />
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row className='d-flex justify-content-end'>
                                                                                                        <Col md={2}>
                                                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                                                onClick={() => {
                                                                                                                    setIsSuggestionTaskAdd(true)
                                                                                                                    setSelectedTaskAgendaId(agenda.meetingAgendaID)
                                                                                                                }}>
                                                                                                                Add Task
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    {(selectedTaskAgendaId == agenda.meetingAgendaID) &&
                                                                                                        <div style={{ backgroundColor: '#fff', borderRadius: '13px', padding: '10px' }}>
                                                                                                            <div className='d-flex task1'>
                                                                                                                <Col md={4} className='d-flex task1 align-items-center'>
                                                                                                                    <div className='task_div'>
                                                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                                                    </div>
                                                                                                                    <div className='task_div '>
                                                                                                                        <Form.Check
                                                                                                                            name='is_Completed'
                                                                                                                            checked={formik.values.is_Completed}
                                                                                                                            onChange={formik.handleChange}
                                                                                                                            onBlur={formik.handleBlur}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                                        <FormFields.BottomBorderInputField
                                                                                                                            md={12}
                                                                                                                            label='Add Task'
                                                                                                                            name='task'
                                                                                                                            val={formik.values.task}
                                                                                                                            onChange={formik.handleChange}
                                                                                                                            onBlur={formik.handleBlur}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                                                                                    <div className='task_div d-flex meeting_task_due_date'>
                                                                                                                        <FormFields.DatePickBottomBorder
                                                                                                                            md={12}
                                                                                                                            handleCompletionDate={handleTaskDueDate}
                                                                                                                            label='Task Due Date'
                                                                                                                            val={taskduedate}
                                                                                                                            className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className='task_div col-md-8'>
                                                                                                                        <Autocomplete
                                                                                                                            id="tags-standard"
                                                                                                                            defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                                                                            options={userlist}
                                                                                                                            getOptionLabel={(option) => option.userName}
                                                                                                                            onChange={handleAssignedTo}
                                                                                                                            renderOption={(props, option) => (
                                                                                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                                                                    </div>
                                                                                                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                                                                                </Box>
                                                                                                                            )}
                                                                                                                            renderInput={(params) => (
                                                                                                                                <TextField
                                                                                                                                    {...params}
                                                                                                                                    variant="standard"
                                                                                                                                    label="Assigned To"
                                                                                                                                    placeholder="Assigned To"
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </div>
                                                                                                            <Row className='border_bottom_input task_description'>
                                                                                                                <div className='task_div'>
                                                                                                                    <FormFields.BottomBorderInputField
                                                                                                                        md={12}
                                                                                                                        label='Task Description'
                                                                                                                        name='taskDescription'
                                                                                                                        val={formik.values.taskDescription}
                                                                                                                        onChange={formik.handleChange}
                                                                                                                        onBlur={formik.handleBlur}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </Row>
                                                                                                            <div className='d-flex justify-content-end'>
                                                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k} onClick={() => {
                                                                                                                    setSelectedTaskAgendaId(0)
                                                                                                                    setIsSuggestionTaskAdd(false)
                                                                                                                }}>
                                                                                                                    Cancel
                                                                                                                </Button>
                                                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                                                    onClick={() => {
                                                                                                                        isSuggestionTaskAdd ?
                                                                                                                            saveSuggestionTask(agenda.meetingAgendaID, sug.agendaSuggestionID)
                                                                                                                            :
                                                                                                                            updateSuggestionTask(agenda.meetingAgendaID, sug.agendaSuggestionID)
                                                                                                                    }}>
                                                                                                                    Save Task
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {sug.meetingAgendaTask.map((task, l) => {
                                                                                                        let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                                                                        return (
                                                                                                            <>

                                                                                                                <div className='d-flex task1'>
                                                                                                                    <div className='task_div'>
                                                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                                                    </div>
                                                                                                                    <div className='task_div '>
                                                                                                                        <Form.Check
                                                                                                                            name='taskCompletedStatus' disabled={true}
                                                                                                                            checked={task.is_Completed}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                                        <div>
                                                                                                                            <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                                                            <div className='meeting_inner_task_date'>{Moment(task.created_Date).format('DD/MM/YYYY')} - {Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                                                            <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='task_div '>
                                                                                                                    </div>
                                                                                                                    <div className='task_div d-flex'>
                                                                                                                        <p className='p-0_5-rem'>
                                                                                                                            Assigned to:
                                                                                                                        </p>
                                                                                                                        {users.length > 0 &&
                                                                                                                            <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                                    <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                                                                </div>
                                                                                                                                <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    {/* <div className='task_div '>
                                                                                                                        <ButtonComponent.EditButton clickEvent={() => { editSuggestionTask(agenda.meetingAgendaID, sug.agendaSuggestionID, task.meetingAgendaTaskID) }} />
                                                                                                                    </div> */}
                                                                                                                    <div className='task_div '>
                                                                                                                        <ButtonComponent.DeleteButton clickEvent={() => { removeSuggestionTask(agenda.meetingAgendaID, sug.agendaSuggestionID, task.meetingAgendaTaskID) }} />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                }
                                                                            </TabPanel>
                                                                        </Tabs>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        })}
                                                    </Accordion>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className='d-flex justify-content-end'>

                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                            setPremeetingStatus(!premetingStatus)
                                                        }}>
                                                            Add Premeeting Point
                                                        </Button>
                                                    </div>
                                                    {premetingStatus != false &&
                                                        <>
                                                            <Col md={8}>
                                                                <Row>
                                                                    <Col md={1} className='d-flex align-items-center'>
                                                                        <img src='assets/images/meeting_task.svg' />
                                                                    </Col>
                                                                    <Col md={9} className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                        <FormFields.BottomBorderInputField
                                                                            md={12}
                                                                            label='Premeeting Points'
                                                                            name='premeetingTask'
                                                                            val={formik.values.premeetingTask}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <div className='d-flex justify-content-end'>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {
                                                                    formik.values.premeetingTask = ''
                                                                    setPremeetingStatus(!premetingStatus)
                                                                }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                                    if (isPremeetingEdit == true) {
                                                                        updatePremeeting()
                                                                    }
                                                                    else {
                                                                        addPremeeting()
                                                                    }
                                                                }}>
                                                                    Save
                                                                </Button>
                                                            </div>
                                                        </>
                                                    }
                                                    {meeting.meeting.meetingAgendaTask.map((agenda, k) => {
                                                        return (
                                                            <div className='bg-light mt-1-rem br-10 p-1-rem d-flex justify-content-between'>
                                                                <div className='meeting_agendaname font-22'>
                                                                    {agenda.task}
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <ButtonComponent.EditButton clickEvent={() => {
                                                                        editPremeetingTask(agenda.meetingAgendaTaskID)
                                                                    }} />
                                                                    <ButtonComponent.DeleteButton clickEvent={() => {
                                                                        deletePremeeting(agenda.meetingAgendaTaskID)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </TabPanel>
                                            </>
                                            :
                                            <>
                                                <TabPanel>

                                                    <Row className='d-flex justify-content-end'>
                                                        <Col md={2}>
                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button"
                                                                onClick={() => {
                                                                    setIsTaskEdit(false)
                                                                    setIsTaskChange(true)
                                                                }}>
                                                                Add Task
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    {isTaskChange &&
                                                        <>

                                                            <Row className='border_bottom_input task_description'>
                                                                <div className='task_div'>
                                                                    <FormFields.BottomBorderInputField
                                                                        md={12}
                                                                        label='Discussion Point'
                                                                        name='taskDescription'
                                                                        val={formik.values.taskDescription}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                            </Row>
                                                            <div className='d-flex task1'>
                                                                <Col md={4} className='d-flex task1 align-items-center'>
                                                                    <div className='task_div'>
                                                                        <img src='assets/images/meeting_task.svg' />
                                                                    </div>
                                                                    <div className='task_div '>
                                                                        <Form.Check
                                                                            name='is_Completed'
                                                                            checked={formik.values.is_Completed}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </div>
                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                        <FormFields.BottomBorderInputField
                                                                            md={12}
                                                                            label='Add Task'
                                                                            name='task'
                                                                            val={formik.values.task}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                                    <div className='task_div d-flex meeting_task_due_date'>
                                                                        <FormFields.DatePickBottomBorder
                                                                            md={12}
                                                                            handleCompletionDate={handleTaskDueDate}
                                                                            label='Task Due Date'
                                                                            val={taskduedate}
                                                                            className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                        />
                                                                    </div>
                                                                    <div className='task_div col-md-8'>
                                                                        <Autocomplete
                                                                            id="tags-standard"
                                                                            defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                            options={userlist}
                                                                            getOptionLabel={(option) => option.userName}
                                                                            onChange={handleAssignedTo}
                                                                            renderOption={(props, option) => (
                                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                    </div>
                                                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                                </Box>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="standard"
                                                                                    label="Assigned To"
                                                                                    placeholder="Assigned To"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <div className='d-flex justify-content-end'>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {
                                                                    setIsTaskEdit(false)
                                                                    formik.values.taskDescription = ''
                                                                    formik.values.task = ''
                                                                    formik.values.is_Completed = false
                                                                    setTaskduedate(false)
                                                                    setIsTaskChange(false)
                                                                }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button"
                                                                    onClick={() => {
                                                                        isTaskEdit ?
                                                                            updateTourTask()
                                                                            :
                                                                            saveTourTask()
                                                                    }}>
                                                                    Save Task
                                                                </Button>
                                                            </div>
                                                        </>
                                                    }
                                                    <Accordion className='meeting_accordin mt-2-rem'>
                                                        {meeting.meeting.meetingAgendaTask.map((task, k) => {
                                                            console.log('Tour tasks', task)
                                                            let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                            return (
                                                                <>
                                                                    <Col md={12} className='bg-light mt-1-rem' style={{ padding: '10px', borderRadius: '13px' }}>
                                                                        {task.taskDescription}
                                                                        <div className='d-flex task1'>
                                                                            <div className='task_div'>
                                                                                <img src='assets/images/meeting_task.svg' />
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <Form.Check
                                                                                    name='taskCompletedStatus' disabled={true}
                                                                                    checked={task.is_Completed}
                                                                                />
                                                                            </div>
                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                <div>
                                                                                    <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                    <div className='meeting_inner_task_date'>{Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='task_div '>
                                                                            </div>
                                                                            <div className='task_div d-flex'>
                                                                                <p className='p-0_5-rem'>
                                                                                    Assigned to:
                                                                                </p>
                                                                                {users.length > 0 &&
                                                                                    <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                            <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                        </div>
                                                                                        <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                    </div>
                                                                                }
                                                                            </div>


                                                                            <div className='task_div '>
                                                                                <ButtonComponent.EditButton clickEvent={() => { editTourTask(task.meetingAgendaTaskID) }} />
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <ButtonComponent.DeleteButton clickEvent={() => { removeTourTask(task.meetingAgendaTaskID) }} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <div className='d-flex'>
                                                                        {task.taskAttahcments != null &&
                                                                            task.taskAttahcments.map((att, k) => {
                                                                                console.log(att)
                                                                                return (
                                                                                    <div className='document_badge px-4 py-2 badge-size color-document mb-2 align-self-center m-1-rem'>
                                                                                        <a download={att.attachmentName} href={att.taskAttahchment}>
                                                                                            {att.attachmentName}
                                                                                        </a>
                                                                                        <span className='pl-8'>
                                                                                            <img src='assets/images/close.svg' className='pointer' id={att.taskAttahcmentsID} onClick={removeDocument} />
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {commentlist['"' + task.meetingAgendaTaskID + '"'] != null &&
                                                                            <>
                                                                                {console.log(commentlist['"' + task.meetingAgendaTaskID + '"'])}
                                                                                {commentlist['"' + task.meetingAgendaTaskID + '"'].map((comment, k) => {
                                                                                    return (
                                                                                        <div className='comment_box'>
                                                                                            <div className={comment.userID == CONFIG.LoginID ? 'comment_user_avatar d-flex justify-content-end mt-1-rem' : 'comment_user_avatar d-flex mt-1-rem'}>
                                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                    <p className='font-12 font-weight-500 mb-0'>{comment.commentedByNameAvatar}</p>
                                                                                                </div>
                                                                                                <div className='pl-10'>
                                                                                                    <div className='user_info_meeting_comments'>{comment.commentedByName}</div>
                                                                                                    <div className='user_designation_meeting'>{comment.commentedByDesignation}</div>
                                                                                                    <div className='meeting_comments_data mt-1-rem'>{comment.meetingComments}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    {commentstatus['"' + task.meetingAgendaTaskID + '"'] &&
                                                                        <Row className='meeting_task_comment_section'>
                                                                            <Col md={1} className='d-flex align-items-center'>
                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                    <p className='font-12 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={7} className='border_bottom_input'>
                                                                                <FormFields.BottomBorderInputField
                                                                                    md={12}
                                                                                    label='Add your comment here...'
                                                                                    name='comment'
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {commenterror &&
                                                                                    <span className='input_error'>Please enter a comment</span>
                                                                                }
                                                                            </Col>
                                                                            <Col md={4} className='d-flex align-items-center'>
                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={task.meetingAgendaTaskID} onClick={removeComment}>
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button
                                                                                    variant="outline-primary"
                                                                                    size="lg"
                                                                                    className={'font-weight-500 font-14 rounded-13 task_description_save'}
                                                                                    type="button"
                                                                                    id={task.meetingAgendaTaskID}
                                                                                    onClick={saveComment}
                                                                                >
                                                                                    Add
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <div className='task_div d-flex justify-content-end'>
                                                                        <div className='p-0_5-rem'>
                                                                            <Button variant='outline-secondary' className='task_button' id={task.meetingAgendaTaskID} onClick={showAddComments}>
                                                                                <img src='assets/images/comments_icon.svg' id={task.meetingAgendaTaskID} onClick={showAddComments} />
                                                                            </Button>
                                                                        </div>
                                                                        <HandleTaskAttachments id={task.meetingAgendaTaskID} getmeetingbyid={getmeetingbyid} />
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </Accordion>
                                                </TabPanel>
                                                <TabPanel>
                                                    <Row className='d-flex justify-content-end'>
                                                        <Col md={2}>
                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button"
                                                                onClick={() => {
                                                                    setIsSuggestion(true)
                                                                    setIsAddSuggestion(true)
                                                                }}>
                                                                Add Suggestion
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    {isSuggestion &&
                                                        <div>
                                                            <Row className='border_bottom_input task_description py-3'>
                                                                <div className='task_div col-md-8'>
                                                                    <FormFields.BottomBorderInputField
                                                                        md={12}
                                                                        name='description'
                                                                        val={formik.values.description}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        label='Type new suggestion...' className=''
                                                                    />
                                                                </div>
                                                                <div className='task_div col-md-2 d-flex'>
                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={() => {

                                                                        formik.values.taskDescription = ''
                                                                        setIsSuggestion(false)
                                                                        setIsAddSuggestion(false)
                                                                    }}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button variant="primary" size="md" onClick={() => {
                                                                        if (isAddSuggestion == true) {
                                                                            addTourSuggestion()
                                                                        } else {
                                                                            updateTourSuggestion()
                                                                        }
                                                                    }}>
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    }
                                                    {meeting.meeting.agendaSuggestion.length > 0 ?
                                                        <>
                                                            {meeting.meeting.agendaSuggestion.map((sug, k) => {
                                                                return (
                                                                    <>

                                                                        <div className='p-1-rem mt-1-rem' style={{ backgroundColor: '#F5F4F4', borderRadius: '13px' }}>
                                                                            <Row>
                                                                                <Col md={11} className='d-flex'>
                                                                                    {/* <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                            {/* <p className='font-14 font-weight-500 mb-0'>{suguser != null && suguser.userAvatar}</p> *
                                                                        </div> */}
                                                                                    <div className=' pl-10'>
                                                                                        <div>
                                                                                            {/* <div className='font-13 font-weight-600'>{suguser != null && suguser.userName}</div>
                                                                                <div className='font-12 font-weight-400'>{suguser != null && suguser.designation}</div> */}
                                                                                        </div>
                                                                                        <div className='font-weight-500'>
                                                                                            {sug.description}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                {/* <Col md={1}>
                                                                            <ButtonComponent.EditButton clickEvent={() => {
                                                                                editOtherPoint()
                                                                            }} />
                                                                        </Col> */}
                                                                            </Row>
                                                                            <Row className='d-flex justify-content-end'>
                                                                                <Col md={2}>
                                                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                        onClick={() => {
                                                                                            formik.values.taskDescription = ''
                                                                                            formik.values.task = ''
                                                                                            setTaskduedate(false)
                                                                                            setTaskassignedtouser(0)
                                                                                            setIsSuggestionTaskAdd(true)
                                                                                            setAgendaSuggestionId(sug.agendaSuggestionID)
                                                                                        }}>
                                                                                        Add Task
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                            {(agendaSuggestionId == sug.agendaSuggestionID) &&
                                                                                <div style={{ backgroundColor: '#fff', borderRadius: '13px', padding: '10px' }}>
                                                                                    <div className='d-flex task1'>
                                                                                        <Col md={4} className='d-flex task1 align-items-center'>
                                                                                            <div className='task_div'>
                                                                                                <img src='assets/images/meeting_task.svg' />
                                                                                            </div>
                                                                                            <div className='task_div '>
                                                                                                <Form.Check
                                                                                                    name='is_Completed'
                                                                                                    checked={formik.values.is_Completed}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                <FormFields.BottomBorderInputField
                                                                                                    md={12}
                                                                                                    label='Add Task'
                                                                                                    name='task'
                                                                                                    val={formik.values.task}
                                                                                                    onChange={formik.handleChange}
                                                                                                    onBlur={formik.handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                                                            <div className='task_div d-flex meeting_task_due_date'>
                                                                                                <FormFields.DatePickBottomBorder
                                                                                                    md={12}
                                                                                                    handleCompletionDate={handleTaskDueDate}
                                                                                                    label='Task Due Date'
                                                                                                    val={taskduedate}
                                                                                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                                                />
                                                                                            </div>
                                                                                            <div className='task_div col-md-8'>
                                                                                                <Autocomplete
                                                                                                    id="tags-standard"
                                                                                                    defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                                                    options={userlist}
                                                                                                    getOptionLabel={(option) => option.userName}
                                                                                                    onChange={handleAssignedTo}
                                                                                                    renderOption={(props, option) => (
                                                                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                                            <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                                            </div>
                                                                                                            <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                                                        </Box>
                                                                                                    )}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField
                                                                                                            {...params}
                                                                                                            variant="standard"
                                                                                                            label="Assigned To"
                                                                                                            placeholder="Assigned To"
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </div>
                                                                                    <Row className='border_bottom_input task_description'>
                                                                                        <div className='task_div'>
                                                                                            <FormFields.BottomBorderInputField
                                                                                                md={12}
                                                                                                label='Task Description'
                                                                                                name='taskDescription'
                                                                                                val={formik.values.taskDescription}
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={formik.handleBlur}
                                                                                            />
                                                                                        </div>
                                                                                    </Row>
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k} onClick={() => {

                                                                                            formik.values.taskDescription = ''
                                                                                            formik.values.task = ''
                                                                                            setTaskduedate(false)
                                                                                            setTaskassignedtouser(0)
                                                                                            setAgendaSuggestionId(0)
                                                                                            setIsSuggestionTaskAdd(false)
                                                                                        }}>
                                                                                            Cancel
                                                                                        </Button>
                                                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                            onClick={() => {
                                                                                                isSuggestionTaskAdd ?
                                                                                                    saveTourSuggestionTask()
                                                                                                    :
                                                                                                    updateTourSuggestionTask()
                                                                                            }}>
                                                                                            Save Task
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {sug.meetingAgendaTask.map((task, l) => {
                                                                                let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                                                return (
                                                                                    <>

                                                                                        <div className='d-flex task1'>
                                                                                            <div className='task_div'>
                                                                                                <img src='assets/images/meeting_task.svg' />
                                                                                            </div>
                                                                                            <div className='task_div '>
                                                                                                <Form.Check
                                                                                                    name='taskCompletedStatus' disabled={true}
                                                                                                    checked={task.is_Completed}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                <div>
                                                                                                    <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                                    <div className='meeting_inner_task_date'>{Moment(task.created_Date).format('DD/MM/YYYY')} - {Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                                    <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='task_div '>
                                                                                            </div>
                                                                                            <div className='task_div d-flex'>
                                                                                                <p className='p-0_5-rem'>
                                                                                                    Assigned to:
                                                                                                </p>
                                                                                                {users.length > 0 &&
                                                                                                    <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                                        <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                            <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                                        </div>
                                                                                                        <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {/* <div className='task_div '>
                                                                                    <ButtonComponent.EditButton clickEvent={() => { editSuggestionTask(agenda.meetingAgendaID, sug.agendaSuggestionID, task.meetingAgendaTaskID) }} />
                                                                                </div> */}
                                                                                            <div className='task_div '>
                                                                                                <ButtonComponent.DeleteButton clickEvent={() => { removeTourSuggestionTask(sug.agendaSuggestionID, task.meetingAgendaTaskID) }} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <>
                                                            
                                                        </>
                                                    }
                                                </TabPanel>
                                            </>
                                        }

                                        <TabPanel>
                                            {meeting.meeting.meetingOtherIntPoints != null ?
                                                <>
                                                    <div className='p-1-rem mt-1-rem' style={{ backgroundColor: '#F5F4F4', borderRadius: '8px' }}>
                                                        <Row>
                                                            <Col md={11} className='d-flex'>
                                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                                    <p className='font-14 font-weight-500 mb-0'>{meeting.meetingOtherIntPointsBy != null && meeting.meetingOtherIntPointsBy.userAvatar}</p>
                                                                </div>
                                                                <div className=' pl-10'>
                                                                    <div>
                                                                        <div className='font-13 font-weight-600'>{meeting.meetingOtherIntPointsBy != null && meeting.meetingOtherIntPointsBy.userName}</div>
                                                                        <div className='font-12 font-weight-400'>{meeting.meetingOtherIntPointsBy != null && meeting.meetingOtherIntPointsBy.designation}</div>
                                                                    </div>
                                                                    <div className='font-weight-500'>
                                                                        {meeting.meeting.meetingOtherIntPoints}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={1}>
                                                                <ButtonComponent.EditButton clickEvent={() => {
                                                                    editOtherPoint()
                                                                }} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Row className='border_bottom_input task_description py-3'>
                                                        <div className='task_div col-md-10'>
                                                            <FormFields.BottomBorderInputField
                                                                md={12}
                                                                name='meetingOtherIntPoints'
                                                                val={formik.values.meetingOtherIntPoints}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                label='Add Points....'
                                                            />
                                                        </div>
                                                        <div className='task_div col-md-2'>
                                                            <span className='pl-10'>
                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={() => {
                                                                    saveOtherPoints()
                                                                }}>
                                                                    Add Point
                                                                </Button>
                                                            </span>
                                                        </div>
                                                    </Row>
                                                </>
                                            }
                                        </TabPanel>
                                    </Tabs>
                                </Row>
                            </Col>
                        </>
                    }
                </Row>
            </div>
        </>
    )
}
