import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as FormFields from "../../components/formcomponents";
import * as ButtonsComponents from "../../components/buttoncomponents";
import { useFormik } from "formik";
import { checkEmailAvailableTrue } from "../../helpers/API/Api";
import axios from "axios";
import * as CONFIG from "../../helpers/config";
import { PRIVILEGE_JSON, PRIVILEGE_ARRAY } from "../../helpers/privilegeList";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export default function Index() {
  const MySwal = withReactContent(Swal);
  const [isloggedin_status, setIsloggedin_status] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLogginIn, setIsLogginIn] = useState(false);

  useEffect(() => {
    if (isloggedin_status) {
      console.log("inside");
      if (localStorage.getItem("AuthToken") != null) {
        window.location.href = "/dashboard";
      } else {
        setIsloggedin_status(false);
      }
    }
  });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isForgot, setIsForgot] = useState(false);
  const [isError, setIsError] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const validate = () => {
    if (!formik.values.email) {
      formik.errors.email = "Required";
      setIsError(true);
      return true;
    } else {
      formik.errors.email = "";
      setIsError(false);
      return false;
    }
  };

  const signIn = () => {
    //let result = handleLogin();
    setIsLogginIn(true);
    let json_array = {
      PMSInstanceID: CONFIG.PMSInstanceID,
      UserEmail: formik.values.email,
      password: formik.values.password,
      SolutionID: CONFIG.SolutionID,
    };

    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.LOGIN_PRO_TMS, json_array, {
        headers: { "Ocp-Apim-Subscription-Key": CONFIG.API_KEY },
      })
      .then((res) => {
        console.log(res.data);
        if ((res.data.responseCode == "02000")) {
          localStorage.setItem("UserFullName", res.data.userFullName);
          localStorage.setItem("UserDesignation", res.data.userDesignation);
          localStorage.setItem("UserEmail", res.data.userEmail);
          localStorage.setItem("AuthToken", res.data.authToken);
          localStorage.setItem("LoginUserID", parseInt(res.data.userID));
          localStorage.setItem("isFirstLogin", res.data.firstLoginStatus);
          localStorage.setItem(
            "LogInUserRoleID",
            parseInt(res.data.userRoleID)
          );
          localStorage.setItem(
            "AccountSubscriptionID",
            parseInt(res.data.accountSubscriptionID)
          );
          localStorage.setItem("DivisionID", res.data.divisionID);
          localStorage.setItem("CompanyID", parseInt(res.data.companyID));
          localStorage.setItem("userAvatar", res.data.userAvatar);
          let privilege = [];
          let final_json = [];
          PRIVILEGE_ARRAY.map((json, k) => {
            final_json.push({ privilegeId: json.id });
          });
          let call = {
            userId: parseInt(res.data.userID),
            instanceID: CONFIG.PMSInstanceID,
            accountSubscriptionID: parseInt(
              localStorage.getItem("AccountSubscriptionID")
            ),
            solutionId: CONFIG.SolutionID,
            roleId: parseInt(localStorage.getItem("LogInUserRoleID")),
            tmsRolePermissionCheckRequestDetail: final_json,
          };
          axios
            .post(CONFIG.PRO_SERVER_URL + CONFIG.ROLES_PERMISSION, call, {
              headers: { "Ocp-Apim-Subscription-Key": CONFIG.API_KEY },
            })
            .then((res) => {
              console.log(res);
              res.data.map((pe) => {
                let cookie_data = PRIVILEGE_ARRAY.filter((x) => x.id == pe.id);
                console.log(cookie_data);
                localStorage.setItem(cookie_data[0].name, pe.result);
                setLoginError(false);
                setIsloggedin_status(true);
              });
            });
        } else if ((res.data.responseCode == "02001")) {
          console.log('inside elist if')
          setLoginError(true);
        }
      });
  };

  const checkEmailAvailable = (e) => {
    let u_email = e.target.value;
    formik.values.email = u_email;
    let res = validate();
    if (!res) {
      setUserName(userName);
    }
    // if(!res){
    //     let result = checkEmailAvailableTrue(formik.values.email )
    //     console.log(result,'here');
    // }
  };

  const setPwd = (e) => {
    formik.values.password = e.target.value;
  };

  const resetPassword = (e) => {
    const json_list = {
      UserEmail: formik.values.userid,
      PMSInstanceID: CONFIG.PMSInstanceID,
    };
    axios
      .post(CONFIG.PRO_SERVER_URL + CONFIG.CHECK_USER_AVAILABLE, json_list, {
        headers: {
          "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
        },
      })
      .then((res) => {
        if(res.data.responseCode == "02002"){
            const json_list = {
              UserEmail: formik.values.userid,
              PMSInstanceID: CONFIG.PMSInstanceID,
            };
            axios
            .post(CONFIG.PRO_SERVER_URL + CONFIG.FORGOT_PASSWORD, json_list, {
              headers: {
                "Ocp-Apim-Subscription-Key": CONFIG.API_KEY,
              },
            })
            .then((res) => {
              MySwal.fire(
                {
                    icon: 'success',
                    title: 'A new password has been generated and send to your email!',
                    type: 'success',
                    confirmButtonText: 'Close',
                    confirmButtonClass: "btn btn-primary",

                }).then(() => {

                })
            })
        }
        else{
            MySwal.fire(
                {
                    icon: 'danger',
                    title: 'Invalid user email. Please try again!',
                    type: 'success',
                    confirmButtonText: 'Close',
                    confirmButtonClass: "btn btn-primary",

                }).then(() => {

                })
        }
      })
      .catch((error) => {});
  };

  const setForgot = () => {
    setIsForgot(!isForgot);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      console.log("User pressed: ", event.key);

      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        signIn();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  //   const changePassword = () =>{
  //     const json_list = {
  //         "userEmail": formik.values.userid,
  //         "pmsInstanceID": CONFIG.PMSInstanceID,
  //         "password" : formik.values.changePassword
  //       }
  //       axios.post(CONFIG.PRO_SERVER_URL + CONFIG.RESET_PASSWORD, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
  //       .then((res) => {
  //         MySwal.fire(
  //           {
  //               icon: 'success',
  //               title: 'Password has been reset!',
  //               type: 'success',
  //               confirmButtonText: 'Close',
  //               confirmButtonClass: "btn btn-primary",

  //           }).then(() => {
  //             window.location.href='/login'
  //           });
  //       }).catch((error) => {
  //       })
  //   }

  return (
    <div className="main-wrapper login-wrapper login-image-background">
      <Row>
        <Col md={12} className="pt-3-rem pl-10-rem">
          <a href="/">
            <img
              src="assets/images/tmslanding/tmslogo.svg"
              className="img-fluid"
              alt="logo"
            />
          </a>
        </Col>
        <Row className="plr-10-rem">
          <Col md={7} sm={12} className='col-10 offset-1 col-lg-6 offset-lg-0'>
            <img
              src="assets/images/login-left-image.svg"
              className="img-fluid login-left-image mt-1-rem"
              alt="left"
            />
          </Col>
          <Col
            sm={12}
            md={4} 
            className='col-12  d-flex align-self-center py-3 py-md-0 col-mg-3'
          >
            <div className="align-items-center width-100l">
              <Card className="br-10 p-2-rem">
                <Card.Body>
                  <div className="font-normal font-weight-400 font-16 login-welcome-title">
                    Welcome to TMS
                  </div>
                  <div className="font-weight-500 font-22 login-welcome-sub-title">
                    Trading & Analytics Platform
                  </div>
                  {isForgot ? (
                    <Form className="pt-1-rem">
                      <div className="font-normal font-weight-400 font-16 login-welcome-title">
                        Reset Password
                      </div>

                      <FormFields.InputField
                        name="userid"
                        label="User ID / Email"
                        classname="pt-1-rem"
                        type="text"
                        val={formik.values.userid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <Col className="pt-1-rem">
                        <ButtonsComponents.LoginButton
                          value="Reset"
                          clickEvent={resetPassword}
                        />
                      </Col>
                      <Col className="pt-3-rem d-flex justify-content-center">
                        <a onClick={setForgot}>Back To Login</a>
                      </Col>
                    </Form>
                  ) : (
                    <Form className="pt-1-rem">
                      <FormFields.InputField
                        name="userid"
                        label="User ID / Email"
                        type="text"
                        val={formik.values.email}
                        onChange={checkEmailAvailable}
                      />
                      <div className="font-13 font-weight-500 error-text">
                        {formik.errors.email ? formik.errors.email : null}
                      </div>
                      <FormFields.InputField
                        name="userid"
                        label="Password"
                        type="password"
                        classname="pt-1-rem"
                        val={formik.values.password}
                        onChange={setPwd}
                      />
                      <div className="d-flex pt-1-rem">
                        <Col className="d-flex">
                          <Form.Check type="checkbox" />
                          <div className="font-12 d-flex align-items-center pl-10px">
                            Remember me
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          <span
                            className="font-12 login-forgot-password font-weight-500 pointer"
                            onClick={setForgot}
                          >
                            Forgot your password?
                          </span>
                        </Col>
                      </div>
                      <div>
                        {loginError == true ? 
                          <span className="input_error">
                            Invalid User Email or Password
                          </span>
                          :
                          ''
                        }
                      </div>
                      <Col className="pt-1-rem">
                        <Button
                          className="login-button"
                          type="button"
                          onClick={signIn}
                        >
                          Login
                        </Button>
                      </Col>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col md={12} className="pt-1-rem pl-10-rem">
          <span className="font-13">
            Powered by <b>STX Platform</b>
          </span>
        </Col>
      </Row>
    </div>
  );
}
