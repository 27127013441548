import React, { useState } from 'react'
import axios from 'axios'
import { Dropdown, Menu, Space } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { useFormik } from 'formik';
import * as FormFields from "../../components/formcomponents"
import * as CONFIG from "../../helpers/config"
import { Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { AddNewButton, AddNewButtonBlue, EditButton, BlueBorderButton } from '../../components/buttoncomponents'


export default function UserList(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(false)

    const edit = (e) =>{
        const userId = parseInt(e.currentTarget.id)
        console.log(props.list.user)
        const json_list =    {
            "userID": userId,
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "solutionID": CONFIG.SolutionID
          }

          axios.post(CONFIG.PRO_SERVER_URL+CONFIG.GET_USERS_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
          .then((res) => {
            //setuserslist(res.data.result)
            props.handleEdit(res.data.result[0])
            //toggle()
          }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })
    }
    const toggle = ()=>{
        setModal(!modal)
    }
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <>{localStorage.getItem('Edit_Supplier') == 3 && <span className=' font-14' id={props.list.user.userID} onClick={(e)=>{edit(e, props.list.user)}}>Edit</span>}</>
                ),
            }
          ]}
        />
    );
    const formik = useFormik({
        initialValues:
        {
            firstName: '',
            lastName: '',
            userEmail: '',
            divisionID : [],
            Roles: []
        }
    })
    
    const handleCssChange = () => {
        if(props.index % 2 == 0){
            return(
            <div className='avatar d-flex align-items-center justify-content-center'>
                <p className='font-14 font-weight-500 mb-0'>{props.list.user.userAvatar}</p>
            </div>
            )
            
        }
        else{
            return(
            <div className='avatar-secondary d-flex align-items-center justify-content-center'>
                 <p className='font-14 font-weight-500 mb-0'>{props.list.user.userAvatar}</p>
            </div>
            )
        }
    }

    const handleUserRole = () => {
        console.log(props.list.userrole)
    }
    return (
        <tr className='product_list_row align-items-center border-bottom-1 align-middle'>
            

            <td className = 'd-flex'>
                {handleCssChange()}
                <h4 className='d-flex mb-0 align-items-center userNameList font-weight-600 font-16 pl-10'>
                    {props.list.user.userFullName}
                </h4>
            </td>
            <td className = ''>
                <div className='useremail'>{props.list.user.userEmail}</div>
            </td>
            <td className = ''>
                {props.list.userrole.map((list,k)=>(
                    <div className='userrole my-2'><center>{list}</center></div>
                ))}
             
            </td>
            <td>
                {props.list.user.is_Active ?
                    <div className='active_user'><center>Active</center></div>
                :
                    <div className='disabled'><center>Disabled</center></div>
                }
            </td>
            <td>
                <EditButton id={props.list.user.userID} clickEvent={(e)=>{edit(e, props.list.user)}}/>
                {/* <Dropdown className='br-8 py-2' overlay={menu} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <img src = '/assets/images/Dot_icon.svg' />
                        </Space>
                        </a>
                </Dropdown> */}
            </td>
        </tr>
    )
}
