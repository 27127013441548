import React from 'react'
import { Dropdown, Menu, Space } from 'antd';
import { useMsal } from "@azure/msal-react";

export default function Menus() {
    const { instance } = useMsal();

    const logout = () => {
        localStorage.clear()
        instance.logoutRedirect({
          postLogoutRedirectUri: "/superadminlogin",
        });
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <a onClick={() => logout()}>
                            <span className='font-weight-400 font-14 pl-10 mb-1-rem' >Logout</span>
                        </a>
                    ),
                },
            ]}
        />
    );
    return (
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                        <p className='font-12 font-weight-500 mb-0'>SA</p>
                    </div>
                    <div><span className='font-weight-500 color-23 d-none d-sm-block d-md-block'>Super Admin</span><span className='font-weight-400 color-23 d-none d-sm-block d-md-block'>Admin</span></div>
                    <img src="../assets/images/DownwardArrow.svg" />
                </Space>
            </a>
        </Dropdown>
    )
}
