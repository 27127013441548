import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from "reactstrap"
import * as CONFIG from "../../../../helpers/config"
import { useFormik } from 'formik'
import axios from 'axios'
import { Row, Col, Form, Card } from "react-bootstrap"
import * as FormFields from "../../../../components/formcomponents"
import { useMsal } from "@azure/msal-react";


export default function Preclosure(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(props.view)
    const [doc1, setdoc1] = useState(false)
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }

    const formik = useFormik({
        initialValues: {
            "tradePreClosureCheckListID": 0,
            "is_CustomerInvoice": false,
            "customerInvoiceNo": "",
            "customerInvoiceNoDocument": "",
            "customerInvoiceNoDocumentName": "",
            "is_CustomerPaymentCompletionStatus": false,
            "customerPaymentRecieptNo": "",
            "customerPaymentDocument": "",
            "customerPaymentDocumentName": "",
            "is_SupplierInvoice": false,
            "supplierInvoiceNo": "",
            "supplierInvoiceDocument": "",
            "supplierInvoiceDocumentName": "",
            "is_SupplierPaymentCompletionStatus": false,
            "supplierPaymentRecieptNo": "",
            "supplierPaymentDocument": "",
            "supplierPaymentDocumentName": "",
        }
    })
    useEffect(() => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_PRE_CLOSURE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.length > 0) {
                    Object.assign(formik.values, res.data.result[0])
                    setdoc1(!doc1)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    return (
        <>

            <div className='p-4 mx-3 mt-4'>
                <h4 className='font-16 font-weight-600 color-707895'>PRE-CLOSURE UPDATES</h4>
            </div>

            <div className='add-edit-wrapper'>
                <div className='card card-body p-4 br-8 mb-4'>

                    <Row className='border-bottom-1 pt-1 pb-4'>
                        <Col md={5} className='d-flex align-items-center'>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_CustomerInvoice'
                                label='Customer Invoice.'
                                checked={formik.values.is_CustomerInvoice}
                            />
                        </Col>
                        <Col md={3}>
                            <span># {formik.values.customerInvoiceNo}</span>
                        </Col>
                        <Col md={3}>
                            <a download={formik.values.customerInvoiceNoDocumentName} href={formik.values.customerInvoiceNoDocument}>View Attachment</a>
                        </Col>
                    </Row>

                    <Row className='border-bottom-1 pt-1 pb-4'>
                        <Col md={5} className='d-flex align-items-center'>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_CustomerPaymentCompletionStatus'
                                label='Customer Payment Completion Status.'
                                checked={formik.values.is_CustomerPaymentCompletionStatus}
                            />
                        </Col>
                        <Col md={3}>
                            <span># {formik.values.customerPaymentRecieptNo}</span>
                        </Col>
                        <Col md={3}>
                            <a download={formik.values.customerPaymentDocumentName} href={formik.values.customerPaymentDocument}>View Attachment</a>
                        </Col>
                    </Row>
                    <Row className='border-bottom-1 pt-1 pb-4'>
                        <Col md={5} className='d-flex align-items-center'>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_SupplierInvoice'
                                label='Supplier Invoice.'
                                checked={formik.values.is_SupplierInvoice}
                            />
                        </Col>
                        <Col md={3}>
                            <span># {formik.values.supplierInvoiceNo}</span>
                        </Col>
                        <Col md={3}>
                            <a download={formik.values.supplierInvoiceDocumentName} href={formik.values.supplierInvoiceDocument}>View Attachment</a>
                        </Col>
                    </Row>

                    <Row className='border-bottom-1 pt-1 pb-4'>
                        <Col md={5} className='d-flex align-items-center'>
                            <Form.Check
                                className='mr-20 ms-3 pt-1-rem font-15 font-weight-400 color-2C2C2E'
                                type='checkbox'
                                name='is_SupplierPaymentCompletionStatus'
                                label='Supplier Payment Completion Status.'
                                checked={formik.values.is_SupplierPaymentCompletionStatus}
                            />
                        </Col>
                        <Col md={3}>
                            <span># {formik.values.supplierPaymentRecieptNo}</span>
                        </Col>
                        <Col md={3}>
                            <a download={formik.values.supplierPaymentDocumentName} href={formik.values.supplierPaymentDocument}>View Attachment</a>
                        </Col>
                    </Row>

                </div>



            </div>

        </>
    )
}
