import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { AddNewButton,BlueButton } from '../../components/buttoncomponents'
import AddEdit from './addedit/AddEdit'
import axios from 'axios'
import * as CONFIG from ".././../helpers/config"
import Productlist from './productlist'
import { Card, Col, FloatingLabel, Form } from 'react-bootstrap'
import View from './view/view'
import * as FormFields from "../../components/formcomponents"
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    const [isFirst, setIsFirst] = useState(true)
    const [isView, setIsView] = useState(false);
    useEffect(()=>{
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href= "/"
        }
        else{
            console.log('inside')
        }
    })

    useEffect(()=>{
        if(isFirst){
            getProductsList(); 
        }
        setIsFirst(false)
    },[isFirst])

    const [isProductAdd, setIsProductAdd] = useState(false);
    const [pList, setPList] = useState([]);
    const [selectedProduct, setSelected] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [productfilterlist, setproductfilterlist] = useState([])

    useEffect(()=>{
        console.log(isEdit, 'Is edit')
    },[isEdit])
    useEffect(()=>{
        console.log(isProductAdd, 'isProductAdd')
    },[isProductAdd])

    useEffect(()=>{
        console.log(selectedProduct, 'isProductAdd')
    },[selectedProduct])
    const addProduct = ()=>{
        setIsProductAdd(!isProductAdd)
        setSelected([]);
        setIsEdit(false)
    }

    const getProductsList = ()=>{
        console.log('inside')
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_LIST, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            setPList(res.data.result);
            setproductfilterlist(res.data.result)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const back =() =>{
        setIsView(false);
        addProduct();
        setIsEdit(false)
        getProductsList()
    }

    const view = (e)=>{
        const id = parseInt(e.target.id);
        getProductData(id,'view');
        if(selectedProduct.length > 0){
            setIsView(true)
        }
    }

    const getProductData = (id, st)=>{
        const json_list = {
            "instanceID" : CONFIG.PMSInstanceID,
            "accountSubscriptionID" : CONFIG.AccountSubscriptionID,
            "created_User" : CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.GET_PRODUCT_BY_ID, json_list, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setSelected(res.data.result);
            if(st == 'edit'){
                setIsProductAdd(true)
                setIsEdit(true)
            }
            else if(st == 'view'){
                setIsView(true)
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const viewChange = ()=>{
        setIsView(false)
        setIsEdit(false)
    }
    const edit = (e)=>{
        setIsView(false)
        const id = parseInt(e.target.id);
        setIsEdit(true)
        getProductData(id, 'edit')
    }

    
    const disable = (e) =>{
        const id = parseInt(e.target.id)
        let data =  pList.filter(x=> x.productID == id)
        let status = data[0].status;
        const json = {
            "id": id,
            "status": status==1?0:1,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL+CONFIG.CHANGE_STATUS, json, { headers: {"Authorization": `Bearer ${CONFIG.AuthToken}`,  "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            getProductsList();
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const searchProduct = (e)=>{
        let searchString = e.target.value
    }
    const handleSearch = (e)=>{
        let val = e.target.value
        let filter = pList.filter(x=> x.productCode.toLowerCase().includes(val.toLowerCase()) ||
            x.productName.toLowerCase().includes(val.toLowerCase()) ||
            x.categoryName.toLowerCase().includes(val.toLowerCase()) ||
            x.description.toLowerCase().includes(val.toLowerCase()) ||
            x.divisionName.toLowerCase().includes(val.toLowerCase()) ||
            x.companyName.toLowerCase().includes(val.toLowerCase()) ||
            x.uomName.toLowerCase().includes(val.toLowerCase())
        )
        setproductfilterlist(filter)
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
              
            <Sidebar id='4.1' isOpenid={4}/>
            <div className='width-100'>
                <Navbar/>
                <div className='d-flex inner-page-header justify-content-between mx-2'>

                    
                    
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/pageicons/productsicon.svg' className='inner-page-titleicon img-fluid'/><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>{isProductAdd?'NEW PRODUCT':'ALL PRODUCTS'}</span>
                    </div>
                    <div className='d-flex'>
                        {isProductAdd || isView ?
                        <BlueButton value='Back' clickEvent={isView?viewChange: addProduct}/>
                        :
                        <>
                        
                        <div className='me-3'>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='Search' 
                                        className="form_input form_input_color_change" 
                                        name='search'
                                        onChange={handleSearch} 
                                        onBlur={handleSearch} 
                                        />
                                    </FloatingLabel>
                                </Col>
                        </div>
                        {localStorage.getItem('Add_Product') == 3 && <AddNewButton value='Add New Product' class='bg-white' clickEvent={addProduct}/>}
                        </>
                        }
                    </div>
                </div>
                
                <div className=''>
                    {isProductAdd?
                        <>
                            <AddEdit back={back} selectedProduct={selectedProduct} isEdit={isEdit} cancel={addProduct}/>
                            
                        </>
                    :
                        <>
                        {isView ?
                        
                            <View isView={isView} selectedProduct={selectedProduct} cancel={viewChange}/>
                        :
                        <div className='inner-page-content  br-8 py-3'>
                            <div className='card card-body table-responsive-sm '>
                            <Card className='col-lg-12  list-scroll'>
                                <table className="table tCustom table-text">
                                    <thead>
                                        <tr className='bg-F8F9FA font-weight-500 color-707895 list-top'>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>Category</th>
                                            <th>Product Description</th>
                                            <th>Product Division</th>
                                            <th>Company</th>
                                            <th>UOM</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(localStorage.getItem('List_Products') == 1 || localStorage.getItem('List_Products') == 3) &&
                                            <>
                                                {productfilterlist.reverse().map((list,k)=>(
                                                    <Productlist data={list} view={view} edit={edit} disable={disable}/>
                                                ))}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        </div>
                        
                        }
                        </>
                    }
                </div>
            </div>
            

        </div>
    )
}
