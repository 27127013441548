import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import GenerateCustomerInvoice from "../ContractFinalized/GenerateCustomerInvoice"

function CustomerSignedContractList(props) {
    const { instance } = useMsal();
    const [customerInvoiceData, setPurchaseOrder] = useState()
    const [tradeData, setTradeData] = useState()
    const [tradeID, setTradeID] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [showView, setShowView] = useState(false)
    const [isloader, setisloader] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        setisloader(true)
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": 1
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SIGNED_CONTRACT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setPurchaseOrder(res.data.result)
                setisloader(false)

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }

    const downloadDoc = (e)=>{
        e.preventDefault()
        let id = parseInt(e.currentTarget.id)
        const json_list ={
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": 1, 
            "tradeID": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SIGNED_DOCUMENT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            console.log(res.data.result)
            var a = document.createElement("a"); //Create <a>
            a.href = res.data.result[0].customersingnedcontract; //Image Base64 Goes here
            a.download = res.data.result[0].customersingnedcontractname; //File name Here
            a.click(); //Downloaded file
        }).catch((error) => {
            if (error.response.status == 401) {
                localStorage.clear();
                instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                });
            }
        })
    }

    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='7.6' isOpenid={7} />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                        <div className='d-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mx-4 px-5 mt-5'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>CUSTOMER SIGNED CONTRACTS</span>
                        </div>
                    </div>
                    {showView == true ?
                        <GenerateCustomerInvoice data={tradeData} tradeID={tradeID} tab={1} test={'asasas'} />
                        :

                        <div className='inner-page-content mx-md-4 mx-2'>
                            <Card>
                                <CardBody>
                                    <div class="col-lg-12 list-scroll">
                                        <div class="table-responsive-sm ">
                                            <table className="table tCustom table-text">
                                                <thead className='p-1-rem'>
                                                    <tr className='text-uppercase list-top'>
                                                        <th>trade#</th>
                                                        <th>company & division details</th>
                                                        <th>customer details</th>
                                                        {/*<th>product details</th>*/}
                                                        {/*<th>customer</th>*/}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isloader ?
                                                        <center>
                                                            <img src='assets/images/loadertrad.gif' className='img-fluid' />
                                                        </center>
                                                        :
                                                        customerInvoiceData != null && customerInvoiceData.length > 0 ?
                                                            customerInvoiceData.map((values, k) => (
                                                                <tr>
                                                                    <td className='font-14 font-weight-500'><p className='mb-0 w-150'>{values.tradeNo}</p></td>
                                                                    <div className='pt-2'>
                                                                        <h4 className='tradeTrsactionsHeading d-flex align-items-center'>{values.companyname} <span className='mx-1 dotSep'>&#8226;</span> {values.divisionname} </h4>
                                                                    </div>
                                                                    <td className='font-14 font-weight-500'><p className='mb-0 w-150'>{values.customerName}</p></td>

                                                                    <td>
                                                                        {/*<button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600 w-150" id={values.tradeId} onClick={(e) => { handlePurchaseOrder(e) }}>View Invoice</button>*/}
                                                                        <a onClick={downloadDoc} id={values.tradeID}>{values.customersingnedcontractname}</a>

                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            ''
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    }
                </div>


            </div>
        </>

    )
}

export default CustomerSignedContractList