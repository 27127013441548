import React, {useState,  useEffect} from 'react'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Dropdown, Menu, Space } from 'antd';
import Sidebar from '../sidebar/sidebar'
import Navbar from '../navbar'
import { AddNewButton, AddNewButtonBlue, DeleteButton, EditButton } from '../buttoncomponents'
import AddDivisionsModal from './AddDivisionsModal'
import * as CONFIG from '../../helpers/config'
import { useMsal } from "@azure/msal-react";


export const List = (props)=>{
    const { instance } = useMsal();
    const handleEdit = (data, params) => {
        console.log(params, 'inside list')
        props.handleEdit(params)
    }

    const handleDelete = (data, params) => {
        console.log(params, 'inside list')
        props.handleDelete(params)
    }
    const menu = (
        <Menu
        
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14'  onClick={event => handleEdit(event, props.values)}>Edit</span>
                ),
            },
           
          ]}
        />
      );
      
    return(
        <tr className = ''>
        <td>
            {props.values.divisionName}
        </td>
        <td className = 'd-flex  flex-wrap align-items-center'>
            {props.values.divWorkflowDefinition.map((divisions, i)=>(
                    <div className = 'ms-2 division-listin-process-pill mb-2'>
                        {divisions.workflowDefinition.description}
                    </div>
            ))}    
        </td>
        <td className = 'ps-0 pe-0'>
            <EditButton   clickEvent={event => handleEdit(event, props.values)}/>
        </td>
        <td className = 'ps-0 pe-0'>
            <DeleteButton   clickEvent={event => handleDelete(event, props.values)}/>
        </td>
        </tr>
    )
}

function Divisions() {
    const MySwal = withReactContent(Swal)
    const { instance } = useMsal();
    const [showModal, setShowModal] = useState(false)
    const [checked, setChecked] = useState([])
    const [checkedName, setCheckedName] = useState([])
    const [divisionList, setDivisionList] = useState()
    const [edit, setEdit] = useState(false)
    const [editData, setEditData] = useState()

    useEffect(()=>{
        console.log(CONFIG.AuthToken)
        getList();
    },[])

    const getList = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
            
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION_, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
                console.log(res.data.result, 'divisions')
                setDivisionList(res.data.result)
        }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
        })  
    }

    const close = () => {
        setShowModal(false)
    }
    const handleCreateDivisions = () => {
        setShowModal(true)
    }
    const handleEdit = (data) => {
       
        setEditData(data)
        setEdit(true)
        setShowModal(true)
    }

    const handleDelete = (data) => {
        console.log(data)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": data.divisionID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.DELETE_DIVISION, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then(res => {
            if(res.data.responseCode == '09002'){
                MySwal.fire(
                    {
                    icon: 'error',
                    title: '<b><p>Unable to Delete</p></b>',
                    html: 'Division has been already been assigned. Deletion not applicable',
                    //type: 'error',
                    confirmButtonText: 'Back to List',
                    confirmButtonClass: "btn btn-primary",
                    backdrop: `
                    rgba(60, 60, 60, 0.5)
                    no-repeat
                  `
                  }).then(() => {
                    
                }); 
            }
            else{
                getList()
            }
        }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
        })  
        // setEditData(data)
        // setEdit(true)
        // setShowModal(true)
    }
    const closeEdit = () => {
        setEdit(false)
    }
    return (
    <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar/>
            <div className='width-100'>
                <Navbar />
                <div className='d-flex justify-content-between align-items-center mx-4 px-3 inner-page-header'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/Dashboard.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>DIVISIONS</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <AddNewButtonBlue value = 'Create Divisions' clickEvent = {handleCreateDivisions}/>
                    </div>
                </div>
                <div className='inner-page-content mx-4 px-3 mt-4 '>
                    <Card>
                        <CardBody>
                            <div class="col-lg-12 p-md-4 p-0">
                                <div class="table-responsive-sm ">
                                    <table className="table tCustom table-text">
                                        <thead className='p-1-rem'>
                                            <tr className='text-uppercase'>
                                                <th>Division</th>
                                                <th>Process</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {divisionList != null && divisionList.length > 0 ?
                                                divisionList.map((values,k) => (
                                                  <List handleDelete= {handleDelete} values = {values} handleEdit={handleEdit}/>
                                                ))
                                                :    
                                                ''
                                                }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                {showModal == true?
                    <AddDivisionsModal close={close} closeEdit={closeEdit} edit={edit} editData={editData} getList={getList}/>
                :
                
                ''
                }
            </div>
        </div>
  )
}

export default Divisions


