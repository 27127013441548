import React, { useEffect } from 'react'
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import Moment from "moment";
import { useState } from 'react';
import * as FormComponent from "../../../components/formcomponents"
import { useFormik } from 'formik';
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import { useMsal } from "@azure/msal-react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function GenerateInvoice(props) {
    console.log(props)
    const { instance } = useMsal();
    const MySwal = withReactContent(Swal)
    const [dueDate, setDueDate] = useState(false)
    const [bankList, setBankList] = useState([])
    const [isBankOpen, setIsBankOpen] = useState(false)
    const [modal, setmodal] = useState(false)
    const [bankAccountList, setBankAccountList] = useState([])
    const [selectedBank, setSelectedBank] = useState([])
    const [formulamodal, setformulamodal] = useState(false)
    const [acutalqualitylist, setactualqualitylist] = useState([])
    const [formulanext, setformulanext] = useState(false)

    const formik = useFormik({
        initialValues: {
            invoiceRemarks: '',
            bankAccountId: 0
        }
    })

    useEffect(() => {
        let finalList = []
        let index = 1
        props.data.qualityParameter.map((qt, k) => {
            let list = {
                "index": index,
                "parameterID": qt.qualityParameterID,
                "parameterType": 1,
                "parameterPer": 0,
                "parameterQty": 0,
                "parameterAmt": 0,
                "parameterAdjPer": 0,
                "parameterName": qt.qualityParametername
            }
            finalList.push(list)
            index++
        })
        props.data.traceElement.map((qt, k) => {
            let list = {
                "index": index,
                "parameterID": qt.traceElementID,
                "parameterType": 2,
                "parameterPer": 0,
                "parameterQty": 0,
                "parameterAmt": 0,
                "parameterAdjPer": 0,
                "parameterName": qt.traceElementName
            }
            finalList.push(list)
            index++
        })
        console.log(finalList)
        setactualqualitylist(finalList)
    }, [])

    const toggle = () => {
        setIsBankOpen(!isBankOpen)
    }


    const openBankList = () => {
        const json_list = {
            PMSAccountSubscriptionID: CONFIG.AccountSubscriptionID,
            PMSInstanceID: CONFIG.PMSInstanceID,
            companyID: props.data.companyID
        }

        axios.post(CONFIG.PMS_SERVER_URL + CONFIG.GET_COMPANY_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setBankAccountList(res.data.result[0].company.companyBankDetails)

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        setIsBankOpen(!isBankOpen)
    }

    const handleBankSelect = (e) => {
        console.log(e)
        if (e.target.checked) {
            let id = e.target.id
            let filter = bankAccountList.filter(x => x.bankDetailID == parseInt(id))
            console.log(filter)
            setSelectedBank(filter)
        }
    }
    const selectBank = () => {
        if (selectedBank.length > 0) {
            toggle()
        }
    }
    const saveInvoice = () => {
        const { value: text } = MySwal.fire(
            {
                icon: 'warning',
                title: 'Are you sure you wish to generate the invoice?',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                confirmButtonClass: "btn btn-primary",
                backdrop: `
            rgba(60, 60, 60, 0.5)
            no-repeat
          `
            }).then((result) => {
                if (result.isConfirmed) {
                    const json_list = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "created_User": CONFIG.LoginID,
                        "tradeID": props.data.tradeID,
                        "customerBankDetailsID": selectedBank[0].bankDetailID,
                        "dueDate": dueDate,
                        "paymentTermID": props.data.customerPaymentTypeID,
                        "tradeCustInvActualResult": null,
                        "customerInvAmount": parseFloat(props.data.sallingPrice * props.data.quantity),
                        "customerInvQty": props.data.quantity,
                        "invoiceRemarks": formik.values.invoiceRemarks
                    }
                    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_CUSTOMER_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                        .then((res) => {
                            props.toggle()
                        }).catch((error) => {
                            if (error.response.status == 401) {
                                localStorage.clear();
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                });
                            }
                        })
                }

            })

    }

    const handlemeetingdate = (date) => {
        console.log(date)
        if (date == null) {
            setDueDate(false)
        }
        else {
            setDueDate(date)
        }
    }

    const handleQualityPercentage = (e) => {
        let id = parseInt(e.target.id)
        let value = e.currentTarget.value
        console.log(id)
        console.log(value)
        let actuallist = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.parameterID == id) {
                console.log(actuallist[k])
                actuallist[k].parameterPer = parseFloat(value)
            }
        })
        console.log(actuallist)
        setactualqualitylist(actuallist)
    }

    const handleTracePercentage = (e) => {
        let id = parseInt(e.target.id)
        let value = e.currentTarget.value
        console.log(id)
        console.log(value)
        let actuallist = [...acutalqualitylist]
        acutalqualitylist.map((list, k) => {
            if (list.parameterID == id) {
                console.log(actuallist[k])
                actuallist[k].parameterPer = parseFloat(value)
            }
        })
        console.log(actuallist)
        setactualqualitylist(actuallist)
    }

    const handleFormula = () => {
        setformulamodal(!formulamodal)
        setformulanext(false)
    }
    
    const handleCheck = (e) => {
        let val = [...acutalqualitylist]
        if (e.target.checked) {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].ischeck = true
                }
            })
        }
        else {
            let index = parseInt(e.target.id)
            acutalqualitylist.map((list, k) => {
                if (list.index == index) {
                    val[k].ischeck = false
                }
            })
        }
        console.log(val)
        setactualqualitylist(val)
    }

    const handleAdjustment = (e)=>{
        let index = parseInt(e.target.id)
        let val = e.target.value
        let li = [...acutalqualitylist]
        acutalqualitylist.map((list,k)=>{
            if(list.index == index){
                li[k].parameterAdjPer = parseFloat(val)
            }
        })
        console.log(li)
        setactualqualitylist(li)
    }
    return (
        <Card>
            <Card.Body className=' p-3-rem'>
                <div className='d-flex justify-content-end'>
                    <img src='assets/images/logo.svg' />
                </div>
                <div className='font-weight-600 d-flex justify-content-center uline'>
                    COMMERCIAL INVOICE
                </div>
                <div className='d-flex justify-content-between mt-1-rem'>
                    <div>
                        <div className='font-weight-500'>
                            INVOICE NUMBER:
                        </div>
                        <div className='font-weight-500'>
                            VAT TRN NO:
                        </div>
                    </div>
                    <div>
                        <div className='font-weight-500'>
                            INVOICE DATE: {Moment(new Date()).format('DD/MM/YYYY')}
                        </div>
                        <div className='font-weight-500 d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>DUE DATE:</div>
                            <div>
                                <FormComponent.DatePickBottomBorder
                                    md={12}
                                    handleCompletionDate={handlemeetingdate}
                                    label='Due Date'
                                    val={dueDate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='invoice_border  p-1-rem mt-1-rem'>
                    <div className='font-weight-500'>
                        NAME AND ADDRESS OF CUSTOMER:
                    </div>
                    <div>
                        {props.data.customerName}<br />
                        {props.data.customerAddress}<br />
                        {props.data.companyTelephone}<br />
                        {props.data.companyWebsite}<br />
                    </div>
                    <div className='font-weight-600 d-flex justify-content-center'>
                        CONTRACT NUMBER : {props.data.tradeNo}
                    </div>
                </div>
                <table className='invoice_border p-3-rem width-100l'>
                    <thead className='invoice_border'>
                        <tr className='width-100l'>
                            <th clssName='br-right'>S.NO</th>
                            <th clssName='br-right'>DESCRIPTION OF GOODS</th>
                            <th clssName='br-right'>QUANTITY</th>
                            <th clssName='br-right'>UNIT PRICE</th>
                            <th clssName='br-right'>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                <div className='font-weight-600 uline'>{props.data.productName}</div>
                                <div className='mt-1-rem font-weight-500'>COUNTRY OF ORIGIN : {props.data.loadPortCountry}</div>
                                <div className='mt-1-rem'><span className='uline'>BILL OF LADING, DATE, QUANTITY :</span> <br />
                                    {props.data.billOfLadingNo}<br />
                                    {props.data.billOfLadingDate}<br />
                                    {props.data.quantity}<br />
                                </div>
                                <div className='mt-1-rem font-weight-500'>PORT OF LOADING : {props.data.loadPort}, {props.data.loadPortCountry}</div>
                                <div className='font-weight-500'>PORT OF DISCHARGE : {props.data.loadPort}, {props.data.destinationPort}</div>
                                <div className='font-weight-500'>TRADE TERM : {props.data.loadPort}</div>
                                <div className='font-weight-500'>VESSEL NAME : {props.data.vesselName}</div>
                                <div className='font-weight-500'>UNIT PRICE : {props.data.sallingPrice} USD</div>
                            </td>
                            <td >
                                <span className='font-weight-500'>{props.data.quantity} MT</span>
                            </td>
                            <td>
                                <div className='font-weight-500'>{props.data.sallingPrice} USD</div>
                                <div className='mt-1-rem font-weight-600'>{props.data.loadPort}, {props.data.loadPortCountry}</div>
                            </td>
                            <td>
                                <span className='font-weight-500'>{props.data.sallingPrice * props.data.quantity} USD</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card.Body>
            <Card.Footer>
                <span><BlueBorderButton value='Cancel' clickEvent={props.toggle} /></span>
                <span className='pl-10'><BlueButton value='Save' clickEvent={saveInvoice} /></span>
            </Card.Footer>
        </Card >
    )
}
