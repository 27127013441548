import React from 'react'
import { Dropdown, Menu, Space } from 'antd';

export default function Quality_list(props) {
    console.log(props)
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.value.key} onClick={props.editList}>Edit</span>
                ),
            },
            {
                key: '2',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.value.key} onClick={props.remove}>Remove</span>
                ),
            }
          ]}
        />
      );
    return (
        <tr className='customer_supplier_row font-weight-600 font-13 color-707895'>
            <td className='color-585966 px-4'>{props.value.productQualityParameterName}</td>
            <td className='color-585966'>%</td>
            <td className='color-585966'>{props.value.typicalValueRange}</td>
            <td className='color-585966'>{props.value.rejectionValueRange}</td> 
            <td className='text-right'>
                <Dropdown overlay={menu}>
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <img src = '/assets/images/Dot_icon.svg' />
                    </Space>
                    </a>
                </Dropdown>
            </td>
        </tr>
    )
}
