import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from "react-bootstrap"
import * as FormFields from "../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import Autocomplete from '@mui/material/Autocomplete';
import { useFormik } from 'formik'
import { CompanyType, CountryList, PaymentTypeList, CustomerCategoryList, IncotermsList, SofList, StateList } from "../../helpers/API/Api"
import TextField from '@mui/material/TextField';
import * as CONFIG from "../../helpers/config";
import { useMsal } from "@azure/msal-react";
import axios from 'axios'

export default function Add_ins_form(props) {
    const { instance } = useMsal();
    console.log(props)
    const [doc, setDoc] = useState(false)
    const [bankList, setBankList] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [isBankSet, setIsBankSet] = useState(false)
    const [listbox, setListbox] = useState({
        options: [],
        getOptionLabel: (option) => { }
    })

    const formik = useFormik({
        initialValues: {
            bankCountryID: 0,
            bankName: '',
            branchName: '',
            unitNo: '',
            buildingName: '',
            streetName: '',
            cityName: '',
            countryID: 0,
            stateName: '',
            poBox: '',
            bankDefault: null,
            financialInstituteContact: []
        }
    })
    const saveData = () => {
        props.saveData(formik.values)
        props.changeindex(1)
    }

    useEffect(() => {
        Object.assign(formik.values, props.data)

        if (props.data.bankCountryID != null) {
            console.log('inside')
            const json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "ID": parseInt(props.data.bankCountryID) // Country ID
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_BANK_LIST_BY_COUNTRY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setBankList(res.data.result)
                    let listbs = {
                        options: res.data.result,
                        getOptionLabel: (option) => option.bankName
                    }
                    console.log('bank result', res.data.result);
                    setListbox(listbs)
                    if (props.isEdit == true) {
                        let filter = res.data.result.filter(x => x.bankID == props.data.bankID)[0]
                        console.log(filter)
                        setIsBankSet(true)
                        setSelectedBank(filter)
                    }
                    else {
                        if(formik.values.bank != null){
                            setIsBankSet(true)
                            setSelectedBank(formik.values.bank)
                        }
                        else{
                            let bank ={
                                bankName : formik.values.bankName
                            }
                            setSelectedBank(bank)
                            let banklist = res.data.result
                            banklist.push(bank)
                            setBankList(banklist)
                            setIsBankSet(true)
                        }
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        setDoc(!doc)
    }, [props])

    const handleCountry = (e) => {
        formik.values.bankCountryID = e.currentTarget.value
        setDoc(!doc)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "ID": parseInt(formik.values.bankCountryID) // Country ID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_BANK_LIST_BY_COUNTRY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setBankList(res.data.result)
                let listbs = {
                    options: res.data.result,
                    getOptionLabel: (option) => option.bankName
                }
                setListbox(listbs)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleSelectBankName = (event, values) => {
        formik.values.bankID = parseInt(values.bankID)
        formik.values.bank = values
        console.log(values)
        setDoc(!doc)
    }
    const handleBankName = (e) => {
        formik.values.bankName = e.target.value
        formik.values.bankID = 0
        formik.values.bank =null
    }
    useEffect(() => {
        console.log(isBankSet)
    }, [isBankSet])
    return (
        <Card style={{ height: '70vh', background: 'transparent' }} >
            <Card.Body style={{ background: '#fff' }}>
                <div className='mx-2 m-1-rem'>
                    <Row>
                        <FormFields.InputSelect
                            md={3}
                            name='bankCountryID'
                            label='Country'
                            classname='pt-1-rem'
                            type='text'
                            optionField={<CountryList
                                cntid={formik.values.bankCountryID} />}
                            onChange={handleCountry}
                            onBlur={handleCountry}
                            val={formik.values.bankCountryID}
                        />
                        {(isBankSet && selectedBank != null) &&
                            <Col md={3} className="pt-1-rem">
                                <div className='free-solo-add'>
                                <Autocomplete
                                    {...listbox}
                                    id="free-solo-demo"
                                    className='br-trans'
                                    freeSolo
                                    onChange={handleSelectBankName}
                                    defaultValue={selectedBank}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Bank Name" variant="standard"
                                            onChange={handleBankName} className='br-trans'/>
                                    )}
                                />
                                </div>
                            </Col>
                        }
                        {(props.isAdd == true && props.isEdit == false && isBankSet == false) &&
                            <Col md={3} className="pt-1-rem">
                                <div className='free-solo-add '>
                                <Autocomplete
                                    {...listbox}
                                    id="free-solo-demo"
                                    className='br-trans'
                                    freeSolo
                                    onChange={handleSelectBankName}
                                    defaultValue={selectedBank}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Bank Name" variant="standard"
                                            onChange={handleBankName} className='br-trans' />
                                    )}
                                />
                                </div>
                            </Col>
                        }
                        <FormFields.InputField
                            md={3}
                            name='branchName'
                            label='Branch Name'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.branchName}
                        />
                    </Row>
                    <Row>
                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466 d-flex'>ADDRESS</div>
                    </Row>
                    <Row>
                        <FormFields.InputField
                            md={3}
                            name='unitNo'
                            label='Unit No'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.unitNo}
                        />
                        <FormFields.InputField
                            md={3}
                            name='buildingName'
                            label='Building Name'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.buildingName}
                        />
                        <FormFields.InputField
                            md={3}
                            name='streetName'
                            label='Street Name'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.streetName}
                        />
                        <FormFields.InputField
                            md={3}
                            name='cityName'
                            label='City'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.cityName}
                        />
                        {/* <FormFields.InputSelect
                            md={3}
                            name='countryID'
                            label='Country'
                            classname='pt-1-rem'
                            type='text'
                            optionField={<CountryList
                                cntid={formik.values.countryID} />}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.countryID}
                        /> */}
                        <FormFields.InputField
                            md={3}
                            name='stateName'
                            label='State'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.stateName}
                        />
                        <FormFields.InputField
                            md={3}
                            name='poBox'
                            label='Post Box'
                            classname='pt-1-rem'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            val={formik.values.poBox}
                        />
                    </Row>
                </div>
            </Card.Body>
            <Card.Footer className='form-card-footer pt-4 d-flex'>
                <span><BlueBorderButton value='Cancel' clickEvent={props.back}/></span>
                <span className='pl-10 '><BlueButton className='font-weight-500' value='Next' clickEvent={saveData} /></span>
            </Card.Footer>
        </Card>
    )
}
