import React from 'react';
import ReactDOM from 'react-dom';
import './global_styles.css';
import 'bootstrap/dist/css/bootstrap.css';


import 'antd/dist/antd.css';
import 'react-tabs/style/react-tabs.css';
import "react-datepicker/dist/react-datepicker.css";
import Divisions from './components/Top Bar Menu Components/Divisions';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./features/Dashboard";
import Customers from "./features/Customers";
import CustomerCategory from "./features/Customers/categories"
import CustomerIndustry from "./features/Customers/industry"
import Suppliers from "./features/Suppliers";
import SupplierCategory from "./features/Suppliers/categories"
import SupplierIndustry from "./features/Suppliers/industry"
import Products from "./features/Products";
import ProductCategory from './features/Products/categories';
import ProductGroup from './features/Products/groups';
import ProductUOM from './features/Products/uom';
import ProductGrade from './features/Products/grades';
import QualityParameters from './features/Products/quality';
import ProductQualityTrace from "./features/Products/productqualitytrace/list"
import TraceElements from './features/Products/trace';
import DraftContract from "./features/DraftContract";
import CostSheet from "./features/CostSheet/costsheet";

import ExportCountryList from "./features/testcountry";

import TradeEnquiries from './features/Trading/TradeEnquiries';
import Login from "./features/Login";
import Trading from "./features/Trading";
import TradeTransactions from "./features/Trading/Trade Transactions"
import PreShipment from './features/Trading/ContractFinalized/PreShipment';
import PostFixtures from './features/Trading/ContractFinalized/PostFixtures';
import CompleteLoading from './features/Trading/ContractFinalized/CompleteLoading';
import DischargePortUpdates from './features/Trading/ContractFinalized/DischargePortUpdates';
import PreClosure from './features/Trading/ContractFinalized/PreClosure';
import Costsheet from './features/CostSheet/costsheet';
import Actualcostsheet from './features/CostSheet/actualcostsheet';
import FreightCharges from "./features/FreightCharges";
import VesselInformation from './features/Trading/ContractFinalized/VesselInformation';
import Approval from "./features/Approval";
import Settings from './features/Settings';
import GenerateCustomerProformaInvoice from './features/Trading/ContractFinalized/GenerateCustomerProformaInvoice';
import GenerateCustomerInvoice from './features/Trading/ContractFinalized/GenerateCustomerInvoice';
import CustomerInvoiceList from './features/Trading/InvoiceLists/CustomerInvoiceList';
import CustomerProformaInvoiceList from './features/Trading/InvoiceLists/CustomerProformaInvoiceList';
import GenerateSupplierProformaInvoice from './features/Trading/ContractFinalized/GenerateSupplierPurchaseOrder';
import UpdateSupplierProformaInvoice from './features/Trading/ContractFinalized/UpdateSupplierProformaInvoice';
import SupplierInvoice from './features/Trading/ContractFinalized/SupplierInvoice';
import SupplierPurchaseOrderList from './features/Trading/InvoiceLists/SupplierPurchaseOrderList';
import SupplierInvoiceList from './features/Trading/InvoiceLists/SupplierInvoiceList';
import Meetings from "./features/Meetings"
import Users from "./features/Users"
import FAQ from "./features/FAQ"
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './authConfig';
import OrganisationSetup from './components/Top Bar Menu Components/Organisation Setup'
import LCDocuments from './features/Trading/ContractFinalized/LCDocuments';
import UpdateInsuranceDetails from './features/Trading/ContractFinalized/UpdateInsuranceDetails';
import LoadingUpdates from './features/Trading/ContractFinalized/LoadingUpdates';
import ShipmentETA from './features/Trading/ContractFinalized/ShipmentETA';
import ViewOrganisationSettings from './components/Top Bar Menu Components/Organisation Setup/Components/ViewOrganisationSettings';
import EditOrganisation from './components/Top Bar Menu Components/Organisation Setup'
import CustomerPayment from './features/Trading/Shipment Completed/CustomerPayment';
import CustomerReport from './features/Reports/customers';
import ShipmentReport from './features/Reports/shipments';
import TradeReport from './features/Reports/trades';
import PurchaseAndSalesReport from './features/Reports/purchaseAndsales';
import PerformanceReport from './features/Reports/Performances';
import CustomerSignedContractList from './features/Trading/InvoiceLists/CustomerSignedContractList';
import SupplierSignedContractList from './features/Trading/InvoiceLists/SupplierSignedContractList';
import ShipmentSignedContractList from './features/Trading/InvoiceLists/ShipmentSignedContractList';
import CustomerInvoice from "./features/Invoices/CustomerInvoice";
import CustomerProformaInvoice from  "./features/Invoices/CustomerPerformaInvoice";
import CustomerPo from "./features/Invoices/CustomerPo";
import CustomerPOList from './features/Trading/InvoiceLists/CustomerPOList';
import Tasks from "./features/Tasks/index";
import BroadCast from  "./features/Broadcast";
import TourReports from "./features/TourReports";
import FinancialInstitution from "./features/FinancialInstitution"
import ContactList from './features/FinancialInstitution/contactmainlist';
import Landing from "./features/Landing"
import SuperAdmin  from "./features/SuperAdmin"
import SuperAdminLogin from "./features/SuperAdminLogin"
import DebitCredit from "./features/Invoices/DebitCredit";
import CustomerDebitCreditList from "./features/Invoices/DebitCredit/Customer/list"
import SupplierDebitCreditList from "./features/Invoices/DebitCredit/Supplier/list"


// Configuration object constructed.

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/superadmin" element={<SuperAdmin />} />
                <Route path="/superadminlogin" element={<SuperAdminLogin />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers-category" element={<CustomerCategory />} />
                <Route path="/customers-industry" element={<CustomerIndustry />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/suppliers-category" element={<SupplierCategory />} />
                <Route path="/suppliers-industry" element={<SupplierIndustry />} />
                <Route path="/products" element={<Products />} />
                <Route path="/productcategories" element={<ProductCategory />} />
                <Route path="/productgroups" element={<ProductGroup />} />
                <Route path="/productuoms" element={<ProductUOM />} />
                <Route path="/productgrades" element={<ProductGrade />} />
                <Route path="/qualityparameters" element={<QualityParameters />} />
                <Route path="/traceelements" element={<TraceElements />} />
                <Route path="/productparameters" element={<ProductQualityTrace />} />
                <Route path="/trade-enquiry-create" element={<TradeEnquiries />} />
                <Route path="/trading" element={<Trading />} />
                <Route path="/draftcontract" element={<DraftContract />} />
                <Route path="/trade-transactions" element={<TradeTransactions />} />
                <Route path="/pre-shipment" element={<PreShipment />} />
                <Route path="/post-fixtures" element={<PostFixtures />} />
                <Route path="/complete-loading" element={<CompleteLoading />} />
                <Route path="/discharge-port" element={<DischargePortUpdates />} />
                <Route path="/pre-closure" element={<PreClosure />} />
                <Route path="/costsheet" element={<Costsheet />} />
                <Route path="/freightcharges" element={<FreightCharges />} />
                <Route path="/costsheet" element={<Costsheet/>} />
                <Route path="/vesselinformation" element={<VesselInformation/>} />
                <Route path="/actualcostsheet" element={<Actualcostsheet/>} />
                <Route path="/approvals" element={<Approval/>} />
                <Route path='/settings' element={<Settings/>}/>
                <Route path='/customer-proforma-invoice' element={<GenerateCustomerProformaInvoice/>}/>
                <Route path='/customer-invoice' element={<GenerateCustomerInvoice/>}/>
                <Route path='/customer-invoice-list' element={<CustomerInvoiceList/>}/>
                <Route path='/customer-proforma-invoice-list' element={<CustomerProformaInvoiceList/>}/>
                <Route path='/supplier-purchase-order' element={<GenerateSupplierProformaInvoice/>}/>
                <Route path='/update-supplier-proforma-invoice' element={<UpdateSupplierProformaInvoice/>}/>
                <Route path='/supplier-invoice' element={<SupplierInvoice/>}/>
                <Route path='/supplier-purchase-order-list' element={<SupplierPurchaseOrderList/>}/>
                <Route path='/supplier-invoice-list' element={<SupplierInvoiceList/>}/>
                <Route path='/customer-contract-list' element={<CustomerSignedContractList/>}/>
                <Route path='/supplier-contract-list' element={<SupplierSignedContractList/>}/>
                <Route path='/shipment-contract-list' element={<ShipmentSignedContractList/>}/>
                <Route path='/divisions-list' element={<Divisions/>}/>
                {localStorage.getItem('User-Menu') == 3?
                        <Route path="/users" element={<Users/>} />
                    :
                        <></>
                }
                <Route path="/meetings" element={<Meetings/>} />
                <Route path="/organisation-setup" element={<OrganisationSetup/>} />
                <Route path="/lc-documents" element={<LCDocuments/>} />
                <Route path="/update-insurance-details" element={<UpdateInsuranceDetails/>} />
                <Route path="/loading-updates" element={<LoadingUpdates />} />
                <Route path="/shipment-eta" element={<ShipmentETA />} /> 
                <Route path="/organisation-settings" element={<ViewOrganisationSettings />} /> 
                <Route path="/organisation-setup" element={<OrganisationSetup />} /> 
                <Route path="/customer-payments" element={<CustomerPayment />} /> 
                <Route path="/customer-report" element={<CustomerReport />} />
                <Route path="/trade-report" element={<TradeReport />} />
                <Route path="/shipment-report" element={<ShipmentReport />} />
                <Route path="/purchase-sales-report" element={<PurchaseAndSalesReport />} />
                <Route path="/performance-report" element={<PerformanceReport />} />
                <Route path='/generate-customer-invoice' element={<CustomerInvoice/>}/>
                <Route path='/generate-customer-proforma-invoice' element={<CustomerProformaInvoice/>}/>
                <Route path='/generate-customer-po' element={<CustomerPo />} />
                <Route path='/customer-po-list' element={<CustomerPOList />} />
                <Route path="/faq" element={<FAQ />} /> 
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/broadcast" element={<BroadCast />} />
                <Route path="/tourreports" element={<TourReports />} />
                <Route path="/financialinstitutions" element={<FinancialInstitution />} />
                <Route path="/contactlist" element={<ContactList />} />
                <Route path="/exportcntlist" element={<ExportCountryList />} />
                <Route path="/debitorcredit" element={<DebitCredit />} />
                <Route path="/suppliercreditdebit" element={<SupplierDebitCreditList />} />
                <Route path="/customercreditdebit" element={<CustomerDebitCreditList />} />
                {/* <Routers/> */}
            </Routes>
        </BrowserRouter>
    </MsalProvider>
    ,
    document.getElementById('root')
);
