import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton,} from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import GenerateSupplierPurchaseOrder from "../ContractFinalized/GenerateSupplierPurchaseOrder"
import ViewSupplierPurchaseOrder from './Components/ViewSupplierPurchaseOrder'

function SupplierPurchaseOrderList(props) {
    const { instance } = useMsal();
    const [supplierProformaInvoiceData, setPurchaseOrder] = useState()
    const [tradeData, setTradeData] = useState()
    const [tradeID, setTradeID] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [showView, setShowView] = useState(false)
    let navigate = useNavigate();

    useEffect(()=> {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            
            
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_SUPPLIER_PO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
               console.log(res.data.result)
               setPurchaseOrder(res.data.result)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    },[])
    
    const back = () => {
        navigate('/trade-transactions', { state:{ tab: 1,}});
    }

    const handleClose = () => {
        setShowView(false)
    }
    const handlePurchaseOrder = (e) => {
        console.log(e.target.id)
        console.log(supplierProformaInvoiceData)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id":parseInt(e.target.id)
            
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAIL_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
           console.log(res.data.result)
           setTradeData(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
           console.log(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
           setTradeID(supplierProformaInvoiceData.tradeId)
           setShowView(true)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }
    const toggleInvoice = () =>{
        console.log('touch')
        setShowView(false)
    }
  return (
    <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar id='7.4' isOpenid={7} />
            <div className='width-100'>
                <Navbar />
                    <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                        <div className = 'd-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div>
                    </div>
                <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-5'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>SUPPLIER PURCHASE ORDERS</span>
                    </div>
                </div>
                <div className='inner-page-content mx-md-4 mx-2 '>
                    <Card>
                        <CardBody>
                            <div class="col-lg-12 list-scroll">
                                <div class="table-responsive-sm ">
                                    <table className="table tCustom table-text">
                                        <thead className='p-1-rem'>
                                            <tr className='text-uppercase list-top'>
                                                <th>supplier purchase order#</th>
                                                <th>purchase order date</th>
                                                <th>trade#</th>
                                                <th>supplier</th>
                                                <th>Document</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {supplierProformaInvoiceData != null && supplierProformaInvoiceData.length > 0 ?
                                            supplierProformaInvoiceData.map((values,k) =>(
                                            <tr>
                                            <td className='font-14 font-weight-500'><p className='w-150 mb-0'>{values.supplierPurchaseOrderNo}</p></td>
                                            <td className='font-14 font-weight-500'><p className='w-150 mb-0'>{Moment(values.supplierPurchaseOrderDate).format("DD/MM/YYYY")}</p></td>
                                            <td className='font-14 font-weight-500'><p className='w-150 mb-0'>{values.tradeNo}</p></td>
                                                <td>
                                                    <div className = 'd-flex flex-column w-150'>
                                                        <h6 className='font-14 font-weight-500'>
                                                            {values.supplierName}
                                                        </h6>
                                                        <div>
                                                            {values.supplierCode}
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                                <td>
                                                    <a download={values.supplierPurchaseOrderDocumentName} href={values.supplierPurchaseOrderDocument}>{values.supplierPurchaseOrderDocumentName}</a>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600 w-150" id={values.tradeId}  onClick={(e)=>{handlePurchaseOrder(e)}}>View PO</button>
                                                </td>
                                            </tr>
                                        )) 
                                        :
                                        ''
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    {showView == true ? 
                    <ViewSupplierPurchaseOrder toggle={toggleInvoice} invoiceList = {true} handleClose = {handleClose} data = {tradeData} tradeID = {tradeID} tab={1} test={'asasas'}/>
                    :
                    ''}
                </div>
                
            </div>

        

        </div>
  )
}

export default SupplierPurchaseOrderList