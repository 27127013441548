import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as FormFields from "../../../components/formcomponents"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import DatePicker from "react-datepicker";
import * as ButtonComponent from "../../../components/buttoncomponents"
import * as CONFIG from "../../../helpers/config";
import { useMsal } from "@azure/msal-react";
import AutoSuggest from "react-autosuggest";
import axios from 'axios'
import { Col, Form, FloatingLabel, Button, Row, ButtonToolbar, Accordion } from "react-bootstrap"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import AddExternalUser from './addExternalUser';
import Chip from '@mui/material/Chip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Moment from "moment"
import { fontWeight } from '@mui/system';

export default function AddMeeting(props) {
    console.log(props)
    const styles = {
        input: 'border p-2 bg-white w-full',
        listbox: 'border p-2 bg-white w-full'
    }
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [agendaList, setAgendaList] = useState([])
    const [agendaStatus, setAgendStatus] = useState(false)
    const [meetingtypelist, setmeetingtypelist] = useState([])
    const [meetingtypid, setmeetingid] = useState('')
    const [categoryavail, setcategoryavail] = useState(false)
    const [meetingdate, setmeetingdate] = useState(false)
    const [meetingtype, setmeetingtype] = useState(1)
    const [hosttype, sethosttype] = useState(1)
    const [userlist, setuserlist] = useState([])
    const [hostmodal, sethostmodal] = useState(false)
    const [taskduedate, settaskduedate] = useState(false)
    const [taskstatus, settaskstatus] = useState(false)
    const [taskassignedtouser, settaskassignedto] = useState(0)
    const [attendeeslist, setattendeeslist] = useState([])
    const [notifyuserlist, setnotifyuserlist] = useState([])
    const [isTaskEdit, setistaskedit] = useState(false)
    const [preemeetingstatus, setpreemeetingstatus] = useState(false)
    const [index, setindex] = useState(0)
    const [premeetinglist, setpreemeetinglist] = useState([])
    const [hostid, sethostid] = useState(0)
    const [meetingstarttime, setmeetingstarttime] = useState(false)
    const [meetingendtime, setmeetingendtime] = useState(false)
    const [key, setKey] = useState(0)
    const [doc, setDoc] = useState(false)
    const [agendaindex, setAgendaindex] = useState(0)
    const [isagendaedit, setIsagendaedit] = useState(false)
    const [premeetingkey, setPremeetingkey] = useState(0)

    useEffect(() => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_CATEGORY, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setmeetingtypelist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        getUsers()

    }, [])

    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        console.log(meetingtypid)
        console.log(hosttype)
        console.log(agendaList)
    }, [meetingtypid, hosttype, agendaList])

    const listbox = {
        options: meetingtypelist!= null ? meetingtypelist: [],
        getOptionLabel: (option) => option.meetingCategoryName
    }
    const toggle = () => {
        setmodal(!modal)
        props.toggle()
    }

    const saveContract = () => {
        console.log(meetingstarttime)
        console.log(meetingendtime)

        const json_list = {
            "is_Meeting": 1,
            "meetingTitle": formik.values.meetingtitle,
            "meetingDate": meetingdate,
            "companyID": props.company.company.companyID,
            "strMeetingStartTime": Moment(new Date(meetingstarttime)).format('HH:mm:ss').toString(),
            "strMeetingEndTime": Moment(new Date(meetingendtime)).format('HH:mm:ss').toString(),
            "meetingType": meetingtype,
            "meetingHost": hosttype,
            "meetingHostUserID": hostid,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "meetingAgenda": agendaList,
            "meetingAgendaTask": premeetinglist,
            "meetingRequiredAttendance": attendeeslist,
            "meetingNotifyUser": notifyuserlist
        }
        if (categoryavail) {
            json_list.meetingCategoryID = meetingtypid
        }
        else {

            json_list.meetingCategoryName = meetingtypid
        }
        console.log(json_list)
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.toggle()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const formik = useFormik({
        initialValues:
        {
            agendaList: '',
            taskname: '',
            taskDescription: '',
            meetingUserID: 0,
        }
    })

    const handleTask = (e) => {
        let ind = parseInt(e.target.id)
        let taskstatus = []
        taskstatus[ind] = true
        settaskstatus(taskstatus)
    }

    const agendaHtml = () => {
        formik.values.agendaList = ''
        formik.values.discussionPoint = ''
        setAgendStatus(!agendaStatus)
        setIsagendaedit(false)
    }

    const removeTask = (e) => {
        let ind = parseInt(e.target.id)
        let taskstatus = []
        taskstatus[ind] = false
        settaskstatus(taskstatus)
    }

    const saveAgenda = () => {
        console.log(preemeetingstatus)
        if (preemeetingstatus == true) {
            let ke = premeetingkey
            let tasklist = {
                "task": formik.values.taskname,
                "is_PreMeetingPoints": preemeetingstatus,
                "is_Completed": false,
                "key": ke
            }
            ke++
            setPremeetingkey(ke)
            let preemeeting = [...premeetinglist]
            preemeeting.push(tasklist)
            formik.values.taskname = ''
            setpreemeetinglist(preemeeting)
            setAgendStatus(false)
        }
        else {
            let ke = key
            let agendList = [...agendaList]
            let agenda = {
                agendaname: '',
                meetingAgendaTask: [],
                key: ke
            }
            ke++
            setKey(ke)
            agenda.agendaname = formik.values.agendaList
            console.log(agenda)
            agendList.push(agenda)
            formik.values.agendaList = ''
            setAgendaList(agendList)
            console.log(agendaList)
            setAgendStatus(false)
        }
    }
    useEffect(() => {
        console.log(taskduedate)
    }, [taskduedate])
    const handlemeetingdate = (date) => {
        console.log(date)
        if (date == null) {
            setmeetingdate(false)
        }
        else {
            setmeetingdate(date)
        }
    }

    const handleTaskDueDate = (date) => {
        console.log(date)
        if (date == null) {
            settaskduedate(false)
        }
        else {
            settaskduedate(date)
            console.log(taskduedate)
        }
    }

    const handleMeeetingType = (e) => {
        let addedValue = e.target.value
        if(meetingtypelist != null){

            let selectedMeeting = meetingtypelist.filter(x => x.meetingCategoryName == addedValue)
            if (selectedMeeting.length > 0) {
                setcategoryavail(true)
                setmeetingid(selectedMeeting[0].meetingCategoryID)
            }
            else {
                setcategoryavail(false)
                setmeetingid(addedValue)
            }
        }
        else{
            setcategoryavail(false)
            setmeetingid(addedValue)
        }
    }

    const handleSelectMeeting = (event, values) => {
        setcategoryavail(true)
        setmeetingid(values.meetingCategoryID)
    }

    const handleSelectUser = (event, values) => {
        console.log(values.meetingUserID)
        sethostid(values.meetingUserID)
    }
    const handleHost = (e) => {
        let host = e.target.value
        console.log(host)
    }
    const addHost = () => {
        sethostmodal(!hostmodal)
    }
    const savetask = (e) => {
        let ind = e
        let agendalis = [...agendaList]
        let tasklist = {
            "task": formik.values.taskname,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": preemeetingstatus,
            "is_Completed": false
        }
        formik.values.taskDescription = ''
        formik.values.taskname = ''
        settaskduedate(false)
        settaskassignedto(0)
        agendalis[ind].meetingAgendaTask.push(tasklist)
        setAgendaList(agendalis)
        let tasksta = [...taskstatus]
        tasksta[ind] = false
        settaskstatus(tasksta)
    }

    const handleAssignedTo = (event, values) => {
        settaskassignedto(values.meetingUserID)
    }

    const handlerequiredattendees = (event, values) => {
        let atlist = [...attendeeslist]
        if (Array.isArray(values)) {
            console.log(atlist)
            values.map((val, k) => {
                atlist[k] = {
                    "meetingUserID": val.meetingUserID
                }
            })
            setattendeeslist(atlist)
        } else {
            setattendeeslist(values.meetingUserID)
        }
    }

    const handlenotifyusers = (event, values) => {
        let notify = [...notifyuserlist]
        if (Array.isArray(values)) {
            values.map((val, k) => {
                notify[k] = {
                    "meetingUserID": val.meetingUserID
                }
            })
            setnotifyuserlist(notify)
        } else {
            setnotifyuserlist(values.meetingUserID)
        }
    }

    const editTask = (k, l) => {
        let ind = k
        setindex(l)
        console.log(agendaList[k].meetingAgendaTask)
        formik.values.taskname = agendaList[k].meetingAgendaTask[l].task
        formik.values.taskDescription = agendaList[k].meetingAgendaTask[l].taskDescription
        settaskduedate(agendaList[k].meetingAgendaTask[l].taskDueDate)
        settaskassignedto(agendaList[k].meetingAgendaTask[l].meetingUserID)
        let tasksta = [...taskstatus]
        tasksta[ind] = true
        settaskstatus(tasksta)
        setistaskedit(true)
        console.log(userlist.filter(x => x.meetingUserID == taskassignedtouser)[0])
    }
    const updatetask = (k, index) => {
        let agendalis = [...agendaList]
        let tasklist = {
            "task": formik.values.taskname,
            "taskDescription": formik.values.taskDescription,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_PreMeetingPoints": false,
            "is_Completed": false
        }
        formik.values.taskDescription = ''
        formik.values.taskname = ''
        settaskduedate(false)
        settaskassignedto(0)
        agendalis[k].meetingAgendaTask[index] = tasklist
        setAgendaList(agendalis)
        let tasksta = [...taskstatus]
        tasksta[k] = false
        settaskstatus(tasksta)
        setistaskedit(false)
    }

    const saveDiscussionPoint = (index) => {
        console.log(index)
        let agendalis = [...agendaList]
        agendalis[index].discussionPoint = formik.values.discussionPoint
        setAgendaList(agendalis)
    }

    const editDiscussion = (k) => {
        let agendalis = [...agendaList]
        formik.values.discussionPoint = agendalis[k].discussionPoint
        formik.values.tempdiscussionPoint = agendalis[k].discussionPoint
        agendalis[k].discussionPoint = null
        setAgendaList(agendalis)
    }

    const removeAgenda = (k) => {
        let agendalis = [...agendaList]
        console.log(agendaList)
        let final = agendalis.filter(x => x.key != k)
        setAgendaList(final)
    }

    const editAgenda = (k) => {
        setAgendaindex(k)
        setIsagendaedit(true)
        let final = agendaList.filter(x => x.key == k)[0]
        formik.values.agendaList = final.agendaname
        setAgendStatus(!agendaStatus)
        setDoc(!doc)
    }

    const updateAgenda = () => {
        let agendalis = [...agendaList]
        let index = agendaList.findIndex(x => x.key == agendaindex)
        agendalis[index].agendaname = formik.values.agendaList
        setAgendaList(agendalis)
        setAgendStatus(!agendaStatus)
        setIsagendaedit(false)
    }

    const removePremeeting = (k) => {
        let final = premeetinglist.filter(x => x.key != k)
        setpreemeetinglist(final)
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
            <ModalHeader toggle={toggle} className='p-40'>
                <h2 className='meetingHeading'>Meetings</h2>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-32 scrollMeet mb-10'>
                <div className='comp-color p-1-rem br-4'>
                    {props.company != null &&
                        <div className='d-flex justify-content-between px-2'>
                            <div className='font-weight-400 font-13'>Company: <span className='font-16 font-weight-500 ps-2'> {props.company.company.companyName}</span></div>
                            <div className='font-13 font-weight-500 pointer' style={{ color: '#0d6efd' }} onClick={props.changeCompany}>Change</div>
                        </div>
                    }
                </div>
                {hostmodal &&
                    <AddExternalUser isOpen={hostmodal} getUsers={getUsers} toggle={addHost} />
                }
                <div className='mt-3'>
                    <div className='font-18 font-weight-500 color-707895 '>
                        <>
                            <Row className='border_bottom_input'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Meeting Title'
                                    name='meetingtitle'
                                    placeholder="Add Meeting Title"
                                    classname='meet-title'
                                    val={formik.values.meetingTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='border_bottom_input'>

                                    <Autocomplete
                                        {...listbox}
                                        id="free-solo-demo"
                                        className='typeMeet'
                                        freeSolo
                                        onChange={handleSelectMeeting}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Type of Meeting" variant="standard"
                                                onChange={handleMeeetingType} className='typeMeet' />
                                        )}
                                    />
                                </Col>
                                <FormFields.DatePickBottomBorder
                                    md={6}
                                    handleCompletionDate={handlemeetingdate}
                                    label='Date of Meeting'
                                    val={meetingdate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date meet-date'
                                />
                            </Row>
                            <Row className='mt-2-rem'>
                                <Col md={6} className='col-12'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            className='meet-time w-100'
                                            label="Meeting Start Time"
                                            value={meetingstarttime}
                                            orientation="landscape"
                                            onChange={(newValue) => {
                                                setmeetingstarttime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            className='meet-time w-100 mt-md-0 mt-4'
                                            label="Meeting End Time"
                                            orientation="landscape"
                                            value={meetingendtime}
                                            onChange={(newValue) => {
                                                setmeetingendtime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Col>
                            </Row>
                            <Row className='mt-2-rem '>

                                <Col md={3}>
                                    <div className='col-12'>
                                        <div className='meeting_type pb-2'>Type of Meeting</div>
                                        <ButtonGroup className='row'>
                                            <ToggleButton
                                                key={1}
                                                className='col-md-6'
                                                id='radio-1'
                                                type="radio"
                                                variant={'outline-primary'}
                                                name="Internal1"
                                                value={1}
                                                checked={meetingtype == 1 ? true : false}
                                                onChange={(e) => setmeetingtype(1)}
                                            >
                                                Internal
                                            </ToggleButton>
                                            <ToggleButton
                                                key={2}
                                                className='col-md-6'
                                                id='radio-2'
                                                type="radio"
                                                variant={'outline-primary'}
                                                name="External1"
                                                value={2}
                                                checked={meetingtype == 2 ? true : false}
                                                onChange={(e) => setmeetingtype(2)}
                                            >
                                                External
                                            </ToggleButton>
                                        </ButtonGroup>

                                    </div>
                                </Col>
                                <Col md={4} className='col-12 mt-md-0 mt-3'>
                                    <div className='col-12'>
                                        <div className='meeting_type pb-2'>Host of the Meeting</div>

                                        <ButtonGroup className='row'>
                                            <ToggleButton
                                                key={3}
                                                className='col-md-6'
                                                id='radio-3'
                                                type="radio"
                                                variant={'outline-primary'}
                                                name="Internal2"
                                                value={3}
                                                checked={hosttype == 1 ? true : false}
                                                onChange={(e) => sethosttype(1)}
                                            >
                                                Internal
                                            </ToggleButton>
                                            <ToggleButton
                                                key={4}
                                                className='col-md-6'
                                                id='radio-4'
                                                type="radio"
                                                variant={'outline-primary'}
                                                name="External2"
                                                value={4}
                                                checked={hosttype == 2 ? true : false}
                                                onChange={(e) => sethosttype(2)}
                                            >
                                                External
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </div>
                                </Col>

                                <Col md={5}>
                                    {/* <span className='meeting_type'>Host of Meeting</span> */}
                                    {hosttype == 1 ?
                                        <Col md={12} className='mt-1-rem'>
                                            {/* <Turnstone id="autocomplete" listbox={userlistbox} styles={styles} typeahead={false} onChange={selectmeetingtypelist}/> */}
                                            <Autocomplete
                                                multiple={false}
                                                id="tags-standard"
                                                options={hosttype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                getOptionLabel={(option) => option.userName}
                                                onChange={handleSelectUser}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                            <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                        </div>
                                                        <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Host of Meeting"
                                                        placeholder="Favorites"
                                                    />
                                                )}
                                            />

                                            {props.errors &&
                                                <span className='input_error'>{props.errors}</span>
                                            }
                                        </Col>
                                        :
                                        <Row className='border_bottom_input task_description'>
                                            <div className='task_div col-md-10 d-flex' style={{ padding: '14px 5px' }}>
                                                <Col md={12}>
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={hosttype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                        getOptionLabel={(option) => option.userName}
                                                        onChange={handleSelectUser}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                </div>
                                                                <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                <span>{option.companyName}</span>
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label="Host of Meeting"
                                                                placeholder="Favorites"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <img src='assets/images/blue_add.svg' className='pointer' onClick={addHost} />

                                            </div>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-2-rem'>
                                <Col md={6}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={meetingtype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist}
                                        getOptionLabel={(option) => option.userName}
                                        limitTags={2}
                                        onChange={handlerequiredattendees}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                    <p className='font-12 font-weight-500 mb-0 '>{option.userAvatar}</p>
                                                </div>
                                                <div className='ps-2 font-weight-500' style={{ fontSize: '12px' }}>{option.userName}
                                                    {option.companyName != null &&
                                                        <>
                                                            <span className='mx-3'>|</span><span className='font-weight-400'>{option.companyName}</span>
                                                        </>
                                                    }
                                                </div>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Add Required Attendees"
                                                placeholder="+ Add More"
                                            />
                                        )}
                                    />
                                    {meetingtype == 2 &&
                                        <img src='assets/images/blue_add.svg' className='pointer' onClick={addHost} />
                                    }
                                </Col>
                                <Col md={6} className='mt-md-0 mt-4'>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        className=''
                                        onChange={handlenotifyusers}
                                        limitTags={2}
                                        options={userlist.filter(x => x.is_Internal == true)}
                                        getOptionLabel={(option) => option.userName}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                    <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                </div>
                                                <div className='font-weight-500 ps-2' style={{ fontSize: '12px' }}>{option.userName}
                                                    {option.companyName != null &&
                                                        <>
                                                            <span className='mx-2'>|</span><span className='font-weight-400'>{option.companyName}</span>
                                                        </>
                                                    }
                                                </div>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Notify Members"
                                                placeholder="+ Add More"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2-rem'>

                                <Tabs>
                                    <div className='d-flex'>
                                        <Col md={6}>
                                            <TabList>
                                                <Tab onClick={() => {
                                                    setpreemeetingstatus(false)
                                                }}>
                                                    Agenda
                                                </Tab>
                                                <Tab onClick={() => {
                                                    setpreemeetingstatus(true)
                                                }}>
                                                    Pre Meeting Points
                                                </Tab>
                                            </TabList>
                                        </Col>
                                        <Col md={6} className='d-flex  justify-content-end'>
                                            <div className='no_border'>
                                                <img src='assets/images/blue_add.svg' className='pointer w-20px' onClick={agendaHtml} />
                                            </div>
                                        </Col>
                                    </div>
                                    <TabPanel>
                                        {agendaStatus &&
                                            <Row className='border_bottom_input task_description py-3'>
                                                <div className='task_div addAgenda col-md-10'>
                                                    <FormFields.BottomBorderInputField
                                                        md={12}
                                                        name='agendaList'
                                                        val={formik.values.agendaList}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        label='Type Agenda Title here...' className=''
                                                    />
                                                </div>
                                                <div className='task_div col-md-2'>
                                                    <span className='pl-10'>
                                                        <Button variant="primary" size="md" onClick={() => {
                                                            isagendaedit == true ?
                                                                updateAgenda()
                                                                :
                                                                saveAgenda()
                                                        }}>
                                                            Save
                                                        </Button>
                                                    </span>
                                                </div>
                                            </Row>
                                        }
                                        <Accordion className='my-4'>
                                            {agendaList.map((agenda, k) => {
                                                return (
                                                    <Accordion.Item className='meet-accord' eventKey={k}>
                                                        <Accordion.Header>
                                                            <span className='bgMeeting'><img src='assets/images/drag.svg' /></span>
                                                            <Col md={10} className='d-flex justify-content-between '>
                                                                <span className='meeting_agendaname'>
                                                                    {agenda.agendaname}
                                                                </span>
                                                                <span className='me-4'>
                                                                    <img src='assets/images/Edit_icon.svg' className='pointer meet-icon me-2' onClick={() => {
                                                                        editAgenda(agenda.key)
                                                                    }} />
                                                                    <img src='assets/images/trash.svg' className='pointer meet-icon' onClick={() => {
                                                                        removeAgenda(agenda.key)
                                                                    }} />
                                                                </span>
                                                            </Col>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {agenda.discussionPoint != null ?
                                                                <>
                                                                    <div className='bg-light p-1-rem font-13'>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                {agenda.discussionPoint}
                                                                            </Col>
                                                                            <Col md={1}>
                                                                                <img src='assets/images/Edit_icon.svg' className='pointer' onClick={() => {
                                                                                    editDiscussion(k)
                                                                                }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    {taskstatus[k] &&
                                                                        <>
                                                                            <div className='d-flex task1'>
                                                                                <Col md={5} className='d-flex task1 align-items-end'>
                                                                                    <div className='task_div'>
                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                    </div>

                                                                                    <div className='task_div '>
                                                                                        <Form.Check
                                                                                            name='taskCompletedStatus' disabled={true} />
                                                                                    </div>
                                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                        <FormFields.BottomBorderInputField
                                                                                            md={11}
                                                                                            label='Add Task'
                                                                                            name='taskname'
                                                                                            val={formik.values.taskname}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={7} className='d-flex task1 align-items-end justify-content-end'>
                                                                                    <div className='task_div d-flex meeting_task_due_date'>
                                                                                        <FormFields.DatePickBottomBorder
                                                                                            md={12}
                                                                                            handleCompletionDate={handleTaskDueDate}
                                                                                            label='Task Due Date'
                                                                                            val={taskduedate}
                                                                                            className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                                                        />
                                                                                    </div>
                                                                                    <div className='task_div col-md-6'>
                                                                                        <Autocomplete
                                                                                            id="tags-standard"
                                                                                            defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                                            options={meetingtype == 1 ? userlist.filter(x => x.is_Internal == true) : userlist.filter(x => x.is_Internal == false)}
                                                                                            getOptionLabel={(option) => option.userName}
                                                                                            onChange={handleAssignedTo}
                                                                                            renderOption={(props, option) => (
                                                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                                                    </div>
                                                                                                    <div className='d-block ms-2'>

                                                                                                        <span className='font-11 font-weight-500'>{option.userName} </span>

                                                                                                        <span className='font-12'>{option.companyName}</span>
                                                                                                    </div>
                                                                                                </Box>
                                                                                            )}
                                                                                            renderInput={(params) => (
                                                                                                <TextField
                                                                                                    {...params}
                                                                                                    variant="standard"
                                                                                                    label="Assigned To"
                                                                                                    placeholder="Assigned To"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </div>
                                                                            <Row className='border_bottom_input task_description'>
                                                                                <div className='task_div'>
                                                                                    <FormFields.BottomBorderInputField
                                                                                        md={12}
                                                                                        label='Task Description'
                                                                                        name='taskDescription'
                                                                                        val={formik.values.taskDescription}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                    />
                                                                                </div>
                                                                            </Row>
                                                                            <div className='d-flex justify-content-end'>
                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k} onClick={removeTask}>
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                                    onClick={() => {
                                                                                        isTaskEdit ?
                                                                                            updatetask(k, index)
                                                                                            :
                                                                                            savetask(k)
                                                                                    }}>
                                                                                    Save Task
                                                                                </Button>
                                                                            </div>
                                                                        </>}
                                                                    {agenda.meetingAgendaTask != null &&
                                                                        agenda.meetingAgendaTask.map((task, l) => {
                                                                            let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                                            console.log(users)
                                                                            return (
                                                                                <div className='d-flex task1'>
                                                                                    <div className='task_div'>
                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                    </div>
                                                                                    <Col md={12} className='d-flex justify-content-between'>
                                                                                        <div className='d-flex col-md-6'>
                                                                                            <div className='task_div '>
                                                                                                <Form.Check
                                                                                                    name='taskCompletedStatus' disabled={true} />
                                                                                            </div>
                                                                                            <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                {task.task}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-end col-md-6'>
                                                                                            <div className='task_div '>
                                                                                                {Moment(task.taskDueDate).format('DD/MM/YYYY')}
                                                                                            </div>
                                                                                            <div className='task_div '>
                                                                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                    <p className='font-12 font-weight-500 mb-0'>{users[0]!= null && users[0].userAvatar}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='task_div '>
                                                                                                <ButtonComponent.EditButton clickEvent={() => { editTask(k, l) }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Row>
                                                                        <Col md={12} className='d-flex task1 align-items-center'>
                                                                            <div className='task_div'>
                                                                                <img src='assets/images/meeting_task.svg' />
                                                                            </div>
                                                                            <div className='task_div '>
                                                                                <img src='assets/images/blue_add.svg' className='pointer' id={k} onClick={handleTask} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                :
                                                                <>
                                                                    <Row>
                                                                        <Col md={1}>
                                                                            <img src='assets/images/meeting_task.svg' />
                                                                        </Col>
                                                                        <Col md={11}>
                                                                            <FormFields.InputField
                                                                                name='discussionPoint'
                                                                                label='Discussion Points'
                                                                                md={12}
                                                                                val={formik.values.discussionPoint}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className='d-flex justify-content-end mt-1-rem'>
                                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={k}
                                                                            onClick={() => {
                                                                                let agendalis = [...agendaList]
                                                                                agendalis[k].discussionPoint = formik.values.tempdiscussionPoint
                                                                                setAgendaList(agendalis)
                                                                            }}>
                                                                            Cancel
                                                                        </Button>
                                                                        <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" id={k}
                                                                            onClick={() => {
                                                                                saveDiscussionPoint(k)
                                                                            }}>
                                                                            Save
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            }

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })}
                                        </Accordion>
                                    </TabPanel>
                                    <TabPanel>
                                        {agendaStatus &&
                                            <>
                                                <div className='d-flex task1 '>
                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='taskCompletedStatus' disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            <FormFields.BottomBorderInputField
                                                                md={12}
                                                                label='Premeeting Points'
                                                                name='taskname'
                                                                val={formik.values.taskname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" >
                                                        Cancel
                                                    </Button>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button" onClick={saveAgenda}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {premeetinglist.map((meeting) => {
                                            return (

                                                <div className='d-flex task1 justify-content-between align-items-center  mt-2 bg-light'>

                                                    <Col md={4} className='d-flex task1 align-items-center '>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='taskCompletedStatus' disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            {meeting.task}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} className=''>
                                                        <img src='assets/images/trash.svg' className='pointer' onClick={() => {
                                                            removePremeeting(meeting.key)
                                                        }} />
                                                    </Col>
                                                    <Col md={3}></Col>
                                                </div>
                                            )
                                        })}
                                    </TabPanel>
                                </Tabs>
                            </Row>
                        </>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Back' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
            </ModalFooter>
        </Modal>
    )
}
