import React, { useEffect, useState } from 'react'
import * as FormFields from "../../../components/formcomponents"
import { Row } from 'react-bootstrap'
import {AddCategory, AddGroup, AddUom} from "../../../helpers/API/ProductApi"
import { useFormik } from 'formik'

export default function Productsform(props) {
    const [isFirst, setIsFirst] = useState(true)
    const formik = useFormik({
        initialValues:
            props.isEdit?
            {
                productName : props.proval.productName,
                productCode : props.proval.productCode,
                hscode : props.proval.hscode,
                productCategoryID: props.proval.productCategoryID,
                productGroupID: props.proval.productGroupID,
                productUOMID: props.proval.productUOMID,
                description: props.proval.description
            }
            :
            {
                productName : '',
                productCategoryID: 0,
                hscode : '',
                productGroupID: 0,
                productUOMID: 1,
                description: ''
            }
        ,
        validate: values=>{
            console.log(values.productUOMID)
            let errors = {}
            if(!values.productName){
                errors.productName = "Product name is required"
            }
            if(!values.productCategoryID){
                errors.productCategoryID = "Product category is required"
            }
            if(!values.productGroupID){
                errors.productGroupID = "Product group is required"
            }
            if(values.productUOMID == 0){
                errors.productUOMID = "Product UOM is required"
            }
            return errors
        }
    })
    useEffect(()=>{
        if(props.isEdit){
            formik.values.productName = props.proval.productName
            formik.values.productCode = props.proval.productCode
            formik.values.hscode = props.proval.hscode
            formik.values.productCategoryID = props.proval.productCategoryID
            formik.values.productGroupID = props.proval.productGroupID
            formik.values.productUOMID = props.proval.productUOMID
            formik.values.description = props.proval.description
        }
        setIsFirst(false)
    },[isFirst])
    useEffect(()=>{
        if(!isFirst){
            if(props.isEdit){
                props.sendBack(formik.values)
            }
            else{
                if(Object.keys(formik.errors).length == 0){
                    props.sendBack(formik.values)
                }
            }
        }
    },[formik])
    return (
        <Row>
            <FormFields.InputField
                md={6}
                name='productName'
                label='Product Name*'
                classname='pt-1-rem'
                type='text'
                val={formik.values.productName}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
                errors= {formik.touched.productName?formik.errors.productName:''}
            />
            <FormFields.InputField
                md={6}
                name='hscode'
                label='HS Code'
                classname='pt-1-rem'
                type='text'
                val={formik.values.hscode}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
            />
            <FormFields.InputSelect
                md={6}
                name='productCategoryID'
                label='Product Category'
                classname='pt-1-rem'
                type='text'
                optionField={<AddCategory val={formik.values.productCategoryID}/>}
                val={formik.values.productCategoryID}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
                errors= {formik.touched.productCategoryID?formik.errors.productCategoryID:''}
            />
            <FormFields.InputSelect
                md={6}
                name='productGroupID'
                label='Product Group'
                classname='pt-1-rem'
                type='text'
                optionField={<AddGroup val={formik.values.productGroupID}/>}
                val={formik.values.productGroupID}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
                errors= {formik.touched.productGroupID?formik.errors.productGroupID:''}
            />
            <FormFields.InputSelect
                md={6}
                name='productUOMID'
                label='Product UOM'
                classname='pt-1-rem'
                type='text'
                optionField={<AddUom val={formik.values.productUOMID}/>}
                val={formik.values.productUOMID}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
                errors= {formik.touched.productUOMID?formik.errors.productUOMID:''}
            />
            <FormFields.inputFieldArea
                md={12}
                name='description'
                label='Remarks'
                classname='pt-1-rem'
                type='text'
                val={formik.values.description}
                onChange={formik.handleChange}
                onBlur ={formik.handleBlur}
            />
        </Row>
    )
}
