import React, { useEffect, useState } from 'react'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import styles from './Divisions.module.css'
import { AgChartsReact } from "ag-charts-react";
import { useMsal } from "@azure/msal-react";

import * as CONFIG from "../../../../helpers/config"
import * as agCharts from 'ag-charts-community';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { height } from '@mui/system';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {

    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

function Index(props) {
    console.log(props)
    const [value, setValue] = useState(0);
    const [valueSubTab, setValueSubTab] = useState(0);
    const [newTrades, setNewTrades] = useState()
    const [userDivision, setUserDivisions] = useState()
    const [selectedDivision, setSelectedDivision] = useState()
    const [divisionDetails, setDivisionDetails] = useState()
    const [subDivision, setSubDivision] = useState('Amount')
    const [dataForChart, setDataForCharts] = useState()
    const [max, setMax] = useState()
    const [maxProduct, setMaxProduct] = useState()
    const { instance } = useMsal();



    useEffect(() => {

    }, [props])

    useEffect(() => {

    }, [dataForChart, max, maxProduct])
    useEffect(() => {
        let divisionList = []
        let userDivisionList = []
        console.log(CONFIG.Divisions)
        if (CONFIG.Divisions.includes(",")) {
            let list = CONFIG.Divisions.split(",")
            list.map((values) => {
                divisionList.push(parseInt(values))
            })
        }
        else {
            let list = []
            list.push(parseInt(CONFIG.Divisions))
            list.map((values) => {
                divisionList.push(parseInt(values))
            })
        }
        console.log(divisionList)
        if (divisionList.length > 0) {
            let final_json = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "companyID" : parseInt(props.companyID)
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DIVISION_, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                for (let i = 0; i < res.data.result.length; i++){
                    for(let x = 0; x < divisionList.length; x++){
                        if(divisionList[x] == res.data.result[i].divisionID){
                            userDivisionList.push(res.data.result[i]) 
                        }
                    }
                }
                if(userDivisionList.length > 0){
                    console.log(userDivisionList[0])
                    let final_json = {
                        "instanceID": CONFIG.PMSInstanceID,
                        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                        "fromDate": props.fromDate,
                        "toDate": props.toDate,
                        "divisionID": parseInt(userDivisionList[0].divisionID),
                        "userID":CONFIG.LoginID,
                        "dashboardTypeID": parseInt(props.dashboardTypeID),
                        "termsID" : parseInt(props.terms),
                        "companyID" : parseInt(props.companyID)
                  }
                  console.log(final_json, ' checking json')
                  axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_DIV_TOTAL, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                  .then((res) => {
                      console.log(res.data.result)
                      setDivisionDetails(res.data.result)
                      let object = {}
                      let list = []
                      for(let i = 0; i < res.data.result.length; i++){
                        object = {
                            'os': res.data.result[i].productName,
                            'share': res.data.result[i].buyingAmount,
                            'satisfaction': res.data.result[i].buyingAmount
                        }
                        list.push(object)
                      }
                      console.log(list,'list')
                      if(list != null && list.length > 0){
                        let listMax = []
                        for (let i = 0; i < list.length; i++){
                          listMax.push(parseInt(list[i].share))
                        }
                        console.log(listMax)
                        var max = Math.max.apply(null, listMax)
                        console.log(max)
                        setMax(max)
                        for (let i = 0; i < list.length; i++){
                            if(list[i].share == max){
                                setMaxProduct(list[i].os)
                                console.log(list[i].os, 'max Product')
                            }
                        }
            
                      }
                      setDataForCharts(list)
          
                      console.log(Math.max(list))
                      console.log(list, 'checking object')
                      //setNotifications(res.data.result)
                  })
                }
                console.log(userDivisionList, 'Checking')
                setUserDivisions(userDivisionList)
                
               
                //setWorkFlowList(res.data.result)
            }).catch((error)=>{
                // if(error.response.status == 401){
                //     localStorage.clear();
                //     instance.logoutRedirect({
                //       postLogoutRedirectUri: "/",
                //     });
                // }
            })  
        }
      },[props])
    const handleInnerText = () => {
        let data = maxProduct
        if (maxProduct != null) {
            if (maxProduct.length > 10){
                data = maxProduct.slice(0,9) + '...'
            }
            else{
                data = maxProduct
            }
        }
        return (
            data
        )
    }
    const handleInnerTextMaxValue = () => {
        let data = max
        if (max != null) {
            data = '$' + max.toString()
            console.log(max, 'max value')
        }
        return (
            data
        )
    }



    const data = ({
        data: dataForChart != null && dataForChart.length > 0 ? dataForChart : [],
        autoSize: false,
        padding: {
            top: 40,
            bottom: 40,
        },
        series: [
            {
                type: "pie",
                angleKey: "share",

                radiusKey: "satisfaction",
                innerRadiusOffset: -42,
                radiusName: "os",
                radiusMin: 5,
                fills: ['#3AA345', '#DDB834', '#42AEEB', '#41a9c9', '#4258c9', '#9a42c8', '#c84164', '#888888'],
                strokes: [],
                innerCircle: {
                    fill: '#2A2658',

                },
                innerLabels: [
                    {
                        text: handleInnerText(),
                        color: '#fff',
                        fontSize: 14
                    },
                    {
                        text: handleInnerTextMaxValue(),
                        color: '#fff',
                        fontSize: 14
                    }
                ]


            }
        ]

    }
    )

    const colors =
        [
            '#3AA345',
            '#DDB834',
            '#42AEEB',
            '#41a9c9',
            '#4258c9',
            '#9a42c8',
            '#c84164',
            '#888888'
        ]
    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };
    const handleChangeSubTab = (event, newValue) => {
        console.log(newValue)
        setValueSubTab(newValue);
    };

    const handleClick = (e, data) => {
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "fromDate": props.fromDate,
            "toDate": props.toDate,
            "divisionID": parseInt(data),
            "userID":CONFIG.LoginID,
            "dashboardTypeID": parseInt(props.dashboardTypeID),
            "termsID" : parseInt(props.terms),
            "companyID" : parseInt(props.companyID)
      }
      console.log(final_json)
      axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_DIV_TOTAL, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setDivisionDetails(res.data.result)
          let object = {}
          let list = []
          for(let i = 0; i < res.data.result.length; i++){
            object = {
                'os': res.data.result[i].productName,
                'share': res.data.result[i].buyingAmount,
                'satisfaction': res.data.result[i].buyingAmount
            }
            list.push(object)
            
          }
          console.log(list, 'list')
          
          if(list != null && list.length > 0){
            let listMax = []
            for (let i = 0; i < list.length; i++){
              listMax.push(parseInt(list[i].share))
            }
            console.log(listMax)
            var max = Math.max.apply(null, listMax)
            console.log(max)
            setMax(max)
            for (let i = 0; i < list.length; i++){
                if(list[i].share == max){
                    if (typeof list[i].os === 'string' || list[i].os instanceof String){
                        console.log('string check')
                }
                    setMaxProduct(list[i].os)
                    console.log(list[i].os)
                }
            }

            }
            setDataForCharts(list)


                //setNotifications(res.data.result)
            })
        console.log(data)
    }

    const handleSubDivision = (data) => {
        setSubDivision(data)
    }

    useState(subDivision)
    const handlePercentageRevenueAmount = (values) => {
    let difference = 0
    let product = 0
    console.log(props)
    if(difference == 0 && values.prevBuyingAmount == 0){
          console.log('cannot calc')
          product = 0
    }
    else{
      difference =  parseInt(values.buyingAmount) - parseInt(values.prevBuyingAmount) 
      console.log(difference, 'calculated')
      product =  parseInt(difference) / parseInt(values.prevBuyingAmount)
    }
    console.log(difference)
    console.log(product, 'product before percentage')
    console.log(parseFloat(product * 100).toFixed(2))
    product = parseFloat(product * 100).toFixed(2)
    console.log(product)
        return(
          <>
           
            {(parseInt(values.prevBuyingAmount) < (parseInt(values.buyingAmount))) == true ? 
             <div className = {`d-flex align-items-center justify-content-around ${styles.profitContainer}`}>
                <div>
                    {product}%
                </div>
                <div>
                    <img src = '/assets/images/supports/dashboard-trends-profit-icon-1.svg' alt = 'icon' className = 'ms-1'/>
                </div>
             </div>
             :
             <div className = {` d-flex align-items-center ${styles.lossContainer}`}>
                 {product}%
              <img src = '/assets/images/supports/dashboard-trends-loss-icon-1.svg' alt = 'icon' />
             </div>
          }
            
          </>
        )
        //console.log(division)
    }

    useEffect(() => {
        console.log(userDivision, 'userDivision')
    }, [userDivision])
    return (
        <>
            <div className={` ${styles.divisionsContainer}`} style = {{height:props.dashboardTypeID == 2 ? '708px' : '648px'}}>
                <h4 className='mt-5 mb-4 text-white gil-medium'>
                    DIVISIONS
                </h4>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, color: '#fff' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="visible arrows tabs example"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                        >
                            {userDivision != null ?
                                userDivision.map((values, k) => (
                                    <Tab
                                        className='gil-medium'
                                        label={values.divisionName}
                                        {...a11yProps(values.divisionID)}
                                        onClick={(e) => { handleClick(e, values.divisionID) }} />
                                ))
                                :
                                ''
                            }
                        </Tabs>
                    </Box>
                    {/* <TabPanel>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[0], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Coal
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 2,303.72
                            </div>
                            <div className = {`d-flex align-items-center  ${styles.profitContainer}`} >
                                <div className = 'justify-self-end'>
                                    10.3%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-profit-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[1], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Aluminium
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 1,242.82
                            </div>
                            <div className = {`d-flex align-items-center ${styles.lossContainer}`}>
                                <div>
                                    11.5%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-loss-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[2], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Iron Ore
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 398.53
                            </div>
                            <div className = {`d-flex align-items-center ${styles.lossContainer}`} >
                                <div>
                                    8.9%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-loss-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </TabPanel> */}

                    {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
                </Box>

                <div>
                    <AgChartsReact options={data} />
                </div>
                <div className=''>
                    <TabPanel>
                        <div className='tab_data'>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, color: '#fff', borderBottom: 'divider' }}>
                                    <Tabs
                                        value={valueSubTab}
                                        onChange={handleChangeSubTab}
                                        variant="scrollable"
                                        scrollButtons
                                        aria-label="visible arrows tabs example"
                                        sx={{
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                '&.Mui-disabled': { opacity: 0.3 },
                                            },
                                        }}
                                    >

                                        <Tab
                                            className='gil-semibold'
                                            label={'Amount'}
                                            {...a11yProps(0)}
                                            onClick={(e) => { handleSubDivision('Amount') }}
                                        />
                                        <Tab
                                            className='gil-semibold'
                                            label={'Volume'}
                                            {...a11yProps(1)}
                                            onClick={(e) => { handleSubDivision('Volume') }}
                                        />
                                    </Tabs>
                                </Box>
                            </Box>
                            <TabPanel>
                                <div style={{ overflowY: 'scroll', height: '172px', }}>
                                    {divisionDetails != null ?
                                        divisionDetails.map((values, k) => (
                                            values.productName != null ?
                                            <div className='d-flex my-3 ' >
                                            <div style={{ width: '14px', height: '14px', backgroundColor: colors[k], borderRadius: '100%', marginTop: '2px' }} className='mx-2' />
                                            <div className={`d-flex flex-column justify-content-between col-md-10 ${styles.tabsListText}`}>
                                                <div className='gil-medium d-flex justify-content-start'>
                                                    {values.productName}
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center gil-regular'>
                                                    {subDivision == 'Amount' ?
                                                        <>
                                                            <div className='d-flex justify-content-between align-items-center gil-regular col-md-12'>
                                                                <NumberFormat className="" value={values.buyingAmount} displayType={'text'} thousandSeparator={true} prefix='$' />
                                                                <div className={``} >
                                                                {handlePercentageRevenueAmount(values)}
                                                            </div>
                                                            </div>
                                                           
                                                        </>
                                                        :
                                                        <div className='gil-medium'>
                                                            {values.volume}
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                            </div>
                                            :
                                            ''
                                            
                        
                                        ))
                                        :
                                        ''}
                                </div>
                            </TabPanel>
                        </div>
                    </TabPanel>
                </div>
                {/* <div className = 'mt-2'>
        <Tabs>
            <div className = {`custom-tab`}>
            <TabList>
                    <Tab>Value</Tab>
                    <Tab>Volume</Tab>
            </TabList>
            
            </div>
            <TabPanel>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[0], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Coal
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 2,303.72
                            </div>
                            <div className = {`d-flex align-items-center  ${styles.profitContainer}`} >
                                <div className = 'justify-self-end'>
                                    10.3%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-profit-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[1], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Aluminium
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 1,242.82
                            </div>
                            <div className = {`d-flex align-items-center ${styles.lossContainer}`}>
                                <div>
                                    11.5%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-loss-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[2], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Iron Ore
                        </div>
                        <div className = 'd-flex justify-content-around align-items-center'>
                            <div>
                                $ 398.53
                            </div>
                            <div className = {`d-flex align-items-center ${styles.lossContainer}`} >
                                <div>
                                    8.9%
                                </div>
                                <div className ='ms-2 mb-1'>
                                    <img src = '/assets/images/supports/divisions-loss-icon.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[0], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Coal
                        </div>
                        <div>
                            20 Matric Ton
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[1], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Aluminium
                        </div>
                        <div>
                            80 Matric Ton
                        </div>
                    </div>
                </div>
                <div className = 'd-flex mt-4'>
                    <div style={{width:'14px', height: '14px', backgroundColor:colors[2], borderRadius:'100%', marginTop:'2px'}} className= 'mx-2'/>  
                    <div className = {`d-flex flex-column align-items-start ${styles.tabsListText}`}>
                        <div>
                            Iron Ore
                        </div>
                        <div>
                            10 Matric Tone
                        </div>
                    </div>
                </div>
            </TabPanel>          
        </Tabs>
       </div> */}
            </div>

        </>

    )
}

export default Index