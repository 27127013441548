import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/sidebar'
import Navbar from "../../components/navbar"
import { Link, useLocation } from 'react-router-dom'
import * as CONFIG from "../../helpers/config"
import axios from 'axios'
import { useFormik } from 'formik'
import Moment from "moment"
import NumberFormat from 'react-number-format';
import * as FormFields from "../../components/formcomponents"
import { BlueBorderButton, BlueButton } from '../../components/buttoncomponents'
import { Row, Col } from 'react-bootstrap'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import Swal from 'sweetalert2';
import { Modal, ModalBody } from "reactstrap"
import withReactContent from 'sweetalert2-react-content';
import { useMsal } from "@azure/msal-react";

export default function Costsheet(props) {
    const { instance } = useMsal();
    const location = useLocation()
    const tradeDataForCostSheet = props.trade
    const [tradeid, settradeid] = useState(); // replace 1 by props.id
    const [tradedata, settradedata] = useState([]); // replace 1 by props.id
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [costsheetdata, setcostsheetdata] = useState([])
    const [iscostsheetavailable, setisavailable] = useState(false)
    const [total, setTotal] = useState(0)
    const [buyingprice, setbuyingprice] = useState(0); // replace 1 by props.id
    const [sellingPrice, setSellingPrice] = useState(0); // replace 1 by props.id
    const [margin, setmargin] = useState(0)
    const [marginper, setmarginper] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [isprint, setIsprint] = useState(false)
    const [isApprovalSend, setIsApproval] = useState(false)
    const [approvalStatus, setApprovalStatus] = useState('')
    const [modal, setmodal] = useState(false)
    const MySwal = withReactContent(Swal)
    const [senddisabled, setsenddisabled] = useState(false)

    useEffect(() => {
        //props.id = 1; // get id from props (tradeid)
        console.log(tradeDataForCostSheet.tradeID, 'trading id');
        settradeid(tradeDataForCostSheet.tradeID)
        getTradeData(tradeDataForCostSheet.tradeID);
    }, [])

    useEffect(() => {
        if (tradedata.length > 0) {
            if (tradedata[0].tradedetails.costSheetApprovalStatus >= 0) {
                getcostsheetdata(tradeDataForCostSheet.tradeID)
            }
        }
    }, [tradedata])


    useEffect(() => {
        console.log(isDataAvailable)
    }, [isDataAvailable])
    useEffect(() => {
        console.log(sellingPrice)
        if (isEdit) {
            getcostsheetdata(tradeDataForCostSheet.tradeID)
        }
    }, [sellingPrice])



    const formik = useFormik({
        initialValues:
        {
            BuyingPrice: "0",
            OceanFreight: "0",
            FinancePrice: "0",
            InsuranceCostCargo: "0",
            InsuranceCostCL: "0",
            InsuranceCostOthers: "0",
            total: "0",
            margin: "0",
            marginper: "0"

        },
        validate: values => {
            let errors = {};

            const val = /^[0-9]+$/;

            //sendBack();
            return errors;
        }
    })



    const getTradeData = (id) => {
        console.log('inside', tradeDataForCostSheet.tradeID)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, "Trade data")
                let total1 = 0, total2 = 0
                res.data.result.map((list, k) => {
                    list.tradedetails.tradeProductDetail.map((pro, p) => {
                        total1 = total1 + (pro.productBuyingPrice * pro.quantity)
                        total2 = total2 + (pro.productSellingPrice * pro.quantity)
                    })
                })
                formik.values.BuyingPrice = total1;
                console.log(total2)
                setbuyingprice(total1);
                setSellingPrice(total2);
                if (res.data.result[0].tradedetails.costSheetWorkFlowID != null) {
                    setApprovalStatus(res.data.result[0].tradedetails.costSheetApprovalStatus)
                    setIsApproval(true)
                }
                settradedata(res.data.result);
                setIsDataAvailable(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const getcostsheetdata = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id), // tradeid,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setcostsheetdata(res.data.result[0])
                console.log(res.data.result[0])
                if (Object.keys(res.data.result[0]).length > 0) {
                    formik.values.FinancePrice = res.data.result[0].financeCost
                    formik.values.InsuranceCostCargo = res.data.result[0].insuranceCargoCost
                    formik.values.InsuranceCostCL = res.data.result[0].insuranceCLCost
                    formik.values.InsuranceCostOthers = res.data.result[0].insuranceOthersCost
                    let total = 0
                    if (tradedata.length > 0) {
                        let freight = tradedata[0].tradedetails.oceanFreight != null ? tradedata[0].tradedetails.oceanFreight : 0
                        total = parseFloat(formik.values.BuyingPrice) + parseFloat(freight) + parseFloat(formik.values.FinancePrice) + parseFloat(formik.values.InsuranceCostCargo) + parseFloat(formik.values.InsuranceCostCL) + parseFloat(formik.values.InsuranceCostOthers) + parseFloat(tradedata[0].tradedetails.dispatch) + parseFloat(tradedata[0].tradedetails.demurrage);
                    }
                    console.log(sellingPrice, 'Selling pRCES')
                    if (sellingPrice > 0) {
                        var margin = sellingPrice - total
                        var marginper = (margin / sellingPrice) * 100;
                    }
                    else {
                        margin = total
                        marginper = 100
                    }
                    formik.values.total = total
                    formik.values.margin = margin
                    formik.values.marginper = marginper
                    console.log(formik.values.total)
                    setTotal(total)
                    setmargin(margin);
                    setmarginper(marginper);
                    setisavailable(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    useEffect(() => {
        let total = 0
        if (!iscostsheetavailable && isDataAvailable) {
            console.log('inside use effect')
            let freight = tradedata[0].tradedetails.oceanFreight != null ? tradedata[0].tradedetails.oceanFreight : 0
            total = parseFloat(formik.values.BuyingPrice) + parseFloat(freight) + parseFloat(formik.values.FinancePrice) + parseFloat(formik.values.InsuranceCostCargo) + parseFloat(formik.values.InsuranceCostCL) + parseFloat(formik.values.InsuranceCostOthers) + parseFloat(tradedata[0].tradedetails.dispatch) + parseFloat(tradedata[0].tradedetails.demurrage);
            console.log(formik.values.InsuranceCostCargo)
            if (sellingPrice > 0) {

                var margin = sellingPrice - total
                var marginper = (margin / sellingPrice) * 100;
            }
            else {
                margin = total
                marginper = 100
            }
            formik.values.total = total
            formik.values.margin = margin
            formik.values.marginper = marginper
            setTotal(total)
            setmargin(margin);
            setmarginper(marginper);
            console.log(formik.values.total)
            console.log(formik.values.margin)
            console.log(formik.values.marginper)
        }
    }, [formik.values])

    const savecostsheet = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "tradeid": parseInt(tradeid), // tradeid,
            "addressCommission": 0,
            "financeCost": parseInt(formik.values.FinancePrice),
            "insuranceCargoCost": parseInt(formik.values.InsuranceCostCargo),
            "insuranceCLCost": parseInt(formik.values.InsuranceCostCL),
            "insuranceOthersCost": parseInt(formik.values.InsuranceCostOthers),
        }
        if (isEdit) {
            json_list.tradeEstimatedCostSheetID = costsheetdata.tradeEstimatedCostSheetID
            json_list.modified_User = CONFIG.LoginID
            console.log(json_list)
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.UPDATE_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result, "Trade data")
                    if (res.data.responseCode == '304000') {
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Estimated Costsheet updated Successfully!',
                                //html: '<p>Trade Number: ' + formik.values.tradeNo + '</p>' + '<br />',
                                type: 'success',
                                confirmButtonText: 'Okay',
                                confirmButtonClass: "btn btn-primary",
                            }).then(() => {
                                //window.location.href = "/trade-transactions"
                            });
                    }
                    getcostsheetdata(tradeDataForCostSheet.tradeID)
                    setIsEdit(false)
                    props.refreshList()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_TRADE_COSTSHEET_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.responseCode)
                    if (res.data.responseCode == '304000') {
                        MySwal.fire(
                            {
                                icon: 'success',
                                title: 'Estimated Costsheet generated Successfully!',
                                //html: '<p>Trade Number: ' + formik.values.tradeNo + '</p>' + '<br />',
                                type: 'success',
                                confirmButtonText: 'Okay',
                                confirmButtonClass: "btn btn-primary",
                            }).then(() => {
                                //window.location.href = "/trade-transactions"
                            });
                    }

                    getcostsheetdata(tradeDataForCostSheet.tradeID)
                    props.refreshList()
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        props.refreshList()
    }

    const openEdit = () => {
        setisavailable(false)
        setIsEdit(true)
    }

    const toggle = () => {
        setmodal(!modal)
    }
    const printDocument = () => {
        setIsprint(true)
        setTimeout(() => {
            const domElement = document.getElementById("download_section");
            html2canvas(domElement)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    heightLeft -= pageHeight;
                    const doc = new jsPDF('p', 'mm');
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    doc.save('CostSheet.pdf');
                    setIsprint(false)
                })
        }, 300);
    }

    const sendForApproval = () => {
        setsenddisabled(true)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": tradeDataForCostSheet.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ESTIMATED_COSTSHEET_SEND_FOR_APPROVAL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                getTradeData(tradeDataForCostSheet.tradeID)
                props.refreshList()
                senddisabled(false)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleStatus = () => {
        if (approvalStatus == 1) {
            // return <span className='approval_in_process'>Cost Sheet Approval - in Process</span>
            return <span className='approval_in_process d-flex justify-content-center my-2'>Estimated Cost Sheet - Pending Approval</span>
        }
        if (approvalStatus == 2) {
            return <span className='approved d-flex justify-content-center my-2'>Cost Sheet Approved</span>
        }
        if (approvalStatus == 9) {
            return <span className='rejected d-flex justify-content-center my-2'>Cost Sheet Rejected</span>
        }
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex' style={{ opacity: props.locked ? '0.1' : '' }}>

            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_contract" style={{ height: 'auto' }}>
                <ModalBody toggle={toggle}>
                    <div className='service-request-detail-title mx-4 mt-4'>
                        <div className='d-flex justify-content-end'>
                            <div>
                                <BlueBorderButton value='DOWNLOAD DOCUMENT' clickEvent={printDocument} className='ml-1-rem' />
                            </div>
                        </div>
                    </div>
                    <div className='table-responsive-sm card-body mx-3' id="download_section">

                        <div className=' br-8 py-3 d-flex justify-content-between align-items-center'>

                            <div>
                                <img src="/assets/images/logo.svg" className='img-fluid' />
                            </div>

                            <div className='d-flex flex-column'>

                                <div className='font-16 tradeTrsactionsHeading d-flex  mt-5'><b>ESTIMATED COST SHEET</b></div>

                                <div className='mt-1'>
                                    Issue Date: <span className='tradeTrsactionsHeading'> {Moment(new Date()).format("DD/MM/yyyy")}  </span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 border-bottom-1 pb-5'>
                            <Col md={9} className='d-flex justify-content-between'>
                                <div >
                                    <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}>TRADE DETAILS</p>
                                    <p className='mt-3 p-0 mb-0 tradeTrsactionsHeading'><b>{props.trade.tradeNo}</b></p>
                                    <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500 d-flex align-items-center'>{props.trade.companyname}<span className='mx-1 dotSep '>&#8226;</span>{props.trade.divisionname}</h4>
                                </div>

                                <div className=''>
                                    <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}>CUSTOMER & SUPPLIER</p>
                                    {props.trade.compcustdetails.map((pt, k) => {
                                        return (
                                            <>
                                                <div className='pb-2'>
                                                    <div className='align-items-center '>
                                                        <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                        <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                    </div>
                                                    <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                    </h4></div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div className=''>
                                        <div className='d-flex align-items-center '>
                                            <h5 className='tradeTrsactionsHeading me-2 font-13 font-weight-600'>{props.trade.supplierName}</h5>
                                            <h4 className='tradeTrsactionsid statBg'>{props.trade.supplierCode}</h4>
                                        </div>
                                        <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                            {props.trade.supplierpaymentmethods.map((pt, k) => {
                                                return (
                                                    <>
                                                        {pt} <span className='mx-1 dotSep'>&#8226;</span>
                                                    </>
                                                )
                                            })}
                                        </h4></div>
                                    </div>
                                </div>



                                <div className=''>
                                    <p className='m-0 font-weight-600' style={{ fontSize: '16px' }}>PRODUCT & CONTRACT DETIALS</p>
                                    <div className=' mt-3'>
                                        {props.trade.tradeProductDetails.map((list, k) => {
                                            return (
                                                <>
                                                    <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                                        {" "}
                                                        {list.productName}
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                                            {list.quantity}&nbsp;{" "}
                                                            {list.productUOMName} <span className='mse-3 dotSep'>&#8226;</span>
                                                            {props.trade.contracttype} <span className='mse-3 dotSep'>&#8226;</span>
                                                            {props.trade.incotermsName} <span className='mse-3 dotSep'>&#8226;</span>
                                                            {props.trade.modeOfTransportName}
                                                        </div>

                                                    </div>


                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className='d-flex py-2'>
                                        <div>
                                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                Load Port
                                            </div>
                                            <div className='tradeTrsactionsHeading '>
                                                {props.trade.loadPort}, {props.trade.lordPortCountry}
                                            </div>
                                        </div>
                                        <div className='ml-20'>
                                            <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                Destination Port
                                            </div>
                                            <div className='tradeTrsactionsHeading'>
                                                {props.trade.loadPort}, {props.trade.lordPortCountry}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </div>



                        <table className='table tCustom table-striped table-text mt-1-rem  mt-5
                         ' >
                            <thead>
                                <tr>
                                    <th> <div className='font-weight-600 ps-5'>SL No </div></th>
                                    <th><div className='font-weight-600'>Description</div></th>
                                    <th><div className='font-weight-600'>Estimated Amount</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>1</div></td>
                                    <td className='col-md-6'>Buying Price (FOB)</td>
                                    <td className='col-md-4'>
                                        <NumberFormat value={formik.values.BuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>2</div></td>
                                    <td className='col-md-6'>Ocean Freight</td>
                                    <td className='col-md-4'>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.oceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>3</div></td>
                                    <td className='col-md-6'>Finance Cost</td>
                                    <td className='col-md-4'>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.FinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}

                                                name='FinancePrice'
                                                type='number'
                                                val={formik.values.FinancePrice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>4</div></td>
                                    <td className='col-md-6'>Insurance Cost(Cargo)</td>
                                    <td className='col-md-4'>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostCargo'
                                                type='number'
                                                val={formik.values.InsuranceCostCargo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>5</div></td>
                                    <td className='col-md-6'>Insurance Cost(CL)</td>
                                    <td className='col-md-4'>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostCL'
                                                type='number'
                                                val={formik.values.InsuranceCostCL}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>6</div></td>
                                    <td className='col-md-6'>Insurance Cost(Others)</td>
                                    <td className='col-md-4'>
                                        {iscostsheetavailable ?
                                            <NumberFormat value={formik.values.InsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            :
                                            <FormFields.InputField
                                                md={4}
                                                name='InsuranceCostOthers'
                                                type='number'
                                                val={formik.values.InsuranceCostOthers}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className='ps-5 col-md-2'>7</div></td>
                                    <td className='col-md-6'>Despatch</td>
                                    <td className='col-md-4'>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.dispatch} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                </tr>
                                <tr className=' d-felx'>
                                    <td><div className='ps-5 col-md-2'>8</div></td>
                                    <td className='col-md-6'>Demmurage</td>
                                    <td className='col-md-4'>
                                        {isDataAvailable &&
                                            <NumberFormat value={tradedata[0].tradedetails.demurrage} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                        }
                                    </td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td className='text-right'>
                                        <p>Estimated Total Amount :</p>
                                        <p>Selling Price :</p>
                                        <p>Margin :</p>
                                        <p>Margin% (Margin/Selling Price*100) :</p>
                                    </td>
                                    <td>
                                        <p><strong><NumberFormat value={formik.values.total} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong></p>
                                        <p><strong> <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong> </p>
                                        <p><strong><NumberFormat value={formik.values.margin} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong></p>
                                        <p><strong>{parseFloat(formik.values.marginper).toFixed(2)}%</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <div className='w-100'>
                <div className='d-flex inner-page-header justify-content-between mt-0'>
                    <div className='d-flex align-items-center'><img src='assets/images/pageicons/Dashboard.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>ESTIMATED COST SHEET</span></div>
                    <div className='d-flex '>
                        <BlueBorderButton class='ms-2' value='EXPORT COST SHEET' clickEvent={toggle} />
                        {iscostsheetavailable &&
                            <>
                                {(!isApprovalSend || approvalStatus == 9) &&
                                    <BlueButton class="ms-2" value="Send For Approval" clickEvent={sendForApproval} disabled={senddisabled} />
                                }
                            </>
                        }
                    </div>
                </div>
                <div className=''>
                    <div className='add-edit-wrapper  br-8 py-3'>
                        <div className='card card-body p-4 br-8 mb-4  d-none d-md-block d-lg-block'>
                            <table className='w-100' >
                                <tr style={{ borderBottom: 'none' }}>
                                    <td >
                                        <div className='pt-2'>
                                            <h4 className='tradeTrsactionsHeading'>{props.trade.tradeNo}</h4>
                                            <h4 className='tradeTrsactionsSubHeading font-11 font-weight-500'>{Moment(props.trade.created_date).format('DD/MM/yyyy')}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='pt-2'>
                                            <h4 className='tradeTrsactionsHeading'>{props.trade.companyname}<span className='mx-1 dotSep'>&#8226;</span>{props.trade.divisionname}</h4>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='d-flex flex-column pt-2'>
                                            {props.trade.compcustdetails.map((pt, k) => {
                                                return (
                                                    <>
                                                        <div className='pb-2'>
                                                            <div className='align-items-center '>
                                                                <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{pt.customername}</h5>
                                                                <span className='tradeTrsactionsid statBg'>{pt.customercode}</span>
                                                            </div>
                                                            <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                            </h4></div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <div className='py-2'>
                                                <div className='d-flex align-items-center '>
                                                    <h5 className='tradeTrsactionsSubHeading me-2 font-13 font-weight-500'>{props.trade.supplierName}</h5>
                                                    <h4 className='tradeTrsactionsid statBg'>{props.trade.supplierCode}</h4>
                                                </div>
                                                <div><h4 className='tradeTrsactionsSubHeading font-12 font-weight-400'>
                                                    {props.trade.supplierpaymentmethods.map((pt, k) => {
                                                        return (
                                                            <>
                                                                {pt} <span className='mx-1 dotSep'>&#8226;</span>
                                                            </>
                                                        )
                                                    })}
                                                </h4></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className=' pt-2'>
                                            {props.trade.tradeProductDetails.map((list, k) => {
                                                return (
                                                    <>
                                                        <div className="table-ttile-color font-13 font-weight-600" key={k}>
                                                            {" "}
                                                            {list.productName}
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className="table-ttile-color-sub font-12 font-weight-500 d-flex align-items-center">
                                                                {list.quantity}&nbsp;{" "}
                                                                {list.productUOMName} <span className='mse-3 dotSep'>&#8226;</span>
                                                                {props.trade.contracttype} <span className='mse-3 dotSep'>&#8226;</span>
                                                                {props.trade.incotermsName} <span className='mse-3 dotSep'>&#8226;</span>
                                                                {props.trade.modeOfTransportName}
                                                            </div>

                                                        </div>


                                                    </>
                                                );
                                            })}
                                        </div>
                                        <div className='d-flex py-2'>
                                            <div>
                                                <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                    Load Port
                                                </div>
                                                <div className='tradeTrsactionsHeading '>
                                                    {props.trade.loadPort}, {props.trade.lordPortCountry}
                                                </div>
                                            </div>
                                            <div className='ml-20'>
                                                <div className='tradeTrsactionsSubHeading font-weight-400 font-12'>
                                                    Destination Port
                                                </div>
                                                <div className='tradeTrsactionsHeading'>
                                                    {props.trade.loadPort}, {props.trade.lordPortCountry}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div id="" className='card'>
                            <div className='d-flex justify-content-between align-items-center mt-4 mx-3'>
                                <span className='font-weight-500 font-14 color-707895'>Pre-shipment Estimated Cost</span>
                                <div>
                                    {
                                        isApprovalSend &&
                                        <>
                                            {
                                                handleStatus()
                                            }
                                        </>
                                    }
                                    {(!isApprovalSend || approvalStatus == 9) && <span><img src="assets/images/editButton.svg" className='img-fluid pointer' onClick={openEdit} /></span>}
                                </div>
                            </div>

                            <div className='table-responsive-sm card-body scroll-custom' >
                                <table className='table tCustom table-striped table-text mt-1-rem mb-2-rem  mt-4 ' >
                                    <thead>
                                        <tr>
                                            <th> <div className='font-weight-600 ps-5'>SL No </div></th>
                                            <th><div className='font-weight-600'>Description</div></th>
                                            <th><div className='font-weight-600'>Estimated Amount</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><div className='ps-5 col-md-2 '>1</div></td>
                                            <td className='col-md-6'>Buying Price (FOB)</td>
                                            <td className='col-md-4'>
                                                <NumberFormat value={formik.values.BuyingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>2</div></td>
                                            <td className='col-md-6'>Ocean Freight</td>
                                            <td className='col-md-4'>
                                                {isDataAvailable &&
                                                    <NumberFormat value={tradedata[0].tradedetails.oceanFreight} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>3</div></td>
                                            <td className='col-md-6'>Finance Cost</td>
                                            <td className='col-md-4'>
                                                {iscostsheetavailable ?
                                                    <NumberFormat value={formik.values.FinancePrice} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    :
                                                    <FormFields.InputField
                                                        md={5}

                                                        name='FinancePrice'
                                                        type='number'
                                                        label='USD'
                                                        val={formik.values.FinancePrice}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>4</div></td>
                                            <td className='col-md-6'>Insurance Cost(Cargo)</td>
                                            <td className='col-md-4'>
                                                {iscostsheetavailable ?
                                                    <NumberFormat value={formik.values.InsuranceCostCargo} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    :
                                                    <FormFields.InputField
                                                        md={5}
                                                        name='InsuranceCostCargo'
                                                        type='number'
                                                        label='USD'
                                                        val={formik.values.InsuranceCostCargo}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>5</div></td>
                                            <td className='col-md-6'>Insurance Cost(CL)</td>
                                            <td className='col-md-4'>
                                                {iscostsheetavailable ?
                                                    <NumberFormat value={formik.values.InsuranceCostCL} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    :
                                                    <FormFields.InputField
                                                        md={5}
                                                        name='InsuranceCostCL'
                                                        type='number'
                                                        label='USD'
                                                        val={formik.values.InsuranceCostCL}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>6</div></td>
                                            <td className='col-md-6'>Insurance Cost(Others)</td>
                                            <td className='col-md-4'>
                                                {iscostsheetavailable ?
                                                    <NumberFormat value={formik.values.InsuranceCostOthers} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                    :
                                                    <FormFields.InputField
                                                        md={5}
                                                        name='InsuranceCostOthers'
                                                        type='number'
                                                        label='USD'
                                                        val={formik.values.InsuranceCostOthers}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>7</div></td>
                                            <td className='col-md-6'>Despatch</td>
                                            <td className='col-md-4'>
                                                {isDataAvailable &&
                                                    <NumberFormat value={tradedata[0].tradedetails.dispatch} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='ps-5 col-md-2'>8</div></td>
                                            <td className='col-md-6'>Demmurage</td>
                                            <td className='col-md-4'>
                                                {isDataAvailable &&
                                                    <NumberFormat value={tradedata[0].tradedetails.demurrage} displayType={'text'} thousandSeparator={true} prefix='USD ' />
                                                }
                                            </td>
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td className='text-right'>
                                                <p>Estimated Total Amount :</p>
                                                <p>Selling Price :</p>
                                                <p>Margin :</p>
                                                <p>Margin% (Margin/Selling Price*100) :</p>
                                            </td>
                                            <td>

                                                <p><strong><NumberFormat value={formik.values.total} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong></p>
                                                <p><strong><NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong></p>
                                                <p><strong> <NumberFormat value={formik.values.margin} displayType={'text'} thousandSeparator={true} prefix='USD ' /></strong></p>
                                                <p><strong>{formik.values.marginper != "" ? parseFloat(formik.values.marginper).toFixed(2) : 0}%</strong></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div>
                                    <Row>
                                        <div className='form-card-footers p-2-rem d-flex' style={{ position: 'relative' }}>
                                            <span className='pl-10'>
                                                <BlueButton value='Save' clickEvent={savecostsheet} disabled={iscostsheetavailable ? true : false} />
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
