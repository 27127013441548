import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { Col, Row, Accordion, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import * as FormComponent from "../../../components/formcomponents";
import * as ButtonComponent from "../../../components/buttoncomponents";
import ViewInvoice from './ViewInvoice';
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import { useFormik } from 'formik'
import { useMsal } from "@azure/msal-react";
import GenerateInvoice from './GenerateInvoice';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

export function GetTradeNo(props) {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [tradeNoList, setTradeNoList] = useState([])

    useEffect(() => {
        getTradeNo()
    }, [props])

    const getTradeNo = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        let id = parseInt(props.customerid)
        console.log(id)
        if (id != 0) {
            console.log(props.customerid, 'customerid')
            json_list.customerID = parseInt(props.customerid)
        }
        if (parseInt(props.productid) != 0) {
            json_list.customerProductID = parseInt(props.productid)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_NO, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let final = res.data.result.filter(x => x.is_PerformaInvoiceGenerated == false)
                final = final.filter((ele, ind) => ind === final.findIndex(elem => elem.tradeID === ele.tradeID))
                setTradeNoList(final)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            {tradeNoList.map((list, k) => {
                return (
                    <>
                        <option value={list.tradeID}>{list.tradeNo}</option>
                    </>
                )
            })}
        </>
    )
}

export function GetProduct(props) {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getProduct()
    }, [props])

    const getProduct = () => {
        if (parseInt(props.customerid) != 0) {
            let json_list = {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "id": parseInt(props.customerid)
            }
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_BY_CUSTOMERID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    setCustomerList(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
    }

    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.productID}>{list.productName}</option>
                    </>
                )
            })}
        </>
    )
}

export function GetCustomer() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    useEffect(() => {
        getCustomer()
    }, [])

    const getCustomer = () => {
        let json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_LIST, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setCustomerList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    return (
        <>
            {CustomerList.map((list, k) => {
                return (
                    <>
                        <option value={list.custid}>{list.customername}</option>
                    </>
                )
            })}
        </>
    )
}

export default function Index() {
    const { instance } = useMsal();
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)
    const [paymentTermList, setPaymentTermList] = useState([])
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false)
    const [selectedDetails, setSelectedDetail] = useState([])
    const [paymentlist, setpaymentlist] = useState([])
    const [isFinal, setIsFinal] = useState(false)
    const [count, setCount] = useState(0)
    const [generatePerInvoice, setGeneratePerInvoice] = useState()
    const [invoiceData, setInvoiceData] = useState()
    const [disabled, setDisabled] = useState(true)
    const [isloader, setisloader] = useState(false)
    const [selectedfromlist, setselectedfromlist] = useState([])
    const [isgeneratedisabled, setisgeneraldisabled] = useState(true)
    const [customerid, setcustomerid] = useState(0)
    const [productid, setproductid] = useState(0)
    const [doc, setdoc] = useState(false)
    const [custcomobject, setcustcomobject] = useState([])
    const [tradescenarioID, settradescenarioID] = useState(0)
    const [selectedcustomerid, setselectedcustomerid] = useState(0)
    const [selectedcompanyid, setselectedcompanyid] = useState(0)

    const MySwal = withReactContent(Swal)

    useEffect(() => {
        console.log(isloader)
    }, [isloader])

    const formik = useFormik({
        initialValues:
        {
            id: '',
            unitPrice: '',
            totalAmount: '',
        },
    })

    const openInvoice = () => {
        console.log('inside')
        setIsInvoiceOpen(!isInvoiceOpen)
    }
    const paymentTermById = (e) => {
        let val = e.currentTarget.value
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(val)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PAYMENT_TERM_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setPaymentTermList(res.data.result)
                let length = res.data.result.length
                let actualLength = 0
                res.data.result.map((re, k) => {
                    if (!re.is_InvGenerated) {
                        actualLength++
                    }
                })
                console.log(actualLength)
                if (actualLength == 1) {
                    setIsFinal(true)
                }

            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })

        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(val) //tradeid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PROFORMA_INVOICE, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setpaymentlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const handleSelectTrade = (e) => {
        const tradeid = parseInt(e.currentTarget.value)
        formik.values.id = tradeid
        let json_lists = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeid) // InitiationId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT, json_lists, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setcustcomobject(res.data.result)
                settradescenarioID(res.data.result[0].tradeScenarioID)
                if (res.data.result[0].tradeScenarioID == 1) {
                    formik.values.totalAmount = res.data.result[0].ratioAmount
                    console.log(res.data.result[0].ratioAmount)
                    setselectedcompanyid(res.data.result[0].companyID)
                    setselectedcustomerid(res.data.result[0].customerID)
                    setDisabled(false)
                }
                console.log(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        const json_new = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(e.target.value) //tradeid
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_CUSTOMER_PAYMENT_DETAILS, json_new, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setSelectedDetail(res.data.result[0])
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
            
        viewProformaInvoices(parseInt(tradeid))
    }
    const handlePaymentTerm = (e) => {
        let val = parseInt(e.target.value)
        console.log(val)
        let filter = paymentlist.filter(x => x.tradeCustomerPaymentDetailID == val)[0]
        console.log(filter)
        setSelectedDetail(filter)
    }
    const getproductdetails = (e) => {
        let val = e.currentTarget.value
        console.log(e.currentTarget.value)
        setcustomerid(val)
        setdoc(!doc)
    }
    const getProductID = (e) => {
        let id = e.currentTarget.value
        setproductid(id)
    }

    const viewProformaInvoices = (id)=>{
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let result = res.data.result
                console.log(result)
                console.log(id)
                let filter = result.filter(x=>x.tradeId == id)
                console.log(filter)
               setInvoiceData(filter)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    const generateInvoice = () => {
        let list = []
        const json_list_other = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(formik.values.id),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list_other, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                if (res.data.result[0].tradedetails.customerProformaInvoiceNo == null || res.data.result[0].tradedetails.customerProformaInvoiceNo == "") {
                    {
                        localStorage.getItem('Generate_Customer_Proforma_Invoice') == 3 &&
                            MySwal.fire(
                                {
                                    icon: 'warning',
                                    html: 'Are you sure you wish to generate ' + '<b>Customer Proforma Invoice?</b> ',
                                    type: 'success',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                    confirmButtonText: 'Confirm',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        const json_list = {
                                            "instanceID": CONFIG.PMSInstanceID,
                                            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                                            "created_User": CONFIG.LoginID,
                                            "id": parseInt(formik.values.id),
                                            "customerID": selectedcustomerid,
                                            "companyID": selectedcompanyid
                                        }
                                        setisloader(true)
                                        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GENERATE_CUSTOMER_PROFORMA_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                                            .then((res) => {
                                                console.log(res.data.result)
                                                setGeneratePerInvoice(true)

                                                let e = {
                                                    "currentTarget": {
                                                        "value": formik.values.id
                                                    }
                                                }
                                                viewProformaInvoices(formik.values.id)
                                                setisloader(false)
                                            })
                                    }
                                })
                    }
                }
                else {
                    setGeneratePerInvoice(false)
                    //setCPIV(true)
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
        //setIsGenerateInvoice(!isGenerateInvoice)
    }

    useEffect(() => {
        console.log(count, 'count', formik.values)

    }, [count, formik.values, invoiceData])

    const handleCompanySelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.companyID == id)[0]
        console.log(filter)
        formik.values.totalAmount = filter.ratioAmount
        setselectedcustomerid(filter.customer.customerID)
        setselectedcompanyid(id)
        setDisabled(false)
    }

    const handleCustomerSelect = (e) => {
        const id = parseInt(e.currentTarget.value)
        console.log(id)
        let filter = custcomobject.filter(x => x.customerID == id)[0]
        formik.values.totalAmount = filter.ratioAmount
        setselectedcompanyid(filter.company.companyID)
        setselectedcustomerid(id)
        setDisabled(false)
    }

    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='6.2' isOpenid={6} />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 text-uppercase d-flex align-items-end pl-10'>Generate Customer Proforma Invoice</span>
                        </div>
                    </div>
                    <div className='add-edit-wrapper faq'>
                        {!isGenerateInvoice ?
                            <>
                                <Card>
                                    <Card.Body className='align-items-center'>
                                        <Row>
                                            <Col md={3} sm={12} lg={3}>
                                                <FormComponent.InputSelect
                                                    label='Select Customer'
                                                    classname='pt-1-rem'
                                                    optionField={<GetCustomer />}
                                                    onChange={getproductdetails}
                                                    onBlur={getproductdetails}
                                                />
                                            </Col>
                                            <Col md={3} sm={12} lg={3}>
                                                <FormComponent.InputSelect
                                                    label='Select Product'
                                                    classname='pt-1-rem'
                                                    optionField={<GetProduct customerid={customerid} />}
                                                    onChange={getProductID}
                                                    onBlur={getProductID}
                                                />
                                            </Col>
                                            <Col md={3} sm={12} lg={3}>
                                                <FormComponent.InputSelect
                                                    label='Select Trade'
                                                    classname='pt-1-rem'
                                                    optionField={<GetTradeNo customerid={customerid} productid={productid} />}
                                                    onChange={handleSelectTrade}
                                                    onBlur={handleSelectTrade}
                                                />
                                            </Col>
                                            {tradescenarioID == 2 &&
                                                <Col md={3}>
                                                    <FloatingLabel controlId="floatingSelect" label='Select Company'>
                                                        <Form.Select
                                                            className="form_input form_input_color_change mt-1-rem"
                                                            placeholder='Select Company'
                                                            onChange={handleCompanySelect}
                                                            onBlur={handleCompanySelect}
                                                            name='companyselect'>
                                                            <option value={0}>Select</option>
                                                            {custcomobject.map((list, k) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.companyID}>{list.company.companyName}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            {tradescenarioID == 3 &&
                                                <Col md={3}>
                                                    <FloatingLabel controlId="floatingSelect" label='Select Customer'>
                                                        <Form.Select
                                                            className="form_input form_input_color_change mt-1-rem"
                                                            placeholder='Select Customer'
                                                            onChange={handleCustomerSelect}
                                                            onBlur={handleCustomerSelect}
                                                            name='companyselect'>
                                                            <option value={0}>Select</option>
                                                            {custcomobject.map((list, k) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.customerID}>{list.customer.customerName}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            {/* <Col md={3} sm={12} lg={3} className='pt-1-rem'>
                                                <FloatingLabel controlId="floatingInput" label={'Unit Price'}>
                                                    <Form.Control
                                                        name='unitPrice'
                                                        type='text'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.unitPrice}
                                                        className={'form_input form_input_color_change'}
                                                        disabled={true}
                                                    />
                                                </FloatingLabel>
                                            </Col> */}
                                            <Col md={3} sm={12} lg={3} className='pt-1-rem'>
                                                <FloatingLabel controlId="floatingInput" label={'Total Amount'}>
                                                    <Form.Control
                                                        name='totalAmount'
                                                        type='text'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.totalAmount}
                                                        className={'form_input form_input_color_change'}
                                                        disabled={true}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4} lg={3} sm={12} className='pt-1-rem d-flex align-items-center'>
                                                <ButtonComponent.BlueButton value='Generate Proforma Invoice' clickEvent={generateInvoice} disabled={disabled} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className='mt-1-rem'>
                                    <table className="table tCustom table-text">
                                        <thead >
                                            <tr className='list-top'>
                                                <th className=''>S.NO</th>
                                                <th className=''>TRADE NO</th>
                                                <th className=''>INVOICE NO</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isloader ?
                                                <center>
                                                    <img src='assets/images/loadertrad.gif' className='img-fluid' />
                                                </center>
                                                :
                                                invoiceData != null ?
                                                    invoiceData.map((list, k) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    {isInvoiceOpen &&
                                                                        <ViewInvoice isOpen={isInvoiceOpen} toggle={openInvoice} data={selectedDetails != null ? selectedDetails : ''} />
                                                                    }
                                                                    <td>{k + 1}</td>
                                                                    <td>{list.tradeNo}</td>
                                                                    <td>{list.customerProformaInvoiceNo}</td>
                                                                    <td>
                                                                        <Button size="md" onClick={openInvoice}>
                                                                            View
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    ''
                                            }
                                        </tbody>
                                    </table>
                                </Card>
                            </>
                            :
                            <>
                                <Col md={9}>
                                    <GenerateInvoice data={selectedDetails} isFinal={isFinal} toggle={generateInvoice} />
                                </Col>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
