import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import Greeting from "./Greeting/index"
import Notifications from "../Common Components/Notifications"
import * as CONFIG from "../../../helpers/config"
function Index(props) {
    const { instance } = useMsal();
    const [notification, setNotifications] = useState([]) 
    const [meetingDetails, setMeetingsDetails] = useState() 
    useEffect(()=>{
        let final_json = {
          "ID": CONFIG.LoginID
      }
      axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setNotifications(res.data.result)
      })
      },[])
  
      useEffect(()=>{
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "fromDate": props.fromDate,
            "toDate": props.toDate,
            "userID": CONFIG.LoginID
      }
      axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DB_MEETING_DETAIL, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
      .then((res) => {
          console.log(res.data.result)
          setMeetingsDetails(res.data.result)
      })
      },[props])

      useEffect(()=>{
        console.log(notification)
      },[notification, meetingDetails])

  return (
    <>
     <div className='inner-page-content'>
            <div className = 'row'>
                <div className = 'col-md-7 col-sm-12'>
                    <div className = 'row'>
                        <Greeting meetingDetails = {meetingDetails != null ? meetingDetails : 0} />
                    </div>
                </div>
                <div className = 'col-md-5 sm-12'>
                    <div className = 'col-12'>
                        <Notifications notificationNo = {6} notification = {notification != null && notification.length > 0 ? notification : '' }/>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Index