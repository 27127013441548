import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import * as CONFIG from "../../../helpers/config"
import { BackButton, } from '../../../components/buttoncomponents'
import { useMsal } from "@azure/msal-react";
import GenerateCustomerInvoice from "../ContractFinalized/GenerateCustomerInvoice"

function CustomerInvoiceList(props) {
    const { instance } = useMsal();
    const [customerInvoiceData, setPurchaseOrder] = useState()
    const [tradeData, setTradeData] = useState()
    const [tradeID, setTradeID] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    const [showView, setShowView] = useState(false)
    //const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,

        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_CUSTOMER_INVOICE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setPurchaseOrder(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])

    const back = () => {
        navigate('/trade-transactions', { state: { tab: 1, } });
    }

    const handleClose = () => {
        setShowView(false)
    }
    const handlePurchaseOrder = (e) => {
        //console.log(e.target.id)
        //console.log(customerInvoiceData)
        //const json_list = {
        //    "instanceID": CONFIG.PMSInstanceID,
        //    "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        //    "created_User": CONFIG.LoginID,
        //    "id":parseInt(e.target.id)
        //}
        //axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAIL_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        //.then((res) => {
        //   console.log(res.data.result)
        //   setTradeData(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
        //   console.log(res.data.result.filter(x=> x.tradeID == e.target.id)[0])
        //   //console.log(res.data.result.filter(x=> x.tradeID == customerInvoiceData.tradeId)[0])
        //   console.log(customerInvoiceData)
        //   //navigate('/customer-invoice', { state:{tradeData: res.data.result.filter(x=> x.tradeID == e.target.id)[0], tab: 1, 
        //   setTradeID(customerInvoiceData.tradeId)
        //   setShowView(true)
        //}).catch((error)=>{
        //    if(error.response.status == 401){
        //        localStorage.clear();
        //        instance.logoutRedirect({
        //          postLogoutRedirectUri: "/",
        //        });
        //    }
        //})  
        //navigate('/customer-proforma-invoice', { state:{tradeData: props.data, tab: 1, tradeID: customerInvoiceData.tradeID}});;

        setShowView(true)
        setTradeID(e.target.id)
    }
    const showviewToggle = () => {
        setShowView(!showView)
    }
    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='7.2' isOpenid={7} />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex mt-4' style={{ paddingLeft: '37px', }}>
                        <div className='d-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-5'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10 '>CUSTOMER INVOICES</span>
                        </div>
                    </div>
                    {showView == true &&
                        <GenerateCustomerInvoice
                            data={tradeData}
                            tradeID={tradeID}
                            tab={1}
                            invoiceList={true}
                            handleClose={handleClose}
                            isOpen={showView}
                            toggle={showviewToggle}
                        />
                    }
                    <div className='inner-page-content mx-md-4 mx-2 mt-4'>
                        <Card>
                            <CardBody>
                                <div class="col-lg-12 list-scroll">
                                    <div class="table-responsive-sm ">
                                        <table className="table tCustom table-text">
                                            <thead className='p-1-rem'>
                                                <tr className='text-uppercase list-top'>
                                                    <th>customer invoice#</th>
                                                    <th>invoice date</th>
                                                    <th>trade#</th>
                                                    <th>customer</th>
                                                    <th>Document</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customerInvoiceData != null && customerInvoiceData.length > 0 ?
                                                    customerInvoiceData.map((values, k) => (
                                                        <tr>
                                                            <td className='font-14 font-weight-500'><p className='mb-0 w-150'>{values.customerInvoiceNo}</p></td>
                                                            <td className='font-14 font-weight-500'><p className='mb-0 w-150'>{Moment(values.customerInvoiceDate).format("DD/MM/YYYY")}</p></td>
                                                            <td><p className='mb-0 w-150'>{values.tradeNo}</p></td>
                                                            <td className='w-150'>
                                                                <div className='d-flex flex-column'>
                                                                    <div>
                                                                        <b>{values.customerCode}</b>
                                                                    </div>
                                                                    <div>
                                                                        <p className='font-14 font-weight-500 mb-0'> {values.customerName}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a download={values.customerInvoiceDocumentName} href={values.customerInvoiceDocument}>{values.customerInvoiceDocumentName}</a>
                                                            </td>
                                                            <td>
                                                                <button type="button" class="btn btn-info text-white bg-primary font-12 font-weight-600 w-150" id={values.customerPaymentDetailID} onClick={(e) => { handlePurchaseOrder(e) }}>View Invoice</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>


            </div>
        </>

    )
}

export default CustomerInvoiceList