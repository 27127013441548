import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import * as FormFields from "../../../components/formcomponents"
import { Modal, ModalHeader, ModalBody, ModalFooter, TabPane } from "reactstrap"
import { Col, Form, FloatingLabel, Button, Row, ButtonToolbar, Accordion } from "react-bootstrap"
import AddExternalUser from './addExternalUser'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { useMsal } from "@azure/msal-react";
import { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as ButtonComponent from "../../../components/buttoncomponents"
import { set } from 'date-fns'
import Moment from "moment";


export default function EditTour(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setmodal] = useState(true)
    const [visitdate, setvisitdate] = useState(props.meeting.meeting.meetingDate)
    const [hostmodal, sethostmodal] = useState(false)
    const [userlist, setuserlist] = useState(props.meeting.meeting.meetingTourUsers)
    const [userid, setuserid] = useState([])
    const [attendeeslist, setattendeeslist] = useState([])
    const [notifyuserlist, setnotifyuserlist] = useState([])
    const [agendaStatus, setAgendStatus] = useState(false)
    const [premeetinglist, setpreemeetinglist] = useState([])
    const [preemeetingstatus, setpreemeetingstatus] = useState(false)
    const [personmetlist, setpersonmetlist] = useState([])
    const [isTaskEdit, setistaskedit] = useState(false)
    const [taskindex, settaskindex] = useState(0)
    const [isdataset, setisdataset] = useState(false)
    const [defaultattendee, setdefaultattendee] = useState([])
    const [defaultnotifylist, setdefaultnotifylist] = useState([])
    const [defaultpersonmet, setdefaultpersonmet] = useState([])
    const [taskduedate, settaskduedate] = useState(false)
    const [taskassignedtouser, settaskassignedto] = useState(0)

    useEffect(() => {
        getUsers()
    }, [])

    const toggle = () => {
        setmodal(!Modal)
        props.toggle()
    }

    const formik = useFormik({
        initialValues: {
            referenceNo: props.meeting.meeting.documentNumberForDocControl,
            companyname: props.meeting.meeting.companyEntityName,
            purposeofVisit: props.meeting.meeting.purposeofVisit,
            taskname: '',
            is_Completed: false
        }
    })

    const handlevisitdate = (date) => {
        if (date == null) {
            setvisitdate(false)
        }
        else {
            setvisitdate(date)
        }
    }
    const addHost = () => {
        sethostmodal(!hostmodal)
    }
    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserlist(res.data.result)
                let users = []
                props.meeting.meeting.meetingRequiredAttendance.map((attendee, k) => {
                    let data = res.data.result.filter(x => x.meetingUserID == attendee.meetingUserID)[0]
                    users.push(data)
                })
                setdefaultattendee(users)
                let usr = []
                props.meeting.meeting.meetingNotifyUser.map((notify, k) => {
                    let data = res.data.result.filter(x => x.meetingUserID == notify.meetingUserID)[0]
                    usr.push(data)
                })
                setdefaultnotifylist(usr)
                let us = []
                props.meeting.meeting.meetingTourUsers.map((li) => {
                    let data = res.data.result.filter(x => x.meetingUserID == li.meetingUserID)[0]
                    us.push(data)
                })
                setpersonmetlist(us)
                setpreemeetinglist(props.meeting.meeting.meetingAgendaTask)
                setisdataset(true)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        console.log(attendeeslist)
        console.log(notifyuserlist)
        console.log(userid)
    }, [userid, attendeeslist, notifyuserlist])
    const handleSelectUser = (event, values) => {
        console.log(values)
        let atlist = []
        if (Array.isArray(values)) {
            values.map((val, k) => {
                let ispresent = props.meeting.meeting.meetingTourUsers.filter(x => x.meetingUserID == val.meetingUserID)
                if (ispresent.length > 0) {
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    atlist[k] = ispresent[0]
                }
                else {
                    atlist[k] = {
                        "meetingUserID": val.meetingUserID
                    }
                }
            })
            console.log(atlist)
            let preselected = atlist.filter(x => x.meetingTourUsersID != null)
            props.meeting.meeting.meetingTourUsers.map((at, l) => {
                let ispresentinnew = preselected.filter(x => x.meetingUserID == at.meetingUserID)
                if (ispresentinnew.length > 0) {
                    //console.log(at)
                }
                else {
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    atlist.push(newlist)
                }
            })
            console.log(atlist)
            setuserid(atlist)

        } else {
            setuserid(values.meetingUserID)
        }
    }
    const handlerequiredattendees = (event, values) => {
        console.log(values)
        let atlist = []
        if (Array.isArray(values)) {
            values.map((val, k) => {
                let ispresent = props.meeting.meeting.meetingRequiredAttendance.filter(x => x.meetingUserID == val.meetingUserID)
                if (ispresent.length > 0) {
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    atlist[k] = ispresent[0]
                }
                else {
                    atlist[k] = {
                        "meetingUserID": val.meetingUserID
                    }
                }
            })
            let preselected = atlist.filter(x => x.meetingRequiredAttendanceID != null)
            props.meeting.meeting.meetingRequiredAttendance.map((at, l) => {
                let ispresentinnew = preselected.filter(x => x.meetingUserID == at.meetingUserID)
                if (ispresentinnew.length > 0) {
                    //console.log(at)
                }
                else {
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    atlist.push(newlist)
                }
            })
            console.log(atlist)
            setattendeeslist(atlist)
        } else {
            setattendeeslist(values.meetingUserID)
        }
    }

    const handlenotifyusers = (event, values) => {
        let notify = []
        if (Array.isArray(values)) {
            values.map((val, k) => {
                let ispresent = props.meeting.meeting.meetingNotifyUser.filter(x => x.meetingUserID == val.meetingUserID)
                if (ispresent.length > 0) {
                    ispresent[0].is_Active = true
                    ispresent[0].is_Deleted = false
                    notify[k] = ispresent[0]
                }
                else {
                    notify[k] = {
                        "meetingUserID": val.meetingUserID
                    }
                }
            })
            let preselected = notify.filter(x => x.meetingAgendaTaskNotifyUserID != null)
            props.meeting.meeting.meetingNotifyUser.map((at, l) => {
                let ispresentinnew = preselected.filter(x => x.meetingUserID == at.meetingUserID)
                if (ispresentinnew.length > 0) {
                    //console.log(at)
                }
                else {
                    let newlist = at
                    newlist.is_Active = false
                    newlist.is_Deleted = true
                    notify.push(newlist)
                }
            })
            console.log(notify)
            setnotifyuserlist(notify)
        } else {
            setnotifyuserlist(values.meetingUserID)
        }
    }

    const agendaHtml = () => {
        setAgendStatus(!agendaStatus)
    }

    const saveAgenda = () => {
        let tasklist = {
            "task": formik.values.taskname,
            "is_PreMeetingPoints": preemeetingstatus,
            "is_Completed": false,
            "taskDueDate": taskduedate,
            "meetingUserID": taskassignedtouser,
            "is_Completed": false
        }
        let preemeeting = [...premeetinglist]
        preemeeting.push(tasklist)
        formik.values.taskname = ''
        setpreemeetinglist(preemeeting)
        setAgendStatus(false)
    }

    const saveContract = () => {
        const json_list = {
            "meetingID": props.meeting.meeting.meetingID,
            "meetingCode": props.meeting.meeting.meetingCode,
            "is_Meeting": props.meeting.meeting.is_Meeting,
            "documentNumberForDocControl": formik.values.referenceNo,
            "meetingDate": visitdate,
            "strMeetingStartTime": "00:00",
            "strMeetingEndTime": "00:00",
            "companyEntityName": formik.values.companyname,
            "purposeofVisit": formik.values.purposeofVisit,
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "modified_User": CONFIG.LoginID,
            "meetingAgendaTask": premeetinglist,
            "meetingRequiredAttendance": attendeeslist,
            "meetingNotifyUser": notifyuserlist,
            "meetingTourUsers": userid
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.UPDATE_MEETING, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.toggle()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const editTask = (index) => {
        let selectedtask = premeetinglist[index]
        console.log(selectedtask)
        formik.values.taskname = selectedtask.task
        formik.values.is_Completed = selectedtask.is_Completed
        settaskduedate(selectedtask.taskDueDate)
        settaskassignedto(selectedtask.meetingUserID)
        setAgendStatus(true)
        setistaskedit(true)
        settaskindex(index)
    }

    const removeTaskAdd = () => {
        setAgendStatus(false)
    }

    const updatetask = (index) => {
        let preemeeting = [...premeetinglist]
        preemeeting[index].task = formik.values.taskname
        preemeeting[index].is_Completed = formik.values.is_Completed
        preemeeting[index].taskDueDate = taskduedate
        preemeeting[index].meetingUserID = taskassignedtouser
        setpreemeetinglist(preemeeting)
        console.log(premeetinglist)
        setAgendStatus(!agendaStatus)
        setistaskedit(false)
    }
    const handleTaskDueDate = (date) => {
        console.log(date)
        if (date == null) {
            settaskduedate(false)
        }
        else {
            settaskduedate(date)
            console.log(taskduedate)
        }
    }
    const handleAssignedTo = (event, values) => {
        settaskassignedto(values.meetingUserID)
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className='payment-method-modals'>
            <ModalHeader toggle={toggle} className='p-40'>
                {hostmodal &&
                    <AddExternalUser isOpen={hostmodal} getUsers={getUsers} />
                }
                <h2>Tours</h2>
            </ModalHeader>
            <ModalBody className='service-request-modal-wrapper p-40 scrollContract mb-10'>
                <div className=''>
                    <div className='font-16 font-weight-600 color-707895 '>
                        <>
                            <Row className='border_bottom_input'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Reference No. for document control' className=''
                                    name='referenceNo'
                                    val={formik.values.referenceNo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <FormFields.DatePickBottomBorder
                                    md={6}
                                    handleCompletionDate={handlevisitdate}
                                    label='Date of Visit'
                                    val={visitdate}
                                    className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                />
                                <FormFields.BottomBorderInputField
                                    md={6}
                                    label='Name of the Company / Entity' className=''
                                    name='companyname'
                                    val={formik.values.companyname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <Col md={11}>
                                    {isdataset &&
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={userlist.filter(x => x.is_Internal == false)}
                                            getOptionLabel={(option) => option.userName}
                                            onChange={handleSelectUser}
                                            defaultValue={personmetlist}
                                            limitTags={2}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Name of person met"
                                                    placeholder="Person Met"
                                                />
                                            )}
                                        />
                                    }
                                </Col>
                                <Col md={1}>
                                    <div className='no_border'>
                                        <img src='assets/images/blue_add.svg' className='pointer' onClick={addHost} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <Col md={6}>
                                    {isdataset &&
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={userlist.filter(x => x.is_Internal == true)}
                                            getOptionLabel={(option) => option.userName}
                                            onChange={handlerequiredattendees}
                                            defaultValue={defaultattendee}
                                            limitTags={2}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Add Required Attendees"
                                                    placeholder="Add Required Attendees"
                                                />
                                            )}
                                        />
                                    }
                                </Col>
                                <Col md={6}>
                                    {isdataset &&
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            onChange={handlenotifyusers}
                                            options={userlist.filter(x => x.is_Internal == true)}
                                            getOptionLabel={(option) => option.userName}
                                            defaultValue={defaultnotifylist}
                                            limitTags={2}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                        <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Notify Members"
                                                    placeholder="Notify Members"
                                                />
                                            )}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-2-rem border_bottom_input task_description'>
                                <FormFields.BottomBorderInputField
                                    md={12}
                                    label='Purpose of visit' className=''
                                    name='purposeofVisit'
                                    val={formik.values.purposeofVisit}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Row>
                            <Row className='mt-2-rem'>
                                <Tabs>
                                    <div className='d-flex'>
                                        <Col md={6}>
                                            <TabList>
                                                <Tab>
                                                    Discussion Points
                                                </Tab>
                                            </TabList>
                                        </Col>
                                        <Col md={6} className='d-flex  justify-content-end'>
                                            <div className='no_border'>
                                                <img src='assets/images/blue_add.svg' className='pointer' onClick={agendaHtml} />
                                            </div>
                                        </Col>
                                    </div>
                                    <TabPanel className='mb-2-rem'>
                                        {agendaStatus &&
                                            <>
                                                <div className='d-flex task1'>
                                                    <Col md={4} className='d-flex task1 align-items-center'>
                                                        <div className='task_div'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div '>
                                                            <Form.Check
                                                                name='is_Completed'
                                                                checked={formik.values.is_Completed}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                            <FormFields.BottomBorderInputField
                                                                md={12}
                                                                label='Add Task'
                                                                name='taskname'
                                                                val={formik.values.taskname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={8} className='d-flex task1 align-items-center justify-content-end'>
                                                        <div className='task_div d-flex meeting_task_due_date'>
                                                            <FormFields.DatePickBottomBorder
                                                                md={12}
                                                                handleCompletionDate={handleTaskDueDate}
                                                                label='Task Due Date'
                                                                val={taskduedate}
                                                                className='br-0 border-right-0 border-left-0 border-right-0 meeting_date'
                                                            />
                                                        </div>
                                                        <div className='task_div col-md-8'>
                                                            <Autocomplete
                                                                id="tags-standard"
                                                                defaultValue={isTaskEdit ? userlist.filter(x => x.meetingUserID == taskassignedtouser)[0] : null}
                                                                options={userlist}
                                                                getOptionLabel={(option) => option.userName}
                                                                onChange={handleAssignedTo}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                            <p className='font-12 font-weight-500 mb-0'>{option.userAvatar}</p>
                                                                        </div>
                                                                        <span style={{ fontSize: '12px' }}>{option.userName} </span>
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Assigned To"
                                                                        placeholder="Assigned To"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" onClick={removeTaskAdd}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_save'} type="button"
                                                        onClick={() => {
                                                            isTaskEdit ?
                                                                updatetask(taskindex)
                                                                :
                                                                saveAgenda()
                                                        }}>
                                                        Save Task
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {premeetinglist.map((meeting, l) => {
                                            let users = userlist.filter(x => x.meetingUserID == meeting.meetingUserID)
                                            return (

                                                <div className='d-flex task1'>

                                                    <Col md={12} className='d-flex task1 align-items-center'>
                                                        <div className='task_div col-md-1'>
                                                            <img src='assets/images/meeting_task.svg' />
                                                        </div>
                                                        <div className='task_div col-md-1 '>
                                                            <Form.Check
                                                                name='is_Completed'
                                                                checked={meeting.is_Completed}
                                                                disabled={true} />
                                                        </div>
                                                        <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description col-md-3'>
                                                            {meeting.task}
                                                        </div>
                                                        <div className='task_div col-md-2'>
                                                            {Moment(meeting.taskDueDate).format('DD/MM/YYYY')}
                                                        </div>
                                                        <div className='task_div col-md-2'>
                                                            <div className='avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar!=null && users[0].userAvatar}</p>
                                                            </div>
                                                        </div>
                                                        <div className='task_div col-md-1'>
                                                            <ButtonComponent.EditButton clickEvent={() => { editTask(l) }} />
                                                        </div>
                                                    </Col>
                                                </div>
                                            )
                                        })}
                                    </TabPanel>
                                </Tabs>
                            </Row>
                        </>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='p-2-rem trade_existing_customer_modal_footer d-flex justify-content-start'>
                <span><ButtonComponent.BlueBorderButton value='Back' clickEvent={toggle} /></span>
                <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={saveContract} /></span>
            </ModalFooter>
        </Modal>
    )
}
