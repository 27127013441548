
export const PMSInstanceID = 56522122;
export const SolutionID =  3;
export const API_KEY = '90e681820cc44b56a5a86bd5ee569ff4'
export const AccountSubscriptionID = parseInt(localStorage.getItem('AccountSubscriptionID'));
export const LoginID = parseInt(localStorage.getItem('LoginUserID'))
export const Divisions = localStorage.getItem('DivisionID')
export const RoleID = parseInt(localStorage.getItem('LogInUserRoleID'))
export const UserFullName = localStorage.getItem('UserFullName')
export const UserEmail = localStorage.getItem('UserEmail')
export const AuthToken = localStorage.getItem('AuthToken')
export const CompanyID = localStorage.getItem('CompanyID')


export const PMS_SERVER_URL = "https://apimdev.tmssaas.cloud/PMS/";
export const TMS_SERVER_URL = "https://apimdev.tmssaas.cloud/TMS/";
export const PCS_SERVER_URL = "https://apimdev.tmssaas.cloud/PCS/"
export const PRO_SERVER_URL = "https://apimdev.tmssaas.cloud/Pro/"
export const SIGNIN_URI = "User/Authenticate";
export const SUPER_ADMIN_LOGIN = "Accounts/SuperAdminLogin"
export const CHECK_USER_AVAILABLE = "User/CheckUserEmail"
export const ROLES_PERMISSION = "UserAuthorization/TMSCheckRolesPermission"


export const COMPANY_TYPE = [
    "Government",
    "Public Limited",
    "Private Limited",
    "Partnership"
]

export const FINANCIAL_TYPE_LIST = [
    "Sales Revenue",
    "Net Profit/Loss"
]

export const CUSTOMER_ADD_COMPANY_TYPE = "Customer/AddCompanyType"
export const SUPPLIER_ADD_COMPANY_TYPE = "Supplier/AddCompanyType"
export const CUSTOMER_ADD_FINANCIAL_INFO_TYPE = "Customer/AddCustomerFinancialInfoType"
export const SUPPLIER_ADD_FINANCIAL_INFO_TYPE = "Supplier/AddSupplierFinancialInfoType"
export const ADD_ACCOUNT_TYPE = "Company/AddAccountType"

//Super admin
export const CONTATC_US_EMAIL = "Notification/ContactUsEmail"
export const LOGIN_PRO_TMS = "User/AuthenticateUser"
export const ADD_ROLES_PERMISSION = "User/AddAccountSubscriptionPrivilegeMapping"
export const CANCEL_COMPANY = "Accounts/CancelCompany"
export const RENEW_ACCOUNT = "Accounts/RenewAccontSubscription"
export const SUPER_ADMIN_USER_EMAIL = "admin@TMSSaaS.Cloud"
export const CHECK_KEY_IN_PROCESS = "Accounts/CheckKeyInProcess"
export const ADD_REQUESTED_COMPANY = "Accounts/AddRequestedCompany"
export const GET_COMPANY_CODE = "Accounts/GetCompanyCodeAndKey"

//** Global APIs */
export const ADD_ACCOUNT_SUBSCRIPTION = "Accounts/AddAccountSubscription"
export const GET_ACCOUNT_SUBSCRIPTION  = "/Accounts/GetAccountSubscriptions"
export const GET_SUBSCIRPITON_HISTORY = "Accounts/GetSubscriptionHistoryBySubscriptionID"
export const GET_REQUESTED_COMPANY_BY_SUBSCRIPTION = "Accounts/GetRequestedCompanyBySubscriptionID"
export const UPDATE_PAYMENT_STATUS = "Accounts/UpdatePaymentStatus"
export const GENERATE_KEY = "Accounts/GenerateActivationKey"

export const GET_COUNTRY_LIST = "Instance/GetCountryList";
export const GET_STATE_LIST = "Instance/GetStateList";
export const GET_COUNTRY_BY_ID = "";
export const GET_STATE_BY_ID = "";

/**Customer API's List */
export const GET_COMPANY_TYPE = "Customer/GetCompanyType";
export const GET_TMS_PAYMENT_TERMS  = "Instance/GetTMSPaymentTypeList";
export const GET_INCOTERMS = "Instance/GetIncotermsList";
export const GET_SOURCE_OF_FUNDS = "Customer/GetSourceOfFunds";
export const POST_CUSTOMER_DATA = "/Customer/AddCustomer"
export const ADD_CUSTOMER = "Customer/AddCustomer"
export const UPDATE_CUSTOMER = "/Customer/UpdateCustomer"
export const GET_SHAREGOLDER_TYPE = "Instance/GetTMSShareholderTypeList"
export const GET_CUSTOMER_LIST = "Customer/GetCustomer"
export const GET_CUSTOMER_LIST_BY_ID = "Customer/GetCustomerByID"
export const GET_CONTACT_TYPE_LIST ='Instance/GetCustomerContactTypeList'
export const GET_CUSTOMER_FINANCIAL_INFO ="Customer/GetCustomerFinancialInfoType"
export const CHANGE_CUSTOMER_STATUS ="Customer/ChangeCustomerStatus"

export const GET_TMS_CUSTOMER_CATEGORY = "Customer/GetCustomerCategory";
export const ADD_CUSTOMER_CATEGORY = "Customer/AddCustomerCategory";
export const EDIT_CUSTOMER_CATEGORY = "Customer/UpdateCustomerCategory";
export const DELETE_CUSTOMER_CATEGORY  = "Customer/DeleteCustomerCategory";

export const GET_INDUSTRY_LIST = "Customer/GetCustomerIndustryType";
export const ADD_CUSTOMER_INDUSTRY_TYPE = "Customer/AddCustomerIndustryType";
export const EDIT_CUSTOMER_INDUSTRY_TYPE = "Customer/UpdateCustomerIndustryType";
export const DELETE_CUSTOMER_INDUSTRY_TYPE  = "Customer/DeleteCustomerIndustryType";
/**Customer API Ends Here */


/**Supplier API'S List */
export const GET_COMPANY_TYPE_SUPPLIER = "Supplier/GetCompanyType";
export const GET_TMS_PAYMENT_TERMS_SUPPLIER  = "Instance/GetTMSPaymentTypeList";
export const GET_INCOTERMS_SUPPLIER = "Instance/GetIncotermsList";
export const GET_SOURCE_OF_FUNDS_SUPPLIER = "Supplier/GetSourceOfFunds";
export const GET_CONTACT_TYPE_SUPPLIER =""
export const POST_CUSTOMER_DATA_SUPPLIER = "Customer/AddCustomer"
export const ADD_SUPPLIER = "Supplier/AddSupplier"
export const UPDATE_SUPPLIER = "Supplier/UpdateSupplier"
export const GET_SHAREGOLDER_TYPE_SUPPLIER = "Instance/GetTMSShareholderTypeList"
export const GET_SUPPLIER_LIST = "Supplier/GetSupplier"
export const GET_SUPPLIER_LIST_BY_ID = "Supplier/GetSupplierByID"
export const GET_CONTACT_TYPE_LIST_SUPPLIER ='Instance/GetSupplierContactTypeList'
export const CHANGE_SUPPLIER_STATUS ="Supplier/ChangeSupplierStatus"
export const GET_SUPPLIER_FINANCIAL_INFO ="Supplier/GetSupplierFinancialInfoType"

export const GET_TMS_SUPPLIER_CATEGORY = "Supplier/GetSupplierCategory";
export const ADD_SUPPLIER_CATEGORY = "Supplier/AddSupplierCategory";
export const EDIT_SUPPLIER_CATEGORY = "Supplier/UpdateSupplierCategory";
export const DELETE_SUPPLIER_CATEGORY  = "Supplier/DeleteSupplierCategory";

export const GET_INDUSTRY_LIST_SUPPLIER = "Supplier/GetSupplierIndustryType"
export const ADD_SUPPLIER_INDUSTRY_TYPE = "Supplier/AddSupplierIndustryType";
export const EDIT_SUPPLIER_INDUSTRY_TYPE = "Supplier/UpdateSupplierIndustryType";
export const DELETE_SUPPLIER_INDUSTRY_TYPE  = "Supplier/DeleteSupplierIndustryType";
/**Supplier API Ends Here */

//**Product API List */

export const ADD_PRODUCT_CATEGORY = "Product/AddProductCategory";
export const EDIT_PRODUCT_CATEGORY = "Product/UpdateProductCategory";
export const GET_PRODUCT_CATEGORY  = "Product/GetProductCategory";
export const DELETE_PRODUCT_CATEGORY  = "Product/DeleteProductCategory";

export const GET_DIVISION = "Product/GetDivision"
export const ADD_PRODUCT_GROUP = "Product/AddProductGroup";
export const EDIT_PRODUCT_GROUP = "Product/UpdateProductGroup";
export const GET_PRODUCT_GROUP  = "Product/GetProductGroup";
export const DELETE_PRODUCT_GROUP  = "Product/DeleteProductGroup";
export const GET_PRODUCT_BY_GROUPID = "Product/GetProductsByGroup"

export const ADD_PRODUCT_UOM = "Product/AddProductUOM";
export const EDIT_PRODUCT_UOM = "Product/UpdateProductUOM";
export const GET_PRODUCT_UOM  = "Product/GetProductUOM";
export const DELETE_PRODUCT_UOM  = "Product/DeleteProductUOM";


export const ADD_PRODUCT_GRADE = "Product/AddProductGrade";
export const EDIT_PRODUCT_GRADE = "Product/UpdateProductGrade";
export const GET_PRODUCT_GRADE  = "Product/GetProductGrade";
export const DELETE_PRODUCT_GRADE  = "Product/DeleteProductGrade";

export const ADD_PRODUCT_QUALITY = "Product/AddProductQualityParameter";
export const EDIT_PRODUCT_QUALITY = "Product/UpdateProductQualityParameter";
export const GET_PRODUCT_QUALITY  = "Product/GetProductQualityParameter";
export const DELETE_PRODUCT_QUALITY  = "Product/DeleteProductQualityParameter";

export const ADD_PRODUCT_TRACE = "Product/AddProductTraceElement";
export const EDIT_PRODUCT_TRACE = "Product/UpdateProductTraceElement";
export const GET_PRODUCT_TRACE  = "Product/GetProductTraceElement";
export const DELETE_PRODUCT_TRACE  = "Product/DeleteProductTraceElement";

export const GET_PRODUCT_LIST = "Product/GetAllProduct";
export const ADD_PRODUCT = "Product/AddProduct";
export const GET_PRODUCT_BY_ID = "Product/GetProductByID";
export const UPDATE_PRODUCT = "Product/UpdateProduct";
export const CHANGE_STATUS = "Product/ChangeProductStatus";



//Trade Enquiry API Starts
export const GET_TMS_TRADEENQUIRY_CONTRACTTYPE = "Instance/GetTMSContractTypeList"
export const GET_TMS_TRADEENQUIRY_MODEOFTRANSPORT = "Instance/GetTMSModeOfTransportList"
export const GET_TMS_PRODUCTLIST = "Product/GetAllProduct"
export const GET_TMS_PRODUCTGROUP = "Product/GetProductsByGroup"
export const GET_TMS_PRODUCTBYIDLIST = "Product/GetProductByID"
export const GET_TRADEENQUIRY_LIST = "Sales/GetTradeEnquiry";

export const ADD_TRADEENQUIRY = "Sales/AddTradeEnquiry"
export const GET_TRADEENQUIRY_BY_ID ="Sales/GetTradeEnquiryByID"
export const GET_TRADEENQUIRY_BY_IDINITIATE= "Sales/GetTradeEnquiryByIDInitiate"
export const UPDATE_TRADEENQUIRY = "Sales/UpdateTradeEnquiry"
export const GET_TMS_SUPPLY_TERMS = "Sales/GetSupplyTerms"

//**Contract API Starts */
export const GET_CONTRACT_SECTIONS = "Contract/GetContractTemplateSections"
export const ADD_CONTRACT_SECTIONS = "Contract/AddContract"
export const GET_CONTRACT_BY_ID = "Contract/GetContractByID"
export const UPDATE_CONTRACT = "Contract/UpdateContract"
export const UPDATE_CONTRACT_EXPORT_STATUS = "Contract/UpdateExportContractStatus"
export const FINALIZE_CONTRACT = "Contract/FinalizeContract"
export const UPDATESTATUS_FINALIZE_CONTRACT = "Sales/UpdateCustomerContractApprovalStatus"
export const UPDATESTATUS_FINALIZE_CONTRACT_SUPPLIER = "Sales/UpdateSupplierContractApprovalStatus"
export const UPDATE_CUSTOMERCONTRACT_APPROVAL_WFID = "Sales/UpdateCustomerContractApprovalWfID"
export const UPDATE_SUPPLIERCONTRACT_APPROVAL_WFID = "Sales/UpdateSupplierContractApprovalWfID"
export const ACTUAL_COSTSHEET_SEND_FOR_APPROVAL = "Sales/ActualCostsheetSendForApproval"
export const CONTRACT_HISTORY = "Contract/AddContractHistory"

//Trade Initiate API Starts

export const GET_TRADE_INITIATION_SUPPLY = "Sales/GetTradeEnquirySupplyPlanDetails"

//Trade Transaction API

export const GET_TRADE_DETAILS = "Sales/GetTradeDetails"
export const ADD_TRADE = "Sales/AddTrade"
export const GET_TRADE_DETAIL_BY_ID = "Sales/GetTradeByID"
export const UPDATE_CONTRACT_DETAILS = "Sales/UpdateContractIDByTrade"
export const UPDATE_TRADE = "/Sales/UpdateTrade"
export const TRADE_HISTORY = "/Sales/AddTradeHistory"
export const GET_SIGNED_CONTRACT = "Sales/GetSignedContract"
export const GET_SIGNED_DOCUMENT_BY_ID = "Sales/GetSignedContractDocumentByID"
export const GET_TRADE_CUSTOMER_PAYMENT = "Sales/GetTradeCustCompPaymentDetailsByID"


// Cost Sheet API Starts - Krupali
export const GET_TRADE_INITIATION_BY_ID = "Sales/GetTradeByID"
export const GET_TRADE_COSTSHEET_BY_ID = "Sales/GetEstimatedCostSheetDetail"
export const ADD_TRADE_COSTSHEET_BY_ID = "Sales/AddEstimatedCostSheet"
export const UPDATE_TRADE_COSTSHEET_BY_ID = "Sales/UpdateEstimatedCostSheet"
export const MARKCONTRACT_TRADE_BY_ID = "Sales/ChangeStatusForShipment"
export const UPDATE_FREIGHTCHARGE = "Sales/UpdateFreightCharge"
export const UPDATE_COSTSHEET_APPROVAL_WFID = "Sales/UpdateCostsheetsApprovalWfID"
export const ADD_TRADE_PRE_SHIPMENT = "Sales/AddTradePreShipment"
export const UPDATE_TRADE_PRE_SHIPMENT = "Sales/UpdateTradePreShipment"
export const GET_TRADE_PRE_SHIPMENT_BY_TRADEID = "Sales/GetTradePreShipmentDetailsByTradeID"
export const UPDATE_VESSEL_INFO = "Sales/UpdateVesselFixture"
export const ADD_TRADE_POST_VESSEL = "Sales/AddTradePostVesselFixture"
export const UPDATE_TRADE_POST_VESSEL = "Sales/UpdateTradePostVesselFixture"
export const GET_TRADE_POST_VESSEL = "Sales/GetTradePostVesselFixtureDetailsByTradeID"
export const ADD_TRADE_DISCHARGE_PORT = "Sales/AddTradeDischargePort"
export const UPDATE_TRADE_DISCHARGE_PORT = "Sales/UpdateTradeDischargePort"
export const GET_TRADE_DISCHARGE_PORT_BY_TRADE_ID = "Sales/GetTradeDischargePortByTradeID"
export const ADD_TRADE_SHIPMENT_LOADING = "Sales/AddTradeShipmentLoading"
export const UPDATE_TRADE_SHIPMENT_LOADING = "Sales/UpdateTradeShipmentLoading"
export const GET_TRADE_SHIPMENT_LOADING = "Sales/GetTradeShipmentLoadingDetailsByTradeID"
export const ADD_TRADE_PRE_CLOSURE = "Sales/AddTradePreClosure"
export const UPDATE_TRADE_PRE_CLOSURE = "Sales/UpdateTradePreClosure"
export const GET_TRADE_PRE_CLOSURE = "Sales/GetTradePreClosureDetailsByTradeID"
export const ADD_TRADE_ACTUAL_COST_SHEET ="Sales/AddTradeActualCostSheet"
export const UPDATE_TRADE_ACTUAL_COST_SHEET = "Sales/UpdateTradeActualCostSheet"
export const GET_TRADE_ACTUAL_COST_SHEET = "Sales/GetTradeActualCostSheetByTradeID"
export const UPLOAD_SUPPLIER_SIGNED_CONTRACT = "Sales/UploadSupplierSignedContract"
export const UPLOAD_CUSTOMER_SIGNED_CONTRACT = "Sales/UploadCustomerSignedContract"
export const UPLOAD_SHIPMENT_SIGNED_CONTRACT = "Sales/UploadShipmentContract"
export const UPLOAD_CUSTOMER_PROFORMA = "Sales/UploadCustomerProformaInvoiceDocument"
export const UPLOAD_CUSTOMER_INVOICE = "Sales/UpdateCustomerInvoiceDocument"
export const UPLOAD_SUPPLIER_PO = "Sales/UploadSupplierPurchaseOrderDocument"
export const GET_TRADE_NO = "Sales/GetTradeNo"
export const GET_PAYMENT_TERM_BY_ID = "Sales/GetPaymentTermByTradeID"
export const GET_TRADE_CUSTOMER_PAYMENT_DETAILS = "Sales/GetTradeCustomerPaymentDetails"
export const GET_TRADE_CUSTOMER_PROFORMA_INVOICE_DETAILS = "Sales/GetTradeCustomerProformaInvDetails"
export const GET_PRODUCT_BY_CUSTOMERID = "Sales/GetProductByCustomerID"
export const GET_COMPANY_BY_TRADEID = "Sales/GetComapnyByTradeID"
export const GET_INVOICELIST_BY_CUSTOMER_ID = "Sales/GetInvoiceListByCustomer"
export const GET_TRADELIST_BY_SUPPLIER = "Sales/GetTradeListBySupplier"
export const ADD_DEBIT_CREDIT = "Sales/AddDebitCreditNotes"
export const GET_SUPPLIER_DEBIT_CREDIT = "Sales/GetDebitCreditNotesSupplier"
export const GET_CUSTOMER_DEBIT_CREDIT = "Sales/GetDebitCreditNotes"
export const GET_DEBIT_CREDIT_BY_ID  = "Sales/GetDebitCreditNotesByID"

 // Cost Sheet API Starts - Krupali

export const GET_APPROVAL_LIST = "workflow/GetSendForApprovalList"
export const ADD_REJECT_APPROVAL = "workflow/AddRejectApproval"
export const CONTRACT_IS_LOCK_ON_REJECT = "Contract/UpdateLockingAfterReject"
export const ESTIMATED_COSTSHEET_SEND_FOR_APPROVAL = "Sales/EstimatedCostsheetSendForApproval"
export const ADD_WORKFLOW_DEFENITION = "workflow/AddWorkflowDefinition"


// Approval Status

export const STATUS_APPROVED =  2
export const STATUS_REJECTED = 9

//Workflow API's
export const GET_WORKFLOW_DEFINITIONS = "workflow/GetWorkflowDefinitions"
export const UPDATE_WORKFLOW_DEFENITIONS = "workflow/UpdateWorkflowDefinition"

//Customer Invoices APIs

export const GENERATE_CUSTOMER_PROFORMA_INVOICE = 'Sales/GenerateCustomerProformaInvoice'
export const GENERATE_CUSTOMER_INVOICE = 'Sales/GenerateCustomerInvoice'
export const GENERATE_SUPPLIER_PO = 'Sales/GenerateSupplierPurchaseOrder'
export const UPLOAD_SUPPLIER_PROFORMA_INVOICE = 'Sales/UploadSupplierProformaInvoice'
export const UPLOAD_SUPPLIER_INVOICE = 'Sales/UploadSupplierInvoice'

export const GET_CUSTOMER_INVOICE = 'Sales/GetCustomerInvoice'
export const GET_CUSTOMER_PROFORMA_INVOICE = 'Sales/GetCustomerProformaInvoice'
export const GET_SUPPLIER_PO = 'Sales/GetSupplierPurchaseOrder'
export const GET_SUPPLIER_INVOICE = 'Sales/GetSupplierInvoice'

export const GET_TRADECUSTOMERPAYMENTDETAILS_BY_PAYMENTID = "Sales/GetTradeCustomerPaymentDetailsByPaymentID"


//Notifications

//Notifications
export const GET_ALL_NOTIFICATIONS ="Notification/GetInAppNotifications"
export const CLEAR_ALL_NOTIFICATIONS ="Notification/DeleteInAppNotifications"
export const GET_BROADCAST_MESSAGES = "/Notification/GetBroadcastMessage"
export const GET_BROADCAST_BY_ID = "Notification/GetBroadcastMessageByID"
export const ADD_BROADCAST_MESSAGE = "Notification/AddBroadcastMessage"
export const UPDATE_BROADCAST_MESSAGE = "Notification/UpdateBroadcastMessage"
export const DELETE_BROADCAST_MESSAGE = "Notification/DeleteBroadcastMessage"
export const GET_BROADCAST_MESSAGE_USERS = "Notification/GetBroadcastMessageForUsers"
//export const GENERATE_CUSTOMER_PROFORMA_INVOICE = 'Sales/GenerateCustomerInvoice'


//Divisions
export const GET_DIVISION_ ="Company/GetDivision"
export const ADD_DIVISION ="Company/AddDivision"
export const DELETE_DIVISION ="Company/DeleteDivision"
export const UPDATE_DIVISION ="Company/UpdateDivision"


//Company List
export const GET_COMPANY_LIST ="Company/GetCompanyList"


//Users
export const GET_ALL_USERS = "User/GetUsersList"
export const GET_ALL_AZURE_USERS = "User/GetUsers"
export const GET_USER_ROLE = "User/GetUserRole"
export const ADD_USER = "User/AddUser"
export const GET_USERS_BY_ID ='User/GetUsersByID'
export const UPDATE_USERS = "User/UpdateUser"
export const GENERATE_OTP = "User/GenerateUpdateOTP"
export const VALIDATE_OTP = "User/ValidateOTP"
export const RESET_PASSWORD = "User/ResetPassword"
export const FORGOT_PASSWORD = "User/ForgotUserPassword"

//Trade Finalized Contract
export const ADD_TRADE_LOADING_UPDATES = "Sales/AddTradeLoadingUpdate"
export const GET_TRADE_LOADING_UPDATES_BY_ID = "Sales/GetTradeLoadingUpdateByID"
export const UPDATE_TRADE_LOADING_UPDATES = "Sales/UpdateTradeLoadingUpdate"
export const ADD_TRADE_LC_CUSTOMER_DOCUMENT = "Sales/AddTradeLcCustomerDocument"
export const ADD_TRADE_LC_SUPPLIER_DOCUMENT = "Sales/AddTradeLcSupplierDocument"
export const GET_TRADE_LC_CUSTOMER_DOCUMENT_BY_ID = "Sales/GetTradeLcCustomerDocumentByID"
export const GET_TRADE_LC_SUPPLIER_DOCUMENT_BY_ID = "Sales/GetTradeLcSupplierDocumentByID"
export const UPDATE_TRADE_LC_CUSTOMER_DOCUMENT = "Sales/UpdateTradeLcCustomerDocument"
export const UPDATE_TRADE_LC_SUPPLIER_DOCUMENT = "Sales/UpdateTradeLcSupplierDocument"
export const ADD_TRADE_INSURANCE_DETAILS = "Sales/AddTradeInsuranceDetails"
export const UPDATE_TRADE_INSURANCE_DETAILS = "Sales/UpdateTradeInsuranceDetails"
export const GET_TRADE_INSURANCE_DETAILS_BY_ID = "Sales/GetTradeInsuranceDetailsByID"
export const ADD_SHIPMENT_ETA = "Sales/UpdateShipmentETA"
export const GET_TRADE_PROCESS_DETAILS ="Sales/GetTradeModifiedDetails"
export const CANCEL_TRADE = "Sales/CancelTrade"

//Organisation Settings

export const GET_ACCOUNT_TYPE = "Company/GetAccountType"
export const GET_PRIVILEGE = "User/GetPrivilege"
export const ADD_USER_ROLE = "User/AddUserRole"
export const UPDATE_USER_ROLE = "User/UpdateUserRole"
export const ADD_PRIVILEGE_MAPPING = "User/AddPrivilegeMappingList"
export const GET_COMPANY_BY_ID = "Company/GetCompanyByID"
export const GET_PRIVILLEGES_BY_ID = "User/GetPrivilegeMapping"
export const ADD_DEPARTMENTS = "User/AddDepartment"
export const GET_DEPARTMENTS = "User/GetDepartmentList"
export const UPDATE_DEPARTMENT = "User/UpdateDepartment"
export const REMOVE_DEPARTMENTS = "User/DeleteDepartment"
export const UPDATE_COMPANY = "Company/UpdateCompany"
export const ADD_COMPANY = "Company/AddCompany"
export const UPDATE_CUSTOMER_PAYMENT = "Sales/CustomerPaymentUpdate"
export const GET_CUSTOMER_PAYMENT_UPDATE = "Sales/GetCustomerPaymentUpdate"
export const UPDATE_SUPPLIER_PAYMENT = "Sales/SupplierPaymentUpdate"
export const GET_SUPPLIER_PAYMENT_UPDATE = "Sales/GetSupplierPaymentUpdate"
//export const GET_COMPANY_LIST = "Company/GetCompanyList"

// export const GET_ACCOUNT_TYPE = "Company/GetAccountType"

export const VERSION_BY_CONTRACT = "Contract/GetVersionsByContract"
export const CONTRACT_BY_VERSION = "Contract/GetContractHistoryByVersion"

//Meetings 
export const ADD_MEETING_CATEGORY = "Meeting/AddMeetingCategory"
export const GET_MEETINGS_LIST = "Meeting/GetMeeting"
export const GET_MEETING_CATEGORY = "Meeting/GetMeetingCategory"
export const GET_MEETING_USER = "Meeting/GetMeetingUser"
export const ADD_MEETING_USER ="Meeting/AddMeetingUser"
export const ADD_MEETING = "Meeting/AddMeeting"
export const GET_MEETING_BY_ID = "Meeting/GetMeetingByID"
export const GET_MEETING_LINKS_BY_ID = "Meeting/GetMeetingLinkDetailsByID" 
export const CANCEL_MEETING = "Meeting/CancelMeeting"
export const UPDATE_MEETING = "Meeting/UpdateMeeting"
export const GET_MEETING_TASK_COMMENTS = "Meeting/GetMeetingTaskComments"
export const ADD_MEETING_TASK_COMMENTS = "Meeting/AddMeetingTaskComments"
export const REMOVE_MEETING_LINK = "Meeting/CancelMeetingLink"
export const ADD_MEETING_LINK = "Meeting/AddMeetingLink"
export const ADD_MEETING_ATTACHMENTS = "Meeting/AddMeetingTaskAttachment"
export const DELETE_MEETING_ATTACHMENT = "Meeting/DeleteMeetingTaskAttachment"
export const GET_MY_TASK_LIST = "Meeting/GetMyTaskList"
export const GET_TEAM_TASK_LIST = "Meeting/GetMyTeamTaskList"
export const GET_OTHER_TASK_LIST = "Meeting/GetOtherTaskList"
export const CHANGE_TASK_STATUS = "Meeting/ChangeTaskStatus"
export const GET_MY_TOUR_LIST = "Meeting/GetMyTourList"
export const GET_MY_TEAM_TOUR_LIST = "Meeting/GetMyTeamTourList"
export const GET_OTHER_TOUR_LIST = "Meeting/GetOtherTourList"
export const GET_MEETING_FOR_FINANCE = "Meeting/GetMeetingForFinance"


//Dashboards
export const DB_CUSTOMER_COUNT = "Customer/DBCustomerCount"
export const DB_SUPPLIER_COUNT = "Supplier/DBSupplierCount"
export const DB_MEETING_COUNT = "Meeting/DBMeetingCount"
export const DB_TRADE_NEW_COUNT = "Sales/DBTradeNewCount"
export const DB_CLOSED_TRADE_DETAILS = "Sales/DBClosedTradeDetails"
export const DB_TRADE_SHIPMENT_DETAILS = "Sales/DBTradeShipmentDetails"
export const DB_MEETING_DETAIL = "Meeting/DBMeetingDetails"
export const DB_TRADE_DIV_TOTAL = "Sales/DBTradeDivTotal"
export const DB_TRADE_CASH_FLOW = "Sales/DBTradeCashFlow"
export const DB_TRADE_DETAILS_TREND = "Sales/DBTradeDetailsTrend"
export const DB_TRADE_PRODUCT_TREND = "Sales/DBTradeProductTrend"


// Reports
export const RPT_CUSTOMER = "Sales/RptCustomerData"
export const RPT_CUSTOMER_BY_ID = "Sales/RPTGetCustomerDetailsByID"
export const RPT_TRADE = "Sales/RptTradeData"
export const RPT_TRADE_BY_ID = "Sales/RPTGetTradeDetailsByID"

export const RPT_SHIPMENTCOMPLETED = "Sales/RPTGetShipmentCompleted"
export const RPT_SHIPMENT_BY_ID = "Sales/RPTGetShipmentTradeDetailsByID"

export const RPT_SHIPMENTINTRANSIT = "Sales/RPTGetShipmentInTransit"

export const RPT_SALESDETAILS = "Sales/RPTGetSalesData"
export const RPT_PURCHASEDETAILS = "Sales/RPTGetPurchaseData"
export const RPT_PERFOMANCE_DATA = "Sales/RPTPerformanceData"

//Invoices Manual

export const GET_CUSTOMER_PURCHASE_ORDER_NO = "Sales/GetCustomerPurchaseOrderNo"
export const UPLOAD_CUSTOMER_PURCHASE_ORDER_NO = "Sales/UploadCustomerPurchaseOrderNo"

//Financial Institution
export const GET_FINANCIAL_INSTITUTE_LIST = "FinancialInstitute/GetFinancialInstituteList"
export const ADD_FINANCIAL_INSTITUTE = "FinancialInstitute/AddFinancialInstituteList"
export const GET_BANK_LIST_BY_COUNTRY = "FinancialInstitute/GetBankList"
export const GET_FINANCIAL_INSTITUTE_CONTACT_TYPE_LIST ="FinancialInstitute/GetFinancialContactTypeList"
export const GET_FINANCIAL_INSTITUTE_BY_ID = "FinancialInstitute/GetFinancialInstitutionByID"
export const UPDATE_FINANCIAL_INSTITUTE = "FinancialInstitute/UpdateFinancialInstituteList"
export const GET_FINANCIAL_CONTACT_HISTORY = "FinancialInstitute/GetFinancialContactsHistoryByContactID"
export const GET_FINANCIAL_CONTATC_DETAILS = "FinancialInstitute/GetFinancialContactsDetails"