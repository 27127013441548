import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/sidebar'
import Navbar from "../../../components/navbar"
import { AddNewButton } from '../../../components/buttoncomponents'
import Informations_form_two from '../common/informations_form_two'
import Listing_two from '../common/listing_two'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import * as CONFIG from "../../../helpers/config"
import { useMsal } from "@azure/msal-react";

export default function Index() {
    const { instance } = useMsal();
    useEffect(() => {
        if (localStorage.getItem('AuthToken') == null) {
            console.log(localStorage.getItem('AuthToken'))
            window.location.href = "/"
        }
        else {
            console.log('inside')
        }
    })
    const [isFirst, setIsFirst] = useState(true);
    const [isAddQuality, setIsAddQuality] = useState(false);
    const [productQuality, setProductQuality] = useState([]);
    const [length, setLength] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState([]);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState('');
    const addQuality = () => {
        setIsAddQuality(!isAddQuality);
    }

    useEffect(() => {
        if (isFirst) {
            getlist()
        }

        setIsFirst(false)
    })

    const getlist = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_QUALITY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setProductQuality(res.data.result);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }

    const saveCategory = (data) => {
        console.log(data)
        const json_list = isEdit ? {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "Modified_User": CONFIG.LoginID,
            "productQualityParameterID": data.id,
            "productQualityParameterName": data.data1,
            'productQualityParameterUnit': "%"
        }
            :
            {
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "productQualityParameterName": data.data1,
                'productQualityParameterUnit': "%",
                "created_User": CONFIG.LoginID
            }
        if (!isEdit) {
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.ADD_PRODUCT_QUALITY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode == '303002') {
                        setError(true)
                        setErrorData('Quality parameter already exists');
                    }
                    else if (res.data.responseCode == '303001') {
                        setError(true)
                        setErrorData('Quality parameter invalid. Please try again!');
                    }
                    else {
                        setError(false)
                    }
                    setIsEdit(false)
                    getlist()
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
        else {
            console.log('inside');
            axios.post(CONFIG.TMS_SERVER_URL + CONFIG.EDIT_PRODUCT_QUALITY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    if (res.data.responseCode == '303002') {
                        setError(true)
                        setErrorData('Quality parameter already exists');
                    }
                    else if (res.data.responseCode == '303001') {
                        setError(true)
                        setErrorData('Quality parameter invalid. Please try again!');
                    }
                    else {
                        setError(false)
                    }
                    setIsEdit(false)
                    getlist()
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.clear();
                        instance.logoutRedirect({
                          postLogoutRedirectUri: "/",
                        });
                    }
                })  
        }
    }

    const edit = (e) => {
        const id = e.target.id;
        let data = productQuality.filter(x => x.productQualityParameterID == id);
        setSelectedData(data);
        setIsEdit(true)
        addQuality();
    }
    const deletedata = (e) => {
        const id = parseInt(e.target.id);
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DELETE_PRODUCT_QUALITY, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                getlist()
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>

            <Sidebar id='4.6' isOpenid={4} />
            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/pageicons/productsicon.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>QUALITY PARAMETERS</span>
                    </div>
                </div>
                <div className='inner-page-content'>
                    <div className=' br-8 py-3'>
                        <Card className='p-2-rem col-md-7'>
                            <div className='d-flex justify-content-end'>
                                <AddNewButton value='Add New Quality Parameters' class='bg-white' clickEvent={addQuality} />
                            </div>
                            {isAddQuality &&
                                <Informations_form_two
                                    isOpen={isAddQuality}
                                    title={isEdit ? 'EDIT QUALITY PARAMETER' : 'ADD NEW QUALITY PARAMETER'}
                                    buttontext={isEdit ? 'Edit Quality Parameter' : 'Add Quality Paramter'}
                                    inputLabel1='Quality Parameter*'
                                    inputLabel2='Unit'
                                    onSave={saveCategory}
                                    toggle={addQuality}
                                    id={isEdit ? selectedData[0].productQualityParameterID : ''}
                                    name={isEdit ? selectedData[0].productQualityParameterName : ''}
                                    unit={isEdit ? selectedData[0].productQualityParameterUnit : ''}
                                    isEdit={isEdit}
                                />
                            }
                            {error &&
                                <div className='input_error'>{errorData}</div>
                            }
                            <table className="table pcustom table-text mt-1-rem">
                                <thead>
                                    <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                        <th>Quality Parameter</th>
                                        <th>Quality Unit</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productQuality.map((cat, k) => (
                                        <Listing_two key={k} id={cat.productQualityParameterID} name={cat.productQualityParameterName} unit={cat.productQualityParameterUnit} edit={edit} delete={deletedata} />
                                    ))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </div>
            </div>


        </div>
    )
}
