import React, { useState } from 'react'
import {Row, Col, ModalFooter} from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as FormFields from "../../../components/formcomponents"
import { BlueButton, BlueBorderButton, CancelButton } from '../../../components/buttoncomponents'
import { useFormik } from 'formik'

export default function Informations_form_two(props) {
    const [isModalOpen, setIsModalOpen] = useState(props.isOpen)
    const [error, seterror] = useState(false)
    const toggle = ()=>{
        setIsModalOpen(!isModalOpen)
        setTimeout(() => {
            props.toggle();
        }, 300);
    }
    const formik = useFormik(
        {
            initialValues:
            props.isEdit?
            {
                id : props.id,
                data1: props.name,
                data2: props.unit
            }
            :
            {
                data1 : '',
                data2: ''
            },
            validate : values =>{
                let errors ={};
                seterror(false)
                if(!formik.values.data1){
                    errors.data1 = "Please enter a value!"
                }
                return errors;
            }
        }
    )
    
    const saveData = ()=>{
        if(formik.values.data1 =="" ){
            seterror(true)
        }
        else{
            if(Object.keys(formik.errors).length == 0){
                props.onSave(formik.values)
                toggle()
            }
        }
    }
    return (
        <Modal isOpen={isModalOpen} toggle={toggle} className='mt-10-rem property_info_modal modal_35 mt-5 '>
            <ModalHeader toggle={toggle}>
                <div className='font-16 font-weight-600 color-707895'>{props.title}</div>
            </ModalHeader>
            <ModalBody>
                <Row className='d-flex plr-2-rem'>
                    <FormFields.InputField
                        md={6}
                        className='mb-3'
                        name='data1'
                        label={props.inputLabel1}
                        val ={formik.values.data1}
                        onChange = {formik.handleChange}
                        onBlur = {formik.handleBlur}
                        errors={formik.touched.data1?formik.errors.data1:''}
                    />
                    <FormFields.InputField
                        md={6}
                        name='data2'
                        label={props.inputLabel2}
                        val ='%'
                        onChange = {formik.handleChange}
                    />
                </Row>
                {error &&
                    <div className='input_error'>Required fields cannot be empty!</div>
                }
            </ModalBody>
            <ModalFooter className='d-flex justify-content-start'>
                <CancelButton value='Cancel' clickEvent={toggle}/>
                <BlueButton value={props.buttontext} clickEvent={saveData}/>
            </ModalFooter>
        </Modal>
    )
}
