import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueBorderButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";
import { ProductGroup } from "../../../../../../helpers/API/Api"
import { AddUom, AddGroup } from "../../../../../../helpers/API/ProductApi"
import * as CONFIG from "../../../../../../helpers/config"
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

export const ProductByGroup = (props) => {
    const { instance } = useMsal();
    const [productList, setProductList] = useState([])
    const [isProductSet, setIsProduct] = useState(false)

    console.log(props)
    useState(() => {
        console.log(isProductSet)
    }, [isProductSet])
    useEffect(() => {
        setIsProduct(false)
        console.log(props)
        console.log(props.groupId, 'asas')
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.groupId //groupId
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_BY_GROUPID, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setProductList(res.data.result)
                console.log(res.data.result, 'product group')
                setIsProduct(true)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }, [props])

    return (
        <>
            {productList.map((list, k) => {
                return (
                    <option value={list.productID} selected={list.productID == props.val}>{list.productName}</option>
                )
            })
            }
        </>
    )
}
function FormComponent(props) {
    const { instance } = useMsal();
    const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
    const [groupId, setGroupId] = useState(0)
    const [productcode, setproductcode] = useState('');
    const [error, seterror] = useState(false)

    useEffect(() => {
        console.log(productcode)
    }, [])
    const formik = useFormik({
        initialValues:
        {
            productName: props.isEdit ? props.productName : '',
            productCode: props.isEdit ? props.productCode : '',
            productGroupValue: props.isEdit ? props.productGroupValue : '',
            productGroupID: props.isEdit ? props.productGroupID : 0,
            productGradeID: 1,
            productUOMID: props.isEdit ? props.productUOMID : 1,
            productUOMValue: props.isEdit ? props.productUOMValue : "",
            productUOMName: props.isEdit ? props.productUOMName : '',
            productID: props.isEdit ? props.productName : 0,
        },
        validate: values => {
            seterror(false)
            let errors = {}
            if (!values.productID) {
                errors.productID = "Please select a product"
            }
            getProductCode(values.productID)
            if (!values.productQuantity) {
                errors.productQuantity = "Please enter a product quantity"
            }
            if (!values.productUOMID) {
                errors.productUOMID = "Please select a value"
            }
            return errors
        }
    })

    const getProductCode = (id) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(id)
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_PRODUCT_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                let ress = res.data.result;
                console.log(res.data.result);
                formik.values.productCode = ress[0].productCode;
                formik.values.productName = ress[0].productName;
                console.log(formik.values)
                setTimeout(() => {
                    setproductcode(ress[0].productCode);
                }, 500);
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        formik.values.productCode = productcode
    }

    useEffect(() => {
        console.log(formik.values.productID, 'product ID')
    }, [formik])

    const handleGroup = (e) => {
        formik.values.productGroupID = e.target.value;
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.productGroupValue = e.nativeEvent.target[index].text;
        const groupID = parseInt(e.target.value)
        setGroupId(groupID)
    }

    const handleUom = (e) => {
        seterror(false)
        const uomID = parseInt(e.target.value);
        var index = e.nativeEvent.target.selectedIndex;
        formik.values.productUOMID = uomID
        formik.values.productUOMName = e.nativeEvent.target[index].text;
        formik.values.productUOMValue = e.nativeEvent.target[index].text;
    }

    //const saveProduct = ()=>{
    //    console.log(formik.values, 'test')
    //    if(formik.values.productID != ''){
    //        formik.values.productUOMID = parseInt(formik.values.productUOMID)
    //        formik.values.productID = parseFloat(formik.values.productID)
    //        props.saveProduct(formik.values)
    //    }
    //}
    const saveProduct = () => {
        console.log(formik.values, 'test')

        if (formik.values.productID != "" && formik.values.productUOMID != "" && formik.values.productGroupID != "") {
            if (formik.values.productID != '') {
                formik.values.productUOMID = parseInt(formik.values.productUOMID)
                formik.values.productID = parseFloat(formik.values.productID)
               
                props.saveProduct(formik.values)
            }
        }
        else {
            seterror(true)
        }
       
    }
   
    return (
        <>
            <Form>
                <div className='d-flex'>
                    <FormFields.InputSelect
                        name='productGroupID'
                        label='Product Group*'
                        classname='mr-20'
                        type='text'
                        optionField={<AddGroup selectedDivision={props.selectedDivision} val={props.productGroupID} />}
                        val={formik.values.productGroupID}
                        onChange={handleGroup}
                        onBlur={handleGroup}
                    />
                    <FormFields.InputSelect
                        name='productID'
                        label='Product Name*'
                        classname='mr-20'
                        type='text'
                        optionField={<ProductByGroup groupId={groupId} val={formik.values.productID} />}
                        val={formik.values.productID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <FormFields.InputField
                        name='productCode'
                        label='Product Code*'
                        classname='mr-20'
                        type='text'
                        val={formik.values.productCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                    />
                    <FormFields.InputSelect
                        name='productUOMID'
                        label='Product UOM'
                        classname='mr-20'
                        type='text'
                        val={formik.values.productUOMID}
                        optionField={<AddUom />}
                        onChange={handleUom}
                        onBlur={handleUom}
                    />
                    
                    <Col className='d-flex align-items-start'>
                        <BlueBorderButton value='Save' clickEvent={saveProduct} disabled = {error}/>
                    </Col>
                </div>
                <Row>
                        {error &&
                            <span className='input_error'>Required fields cannot be empty!</span>
                        }
                    </Row>
            </Form>
        </>

    )
}

export default FormComponent