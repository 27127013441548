import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Col, Row, TabPane, ModalFooter, Form } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Steps } from 'antd';
import { useFormik } from 'formik'
import Moment from "moment"
import { Dropdown, Menu, Space } from 'antd';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as FormFields from "../../formcomponents"
import * as ButtonComponent from "../../buttoncomponents"
import * as CONFIG from "../../../helpers/config"
import Sidebar from '../../sidebar/sidebar';
import Navbar from '../../navbar';
import { CompanyType, CountryList, PaymentTypeList, CustomerCategoryList, IncotermsList, SofList, StateList } from "../../../helpers/API/Api"
import BankAccountModal from './Components/BankAccountModal';
import RoleCreationModal from './Components/RoleCreationModal';
import ListBankAccount from './Components/ListBankAccount'
import ListDepartments from './Components/ListDepartments';
import { set } from 'date-fns';
import DepartmentModal from './Components/DepartmentModal';
import { useMsal } from "@azure/msal-react";

function Index(props) {

    const { instance } = useMsal();
    const [issueDate, setIssueDate] = useState(false);
    const [expiryDate, setExpiryDate] = useState(false);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [cntID, setCntID] = useState(0);
    const [countryerror, setCountryError] = useState(false)
    const [stateID, setStateID] = useState(0);
    const [stateerror, setStateError] = useState(false)
    const [companyLogoName, setCompanyLogoName] = useState('')
    const [companyLogoFile, setCompanyLogoFile] = useState('')

    const [licencedoc, setLicenceDoc] = useState('')
    const [licencedocList, setLicenceDocList] = useState([])
    const [licencedocFile, setLicenceDocFile] = useState('')
    const [licencedocFileList, setLicenceDocFileList] = useState([])

    const [trndocument, setTrndocument] = useState('')
    const [trndocumentList, setTrndocumentList] = useState()
    const [trndocumentFile, setTrndocumentFile] = useState('')
    const [trndocumentFileList, setTrndocumentFileList] = useState()

    const [key, setkey] = useState([])
    const [doc1, setdoc1] = useState(false)
    const [companyBankDetails, setCompanyBankDetails] = useState([])
    const [isBankMet, setIsBankMet] = useState(false);
    const [bankAccountModal, setBankAccountModal] = useState(false)
    const [roleCreationModal, setRoleCreationModal] = useState(false)
    const [departmentModal, setDepartmentModal] = useState(false)
    const [bankAccountList, setBankAccountList] = useState([])
    const [rolesList, setRolesList] = useState([])
    const [bankForEdit, setBankForEdit] = useState()
    const [edit, setEdit] = useState(false)
    const [editDepartment, setEditDepartment] = useState(false)
    const [editCompany, setEditCompany] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const [departmentList, setDepartmentList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [deleteBank, setDeleteBank] = useState([])
    const [error, setError] = useState(false)
    const [compcode, setCompcode] = useState('')

    const location = useLocation()
    let navigate = useNavigate();
    console.log(location)
    //console.log(location)
    useEffect(() => {
        if (location.state != null) {
            console.log(location.state.edit)
            setError(false)
            const company = location.state.data.company
            setEditCompany(location.state.data.company)
            const { tab } = location.state
            setBankAccountList(location.state.data.company.companyBankDetails)
            setCompanyLogoName(location.state.data.company.companyLogoName)
            let names = licencedoc
            let objectTaxDoc = {}
            let listTaxDoc = []
            let objectTradeDoc = {}
            let listTradeDoc = []

            console.log(location.state.data.company.companyTradedocumentDetail, 'trddoc')
            console.log(location.state.data.company.companyTaxdocumentDetail, 'taxdoc')
            location.state.data.company.companyTradedocumentDetail.map((file, k) => {
                names += file.name + ', '
                objectTradeDoc = {
                    'name': file.name,
                    'base64': file.base64
                }
                listTradeDoc.push(objectTradeDoc)
            })
            setLicenceDocList(listTradeDoc)
            setLicenceDocFileList(listTradeDoc)
            setLicenceDoc(names)
            let namesTRN = trndocument
            location.state.data.company.companyTaxdocumentDetail.map((file, k) => {
                console.log(file)
                namesTRN += file.name + ', '
                objectTaxDoc = {
                    'name': file.name,
                    'base64': file.base64
                }
                listTaxDoc.push(objectTaxDoc)
            })
            setTrndocumentList(listTaxDoc)
            setTrndocumentFileList(listTaxDoc)
            setTrndocument(namesTRN)
            //setLicenceDoc(location.state.data.company.companyTradedocumentDetail.name)
            // setTrndocument(location.state.data.company.companyTaxdocumentDetail.name)
        }
    }, [])

    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "created_User": CONFIG.LoginID,
            "id": CONFIG.AccountSubscriptionID, // SubscriptionID
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_COMPANY_CODE, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                setCompcode(res.data.result.companyCode)
            })
    }, [])

    useEffect(() => {
        if (location.state != null) {
            if (location.state.edit == true && Object.keys(formik.errors).length == 0) {
                setError(false)
            }
        }

    }, [error])
    useEffect(() => {
        let final_json = {
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_USER_ROLE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }, [])
    useEffect(() => {
        getDepartmentList()
    }, [])

    const getDepartmentList = () => {
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID

        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DEPARTMENTS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setDepartmentList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    useEffect(() => {
        console.log(bankAccountList)
    }, [bankAccountList])

    useEffect(() => {
        handleError()
    }, [])

    const formik = useFormik({
        initialValues:
        {

            companyName: location.state != null ? location.state.data.company.companyName : "",
            companyTelephone: location.state != null ? location.state.data.company.companyTelephone : "",
            contactEmail: location.state != null ? location.state.data.company.contactEmail : "",
            cityName: location.state != null ? location.state.data.company.cityName : "",
            companyStreet: location.state != null ? location.state.data.company.companyStreet : "",
            companyBuilding: location.state != null ? location.state.data.company.companyBuilding : "",
            companyPoBox: location.state != null ? location.state.data.company.companyPoBox : "",
            statename: location.state != null ? location.state.data.statename : "",
            companyLogo: location.state != null ? location.state.data.company.companyLogo : "",
            companyLogoName: location.state != null ? location.state.data.company.companyLogoName : "",
            companyUnit: location.state != null ? location.state.data.company.companyUnit : "",
            tradeLicenseNo: location.state != null ? location.state.data.company.tradeLicenseNo : "",
            tradeName: location.state != null ? location.state.data.company.tradeName : "",
            issueDate: location.state != null ? location.state.data.company.issueDate : "",
            expiryDate: location.state != null ? location.state.data.company.expiryDate : "",
            trnNo: location.state != null ? location.state.data.company.trnNo : "",
            // stateID: location.state != null ? location.state.data.company.stateID : "",
            countryID: location.state != null ? location.state.data.company.countryID : "",
            is_Parent: true,
            companyTradedocumentDetail: location.state != null ? location.state.data.company.companyTradedocumentDetail : [],
            companyTaxdocumentDetail: location.state != null ? location.state.data.company.companyTaxdocumentDetail : [],


        },
        validate: values => {
            let errors = {};
            const letters = /^[A-Za-z ]+$/;

            const cnic = /^[0-9--]+$/;

            const phone = /^[0-9]+$/;

            const symbols = /[-!$%^&*()_+|~=`{}\[\]@:";'<>?,.\/]/;


            if (!values.companyName) {
                errors.companyName = "Please enter company name"

            }
            if (!values.companyTelephone) {
                errors.companyTelephone = "Please enter Contact Number"
            } else if (!phone.test(values.companyTelephone) && values.companyTelephone) {
                errors.companyTelephone = 'Please enter a valid Contact Number'
            }
            if (!values.companyUnit) {
                errors.companyUnit = "Please enter Unit No."
            }

            if (!values.companyBuilding) {
                errors.companyBuilding = "Please enter Building Name"
            }
            if (!values.companyStreet) {
                errors.companyStreet = "Please enter Street Name"
            }
            if (!values.cityName) {
                errors.cityName = "Please enter City Name"
            }

            if (!values.countryID) {
                errors.countryID = "Please select a Country"
            }
            if (!values.companyPoBox) {
                errors.companyPoBox = "Please enter a Post Box Number"
            }

            if (!values.tradeName) {
                errors.tradeName = "Please enter a Trade Name"
            }

            if (!values.tradeLicenseNo) {
                errors.tradeLicenseNo = "Please enter a Trade License Number"
            }

            if (!values.trnNo) {
                errors.trnNo = "Please enter a Trade Number"
            }


            else if (!values.contactEmail) {
                errors.contactEmail = 'Invalid email address';
            }

            setError(true);
            handleError()
            return errors;
        }

    })

    const handleError = () => {

        if ((Object.keys(formik.errors).length == 0)) {
            if (error == true) {
                console.log(formik.errors)
                console.log('error')
                setError(true)
                //props.setErrors()
            }
            console.log('error checking')
            if (formik.initialValues == formik.values) {
                console.log(formik.errors)
                console.log('error')
                setError(true)
                //props.setErrors()
            }
            else {
                setError(false)
                console.log(formik.errors)
                console.log('no error first condition')
            }

        }
        else {
            setError(true)
            console.log(formik.errors)
            console.log('error')

        }
    }

    const onCountrySelect = (e) => {
        setIsCountryChanged(false)
        const value = e.currentTarget.value;
        if (value != 0) {
            setCntID(parseInt(value))
            formik.values.country_val = parseInt(value)
            setCountryError(false)
            setTimeout(() => {
                setIsCountryChanged(true)
            }, 800);
        }
        else {
            setCountryError(true)
        }
        generalcountrySelected(value)
    }
    const onStateChange = (e) => {
        const value = e.currentTarget.value;
        if (value != 0) {
            setStateID(value)
            formik.values.state_val = value
            setStateError(false)
        }
        else {
            setStateError(true)
        }
        generalstateSelected(value)
    }

    const generalstateSelected = (value) => {
        if (value != 0) {
            formik.values.stateID = value;
        }
    }
    const generalcountrySelected = (value) => {
        if (value != 0) {
            formik.values.countryID = value
        }
    }
    useEffect(() => {
        console.log(issueDate)
    }, [issueDate])
    const handleIssueDate = (date) => {
        console.log('click')
        if (date == null) {
            formik.values.issueDate = false
        }
        else {
            formik.values.issueDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        }
        setIssueDate(date)
    }
    useEffect(() => {
        console.log(expiryDate)
    }, [expiryDate])

    const handleExpiryDate = (date) => {
        if (date == null) {
            formik.values.expiryDate = false
        }
        else {
            formik.values.expiryDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')

        }
        setExpiryDate(date)

    }

    const handleCompanyLogo = (file) => {
        console.log(file)
        formik.values.companyLogo = file.base64;
        formik.values.companyLogoName = file.name;
        //formik.values.incDocument = file.base64.name;
        console.log('file.base64')
        setCompanyLogoFile(file.base64)
        setCompanyLogoName(file.name)
        console.log(file.name)
    }

    const handleAddBankAccount = (data) => {
        let list = [...companyBankDetails]
        list.push(data);
        setCompanyBankDetails(list)
        setIsBankMet(true)
    }

    const handleCompanyTradeDocument = (file) => {
        console.log(file)
        let ke = key
        let names = licencedoc
        let list = {
            "companyID": parseInt(CONFIG.CompanyID),
            "base64": file.base64,
            "name": file.name,
            "created_User": CONFIG.LoginID
        }
        setLicenceDoc(file.name)
        console.log(names)
        setkey(ke)
        let l = []
        l.push(list)
        formik.values.companyTradedocumentDetail = l
        console.log(formik.values.companyTradedocumentDetail)
        setdoc1(!doc1)
        setLicenceDocList(l)
        setLicenceDocFileList(l)
    }


    const handleTaxDocument = (file) => {
        let ke = key
        let names = trndocument
        let list = {
            "companyID": parseInt(CONFIG.CompanyID),
            "base64": file.base64,
            "name": file.name,
            "created_User": CONFIG.LoginID
        }
        setTrndocument(file.name)
        console.log(names)
        setkey(ke)
        let l = []
        l.push(list)
        formik.values.companyTaxdocumentDetail = l
        console.log(formik.values.companyTaxdocumentDetail)
        setdoc1(!doc1)
        setTrndocumentFileList(l)
        setTrndocumentList(l)
    }

    const handleBankAccountModal = () => {
        setEdit(false)
        setBankAccountModal(true)
    }
    const handleDepartmentModal = () => {
        setEditDepartment(false)
        setDepartmentModal(true)
    }

    const handleRoleCreationModal = () => {
        setSelectedRole('')
        setRoleCreationModal(true)
    }

    const handleBankAccountList = (data) => {
        if (edit == true) {
            console.log('as')
            console.log(key)
            let list = [...bankAccountList]
            for (let i = 0; i < list.length; i++) {
                if (data.key == list[i].key) {
                    console.log('if')
                    list[i] = data
                }

            }
            setBankAccountList(list)
            setEdit(false)
        }
        else {
            let list = [...bankAccountList]
            let key = 1
            list.push(data)
            list.map((doc, k) => {
                list[k].key = key
                key++

            })
            setkey(key)

            console.log(list)
            setBankAccountList(list)
        }
    }



    const close = () => {
        setBankAccountModal(false)
    }

    const closeRoleModal = () => {
        setRoleCreationModal(false)
    }

    const handleEdit = (e) => {
        console.log(e.target.id)
        for (let i = 0; i < bankAccountList.length; i++) {
            if (bankAccountList[i].key == parseInt(e.target.id)) {
                setBankForEdit(bankAccountList[i])
            }
        }
        setEdit(true)
        setBankAccountModal(true)
    }

    const handleActive = (data) => {
        console.log(data)
        console.log(bankAccountList)
        console.log(data)

        let remainingaccounts = bankAccountList.filter(x => x.key != data.key)
        remainingaccounts.push(data)
        setBankAccountList(remainingaccounts)
    }

    const handleInActive = (data) => {
        console.log(data)
        console.log(bankAccountList)
        console.log(data)

        let remainingaccounts = bankAccountList.filter(x => x.key != data.key)
        remainingaccounts.push(data)
        setBankAccountList(remainingaccounts)
    }

    const handleSaveOrganisationDetails = () => {

        console.log(formik.values)
        formik.values.countryID = parseInt(formik.values.countryID)
        let finalList = []
        if (deleteBank.length != 0) {
            finalList = [...deleteBank, ...bankAccountList]
        }
        else {
            finalList = [...bankAccountList]
        }
        if (location.state != null) {
            let final_json = {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "pmsUserID": CONFIG.LoginID,
                ...formik.values,
                "companyBankDetails": finalList,
                "companyID": parseInt(location.state.data.company.companyID),
                "companyCode" : compcode
            }
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.UPDATE_COMPANY, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result, 'roles')
                    navigate('/organisation-settings')
                    //setDepartmentList(res.data.result)
                    //setWorkFlowList(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            let final_json = {
                "pmsInstanceID": CONFIG.PMSInstanceID,
                "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID,
                "solutionID": CONFIG.SolutionID,
                "pmsUserID": CONFIG.LoginID,
                ...formik.values,
                "companyBankDetails": finalList,
                "companyCode" : compcode
            }
            console.log(final_json)
            axios.post(CONFIG.PRO_SERVER_URL + CONFIG.ADD_COMPANY, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result, 'roles')
                    navigate('/organisation-settings')
                    //setDepartmentList(res.data.result)
                    //setWorkFlowList(res.data.result)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }

        //console.log(final_json)
    }

    const handlePermissionsClick = (e, data) => {
        setSelectedRole(data)
        setRoleCreationModal(true)
    }

    const handleEditDepartments = (data) => {
        setSelectedDepartment(data)
        setEditDepartment(true)
        setDepartmentModal(true)
    }

    const closeDepartment = () => {

        setDepartmentModal(false)
    }

    const handleRemove = (e, data) => {
        console.log(data)
        let dlisst = [...deleteBank]
        let deletelist = {
            "bankDetailID": data.key != null ? data.key : data.bankDetailID,
            "companyID": parseInt(CONFIG.CompanyID),
            "accountName": data.accountName,
            "bankName": data.bankName,
            "accountNo": data.accountNo,
            "bankAddress": data.bankAddress,
            "swiftCode": data.swiftCode,
            "ibanNo": data.ibanNo,
            "bankCountryID": data.bankCountryID,
            "bankStateID": data.bankStateID,
            "bankCityName": data.bankCityName,
            "bankStatus": data.bankStatus,
            "AccountTypeID": 1,
            "CurrencyID": 1,
            "Is_Active": false,
            "Is_Deleted": true
        }
        dlisst.push(deletelist)

        console.log(dlisst)
        setDeleteBank(dlisst)
        console.log(e.target.id)
        let list = [...bankAccountList]
        console.log(e)
        for (let i = 0; i < list.length; i++) {
            if (list[i].key == e.target.id) {
                list.splice(i, 1);
            }
        }
        setBankAccountList(list)
    }

    const reloadList = () => {
        let final_json = {
            "pmsInstanceID": CONFIG.PMSInstanceID,
            "pmsAccountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "solutionID": CONFIG.SolutionID,
        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_USER_ROLE, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setRolesList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const reloadDepartment = () => {
        let final_json = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID

        }
        axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_DEPARTMENTS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result, 'roles')
                setDepartmentList(res.data.result)
                //setWorkFlowList(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    useEffect(() => {

    }, [companyLogoFile])

    const handleSaveDepartment = () => {
        getDepartmentList()
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <Sidebar />

            <div className='width-100'>
                <Navbar />
                <div className='d-flex inner-page-header justify-content-between'>
                    <div className='d-flex'>
                        <img src='assets/images/sidebaricons/Settings.svg' className='img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-end pl-10'>Organisation Settings</span>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='add-edit-wrapper mt-4'>
                        <Card>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Company Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='companyName'
                                                label='Company Name'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.companyName}
                                                errors={formik.touched.companyName ? formik.errors.companyName : ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='companyTelephone'
                                                label='Contact No'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.companyTelephone}
                                                errors={formik.touched.companyTelephone ? formik.errors.companyTelephone : ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='contactEmail'
                                                label='Email'
                                                classname='pt-1-rem'
                                                type='text'
                                                val={formik.values.contactEmail}
                                                errors={formik.touched.contactEmail ? formik.errors.contactEmail : ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />
                                            <Col md={3} className='pt-1-rem align-items-center'>
                                                <ButtonComponent.UploadButton value='Thumbnail' onUploadHandle={handleCompanyLogo} filename={companyLogoName} document={companyLogoFile} />
                                            </Col>
                                        </>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card className='my-3'>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Address Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='companyUnit'
                                                label='Unit No.'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.companyUnit}
                                                errors={formik.touched.companyUnit ? formik.errors.companyUnit : ''}
                                            //isabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='companyBuilding'
                                                label='Building'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.companyBuilding}
                                                errors={formik.touched.companyBuilding ? formik.errors.companyBuilding : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='companyStreet'
                                                label='Street'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.companyStreet}
                                                errors={formik.touched.companyStreet ? formik.errors.companyStreet : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='cityName'
                                                label='City'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.cityName}
                                                errors={formik.touched.cityName ? formik.errors.cityName : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <FormFields.InputSelect
                                                md={3}
                                                name='countryID'
                                                label='Country'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={onCountrySelect}
                                                onBlur={onCountrySelect}
                                                optionField={location.state != null ?
                                                    <CountryList
                                                        cntid={location.state.data.company.countryID}
                                                    />
                                                    :
                                                    <CountryList />}
                                                val={formik.values.countryID}
                                                errors={formik.touched.countryID ? formik.errors.countryID : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            {/*{isCountryChanged ?*/}
                                            {/*    <FormFields.InputSelect*/}
                                            {/*        md={3}*/}
                                            {/*        name='stateID'*/}
                                            {/*        label='State'*/}
                                            {/*        classname='pt-1-rem'*/}
                                            {/*        type='text'*/}
                                            {/*        optionField={<StateList countryID={cntID} countryChange = {'country change'}/>}*/}
                                            {/*        val={formik.values.stateID}*/}
                                            {/*        onChange={onStateChange}*/}
                                            {/*        onBlur={onStateChange}*/}
                                            {/*        //disabled={props.isDisabled}*/}
                                            {/*    />*/}
                                            {/*    :*/}
                                            {/*        <FormFields.InputSelect*/}
                                            {/*            md={3}*/}
                                            {/*            name='stateID'*/}
                                            {/*            label='State'*/}
                                            {/*            classname='pt-1-rem'*/}
                                            {/*            type='text'*/}
                                            {/*            optionField={location.state != null ?*/}
                                            {/*                <StateList */}
                                            {/*                    countryID={formik.values.countryID} countryChange = {'no country change'}*/}
                                            {/*                /> */}

                                            {/*                :*/}
                                            {/*                ''}*/}
                                            {/*            //val={formik.values.stateID}*/}
                                            {/*            onChange={onStateChange}*/}
                                            {/*            onBlur={onStateChange}*/}
                                            {/*            //disabled={props.isDisabled}*/}
                                            {/*        />*/}
                                            {/*}*/}

                                            <FormFields.InputField
                                                md={3}
                                                name='statename'
                                                label='State'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.statename}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='companyPoBox'
                                                label='Post Box No.'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.companyPoBox}
                                                errors={formik.touched.companyPoBox ? formik.errors.companyPoBox : ''}
                                                disabled={props.isDisabled}
                                            />

                                        </>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card className='my-3'>
                            <CardBody>
                                <Form >
                                    <Row>
                                        <div className='form-title font-16 font-weight-500 mt-1-rem color-4F5466'>
                                            Corporate Details
                                        </div>
                                        <>
                                            <FormFields.InputField
                                                md={3}
                                                name='tradeName'
                                                label='Trade Name*'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.tradeName}
                                                errors={formik.touched.tradeName ? formik.errors.tradeName : ''}
                                            //isabled={props.isDisabled}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='tradeLicenseNo'
                                                label='License No*'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.tradeLicenseNo}
                                                errors={formik.touched.tradeLicenseNo ? formik.errors.tradeLicenseNo : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <Col md={3} className='pt-1-rem align-items-center'>
                                                <ButtonComponent.UploadButton value='License*' onUploadHandle={handleCompanyTradeDocument} filename={licencedoc} list={licencedocList} />
                                            </Col>

                                            <FormFields.DatePick
                                                md={3}
                                                label='Issued Date* (MM/DD/YYYY)'
                                                name='issueDate'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleIssueDate}
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.issueDate}
                                            />
                                            <FormFields.DatePick
                                                md={3}
                                                label='Expiry Date* (MM/DD/YYYY)'
                                                name='expiryDate'
                                                classname='pt-1-rem iconDatePicker'
                                                type='date'
                                                handleCompletionDate={handleExpiryDate}
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.expiryDate}
                                            />
                                            <FormFields.InputField
                                                md={3}
                                                name='trnNo'
                                                label='TRN No*'
                                                classname='pt-1-rem'
                                                type='text'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                val={formik.values.trnNo}
                                                errors={formik.touched.trnNo ? formik.errors.trnNo : ''}
                                            //disabled={props.isDisabled}
                                            />
                                            <Col md={3} className='pt-1-rem align-items-center'>
                                                <ButtonComponent.UploadButton value='TRN Document*' onUploadHandle={handleTaxDocument} filename={trndocument} list={trndocumentList} />
                                            </Col>
                                        </>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Tabs >
                            <Card className='tabs-wrapper'>
                                <TabList>
                                    <Tab>Bank Accounts</Tab>
                                    <Tab>Roles</Tab>
                                    <Tab>Departments</Tab>
                                </TabList>
                            </Card>
                            <TabPanel className='mt-1-rem'>
                                <Card className='bg-white'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <img src='assets/images/bankIcon.svg' alt='bank icon' />
                                                </div>
                                                <div className='font-weight-600 font-16 ms-3 pt-1'>
                                                    Bank Accounts
                                                </div>
                                            </div>
                                            <div>
                                                <ButtonComponent.BlueBorderButton value='Add New Bank Account(s)' clickEvent={handleBankAccountModal} />
                                            </div>

                                        </div>
                                        {bankAccountList != null && bankAccountList.length > 0 ?

                                            <div class="col-lg-12 p-4">
                                                <div class="table-responsive-sm ">
                                                    <table className="table tCustom table-text">
                                                        <thead className='p-1-rem'>
                                                            <tr className='text-uppercase'>
                                                                <th>Account Name</th>
                                                                <th>Account No.</th>
                                                                <th>Bank Name</th>
                                                                <th>IBAN No.</th>
                                                                <th>Account Type</th>
                                                                <th className='text-center'>Status</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bankAccountList.map((values, k) => (
                                                                <ListBankAccount values={values} handleEdit={handleEdit} handleActive={handleActive} handleInActive={handleInActive} handleRemove={handleRemove} />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>



                                            :
                                            <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                <div>
                                                    <div className='font-weight-600 color-243448'>No Bank Accounts Created</div>
                                                </div>
                                            </div>}
                                    </Card.Body>
                                </Card>
                                <Card className='my-2'>
                                    <CardBody>
                                        <span className='pl-10'><ButtonComponent.BlueButton value='Save' clickEvent={handleSaveOrganisationDetails} disabled={error == true ? true : false} /></span>
                                    </CardBody>
                                </Card>
                            </TabPanel>
                            <TabPanel className='mt-1-rem'>
                                <Card className='bg-white'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <img src='assets/images/userRoles.svg' alt='bank icon' />
                                                </div>
                                                <div className='font-weight-600 font-16 ms-3 pt-1'>
                                                    User Roles & Permissions
                                                </div>
                                            </div>
                                            <div>
                                                <ButtonComponent.BlueBorderButton value='Add New Role(s)' clickEvent={handleRoleCreationModal} />
                                            </div>

                                        </div>
                                        {rolesList != null && rolesList.length > 0 ?
                                            <div class="col-lg-12 p-4">
                                                <div class="table-responsive-sm ">
                                                    <table className="table tCustom table-text">
                                                        <thead className='p-1-rem'>
                                                            <tr className='text-uppercase'>
                                                                <th>Role Name</th>
                                                                <th>Description</th>
                                                                <th></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rolesList.map((values, k) => (
                                                                <tr className='align-middle'>
                                                                    <td >
                                                                        {values.userRoleType}
                                                                    </td>
                                                                    <td>
                                                                        {values.description}
                                                                    </td>
                                                                    <td style={{ float: 'right' }}>
                                                                        <ButtonComponent.PermissionsButton value='Permissions' clickEvent={(e) => { handlePermissionsClick(e, values) }} />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            :
                                            <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                <div>
                                                    <div className='font-weight-600 color-243448'>No Roles Created</div>
                                                </div>
                                            </div>}
                                    </Card.Body>
                                </Card>

                            </TabPanel>
                            <TabPanel>
                                <Card className='bg-white'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <img src='assets/images/departmentIcon.svg' alt='Department' />
                                                </div>
                                                <div className='font-weight-600 font-16 ms-3 pt-1'>
                                                    Departments List
                                                </div>
                                            </div>
                                            <div>
                                                <ButtonComponent.BlueBorderButton value='Add New Department(s)' clickEvent={handleDepartmentModal} />
                                            </div>

                                        </div>
                                        {departmentList != null && departmentList.length > 0 ?

                                            <div class="col-lg-12 p-4">
                                                <div class="table-responsive-sm ">
                                                    <table className="table tCustom table-text">
                                                        <thead className='p-1-rem'>
                                                            <tr className='text-uppercase'>
                                                                <th>Department Name</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {departmentList.map((values, k) => (
                                                                <ListDepartments values={values} handleEditDepartments={handleEditDepartments} />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <div className='organisational-settings-bank-account-listing d-flex  justify-content-between mt-3'>
                                                <div>
                                                    <div className='font-weight-600 color-243448'>No Departments Created</div>
                                                </div>
                                            </div>}
                                    </Card.Body>
                                </Card>
                            </TabPanel>
                        </Tabs>
                        {bankAccountModal == true ?
                            <BankAccountModal
                                close={close}
                                handleBankAccountList={handleBankAccountList}
                                companyID={formik.values.companyID}
                                edit={edit}
                                bankForEdit={bankForEdit}
                                bankdetailsstate={location.state != null ? location.state.data.bankstatename : ''}
                                key={key}
                            />
                            :
                            ''
                        }
                        {roleCreationModal == true ?
                            <RoleCreationModal reloadList={reloadList} closeRoleModal={closeRoleModal} handleBankAccountList={handleBankAccountList} companyID={formik.values.companyID} data={location.state != null ? location.state.data.company : null} selectedRole={selectedRole} />
                            :
                            ''
                        }
                        {departmentModal == true ?
                            <DepartmentModal handleSaveDepartment={handleSaveDepartment} reloadDepartment={reloadDepartment} closeDepartment={closeDepartment} selectedDepartment={selectedDepartment} edit={editDepartment} />
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index