import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Moment from 'moment'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import NumberFormat from 'react-number-format';
import { BackButton, BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents'
import * as CONFIG from "../.././../../helpers/config"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useMsal } from "@azure/msal-react";

export default function DownloadCustomerPayment(props) {
    const { instance } = useMsal();
    const [customerInvoiceData, setCustomerInvoiceData] = useState()
    const [subTotal, setSubTotal] = useState()
    const [totalTax, setTotalTax] = useState()
    const [overAllTotal, setOverAllTotal] = useState()
    const [data, setData] = useState({})
    console.log(props)
    const location = useLocation()
    let navigate = useNavigate();
    const  tradeData = props.tradeData
    const tab = props.tab
    useEffect(()=> {
        let listValues = []
        let listValuesTax = []
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(tradeData.tradeID),
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
               console.log(res.data.result[0])
               console.log((parseInt((res.data.result[0].tradedetails.tradeProductDetail[0].productSellingPrice) * (res.data.result[0].tradedetails.tradeProductDetail[0].quantity))))
               setCustomerInvoiceData(res.data.result[0])
               res.data.result[0].tradedetails.tradeProductDetail.map((list, k) => (
                listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
                // listValues.push(parseInt((list.productSellingPrice) * (list.quantity)))
               ))
               console.log(listValues)
               let sum = 0
               let sumTax = parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax1)) + parseInt((res.data.result[0].tradedetails.tradeEnquiry.tax2))
               for (let i = 0; i < listValues.length; i++) {
                sum += listValues[i]
            }
            console.log(sum)
            setSubTotal(sum)
            setTotalTax(sumTax)
            setOverAllTotal(sum + sumTax)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        
        
    },[])
    const back = () => {
        props.back()
    }

    const printDocument = () => {
        const domElement = document.getElementById("download_section");
        html2canvas(domElement)
            .then((canvas) => {
                const imgWidth = 300;
                const pageHeight = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('l', 'mm', 'A4');
                doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 1.5, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save('Downld.pdf');
                // var doc = new jsPDF("p", "pt", "letter");
                // doc.html(document.getElementById("download_section"), {
                //     callback: function (doc) {
                //       doc.save();
                //     },
                //     margin: 10
                // });
               
              
            })
    }
    return (
        <div className='main-wrapper inner-wrapper d-flex'>
            <div className='width-100'>
                    <div className='d-flex mt-4' style={{paddingLeft: '37px',}}>
                        <div className = 'd-flex justify-content-center align-items-center '>
                            <BackButton clickEvent={back} />
                        </div>
                    </div>
             
                <div className='d-flex justify-content-between align-items-center mx-4 px-3 mt-3'>
                    <div className='d-flex align-items-center'>
                        <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>Customer Payment Receipt</span>
                    </div>
                    <div className = 'd-flex'>
                      
                        <div className = 'mx-2'>
                            <BlueBorderButton value = "EXPORT PDF" clickEvent={printDocument} />
                        </div>
                        
                    </div>
                </div>
                <div className='inner-page-content mx-4 px-3 mt-4 '  id='download_section'>
                    <Card>
                        <CardBody>
                        {customerInvoiceData != null ?
                        <>
                            <div className='mx-2 br-8 py-3 d-flex justify-content-between align-items-center'>
                                <div>
                                    <img src="/assets/images/logo.svg" className='img-fluid'/>
                                </div>
                                
                                <div className = 'd-flex flex-column'> 
                                    <div>
                                        Receipt #:  <b>{props.paymentData.recieptNo}</b>
                                    </div>
                                    <div>
                                        Invoice Date: <b>{Moment(props.paymentData.created_Datetime).format("DD/MM/YYYY")}</b>
                                    </div>
                                </div> 
                                </div>
                            <div className = 'mx-2 br-8 py-3 d-flex justify-content-between '>
                                <div style = {{width:'120px'}}>
                                    <p className = 'm-0 font-weight-600' style={{fontSize :'16px'}}><b>CUSTOMER</b></p>
                                    <p className = 'mt-2 p-0 mb-0'><b>{props.custcompdata.customer.customerName}</b></p>
                                    <p className = 'customer-invoice-text m-0 p-0'style={{width:'320px'}}>
                                        {props.custcompdata.customer.registeredUnitNo}, &nbsp;
                                        {props.custcompdata.customer.registeredBuildingName}, &nbsp;
                                        {props.custcompdata.customer.registeredStreetName}, &nbsp;
                                        {props.custcompdata.customer.registeredPostBox}, &nbsp;
                                        {props.custcompdata.customer.registeredCityName}, &nbsp;
                                        {/* {props.country} */}
                                    </p>
                                </div>
                                <div>
                                    <p className = 'm-0 font-weight-600' style={{fontSize :'16px'}}>SHIP TO</p>
                                    {props.custcompdata.customer.separateShipmentAddress === false ? 
                                        <>
                                        <p className = 'mt-2 p-0 mb-0'><b>{props.custcompdata.customer.customerName}</b></p>
                                        <p className = 'customer-invoice-text m-0 p-0'style={{width:'320px'}}>
                                            {props.custcompdata.customer.registeredUnitNo}, &nbsp;
                                            {props.custcompdata.customer.registeredBuildingName}, &nbsp;
                                            {props.custcompdata.customer.registeredStreetName}, &nbsp;
                                            {props.custcompdata.customer.registeredPostBox}, &nbsp;
                                            {props.custcompdata.customer.registeredCityName}, &nbsp;
                                            {props.country}
                                        </p>
                                        </>
                                        :
                                        <>
                                        <p className = 'mt-2 p-0 mb-0'><b>{props.custcompdata.customer.customerName}</b></p>
                                        <p className = 'customer-invoice-text'>
                                            {props.custcompdata.customer.shipmentUnitNo}, &nbsp;
                                            {props.custcompdata.customer.shipmentBuildingName}, &nbsp;
                                            {props.custcompdata.customer.shipmentStreetName}, &nbsp;
                                            {props.custcompdata.customer.shipmentPostBox}, &nbsp;
                                            {props.custcompdata.customer.shipmentCityName}, &nbsp;
                                            {props.country}
                                        </p>
                                        </>
                                    }
                                </div>
                                <div>
                                    <p className = 'm-0 font-weight-600' style={{fontSize :'16px'}}>SHIPMENT DETAILS</p>
                                    {/* <p className = 'mt-2 p-0 mb-0'><b>{customerInvoiceData.tradedetails.customer.customerName}</b></p> */}
                                    <p className = 'customer-invoice-text'>
                                       Mode Of Transport: <b>By Sea</b><br/ >
                                       Est Shipment Date: <br/ >
                                       Country Of Origin: <br/ >
                                       Load Port: <b>{customerInvoiceData.tradedetails.tradeEnquiry.loadPort}</b><br/ >
                                       Destination Port: <b>{customerInvoiceData.tradedetails.tradeEnquiry.destinationPort}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="table-responsive-sm ">
                                <table className="table tCustom table-text border-bottom-0">
                                    <thead className='p-1-rem'>
                                        <tr className='text-uppercase'>
                                            <th>PRODUCT CODE</th>
                                            <th>DESCRIPTION</th>
                                            <th>PAYMENT METHOD</th>
                                            <th>QTY</th>
                                            <th>UNIT PRICE</th>
                                            <th>AMOUNT</th>
                                        </tr>
                                     </thead>
                                     <tbody className = ''>
                                        {customerInvoiceData != null &&
                                                customerInvoiceData.tradedetails.tradeProductDetail.map((list, k) => (
                                                   <tr>
                                                    <td>{list.product.productCode}</td>
                                                    <td>{list.product.productName}</td>
                                                    <td>{props.paymentData.paymentType.typeName}</td>
                                                    <td><NumberFormat value = {list.quantity} displayType={'text'} thousandSeparator={true}/> {list.productUOM.productUOMName}</td>
                                                   
                                                    <td>${list.productSellingPrice}</td>
                                                   
                                                    <td>$<NumberFormat value = {props.paymentData.customerPaymentAmount} displayType={'text'} thousandSeparator={true}/></td>
                                                   </tr>
                                                ))
                                            }
                                            <tr className = 'border-0'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="table-secondary border border-secondary">SUB TOTAL:</td>
                                                <td className="table-secondary border border-secondary">$<NumberFormat value = {props.paymentData.customerPaymentAmount} displayType={'text'} thousandSeparator={true}/></td>
                                            </tr>
                                        
                                            <tr className = ''>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="border border-secondary">GRAND TOTAL:</td>
                                                <td className="border border-secondary">$<NumberFormat value = {props.paymentData.customerPaymentAmount} displayType={'text'} thousandSeparator={true}/></td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                            </>
                                :
                                ''
                                }
                
                    
                        </CardBody>
                    </Card>
                   
                </div>
            </div>


        </div>
    )
}
