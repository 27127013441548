import React,{useState} from 'react'
import {Row, Col, Form } from "react-bootstrap";
import { useFormik } from 'formik';
import { BlueButton, BlueBorderButton } from '../../../../../../components/buttoncomponents'
import * as FormFields from "../../../../../../components/formcomponents";
import Moment from 'moment';

function FormComponent(props) {
    const [dataSubmissionValidation, setDataSubmissionValidation] = useState(false)
    const [DateOfIncorporation, SetDateOfIncorporation] = useState(false);
    const [isError, setIsError] = useState(true);
    console.log(props.data)
    console.log(props)
    const handleDOC = (date)=>{
        formik.values.appointedDate = Moment(date).format('YYYY-MM-DDTHH:mm:ss')
        SetDateOfIncorporation(date)
    }
    const addDirector = ()=>{
 
            const director_list ={
                "directorName": formik.values.directorName,
                "directorDesignation": formik.values.directorDesignation,
                "directorNationality": formik.values.directorNationality,
                "directorNationalIdentityNo": formik.values.directorNationalIdentityNo,
                "appointedDate": formik.values.appointedDate,
                "is_Default" : false,
            }
            console.log(director_list, 'sds')
            console.log(props)
            props.addDirectorToList(director_list)
        
       
    }

    const formik = useFormik({
        initialValues:
        !props.isEdit?
        {
            directorName: '',
            directorDesignation: '',
            directorNationality: '',
            directorNationalIdentityNo: '',
            appointedDate: null,
        }
        :
        {
            directorName: props.data.directorName,
            directorDesignation: props.data.directorDesignation,
            directorNationality: props.data.directorNationality,
            directorNationalIdentityNo: props.data.directorNationalIdentityNo,
            appointedDate: props.data.appointedDate,
        },
        validate: values => {
            
            let errors = {}
            const letters = /^[A-Za-z ]+$/;
            const phone = /^[0-9]+$/;

            if(!values.directorName){
                errors.directorName = "Please fill in name of Director"
            }else if (!letters.test(values.directorName)) {
                errors.directorName = "Please enter a valid Name"
            }

            if (!letters.test(values.directorDesignation) && values.directorDesignation) {
                errors.directorDesignation = "Please enter a valid Designation"
            }
            if (!letters.test(values.directorNationality) && values.directorNationality) {
                errors.directorNationality = 'Please enter a valid Nationality'
            }
            setIsError(true)
            sendBack();
         
            return errors
        }

    })

    const editBank = ()=>{
        if(props.isEdit == true){
            const director_list = {
                "directorName": formik.values.directorName,
                "directorDesignation": formik.values.directorDesignation,
                "directorNationality": formik.values.directorNationality,
                "directorNationalIdentityNo": formik.values.directorNationalIdentityNo,
                "appointedDate":formik.values.appointedDate,
                "is_Default" : props.data.is_Default,
                "key" :props.data.key,
                "supplierCompanyDirectorID" : props.data.supplierCompanyDirectorID,
                "supplierID": props.data.supplierID
            }
            props.editDirectorToList(director_list)
        }
        else{
            const director_list = {
                "directorName": formik.values.directorName,
                "directorDesignation": formik.values.directorDesignation,
                "directorNationality": formik.values.directorNationality,
                "directorNationalIdentityNo": formik.values.directorNationalIdentityNo,
                "appointedDate":formik.values.appointedDate,
                "is_Default" : props.data.is_Default,
                "key" :props.data.key
            }
            props.editDirectorToList(director_list)
        }
   
    }

    const sendBack = () => {

        if ((Object.keys(formik.errors).length == 0) ) {
            
            if((props.isBackClicked == true) 
            && (formik.initialValues == formik.values) 
            && (isError == false)){
                console.log('condition one')
                setIsError(false)
                props.handleValidationDirector()
            // console.log('error checking')
            // if((props.isBackClicked == true) && (formik.initialValues == formik.values) && (isError == false)){
            //     console.log(formik.errors)
            //     console.log('no error first condition')
            //     setIsError(false)
            //     props.handleError()    
            // }
            // else if(formik.initialValues == formik.values){
            //     console.log(formik.errors)
            //     console.log('error')
            //     props.setErrors()
            // }
            // else{
            //     setIsError(false)
            //     console.log(formik.errors)
            //     console.log('no error first condition')
            //     props.handleError()    
            // }
            }
            else if(formik.initialValues == formik.values){
                    setIsError(true)
                    console.log(formik.errors)
                    console.log('error')
                    props.setErrors()
            }
            else{
                console.log('no log')
                setIsError(false)
                props.handleValidationDirector()
            }
            
              
        }
        else{
            setIsError(true)
            console.log(formik.errors)
            console.log('error')
            props.setErrors()
        }
    }

    console.log(formik.errors)
  return (
    <>
            <Form>
                <Row>
                    <FormFields.InputField
                        md={3}
                        name='directorName'
                        label='Name Of Director'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.directorName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.directorName?formik.errors.directorName:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='directorDesignation'
                        label='Designation'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.directorDesignation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.directorDesignation?formik.errors.directorDesignation:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='directorNationality'
                        label='Nationality'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.directorNationality}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors= {formik.touched.directorNationality?formik.errors.directorNationality:''}
                    />
                    <FormFields.InputField
                        md={3}
                        name='directorNationalIdentityNo'
                        label='National Identity No'
                        classname='pt-1-rem'
                        type='text'
                        val={formik.values.directorNationalIdentityNo}
                        onChange={formik.handleChange}
                    />
                     <FormFields.DatePick 
                        md={3}
                        label='Appointed Date' 
                        name='appointedDate'
                        classname='pt-1-rem iconDatePicker'
                        handleCompletionDate={handleDOC}
                        isRequired={false}
                        val={formik.values.appointedDate}
                    />
                    <Col className='d-flex align-items-end'>
                        <BlueButton value={props.isEdit?'Edit Director':'Add Director'} clickEvent={props.isEdit?editBank:addDirector} disabled = {props.isEdit == true ? false : isError}/>
                        <div className = 'ms-4'>
                            <BlueBorderButton value={'Cancel'} clickEvent={props.handleCancel}/>
                        </div>
                    </Col>

                </Row>
            </Form>

    </>

  )
}

export default FormComponent