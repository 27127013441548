import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, Col, FloatingLabel, Form } from "react-bootstrap";
import List from "./components/List"
import { BlueButton } from '../../../components/buttoncomponents';
import * as CONFIG from "../../../helpers/config"
import Sidebar from '../../../components/sidebar/sidebar';
import Navbar from '../../../components/navbar';
import TradeInitiationEdit from '../../Trading/Trade Initiation'
import AddCostSheet from '../../CostSheet/index1'
import { Link, useLocation } from 'react-router-dom'
import { useMsal } from "@azure/msal-react";

function Index() {
    const { instance } = useMsal();
    const location = useLocation()
    const {tab} = location.state != null && location.state
    const [selectedTab, setSelectedTab] = useState(0)
    const [tradeData, setTradeData] = useState([])
    const [showList, setShowList] = useState(false)
    const [tradeInitiateEdit, setTradeInitiateEdit] = useState(false)
    const [tradeInitEditData, setTradeInitEditData] = useState([])
    const [tradestatus, settradestatus] = useState(false)
    const [showcostsheet, setshowcostsheet] = useState(false)
    const [showviewcostsheet, setshowviewcostsheet] = useState(false)
    const [tradeDataForCostSheet, setTradeDataForCostSheet] = useState()
    const [tradeDataForViewCostSheet, setTradeDataForViewCostSheet] = useState()
    const [tradeEnquiryByID, setTradeEnquiryByID] = useState()
    const [tabselected, settabselected] = useState(1)
    const [isloader, setisloader] = useState(false)
    //const [tradeData, setTradeData] = useState(
    const [all_list, setall_list] = useState([])

    useEffect(() => {
        getTrade(0)
    }, [])
    
    useEffect(()=>{
        console.log(isloader)
    },[isloader])

    const getTrade = (id) => {
        console.log(id)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
            setTradeData(res.data.result);
            setall_list(res.data.result)
            setShowList(true)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const handleEdit = (data) => {
        console.log(data)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": data.data.tradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_INITIATION_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setTradeInitEditData(res.data.result[0]);
                setTradeInitiateEdit(true)
                setTradeEnquiryByID(res.data.result[0].tradedetails.tradeEnquiry)
                setShowList(false)
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
        
    }
    
    const addProduct = () => {
        setShowList(true)
    }

    const handleEventCondition = () => {

    }

    const addcostsheet = (data) => {
        console.log('Checked at oindex', data)
        setTradeDataForCostSheet(data)
        setshowcostsheet(true);
        setShowList(false)
    }

    const viewcostsheet = (data) => {
        console.log('Checked at oindex', data)
        setTradeDataForViewCostSheet(data)
        setshowcostsheet(false);
        setshowviewcostsheet(true);
        setShowList(false)
    }

    const toggleCost = () => {
        setshowcostsheet(!showcostsheet)
        // getTrade()
    }

    const getTrades = (id) =>{
        setisloader(true)
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id" : id
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_DETAILS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
        .then((res) => {
                console.log(res.data.result, 'get all trade data')
                setTradeData(res.data.result);
                setShowList(true)
                setisloader(false)
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.clear();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
            }
        })  
    }

    const openTab = (id)=>{
        getTrades(id)
    }

    const handleSearch = (event)=>{
        let val = event.currentTarget.value
        console.log(val)
        let filter = all_list.map(item => ({
          ...item,
          tradeProductDetails: item.tradeProductDetails
            .filter(child => child.productName.toLowerCase().includes(val.toLowerCase()))
        }))
        .filter(item => item.tradeProductDetails.length > 0)
        let f = all_list.filter(x => x.customerName.toLowerCase().includes(val.toLowerCase()) || (x.vesselName != null && x.vesselName.toLowerCase().includes(val.toLowerCase())))
        filter = [...filter, ...f]
        setTradeData(filter)
    }

    return (
        <>
            <div className='main-wrapper inner-wrapper d-flex'>
                <Sidebar id='5.2' isOpenid={5} />
                <div className='width-100'>
                    <Navbar />
                    <div className='d-flex inner-page-header justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img src='assets/images/sidebaricons/TradeEnquiry.svg' className='inner-page-titleicon img-fluid' /><span className='font-16 font-weight-600 color-707895 d-flex align-items-center pl-10'>TRADE TRANSACTIONS</span>
                        </div>
                        {showList == true ?
                            <div className='me-3'>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label='Search'>
                                        <Form.Control type='text' placeholder='Search' 
                                            className="form_input form_input_color_change" 
                                            name='search'
                                            onChange={handleSearch}
                                            onBlur={handleSearch}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </div>
                            :
                            <>
                                
                                <BlueButton value='Back' clickEvent={addProduct} />
                            </>
                        }
                    </div>
                    <div className=''>
                        {showList == true ?
                            <div className='inner-page-content'>
                                <Tabs defaultIndex={tab}>
                                    <Card className='tabs-wrapper'>
                                        <TabList>
                                            <Tab onClick={()=>{
                                                openTab(0)
                                            }}>Contract Preperation.</Tab>
                                            <Tab onClick={()=>{
                                                openTab(1)
                                            }}>Shipment Execution</Tab>
                                            <Tab onClick={()=>{
                                                openTab(4)
                                            }}>Shipment Completed (Pre-Closure)</Tab>
                                            <Tab onClick={()=>{
                                                openTab(5)
                                            }}>Closed Trades</Tab>
                                        </TabList>
                                    </Card>
                                     <TabPanel className='mt-1-rem'>
                                        {isloader?
                                        <center>
                                            <img src='assets/images/loadertrad.gif' className='img-fluid'/>
                                        </center>
                                        :
                                        <div className='inner-page-content' style={{ background: 'white' }}>
                                            {(localStorage.getItem('List_Trade') == 1 || localStorage.getItem('List_Trade') == 3) && <List handleEdit={handleEdit} tradeData={tradeData} addcostsheet={addcostsheet} viewcostsheet={viewcostsheet} refreshList={()=>{
                                                getTrade(0)
                                            }} status={1}/>}
                                        </div>
                                        }
                                    </TabPanel>
                                    <TabPanel className='mt-1-rem'>
                                        {isloader?
                                            <center>
                                                <img src='assets/images/loadertrad.gif' className='img-fluid'/>
                                            </center>
                                            :
                                            <div className='inner-page-content' style={{ background: 'white' }}>
                                                {(localStorage.getItem('List_Trade') == 1 || localStorage.getItem('List_Trade') == 3) && <List handleEdit={handleEdit} tradeData={tradeData} addcostsheet={addcostsheet} viewcostsheet={viewcostsheet} refreshList={()=>{
                                                    getTrade(1)
                                                }} status={2}/>}
                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel className='mt-1-rem'>
                                        {isloader?
                                            <center>
                                                <img src='assets/images/loadertrad.gif' className='img-fluid'/>
                                            </center>
                                            :
                                            <div className='inner-page-content' style={{ background: 'white' }}>
                                                {(localStorage.getItem('List_Trade') == 1 || localStorage.getItem('List_Trade') == 3) && <List handleEdit={handleEdit} tradeData={tradeData} addcostsheet={addcostsheet} viewcostsheet={viewcostsheet} refreshList={()=>{
                                                    getTrade(4)
                                                }} status={4}/>}
                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel className='mt-1-rem'>
                                        {isloader?
                                            <center>
                                                <img src='assets/images/loadertrad.gif' className='img-fluid'/>
                                            </center>
                                            :
                                            <div className='inner-page-content' style={{ background: 'white' }}>
                                                {(localStorage.getItem('List_Trade') == 1 || localStorage.getItem('List_Trade') == 3) && <List handleEdit={handleEdit} tradeData={tradeData} addcostsheet={addcostsheet} viewcostsheet={viewcostsheet} refreshList={()=>{
                                                    getTrade(5)
                                                }} status={5}/>}
                                            </div>
                                        }
                                    </TabPanel>
                                </Tabs>
                            </div>
                            :
                            showcostsheet == true ?
                                <AddCostSheet tradeDataForCostSheet={tradeDataForCostSheet} toggleCost={toggleCost} />
                                :
                                tradeInitiateEdit == true ?
                                    <div className='inner-page-content w-100'>
                                        <TradeInitiationEdit TradeInitiateData={tradeInitiateEdit ? tradeInitEditData : ''} tradeInitiateEdit={tradeInitiateEdit} TradeEnquiryByIDData={tradeEnquiryByID} />
                                    </div>
                                    :
                                    ''
                            //showviewcostsheet == true ?
                            //    <ViewCostSheet tradeDataForViewCostSheet={tradeDataForViewCostSheet} />
                            //    : ''


                        }
                    </div>
                    <div className='inner-page-content'>
                        {handleEventCondition()}
                    </div>
                </div>


            </div>
        </>
    )
}

export default Index