import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import styles from './styles.module.css'
import GreetingsContainer from "./Components/Greeting Component/index"
import Tradeoverview from "./Components/Trade Overview/index"
import PurchaseTrends from "./Components/Trends/index"
import SalesTrends from "./Components/Trends Sales/index"
import PaymentsOverview from "./Components/Payments Overview/index"
import CashFlows from "./Components/Cash Flows/index"
import * as CONFIG from "../../../helpers/config"
import Division from "../Common Components/Divisions/index"
import TotalCounter from "../Common Components/Total Counters/index"
import Notifications from "../Common Components/Notifications/index"


export default function Index(props) {
    const { instance } = useMsal();
    const [customerCount, setCustomerCount] = useState()
    const [supplierCount, setSupplierCount] = useState()
    const [meetingCount, setMeetingCount] = useState()
    const [closedTradeDetails , setClosedTradeDetails] = useState()
    const [notification, setNotifications] = useState([])
    const [shipmentDetails, setShipmentDetails] = useState()

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CUSTOMER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setCustomerCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_SUPPLIER_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setSupplierCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "userID" : CONFIG.LoginID
        
    }
    axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DB_MEETING_COUNT, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setMeetingCount(res.data.result)
    }).catch((error)=>{
        if(error.response.status == 401){
            localStorage.clear();
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
        }
    })  
    },[])

    useEffect(()=>{
      let final_json = {
        "ID": CONFIG.LoginID
    }
    axios.post(CONFIG.PRO_SERVER_URL + CONFIG.GET_ALL_NOTIFICATIONS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setNotifications(res.data.result)
    })
    },[])


    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "userID": CONFIG.LoginID,
        "dashboardTypeID": parseInt(4),
        "companyID" : parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_CLOSED_TRADE_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setClosedTradeDetails(res.data.result)
        //setNotifications(res.data.result)
    })
    },[props])

    useEffect(()=>{
      let final_json = {
        "instanceID": CONFIG.PMSInstanceID,
        "accountSubscriptionID": CONFIG.AccountSubscriptionID,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "userID" : CONFIG.LoginID,
        "dashboardTypeID": parseInt(4),
        "companyID" : parseInt(props.companyID)
    }
    axios.post(CONFIG.TMS_SERVER_URL + CONFIG.DB_TRADE_SHIPMENT_DETAILS, final_json, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
    .then((res) => {
        console.log(res.data.result)
        setShipmentDetails(res.data.result)
        //setNotifications(res.data.result)
    })
    },[props])

    useEffect(()=>{
      console.log(notification)
    },[notification])
    return (
            <>
              <div className='inner-page-content'>
                <div className = 'row'>
                  <div className = 'col-md-5 col-sm-12'>
                      <GreetingsContainer 
                         closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}
                         toDate = {props.toDate} fromDate = {props.fromDate} terms = {props.terms}
                         companyID = {props.companyID}
                      />
                  </div>
                  <div className = 'col-md-3 col-sm-12'>
                    <Division companyID = {props.companyID} terms = {props.terms} dashboardTypeID = {props.dashboardTypeID} toDate = {props.toDate} fromDate = {props.fromDate}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12 '>
                    <div className = 'row mt-md-0 mt-4'>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {customerCount} text = {'CUSTOMERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                      </div>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {supplierCount} text = {'SUPPLIERS'} img = '/assets/images/supports/dashboard-customer-counter-icon.svg'/>
                      </div>
                      <div className = 'col-4'>
                        <TotalCounter companyID = {props.companyID} title = {meetingCount} text = {'MEETINGS'} img = '/assets/images/supports/dashboard-meeting-counter-icon.svg'/>
                      </div>
                    </div>
                    <div className = 'row mt-4'>
                      <div className = 'col-12'>
                        <Notifications notification = {notification != null && notification.length > 0 ? notification : '' }/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className = {`ms-4 mt-5 ${styles.titleHeader}`}>
                  Trade Overview
                </div>
                <div div className = 'mt-4 row'>
                  <div className = 'col-md-4 col-sm-12'>
                    <SalesTrends companyID = {props.companyID} toDate = {props.toDate} fromDate = {props.fromDate} terms = {props.terms} closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12'>
                    <PurchaseTrends companyID = {props.companyID} toDate = {props.toDate} fromDate = {props.fromDate} terms = {props.terms} closedTradeDetails = {closedTradeDetails != null ? closedTradeDetails : 0}/>
                  </div>
                  <div className = 'col-md-4 col-sm-12'>
                    <PaymentsOverview companyID = {props.companyID}  shipmentDetails = {shipmentDetails != null ? shipmentDetails : 0}/>
                  </div>
                </div>
                <div className = {`mt-5 ${styles.titleHeader}`}>
                    Cash Flow
                </div>
                <div className = 'mt-4 row'>
                  <div className = 'col-md-6 col-sm-12'>
                    <CashFlows companyID = {props.companyID} />
                  </div>
                </div>
              </div>
            </>
    )
}
