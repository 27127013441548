import React from 'react'
import styles from './Overview.module.css'
import NumberFormat from 'react-number-format';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function Index(props) {
  console.log(props)
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        }
      },
      x:{
        grid: {
          display: false
        },
        ticks: {
          display: false
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: true,
      },
    },
  };
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  const dataLoss  = {
    labels,
    datasets: [
      {
        fill: false,
        label: 'Dataset 2',
        data: [12, 19, 8, 11, 10, 7, 10, 20, 5],
        borderColor: 'red',
        lineTension: 0
      },
    ],
  };

  const dataProfit = {
    labels,
    datasets: [
      {
        fill: false,
        label: 'Dataset 2',
        data: [12, 19, 8, 11, 10, 7, 10, 20, 5],
        borderColor: 'green',
        lineTension: 0
      },
    ],
  };
  return (
    <>
        <div className = {`${styles.trendContainer}`}>
            <div className = {`${styles.trendHeader}`}>
              <div className = 'd-flex align-items-center justify-content-between '>
                  <div className = 'd-flex flex-column'>
                    <div>
                      Payments
                    </div>
                    <div className = {`mt-2 ${styles.trendsHeading}`}>
                      <NumberFormat value={props.shipmentDetails.shipmentCompletedCount != null ? props.shipmentDetails.shipmentCompletedCount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                    <div className = {`mt-2 ${styles.subtext}`}>
                      total payments
                    </div>
                  </div>
              </div>
  
            </div>
            <div className = 'd-flex'>
                <div>
                    <img src = '/assets/images/supports/finance-dashboard-payments-overview-icon.svg' />
                </div>
                <div className = 'd-flex flex-column ms-3 mt-3'>
                    <div className = {`mt-4 ${styles.subtext}`}>
                      Pending Payables
                    </div>
                    <div className = {`${styles.text}`}>
                      $<NumberFormat value={props.shipmentDetails.totalPayableAmount != null ? props.shipmentDetails.totalPayableAmount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                    <div className = {`mt-5 ${styles.subtext}`}>
                      pending receivables
                    </div>
                    <div className = {` ${styles.text}`} style={{marginBottom:'33px'}}>
                    $<NumberFormat value={props.shipmentDetails.totalReceivableAmount != null ? props.shipmentDetails.totalReceivableAmount : 0} displayType={'text'} thousandSeparator={true}  />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Index