import React from 'react'
import { useEffect, useState } from 'react'
import { useMsal } from "@azure/msal-react";
import * as CONFIG from "../../helpers/config"
import axios from 'axios'
import Moment from 'moment'
import { Col, Row, Accordion, Form, Button } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as FormFields from "../../components/formcomponents"
import FileBase64 from 'react-file-base64';
import { useFormik } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export const HandleTaskAttachments = (props) => {
    const { instance } = useMsal();
    const saveAttachments = (files) => {
        let json_list = []
        files.map((file, k) => {
            let json_in = {
                "meetingAgendaTaskID": props.id,
                "taskAttahchment": file.base64,
                "attachmentName": file.name,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            json_list.push(json_in)
        })
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_ATTACHMENTS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                props.getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    return (
        <div className='p-0_5-rem'>
            <label className={'task_button_attachment font-12 font-weight-600 d-flex justify-content-center align-items-center color-707895 cursor-pointer '}>
                <div className=" ">
                    <div className='d-flex align-items-end '>
                        <div className='mx-2 d-flex align-items-end uploadText '>
                            <img src='assets/images/paperclip.svg' />
                        </div>
                        <FileBase64 className="form_input" onDone={saveAttachments} multiple={true} />
                    </div>
                </div>
            </label>
        </div>
    )
}

export default function ViewMeeting(props) {
    const [modal, setModal] = useState(props.isOpen)
    const { instance } = useMsal();
    const [meeting, setmeeting] = useState([])
    const [userlist, setuserlist] = useState([])
    const [commentstatus, setcommentstatus] = useState([])
    const [commentlist, setcommentlist] = useState([])
    const [commenterror, setcommenterror] = useState(false)
    const [meetingid, setmeetingid] = useState(0)

    useEffect(() => {
        getmeetingbyid()
    }, [])

    const getmeetingbyid = () => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(props.id)
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_BY_ID, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setmeeting(res.data.result[0])
                getUsers()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const getUsers = () => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID
        }

        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_USER, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                setuserlist(res.data.result)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }

    const formik = useFormik({
        initialValues: {
            comment: ''
        },
        validate: values => {
            setcommenterror(false)
        }
    })

    useEffect(() => {
        console.log(commentstatus)
        console.log(commentlist)
    }, [commentstatus, commentlist])

    const showAddComments = (e) => {
        let index = e.target.id
        handleTaskComment(index)
        let commentst = [...commentstatus]
        commentst['"' + index + '"'] = true
        setcommentstatus(commentst)
    }
    const handleTaskComment = (id) => {
        const json_category = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "id": parseInt(id),
            "created_User": CONFIG.LoginID
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.GET_MEETING_TASK_COMMENTS, json_category, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                let cmtlist = [...commentlist]
                cmtlist['"' + id + '"'] = res.data.result
                setcommentlist(cmtlist)
                console.log(commentlist)
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }


    const removeComment = () => {
        let commentst = [...commentstatus]
        commentst = []
        setcommentstatus(commentst)
    }
    const saveComment = (e) => {
        const id = e.target.id
        if (formik.values.comment != '') {
            setcommenterror(false)
            const json_list = {
                "meetingAgendaTaskID": parseInt(id),
                "comment": formik.values.comment,
                "commentedBy": CONFIG.LoginID,
                "instanceID": CONFIG.PMSInstanceID,
                "accountSubscriptionID": CONFIG.AccountSubscriptionID,
                "created_User": CONFIG.LoginID
            }
            axios.post(CONFIG.PCS_SERVER_URL + CONFIG.ADD_MEETING_TASK_COMMENTS, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
                .then((res) => {
                    console.log(res.data.result)
                    handleTaskComment(parseInt(id))
                    let commentst = [...commentstatus]
                    commentst = []
                    setcommentstatus(commentst)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }
                })
        }
        else {
            setcommenterror(true)
        }
    }

    const removeDocument = (e) => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": parseInt(e.currentTarget.id)
        }
        axios.post(CONFIG.PCS_SERVER_URL + CONFIG.DELETE_MEETING_ATTACHMENT, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                getmeetingbyid()
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.clear();
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    });
                }
            })
    }
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-custom-width trade_existing_customer view_meeting">
            <>
                <div className='meeting_vew_top_header'>
                    {meeting.meeting != null &&
                        <>
                            <span> Created By {Moment(meeting.meeting.meetingDate).format('DD/MM/YYYY')}</span>
                            {meeting.meeting.is_Meeting == 1 ?
                                <h4>{meeting.meeting.meetingTitle}</h4>
                                :
                                <h4>Ref : {meeting.meeting.documentNumberForDocControl}</h4>
                            }
                        </>
                    }
                </div>
                <div className='meeting_innner_info mb-10-rem'>
                    <Row>
                        {meeting.meeting != null &&
                            <>
                                <Col md={1} className='meeting_inner_left_info  d-flex justify-content-center' style={{ width: '10%', height: '100px' }}>
                                    <div>
                                        {(Moment(meeting.meeting.meetingDate).format('DD  MMM').toString().toUpperCase())}
                                        <span className='meeting_time d-flex justify-content-between'>
                                            {Moment(meeting.meetingstarttime).format('h:mm a')} - {Moment(meeting.meetingendtime).format('h:mm a')}
                                        </span>
                                    </div>
                                </Col>
                                <Col md={9} className='meeting_inner_right_info'>
                                    <Row className=' d-flex justify-content-between'>
                                        <Col md={6}>
                                            {meeting.meeting.meetingCategory != null &&
                                                <span className='meeting_category_title'>{meeting.meeting.meetingCategory.meetingCategoryName}</span>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <span className='meeting_hosted_by'>Hosted By</span>
                                            {meeting.meeting.meetingUser != null &&
                                                <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                    <p className='font-14 font-weight-500 mb-0'>{meeting.meeting.meetingUser.userAvatar}</p>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='mt-2-rem d-flex justify-content-between'>
                                        <Col md={6}>
                                            <span className='meeting_hosted_by mb-1-rem'>Attendees</span>
                                            <div className='d-flex align-items-center mt-2'>
                                                {meeting.meeting.meetingRequiredAttendance.map((attendees, k) => {
                                                    return (
                                                        <div className='avatar d-flex align-items-center justify-content-center me-1' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                            <p className='font-14 font-weight-500 mb-0'>{attendees.meetingUser.userAvatar}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <span className='meeting_hosted_by'>Notify Members</span>
                                            <div className='d-flex align-items-center mt-2'>
                                                {meeting.meeting.meetingNotifyUser.map((attendees, k) => {
                                                    return (
                                                        <div className='avatar d-flex align-items-center justify-content-center' style={{ padding: '0px', width: '40px', height: '40px' }}>
                                                            <p className='font-14 font-weight-500 mb-0'>{attendees.meetingUser.userAvatar}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2-rem d-flex justify-content-between'>
                                        <Col md={6}>
                                            <span className='meeting_category_title'>Sub Tasks</span>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2-rem meeting_content_view'>
                                        <Tabs>
                                            <TabList>
                                                {meeting.meeting.is_Meeting == 1 ?
                                                    <>
                                                        <Tab>Agenda</Tab>
                                                        <Tab>Pre-Meeting Tasks</Tab>
                                                    </>
                                                    :
                                                    <Tab>Discussion Points</Tab>
                                                }
                                            </TabList>
                                            {meeting.meeting.is_Meeting == 1 ?
                                                <>
                                                    <TabPanel>
                                                        <Accordion className='meeting_accordin mt-2-rem'>
                                                            {meeting.meeting.meetingAgenda.map((agenda, k) => {
                                                                return (
                                                                    <Accordion.Item className='mb-2' eventKey={k}>
                                                                        <Accordion.Header>

                                                                            <span className='bgMeeting'> <img src='assets/images/meeting_task.svg' /> </span>
                                                                            <span className='meeting_agendaname'>
                                                                                {agenda.agendaName}
                                                                            </span>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {agenda.meetingAgendaTask != null &&
                                                                                <>
                                                                                    {agenda.meetingAgendaTask.map((task, l) => {
                                                                                        let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                                                        return (
                                                                                            <>
                                                                                                <div className='d-flex task1'>
                                                                                                    <div className='task_div'>
                                                                                                        <img src='assets/images/meeting_task.svg' />
                                                                                                    </div>
                                                                                                    <div className='task_div '>
                                                                                                        <Form.Check
                                                                                                            name='taskCompletedStatus' disabled={true}
                                                                                                            checked={task.is_Completed}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                                                        <div>
                                                                                                            <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                                                            <div className='meeting_inner_task_date'>{Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                                                            <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='task_div '>
                                                                                                    </div>
                                                                                                    <div className='task_div d-flex'>
                                                                                                        <p className='p-0_5-rem'>
                                                                                                            Assigned to:
                                                                                                        </p>
                                                                                                        {users.length > 0 &&
                                                                                                            <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                                                    <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                                                </div>
                                                                                                                <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='d-flex'>
                                                                                                    {task.taskAttahcments != null &&
                                                                                                        task.taskAttahcments.map((att, k) => {
                                                                                                            console.log(att)
                                                                                                            return (
                                                                                                                <div className='document_badge px-4 py-2 badge-size color-document mb-2 align-self-center m-1-rem'>
                                                                                                                    <a download={att.attachmentName} href={att.taskAttahchment}>
                                                                                                                        {att.attachmentName}
                                                                                                                    </a>
                                                                                                                    <span>
                                                                                                                        <img src='assets/images/close.svg' className='pointer' id={att.taskAttahcmentsID} onClick={removeDocument} />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                                <div>
                                                                                                    {commentlist['"' + task.meetingAgendaTaskID + '"'] != null &&
                                                                                                        <>
                                                                                                            {console.log(commentlist['"' + task.meetingAgendaTaskID + '"'])}
                                                                                                            {commentlist['"' + task.meetingAgendaTaskID + '"'].map((comment, k) => {
                                                                                                                return (
                                                                                                                    <div className='comment_box'>
                                                                                                                        <div className={comment.userID == CONFIG.LoginID ? 'comment_user_avatar d-flex justify-content-end mt-1-rem' : 'comment_user_avatar d-flex mt-1-rem'}>
                                                                                                                            <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                                                <p className='font-12 font-weight-500 mb-0'>{comment.commentedByNameAvatar}</p>
                                                                                                                            </div>
                                                                                                                            <div className='pl-10'>
                                                                                                                                <div className='user_info_meeting_comments'>{comment.commentedByName}</div>
                                                                                                                                <div className='user_designation_meeting'>{comment.commentedByDesignation}</div>
                                                                                                                                <div className='meeting_comments_data mt-1-rem'>{comment.meetingComments}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                                {commentstatus['"' + task.meetingAgendaTaskID + '"'] &&
                                                                                                    <Row className='meeting_task_comment_section'>
                                                                                                        <Col md={1} className='d-flex align-items-center'>
                                                                                                            <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                                <p className='font-12 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col md={7} className='border_bottom_input'>
                                                                                                            <FormFields.BottomBorderInputField
                                                                                                                md={12}
                                                                                                                label='Add your comment here...'
                                                                                                                name='comment'
                                                                                                                onChange={formik.handleChange}
                                                                                                                onBlur={formik.handleBlur}
                                                                                                            />
                                                                                                            {commenterror &&
                                                                                                                <span className='input_error'>Please enter a comment</span>
                                                                                                            }
                                                                                                        </Col>
                                                                                                        <Col md={4} className='d-flex align-items-center'>
                                                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={task.meetingAgendaTaskID} onClick={removeComment}>
                                                                                                                Cancel
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="outline-primary"
                                                                                                                size="lg"
                                                                                                                className={'font-weight-500 font-14 rounded-13 task_description_save'}
                                                                                                                type="button"
                                                                                                                id={task.meetingAgendaTaskID}
                                                                                                                onClick={saveComment}
                                                                                                            >
                                                                                                                Add
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                                <div className='task_div d-flex justify-content-end'>
                                                                                                    <div className='p-0_5-rem'>
                                                                                                        <Button variant='outline-secondary' className='task_button' id={task.meetingAgendaTaskID} onClick={showAddComments}>
                                                                                                            <img src='assets/images/comments_icon.svg' id={task.meetingAgendaTaskID} onClick={showAddComments} />
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                    <HandleTaskAttachments id={task.meetingAgendaTaskID} getmeetingbyid={getmeetingbyid} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            }
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            })}
                                                        </Accordion>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Accordion className='meeting_accordin mt-2-rem'>
                                                            {meeting.meeting.meetingAgendaTask.map((agenda, k) => {
                                                                return (
                                                                    <Accordion.Item className='mb-2' eventKey={k}>
                                                                        <Accordion.Header>

                                                                            <span className='bgMeeting'> <img src='assets/images/meeting_task.svg' /> </span>
                                                                            <span className='meeting_agendaname'>
                                                                                {agenda.task}
                                                                            </span>
                                                                        </Accordion.Header>

                                                                    </Accordion.Item>
                                                                )
                                                            })}
                                                        </Accordion>
                                                    </TabPanel>
                                                </>
                                                :
                                            <TabPanel>
                                                <Accordion className='meeting_accordin mt-2-rem'>
                                                    {meeting.meeting.meetingAgendaTask.map((task, k) => {
                                                        console.log('Tour tasks', task)
                                                        let users = userlist.filter(x => x.meetingUserID == task.meetingUserID)
                                                        return (
                                                            <>

                                                                <div className='d-flex task1'>
                                                                    <div className='task_div'>
                                                                        <img src='assets/images/meeting_task.svg' />
                                                                    </div>
                                                                    <div className='task_div '>
                                                                        <Form.Check
                                                                            name='taskCompletedStatus' disabled={true}
                                                                            checked={task.is_Completed}
                                                                        />
                                                                    </div>
                                                                    <div className='meeting_task_title task_div d-flex align-items-center border_bottom_input task_description'>
                                                                        <div>
                                                                            <div className='meeting_inner_task_heading' style={{ color: '#62668B' }}>{task.task}</div>
                                                                            <div className='meeting_inner_task_date'>{Moment(task.taskDueDate).format('DD/MM/YYYY')}</div>
                                                                            <div className='mt-1-rem'>{task.taskDescription}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='task_div '>
                                                                    </div>
                                                                    <div className='task_div d-flex'>
                                                                        <p className='p-0_5-rem'>
                                                                            Assigned to:
                                                                        </p>
                                                                        {users.length > 0 &&
                                                                            <div className='chip_meeting_task_user d-flex' style={{ padding: '6px 9px 3px 9px' }}>
                                                                                <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', fontSize: '12px' }}>
                                                                                    <p className='font-12 font-weight-500 mb-0'>{users[0].userAvatar}</p>
                                                                                </div>
                                                                                <p style={{ padding: '5px 0 0 3px' }}>{users[0].userName}</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    {task.taskAttahcments != null &&
                                                                        task.taskAttahcments.map((att, k) => {
                                                                            console.log(att)
                                                                            return (
                                                                                <div className='document_badge px-4 py-2 badge-size color-document mb-2 align-self-center m-1-rem'>
                                                                                    <a download={att.attachmentName} href={att.taskAttahchment}>
                                                                                        {att.attachmentName}
                                                                                    </a>
                                                                                    <span>
                                                                                        <img src='assets/images/close.svg' className='pointer' id={att.taskAttahcmentsID} onClick={removeDocument} />
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {commentlist['"' + task.meetingAgendaTaskID + '"'] != null &&
                                                                        <>
                                                                            {console.log(commentlist['"' + task.meetingAgendaTaskID + '"'])}
                                                                            {commentlist['"' + task.meetingAgendaTaskID + '"'].map((comment, k) => {
                                                                                return (
                                                                                    <div className='comment_box'>
                                                                                        <div className={comment.userID == CONFIG.LoginID ? 'comment_user_avatar d-flex justify-content-end mt-1-rem' : 'comment_user_avatar d-flex mt-1-rem'}>
                                                                                            <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                                <p className='font-12 font-weight-500 mb-0'>{comment.commentedByNameAvatar}</p>
                                                                                            </div>
                                                                                            <div className='pl-10'>
                                                                                                <div className='user_info_meeting_comments'>{comment.commentedByName}</div>
                                                                                                <div className='user_designation_meeting'>{comment.commentedByDesignation}</div>
                                                                                                <div className='meeting_comments_data mt-1-rem'>{comment.meetingComments}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    }
                                                                </div>
                                                                {commentstatus['"' + task.meetingAgendaTaskID + '"'] &&
                                                                    <Row className='meeting_task_comment_section'>
                                                                        <Col md={1} className='d-flex align-items-center'>
                                                                            <div className='p-0_5-rem avatar d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', fontSize: '12px' }}>
                                                                                <p className='font-12 font-weight-500 mb-0'>{localStorage.getItem('userAvatar')}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={7} className='border_bottom_input'>
                                                                            <FormFields.BottomBorderInputField
                                                                                md={12}
                                                                                label='Add your comment here...'
                                                                                name='comment'
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            {commenterror &&
                                                                                <span className='input_error'>Please enter a comment</span>
                                                                            }
                                                                        </Col>
                                                                        <Col md={4} className='d-flex align-items-center'>
                                                                            <Button variant="outline-primary" size="lg" className={'font-weight-500 font-14 rounded-13 task_description_cancel'} type="button" id={task.meetingAgendaTaskID} onClick={removeComment}>
                                                                                Cancel
                                                                            </Button>
                                                                            <Button
                                                                                variant="outline-primary"
                                                                                size="lg"
                                                                                className={'font-weight-500 font-14 rounded-13 task_description_save'}
                                                                                type="button"
                                                                                id={task.meetingAgendaTaskID}
                                                                                onClick={saveComment}
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                <div className='task_div d-flex justify-content-end'>
                                                                    <div className='p-0_5-rem'>
                                                                        <Button variant='outline-secondary' className='task_button' id={task.meetingAgendaTaskID} onClick={showAddComments}>
                                                                            <img src='assets/images/comments_icon.svg' id={task.meetingAgendaTaskID} onClick={showAddComments} />
                                                                        </Button>
                                                                    </div>
                                                                    <HandleTaskAttachments id={task.meetingAgendaTaskID} getmeetingbyid={getmeetingbyid} />
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </Accordion>
                                            </TabPanel>
                                            }
                                        </Tabs>
                                    </Row>
                                </Col>
                            </>
                        }
                    </Row>
                </div>
            </>

        </Modal>
    )
}
