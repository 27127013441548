import React from 'react'
import { Dropdown, Menu, Space } from 'antd';


export default function Listing(props) {
  const menu = (
    <Menu
      items={[
        {
            key: '1',
            label: (
              !props.onlyRemove && <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.id} onClick={props.edit}>Edit</span>
            ),
        },
        {
          key: '2',
          label: (
              <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.id} onClick={props.delete}>Remove</span>
          ),
      }
      ]}
    />
  );
  return (
    <>
      <tr id={props.id}>
          <td class="py-4 px-2">{props.name}</td>
          <td className='text-right'>

              {/* <EditButton /> */}


              <Dropdown overlay={!props.isMenuDisabled?menu:''}>
                  <a onClick={(e) => e.preventDefault()}>
                  <Space>
                      <img src = '/assets/images/Dot_icon.svg' />
                  </Space>
                  </a>
              </Dropdown>
          </td>
      </tr>
    </>
  )
}
