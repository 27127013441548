import React,{useEffect, useState} from 'react'
import { Dropdown, Menu, Space } from 'antd';
import {useFormik } from 'formik'
import {  Col, Card, Form } from "react-bootstrap"
import axios from 'axios'
import * as CONFIG from "../../../../helpers/config"
import { useMsal } from "@azure/msal-react";

export default function SupplierList(props) {
    console.log(props)
    const { instance } = useMsal();
    const [modal, setModal] = useState(true);
    const [checked, setChecked] = useState(false);
    const [checkedID, setCheckedID] = useState()
    const [data, setData] = useState([])
    const [list, setList] = useState()
    
    const formik = useFormik({
        initialValues: {
            supplierData : []
        },
      })
    
    const trackCheck = (e) => {
        console.log(props)
        let id = parseInt(e.target.id);
        //props.selectedSupplier(props.data)
        setList(id)
    }
    useEffect(()=>{
        props.sendBack(list)
    },[list])
    return (
        <tr className='py-3 br-bottom-1 '>
            <td className='font'><div className = 'idStatus'> {props.data.suppliercode}</div></td>
            <td className=''>{props.data.suppliername}</td>
            <td className=''>{props.data.address}</td>
            <td className=''>{props.data.category}</td>
            <td className=''>{props.data.incoterms}</td>
            <td>
            <Form.Check 
                    type='radio'
                    name='supplierData'
                    id={props.data.supplierID}
                    defaultChecked = {props.selectedSupplier != null ? 
                        props.selectedSupplier.supplierID == props.data.supplierID ?
                        true :
                        false :
                        false
                    }
                    onChange = {trackCheck}
                    className='mx-3 slectSupplier'
                />
            </td>
        </tr>
    )
}
