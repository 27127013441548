import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"
import { BlueBorderButton, BlueButton } from '../../../../components/buttoncomponents';
import * as CONFIG from "../../../../helpers/config"
import { useFormik } from 'formik'
import axios from 'axios'
import { Row, Col, Form, Card } from "react-bootstrap"
import * as FormFields from "../../../../components/formcomponents"
import { useMsal } from "@azure/msal-react";

export default function Postvesselfixture(props) {
    const { instance } = useMsal();
    const [modal, setModal] = useState(props.view)
    const [doc1, setdoc1] = useState(false)
    const toggle = () => {
        setModal(!modal)
        props.toggle();
    }
    const formik = useFormik({
        initialValues: {
            tradePostVasselFixtureCheckListID: 0,
            vesselName: '',
            eTALoadPort: '',
            ETADischargePort: '',
            VesselDespatch: '',
            VesselDemurrage: '',
            is_NominateVesselSupplier: false,
            is_NominateVesselCustomer: false,
            is_CFSupplier: false,
            is_DraftDocumentChecked: false,
        }
    })
    useEffect(() => {
        const json_list = {
            "instanceID": CONFIG.PMSInstanceID,
            "accountSubscriptionID": CONFIG.AccountSubscriptionID,
            "created_User": CONFIG.LoginID,
            "id": props.tradeID // TradeID
        }
        axios.post(CONFIG.TMS_SERVER_URL + CONFIG.GET_TRADE_POST_VESSEL, json_list, { headers: { "Authorization": `Bearer ${CONFIG.AuthToken}`, "Ocp-Apim-Subscription-Key": CONFIG.API_KEY } })
            .then((res) => {
                console.log(res.data.result)
                if (res.data.result.length > 0) {
                    formik.values.tradePostVesselFixtureCheckListID = res.data.result[0].tradePostVesselFixtureCheckListID
                    formik.values.is_NominateVesselSupplier = res.data.result[0].is_NominateVesselSupplier
                    formik.values.is_NominateVesselCustomer = res.data.result[0].is_NominateVesselCustomer
                    formik.values.is_CFSupplier = res.data.result[0].is_CFSupplier
                    formik.values.is_DraftDocumentChecked = res.data.result[0].is_DraftDocumentChecked
                    console.log(formik.values.is_DraftDocumentChecked)
                    setdoc1(!doc1)
                }
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.clear();
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                }
            })  
    }, [])
    return (
        <>
            <div className='p-4 mx-3 mt-4'>
                    <div className='font-16 font-weight-600 color-707895'>
                    POST-VESSEL FIXTURE CHECKLIST
                    </div>
                </div>

                <div className='add-edit-wrapper'>
                    <div className='card card-body p-4 br-8 mb-4'>
                    <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                    <Col md={6}>
                        <Form.Check
                            className=' font-15 font-weight-400 color-2C2C2E'
                            type='checkbox'
                            name='is_NominateVesselSupplier'
                            label='Vessel - Supplier acceptance?'
                            checked={formik.values.is_NominateVesselSupplier}
                        />
                    </Col>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>


                <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                    <Col md={6} >
                        <Form.Check
                            className='font-15 font-weight-400 color-2C2C2E'
                            type='checkbox'
                            name='is_NominateVesselCustomer'
                            label='Vessel - Customer acceptance?'
                            checked={formik.values.is_NominateVesselCustomer}
                        />
                    </Col>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>

                <Row className='border-bottom-1 py-4 d-flex align-items-center'>
                    <Col md={6} >
                        <Form.Check
                            className='font-15 font-weight-400 color-2C2C2E'
                            type='checkbox'
                            name='is_CFSupplier'
                            label='C&F - Documents Sent to Customers for Obtaining cargo Over?'
                            checked={formik.values.is_CFSupplier}
                        />
                    </Col>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>


                <Row className='border-bottom-1 py-4  d-flex align-items-center'>
                    <Col md={6} >
                        <Form.Check
                            className='font-15 font-weight-400 color-2C2C2E'
                            type='checkbox'
                            name='is_DraftDocumentChecked'
                            label='DRAFT Documents checked prior Vessel loading?'
                            checked={formik.values.is_DraftDocumentChecked}
                        />
                    </Col>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                    </div>

                
                </div>
                

                
                

                
        </>
    )
}
