import React, { useEffect, useState } from 'react'
import {Row, Col, Card} from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Productsform from './productsform'
import { BlueBorderButton, BlueButton } from '../../../components/buttoncomponents'
import Listing from '../common/listing'
import { AddNewButton } from '../../../components/buttoncomponents'
import * as CONFIG from "../../../helpers/config"
import axios from "axios"
import FileBase64 from 'react-file-base64'; 

export default function View(props) {
    const [gradeList, setGradeList] = useState([]);
    const [isFirst, setIsFirst] = useState(true)
    const [proval, setproval] = useState([])
    const [isproval, setisproval] = useState(false)
    const [proimg, setproimg] = useState(null);
    useEffect(()=>{
        if(isFirst){
            let product_val = {
                "productCode" : props.selectedProduct[0].productCode,
                "productName" : props.selectedProduct[0].productName,
                "productCategoryID": parseInt(props.selectedProduct[0].productCategoryID),
                "productGroupID" : parseInt(props.selectedProduct[0].productGroupID),
                "productUOMID" : parseInt(props.selectedProduct[0].productUOMID),
                "description" : props.selectedProduct[0].description,
            }   
            setproimg(props.selectedProduct[0].productImagebase64);
            setproval(product_val) 
            setisproval(true)
        }
        setIsFirst(false)
    },[isFirst])

   
    return (
      <div >
          <Row>
              <Col md={7} className='ms-4 me-3 py-3'>
                    <Card style={{  height: '70vh', background:'transparent'}}>
                        <Card style={{background: 'transparent',overflowY: 'auto' }}>
                            <Card.Body className='px-4 pb-4 br-8' style={{background: '#fff'}}>
                                <div className='d-flex justify-content-between width-100l'>
                                    <div className='font-16 font-weight-400 color-343A40'>General Information</div>
                                </div>
                                {isproval &&
                                <Productsform isView={props.isView} proval={proval}/>
                                }
                            </Card.Body>
                            {/* <Card.Body className='mt-2-rem mb-10-rem' style={{background: '#fff'}}>
                                    <Row>
                                        <table className="table table-text mt-1-rem">
                                            <thead>
                                                <tr className='bg-F8F9FA font-weight-500 color-707895'>
                                                    <th>
                                                        Product Grade
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gradeList.map((grade,k)=>(
                                                    <Listing key={k} 
                                                        id={grade.productGradeID} 
                                                        name={grade.productGradeName} 
                                                        isMenuDisabled={props.isView}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>

                                    </Row>
                            </Card.Body> */}
                        </Card>
                        <Card.Footer className='form-card-footer pt-4 d-flex'>
                            <span><BlueBorderButton value='Cancel' clickEvent={props.cancel}/></span>
                            <span className='pl-10'><BlueButton value='Save' disabled={true}/></span>
                        </Card.Footer>
                    </Card>
              </Col>
              <Col md={3} className='py-3'>
                  <Card className='br-8 p-2-rem'>
                        <div className='width-100l d-flex justify-content-center'>
                            {proimg!=null?
                                <img className='img-fluid' src={proimg}/>

                                :
                                <div className='product_img_wrapper'>
                                
                                </div>
                            }
                        </div>
                  </Card>
              </Col>
          </Row>
      </div>
    )
}
