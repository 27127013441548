import React from 'react'
import { Dropdown, Menu, Space } from 'antd';

export default function ListData(props) {
    console.log(props.is_DefaultSelected,'isprimary')
    const menu = (
        <Menu
          items={[
            {
                key: '1',
                label: (
                    <span className='font-weight-400 font-14 pl-10 mb-1-rem' id={props.values.key}  onClick={props.editBank}>Edit</span>
                ),
            },
           
          ]}
        />
      );
    return (
        <tr className='supplier_supplier_row font-weight-600 font-13 color-707895'>
            <td>{props.values.make}</td>
            <td>{props.values.noOfMachines}</td>
            <td>{props.values.capacityPerKGHr}</td>
            <td>{props.values.capacityMTPerMonth}</td>
            <td>{props.values.actualProductionMTPerMonth}</td>
            <td style={{top:'5px', position:'relative'}}>{props.is_Default &&
                    <span className='contact-form-primary-badge'>Primary</span>
                }
            </td>
            <td>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    ...
                </Space>
                </a>
            </Dropdown>
            </td>
        </tr>
    )
}
